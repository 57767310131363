import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Updater } from 'dg-web-shared/lib/Flux.tsx';
import * as HttpResponse from 'dg-web-shared/lib/HttpResponse.ts';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as FilteredPermitsState from '../../clearance-permit-list/state/FilteredPermitsState.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { PrimaryActionButton } from '../../ui/buttons/TextButton.tsx';
import { SlideInBody } from '../../ui/slidein/Slidein.tsx';
import * as PermitEditState from '../state/PermitEditState.ts';
import { RefundContent } from './PermitCancellationSlideIn.tsx';
import { Localized } from '../../common/components/Localized.tsx';

const stornoPermit = AsyncRequest.request(
    Http.OperatorAccount.Permits.storno,
    (store: Flux.Store, res: HttpResponse.Response): string => {
        PermitEditState.PermitCancellationResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            PermitEditState.Edit.reset(store);
            PermitEditState.Refund.reset(store);
            PermitEditState.Server.setResponse(store, res);
            ResultState.refetchSameContext(store, true);
            FilteredPermitsState.List.reset(store);
        }
        return 'PermitEdit-stornoPermit';
    },
);

export function StornoHeaderButton({
    permit,
    refund,
    refData,
    update,
}: {
    permit: Maybe<PermitEditState.Permit>;
    refund: PermitEditState.Refund.State;
    refData: OperatorDataState.OperatorData | null;
    update: Updater;
}) {
    if (isDefined(permit) && isDefined(refData)) {
        return (
            <PrimaryActionButton
                label={
                    <Localized
                        de="Stornieren"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                }
                onClick={() =>
                    update(stornoPermit, {
                        id: permit.id,
                        refund: {
                            doRefund: refund.doRefund,
                            remark: refund.remark ?? (permit.remark || ''),
                        },
                    })
                }
            />
        );
    } else {
        return null;
    }
}

export function StornoBody({
    permit,
    confirmRefundAllowed,
    refund,
    settings,
    isFormValid,
    update,
}: {
    permit: Maybe<PermitEditState.Permit>;
    confirmRefundAllowed: boolean;
    refund: PermitEditState.Refund.State;
    settings: SettingsState.State;
    isFormValid: boolean;
    update: Updater;
}) {
    return (
        <SlideInBody>
            {isDefined(permit) && (
                <RefundContent
                    permit={permit}
                    confirmRefundAllowed={confirmRefundAllowed}
                    onlyFullRefundAllowed={true}
                    refund={refund}
                    settings={settings}
                    isFormValid={isFormValid}
                    update={update}
                />
            )}
        </SlideInBody>
    );
}
