import moment from 'moment';

import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { DateHeader } from '../../../clearance-permit-list/components/shared/DateHeader.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { DatePicker } from '../../../ui/date-picker/DatePicker.tsx';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from '../../../ui/slidein/Slidein.tsx';
import { outOfServiceTexts } from '../../i18n/TomTexts.ts';
import * as TomLayoutState from '../../state/TomLayoutState.ts';
import * as TomState from '../../state/TomState.ts';

const cn = ElementNamer('OutOfServiceDate');

interface OutOfServiceStartDateHalfSlideInState {
    settings: SettingsState.State;
    form: TomState.OutOfService.Form.State;
    layout: TomLayoutState.Layout.State;
}

export class OutOfServiceDateHalfSlideIn extends Flux.Container<OutOfServiceStartDateHalfSlideInState> {
    static displayName: string = cn('HalfSlideIn');

    stateSelector(): OutOfServiceStartDateHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            form: TomState.OutOfService.Form.get(this.props.allState),
            layout: TomLayoutState.Layout.get(this.props.allState),
        };
    }

    getMinPossibleDate(): moment.Moment {
        return moment().endOf('day');
    }

    renderValidFrom(): JSX.Element | null {
        const texts = outOfServiceTexts[this.state.settings.language];
        if (!this.state.layout) {
            return null;
        }
        const defaultMinDate = this.getMinPossibleDate();
        return (
            <div>
                <DateHeader
                    label={texts.StartDate()}
                    date={this.state.form.startDate}
                />
                <DatePicker
                    currentDate={defaultMinDate}
                    selectedDate={this.state.form.startDate}
                    minDate={defaultMinDate}
                    onSelect={d =>
                        this.update(store =>
                            TomState.OutOfService.Form.stateWrite(store, {
                                startDate: d.startOf('day'),
                                endDate: d.isBefore(
                                    this.state.form.endDate ||
                                        moment('1970-01-01'),
                                )
                                    ? this.state.form.endDate
                                    : null,
                            }),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    renderToDatePicker(): JSX.Element {
        const texts = outOfServiceTexts[this.state.settings.language];
        const defaultMinDate = this.getMinPossibleDate();
        return (
            <div style={{ marginTop: 32 }}>
                <DateHeader
                    label={texts.EndDate()}
                    date={this.state.form.endDate}
                />
                <DatePicker
                    currentDate={defaultMinDate}
                    selectedDate={this.state.form.endDate}
                    minDate={this.state.form.startDate || defaultMinDate}
                    onSelect={d =>
                        this.update(store =>
                            TomState.OutOfService.Form.stateWrite(store, {
                                endDate: d.endOf('day'),
                            }),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    render() {
        const texts = outOfServiceTexts[this.state.settings.language];
        return (
            <HalfSlideIn open={this.state.layout.outOfServiceDatePickerOpen}>
                <SecondLevelHeader
                    onClose={() =>
                        this.props.allState.update(store =>
                            TomLayoutState.Layout.stateWrite(store, {
                                outOfServiceDatePickerOpen: false,
                            }),
                        )
                    }
                >
                    <SlideInHeaderTexts
                        subtitle={''}
                        title={texts.OutOfServiceDateSlideInTitle()}
                        hasLeftIcon={false}
                    />
                </SecondLevelHeader>
                <SlideInBody>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {this.renderValidFrom()}
                        {this.renderToDatePicker()}
                    </div>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

interface OutOfServiceDateSelectionState {
    settings: SettingsState.State;
    form: TomState.OutOfService.Form.State;
    layout: TomLayoutState.Layout.State;
}

interface OutOfServiceDateSelectionProps extends Flux.ContainerProps {
    isValid: boolean;
    showErrors: boolean;
}

export class OutOfServiceDateSelection extends Flux.ContainerWithProps<
    OutOfServiceDateSelectionProps,
    OutOfServiceDateSelectionState
> {
    static displayName: string = cn('Selection');

    stateSelector(): OutOfServiceDateSelectionState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            form: TomState.OutOfService.Form.get(this.props.allState),
            layout: TomLayoutState.Layout.get(this.props.allState),
        };
    }

    render() {
        const texts = outOfServiceTexts[this.state.settings.language];
        const selection = Formatter.openRange(
            this.state.form.startDate,
            this.state.form.endDate,
            Formatter.dayMonthYear,
        );
        const write = (s: Partial<TomLayoutState.Layout.State>) =>
            this.props.allState.update(store =>
                TomLayoutState.Layout.stateWrite(store, s),
            );

        return (
            <div>
                <SingleSelection
                    label={texts.OutOfServiceDateSlideInTitle()}
                    focused={false}
                    selection={selection}
                    onClick={() => write({ outOfServiceDatePickerOpen: true })}
                    errorText={
                        this.props.showErrors && !this.props.isValid
                            ? texts.MessageDateConstraints()
                            : undefined
                    }
                />
            </div>
        );
    }
}
