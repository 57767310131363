import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Badge, Permit } from '../../state/ResultState.ts';
import { selectPermit } from '../../actions/ClearancePermitListActions.ts';
import {
    ColumnWidth,
    TableColumn,
    TableRow,
} from '../../../ui/table/Table.tsx';
import { InfoItem } from '../shared/InfoItem.tsx';
import { css } from '@emotion/css';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import {
    getPermitStatusBackground,
    getPermitStatusColor,
} from '../../../ui/permit/permitStatusColors.ts';
import { PermitStatus } from '../../state/PermitsState.ts';
import { ColorHex } from '../../../ui/Colors.ts';
import { IdentificationLicensePlate } from '../../../common/components/IdentificationItems.tsx';
import { clearfix } from 'dg-web-shared/ui/clearfix.ts';
import { Icon16, Icon24 } from '../../../ui/icons/Icon.tsx';
import React from 'react';

const IdentifierIcon = (props: { icon: JSX.Element }) => (
    <div
        className={css({
            width: '20px',
            paddingTop: '2px',
            color: ColorHex.darkblue,
        })}
    >
        <Icon16 icon={props.icon} />
    </div>
);

interface PermitItemProps {
    permit: Permit;
    update: Flux.Updater;
    key?: React.Key;
}

export const permitStatusIcon = (p: Permit) => {
    switch (p.status) {
        case PermitStatus.active:
            return Icons24.permitState.active;
        case PermitStatus.stornod:
            return Icons24.permitState.canceled;
        case PermitStatus.future:
            return Icons24.permitState.future;
        default:
            return Icons24.permitState.expired;
    }
};

export class PermitItem extends React.Component<PermitItemProps> {
    renderLicensePlates(): JSX.Element[] {
        return this.props.permit.licensePlates.map(lp => (
            <IdentificationLicensePlate key={lp.id} {...lp} inlineMode={true} />
        ));
    }

    renderBadges(): JSX.Element[] {
        return this.props.permit.badges.map((badge: Badge) => (
            <div key={badge.id} className={clearfix}>
                <IdentifierIcon icon={Icons16.identifier.badge} />
                <div className={css(OperatorTypo.body)}>{badge.labelNr}</div>
            </div>
        ));
    }

    render() {
        const p = this.props.permit;
        const validFrom = thenElse(
            p.validFrom,
            v => Date.Formatter.dayMonthYear(Date.Parser.isoToMoment(v)),
            '',
        );
        const validTo = thenElse(
            p.validTo,
            v =>
                Date.Parser.isoToMoment(v).isBefore(
                    Date.Parser.isoToMoment('2030-01-01'),
                )
                    ? Date.Formatter.dayMonthYear(Date.Parser.isoToMoment(v))
                    : '',
            '',
        );

        return (
            <TableRow
                onClick={() =>
                    this.props.update(store => selectPermit(store, p.id))
                }
                className={css({
                    ...OperatorTypo.body,
                    ...getPermitStatusBackground(p.status, true),
                    color: ColorHex.darkblue,
                })}
            >
                <TableColumn width={ColumnWidth._24px} />
                <TableColumn width={ColumnWidth._192px} noPadding={true}>
                    <div
                        className={css({
                            display: 'flex',
                            paddingLeft: '4px',
                            alignItems: 'center',
                        })}
                    >
                        <div
                            className={css({
                                color: getPermitStatusColor(p.status),
                                width: '32px',
                                paddingTop: '2px',
                            })}
                        >
                            <Icon24 icon={permitStatusIcon(p)} />
                        </div>
                        <div>
                            {this.renderLicensePlates()}
                            {this.renderBadges()}
                        </div>
                    </div>
                </TableColumn>
                <TableColumn width={ColumnWidth._72px}>{validFrom}</TableColumn>
                <TableColumn width={ColumnWidth._72px}>{validTo}</TableColumn>
                <TableColumn width={ColumnWidth.variable}>
                    {this.props.permit.permitTypeName}
                </TableColumn>
                <TableColumn width={ColumnWidth._312px}>
                    <InfoItem
                        icon={Icons16.identifier.permitId}
                        value={this.props.permit.id.toString()}
                    />
                    <InfoItem
                        icon={Icons16.identifier.infoField}
                        value={this.props.permit.additionalInfos}
                    />
                    <InfoItem
                        icon={Icons16.identifier.infoField}
                        value={this.props.permit.remarks}
                    />
                </TableColumn>
                <TableColumn width={ColumnWidth._24px} captionFontSize={true}>
                    <div
                        className={css({
                            paddingTop: '7px',
                        })}
                    >
                        <Icon16 icon={Icons16.chevronRight} />
                    </div>
                </TableColumn>
            </TableRow>
        );
    }
}
