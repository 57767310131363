export type QuotaConfig = QuotaPerEntity | QuotaOverlapping;

export enum QuotaInfoType {
    CONTRACT_PURCHASE_PER_ENTITY = 'CONTRACT_PURCHASE_PER_ENTITY',
    CONTRACT_PURCHASE_OVERLAPPING = 'CONTRACT_PURCHASE_OVERLAPPING',
}

export enum QuotaPerEntityPeriod {
    CALENDAR_MONTH = 'CALENDAR_MONTH',
    CALENDAR_YEAR = 'CALENDAR_YEAR',
}

export interface QuotaPerEntity {
    type: QuotaInfoType.CONTRACT_PURCHASE_PER_ENTITY;
    period: QuotaPerEntityPeriod;
    limit: number;
}

export interface QuotaOverlapping {
    type: QuotaInfoType.CONTRACT_PURCHASE_OVERLAPPING;
    limit: number;
}

export function quotaPartitionConfigText(
    quotaConfig: QuotaConfig,
    isOffstreet: boolean,
) {
    switch (quotaConfig.type) {
        case QuotaInfoType.CONTRACT_PURCHASE_OVERLAPPING:
            return {
                de: `${quotaConfig.limit} Bewilligungen (gleichzeitig gültig)`,
                fr: `${quotaConfig.limit} autorisations (valables simultanément)`,
                it: `${quotaConfig.limit} autorizzazioni (valide contemporaneamente)`,
                en: `${quotaConfig.limit} permits (valid at the same time)`,
            };

        case QuotaInfoType.CONTRACT_PURCHASE_PER_ENTITY:
            switch (quotaConfig.period) {
                case QuotaPerEntityPeriod.CALENDAR_MONTH:
                    return {
                        de: `${quotaConfig.limit} Tage pro Monat (pro ${
                            isOffstreet ? `Badge` : `Kennzeichen`
                        })`,
                        fr: `${quotaConfig.limit} jours par mois (par ${
                            isOffstreet ? `badge` : `plaque`
                        })`,
                        it: `${quotaConfig.limit} giorni al mese (per ${
                            isOffstreet ? `badge` : `targa`
                        })`,
                        en: `${quotaConfig.limit} days per month (per ${
                            isOffstreet ? `badge` : `licence plate`
                        })`,
                    };

                case QuotaPerEntityPeriod.CALENDAR_YEAR:
                    return {
                        de: `${quotaConfig.limit} Tage pro Jahr (pro ${
                            isOffstreet ? `Badge` : `Kennzeichen`
                        })`,
                        fr: `${quotaConfig.limit} jours par an (par ${
                            isOffstreet ? `badge` : `plaque`
                        })`,
                        it: `${quotaConfig.limit} giorni all'anno (per ${
                            isOffstreet ? `badge` : `targa`
                        })`,
                        en: `${quotaConfig.limit} days per year (per ${
                            isOffstreet ? `badge` : `licence plate`
                        })`,
                    };
            }
    }
}
