import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as Text from '../../common/i18n/Text.ts';
import { tomFilterTexts } from '../i18n/TomTexts.ts';
import * as TomState from '../state/TomState.ts';
import { tomSelectionText } from './TomFilterHalfSlideIn.tsx';

const cn = ElementNamer('TomFilter');

export interface TomFilterTexts {
    filterTitle: Text.Translation;
    dismantled: Text.Translation;
}

interface TomFilterSelectionState {
    settings: SettingsState.State;
    toms: TomState.List.State;
}

interface TomFilterSelectionProps extends Flux.ContainerProps {
    selectedToms: number[];
    onOpenSlideIn: () => void;
    onClear: () => void;
}

export class TomsFilterSelection extends Flux.ContainerWithProps<
    TomFilterSelectionProps,
    TomFilterSelectionState
> {
    static displayName: string = cn('Selection');

    stateSelector(): TomFilterSelectionState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            toms: TomState.List.get(this.props.allState),
        };
    }

    render() {
        const texts = tomFilterTexts[this.state.settings.language];
        const selectedToms = this.props.selectedToms;
        const hasTomSelected = selectedToms.length > 0;

        return (
            <div>
                <SingleSelection
                    label={texts.filterTitle()}
                    focused={false}
                    selection={tomSelectionText(
                        selectedToms,
                        this.state.toms.data.length,
                    )}
                    onClick={() => this.props.onOpenSlideIn()}
                    onClear={hasTomSelected ? () => this.props.onClear() : null}
                />
            </div>
        );
    }
}
