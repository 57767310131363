import { outOfServiceTexts } from '../../i18n/TomTexts.ts';
import { getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../../ui/Colors.ts';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import {
    LINE_DELIMITER,
    MAX_BODY_LINE_LENGTH,
    MAX_TITLE_LINE_LENGTH,
} from 'taxomex-shared/tariff/texts/MessageLineLength.ts';

export interface OutOfServiceMessageProps {
    title?: string | null;
    body?: string | null;
    language: string;
    showLabel?: boolean | null;
}

export function OutOfServiceMessage(
    props: OutOfServiceMessageProps,
): JSX.Element {
    const texts = outOfServiceTexts[props.language];
    const title = definedAndNonEmpty(props.title)
        ? (props.title as string)
        : texts.OutOfServiceTitleDefault();
    const body = definedAndNonEmpty(props.body)
        ? (props.body as string)
        : texts.OutOfServiceBodyDefault();
    const showLabel = getOrElse(props.showLabel, false);

    return (
        <div
            className={css({
                backgroundColor: ColorHex.rgba(ColorHex.darkblue, 0.1),
                width: '100%',
                textAlign: 'center',
                margin: 'auto auto 20px',
                padding: '20px',
                ...Typo.robotoLight,
                color: ColorHex.darkblue,
            })}
        >
            {showLabel && <div>{texts.OutOfService()}</div>}
            <h1
                className={css({
                    ...OperatorTypo.headingTwo,
                    ...Typo.robotoRegular,
                })}
            >
                {breakLines(title, MAX_TITLE_LINE_LENGTH)}
            </h1>
            <p>{breakLines(body, MAX_BODY_LINE_LENGTH)}</p>
        </div>
    );
}

function definedAndNonEmpty(text: string | null | undefined): boolean {
    return !!text && text.length > 0;
}

function breakLines(text: string, maxCharacters = 15): JSX.Element {
    const lines = text.split(LINE_DELIMITER);
    return (
        <span>
            {lines.map((l, i) => (
                <span key={i}>
                    {l.substr(0, maxCharacters)}
                    <br />
                </span>
            ))}
        </span>
    );
}
