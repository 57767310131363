import moment from 'moment';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { Translations } from '../../common/i18n/Text.ts';
import { TariffTexts } from '../components/TariffsBody.tsx';
import { TariffCalculatorTexts } from '../components/TariffCalculator.tsx';
import * as TariffDefinition from 'dg-web-shared/common/tariff-logic/TariffDefinition.ts';

/* tslint:disable */

export const tariffTexts: Translations<TariffTexts> = {
    de: {
        Holidays: () => 'Feiertage',
        InheritMode: () => 'Erbmodus',
        InheritModeInheritTime: () => 'Erbzeit',
        InheritModeReset: () => 'Zurückstellung',
        InheritModeValue: (mode: TariffDefinition.InheritMode) => {
            switch (mode) {
                case 'inheritTime':
                    return tariffTexts['de'].InheritModeInheritTime();
                case 'reset':
                default:
                    return tariffTexts['de'].InheritModeReset();
            }
        },
        Name: () => 'Bezeichnung',
        NoHolidays: () => 'Keine Feiertage',
        Tariff: () => 'Tarifname',
        TariffCard: () => 'Tarifschild',
    },
    fr: {
        Holidays: () => 'Jours fériés',
        InheritMode: () => "Mode d'héritage",
        InheritModeInheritTime: () => 'Temps hérité',
        InheritModeReset: () => 'Réinitialisation',
        InheritModeValue: (mode: TariffDefinition.InheritMode) => {
            switch (mode) {
                case 'inheritTime':
                    return tariffTexts['fr'].InheritModeInheritTime();
                case 'reset':
                default:
                    return tariffTexts['fr'].InheritModeReset();
            }
        },
        Name: () => 'Désignation',
        NoHolidays: () => 'Pas de jours fériés',
        Tariff: () => 'Nom tarif',
        TariffCard: () => 'Plaquette tarifaire',
    },
    it: {
        Holidays: () => 'Giorni festivi',
        InheritMode: () => 'Modalità durata residua ',
        InheritModeInheritTime: () => 'Durata residua',
        InheritModeReset: () => 'Ripristino',
        InheritModeValue: (mode: TariffDefinition.InheritMode) => {
            switch (mode) {
                case 'inheritTime':
                    return tariffTexts['it'].InheritModeInheritTime();
                case 'reset':
                default:
                    return tariffTexts['it'].InheritModeReset();
            }
        },
        Name: () => 'Denominazione',
        NoHolidays: () => 'Nessun giorno festivo',
        Tariff: () => 'Nome tariffa',
        TariffCard: () => 'Targhetta tariffa',
    },
};

export const tariffCalculatorTexts: Translations<TariffCalculatorTexts> = {
    de: {
        AmountMustBePositiveNumber: () => 'Betrag muss positiv sein',
        Date: () => 'Datum',
        DateIsMandatory: () => 'Datum ist ein Pflichtfeld',
        InheritedMinutes: () => 'Erbzeit [min]',
        InheritedTimeMustBePositiveNumber: () =>
            'Erbzeit muss positiv und in Minuten sein',
        MaximumParkingTimeReached: () => 'Maximale Parkzeit erreicht',
        MissingInput: () => 'Fehlende Eingaben',
        NetInheritedTime: (inheritedMin: number) =>
            `Netto Erbzeit: ${inheritedMin} Minuten`,
        ParkingTimeMinutes: (minutes: number) => `${minutes} Minuten`,
        PayedAmount: () => 'Betrag [CHF]',
        ResultingParkingTime: () => 'Parkzeit',
        TariffCalculator: () => 'Tarifrechner',
        Time: () => 'Uhrzeit [HH:MM]',
        TimeMustBeHHMM: () => 'Uhrzeit muss im Format HH:MM sein',
        UntilDate: (date: moment.Moment) =>
            `Bis am ${Formatter.dayMonthYear(date)} um ${Formatter.hourMinute(
                date,
            )}`,
    },
    fr: {
        AmountMustBePositiveNumber: () => 'Le montant doit être positif',
        Date: () => 'Date',
        DateIsMandatory: () => 'La date est un champ obligatoire',
        InheritedMinutes: () => 'Temps hérité [min]',
        InheritedTimeMustBePositiveNumber: () =>
            'Le temps hérité doit être positif et en minutes',
        MaximumParkingTimeReached: () =>
            'Durée maximale de stationnement atteinte',
        MissingInput: () => 'Données manquantes',
        NetInheritedTime: (inheritedMin: number) =>
            `Temps hérité net: ${inheritedMin} minutes`,
        ParkingTimeMinutes: (minutes: number) => `${minutes} minutes`,
        PayedAmount: () => 'Montant [CHF]',
        ResultingParkingTime: () => 'Durée de stationnement',
        TariffCalculator: () => 'Calculateur de tarif',
        Time: () => 'Heure [HH:MM]',
        TimeMustBeHHMM: () => "L'heure doit être au format HH:MM",
        UntilDate: (date: moment.Moment) =>
            `Jusqu'au ${Formatter.dayMonthYear(date)} à ${Formatter.hourMinute(
                date,
            )}`,
    },
    it: {
        AmountMustBePositiveNumber: () => "L'importo deve essere positivo",
        Date: () => 'Data',
        DateIsMandatory: () => 'Il campo della data è obbligatorio',
        InheritedMinutes: () => 'Durata residua [min]',
        InheritedTimeMustBePositiveNumber: () =>
            'La durata residua deve essere positiva ed espressa in minuti',
        MaximumParkingTimeReached: () => 'Durata massima raggiunta',
        MissingInput: () => 'Dati mancanti',
        NetInheritedTime: (inheritedMin: number) =>
            `Durata residua netta: ${inheritedMin} minuti`,
        ParkingTimeMinutes: (minutes: number) => `${minutes} minuti`,
        PayedAmount: () => 'Importo [CHF]',
        ResultingParkingTime: () => 'Durata della sosta',
        TariffCalculator: () => 'Calcolo tariffa',
        Time: () => 'Orario [HH:MM]',
        TimeMustBeHHMM: () => "L'orario deve essere espresso nel formato HH:MM",
        UntilDate: (date: moment.Moment) =>
            `Fino al giorno ${Formatter.dayMonthYear(
                date,
            )} alle ore ${Formatter.hourMinute(date)}`,
    },
};

/* tslint:enable */
