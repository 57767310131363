import * as Flux from 'dg-web-shared/lib/Flux.tsx';

export namespace CurrentOperatorLogin {
    export interface State {
        showCurrentOperatorLoginDropdown?: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'layout-Navbar-CurrentOperatorLogin',
        {
            showCurrentOperatorLoginDropdown: false,
        },
    );
}

export namespace NavigationDropdown {
    export interface State {
        showNavigationDropdown: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'layout-Navbar-NavigationDropdown',
        {
            showNavigationDropdown: false,
        },
    );
}
