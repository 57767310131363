import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';

export namespace CustomPermissionCheckers {
    export type CustomPermissionChecker = (
        o: OperatorDataState.OperatorData,
        l: CurrentOperatorLoginState.CurrentOperatorLogin,
    ) => boolean;

    export const customerWhitelistAllowed: CustomPermissionChecker = (
        o: OperatorDataState.OperatorData,
    ): boolean => {
        return (
            o.settings.customerWhitelistOverview &&
            (o.hasAWhitelistCustomerPermittype || o.hasAPermittypeCustomerGrant)
        );
    };

    export const syncAppInteractionAllowed: CustomPermissionChecker = (
        _o,
        l,
    ) => {
        return l.permissions.syncApp;
    };

    export const parkingMeterEditAllowed: CustomPermissionChecker = (_o, l) => {
        return l.permissions.parkingMeterEdit;
    };

    export const usbStickEditAllowed: CustomPermissionChecker = (_o, l) => {
        return l.permissions.usbStickEdit;
    };
}
