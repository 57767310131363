import * as Text from '../../../common/i18n/Text.ts';
import * as PermitTypeState from '../../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import {
    clearPermitTypes,
    setPermitTypes,
} from '../../actions/FilteredPermitsActions.ts';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import { Localized } from '../../../common/components/Localized.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import {
    filterInactiveUnusedPermits,
    flagInactivePermits,
    PermitTypeSelectionSlideIn,
} from '../../../ui/slidein/PermitSelectionSlideIn.tsx';

export interface PermitTypeFilterTexts {
    filterTitle: Text.Translation;
    inactive: Text.Translation;
}

export function permitTypeSelection(
    permitTypes: PermitTypeState.PermitType[],
    selectedPt: number[] | null,
) {
    return selectedPt && selectedPt.length > 0
        ? `${selectedPt.length}/${
              permitTypes.filter(pt => {
                  return pt.operatorState === 'ACTIVE';
              }).length
          }`
        : '';
}

export function PermitTypeFilterHalfSlideIn() {
    const { storeState, update } = useStore(store => ({
        filter: FilteredPermitsState.Filter.get(store),
        permitTypes: PermitTypeState.get(store),
    }));

    return (
        <PermitTypeSelectionSlideIn
            open={storeState.filter.permitTypeSelectionVisible}
            heading={<Localized de="Typ" fr="Type" it="Tipo" en="Type" />}
            selection={storeState.filter.permitTypes || []}
            selectablePermits={filterInactiveUnusedPermits(
                storeState.permitTypes.data,
            ).map(permit => {
                return {
                    id: permit.id,
                    displayText: flagInactivePermits(permit),
                    group: permit.permitTypeScope,
                };
            })}
            onLeftActionClick={() =>
                update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        permitTypeSelectionVisible: false,
                    }),
                )
            }
            setNewSelection={v => update(store => setPermitTypes(store, v))}
            filterGroups={[
                {
                    id: PermitTypeState.PermitTypeScope.ONSTREET,
                    displayName: (
                        <Localized
                            de="ONSTREET"
                            fr="ONSTREET"
                            it="ONSTREET"
                            en="ONSTREET"
                        />
                    ),
                },
                {
                    id: PermitTypeState.PermitTypeScope.OFFSTREET,
                    displayName: (
                        <Localized
                            de="OFFSTREET"
                            fr="OFFSTREET"
                            it="OFFSTREET"
                            en="OFFSTREET"
                        />
                    ),
                },
                {
                    id: PermitTypeState.PermitTypeScope.MIXED,
                    displayName: (
                        <Localized
                            de="ON-/OFFSTREET"
                            fr="ON-/OFFSTREET"
                            it="ON-/OFFSTREET"
                            en="ON-/OFFSTREET"
                        />
                    ),
                },
            ]}
        />
    );
}

export function PermitTypeFilterSelection() {
    const { storeState, update } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: FilteredPermitsState.Filter.get(store),
        permitTypes: PermitTypeState.get(store),
    }));

    const clear = storeState.filter.permitTypes
        ? () => update(clearPermitTypes)
        : null;

    return (
        <SingleSelection
            label={<Localized de="Typ" fr="Type" it="Tipo" en="Type" />}
            focused={false}
            selection={permitTypeSelection(
                storeState.permitTypes.data,
                storeState.filter.permitTypes,
            )}
            onClick={() =>
                update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        permitTypeSelectionVisible: true,
                    }),
                )
            }
            onClear={clear}
        />
    );
}
