import { css } from '@emotion/css';

import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon.tsx';

interface IconProps {
    icon: JSX.Element;
}

export const Icon16 = (props: IconProps): JSX.Element => (
    <div
        className={css({
            position: 'relative',
            display: 'inline-block',
            width: 16,
            height: 16,
        })}
    >
        <SvgIcon {...props} width={16} height={16} />
    </div>
);

export const Icon30 = (props: IconProps): JSX.Element => (
    <div
        className={css({
            position: 'relative',
            display: 'inline-block',
            width: 30,
            height: 30,
        })}
    >
        <SvgIcon {...props} width={24} height={24} />
    </div>
);

export const Icon24 = (props: IconProps): JSX.Element => (
    <div
        className={css({
            position: 'relative',
            display: 'inline-block',
            width: 24,
            height: 24,
        })}
    >
        <SvgIcon {...props} width={24} height={24} />
    </div>
);
