import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as NavbarState from '../../layout/state/NavbarState.ts';
import { selectState } from 'dg-web-shared/lib/Flux.tsx';
import { css } from '@emotion/css';
import { Shadows } from '../Shadows.ts';

interface ContainerState {
    settings: SettingsState.State;
    currentOperatorLogin: NavbarState.CurrentOperatorLogin.State;
}
interface ContainerProps {
    children?: React.ReactNode;
}

export const Container = selectState<ContainerProps, ContainerState>(
    (store: Flux.Store): ContainerState => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            currentOperatorLogin: NavbarState.CurrentOperatorLogin.get(store),
        };
    },
    p => {
        return (
            <div
                className={css({
                    position: 'absolute',
                    top: 72,
                    right: 0,
                    bottom: 0,
                    left: 0,
                })}
            >
                <div
                    className={css({
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                        maxWidth: 1376,
                        margin: '0 auto',
                    })}
                >
                    <div
                        className={css({
                            overflow: 'hidden',
                            position: 'absolute',
                            top: -Shadows.TopShadowSpace,
                            bottom: 0,
                            left: 48 - Shadows.LeftShadowSpace,
                            right: 48 - Shadows.RightShadowSpace,
                        })}
                    >
                        {p.children}
                    </div>
                </div>
            </div>
        );
    },
);
