import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { OperatorAccount } from '../../api/Http.ts';
import { Localized } from '../../common/components/Localized.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { GenericSingleSelectionSlideIn } from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import * as PermitCreateState from '../state/PermitCreateState.ts';

export function CounterPaymentChannelEdit() {
    const { storeState, update } = useStore(s => ({
        layout: PermitCreateState.Layout.get(s),
        config: PermitCreateState.Config.get(s),
    }));

    return (
        <SingleSelection
            label={<Title />}
            selection={
                <SelectionDisplayText
                    channel={storeState.config.counterPaymentChannel}
                />
            }
            focused={storeState.layout.counterPaymentChannelSelectionOpen}
            onClick={() => {
                update(store =>
                    PermitCreateState.Layout.stateWrite(store, {
                        counterPaymentChannelSelectionOpen: true,
                    }),
                );
            }}
            errorText={
                storeState.layout.confirmPressedOnError &&
                !storeState.config.counterPaymentChannel ? (
                    <Localized
                        de="Pflichtfeld"
                        fr="Champ obligatoire"
                        it="Campo obbligatorio"
                        en="Mandatory field"
                    />
                ) : null
            }
        />
    );
}

export function CounterPaymentChannelSelectionSlideIn() {
    const { storeState, update } = useStore(s => ({
        layout: PermitCreateState.Layout.get(s),
        config: PermitCreateState.Config.get(s),
    }));

    function closeSlideIn() {
        update(store =>
            PermitCreateState.Layout.stateWrite(store, {
                counterPaymentChannelSelectionOpen: false,
            }),
        );
    }

    return (
        <CounterPaymentChannelSingleSelectionSlideIn
            open={storeState.layout.counterPaymentChannelSelectionOpen}
            heading={<Title />}
            selection={storeState.config.counterPaymentChannel}
            options={options()}
            onSelect={counterPaymentChannel => {
                update(store =>
                    PermitCreateState.Config.stateWrite(store, {
                        counterPaymentChannel,
                    }),
                );
                update(PermitCreateState.Calc.reset);

                closeSlideIn();
            }}
            onLeftActionClick={closeSlideIn}
            outsideBody={true}
        />
    );
}

class CounterPaymentChannelSingleSelectionSlideIn extends GenericSingleSelectionSlideIn<OperatorAccount.Permits.CounterPaymentChannel> {}

export function CounterPaymentChannel(props: {
    counterPaymentChannel: OperatorAccount.Permits.CounterPaymentChannel;
}) {
    return (
        <LabeledText label={<Title />}>
            <SelectionDisplayText channel={props.counterPaymentChannel} />
        </LabeledText>
    );
}

function Title() {
    return (
        <Localized
            de="Zahlungsmittel"
            fr="Moyen de payement"
            it="Mezzo di pagamento"
            en="Mean of payment"
        />
    );
}

function SelectionDisplayText(props: {
    channel: OperatorAccount.Permits.CounterPaymentChannel | null;
}): JSX.Element {
    return (
        options().find(o => o.id === String(props.channel))?.displayText ?? (
            <span>—</span>
        )
    );
}

function options() {
    return [
        {
            id: OperatorAccount.Permits.CounterPaymentChannel.POSTCARD,
            displayText: (
                <Localized
                    de="Postfinance Card"
                    fr="Postfinance Card"
                    it="Postfinance Card"
                    en="Postfinance Card"
                />
            ),
        },
        {
            id: OperatorAccount.Permits.CounterPaymentChannel.SIX,
            displayText: <Localized de="SIX" fr="SIX" it="SIX" en="SIX" />,
        },
        {
            id: OperatorAccount.Permits.CounterPaymentChannel.CASH,
            displayText: (
                <Localized de="Bargeld" fr="Espèces" it="Contanti" en="Cash" />
            ),
        },
        {
            id: OperatorAccount.Permits.CounterPaymentChannel.NO_CASHFLOW,
            displayText: (
                <Localized
                    de="Kein Geldfluss"
                    fr="Kein Geldfluss"
                    it="Kein Geldfluss"
                    en="Kein Geldfluss"
                />
            ),
        },
    ];
}
