import * as Types from './Types';
import { LocalStorageBase } from './LocalStorageBase';
import { Maybe } from './MaybeV2';

export enum Items {
    licensePlateContextLicensePlateId,
    parkCreateLicensePlate,
    parkCreateBadge,
    parkOptionStateV2,
    parkCreateOptionItem,
    parkCreateParkVariant,
    parkCreateTransactionLicensePlateSelection,
    parkCreateZonePermitOptionItem,
    parkCreateGatePermitOptionItem,
    zoneTransactionZone,
    uiLanguage,
    nativeWrapperProps,
    clearancePermitListSearchEntity,
    enforcementAppToken,
    enforcementSelectionState,
    startedCeckinByPlateOnThisDevice,
    enforcementNativeVersion,
    deviceUuid,
}

export const HAS_EVER_LOGGED_IN_STATE_KEY = 'unauth-landing-has-ever-logged-in';

export function clear(): void {
    if (LocalStorageBase.hasLocalStorage()) {
        /*
        never clear the deviceUUID
        */
        const token = getStringItem(Items.deviceUuid);
        const lang = getStringItem(Items.uiLanguage);
        const nativeWrapper = getStringItem(Items.nativeWrapperProps);
        const hasEverLoggedIn = LocalStorageBase.getStringItem(
            HAS_EVER_LOGGED_IN_STATE_KEY,
        );
        try {
            localStorage.clear();
        } catch (e) {
            // noop
        }
        if (token) {
            setStringItem(Items.deviceUuid, token);
        }
        if (lang) {
            setStringItem(Items.uiLanguage, lang);
        }
        if (nativeWrapper) {
            setStringItem(Items.nativeWrapperProps, nativeWrapper);
        }

        if (hasEverLoggedIn) {
            LocalStorageBase.setStringItem(
                HAS_EVER_LOGGED_IN_STATE_KEY,
                hasEverLoggedIn,
            );
        }
    }
}

export function setStringItem(item: Items, value: string): void {
    LocalStorageBase.setStringItem(Items[item], value);
}

export function getStringItem(item: Items): string | null {
    return LocalStorageBase.getStringItem(Items[item]);
}

export const setSerializableItem = <T>(item: Items, value: T): void => {
    LocalStorageBase.setSerializableItem(Items[item], value);
};

export const getSerializableItem = <T>(item: Items): T | null => {
    return LocalStorageBase.getSerializableItem(Items[item]);
};

export function removeItem(item: Items): void {
    LocalStorageBase.removeItem(Items[item]);
}

function getItemAsStringId(item: Items): Types.StringId {
    const value = getStringItem(item);
    if (value) {
        return new Types.StringId(value);
    } else {
        return new Types.StringId(undefined);
    }
}

export namespace Settings {
    export function setUiLanguage(language: string): void {
        setStringItem(Items.uiLanguage, language);
    }

    export function getUiLanguageAsStringId(): Types.StringId {
        return getItemAsStringId(Items.uiLanguage);
    }

    export function getUiLanguage(): Maybe<string> {
        return getStringItem(Items.uiLanguage);
    }
}
