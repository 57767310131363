import * as ClearanceCreateState from '../../clearance-detail/state/ClearanceCreateState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { Overlap } from '../state/SharedParseLogic.ts';
import {
    Notification,
    NotificationBodyList,
    NotificationColor,
    NotificationIcon,
} from '../../ui/notifications/Notification.tsx';
import { LicensePlate } from './AddLicensePlateSlideIn.tsx';
import { Badge } from './AddBadgeSlideIn.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import { texts } from '../i18n/SharedNotificationsTexts.ts';
import { IdentificationType } from '../../common/state/PermitTypeState.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';

export interface Texts {
    OverlapNotificationHeading: Translation;
    LpOverlapNotificationPrefix: Translation;
    BadgeOverlapNotificationPrefix: Translation;
    OverlapNotificationPostfix: Translation;
    OverlapEntityValidTo: Translation;

    MissingWhitelistCreateWhitelist: Translation;

    MissingLpWhitelistHeading: Translation;
    MissingLpWhitelistPrefix: Translation;
    MissingLpWhitelistPostfix: Translation;

    MissingBadgeWhitelistHeading: Translation;
    MissingBadgeWhitelistPrefix: Translation;
    MissingBadgeWhitelistPostfix: Translation;

    BadgesThatNeedAccountHeading: Translation;
    BadgesThatNeedAccountPrefix: Translation;
}

const errorTextStyle = css({
    color: ColorHex.error,
});

const OverlapEntity = (p: { entity: Overlap; lang: string }): JSX.Element => {
    const text = texts[p.lang];
    return (
        <li>
            <span className={errorTextStyle}>{p.entity.identifier + ' '}</span>
            <span>
                {`(${text.OverlapEntityValidTo()} ${Formatter.dayMonthYear(
                    p.entity.validTo,
                )}, #${p.entity.permitId})`}
            </span>
        </li>
    );
};

interface OverlappingEntitiesNotificationProps {
    overlaps: Overlap[];
    language: string;
    identificationType: IdentificationType;
}

export const OverlappingEntitiesNotification = (
    p: OverlappingEntitiesNotificationProps,
): JSX.Element => {
    const text = texts[p.language];
    return (
        <Notification
            title={text.OverlapNotificationHeading()}
            color={NotificationColor.error}
            icon={NotificationIcon.error}
        >
            <div>
                {p.identificationType === 'Badge'
                    ? text.BadgeOverlapNotificationPrefix()
                    : text.LpOverlapNotificationPrefix()}
            </div>
            <NotificationBodyList>
                {p.overlaps.map(c => (
                    <OverlapEntity
                        entity={c}
                        lang={p.language}
                        key={c.permitId}
                    />
                ))}
            </NotificationBodyList>
            <div>{text.OverlapNotificationPostfix()}</div>
        </Notification>
    );
};

const prefillLpWhitelistCreate = (
    store: Flux.Store,
    p: { lp: PermitCreateState.LicensePlate; permitType: number },
) => {
    ClearanceCreateState.Create.stateWrite(store, {
        licensePlateNumber: p.lp.licensePlateNr,
        country: p.lp.country,
        type: p.lp.type,
    });
    ClearanceCreateState.Create.setSelectedPermitTypes(store, [p.permitType]);
    ClearanceCreateState.Layout.stateWrite(store, { createEnabled: true });
    return 'PermitCreate-prefillLpWhitelistCreate';
};

const prefillBadgeWhitelistCreate = (
    store: Flux.Store,
    p: { badge: Badge; permitType: number },
) => {
    ClearanceCreateState.Create.stateWrite(store, {
        badgeLabelNr: p.badge.badgeLabelNr,
    });
    ClearanceCreateState.Create.setSelectedPermitTypes(store, [p.permitType]);
    ClearanceCreateState.Layout.stateWrite(store, { createEnabled: true });
    return 'PermitCreate-prefillBadgeWhitelistCreate';
};

interface MissingWhitelistProps {
    lp: PermitCreateState.LicensePlate;
    permitType?: number | null;
    update: Flux.Updater;
    lang: string;
    canCreateClearance: boolean;
}

const MissingWhitelist = (p: MissingWhitelistProps): JSX.Element => {
    const text = texts[p.lang];
    return (
        <li>
            <span className={errorTextStyle}>{p.lp.licensePlateNr + ' '}</span>
            {p.canCreateClearance && (
                <Clickable
                    element="span"
                    className={errorTextStyle}
                    onClick={() =>
                        p.update(store => {
                            const permitType = p.permitType;
                            if (permitType) {
                                return prefillLpWhitelistCreate(store, {
                                    lp: p.lp,
                                    permitType: permitType,
                                });
                            }
                            return 'permitType not defined';
                        })
                    }
                >
                    {text.MissingWhitelistCreateWhitelist()}
                </Clickable>
            )}
        </li>
    );
};

interface LpsThatNeedWhitelistNotificationProps {
    lpsThatNeedWhitelist: LicensePlate[];
    language: string;
    permitTypeId?: number | null;
    update: Flux.Updater;
}

export const LpsThatNeedWhitelistNotification = (
    p: LpsThatNeedWhitelistNotificationProps,
): JSX.Element => {
    const text = texts[p.language];
    const operatorContext = useOperatorContext();
    const canCreateClearance =
        operatorContext.currentLogin.permissions.whitelistCreate;
    return (
        <Notification
            title={text.MissingLpWhitelistHeading()}
            color={NotificationColor.error}
            icon={NotificationIcon.error}
        >
            <div>{text.MissingLpWhitelistPrefix()}</div>
            <div>
                {p.lpsThatNeedWhitelist.map(c => (
                    <MissingWhitelist
                        lp={c}
                        lang={p.language}
                        permitType={p.permitTypeId}
                        update={p.update}
                        key={c.id}
                        canCreateClearance={canCreateClearance}
                    />
                ))}
            </div>
            <div>{canCreateClearance && text.MissingLpWhitelistPostfix()}</div>
        </Notification>
    );
};

interface MissingBadgeWhitelistProps {
    badge: Badge;
    permitType?: number | null;
    update: Flux.Updater;
    lang: string;
}

const MissingBadgeWhitelist = (p: MissingBadgeWhitelistProps): JSX.Element => {
    const text = texts[p.lang];
    return (
        <li>
            <span className={errorTextStyle}>{p.badge.badgeLabelNr + ' '}</span>
            <Clickable
                element="span"
                className={errorTextStyle}
                onClick={() =>
                    p.update(store => {
                        const permitType = p.permitType;
                        if (permitType) {
                            prefillBadgeWhitelistCreate(store, {
                                badge: p.badge,
                                permitType: permitType,
                            });
                        }
                        return 'permitType not defined';
                    })
                }
            >
                {text.MissingWhitelistCreateWhitelist()}
            </Clickable>
        </li>
    );
};

interface BadgesThatNeedWhitelistNotificationProps {
    badgesThatNeedWhitelist: Badge[];
    language: string;
    permitTypeId?: number | null;
    update: Flux.Updater;
}

export const BadgesThatNeedWhitelistNotification = (
    p: BadgesThatNeedWhitelistNotificationProps,
): JSX.Element => {
    const text = texts[p.language];
    return (
        <Notification
            title={text.MissingBadgeWhitelistHeading()}
            color={NotificationColor.error}
            icon={NotificationIcon.error}
        >
            <div>{text.MissingBadgeWhitelistPrefix()}</div>
            <div>
                {p.badgesThatNeedWhitelist.map(c => (
                    <MissingBadgeWhitelist
                        badge={c}
                        lang={p.language}
                        permitType={p.permitTypeId}
                        update={p.update}
                        key={c.id}
                    />
                ))}
            </div>
            <div>{text.MissingBadgeWhitelistPostfix()}</div>
        </Notification>
    );
};

interface BadgesThatNeedAccount {
    badgesThatNeedAccount: string[];
    language: string;
}

export const BadgesThatNeedAccountNotification = (
    p: BadgesThatNeedAccount,
): JSX.Element => {
    const text = texts[p.language];
    return (
        <Notification
            title={text.BadgesThatNeedAccountHeading()}
            color={NotificationColor.error}
            icon={NotificationIcon.error}
        >
            <div>{text.BadgesThatNeedAccountPrefix()}</div>
            <NotificationBodyList>
                {p.badgesThatNeedAccount.map(b => (
                    <li key={b}>{b}</li>
                ))}
            </NotificationBodyList>
        </Notification>
    );
};
