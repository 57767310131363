import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../../../ui/filter/FilterSummary.tsx';
import * as FilteredClearancesState from '../../state/FilteredClearancesState.ts';
import * as Text from '../../../common/i18n/Text.ts';
import * as FilteredClearancesActions from '../../actions/FilteredClearancesActions.ts';
import * as PermitTypeState from '../../../common/state/PermitTypeState.ts';
import { permitTypeSelection } from './PermitTypeFilter.tsx';
import * as OperatorLoginsState from '../../../common/state/OperatorLoginsState.ts';
import { operatorLoginSelection } from './OperatorLoginFilter.tsx';
import { FilterButtonWithSummary } from '../../../ui/filter/FilterButtonWithSummary.tsx';
import { Localized } from '../../../common/components/Localized.tsx';

export interface FilterSummaryTexts {
    zone: Text.Translation;
}

interface FilteredClearancesFilterSummaryState {
    settings: SettingsState.State;
    filter: FilteredClearancesState.Filter.State;
    filterSummary: FilteredClearancesState.FilterSummary.State;
}

interface FilterSummaryObject {
    filterSummaryElement: React.ReactNode;
    key: string;
}

const cn = ElementNamer('FilteredClearancesFilterSummary');

export class FilteredClearancesFilterSummary extends Flux.Container<FilteredClearancesFilterSummaryState> {
    static displayName: string = cn('');

    stateSelector(): FilteredClearancesFilterSummaryState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredClearancesState.Filter.get(this.props.allState),
            filterSummary: FilteredClearancesState.FilterSummary.get(
                this.props.allState,
            ),
        };
    }

    render() {
        const activeFilters: FilterSummaryObject[] = [];

        if (
            FilteredClearancesState.Filter.permitTypeFilterActive(
                this.state.filter,
            )
        ) {
            activeFilters.push({
                filterSummaryElement: <PermitTypeFilterSummary />,
                key: 'permitType',
            });
        }

        if (
            FilteredClearancesState.Filter.operatorLoginFilterActive(
                this.state.filter,
            )
        ) {
            activeFilters.push({
                filterSummaryElement: <OperatorLoginFilterSummary />,
                key: 'operatorLogin',
            });
        }
        if (
            FilteredClearancesState.Filter.issueDateFilterActive(
                this.state.filter,
            )
        ) {
            activeFilters.push({
                filterSummaryElement: <IssueDateFilterSummary />,
                key: 'issueDate',
            });
        }
        if (
            FilteredClearancesState.Filter.permitsFilterActive(
                this.state.filter,
            )
        ) {
            activeFilters.push({
                filterSummaryElement: <PermitFilterSummary />,
                key: 'permitFilter',
            });
        }
        if (
            FilteredClearancesState.Filter.validDateFilterActive(
                this.state.filter,
            )
        ) {
            activeFilters.push({
                filterSummaryElement: <ValidDateFilterSummary />,
                key: 'validDate',
            });
        }

        return (
            <>
                <FilterButtonWithSummary
                    onFilterClick={() =>
                        this.update(store =>
                            FilteredClearancesState.Filter.stateWrite(store, {
                                filterSlideInVisible: true,
                            }),
                        )
                    }
                    clearFilter={() =>
                        this.update(FilteredClearancesActions.clearFilter)
                    }
                >
                    {FilteredClearancesState.Filter.filterActive(
                        this.state.filter,
                    ) &&
                        activeFilters.map((filter, i) => (
                            <FilterSummaryItem
                                hasSeparator={i > 0}
                                key={filter.key}
                            >
                                {filter.filterSummaryElement}
                            </FilterSummaryItem>
                        ))}
                </FilterButtonWithSummary>
            </>
        );
    }
}

function PermitTypeFilterSummary() {
    const { storeState } = useStore(s => ({
        filter: FilteredClearancesState.Filter.get(s),
        permitTypes: PermitTypeState.get(s),
    }));

    return (
        <>
            <FilterSummaryItemTitle
                title={<Localized de="Typ" fr="Type" it="Tipo" en="Type" />}
            />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    permitTypeSelection(
                        storeState.permitTypes.data,
                        storeState.filter.permitTypes,
                        true,
                    )
                }
            />
        </>
    );
}

function OperatorLoginFilterSummary() {
    const { storeState } = useStore(s => ({
        filter: FilteredClearancesState.Filter.get(s),
        operatorLogins: OperatorLoginsState.List.get(s),
    }));

    return (
        <>
            <FilterSummaryItemTitle
                title={
                    <Localized
                        de="Erfasst von"
                        fr="Saisie de"
                        it="Registrata da"
                        en="Captured by"
                    />
                }
            />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    operatorLoginSelection(
                        storeState.operatorLogins.data,
                        storeState.filter.operatorLogins,
                    )
                }
            />
        </>
    );
}

function IssueDateFilterSummary() {
    const { storeState } = useStore(s => ({
        filter: FilteredClearancesState.Filter.get(s),
    }));

    return (
        <>
            <FilterSummaryItemTitle
                title={
                    <Localized
                        de="Freigabedatum"
                        fr="Date approbation"
                        it="Data approvazione"
                        en="Issue Date"
                    />
                }
            />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    Formatter.openRange(
                        storeState.filter.issueDateFrom,
                        storeState.filter.issueDateTo,
                        Formatter.dayMonthYear,
                    )
                }
            />
        </>
    );
}

function PermitFilterSummary() {
    const { storeState } = useStore(s => ({
        filter: FilteredClearancesState.Filter.get(s),
    }));

    return (
        <FilterSummaryItemTitle
            title={
                storeState.filter.permits === 'WITH_PERMITS' ? (
                    <Localized
                        de="Freigaben mit Bewilligungen"
                        fr="Approbations avec autorisations"
                        it="Approvazioni con autorizzazioni"
                        en="Approvals with permits"
                    />
                ) : (
                    <Localized
                        de="Freigaben ohne Bewilligungen"
                        fr="Approbations sans autorisations"
                        it="Approvazioni senza autorizzazioni"
                        en="Approvals without permits"
                    />
                )
            }
        />
    );
}

function ValidDateFilterSummary() {
    const { storeState } = useStore(s => ({
        filter: FilteredClearancesState.Filter.get(s),
    }));

    return (
        <>
            <FilterSummaryItemTitle
                title={
                    <Localized
                        de="Gültigkeit"
                        fr="Validité"
                        it="Validità"
                        en="Validity"
                    />
                }
            />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    Formatter.openRange(
                        storeState.filter.validDateFrom,
                        storeState.filter.validDateTo,
                        Formatter.dayMonthYear,
                    )
                }
            />
        </>
    );
}
