import moment from 'moment';

import { AdditionalInfoMode } from '../../common/state/PermitTypeState.ts';
import { DatePickerSlideIn } from '../../ui/slidein/DatePickerSlideIn.tsx';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Formatter, isUnlimitedToDate } from 'dg-web-shared/lib/Date.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import { FieldSetting } from '../../common/state/OperatorDataState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { texts } from '../i18n/SharedTexts.ts';

export interface Texts {
    validFrom: Translation;
    validTo: Translation;
    personalNumber: Translation;
    contractNumber: Translation;
    infos: Translation;
}

interface InfoFieldProps {
    allState: Flux.Store;
    value: string;
    onChange: (v: string) => void;
    headerPrefix?: string;
}

interface State {
    settings: SettingsState.State;
    operator: OperatorDataState.State;
}

export class BaseContainer<
    P extends Flux.ContainerProps,
> extends Flux.ContainerWithProps<P, State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            operator: OperatorDataState.get(this.props.allState),
        };
    }
}

export class PersonalNumber extends BaseContainer<InfoFieldProps> {
    render() {
        const operator = this.state.operator.data;
        if (!operator) {
            return null;
        } else if (
            operator.licensePlatePermitSettings.whitelistPersonalNumber ===
            FieldSetting.hidden
        ) {
            return null;
        } else {
            return (
                <TextField
                    value={this.props.value}
                    label={texts[this.state.settings.language].personalNumber()}
                    onChange={this.props.onChange}
                    inputType="text"
                    disabled={false}
                />
            );
        }
    }
}

export class ContractNumber extends BaseContainer<InfoFieldProps> {
    render() {
        const operator = this.state.operator.data;
        if (!operator) {
            return null;
        } else if (
            operator.licensePlatePermitSettings.whitelistContractNumber ===
            FieldSetting.hidden
        ) {
            return null;
        } else {
            return (
                <TextField
                    value={this.props.value}
                    label={texts[this.state.settings.language].contractNumber()}
                    onChange={this.props.onChange}
                    inputType="text"
                    disabled={false}
                />
            );
        }
    }
}

export class AdditionalInfos extends BaseContainer<
    InfoFieldProps & { mode: AdditionalInfoMode; disabled: boolean }
> {
    render() {
        const label =
            (this.props.headerPrefix || '') +
            ' ' +
            texts[this.state.settings.language].infos();
        if (this.props.mode === 'show') {
            return <LabeledText label={label}>{this.props.value}</LabeledText>;
        } else {
            return (
                <TextField
                    value={this.props.value}
                    multiline={true}
                    label={label}
                    onChange={this.props.onChange}
                    inputType="text"
                    disabled={this.props.disabled}
                />
            );
        }
    }
}

interface FromDateSelectionProps {
    focused: boolean;
    onClick: () => void;
    validFrom: moment.Moment;
    language: string;
}

export const FromDateSelection = (p: FromDateSelectionProps): JSX.Element => {
    const label = texts[p.language].validFrom();
    const content = Formatter.dayMonthYear(p.validFrom);
    if (p.validFrom.isBefore(moment().startOf('day'))) {
        return <LabeledText label={label}>{content}</LabeledText>;
    }
    return (
        <SingleSelection
            focused={p.focused}
            label={label}
            onClick={p.onClick}
            selection={content}
        />
    );
};

interface ToDateSelectionProps {
    focused: boolean;
    onClick: () => void;
    validTo: Maybe<moment.Moment>;
    onClear: Maybe<() => void>;
    language: string;
}

export const ToDateSelection = (p: ToDateSelectionProps): JSX.Element => {
    return (
        <SingleSelection
            focused={p.focused}
            label={texts[p.language].validTo()}
            onClick={p.onClick}
            onClear={p.onClear}
            selection={
                !p.validTo || isUnlimitedToDate(p.validTo)
                    ? '∞'
                    : Formatter.dayMonthYear(p.validTo)
            }
        />
    );
};

interface FromDatePickerSlideInProps {
    open: boolean;
    language: string;
    validFrom: moment.Moment | null;
    validTo: Maybe<moment.Moment>;
    onClose: () => void;
    onSelect: (d: moment.Moment) => void;
}

export const FromDatePickerSlideIn = (
    p: FromDatePickerSlideInProps,
): JSX.Element => {
    return (
        <DatePickerSlideIn
            open={p.open}
            caption={texts[p.language].validFrom()}
            onClose={p.onClose}
            selectedDate={p.validFrom}
            onSelect={p.onSelect}
            language={p.language}
            minDate={moment().startOf('day')}
            maxDate={p.validTo}
            outsideBody
        />
    );
};

interface ToDatePickerSlideInProps {
    open: boolean;
    language: string;
    validTo: Maybe<moment.Moment>;
    validFrom: moment.Moment;
    onClose: () => void;
    onSelect: (d: moment.Moment) => void;
}

export const ToDatePickerSlideIn = (
    p: ToDatePickerSlideInProps,
): JSX.Element => {
    return (
        <DatePickerSlideIn
            open={p.open}
            caption={texts[p.language].validTo()}
            onClose={p.onClose}
            selectedDate={p.validTo}
            onSelect={p.onSelect}
            language={p.language}
            minDate={moment.max(p.validFrom, moment().startOf('day'))}
            maxDate={moment('2037-12-31')}
            outsideBody
        />
    );
};
