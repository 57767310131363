import React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    value: string;
    inputType: string;
    onFocus: Function;
    onBlur: Function;
    onChange: Function;
    maxChars: number;
    autoCapitalize: string | undefined;
    autoCorrect: string | undefined;
    onKeyDown?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    ref?: React.Ref<Input>;
    disabled?: boolean;
    tabIndex?: number;
    className: string;
}

export class Input extends React.Component<Props> {
    _onChange(e: any): void {
        const val = e.target.value;
        if (val.length <= this.props.maxChars) {
            this.props.onChange(val);
        }
    }
    _onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }
    focus(): void {
        (ReactDOM.findDOMNode(this) as any).focus();
    }
    blur(): void {
        (ReactDOM.findDOMNode(this) as any).blur();
    }
    render() {
        return (
            <input
                className={this.props.className}
                autoCorrect={this.props.autoCorrect || 'off'}
                autoCapitalize={this.props.autoCapitalize}
                value={this.props.value}
                type={this.props.inputType}
                onFocus={(): void => this.props.onFocus()}
                onBlur={(): void => this.props.onBlur()}
                onChange={(e: React.SyntheticEvent<HTMLInputElement>): void =>
                    this._onChange(e)
                }
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void =>
                    this._onKeyDown(e)
                }
                disabled={this.props.disabled}
                tabIndex={
                    this.props.tabIndex !== undefined ? this.props.tabIndex : -1
                }
            />
        );
    }
}
