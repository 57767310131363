import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Container } from '../ui/layout/Container.tsx';
import { Body, Tabs } from '../ui/layout/TabContent.tsx';
import { Search } from '../ui/search/Search.tsx';
import { Detail, Filter, UI } from './ClearanceRequestsState.ts';
import {
    FilterLayout,
    FilterLayoutBody,
    FilterLayoutHeader,
} from '../ui/filter/FilterLayout.tsx';
import { FilterSummary } from './FilterSummary.tsx';
import { FilterSlideIn } from './FilterSlideIn.tsx';
import { DateFilterSlideIn } from './DateFilter.tsx';
import { ClearanceRequestsList } from './ClearanceRequestsList.tsx';
import { PermitTypeFilterSlideIn } from './PermitTypeFilter.tsx';
import { StatusFilterSlideIn } from './StatusFilter.tsx';
import { DetailSlideIn } from './Detail.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';

export const ClearanceRequests = Flux.selectState(
    store => ({
        filter: Filter.get(store),
        ui: UI.get(store),
        detail: Detail.get(store),
        settings: new SettingsState.StateSlice(store).state,
    }),
    p => (
        <Container>
            <Tabs>
                <Search
                    searchValue={p.filter.search || ''}
                    onSearchFieldChange={v =>
                        p.update(store =>
                            Filter.stateWrite(store, {
                                search: v === '' ? null : v,
                            }),
                        )
                    }
                    onSelect={() => null}
                    selected={true}
                    language={p.settings.language}
                    mousetrap={!!p.detail.selectedClearanceRequest}
                />
            </Tabs>
            <Body
                disabled={
                    p.ui.filterSlideInVisible ||
                    !!p.detail.selectedClearanceRequest
                }
                slideIns={[
                    <FilterSlideIn key="filter" />,
                    <DateFilterSlideIn key="dateFilter" />,
                    <PermitTypeFilterSlideIn key="permitType" />,
                    <StatusFilterSlideIn key="status" />,
                    <DetailSlideIn
                        key="detail"
                        hideMultipliers={
                            p.detail.selectedClearanceRequest?.status !==
                            'pending'
                        }
                    />,
                ]}
            >
                <FilterLayout>
                    <FilterLayoutHeader filterSummary={<FilterSummary />} />
                    <FilterLayoutBody>
                        <ClearanceRequestsList />
                    </FilterLayoutBody>
                </FilterLayout>
            </Body>
        </Container>
    ),
);
