import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInHeaderTexts,
    SlideInBody,
} from './Slidein.tsx';
import { DateHeader } from '../../clearance-permit-list/components/shared/DateHeader.tsx';
import { DatePicker } from '../date-picker/DatePicker.tsx';
import moment from 'moment';
import { Translation, Translations } from '../../common/i18n/Text.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { SingleSelection } from '../labeled-elements/SingleSelection.tsx';

export interface DateRangePickerProps {
    language: string;
    open: boolean;
    title: React.ReactNode;
    validFrom: Maybe<moment.Moment>;
    validTo: Maybe<moment.Moment>;
    onClose: () => void;
    onSelectFrom: (d: moment.Moment) => void;
    onSelectTo: (d: moment.Moment) => void;
}

interface Texts {
    from: Translation;
    to: Translation;
}

const texts: Translations<Texts> = {
    de: {
        from: () => 'Von',
        to: () => 'Bis',
    },
    fr: {
        from: () => 'Du',
        to: () => 'Au',
    },
    it: {
        from: () => 'Dal',
        to: () => 'Al',
    },
};

const ValidFrom = (p: DateRangePickerProps): JSX.Element | null => {
    if (!p.open) {
        return null;
    } else {
        return (
            <div>
                <DateHeader
                    label={texts[p.language].from()}
                    date={p.validFrom}
                />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={p.validFrom}
                    onSelect={p.onSelectFrom}
                    language={p.language}
                />
            </div>
        );
    }
};

const ValidTo = (p: DateRangePickerProps): JSX.Element | null => {
    if (!p.open) {
        return null;
    } else {
        return (
            <div style={{ marginTop: 32 }}>
                <DateHeader label={texts[p.language].to()} date={p.validTo} />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={p.validTo}
                    onSelect={p.onSelectTo}
                    language={p.language}
                />
            </div>
        );
    }
};

interface DateRangeSingleSelectionProps {
    title: React.ReactNode;
    validFrom: Maybe<moment.Moment>;
    validTo: Maybe<moment.Moment>;
    onClick: () => void;
    onClear: Maybe<() => void>;
}

export const DateRangeSingleSelection = (p: DateRangeSingleSelectionProps) => {
    const selection = Formatter.openRange(
        p.validFrom,
        p.validTo,
        Formatter.dayMonthYear,
    );
    return (
        <div>
            <SingleSelection
                label={p.title}
                focused={false}
                selection={selection}
                onClick={p.onClick}
                onClear={p.validFrom || p.validTo ? p.onClear : null}
            />
        </div>
    );
};

export const DateRangePickerSlideIn = (
    p: DateRangePickerProps,
): JSX.Element => {
    return (
        <HalfSlideIn open={p.open}>
            <SecondLevelHeader onClose={p.onClose}>
                <SlideInHeaderTexts
                    subtitle={''}
                    title={p.title}
                    hasLeftIcon={false}
                />
            </SecondLevelHeader>
            <SlideInBody>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ValidFrom {...p} />
                    <ValidTo {...p} />
                </div>
            </SlideInBody>
        </HalfSlideIn>
    );
};
