import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import { PermitTypesBody } from './PermitTypesBody.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { getNavigationText } from '../../layout/components/BreadCrumb.tsx';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { OperatorQuotaOutlet } from '../../quotas/OperatorQuotasDetail.tsx';

export const PermitTypes = () => {
    const { storeState } = Flux.useStore(state => {
        return {
            currentLogin: CurrentOperatorLoginState.get(state),
            allState: state,
            resetPermittypeState: () => PermitTypeState.reset(state),
        };
    });
    const login = storeState.currentLogin.data;

    if (!login) {
        return null;
    }

    return (
        <Container>
            <Tabs>
                <TabItem
                    active={true}
                    description={getNavigationText(
                        OperatorAppRoutes.PermitTypes,
                    )}
                    onClick={null}
                />
            </Tabs>
            <PermitTypesBody allState={storeState.allState} />
            <OperatorQuotaOutlet
                context={{ refetch: storeState.resetPermittypeState }}
            />
        </Container>
    );
};
