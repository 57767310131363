import * as Text from '../../common/i18n/Text.ts';
import {
    DateRangePickerSlideIn,
    DateRangeSingleSelection,
} from '../../ui/slidein/DateRangePickerSlideIn.tsx';
import { OnstreetTransactionFilter } from '../OnstreetTransactionFilter.tsx';
import { Translations } from '../../common/i18n/Text.ts';

export function DateFilterHalfSlideIn({
    language,
    filter,
}: {
    language: string;
    filter: OnstreetTransactionFilter;
}) {
    const texts = dateFilterTexts[language];
    return (
        <DateRangePickerSlideIn
            language={language}
            open={filter.state.validDateSelectionVisible}
            title={texts.filterTitle()}
            validFrom={filter.state.validFrom}
            validTo={filter.state.validTo}
            onSelectFrom={filter.setValidFrom}
            onSelectTo={filter.setValidTo}
            onClose={() => filter.showDateFilterSlideIn(false)}
        />
    );
}

export function DateFilterSelection({
    language,
    filter,
}: {
    language: string;
    filter: OnstreetTransactionFilter;
}) {
    const texts = dateFilterTexts[language];
    return (
        <DateRangeSingleSelection
            title={texts.filterTitle()}
            validFrom={filter.state.validFrom}
            validTo={filter.state.validTo}
            onClick={() => filter.showDateFilterSlideIn(true)}
            onClear={
                filter.state.dateFilterActive ? filter.clearValidDate : null
            }
        />
    );
}

interface DateFilterTexts {
    filterTitle: Text.Translation;
    from: Text.Translation;
    to: Text.Translation;
}

const dateFilterTexts: Translations<DateFilterTexts> = {
    de: {
        filterTitle: () => 'Gültigkeit',
        from: () => 'Von',
        to: () => 'Bis',
    },
    fr: {
        filterTitle: () => 'Validité',
        from: () => 'Du',
        to: () => 'Au',
    },
    it: {
        filterTitle: () => 'Validità',
        from: () => 'Dal',
        to: () => 'Al',
    },
};
