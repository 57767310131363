import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    getOrElse,
    isDefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import {
    ConfirmationHeader,
    ConfirmSaveHeader,
    ErrorHeader,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    LoaderHeader,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as ClearanceEditTexts from '../i18n/ClearanceEditTexts.ts';
import {
    Formatter,
    isUnlimitedToDate,
    Parser,
    unlimitedToDate,
} from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ClearanceEditState from '../state/ClearanceEditState.ts';
import { Clearance, Edit } from '../state/ClearanceEditState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import * as OperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import { ClearanceViewHeader } from './ClearanceViewHeader.tsx';
import {
    AdditionalInfos,
    ContractNumber,
    FromDateSelection,
    PersonalNumber,
    ToDateSelection,
} from './Shared.tsx';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import {
    deselectClearance,
    editClearance,
    editStateToPayload,
} from '../actions/ClearanceDetailActions.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as FilteredClearancesState from '../../clearance-permit-list/state/FilteredClearancesState.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { IdentificationLicensePlate } from '../../common/components/IdentificationItems.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export interface Texts {
    editCaption: Translation;
    issued: Translation;
    licenseplate: Translation;
    badge: Translation;
    PriceMultiplier: Translation;
    deleteConfirmation: Translation;
    unableToDelete: Translation;
    deletePending: Translation;
    deleteError: Translation;
    permitType: Translation;
}

interface State {
    settings: SettingsState.State;
    edit: ClearanceEditState.Edit.State;
    delete: ClearanceEditState.Delete.State;
    deleteResponse: ClearanceEditState.DeleteResponse.State;
    server: ClearanceEditState.Server.State;
    clearancePermitList: ClearancePermitListState.State;
    editResponse: ClearanceEditState.EditResponse.State;
    operatorLogins: OperatorLoginsState.List.State;
    operatorData: OperatorDataState.State;
    layout: ClearanceEditState.Layout.State;
}

const deleteClearance = AsyncRequest.request(
    Http.OperatorAccount.Clearances.del,
    (store: Flux.Store, res: Response): string => {
        ClearanceEditState.DeleteResponse.setResponse(store, res);

        if (
            ClearanceEditState.DeleteResponse.get(store).statusCode.cls.success
        ) {
            ClearanceEditState.Delete.reset(store);
            ClearanceEditState.DeleteResponse.reset(store);
            FilteredClearancesState.List.reset(store);
            deselectClearance(store);
            ResultState.refetchSameContext(store, true);
        }

        return 'ClearanceEditdeleteClearance';
    },
);

const resetDelete = (store: Flux.Store) => {
    ClearanceEditState.Delete.reset(store);
    ClearanceEditState.DeleteResponse.reset(store);
    return 'ClearanceEditresetdelete';
};

interface HeaderProps {
    state: State;
    clearance: Clearance;
    update: Flux.Updater;
    text: Texts;
    allState: Flux.Store;
}

const isSaveButtonSpecialState = (
    editResponse: ClearanceEditState.EditResponse.State,
): Maybe<ButtonSpecialState> => {
    if (editResponse.pending) {
        return ButtonSpecialState.DISABLED;
    } else {
        return null;
    }
};

const Header = (p: HeaderProps): JSX.Element => {
    const hasEdits =
        isDefined(p.state.edit.validFrom) ||
        isDefined(p.state.edit.validTo) ||
        isDefined(p.state.edit.infos) ||
        isDefined(p.state.edit.personalNumber) ||
        isDefined(p.state.edit.contractNumber);

    const login = CurrentOperatorLoginState.get(p.allState);
    if (hasEdits) {
        return (
            <ConfirmSaveHeader
                language={p.state.settings.language}
                title={p.text.editCaption()}
                confirmButtonSpecialState={isSaveButtonSpecialState(
                    p.state.editResponse,
                )}
                onCancel={() => p.update(ClearanceEditState.Edit.reset)}
                onSave={() => {
                    p.update(editClearance, {
                        id:
                            p.state.clearancePermitList.selectedClearanceId ||
                            0,
                        changes: editStateToPayload(p.state.edit),
                    });
                }}
            />
        );
    } else if (
        p.state.delete.deletePressed &&
        p.clearance.hasPermits &&
        (!login.data || login.data.loginId !== 565)
    ) {
        return (
            <ErrorHeader
                language={p.state.settings.language}
                title={p.text.unableToDelete()}
                onCancel={() => p.update(ClearanceEditState.Delete.reset)}
            />
        );
    } else if (p.state.delete.deletePressed && p.state.deleteResponse.pending) {
        return <LoaderHeader title={p.text.deletePending()} />;
    } else if (
        p.state.delete.deletePressed &&
        p.state.deleteResponse.statusCode.cls.error
    ) {
        return (
            <ErrorHeader
                language={p.state.settings.language}
                title={p.text.deleteError()}
                onCancel={() => p.update(resetDelete)}
            />
        );
    } else if (p.state.delete.deletePressed) {
        return (
            <ConfirmationHeader
                language={p.state.settings.language}
                title={p.text.deleteConfirmation()}
                onCancel={() =>
                    p.update(store =>
                        ClearanceEditState.Delete.stateWrite(store, {
                            deletePressed: false,
                        }),
                    )
                }
                onConfirm={() => {
                    p.update(store =>
                        deleteClearance(
                            store,
                            p.state.clearancePermitList.selectedClearanceId ??
                                undefined,
                        ),
                    );
                }}
            />
        );
    } else {
        return <ClearanceViewHeader allState={p.allState} />;
    }
};

type CreatedByProps = {
    clearance: Clearance;
    logins: OperatorLoginsState.OperatorLogin[];
    texts: Texts;
};

const CreatedBy = (p: CreatedByProps): JSX.Element => {
    const user = OperatorLoginsState.getLoginById(
        p.logins,
        p.clearance.adminId,
    );
    const userString = thenElse(
        user,
        u => `${u.firstName.toUpperCase()} ${u.lastName.toUpperCase()}`,
        '',
    );
    return (
        <div
            className={css({
                marginTop: '48px',
                color: ColorHex.rgba(ColorHex.darkblue, 0.6),
                ...Typo.captionC1,
            })}
        >
            <span className={css({ ...Typo.captionC2 })}>
                {`${p.texts.issued()}: `}
            </span>
            <span>
                {`${userString}, ${Formatter.dayMonthYearHourMinute(
                    Parser.isoToMoment(p.clearance.editDate),
                )}`}
            </span>
        </div>
    );
};

export class ClearanceEdit extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            edit: ClearanceEditState.Edit.get(this.props.allState),
            delete: ClearanceEditState.Delete.get(this.props.allState),
            deleteResponse: ClearanceEditState.DeleteResponse.get(
                this.props.allState,
            ),
            server: ClearanceEditState.Server.get(this.props.allState),
            clearancePermitList: new ClearancePermitListState.StateSlice(
                this.props.allState,
            ).state,
            editResponse: ClearanceEditState.EditResponse.get(
                this.props.allState,
            ),
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
            operatorData: OperatorDataState.get(this.props.allState),
            layout: ClearanceEditState.Layout.get(this.props.allState),
        };
    }

    getTexts(): Texts {
        return ClearanceEditTexts.texts[this.state.settings.language];
    }

    renderIdentifier(clearance: Clearance): JSX.Element {
        if (clearance.identificationType === 'BADGE') {
            return (
                <LabeledText label={this.getTexts().badge()}>
                    {clearance.badgeNr}
                </LabeledText>
            );
        } else {
            return (
                <div>
                    <LabeledText label={this.getTexts().licenseplate()}>
                        <IdentificationLicensePlate
                            licensePlateNr={clearance.licensePlateNr}
                            type={clearance.licensePlateType}
                            country={clearance.licensePlateCountry}
                            inlineMode={false}
                        />
                    </LabeledText>
                </div>
            );
        }
    }

    bodyDisabled(): boolean {
        return (
            this.state.layout.fromDatePickerOpen ||
            this.state.layout.toDatePickerOpen ||
            false
        );
    }

    render() {
        const clearance = this.state.server.data;
        if (isDefined(clearance)) {
            const validTo = getOrElse(
                this.state.edit.validTo,
                Parser.isoToMoment(clearance.validTo),
            );
            return (
                <div>
                    <SlideInBody disabled={this.bodyDisabled()}>
                        <FullSlideInLeftColumn>
                            <LabeledText label={this.getTexts().permitType()}>
                                {clearance.permitTypeName}
                            </LabeledText>
                            <FromDateSelection
                                focused={false}
                                onClick={() =>
                                    this.update(store =>
                                        ClearanceEditState.Layout.stateWrite(
                                            store,
                                            { fromDatePickerOpen: true },
                                        ),
                                    )
                                }
                                validFrom={getOrElse(
                                    this.state.edit.validFrom,
                                    Parser.isoToMoment(clearance.validFrom),
                                )}
                                language={this.state.settings.language}
                            />
                            <ToDateSelection
                                focused={false}
                                onClick={() =>
                                    this.update(store =>
                                        ClearanceEditState.Layout.stateWrite(
                                            store,
                                            { toDatePickerOpen: true },
                                        ),
                                    )
                                }
                                onClear={
                                    isUnlimitedToDate(validTo)
                                        ? null
                                        : () =>
                                              this.update(store =>
                                                  ClearanceEditState.Edit.stateWrite(
                                                      store,
                                                      {
                                                          validTo:
                                                              unlimitedToDate,
                                                      },
                                                  ),
                                              )
                                }
                                validTo={validTo}
                                language={this.state.settings.language}
                            />
                            {this.renderIdentifier(clearance)}
                        </FullSlideInLeftColumn>
                        <FullSlideInRightColumn>
                            <PersonalNumber
                                value={getOrElse(
                                    this.state.edit.personalNumber,
                                    clearance.personalNumber || '',
                                )}
                                allState={this.props.allState}
                                onChange={(v: string) =>
                                    this.props.allState.update(store =>
                                        Edit.stateWrite(store, {
                                            personalNumber: v,
                                        }),
                                    )
                                }
                            />
                            <ContractNumber
                                value={getOrElse(
                                    this.state.edit.contractNumber,
                                    clearance.contractNumber || '',
                                )}
                                allState={this.props.allState}
                                onChange={(v: string) =>
                                    this.props.allState.update(store =>
                                        Edit.stateWrite(store, {
                                            contractNumber: v,
                                        }),
                                    )
                                }
                            />
                            {
                                <AdditionalInfos
                                    value={getOrElse(
                                        this.state.edit.infos,
                                        clearance.infos,
                                    )}
                                    mode={clearance.additionalInfoMode}
                                    allState={this.props.allState}
                                    onChange={(v: string) =>
                                        this.props.allState.update(store =>
                                            Edit.stateWrite(store, {
                                                infos: v,
                                            }),
                                        )
                                    }
                                    disabled={
                                        clearance.additionalInfoMode === 'show'
                                    }
                                />
                            }
                            <Conditional
                                c={isDefined(clearance.priceMultiplier)}
                            >
                                <LabeledText
                                    label={this.getTexts().PriceMultiplier()}
                                >
                                    {clearance.priceMultiplier}
                                </LabeledText>
                            </Conditional>
                            <CreatedBy
                                clearance={clearance}
                                logins={this.state.operatorLogins.data}
                                texts={this.getTexts()}
                            />
                        </FullSlideInRightColumn>
                    </SlideInBody>
                    <Header
                        clearance={clearance}
                        text={this.getTexts()}
                        state={this.state}
                        allState={this.props.allState}
                        update={this.props.allState.update}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
}
