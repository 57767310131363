import * as Flux from 'dg-web-shared/lib/Flux.tsx';

export namespace Layout {
    export interface State {
        billingPropsOpen?: boolean;
        permitPropsOpen?: boolean;
    }
    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'oeprator-data-Layout',
        {
            billingPropsOpen: false,
            permitPropsOpen: false,
        },
    );
}
