import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Translation } from '../../../common/i18n/Text.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import {
    HalfSlideIn,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../../../ui/slidein/Slidein.tsx';
import { filteredPermitsFilterHalfSlideInTexts } from '../../i18n/FilteredPermitsTexts.ts';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import { CostFilterSelection } from './CostFilter.tsx';
import { IssueDateFilterSelection } from './IssueDateFilter.tsx';
import { OperatorLoginFilterSelection } from './OperatorLoginFilter.tsx';
import { PermitTypeFilterSelection } from './PermitTypeFilter.tsx';
import { TimeLimitFilterSelection } from './TimeLimitFilter.tsx';
import { StatusFilterSelection } from './StatusFilter.tsx';
import { ValidDateFilterSelection } from './ValidDateFilter.tsx';
import { ChannelFilterSelection } from './ChannelFilter.tsx';

export interface FilteredPermitsFilterHalfSlideInTexts {
    title: Translation;
}

interface FilteredPermitsFilterHalfSlideInState {
    settings: SettingsState.State;
    filter: FilteredPermitsState.Filter.State;
}

export class FilteredPermitsFilterHalfSlideIn extends Flux.Container<FilteredPermitsFilterHalfSlideInState> {
    stateSelector(): FilteredPermitsFilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredPermitsState.Filter.get(this.props.allState),
        };
    }

    render() {
        return (
            <HalfSlideIn open={this.state.filter.filterSlideInVisible}>
                <StandardFirstLevelHeader
                    onClose={() =>
                        this.props.allState.update(store =>
                            FilteredPermitsState.Filter.stateWrite(store, {
                                filterSlideInVisible: false,
                            }),
                        )
                    }
                    title={filteredPermitsFilterHalfSlideInTexts[
                        this.state.settings.language
                    ].title()}
                />
                <SlideInBody>
                    <ValidDateFilterSelection allState={this.props.allState} />
                    <PermitTypeFilterSelection />
                    <OperatorLoginFilterSelection
                        allState={this.props.allState}
                    />
                    <IssueDateFilterSelection allState={this.props.allState} />
                    <TimeLimitFilterSelection />
                    <ChannelFilterSelection />
                    <CostFilterSelection />
                    <StatusFilterSelection />
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}
