import { Translations } from '../../common/i18n/Text.ts';
import { ConfirmationHeaderTexts, ErrorHeaderTexts } from './Slidein.tsx';

export const confirmationHeaderTexts: Translations<ConfirmationHeaderTexts> = {
    de: {
        cancel: () => 'Abbrechen',
        confirm: () => 'Bestätigen',
        save: () => 'Speichern',
    },
    fr: {
        cancel: () => 'Annuler',
        confirm: () => 'Confirmer',
        save: () => 'Enregistrer',
    },
    it: {
        cancel: () => 'Annulla',
        confirm: () => 'Conferma',
        save: () => 'Salvare',
    },
};

export const errorHeaderTexts: Translations<ErrorHeaderTexts> = {
    de: {
        back: () => 'Zurück',
    },
    fr: {
        back: () => 'Retour',
    },
    it: {
        back: () => 'Indietro',
    },
};
