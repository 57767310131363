import {
    extractActiveFiltersFromQueryParams,
    FilterConfig,
    FilterFieldOptions,
    OMNIBOX_SEARCH_PARAM_NAME,
    useQuery,
} from './OperatorFilterHelpers.tsx';
import { useEffect, useState } from 'react';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { CustomerSearchResult } from '../../parkingabo-users/OperatorParkingaboUsers.tsx';
import { ProductSearchResult } from '../../products/OperatorProducts.tsx';
import {
    BackendFilterConfig,
    BackendFilterType,
    BackendFilterValueConfig,
} from './OperatorFilterBackendConfiguration.tsx';
import { CheckinStatisticResponse } from '../../statistics/OperatorStatisticsRoute.tsx';
import { OffstreetTransactionsSearchResult } from '../../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';
import { PeripheryHistorySearchResult } from '../../periphery-history/PeripheryHistory.tsx';

export function useProductFilterConfiguration() {
    return useFilterConfiguration<ProductSearchResult>(
        '/ui-api/operator-account/product/filter-configuration',
        `/ui-api/operator-account/product/search`,
    );
}

export function useOffstreetTransactionsFilterConfiguration() {
    return useFilterConfiguration<OffstreetTransactionsSearchResult>(
        '/ui-api/operator-account/offstreet-transactions/filter-configuration',
        `/ui-api/operator-account/offstreet-transactions/search`,
    );
}

export function useParkingaboUserFilterConfiguration() {
    return useFilterConfiguration<CustomerSearchResult>(
        '/ui-api/operator-account/parkingabo/customer/filter-configuration',
        `/ui-api/operator-account/parkingabo/customer/search`,
    );
}

export function useCloudConnectorPeripheryHistoryFilterConfiguration() {
    return useFilterConfiguration<PeripheryHistorySearchResult>(
        '/ui-api/operator-account/periphery-history/filter-configuration',
        `/ui-api/operator-account/periphery-history/search`,
    );
}

export type FilteredSerchResult =
    | ProductSearchResult
    | CustomerSearchResult
    | PeripheryHistorySearchResult
    | OffstreetTransactionsSearchResult;

export function useStatisticsFilterConfiguration() {
    return useFilterConfiguration<CheckinStatisticResponse>(
        '/ui-api/operator-account/statistics/filter-configuration',
        `/ui-api/operator-account/statistics`,
    );
}

function useFilterConfiguration<T>(filterConfigUrl: string, searchUrl: string) {
    const query = useQuery();
    const searchText = query.get(OMNIBOX_SEARCH_PARAM_NAME);

    const [filterConfig, setFilterCofig] = useState<FilterConfig>({});
    const [filterConfigState] = useServerFetch<BackendFilterConfig[], object>(
        () => ({
            url: filterConfigUrl,
        }),
        {},
    );
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedQuery(query);
        }, 50);

        return () => clearTimeout(timeoutId);
    }, [query]);

    useEffect(() => {
        if (filterConfigState.status !== RequestStatus.SUCCESS) {
            setFilterCofig({});
            return;
        }
        setFilterCofig(buildFilterConfig(filterConfigState.data));
    }, [filterConfigState.data]);

    const activeFilters = extractActiveFiltersFromQueryParams(
        query,
        filterConfig,
    );

    const [listState, refetchList] = useServerFetch<
        T,
        { queryString: string },
        null
    >(
        context => ({
            url: searchUrl + context.queryString,
        }),
        {
            queryString: '?' + debouncedQuery,
        },
    );
    return {
        filterConfig: filterConfig,
        activeFilters: activeFilters,
        listState: listState,
        refetchList: refetchList,
        filterConfigState: filterConfigState,
        searchText: searchText,
    };
}

function buildFilterConfig(filterConfigData: BackendFilterConfig[]) {
    const filters: FilterConfig = {};
    filterConfigData.forEach(filter => {
        switch (filter.type) {
            case BackendFilterType.DATE:
                filters[filter.key] = {
                    type: BackendFilterType.DATE,
                    displayName: filter.displayName,
                    disableSelectionInFuture: filter.disableSelectionInFuture,
                    value: null,
                };
                break;
            case BackendFilterType.SELECTION:
                filters[filter.key] = {
                    type: BackendFilterType.SELECTION,
                    displayName: filter.displayName,
                    values: filter.filters.reduce(
                        (
                            map: FilterFieldOptions,
                            filter: BackendFilterValueConfig,
                        ) => {
                            map[filter.value] = {
                                displayName: filter.displayName,
                            };
                            return map;
                        },
                        {},
                    ),
                };
                break;
            case BackendFilterType.DATE_RANGE:
                filters[filter.key] = {
                    type: BackendFilterType.DATE_RANGE,
                    displayName: filter.displayName,
                    disableSelectionInFuture: filter.disableSelectionInFuture,
                    fromValue: null,
                    toValue: null,
                };
                break;
            case BackendFilterType.ZONE:
                filters[filter.key] = {
                    type: BackendFilterType.ZONE,
                    displayName: filter.displayName,
                    filters: filter.filters.map(filter => ({
                        displayName: filter.displayName,
                        values: filter.zones.reduce(
                            (
                                map: FilterFieldOptions,
                                filter: BackendFilterValueConfig,
                            ) => {
                                map[filter.value] = {
                                    displayName: filter.displayName,
                                };
                                return map;
                            },
                            {},
                        ),
                    })),
                };
                break;
        }
    });
    return filters;
}
