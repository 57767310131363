// alternate approach if we first want to send a server request before opening the app
// https://jsfiddle.net/h9wLdqv8/2/

import { UsbAppAction } from './UsbAppAction.ts';

const URI_SCHEME = 'parkingportal-sync://';

interface UsbAppArgsA {
    operatorId?: number;
    tomId?: number;
    tomIds?: number[];
    stickId?: number;
    firmwareVersion?: string;
    otp?: number;
}

type UsbAppArgsB = {
    [key: string]: string | number | number[];
};

export type UsbAppArgs = UsbAppArgsA & UsbAppArgsB;

export function openUsbApp(action: UsbAppAction, args?: UsbAppArgs): void {
    const uri = buildAppURI(action, args);
    // FIXME: some browsers do not support opening via window.location when the uri scheme is not yet registered
    window.location.assign(uri);
}

export function buildAppURI(
    action: UsbAppAction,
    args: UsbAppArgs = {},
): string {
    const paramString = buildParamString(action, args);
    return URI_SCHEME + '?' + paramString;
}

function buildParamString(action: UsbAppAction, args: UsbAppArgs): string {
    const parts: string[] = [];
    for (const p in args) {
        if (Object.prototype.hasOwnProperty.call(args, p)) {
            const key: string = encodeURIComponent(p);
            const rawValue = Array.isArray(args[p])
                ? (args[p] as number[]).join(',')
                : args[p].toString();
            const val: string = encodeURIComponent(rawValue);
            parts.push(key + '=' + val);
        }
    }
    parts.push(`action=${action}`);
    return parts.join('&');
}
