import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import { isDefined, isUndefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import { permitFilterTexts } from '../../i18n/FilteredClearancesTexts.ts';
import * as PermitTypeState from '../../../common/state/PermitTypeState.ts';
import { StringSingleSelectionSlideIn } from '../../../ui/slidein/SingleSelectionSlideIn.tsx';
import * as FilteredClearancesState from '../../state/FilteredClearancesState.ts';
import {
    clearPermits,
    setPermits,
} from '../../actions/FilteredClearancesActions.ts';

export interface PermitFilterTexts {
    filterTitle: Text.Translation;
    withPermits: Text.Translation;
    withoutPermits: Text.Translation;
}

interface PermitFilterHalfSlideInState {
    settings: SettingsState.State;
    filter: FilteredClearancesState.Filter.State;
}

const cn = ElementNamer('PermitFilterSlideIn');

export class PermitFilterHalfSlideIn extends Flux.Container<PermitFilterHalfSlideInState> {
    static displayName: string = cn('');

    stateSelector(): PermitFilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredClearancesState.Filter.get(this.props.allState),
        };
    }

    render() {
        const texts = permitFilterTexts[this.state.settings.language];
        return (
            <StringSingleSelectionSlideIn
                open={this.state.filter.permitsSelectionVisible}
                heading={permitFilterTexts[
                    this.state.settings.language
                ].filterTitle()}
                selection={this.state.filter.permits}
                options={[
                    { id: 'WITH_PERMITS', displayText: texts.withPermits() },
                    {
                        id: 'WITHOUT_PERMITS',
                        displayText: texts.withoutPermits(),
                    },
                ]}
                onLeftActionClick={() =>
                    this.update(store =>
                        FilteredClearancesState.Filter.stateWrite(store, {
                            permitsSelectionVisible: false,
                        }),
                    )
                }
                onSelect={v => this.update(store => setPermits(store, v))}
            />
        );
    }
}

const permitsString = (
    value: Maybe<string>,
    texts: PermitFilterTexts,
): string => {
    if (isUndefined(value)) {
        return '';
    } else if (value === 'WITH_PERMITS') {
        return texts.withPermits();
    } else if (value === 'WITHOUT_PERMITS') {
        return texts.withoutPermits();
    } else {
        return '';
    }
};

interface PermitFilterSelectionState {
    settings: SettingsState.State;
    filter: FilteredClearancesState.Filter.State;
    permitTypes: PermitTypeState.State;
}

export class PermitFilterSelection extends Flux.Container<PermitFilterSelectionState> {
    static displayName = 'PermitFilterSelection';

    stateSelector(): PermitFilterSelectionState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredClearancesState.Filter.get(this.props.allState),
            permitTypes: PermitTypeState.get(this.props.allState),
        };
    }

    render() {
        const texts = permitFilterTexts[this.state.settings.language];
        const clear = isDefined(this.state.filter.permits)
            ? () => this.update(clearPermits)
            : null;

        return (
            <SingleSelection
                label={texts.filterTitle()}
                focused={false}
                selection={permitsString(this.state.filter.permits, texts)}
                onClick={() =>
                    this.update(store =>
                        FilteredClearancesState.Filter.stateWrite(store, {
                            permitsSelectionVisible: true,
                        }),
                    )
                }
                onClear={clear}
            />
        );
    }
}
