import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ClearanceCreateState from '../state/ClearanceCreateState.ts';
import { NumberSingleSelectionSlideIn } from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as ClearanceCreateTexts from '../i18n/ClearanceCreateTexts.ts';
import moment from 'moment';
import { NumberMultiSelectionSlideIn } from '../../ui/slidein/MultiSelectionSlideIn.tsx';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { FromDatePickerSlideIn, ToDatePickerSlideIn } from './Shared.tsx';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { LicensePlateCountrySlideIn } from '../../common/components/LicensePlateCountrySlideIn.tsx';
import { AutoActive } from 'product-shared/product-template/ProductOptions.tsx';

export interface LpSlideinTexts {
    Heading: Translation;
    Caption: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: ClearanceCreateState.Layout.State;
    create: ClearanceCreateState.Create.State;
}

export class ClearanceLpCountrySlideIn extends Flux.Container<State> {
    static displayName = 'ClearanceLpCountrySlideIn';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ClearanceCreateState.Layout.get(this.props.allState),
            create: ClearanceCreateState.Create.get(this.props.allState),
        };
    }

    txt(): LpSlideinTexts {
        return ClearanceCreateTexts.countrySelection[
            this.state.settings.language
        ];
    }

    render() {
        return (
            <LicensePlateCountrySlideIn
                language={this.state.settings.language}
                open={this.state.layout.lpCountrySelectionOpen}
                onSelect={(v: string, formValid: boolean) =>
                    this.props.allState.update((store: Flux.Store): string => {
                        ClearanceCreateState.Create.stateWrite(store, {
                            country: v,
                            licensePlateValid: formValid,
                        });
                        ClearanceCreateState.Layout.stateWrite(store, {
                            lpCountrySelectionOpen: false,
                        });
                        return 'LicensePlateCountrySlideIn-SelectLicensePlateCountry';
                    })
                }
                country={this.state.create.country}
                licensePlateType={this.state.create.type}
                licensePlateNr={this.state.create.licensePlateNumber}
                onClose={() =>
                    this.update(store =>
                        ClearanceCreateState.Layout.stateWrite(store, {
                            lpCountrySelectionOpen: false,
                        }),
                    )
                }
                outsideBody
            />
        );
    }
}

interface ValidFromState {
    settings: SettingsState.State;
    layout: ClearanceCreateState.Layout.State;
    create: ClearanceCreateState.Create.State;
}

export class ClearanceValidFromSlideIn extends Flux.Container<ValidFromState> {
    static displayName = 'ClearanceValidFromSlideIn';

    stateSelector(): ValidFromState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ClearanceCreateState.Layout.get(this.props.allState),
            create: ClearanceCreateState.Create.get(this.props.allState),
        };
    }

    onDateSelected(selectedDate: moment.Moment): void {
        this.update((store: Flux.Store): string => {
            ClearanceCreateState.Create.stateWrite(store, {
                validFrom: selectedDate,
            });
            ClearanceCreateState.Layout.stateWrite(store, {
                validFromDatePickerOpen: false,
            });
            return 'ClearanceValidFromSlideIn-selectDate';
        });
    }

    render() {
        return (
            <FromDatePickerSlideIn
                open={this.state.layout.validFromDatePickerOpen}
                language={this.state.settings.language}
                onClose={() =>
                    this.update(store =>
                        ClearanceCreateState.Layout.stateWrite(store, {
                            validFromDatePickerOpen: false,
                        }),
                    )
                }
                validFrom={this.state.create.validFrom}
                validTo={this.state.create.validTo}
                onSelect={(selectedDate: moment.Moment) =>
                    this.onDateSelected(selectedDate)
                }
            />
        );
    }
}

export class ClearanceValidToSlideIn extends Flux.Container<ValidFromState> {
    static displayName = 'ClearanceValidToSlideIn';

    stateSelector(): ValidFromState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ClearanceCreateState.Layout.get(this.props.allState),
            create: ClearanceCreateState.Create.get(this.props.allState),
        };
    }

    onDateSelected(selectedDate: moment.Moment): void {
        this.update((store: Flux.Store): string => {
            ClearanceCreateState.Create.stateWrite(store, {
                validTo: selectedDate,
            });
            ClearanceCreateState.Layout.stateWrite(store, {
                validToDatePickerOpen: false,
            });
            return 'ClearanceValidToSlideIn-selectDate';
        });
    }

    render() {
        return (
            <ToDatePickerSlideIn
                open={this.state.layout.validToDatePickerOpen}
                language={this.state.settings.language}
                onClose={() =>
                    this.update(store =>
                        ClearanceCreateState.Layout.stateWrite(store, {
                            validToDatePickerOpen: false,
                        }),
                    )
                }
                validTo={this.state.create.validTo}
                onSelect={(selectedDate: moment.Moment) =>
                    this.onDateSelected(selectedDate)
                }
                validFrom={this.state.create.validFrom}
            />
        );
    }
}

interface ClearancePermitTypeSelectionSlideInState {
    settings: SettingsState.State;
    layout: ClearanceCreateState.Layout.State;
    create: ClearanceCreateState.Create.State;
    permitTypes: PermitTypeState.State;
}

export interface PermitTypeSelectionTexts {
    PermitTypes: Translation;
}

export class ClearancePermitTypeSelectionSlideIn extends Flux.Container<ClearancePermitTypeSelectionSlideInState> {
    static displayName = 'ClearancePermitTypeSelectionSlideIn';

    stateSelector(): ClearancePermitTypeSelectionSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ClearanceCreateState.Layout.get(this.props.allState),
            create: ClearanceCreateState.Create.get(this.props.allState),
            permitTypes: PermitTypeState.get(this.props.allState),
        };
    }

    txt(): PermitTypeSelectionTexts {
        return ClearanceCreateTexts.permitTypeSelection[
            this.state.settings.language
        ];
    }

    render() {
        return (
            <NumberMultiSelectionSlideIn
                open={this.state.layout.permitTypeSelectionOpen}
                heading={this.txt().PermitTypes()}
                selection={this.state.create.permitTypes}
                options={this.state.permitTypes.data
                    .filter(
                        pt =>
                            (pt.needsWhitelist ||
                                pt.autoActive ===
                                    AutoActive.STARTS_TRANSACTION) &&
                            pt.operatorState === 'ACTIVE' &&
                            !pt.isWhitelistCustomer,
                    )
                    .map(pt => {
                        return { id: pt.id, displayText: pt.description };
                    })}
                onLeftActionClick={() =>
                    this.update(store =>
                        ClearanceCreateState.Layout.stateWrite(store, {
                            permitTypeSelectionOpen: false,
                        }),
                    )
                }
                setNewSelection={(v: number[]) =>
                    this.update(store =>
                        ClearanceCreateState.Create.setSelectedPermitTypes(
                            store,
                            v,
                        ),
                    )
                }
                outsideBody
            />
        );
    }
}

interface PriceMultiplierSelectionSlideInState {
    settings: SettingsState.State;
    layout: ClearanceCreateState.Layout.State;
    create: ClearanceCreateState.Create.State;
    permitTypes: PermitTypeState.State;
}

export interface PriceMultiplierSelectionSlideInTexts {
    Heading: Translation;
}

export class PriceMultiplierSelectionSlideIn extends Flux.Container<PriceMultiplierSelectionSlideInState> {
    static displayName = 'PriceMultiplierSelectionSlideIn';

    stateSelector(): ClearancePermitTypeSelectionSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ClearanceCreateState.Layout.get(this.props.allState),
            create: ClearanceCreateState.Create.get(this.props.allState),
            permitTypes: PermitTypeState.get(this.props.allState),
        };
    }

    txt(): PriceMultiplierSelectionSlideInTexts {
        return ClearanceCreateTexts.priceMultiplierSelection[
            this.state.settings.language
        ];
    }

    render() {
        const permitType: Maybe<PermitTypeState.PermitType> =
            this.state.permitTypes.data.filter(
                pt =>
                    pt.id === this.state.layout.priceMultiplierOfPermitTypeOpen,
            )[0];

        if (
            permitType &&
            permitType.allowedPriceModifiers &&
            permitType.allowedPriceModifiers.length > 0
        ) {
            return (
                <NumberSingleSelectionSlideIn
                    open={isDefined(
                        this.state.layout.priceMultiplierOfPermitTypeOpen,
                    )}
                    heading={this.txt().Heading()}
                    selection={
                        this.state.create.priceModifierIds[permitType.id]
                    }
                    options={permitType.allowedPriceModifiers.map(m => {
                        return { id: m.priceModifierId, displayText: m.label };
                    })}
                    onSelect={(v: number) =>
                        this.props.allState.update(
                            (store: Flux.Store): string => {
                                ClearanceCreateState.Create.setPriceMultiplier(
                                    store,
                                    { id: permitType.id, factor: v },
                                );
                                ClearanceCreateState.Layout.stateWrite(store, {
                                    priceMultiplierOfPermitTypeOpen: null,
                                });
                                return 'ClearanceLpCountrySlideIn-SelectPriceMultiplier';
                            },
                        )
                    }
                    onLeftActionClick={() =>
                        this.update(store =>
                            ClearanceCreateState.Layout.stateWrite(store, {
                                priceMultiplierOfPermitTypeOpen: null,
                            }),
                        )
                    }
                    outsideBody
                />
            );
        } else {
            return (
                <NumberSingleSelectionSlideIn
                    open={false}
                    options={[]}
                    heading={''}
                    onLeftActionClick={() => null}
                    onSelect={() => null}
                    selection={null}
                />
            );
        }
    }
}
