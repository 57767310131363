import {
    Translation,
    Translations,
    TranslationWithArgs1,
    TranslationWithArgs2,
} from '../common/i18n/Text.ts';

export const passwordChangeTokenTexts: Translations<PasswordChangeTokenTexts> =
    {
        de: {
            confirmPassword: () => 'Passwort bestätigen',
            httpRequestFailed: httpStatus =>
                `Die Anfrage konnte nicht übermittelt werden (HTTP ${httpStatus}). Bitte versuchen Sie später nochmals.`,
            newPassword: () => 'Neues Passwort',
            passwordChangeSucceeded: () =>
                'Das neue Passwort wurde gespeichert.',
            passwordFieldsDoNotMatch: () => 'Passwörter stimmen nicht überein.',
            passwordIsTooShort: minLength =>
                `Das Passwort ist zu kurz. Es muss midestens ${minLength} Zeichen lang sein.`,
            proceedToParkingPortal: () => 'Zurück zum Parkingportal',
            requiredField: () => 'Pflichtfeld',
            resetPasswordForUserX: (firstName, lastName) =>
                `Passwort für den Benutzer ${firstName} ${lastName} zurücksetzen`,
            save: () => 'Speichern',
            tokenLookupFailed: httpStatus =>
                `Der Link ist ungültig oder abgelaufen (HTTP ${httpStatus}).`,
        },
        fr: {
            confirmPassword: () => 'Confirmer le mot de passe',
            httpRequestFailed: httpStatus =>
                `La requête n'a pas pu être envoyée (HTTP ${httpStatus}). Veuillez réessayer plus tard.`,
            newPassword: () => 'Nouveau mot de passe',
            passwordChangeSucceeded: () =>
                'Le nouveau mot de passe a été enregistré.',
            passwordFieldsDoNotMatch: () =>
                'Les mots de passe ne correspondent pas.',
            passwordIsTooShort: minLength =>
                `Le mot de passe est trop court. Il doit contenir au moins ${minLength} caractères.`,
            proceedToParkingPortal: () => 'Retourner au Parkingportal',
            requiredField: () => 'Champ obligatoire',
            resetPasswordForUserX: (firstName, lastName) =>
                `Réinitialiser le mot de passe pour l'utilisateur ${firstName} ${lastName}`,
            save: () => 'Sauvegarder',
            tokenLookupFailed: httpStatus =>
                `Le lien est invalide ou a expiré (HTTP ${httpStatus}).`,
        },
        it: {
            confirmPassword: () => 'Conferma password',
            httpRequestFailed: httpStatus =>
                `La richiesta non ha potuto essere inviata (HTTP ${httpStatus}). Vogliate riprovare più tardi.`,
            newPassword: () => 'Nuova password',
            passwordChangeSucceeded: () => 'La nuova password è stata salvata.',
            passwordFieldsDoNotMatch: () =>
                'Le due password inserite non corrispondono',
            passwordIsTooShort: minLength =>
                `La password è troppo corta. Deve contenere almeno ${minLength} caratteri.`,
            proceedToParkingPortal: () => 'Torna al Parkingportal',
            requiredField: () => 'Campo obbligatorio',
            resetPasswordForUserX: (firstName, lastName) =>
                `Reimposta la password per l'utente ${firstName} ${lastName}`,
            save: () => 'Salva',
            tokenLookupFailed: httpStatus =>
                `Il link non è valido oppure è scaduto (HTTP ${httpStatus}).`,
        },
    };

export interface PasswordChangeTokenTexts {
    confirmPassword: Translation;
    httpRequestFailed: TranslationWithArgs1<number>;
    newPassword: Translation;
    passwordChangeSucceeded: Translation;
    passwordFieldsDoNotMatch: Translation;
    passwordIsTooShort: TranslationWithArgs1<number>;
    proceedToParkingPortal: Translation;
    requiredField: Translation;
    resetPasswordForUserX: TranslationWithArgs2<string, string>;
    save: Translation;
    tokenLookupFailed: TranslationWithArgs1<number>;
}
