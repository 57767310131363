import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import moment from 'moment/moment';
import { useState } from 'react';
import * as MasterDataZonesState from '../common/state/MasterDataZonesState.ts';
import { FilterButtonWithSummary } from '../ui/filter/FilterButtonWithSummary.tsx';
import * as Text from '../common/i18n/Text.ts';
import { Translations } from '../common/i18n/Text.ts';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../ui/filter/FilterSummary.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import {
    fromDateConstraint,
    toDateConstraint,
} from '../clearance-permit-list/actions/shared.ts';

export type OnstreetTransactionStatus = 'running' | 'terminated' | 'refunded';

export type OnstreetTransactionFilterState = {
    filterSlideInVisible: boolean;
    validFrom: Maybe<moment.Moment>;
    validTo: Maybe<moment.Moment>;
    validDateSelectionVisible: boolean;
    zoneIds: number[];
    zoneSelectionVisible: boolean;
    search: Maybe<string>;
    status: OnstreetTransactionStatus | null;
    statusFilterVisible: boolean;
};

export type OnstreetTransactionFilter = {
    state: OnstreetTransactionFilterState & {
        dateFilterActive: boolean;
        zoneFilterActive: boolean;
        statusFilterActive: boolean;
        filterActive: boolean;
        searchActive: boolean;
    };
    clearFilter: () => void;
    showFilterSlideIn: (show: boolean) => void;
    showDateFilterSlideIn: (show: boolean) => void;
    showZoneFilterSlideIn: (show: boolean) => void;
    showStatusFilterSlideIn: (show: boolean) => void;
    setValidFrom: (validFrom: moment.Moment) => void;
    setValidTo: (validFrom: moment.Moment) => void;
    clearValidDate: () => void;
    setZoneIds: (zoneIds: number[]) => void;
    setTransactionStatus: (state: OnstreetTransactionStatus) => void;
    clearTransactionStatus: () => void;
    setSearchValue: (searchString: string | null) => void;
};

export function useOnstreetTransactionFilter(): OnstreetTransactionFilter {
    const [state, setState] = useState<OnstreetTransactionFilterState>({
        filterSlideInVisible: false,
        validFrom: null,
        validTo: null,
        validDateSelectionVisible: false,
        zoneIds: [],
        zoneSelectionVisible: false,
        search: null,
        status: null,
        statusFilterVisible: false,
    });

    const dateFilterActive =
        isDefined(state.validFrom) || isDefined(state.validTo);
    const zoneFilterActive = state.zoneIds.length > 0;
    const statusFilterActive = isDefined(state.status);

    return {
        state: {
            ...state,
            dateFilterActive,
            zoneFilterActive,
            statusFilterActive,
            filterActive:
                dateFilterActive || zoneFilterActive || statusFilterActive,
            searchActive: isDefined(state.search) && state.search.length >= 3,
        },
        clearFilter: () =>
            setState(state => ({
                ...state,
                validFrom: null,
                validTo: null,
                zoneIds: [],
                status: null,
            })),
        showFilterSlideIn: visible =>
            setState(state => ({
                ...state,
                filterSlideInVisible: visible,
            })),
        showDateFilterSlideIn: visible =>
            setState(state => ({
                ...state,
                validDateSelectionVisible: visible,
            })),
        showZoneFilterSlideIn: visible =>
            setState(state => ({
                ...state,
                zoneSelectionVisible: visible,
            })),
        showStatusFilterSlideIn: visible =>
            setState(state => ({
                ...state,
                statusFilterVisible: visible,
            })),
        setValidFrom: validFrom =>
            setState(state => ({
                ...state,
                validFrom: validFrom,
                validTo: toDateConstraint(validFrom, state.validTo),
            })),
        setValidTo: validTo =>
            setState(state => ({
                ...state,
                validFrom: fromDateConstraint(validTo, state.validFrom),
                validTo: validTo,
            })),
        clearValidDate: () =>
            setState(state => ({
                ...state,
                validFrom: null,
                validTo: null,
            })),
        setZoneIds: zoneIds =>
            setState(state => ({
                ...state,
                zoneIds,
            })),
        setTransactionStatus: status =>
            setState(state => ({
                ...state,
                status,
                statusFilterVisible: false,
            })),
        clearTransactionStatus: () =>
            setState(state => ({
                ...state,
                status: null,
            })),
        setSearchValue: searchString =>
            setState(state => ({
                ...state,
                search: searchString,
            })),
    };
}

export function ParkTransactionsFilterSummary({
    language,
    filter,
    zones,
}: {
    language: string;
    filter: OnstreetTransactionFilter;
    zones: MasterDataZonesState.State;
}) {
    const texts = onstreetTransactionsFilterSummaryTexts[language];
    return (
        <FilterButtonWithSummary
            onFilterClick={() => filter.showFilterSlideIn(true)}
            clearFilter={filter.clearFilter}
        >
            {filter.state.filterActive && (
                <>
                    {filter.state.dateFilterActive && (
                        <DateFilterSummary
                            key="date"
                            filter={filter}
                            texts={texts}
                        />
                    )}
                    {filter.state.zoneFilterActive && (
                        <ZoneFilterSummary
                            key="zone"
                            filter={filter}
                            texts={texts}
                            zones={zones.data}
                        />
                    )}
                    {filter.state.statusFilterActive && (
                        <StatusFilterSummary
                            key="status"
                            filter={filter}
                            texts={texts}
                        />
                    )}
                </>
            )}
        </FilterButtonWithSummary>
    );
}

function DateFilterSummary({
    filter,
    texts,
}: {
    filter: OnstreetTransactionFilter;
    texts: FilterSummaryTexts;
}) {
    return (
        <FilterSummaryItem hasSeparator={false}>
            <FilterSummaryItemTitle title={texts.dateFrom()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    Formatter.openRange(
                        filter.state.validFrom,
                        filter.state.validTo,
                        Formatter.dayMonthYear,
                    )
                }
            />
        </FilterSummaryItem>
    );
}

function ZoneFilterSummary({
    zones,
    filter,
    texts,
}: {
    zones: MasterDataZonesState.MasterDataZone[];
    filter: OnstreetTransactionFilter;
    texts: FilterSummaryTexts;
}) {
    return (
        <FilterSummaryItem hasSeparator={filter.state.dateFilterActive}>
            <FilterSummaryItemTitle title={texts.zone()} />
            <FilterSummaryItemSubTitle
                title={'\u00A0' + zoneSelection(filter.state.zoneIds, zones)}
            />
        </FilterSummaryItem>
    );
}

function StatusFilterSummary({
    filter,
    texts,
}: {
    filter: OnstreetTransactionFilter;
    texts: FilterSummaryTexts;
}) {
    return (
        <FilterSummaryItem hasSeparator={filter.state.zoneFilterActive}>
            <FilterSummaryItemTitle
                title={
                    '\u00A0' +
                    (filter.state.status === 'running'
                        ? texts.statusRunningOnly()
                        : filter.state.status === 'terminated'
                          ? texts.statusTerminatedOnly()
                          : texts.statusRefundedOnly())
                }
            />
        </FilterSummaryItem>
    );
}

type FilterSummaryTexts = {
    dateFrom: Text.Translation;
    dateTo: Text.Translation;
    zone: Text.Translation;
    status: Text.Translation;
    statusRunningOnly: Text.Translation;
    statusTerminatedOnly: Text.Translation;
    statusRefundedOnly: Text.Translation;
};

const onstreetTransactionsFilterSummaryTexts: Translations<FilterSummaryTexts> =
    {
        de: {
            dateFrom: () => 'Von',
            dateTo: () => 'Bis',
            zone: () => 'Zone',
            status: () => 'Status',
            statusRunningOnly: () => 'Nur laufende',
            statusTerminatedOnly: () => 'Nur abgeschlossene',
            statusRefundedOnly: () => 'Nur zurückbezahlte',
        },
        fr: {
            dateFrom: () => 'Du',
            dateTo: () => 'Au',
            zone: () => 'Zone',
            status: () => 'État',
            statusRunningOnly: () => 'Seulement en cours',
            statusTerminatedOnly: () => 'Seulement terminée',
            statusRefundedOnly: () => 'Seulement remboursées',
        },
        it: {
            dateFrom: () => 'Dal',
            dateTo: () => 'Al',
            zone: () => 'Zona',
            status: () => 'Stato',
            statusRunningOnly: () => 'Solo in corso',
            statusTerminatedOnly: () => 'Solo terminate',
            statusRefundedOnly: () => 'Solo rimborsate',
        },
    };

export const zoneSelection = (
    filter: number[],
    zones: MasterDataZonesState.MasterDataZone[],
) =>
    filter.length
        ? filter.length +
          `/` +
          zones.filter(
              z => z.transactionType != null && !z.isFromForeignMandant,
          ).length
        : null;
