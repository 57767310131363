import { LoginBlock } from './LoginBlock.tsx';
import { SupportTextBlock } from './SupportTextBlock.tsx';
import { ContentTextBlock } from './ContentTextBlock.tsx';
import { ALLOWED_LANGUAGE_CODES } from '../common/i18n/Text.ts';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { selectState, Store } from 'dg-web-shared/lib/Flux.tsx';
import { envIsProduction } from 'dg-web-shared/lib/Environment.ts';
import { SystemStatus } from '../common/components/SystemStatusLink.tsx';
import { css } from '@emotion/css';
import { languageFromString } from 'dg-web-shared/lib/Localized.ts';
import { ColorHex } from '../ui/Colors.ts';
import { OperatorTypo } from '../ui/OperatorTypo.ts';

import parkingPortalLogo from '../../assets/parkingportal_logo_white.svg';
import { TermOfUseLink } from './TermOfUseFiles.tsx';
import bg from './bg_landingpage.jpg';

const setLanguage = (store: Store, lang: string) => {
    new SettingsState.StateSlice(store).writeLanguage(lang);
    return 'setLanguage';
};

const LanguageChooser = selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
    }),
    p => (
        <div
            className={css({
                display: 'flex',
                alignItems: 'center',
                marginRight: '48px',
            })}
        >
            {ALLOWED_LANGUAGE_CODES.map(lang =>
                lang !== p.settings.language ? (
                    <Clickable
                        element="div"
                        key={lang}
                        className={css({
                            ...OperatorTypo.bodyC,
                            color: ColorHex.white,
                            padding: '10px',
                            '&:hover': {
                                color: ColorHex.lightblue,
                            },
                        })}
                        onClick={() =>
                            p.update(store => setLanguage(store, lang))
                        }
                    >
                        {lang}
                    </Clickable>
                ) : (
                    <div
                        key={lang}
                        className={css({
                            ...OperatorTypo.bodyC,
                            color: ColorHex.white,
                            padding: '10px',
                            cursor: 'default',
                            backgroundColor: ColorHex.navbarhover,
                        })}
                    >
                        {lang}
                    </div>
                ),
            )}
        </div>
    ),
);

export const LandingPage = selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
    }),
    p => (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                minWidth: '1024px',
            })}
        >
            <div
                className={css({
                    height: '48px',
                    background: envIsProduction()
                        ? ColorHex.navbar
                        : ColorHex.fPendent,
                    display: 'flex',
                    justifyContent: 'space-between',
                })}
            >
                <img
                    src={parkingPortalLogo}
                    className={css({
                        width: '136px',
                        height: '48px',
                        marginLeft: '15px',
                    })}
                />
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                    })}
                >
                    <div className={css({ marginRight: '32px' })}>
                        <TermOfUseLink
                            lang={languageFromString(p.settings.language)}
                        />
                    </div>

                    <div className={css({ marginRight: '48px' })}>
                        <SystemStatus />
                    </div>

                    <LanguageChooser />
                </div>
            </div>
            <div
                className={css({
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    backgroundSize: 'cover',
                    backgroundColor: ColorHex.black,
                    backgroundImage: `url(${bg})`,
                })}
            >
                <div
                    className={css({
                        display: 'flex',
                        width: '928px',
                        height: '700px',
                        alignSelf: 'center',
                    })}
                >
                    <div
                        className={css({
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        })}
                    >
                        <div
                            className={css({
                                flex: 3,
                                backgroundColor: ColorHex.darkblue,
                            })}
                        >
                            <LoginBlock />
                        </div>
                        <div
                            className={css({
                                flex: 2,
                                backgroundColor: ColorHex.navbarhover,
                            })}
                        >
                            <SupportTextBlock />
                        </div>
                    </div>
                    <div
                        className={css({
                            flex: 1.9,
                            backgroundColor: ColorHex.white,
                        })}
                    >
                        <ContentTextBlock />
                    </div>
                </div>
            </div>
        </div>
    ),
);
