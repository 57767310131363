import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import {
    IdentificationBadge,
    IdentificationLicensePlate,
} from '../../../common/components/IdentificationItems.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../../common/state/OperatorDataState.ts';
import * as OperatorLoginsState from '../../../common/state/OperatorLoginsState.ts';
import {
    getLoginById,
    getLoginTitle,
} from '../../../common/state/OperatorLoginsState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import {
    TextColumnContent,
    TextLeftColumn,
    TextRightColumn,
} from '../../../ui/layout/Text.tsx';
import {
    ColumnWidth,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
    TableRow,
} from '../../../ui/table/Table.tsx';
import { selectClearance } from '../../actions/ClearancePermitListActions.ts';
import { filteredClearancesListTexts } from '../../i18n/FilteredClearancesTexts.ts';
import * as FilteredClearancesState from '../../state/FilteredClearancesState.ts';
import { clearanceStatusIcon } from '../../state/LicensePlateBadgeClearancesState.ts';
import { CreateGuide } from '../shared/Guides.tsx';
import * as Identifier from '../shared/Identifier.ts';
import { IconTopOffsetContainer } from '../../../zones/components/ZoneListBody.tsx';
import { ModalSpecialTabResultRefresher } from '../../../ui/layout/TabContent.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../../ui/Colors.ts';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import { Icon16, Icon24 } from '../../../ui/icons/Icon.tsx';
import { error } from 'dg-web-shared/ui/icons/Icons24.tsx';
import { chevronRight } from 'dg-web-shared/ui/icons/Icons16.tsx';

export interface FilteredClearancesListTexts {
    tooManyRowsTitle: Text.Translation;
    tooManyRowsDescription: Text.Translation;

    noRowsTitle: Text.Translation;
    noRowsDescription: Text.Translation;

    headerStatus: Text.Translation;
    headerIdentifier: Text.Translation;
    headerZoneName: Text.Translation;
    headerIssuedDate: Text.Translation;
    headerValidFrom: Text.Translation;
    headerValidTo: Text.Translation;
    headerPermitTypeName: Text.Translation;
    headerOperatorLoginName: Text.Translation;
    headerPermitId: Text.Translation;
    headerCustomerNr: Text.Translation;

    hasMany: Text.Translation;
    onlineUser: Text.Translation;

    filterGuideTitle: Text.Translation;
    filterGuideIntro: Text.Translation;
    filterGuideListDescription: Text.Translation;
    filterGuideType: Text.Translation;
    filterGuideUser: Text.Translation;
    filterGuideCreateDate: Text.Translation;
    filterGuideValidity: Text.Translation;
    filterWithOrWithoutDate: Text.Translation;
    filterGuideExportTitle: Text.Translation;
    filterGuideExportDescription: Text.Translation;
}

interface ResultsErrorProps {
    title: string;
    description: string;
}

const ResultsError = (p: ResultsErrorProps) => (
    <div
        className={css({
            textAlign: 'center',
            height: '80px',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            marginTop: '-40px',
        })}
    >
        <div className={css({ color: ColorHex.error })}>
            <Icon24 icon={error} />
        </div>
        <div
            className={css({
                ...OperatorTypo.headingTwo,
                color: ColorHex.error,
            })}
        >
            {p.title}
        </div>
        <div
            className={css({
                ...OperatorTypo.headingOne,
                color: ColorHex.error,
            })}
        >
            {p.description}
        </div>
    </div>
);

interface FilteredClearancesListState {
    settings: SettingsState.State;
    clearancesList: FilteredClearancesState.List.State;
    filter: FilteredClearancesState.Filter.State;
    operatorLogins: OperatorLoginsState.List.State;
    login: CurrentOperatorLoginState.State;
    operatorData: OperatorDataState.State;
}

export const Guide = (p: FilteredClearancesListState) => {
    const login = p.login.data;
    const texts = filteredClearancesListTexts[p.settings.language];
    if (login) {
        return (
            <TextColumnContent>
                <TextLeftColumn>
                    <h1>{texts.filterGuideTitle()}</h1>
                    <h3>
                        <span>{texts.filterGuideIntro()}</span>
                    </h3>
                    <h3>{texts.filterGuideListDescription()}</h3>
                    <ul>
                        <li>{texts.filterGuideType()}</li>
                        <li>{texts.filterGuideUser()}</li>
                        <li>{texts.filterGuideCreateDate()}</li>
                        <li>{texts.filterWithOrWithoutDate()}</li>
                        <li>{texts.filterGuideValidity()}</li>
                    </ul>
                </TextLeftColumn>
                <TextRightColumn>
                    <h1>{texts.filterGuideExportTitle()}</h1>
                    <h3>{texts.filterGuideExportDescription()}</h3>
                    <CreateGuide login={login} lang={p.settings.language} />
                </TextRightColumn>
            </TextColumnContent>
        );
    } else {
        return <noscript />;
    }
};

export class FilteredClearancesList extends Flux.Container<FilteredClearancesListState> {
    stateSelector(): FilteredClearancesListState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            clearancesList: FilteredClearancesState.List.get(
                this.props.allState,
            ),
            filter: FilteredClearancesState.Filter.get(this.props.allState),
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
            login: CurrentOperatorLoginState.get(this.props.allState),
            operatorData: OperatorDataState.get(this.props.allState),
        };
    }

    renderIntroduction(): JSX.Element {
        return <Guide {...this.state} />;
    }

    getTexts(): FilteredClearancesListTexts {
        return filteredClearancesListTexts[this.state.settings.language];
    }

    renderTooManyRows(): JSX.Element {
        const texts = this.getTexts();
        return (
            <ResultsError
                title={texts.tooManyRowsTitle()}
                description={texts.tooManyRowsDescription()}
            />
        );
    }

    renderNoRows(): JSX.Element {
        const texts = this.getTexts();
        return (
            <ResultsError
                title={texts.noRowsTitle()}
                description={texts.noRowsDescription()}
            />
        );
    }

    render() {
        const texts = this.getTexts();
        const clearances = this.state.clearancesList;

        const filteredClearancesListRefresher = (
            <ModalSpecialTabResultRefresher
                onRefreshClick={() =>
                    this.update(FilteredClearancesState.List.forceRefetch, true)
                }
                successRequest={
                    this.state.clearancesList.statusCode.statusCode === 200
                }
                pendingRequest={this.state.clearancesList.pending}
            />
        );
        if (clearances.pending) {
            return filteredClearancesListRefresher;
        }

        if (!FilteredClearancesState.Filter.filterActive(this.state.filter)) {
            return this.renderIntroduction();
        }

        if (
            clearances.data.data.length < 1 &&
            clearances.data.size &&
            clearances.data.size > 1
        ) {
            return (
                <>
                    {filteredClearancesListRefresher}
                    {this.renderTooManyRows()}
                </>
            );
        }

        if (
            clearances.data.data.length < 1 &&
            clearances.data.size &&
            clearances.data.size < 1
        ) {
            return (
                <>
                    {filteredClearancesListRefresher}
                    {this.renderNoRows()}
                </>
            );
        }

        return (
            <>
                {filteredClearancesListRefresher}
                <div>
                    <Table>
                        <TableHeader>
                            <TableHeaderColumn
                                name={texts.headerStatus()}
                                width={ColumnWidth._72px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={thenElse(
                                    this.state.operatorData.data,
                                    d =>
                                        Identifier.getDescriptionSingle(
                                            d.licensePlatePermitSettings
                                                .hasBadgePermits,
                                            this.state.settings.language,
                                        ),
                                    '',
                                )}
                                width={ColumnWidth._192px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerCustomerNr()}
                                width={ColumnWidth._136px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerIssuedDate()}
                                direction={SortDirection.Descending}
                                selected={true}
                                width={ColumnWidth._136px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerValidFrom()}
                                width={ColumnWidth._108px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerValidTo()}
                                width={ColumnWidth._108px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerPermitTypeName()}
                                width={ColumnWidth.variable}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerOperatorLoginName()}
                                width={ColumnWidth._192px}
                                delimiterBottom={false}
                            />
                            <EmptyTableHeaderColumn
                                width={ColumnWidth._24px}
                                delimiterBottom={false}
                            />
                        </TableHeader>
                        <TableBody>
                            {clearances.data.data.map(p => (
                                <ClearanceRow
                                    allState={this.props.allState}
                                    clearance={p}
                                    language={this.state.settings.language}
                                    onClick={() =>
                                        this.update(store =>
                                            selectClearance(store, p.id),
                                        )
                                    }
                                    operatorLogins={
                                        this.state.operatorLogins.data
                                    }
                                    key={p.id}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </>
        );
    }
}

const clearanceValidDatesAsString = (p: FilteredClearancesState.Clearance) => {
    return {
        validFrom: Formatter.isoString(p.validFrom),
        validTo: Formatter.isoString(p.validTo),
    };
};

interface ClearanceRowProps {
    allState: Flux.Store;
    clearance: FilteredClearancesState.Clearance;
    language: string;
    onClick: ClickHandler;
    operatorLogins: OperatorLoginsState.OperatorLogin[];
}

const ClearanceRow = (p: ClearanceRowProps): JSX.Element => (
    <TableRow
        className={css({
            borderTop: `1px solid ${ColorHex.rgba(ColorHex.darkblue, 0.2)}`,
        })}
        onClick={p.onClick}
    >
        <TableColumn width={ColumnWidth._72px}>
            <div
                className={css({
                    display: 'table-cell',
                    width: '32px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                })}
            >
                <Icon24
                    icon={clearanceStatusIcon(
                        clearanceValidDatesAsString(p.clearance),
                    )}
                />
            </div>
        </TableColumn>
        <TableColumn width={ColumnWidth._192px}>
            <div
                className={css({
                    display: 'table',
                    width: '100%',
                    paddingLeft: '4px',
                })}
            >
                <div
                    className={css({
                        display: 'table-cell',
                        verticalAlign: 'middle',
                    })}
                >
                    {p.clearance.licensePlateNr ? (
                        <IdentificationLicensePlate
                            licensePlateNr={p.clearance.licensePlateNr}
                            type={p.clearance.licensePlateType}
                            country={p.clearance.licensePlateCountry}
                            inlineMode={true}
                        />
                    ) : p.clearance.badgeLabelNr ? (
                        <IdentificationBadge
                            badgeLabelNr={p.clearance.badgeLabelNr}
                            rfidDecimal={null}
                            inlineMode={true}
                        />
                    ) : (
                        '–'
                    )}
                </div>
            </div>
        </TableColumn>
        <TableColumn width={ColumnWidth._136px}>
            {p.clearance.customerNr ? p.clearance.customerNr : '–'}
        </TableColumn>
        <TableColumn width={ColumnWidth._136px}>
            {Formatter.dayMonthYear(p.clearance.created)}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px}>
            {Formatter.dayMonthYear(p.clearance.validFrom)}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px}>
            {Formatter.dayMonthYear(p.clearance.validTo)}
        </TableColumn>
        <TableColumn width={ColumnWidth.variable}>
            {p.clearance.permitTypeName}
        </TableColumn>
        <TableColumn width={ColumnWidth._192px}>
            {thenElse(
                getLoginById(p.operatorLogins, p.clearance.operatorLoginId),
                l => getLoginTitle(l),
                filteredClearancesListTexts[p.language].onlineUser(),
            )}
        </TableColumn>
        <TableColumn width={ColumnWidth._24px}>
            <IconTopOffsetContainer>
                <Icon16 icon={chevronRight} />
            </IconTopOffsetContainer>
        </TableColumn>
    </TableRow>
);
