import { Input } from '../Input.tsx';
import { Icon24 } from '../icons/Icon.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { texts } from './SearchPlaceholder.tsx';
import { css } from '@emotion/css';
import { OperatorTypo } from '../OperatorTypo.ts';
import { ColorHex } from '../Colors.ts';
import { defaultTransition } from 'dg-web-shared/tb-ui/transitions.ts';
import React from 'react';

interface Props {
    searchValue: string;
    language: string;
    onChange: (value: string) => void;
}

export class SearchField extends React.Component<Props, { focused: boolean }> {
    declare refs: {
        input: HTMLElement;
    };

    constructor(props: Props) {
        super(props);
        this.state = { focused: true };
    }

    componentDidMount(): void {
        this.focusInputField();
    }

    renderActionIcon(): JSX.Element {
        function getActionIconStyle(hoverEffect: boolean) {
            return css({
                color: ColorHex.white,
                position: 'absolute',
                right: '-8px',
                top: '12px',
                padding: '4px',
                '&:hover': {
                    background: hoverEffect ? ColorHex.lightblue : undefined,
                },
            });
        }

        if (this.props.searchValue) {
            return (
                <Clickable
                    element="div"
                    className={getActionIconStyle(true)}
                    onClick={() => {
                        this.props.onChange('');
                    }}
                >
                    <Icon24 icon={Icons24.clear_m} />
                </Clickable>
            );
        } else {
            return (
                <div className={getActionIconStyle(false)}>
                    <Icon24 icon={Icons24.search_m} />
                </div>
            );
        }
    }

    render() {
        return (
            <div
                className={css({
                    background: ColorHex.darkblue,
                    height: '100%',
                    borderRight: `2px solid ${ColorHex.white}`,
                })}
            >
                <div
                    className={css({
                        position: 'relative',
                        marginLeft: '24px',
                        marginRight: '24px',
                        height: '100%',
                    })}
                >
                    <label
                        className={css({
                            height: '16px',
                            position: 'relative',
                            display: 'block',
                            cursor: 'default',
                            overflow: 'visible',
                        })}
                    >
                        <span
                            className={css({
                                display: 'block',
                                position: 'absolute',
                                cursor:
                                    this.state.focused ||
                                    !!this.props.searchValue
                                        ? 'default'
                                        : 'text',
                                ...defaultTransition('transform'),
                                color: ColorHex.white,
                                top: '7px',
                                ...OperatorTypo.label,
                            })}
                        >
                            {texts[this.props.language].Search()}
                        </span>
                    </label>
                    <div
                        className={css({
                            position: 'absolute',
                            left: 0,
                            top: '18px',
                            right: '56px',
                            color: ColorHex.white,
                        })}
                    >
                        <Input
                            className={css({
                                color: ColorHex.white,
                                height: '24px',
                                ...OperatorTypo.headingOne,
                                userSelect: 'text',
                                margin: 0,
                                padding: 0,
                                border: 'none',
                                outline: 'none',
                                display: 'block',
                                verticalAlign: 'baseline',
                                whiteSpace: 'normal',
                                background: 'none',
                                width: '100%',
                                lineHeight: 1,
                                '&::-ms-clear': {
                                    display: 'none',
                                },
                            })}
                            inputType="text"
                            maxChars={80}
                            autoCapitalize={undefined}
                            autoCorrect={undefined}
                            onBlur={(): void => {
                                /*noop*/
                            }}
                            onFocus={(): void => {
                                /*noop*/
                            }}
                            onChange={this.props.onChange}
                            value={this.props.searchValue}
                            tabIndex={1}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            ref={'input' as any}
                        />
                    </div>
                    <div
                        className={css({
                            height: '1px',
                            background: ColorHex.rgba(ColorHex.white, 0.25),
                            position: 'absolute',
                            bottom: '9px',
                            left: 0,
                            right: 0,
                        })}
                    />
                    {this.renderActionIcon()}
                </div>
            </div>
        );
    }

    private focusInputField(): void {
        // eslint-disable-next-line react/no-string-refs
        this.refs.input.focus();
    }
}
