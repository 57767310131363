import moment from 'moment';
import { Translations } from '../../common/i18n/Text.ts';
import { TomTexts } from '../components/TomsBody.tsx';
import {
    TomModelType,
    TomPaymentType,
    TomSynchronizationStatus,
    TomSyncReason,
} from 'dg-web-shared/model/Tom.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { CollectionTexts } from '../components/collection/CollectionsBody.tsx';
import { TomFilterTexts } from '../components/TomFilter.tsx';
import {
    TomDetailTexts,
    UpcomingChangesTexts,
} from '../components/TomDetailSlideIn.tsx';
import { OutOfServiceTexts } from '../components/out-of-service/OutOfServiceHalfSlideIn.tsx';
import { TomConfigurationChangeType } from 'dg-web-shared/model/TomConfiguration.ts';
import { TomSyncStatusTexts } from '../components/TomSyncStatus.tsx';
import { TomFirmwareStatusTexts } from '../components/TomFirmwareStatus.tsx';
import { TomErrorTexts } from '../components/TomError.tsx';

/* tslint:disable */

export const tomTexts: Translations<TomTexts> = {
    de: {
        Spaces: () => 'Parkfelder',
        AcceptedCoins: () => 'Akzeptierte Münzen',
        AcceptedTokens: () => 'Akzeptierte Jetons',
        Actions: () => 'Aktionen',
        Address: () => 'Adresse',
        Place: () => 'Ort',
        ZipCode: () => 'PLZ',
        CreationDate: () => 'Erstellung',
        FirmwareVersion: () => 'Firmware Version',
        InternalId: () => `ID`,
        OperatorExternalId: () => 'Nummer',
        InOperationSince: () => 'In Betrieb seit',
        LastCollection: () => 'Letzte Leerung',
        LastCollectionNever: () => 'Keine Leerung gefunden',
        Location: () => 'Ort',
        Name: () => 'Name',
        PaymentType: () => 'Zahlungstyp',
        CashboxType: () => 'Kassentyp',
        PaymentTransactionPeriod: () => 'Zeitlimite für Parkplatzbezahlung',
        Permissions: () => 'Gruppen',
        SlideInHeaderCaption: () => 'TOMeco',
        State: () => 'Status',
        StateMessages: () => 'Statusmeldungen',
        LastContact: () => 'Letzter Kontakt',
        LastCashBoxLevel: () => 'Letzter Kassenstand',
        LastBatteryVoltage: () => 'Letzte Batteriespannung',
        LastVoltage: () => 'Letzte Spannung',
        SyncStatus: () => 'Konfiguration',
        TabTitle: () => 'Parkuhren',
        CSVExport: () => 'CSV Export',
        Tom: () => 'TOM',
        WriteToStick: () => 'Auf Unterhaltsstick schreiben',
        Zone: () => 'Zone',
        SaveEdit: () => 'Änderungen speichern?',
        FirmwareStatus: () => 'Firmware',
        UpdateStick: () => 'Firmware aktualisieren',
        PayByPlateTimelimitNote: () => '(nach Einwurf der letzten Münze)',
        PayBySpaceTimelimitNote: () => '(nach Einwurf der ersten Münze)',
        PaymentTypeOptions: (status: TomPaymentType) => {
            switch (status) {
                case 'pay_by_space':
                    return 'Parkfeld';
                case 'pay_by_plate':
                    return 'Kennzeichen';
                default:
                    return '';
            }
        },
        CashboxTypeOptions: (status: TomModelType) => {
            switch (status) {
                case 'small':
                    return 'normal';
                case 'x':
                    return 'XL';
                default:
                    return '';
            }
        },
    },
    fr: {
        Spaces: () => 'Places',
        AcceptedCoins: () => 'Pièces acceptées',
        AcceptedTokens: () => 'Jetons acceptées',
        Actions: () => 'Actions',
        Address: () => 'Adresse',
        Place: () => 'Lieu',
        ZipCode: () => 'NPA',
        CreationDate: () => 'Date de création',
        FirmwareVersion: () => 'Version de firmware',
        InternalId: () => 'ID',
        OperatorExternalId: () => 'Numéro',
        InOperationSince: () => 'En service depuis',
        LastCollection: () => 'Dernière collecte',
        LastCollectionNever: () => 'Aucune collecte connue',
        Location: () => 'Lieu',
        Name: () => 'Nom',
        PaymentType: () => 'Type de payement',
        CashboxType: () => 'Type de caisse',
        PaymentTransactionPeriod: () =>
            'Durée limite pour le paiement du parking',
        Permissions: () => 'Groups',
        SlideInHeaderCaption: () => 'TOMeco',
        CSVExport: () => 'Exportation CSV',
        State: () => 'Etat',
        StateMessages: () => "Messages d'état",
        LastContact: () => 'Dernier contact',
        LastCashBoxLevel: () => 'Dernier solde caisse',
        LastBatteryVoltage: () => 'Dernière tension batterie',
        LastVoltage: () => 'Dernière tension',
        SyncStatus: () => 'Configuration',
        TabTitle: () => 'Horodateurs',
        Tom: () => 'TOM',
        WriteToStick: () => 'Ecrire sur la clé de maintenance',
        Zone: () => 'Zone',
        SaveEdit: () => 'Enregistrer les modifications?',
        FirmwareStatus: () => 'Firmware',
        UpdateStick: () => 'Mettre à jour firmware',
        PayByPlateTimelimitNote: () =>
            "(à partir de l'insertion de la dernière pièce)",
        PayBySpaceTimelimitNote: () =>
            "(à partir de l'insertion de la première pièce)",
        PaymentTypeOptions: (status: TomPaymentType) => {
            switch (status) {
                case 'pay_by_space':
                    return 'no de place';
                case 'pay_by_plate':
                    return 'immatriculation';
                default:
                    return '';
            }
        },
        CashboxTypeOptions: (status: TomModelType) => {
            switch (status) {
                case 'small':
                    return 'normale';
                case 'x':
                    return 'XL';
                default:
                    return '';
            }
        },
    },
    it: {
        Spaces: () => 'Posti',
        AcceptedCoins: () => 'Monete accettate',
        AcceptedTokens: () => 'Gettoni accettati',
        Actions: () => 'Azioni',
        Address: () => 'Indirizzo',
        Place: () => 'Luogo',
        ZipCode: () => 'NAP',
        CreationDate: () => 'Esecuzione',
        FirmwareVersion: () => 'Versione del firmware',
        InternalId: () => 'ID',
        OperatorExternalId: () => 'Numero',
        InOperationSince: () => 'In funzione da',
        LastCollection: () => 'Ultima vuotatura',
        LastCollectionNever: () => 'Nessuna vuotatura trovata',
        Location: () => 'Luogo',
        Name: () => 'Nome',
        PaymentType: () => 'Tipo pagamento',
        CashboxType: () => 'Tipo cassa',
        PaymentTransactionPeriod: () =>
            'Limite temporale per il pagamento del parcheggio',
        Permissions: () => 'Gruppi',
        SlideInHeaderCaption: () => 'TOMeco',
        CSVExport: () => 'Esportazione CSV',
        State: () => 'Stato',
        StateMessages: () => 'Messaggi di stato',
        LastContact: () => 'Ultimo contatto',
        LastCashBoxLevel: () => 'Ultimo saldo cassa',
        LastBatteryVoltage: () => 'Ultimo voltaggio batteria',
        LastVoltage: () => 'Ultimo voltaggio',
        SyncStatus: () => 'Configurazione',
        TabTitle: () => 'Parchimetri',
        Tom: () => 'TOM',
        WriteToStick: () => 'Scrivere sulla chiavetta di manutenzione',
        Zone: () => 'Zona',
        SaveEdit: () => 'Salvare le modifiche?',
        FirmwareStatus: () => 'Firmware',
        UpdateStick: () => 'Aggiorna firmware',
        PayByPlateTimelimitNote: () =>
            "(dopo l'inserimento dell’ultima moneta)",
        PayBySpaceTimelimitNote: () =>
            "(dopo l'inserimento della prima moneta)",
        PaymentTypeOptions: (status: TomPaymentType) => {
            switch (status) {
                case 'pay_by_space':
                    return 'nr. posto';
                case 'pay_by_plate':
                    return 'targa';
                default:
                    return '';
            }
        },
        CashboxTypeOptions: (status: TomModelType) => {
            switch (status) {
                case 'small':
                    return 'normale';
                case 'x':
                    return 'XL';
                default:
                    return '';
            }
        },
    },
};

export const tomDetailTexts: Translations<TomDetailTexts> = {
    de: {
        PlanSuspension: () => 'Ausserbetriebsetzung planen',
        DuplicateExternalId: () => 'Bezeichnung bereits in Verwendung',
    },
    fr: {
        PlanSuspension: () => 'Planifier la mise hors service',
        DuplicateExternalId: () => 'Désignation déjà utilisée',
    },
    it: {
        PlanSuspension: () => 'Pianificare sospensione servizio',
        DuplicateExternalId: () => 'Designazione già in uso',
    },
};

export const outOfServiceTexts: Translations<OutOfServiceTexts> = {
    de: {
        CustomMessageToggle: () => 'Benutzerdefinierte Ausserbetriebsnachricht',
        EndDate: () => 'Enddatum',
        MessageBody: () => 'Nachricht Text (3 Zeilen)',
        MessageBodyConstraints: () =>
            'Darf maximal 3 Zeilen zu je 24 Zeichen lang sein',
        MessageDateConstraints: () =>
            'Start- und Enddatum müssen ausgewählt werden und nacheinander sein',
        MessageTitle: () => 'Nachricht Titel (2 Zeilen)',
        MessageTitleConstraints: () =>
            'Darf maximal 2 Zeilen zu je 15 Zeichen lang sein',
        OutOfService: () => 'Ausser Betrieb',
        OutOfServiceBodyDefault: () => '',
        OutOfServiceDateSlideInTitle: () => 'Ausserbetriebsetzung Daten',
        OutOfServiceMessagePreview: () => 'Vorschau: Anzeige auf Parkuhr',
        OutOfServiceTitleDefault: () => 'Ausser Betrieb',
        PlanOutOfService: () => 'Ausserbetriebsetzung planen',
        StartDate: () => 'Startdatum',
    },
    fr: {
        CustomMessageToggle: () => "Message 'hors service' personnalisé",
        EndDate: () => 'Date de fin',
        MessageBody: () => 'Texte du message (3 lignes)',
        MessageBodyConstraints: () =>
            'Maximum 3 lignes de 24 caractères chacune',
        MessageDateConstraints: () =>
            'Les dates de début et de fin doivent être sélectionnées et consécutives',
        MessageTitle: () => 'Titre du message (2 lignes)',
        MessageTitleConstraints: () =>
            'Maximum 2 lignes de 15 caractères chacune',
        OutOfService: () => 'Hors service',
        OutOfServiceBodyDefault: () => '',
        OutOfServiceDateSlideInTitle: () => 'Dates de mise hors service',
        OutOfServiceMessagePreview: () => "Aperçu: affichage sur l'horodateur",
        OutOfServiceTitleDefault: () => 'Hors service',
        PlanOutOfService: () => 'Planifier la mise hors service',
        StartDate: () => 'Date de début',
    },
    it: {
        CustomMessageToggle: () =>
            "Messaggio di macchina fuori servizio definito dall'utente",
        EndDate: () => 'Data finale',
        MessageBody: () => 'Messaggio di testo (3 righe)',
        MessageBodyConstraints: () =>
            "Il messaggio può essere di max. 3 righe con 24 caratteri l'una",
        MessageDateConstraints: () =>
            'La data iniziale e finale devono essere selezionate come consecutive',
        MessageTitle: () => 'Messaggio di testo (2 righe)',
        MessageTitleConstraints: () =>
            "Il messaggio può essere di max. 2 righe con 15 caratteri l'una",
        OutOfService: () => 'Fuori servizio',
        OutOfServiceBodyDefault: () => '',
        OutOfServiceDateSlideInTitle: () => 'Date sospensione servizio',
        OutOfServiceMessagePreview: () =>
            'Anteprima: visualizzazione parchimetro',
        OutOfServiceTitleDefault: () => 'Fuori servizio',
        PlanOutOfService: () => 'Pianificare sospensione servizio',
        StartDate: () => 'Data iniziale',
    },
};

export const collectionTexts: Translations<CollectionTexts> = {
    de: {
        StartCollection: () => 'Leerungsdaten auslesen',
        TabTitle: () => 'Leerungen',
        MenuTitle: () => 'Aktionen',
        Export: () => 'CSV Export',
        Pdf: () => 'PDF Export',
    },
    fr: {
        StartCollection: () => 'Lecture des données de collecte',
        TabTitle: () => 'Collectes',
        MenuTitle: () => 'Actions',
        Export: () => 'Exporter CSV',
        Pdf: () => 'Exporter PDF',
    },
    it: {
        StartCollection: () => 'Lettura dati vuotature',
        TabTitle: () => 'Vuotature',
        MenuTitle: () => 'Azioni',
        Export: () => 'Esporta CSV',
        Pdf: () => 'Esporta PDF',
    },
};

export const tomFilterTexts: Translations<TomFilterTexts> = {
    de: {
        filterTitle: () => 'Parkuhren',
        dismantled: () => 'DEMONTIERT',
    },
    fr: {
        filterTitle: () => 'Horodateurs',
        dismantled: () => 'DÉMONTÉ',
    },
    it: {
        filterTitle: () => 'Parchimetri',
        dismantled: () => 'SMONTATA',
    },
};

export const tomUpcomingChangesTexts: Translations<UpcomingChangesTexts> = {
    de: {
        ChangeType: () => 'Änderungstyp',
        ChangeTypeBackInService: () => 'Inbetriebnahme',
        ChangeTypeCoins: () => 'Änderung der akzeptierten Münzen',
        ChangeTypeDuplicate: () => 'Duplizierung',
        ChangeTypeOther: () => 'Konfigurationsänderung',
        ChangeTypeOutOfService: () => 'Ausserbetriebssetzung',
        ChangeTypeParameter: () => 'Parameteränderung',
        ChangeTypePermissions: () => 'Gruppensänderung',
        ChangeTypeSpaces: () => 'Parkfeldänderung',
        ChangeTypeValue: (type: TomConfigurationChangeType) => {
            const lng = 'de';
            switch (type) {
                case 'SPACES':
                    return tomUpcomingChangesTexts[lng].ChangeTypeSpaces();
                case 'ZONE':
                    return tomUpcomingChangesTexts[lng].ChangeTypeZone();
                case 'OUT_OF_SERVICE':
                    return tomUpcomingChangesTexts[
                        lng
                    ].ChangeTypeOutOfService();
                case 'BACK_IN_SERVICE':
                    return tomUpcomingChangesTexts[
                        lng
                    ].ChangeTypeBackInService();
                case 'PARAMETER':
                    return tomUpcomingChangesTexts[lng].ChangeTypeParameter();
                case 'COINS':
                    return tomUpcomingChangesTexts[lng].ChangeTypeCoins();
                case 'PERMISSIONS':
                    return tomUpcomingChangesTexts[lng].ChangeTypePermissions();
                case 'DUPLICATE':
                    return tomUpcomingChangesTexts[lng].ChangeTypeDuplicate();
                case 'OTHER':
                default:
                    return tomUpcomingChangesTexts[lng].ChangeTypeOther();
            }
        },
        ChangeTypeZone: () => 'Zonenwechsel',
        NoUpcomingChanges: () => 'Keine bevorstehenden Änderungen',
        UpcomingChanges: () => 'Geplante Änderungen',
    },
    fr: {
        ChangeType: () => 'Type de modification',
        ChangeTypeBackInService: () => 'Mise en service',
        ChangeTypeCoins: () => 'Modification des pièces acceptées',
        ChangeTypeDuplicate: () => 'Duplication',
        ChangeTypeOther: () => 'Changement de configuration',
        ChangeTypeOutOfService: () => 'Mise hors service',
        ChangeTypeParameter: () => 'Modification de paramètres',
        ChangeTypePermissions: () => 'Modification de groups',
        ChangeTypeSpaces: () => 'Modification des places de stationnement',
        ChangeTypeValue: (type: TomConfigurationChangeType) => {
            const lng = 'fr';
            switch (type) {
                case 'SPACES':
                    return tomUpcomingChangesTexts[lng].ChangeTypeSpaces();
                case 'ZONE':
                    return tomUpcomingChangesTexts[lng].ChangeTypeZone();
                case 'OUT_OF_SERVICE':
                    return tomUpcomingChangesTexts[
                        lng
                    ].ChangeTypeOutOfService();
                case 'BACK_IN_SERVICE':
                    return tomUpcomingChangesTexts[
                        lng
                    ].ChangeTypeBackInService();
                case 'PARAMETER':
                    return tomUpcomingChangesTexts[lng].ChangeTypeParameter();
                case 'COINS':
                    return tomUpcomingChangesTexts[lng].ChangeTypeCoins();
                case 'PERMISSIONS':
                    return tomUpcomingChangesTexts[lng].ChangeTypePermissions();
                case 'DUPLICATE':
                    return tomUpcomingChangesTexts[lng].ChangeTypeDuplicate();
                case 'OTHER':
                default:
                    return tomUpcomingChangesTexts[lng].ChangeTypeOther();
            }
        },
        ChangeTypeZone: () => 'Changement de zone',
        NoUpcomingChanges: () => 'Pas de changements en vue',
        UpcomingChanges: () => 'Changements planifiés',
    },
    it: {
        ChangeType: () => 'Tipo di modifica',
        ChangeTypeBackInService: () => 'Messa in funzione',
        ChangeTypeCoins: () => 'Modifica delle monete accettate',
        ChangeTypeDuplicate: () => 'Duplicazione',
        ChangeTypeOther: () => 'Modifica di configurazione',
        ChangeTypeOutOfService: () => 'Sospensione del servizio',
        ChangeTypeParameter: () => 'Modifica parametro',
        ChangeTypePermissions: () => 'Modifica gruppi',
        ChangeTypeSpaces: () => 'Modifica posto auto',
        ChangeTypeValue: (type: TomConfigurationChangeType) => {
            const lng = 'it';
            switch (type) {
                case 'SPACES':
                    return tomUpcomingChangesTexts[lng].ChangeTypeSpaces();
                case 'ZONE':
                    return tomUpcomingChangesTexts[lng].ChangeTypeZone();
                case 'OUT_OF_SERVICE':
                    return tomUpcomingChangesTexts[
                        lng
                    ].ChangeTypeOutOfService();
                case 'BACK_IN_SERVICE':
                    return tomUpcomingChangesTexts[
                        lng
                    ].ChangeTypeBackInService();
                case 'PARAMETER':
                    return tomUpcomingChangesTexts[lng].ChangeTypeParameter();
                case 'COINS':
                    return tomUpcomingChangesTexts[lng].ChangeTypeCoins();
                case 'PERMISSIONS':
                    return tomUpcomingChangesTexts[lng].ChangeTypePermissions();
                case 'DUPLICATE':
                    return tomUpcomingChangesTexts[lng].ChangeTypeDuplicate();
                case 'OTHER':
                default:
                    return tomUpcomingChangesTexts[lng].ChangeTypeOther();
            }
        },
        ChangeTypeZone: () => 'Cambio di zona',
        NoUpcomingChanges: () => 'Nessuna modifica imminente',
        UpcomingChanges: () => 'Modifiche pianificate',
    },
};

export const tomFirmwareStatusTexts: Translations<TomFirmwareStatusTexts> = {
    de: {
        InSync: () => 'aktuell',
        UpdateRequired: () => 'nicht aktuell',
    },
    fr: {
        InSync: () => 'actuel',
        UpdateRequired: () => 'pas actuel',
    },
    it: {
        InSync: () => 'aggiornato',
        UpdateRequired: () => 'non aggiornato',
    },
};

export const tomErrorTexts: Translations<TomErrorTexts> = {
    de: {
        Error: () => 'Fehler',
        Alarm: () => 'Alarm',
        Troubleshooting: () => 'Fehlerbehebung',
    },
    fr: {
        Error: () => 'Erreur',
        Alarm: () => 'Alarme',
        Troubleshooting: () => 'Résolution du problème',
    },
    it: {
        Error: () => 'Errore',
        Alarm: () => 'Allarme',
        Troubleshooting: () => 'Risoluzione problema',
    },
};

export const tomSyncStatusTexts: Translations<TomSyncStatusTexts> = {
    de: {
        ConfigurationExpiryValue: (expiry: moment.Moment) =>
            `Aktualisierung notwendig vor dem ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'de',
            )}`,
        CurrentSynchronizationStatus: () => 'Konfiguration',
        Error: () => ' Synchronisationsfehler',
        InSync: () => ' aktuell',
        NeedsUpdate: () => ' nicht aktuell',
        NewConfiguration: () => 'neue Konfiguration verfügbar',
        NextUpdateNecessary: () => 'Nächste Aktualisierung notwendig',
        NotAllConfigsOnTom: () =>
            'nicht alle Konfigurationen wurden auf den TOM geladen',
        NoUpdateNeeded: () => 'Keine Synchronisation notwendig',
        OutOfHolidays: () => 'Feiertagskalender leer',
        OutOfSpecialDays: () => 'Spezialtage aufgebraucht',
        Reason: () => 'Grund:',
        SynchronizationStatus: (status: TomSynchronizationStatus) => {
            switch (status) {
                case 'needs_update':
                    return tomSyncStatusTexts.de.NeedsUpdate();
                case 'updating':
                    return tomSyncStatusTexts.de.Updating();
                case 'error':
                    return tomSyncStatusTexts.de.Error();
                case 'in_sync':
                default:
                    return tomSyncStatusTexts.de.InSync();
            }
        },
        SyncReason: () => 'Synchronisationsgrund',
        SyncReasonNewTomConfig: () => 'Neue TOM Konfiguration',
        SyncReasonNewTomConfigUntil: (expiry: moment.Moment) =>
            `Neue TOM Konfiguration gültig ab ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'de',
            )}.`,
        SyncReasonNewZoneConfig: () => 'Neue Zonenkonfiguration',
        SyncReasonNewZoneConfigUntil: (expiry: moment.Moment) =>
            `Neue Zonenkonfiguration gültig ab ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'de',
            )}.`,
        SyncReasonNotAllConfigsSynced: () =>
            'Nicht alle Konfigurationen synchronisiert',
        SyncReasonNotAllConfigsSyncedUntil: (expiry: moment.Moment) =>
            `Letzte synchronisierte Konfiguration läuft am ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'de',
            )} aus.`,
        SyncReasonOutOfHolidays: () => 'Feiertage aufgebraucht',
        SyncReasonOutOfHolidaysUntil: (expiry: moment.Moment) =>
            `Ferientage sind am ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'de',
            )} aufgebraucht.`,
        SyncReasonOutOfSpecialDays: () => 'Spezialtage aufgebraucht',
        SyncReasonOutOfSpecialDaysUntil: (expiry: moment.Moment) =>
            `Spezialtage sind am ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'de',
            )} aufgebraucht.`,
        SyncReasonUntil: (reason: TomSyncReason, expiry: moment.Moment) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.de.SyncReasonNewTomConfigUntil(
                        expiry,
                    );
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.de.SyncReasonNewZoneConfigUntil(
                        expiry,
                    );
                case 'out_of_special_days':
                    return tomSyncStatusTexts.de.SyncReasonOutOfSpecialDaysUntil(
                        expiry,
                    );
                case 'out_of_holidays':
                    return tomSyncStatusTexts.de.SyncReasonOutOfHolidaysUntil(
                        expiry,
                    );
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.de.SyncReasonNotAllConfigsSyncedUntil(
                        expiry,
                    );
            }
        },
        SyncReasonValue: (reason: TomSyncReason) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.de.SyncReasonNewTomConfig();
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.de.SyncReasonNewZoneConfig();
                case 'out_of_special_days':
                    return tomSyncStatusTexts.de.SyncReasonOutOfSpecialDays();
                case 'out_of_holidays':
                    return tomSyncStatusTexts.de.SyncReasonOutOfHolidays();
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.de.SyncReasonNotAllConfigsSynced();
            }
        },
        SyncUntil: (expiryDate: moment.Moment) =>
            `Synchronisation notwendig bis am ${Date.Formatter.dateWithDurationFromNow(
                expiryDate,
                'de',
            )}`,
        UpdateBefore: (expiry: moment.Moment) =>
            `vor dem ${Date.Formatter.dateWithDurationFromNow(expiry, 'de')}`,
        Updating: () => ' Aktualisierung in Bearbeitung',
    },
    en: {
        ConfigurationExpiryValue: (expiry: moment.Moment) =>
            `Update before ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'en',
            )}`,
        CurrentSynchronizationStatus: () => 'Synchronization state',
        Error: () => 'Synchronization error',
        InSync: () => 'In sync',
        NeedsUpdate: () => 'Needs update',
        NewConfiguration: () => 'a new configuration has been released',
        NextUpdateNecessary: () => 'Next Update needed',
        NotAllConfigsOnTom: () => 'not all configs were synced to TOM',
        NoUpdateNeeded: () => 'No Update necessary',
        OutOfHolidays: () => 'out of holidays',
        OutOfSpecialDays: () => 'out of special days',
        Reason: () => 'Reason:',
        SynchronizationStatus: (status: TomSynchronizationStatus) => {
            switch (status) {
                case 'needs_update':
                    return tomSyncStatusTexts.en.NeedsUpdate();
                case 'updating':
                    return tomSyncStatusTexts.en.Updating();
                case 'error':
                    return tomSyncStatusTexts.en.Error();
                case 'in_sync':
                default:
                    return tomSyncStatusTexts.en.InSync();
            }
        },
        SyncReason: () => 'Synchronization Reason',
        SyncReasonNewTomConfig: () => 'New TOM configuration',
        SyncReasonNewTomConfigUntil: (expiry: moment.Moment) =>
            `New TOM configuration valid from ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'en',
            )}.`,
        SyncReasonNewZoneConfig: () => 'New zone configuration',
        SyncReasonNewZoneConfigUntil: (expiry: moment.Moment) =>
            `New Zone configuration valid from ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'en',
            )}.`,
        SyncReasonNotAllConfigsSynced: () =>
            'not all configurations synchronized',
        SyncReasonNotAllConfigsSyncedUntil: (expiry: moment.Moment) =>
            `Last synced config will expire on ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'en',
            )}.`,
        SyncReasonOutOfHolidays: () => 'running out of holidays',
        SyncReasonOutOfHolidaysUntil: (expiry: moment.Moment) =>
            `Will run out of holidays on ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'en',
            )}.`,
        SyncReasonOutOfSpecialDays: () => 'running out of special days',
        SyncReasonOutOfSpecialDaysUntil: (expiry: moment.Moment) =>
            `Will run out of special days on ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'en',
            )}.`,
        SyncReasonUntil: (reason: TomSyncReason, expiry: moment.Moment) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.en.SyncReasonNewTomConfigUntil(
                        expiry,
                    );
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.en.SyncReasonNewZoneConfigUntil(
                        expiry,
                    );
                case 'out_of_special_days':
                    return tomSyncStatusTexts.en.SyncReasonOutOfSpecialDaysUntil(
                        expiry,
                    );
                case 'out_of_holidays':
                    return tomSyncStatusTexts.en.SyncReasonOutOfHolidaysUntil(
                        expiry,
                    );
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.en.SyncReasonNotAllConfigsSyncedUntil(
                        expiry,
                    );
            }
        },
        SyncReasonValue: (reason: TomSyncReason) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.en.SyncReasonNewTomConfig();
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.en.SyncReasonNewZoneConfig();
                case 'out_of_special_days':
                    return tomSyncStatusTexts.en.SyncReasonOutOfSpecialDays();
                case 'out_of_holidays':
                    return tomSyncStatusTexts.en.SyncReasonOutOfHolidays();
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.en.SyncReasonNotAllConfigsSynced();
            }
        },
        SyncUntil: (expiryDate: moment.Moment) =>
            `Synchronization needed until ${Date.Formatter.dateWithDurationFromNow(
                expiryDate,
                'en',
            )}`,
        UpdateBefore: (expiry: moment.Moment) =>
            `before ${Date.Formatter.dateWithDurationFromNow(expiry, 'en')}`,
        Updating: () => 'Update in progress',
    },
    fr: {
        ConfigurationExpiryValue: (expiry: moment.Moment) =>
            `Actualisation nécessaire avant le ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'fr',
            )}`,
        CurrentSynchronizationStatus: () => 'Configuration',
        Error: () => ' erreur',
        InSync: () => ' actuelle',
        NeedsUpdate: () => ' pas actuelle',
        NewConfiguration: () => 'nouvelle configuration disponible',
        NextUpdateNecessary: () => 'Prochaine actualisation nécessaire',
        NotAllConfigsOnTom: () =>
            "toutes les configurations n'ont pas été chargées sur le TOM",
        NoUpdateNeeded: () => 'aucune synchronisation requise',
        OutOfHolidays: () => 'Calendrier des jours fériés vide',
        OutOfSpecialDays: () => 'Jours spéciaux épuisés',
        Reason: () => 'Motif:',
        SynchronizationStatus: (status: TomSynchronizationStatus) => {
            switch (status) {
                case 'needs_update':
                    return tomSyncStatusTexts.fr.NeedsUpdate();
                case 'updating':
                    return tomSyncStatusTexts.fr.Updating();
                case 'error':
                    return tomSyncStatusTexts.fr.Error();
                case 'in_sync':
                default:
                    return tomSyncStatusTexts.fr.InSync();
            }
        },
        SyncReason: () => 'Motif de la synchronisation',
        SyncReasonNewTomConfig: () => 'Nouvelle configuration TOM',
        SyncReasonNewTomConfigUntil: (expiry: moment.Moment) =>
            `Nouvelle configuration TOM valable à partir du ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'fr',
            )}.`,
        SyncReasonNewZoneConfig: () => 'Nouvelle configuration de zones',
        SyncReasonNewZoneConfigUntil: (expiry: moment.Moment) =>
            `Nouvelle configuration de zones valable à partir du ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'fr',
            )}.`,
        SyncReasonNotAllConfigsSynced: () =>
            'Toutes les configurations ne sont pas synchronisées',
        SyncReasonNotAllConfigsSyncedUntil: (expiry: moment.Moment) =>
            `La dernière configuration synchronisée arrive à expiration le ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'fr',
            )}.`,
        SyncReasonOutOfHolidays: () => 'Jours fériés épuisés',
        SyncReasonOutOfHolidaysUntil: (expiry: moment.Moment) =>
            `Les jours fériés seront épuisés le ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'fr',
            )}.`,
        SyncReasonOutOfSpecialDays: () => 'Jours spéciaux épuisés',
        SyncReasonOutOfSpecialDaysUntil: (expiry: moment.Moment) =>
            `Les jours spéciaux seront épuisés le ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'fr',
            )}.`,
        SyncReasonUntil: (reason: TomSyncReason, expiry: moment.Moment) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.fr.SyncReasonNewTomConfigUntil(
                        expiry,
                    );
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.fr.SyncReasonNewZoneConfigUntil(
                        expiry,
                    );
                case 'out_of_special_days':
                    return tomSyncStatusTexts.fr.SyncReasonOutOfSpecialDaysUntil(
                        expiry,
                    );
                case 'out_of_holidays':
                    return tomSyncStatusTexts.fr.SyncReasonOutOfHolidaysUntil(
                        expiry,
                    );
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.fr.SyncReasonNotAllConfigsSyncedUntil(
                        expiry,
                    );
            }
        },
        SyncReasonValue: (reason: TomSyncReason) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.fr.SyncReasonNewTomConfig();
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.fr.SyncReasonNewZoneConfig();
                case 'out_of_special_days':
                    return tomSyncStatusTexts.fr.SyncReasonOutOfSpecialDays();
                case 'out_of_holidays':
                    return tomSyncStatusTexts.fr.SyncReasonOutOfHolidays();
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.fr.SyncReasonNotAllConfigsSynced();
            }
        },
        SyncUntil: (expiryDate: moment.Moment) =>
            `Synchronisation requise avant le ${Date.Formatter.dateWithDurationFromNow(
                expiryDate,
                'fr',
            )}`,
        UpdateBefore: (expiry: moment.Moment) =>
            `avant le ${Date.Formatter.dateWithDurationFromNow(expiry, 'fr')}`,
        Updating: () => ' actualisation en cours',
    },
    it: {
        ConfigurationExpiryValue: (expiry: moment.Moment) =>
            `Aggiornamento necessario prima del giorno ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'it',
            )}`,
        CurrentSynchronizationStatus: () => 'Configurazione',
        Error: () => ' errore',
        InSync: () => ' aggiornata',
        NeedsUpdate: () => ' non aggiornata',
        NewConfiguration: () => 'Disponibile nuova configurazione',
        NextUpdateNecessary: () => 'Prossimo aggiornamento necessario',
        NotAllConfigsOnTom: () =>
            'Non tutte le configurazioni sono state caricate sul TOM',
        NoUpdateNeeded: () => 'Non è necessaria alcuna sincronizzazione',
        OutOfHolidays: () => 'Il calendario dei giorni festivi è vuoto',
        OutOfSpecialDays: () => 'Giorni speciali esauriti',
        Reason: () => 'Causa:',
        SynchronizationStatus: (status: TomSynchronizationStatus) => {
            switch (status) {
                case 'needs_update':
                    return tomSyncStatusTexts.it.NeedsUpdate();
                case 'updating':
                    return tomSyncStatusTexts.it.Updating();
                case 'error':
                    return tomSyncStatusTexts.it.Error();
                case 'in_sync':
                default:
                    return tomSyncStatusTexts.it.InSync();
            }
        },
        SyncReason: () => 'Causa della sincronizzazione',
        SyncReasonNewTomConfig: () => 'Nuova configurazione TOM',
        SyncReasonNewTomConfigUntil: (expiry: moment.Moment) =>
            `Nuova configurazione TOM valida dal ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'it',
            )}.`,
        SyncReasonNewZoneConfig: () => 'Nuova configurazione di zona',
        SyncReasonNewZoneConfigUntil: (expiry: moment.Moment) =>
            `Nuove configurazioni di zona a partire dal ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'it',
            )}.`,
        SyncReasonNotAllConfigsSynced: () =>
            'Non tutte le configurazioni sono state sincronizzate',
        SyncReasonNotAllConfigsSyncedUntil: (expiry: moment.Moment) =>
            `L'ultima configurazione sincronizzata scadrà il ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'it',
            )}.`,
        SyncReasonOutOfHolidays: () => 'Giorni festivi esauriti',
        SyncReasonOutOfHolidaysUntil: (expiry: moment.Moment) =>
            `I giorni festivi saranno esauriti il ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'it',
            )}.`,
        SyncReasonOutOfSpecialDays: () => 'Giorni speciali esauriti',
        SyncReasonOutOfSpecialDaysUntil: (expiry: moment.Moment) =>
            `I giorni speciali saranno esauriti il ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'it',
            )}.`,
        SyncReasonUntil: (reason: TomSyncReason, expiry: moment.Moment) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.it.SyncReasonNewTomConfigUntil(
                        expiry,
                    );
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.it.SyncReasonNewZoneConfigUntil(
                        expiry,
                    );
                case 'out_of_special_days':
                    return tomSyncStatusTexts.it.SyncReasonOutOfSpecialDaysUntil(
                        expiry,
                    );
                case 'out_of_holidays':
                    return tomSyncStatusTexts.it.SyncReasonOutOfHolidaysUntil(
                        expiry,
                    );
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.it.SyncReasonNotAllConfigsSyncedUntil(
                        expiry,
                    );
            }
        },
        SyncReasonValue: (reason: TomSyncReason) => {
            switch (reason) {
                case 'new_tom_configuration':
                    return tomSyncStatusTexts.it.SyncReasonNewTomConfig();
                case 'new_zone_configuration':
                    return tomSyncStatusTexts.it.SyncReasonNewZoneConfig();
                case 'out_of_special_days':
                    return tomSyncStatusTexts.it.SyncReasonOutOfSpecialDays();
                case 'out_of_holidays':
                    return tomSyncStatusTexts.it.SyncReasonOutOfHolidays();
                case 'not_all_configs_synced':
                default:
                    return tomSyncStatusTexts.it.SyncReasonNotAllConfigsSynced();
            }
        },
        SyncUntil: (expiryDate: moment.Moment) =>
            `Sincronizzazione necessaria fino al ${Date.Formatter.dateWithDurationFromNow(
                expiryDate,
                'it',
            )}`,
        UpdateBefore: (expiry: moment.Moment) =>
            `prima del giorno ${Date.Formatter.dateWithDurationFromNow(
                expiry,
                'it',
            )}`,
        Updating: () => ' aggiornamento in corso',
    },
};

/* tslint:enable */
