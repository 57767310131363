import moment from 'moment';
import { getOrElse, Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import { Validators } from 'dg-web-shared/lib/String.ts';
import * as Http from '../../api/Http.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';

const sn = ElementNamer('customer-Clearance-CustomerClearanceState');

export interface CustomerClearance {
    id: number | null;
    contractId: number;
    customerNr: number;
    adminId: number;
    validFrom: moment.Moment;
    validTo: moment.Moment;
    remark1: Maybe<string>;
    remark2: Maybe<string>;
    created: moment.Moment;
    permitTypeName: string;
}

export const remark = (c: CustomerClearance) =>
    getOrElse(c.remark1, '') + thenElse(c.remark2, r2 => ' ' + r2, '');

export namespace List {
    export type State = ServerStateSlice.ServerState<CustomerClearance[]>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parseCustomerClearance = (t: any): CustomerClearance =>
        Object.assign(t, {
            validFrom: Parser.isoToMoment(t.validFrom),
            validTo: Parser.isoToMoment(t.validTo),
            created: Parser.isoToMoment(t.created),
        });

    export const { get, setResponse, reset, forceRefetch } =
        ServerStateSlice.generateServerState<CustomerClearance[]>(
            sn('List'),
            () => [],
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(fetchCustomerClearances);
                }
            },
            (body: CustomerClearance[]): CustomerClearance[] =>
                body.map(parseCustomerClearance),
        );

    const fetchCustomerClearances = AsyncRequest.request(
        Http.OperatorAccount.CustomerClearance.list,
        setResponse,
    );
}

export namespace Add {
    export interface State {
        customerNr?: Maybe<string>;
        remark?: Maybe<string>;
        permitTypeIds?: number[];

        permitTypeSelectionOpen?: boolean;
        createCustomerClearanceOpen?: boolean;
        confirmPressedWhileError?: Maybe<boolean>;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('Add'),
        {
            createCustomerClearanceOpen: false,
            confirmPressedWhileError: false,
            permitTypeIds: [],
        },
    );

    export const isCustomerNrValid = (s: State) =>
        !!s.customerNr &&
        s.customerNr.length > 0 &&
        Validators.isNumber(s.customerNr);

    export const isPermitTypeIdsValid = (s: State) =>
        !!s.permitTypeIds && s.permitTypeIds.length > 0;

    export const isValid = (s: State) =>
        isCustomerNrValid(s) && isPermitTypeIdsValid(s);
}

export namespace AddResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate<void>(
        sn('AddResponse'),
        () => null,
    );
}

export namespace AddErrors {
    interface Errors {
        overlaps: Maybe<CustomerClearance[]>;
        invalidCustomerNr: Maybe<boolean>;
    }

    export interface State {
        errors: Errors | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('AddErrors'),
        {
            errors: null,
        },
    );

    export const customerNrInvalid = (s: State) =>
        !!s.errors && s.errors.invalidCustomerNr;

    export const hasOverlaps = (s: State) =>
        !!s.errors && !!s.errors.overlaps && s.errors.overlaps.length > 0;
}

export namespace Detail {
    export interface State {
        selectedClearance?: Maybe<CustomerClearance>;
        confirmDeleteVisible?: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('Detail'),
        {},
    );
}

export namespace DeleteResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate<void>(
        sn('DeleteResponse'),
        () => null,
    );
}
