import {
    HalfSlideIn,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { tariffTexts } from '../i18n/TariffTexts.ts';
import * as TariffState from '../state/TariffState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { TariffCalculator } from './TariffCalculator.tsx';
import { TariffData } from 'dg-web-shared/model/Tariff.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';

import { TariffPreview } from './TariffPreview.tsx';
import { css } from '@emotion/css';
import { OperatorTypo } from '../../ui/OperatorTypo.ts';
import { getHolidayByKey } from 'taxomex-shared/tariff/logic/Holidays.ts';

interface State {
    settings: SettingsState.State;
    layout: TariffState.Layout.State;
    tariffs: TariffState.List.State;
}

export class TariffDetailSlideIn extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: TariffState.Layout.get(this.props.allState),
            tariffs: TariffState.List.get(this.props.allState),
        };
    }

    render() {
        const lng = this.state.settings.language;
        const txt = tariffTexts[lng];
        const loaded =
            this.state.tariffs.data && this.state.tariffs.data.length > 0;
        const selectedTariff = this.state.tariffs.data.filter(
            t => t.tariffId === this.state.layout.selectedTariffId,
        );
        if (loaded && selectedTariff.length) {
            const tariff = selectedTariff[0];

            return (
                <HalfSlideIn open={true}>
                    <SlideInBody>
                        <TariffPreview tariff={tariff} language={lng} />
                        <LabeledText label={txt.InheritMode()}>
                            {txt.InheritModeValue(
                                tariff.tariffDefinition.inheritMode,
                            )}
                        </LabeledText>
                        <LabeledText label={txt.Holidays()}>
                            <HolidayDetail tariff={tariff} language={lng} />
                        </LabeledText>

                        <TariffCalculator
                            tariffId={tariff.tariffId}
                            allState={this.props.allState}
                        />
                    </SlideInBody>
                    <StandardFirstLevelHeader
                        onClose={() =>
                            this.update(store =>
                                TariffState.Layout.stateWrite(store, {
                                    selectedTariffId: null,
                                }),
                            )
                        }
                    >
                        <SlideInHeaderTexts
                            title={`${txt.Tariff()} - ${tariff.name}`}
                            hasLeftIcon={false}
                        />
                    </StandardFirstLevelHeader>
                </HalfSlideIn>
            );
        } else {
            return <HalfSlideIn open={false} />;
        }
    }
}

interface HolidayDetailProps {
    tariff: TariffData;
    language: string;
}

function HolidayDetail(props: HolidayDetailProps): JSX.Element {
    const txt = tariffTexts[props.language];
    const holidays = props.tariff.tariffDefinition.holidays;

    if (holidays.length === 0) {
        return <div>{txt.NoHolidays()}</div>;
    }

    return (
        <div>
            {holidays.map((h, index) => {
                const dayProfileName = getDayProfileNameById(
                    props.tariff,
                    h.dayIdRef,
                );
                const holidayText = getHolidayByKey(h.holidayKey).text(
                    props.language,
                );
                return (
                    <div key={index}>
                        {holidayText}
                        {dayProfileName && (
                            <span
                                className={css({
                                    ...OperatorTypo.body,
                                    marginLeft: '3px',
                                })}
                            >
                                ({dayProfileName})
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

const getDayProfileNameById = (
    tariff: TariffData,
    dayProfileId?: Maybe<number>,
): string | undefined => {
    for (const d of tariff.tariffDefinition.days) {
        if (d.dayProfileId === dayProfileId) {
            return d.name;
        }
    }
};
