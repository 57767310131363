import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import { operatorAppApiUrl } from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as ParkingsState from './ParkingsState.ts';
import * as MasterDataZonesState from './MasterDataZonesState.ts';
import * as PermitTypeState from './PermitTypeState.ts';
import * as TariffState from '../../tariffs/state/TariffState.ts';
import { datadogRum } from '@datadog/browser-rum';
import { FcmToken } from '../../app/components/Native.tsx';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage.ts';
import { Items } from 'dg-web-shared/lib/LocalStorage.ts';
import { v1 as uuidv1 } from 'uuid';
import superagent from 'superagent';

export interface CurrentOperatorLogin {
    loginId: number;
    username: string;
    mandantId: number;
    mandantName: string;
    surname: string;
    lastname: string;
    email: string;
    permissions: Permissions;
    isAdmin: boolean;
    softAnonymizeCheckinPublicPermitAfterDays: number;
    maxSoftAnonymizeCheckinPublicPermitAfterDays: number;
    allowedPermitTypes: number[];
    allowedProductTypes: number[];
    allowedZones: number[];
    fcmToken: FcmToken | null;
}

export enum ExistingOffstreetTransactionsType {
    TICKET = 'TICKET',
    OFFSTREET_TRANSACTIONS = 'OFFSTREET_TRANSACTIONS',
    TICKET_AND_OFFSTREET_TRANSACTIONS = 'TICKET_AND_OFFSTREET_TRANSACTIONS',
}

export interface Permissions {
    operatorAppActive: boolean;
    enforcementAppActive: boolean;
    parkingpayModule: boolean;
    taxomexModule: boolean;
    transactions: boolean;
    permitsRead: boolean;
    permitsWrite: boolean;
    activityStatement: boolean;
    whitelistCreate: boolean;
    whitelistDelete: boolean;
    userPermissionEdit: boolean;
    enforcementLog: boolean;
    syncApp: boolean;
    usbStickEdit: boolean;
    parkingMeterEdit: boolean;
    refundParkTransactions: boolean;
    refundPermits: boolean;
    quotasWrite: boolean;
    parkingaboInitialBalanceCreate: boolean;
    cloudConnectorOffstreetDevicesView: boolean;
    cloudConnectorRecodeTicket: boolean;
    cloudConnectorAlerts: boolean;
    cloudConnectorReportingAccess: boolean;
}

type Data = Maybe<CurrentOperatorLogin>;

export type State = ServerStateSlice.ServerState<Data>;

export const { get, reset, setResponse } =
    ServerStateSlice.generateServerState<Data>(
        'common-OperatorLogin',
        () => null,
        (store: Flux.Store, state: State) => {
            if (state.shouldFetch) {
                store.update(fetchAccountMeta);
            }
        },
        body => body,
    );

function getOrSetUUID() {
    const token = LocalStorage.getStringItem(Items.deviceUuid);
    if (token) {
        return token;
    }
    const uuid = uuidv1();
    LocalStorage.setStringItem(Items.deviceUuid, uuid);
    return uuid;
}

export const DEVICE_UUID = getOrSetUUID();

const fetchAccountMeta = AsyncRequest.request(
    () =>
        superagent.get(
            operatorAppApiUrl(
                `/operator-logins/current?deviceUuid=${DEVICE_UUID}`,
            ),
        ),
    (store: Flux.Store, res: Response): string => {
        const name = setResponse(store, res);
        if (res.statusCode.cls.success) {
            datadogRum.setUser({
                id: res.body.loginId,
                name: res.body.username,
                email: res.body.email,
            });
        }
        store.update(store => {
            ParkingsState.reset(store);
            MasterDataZonesState.reset(store);
            PermitTypeState.reset(store);
            TariffState.List.reset(store);

            return 'update-after-login';
        });
        return name;
    },
);
