import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { loginMetaTexts } from '../i18n/OperatorLoginsTexts.ts';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import * as Text from '../../common/i18n/Text.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Title } from './Titles.tsx';
import { getOrElse, Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { Validation } from './Validation.ts';
import { FormValidation } from 'dg-web-shared/lib/FormValidation.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { setUsername } from '../actions/OperatorLoginActions.ts';

const cn = ElementNamer('OperatorLoginDetail');

export interface LoginMetaTexts {
    telnr: Text.Translation;
    firstname: Text.Translation;
    lastname: Text.Translation;
    email: Text.Translation;
    description: Text.Translation;
    username: Text.Translation;
    subTitle: Text.Translation;
    password: Text.Translation;
    passwordEmpty: Text.Translation;
    duplicateUsername: Text.Translation;
}

interface LoginMetaProps {
    login: Maybe<CommonOperatorLoginsState.OperatorLogin>;
    edit: OperatorLoginsState.Edit.State;
    editCreateResponse: OperatorLoginsState.EditCreateResponse.State;
    passwordEdit: OperatorLoginsState.PasswordEdit.State;
    layout: OperatorLoginsState.Layout.State;
    settings: SettingsState.State;
    update: Flux.Updater;
    isPasswordDefined: boolean;
    canChangeMetaInfo: boolean;
    canChangePassword: boolean;
    maxAnonymizeCheckinPublicPermitAfterDays: number;
}

export const LoginMeta = (p: LoginMetaProps) => {
    const login = p.login;
    const texts = loginMetaTexts[p.settings.language];
    const formErrors = Validation.LoginForm.validateForm(p).errorTexts;

    const pwError =
        p.layout.showLoginErrors && !p.isPasswordDefined
            ? texts.passwordEmpty()
            : null;
    const usernameError = Validation.LoginForm.usernameIsDuplicate(p)
        ? texts.duplicateUsername()
        : FormValidation.errorText(
              p.layout.showLoginErrors,
              formErrors,
              t => t.username && t.username(),
          );

    return (
        <div className={cn('')}>
            <Title noMarginTop>{texts.subTitle()}</Title>
            <TextField
                inputType="text"
                value={Validation.LoginForm.getFieldValues(p).firstName}
                label={texts.firstname()}
                onChange={(v: string) =>
                    p.update(store =>
                        OperatorLoginsState.Edit.stateWrite(store, {
                            firstName: v,
                        }),
                    )
                }
                errorText={FormValidation.errorText(
                    p.layout.showLoginErrors,
                    formErrors,
                    t => t.firstName && t.firstName(),
                )}
                disabled={!p.canChangeMetaInfo}
            />
            <TextField
                inputType="text"
                value={Validation.LoginForm.getFieldValues(p).lastName}
                label={texts.lastname()}
                onChange={(v: string) =>
                    p.update(store =>
                        OperatorLoginsState.Edit.stateWrite(store, {
                            lastName: v,
                        }),
                    )
                }
                errorText={FormValidation.errorText(
                    p.layout.showLoginErrors,
                    formErrors,
                    t => t.lastName && t.lastName(),
                )}
                disabled={!p.canChangeMetaInfo}
            />
            <TextField
                inputType="text"
                value={getOrElse(
                    getOrElse(
                        p.edit.email,
                        thenElse(login, l => l.email, ''),
                    ),
                    '',
                )}
                label={texts.email()}
                onChange={(v: string) =>
                    p.update(store =>
                        OperatorLoginsState.Edit.stateWrite(store, {
                            email: v,
                        }),
                    )
                }
                errorText={FormValidation.errorText(
                    p.layout.showLoginErrors,
                    formErrors,
                    t => t.email && t.email(),
                )}
                disabled={!p.canChangeMetaInfo}
            />
            <TextField
                inputType="text"
                value={getOrElse(
                    getOrElse(
                        p.edit.description,
                        thenElse(login, l => l.description, ''),
                    ),
                    '',
                )}
                label={texts.description()}
                onChange={(v: string) =>
                    p.update(store =>
                        OperatorLoginsState.Edit.stateWrite(store, {
                            description: v,
                        }),
                    )
                }
                disabled={!p.canChangeMetaInfo}
            />
            <TextField
                inputType="text"
                value={Validation.LoginForm.getFieldValues(p).username}
                label={texts.username()}
                onChange={(v: string) =>
                    p.update(store => setUsername(store, v.replace(/\s/g, '')))
                }
                errorText={usernameError}
                disabled={!p.canChangeMetaInfo}
            />
            <TextField
                inputType="text"
                value={getOrElse(
                    getOrElse(
                        p.edit.telnr,
                        thenElse(login, l => l.telnr, ''),
                    ),
                    '',
                )}
                label={texts.telnr()}
                onChange={(v: string) =>
                    p.update(store =>
                        OperatorLoginsState.Edit.stateWrite(store, {
                            telnr: v,
                        }),
                    )
                }
                disabled={!p.canChangeMetaInfo}
            />
            <SingleSelection
                focused={p.layout.editPasswordOpen}
                label={texts.password()}
                onClick={
                    p.canChangePassword
                        ? () =>
                              p.update(store =>
                                  OperatorLoginsState.Layout.stateWrite(store, {
                                      editPasswordOpen: true,
                                  }),
                              )
                        : () => null
                }
                selection={p.isPasswordDefined ? '••••••••••••••••••••' : 'n/d'}
                errorText={pwError}
            />
        </div>
    );
};
