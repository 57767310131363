import { Message } from 'dg-web-shared/lib/Localized.ts';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { ZoneType } from 'dg-web-shared/dto/ZoneType.ts';

import {
    AppPurchaseMode,
    AppPurchaseVisibility,
} from './ProductTemplateEnums.ts';
import { SubscriptionIntervalConfig } from './SubscriptionIntervalConfig.ts';
import { ReservationTimeAndPriceConfig } from './ReservationTimeAndPriceConfig.ts';

export const enum ProductTemplateConfigType {
    PERMIT_UNTIL_REVOCATION_BARRIER_GATE = 'PERMIT_UNTIL_REVOCATION_BARRIER_GATE',
    PERMIT_FREE_FIXED_DURATION_BARRIER_GATE = 'PERMIT_FREE_FIXED_DURATION_BARRIER_GATE',
    SUBSCRIPTION_BARRIER_GATE = 'SUBSCRIPTION_BARRIER_GATE',
    SUBSCRIPTION_BARRIER_GATE_APP = 'SUBSCRIPTION_BARRIER_GATE_APP',
    SUBSCRIPTION_ENFORCED = 'SUBSCRIPTION_ENFORCED',
    SUBSCRIPTION_ENFORCED_APP = 'SUBSCRIPTION_ENFORCED_APP',
    CALENDAR_DAY_RESERVATION_BARRIER_GATE = 'CALENDAR_DAY_RESERVATION_BARRIER_GATE',
    CALENDAR_DAY_RESERVATION_ENFORCED = 'CALENDAR_DAY_RESERVATION_ENFORCED',
    GUEST_RESERVATION_BARRIER_GATE = 'GUEST_RESERVATION_BARRIER_GATE',
    GUEST_RESERVATION_ENFORCED = 'GUEST_RESERVATION_ENFORCED',
    ALL_ACCESS_BARRIER_GATE = 'ALL_ACCESS_BARRIER_GATE',
}

export type ProductTemplate =
    | PermitUntilRevocationBarrierGate
    | PermitFreeFixedDurationBarrierGate
    | Subscription
    | CalendarDayReservationEnforced
    | CalendarDayReservationBarrierGate
    | GuestReservationUBS;

type ProductTemplateBase = {
    contractTemplateId: number;
    productTemplateVersionId: string;
    operatorId: number;
    dtaAccountId: number;
    config: ProductTemplateConfig;
};

export type PermitUntilRevocationBarrierGate = {
    readonly config: PermitUntilRevocationBarrierGateConfig;
} & ProductTemplateBase;

export type PermitFreeFixedDurationBarrierGate = {
    readonly config: PermitFreeFixedDurationBarrierGateConfig;
} & ProductTemplateBase;

export type Subscription = {
    readonly config: SubscriptionConfig;
} & ProductTemplateBase;

export type CalendarDayReservationEnforced = {
    readonly config: CalendarDayReservationEnforcedConfig;
} & ProductTemplateBase;

export type CalendarDayReservationBarrierGate = {
    readonly config: CalendarDayReservationBarrierGateConfig;
} & ProductTemplateBase;

export type GuestReservationUBS = {
    readonly config: GuestReservationUbsConfig;
} & ProductTemplateBase;

export type AllAccessBarrierGate = {
    readonly config: AllAccessBarrierGateConfig;
} & ProductTemplateBase;

export type ProductTemplateConfig =
    | PermitUntilRevocationBarrierGateConfig
    | PermitFreeFixedDurationBarrierGateConfig
    | SubscriptionConfig
    | CalendarDayReservationConfig
    | GuestReservationUbsConfig
    | AllAccessBarrierGateConfig;

interface ProductTemplateBaseConfig {
    configType: ProductTemplateConfigType;
    productName: Message;
    navProjectNumber: string | null;
    additionalInfo: Message | null;
    vehicleType: LicensePlateType;
    appPurchaseMode: AppPurchaseMode;
    allowedZoneTypes: ZoneType[] | null;
}

export interface PermitUntilRevocationBarrierGateConfig
    extends ProductTemplateBaseConfig {
    configType: ProductTemplateConfigType.PERMIT_UNTIL_REVOCATION_BARRIER_GATE;
    allowedZoneTypes: [ZoneType.barrier_gate];
}

export interface PermitFreeFixedDurationBarrierGateConfig
    extends ProductTemplateBaseConfig {
    configType: ProductTemplateConfigType.PERMIT_FREE_FIXED_DURATION_BARRIER_GATE;
    allowedZoneTypes: [ZoneType.barrier_gate];
    allowedDurations: FixedDuration[];
}

export interface FixedDuration {
    type: FixedDurationType;
    quantity: number;
    price: number;
}

export const enum FixedDurationType {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export interface AllAccessBarrierGateConfig extends ProductTemplateBaseConfig {
    configType: ProductTemplateConfigType.ALL_ACCESS_BARRIER_GATE;
    allowedZoneTypes: [ZoneType.barrier_gate];
}

export type SubscriptionConfig =
    | SubscriptionBarrierGateAppConfig
    | SubscriptionBarrierGateNoAppConfig
    | SubscriptionEnforcedAppConfig
    | SubscriptionEnforcedNoAppConfig;

interface SubscriptionBaseConfig extends ProductTemplateBaseConfig {
    intervalConfig: SubscriptionIntervalConfig;
}

interface SubscriptionBarrierGateAppConfig extends SubscriptionBaseConfig {
    configType: ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE_APP;
    appPurchaseVisibility: AppPurchaseVisibility;
    allowedZoneTypes: [ZoneType.barrier_gate];
}

interface SubscriptionBarrierGateNoAppConfig extends SubscriptionBaseConfig {
    configType: ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE;
    allowedZoneTypes: [ZoneType.barrier_gate];
}

interface SubscriptionEnforcedAppConfig extends SubscriptionBaseConfig {
    configType: ProductTemplateConfigType.SUBSCRIPTION_ENFORCED_APP;
    appPurchaseVisibility: AppPurchaseVisibility;
    allowedZoneTypes: [ZoneType.enforced];
}

interface SubscriptionEnforcedNoAppConfig extends SubscriptionBaseConfig {
    configType: ProductTemplateConfigType.SUBSCRIPTION_ENFORCED;
    allowedZoneTypes: [ZoneType.enforced];
}

export type CalendarDayReservationConfig =
    | CalendarDayReservationEnforcedConfig
    | CalendarDayReservationBarrierGateConfig;

interface CalendarDayReservationBaseConfig extends ProductTemplateBaseConfig {
    appPurchaseVisibility: AppPurchaseVisibility;
    timeAndPriceConfig: ReservationTimeAndPriceConfig;
}

interface CalendarDayReservationBarrierGateConfig
    extends CalendarDayReservationBaseConfig {
    configType: ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_BARRIER_GATE;
    allowedZoneTypes: [ZoneType.barrier_gate];
}

interface CalendarDayReservationEnforcedConfig
    extends CalendarDayReservationBaseConfig {
    configType: ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_ENFORCED;
    allowedZoneTypes: [ZoneType.enforced];
}

type GuestReservationUbsConfig =
    | GuestReservationUbsBarrierGateConfig
    | GuestReservationUbsEnforcedConfig;

interface GuestReservationUbsBarrierGateConfig
    extends GuestReservationUbsBaseConfig {
    configType: ProductTemplateConfigType.GUEST_RESERVATION_BARRIER_GATE;
    allowedZoneTypes: [ZoneType.barrier_gate];
}

interface GuestReservationUbsEnforcedConfig
    extends GuestReservationUbsBaseConfig {
    configType: ProductTemplateConfigType.GUEST_RESERVATION_ENFORCED;
    allowedZoneTypes: [ZoneType.enforced];
}

interface GuestReservationUbsBaseConfig extends ProductTemplateBaseConfig {
    appPurchaseConfig: GuestReservationAppPurchaseConfig;
    maxHours: number;
    maxDaysPurchaseAheadInFuture: number;
    minValidityStart: string | null;
}

interface GuestReservationAppPurchaseConfig {
    disclaimerText: Message;
    customerRemarkLabel: Message | null;
    customerRemarkRequired: number;
    purchaseVisibility: AppPurchaseVisibility;
}
