import { Maybe, isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { FullSlideIn } from '../../ui/slidein/Slidein.tsx';
import * as ClearanceEditState from '../state/ClearanceEditState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import { ClearanceEdit } from './ClearanceEdit.tsx';

interface State {
    layout: ClearanceEditState.Layout.State;
    clearancePermitList: ClearancePermitListState.State;
}

export class ClearanceSlideIn extends Flux.Container<State> {
    static displayName = 'ClearanceSlideIn';
    stateSelector(): State {
        return {
            clearancePermitList: new ClearancePermitListState.StateSlice(
                this.props.allState,
            ).state,
            layout: ClearanceEditState.Layout.get(this.props.allState),
        };
    }

    editContextEnabled(): boolean {
        return isDefined(this.state.clearancePermitList.selectedClearanceId);
    }

    renderContent(): Maybe<JSX.Element> {
        if (this.editContextEnabled()) {
            return <ClearanceEdit allState={this.props.allState} />;
        } else {
            return null;
        }
    }
    isDisabled(): boolean {
        return (
            this.state.layout.fromDatePickerOpen ||
            this.state.layout.toDatePickerOpen ||
            false
        );
    }
    render() {
        const content = this.renderContent();
        return (
            <FullSlideIn
                open={isDefined(content)}
                disabled={this.isDisabled()}
                outsideBody
            >
                {content}
            </FullSlideIn>
        );
    }
}
