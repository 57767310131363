import { selectState, Store, Updater } from 'dg-web-shared/lib/Flux.tsx';
import {
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    LoaderHeader,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../ui/slidein/Slidein.tsx';
import * as EnforcementLogState from './EnforcementLogState.ts';
import { LabeledText } from '../ui/labeled-elements/LabeledText.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { Language } from 'dg-web-shared/lib/Localized.ts';
import { EnforcementResult } from 'enforcement-result/src/EnforcementResult.tsx';
import { enforcementLogResponseString } from './EnforcementLogResultList.tsx';

interface State {
    detail: EnforcementLogState.Detail.State;
    layout: EnforcementLogState.Layout.State;
}

const Header = (p: State & { update: Updater }): JSX.Element => {
    if (p.detail.pending) {
        return <LoaderHeader />;
    } else {
        return (
            <StandardFirstLevelHeader
                title={p.detail.data && p.detail.data.identification}
                onClose={() =>
                    p.update(store =>
                        EnforcementLogState.Layout.stateWrite(store, {
                            selectedItem: null,
                        }),
                    )
                }
            />
        );
    }
};

const deriveRecognitionType = (type: string, language: Language): string => {
    const rfid = {
        de: 'Vignette-Scan',
        fr: 'Scansion de la vignette',
        it: 'Scansione vignetta',
        en: 'Vignette scan',
    };
    const lpr = {
        de: 'Nummernschilderkennung',
        fr: 'Reconnaissance de la plaque',
        it: 'Riconoscimento targa',
        en: 'License plate recognition',
    };
    const keyboard = {
        de: 'Tastatur',
        fr: 'Manuelle',
        it: 'Manuale',
        en: 'Keyboard',
    };
    switch (type) {
        case 'rfid':
            return rfid[language];
        case 'lpr':
            return lpr[language];
        case 'keyboard':
            return keyboard[language];
        default:
            console.error(`Enforcement recognition type '${type}' is unknown.`);
            return keyboard[language];
    }
};

const Info = ({
    detail,
}: {
    detail: EnforcementLogState.Detail.Data;
}): JSX.Element => {
    const language = useOperatorLanguage();
    return (
        <div>
            <LabeledText
                label={
                    <Localized
                        de="Referenz"
                        fr="Référence"
                        it="Referenza"
                        en="Reference"
                    />
                }
            >
                {detail.id}
            </LabeledText>
            <LabeledText
                label={
                    <Localized
                        de="Kennzeichen"
                        fr="Immatriculation"
                        it="Targa"
                        en="License plate"
                    />
                }
            >
                {detail.identification}
            </LabeledText>
            <LabeledText
                label={<Localized de="Zone" fr="Zone" it="Zona" en="Zone" />}
            >
                {detail.zoneName}
            </LabeledText>
            <LabeledText
                label={
                    <Localized
                        de="Ort"
                        fr="Localité"
                        it="Località"
                        en="Place"
                    />
                }
            >
                {detail.locationName}
            </LabeledText>
            <LabeledText
                label={
                    <Localized
                        de="Eingabe"
                        fr="Saisie"
                        it="Inserimento"
                        en="Input"
                    />
                }
            >
                {`${detail.rawIdentification} (${deriveRecognitionType(
                    detail.recognitionType,
                    language,
                )})`}
            </LabeledText>
            <LabeledText
                label={
                    <Localized
                        de="Kontrolleur"
                        fr="Contrôleur"
                        it="Controllore"
                        en="Controller"
                    />
                }
            >
                {detail.enforcerName}
            </LabeledText>
            <LabeledText
                label={
                    <Localized
                        de="Gerätetyp"
                        fr="Type d'appareil"
                        it="Tipo d'apparecchio"
                        en="Device type"
                    />
                }
            >
                {detail.clientName}
            </LabeledText>
            <LabeledText
                label={
                    <Localized
                        de="Resultat"
                        fr="Résultat"
                        it="Risultato"
                        en="Resultat"
                    />
                }
            >
                {enforcementLogResponseString(
                    detail.responseResult,
                ).toUpperCase()}
            </LabeledText>
        </div>
    );
};

export const EnforcementLogDetailSlideIn = selectState(
    (store: Store): State => {
        const layout = EnforcementLogState.Layout.get(store);
        return {
            layout,
            detail: EnforcementLogState.Detail.get(
                store,
                layout.selectedItem ? { id: layout.selectedItem } : null,
            ),
        };
    },
    p => {
        return (
            <FullSlideIn open={!!p.layout.selectedItem}>
                <Header {...p} />
                {p.detail.data && (
                    <SlideInBody>
                        <FullSlideInLeftColumn>
                            <Info detail={p.detail.data} />
                        </FullSlideInLeftColumn>
                        <FullSlideInRightColumn>
                            <EnforcementResult result={p.detail.data.result} />
                        </FullSlideInRightColumn>
                    </SlideInBody>
                )}
            </FullSlideIn>
        );
    },
);
