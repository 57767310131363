import { Translations } from '../../common/i18n/Text.ts';
import { PermitTexts } from '../components/PermitTypesBody.tsx';
/* tslint:disable */

export const permitTypeTexts: Translations<PermitTexts> = {
    de: {
        Type: () => 'Bewilligungsart',
        ProfileNr: () => 'Profil Nr',
        Zones: () => 'Zonen',
        Price: () => 'Gebühr per Einheit',
        SellChannel: () => 'Verkaufsart',
        Clearance: () => 'Freigabe Pflicht',
        MaxId: () => 'Max. Kennzeichen / Badges',
        ProfileNumberBody: () => 'Profil Nummer',
        IdentificationBody: () => 'Identifikation',
        MaxIdBody: () => 'Max. Anwesende / Registrierte Identifikationen',
        LicensePlateTypeBody: () => 'Schildarten',
        VatBody: () => 'MWST. Pflicht',
        ApplyForClearanceBody: () => 'Online Antrag über die Benutzer App',
        AppChannel: () => 'Online',
        CounterChannel: () => 'Schalter',
        HeaderCaption: () => 'Bewilligungsart',
        Yes: () => 'Ja',
        LicensePlate: () => 'Kennzeichen',
        Badge: () => 'Badge',
        WithinValidityConstraintDates: () => 'Gültigkeitsdaten',
        Fixed: () => 'fixe',
        Selectable: () => 'wählbare',
        Account: () => 'Bankkonto',
    },
    fr: {
        AppChannel: () => 'En ligne',
        ApplyForClearanceBody: () =>
            "Domande en ligne par l'application Parkingpay",
        Clearance: () => 'Approbation obligatoire',
        CounterChannel: () => 'Guichet',
        HeaderCaption: () => "Type d'autorisation",
        IdentificationBody: () => 'Identification',
        LicensePlateTypeBody: () => 'Type de plaque',
        MaxId: () => 'Max. Immatriculations / Badges',
        MaxIdBody: () => 'Max. présents / identifications enregistrés',
        Price: () => 'Prix unitaire',
        ProfileNr: () => 'N° du profil',
        ProfileNumberBody: () => 'Numéro du profil',
        SellChannel: () => 'Modalité de vente',
        Type: () => "Type d'autorisation",
        VatBody: () => 'Numéro de TVA',
        Zones: () => 'Zones',
        Yes: () => 'Oui',
        LicensePlate: () => 'Immatriculation',
        Badge: () => 'Badge',
        WithinValidityConstraintDates: () => 'FR Gültigkeitsdaten',
        Fixed: () => 'fixe',
        Selectable: () => 'au choix',
        Account: () => 'Compte bancaire',
    },
    it: {
        AppChannel: () => 'Online',
        ApplyForClearanceBody: () =>
            'Richiesta online tramite applicazione Parkingpay',
        Clearance: () => 'Approvazione obbligatoria',
        CounterChannel: () => 'Sportello',
        HeaderCaption: () => "Tipo d'autorizzazione",
        IdentificationBody: () => 'Identificazione',
        LicensePlateTypeBody: () => 'Tipi di targa',
        MaxId: () => 'Max. Targhe / Badges',
        MaxIdBody: () => 'Max. presenti / identificazioni registrate',
        Price: () => 'Prezzo unitario',
        ProfileNr: () => 'Nr. profilo',
        ProfileNumberBody: () => 'Numero profilo',
        SellChannel: () => 'Modalità di vendita',
        Type: () => "Tipo d'autorizzazione",
        VatBody: () => 'Numero IVA',
        Zones: () => 'Zone',
        Yes: () => 'Si',
        LicensePlate: () => 'Targa',
        Badge: () => 'Badge',
        WithinValidityConstraintDates: () => 'IT Gültigkeitsdaten',
        Fixed: () => 'fissa/e',
        Selectable: () => 'a scelta',
        Account: () => 'Conto bancario',
    },
};

/* tslint:enable */
