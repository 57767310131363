import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import * as Text from '../../common/i18n/Text.ts';
import { collectionTexts, tomTexts } from '../i18n/TomTexts.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as TomLayoutState from '../state/TomLayoutState.ts';
import { TomsBody } from './TomsBody.tsx';
import { CollectionsBody } from './collection/CollectionsBody.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';

const cn = ElementNamer('Toms');

export interface ParkTransactionsTexts {
    tabtitleFinishedTransactions: Text.Translation;
    tabtitleTransactionStatistics: Text.Translation;
}

interface TomsOverviewState {
    settings: SettingsState.State;
    currentLogin: CurrentOperatorLoginState.State;
    layout: TomLayoutState.SelectedTab.State;
}

export class TomsOverview extends Flux.Container<TomsOverviewState> {
    static displayName: string = cn('');

    stateSelector(): TomsOverviewState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            layout: TomLayoutState.SelectedTab.get(this.props.allState),
        };
    }

    renderToms(): JSX.Element {
        return <TomsBody allState={this.props.allState} />;
    }

    renderCollections(): JSX.Element {
        return <CollectionsBody allState={this.props.allState} />;
    }

    renderTabBody(): JSX.Element | null {
        switch (this.state.layout.activeTab) {
            case TomLayoutState.Tabs.TOM:
                return this.renderToms();
            case TomLayoutState.Tabs.Collections:
                return this.renderCollections();
            default:
                return null;
        }
    }

    render() {
        const texts = tomTexts[this.state.settings.language];
        const layout = this.state.layout;
        if (isUndefined(this.state.currentLogin.data)) {
            return null;
        }

        return (
            <Container>
                <Tabs>
                    <TabItem
                        active={layout.activeTab === TomLayoutState.Tabs.TOM}
                        description={texts.TabTitle()}
                        onClick={() =>
                            this.update(store =>
                                TomLayoutState.SelectedTab.stateWrite(store, {
                                    activeTab: TomLayoutState.Tabs.TOM,
                                }),
                            )
                        }
                    />
                    <TabItem
                        active={
                            layout.activeTab === TomLayoutState.Tabs.Collections
                        }
                        description={collectionTexts[
                            this.state.settings.language
                        ].TabTitle()}
                        onClick={() =>
                            this.update(store =>
                                TomLayoutState.SelectedTab.stateWrite(store, {
                                    activeTab: TomLayoutState.Tabs.Collections,
                                }),
                            )
                        }
                    />
                </Tabs>
                {this.renderTabBody()}
            </Container>
        );
    }
}
