import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ContainerProps } from 'dg-web-shared/lib/Flux.tsx';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    ConfirmSaveHeader,
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { operatorLoginsListTexts } from '../i18n/OperatorLoginsTexts.ts';
import { LoginMeta } from './LoginMeta.tsx';
import { OperatorLoginsListTexts } from './OperatorLoginsList.tsx';
import {
    closeSlideIn,
    editLogin,
    makeEditPayload,
} from '../actions/OperatorLoginActions.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { CurrentOperatorLogin } from '../../common/state/CurrentOperatorLoginState.ts';
import { Validation } from './Validation.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { SubTitle } from './Titles.tsx';
import { PermissionSwitch, texts } from './OperatorLoginsDetailSlideIn.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';

const cn = ElementNamer('OperatorLoginsDetailSlideIn');

interface OperatorLoginsDetailSlideInState {
    layout: OperatorLoginsState.Layout.State;
    operatorLoginsList: CommonOperatorLoginsState.List.State;
    edit: OperatorLoginsState.Edit.State;
    passwordEdit: OperatorLoginsState.PasswordEdit.State;
    editCreateResponse: OperatorLoginsState.EditCreateResponse.State;
    currentLogin: CurrentOperatorLoginState.State;
    settings: SettingsState.State;
    operatorData: OperatorDataState.State;
}

const isFormValid = (
    p: OperatorLoginsDetailSlideInState,
    login: Maybe<CommonOperatorLoginsState.OperatorLogin>,
    currentLoginData: CurrentOperatorLogin,
) =>
    Validation.LoginForm.validateForm({
        edit: p.edit,
        editCreateResponse: p.editCreateResponse,
        settings: p.settings,
        login: login,
        maxAnonymizeCheckinPublicPermitAfterDays:
            currentLoginData.softAnonymizeCheckinPublicPermitAfterDays,
    }).isValid;

export class OperatorAdminOrSuperuserDetailSlideIn extends Flux.ContainerWithProps<
    { currentLogin: CurrentOperatorLogin } & ContainerProps,
    OperatorLoginsDetailSlideInState
> {
    static displayName: string = cn('');

    stateSelector(): OperatorLoginsDetailSlideInState {
        return {
            layout: OperatorLoginsState.Layout.get(this.props.allState),
            operatorLoginsList: CommonOperatorLoginsState.List.get(
                this.props.allState,
            ),
            edit: OperatorLoginsState.Edit.get(this.props.allState),
            passwordEdit: OperatorLoginsState.PasswordEdit.get(
                this.props.allState,
            ),
            editCreateResponse: OperatorLoginsState.EditCreateResponse.get(
                this.props.allState,
            ),
            settings: new SettingsState.StateSlice(this.props.allState).state,
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            operatorData: OperatorDataState.get(this.props.allState),
        };
    }

    txt(): OperatorLoginsListTexts {
        return operatorLoginsListTexts[this.state.settings.language];
    }

    getSelectedAdminOrSuperuserLogin() {
        if (this.state.layout.selectedOperatorLogin) {
            const selectedLogin = CommonOperatorLoginsState.getLoginById(
                this.state.operatorLoginsList.data,
                this.state.layout.selectedOperatorLogin,
            );
            if (selectedLogin?.permissions?.userPermissionEdit) {
                return selectedLogin;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    renderHeader() {
        const adminOrSuperuserLogin = this.getSelectedAdminOrSuperuserLogin();
        if (!adminOrSuperuserLogin) {
            return null;
        }
        if (this.state.editCreateResponse.pending) {
            return <LoaderHeader />;
        } else if (
            OperatorLoginsState.Edit.hasChanges(
                this.state.edit,
                adminOrSuperuserLogin,
            )
        ) {
            return (
                <ConfirmSaveHeader
                    language={this.state.settings.language}
                    title={this.txt().saveEdit()}
                    onCancel={() => this.props.allState.update(closeSlideIn)}
                    onSave={() => {
                        if (
                            isFormValid(
                                this.state,
                                adminOrSuperuserLogin,
                                this.props.currentLogin,
                            )
                        ) {
                            this.update(editLogin, {
                                loginId: adminOrSuperuserLogin.operatorLoginId,
                                payload: makeEditPayload(
                                    this.state.edit,
                                    adminOrSuperuserLogin,
                                ),
                            });
                        } else {
                            this.update(store =>
                                OperatorLoginsState.Layout.stateWrite(store, {
                                    showLoginErrors: true,
                                }),
                            );
                        }
                    }}
                    confirmButtonSpecialState={
                        !isFormValid(
                            this.state,
                            adminOrSuperuserLogin,
                            this.props.currentLogin,
                        )
                            ? ButtonSpecialState.DISABLED
                            : null
                    }
                />
            );
        } else {
            return (
                <StandardFirstLevelHeader
                    onClose={() => this.props.allState.update(closeSlideIn)}
                >
                    <SlideInHeaderTexts
                        subtitle={this.txt().administrators()}
                        title={CommonOperatorLoginsState.getLoginTitle(
                            adminOrSuperuserLogin,
                        )}
                        hasLeftIcon={false}
                    />
                </StandardFirstLevelHeader>
            );
        }
    }

    renderBody() {
        const adminOrSuperuserLogin = this.getSelectedAdminOrSuperuserLogin();
        const currentLogin = this.state.currentLogin.data;
        const operatorData = this.state.operatorData.data;

        if (!adminOrSuperuserLogin || !currentLogin || !operatorData) {
            return null;
        }

        const showPermissionEdit =
            currentLogin.isAdmin &&
            currentLogin.loginId != adminOrSuperuserLogin.operatorLoginId;

        return (
            <SlideInBody disabled={this.state.layout.editPasswordOpen}>
                <FullSlideInLeftColumn>
                    <LoginMeta
                        {...this.state}
                        login={adminOrSuperuserLogin}
                        maxAnonymizeCheckinPublicPermitAfterDays={
                            this.props.currentLogin
                                .softAnonymizeCheckinPublicPermitAfterDays
                        }
                        update={this.props.allState.update}
                        isPasswordDefined={true}
                        canChangeMetaInfo={
                            adminOrSuperuserLogin.editableMetaInfo
                        }
                        canChangePassword={
                            adminOrSuperuserLogin.editablePassword
                        }
                    />
                </FullSlideInLeftColumn>
                <FullSlideInRightColumn>
                    <SubTitle noMarginTop>
                        {texts(this.state).permissions()}
                    </SubTitle>
                    {showPermissionEdit && (
                        <PermissionSwitch
                            label={texts(this.state).userPermissionEdit()}
                            editVal={this.state.edit.permPermissionsEdit}
                            persistedVal={
                                adminOrSuperuserLogin.permissions
                                    .userPermissionEdit
                            }
                            write={(v: boolean) =>
                                this.update(store =>
                                    OperatorLoginsState.Edit.stateWrite(store, {
                                        permPermissionsEdit: v,
                                    }),
                                )
                            }
                            disabled={false}
                        />
                    )}
                    <PermissionSwitch
                        label={texts(this.state).permParkingMeterAlerts()}
                        editVal={this.state.edit.permParkingMeterAlerts}
                        persistedVal={
                            adminOrSuperuserLogin.permissions.parkingMeterAlerts
                        }
                        write={(v: boolean) =>
                            this.update(store =>
                                OperatorLoginsState.Edit.stateWrite(store, {
                                    permParkingMeterAlerts: v,
                                }),
                            )
                        }
                        disabled={false}
                    />
                    {operatorData.hasCloudConnector && (
                        <PermissionSwitch
                            label={
                                <Localized
                                    de="Cloud Connector - Benachrichtigungen"
                                    fr="Cloud Connector - Notifications"
                                    it="Cloud Connector - Notifiche"
                                    en="Cloud Connector - Alerts"
                                />
                            }
                            editVal={this.state.edit.permCloudConnectorAlerts}
                            persistedVal={
                                adminOrSuperuserLogin.permissions
                                    .cloudConnectorAlerts
                            }
                            write={(v: boolean) =>
                                this.update(store =>
                                    OperatorLoginsState.Edit.stateWrite(store, {
                                        permCloudConnectorAlerts: v,
                                    }),
                                )
                            }
                            disabled={false}
                        />
                    )}
                    <TextField
                        inputType="number"
                        value={String(
                            this.state.operatorLoginsList.data.find(
                                ol =>
                                    ol.operatorLoginId ===
                                    this.state.currentLogin.data?.loginId,
                            )?.softAnonymizeCheckinPublicPermitAfterDays ??
                                this.props.currentLogin
                                    .maxSoftAnonymizeCheckinPublicPermitAfterDays,
                        )}
                        label={texts(
                            this.state,
                        ).softAnonymizeCheckinPublicPermitAfterDaysLabel()}
                        onChange={() => null}
                        disabled={true}
                    />
                </FullSlideInRightColumn>
            </SlideInBody>
        );
    }

    render() {
        const isAdmin =
            this.state.currentLogin.data &&
            this.state.currentLogin.data.permissions.userPermissionEdit;
        if (this.state.operatorLoginsList.data.length === 0 || !isAdmin) {
            return <HalfSlideIn open={false} />;
        }
        return (
            <FullSlideIn open={!!this.getSelectedAdminOrSuperuserLogin()}>
                {this.renderBody()}
                {this.renderHeader()}
            </FullSlideIn>
        );
    }
}
