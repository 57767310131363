import { Box, Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Localized } from '../common/components/Localized.tsx';
import { Warning } from '@mui/icons-material';

export function ResultLimitAlert({
    eof,
    numberOfResults,
    hasExport,
}: {
    eof: boolean;
    numberOfResults: number;
    hasExport: boolean;
}) {
    const adjustedNumberOfResults = numberOfResults - 1;
    if (eof) {
        return null;
    }
    return (
        <Alert
            severity="info"
            sx={{ whiteSpace: 'pre-line', alignItems: 'center' }}
        >
            <Localized
                de={`Die Liste ist zu lang und deshalb sind nur die ersten ${adjustedNumberOfResults} Ergebnisse sichtbar. Ändern Sie die Filter und/oder die Suche, um die gewünschten Ergebnisse zu finden${hasExport ? ', oder exportieren Sie die komplette Liste' : ''}.`}
                fr={`La liste est trop longue et donc seuls les ${adjustedNumberOfResults} premiers résultats sont visibles. Modifiez les filtres et/ou la recherche pour trouver les résultats souhaités${hasExport ? ' ou exportez la liste complète' : ''}.`}
                it={`La lista è troppo lunga e quindi sono visibili solo i primi ${adjustedNumberOfResults} risultati. Modificare i filtri e/o la ricerca, per trovare i risultati desiderati${hasExport ? ' oppure esportare la lista completa' : ''}.`}
                en={`The list is too long and therefore only the first ${adjustedNumberOfResults} results are visible. Change the filters and/or the search to find the desired results${hasExport ? ' or export the complete list.' : ''}.`}
            />
        </Alert>
    );
}

export function EmptyResults({ hasFilter = true }: { hasFilter?: boolean }) {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
            }}
        >
            <Warning color="error" style={{ fontSize: 40 }} />
            <Typography
                color="error"
                variant="h2"
                style={{ fontSize: 24, fontWeight: 400 }}
            >
                <Localized
                    de="Keine Resultate gefunden"
                    fr="Aucun résultat relevé"
                    it="Nessun risultato trovato"
                    en="No results found"
                />
            </Typography>
            {hasFilter ?? (
                <Typography style={{ fontSize: 20, fontWeight: 300 }}>
                    <Localized
                        de="Bitte überprufen Sie die ausgewählten Such- and Filterkriterien."
                        fr="Veuillez vérifier le critère de recherche et les filtres saise."
                        it="Verificare il criterio di ricerca e i filtri selezionati."
                        en="Please check the selected search and filter criteria."
                    />
                </Typography>
            )}
        </Box>
    );
}

export function FetchError({ refetchList }: { refetchList: () => void }) {
    return (
        <Alert
            variant="filled"
            severity="warning"
            action={
                <Button onClick={refetchList} size="small" color="inherit">
                    <Localized de="Retry" fr="Retry" it="Retry" en="Retry" />
                </Button>
            }
        >
            <Localized
                de="Es war nicht möglich, die Daten abzurufen."
                fr="Il n'a pas été possible de récupérer les données."
                it="Non è stato possibile scaricare i dati."
                en="It was not possible to fetch the data."
            />
        </Alert>
    );
}
