import moment from 'moment';

import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { chevronRight } from 'dg-web-shared/ui/icons/Icons16.tsx';
import {
    IdentificationBadge,
    IdentificationLicensePlate,
} from '../../../common/components/IdentificationItems.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../../common/state/OperatorDataState.ts';
import * as OperatorLoginsState from '../../../common/state/OperatorLoginsState.ts';
import {
    getLoginById,
    getLoginTitle,
} from '../../../common/state/OperatorLoginsState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import {
    TextColumnContent,
    TextLeftColumn,
    TextRightColumn,
} from '../../../ui/layout/Text.tsx';
import { ResultsError } from '../../../ui/table/ResultsError.tsx';
import {
    ColumnWidth,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
    TableRow,
} from '../../../ui/table/Table.tsx';
import { selectPermit } from '../../actions/ClearancePermitListActions.ts';
import { filteredPermitsListTexts } from '../../i18n/FilteredPermitsTexts.ts';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import {
    permitStatusIcon,
    permitStatusString,
} from '../../state/PermitsState.ts';
import { CreateGuide } from '../shared/Guides.tsx';
import * as Identifier from '../shared/Identifier.ts';
import { IconTopOffsetContainer } from '../../../zones/components/ZoneListBody.tsx';
import { ModalSpecialTabResultRefresher } from '../../../ui/layout/TabContent.tsx';
import { css } from '@emotion/css';
import {
    getPermitStatusBackground,
    getPermitStatusColor,
} from '../../../ui/permit/permitStatusColors.ts';
import { Icon16, Icon24 } from '../../../ui/icons/Icon.tsx';
import { NicerPaymentChannel } from '../../../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';

export interface FilteredPermitsListTexts {
    tooManyRowsTitle: Text.Translation;
    tooManyRowsDescription: Text.Translation;

    noRowsTitle: Text.Translation;
    noRowsDescription: Text.Translation;

    headerZoneName: Text.Translation;
    headerIssuedDate: Text.Translation;
    headerValidFrom: Text.Translation;
    headerValidTo: Text.Translation;
    headerPermitTypeName: Text.Translation;
    headerChannel: Text.Translation;
    headerOperatorLoginName: Text.Translation;
    headerPermitId: Text.Translation;

    hasMany: Text.Translation;
    onlineUser: Text.Translation;

    filterGuideTitle: Text.Translation;
    filterGuideIntro: Text.Translation;
    filterGuideListDescription: Text.Translation;
    filterGuideValidity: Text.Translation;
    filterGuideType: Text.Translation;
    filterGuideUser: Text.Translation;
    filterGuideCreateDate: Text.Translation;
    filterGuideCategory: Text.Translation;
    filterGuidePrice: Text.Translation;
    filterGuideState: Text.Translation;
    filterGuideExportTitle: Text.Translation;
    filterGuideExportDescription: Text.Translation;
}

interface FilteredPermitsListState {
    settings: SettingsState.State;
    permitsList: FilteredPermitsState.List.State;
    filter: FilteredPermitsState.Filter.State;
    operatorLogins: OperatorLoginsState.List.State;
    login: CurrentOperatorLoginState.State;
    operatorData: OperatorDataState.State;
}

export const Guide = (p: FilteredPermitsListState) => {
    const login = p.login.data;
    const texts = filteredPermitsListTexts[p.settings.language];
    if (login) {
        return (
            <TextColumnContent>
                <TextLeftColumn>
                    <h1>{texts.filterGuideTitle()}</h1>
                    <h3>
                        <span>{texts.filterGuideIntro()}</span>
                    </h3>
                    <h3>{texts.filterGuideListDescription()}</h3>
                    <ul>
                        <li>{texts.filterGuideValidity()}</li>
                        <li>{texts.filterGuideType()}</li>
                        <li>{texts.filterGuideUser()}</li>
                        <li>{texts.filterGuideCreateDate()}</li>
                        <li>{texts.filterGuideCategory()}</li>
                        <li>{texts.filterGuidePrice()}</li>
                        <li>{texts.filterGuideState()}</li>
                    </ul>
                </TextLeftColumn>
                <TextRightColumn>
                    <h1>{texts.filterGuideExportTitle()}</h1>
                    <h3>{texts.filterGuideExportDescription()}</h3>
                    <CreateGuide login={login} lang={p.settings.language} />
                </TextRightColumn>
            </TextColumnContent>
        );
    } else {
        return <noscript />;
    }
};

export class FilteredPermitsList extends Flux.Container<FilteredPermitsListState> {
    stateSelector(): FilteredPermitsListState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            permitsList: FilteredPermitsState.List.get(this.props.allState),
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
            login: CurrentOperatorLoginState.get(this.props.allState),
            operatorData: OperatorDataState.get(this.props.allState),
        };
    }

    renderIntroduction(): JSX.Element {
        return <Guide {...this.state} />;
    }

    getTexts(): FilteredPermitsListTexts {
        return filteredPermitsListTexts[this.state.settings.language];
    }

    renderTooManyRows(): JSX.Element {
        const texts = this.getTexts();
        return (
            <ResultsError
                title={texts.tooManyRowsTitle()}
                description={texts.tooManyRowsDescription()}
            />
        );
    }

    renderNoRows(): JSX.Element {
        const texts = this.getTexts();
        return (
            <ResultsError
                title={texts.noRowsTitle()}
                description={texts.noRowsDescription()}
            />
        );
    }

    render() {
        const texts = this.getTexts();
        const filteredPermitsListRefresher = (
            <ModalSpecialTabResultRefresher
                onRefreshClick={() =>
                    this.update(FilteredPermitsState.List.forceRefetch, true)
                }
                successRequest={
                    this.state.permitsList.statusCode.statusCode === 200
                }
                pendingRequest={this.state.permitsList.pending}
            />
        );
        if (!FilteredPermitsState.Filter.filterActive(this.state.filter)) {
            return this.renderIntroduction();
        }

        if (
            this.state.permitsList.data.data.length < 1 &&
            (this.state.permitsList.data.size || 0) > 1
        ) {
            return (
                <>
                    {filteredPermitsListRefresher}
                    {this.renderTooManyRows()}
                </>
            );
        }

        if (
            this.state.permitsList.data.data.length < 1 &&
            (this.state.permitsList.data.size || 0) < 1
        ) {
            return (
                <>
                    {filteredPermitsListRefresher}
                    {this.renderNoRows()}
                </>
            );
        }

        return (
            <>
                {filteredPermitsListRefresher}
                <>
                    <Table>
                        <TableHeader>
                            <EmptyTableHeaderColumn
                                width={ColumnWidth._24px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={thenElse(
                                    this.state.operatorData.data,
                                    d =>
                                        Identifier.getDescriptionMany(
                                            d.licensePlatePermitSettings
                                                .hasBadgePermits,
                                            this.state.settings.language,
                                        ),
                                    '',
                                )}
                                width={ColumnWidth._208px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerValidFrom()}
                                width={ColumnWidth._108px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerValidTo()}
                                direction={SortDirection.Descending}
                                selected={true}
                                width={ColumnWidth._108px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerPermitTypeName()}
                                width={ColumnWidth.variable}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerPermitId()}
                                width={ColumnWidth._108px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerChannel()}
                                width={ColumnWidth._108px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerOperatorLoginName()}
                                width={ColumnWidth._192px}
                                delimiterBottom={false}
                            />

                            <TableHeaderColumn
                                name={texts.headerIssuedDate()}
                                width={ColumnWidth._136px}
                                delimiterBottom={false}
                            />

                            <EmptyTableHeaderColumn
                                width={ColumnWidth._24px}
                                delimiterBottom={false}
                            />
                        </TableHeader>
                        <TableBody>
                            {this.state.permitsList.data.data.map(p => (
                                <PermitRow
                                    allState={this.props.allState}
                                    permit={p}
                                    language={this.state.settings.language}
                                    onClick={() =>
                                        this.update(store =>
                                            selectPermit(store, p.id),
                                        )
                                    }
                                    operatorLogins={
                                        this.state.operatorLogins.data
                                    }
                                    key={p.id}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </>
            </>
        );
    }
}

const permitValidDatesAsString = (p: FilteredPermitsState.Permit) => {
    return {
        validFrom: Formatter.isoString(p.validFrom),
        validTo: Formatter.isoString(p.validTo),
    };
};

interface PermitRowProps {
    allState: Flux.Store;
    permit: FilteredPermitsState.Permit;
    language: string;
    onClick: ClickHandler;
    operatorLogins: OperatorLoginsState.OperatorLogin[];
}

const PermitRow = (p: PermitRowProps): JSX.Element => {
    const permitStatus = permitStatusString(
        permitValidDatesAsString(p.permit),
        moment(),
    );

    const permitStatusIco = permitStatusIcon(
        permitValidDatesAsString(p.permit),
        moment(),
    );

    return (
        <TableRow
            className={css({ ...getPermitStatusBackground(permitStatus) })}
            onClick={p.onClick}
        >
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth._208px}>
                <div
                    className={css({
                        paddingLeft: '4px',
                        display: 'flex',
                        aligItems: 'center',
                    })}
                >
                    <div
                        className={css({
                            color: getPermitStatusColor(permitStatus),
                            width: '32px',
                            marginBottom: '-2px',
                        })}
                    >
                        <Icon24 icon={permitStatusIco} />
                    </div>
                    <IdColumnIdentifier
                        licensePlates={p.permit.licensePlates}
                        badges={p.permit.badges}
                    />
                </div>
            </TableColumn>
            <TableColumn width={ColumnWidth._108px}>
                {Formatter.dayMonthYear(p.permit.validFrom)}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px}>
                {p.permit.validTo.isAfter(moment('2030-01-01'))
                    ? ''
                    : Formatter.dayMonthYear(p.permit.validTo)}
            </TableColumn>
            <TableColumn width={ColumnWidth.variable}>
                {p.permit.permitTypeName}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px}>{p.permit.id}</TableColumn>
            <TableColumn width={ColumnWidth._108px}>
                <NicerPaymentChannel paymentChannel={p.permit.paymentChannel} />
            </TableColumn>
            <TableColumn width={ColumnWidth._192px}>
                {thenElse(
                    getLoginById(p.operatorLogins, p.permit.operatorLoginId),
                    l => getLoginTitle(l),
                    filteredPermitsListTexts[p.language].onlineUser(),
                )}
            </TableColumn>
            <TableColumn width={ColumnWidth._136px}>
                {thenElse(
                    p.permit.paydate,
                    pd => Formatter.dayMonthYearHourMinute(pd),
                    thenElse(
                        p.permit.generated,
                        gd => Formatter.dayMonthYearHourMinute(gd),
                        '',
                    ),
                )}
            </TableColumn>

            <TableColumn width={ColumnWidth._24px}>
                <IconTopOffsetContainer>
                    <Icon16 icon={chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </TableRow>
    );
};

const LicensePlateIdentifier = (p: {
    licensePlates: FilteredPermitsState.LicensePlate[];
}) =>
    p.licensePlates.length === 0 ? null : (
        <IdentificationLicensePlate {...p.licensePlates[0]} inlineMode={true} />
    );

const Badges = (p: { badges: FilteredPermitsState.Badge[] }) =>
    p.badges.length === 0 ? null : (
        <IdentificationBadge
            badgeLabelNr={p.badges[0].labelNr}
            rfidDecimal={null}
            inlineMode={true}
        />
    );

const IdColumnIdentifier = (p: {
    licensePlates: FilteredPermitsState.LicensePlate[];
    badges: FilteredPermitsState.Badge[];
}) => (
    <div
        className={css({
            display: 'flex',
            verticalAlign: 'bottom',
        })}
    >
        <LicensePlateIdentifier licensePlates={p.licensePlates} />
        <Badges badges={p.badges} />
        <Conditional c={p.licensePlates.length + p.badges.length > 1}>
            <div className={css({ paddingLeft: '8px' })}>
                <Icon16 icon={Icons16.moreHorizontal} />
            </div>
        </Conditional>
    </div>
);
