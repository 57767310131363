import {
    RoutedModal,
    RoutedModalController,
} from 'dg-web-shared/common/components/material-ui/routed-modal/RoutedModal.tsx';
import { DiscardChangesDialog } from '../../layout/components/DiscardChangesButton.tsx';

export enum ModalVariant {
    BIG = 'BIG',
    STANDARD = 'STANDARD',
    SMALL = 'SMALL',
    MOBILE = 'MOBILE',
}

export function OperatorRoutedModal({
    backUrl,
    render,
    variant,
    style,
}: {
    backUrl: string;
    render: (controller: RoutedModalController) => React.ReactNode;
    variant?: ModalVariant;
    style?: React.CSSProperties;
}) {
    const modalSize = getModalSize(variant);

    return (
        <RoutedModal
            open={true}
            backUrl={backUrl}
            style={{ ...modalSize, ...style, overflow: 'hidden' }}
            render={render}
            DiscardChangesDialog={DiscardChangesDialog}
        />
    );
}

function getModalSize(variant?: ModalVariant) {
    switch (variant) {
        case ModalVariant.BIG:
            return { minWidth: '1152px', width: '1152px', height: '80%' };
        case ModalVariant.STANDARD:
            return { minWidth: '1024px', width: '1024px', maxHeight: '800px' };
        case ModalVariant.SMALL:
            return { minWidth: '576px', width: '576px', maxHeight: '650px' };
        case ModalVariant.MOBILE:
            return { width: '90%', height: '60%' };
    }
}
