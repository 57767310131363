import Alert from '@mui/material/Alert';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { Localized } from '../../common/components/Localized.tsx';

interface OperatorAsyncLoadedSectionProps<DataType, ErrorType> {
    /**
     * renders the loading idicator with the specified preset values
     * if `renderPending` id passed, this prop is ignored§
     */
    pendingLoaderPreset?: LoadingSpinnerPresets;
    /**
     * the status of the request coming from `useServerFetch`
     */
    requestState: ServerRequestState<DataType, ErrorType>;
    /**
     * your page / data goes here.
     */
    render: (data: DataType) => JSX.Element;
    /**
     * overrides the default renderer for when the API call fails
     */
    renderError?: (data: ErrorType | null) => JSX.Element;
    /**
     * overrides the default renderer for when the API call is still pending
     */
    renderPending?: (data: DataType | null) => JSX.Element;
}

/**
 * Renders the common scenario of having an API that loads the data
 * taking care of displaying a loading indicator, an error or the actual
 * content depending on the status of the call.
 *
 * requires MaterialUI
 */
export function OperatorAsyncLoadedSection<DataType, ErrorType>({
    pendingLoaderPreset,
    requestState,
    render,
    renderError,
    renderPending,
}: OperatorAsyncLoadedSectionProps<DataType, ErrorType>): JSX.Element {
    if (
        requestState.status === RequestStatus.NEVER_EXECUTED ||
        requestState.status === RequestStatus.PENDING
    ) {
        return renderPending ? (
            renderPending(requestState.data)
        ) : (
            <PresetLoadingSpinner
                preset={
                    pendingLoaderPreset
                        ? pendingLoaderPreset
                        : LoadingSpinnerPresets.FillAllSpaceAndCenter
                }
            />
        );
    }

    if (requestState.status === RequestStatus.ERROR) {
        return renderError ? (
            renderError(requestState.data)
        ) : (
            <Alert severity="error">
                <Localized
                    de="ERROR - Die Daten konnten nicht geladen werden."
                    fr="ERREUR - Les données n'ont pas pu être téléchargées."
                    it="ERRORE - Non è stato possibile caricare i dati."
                    en="ERROR - Data could not be loaded."
                />
            </Alert>
        );
    }

    // despite the check (in case of the pending state, typing reads the field as possibly undefined from the type definition)
    return render(requestState.data!);
}
