export type UsbAppAction =
    | 'collection'
    | 'whitelist'
    | 'commission'
    | 'updateStick'
    | 'productionProcess'
    | 'writeConfiguration'
    | 'firmwareUpdate'
    | 'multiTomUpdate';

export namespace UsbAppAction {
    export const COLLECTION: UsbAppAction = 'collection';
    export const WHITELIST: UsbAppAction = 'whitelist';
    export const COMMISSION: UsbAppAction = 'commission';
    export const UPDATE_STICK: UsbAppAction = 'updateStick';
    export const WRITE_INTERNAL: UsbAppAction = 'productionProcess';
    export const WRITE_CONFIGURATION: UsbAppAction = 'writeConfiguration';
    export const FIRMWARE_UPDATE: UsbAppAction = 'firmwareUpdate';
    export const MULTI_TOM_UPDATE: UsbAppAction = 'multiTomUpdate';
}
