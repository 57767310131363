import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import {
    HalfSlideIn,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../../../ui/slidein/Slidein.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import { DateFilterSelection } from './DateFilter.tsx';
import { TomsFilterSelection } from '../TomFilter.tsx';
import { Translation } from '../../../common/i18n/Text.ts';
import { filterHalfSlideInTexts } from '../../i18n/CollectionsTexts.ts';
import { EmptyCollectionsFilterSelection } from './EmptyCollectionsFilterSelection.tsx';
import * as CollectionActions from '../../actions/CollectionsActions.ts';
import { CollectionSticksFilter } from './CollectionSticksFilter.tsx';

export interface FilterHalfSlideInTexts {
    title: Translation;
}

interface FilterHalfSlideInState {
    settings: SettingsState.State;
    filter: TomCollectionsState.Filter.State;
}

export class FilterHalfSlideIn extends Flux.Container<FilterHalfSlideInState> {
    stateSelector(): FilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: TomCollectionsState.Filter.get(this.props.allState),
        };
    }

    render() {
        return (
            <HalfSlideIn open={this.state.filter.filterSlideInVisible}>
                <StandardFirstLevelHeader
                    onClose={() =>
                        this.props.allState.update(store =>
                            TomCollectionsState.Filter.stateWrite(store, {
                                filterSlideInVisible: false,
                            }),
                        )
                    }
                    title={filterHalfSlideInTexts[
                        this.state.settings.language
                    ].title()}
                />
                <SlideInBody>
                    <DateFilterSelection allState={this.props.allState} />
                    <TomsFilterSelection
                        selectedToms={this.state.filter.toms}
                        onOpenSlideIn={() =>
                            this.update(store =>
                                TomCollectionsState.Filter.stateWrite(store, {
                                    tomSelectionVisible: true,
                                }),
                            )
                        }
                        onClear={() => this.update(CollectionActions.clearToms)}
                        allState={this.props.allState}
                    />
                    <CollectionSticksFilter />
                    <EmptyCollectionsFilterSelection
                        hideEmptyCollections={
                            this.state.filter.hideEmptyCollections
                        }
                        onChange={(hideEmptyCollections: boolean) =>
                            this.update(store =>
                                CollectionActions.setHideEmptyCollections(
                                    store,
                                    hideEmptyCollections,
                                ),
                            )
                        }
                        language={this.state.settings.language}
                    />
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}
