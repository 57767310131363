import { Localized } from './Localized.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const SystemStatus = () => {
    return (
        <a
            className={css({
                fontSize: 13,
                marginRight: 32,
                color: ColorHex.white,
                ...Typo.robotoRegular,
                ':hover': {
                    color: ColorHex.lightblue,
                },
            })}
            target="_blank"
            href="https://status.digitalparking.ch"
            rel="noreferrer"
        >
            <Localized
                de="Systemstatus"
                fr="État du système"
                it="Stato del sistema"
                en="System status"
            />
        </a>
    );
};
