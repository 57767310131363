import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { Option } from './Option.ts';
import { HalfSlideIn, SecondLevelHeader, SlideInBody } from './Slidein.tsx';
import { css } from '@emotion/css';
import { OperatorTypo } from '../OperatorTypo.ts';
import { ColorHex } from '../Colors.ts';
import React from 'react';

export type { Option } from './Option.ts';

interface Props<I> {
    open: boolean;
    heading: React.ReactNode;
    selection: I | null;
    options: Option<I>[];
    onLeftActionClick: () => void;
    onSelect: (value: I) => void;
    outsideBody?: boolean;
}

export class GenericSingleSelectionSlideIn<I> extends React.Component<
    Props<I>
> {
    static displayName = 'SingleSelectionSlideIn';

    renderElements(): JSX.Element[] {
        return this.props.options.map((o: Option<I>, key: number) => {
            return (
                <Clickable
                    key={key}
                    element="div"
                    disabled={o.disabled}
                    className={css([
                        {
                            ...OperatorTypo.headingOne,
                            fontWeight: 500,
                            lineHeight: '2em',
                            padding: '4px 48px',
                            margin: '0 -48px',
                            color: ColorHex.rgba(
                                ColorHex.darkblue,
                                o.disabled ? 0.4 : 0.8,
                            ),
                            '&:hover': o.disabled
                                ? undefined
                                : {
                                      background: ColorHex.darkblue,
                                      color: ColorHex.white,
                                  },
                        },
                        o.id === this.props.selection && {
                            color: ColorHex.rgba(ColorHex.darkblue, 0.8),
                            background: ColorHex.rgba(ColorHex.darkblue, 0.2),
                            '&:hover': o.disabled
                                ? undefined
                                : {
                                      background: ColorHex.darkblue,
                                      color: ColorHex.white,
                                  },
                        },
                    ])}
                    onClick={() => !o.disabled && this.props.onSelect(o.id)}
                >
                    {o.displayText}
                </Clickable>
            );
        });
    }

    render() {
        return (
            <HalfSlideIn
                open={this.props.open}
                outsideBody={this.props.outsideBody || false}
            >
                <SecondLevelHeader
                    onClose={this.props.onLeftActionClick}
                    title={this.props.heading}
                />
                <SlideInBody>{this.renderElements()}</SlideInBody>
            </HalfSlideIn>
        );
    }
}

// generic components crap, see https://github.com/Microsoft/TypeScript/issues/3960

export class NumberSingleSelectionSlideIn extends GenericSingleSelectionSlideIn<number> {}

export class StringSingleSelectionSlideIn extends GenericSingleSelectionSlideIn<string> {}
