import { css } from '@emotion/css';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { ColorHex } from '../ui/Colors.ts';
import { passwordChangeTokenTexts } from './i18n.ts';
import { BasicButton } from '../ui/buttons/BasicButton.tsx';
import { PasswordChangeTokenSource } from './ChangePasswordController.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function Success(props: Props) {
    const t = passwordChangeTokenTexts[props.language];

    return (
        <div className={style}>
            <p className={successMessageStyle}>{t.passwordChangeSucceeded()}</p>

            {props.source === PasswordChangeTokenSource.WEB && (
                <div className={buttonStyle}>
                    <BasicButton
                        label={t.proceedToParkingPortal()}
                        context="darkblue"
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    />
                </div>
            )}
        </div>
    );
}

const style = css`
    display: flex;
    flex-direction: column;
`;

const successMessageStyle = css`
    font-size: 26px;
    line-height: 34px;
    ${Typo.robotoLight};
    color: ${ColorHex.white};
    margin: 25px 25px 0px 25px;
`;

const buttonStyle = css`
    align-self: center;
    margin-top: 48px;
`;

interface Props {
    language: Language;
    source: PasswordChangeTokenSource;
}
