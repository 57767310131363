import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { statusFilterTexts } from './Texts.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { SingleSelection } from '../ui/labeled-elements/SingleSelection.tsx';
import { Filter, UI } from './ClearanceRequestsState.ts';
import * as PermitTypeState from '../common/state/PermitTypeState.ts';
import { StringSingleSelectionSlideIn } from '../ui/slidein/SingleSelectionSlideIn.tsx';

export interface StatusFilterTexts {
    filterTitle: Translation;
    valuePending: Translation;
    valueDeclined: Translation;
    valueAccepted: Translation;
}

const texts = (p: { settings: SettingsState.State }) =>
    statusFilterTexts[p.settings.language];

const setStatus = (
    store: Flux.Store,
    status: Filter.ClearanceRequestStatus,
) => {
    Filter.stateWrite(store, { status: status });
    UI.stateWrite(store, { statusSlideInVisible: false });
    return 'setStatus';
};

export const StatusFilterSlideIn = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
        permitTypes: PermitTypeState.get(store),
        ui: UI.get(store),
    }),
    p => (
        <StringSingleSelectionSlideIn
            open={p.ui.statusSlideInVisible}
            heading={texts(p).filterTitle()}
            selection={p.filter.status}
            options={[
                { id: 'pending', displayText: texts(p).valuePending() },
                {
                    id: 'declined',
                    displayText: texts(p).valueDeclined(),
                },
                {
                    id: 'accepted',
                    displayText: texts(p).valueAccepted(),
                },
            ]}
            onLeftActionClick={() =>
                p.update(store =>
                    UI.stateWrite(store, {
                        statusSlideInVisible: false,
                    }),
                )
            }
            onSelect={v =>
                p.update(store =>
                    setStatus(store, v as Filter.ClearanceRequestStatus),
                )
            }
        />
    ),
);

export const statusSelection = (
    selection: Filter.ClearanceRequestStatus | null,
    p: {
        settings: SettingsState.State;
    },
) => {
    switch (selection) {
        case 'pending':
            return texts(p).valuePending();
        case 'declined':
            return texts(p).valueDeclined();
        case 'accepted':
            return texts(p).valueAccepted();
        default:
            return null;
    }
};

export const StatusFilterSelection = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
        permitTypes: PermitTypeState.get(store),
    }),
    p => (
        <SingleSelection
            label={texts(p).filterTitle()}
            focused={false}
            selection={statusSelection(p.filter.status, p)}
            onClick={() =>
                p.update(store =>
                    UI.stateWrite(store, {
                        statusSlideInVisible: true,
                    }),
                )
            }
            onClear={
                p.filter.status
                    ? () =>
                          p.update(store =>
                              Filter.stateWrite(store, { status: null }),
                          )
                    : null
            }
        />
    ),
);
