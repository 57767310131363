import { selectState, Store, Updater } from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as UsbStickState from '../../../usb-sticks/state/UsbStickState.ts';
import { NumberMultiSelectionSlideIn } from '../../../ui/slidein/MultiSelectionSlideIn.tsx';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import { contentBodySlideInPortal } from '../../../ui/layout/Shared.tsx';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import { tomSelectionText } from '../TomFilterHalfSlideIn.tsx';
import { clearSticks } from '../../actions/CollectionsActions.ts';
import {
    UsbStick,
    UsbStickState as UsbStickStateEnum,
} from 'dg-web-shared/model/UsbStick.ts';
import { collectionsFilterTexts } from '../../i18n/CollectionsTexts.ts';

interface State {
    settings: SettingsState.State;
    sticks: UsbStickState.List.State;
    filter: TomCollectionsState.Filter.State;
}

export const filterSticks = (s: UsbStick): boolean => {
    return (
        s.type === 'collection' && s.state === UsbStickStateEnum.commissioned
    );
};

export const CollectionSticksFilter = selectState(
    (store: Store): State => ({
        settings: new SettingsState.StateSlice(store).state,
        sticks: UsbStickState.List.get(store),
        filter: TomCollectionsState.Filter.get(store),
    }),
    p => {
        if (p.sticks.pending || !p.sticks.data) {
            return null;
        }

        const texts = collectionsFilterTexts[p.settings.language];

        return (
            <div>
                <SingleSelection
                    label={texts.Sticks()}
                    focused={false}
                    selection={tomSelectionText(
                        p.filter.sticks,
                        p.sticks.data.filter(filterSticks).length,
                    )}
                    onClick={() =>
                        p.update(store =>
                            TomCollectionsState.Filter.stateWrite(store, {
                                sticksSelectionVisible: true,
                            }),
                        )
                    }
                    onClear={
                        p.filter.sticks.length > 0
                            ? () => p.update(clearSticks)
                            : null
                    }
                />
                <CollectionSticksFilterSlideIn {...p} />
            </div>
        );
    },
);

export const CollectionSticksFilterSlideIn = contentBodySlideInPortal<
    State & { update: Updater }
>(p => {
    const texts = collectionsFilterTexts[p.settings.language];
    return (
        <NumberMultiSelectionSlideIn
            open={p.filter.sticksSelectionVisible}
            heading={texts.Sticks()}
            selection={p.filter.sticks}
            options={
                p.sticks.data &&
                p.sticks.data.filter(filterSticks).map(s => {
                    return {
                        id: s.stickId as number,
                        displayText: s.name,
                    };
                })
            }
            setNewSelection={selection =>
                p.update((store: Store): string => {
                    const name = TomCollectionsState.Filter.stateWrite(store, {
                        sticks: selection,
                    });

                    TomCollectionsState.List.reset(store);
                    return name;
                })
            }
            onLeftActionClick={() =>
                p.update(store =>
                    TomCollectionsState.Filter.stateWrite(store, {
                        sticksSelectionVisible: false,
                    }),
                )
            }
            outsideBody={false}
        />
    );
});
