import { Translations } from '../../common/i18n/Text.ts';
import * as ClearanceCreateSlideIn from '../components/ClearanceCreateSlideIn.tsx';
import * as ClearanceSecondLevelSlideIns from '../components/ClearanceSecondLevelSlideIns.tsx';

/* tslint:disable:typedef max-line-length */

export const texts: Translations<ClearanceCreateSlideIn.Texts> = {
    de: {
        HeaderCaption: () => 'Freigaben erfassen',
        LicensePlateType: () => 'Schildart',
        Country: () => 'Land',
        LicensePlate: () => 'Kennzeichen',
        Badge: () => 'Badge',
        CarType: () => 'Motorwagen',
        MotorcycleType: () => 'Motorrad',
        LicensePlateAlreadyHasClearance: () => 'Bereits vorhandene Freigabe',
        PermitType: () => 'Bewilligungsarten',
        CreateError: () => 'Die Freigabe konnte nicht erfasst werden.',
        PriceMultiplierError: () => 'Preiskategorie zwingend',
        PriceMultiplier: () => 'Preiskategorie',
        CarTypeDescription: () => 'Fahrzeugtyp',
    },
    fr: {
        HeaderCaption: () => 'Saisir approbation',
        LicensePlateType: () => 'Type de plaque',
        Country: () => 'Pays',
        LicensePlate: () => 'Immatriculation',
        Badge: () => 'Badge',
        CarType: () => 'Voiture',
        MotorcycleType: () => 'Moto',
        LicensePlateAlreadyHasClearance: () => 'Approbations déjà existantes',
        PermitType: () => "Types d'autorisations",
        CreateError: () => "L'approbation n'a pas pu être saise.",
        PriceMultiplierError: () => 'Catégorie de prix obligatoire',
        PriceMultiplier: () => 'Catégorie de prix',
        CarTypeDescription: () => 'Type de véhicule',
    },
    it: {
        HeaderCaption: () => 'Registra approvazione',
        LicensePlateType: () => 'Tipo di targa',
        Country: () => 'Nazione',
        LicensePlate: () => 'Targa',
        Badge: () => 'Badge',
        CarType: () => 'Automobile',
        MotorcycleType: () => 'Motocicletta',
        LicensePlateAlreadyHasClearance: () => 'Approvazioni già esistenti',
        PermitType: () => "Tipi d'autorizzazione",
        CreateError: () => "L'approvazione non ha potuto essere registrata.",
        PriceMultiplierError: () => 'Categoria di prezzo obbligatoria',
        PriceMultiplier: () => 'Categoria di prezzo',
        CarTypeDescription: () => 'Tipo veicolo',
    },
};

export const countrySelection: Translations<ClearanceSecondLevelSlideIns.LpSlideinTexts> =
    {
        de: {
            Caption: () => texts.de.HeaderCaption(),
            Heading: () => 'Land',
        },
        fr: {
            Caption: () => 'Saisir approbation',
            Heading: () => 'Pays',
        },
        it: {
            Caption: () => 'Registra approvazione',
            Heading: () => 'Nazione',
        },
    };

export const permitTypeSelection: Translations<ClearanceSecondLevelSlideIns.PermitTypeSelectionTexts> =
    {
        de: {
            PermitTypes: () => 'Bewilligungsart auswählen',
        },
        fr: {
            PermitTypes: () => "Choisier le type d'autorisation",
        },
        it: {
            PermitTypes: () => "Scegliere il tipo d'autorizzazione",
        },
    };

export const priceMultiplierSelection: Translations<ClearanceSecondLevelSlideIns.PriceMultiplierSelectionSlideInTexts> =
    {
        de: {
            Heading: () => 'Preiskategorie',
        },
        fr: {
            Heading: () => 'Catégorie de prix',
        },
        it: {
            Heading: () => 'Categoria di prezzo',
        },
    };
