import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { FullSlideIn } from '../../ui/slidein/Slidein.tsx';
import * as AddressState from '../state/AddressState.ts';
import * as LicensePlateState from '../state/LicensePlateState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import { PermitCreate } from './PermitCreate.tsx';
import { PermitEdit } from './PermitEdit.tsx';

interface State {
    clearancePermitList: ClearancePermitListState.State;
    permitCreateLayout: PermitCreateState.Layout.State;
    permitType: Maybe<PermitTypeState.PermitType>;
    edit: PermitEditState.Edit.State;
    editLayout: PermitEditState.Layout.State;
    licensePlate: LicensePlateState.Entity.State;
    addLp: LicensePlateState.AddTripple.State;
    addressSelect: AddressState.Select.State;
}

interface EditContextEnabledProps {
    permitCreateLayout: PermitCreateState.Layout.State;
    clearancePermitList: ClearancePermitListState.State;
}
export const editContextEnabled = (s: EditContextEnabledProps): boolean => {
    const permitCreateLayout = s.permitCreateLayout;
    return (
        !permitCreateLayout.createEnabled &&
        isDefined(s.clearancePermitList.selectedPermitId)
    );
};

interface CreateContextEnabledProps {
    permitCreateLayout: PermitCreateState.Layout.State;
    permitType: Maybe<PermitTypeState.PermitType>;
}
export const createContextEnabled = (s: CreateContextEnabledProps): boolean => {
    return s.permitCreateLayout.createEnabled;
};

export class PermitSlideIn extends Flux.Container<State> {
    static displayName = 'PermitSlideIn';

    stateSelector(): State {
        return {
            clearancePermitList: new ClearancePermitListState.StateSlice(
                this.props.allState,
            ).state,
            permitCreateLayout: PermitCreateState.Layout.get(
                this.props.allState,
            ),
            permitType: PermitTypeState.getById(
                this.props.allState,
                PermitCreateState.Config.get(this.props.allState).permitTypeId,
            ),
            edit: PermitEditState.Edit.get(this.props.allState),
            editLayout: PermitEditState.Layout.get(this.props.allState),
            licensePlate: LicensePlateState.Entity.get(this.props.allState),
            addLp: LicensePlateState.AddTripple.get(this.props.allState),
            addressSelect: AddressState.Select.get(this.props.allState),
        };
    }

    renderContent(): Maybe<JSX.Element> {
        if (editContextEnabled(this.state)) {
            return <PermitEdit />;
        } else if (createContextEnabled(this.state)) {
            return <PermitCreate allState={this.props.allState} />;
        } else {
            return null;
        }
    }

    isDisabled(): boolean {
        if (editContextEnabled(this.state)) {
            return (
                this.state.editLayout.showPrintSlideIn ||
                isDefined(this.state.licensePlate.id) ||
                this.state.addressSelect.selectAddressOpen
            );
        } else {
            return (
                this.state.addLp.createLpOpen ||
                this.state.permitCreateLayout.vignetteCountSelectionOpen ||
                this.state.permitCreateLayout.validityFromDatePickerOpen ||
                this.state.permitCreateLayout.validityToDatePickerOpen ||
                this.state.permitCreateLayout.validityDurationPickerOpen ||
                this.state.permitCreateLayout.permitTypeSelectionOpen ||
                this.state.permitCreateLayout.zonePickerOpen ||
                isDefined(this.state.licensePlate.id) ||
                this.state.addressSelect.selectAddressOpen
            );
        }
    }
    render() {
        const content = this.renderContent();
        return (
            <FullSlideIn
                open={isDefined(content)}
                outsideBody
                disabled={this.isDisabled()}
            >
                {content}
            </FullSlideIn>
        );
    }
}
