import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/ExternalHttp.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    GithubRelease,
    parseRelease,
} from 'dg-web-shared/model/GithubRelease.ts';

export namespace Layout {
    export interface State {
        open: boolean;
        loading: boolean;
        showNotInstalledHint: boolean;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'SyncAppHelpSliedIn-Layout',
        {
            open: false,
            loading: false,
            showNotInstalledHint: false,
        },
    );
}

export namespace LatestRelease {
    export type State = ServerStateSlice.ServerState<Maybe<GithubRelease>>;

    const shouldFetch = (_store: Flux.Store, state: State): boolean => {
        return state.shouldFetch;
    };

    const generateLatestReleaseState = (
        key: string,
        fetcher: (store: Flux.Store) => Flux.Update<State>,
    ) => {
        return ServerStateSlice.generateServerState<Maybe<GithubRelease>>(
            key,
            () => null, // initial state
            (store: Flux.Store, state: State) => {
                if (shouldFetch(store, state)) {
                    store.update(fetcher(store));
                }
            },
            body => parseRelease(body),
        );
    };

    function fetchLatestRelease(): Flux.Update<State> {
        return AsyncRequest.request(
            () => Http.SyncAppGithubRelease.Latest.get(),
            setResponse,
        );
    }

    export const { get, reset, setResponse } = generateLatestReleaseState(
        'SyncAppHelpSliedIn-LatestGithubRelease',
        fetchLatestRelease,
    );
}
