import { tomOperationStatusTexts } from './tomOperationStatusTexts.ts';
import { getOrElse, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Form, Label, SemanticCOLORS } from 'semantic-ui-react';
import moment from 'moment/moment';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { ParsedTom, TomWithOperatorName } from 'dg-web-shared/model/Tom.ts';

/**
 * code 0 is always present without any further information if the TOM is out of service.
 * All the other error and alarm codes will indicate why this is the case.
 */
export const OUT_OF_SERVICE_ALARM_CODE = 0;

export interface OperationStatusDetailProps {
    tom: ParsedTom | TomWithOperatorName;
    language: string;
    showLabel?: Maybe<boolean>;
    tomAlertList: JSX.Element;
}

export function OperationStatusDetail(
    props: OperationStatusDetailProps,
): JSX.Element {
    const txt = tomOperationStatusTexts[props.language];
    const showLabel = getOrElse(props.showLabel, true);
    const labelColor = getOperationStatusLabelColor(props.tom);
    const label = showLabel ? (
        <Label circular color={labelColor} empty />
    ) : null;
    const operationStatusText = getOperationStatusText(
        props.tom,
        props.language,
    );

    const hasErrors = props.tom.errorCodes.length > 0;
    const tomAlarmCodes = props.tom.alarmCodes.filter(
        a => a !== OUT_OF_SERVICE_ALARM_CODE,
    );
    const hasAlarms = tomAlarmCodes.length > 0;

    const lastOnlineStatusMoment = moment.isMoment(props.tom.lastOnlineStatus)
        ? props.tom.lastOnlineStatus
        : moment(props.tom.lastOnlineStatus);

    return (
        <div style={{ marginBottom: '1em' }}>
            <Form.Field>
                <label>{txt.OperationStatus()}</label>
                <div>
                    <div>
                        {label} {operationStatusText}
                    </div>
                    {props.tom.offline && props.tom.lastOnlineStatus && (
                        <div>
                            {txt.LastContact()}
                            :&nbsp;
                            {Date.Formatter.dateWithDurationFromNow(
                                lastOnlineStatusMoment,
                                props.language,
                            )}
                        </div>
                    )}
                </div>
            </Form.Field>
            <Conditional c={hasErrors || hasAlarms}>
                <Form.Field>
                    <label>{txt.StatusMessages()}</label>
                    {props.tomAlertList}
                </Form.Field>
            </Conditional>
        </div>
    );
}

function getOperationStatusText(
    tom: ParsedTom | TomWithOperatorName,
    language: string,
): string {
    const txt = tomOperationStatusTexts[language];
    if (tom.offline) {
        return txt.Offline();
    } else if (tom.outOfService) {
        return txt.OutOfService();
    } else {
        return txt.InService();
    }
}

export function getOperationStatusLabelColor(
    tom: ParsedTom | TomWithOperatorName,
): SemanticCOLORS {
    const hasAlarms = tom.alarmCodes && tom.alarmCodes.length;
    const hasErrors = tom.errorCodes && tom.errorCodes.length;
    let labelColor: SemanticCOLORS = 'green';
    if (tom.outOfService || tom.offline) {
        labelColor = 'red';
    } else if (hasAlarms || hasErrors) {
        labelColor = 'yellow';
    }
    return labelColor;
}
