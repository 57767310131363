import moment from 'moment';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as TariffState from '../state/TariffState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Translation, TranslationWithArgs1 } from '../../common/i18n/Text.ts';
import { tariffCalculatorTexts } from '../i18n/TariffTexts.ts';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import {
    RequestMethod,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export interface TariffCalculatorTexts {
    TariffCalculator: Translation;
    Date: Translation;
    Time: Translation;
    InheritedMinutes: Translation;
    PayedAmount: Translation;
    ResultingParkingTime: Translation;

    MissingInput: Translation;
    DateIsMandatory: Translation;
    TimeMustBeHHMM: Translation;
    InheritedTimeMustBePositiveNumber: Translation;
    AmountMustBePositiveNumber: Translation;

    MaximumParkingTimeReached: Translation;
    NetInheritedTime: TranslationWithArgs1<number>;
    ParkingTimeMinutes: TranslationWithArgs1<number>;
    UntilDate: TranslationWithArgs1<moment.Moment>;
}

interface Props extends Flux.ContainerProps {
    tariffId: number;
}

interface State {
    settings: SettingsState.State;
    layout: TariffState.Layout.State;
    calculator: TariffState.Calculator.State;
}

interface PricePayload {
    start: string;
    chf: number;
}

interface PriceResponse {
    minutes: number;
    maxTimeReached: boolean;
}

export function TariffCalculator(props: Props) {
    const { storeState, update } = Flux.useStore<State>(s => ({
        settings: new SettingsState.StateSlice(s).state,
        layout: TariffState.Layout.get(s),
        calculator: TariffState.Calculator.get(s),
    }));
    const txt = tariffCalculatorTexts[storeState.settings.language];
    const selectedDate = storeState.calculator.date;
    const startMoment = getStartMoment(storeState.calculator);
    const showErrors = isDefined(selectedDate); // show errors if at least the date was selected

    const payload: PricePayload | null =
        !!startMoment && !!storeState.calculator.amount
            ? {
                  start: startMoment?.toISOString(),
                  chf: parseFloat(storeState.calculator.amount),
              }
            : null;
    const [calcState] = useServerFetch<PriceResponse, PricePayload>(
        context => ({
            url: `/ui-api/operator-account/taxomex/tariff/${props.tariffId}/calculate-tomeco`,
            method: RequestMethod.POST,
            body: context,
        }),
        payload,
    );

    return (
        <div>
            <div
                className={css({
                    ...Typo.captionC3,
                    color: Colors.darkblue,
                    marginTop: '48px',
                })}
            >
                {txt.TariffCalculator()}
            </div>
            <SingleSelection
                focused={false}
                label={txt.Date()}
                onClick={() =>
                    update(store =>
                        TariffState.Layout.stateWrite(store, {
                            dateSelectionOpen: true,
                        }),
                    )
                }
                selection={
                    isDefined(selectedDate)
                        ? Formatter.dayMonthYear(selectedDate)
                        : ' — '
                }
            />
            <TextField
                value={storeState.calculator.time || ''}
                label={txt.Time()}
                onChange={v =>
                    update(store =>
                        TariffState.Calculator.stateWrite(store, {
                            time: v as string,
                        }),
                    )
                }
                inputType="text"
                errorText={
                    showErrors && !isTimeValid(storeState.calculator.time)
                        ? txt.TimeMustBeHHMM()
                        : undefined
                }
            />

            <TextField
                value={storeState.calculator.amount || ''}
                label={txt.PayedAmount()}
                onChange={v =>
                    update(store =>
                        TariffState.Calculator.stateWrite(store, {
                            amount: v as string,
                        }),
                    )
                }
                inputType="text"
                errorText={
                    showErrors && !isAmountValid(storeState.calculator.amount)
                        ? txt.AmountMustBePositiveNumber()
                        : undefined
                }
            />

            <LabeledText label={txt.ResultingParkingTime()}>
                {calcState?.data ? (
                    <div>
                        <b>{txt.ParkingTimeMinutes(calcState.data.minutes)}</b>
                        <br />
                        <b>
                            {txt.UntilDate(
                                moment(startMoment || undefined).add(
                                    calcState.data.minutes,
                                    'minutes',
                                ),
                            )}
                        </b>
                        {calcState.data.maxTimeReached && (
                            <div>{txt.MaximumParkingTimeReached()}</div>
                        )}
                    </div>
                ) : (
                    txt.MissingInput()
                )}
            </LabeledText>
        </div>
    );
}

function isTimeValid(time: string | null | undefined): boolean {
    return isDefined(time) && moment.utc(time, 'H:mm').isValid();
}

function isAmountValid(amountString: string | null | undefined): boolean {
    const amount = parseFloat(amountString || '0');
    return !isNaN(amount) && amount >= 0;
}

function getStartMoment(
    calculator: TariffState.Calculator.State,
): moment.Moment | undefined {
    const date = calculator.date;

    if (!calculator.time) {
        return;
    }
    const time = moment(calculator.time, 'H:mm');

    if (!date || !time.isValid()) {
        return;
    }
    return date.hour(time.hour()).minute(time.minute());
}
