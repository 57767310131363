import { FilterConfig, setSearchURL } from './OperatorFilterHelpers.tsx';
import { ServerRequestState } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Box, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchInputField } from '../../app/components/themable/SearchInputField.tsx';
import { OperatorParkingaboUsersFilters } from '../../parkingabo-users/OperatorParkingaboUsersFilterList.tsx';
import {
    LastUpdateTimestampAndRefresh,
    LastUpdateTimestampAndRefreshAsyncLoadedSection,
} from '../../ui/LastUpdateTimestampAndRefresh.tsx';
import { FilteredSerchResult } from './OperatorFilterConfiguration.tsx';
import React from 'react';

export function FiltersBox({
    searchText,
    activeFilters,
    filterConfig,
    requestState,
    refetch,
    pending,
}: {
    searchText: string | null;
    activeFilters: FilterConfig;
    filterConfig: FilterConfig;
    requestState: ServerRequestState<FilteredSerchResult, null>;
    refetch: () => void;
    pending: boolean;
}) {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <FilterBar>
            <SearchInputField
                value={searchText}
                onChange={searchText =>
                    setSearchURL(navigate, location, searchText, activeFilters)
                }
            />
            <Box
                sx={{
                    backgroundColor: theme => theme.palette.primary.main,
                    width: 2,
                    height: '100%',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                }}
            >
                <OperatorParkingaboUsersFilters
                    activeFilters={activeFilters}
                    filterConfig={filterConfig}
                    onFiltersStateChange={activeFilters =>
                        setSearchURL(
                            navigate,
                            location,
                            searchText,
                            activeFilters,
                        )
                    }
                    pending={pending}
                />
                <LastUpdateTimestampAndRefreshAsyncLoadedSection
                    requestState={requestState}
                    onRefresh={refetch}
                    render={(list, timestamp) => (
                        <LastUpdateTimestampAndRefresh
                            resultsCount={list.results.length}
                            eof={list.eof}
                            onRefresh={refetch}
                            timestamp={timestamp}
                        />
                    )}
                />
            </Box>
        </FilterBar>
    );
}

export function FilterBar({ children }: { children: React.ReactNode }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{
                    height: 55,
                    paddingX: 3,
                    backgroundColor: 'blue.light',
                }}
            >
                {children}
            </Stack>
        </Box>
    );
}
