import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import * as FilteredPermitsState from '../../clearance-permit-list/state/FilteredPermitsState.ts';
import * as AddressState from '../state/AddressState.ts';

export const selectPermit = (store: Flux.Store, id: number): string => {
    new ClearancePermitListState.StateSlice(store).selectPermit(id);
    return 'selectPermit';
};

export const receivePermitDetail = AsyncRequest.request(
    Http.OperatorAccount.Permits.detail,
    (store: Flux.Store, res: Response): string => {
        PermitEditState.Server.setResponse(store, res);
        return 'PermitDetailActions-receivePermitDetail';
    },
);

export const deselectPermit = (store: Flux.Store): string => {
    PermitEditState.Server.reset(store);
    new ClearancePermitListState.StateSlice(store).selectPermit(null);
    PermitEditState.resetAllStates(store);
    AddressState.resetAll(store);
    return 'deselectPermit';
};

export const editPermit = AsyncRequest.request(
    Http.OperatorAccount.Permits.edit,
    (store: Flux.Store, res: Response): string => {
        PermitEditState.EditResponse.setResponse(store, res);
        if (res.statusCode.cls.success && res.body.id) {
            PermitEditState.Edit.reset(store);
            PermitEditState.Server.setResponse(store, res);
            ResultState.refetchSameContext(store, true);
            FilteredPermitsState.List.reset(store);
        }
        return 'PermitEdit-editPermit';
    },
);
