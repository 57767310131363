import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useOperatorContext } from './BaseLayoutAndData.tsx';
import { DEVICE_UUID } from '../../common/state/CurrentOperatorLoginState.ts';
import {
    findMatchingUrlAction,
    QrScanActionType,
} from 'dg-web-shared/common/utils/QrScanPatternMatching.tsx';
import { RecodeError } from '../../cloud-connector/CloudConnectorRecodeTicketConditionsOutlet.tsx';

type QRCodeScanner = () => void;

interface NativeBridge {
    scanQRCode: QRCodeScanner | null;
    requestFCMToken: () => void;
    notificationSettings: () => void;
    appTokenV2: (
        appToken: string | null,
        uuid: string,
        version: string,
    ) => void;
}

export const getBridge = (): NativeBridge | null => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (w.android) {
        return w.android;
    } else if (w.webkit && w.webkit.messageHandlers) {
        return {
            scanQRCode: scanQRCodeHandler(),
            requestFCMToken: getRequestFCMTokenHandler(),
            notificationSettings: getNotificationSettingsHandler(),
            appTokenV2: getAppTokenHandler(),
        };
    } else {
        return null;
    }
};

export function isRunningInNativeWrapper() {
    return Boolean(getBridge());
}

export function setAppToken(appToken: string | null) {
    const bridge = getBridge();
    if (bridge && bridge.appTokenV2) {
        bridge.appTokenV2(appToken, DEVICE_UUID, SENTRY_RELEASE.id);
    }
}

function scanQRCodeHandler(): QRCodeScanner | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.scanQRCode
    ) {
        return () => w.webkit.messageHandlers.scanQRCode.postMessage(null);
    }
    return null;
}

function getRequestFCMTokenHandler() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.requestFCMToken
    ) {
        return () => w.webkit.messageHandlers.requestFCMToken.postMessage(null);
    }
    return () => null;
}

function getNotificationSettingsHandler() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.notificationSettings
    ) {
        return () =>
            w.webkit.messageHandlers.notificationSettings.postMessage(null);
    }
    return () => null;
}

function getAppTokenHandler() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.appToken
    ) {
        return (appToken: string | null, uuid: string, appVersion: string) =>
            w.webkit.messageHandlers.appToken.postMessage({
                appToken,
                uuid,
                appVersion,
            });
    }
    return () => null;
}

const enum NativeDeviceMessageType {
    FCMToken = 'FCMToken',
    PushNotificationCallback = 'PushNotificationCallback',
    QRScannerCallback = 'QRScannerCallback',
}

type MessageFromNativeDeviceCallback = (
    type: NativeDeviceMessageType,
    message: string,
) => void;

export type FcmToken = {
    token: string | null;
    status: boolean | null;
};
export type NativeContextState = {
    fcmToken: FcmToken;
};

function emptyNativeContext() {
    return {
        fcmToken: { token: null, status: null },
    };
}

const NativeContext = createContext<NativeContextState>(
    emptyNativeContext() as NativeContextState,
);

export function useNativeContext() {
    return useContext(NativeContext);
}

type WindowWithBridge = {
    messageFromNativeDevice: MessageFromNativeDeviceCallback;
} & Window &
    typeof globalThis;

export function NativeContextProdivder() {
    const [nativeState, setNativeState] = useState(emptyNativeContext);
    const { currentLogin } = useOperatorContext();
    const navigate = useNavigate();
    useEffect(() => {
        (window as WindowWithBridge).messageFromNativeDevice = (
            type: NativeDeviceMessageType,
            message: string,
        ) => {
            switch (type) {
                case NativeDeviceMessageType.FCMToken:
                    return setNativeState({
                        ...nativeState,
                        fcmToken: JSON.parse(message),
                    });
                case NativeDeviceMessageType.PushNotificationCallback:
                    return 'ok';
                case NativeDeviceMessageType.QRScannerCallback: {
                    const urlAction = findMatchingUrlAction(message);

                    switch (urlAction.action) {
                        case QrScanActionType.PARSING_FAILED:
                        case QrScanActionType.SCAN_PERMIT_URL:
                        case QrScanActionType.SCAN_ZONE_URL:
                        case QrScanActionType.SCAN_UNKNOWN:
                            {
                                navigate(
                                    `/mobile/recode/recode-error/${RecodeError.PARSING_FAILED}`,
                                );
                            }
                            return 'ok';
                        case QrScanActionType.SCAN_TICKET_URL: {
                            const ticketScan =
                                urlAction.url.pathname.split('/t/')[1];
                            navigate(`/mobile/recode/ticket/${ticketScan}`);
                        }
                    }

                    return 'ok';
                }
                default:
                    return 'unknownMessageFromDevice';
            }
        };
    }, []);

    useEffect(() => {
        if (currentLogin == null) {
            return;
        }
        const fcmToken = currentLogin.fcmToken;
        const fcmState = nativeState.fcmToken;
        if (fcmState.token === null) {
            const bridge = getBridge();
            if (bridge && bridge.requestFCMToken) {
                bridge.requestFCMToken();
            }
        }

        if (
            fcmState.status !== null &&
            (fcmToken === null ||
                fcmState.token !== fcmToken.token ||
                fcmState.status !== fcmToken.status)
        ) {
            fetch('/ui-api/operator-account/fcm-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operatorLoginDeviceId: DEVICE_UUID,
                    fcmToken: fcmState.token,
                    status: fcmState.status,
                }),
            });
        }
    }, [
        nativeState.fcmToken.token,
        nativeState.fcmToken.status,
        currentLogin.fcmToken,
    ]);

    return (
        <NativeContext.Provider
            value={{
                ...nativeState,
            }}
        >
            <Outlet />
        </NativeContext.Provider>
    );
}
