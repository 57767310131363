export enum CloudConnectorPeripheryType {
    ENTRY = 'ENTRY',
    EXIT = 'EXIT',
    PAY_STATION = 'PAY_STATION',
    TRANSIT_ENTRY = 'TRANSIT_ENTRY',
    TRANSIT_EXIT = 'TRANSIT_EXIT',
    ACCESS = 'ACCESS',
}

export enum CloudConnectorPeripheryConfigState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    UNCONFIGURED = 'UNCONFIGURED',
}
