import {
    Alert,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import {
    ServerRequestState,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Localized } from '../common/components/Localized.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { DateTime } from 'luxon';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { Language, languageFromString } from 'dg-web-shared/lib/Localized.ts';
import * as SettingsState from '../common/state/SettingsState.ts';
import {
    LastUpdateTimestampAndRefresh,
    LastUpdateTimestampAndRefreshAsyncLoadedSection,
} from '../ui/LastUpdateTimestampAndRefresh.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import {
    DetailHeader,
    DetailTabs,
    ParkingaboUserInfoTab,
    useParkingaboUserDetailOutletContext,
    UserDeailsSearchBar,
} from './OperatorParkingaboUsersDetail.tsx';
import { TenantPaymentMode } from 'product-shared/tenant/TenantEnums.ts';

interface ParkingaboAccountStatementResults {
    results: AccountStatementLine[];
    eof: boolean;
}

interface AccountStatementLine {
    date: string;
    remarks: string[];
    formattedPrice: string;
    formattedSaldo: string | null;
    occurrences: number;
    isError: boolean;
    firstOccurrence: string;
}

export function OperatorParkingaboUsersAccountStatement() {
    const { parkingaboUser } = useParkingaboUserDetailOutletContext();
    const { storeState } = useStore(s => ({
        language: languageFromString(
            new SettingsState.StateSlice(s).state.language,
        ),
    }));
    const [transactionsState, refetchList] = useServerFetch<
        ParkingaboAccountStatementResults,
        { customerNr: string; language: Language },
        null
    >(
        context => ({
            url: `/ui-api/operator-account/parkingabo/customer/${context.customerNr}/account-statement?lang=${context.language}`,
        }),
        {
            customerNr: parkingaboUser.customerNr,
            language: storeState.language,
        },
    );
    return (
        <OperatorRoutedModalContent
            header={<DetailHeader />}
            bar={
                <DetailTabs
                    customerNr={parkingaboUser.customerNr}
                    parkingaboUserInfoTab={
                        ParkingaboUserInfoTab.ACCOUNT_STATEMTENT
                    }
                    tenantHasDigitalPurchase={
                        parkingaboUser.tenant.paymentMode ===
                        TenantPaymentMode.DIGITAL_PURCHASE
                    }
                />
            }
            style={{ padding: '0px 0px' }}
            body={
                <>
                    <UserDeailsSearchBar
                        refreshButton={
                            <LastUpdateTimestampAndRefreshAsyncLoadedSection
                                requestState={transactionsState}
                                onRefresh={refetchList}
                                render={(transactions, timestamp) => (
                                    <LastUpdateTimestampAndRefresh
                                        resultsCount={
                                            transactions.results.length
                                        }
                                        eof={transactions.eof}
                                        onRefresh={refetchList}
                                        timestamp={timestamp}
                                    />
                                )}
                            />
                        }
                    />
                    <Box
                        style={{
                            flex: '1 1 0',
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'auto',
                        }}
                    >
                        <OperatorParkingaboUsersTransactionsTable
                            transactionsState={transactionsState}
                        />
                    </Box>
                </>
            }
        />
    );
}

interface OperatorParkingaboUsersTransactionsTableProps {
    transactionsState: ServerRequestState<
        ParkingaboAccountStatementResults,
        null
    >;
}

function OperatorParkingaboUsersTransactionsTable({
    transactionsState,
}: OperatorParkingaboUsersTransactionsTableProps): JSX.Element {
    return (
        <OperatorAsyncLoadedSection
            requestState={transactionsState}
            render={transactions => {
                if (transactions.results.length <= 0) {
                    return (
                        <Alert severity="info">
                            <Localized
                                de="Dieser Benutzer hat keine Bewegungen."
                                fr="Cet utilisateur n'a pas de mouvements."
                                it="Questo utente non ha movimenti."
                                en="This user has no movements."
                            />
                        </Alert>
                    );
                }

                return (
                    <TableContainer
                        sx={{
                            flex: '1 1 0',
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Localized
                                            de="Datum"
                                            fr="Date"
                                            it="Data"
                                            en="Date"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Localized
                                            de="Beschreibung"
                                            fr="Description"
                                            it="Descrizione"
                                            en="Description"
                                        />
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>
                                        <Localized
                                            de="Preis"
                                            fr="Prix"
                                            it="Prezzo"
                                            en="Price"
                                        />
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>
                                        <Localized
                                            de="Saldo"
                                            fr="Solde"
                                            it="Saldo"
                                            en="Balance"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.results.map(transaction => (
                                    <TableRow
                                        key={transaction.date}
                                        sx={{
                                            backgroundColor: theme =>
                                                transaction.isError
                                                    ? 'rgba(239, 83, 80,0.1)'
                                                    : theme.palette.background
                                                          .default,
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                color: theme =>
                                                    transaction.isError
                                                        ? 'rgb(239, 83, 80)'
                                                        : theme.palette.blue
                                                              .main,
                                            }}
                                        >
                                            {DateTime.fromISO(
                                                transaction.firstOccurrence,
                                            ).toFormat('dd.MM.yyyy – HH:mm')}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: theme =>
                                                    transaction.isError
                                                        ? 'rgb(239, 83, 80)'
                                                        : theme.palette.blue
                                                              .main,
                                            }}
                                        >
                                            {formatDescription(transaction)}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: 'right',
                                                fontStyle: transaction.isError
                                                    ? 'italic'
                                                    : '',
                                                color: theme =>
                                                    transaction.isError
                                                        ? 'rgb(239, 83, 80)'
                                                        : theme.palette.blue
                                                              .main,
                                            }}
                                        >
                                            {transaction.formattedPrice}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: 'right',
                                                color: theme =>
                                                    transaction.isError
                                                        ? 'rgb(239, 83, 80)'
                                                        : theme.palette.blue
                                                              .main,
                                            }}
                                        >
                                            {transaction.formattedSaldo}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            }}
        />
    );
}

function formatDescription(transaction: AccountStatementLine): JSX.Element {
    return (
        <>
            <strong>{transaction.remarks[0]}</strong>
            {transaction.remarks.slice(1).map(line => (
                <>
                    <br />
                    {line}
                </>
            ))}
            {transaction.occurrences > 1 && (
                <>
                    <br />
                    <em>
                        <Localized
                            de={`${transaction.occurrences} mal wiederholt`}
                            fr={`répété ${transaction.occurrences} fois`}
                            it={`ripetuto ${transaction.occurrences} volte`}
                            en={`repeated ${transaction.occurrences} times`}
                        />
                    </em>
                    <br />
                    <em>
                        <Localized
                            de={`(zuletzt am ${DateTime.fromISO(
                                transaction.date,
                            ).toFormat('dd.MM.yyyy – HH:mm')})`}
                            fr={`(dernier le ${DateTime.fromISO(
                                transaction.date,
                            ).toFormat('dd.MM.yyyy – HH:mm')})`}
                            it={`(ultima volta il ${DateTime.fromISO(
                                transaction.date,
                            ).toFormat('dd.MM.yyyy – HH:mm')})`}
                            en={`(last on ${DateTime.fromISO(
                                transaction.date,
                            ).toFormat('dd.MM.yyyy – HH:mm')})`}
                        />
                    </em>
                </>
            )}
        </>
    );
}
