import {
    ConfirmSaveHeader,
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import {
    UpcomingChange,
    UpcomingChanges,
} from '../../ui/labeled-elements/UpcomingChanges.tsx';
import {
    tomDetailTexts,
    tomSyncStatusTexts,
    tomTexts,
    tomUpcomingChangesTexts,
} from '../i18n/TomTexts.ts';
import * as TomLayoutState from '../state/TomLayoutState.ts';
import * as TomState from '../state/TomState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Date from 'dg-web-shared/lib/Date.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { getOrElse, isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { ConfigurationSummary } from './ConfigurationSummary.tsx';
import { FullTomAtTime, ParsedTom } from 'dg-web-shared/model/Tom.ts';
import { TomSyncStatus } from './TomSyncStatus.tsx';
import { SlideInHeaderButtonsContainer } from '../../ui/slidein/SlideInHeader2.tsx';
import {
    ButtonDropdown,
    ButtonDropdownItem,
    ButtonDropdownSeparator,
} from '../../ui/slidein/ButtonDropdown.tsx';
import { Translation, TranslationWithArgs1 } from '../../common/i18n/Text.ts';
import { TomConfigurationChangeType } from 'dg-web-shared/model/TomConfiguration.ts';
import { TomEco } from './TomEco.tsx';
import { TariffPreview } from '../../tariffs/components/TariffPreview.tsx';
import * as SyncAppHelpSlideInState from '../../sync-app/state/SyncAppHelpSlideInState.ts';
import { openUsbAppOrInfo } from '../../sync-app/actions/SyncAppActions.ts';
import { RestrictedComponent } from '../../app/components/RestrictedComponent.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { CustomPermissionCheckers } from '../../app/state/Permission.ts';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { editBaseData, makeEditPayload } from '../actions/TomActions.ts';
import { Validation } from './Validation.ts';
import { FormValidation } from 'dg-web-shared/lib/FormValidation.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { TomFirmwareStatus } from './TomFirmwareStatus.tsx';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { OperatorBadge } from './OperatorBadge.tsx';
import { SemanticCOLORS } from 'semantic-ui-react';
import { UsbAppAction } from 'taxomex-shared/usbApp/UsbAppAction.ts';
import { tomOperationStatusTexts } from 'taxomex-shared/tom-operation-status/tomOperationStatusTexts.ts';
import { OUT_OF_SERVICE_ALARM_CODE } from 'taxomex-shared/tom-operation-status/OperationStatusDetail.tsx';
import { TomAlertList } from './TomError.tsx';

export interface TomDetailTexts {
    PlanSuspension: Translation;
    DuplicateExternalId: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: TomLayoutState.Layout.State;
    toms: TomState.List.State;
    syncSlideIn: SyncAppHelpSlideInState.Layout.State;
    tomDetail?: TomState.Detail.State | null;
    edit: TomState.Edit.State;
    editResponse: TomState.EditBaseDataResponse.State;
    login: CurrentOperatorLoginState.State;
}

export class TomDetailSlideIn extends Flux.Container<State> {
    stateSelector(): State {
        const layoutState = TomLayoutState.Layout.get(this.props.allState);
        const tomId = layoutState.selectedTomId;
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: layoutState,
            toms: TomState.List.get(this.props.allState),
            syncSlideIn: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
            tomDetail: tomId
                ? TomState.Detail.get(this.props.allState, { tomId })
                : null,
            edit: TomState.Edit.get(this.props.allState),
            editResponse: TomState.EditBaseDataResponse.get(
                this.props.allState,
            ),
            login: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    openPlanSuspensionSlideIn(): void {
        this.update(TomState.OutOfService.Form.reset);
        this.update(store =>
            TomLayoutState.Layout.stateWrite(store, {
                outOfServiceOpen: true,
            }),
        );
    }

    closeSlideIn(): void {
        this.update(TomLayoutState.Layout.reset);
        this.update(TomState.Edit.reset);
    }

    saveBaseData(tom: ParsedTom, isValid: boolean): void {
        if (isValid) {
            this.update(editBaseData, {
                tomId: tom.tomId,
                payload: makeEditPayload(this.state.edit, tom),
            });
        } else {
            this.update(store =>
                TomLayoutState.Layout.stateWrite(store, {
                    showTomBaseDataValidation: true,
                }),
            );
        }
    }

    render() {
        const lng = this.state.settings.language;
        const txt = tomTexts[lng];
        const detailTxt = tomDetailTexts[lng];
        const openSlideIn = isDefined(this.state.layout.selectedTomId);

        if (openSlideIn) {
            const tom =
                this.state.tomDetail &&
                !this.state.tomDetail.pending &&
                this.state.tomDetail.data;
            const login = this.state.login.data;
            if (tom && login) {
                const tomPlaceLine = isDefined(tom.zipCode)
                    ? `${tom.zipCode} ${tom.place}`
                    : tom.place;
                const allowEdit = login.permissions.parkingMeterEdit;

                // form validation logic
                const showErrors = this.state.layout.showTomBaseDataValidation;
                const validationProps: Validation.TomBaseDataEditForm.Props = {
                    tom: tom || null,
                    edit: this.state.edit,
                    editResponse: this.state.editResponse,
                    settings: this.state.settings,
                };
                const formValidation =
                    Validation.TomBaseDataEditForm.validateForm(
                        validationProps,
                    );
                const formErrors = formValidation.errorTexts;
                const operatorExternalIdError =
                    Validation.TomBaseDataEditForm.usernameIsDuplicate(
                        validationProps,
                    )
                        ? detailTxt.DuplicateExternalId()
                        : FormValidation.errorText(
                              showErrors,
                              formErrors,
                              t =>
                                  t.operatorExternalId &&
                                  t.operatorExternalId(),
                          );

                const label = (
                    <OperatorBadge
                        circular
                        color={getOperationStatusLabelColor(tom)}
                        empty
                    />
                );
                const operationStatusText = getOperationStatusText(tom, lng);

                return (
                    <FullSlideIn
                        open={true}
                        disabled={
                            this.state.layout.outOfServiceOpen ||
                            this.state.syncSlideIn.open
                        }
                    >
                        <SlideInBody>
                            <FullSlideInLeftColumn>
                                {allowEdit && (
                                    <TextField
                                        inputType="text"
                                        value={
                                            getOrElse(
                                                this.state.edit.name,
                                                tom.name,
                                            ) || ''
                                        }
                                        label={txt.Name()}
                                        errorText={FormValidation.errorText(
                                            showErrors,
                                            formErrors,
                                            t => t.name && t.name(),
                                        )}
                                        onChange={(v: string) =>
                                            this.update(store =>
                                                TomState.Edit.stateWrite(
                                                    store,
                                                    { name: v },
                                                ),
                                            )
                                        }
                                    />
                                )}
                                {!allowEdit && (
                                    <LabeledText label={txt.Name()}>
                                        {tom.name}
                                    </LabeledText>
                                )}
                                <LabeledText label={txt.InternalId()}>
                                    {tom.tomId}
                                </LabeledText>
                                {allowEdit && (
                                    <TextField
                                        inputType="text"
                                        value={
                                            getOrElse(
                                                this.state.edit
                                                    .operatorExternalId,
                                                tom.operatorExternalId,
                                            ) || ''
                                        }
                                        label={txt.OperatorExternalId()}
                                        errorText={operatorExternalIdError}
                                        onChange={(v: string) =>
                                            this.update(store =>
                                                TomState.Edit.stateWrite(
                                                    store,
                                                    { operatorExternalId: v },
                                                ),
                                            )
                                        }
                                    />
                                )}
                                {!allowEdit && (
                                    <LabeledText
                                        label={txt.OperatorExternalId()}
                                    >
                                        {tom.operatorExternalId}
                                    </LabeledText>
                                )}
                                {allowEdit && (
                                    <TextField
                                        inputType="text"
                                        value={
                                            getOrElse(
                                                this.state.edit.street,
                                                tom.street,
                                            ) || ''
                                        }
                                        label={txt.Address()}
                                        errorText={FormValidation.errorText(
                                            showErrors,
                                            formErrors,
                                            t => t.street && t.street(),
                                        )}
                                        onChange={(v: string) =>
                                            this.update(store =>
                                                TomState.Edit.stateWrite(
                                                    store,
                                                    { street: v },
                                                ),
                                            )
                                        }
                                    />
                                )}
                                {!allowEdit && (
                                    <LabeledText label={txt.Address()}>
                                        {tom.street}
                                    </LabeledText>
                                )}

                                <LabeledText label={txt.Place()}>
                                    {tomPlaceLine || '—'}
                                </LabeledText>

                                <LabeledText label={txt.State()}>
                                    {label} {operationStatusText}
                                </LabeledText>
                                {tom.offline && tom.lastOnlineStatus && (
                                    <LabeledText label={txt.LastContact()}>
                                        {Date.Formatter.dateWithDurationFromNow(
                                            tom.lastOnlineStatus,
                                            lng,
                                        )}
                                    </LabeledText>
                                )}
                                {(tom.errorCodes.length > 0 ||
                                    tom.alarmCodes.filter(
                                        a => a !== OUT_OF_SERVICE_ALARM_CODE,
                                    ).length > 0) && (
                                    <LabeledText label={txt.StateMessages()}>
                                        <TomAlertList
                                            errorCodes={tom.errorCodes}
                                            alarmCodes={tom.alarmCodes}
                                            language={lng}
                                        />
                                    </LabeledText>
                                )}
                                <LabeledText label={txt.CashboxType()}>
                                    {txt.CashboxTypeOptions(tom.model)}
                                </LabeledText>
                                <LabeledText label={txt.LastCollection()}>
                                    {tom.lastCollection
                                        ? Date.Formatter.dateWithDurationFromNow(
                                              tom.lastCollection,
                                              lng,
                                          )
                                        : txt.LastCollectionNever()}
                                </LabeledText>
                                <LabeledText label={txt.LastCashBoxLevel()}>
                                    {tom.lastCashBoxLevel
                                        ? NumberFormatter.numberToPrice(
                                              tom.lastCashBoxLevel / 100,
                                          )
                                        : ' - '}
                                </LabeledText>
                                <LabeledText label={txt.LastBatteryVoltage()}>
                                    {tom.lastBatteryVoltage
                                        ? (
                                              tom.lastBatteryVoltage / 1000
                                          ).toFixed(3) + ' V'
                                        : ' - '}
                                </LabeledText>
                                <LabeledText label={txt.FirmwareStatus()}>
                                    <TomFirmwareStatus
                                        tom={tom}
                                        language={lng}
                                    />
                                </LabeledText>
                                <LabeledText label={txt.InOperationSince()}>
                                    {tom.productionEndDate &&
                                        Date.Formatter.dayMonthYear(
                                            tom.productionEndDate,
                                        )}
                                </LabeledText>
                            </FullSlideInLeftColumn>
                            <FullSlideInRightColumn>
                                <SyncStatusInfo tom={tom} language={lng} />
                                <UpcomingTomChanges tom={tom} language={lng} />
                                {isDefined(tom.tariff) && (
                                    <TariffPreview
                                        tariff={tom.tariff}
                                        language={lng}
                                    />
                                )}
                                {isDefined(tom.tomConfiguration) &&
                                    isDefined(tom.zone) && (
                                        <ConfigurationSummary
                                            configuration={tom.tomConfiguration}
                                            zone={tom.zone}
                                            language={lng}
                                        />
                                    )}
                            </FullSlideInRightColumn>
                        </SlideInBody>
                        <SlideInHeader
                            language={lng}
                            hasChanges={TomState.Edit.hasChanges(
                                this.state.edit,
                                tom,
                            )}
                            tom={tom}
                            onClose={() => this.closeSlideIn()}
                            onSave={() =>
                                this.saveBaseData(tom, formValidation.isValid)
                            }
                            allowSave={formValidation.isValid}
                            update={this.props.allState.update}
                            openPlanSuspensionSlideIn={() =>
                                this.openPlanSuspensionSlideIn()
                            }
                        />
                    </FullSlideIn>
                );
            } else {
                return (
                    <FullSlideIn open={true}>
                        <LoaderHeader title={<TomEco />} />
                    </FullSlideIn>
                );
            }
        } else {
            return <FullSlideIn open={false} />;
        }
    }
}

function getOperationStatusText(tom: ParsedTom, language: string): string {
    const txt = tomOperationStatusTexts[language];
    if (tom.offline) {
        return txt.Offline();
    } else if (tom.outOfService) {
        return txt.OutOfService();
    } else {
        return txt.InService();
    }
}

export function getOperationStatusLabelColor(
    tom: ParsedTom,
): 'green' | 'red' | 'yellow' {
    const hasAlarms = tom.alarmCodes && tom.alarmCodes.length;
    const hasErrors = tom.errorCodes && tom.errorCodes.length;
    let labelColor: SemanticCOLORS = 'green';
    if (tom.outOfService || tom.offline) {
        labelColor = 'red';
    } else if (hasAlarms || hasErrors) {
        labelColor = 'yellow';
    }
    return labelColor;
}

interface SyncStatusInfoProps {
    tom: ParsedTom;
    language: string;
}

function SyncStatusInfo(props: SyncStatusInfoProps): JSX.Element {
    const txt = tomSyncStatusTexts[props.language];

    return (
        <div>
            <LabeledText label={txt.CurrentSynchronizationStatus()}>
                <div>
                    <TomSyncStatus status={props.tom.synchronizationStatus} />
                    {txt.SynchronizationStatus(props.tom.synchronizationStatus)}
                </div>
            </LabeledText>
        </div>
    );
}

export interface UpcomingChangesTexts {
    UpcomingChanges: Translation;
    NoUpcomingChanges: Translation;
    ChangeType: Translation;
    ChangeTypeValue: TranslationWithArgs1<TomConfigurationChangeType>;
    ChangeTypeSpaces: Translation;
    ChangeTypeZone: Translation;
    ChangeTypeOutOfService: Translation;
    ChangeTypeBackInService: Translation;
    ChangeTypeParameter: Translation;
    ChangeTypeCoins: Translation;
    ChangeTypePermissions: Translation;
    ChangeTypeDuplicate: Translation;
    ChangeTypeOther: Translation;
}

interface UpcomingTomChangesProps {
    tom: FullTomAtTime;
    language: string;
}

const MAX_UPCOMING_CHANGES_DISPLAY_COUNT = 4;

function UpcomingTomChanges(
    props: UpcomingTomChangesProps,
): JSX.Element | null {
    const txt = tomUpcomingChangesTexts[props.language];
    const upcomingChanges: UpcomingChange[] = props.tom.upcomingChanges.map(
        c => ({
            date: c.validFrom,
            description: c.changeTypes.map(txt.ChangeTypeValue).join(', '),
            comment: c.comment,
        }),
    );

    return upcomingChanges.length > 0 ? (
        <UpcomingChanges
            changes={upcomingChanges}
            label={txt.UpcomingChanges()}
            noChangesText={txt.NoUpcomingChanges()}
            language={props.language}
            maxChanges={MAX_UPCOMING_CHANGES_DISPLAY_COUNT}
        />
    ) : null;
}

interface TomSlideInHeaderProps {
    language: string;
    hasChanges: boolean;
    tom: ParsedTom;
    onClose: () => void;
    onSave: () => void;
    allowSave: boolean;
    openPlanSuspensionSlideIn: () => void;
    update: Flux.Updater;
}

function writeConfigurationToStick(tom: ParsedTom, update: Flux.Updater): void {
    update(store =>
        openUsbAppOrInfo(store, {
            usbAppAction: UsbAppAction.WRITE_CONFIGURATION,
            usbAppArgs: {
                operatorId: tom.operatorId,
                tomId: tom.tomId,
            },
            stateResetActionId: 'refetch-after-tom-configuration-write',
            stateResetAction: (store: Flux.Store) => {
                TomState.List.reset(store);
                TomState.Detail.refetchSameContext(store, false);
            },
        }),
    );
}

function SlideInHeader(props: TomSlideInHeaderProps): JSX.Element {
    const txt = tomTexts[props.language];

    if (props.hasChanges) {
        return (
            <ConfirmSaveHeader
                language={props.language}
                title={txt.SaveEdit()}
                onCancel={props.onClose}
                onSave={props.onSave}
                confirmButtonSpecialState={
                    props.allowSave ? null : ButtonSpecialState.DISABLED
                }
            />
        );
    } else {
        return (
            <StandardFirstLevelHeader onClose={props.onClose}>
                <SlideInHeaderTexts
                    title={
                        <span>
                            <TomEco />
                            {' — ' + props.tom.name}
                        </span>
                    }
                    hasLeftIcon={false}
                />
                <SlideInHeaderButtonsContainer>
                    <RestrictedComponent
                        route={OperatorAppRoutes.ParkingMeters}
                        permissionChecker={(o, l) =>
                            CustomPermissionCheckers.syncAppInteractionAllowed(
                                o,
                                l,
                            ) ||
                            CustomPermissionCheckers.parkingMeterEditAllowed(
                                o,
                                l,
                            )
                        }
                    >
                        <ButtonDropdown label={txt.Actions()}>
                            <RestrictedComponent
                                route={OperatorAppRoutes.ParkingMeters}
                                permissionChecker={
                                    CustomPermissionCheckers.syncAppInteractionAllowed
                                }
                            >
                                <ButtonDropdownItem
                                    label={txt.WriteToStick()}
                                    onClick={() =>
                                        writeConfigurationToStick(
                                            props.tom,
                                            props.update,
                                        )
                                    }
                                />
                            </RestrictedComponent>
                            <RestrictedComponent
                                route={OperatorAppRoutes.ParkingMeters}
                                permissionChecker={(o, l) =>
                                    CustomPermissionCheckers.syncAppInteractionAllowed(
                                        o,
                                        l,
                                    ) &&
                                    CustomPermissionCheckers.parkingMeterEditAllowed(
                                        o,
                                        l,
                                    )
                                }
                            >
                                <ButtonDropdownSeparator />
                            </RestrictedComponent>
                        </ButtonDropdown>
                    </RestrictedComponent>
                </SlideInHeaderButtonsContainer>
            </StandardFirstLevelHeader>
        );
    }
}
