import { selectState } from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { supportTextBlockTexts } from './LandingPageTexts.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../ui/Colors.ts';
import { OperatorTypo } from '../ui/OperatorTypo.ts';

export interface SupportTextBlockTexts {
    header: Translation;
    body: () => JSX.Element;
}

const texts = (p: { settings: SettingsState.State }) =>
    supportTextBlockTexts[p.settings.language];

export const SupportTextBlock = selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
    }),
    p => (
        <div
            className={css({
                maxWidth: '224px',
                margin: '0 auto',
            })}
        >
            <div
                className={css({
                    ...OperatorTypo.headingThree,
                    color: ColorHex.white,
                    marginTop: '18px',
                })}
            >
                {texts(p).header()}
            </div>
            <div
                className={css({
                    ...OperatorTypo.headingOne,
                    color: ColorHex.white,
                    marginTop: '14px',
                })}
            >
                {texts(p).body()}
            </div>
        </div>
    ),
);
