import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { operatorAppApiUrl } from '../../api/Http.ts';
import { css } from '@emotion/css';

import { Localized } from './Localized.tsx';
import { Icon16 } from '../../ui/icons/Icon.tsx';
import { download } from 'dg-web-shared/ui/icons/Icons16.tsx';

export const enum SignalisationType {
    ZONE = 'zone',
    PERMIT = 'permit',
}

export function LabeledSignalisationDownload({
    signalisationPdfId,
    type,
}: {
    signalisationPdfId: string;
    type: SignalisationType;
}) {
    return (
        <LabeledText
            label={
                <Localized
                    de="Signalisations-PDF"
                    fr="PDF de signalisation"
                    it="PDF segnaletica"
                    en="Signalisation PDF"
                />
            }
            toolTip={
                <>
                    <Localized
                        de="Der Inhalt der PDF-Datei ist im Vektorformat,"
                        fr="Le contenu du PDF est en format vectoriel,"
                        it="Il contenuto del PDF è in formato vettoriale,"
                        en="The PDF content is in vector format"
                    />
                    <br />
                    <Localized
                        de="so dass die Auflösung beim Druck optimal ist."
                        fr="de sorte que la résolution est optimale lors de l'impression."
                        it="in modo che in fase di stampa la risoluzione sia ottimale."
                        en="to provide the optimal resolution for print."
                    />
                    <br />
                    <br />
                    <b>
                        <Localized
                            de="Wichtig: das Layout des PDF-Inhalts darf nicht geändert werden."
                            fr="Important: la mise en page du contenu du PDF ne peut pas être modifiée."
                            it="Importante: il layout contenuto PDF non può essere modificato."
                            en="Important: The layout of the PDF must not be changed."
                        />
                    </b>
                </>
            }
            toolTipRight={true}
        >
            <a
                href={operatorAppApiUrl(
                    `/${type}-signalisation-pdf/${signalisationPdfId}`,
                )}
                target="_blank"
                download
                rel="noreferrer"
            >
                <div
                    className={css({
                        display: 'inline-block',
                        verticalAlign: 'bottom',
                        marginRight: '4px',
                        marginBottom: '-3px',
                    })}
                >
                    <Icon16 icon={download} />
                </div>
                <Localized
                    de="PDF herunterladen"
                    fr="Télécharger PDF"
                    it="Scarica PDF"
                    en="Download PDF"
                />
            </a>
        </LabeledText>
    );
}
