import { Translations } from '../../common/i18n/Text.ts';
import * as AddLicensePlateCountrySlideIn from '../components/AddLicensePlateCountrySlidein.tsx';
import * as AddLicensePlateSlideIn from '../components/AddLicensePlateSlideIn.tsx';

export const texts: Translations<AddLicensePlateSlideIn.Texts> = {
    de: {
        AlreadySelectedLicensePlate: () =>
            'Dieses Kennzeichen wurde bereits hinzugefügt',
        CarType: () => 'Motorwagen',
        Country: () => 'Land',
        HeaderCaption: () => 'Kennzeichen hinzufügen',
        HeaderHeading: () => 'Eingabe des Kennzeichens',
        LicensePlate: () => 'Kennzeichen',
        LicensePlateType: () => 'Schildart',
        MotorcycleType: () => 'Motorrad',
    },
    fr: {
        AlreadySelectedLicensePlate: () =>
            'Cette immatriculation a déjà été ajoutée',
        CarType: () => 'Voiture',
        Country: () => 'Pays',
        HeaderCaption: () => 'Ajouter immatriculation',
        HeaderHeading: () => 'Saisir immatriculation',
        LicensePlate: () => 'Immatriculation',
        LicensePlateType: () => 'Type de plaque',
        MotorcycleType: () => 'Moto',
    },
    it: {
        AlreadySelectedLicensePlate: () => 'Questa targa è già stata aggiunta',
        CarType: () => 'Automobile',
        Country: () => 'Nazione',
        HeaderCaption: () => 'Aggiungere targa',
        HeaderHeading: () => 'Registrazione targa',
        LicensePlate: () => 'Targa',
        LicensePlateType: () => 'Tipo di targa',
        MotorcycleType: () => 'Motocicletta',
    },
};

export const countrySelection: Translations<AddLicensePlateCountrySlideIn.Texts> =
    {
        de: {
            Caption: () => texts.de.HeaderCaption(),
            Heading: () => 'Eingabe des Landes',
        },
        fr: {
            Caption: () => texts.fr.HeaderCaption(),
            Heading: () => 'Saisir le pays.',
        },
        it: {
            Caption: () => texts.it.HeaderCaption(),
            Heading: () => 'Registrare la nazione',
        },
    };
