import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    getOrElse,
    getOrNull,
    isUndefined,
} from 'dg-web-shared/lib/MaybeV2.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';

export const closeSlideIn = (store: Flux.Store): string => {
    OperatorLoginsState.Layout.reset(store);
    OperatorLoginsState.Edit.reset(store);
    OperatorLoginsState.PasswordEdit.reset(store);
    OperatorLoginsState.EditCreateResponse.reset(store);
    return 'OperatorLogins-closeSlideIn';
};

export const editLogin = AsyncRequest.request(
    Http.OperatorAccount.OperatorLogins.edit,
    (store: Flux.Store, res: Response): string => {
        OperatorLoginsState.EditCreateResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            OperatorLoginsState.Edit.reset(store);
            CommonOperatorLoginsState.List.reset(store);
        }
        return 'OperatorLoginsDetailSlideIn-save';
    },
);

export const newLogin = AsyncRequest.request(
    Http.OperatorAccount.OperatorLogins.postNewLogin,
    (store: Flux.Store, res: Response): string => {
        OperatorLoginsState.EditCreateResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            OperatorLoginsState.Edit.reset(store);
            CommonOperatorLoginsState.List.reset(store);
            OperatorLoginsState.Layout.stateWrite(store, {
                createEnabled: false,
                selectedOperatorLogin: res.body.createdId,
            });
        }
        return 'OperatorLoginsDetailSlideIn-save';
    },
);

export const openCreateSlideIn = (store: Flux.Store): string => {
    const operator = OperatorDataState.get(store).data;
    if (isUndefined(operator)) {
        return 'OperatorLoginsDetailSlideIn-shouldnothappen';
    } else {
        OperatorLoginsState.Layout.stateWrite(store, { createEnabled: true });
        OperatorLoginsState.Edit.stateWrite(store, {
            allowedPermitTypes: [],
            allowedProductTypes: [],
            allowedZones: [],
            permOperatorAppActive: true,
            permEnforcementAppActive: true,
            permPermissionsEdit: false,
            permTransactions: true,
            permRefundParkTransactions: true,
            permParkingpay: true,
            permPermitsRead: operator.licensePlatePermitSettings.active,
            permPermitsWrite: operator.licensePlatePermitSettings.active,
            permRefundPermits: operator.licensePlatePermitSettings.active,
            permActivityStatement: operator.settings.activityStatement,
            permWhitelistCreate: false,
            permWhitelistDelete: false,
            permTaxomex: true,
            permSyncApp: true,
            permUsbStickEdit: true,
            permParkingMeterEdit: true,
            permParkingMeterAlerts: false,
            permCloudConnectorOffstreetDevicesView: true,
            permCloudConnectorAlerts: true,
            permCloudConnectorReportingAccess: true,
            firstName: '',
            lastName: '',
            username: '',
            zipCode: '',
            description: '',
            city: '',
            telnr: '',
            email: '',
        });
    }
    return 'OperatorLoginsDetailSlideIn-openCreateSlideIn';
};

export const makePostPayload = (
    edit: OperatorLoginsState.Edit.State,
    pw: OperatorLoginsState.PasswordEdit.State,
    maxAnonymizeCheckinDataAfterWeeks: number,
): Http.OperatorAccount.OperatorLogins.EditCreatePayload => {
    return {
        allowedPermitTypes: edit.allowedPermitTypes || [],
        allowedProductTypes: edit.allowedProductTypes || [],
        allowedZones: edit.allowedZones || [],
        allowedRecodeConditions: edit.allowedRecodeConditions || [],
        permOperatorAppActive: getOrNull(edit.permOperatorAppActive) || false,
        permEnforcementAppActive:
            getOrNull(edit.permEnforcementAppActive) || false,
        permPermissionsEdit: edit.permPermissionsEdit || false,
        permTransactions: edit.permTransactions || false,
        permRefundParkTransactions: edit.permRefundParkTransactions || false,
        permParkingpay: edit.permParkingpay || false,
        permPermitsRead: edit.permPermitsRead || false,
        permPermitsWrite: edit.permPermitsWrite || false,
        permRefundPermits: edit.permRefundPermits || false,
        permActivityStatement: edit.permActivityStatement || false,
        permWhitelistCreate: edit.permWhitelistCreate || false,
        permWhitelistDelete: edit.permWhitelistDelete || false,
        permEnforcementLog: edit.permEnforcementLog || false,
        permTaxomex: edit.permTaxomex || false,
        permSyncApp: edit.permSyncApp || false,
        permUsbStickEdit: edit.permUsbStickEdit || false,
        permParkingMeterEdit: edit.permParkingMeterEdit || false,
        permParkingMeterAlerts: edit.permParkingMeterAlerts || false,
        permTwintQrCodePairing: edit.permTwintQrCodePairing || false,
        permQuotasWrite: edit.permQuotasWrite || false,
        permParkingaboInitialBalanceCreate:
            edit.permParkingaboInitialBalanceCreate || false,
        permCloudConnectorOffstreetDevicesView:
            edit.permCloudConnectorOffstreetDevicesView || false,
        permCloudConnectorRecodeTicket:
            edit.permCloudConnectorRecodeTicket || false,
        permCloudConnectorAlerts: edit.permCloudConnectorAlerts || false,
        permCloudConnectorReportingAccess:
            edit.permCloudConnectorReportingAccess || false,
        firstName: edit.firstName || '',
        lastName: edit.lastName || '',
        username: edit.username || '',
        zipCode: getOrNull(edit.zipCode),
        description: getOrNull(edit.description),
        city: getOrNull(edit.city),
        telnr: getOrNull(edit.telnr),
        email: getOrNull(edit.email),
        password: getOrNull(pw.password1),
        softAnonymizeCheckinPublicPermitAfterDays:
            edit.softAnonymizeCheckinPublicPermitAfterDays
                ? parseInt(edit.softAnonymizeCheckinPublicPermitAfterDays, 10)
                : maxAnonymizeCheckinDataAfterWeeks,
    };
};

export const makeEditPayload = (
    edit: OperatorLoginsState.Edit.State,
    login: CommonOperatorLoginsState.OperatorLogin,
): Http.OperatorAccount.OperatorLogins.EditCreatePayload => {
    return {
        allowedPermitTypes: getOrElse(
            edit.allowedPermitTypes,
            login.allowedPermitTypes,
        ),
        allowedProductTypes: getOrElse(
            edit.allowedProductTypes,
            login.allowedProductTypes,
        ),
        allowedZones: getOrElse(edit.allowedZones, login.allowedZones),
        allowedRecodeConditions: getOrElse(
            edit.allowedRecodeConditions,
            login.allowedRecodeConditions,
        ),
        permOperatorAppActive: getOrElse(
            edit.permOperatorAppActive,
            login.permissions.operatorAppActive,
        ),
        permEnforcementAppActive: getOrElse(
            edit.permEnforcementAppActive,
            login.permissions.enforcementAppActive,
        ),
        permTransactions: getOrElse(
            edit.permTransactions,
            login.permissions.transactions,
        ),
        permRefundParkTransactions: getOrElse(
            edit.permRefundParkTransactions,
            login.permissions.refundParkTransactions,
        ),
        permPermissionsEdit: getOrElse(
            edit.permPermissionsEdit,
            login.permissions.userPermissionEdit,
        ),
        permParkingpay: getOrElse(
            edit.permParkingpay,
            login.permissions.parkingpayModule,
        ),
        permPermitsRead: getOrElse(
            edit.permPermitsRead,
            login.permissions.permitsRead,
        ),
        permPermitsWrite: getOrElse(
            edit.permPermitsWrite,
            login.permissions.permitsWrite,
        ),
        permRefundPermits: getOrElse(
            edit.permRefundPermits,
            login.permissions.refundPermits,
        ),
        permActivityStatement: getOrElse(
            edit.permActivityStatement,
            login.permissions.activityStatement,
        ),
        permEnforcementLog: getOrElse(
            edit.permEnforcementLog,
            login.permissions.enforcementLog,
        ),
        permWhitelistCreate: getOrElse(
            edit.permWhitelistCreate,
            login.permissions.whitelistCreate,
        ),
        permWhitelistDelete: getOrElse(
            edit.permWhitelistDelete,
            login.permissions.whitelistDelete,
        ),
        permTwintQrCodePairing: getOrElse(
            edit.permTwintQrCodePairing,
            login.permissions.twintQrCodePairing,
        ),
        permQuotasWrite: getOrElse(
            edit.permQuotasWrite,
            login.permissions.quotasWrite,
        ),
        permTaxomex: getOrElse(
            edit.permTaxomex,
            login.permissions.taxomexModule,
        ),
        permSyncApp: getOrElse(edit.permSyncApp, login.permissions.syncApp),
        permUsbStickEdit: getOrElse(
            edit.permUsbStickEdit,
            login.permissions.usbStickEdit,
        ),
        permParkingMeterEdit: getOrElse(
            edit.permParkingMeterEdit,
            login.permissions.parkingMeterEdit,
        ),
        permParkingMeterAlerts: getOrElse(
            edit.permParkingMeterAlerts,
            login.permissions.parkingMeterAlerts,
        ),
        permParkingaboInitialBalanceCreate: getOrElse(
            edit.permParkingaboInitialBalanceCreate,
            login.permissions.parkingaboInitialBalanceCreate,
        ),
        permCloudConnectorOffstreetDevicesView: getOrElse(
            edit.permCloudConnectorOffstreetDevicesView,
            login.permissions.cloudConnectorOffstreetDevicesView,
        ),
        permCloudConnectorRecodeTicket: getOrElse(
            edit.permCloudConnectorRecodeTicket,
            login.permissions.cloudConnectorRecodeTicket,
        ),
        permCloudConnectorAlerts: getOrElse(
            edit.permCloudConnectorAlerts,
            login.permissions.cloudConnectorAlerts,
        ),
        permCloudConnectorReportingAccess: getOrElse(
            edit.permCloudConnectorReportingAccess,
            login.permissions.cloudConnectorReportingAccess,
        ),
        firstName: getOrElse(edit.firstName, login.firstName),
        lastName: getOrElse(edit.lastName, login.lastName),
        username: getOrElse(edit.username, login.username),
        zipCode: getOrElse(edit.zipCode, login.zipCode),
        description: getOrElse(edit.description, login.description),
        city: getOrElse(edit.city, login.city),
        telnr: getOrElse(edit.telnr, login.telnr),
        email: getOrElse(edit.email, login.email),
        softAnonymizeCheckinPublicPermitAfterDays:
            edit.softAnonymizeCheckinPublicPermitAfterDays
                ? parseInt(edit.softAnonymizeCheckinPublicPermitAfterDays, 10)
                : login.softAnonymizeCheckinPublicPermitAfterDays,
    };
};

export const setUsername = (store: Flux.Store, username: string) => {
    OperatorLoginsState.Edit.stateWrite(store, {
        username: username,
    });
    OperatorLoginsState.EditCreateResponse.reset(store);
    return 'OperatorLoginActions.setUsername';
};
