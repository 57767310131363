import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import {
    DateRangePickerSlideIn,
    DateRangeSingleSelection,
} from '../ui/slidein/DateRangePickerSlideIn.tsx';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { Filter, UI } from './ClearanceRequestsState.ts';
import { dateFilterTexts } from './Texts.tsx';
import { clearValidDate, setValidFrom, setValidTo } from './Actions.ts';

export interface DateFilterTexts {
    filterTitle: Translation;
    from: Translation;
    to: Translation;
}

const texts = (p: { settings: SettingsState.State }) =>
    dateFilterTexts[p.settings.language];

export const DateFilterSlideIn = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
        ui: UI.get(store),
    }),
    p => (
        <DateRangePickerSlideIn
            language={p.settings.language}
            open={p.ui.validDateSlideInVisible}
            title={texts(p).filterTitle()}
            validFrom={p.filter.dateFrom}
            validTo={p.filter.dateTo}
            onSelectFrom={d => p.update(store => setValidFrom(store, d))}
            onClose={() =>
                p.update(store =>
                    UI.stateWrite(store, {
                        validDateSlideInVisible: false,
                    }),
                )
            }
            onSelectTo={d => p.update(store => setValidTo(store, d))}
        />
    ),
);

export const DateFilterSelection = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
    }),
    p => (
        <DateRangeSingleSelection
            title={texts(p).filterTitle()}
            validFrom={p.filter.dateFrom}
            validTo={p.filter.dateTo}
            onClick={() =>
                p.update(store =>
                    UI.stateWrite(store, { validDateSlideInVisible: true }),
                )
            }
            onClear={
                Filter.dateFilterActive(p.filter)
                    ? () => p.update(clearValidDate)
                    : null
            }
        />
    ),
);
