import moment from 'moment';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { Translations } from '../../common/i18n/Text.ts';
import { UsbStickTexts } from '../components/UsbSticksBody.tsx';
import { UsbStickState, UsbStickType } from 'dg-web-shared/model/UsbStick.ts';

/* tslint:disable */

export const usbStickTexts: Translations<UsbStickTexts> = {
    de: {
        CommentLabel: () => 'Kommentar',
        ExpirationCheckbox: () => 'automatisch terminieren',
        ExpirationLabel: () => 'Ablaufdatum',
        ExpirationNotSet: () => '-',
        ExpirationValue: (expiration: moment.Moment) =>
            `${Date.Formatter.dayMonthYear(
                expiration,
            )} (${Date.Formatter.durationFromNow(expiration, 'de')})`,
        Id: () => 'Nummer',
        InSync: () => 'aktuell',
        LanguagePlaceholder: () => 'Sprache der Benutzeroberfläche',
        LastWriteLabel: () => 'Letztes Stick Update',
        LastWriteNever: () => 'Bisher nicht auf Stick geschrieben',
        LastWriteValue: (lastWrite: moment.Moment) =>
            `${Date.Formatter.dayMonthYearHourMinute(
                lastWrite,
            )} (${Date.Formatter.durationFromNow(lastWrite, 'de')})`,
        NeedsSynchronization: () => 'nicht aktuell',
        Permissions: () => 'Gruppen',
        PinCodeCheckbox: () => 'Pincode aktivieren',
        PinCodeLabel: () => 'Pincode',
        PinCodeNotSet: () => 'Kein Pincode gesetzt',
        SlideInHeaderCaption: () => 'USB Stick',
        StateCommissioned: () => 'In Verwendung',
        StateDeleted: () => 'Gelöscht',
        StateDraft: () => 'In Entwurf',
        StateInvalidated: () => 'Invalidiert',
        StateLabel: () => 'Status',
        StateUnknown: () => 'Unbekannt',
        StickState: (state: UsbStickState) =>
            getStickStateText(state, usbStickTexts.de),
        StickType: (type: UsbStickType) =>
            getStickTypeText(type, usbStickTexts.de),
        StickTypeCollection: () => 'Leerung',
        StickTypeLabel: () => 'Typ',
        StickTypeMaintenance: () => 'Unterhalt',
        StickTypeUnknown: () => 'Unbekannt',
        SyncStatus: () => 'Konfiguration',
        TagsHint: () =>
            'Dieser USB Stick kann mit allen TOMs interagieren die mindestens eine der konfigurierten Berechtigungen aufweisen.',
        Title: () => 'Bezeichnung',
        VersionLabel: () => 'Version',
        WriteToStick: () => 'Stick aktualisieren',
        SaveEdit: () => 'Änderungen speichern?',
    },
    en: {
        CommentLabel: () => 'Comment',
        ExpirationCheckbox: () => 'Automatically Expire',
        ExpirationLabel: () => 'Expiration',
        ExpirationNotSet: () => '-',
        ExpirationValue: (expiration: moment.Moment) =>
            `${Date.Formatter.dayMonthYear(
                expiration,
            )} (${Date.Formatter.durationFromNow(expiration, 'en')})`,
        Id: () => 'Number',
        InSync: () => 'Konfiguration aktuell',
        LanguagePlaceholder: () => 'User Interface Language',
        LastWriteLabel: () => 'Last Write',
        LastWriteNever: () => 'Not yet written to stick',
        LastWriteValue: (lastWrite: moment.Moment) =>
            `${Date.Formatter.dayMonthYearHourMinute(
                lastWrite,
            )} (${Date.Formatter.durationFromNow(lastWrite, 'en')})`,
        NeedsSynchronization: () => 'Nicht synchronisierte Änderungen',
        Permissions: () => 'Groups',
        PinCodeCheckbox: () => 'Require Pin Code',
        PinCodeLabel: () => 'Pin Code',
        PinCodeNotSet: () => 'No Pin Code set',
        SlideInHeaderCaption: () => 'USB Stick',
        StateCommissioned: () => 'Commissioned',
        StateDeleted: () => 'Deleted',
        StateDraft: () => 'Draft',
        StateInvalidated: () => 'Invalidated',
        StateLabel: () => 'State',
        StateUnknown: () => 'Unknown',
        StickState: (state: UsbStickState) =>
            getStickStateText(state, usbStickTexts.en),
        StickType: (type: UsbStickType) =>
            getStickTypeText(type, usbStickTexts.en),
        StickTypeCollection: () => 'Collection',
        StickTypeLabel: () => 'Type',
        StickTypeMaintenance: () => 'Maintenance',
        StickTypeUnknown: () => 'Unknown',
        SyncStatus: () => 'Synchronisationsstatus',
        TagsHint: () =>
            'This USB stick can interact with all TOMs that have at least one of the configured permissions.',
        Title: () => 'Caption',
        VersionLabel: () => 'Version',
        WriteToStick: () => 'Update Stick',
        SaveEdit: () => 'Save changes?',
    },
    fr: {
        CommentLabel: () => 'Commentaire',
        ExpirationCheckbox: () => 'expiration automatique',
        ExpirationLabel: () => "Date d'expiration",
        ExpirationNotSet: () => '-',
        ExpirationValue: (expiration: moment.Moment) =>
            `${Date.Formatter.dayMonthYear(
                expiration,
            )} (${Date.Formatter.durationFromNow(expiration, 'fr')})`,
        Id: () => 'Numéro',
        InSync: () => 'actuelle',
        LanguagePlaceholder: () => "Langue de l'interface utilisateur",
        LastWriteLabel: () => 'Dernière mise à jour de la clé',
        LastWriteNever: () => "Pas encore d'écriture sur la clé",
        LastWriteValue: (lastWrite: moment.Moment) =>
            `${Date.Formatter.dayMonthYearHourMinute(
                lastWrite,
            )} (${Date.Formatter.durationFromNow(lastWrite, 'fr')})`,
        NeedsSynchronization: () => 'pas actuelle',
        Permissions: () => 'Groupes',
        PinCodeCheckbox: () => 'Activer le code PIN',
        PinCodeLabel: () => 'Code PIN',
        PinCodeNotSet: () => 'Aucun code PIN défini',
        SlideInHeaderCaption: () => 'Clé USB',
        StateCommissioned: () => 'Chargée',
        StateDeleted: () => 'Supprimée',
        StateDraft: () => 'Brouillon',
        StateInvalidated: () => 'Invalidée',
        StateLabel: () => 'Etat',
        StateUnknown: () => 'Inconnue',
        StickState: (state: UsbStickState) =>
            getStickStateText(state, usbStickTexts.fr),
        StickType: (type: UsbStickType) =>
            getStickTypeText(type, usbStickTexts.fr),
        StickTypeCollection: () => 'Collecte',
        StickTypeLabel: () => 'Type',
        StickTypeMaintenance: () => 'Maintenance',
        StickTypeUnknown: () => 'Inconnu',
        SyncStatus: () => 'Configuration',
        TagsHint: () =>
            "Cette clé USB peut interagir avec tous les TOM présentant au moins l'une des permissions configurées.",
        Title: () => 'Désignation',
        VersionLabel: () => 'Version',
        WriteToStick: () => 'Actualiser la clé',
        SaveEdit: () => 'Enregistrer les modifications?',
    },
    it: {
        CommentLabel: () => 'Commento',
        ExpirationCheckbox: () => 'Terminare automaticamente',
        ExpirationLabel: () => 'Data di scadenza',
        ExpirationNotSet: () => '-',
        ExpirationValue: (expiration: moment.Moment) =>
            `${Date.Formatter.dayMonthYear(
                expiration,
            )} (${Date.Formatter.durationFromNow(expiration, 'it')})`,
        Id: () => 'Numero',
        InSync: () => 'aggiornata',
        LanguagePlaceholder: () => "Lingua dell'interfaccia utente",
        LastWriteLabel: () => 'Ultimo aggiornamento chiavetta',
        LastWriteNever: () => 'Non ancora scritto sulla chiavetta',
        LastWriteValue: (lastWrite: moment.Moment) =>
            `${Date.Formatter.dayMonthYearHourMinute(
                lastWrite,
            )} (${Date.Formatter.durationFromNow(lastWrite, 'it')})`,
        NeedsSynchronization: () => 'non aggiornata',
        Permissions: () => 'Gruppi',
        PinCodeCheckbox: () => 'Attivare il codice PIN',
        PinCodeLabel: () => 'Codice PIN',
        PinCodeNotSet: () => 'Nessun codice PIN definito',
        SlideInHeaderCaption: () => 'Chiavetta USB',
        StateCommissioned: () => 'In uso',
        StateDeleted: () => 'Cancellato',
        StateDraft: () => 'In progettazione',
        StateInvalidated: () => 'Invalidato',
        StateLabel: () => 'Stato',
        StateUnknown: () => 'Sconosciuto',
        StickState: (state: UsbStickState) =>
            getStickStateText(state, usbStickTexts.it),
        StickType: (type: UsbStickType) =>
            getStickTypeText(type, usbStickTexts.it),
        StickTypeCollection: () => 'Svuotamento',
        StickTypeLabel: () => 'Tipo',
        StickTypeMaintenance: () => 'Mantenimento',
        StickTypeUnknown: () => 'Sconosciuto',
        SyncStatus: () => 'Configurazione',
        TagsHint: () =>
            'Questa chiavetta USB può interagire con TOM dotati di almeno una delle autorizzazioni configurate.',
        Title: () => 'Denominazione',
        VersionLabel: () => 'Versione',
        WriteToStick: () => 'Aggiorna chiavetta',
        SaveEdit: () => 'Salvare le modifiche?',
    },
};
/* tslint:enable */

function getStickTypeText(type: UsbStickType, texts: UsbStickTexts): string {
    switch (type) {
        case UsbStickType.COLLECTION:
            return texts.StickTypeCollection();
        case UsbStickType.MAINTENANCE:
            return texts.StickTypeMaintenance();
        default:
            return texts.StickTypeUnknown();
    }
}

function getStickStateText(state: UsbStickState, texts: UsbStickTexts): string {
    switch (state) {
        case UsbStickState.planning:
            return texts.StateDraft();
        case UsbStickState.commissioned:
            return texts.StateCommissioned();
        case UsbStickState.invalidated:
            return texts.StateInvalidated();
        case UsbStickState.deleted:
            return texts.StateDeleted();
        default:
            return texts.StateUnknown();
    }
}
