import { Translations } from '../../common/i18n/Text.ts';
import { CustomerClearanceTableTexts } from '../components/CustomerClearanceTable.tsx';
import { PermitTypeSelectionTexts } from '../components/PermitTypeSelectionSlideIn.tsx';
import { CustomerClearanceCreateSlideInTexts } from '../components/CustomerClearanceCreateSlideIn.tsx';
import { CustomerClearanceDetailSlideInTexts } from '../components/CustomerClearanceDetailSlideIn.tsx';

export const customerClearanceTableTexts: Translations<CustomerClearanceTableTexts> =
    {
        de: {
            createdDate: () => 'Erfassungsdatum',
            createdBy: () => 'Erfasst von',
            customerNr: () => 'Benutzerkonto',
            remark: () => 'Bezeichnung',
            permitTypeName: () => 'Bewilligungsart',
        },
        fr: {
            createdDate: () => 'Date création',
            createdBy: () => 'Saisie par',
            customerNr: () => 'Compte utilisateur',
            remark: () => 'Désignation',
            permitTypeName: () => "Type d'autorisation",
        },
        it: {
            createdDate: () => 'Data creazione',
            createdBy: () => 'Registrata da',
            customerNr: () => 'Conto utente',
            remark: () => 'Denominazione',
            permitTypeName: () => "Tipo d'autorizzazione",
        },
    };

/* tslint:disable */
export const customerClearanceCreateSlideInTexts: Translations<CustomerClearanceCreateSlideInTexts> =
    {
        de: {
            slideInHeader: () => 'Kontofreigabe erfassen',
            permitTypes: () => 'Bewilligungsarten',
            customerNr: () => 'Benutzerkonto',
            remark: () => 'Bezeichnung',
            createInProgress: () => 'Kontofreigabe wird erstellt...',
            invalidCustomerNr: () => 'Ungültige Kontonummer',
            noPermitTypesSelected: () =>
                'Wählen Sie mindestens eine Bewilligungsart',
            hasOverlaps: () =>
                'Für dieses Benutzerkonto und eine der gewählten Bewilligunsarten existiert bereits eine Kontofreigabe',
        },
        fr: {
            slideInHeader: () => 'Saisir approbation compte',
            permitTypes: () => "Types d'autorisations",
            customerNr: () => 'Compte utilisateur',
            remark: () => 'Désignation',
            createInProgress: () => "L'approbation compre sera crée...",
            invalidCustomerNr: () => 'Numero de compte pas valide',
            noPermitTypesSelected: () =>
                "Sélectionner au mois un type d'autorisation",
            hasOverlaps: () =>
                "Il y a déjà une approbation pour ce compte utilisateur et pour au mois un type d'autorisation selectionné",
        },
        it: {
            slideInHeader: () => 'Registrare approvazione conto',
            permitTypes: () => "Tipi d'autorizzazione",
            customerNr: () => 'Conto utente',
            remark: () => 'Denominazione',
            createInProgress: () => "L'approvazione conto verrà creata...",
            invalidCustomerNr: () => 'Numero di conto non valido',
            noPermitTypesSelected: () =>
                "Selezionare almeno un tipo d'autorizzazione",
            hasOverlaps: () =>
                "Esiste già un'approvazione per questo conto utente e per almeno uno dei tipi d'autorizzazione scelti",
        },
    };
/* tslint:enable */

export const customerClearanceDetailSlideInTexts: Translations<CustomerClearanceDetailSlideInTexts> =
    {
        de: {
            slideInHeader: () => 'Kontofreigabe',
            confirmDelete: () => 'Kontofreigabe löschen?',
            deletingClearance: () => 'Kontofreigabe wird gelöscht..',
            deleteError: () => 'Fehler beim Löschen der Kontofreigabe',
            editLabel: () => 'Bearbeiten',
            delete: () => 'Löschen',
        },
        fr: {
            slideInHeader: () => 'Approbation compte',
            confirmDelete: () => "Effacer l'approbation?",
            deletingClearance: () => "L'approbation compte sera effacée...",
            deleteError: () => "Erreur pendant l'elimination de l'approbation",
            editLabel: () => 'Modifier',
            delete: () => 'Effacer',
        },
        it: {
            slideInHeader: () => 'Approvazione conto',
            confirmDelete: () => "Cancellare l'approvazione?",
            deletingClearance: () => "L'approvazione verra cancellata...",
            deleteError: () =>
                "Errore durante l'eliminazione dell'approvazione",
            editLabel: () => 'Modificare',
            delete: () => 'Cancellare',
        },
    };

export const permitTypeSelectionTexts: Translations<PermitTypeSelectionTexts> =
    {
        de: {
            slideInHeader: () => 'Bewilligungsarten auswählen',
        },
        fr: {
            slideInHeader: () => "Sélectionner les types d'autorisation",
        },
        it: {
            slideInHeader: () => "Selezionare i tipi d'autorizzazione",
        },
    };
