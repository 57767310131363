import { selectState } from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { contentTextBlockTexts } from './LandingPageTexts.tsx';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../ui/Colors.ts';
import { OperatorTypo } from '../ui/OperatorTypo.ts';
import parkingPortalDe from './Grafik_parkingportal_DE.png';
import parkingPortalFr from './Grafik_parkingportal_FR.png';
import parkingPortalIt from './Grafik_parkingportal_IT.png';
import dpLogo from '../../assets/digitalparking_logo_positive.svg';

const contentImage: { [idx: string]: string } = {
    de: parkingPortalDe,
    fr: parkingPortalFr,
    it: parkingPortalIt,
};

export interface ContentTextBlockTexts {
    header: Translation;
    body: () => JSX.Element;
    footerLeft: Translation;
}

const texts = (p: { settings: SettingsState.State }) =>
    contentTextBlockTexts[p.settings.language];

export const ContentTextBlock = selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
    }),
    p => (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                margin: '0 auto',
                maxWidth: '480px',
            })}
        >
            <div
                className={css({
                    flex: 1,
                })}
            >
                <div
                    className={css({
                        ...OperatorTypo.headingThree,
                        color: ColorHex.darkblue,
                        marginTop: '25px',
                    })}
                >
                    {texts(p).header()}
                </div>
                <img
                    className={css({
                        margin: '60px 0',
                        width: '100%',
                    })}
                    src={contentImage[p.settings.language]}
                />
                <div
                    className={css({
                        ...OperatorTypo.headingOne,
                        color: ColorHex.darkblue,
                        marginTop: '25px',
                    })}
                >
                    {texts(p).body()}
                </div>
            </div>
            <Clickable
                element="a"
                className={css({
                    height: '48px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    textDecoration: 'none',
                })}
                href={
                    'http://www.digitalparking.ch' +
                    (p.settings.language === 'de'
                        ? ''
                        : '/' + p.settings.language)
                }
                target="_blank"
            >
                <div
                    className={css({
                        ...OperatorTypo.caption,
                        color: ColorHex.darkblue,
                    })}
                >
                    {texts(p).footerLeft()}
                </div>
                <img
                    className={css({
                        width: '100px',
                    })}
                    src={dpLogo}
                />
            </Clickable>
        </div>
    ),
);
