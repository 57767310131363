import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SyncAppHelpSlideInState from '../state/SyncAppHelpSlideInState.ts';
import { openUsbApp, UsbAppArgs } from 'taxomex-shared/usbApp/openUsbApp.ts';
import { executeDelayed, reloadDelayedMulti } from '../../api/DelayedAction.ts';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { UsbAppAction } from 'taxomex-shared/usbApp/UsbAppAction.ts';

export const startLoadingSyncApp = (s: Flux.Store): string => {
    SyncAppHelpSlideInState.Layout.stateWrite(s, {
        loading: true,
    });
    return 'syncApp-startLoading';
};

export const stopLoadingSyncApp = (s: Flux.Store): string => {
    SyncAppHelpSlideInState.Layout.stateWrite(s, {
        loading: false,
    });
    return 'syncApp-stopLoading';
};

export const openSyncAppSlideIn = (s: Flux.Store): string => {
    SyncAppHelpSlideInState.Layout.stateWrite(s, {
        open: true,
        loading: false,
    });
    return 'syncApp-openSlideIn';
};

export const openSyncAppSlideInWithHint = (s: Flux.Store): string => {
    SyncAppHelpSlideInState.Layout.stateWrite(s, {
        open: true,
        loading: false,
        showNotInstalledHint: true,
    });
    return 'syncApp-openSlideInWithHint';
};

export const closeSyncAppSlideIn = (s: Flux.Store): string => {
    SyncAppHelpSlideInState.Layout.reset(s);
    return 'syncApp-closeSlideIn';
};

interface OpenUsbAppOrInfoArgs {
    usbAppAction: UsbAppAction;
    usbAppArgs: UsbAppArgs;
    stateResetActionId?: Maybe<string>;
    stateResetAction?: Maybe<(s: Flux.Store) => void>;
}

export const openUsbAppOrInfo = (
    store: Flux.Store,
    args: OpenUsbAppOrInfoArgs,
): string => {
    const actionId = 'open-usb-app-or-info';
    openUsbApp(args.usbAppAction, args.usbAppArgs);
    store.update(startLoadingSyncApp);

    const focusedAction = (store: Flux.Store) => {
        if (document.hasFocus()) {
            store.update(openSyncAppSlideInWithHint);
        } else {
            store.update(stopLoadingSyncApp);
        }
        return actionId + '-stillFocused';
    };
    executeDelayed(store, focusedAction, actionId + '-delay', 5000);

    if (
        isDefined(args.stateResetActionId) &&
        isDefined(args.stateResetAction)
    ) {
        // reset state after a defined time
        reloadDelayedMulti(
            store,
            args.stateResetAction,
            args.stateResetActionId,
            [12000, 30000, 60000],
        );
    }
    return actionId;
};
