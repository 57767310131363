import moment from 'moment';

import * as Date from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    GithubRelease,
    LATEST_GITHUB_RELEASE_OVERVIEW_URL,
} from 'dg-web-shared/model/GithubRelease.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { TextButton } from '../../ui/buttons/TextButton.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { BulletList, Item } from '../../ui/layout/BulletList.tsx';
import { Paragraph } from '../../ui/layout/Text.tsx';
import {
    Notification,
    NotificationColor,
    NotificationIcon,
} from '../../ui/notifications/Notification.tsx';
import {
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import * as SyncAppActions from '../actions/SyncAppActions.ts';
import { syncAppTexts } from '../i18n/SyncAppTexts.ts';
import * as SyncAppHelpSlideInState from '../state/SyncAppHelpSlideInState.ts';

export interface SyncAppTexts {
    SyncApp: Translation;
    SyncAppRequired: Translation;
    SlideInHeaderCaption: Translation;
    NotInstalledHint: Translation;
    AppIntro: Translation;
    DownloadLatestVersion: Translation;
    Download: Translation;
    InstallationAndDownload: Translation;
    LatestVersion: Translation;
    UsageInfo: Translation;
    SystemRequirementsLabel: Translation;
    SystemRequirementsValue: Translation;
    Functionality: Translation;
    ImportCollectionData: Translation;
    ConfigureUsbSticks: Translation;
    UpdateTomConfigurations: Translation;
    IdentifyUsbStick: Translation;
    AutoUpdate: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: SyncAppHelpSlideInState.Layout.State;
    latestRelease: SyncAppHelpSlideInState.LatestRelease.State;
}

export class SyncAppSlideIn extends Flux.Container<State> {
    static getInstallerDownloadUrl(release: GithubRelease): string {
        const executables = release.assets.filter(
            a => a.content_type === 'application/x-msdownload',
        );
        if (executables.length > 0) {
            return executables[0].browser_download_url;
        }
        return LATEST_GITHUB_RELEASE_OVERVIEW_URL;
    }

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: SyncAppHelpSlideInState.Layout.get(this.props.allState),
            latestRelease: SyncAppHelpSlideInState.LatestRelease.get(
                this.props.allState,
            ),
        };
    }

    render() {
        const lng = this.state.settings.language;
        const txt = syncAppTexts[lng];

        if (this.state.layout.open) {
            if (
                this.state.latestRelease.pending ||
                !this.state.latestRelease.data
            ) {
                return (
                    <HalfSlideIn open={true}>
                        <LoaderHeader title={txt.SlideInHeaderCaption()} />
                    </HalfSlideIn>
                );
            } else {
                const latestVersionInfo = this.state.latestRelease.data;

                const releaseDate = Date.Formatter.dayMonthYear(
                    moment(
                        latestVersionInfo
                            ? latestVersionInfo.published_at
                            : undefined,
                    ),
                );

                return (
                    <HalfSlideIn open={true}>
                        <SlideInBody>
                            <Paragraph>{txt.AppIntro()}</Paragraph>
                            <Paragraph>{txt.UsageInfo()}</Paragraph>
                            <LabeledText label={txt.Functionality()}>
                                <BulletList>
                                    <Item>{txt.ImportCollectionData()}</Item>
                                    <Item>{txt.ConfigureUsbSticks()}</Item>
                                    <Item>{txt.UpdateTomConfigurations()}</Item>
                                    <Item>{txt.IdentifyUsbStick()}</Item>
                                    <Item>{txt.AutoUpdate()}</Item>
                                </BulletList>
                            </LabeledText>
                            <Conditional
                                c={this.state.layout.showNotInstalledHint}
                            >
                                <Notification
                                    title={txt.SyncAppRequired()}
                                    color={NotificationColor.blue}
                                    icon={NotificationIcon.info}
                                >
                                    {txt.NotInstalledHint()}
                                </Notification>
                            </Conditional>
                            <LabeledText label={txt.LatestVersion()}>
                                {latestVersionInfo.name} ({releaseDate})
                            </LabeledText>
                            <LabeledText label={txt.SystemRequirementsLabel()}>
                                {txt.SystemRequirementsValue()}
                            </LabeledText>
                            <a
                                href={SyncAppSlideIn.getInstallerDownloadUrl(
                                    latestVersionInfo,
                                )}
                            >
                                <TextButton
                                    onClick={() => {}}
                                    label={txt.DownloadLatestVersion()}
                                />
                            </a>
                        </SlideInBody>
                        <StandardFirstLevelHeader
                            onClose={() =>
                                this.update(SyncAppActions.closeSyncAppSlideIn)
                            }
                        >
                            <SlideInHeaderTexts
                                title={txt.SlideInHeaderCaption()}
                                hasLeftIcon={false}
                            />
                        </StandardFirstLevelHeader>
                    </HalfSlideIn>
                );
            }
        } else {
            return <HalfSlideIn open={false} />;
        }
    }
}
