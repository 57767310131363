import moment from 'moment';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Icon16 } from '../icons/Icon.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { range } from 'dg-web-shared/lib/ArrayUtil.ts';
import { DateSelectHandler } from './DatePicker.tsx';
import {
    getOrElse,
    isDefined,
    isUndefined,
    Maybe,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';
import React from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';

interface YearNavigationProps {
    visibleDate: moment.Moment;
    onPrev: Maybe<ClickHandler>;
    onNext: Maybe<ClickHandler>;
    onCurrent: Maybe<ClickHandler>;
}

export function iconButtonStyleParkingPortal(
    disabled: boolean,
    type: 'prev' | 'next',
): string {
    return css([
        {
            position: 'absolute',
            width: '40px',
            height: '100%',
            padding: '12px',
            ...OperatorTypo.bodyC,
        },
        disabled && {
            color: ColorHex.rgba(ColorHex.darkblue, 0.4),
        },
        !disabled && {
            '&:hover': {
                background: ColorHex.lightblue,
                color: ColorHex.white,
            },
        },
        type === 'prev' && {
            left: 0,
        },
        type === 'next' && {
            right: 0,
        },
    ]);
}

const YearNavigation = (p: YearNavigationProps): JSX.Element => (
    <div
        className={css({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '40px',
            background: ColorHex.rgba(ColorHex.lightblue, 0.2),
            color: ColorHex.darkblue,
        })}
    >
        <Clickable
            element="div"
            className={iconButtonStyleParkingPortal(
                isUndefined(p.onPrev),
                'prev',
            )}
            onClick={p.onPrev}
            disabled={isUndefined(p.onPrev)}
        >
            <Icon16 icon={Icons16.chevronLeft} />
        </Clickable>
        <Clickable
            element="div"
            className={iconButtonStyleParkingPortal(
                isUndefined(p.onNext),
                'next',
            )}
            onClick={p.onNext}
            disabled={isUndefined(p.onNext)}
        >
            <Icon16 icon={Icons16.chevronRight} />
        </Clickable>
        <Clickable
            element="div"
            className={css([
                {
                    margin: '0 40px',
                    textAlign: 'center',
                    height: '100%',
                    paddingTop: '11px',
                    ...OperatorTypo.bodyC,
                },
                !isUndefined(p.onCurrent) && {
                    '&:hover': {
                        background: ColorHex.lightblue,
                        color: ColorHex.white,
                    },
                },
            ])}
            onClick={p.onCurrent}
            disabled={isUndefined(p.onCurrent)}
        >
            {Formatter.year(p.visibleDate)}
        </Clickable>
    </div>
);

interface MonthsProps {
    visibleDate: moment.Moment;
    selectedDate: Maybe<moment.Moment>;
    onSelect: DateSelectHandler;
    minDate: Maybe<moment.Moment>;
    maxDate: Maybe<moment.Moment>;
    language: string;
}

const Months = (p: MonthsProps): JSX.Element => {
    const { minDate, maxDate, selectedDate } = p;
    return (
        <div
            className={css({
                position: 'absolute',
                top: '40px',
                bottom: 0,
                width: '100%',
                overflow: 'hidden',
                background: ColorHex.rgba(ColorHex.lightblue, 0.1),
            })}
        >
            {range(12).map(i => {
                const m = p.visibleDate.clone().startOf('year').add(i, 'month');
                const active =
                    (isUndefined(minDate) ||
                        m.clone().endOf('month').isAfter(minDate)) &&
                    (isUndefined(maxDate) || m.isBefore(maxDate));
                const dataSelected =
                    isDefined(selectedDate) &&
                    m.isSame(selectedDate, 'month') &&
                    m.isSame(selectedDate, 'year');
                return (
                    <Clickable
                        element="div"
                        className={css([
                            {
                                color: ColorHex.darkblue,
                                height: '40px',
                                width: '93px',
                                float: 'left',
                                textAlign: 'center',
                                marginTop: '15px',
                                marginBottom: '15px',
                                ...Typo.captionC1,
                                lineHeight: '40px',
                            },
                            dataSelected && {
                                color: ColorHex.white,
                                background: ColorHex.darkblue,
                            },
                            active && {
                                '&:hover': {
                                    color: ColorHex.white,
                                    background: ColorHex.lightblue,
                                },
                            },
                            !active && {
                                color: ColorHex.rgba(ColorHex.darkblue, 0.4),
                            },
                        ])}
                        key={m.unix()}
                        onClick={() => active && p.onSelect(m)}
                        disabled={!active}
                        data-selected={
                            isDefined(selectedDate) &&
                            m.isSame(selectedDate, 'month') &&
                            m.isSame(selectedDate, 'year')
                        }
                    >
                        {Formatter.monthNameAbbr(m, p.language)}
                    </Clickable>
                );
            })}
        </div>
    );
};

interface MonthPickerProps {
    currentDate: moment.Moment;
    selectedDate: Maybe<moment.Moment>;
    onSelect: DateSelectHandler;
    onClose?: Maybe<ClickHandler>;
    minDate: Maybe<moment.Moment>;
    maxDate: Maybe<moment.Moment>;
    language: string;
}

interface MonthPickerState {
    visibleDate?: moment.Moment;
}

export class MonthPicker extends React.Component<
    MonthPickerProps,
    MonthPickerState
> {
    constructor(props: MonthPickerProps) {
        super(props);
        this.state = {
            visibleDate: getOrElse(props.selectedDate, props.currentDate),
        };
    }

    UNSAFE_componentWillReceiveProps(props: MonthPickerProps): void {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = {
            visibleDate: getOrElse(props.selectedDate, props.currentDate),
        };
    }

    getPrevYearHandler(): Maybe<ClickHandler> {
        const { minDate } = this.props;
        if (
            isDefined(minDate) &&
            this.state.visibleDate &&
            this.state.visibleDate.clone().startOf('year').isBefore(minDate)
        ) {
            return null;
        } else {
            return () => {
                if (!this.state.visibleDate) {
                    return;
                }

                this.setState({
                    visibleDate: this.state.visibleDate
                        .clone()
                        .subtract(1, 'year'),
                });
            };
        }
    }

    getNextYearhHandler(): Maybe<ClickHandler> {
        const { maxDate } = this.props;
        if (
            isDefined(maxDate) &&
            this.state.visibleDate &&
            this.state.visibleDate.clone().endOf('year').isAfter(maxDate)
        ) {
            return null;
        } else {
            return () => {
                if (!this.state.visibleDate) {
                    return;
                }

                this.setState({
                    visibleDate: this.state.visibleDate.clone().add(1, 'year'),
                });
            };
        }
    }

    render() {
        return (
            <div
                className={css({
                    height: '320px',
                    width: '280px',
                    background: ColorHex.white,
                    position: 'relative',
                })}
            >
                <YearNavigation
                    onCurrent={this.props.onClose}
                    onNext={this.getNextYearhHandler()}
                    onPrev={this.getPrevYearHandler()}
                    visibleDate={this.state.visibleDate || moment()}
                />
                <Months
                    visibleDate={this.state.visibleDate || moment()}
                    selectedDate={this.props.selectedDate}
                    onSelect={this.props.onSelect}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    language={this.props.language}
                />
            </div>
        );
    }
}
