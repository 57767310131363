import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import {
    OnstreetTransactionFilter,
    zoneSelection,
} from '../OnstreetTransactionFilter.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import {
    selectableOnstreetZone,
    SelectableZone,
    ZonesSelectionSlideIn,
} from '../../ui/slidein/ZonesSelectionSlideIn.tsx';
import { Localized } from '../../common/components/Localized.tsx';

export function ZoneFilterSelection({
    filter,
}: {
    filter: OnstreetTransactionFilter;
}) {
    const { storeState } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        zones: MasterDataZonesState.get(store),
    }));

    return (
        <SingleSelection
            label={<Localized de="Zonen" fr="Zones" it="Zone" en="Zones" />}
            focused={false}
            selection={zoneSelection(
                filter.state.zoneIds,
                storeState.zones.data,
            )}
            onClick={() => filter.showZoneFilterSlideIn(true)}
            onClear={
                filter.state.zoneIds && filter.state.zoneIds.length > 0
                    ? () => filter.setZoneIds([])
                    : null
            }
        />
    );
}

export function ZoneFilterTransactionListSlideIn({
    filter,
}: {
    filter: OnstreetTransactionFilter;
}) {
    return (
        <ZoneFilterHalfSlideIn
            filter={filter.state.zoneIds}
            open={filter.state.zoneSelectionVisible}
            setSelection={filter.setZoneIds}
            onClose={() => filter.showZoneFilterSlideIn(false)}
            showInfoText={false}
        />
    );
}

function ZoneFilterHalfSlideIn(props: {
    filter: number[];
    open: boolean;
    setSelection: (zoneIds: number[]) => void;
    onClose: () => void;
    filterZonesWithoutRelevanceTag?: boolean;
    showInfoText: boolean;
}) {
    const { storeState } = useStore(s => ({
        zones: MasterDataZonesState.get(s),
    }));
    const selectableZones = zones(
        storeState.zones,
        props.filterZonesWithoutRelevanceTag || false,
    );

    const selectedIds = props.filter.filter(selectedId =>
        selectableZones.map(zone => zone.id).includes(selectedId),
    );

    return (
        <ZonesSelectionSlideIn
            open={props.open}
            selectedIds={selectedIds}
            title={<Localized de="Zonen" fr="Zones" it="Zone" en="Zones" />}
            zones={selectableZones}
            onSelectedIdsChange={selection => props.setSelection(selection)}
            onClose={props.onClose}
            infoText={
                props.showInfoText &&
                selectedIds.length != selectableZones.length ? (
                    <Localized
                        de="Neue Zonen werden nicht automatisch zugelassen."
                        fr="Les nouveaux zones ne sont pas automatiquement autorisés."
                        it="I nuovi zone non vengono autorizzati automaticamente."
                        en="New zones are not automatically allowed."
                    />
                ) : null
            }
        />
    );
}

function zones(
    zones: MasterDataZonesState.State,
    filterZonesWithoutRelevanceTag: boolean,
): SelectableZone[] {
    return zones.data
        .filter(z =>
            filterZonesWithoutRelevanceTag
                ? !z.isFromForeignMandant
                : !z.isFromForeignMandant &&
                  (z.relevance.tag ===
                      MasterDataZonesState.MasterDataZoneRelevanceTag.ACTIVE ||
                      (z.relevance.tag ===
                          MasterDataZonesState.MasterDataZoneRelevanceTag
                              .HAS_NON_ACTIVE_USAGES &&
                          z.relevance.hasCheckIns)) &&
                  z.transactionType !== null,
        )
        .map(selectableOnstreetZone);
}
