import { getOrElse, isDefined, Maybe, thenElse } from '../lib/MaybeV2';
import moment from 'moment';
import { ZoneEnforcedConfig } from './ZoneConfiguration';
import {
    parseTomConfiguration,
    TomConfiguration,
    TomConfigurationChangeType,
} from './TomConfiguration';
import { Zone } from './Zone';
import { TariffData } from './Tariff';

export interface Tom {
    tomId: number;
    operatorId: number;
    name: string;
    street?: string | null;
    zipCode?: string | null;
    place?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    operatorExternalId?: string | null;
    state: TomStatus;
    firmwareId?: number | null;
    targetFirmwareId?: number | null;
    creationDate: string;
    productionStartDate?: string | null;
    productionEndDate?: string | null;
    operationStartDate?: string | null;
    dismantledAtDate?: string | null;
    simId: number | null;
    synchronizationStatus: TomSynchronizationStatus;
    synchronizedConfigsExpiration?: string | null;
    expirationReason?: TomSyncReason | null;
    lastCollection?: string | null;
    lastOnlineStatus?: string | null;
    offline: boolean;
    lastBatteryVoltage?: number | null;
    lastCashBoxLevel?: number | null;
    errorCodes: number[];
    alarmCodes: number[];
    outOfService: boolean;
    synchronizationErrors: number[];
    isLogRequested: boolean;
    paymentType: TomPaymentType;
    model: TomModelType;
    simCardSupplier?: TomSimCardSupplier | null;
    navServiceArticleNumber: string | null;
}

export interface TomWithOperatorName extends Tom {
    operatorName: string;
}

export const enum TomStatus {
    PLANNING = 'planning',
    READY_FOR_PRODUCTION = 'ready_for_production',
    IN_PRODUCTION = 'in_production',
    OPERATIONAL = 'operational',
    DISMANTLED = 'dismantled',
}

export type TomPaymentType = 'pay_by_space' | 'pay_by_plate';

export enum TomModelType {
    SMALL = 'small',
    X = 'x',
}

export enum TomSimCardSupplier {
    EMNIFY = 'EMNIFY',
    EXTERNAL_DIGITALPARKING = 'EXTERNAL_DIGITALPARKING',
    EXTERNAL_OPERATOR = 'EXTERNAL_OPERATOR',
}

export namespace TomStateConstants {
    export const EDITABLE_STATES: TomStatus[] = [
        TomStatus.PLANNING,
        TomStatus.OPERATIONAL,
    ];
    export const PRE_OPERATIONAL_STATES: TomStatus[] = [
        TomStatus.PLANNING,
        TomStatus.READY_FOR_PRODUCTION,
        TomStatus.IN_PRODUCTION,
    ];
}

export type TomSynchronizationStatus =
    | 'in_sync'
    | 'needs_update'
    | 'updating'
    | 'error';
export type TomSyncReason =
    | 'new_tom_configuration'
    | 'new_zone_configuration'
    | 'out_of_special_days'
    | 'out_of_holidays'
    | 'not_all_configs_synced';

/*
 * @deprecated This interface uses Moment. Dates should be saved as ISO-Strings and transformed outside of the interface to DateTime.
 * Use Tom interface instead if possible.
 */
export interface ParsedTom {
    tomId: number;
    operatorId: number;
    name: string;
    street?: Maybe<string>;
    zipCode?: Maybe<string>;
    place?: Maybe<string>;
    longitude?: Maybe<number>;
    latitude?: Maybe<number>;
    operatorExternalId?: Maybe<string>;
    state: TomStatus;
    hasTransactions?: boolean;
    firmwareId?: Maybe<number>;
    targetFirmwareId?: Maybe<number>;
    creationDate: moment.Moment;
    productionStartDate?: Maybe<moment.Moment>;
    productionEndDate?: Maybe<moment.Moment>;
    operationStartDate?: Maybe<moment.Moment>;
    dismantledAtDate?: Maybe<moment.Moment>;
    simId: number | null;
    synchronizationStatus: TomSynchronizationStatus;
    synchronizedConfigsExpiration?: Maybe<moment.Moment>;
    expirationReason?: Maybe<TomSyncReason>;
    lastCollection?: Maybe<moment.Moment>;
    lastOnlineStatus?: Maybe<moment.Moment>;
    offline: boolean;
    lastBatteryVoltage?: Maybe<number>;
    lastCashBoxLevel?: Maybe<number>;
    errorCodes: number[];
    alarmCodes: number[];
    outOfService: boolean;
    synchronizationErrors: number[];
    isLogRequested: boolean;
    paymentType: TomPaymentType;
    model: TomModelType;
    simCardSupplier?: TomSimCardSupplier | null;
    navServiceArticleNumber: string | null;
    unfixableProblemsAdmin: string | null;
    unfixableProblemsComment: string | null;
    unfixableProblemsCommentAdminId: number | null;
    unfixableProblemsCommentDate: string | null;
}

/*
 * @deprecated This interface uses Moment. Dates should be saved as ISO-Strings and transformed outside of the interface to DateTime
 */
export interface TomListData extends ParsedTom {
    zoneDescription: string;
    tarifDescription: string;
}

/*
 * @deprecated Do not transform to deprecated Moment datatypes
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTomListData(t: any): TomListData {
    const baseTom = parseTom(t);
    return {
        ...baseTom,
        zoneDescription: t.zoneDescription,
        tarifDescription: t.tarifDescription,
    };
}

/*
 * @deprecated This interface uses Moment. Dates should be saved as ISO-Strings and transformed outside of the interface to DateTime
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTom(t: any): ParsedTom {
    return {
        tomId: t.tomId,
        operatorId: t.operatorId,
        name: t.name,
        street: t.street,
        zipCode: t.zipCode,
        place: t.place,
        longitude: t.longitude,
        latitude: t.latitude,
        operatorExternalId: t.operatorExternalId,
        state: t.state,
        hasTransactions: t.hasTransactions,
        firmwareId: t.firmwareId,
        targetFirmwareId: t.targetFirmwareId,
        creationDate: moment(t.creationDate),
        productionStartDate: isDefined(t.productionStartDate)
            ? moment(t.productionStartDate)
            : null,
        productionEndDate: isDefined(t.productionEndDate)
            ? moment(t.productionEndDate)
            : null,
        operationStartDate: isDefined(t.operationStartDate)
            ? moment(t.operationStartDate)
            : null,
        dismantledAtDate: isDefined(t.dismantledAtDate)
            ? moment(t.dismantledAtDate)
            : null,
        simId: t.simId,
        synchronizationStatus: t.synchronizationStatus,
        synchronizedConfigsExpiration: isDefined(
            t.synchronizedConfigsExpiration,
        )
            ? moment(t.synchronizedConfigsExpiration)
            : null,
        expirationReason: isDefined(t.expirationReason)
            ? t.expirationReason
            : null,
        lastCollection: isDefined(t.lastCollection)
            ? moment(t.lastCollection)
            : null,
        lastOnlineStatus: isDefined(t.lastOnlineStatus)
            ? moment(t.lastOnlineStatus)
            : null,
        offline: t.offline,
        lastBatteryVoltage: t.lastBatteryVoltage,
        lastCashBoxLevel: t.lastCashBoxLevel,
        errorCodes: (t.errorCodes || []) as number[],
        alarmCodes: (t.alarmCodes || []) as number[],
        outOfService: t.outOfService,
        synchronizationErrors: (t.synchronizationErrors || []) as number[],
        isLogRequested: t.isLogRequested,
        paymentType: t.paymentType,
        model: t.model,
        simCardSupplier: t.simCardSupplier,
        navServiceArticleNumber: t.navServiceArticleNumber,
        unfixableProblemsComment: t.unfixableProblemsComment,
        unfixableProblemsCommentAdminId: t.unfixableProblemsCommentAdminId,
        unfixableProblemsCommentDate: t.unfixableProblemsCommentDate,
        unfixableProblemsAdmin: null,
    };
}

/*
 * @deprecated This interface uses Moment. Dates should be saved as ISO-Strings and transformed outside of the interface to DateTime
 */
export interface FullTomAtTime extends ParsedTom {
    snapshotTime: moment.Moment; // time at which this config is active (input)

    // the following can be null if no configuration is available at the given snapshot time
    tomConfiguration?: Maybe<TomConfiguration>;
    zone?: Maybe<Zone>;
    zoneConfiguration?: Maybe<ZoneEnforcedConfig>;
    tariff?: Maybe<TariffData>;
    upcomingChanges: TomConfigurationChange[];
    unfixableProblemsAdmin: string | null;
    navInfos: NavInfos | null;
}

export interface NavInfos {
    installationDate: string;
    lastServiceDate: string;
    warrantyStart: string;
    warrantyEnd: string;
    serviceShipmentLines: ServiceShipmentLine[];
}

interface ServiceShipmentLine {
    saNumber: string;
    shipmentId: string;
    lineNo: number;
    postingDate: string;
    serviceDate: string;
    warranty: boolean;
    goodwill: boolean;
    itemId: string;
    description: string;
    quantity: number;
}

/*
 * @deprecated Do not transform to deprecated Moment datatypes
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseFullTom(t: any): FullTomAtTime {
    const parsedTom = parseTom(t);
    return {
        ...parsedTom,
        snapshotTime: moment(t.snapshotTime),
        tomConfiguration: thenElse(
            t.tomConfiguration,
            parseTomConfiguration,
            null,
        ),
        zone: t.zone,
        zoneConfiguration: t.zoneConfiguration,
        tariff: t.tariff,
        upcomingChanges: t.upcomingChanges.map(parseTomConfigurationChange),
        unfixableProblemsAdmin: t.unfixableProblemsAdmin,
        navInfos: t.navInfos || null,
    };
}

/*
 * @deprecated Do not transform to deprecated Moment datatypes
 */
export function parseTomConfigurationChange(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: any,
): TomConfigurationChange {
    return {
        configurationId: change.configurationId,
        validFrom: moment(change.validFrom),
        changeTypes: change.changeTypes.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (c: any) => c as TomConfigurationChangeType,
        ),
        comment: getOrElse(change.comment, ''),
    };
}

/*
 * @deprecated This interface uses Moment. Dates should be saved as ISO-Strings and transformed outside of the interface to DateTime
 */
export interface TomConfigurationChange {
    configurationId: number;
    validFrom: moment.Moment;
    changeTypes: TomConfigurationChangeType[];
    comment: string;
}
