import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    getOrElse,
    isDefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import {
    LicensePlateTrippleEntry,
    Tripple,
} from '../../common/components/LicensePlateTrippleEntry.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import {
    ConfirmationHeader,
    HalfSlideIn,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import * as AddLicensePlateTexts from '../i18n/AddLicensePlateTexts.ts';
import * as LicensePlateState from '../state/LicensePlateState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import {
    addLicensePlateToExistingPermit,
    addLicensePlateToNewPermit,
} from './PermitLicensePlateSlidein.tsx';

export interface LicensePlate {
    id: number;
    licensePlateNr: string;
    type: LicensePlateType;
    country: string;
}

export interface Texts {
    HeaderCaption: Translation;
    HeaderHeading: Translation;
    LicensePlateType: Translation;
    Country: Translation;
    LicensePlate: Translation;
    CarType: Translation;
    MotorcycleType: Translation;
    AlreadySelectedLicensePlate: Translation;
}

interface State {
    mode: DisplayMode;
    settings: SettingsState.State;
    operatorData: OperatorDataState.State;
    relevantPermitType: Maybe<PermitTypeState.PermitType>;
    addLp: LicensePlateState.AddTripple.State;
    addLpServer: LicensePlateState.AddServerData.State;
    selectedLicensePlates: LicensePlate[];
}

enum DisplayMode {
    CREATE,
    EDIT,
}

const closeSlideIn = (store: Flux.Store): string => {
    LicensePlateState.AddTripple.reset(store);
    return 'AddLicensePlateSlideIn-cancel';
};

const sendTripple = (needsVignette: boolean, carDescription: boolean) =>
    AsyncRequest.request(
        Http.OperatorAccount.LicensePlates.postTripple,
        (store: Flux.Store, res: Response): string => {
            // let's set the response on the add server data
            LicensePlateState.AddServerData.setResponse(store, res);
            if (res.statusCode.cls.success) {
                // on success, set the data also to the ServerData to avoid another
                // request
                const successData =
                    LicensePlateState.AddServerData.get(store).successData;

                const createEnabled =
                    PermitCreateState.Layout.get(store).createEnabled;
                if (successData) {
                    if (createEnabled) {
                        LicensePlateState.Entity.new_permit_add(
                            store,
                            successData.id,
                        );
                        if (!needsVignette && !carDescription) {
                            store.update(store =>
                                addLicensePlateToNewPermit(store, successData),
                            );
                        }
                    } else {
                        LicensePlateState.Entity.existing_permit_add(
                            store,
                            successData.id,
                        );
                        if (!needsVignette && !carDescription) {
                            store.update(store =>
                                addLicensePlateToExistingPermit(
                                    store,
                                    successData,
                                ),
                            );
                        }
                    }
                }
                // hide current slidein
                closeSlideIn(store);
            }
            return 'AddLicensePlateSlideIn-sendTripple';
        },
    );

export class AddLicensePlateSlideIn extends Flux.Container<State> {
    static displayName = 'AddLicensePlateSlideIn';

    stateSelector(): State {
        const permitCreateStateConfig = PermitCreateState.Config.get(
            this.props.allState,
        );
        const permitEditStateConfig = PermitEditState.Edit.get(
            this.props.allState,
        );

        const displayMode = isDefined(permitCreateStateConfig.permitTypeId)
            ? DisplayMode.CREATE
            : DisplayMode.EDIT;

        let permitTypeId: Maybe<number>;
        let selectedLicensePlates: LicensePlate[];
        if (displayMode === DisplayMode.CREATE) {
            permitTypeId = permitCreateStateConfig.permitTypeId;
            selectedLicensePlates = permitCreateStateConfig.licensePlates;
        } else {
            const permitData: Maybe<PermitEditState.Permit> =
                PermitEditState.Server.get(this.props.allState).data;
            permitTypeId = thenElse(permitData, d => d.permitTypeId, null);
            const permitLicensePlates: LicensePlate[] = thenElse(
                permitData,
                permit => permit.licensePlates,
                [],
            );

            // after edits, the selected plates will be in the EditStateConfig
            selectedLicensePlates = getOrElse(
                permitEditStateConfig.licensePlates,
                permitLicensePlates,
            );
        }
        const permitType: Maybe<PermitTypeState.PermitType> =
            PermitTypeState.getById(this.props.allState, permitTypeId);

        return {
            mode: displayMode,
            settings: new SettingsState.StateSlice(this.props.allState).state,
            operatorData: OperatorDataState.get(this.props.allState),
            addLp: LicensePlateState.AddTripple.get(this.props.allState),
            relevantPermitType: permitType,
            addLpServer: LicensePlateState.AddServerData.get(
                this.props.allState,
            ),
            selectedLicensePlates: selectedLicensePlates,
        };
    }

    txt(): Texts {
        return AddLicensePlateTexts.texts[this.state.settings.language];
    }

    renderContent() {
        const permitType = this.state.relevantPermitType;
        if (isDefined(permitType)) {
            return (
                <SlideInBody>
                    <LicensePlateTrippleEntry
                        language={this.state.settings.language}
                        allowedLicensePlateTypes={permitType.allowedLicensePlateTypes.map(
                            t => t.type,
                        )}
                        onCountrySlideInToggle={() =>
                            this.update(store =>
                                LicensePlateState.AddTripple.stateWrite(store, {
                                    lpCountrySelectionOpen: true,
                                }),
                            )
                        }
                        onTypeSelect={(type: LicensePlateType) =>
                            this.update(store =>
                                LicensePlateState.AddTripple.stateWrite(store, {
                                    type,
                                }),
                            )
                        }
                        licensePlateType={this.state.addLp.type}
                        country={this.state.addLp.country}
                        licensePlateNr={this.state.addLp.licensePlateNr}
                        onLicensePlateNumberChange={(
                            v: string,
                            formValid: boolean,
                        ) => {
                            this.props.allState.update(store =>
                                LicensePlateState.AddTripple.stateWrite(store, {
                                    licensePlateNr: v,
                                    formValid,
                                }),
                            );
                        }}
                        showErrors={this.state.addLp.confirmPressedWhileError}
                        outsideLicensePlateNrErrorGen={this.wasPlateAlreadySelectedGen()}
                    />
                </SlideInBody>
            );
        } else {
            return null;
        }
    }

    buttonSpecialState(): Maybe<ButtonSpecialState> {
        if (
            this.state.addLp.confirmPressedWhileError &&
            !this.state.addLp.formValid
        ) {
            return ButtonSpecialState.ERROR;
        } else if (!this.state.addLp.formValid) {
            return ButtonSpecialState.DISABLED;
        } else {
            return null;
        }
    }

    wasPlateAlreadySelectedGen(): (args: Tripple) => string | null {
        return (args: Tripple): string | null => {
            const { licensePlateNr, country, licensePlateType } = args;
            const wasSelected = this.state.selectedLicensePlates.some(plate => {
                return (
                    plate.country === country &&
                    plate.type === licensePlateType &&
                    plate.licensePlateNr === licensePlateNr
                );
            });
            if (wasSelected) {
                return this.txt().AlreadySelectedLicensePlate();
            } else {
                return null;
            }
        };
    }

    render() {
        const lp = this.state.addLp;

        return (
            <HalfSlideIn open={this.state.addLp.createLpOpen} outsideBody>
                <ConfirmationHeader
                    language={this.state.settings.language}
                    title={this.txt().HeaderCaption()}
                    onCancel={() => this.update(closeSlideIn)}
                    confirmButtonSpecialState={this.buttonSpecialState()}
                    onConfirm={() => {
                        const permitType = this.state.relevantPermitType;
                        const needsVignette =
                            !isDefined(permitType) ||
                            permitType.allowedLicensePlateTypes.some(
                                lp => lp.needsVignette,
                            );
                        const carType =
                            (this.state.operatorData.data &&
                                this.state.operatorData.data.settings
                                    .carTypeDescription) ||
                            false;

                        if (!this.state.addLp.formValid) {
                            this.props.allState.update(store =>
                                LicensePlateState.AddTripple.stateWrite(store, {
                                    confirmPressedWhileError: true,
                                }),
                            );
                        } else {
                            this.props.allState.update(
                                sendTripple(needsVignette, carType),
                                {
                                    licensePlateNr: lp.licensePlateNr as string,
                                    type: lp.type as LicensePlateType,
                                    country: lp.country as string,
                                },
                            );
                        }
                    }}
                />
                {this.renderContent()}
            </HalfSlideIn>
        );
    }
}
