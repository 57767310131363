import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { Clickable, ClickEvent } from 'dg-web-shared/ui/Clickable.tsx';
import { ClearancePermitsList } from './ClearancePermitsList.tsx';
import { selectClearance } from '../../actions/ClearancePermitListActions.ts';
import * as String from 'dg-web-shared/lib/String.ts';
import { InfoItem } from '../shared/InfoItem.tsx';
import { Clearance, Permit } from '../../state/ResultState.ts';
import {
    ColumnWidth,
    TableColumn,
    TableRow,
} from '../../../ui/table/Table.tsx';
import { IconTopOffsetContainer } from '../../../zones/components/ZoneListBody.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../../ui/Colors.ts';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import { IdentificationLicensePlate } from '../../../common/components/IdentificationItems.tsx';
import { Icon16, Icon24 } from '../../../ui/icons/Icon.tsx';
import {
    chevronRight,
    collapsed,
    expanded,
    identifier,
} from 'dg-web-shared/ui/icons/Icons16.tsx';
import { clearanceState } from 'dg-web-shared/ui/icons/Icons24.tsx';
import React from 'react';

interface ClearancesListItemProps {
    clearance: Clearance;
    key: React.Key;
    forceExpanded: boolean;
    update: Flux.Updater;
}

interface ToggleProps {
    expandable: boolean;
    expanded: boolean;
    onToggle: (e?: ClickEvent) => void;
}

export const ClearanceListItem = (p: { children?: React.ReactNode }) => (
    <div
        className={css({
            width: '100%',
            borderTop: `1px solid ${ColorHex.rgba(ColorHex.darkblue, 0.2)}`,
        })}
    >
        {p.children}
    </div>
);

export const ClearanceListItemRow = (p: {
    onClick: () => void;
    children?: React.ReactNode;
}) => (
    <TableRow
        className={css({
            display: 'table',
            width: '100%',
            background: ColorHex.white,
            color: ColorHex.darkblue,
            '&:hover': {
                background: ColorHex.rgba(ColorHex.lightblue, 0.2),
            },
        })}
        onClick={p.onClick}
    >
        {p.children}
    </TableRow>
);

export const Toggle = (p: ToggleProps) => {
    const toggleColumnClass = css({
        height: '100%',
        position: 'relative',
        display: 'table-cell',
        width: '24px',
        verticalAlign: 'middle',
        textAlign: 'center',
        backgroundColor: p.expandable
            ? ColorHex.rgba(ColorHex.darkblue, 1)
            : 'transparent',
    });

    if (p.expandable) {
        return (
            <Clickable
                element="div"
                className={toggleColumnClass}
                onClick={p.onToggle}
            >
                <div className={css({ color: ColorHex.white })}>
                    <IconTopOffsetContainer>
                        <Icon16 icon={p.expanded ? expanded : collapsed} />
                    </IconTopOffsetContainer>
                </div>
            </Clickable>
        );
    } else {
        return <div className={toggleColumnClass} />;
    }
};

export class ClearancesListItem extends React.Component<
    ClearancesListItemProps,
    { expanded: boolean }
> {
    constructor(p: ClearancesListItemProps) {
        super(p);
        this.state = { expanded: false };
    }

    render() {
        const expanded = this.state.expanded || this.props.forceExpanded;
        const c = this.props.clearance;
        const permits = c.permits;

        permits.sort((a: Permit, b: Permit) =>
            String.getSortFun({ asc: false })(a.validTo, b.validTo),
        );

        return (
            <ClearanceListItem>
                <ClearanceListItemRow
                    onClick={() =>
                        this.props.update(store => selectClearance(store, c.id))
                    }
                >
                    <Toggle
                        expandable={permits.length > 0}
                        expanded={expanded}
                        onToggle={(e?: ClickEvent) => {
                            if (e) {
                                this.setState({
                                    expanded: !this.state.expanded,
                                });
                                e.stopPropagation();
                            }
                        }}
                    />
                    <TableColumn width={ColumnWidth._192px} noPadding={true}>
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '4px',
                            })}
                        >
                            <div
                                className={css({
                                    width: '32px',
                                    paddingTop: '2px',
                                    color: ColorHex.darkblue,
                                })}
                            >
                                <Icon24
                                    icon={
                                        this.props.clearance.isActive
                                            ? clearanceState.active
                                            : clearanceState.inactive
                                    }
                                />
                            </div>
                            <div
                                className={css({
                                    ...OperatorTypo.body,
                                })}
                            >
                                {this.props.clearance.licensePlateNr ? (
                                    <IdentificationLicensePlate
                                        licensePlateNr={
                                            this.props.clearance.licensePlateNr
                                        }
                                        type={
                                            this.props.clearance
                                                .licensePlateType
                                        }
                                        country={
                                            this.props.clearance
                                                .licensePlateCountry
                                        }
                                        inlineMode={true}
                                    />
                                ) : (
                                    this.props.clearance.badgeLabelNr
                                )}
                            </div>
                        </div>
                    </TableColumn>
                    <TableColumn
                        width={ColumnWidth._72px}
                        captionFontSize={true}
                    >
                        {Date.Formatter.dayMonthYear(
                            Date.Parser.isoToMoment(c.validFrom),
                        )}
                    </TableColumn>
                    <TableColumn
                        width={ColumnWidth._72px}
                        captionFontSize={true}
                    >
                        {Date.Formatter.formatPermitToDateWithIndefinite(
                            Date.Parser.isoToMoment(c.validTo),
                        )}
                    </TableColumn>
                    <TableColumn width={ColumnWidth.variable}>
                        {this.props.clearance.permitTypeName}
                    </TableColumn>
                    <TableColumn
                        width={ColumnWidth._312px}
                        captionFontSize={true}
                    >
                        <div>
                            <InfoItem
                                icon={identifier.contractNumber}
                                value={this.props.clearance.contractNumber}
                            />
                            <InfoItem
                                icon={identifier.personalNumber}
                                value={this.props.clearance.personalNumber}
                            />
                            <InfoItem
                                icon={identifier.infoField}
                                value={this.props.clearance.infos}
                            />
                        </div>
                    </TableColumn>
                    <TableColumn
                        width={ColumnWidth._24px}
                        captionFontSize={true}
                    >
                        <div className={css({ marginTop: 0 })}>
                            <IconTopOffsetContainer>
                                <Icon16 icon={chevronRight} />
                            </IconTopOffsetContainer>
                        </div>
                    </TableColumn>
                </ClearanceListItemRow>
                <ClearancePermitsList
                    permits={permits}
                    expanded={expanded}
                    update={this.props.update}
                />
            </ClearanceListItem>
        );
    }
}
