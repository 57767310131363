import { DateTime } from 'luxon';
import moment from 'moment';
import { Parser, Range } from 'dg-web-shared/lib/Date.ts';

import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';

export type { LicensePlate } from 'dg-web-shared/dto/LicensePlate.ts';

export enum PermitStatus {
    active = 'active',
    future = 'future',
    expired = 'expired',
    stornod = 'stornod',
    credited = 'credited',
}

interface PermitDates {
    validFrom: string;
    validTo: string;
}

export const isActive = (p: PermitDates, ref: moment.Moment): boolean => {
    return new Range(
        Parser.isoToMoment(p.validFrom),
        Parser.isoToMoment(p.validTo),
    ).contains(ref);
};

export const isFuture = (p: PermitDates, ref: moment.Moment): boolean =>
    new Range(
        Parser.isoToMoment(p.validFrom),
        Parser.isoToMoment(p.validTo),
    ).after(ref) && !isStornod(p);

/**
 * @deprecated use luxon instead
 */
export const isExpiredOld = (p: PermitDates, ref: moment.Moment): boolean =>
    new Range(
        Parser.isoToMoment(p.validFrom),
        Parser.isoToMoment(p.validTo),
    ).before(ref) && !isStornod(p);

export const isExpired = (p: PermitDates): boolean =>
    DateTime.fromISO(p.validTo) < DateTime.now();

export const isStornod = (p: PermitDates): boolean => p.validTo === p.validFrom;

export const permitStatusString = (p: PermitDates, ref: moment.Moment) =>
    isActive(p, ref)
        ? PermitStatus.active
        : isStornod(p)
          ? PermitStatus.stornod
          : isFuture(p, ref)
            ? PermitStatus.future
            : PermitStatus.expired;

export const permitStatusIcon = (p: PermitDates, ref: moment.Moment) =>
    isActive(p, ref)
        ? Icons24.permitState.active
        : isStornod(p)
          ? Icons24.permitState.canceled
          : isFuture(p, ref)
            ? Icons24.permitState.future
            : Icons24.permitState.expired;
