import {
    HalfSlideIn,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../ui/slidein/Slidein.tsx';
import { OnstreetTransactionFilter } from './OnstreetTransactionFilter.tsx';
import { SelectedTab } from './OnstreetTransaction.tsx';
import {
    DateFilterHalfSlideIn,
    DateFilterSelection,
} from './filter-slidein-components/DateFilter.tsx';
import {
    ZoneFilterSelection,
    ZoneFilterTransactionListSlideIn,
} from './filter-slidein-components/ZoneFilter.tsx';
import {
    TransactionStatusFilterHalfSlideIn,
    TransactionStatusFilterSelection,
} from './filter-slidein-components/TransactionStatusFilter.tsx';
import { Localized } from '../common/components/Localized.tsx';

export function OnstreetTransactionFilterSlideIns({
    selectedTab,
    language,
    filter,
}: {
    selectedTab: SelectedTab;
    language: string;
    filter: OnstreetTransactionFilter;
}): JSX.Element | null {
    switch (selectedTab) {
        case SelectedTab.TRANSACTIONS:
            return (
                <>
                    <FilterHalfSlideIn
                        key="filter"
                        language={language}
                        filter={filter}
                    />
                    <DateFilterHalfSlideIn
                        key="dateFilter"
                        language={language}
                        filter={filter}
                    />
                    <ZoneFilterTransactionListSlideIn
                        key="listZoneFilter"
                        filter={filter}
                    />
                    <TransactionStatusFilterHalfSlideIn
                        key="status"
                        language={language}
                        filter={filter}
                    />
                </>
            );
    }
}

function FilterHalfSlideIn({
    language,
    filter,
}: {
    language: string;
    filter: OnstreetTransactionFilter;
}) {
    return (
        <HalfSlideIn open={filter.state.filterSlideInVisible}>
            <StandardFirstLevelHeader
                onClose={() => filter.showFilterSlideIn(false)}
                title={
                    <Localized
                        de="Filter"
                        fr="Filtre"
                        it="Filtro"
                        en="Filter"
                    />
                }
            />
            <SlideInBody>
                <DateFilterSelection language={language} filter={filter} />
                <ZoneFilterSelection filter={filter} />
                <TransactionStatusFilterSelection
                    language={language}
                    filter={filter}
                />
            </SlideInBody>
        </HalfSlideIn>
    );
}
