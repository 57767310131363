import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as AuthenticationActions from '../actions/AuthenticationActions.ts';

export enum AuthStatusType {
    Unknown,
    Authorized,
    Unauthorized,
}

export class AuthStatus {
    private status: AuthStatusType;

    constructor(authStatusType: AuthStatusType) {
        this.status = authStatusType;
    }

    get authorized(): boolean {
        return this.status === AuthStatusType.Authorized;
    }

    get unauthorized(): boolean {
        return this.status === AuthStatusType.Unauthorized;
    }

    get unknown(): boolean {
        return this.status === AuthStatusType.Unknown;
    }
}

export interface State {
    status: AuthStatus;
    statusPending: boolean;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'app-AuthenticationState';
    }

    getInitialState(): State {
        return {
            status: new AuthStatus(AuthStatusType.Unknown),
            statusPending: false,
        };
    }

    sideEffects(store: Flux.Store): void {
        if (this.state.status.unknown && !this.state.statusPending) {
            this.writeStatusPending(true);
            store.update(AuthenticationActions.authTokenValidity);
        }
    }

    writeStatusPending(v: boolean): void {
        this.set((s: State) => {
            s.statusPending = v;
            return s;
        });
    }

    writeStatus(status: AuthStatusType): void {
        this.set((s: State) => {
            s.status = new AuthStatus(status);
            return s;
        });
    }
}
