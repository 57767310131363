import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { Icon16, Icon24 } from '../icons/Icon.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { css } from '@emotion/css';
import { clearfix } from 'dg-web-shared/ui/clearfix.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { ColorHex } from '../Colors.ts';
import React from 'react';

export enum SwitchType {
    Node,
    RadioButton,
    Checkbox,
}

interface SwitchIconProps {
    className: string;
    selected: boolean;
    allSelected?: boolean;
    type: SwitchType;
}

const SwitchIcon = (p: SwitchIconProps): JSX.Element => (
    <div className={p.className}>
        <Icon16
            icon={
                p.type === SwitchType.Node
                    ? p.selected
                        ? p.allSelected
                            ? Icons16.node.all
                            : Icons16.node.on
                        : Icons16.node.off
                    : p.type === SwitchType.Checkbox
                      ? p.selected
                          ? p.allSelected
                              ? Icons16.checkbox.all
                              : Icons16.checkbox.on
                          : Icons16.checkbox.off
                      : p.selected
                        ? Icons16.radiobutton.on
                        : Icons16.radiobutton.off
            }
        />
    </div>
);

interface SwitchProps {
    label: React.ReactNode;
    selected: boolean;
    onClick: ClickHandler;
    type: SwitchType;
    icon?: Maybe<JSX.Element>;
    disabled?: boolean;
}

export class Switch extends React.Component<SwitchProps> {
    renderIcon(): JSX.Element | null {
        return thenElse(this.props.icon, icon => <Icon24 icon={icon} />, null);
    }

    render() {
        return (
            <Clickable
                className={css([
                    {
                        height: '24px',
                        color: this.props.selected
                            ? ColorHex.darkblue
                            : ColorHex.rgba(ColorHex.darkblue, 0.6),
                        ...Typo.captionC1,
                        display: 'inline-block',
                        '&:hover': {
                            color:
                                this.props.disabled || this.props.selected
                                    ? ColorHex.rgba(ColorHex.darkblue, 0.6)
                                    : ColorHex.darkblue,
                        },
                    },
                    clearfix,
                ])}
                element="div"
                onClick={!this.props.disabled ? this.props.onClick : null}
                data-selected={this.props.selected}
                data-type={
                    this.props.type === SwitchType.Node
                        ? 'checkbox'
                        : 'radiobutton'
                }
                disabled={this.props.disabled}
            >
                <SwitchIcon
                    className={css({
                        paddingTop: '4px',
                        float: 'left',
                    })}
                    type={this.props.type}
                    selected={this.props.selected}
                />
                {this.renderIcon()}
                <div
                    className={css({
                        marginLeft: '8px',
                        paddingTop: '5px',
                        float: 'left',
                    })}
                >
                    {this.props.label}
                </div>
            </Clickable>
        );
    }
}

export interface RadioButtonProps {
    label: string;
    selected: boolean;
    onClick: ClickHandler;
    icon?: Maybe<JSX.Element>;
    disabled?: boolean;
}

export const RadioButton = (p: RadioButtonProps): JSX.Element => (
    <Switch {...p} type={SwitchType.RadioButton} />
);
