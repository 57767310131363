import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import {
    isDefinedAndDiffersFrom,
    isUndefined,
    Maybe,
} from 'dg-web-shared/lib/MaybeV2.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    parseStickDetail,
    parseUsbStick,
    UsbStick,
    UsbStickDetail,
} from 'dg-web-shared/model/UsbStick.ts';

export namespace List {
    export type State = ServerStateSlice.ServerState<UsbStick[]>;

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<UsbStick[]>(
            'UsbStickState-UsbSticks',
            () => [],
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(fetchUsbSticks);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (body: any): UsbStick[] => {
                if (isUndefined(body)) {
                    return [];
                } else {
                    return body.map(parseUsbStick);
                }
            },
        );

    const fetchUsbSticks = AsyncRequest.request(
        Http.OperatorAccount.UsbSticks.get,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            return 'UsbStickState-fetchUsbSticks';
        },
    );
}

export namespace Detail {
    export interface Context {
        usbStickId: number;
    }

    export type State =
        ContextualServerStateSlice.ContextualState<UsbStickDetail>;

    export const { get, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<
            Context,
            UsbStickDetail
        >({
            key: 'UsbStickState-UsbStickDetail',
            requestGenerator: (context: Context) =>
                Http.OperatorAccount.UsbSticks.getDetail(context),
            request: AsyncRequest.request,
            parseBody: parseStickDetail,
        });
}

export namespace Edit {
    export interface State {
        name?: Maybe<string>;
    }

    export const hasChanges = (s: State, usbStick: UsbStickDetail): boolean => {
        return isDefinedAndDiffersFrom(s.name, usbStick.name);
    };

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'UsbStickState-UsbStickDetail-Edit',
        {},
    );
}

export namespace EditBaseDataResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'UsbStickState-EditBaseDataResponse',
        () => null,
    );
}

export namespace Layout {
    export interface State {
        selectedUsbStickId?: Maybe<number>;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'UsbStickState-Layout',
        {},
    );
}
