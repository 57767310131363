import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Icon24 } from '../icons/Icon.tsx';
import { Translation, Translations } from '../../common/i18n/Text.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';
import React from 'react';

interface Texts {
    Search: Translation;
}

export const texts: Translations<Texts> = {
    de: {
        Search: () => 'Suche',
    },
    fr: {
        Search: () => 'Recherche',
    },
    it: {
        Search: () => 'Ricerca',
    },
};

interface Props {
    language: string;
    onClick: () => void;
}

export class SearchPlaceholder extends React.Component<Props> {
    render() {
        return (
            <Clickable
                element="div"
                className={css({
                    background: ColorHex.rgba(ColorHex.darkblue, 0.5),
                    height: '52px',
                    borderWidth: '0',
                    borderColor: ColorHex.white,
                    borderStyle: 'solid',
                    borderBottomWidth: '2px',
                    borderRightWidth: '2px',
                    '&:hover': {
                        background: ColorHex.lightblue,
                    },
                })}
                onClick={this.props.onClick}
            >
                <div
                    className={css({
                        ...OperatorTypo.body,
                        color: ColorHex.white,
                        position: 'absolute',
                        top: '24px',
                        left: '24px',
                    })}
                >
                    {texts[this.props.language].Search()}
                </div>
                <div
                    className={css({
                        color: ColorHex.white,
                        position: 'absolute',
                        right: '22px',
                        bottom: '8px',
                    })}
                >
                    <Icon24 icon={Icons24.search_m} />
                </div>
            </Clickable>
        );
    }
}
