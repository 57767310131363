import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as Text from '../../../common/i18n/Text.ts';
import { validDateFilterTexts } from '../../i18n/FilteredPermitsTexts.ts';
import { DatePicker } from '../../../ui/date-picker/DatePicker.tsx';
import moment from 'moment';
import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from '../../../ui/slidein/Slidein.tsx';
import * as FilteredPermitsActions from '../../actions/FilteredPermitsActions.ts';
import { DateHeader } from '../shared/DateHeader.tsx';
import { css } from '@emotion/css';

export interface ValidDateFilterTexts {
    filterTitle: Text.Translation;
    from: Text.Translation;
    to: Text.Translation;
}

interface ValidDateFilterHalfSlideInState {
    settings: SettingsState.State;
    filter: FilteredPermitsState.Filter.State;
}

export class ValidDateFilterHalfSlideIn extends Flux.Container<ValidDateFilterHalfSlideInState> {
    stateSelector(): ValidDateFilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredPermitsState.Filter.get(this.props.allState),
        };
    }

    getTexts(): ValidDateFilterTexts {
        return validDateFilterTexts[this.state.settings.language];
    }

    renderFromDatePicker(): JSX.Element {
        const texts = this.getTexts();
        return (
            <div className={css({ marginBottom: '30px' })}>
                <DateHeader
                    label={texts.from()}
                    date={this.state.filter.validDateFrom}
                />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={this.state.filter.validDateFrom}
                    onSelect={d =>
                        this.props.allState.update(store =>
                            FilteredPermitsActions.setValidDateFrom(store, d),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    renderToDatePicker(): JSX.Element {
        const texts = this.getTexts();
        return (
            <div>
                <DateHeader
                    label={texts.to()}
                    date={this.state.filter.validDateTo}
                />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={this.state.filter.validDateTo}
                    onSelect={d =>
                        this.props.allState.update(store =>
                            FilteredPermitsActions.setValidDateTo(store, d),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    render() {
        const texts = validDateFilterTexts[this.state.settings.language];
        return (
            <HalfSlideIn open={this.state.filter.validDateSelectionVisible}>
                <SecondLevelHeader
                    onClose={() =>
                        this.props.allState.update(store =>
                            FilteredPermitsState.Filter.stateWrite(store, {
                                validDateSelectionVisible: false,
                            }),
                        )
                    }
                >
                    <SlideInHeaderTexts
                        subtitle={''}
                        title={texts.filterTitle()}
                        hasLeftIcon={false}
                    />
                </SecondLevelHeader>
                <SlideInBody>
                    <div
                        className={css({
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            overflowX: 'hidden',
                            overflowY: 'auto',
                        })}
                    >
                        <div
                            className={css({
                                width: '280px',
                                margin: '30px auto 0 auto',
                            })}
                        >
                            {this.renderFromDatePicker()}
                            {this.renderToDatePicker()}
                        </div>
                    </div>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

interface ValidDateFilterSelectionState {
    settings: SettingsState.State;
    filter: FilteredPermitsState.Filter.State;
}

export class ValidDateFilterSelection extends Flux.Container<ValidDateFilterSelectionState> {
    stateSelector(): ValidDateFilterSelectionState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredPermitsState.Filter.get(this.props.allState),
        };
    }

    render() {
        const texts = validDateFilterTexts[this.state.settings.language];
        const selection = Formatter.openRange(
            this.state.filter.validDateFrom,
            this.state.filter.validDateTo,
            Formatter.dayMonthYear,
        );

        const write = (s: Partial<FilteredPermitsState.Filter.State>) =>
            this.props.allState.update(store =>
                FilteredPermitsState.Filter.stateWrite(store, s),
            );

        const clear = FilteredPermitsState.Filter.validDateFilterActive(
            this.state.filter,
        )
            ? () => this.update(FilteredPermitsActions.clearValidDate)
            : null;

        return (
            <div>
                <SingleSelection
                    label={texts.filterTitle()}
                    focused={false}
                    selection={selection}
                    onClick={() => write({ validDateSelectionVisible: true })}
                    onClear={clear}
                />
            </div>
        );
    }
}
