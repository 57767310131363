import * as ArrayUtil from 'dg-web-shared/lib/ArrayUtil.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { holidayTexts } from '../texts/HolidayTexts.ts';
import {
    addDays,
    getEasterSunday,
    getEidgBettag,
    getGenevaBettag,
    getHilariFriday,
    getKnabenschiessenMonday,
    getMaienzug,
} from './variableHolidaysLogic.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';

export interface HolidayTexts {
    NewYear: Translation;
    Berchtoldstag: Translation;
    Epiphany: Translation;
    MariaLichtmess: Translation;
    StJosef: Translation;
    LaborDay: Translation;
    StPeterAndPaul: Translation;
    NationalDayCH: Translation;
    AssumptionOfMary: Translation;
    NationalDayLI: Translation;
    MaryBirth: Translation;
    AllSaintsDay: Translation; // Allerheiligen
    AndreasTag: Translation;
    ConceptionOfMary: Translation; // Mariä Empfängnis
    ChristmasEve: Translation;
    Christmas: Translation;
    StStephensDay: Translation;
    NewYearsEve: Translation;
    // variable in every year
    HilariFriday: Translation;
    HilariSaturday: Translation;
    GoodFriday: Translation;
    Easter: Translation;
    EasterMonday: Translation;
    Sechselauten: Translation;
    AscensionDay: Translation;
    Pentecost: Translation;
    PentecostMonday: Translation;
    CorpusChristi: Translation;
    Maienzug: Translation;
    KnabenschiessenMonday: Translation;
    BettagSunday: Translation;
    BettagMonday: Translation;
    BettagGeneva: Translation;
}

export type HolidayKey =
    // fixed days in each year
    | 'NewYear'
    | 'Berchtoldstag'
    | 'Epiphany'
    | 'MariaLichtmess'
    | 'StJosef'
    | 'LaborDay'
    | 'StPeterAndPaul'
    | 'NationalDayCH'
    | 'AssumptionOfMary'
    | 'NationalDayLI'
    | 'MaryBirth'
    | 'AllSaintsDay' // Allerheiligen
    | 'AndreasTag'
    | 'ConceptionOfMary' // Mariä Empfängnis
    | 'ChristmasEve'
    | 'Christmas'
    | 'StStephensDay'
    | 'NewYearsEve'
    // variable in every year
    | 'HilariFriday'
    | 'HilariSaturday'
    | 'GoodFriday'
    | 'Easter'
    | 'EasterMonday'
    | 'AscensionDay'
    | 'Pentecost'
    | 'PentecostMonday'
    | 'CorpusChristi'
    | 'Maienzug'
    | 'KnabenschiessenMonday'
    | 'BettagSunday'
    | 'BettagMonday'
    | 'BettagGeneva';

export type LanguageTranslation = (lng: string) => string;

export type Holiday = FixedHoliday | VariableHoliday;

interface BaseHoliday {
    key: HolidayKey;
    text: LanguageTranslation;
}

export interface FixedHoliday extends BaseHoliday {
    type: 'fixed';
    month: number;
    day: number;
}

export interface VariableHoliday extends BaseHoliday {
    type: 'variable';
    dateInYear: (year: number) => string;
}

export const FIXED_DAY_HOLIDAYS: FixedHoliday[] = [
    {
        key: 'NewYear',
        type: 'fixed',
        text: lng => holidayTexts[lng].NewYear(),
        month: 1,
        day: 1,
    },
    {
        key: 'Berchtoldstag',
        type: 'fixed',
        text: lng => holidayTexts[lng].Berchtoldstag(),
        month: 1,
        day: 2,
    },
    {
        key: 'Epiphany', // dreikönigstag
        type: 'fixed',
        text: lng => holidayTexts[lng].Epiphany(),
        month: 1,
        day: 6,
    },
    {
        key: 'MariaLichtmess',
        type: 'fixed',
        text: lng => holidayTexts[lng].MariaLichtmess(),
        month: 2,
        day: 2,
    },
    {
        key: 'StJosef',
        type: 'fixed',
        text: lng => holidayTexts[lng].StJosef(),
        month: 3,
        day: 19,
    },
    {
        key: 'LaborDay',
        type: 'fixed',
        text: lng => holidayTexts[lng].LaborDay(),
        month: 5,
        day: 1,
    },
    {
        key: 'StPeterAndPaul',
        type: 'fixed',
        text: lng => holidayTexts[lng].StPeterAndPaul(),
        month: 6,
        day: 29,
    },
    {
        key: 'NationalDayCH',
        type: 'fixed',
        text: lng => holidayTexts[lng].NationalDayCH(),
        month: 8,
        day: 1,
    },
    {
        key: 'AssumptionOfMary',
        type: 'fixed',
        text: lng => holidayTexts[lng].AssumptionOfMary(),
        month: 8,
        day: 15,
    },
    {
        key: 'NationalDayLI',
        type: 'fixed',
        text: lng => holidayTexts[lng].NationalDayLI(),
        month: 8,
        day: 15,
    },
    {
        key: 'MaryBirth',
        type: 'fixed',
        text: lng => holidayTexts[lng].MaryBirth(),
        month: 9,
        day: 8,
    },
    {
        key: 'AllSaintsDay',
        type: 'fixed',
        text: lng => holidayTexts[lng].AllSaintsDay(),
        month: 11,
        day: 1,
    },
    {
        key: 'AndreasTag',
        type: 'fixed',
        text: lng => holidayTexts[lng].AndreasTag(),
        month: 11,
        day: 30,
    },
    {
        key: 'ConceptionOfMary',
        type: 'fixed',
        text: lng => holidayTexts[lng].ConceptionOfMary(),
        month: 12,
        day: 8,
    },
    {
        key: 'ChristmasEve',
        type: 'fixed',
        text: lng => holidayTexts[lng].ChristmasEve(),
        month: 12,
        day: 24,
    },
    {
        key: 'Christmas',
        type: 'fixed',
        text: lng => holidayTexts[lng].Christmas(),
        month: 12,
        day: 25,
    },
    {
        key: 'StStephensDay',
        type: 'fixed',
        text: lng => holidayTexts[lng].StStephensDay(),
        month: 12,
        day: 26,
    },
    {
        key: 'NewYearsEve',
        type: 'fixed',
        text: lng => holidayTexts[lng].NewYearsEve(),
        month: 12,
        day: 31,
    },
];

export const VARIABLE_DATE_HOLIDAYS: VariableHoliday[] = [
    {
        key: 'HilariFriday',
        type: 'variable',
        text: lng => holidayTexts[lng].HilariFriday(),
        dateInYear: year =>
            Date.Formatter.isoYearMonthDay(getHilariFriday(year)),
    },
    {
        key: 'HilariSaturday',
        type: 'variable',
        text: lng => holidayTexts[lng].HilariSaturday(),
        dateInYear: year => addDays(getHilariFriday(year), 1),
    },
    {
        key: 'GoodFriday',
        type: 'variable',
        text: lng => holidayTexts[lng].GoodFriday(),
        dateInYear: year => addDays(getEasterSunday(year), -2),
    },
    {
        key: 'Easter',
        type: 'variable',
        text: lng => holidayTexts[lng].Easter(),
        dateInYear: year =>
            Date.Formatter.isoYearMonthDay(getEasterSunday(year)),
    },
    {
        key: 'EasterMonday',
        type: 'variable',
        text: lng => holidayTexts[lng].EasterMonday(),
        dateInYear: year => addDays(getEasterSunday(year), 1),
    },
    // Sechseläuten is not available because the date can be chosen based on school holidays
    // which does not follow any strict rules [1]
    // [1]: https://de.wikipedia.org/wiki/Sechsel%C3%A4uten#Datum
    {
        key: 'AscensionDay',
        type: 'variable',
        text: lng => holidayTexts[lng].AscensionDay(),
        dateInYear: year => addDays(getEasterSunday(year), 39),
    },
    {
        key: 'Pentecost',
        type: 'variable',
        text: lng => holidayTexts[lng].Pentecost(),
        dateInYear: year => addDays(getEasterSunday(year), 49),
    },
    {
        key: 'PentecostMonday',
        type: 'variable',
        text: lng => holidayTexts[lng].PentecostMonday(),
        dateInYear: year => addDays(getEasterSunday(year), 50),
    },
    {
        key: 'CorpusChristi',
        type: 'variable',
        text: lng => holidayTexts[lng].CorpusChristi(),
        dateInYear: year => addDays(getEasterSunday(year), 60),
    },
    {
        key: 'Maienzug',
        type: 'variable',
        text: lng => holidayTexts[lng].Maienzug(),
        dateInYear: year => Date.Formatter.isoYearMonthDay(getMaienzug(year)),
    },
    {
        key: 'KnabenschiessenMonday',
        type: 'variable',
        text: lng => holidayTexts[lng].KnabenschiessenMonday(),
        dateInYear: year =>
            Date.Formatter.isoYearMonthDay(getKnabenschiessenMonday(year)),
    },
    {
        key: 'BettagSunday',
        type: 'variable',
        text: lng => holidayTexts[lng].BettagSunday(),
        dateInYear: year => Date.Formatter.isoYearMonthDay(getEidgBettag(year)),
    },
    {
        key: 'BettagMonday',
        type: 'variable',
        text: lng => holidayTexts[lng].BettagMonday(),
        dateInYear: year => addDays(getEidgBettag(year), 1),
    },
    {
        key: 'BettagGeneva',
        type: 'variable',
        text: lng => holidayTexts[lng].BettagGeneva(),
        dateInYear: year => addDays(getGenevaBettag(year), 0),
    },
];

export const HOLIDAYS: Holiday[] = [
    ...FIXED_DAY_HOLIDAYS,
    ...VARIABLE_DATE_HOLIDAYS,
];

const HOLIDAY_MAP: { [key: string]: Holiday } = ArrayUtil.indexBy(
    HOLIDAYS,
    h => h.key,
);

export function getHolidayByKey(key: HolidayKey): Holiday {
    return HOLIDAY_MAP[key];
}
