import { HasChildren } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Icon24 } from '../icons/Icon.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { css } from '@emotion/css';
import { clearfix } from 'dg-web-shared/ui/clearfix.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

interface FilterSummaryProps extends HasChildren {
    hover: boolean;
}

export const FilterSummary = (p: FilterSummaryProps): JSX.Element => (
    <div
        className={css({
            height: 32,
            borderTopLeftRadius: 32,
            borderBottomLeftRadius: 32,
        })}
        data-hover={p.hover}
    >
        {p.children}
    </div>
);

type FilterSummaryStatusProps = HasChildren;
export const FilterSummaryStatus = (
    p: FilterSummaryStatusProps,
): JSX.Element => (
    <div
        className={css([
            {
                float: 'left',
                marginTop: '-4px',
                marginLeft: '-4px',
                marginRight: '16px',
            },
            clearfix,
        ])}
    >
        {p.children}
    </div>
);

type FilterSummaryContainerProps = HasChildren;
export const FilterSummaryContainer = (
    p: FilterSummaryContainerProps,
): JSX.Element => <div className={css({ float: 'left' })}>{p.children}</div>;

interface FilterSummaryCloseProps {
    onClick: ClickHandler;
    onMouseEnter: React.MouseEventHandler<HTMLElement>;
    onMouseLeave: React.MouseEventHandler<HTMLElement>;
}

export const FilterSummaryClose = (p: FilterSummaryCloseProps): JSX.Element => (
    <Clickable
        element="div"
        className={css({
            float: 'left',
            marginLeft: '8px',
            padding: '4px',
            height: '32px',
            width: '32px',
            '&:hover': {
                background: Colors.lightblue,
                color: Colors.white,
            },
        })}
        onClick={p.onClick}
        onMouseEnter={p.onMouseEnter}
        onMouseLeave={p.onMouseLeave}
    >
        <Icon24 icon={Icons24.clear_m} />
    </Clickable>
);

interface FilterSummaryItemProps extends HasChildren {
    hasSeparator: boolean;
}

export const FilterSummaryItem = (p: FilterSummaryItemProps): JSX.Element => (
    <div
        className={css([
            {
                float: 'left',
                height: '24px',
                color: Colors.darkblue,
                marginTop: '4px',
            },
            p.hasSeparator && {
                overflowX: 'hidden',
                overFlowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                marginLeft: '16px',
                borderLeft: `1px solid ${Colors.rgba(Colors.darkblue, 0.2)}`,
                paddingLeft: '15px',
            },
            clearfix,
        ])}
    >
        {p.children}
    </div>
);

interface FilterSummaryItemTitleProps {
    title: React.ReactNode;
}

export const FilterSummaryItemTitle = (
    p: FilterSummaryItemTitleProps,
): JSX.Element => (
    <div
        className={css({
            ...Typo.captionC2,
            lineHeight: '24px',
            float: 'left',
        })}
    >
        {p.title}
    </div>
);

interface FilterSummaryItemSubTitleProps {
    title: React.ReactNode;
}

export const FilterSummaryItemSubTitle = (
    p: FilterSummaryItemSubTitleProps,
): JSX.Element => (
    <div
        className={css({
            ...Typo.captionC1,
            lineHeight: '24px',
            float: 'left',
        })}
    >
        {p.title}
    </div>
);
