import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import {
    getStringItem,
    Items,
    setStringItem,
} from 'dg-web-shared/lib/LocalStorage.ts';

interface SearchStrings {
    LicensePlate: string;
    Badge: string;
    PersonalNr: string;
    ContractNr: string;
    ClearanceInfo: string;
    PermitId: string;
    CustomerName: string;
}

export type SearchEntity = keyof SearchStrings;

export interface State {
    searchValue: string;
    searchEntity: SearchEntity;
    searchFocused: boolean;
}

export const s = Flux.generateState<State>(
    'clearance-permit-list-SearchState',
    {
        searchValue: '',
        searchEntity:
            (getStringItem(
                Items.clearancePermitListSearchEntity,
            ) as SearchEntity) || 'LicensePlate',
        searchFocused: false,
    },
);

export const get = s.get;
export const stateWrite = (
    store: Flux.Store,
    state: Partial<State>,
): string => {
    const name = s.stateWrite(store, state);
    setStringItem(
        Items.clearancePermitListSearchEntity,
        get(store).searchEntity,
    );
    return name;
};
