import { ColorHex } from '../Colors.ts';
import { PermitStatus } from '../../clearance-permit-list/state/PermitsState.ts';

export function getPermitStatusColor(status: PermitStatus, opacity = 1) {
    switch (status) {
        case PermitStatus.active:
            return ColorHex.rgba(ColorHex.bActive, opacity);
        case PermitStatus.credited:
            return ColorHex.rgba(ColorHex.bCredited, opacity);
        case PermitStatus.expired:
            return ColorHex.rgba(ColorHex.bExpired, opacity);
        case PermitStatus.future:
            return ColorHex.rgba(ColorHex.bFuture, opacity);
        case PermitStatus.stornod:
            return ColorHex.rgba(ColorHex.bCanceled, opacity);
    }
}
export function getPermitStatusBackground(
    status: PermitStatus,
    enableHover?: boolean,
) {
    const permitBackgroundColor = getPermitStatusColor(status, 0.1);

    return {
        backgroundColor: permitBackgroundColor,
        '&:hover': {
            backgroundColor: enableHover
                ? getPermitStatusColor(status, 0.2)
                : permitBackgroundColor,
        },
    };
}
