import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';

import { OperatorAccount } from '../../../api/Http.ts';
import { StringSingleSelectionSlideIn } from '../../../ui/slidein/SingleSelectionSlideIn.tsx';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../../../ui/filter/FilterSummary.tsx';
import { Localized } from '../../../common/components/Localized.tsx';

const titleText = (
    <Localized
        de="Subkategorie"
        fr="Sous-catégorie"
        it="Sottocategoria"
        en="Subcategory"
    />
);

const publicText = (
    <Localized de="Öffentliche" fr="Publiques" it="Pubbliche" en="Public" />
);

const clearanceText = (
    <Localized
        de="Nicht-öffentliche"
        fr="Non publiques"
        it="Non pubbliche"
        en="Not-public"
    />
);

export function ChannelFilterSelection() {
    const { storeState, update } = useStore(s => ({
        filter: FilteredPermitsState.Filter.get(s),
    }));

    return (
        <SingleSelection
            label={titleText}
            focused={false}
            selection={
                storeState.filter.channel ===
                OperatorAccount.Permits.Channel.PUBLIC
                    ? publicText
                    : storeState.filter.channel ===
                        OperatorAccount.Permits.Channel.CLEARANCE
                      ? clearanceText
                      : null
            }
            onClick={() => {
                update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        channelSelectionVisible: true,
                    }),
                );
            }}
            onClear={
                storeState.filter.channel
                    ? () => {
                          update(store =>
                              FilteredPermitsState.Filter.stateWrite(store, {
                                  channel: null,
                              }),
                          );

                          update(FilteredPermitsState.List.reset);
                      }
                    : null
            }
        />
    );
}

export function ChannelFilterSummary() {
    const { storeState } = useStore(s => ({
        filter: FilteredPermitsState.Filter.get(s),
    }));

    return storeState.filter.channel ? (
        <FilterSummaryItem hasSeparator={true}>
            <FilterSummaryItemTitle title={titleText} />
            <FilterSummaryItemSubTitle
                title={
                    <>
                        {'\u00A0'}
                        {storeState.filter.channel ===
                        OperatorAccount.Permits.Channel.PUBLIC
                            ? publicText
                            : storeState.filter.channel ===
                                OperatorAccount.Permits.Channel.CLEARANCE
                              ? clearanceText
                              : '-'}
                    </>
                }
            />
        </FilterSummaryItem>
    ) : null;
}

export function ChannelFilterHalfSlideIn() {
    const { storeState, update } = useStore(s => ({
        filter: FilteredPermitsState.Filter.get(s),
    }));

    return (
        <StringSingleSelectionSlideIn
            open={storeState.filter.channelSelectionVisible}
            heading={titleText}
            selection={storeState.filter.channel}
            options={getChannelFilterOptions()}
            onLeftActionClick={() => {
                update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        channelSelectionVisible: false,
                    }),
                );
            }}
            onSelect={v => {
                update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        channel: v as OperatorAccount.Permits.Channel,
                        channelSelectionVisible: false,
                    }),
                );

                update(FilteredPermitsState.List.reset);
            }}
        />
    );
}

function getChannelFilterOptions() {
    return [
        {
            id: OperatorAccount.Permits.Channel.PUBLIC,
            displayText: publicText,
        },
        {
            id: OperatorAccount.Permits.Channel.CLEARANCE,
            displayText: clearanceText,
        },
    ];
}
