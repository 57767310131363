import { css } from '@emotion/css';

import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Colors } from 'dg-web-shared/ui/vars.ts';

export const Mousetrap = (p: { enabled: boolean }) => (
    <Conditional c={p.enabled}>
        <div
            className={css({
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '100%',
                background: Colors.rgba(Colors.white, 0.7),
            })}
        />
    </Conditional>
);
