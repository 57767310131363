import moment from 'moment';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as Text from '../../../common/i18n/Text.ts';
import { dateFilterTexts } from '../../i18n/CollectionsTexts.ts';
import * as CollectionsActions from '../../actions/CollectionsActions.ts';
import { DatePicker } from '../../../ui/date-picker/DatePicker.tsx';
import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from '../../../ui/slidein/Slidein.tsx';
import { DateHeader } from '../../../clearance-permit-list/components/shared/DateHeader.tsx';

const cn = ElementNamer('DateFilter');

export interface DateFilterTexts {
    filterTitle: Text.Translation;
    from: Text.Translation;
    to: Text.Translation;
    unlimited: Text.Translation;
}

interface DateFilterHalfSlideInState {
    settings: SettingsState.State;
    filter: TomCollectionsState.Filter.State;
}

export class DateFilterHalfSlideIn extends Flux.Container<DateFilterHalfSlideInState> {
    static displayName: string = cn('HalfSlideIn');

    stateSelector(): DateFilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: TomCollectionsState.Filter.get(this.props.allState),
        };
    }

    renderValidFrom(): JSX.Element | null {
        const texts = dateFilterTexts[this.state.settings.language];
        if (!this.state.filter.validDateSelectionVisible) {
            return null;
        }
        return (
            <div>
                <DateHeader
                    label={texts.from()}
                    date={this.state.filter.validFrom}
                />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={this.state.filter.validFrom}
                    onSelect={d =>
                        this.props.allState.update(store =>
                            CollectionsActions.setValidFrom(store, d),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    renderToDatePicker(): JSX.Element {
        const texts = dateFilterTexts[this.state.settings.language];
        return (
            <div style={{ marginTop: 32 }}>
                <DateHeader
                    label={texts.to()}
                    date={this.state.filter.validTo}
                />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={this.state.filter.validTo}
                    onSelect={d =>
                        this.props.allState.update(store =>
                            CollectionsActions.setValidTo(store, d),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    render() {
        const texts = dateFilterTexts[this.state.settings.language];
        return (
            <HalfSlideIn open={this.state.filter.validDateSelectionVisible}>
                <SecondLevelHeader
                    onClose={() =>
                        this.props.allState.update(store =>
                            TomCollectionsState.Filter.stateWrite(store, {
                                validDateSelectionVisible: false,
                            }),
                        )
                    }
                >
                    <SlideInHeaderTexts
                        subtitle={''}
                        title={texts.filterTitle()}
                        hasLeftIcon={false}
                    />
                </SecondLevelHeader>
                <SlideInBody>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {this.renderValidFrom()}
                        {this.renderToDatePicker()}
                    </div>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

interface DateFilterSelectionState {
    settings: SettingsState.State;
    filter: TomCollectionsState.Filter.State;
}

export class DateFilterSelection extends Flux.Container<DateFilterSelectionState> {
    static displayName: string = cn('Selection');

    stateSelector(): DateFilterSelectionState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: TomCollectionsState.Filter.get(this.props.allState),
        };
    }

    render() {
        const texts = dateFilterTexts[this.state.settings.language];
        const selection = Formatter.openRange(
            this.state.filter.validFrom,
            this.state.filter.validTo,
            Formatter.dayMonthYear,
            texts.unlimited(),
        );
        const write = (s: Partial<TomCollectionsState.Filter.State>) =>
            this.props.allState.update(store =>
                TomCollectionsState.Filter.stateWrite(store, s),
            );

        const clear = TomCollectionsState.Filter.dateFilterActive(
            this.state.filter,
        )
            ? () => this.update(CollectionsActions.clearValidDate)
            : null;

        return (
            <div>
                <SingleSelection
                    label={texts.filterTitle()}
                    focused={false}
                    selection={selection}
                    onClick={() => write({ validDateSelectionVisible: true })}
                    onClear={clear}
                />
            </div>
        );
    }
}
