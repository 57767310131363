import { Controller, UseFormReturn } from 'react-hook-form';
import { SlideInBody } from '../ui/slidein/Slidein.tsx';
import {
    getRfidCardStates,
    RfidCardState,
} from 'dg-web-shared/lib/RfidCardValidation.ts';
import { TextField } from '../ui/labeled-elements/TextField.tsx';

import { ReactElement } from 'react';
import { Badge } from './components/AddBadgeSlideIn.tsx';
import {
    ServerRequestState,
    useServerErrorEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Localized } from '../common/components/Localized.tsx';

enum AddBadgeErrors {
    HAS_NOT_EXACT_LENGTH = 'HAS_NOT_EXACT_LENGTH',
    INVALID = 'INVALID',
    BADGE_ALREADY_ADDED = 'BADGE_ALREADY_ADDED',
}

function rfidCardStateToAddBadgeError(
    errors?: RfidCardState[],
): AddBadgeErrors | undefined {
    const error = errors?.pop();
    switch (error) {
        case RfidCardState.HAS_NOT_EXACT_LENGTH:
            return AddBadgeErrors.HAS_NOT_EXACT_LENGTH;
        case RfidCardState.INVALID:
            return AddBadgeErrors.INVALID;
    }
}

function isBadgeError(error: unknown): error is AddBadgeErrors {
    return (
        error !== undefined &&
        Object.values(AddBadgeErrors).includes(error as AddBadgeErrors)
    );
}

function TranslateAddBadgeError({
    error,
}: {
    error?: unknown;
}): ReactElement | null {
    if (!isBadgeError(error)) {
        return null;
    }

    switch (error) {
        case AddBadgeErrors.BADGE_ALREADY_ADDED:
            return (
                <Localized
                    de={'Dieser Badge wurde bereits hinzugefügt'}
                    fr={'Ce badge a déjà été ajoutée'}
                    it={'Questo badge è già stato aggiunto'}
                    en={'This badge was already added'}
                />
            );
        case AddBadgeErrors.HAS_NOT_EXACT_LENGTH:
            return (
                <Localized
                    de={'Die Badge-Nummer muss 12 Ziffern lang sein'}
                    fr={'Le numéro de badge doit comporter 12 chiffres'}
                    it={'Il numero di badge deve essere di 12 cifre'}
                    en={'The badge number must be 12 digits'}
                />
            );
        case AddBadgeErrors.INVALID:
            return (
                <Localized
                    de={'Invalide Badge-Nummer'}
                    fr={'Numéro de badge invalide'}
                    it={'Numero di badge non valido'}
                    en={'Invalid badge number'}
                />
            );
    }
}

export enum BadgeFields {
    badgeLabelNr = 'badgeLabelNr',
}

export type BadgePayload = {
    [BadgeFields.badgeLabelNr]: string;
};

export function AddBadgeSlideInBody({
    form,
    preSelectedBadges,
    badgeResponse,
}: {
    form: UseFormReturn<BadgePayload>;
    preSelectedBadges: Badge[];
    badgeResponse: ServerRequestState<Badge, object>;
}) {
    const { control, setError } = form;

    useServerErrorEffect(badgeResponse, statusCode => {
        if (statusCode === 404) {
            setError(BadgeFields.badgeLabelNr, {
                message: AddBadgeErrors.INVALID,
            });
        }
    });

    return (
        <SlideInBody>
            <Controller
                name={BadgeFields.badgeLabelNr}
                control={control}
                rules={{
                    validate: value => {
                        if (value.length === 0) {
                            return true;
                        }

                        if (
                            preSelectedBadges
                                .map(badge => badge.badgeLabelNr)
                                .includes(value)
                        ) {
                            return AddBadgeErrors.BADGE_ALREADY_ADDED;
                        }

                        if (!value.match(/^[0-9]*$/)) {
                            return AddBadgeErrors.INVALID;
                        }
                        return rfidCardStateToAddBadgeError(
                            getRfidCardStates(value),
                        );
                    },
                    required: true,
                }}
                render={({
                    field: { onChange, value, onBlur },
                    fieldState: { error },
                }) => (
                    <TextField
                        inputType="text"
                        onBlur={onBlur}
                        label={
                            <Localized
                                de="Badge Nummer"
                                fr="Numéro de badge"
                                it="Numero di badge"
                                en="Badge Number"
                            />
                        }
                        value={value}
                        saveable={false}
                        onChange={onChange}
                        errorText={
                            error && (
                                <TranslateAddBadgeError error={error.message} />
                            )
                        }
                    />
                )}
            />
        </SlideInBody>
    );
}
