import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import { Add, DeleteForever, Person } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { licensePlateOptions } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/CommonOptions.tsx';
import { ReactHookFormSelectOption } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormSelect.tsx';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField.tsx';
import { getCustomerDisplayName } from 'dg-web-shared/common/models/Users.tsx';
import {
    ParkingaboUserBadgeType,
    ParkingaboVehicle,
    VehicleLicensePlateType,
} from 'dg-web-shared/common/models/Vehicle.tsx';
import { ShouldConfirmBeforeExitFormProps } from 'dg-web-shared/common/utils/FormDefinitions.tsx';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils.tsx';
import {
    RequestMethod,
    RequestStatus,
    ServerRequestState,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { Localized, useLocalized } from '../common/components/Localized.tsx';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import {
    ParkingaboUser,
    ParkingaboUserStatus,
} from './OperatorParkingaboUsersForm.tsx';
import { VehicleIconMap } from 'dg-web-shared/common/components/material-ui/VehicleTypeIcon.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { LoadingButton } from '@mui/lab';
import { BackendRequestErrorMessage } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage.tsx';
import { MaterialBar } from '../layout/components/MaterialBar.tsx';
import { useParkingaboUserFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { LicensePlateCountry } from 'dg-web-shared/dto/LicensePlateCountry.ts';
import { makeLocalizedText } from 'dg-web-shared/lib/Localized.ts';
import { Control, useController } from 'react-hook-form';
import { GenericFormSubmitError } from './ParkingaboUsersUtils.tsx';
import { TenantAllowedBarrierGateVehicleIdentification } from 'product-shared/tenant/TenantEnums.ts';
import { BadgeConfirmationDialog } from './OperatorParkingaboUsersVehicleFormBadgeDetails.tsx';
import { OperatorFormSelect } from '../ui/form/OperatorFormSelect.tsx';
import DriveEta from '@mui/icons-material/DriveEta';
import {
    ParkingaboVehicleSearchResult,
    useUserVehiclesOutlet,
} from './OperatorParkingaboUsersVehicles.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { HeaderCancelSaveButtons } from '../layout/components/HeaderCancelSaveButtons.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import {
    getDisplayLabelForBadge,
    isLicensePlateMandatory,
} from 'product-shared/tenant/TenantVehicleIdentificationUtils.ts';

enum Fields {
    Description = 'description',
    LicensePlateNr = 'licensePlateNr',
    Type = 'type',
    Country = 'country',
    BadgeLabelNr = 'badgeLabelNr',
    BadgeType = 'badgeType',
}

export interface CreateVehiclePayload {
    [Fields.Description]: string;
    [Fields.LicensePlateNr]: string | null;
    [Fields.Type]: VehicleLicensePlateType | null;
    [Fields.Country]: string | null;
    [Fields.BadgeLabelNr]: string | null;
    [Fields.BadgeType]: ParkingaboUserBadgeType | null;
}

export function OperatorParkingaboUsersVehicleAddForm() {
    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    const { parkingaboUser, refetchList, refetchParkingaboUser } =
        useUserVehiclesOutlet();
    const vehiclesListUrl = `${generatePath(
        '/parkingabo/users/:customerNr/vehicles',
        {
            customerNr: parkingaboUser.customerNr,
        },
    )}${makeSearchQueryWithFilters(searchText, activeFilters)}`;
    const [createState, createRequest] = useServerWrite<
        CreateVehiclePayload,
        null,
        ValidationData
    >(() => ({
        url: `/ui-api/operator-account/parkingabo/customer/${parkingaboUser.customerNr}/vehicle`,
    }));

    return (
        <OperatorRoutedModal
            variant={ModalVariant.STANDARD}
            backUrl={vehiclesListUrl}
            render={controller => (
                <OperatorParkingaboUsersVehicleForm
                    onSuccess={() => {
                        controller.setShouldConfirmBeforeLeave(false);
                        refetchList();
                        refetchParkingaboUser();
                        controller.safeNavigateTo(vehiclesListUrl);
                    }}
                    parkingaboUser={parkingaboUser}
                    onClose={controller.close}
                    onDirtyStateChange={controller.setShouldConfirmBeforeLeave}
                    submitState={createState}
                    onSubmit={createRequest}
                    isCreate={true}
                    readOnly={false}
                />
            )}
        />
    );
}

export function OperatorParkingaboUsersVehicleEditForm() {
    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    const {
        parkingaboUser,
        refetchList,
        vehiclesState,
        refetchParkingaboUser,
    } = useUserVehiclesOutlet();
    const vehiclesListUrl = `${generatePath(
        '/parkingabo/users/:customerNr/vehicles',
        {
            customerNr: parkingaboUser.customerNr,
        },
    )}${makeSearchQueryWithFilters(searchText, activeFilters)}`;

    return (
        <OperatorRoutedModal
            variant={ModalVariant.STANDARD}
            backUrl={vehiclesListUrl}
            render={controller => (
                <OperatorParkingaboUsersVehicleEditFormContent
                    onSuccess={() => {
                        controller.setShouldConfirmBeforeLeave(false);
                        refetchList();
                        refetchParkingaboUser();
                        controller.safeNavigateTo(vehiclesListUrl);
                    }}
                    vehiclesState={vehiclesState}
                    parkingaboUser={parkingaboUser}
                    readOnly={
                        parkingaboUser.state !== ParkingaboUserStatus.ACTIVE
                    }
                    onClose={controller.close}
                    onDirtyStateChange={controller.setShouldConfirmBeforeLeave}
                    onDeleteSuccess={() => {
                        controller.setShouldConfirmBeforeLeave(false);
                        refetchList();
                        refetchParkingaboUser();
                        controller.safeNavigateTo(vehiclesListUrl);
                    }}
                />
            )}
        />
    );
}

interface OperatorParkingaboUsersVehicleEditFormProps
    extends ShouldConfirmBeforeExitFormProps {
    onSuccess: () => void;
    vehiclesState: ServerRequestState<ParkingaboVehicleSearchResult, null>;
    parkingaboUser: ParkingaboUser;
    onDeleteSuccess: () => void;
    readOnly: boolean;
}

export function OperatorParkingaboUsersVehicleEditFormContent({
    onSuccess,
    onClose,
    onDirtyStateChange,
    parkingaboUser,
    vehiclesState,
    onDeleteSuccess,
    readOnly,
}: OperatorParkingaboUsersVehicleEditFormProps) {
    const params = useParams<{ vehicleId: string }>();

    const [editState, editRequest] = useServerWrite<
        CreateVehiclePayload,
        null,
        ValidationData
    >(() => ({
        url: `/ui-api/operator-account/parkingabo/customer/${parkingaboUser.customerNr}/vehicle/${params.vehicleId}`,
        method: RequestMethod.PUT,
    }));

    const [deleteState, deleteRequest] = useServerWrite<
        CreateVehiclePayload,
        null,
        ValidationData
    >(() => ({
        url: `/ui-api/operator-account/parkingabo/customer/${parkingaboUser.customerNr}/vehicle/${params.vehicleId}`,
        method: RequestMethod.DELETE,
    }));

    useServerSuccessEffect(deleteState, () => {
        onDeleteSuccess();
    });

    return (
        <OperatorAsyncLoadedSection
            requestState={vehiclesState}
            render={vehicles => {
                const vehicle = vehicles.results.find(
                    v => v.customerTenantCarId === params.vehicleId,
                );

                if (!vehicle) {
                    return (
                        <Alert severity="error">
                            <Localized
                                de="Fahrzeug nicht gefunden (Fehler 404)."
                                fr="Véhicule non trouvé (Erreur 404)."
                                it="Veicolo non trovato (Errore 404)."
                                en="Vehicle not found (Error 404)."
                            />
                        </Alert>
                    );
                }

                return (
                    <OperatorParkingaboUsersVehicleForm
                        onSuccess={onSuccess}
                        parkingaboUser={parkingaboUser}
                        onClose={onClose}
                        onDirtyStateChange={onDirtyStateChange}
                        submitState={editState}
                        vehicle={vehicle}
                        onSubmit={editRequest}
                        onDelete={deleteRequest}
                        deleteState={deleteState}
                        isCreate={false}
                        readOnly={readOnly}
                    />
                );
            }}
        />
    );
}

interface OperatorParkingaboUsersVehicleFormProps
    extends ShouldConfirmBeforeExitFormProps {
    parkingaboUser: ParkingaboUser;
    onSubmit: (formData: CreateVehiclePayload) => void;
    submitState: ServerRequestState<null, ValidationData>;
    vehicle?: ParkingaboVehicle;
    onSuccess: () => void;
    onDelete?: () => void;
    deleteState?: ServerRequestState<null, ValidationData>;
    isCreate: boolean;
    readOnly: boolean;
}

function getInitialValuesFromVehicle(vehicle?: ParkingaboVehicle) {
    if (vehicle) {
        return {
            [Fields.Description]: vehicle.description,
            [Fields.LicensePlateNr]: vehicle.licensePlateNr,
            [Fields.Type]: vehicle.type,
            [Fields.Country]: vehicle.country,
            [Fields.BadgeLabelNr]: vehicle.badgeLabelNr,
            [Fields.BadgeType]: vehicle.badgeType,
        };
    }
    return {
        [Fields.Description]: '',
        [Fields.LicensePlateNr]: null,
        [Fields.Type]: VehicleLicensePlateType.CAR,
        [Fields.Country]: 'CH',
        [Fields.BadgeLabelNr]: null,
        [Fields.BadgeType]: null,
    };
}

export function OperatorParkingaboUsersVehicleForm({
    parkingaboUser,
    submitState,
    vehicle,
    onDirtyStateChange,
    onSubmit,
    onClose,
    onSuccess,
    onDelete,
    deleteState,
    isCreate,
    readOnly,
}: OperatorParkingaboUsersVehicleFormProps) {
    const language = useOperatorLanguage();
    const {
        formInfo: { control, handleSubmit, reset, watch, setValue, trigger },
        formState: { isDirty },
        genericSubmitError,
    } = useEasyForm(onDirtyStateChange, submitState, language, {
        defaultValues: getInitialValuesFromVehicle(vehicle),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (vehicle) {
            reset(getInitialValuesFromVehicle(vehicle));
        }
    }, [vehicle]);

    useServerSuccessEffect(submitState, () => {
        onSuccess();
    });

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddBadgeDialog, setShowAddBadgeDialog] = useState(false);
    const type = watch(Fields.Type);
    const country = watch(Fields.Country);
    const licensePlateNr = watch(Fields.LicensePlateNr);
    const badgeType = watch(Fields.BadgeType);
    const translate = makeLocalizedText(language);
    const mandatoryFieldMessage = useLocalized(mandatoryFieldErrorMessage);
    const [countriesState] = useServerFetch<LicensePlateCountry[], object>(
        () => ({
            url: `/ui-api/meta/license-plate-countries`,
        }),
        {},
    );
    const isLpRequired = isLicensePlateMandatory(
        parkingaboUser.tenant.allowedEnforcedVehicleIdentification,
        parkingaboUser.tenant.allowedBarrierGateVehicleIdentification,
    );
    const showLpFields =
        isLpRequired || (!isLpRequired && Boolean(licensePlateNr));
    return (
        <OperatorAsyncLoadedSection
            requestState={countriesState}
            render={countryList => {
                const countryOptions = countryList
                    .sort((a, b) => (a.id <= b.id ? -1 : 1))
                    .map<ReactHookFormSelectOption<string>>(c => ({
                        value: c.id,
                        display: `${c.id} - ${translate(c.name)}`,
                    }));

                return (
                    <OperatorRoutedModalContent
                        header={
                            <HeaderComponent
                                Icon={type ? VehicleIconMap[type] : DriveEta}
                                title={
                                    !vehicle ? (
                                        <Localized
                                            de="Neues Fahrzeug"
                                            fr="Nouveau véhicule"
                                            it="Nuovo veicolo"
                                            en="New vehicle"
                                        />
                                    ) : (
                                        vehicle.description
                                    )
                                }
                                editMode={isDirty || isCreate}
                                buttons={
                                    <>
                                        {isDirty || isCreate ? (
                                            <HeaderCancelSaveButtons
                                                onCancel={
                                                    vehicle
                                                        ? () => reset()
                                                        : () => onClose()
                                                }
                                                onSave={handleSubmit(onSubmit)}
                                                loading={
                                                    submitState?.status ===
                                                    RequestStatus.PENDING
                                                }
                                                saveDisabled={!isDirty}
                                                noDiscardDialog={!vehicle}
                                            />
                                        ) : (
                                            <>
                                                {onDelete && (
                                                    <LoadingButton
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={() =>
                                                            setShowDeleteDialog(
                                                                true,
                                                            )
                                                        }
                                                        disabled={
                                                            parkingaboUser.state !==
                                                            ParkingaboUserStatus.ACTIVE
                                                        }
                                                        loading={
                                                            deleteState?.status ===
                                                            RequestStatus.PENDING
                                                        }
                                                    >
                                                        <Localized
                                                            de="Löschen"
                                                            fr="Effacer"
                                                            it="Elimina"
                                                            en="Delete"
                                                        />
                                                    </LoadingButton>
                                                )}
                                                <HeaderActionCloseButtons
                                                    onClose={onClose}
                                                    isLoading={
                                                        deleteState?.status ===
                                                        RequestStatus.PENDING
                                                    }
                                                />
                                            </>
                                        )}
                                        {deleteState && (
                                            <BackendRequestErrorMessage
                                                requestState={deleteState}
                                            />
                                        )}
                                        <DeleteVehicleDialog
                                            open={showDeleteDialog}
                                            onDelete={() => {
                                                setShowDeleteDialog(false);
                                                onDelete && onDelete();
                                            }}
                                            onCancel={() =>
                                                setShowDeleteDialog(false)
                                            }
                                            vehicleDescription={watch(
                                                Fields.Description,
                                            )}
                                        />
                                    </>
                                }
                            />
                        }
                        bar={
                            <MaterialBar
                                title={getCustomerDisplayName(parkingaboUser)}
                                Icon={Person}
                            />
                        }
                        body={
                            <Stack spacing={3}>
                                <GenericFormSubmitError
                                    error={genericSubmitError}
                                    submitState={submitState}
                                />
                                <ReactHookFormTextField
                                    label={
                                        <Localized
                                            de="Beschreibung"
                                            fr="Description"
                                            it="Descrizione"
                                            en="Description"
                                        />
                                    }
                                    name={Fields.Description}
                                    control={control}
                                    readonly={readOnly}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: mandatoryFieldMessage,
                                        },
                                    }}
                                />
                                <Grid container>
                                    <Grid item xs={4}>
                                        <ReactHookFormTextField
                                            label={
                                                <Localized
                                                    de="Kennzeichen"
                                                    fr="Immatriculation"
                                                    it="Targa"
                                                    en="License plate"
                                                />
                                            }
                                            name={Fields.LicensePlateNr}
                                            control={control}
                                            readonly={readOnly}
                                            onChange={event => {
                                                if (
                                                    !isLpRequired ||
                                                    event.target.value
                                                ) {
                                                    if (!type) {
                                                        setValue(
                                                            Fields.Type,
                                                            VehicleLicensePlateType.CAR,
                                                        );
                                                    }
                                                    if (!country) {
                                                        setValue(
                                                            Fields.Country,
                                                            'CH',
                                                        );
                                                    }
                                                }
                                            }}
                                            rules={{
                                                required: {
                                                    value: isLpRequired,
                                                    message:
                                                        mandatoryFieldMessage,
                                                },
                                            }}
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                    {showLpFields && (
                                        <>
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{ paddingLeft: '15px' }}
                                            >
                                                <OperatorFormSelect
                                                    label={
                                                        <Localized
                                                            de="Fahrzeugtyp"
                                                            fr="Type de véhicule"
                                                            it="Tipo veicolo"
                                                            en="Vehicle type"
                                                        />
                                                    }
                                                    name={Fields.Type}
                                                    control={control}
                                                    options={licensePlateOptions(
                                                        language,
                                                    )}
                                                    readonly={readOnly}
                                                    rules={{
                                                        required: {
                                                            value: isLpRequired,
                                                            message:
                                                                mandatoryFieldMessage,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{ paddingLeft: '15px' }}
                                            >
                                                <OperatorFormSelect
                                                    options={countryOptions}
                                                    label={
                                                        <Localized
                                                            de="Land"
                                                            fr="Pays"
                                                            it="Paese"
                                                            en="Country"
                                                        />
                                                    }
                                                    name={Fields.Country}
                                                    control={control}
                                                    readonly={readOnly}
                                                    rules={{
                                                        required: {
                                                            value: isLpRequired,
                                                            message:
                                                                mandatoryFieldMessage,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <FurtherIdentificationField
                                    control={control}
                                    isCreate={isCreate}
                                    allowedBarrierGateVehicleIdentification={
                                        parkingaboUser.tenant
                                            .allowedBarrierGateVehicleIdentification
                                    }
                                    onBadgeAdd={() =>
                                        setShowAddBadgeDialog(true)
                                    }
                                    onBadgeRemove={() => {
                                        setValue(Fields.BadgeLabelNr, null, {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                            shouldTouch: true,
                                        });
                                        setValue(Fields.BadgeType, null, {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                            shouldTouch: true,
                                        });
                                    }}
                                    badgeType={badgeType}
                                    vehicle={vehicle}
                                    mandatoryFieldMessage={
                                        mandatoryFieldMessage
                                    }
                                />
                                {showAddBadgeDialog && (
                                    <BadgeConfirmationDialog
                                        onAbort={() =>
                                            setShowAddBadgeDialog(false)
                                        }
                                        onSave={(labelNr, type) => {
                                            setValue(
                                                Fields.BadgeLabelNr,
                                                labelNr,
                                                {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                    shouldTouch: true,
                                                },
                                            );
                                            setValue(Fields.BadgeType, type, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                                shouldTouch: true,
                                            });
                                            trigger();
                                            setShowAddBadgeDialog(false);
                                        }}
                                        parkingaboUser={parkingaboUser}
                                        language={language}
                                        badgeRequired={
                                            parkingaboUser.tenant
                                                .allowedBarrierGateVehicleIdentification ===
                                            TenantAllowedBarrierGateVehicleIdentification.BADGE
                                        }
                                        mandatoryFieldMessage={
                                            mandatoryFieldMessage
                                        }
                                    />
                                )}
                            </Stack>
                        }
                    />
                );
            }}
        />
    );
}

function FurtherIdentificationField({
    control,
    vehicle,
    isCreate,
    onBadgeAdd,
    onBadgeRemove,
    badgeType,
    allowedBarrierGateVehicleIdentification,
    mandatoryFieldMessage,
}: {
    control: Control<CreateVehiclePayload, object>;
    vehicle?: ParkingaboVehicle;
    isCreate: boolean;
    onBadgeAdd: () => void;
    onBadgeRemove: () => void;
    badgeType: ParkingaboUserBadgeType | null;
    allowedBarrierGateVehicleIdentification: TenantAllowedBarrierGateVehicleIdentification | null;
    mandatoryFieldMessage: string;
}) {
    switch (allowedBarrierGateVehicleIdentification) {
        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR:
            if (!isCreate) {
                return (
                    <TextField
                        label={
                            <Localized
                                de="QR-Code ID"
                                fr="ID code QR"
                                it="ID codice QR"
                                en="QR code ID"
                            />
                        }
                        value={vehicle?.identificationQrCodeId ?? ''}
                        disabled={true}
                        InputProps={{
                            readOnly: true,
                            sx: {
                                borderBottom: theme =>
                                    `1px solid ${theme.palette.grey.A400}`,
                            },
                        }}
                    />
                );
            }
            return null;
        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_BADGE:
        case TenantAllowedBarrierGateVehicleIdentification.BADGE:
            return (
                <BadgeField
                    control={control}
                    badgeType={badgeType}
                    onBadgeAdd={onBadgeAdd}
                    onBadgeRemove={onBadgeRemove}
                    allowedBarrierGateVehicleIdentification={
                        allowedBarrierGateVehicleIdentification
                    }
                    mandatoryFieldMessage={mandatoryFieldMessage}
                />
            );
        case null:
            return null;
    }
}

function BadgeField({
    control,
    badgeType,
    onBadgeAdd,
    onBadgeRemove,
    allowedBarrierGateVehicleIdentification,
    mandatoryFieldMessage,
}: {
    control: Control<CreateVehiclePayload, object>;
    badgeType: ParkingaboUserBadgeType | null;
    onBadgeAdd: () => void;
    onBadgeRemove: () => void;
    allowedBarrierGateVehicleIdentification: TenantAllowedBarrierGateVehicleIdentification | null;
    mandatoryFieldMessage: string;
}) {
    const language = useOperatorLanguage();
    const {
        field: { value },
        fieldState: { error },
    } = useController({
        control: control,
        name: Fields.BadgeLabelNr,
        rules: {
            required:
                allowedBarrierGateVehicleIdentification ===
                TenantAllowedBarrierGateVehicleIdentification.BADGE
                    ? mandatoryFieldMessage
                    : false,
        },
    });
    return (
        <Grid container sx={{ marginRight: '24px' }}>
            <Grid item xs>
                <TextField
                    label={
                        <Localized
                            de="Badge"
                            fr="Badge"
                            it="Badge"
                            en="Badge"
                        />
                    }
                    value={getDisplayLabelForBadge(language, value, badgeType)}
                    error={Boolean(error)}
                    helperText={error?.message}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid
                item
                sx={{
                    marginTop: 1,
                    alignItems: 'start',
                    display: 'flex',
                    gap: '16px',
                }}
            >
                <Button
                    startIcon={<Add />}
                    variant="outlined"
                    onClick={onBadgeAdd}
                    disabled={!!value}
                >
                    <Localized
                        de="Hinzufügen"
                        fr="Ajouter"
                        it="Aggiungi"
                        en="Add"
                    />
                </Button>
                <Button
                    startIcon={<DeleteForever />}
                    variant="outlined"
                    onClick={onBadgeRemove}
                    disabled={!value}
                >
                    <Localized
                        de="Entfernen"
                        fr="Retirer"
                        it="Rimuovi"
                        en="Remove"
                    />
                </Button>
            </Grid>
        </Grid>
    );
}

export const mandatoryFieldErrorMessage = {
    de: 'Pflichtfeld',
    fr: 'Champ obligatoire',
    it: 'Campo obbligatorio',
    en: 'Mandatory field',
};

function DeleteVehicleDialog({
    open,
    onDelete,
    onCancel,
    vehicleDescription,
}: {
    open: boolean;
    onDelete: () => void;
    onCancel: () => void;
    vehicleDescription: string;
}) {
    return (
        <Dialog open={open} maxWidth="xs">
            <DialogTitle>
                <Localized
                    de="Fahrzeug löschen"
                    fr="Supprimer véhicule"
                    it="Eliminare veicolo"
                    en="Delete vehicle"
                />
            </DialogTitle>
            <DialogContent>
                <Localized
                    de={`Wollen Sie wirklich das Fahrzeug «${vehicleDescription}» löschen?`}
                    fr={`Voulez-vous vraiment effacer le véhicule «${vehicleDescription}»?`}
                    it={`Vuole veramente eliminare il veicolo «${vehicleDescription}»?`}
                    en={`Do you really want to delete the vehicle «${vehicleDescription}»?`}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                </Button>
                <Button onClick={onDelete}>
                    <Localized
                        de="Löschen"
                        fr="Effacer"
                        it="Elimina"
                        en="Delete"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
