import { Message } from 'dg-web-shared/lib/Localized.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';

export type OnstreetTransactionResponse = {
    data: OnstreetTransaction[];
    tooManyRows: boolean;
};

export type OnstreetTransactionRequestSearchParams = {
    validFrom: string | null;
    validTo: string | null;
    zones: string | null;
    search: string | null;
    status: string | null;
    lang: string | null;
};

export type OnstreetTransaction = {
    id: number;
    tbegin: string;
    tend: Maybe<string>;
    price: number;
    zoneId: number;
    zoneName: string;
    zoneCode: number;
    location: string;
    licensePlateNr: string;
    licensePlateType?: LicensePlateType;
    licensePlateCountry?: string;
    isRunning: boolean;
    refund: TransactionRefund | null;
    maxRefundableAmount: number | null;
    channel: OnstreetTransactionChannel;
    customerAccountTerminated: boolean;
};

export const enum OnstreetTransactionChannel {
    Parkingpay = 'Parkingpay',
    TWINT = 'TWINT',
    EasyPark = 'EasyPark',
    Pnrail = 'Pnrail',
}

export type TransactionRefund = {
    amount: number;
    refundedAt: string;
    remark: string;
    user: string;
};

export type TransactionProduct = {
    contractId: number;
    name: Message;
};
