import {
    HalfSlideIn,
    SlideInBody,
    SlideInHeaderButtonsContainer,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { isDefined, isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import {
    parkTransactionsListTexts,
    transactionDetailTexts,
} from '../i18n/ParkTransactionsTexts.ts';
import * as TomState from '../../tom/state/TomState.ts';
import * as ParkTransactionsState from '../state/ParkTransactionsState.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import * as Text from '../../common/i18n/Text.ts';
import * as TariffState from '../../tariffs/state/TariffState.ts';
import { TariffPreview } from '../../tariffs/components/TariffPreview.tsx';
import { closeTransactionDetail } from '../actions/ParkTransactionsActions.ts';
import { css } from '@emotion/css';
import { OperatorTypo } from '../../ui/OperatorTypo.ts';
import { PrimaryLinkButton } from '../../ui/buttons/TextButton.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { TomTransaction } from 'dg-web-shared/model/TomTransaction.ts';
import { operatorAppApiUrl } from '../../api/Http.ts';

export interface TransactionDetailTexts {
    SlideInHeaderCaption: Text.Translation;
    InheritedTime: Text.Translation;
    Net: Text.Translation;
    NoInheritedTime: Text.Translation;
    MaximumTimeReached: Text.Translation;
    Tariff: Text.Translation;
    UnknownTariff: Text.Translation;
    PayedWith: Text.Translation;
}

export function TransactionDetailSlideIn(): JSX.Element {
    const { storeState, update } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        detail: ParkTransactionsState.Detail.get(s),
        toms: TomState.List.get(s),
        tariffs: TariffState.List.get(s),
    }));

    const lng = storeState.settings.language;
    const txt = transactionDetailTexts[lng];
    const listTexts = parkTransactionsListTexts[lng];
    const transaction = storeState.detail.selectedTransaction;
    const durationTexts = Date.Formatter.durationTexts[lng];

    if (
        isUndefined(transaction) ||
        isUndefined(storeState.detail.selectedTransaction)
    ) {
        return <HalfSlideIn open={false} />;
    } else {
        const tariff = TariffState.List.getTariffById(
            storeState.tariffs.data,
            transaction.tariffId,
        );
        return (
            <HalfSlideIn
                open={isDefined(storeState.detail.selectedTransaction)}
            >
                <StandardFirstLevelHeader
                    onClose={() => update(closeTransactionDetail)}
                >
                    <SlideInHeaderTexts
                        title={txt.SlideInHeaderCaption()}
                        hasLeftIcon={false}
                    />

                    <SlideInHeaderButtonsContainer>
                        <PrimaryLinkButton
                            href={operatorAppApiUrl(
                                `/tom-transactions/${storeState.detail.selectedTransaction.transactionId}/print-pdf`,
                            )}
                            target={'_blank'}
                            label={
                                <Localized
                                    de="Quittung"
                                    fr="Reçu"
                                    it="Ricevuta"
                                    en="Receipt"
                                />
                            }
                        />
                    </SlideInHeaderButtonsContainer>
                </StandardFirstLevelHeader>
                <SlideInBody>
                    {isDefined(tariff) ? (
                        <TariffPreview tariff={tariff} language={lng} />
                    ) : (
                        txt.UnknownTariff()
                    )}
                    <LabeledText label={listTexts.headerParkingMeterName()}>
                        {TomState.getTomName(
                            storeState.toms.data,
                            transaction.tomId,
                        )}
                    </LabeledText>
                    <LabeledText label={listTexts.headerParkingSpace()}>
                        {transaction.label}
                    </LabeledText>
                    <LabeledText label={listTexts.headerPrice()}>
                        {NumberFormatter.numberToPrice(
                            transaction.paidAmount / 100,
                            true,
                        )}
                        <PriceDetail transaction={transaction} language={lng} />
                    </LabeledText>
                    <LabeledText label={listTexts.headerTransactionNumber()}>
                        {transaction.transactionNumber}
                    </LabeledText>
                    <LabeledText label={listTexts.headerDateFrom()}>
                        {Date.Formatter.dayMonthYearHourMinute(
                            transaction.startTime,
                        )}
                    </LabeledText>
                    <LabeledText label={listTexts.headerDateTo()}>
                        {Date.Formatter.dayMonthYearHourMinute(
                            transaction.expirationTime,
                        )}
                    </LabeledText>
                    <LabeledText label={listTexts.headerDuration()}>
                        {Date.Formatter.getDuration(
                            transaction.startTime,
                            transaction.expirationTime,
                            durationTexts,
                        )}
                        {transaction.maximumParkingTimeReached && (
                            <div
                                className={css({
                                    ...OperatorTypo.caption,
                                    paddingLeft: '10px',
                                    display: 'inline',
                                })}
                            >
                                {txt.MaximumTimeReached()}
                            </div>
                        )}
                    </LabeledText>
                    <LabeledText label={txt.InheritedTime()}>
                        <InheritedTimeDetail
                            transaction={transaction}
                            language={lng}
                        />
                    </LabeledText>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

interface TransactionDetailProps {
    transaction: TomTransaction;
    language: string;
}

function InheritedTimeDetail(props: TransactionDetailProps): JSX.Element {
    const txt = transactionDetailTexts[props.language];
    const durationTexts = Date.Formatter.durationTexts[props.language];
    const hasInheritedTime =
        isDefined(props.transaction.awardedInheritedTime) &&
        props.transaction.awardedInheritedTime > 0;
    if (!hasInheritedTime) {
        return <span>{txt.NoInheritedTime()}</span>;
    }

    const inheritedTime = Date.Formatter.getDurationFromMinutes(
        props.transaction.inheritedTime,
        durationTexts,
    );
    const netInheritedTime = Date.Formatter.getDurationFromMinutes(
        props.transaction.awardedInheritedTime,
        durationTexts,
    );
    return (
        <div>
            {inheritedTime}
            <div
                className={css({
                    ...OperatorTypo.caption,
                    paddingLeft: '10px',
                    display: 'inline',
                })}
            >
                {txt.Net()} {netInheritedTime}
            </div>
        </div>
    );
}

function PriceDetail(props: TransactionDetailProps): JSX.Element {
    const txt = transactionDetailTexts[props.language];
    return (
        <div className={css({ ...OperatorTypo.body })}>
            {txt.PayedWith()}:
            {props.transaction.paidAmountChf > 0 && (
                <div>
                    CHF{' '}
                    {NumberFormatter.numberToPrice(
                        props.transaction.paidAmountChf / 100,
                        false,
                    )}
                </div>
            )}
            {props.transaction.paidAmountEuro > 0 && (
                <div>
                    €{' '}
                    {NumberFormatter.numberToPrice(
                        props.transaction.paidAmountEuro / 100,
                        false,
                    )}
                </div>
            )}
            {props.transaction.paidAmountToken > 0 && (
                <div>
                    TKN{' '}
                    {NumberFormatter.numberToPrice(
                        props.transaction.paidAmountToken / 100,
                        false,
                    )}
                </div>
            )}
        </div>
    );
}
