import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import * as Text from '../../common/i18n/Text.ts';
import { Translations } from '../../common/i18n/Text.ts';
import { StringSingleSelectionSlideIn } from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import { isDefined, thenOrNull } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    OnstreetTransactionFilter,
    OnstreetTransactionStatus,
} from '../OnstreetTransactionFilter.tsx';

export function TransactionStatusFilterHalfSlideIn({
    language,
    filter,
}: {
    language: string;
    filter: OnstreetTransactionFilter;
}) {
    const texts = transactionStatusFilterTexts[language];
    return (
        <StringSingleSelectionSlideIn
            open={filter.state.statusFilterVisible}
            heading={texts.filterTitle()}
            selection={filter.state.status}
            options={[
                { id: 'running', displayText: texts.runningOnly() },
                {
                    id: 'terminated',
                    displayText: texts.terminatedOnly(),
                },
                { id: 'refunded', displayText: texts.refundedOnly() },
            ]}
            onLeftActionClick={() => filter.showStatusFilterSlideIn(false)}
            onSelect={state =>
                filter.setTransactionStatus(state as OnstreetTransactionStatus)
            }
        />
    );
}

export function TransactionStatusFilterSelection({
    language,
    filter,
}: {
    language: string;
    filter: OnstreetTransactionFilter;
}) {
    const texts = transactionStatusFilterTexts[language];
    return (
        <SingleSelection
            label={transactionStatusFilterTexts[language].filterTitle()}
            focused={false}
            selection={thenOrNull(filter.state.status, s =>
                s === 'running'
                    ? texts.runningOnly()
                    : s === 'refunded'
                      ? texts.refundedOnly()
                      : texts.terminatedOnly(),
            )}
            onClick={() => filter.showStatusFilterSlideIn(true)}
            onClear={
                isDefined(filter.state.status)
                    ? filter.clearTransactionStatus
                    : null
            }
        />
    );
}

interface TransactionStatusFilterTexts {
    filterTitle: Text.Translation;
    runningOnly: Text.Translation;
    terminatedOnly: Text.Translation;
    refundedOnly: Text.Translation;
}

const transactionStatusFilterTexts: Translations<TransactionStatusFilterTexts> =
    {
        de: {
            filterTitle: () => 'Status',
            runningOnly: () => 'Nur laufende',
            terminatedOnly: () => 'Nur abgeschlossene',
            refundedOnly: () => 'Nur zurückbezahlte',
        },
        fr: {
            filterTitle: () => 'État',
            runningOnly: () => 'Seulement en cours',
            terminatedOnly: () => 'Seulement terminée',
            refundedOnly: () => 'Seulement remboursées',
        },
        it: {
            filterTitle: () => 'Stato',
            runningOnly: () => 'Solo in corso',
            terminatedOnly: () => 'Solo terminate',
            refundedOnly: () => 'Solo rimborsate',
        },
    };
