import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { TariffsBody } from './TariffsBody.tsx';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { getNavigationText } from '../../layout/components/BreadCrumb.tsx';

export const Tariffs: React.FC = () => {
    const { storeState } = Flux.useStore(state => ({
        allState: state,
        currentLogin: CurrentOperatorLoginState.get(state),
    }));
    const login = storeState.currentLogin.data;

    if (!login) {
        return null;
    }
    return (
        <Container>
            <Tabs>
                <TabItem
                    active={true}
                    description={getNavigationText(OperatorAppRoutes.Tariffs)}
                    onClick={null}
                />
            </Tabs>
            <TariffsBody allState={storeState.allState} />
        </Container>
    );
};
