import moment from 'moment';
import { LicensePlate } from 'dg-web-shared/dto/LicensePlate.ts';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { PaymentChannel } from '../../common/models/PaymentChannel.ts';

export type { LicensePlate } from 'dg-web-shared/dto/LicensePlate.ts';

export interface Badge {
    id: number;
    labelNr: string;
}

export interface Permit {
    id: number;
    validFrom: moment.Moment;
    validTo: moment.Moment;
    permitTypeName: string;
    paydate: moment.Moment | null;
    generated: moment.Moment | null;
    licensePlates: LicensePlate[];
    badges: Badge[];
    operatorLoginId: number;
    paymentChannel: PaymentChannel;
}

export namespace List {
    const parsePermit = (p: Permit): Permit => {
        return {
            id: p.id,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            validFrom: Parser.isoToMoment(<any>p.validFrom),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            validTo: Parser.isoToMoment(<any>p.validTo),
            permitTypeName: p.permitTypeName,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            paydate: Parser.isoToMaybeMoment(<any>p.paydate),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            generated: Parser.isoToMaybeMoment(<any>p.generated),
            licensePlates: p.licensePlates,
            badges: p.badges,
            operatorLoginId: p.operatorLoginId,
            paymentChannel: p.paymentChannel,
        };
    };

    export type Data = { size: number | null; data: Permit[] };

    export type State = ServerStateSlice.ServerState<Data>;

    const shouldFetch = (store: Flux.Store) =>
        Filter.filterActive(Filter.get(store));

    export const { get, reset, setResponse, forceRefetch } =
        ServerStateSlice.generateServerState<Data>(
            'clearance-permit-list-FilteredPermitsState.List',
            () => {
                return { size: null, data: [] };
            },
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch && shouldFetch(store)) {
                    store.update(fetchPermits(store));
                }
            },
            (body: any): Data => {
                return { size: body.size, data: body.data.map(parsePermit) };
            },
        );

    const fetchPermits = (store: Flux.Store) =>
        AsyncRequest.request(
            () =>
                Http.OperatorAccount.Permits.filteredList({
                    validDateFrom: Filter.get(store).validDateFrom,
                    validDateTo: Filter.get(store).validDateTo,
                    permitType: Filter.get(store).permitTypes,
                    issuedBy: thenElse(
                        Filter.get(store).operatorLogins,
                        l =>
                            l.map(id =>
                                id === Filter.OperatorLoginFilterOnlineOption
                                    ? null
                                    : id,
                            ),
                        null,
                    ),
                    issueDateFrom: Filter.get(store).issueDateFrom,
                    issueDateTo: Filter.get(store).issueDateTo,
                    timeLimit: Filter.get(store).timeLimit,
                    channel: Filter.get(store).channel,
                    cost: Filter.get(store).cost,
                    status: Filter.get(store).status,
                    sortField: 'validTo',
                    lang: null,
                }),
            setResponse,
        );
}

export namespace Filter {
    export const OperatorLoginFilterOnlineOption = -99;

    export interface State {
        filterSlideInVisible: boolean;

        validDateFrom: moment.Moment | null;
        validDateTo: moment.Moment | null;
        validDateSelectionVisible: boolean;

        permitTypes: number[] | null;
        permitTypeSelectionVisible: boolean;

        operatorLogins: number[] | null;
        operatorLoginSelectionVisible: boolean;

        issueDateFrom: moment.Moment | null;
        issueDateTo: moment.Moment | null;
        issueDateSelectionVisible: boolean;

        timeLimit: Http.OperatorAccount.Permits.TimeLimit | null;
        timeLimitSelectionVisible: boolean;

        channel: Http.OperatorAccount.Permits.Channel | null;
        channelSelectionVisible: boolean;

        cost: Http.OperatorAccount.Permits.Cost | null;
        costSelectionVisible: boolean;

        status: Http.OperatorAccount.Permits.Status[] | null;
        statusSelectionVisible: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'clearance-permit-list-FilteredPermitsState.Filter',
        {
            filterSlideInVisible: false,

            validDateFrom: null,
            validDateTo: null,
            validDateSelectionVisible: false,

            permitTypes: null,
            permitTypeSelectionVisible: false,

            operatorLogins: null,
            operatorLoginSelectionVisible: false,

            issueDateFrom: null,
            issueDateTo: null,
            issueDateSelectionVisible: false,

            timeLimit: null,
            timeLimitSelectionVisible: false,

            channel: null,
            channelSelectionVisible: false,

            cost: null,
            costSelectionVisible: false,

            status: null,
            statusSelectionVisible: false,
        },
    );

    export const validDateFilterActive = (s: State): boolean =>
        !!s.validDateFrom || !!s.validDateTo;

    export const permitTypeFilterActive = (s: State): boolean =>
        thenElse(s.permitTypes, pt => pt.length > 0, false);

    export const operatorLoginFilterActive = (s: State): boolean =>
        thenElse(s.operatorLogins, ol => ol.length > 0, false);

    export const issueDateFilterActive = (s: State): boolean =>
        !!s.issueDateFrom || !!s.issueDateTo;

    export const filterActive = (s: State): boolean =>
        validDateFilterActive(s) ||
        permitTypeFilterActive(s) ||
        operatorLoginFilterActive(s) ||
        issueDateFilterActive(s) ||
        !!s.timeLimit ||
        !!s.channel ||
        !!s.cost ||
        !!s.status;
}

export namespace FilterSummary {
    export interface State {
        hover: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'clearance-permit-list-FilteredPermitsState.FilterSummary',
        {
            hover: false,
        },
    );
}
