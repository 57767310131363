import moment from 'moment';

import { PermitSelectZone } from '../../app/state/PermitSelectZone.ts';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    getOrElse,
    isDefined,
    isUndefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { QuotaPartitionInfo } from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { IconButton24pxType } from '../../ui/buttons/IconButton.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import {
    MiniTable,
    MiniTableActionOnlyElement,
    MiniTableElement,
} from '../../ui/labeled-elements/MiniTable.tsx';
import { Body } from '../../ui/layout/TabContent.tsx';
import { DatePickerSlideIn } from '../../ui/slidein/DatePickerSlideIn.tsx';
import {
    ConfirmationHeader,
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import {
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import { permitTypeTexts } from '../i18n/PermitTypeTexts.ts';
import * as PermitTypesState from '../state/PermitTypesState.ts';
import { Localized } from '../../common/components/Localized.tsx';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { IntradayValidityWeekProfileDisplay } from 'dg-web-shared/common/components/intraday-validity-week-profile-display/IntradayValidityWeekProfileDisplay.tsx';
import {
    LabeledSignalisationDownload,
    SignalisationType,
} from '../../common/components/LabeledSignalisationDownload.tsx';
import { quotaPartitionConfigText } from 'dg-web-shared/model/QuotaConfig.ts';
import {
    AutoActive,
    PermittypeChannels,
} from 'product-shared/product-template/ProductOptions.tsx';
import {
    Notification,
    NotificationColor,
    NotificationIcon,
} from '../../ui/notifications/Notification.tsx';
import { Icon16 } from '../../ui/icons/Icon.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { useNavigate } from 'react-router-dom';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';

export interface PermitTexts {
    Type: Translation;
    ProfileNr: Translation;
    Zones: Translation;
    Price: Translation;
    SellChannel: Translation;
    Clearance: Translation;
    MaxId: Translation;
    ProfileNumberBody: Translation;
    IdentificationBody: Translation;
    MaxIdBody: Translation;
    LicensePlateTypeBody: Translation;
    VatBody: Translation;
    ApplyForClearanceBody: Translation;
    CounterChannel: Translation;
    AppChannel: Translation;
    HeaderCaption: Translation;
    Yes: Translation;
    LicensePlate: Translation;
    Badge: Translation;
    WithinValidityConstraintDates: Translation;
    Fixed: Translation;
    Selectable: Translation;
    Account: Translation;
}

const zonesString = (
    zoneCount: number,
    type: PermitSelectZone,
    lang: string,
): string => {
    const txt = permitTypeTexts[lang];
    switch (type) {
        case 'NO_CHOICE':
            return `${zoneCount} ${txt.Fixed()}`;
        case 'SELECT_ONE':
            return `1 ${txt.Selectable()}`;
        default:
            return `x ${txt.Selectable()}`;
    }
};

interface ItemProps {
    update: Flux.Updater;
    permitType: PermitTypeState.PermitType;
    language: string;
    account: OperatorDataState.Account | null;
}

interface ValidityDatePickerSlideInProps {
    language: string;
    update: Flux.Updater;
    permitType: Maybe<PermitTypeState.PermitType>;
    editDates: Maybe<string[]>;
    open: boolean;
}

export const editWithinValidyDates = AsyncRequest.request(
    Http.OperatorAccount.Permits.editWithinValidityDates,
    (store: Flux.Store, res: Response): string => {
        PermitTypesState.ValidityDatesResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            PermitTypesState.WithinValidityDatesEdit.reset(store);
            PermitTypeState.reset(store);
        }
        return 'PermitTypes-editWithinValidityDates';
    },
);

export const ValidityDatePickerSlideIn = (
    p: ValidityDatePickerSlideInProps,
) => {
    const texts = permitTypeTexts[p.language];
    if (isUndefined(p.permitType)) {
        return null;
    }
    const dates = getOrElse(
        p.editDates,
        p.permitType.withinValidityConstraintDates,
    );
    if (isUndefined(dates)) {
        return null;
    }
    return (
        <DatePickerSlideIn
            caption={texts.WithinValidityConstraintDates()}
            language={p.language}
            onClose={() =>
                p.update(store =>
                    PermitTypesState.Layout.stateWrite(store, {
                        wthinValidityDatePickerOpen: false,
                    }),
                )
            }
            onSelect={(date: moment.Moment) => {
                const dateStr = Formatter.isoYearMonthDay(date);
                if (dates.indexOf(dateStr) === -1) {
                    p.update((store: Flux.Store): string => {
                        PermitTypesState.WithinValidityDatesEdit.stateWrite(
                            store,
                            { dates: dates.concat([dateStr]) },
                        );
                        PermitTypesState.Layout.stateWrite(store, {
                            wthinValidityDatePickerOpen: false,
                        });
                        return 'WithinValidityDates-selectDate';
                    });
                }
            }}
            open={p.open}
            selectedDate={
                dates.length > 0
                    ? Parser.isoYearMonthDay(dates[dates.length - 1])
                    : moment()
            }
            outsideBody={false}
        />
    );
};

function QuotaPartitionInfoContent({
    quotaPartitionInfo,
    isOffstreet,
}: {
    quotaPartitionInfo: QuotaPartitionInfo;
    isOffstreet: boolean;
}) {
    const navigate = useNavigate();
    const { currentLogin } = useOperatorContext();
    const quotasReadOnly = !currentLogin.permissions.quotasWrite;
    return (
        <>
            {quotasReadOnly ? (
                <LabeledText label={<QuotaLabel />}>
                    <QuotaConfigBody
                        quotaPartitionInfo={quotaPartitionInfo}
                        isOffstreet={isOffstreet}
                    />
                </LabeledText>
            ) : (
                <SingleSelection
                    focused={false}
                    label={<QuotaLabel />}
                    disabled={quotasReadOnly}
                    selection={
                        <QuotaConfigBody
                            quotaPartitionInfo={quotaPartitionInfo}
                            isOffstreet={isOffstreet}
                        />
                    }
                    onClick={() =>
                        navigate(
                            `/permit-types/quotas/${quotaPartitionInfo.contractQuotaPartitionId}`,
                        )
                    }
                />
            )}
            {quotaPartitionInfo.otherProducts.length > 0 && (
                <LabeledText
                    label={
                        <Localized
                            de="Andere Bewilligungsarten im Kontingent"
                            fr="Autres types d'autorisation dans le contingent"
                            it="Altri tipi d'autorizzazione nel contingente"
                            en="Other permit types in the quota"
                        />
                    }
                >
                    {quotaPartitionInfo.otherProducts.map(product => (
                        <div key={product.contractTemplateId}>
                            <Localized {...product.name} />
                        </div>
                    ))}
                </LabeledText>
            )}
        </>
    );
}

function QuotaLabel() {
    return (
        <Localized
            de="Kontingente"
            fr="Contingents"
            it="Contingenti"
            en="Quotas"
        />
    );
}

function QuotaConfigBody({
    quotaPartitionInfo,
    isOffstreet,
}: {
    quotaPartitionInfo: QuotaPartitionInfo;
    isOffstreet: boolean;
}) {
    return (
        <>
            {quotaPartitionInfo.quotaConfigs.map((quotaConfig, index) => (
                <div key={index}>
                    <Localized
                        {...quotaPartitionConfigText(quotaConfig, isOffstreet)}
                    />
                </div>
            ))}
        </>
    );
}

const PermitTypeItem = (p: ItemProps): JSX.Element => {
    const unitPrice = p.permitType.unitPrice;
    const texts = permitTypeTexts[p.language];
    return (
        <DefaultTableRow
            onClick={() =>
                p.update(store =>
                    PermitTypesState.Layout.stateWrite(store, {
                        selectedPermitType: p.permitType.id,
                    }),
                )
            }
        >
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth.variable}>
                {p.permitType.description}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px} captionFontSize>
                {isDefined(p.permitType.profileNr)
                    ? p.permitType.profileNr
                    : '-'}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px} captionFontSize>
                {zonesString(
                    p.permitType.zones.length,
                    p.permitType.selectZones,
                    p.language,
                )}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px} captionFontSize>
                {isDefined(unitPrice) ? numberToPrice(unitPrice) : '-'}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px} captionFontSize>
                {getAppChannelString(p.permitType.autoActive, texts)}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px} captionFontSize>
                {p.permitType.needsWhitelist ? texts.Yes() : '-'}
            </TableColumn>
            {isDefined(p.account) ? (
                <TableColumn width={ColumnWidth._108px} captionFontSize>
                    {`${p.account.dtaText}`}
                </TableColumn>
            ) : null}
            <TableColumn width={ColumnWidth._24px} captionFontSize>
                <IconTopOffsetContainer>
                    <Icon16 icon={Icons16.chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </DefaultTableRow>
    );
};

interface SlideInProps {
    open: boolean;
    withinValidityDatesCanBeEdited: boolean;
    permitType: Maybe<PermitTypeState.PermitType>;
    editResponse: PermitTypesState.ValidityDatesResponse.State;
    validityDateEdit: Maybe<string[]>;
    onClose: () => void;
    language: string;
    update: Flux.Updater;
    account: OperatorDataState.Account;
}

export const getAppChannelString = (
    autoActive: AutoActive,
    texts: PermitTexts,
): string => {
    const channels: string[] = [];
    if (PermitTypeState.isAppChannel(autoActive)) {
        channels.push(texts.AppChannel());
    }
    if (PermitTypeState.isCounterChannel(autoActive)) {
        channels.push(texts.CounterChannel());
    }
    return channels.join(' / ');
};

interface WithinValidityConstraintDatesProps {
    dates: Maybe<string[]>;
    language: string;
    update: Flux.Updater;
    canBeEdited: boolean;
}

export const WithinValidityConstraintDates = (
    p: WithinValidityConstraintDatesProps,
): JSX.Element | null => {
    if (isDefined(p.dates)) {
        const txt = permitTypeTexts[p.language];
        return (
            <MiniTable
                label={txt.WithinValidityConstraintDates()}
                actionButtons={p.canBeEdited}
            >
                {p.dates.map(date => (
                    <MiniTableElement
                        key={date}
                        actionButton={
                            p.canBeEdited ? IconButton24pxType.clear : undefined
                        }
                        onAction={() => {
                            const dates = p.dates;
                            if (dates) {
                                p.update(store =>
                                    PermitTypesState.WithinValidityDatesEdit.stateWrite(
                                        store,
                                        {
                                            dates: dates.filter(
                                                d => d !== date,
                                            ),
                                        },
                                    ),
                                );
                            }
                        }}
                    >
                        {Formatter.dayMonthYear(Parser.isoYearMonthDay(date))}
                    </MiniTableElement>
                ))}
                {p.canBeEdited && (
                    <MiniTableActionOnlyElement
                        actionButton={IconButton24pxType.add}
                        onAction={() =>
                            p.update(store =>
                                PermitTypesState.Layout.stateWrite(store, {
                                    wthinValidityDatePickerOpen: true,
                                }),
                            )
                        }
                        showUnderline={p.dates.length === 0}
                    />
                )}
            </MiniTable>
        );
    } else {
        return null;
    }
};

export const Header = (p: SlideInProps): JSX.Element => {
    const txt = permitTypeTexts[p.language];
    const permitType = p.permitType;

    if (isUndefined(permitType) || p.editResponse.pending) {
        return <LoaderHeader />;
    }
    if (isDefined(p.validityDateEdit)) {
        return (
            <ConfirmationHeader
                language={p.language}
                title={txt.HeaderCaption()}
                onCancel={() =>
                    p.update(store =>
                        PermitTypesState.WithinValidityDatesEdit.stateWrite(
                            store,
                            { dates: null },
                        ),
                    )
                }
                onConfirm={() => {
                    p.update(editWithinValidyDates, {
                        id: permitType.id,
                        dates: p.validityDateEdit || [],
                    });
                }}
            />
        );
    } else {
        return (
            <StandardFirstLevelHeader onClose={p.onClose}>
                <SlideInHeaderTexts
                    title={txt.HeaderCaption()}
                    hasLeftIcon={false}
                />
            </StandardFirstLevelHeader>
        );
    }
};

const DetailSlideInBody = (p: SlideInProps): JSX.Element | null => {
    const txt = permitTypeTexts[p.language];
    const permitType = p.permitType;
    if (isDefined(permitType)) {
        const unitPrice = permitType.unitPrice;
        const vatNumber = permitType.vatNumber;
        return (
            <SlideInBody>
                <FullSlideInLeftColumn>
                    <LabeledText label={txt.Type()}>
                        {permitType.description}
                    </LabeledText>
                    <LabeledText label={txt.ProfileNr()}>
                        {isDefined(permitType.profileNr)
                            ? permitType.profileNr
                            : '-'}
                    </LabeledText>
                    <LabeledText label={txt.IdentificationBody()}>
                        {permitType.isOffstreet
                            ? txt.Badge()
                            : txt.LicensePlate()}
                    </LabeledText>
                    <LabeledText label={txt.MaxId()}>
                        {`${permitType.maxLicenses}`}
                    </LabeledText>
                    <LabeledText label={txt.Zones()}>
                        {permitType.zones.map(p => (
                            <div key={p.id}>
                                {(p.extZoneCode ? `${p.extZoneCode} | ` : '') +
                                    `${p.name} — ${p.city}`}
                            </div>
                        ))}
                    </LabeledText>

                    {permitType.channels ===
                        PermittypeChannels.PARKINGPAY_TWINT_QUICKCHECKOUT &&
                        permitType.permittypeUrlIdentification && (
                            <LabeledSignalisationDownload
                                signalisationPdfId={
                                    permitType.permittypeUrlIdentification
                                }
                                type={SignalisationType.PERMIT}
                            />
                        )}
                    {permitType.channels ===
                        PermittypeChannels.PARKINGPAY_TWINT && (
                        <Notification
                            title={
                                <Localized
                                    de="Signalisations-PDF"
                                    fr="PDF de signalisation"
                                    it="PDF segnaletica"
                                    en="Signalisation PDF"
                                />
                            }
                            color={NotificationColor.blue}
                            icon={NotificationIcon.info}
                        >
                            <Localized
                                de="Die Signalisation nur mit Parkingpay und TWINT ist nicht mehr verfügbar. Bitte kontaktieren Sie unser Parkingportal-Team ("
                                fr="La signalisation avec seulement Parkingpay et TWINT n'est plus disponible. Veuillez contacter notre équipe Parkingportal ("
                                it="La segnaletica con solo Parkingpay e TWINT non è più disponibile. Vogliate contattare il nostro team Parkingportal ("
                                en="The signage with only Parkingpay and TWINT is no longer available. Please contact our Parkingportal team ("
                            />
                            <a href="mailto:info@parkingportal.ch">
                                info@parkingportal.ch
                            </a>
                            <Localized
                                de=") für ein Upgrade auf Quick-Checkout."
                                fr=") pour passer à Quick-Checkout."
                                it=") per effettuare l’aggiornamento a Quick-Checkout."
                                en=") for an upgrade to Quick-Checkout."
                            />
                        </Notification>
                    )}
                </FullSlideInLeftColumn>
                <FullSlideInRightColumn>
                    <LabeledText label={txt.Price()}>
                        {isDefined(unitPrice) ? numberToPrice(unitPrice) : '-'}
                    </LabeledText>
                    <LabeledText label={txt.VatBody()}>
                        {isDefined(vatNumber) ? vatNumber : '-'}
                    </LabeledText>
                    <LabeledText label={txt.SellChannel()}>
                        {getAppChannelString(permitType.autoActive, txt)}
                    </LabeledText>

                    {p.account ? (
                        <LabeledText label={txt.Account()}>
                            {p.account.iban} | {p.account.dtaText}
                        </LabeledText>
                    ) : null}
                    {permitType.quotaPartitionInfo !== null && (
                        <QuotaPartitionInfoContent
                            quotaPartitionInfo={permitType.quotaPartitionInfo}
                            isOffstreet={permitType.isOffstreet}
                        />
                    )}
                    {permitType.intradayValidityWeekProfile !== null && (
                        <LabeledText
                            label={
                                <Localized
                                    de="Tägliche Gültigkeit"
                                    fr="Validité journalière"
                                    it="Validità giornaliera"
                                    en="Daily validity"
                                />
                            }
                        >
                            <IntradayValidityWeekProfileDisplay
                                localizedComponent={Localized}
                                weekProfile={
                                    permitType.intradayValidityWeekProfile
                                }
                            />
                        </LabeledText>
                    )}
                    <WithinValidityConstraintDates
                        canBeEdited={p.withinValidityDatesCanBeEdited}
                        dates={getOrElse(
                            p.validityDateEdit,
                            permitType.withinValidityConstraintDates,
                        )}
                        language={p.language}
                        update={p.update}
                    />
                </FullSlideInRightColumn>
            </SlideInBody>
        );
    } else {
        return null;
    }
};

export const PermitDetailSlideIn = (p: SlideInProps): JSX.Element => {
    return (
        <FullSlideIn open={p.open}>
            <DetailSlideInBody {...p} />
            <Header {...p} />
        </FullSlideIn>
    );
};

interface State {
    settings: SettingsState.State;
    layout: PermitTypesState.Layout.State;
    permitTypes: PermitTypeState.State;
    withinValidityDatesEdit: PermitTypesState.WithinValidityDatesEdit.State;
    editResponse: PermitTypesState.ValidityDatesResponse.State;
    operatorData: OperatorDataState.State;
    currentLogin: CurrentOperatorLoginState.State;
}

export class PermitTypesBody extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: PermitTypesState.Layout.get(this.props.allState),
            permitTypes: PermitTypeState.get(this.props.allState),
            withinValidityDatesEdit:
                PermitTypesState.WithinValidityDatesEdit.get(
                    this.props.allState,
                ),
            editResponse: PermitTypesState.ValidityDatesResponse.get(
                this.props.allState,
            ),
            operatorData: OperatorDataState.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    getDependingStateSlices(): AbstractLegacyServerState<unknown>[] {
        return [this.state.permitTypes, this.state.operatorData];
    }

    renderSlideIns(): JSX.Element[] {
        const login = this.state.currentLogin.data;

        const permitType = thenElse(
            this.state.layout.selectedPermitType,
            id => PermitTypeState.getById(this.props.allState, id),
            null,
        );

        const operatorData = this.state.operatorData.data;

        const account =
            !!permitType &&
            !!operatorData &&
            operatorData.billingProperties.accounts.length > 0
                ? operatorData.billingProperties.accounts.find(
                      account =>
                          account.dtaAccountId === permitType.dtaAccountId,
                  )
                : undefined;

        if (!account) {
            return [];
        }

        return [
            <PermitDetailSlideIn
                key="PermitDetailSlideIn"
                open={isDefined(this.state.layout.selectedPermitType)}
                language={this.state.settings.language}
                onClose={() =>
                    this.update(store =>
                        PermitTypesState.Layout.stateWrite(store, {
                            selectedPermitType: null,
                        }),
                    )
                }
                validityDateEdit={this.state.withinValidityDatesEdit.dates}
                permitType={permitType}
                account={account}
                update={this.props.allState.update}
                editResponse={this.state.editResponse}
                withinValidityDatesCanBeEdited={Boolean(
                    login?.permissions.userPermissionEdit,
                )}
            />,
            <ValidityDatePickerSlideIn
                key="ValidityDatePickerSlideIn"
                language={this.state.settings.language}
                editDates={this.state.withinValidityDatesEdit.dates}
                permitType={permitType}
                open={!!this.state.layout.wthinValidityDatePickerOpen}
                update={this.props.allState.update}
            />,
        ];
    }

    render() {
        const txt = permitTypeTexts[this.state.settings.language];
        const language = this.state.settings.language;
        const operator = this.state.operatorData.data;

        if (!operator || !operator.billingProperties) {
            return;
        }

        const accounts = operator.billingProperties.accounts;

        return (
            <Body
                disabled={false}
                dependingStateSlices={this.getDependingStateSlices()}
                slideIns={this.renderSlideIns()}
            >
                <Table>
                    <TableHeader>
                        <EmptyTableHeaderColumn
                            width={ColumnWidth._24px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.Type()}
                            direction={SortDirection.Ascending}
                            onClick={null}
                            selected={true}
                            width={ColumnWidth.variable}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.ProfileNr()}
                            direction={null}
                            onClick={null}
                            selected={false}
                            width={ColumnWidth._108px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.Zones()}
                            direction={null}
                            onClick={null}
                            selected={false}
                            width={ColumnWidth._108px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.Price()}
                            direction={null}
                            onClick={null}
                            selected={false}
                            width={ColumnWidth._108px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.SellChannel()}
                            direction={null}
                            onClick={null}
                            selected={false}
                            width={ColumnWidth._192px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.Clearance()}
                            direction={null}
                            onClick={null}
                            selected={false}
                            width={ColumnWidth._108px}
                            delimiterBottom={true}
                        />

                        {accounts.length > 1 ? (
                            <TableHeaderColumn
                                name={txt.Account()}
                                direction={null}
                                onClick={null}
                                selected={false}
                                width={ColumnWidth._108px}
                                delimiterBottom={true}
                            />
                        ) : null}
                        <EmptyTableHeaderColumn
                            width={ColumnWidth._24px}
                            delimiterBottom={true}
                        />
                    </TableHeader>
                    <TableBody>
                        {this.state.permitTypes.data
                            .filter(
                                pt =>
                                    pt.operatorState === 'ACTIVE' &&
                                    pt.autoActive !==
                                        AutoActive.STARTS_TRANSACTION,
                            )
                            .map((t: PermitTypeState.PermitType) => {
                                const account =
                                    accounts.length > 1
                                        ? accounts.find(
                                              account =>
                                                  account.dtaAccountId ===
                                                  t.dtaAccountId,
                                          )
                                        : null;
                                return (
                                    <PermitTypeItem
                                        permitType={t}
                                        account={account || null}
                                        language={language}
                                        update={this.props.allState.update}
                                        key={t.id}
                                    />
                                );
                            })}
                    </TableBody>
                </Table>
            </Body>
        );
    }
}
