import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { selectState, Store } from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Spinner } from '../../ui/loaders/Spinner.tsx';
import { StringSingleSelectionSlideIn } from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import { countrySelectionTexts } from 'dg-web-shared/common/components/translations/LicensePlateTexts.ts';
import { Translation } from '../i18n/Text.ts';
import * as MetaState from '../state/MetaState.ts';
import * as SettingsState from '../state/SettingsState.ts';
import { valuesValid } from './LicensePlateTrippleEntry.tsx';

export interface Texts {
    Heading: Translation;
}

interface State {
    settings: SettingsState.State;
    countries: MetaState.LicensePlateCountries.State;
}

interface Props {
    onClose: () => void;
    onSelect: (country: string, formValid: boolean) => void;
    country: string | null;
    licensePlateType: Maybe<LicensePlateType>;
    licensePlateNr: Maybe<string>;
    outsideBody: boolean;
    language: string;
    open: boolean;
}

export const LicensePlateCountrySlideIn = selectState<Props, State>(
    (store: Store): State => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            countries: MetaState.LicensePlateCountries.get(store),
        };
    },
    p => {
        if (p.countries.pending) {
            return <Spinner />;
        } else if (!p.countries.data) {
            return <div>Error</div>;
        }
        return (
            <StringSingleSelectionSlideIn
                open={p.open}
                heading={countrySelectionTexts[p.language].Heading()}
                selection={p.country}
                options={p.countries.data.map(
                    (t: MetaState.LicensePlateCountry) => {
                        return {
                            id: t.id,
                            displayText: `${t.id} — ${
                                t.name[p.settings.language]
                            }`,
                        };
                    },
                )}
                onSelect={(v: string) =>
                    p.onSelect(
                        v,
                        valuesValid({
                            country: v,
                            licensePlateNr: p.licensePlateNr,
                            licensePlateType: p.licensePlateType,
                            outsideLicensePlateNrErrorGen: null,
                            language: p.language,
                        }),
                    )
                }
                onLeftActionClick={p.onClose}
                outsideBody={p.outsideBody}
            />
        );
    },
);
