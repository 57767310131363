import moment from 'moment';
import * as TariffState from '../state/TariffState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { DatePickerSlideIn } from '../../ui/slidein/DatePickerSlideIn.tsx';
import { tariffCalculatorTexts } from '../i18n/TariffTexts.ts';

interface State {
    settings: SettingsState.State;
    layout: TariffState.Layout.State;
    calculator: TariffState.Calculator.State;
}

export class TariffCalculatorDateSlideIn extends Flux.Container<State> {
    static displayName = 'TariffCalculatorDateSlideIn';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: TariffState.Layout.get(this.props.allState),
            calculator: TariffState.Calculator.get(this.props.allState),
        };
    }

    onDateSelected(selectedDate: moment.Moment): void {
        this.update((store: Flux.Store): string => {
            TariffState.Calculator.stateWrite(store, {
                date: selectedDate,
            });
            TariffState.Layout.stateWrite(store, {
                dateSelectionOpen: false,
            });
            return 'TariffCalculator-selectDate';
        });
    }

    render() {
        const txt = tariffCalculatorTexts[this.state.settings.language];
        return (
            <DatePickerSlideIn
                open={this.state.layout.dateSelectionOpen}
                caption={txt.Date()}
                onClose={() =>
                    this.update(store =>
                        TariffState.Layout.stateWrite(store, {
                            dateSelectionOpen: false,
                        }),
                    )
                }
                selectedDate={this.state.calculator.date}
                onSelect={(selectedDate: moment.Moment) =>
                    this.onDateSelected(selectedDate)
                }
                language={this.state.settings.language}
                outsideBody={false}
            />
        );
    }
}
