import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { CustomPermissionCheckers } from '../state/Permission.ts';
import { isRouteAllowed } from './OperatorRoutes.tsx';
import { OperatorAppRoutes } from '../config/OperatorRoutingConfig.tsx';
import React from 'react';

export interface RestrictedComponentProps {
    route: OperatorAppRoutes;
    /**
     * optional permission checker that is not route based.
     * Import from Permission.CustomPermissionCheckers
     */
    permissionChecker?:
        | CustomPermissionCheckers.CustomPermissionChecker
        | undefined;
    children?: React.ReactElement;
}

interface RestrictedComponentState {
    login: CurrentOperatorLoginState.State;
    operator: OperatorDataState.State;
}

export const RestrictedComponent = Flux.selectState(
    (
        store: Flux.Store,
        _props: RestrictedComponentProps,
    ): RestrictedComponentState => ({
        login: CurrentOperatorLoginState.get(store),
        operator: OperatorDataState.get(store),
    }),
    (p): JSX.Element | null => {
        if (!p.login.data || !p.operator.data) {
            return null;
        }

        if (!isRouteAllowed(p.operator.data, p.login.data, p.route)) {
            return null;
        }
        if (
            p.permissionChecker &&
            !p.permissionChecker(p.operator.data, p.login.data)
        ) {
            return null;
        }
        return React.Children.only(p.children || null);
    },
);
