import { TextColumnContent, TextLeftColumn } from '../ui/layout/Text.tsx';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { guideTexts } from './Texts.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';

export interface GuideTexts {
    header: Translation;
    intro: Translation;
    listDescription: Translation;
    licenseplate: Translation;
    customerName: Translation;
    customerNumber: Translation;
}

const texts = (p: { settings: SettingsState.State }) =>
    guideTexts[p.settings.language];

interface GuideProps {
    settings: SettingsState.State;
}
export const Guide = (p: GuideProps) => (
    <TextColumnContent>
        <TextLeftColumn>
            <h1>{texts(p).header()}</h1>
            <h3>{texts(p).intro()}</h3>
            <h3>{texts(p).listDescription()}</h3>
            <ul>
                <li>{texts(p).licenseplate()}</li>
                <li>{texts(p).customerName()}</li>
                <li>{texts(p).customerNumber()}</li>
            </ul>
        </TextLeftColumn>
    </TextColumnContent>
);
