import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import {
    HalfSlideIn,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../ui/slidein/Slidein.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { DateFilterSelection } from './DateFilter.tsx';
import { Filter, UI } from './ClearanceRequestsState.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { filterSlideInTexts } from './Texts.tsx';
import { PermitTypeFilterSelection } from './PermitTypeFilter.tsx';
import { StatusFilterSelection } from './StatusFilter.tsx';

export interface FilterSlideInTexts {
    title: Translation;
}

export const FilterSlideIn = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
        ui: UI.get(store),
    }),
    p => (
        <HalfSlideIn open={p.ui.filterSlideInVisible}>
            <StandardFirstLevelHeader
                onClose={() =>
                    p.update(store =>
                        UI.stateWrite(store, { filterSlideInVisible: false }),
                    )
                }
                title={filterSlideInTexts[p.settings.language].title()}
            />
            <SlideInBody>
                <DateFilterSelection />
                <PermitTypeFilterSelection />
                <StatusFilterSelection />
            </SlideInBody>
        </HalfSlideIn>
    ),
);
