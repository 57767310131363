import type {
    EnforcedLicensePlate,
    EnforcedLPMessage,
    InfoSection,
    EnforcementItem,
} from './EnforcementTypes.ts';
import { css } from '@emotion/css';
import { rgba } from 'utils/src/Rgba.ts';
import { Colors, Typo } from './Styles.ts';

export enum EnforcedLPMessageType {
    INFO = 'INFO',
    WARN = 'WARN',
    ERR = 'ERR',
}

export function EnforcementResult({
    result,
}: {
    result: Readonly<EnforcedLicensePlate[] | null>;
}) {
    const text = {
        it: 'Ora del controllo:',
        de: 'Kontrollzeit:',
        fr: 'Heure du contrôle:',
    }[language()];

    return (
        <div
            className={css({
                flexGrow: 1,
            })}
        >
            {result && result[0] && (
                <div
                    className={css({
                        background: '#EDEDED',
                        fontSize: '11px',
                        lineHeight: '24px',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        height: '24px',
                        padding: '0 16px',
                    })}
                >
                    {`${text} ${result[0].enforceTime}`}
                </div>
            )}
            {result &&
                result.map((r, i) => <EnforcedLicensePlate key={i} {...r} />)}
        </div>
    );
}

/**
 * @deprecated
 */
enum Language {
    DE = 'de',
    FR = 'fr',
    IT = 'it',
}

/**
 * @deprecated
 */
const language = (): Language => {
    const locale = navigator.language;
    if (!locale) {
        return Language.DE;
    }
    if (locale.slice(0, 2) === 'fr') {
        return Language.FR;
    }
    if (locale.slice(0, 2) === 'it') {
        return Language.IT;
    } else {
        return Language.DE;
    }
};

const EnforcedLicensePlate = (p: EnforcedLicensePlate): JSX.Element => {
    return (
        <div>
            {p.message && <ResultMessage message={p.message} />}
            <div
                className={css({
                    height: '56px',
                    ...Typo.robotoMono,
                    letterSpacing: '0.04em',
                    fontSize: '22px',
                    lineHeight: '56px',
                    paddingLeft: '16px',
                    color: Colors.white,
                    backgroundColor: p.isValid ? Colors.green : Colors.red,
                })}
            >
                {p.title}
                <div
                    className={css({
                        height: '56px',
                        width: '44px',
                        float: 'right',
                        paddingRight: '16px',
                    })}
                >
                    {p.isValid ? (
                        <svg
                            focusable="false"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 80 80"
                            style={{ display: 'block' }}
                        >
                            <path
                                d="M65.779,29.262 L60.948,24.431 C60.285,23.768 59.479,23.437 58.532,23.437 C57.585,23.437 56.78,23.768 56.116,24.431 L32.812,47.77 L22.369,37.289 C21.706,36.626 20.9,36.294 19.953,36.294 C19.006,36.294 18.201,36.626 17.537,37.289 L12.707,42.12 C12.043,42.783 11.712,43.588 11.712,44.535 C11.712,45.482 12.043,46.288 12.707,46.951 L25.566,59.81 L30.396,64.641 C31.059,65.304 31.864,65.635 32.812,65.635 C33.759,65.635 34.565,65.303 35.227,64.641 L40.058,59.81 L65.776,34.092 C66.439,33.429 66.77,32.624 66.77,31.676 C66.77,30.729 66.439,29.923 65.776,29.261"
                                fill="#FFFFFF"
                            />
                        </svg>
                    ) : (
                        <svg
                            focusable="false"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 80 80"
                            style={{ display: 'block' }}
                        >
                            <path
                                d="M49.291,41.062 L59.191,31.162 C59.821,30.542 60.131,29.772 60.131,28.872 C60.131,27.972 59.821,27.212 59.191,26.582 L54.611,22.002 C53.981,21.382 53.221,21.062 52.321,21.062 C51.421,21.062 50.661,21.372 50.031,22.002 L40.131,31.902 L30.231,22.002 C29.601,21.382 28.841,21.062 27.941,21.062 C27.041,21.062 26.281,21.372 25.651,22.002 L21.071,26.582 C20.441,27.212 20.131,27.972 20.131,28.872 C20.131,29.772 20.441,30.532 21.071,31.162 L30.971,41.062 L21.071,50.962 C20.441,51.582 20.131,52.352 20.131,53.252 C20.131,54.152 20.441,54.912 21.071,55.542 L25.651,60.122 C26.281,60.752 27.041,61.062 27.941,61.062 C28.841,61.062 29.601,60.752 30.231,60.122 L40.131,50.222 L50.031,60.122 C50.661,60.752 51.421,61.062 52.321,61.062 C53.221,61.062 53.981,60.752 54.611,60.122 L59.191,55.542 C59.821,54.922 60.131,54.152 60.131,53.252 C60.131,52.352 59.821,51.592 59.191,50.962 L49.291,41.062"
                                fill="#FFFFFF"
                            />
                        </svg>
                    )}
                </div>
            </div>
            <div
                className={css({
                    color: Colors.white,
                    ...Typo.robotoRegular,
                    fontSize: '14px',
                    padding: '8px 40px 8px 16px',
                    backgroundColor: p.isValid
                        ? rgba(Colors.green, 0.5)
                        : rgba(Colors.red, 0.5),
                })}
            >
                {p.caption}
            </div>
            <EnforcementItems items={p.enforcementItems} isValid={p.isValid} />
        </div>
    );
};

const EnforcementItems = (p: {
    items: EnforcementItem[];
    isValid: boolean;
}): JSX.Element => {
    const last = p.items.length - 1;
    return (
        <div
            className={css({
                padding: '0px 16px',
                marginBottom: '12px',
                backgroundColor: p.isValid
                    ? rgba(Colors.green, 0.1)
                    : rgba(Colors.red, 0.1),
            })}
        >
            {p.items.map((item, i) => (
                <EnforcementItem
                    key={i}
                    first={i === 0}
                    last={i === last}
                    item={item}
                />
            ))}
        </div>
    );
};

const EnforcementItem = (p: {
    item: EnforcementItem;
    first: boolean;
    last: boolean;
}): JSX.Element => {
    return (
        <div
            className={css({
                color: Colors.grey,
                paddingBottom: '24px',
                marginBottom: '24px',
                ...Typo.robotoMedium,
                letterSpacing: '0.04em',
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 700,
                borderBottom: p.last ? '0px' : `1px solid ${Colors.border}`,
                paddingTop: p.first ? '24px' : '0px',
            })}
        >
            <div className={getItemColorClass(p.item.data.state)}>
                <div
                    className={css({
                        fontSize: '15px',
                        fontWeight: 400,
                    })}
                >
                    {p.item.heading}
                </div>
                <div>{p.item.caption}</div>
            </div>
            {p.item.infoSections.map((s, i) => (
                <InfoSection key={i} {...s} />
            ))}
        </div>
    );
};

function getItemColorClass(state: 'ACTIVE' | 'EXPIRED' | 'INFO' | 'FUTURE') {
    switch (state) {
        case 'ACTIVE':
            return css({
                color: Colors.green,
            });
        case 'EXPIRED':
            return css({
                color: Colors.red,
            });
        default:
            return '';
    }
}

const InfoSection = (p: InfoSection): JSX.Element => {
    return (
        <div
            className={css({
                marginTop: '16px',
                fontWeight: 700,
            })}
        >
            <div className={css({ fontSize: '15px', fontWeight: 400 })}>
                {p.caption}
            </div>
            {p.lines.map((l, i) => (
                <div key={i}>{l}</div>
            ))}
        </div>
    );
};

function ResultMessage({ message }: { message: EnforcedLPMessage }) {
    return (
        <div>
            <div
                className={css({
                    ...Typo.robotoRegular,
                    background: getCaptionBackgroundColor(message.type),
                    color: getMessageFont(message.type),
                    padding: '8px 40px 8px 16px',
                })}
            >
                {message.caption}
            </div>
            {message.lines.map(line => (
                <div
                    key={line}
                    className={css({
                        ...Typo.robotoRegular,
                        background: getLinesBackgroundColor(message.type),
                        color: getMessageFont(message.type),
                        padding: '8px 40px 8px 16px',
                    })}
                >
                    {line}
                </div>
            ))}
        </div>
    );
}

function getCaptionBackgroundColor(messageType: EnforcedLPMessageType) {
    switch (messageType) {
        case EnforcedLPMessageType.ERR:
            return Colors.yellow;
        case EnforcedLPMessageType.WARN:
            return Colors.yellow;
        case EnforcedLPMessageType.INFO:
            return Colors.infoCaption;
    }
}

function getLinesBackgroundColor(messageType: EnforcedLPMessageType) {
    switch (messageType) {
        case EnforcedLPMessageType.ERR:
            return Colors.lightYellow;
        case EnforcedLPMessageType.WARN:
            return Colors.lightYellow;
        case EnforcedLPMessageType.INFO:
            return Colors.infoText;
    }
}

function getMessageFont(messageType: EnforcedLPMessageType) {
    switch (messageType) {
        case EnforcedLPMessageType.ERR:
            return Colors.black;
        case EnforcedLPMessageType.WARN:
            return Colors.black;
        case EnforcedLPMessageType.INFO:
            return Colors.grey;
    }
}
