import {
    FilterSummary,
    FilterSummaryStatus,
    FilterSummaryContainer,
    FilterSummaryClose,
} from './FilterSummary.tsx';
import { FilterStatusIcon } from './FilterStatusIcon.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import React from 'react';

interface Props {
    onFilterClick: Maybe<() => void>;
    clearFilter: () => void;
    children?: React.ReactNode;
}

interface State {
    hover: boolean;
}

export class FilterButtonWithSummary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hover: false };
    }

    render(): JSX.Element {
        return (
            <FilterSummary hover={this.state.hover}>
                <FilterSummaryStatus>
                    <FilterStatusIcon
                        clickable={!!this.props.onFilterClick}
                        active={!!this.props.children}
                        onClick={this.props.onFilterClick}
                    />
                </FilterSummaryStatus>
                {this.props.children && (
                    <FilterSummaryContainer>
                        {this.props.children}
                        <FilterSummaryClose
                            onClick={() => {
                                this.props.clearFilter();
                                this.setState({ hover: false });
                            }}
                            onMouseEnter={() => this.setState({ hover: true })}
                            onMouseLeave={() => this.setState({ hover: false })}
                        />
                    </FilterSummaryContainer>
                )}
            </FilterSummary>
        );
    }
}
