import { Language } from 'dg-web-shared/lib/Text.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../ui/Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function ServerCommunicationError(props: Props): JSX.Element {
    return (
        <div className={style}>
            <p className={messageStyle}>{props.message}</p>
        </div>
    );
}

interface Props {
    language: Language;
    message: string;
}

const style = css`
    display: flex;
    flex-direction: column;
`;

const messageStyle = css`
    font-size: 26px;
    line-height: 34px;
    ${Typo.robotoLight};
    color: ${ColorHex.errorBright};
    margin: 25px 25px 0px 25px;
`;
