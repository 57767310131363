import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { FilteredClearancesFilterSummary } from './FilteredClearancesFilterSummary.tsx';
import { FilteredClearancesList } from './FilteredClearancesList.tsx';
import * as FilteredClearancesState from '../../state/FilteredClearancesState.ts';
import { FilteredClearancesFilterHalfSlideIn } from './FilteredClearancesFilterHalfSlideIn.tsx';
import { PermitTypeFilterHalfSlideIn } from './PermitTypeFilter.tsx';
import { IssueDateFilterHalfSlideIn } from './IssueDateFilter.tsx';
import { OperatorLoginFilterHalfSlideIn } from './OperatorLoginFilter.tsx';
import * as Http from '../../../api/Http.ts';
import { thenElse, isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { PermitFilterHalfSlideIn } from './PermitFilter.tsx';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import { AddMenu } from '../shared/AddMenu.tsx';
import {
    ButtonDropdown,
    ButtonDropdownLinkItem,
} from '../../../ui/slidein/ButtonDropdown.tsx';
import {
    FilterLayoutBody,
    FilterLayout,
    FilterLayoutHeader,
} from '../../../ui/filter/FilterLayout.tsx';
import { ValidDateFilterHalfSlideIn } from './ValidDateFilter.tsx';
import {
    captureText,
    exportText,
} from '../filtered-permits/FilteredPermits.tsx';
import { Localized } from '../../../common/components/Localized.tsx';

export const renderClearanceFilterSlideIns = (
    allState: Flux.Store,
): JSX.Element[] => [
    <FilteredClearancesFilterHalfSlideIn
        allState={allState}
        key={'FilteredClearancesFilterSlideIns'}
    />,
    <PermitTypeFilterHalfSlideIn key={'FilteredClearancesPermitTypeFilter'} />,
    <OperatorLoginFilterHalfSlideIn
        allState={allState}
        key={'FilteredClearancesOperatorLogFilter'}
    />,
    <IssueDateFilterHalfSlideIn
        allState={allState}
        key={'FilteredClearancesIssueDateFilter'}
    />,
    <ValidDateFilterHalfSlideIn
        allState={allState}
        key={'FilteredClearancesValidDateFilter'}
    />,
    <PermitFilterHalfSlideIn
        allState={allState}
        key={'FilteredClearancesPermitFilter'}
    />,
];

const stringifyParams = (params: {
    [idx: string]: string | string | null;
}): string => {
    return Object.keys(params)
        .filter(k => isDefined(params[k]))
        .map((k: string) => `${k}=${params[k]}`)
        .join('&');
};

const filterToParams = (state: FilteredClearancesState): string => {
    return stringifyParams(
        Http.OperatorAccount.Clearances.FilteredList.getQueryParams({
            permitType: state.filter.permitTypes,
            issuedBy: thenElse(
                state.filter.operatorLogins,
                l =>
                    l.map(id =>
                        id ===
                        FilteredClearancesState.Filter
                            .OperatorLoginFilterOnlineOption
                            ? null
                            : id,
                    ),
                null,
            ),
            issueDateFrom: state.filter.issueDateFrom,
            issueDateTo: state.filter.issueDateTo,
            validDateFrom: state.filter.validDateFrom,
            validDateTo: state.filter.validDateTo,
            sortField: 'created',
            withOrWithoutPermits: state.filter.permits,
        }),
    );
};

interface FilteredClearancesState {
    filter: FilteredClearancesState.Filter.State;
    operatorLogin: CurrentOperatorLoginState.State;
}
export class FilteredClearances extends Flux.Container<FilteredClearancesState> {
    stateSelector(): FilteredClearancesState {
        return {
            filter: FilteredClearancesState.Filter.get(this.props.allState),
            operatorLogin: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    render() {
        const filterActive = FilteredClearancesState.Filter.filterActive(
            this.state.filter,
        );
        const params = filterToParams(this.state);
        return (
            <FilterLayout>
                <FilterLayoutHeader
                    filterSummary={
                        <FilteredClearancesFilterSummary
                            allState={this.props.allState}
                        />
                    }
                    rightDropdown={<AddMenu label={captureText} />}
                    secondRightDropdown={
                        <ButtonDropdown label={exportText}>
                            <ButtonDropdownLinkItem
                                href={
                                    `/ui-api/operator-account/clearances` +
                                    `/licenseplate-badge/filter/csv-export?${params}`
                                }
                                label={
                                    <Localized
                                        de="Freigaben"
                                        fr="Approbations"
                                        it="Approvazioni"
                                        en="Approvals"
                                    />
                                }
                                disabled={!filterActive}
                            />
                        </ButtonDropdown>
                    }
                />
                <FilterLayoutBody>
                    <FilteredClearancesList allState={this.props.allState} />
                </FilterLayoutBody>
            </FilterLayout>
        );
    }
}
