import { SearchField } from './SearchField.tsx';
import { Mousetrap } from '../layout/Mousetrap.tsx';
import { SearchPlaceholder } from './SearchPlaceholder.tsx';
import { css } from '@emotion/css';

interface Props {
    searchValue: string;
    onSearchFieldChange: (v: string) => void;
    onSelect: () => void;
    selected: boolean;
    language: string;
    mousetrap: boolean;
}

export const Search = (p: Props): JSX.Element => {
    return (
        <div
            className={css({
                float: 'left',
                height: '52px',
                position: 'relative',
            })}
        >
            <div
                className={css({
                    position: 'relative',
                    width: '230px',
                    height: '100%',
                })}
            >
                {p.selected ? (
                    <SearchField
                        searchValue={p.searchValue}
                        language={p.language}
                        onChange={p.onSearchFieldChange}
                    />
                ) : (
                    <SearchPlaceholder
                        language={p.language}
                        onClick={p.onSelect}
                    />
                )}
            </div>
            <Mousetrap enabled={p.mousetrap} />
        </div>
    );
};
