import moment from 'moment';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as FilteredClearancesState from '../../state/FilteredClearancesState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { DatePicker } from '../../../ui/date-picker/DatePicker.tsx';
import * as FilteredClearancesActions from '../../actions/FilteredClearancesActions.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import { issueDateFilterTexts } from '../../i18n/FilteredClearancesTexts.ts';
import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from '../../../ui/slidein/Slidein.tsx';
import { DateHeader } from '../shared/DateHeader.tsx';
import { css } from '@emotion/css';

export interface IssueDateFilterTexts {
    filterTitle: Text.Translation;
    from: Text.Translation;
    to: Text.Translation;
}

interface IssueDateFilterHalfSlideInState {
    settings: SettingsState.State;
    filter: FilteredClearancesState.Filter.State;
}

export class IssueDateFilterHalfSlideIn extends Flux.Container<IssueDateFilterHalfSlideInState> {
    stateSelector(): IssueDateFilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredClearancesState.Filter.get(this.props.allState),
        };
    }

    getTexts(): IssueDateFilterTexts {
        return issueDateFilterTexts[this.state.settings.language];
    }

    renderFromDatePicker(): JSX.Element {
        const texts = this.getTexts();
        return (
            <div className={css({ marginBottom: '30px' })}>
                <DateHeader
                    label={texts.from()}
                    date={this.state.filter.issueDateFrom}
                />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={this.state.filter.issueDateFrom}
                    onSelect={d =>
                        this.props.allState.update(store =>
                            FilteredClearancesActions.setIssueDateFrom(
                                store,
                                d,
                            ),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    renderToDatePicker(): JSX.Element {
        const texts = this.getTexts();
        return (
            <div>
                <DateHeader
                    label={texts.to()}
                    date={this.state.filter.issueDateTo}
                />
                <DatePicker
                    currentDate={moment()}
                    selectedDate={this.state.filter.issueDateTo}
                    onSelect={d =>
                        this.props.allState.update(store =>
                            FilteredClearancesActions.setIssueDateTo(store, d),
                        )
                    }
                    language={this.state.settings.language}
                />
            </div>
        );
    }

    render() {
        const texts = this.getTexts();
        return (
            <HalfSlideIn open={this.state.filter.issueDateSelectionVisible}>
                <SecondLevelHeader
                    onClose={() =>
                        this.update(store =>
                            FilteredClearancesState.Filter.stateWrite(store, {
                                issueDateSelectionVisible: false,
                            }),
                        )
                    }
                >
                    <SlideInHeaderTexts
                        subtitle={''}
                        title={texts.filterTitle()}
                        hasLeftIcon={false}
                    />
                </SecondLevelHeader>
                <SlideInBody>
                    <div
                        className={css({
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            overflowX: 'hidden',
                            overflowY: 'auto',
                        })}
                    >
                        <div
                            className={css({
                                width: '280px',
                                margin: '30px auto 0 auto',
                            })}
                        >
                            {this.renderFromDatePicker()}
                            {this.renderToDatePicker()}
                        </div>
                    </div>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

interface IssueDateFilterSelectionState {
    settings: SettingsState.State;
    filter: FilteredClearancesState.Filter.State;
}

export class IssueDateFilterSelection extends Flux.Container<IssueDateFilterSelectionState> {
    static displayName = 'IssueDateFilterSelection';

    stateSelector(): IssueDateFilterSelectionState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredClearancesState.Filter.get(this.props.allState),
        };
    }

    render() {
        const texts = issueDateFilterTexts[this.state.settings.language];
        const clear = FilteredClearancesState.Filter.issueDateFilterActive(
            this.state.filter,
        )
            ? () => this.update(FilteredClearancesActions.clearIssueDate)
            : null;
        const selection = Formatter.openRange(
            this.state.filter.issueDateFrom,
            this.state.filter.issueDateTo,
            Formatter.dayMonthYear,
        );

        return (
            <SingleSelection
                label={texts.filterTitle()}
                focused={false}
                selection={selection}
                onClick={() =>
                    this.update(store =>
                        FilteredClearancesState.Filter.stateWrite(store, {
                            issueDateSelectionVisible: true,
                        }),
                    )
                }
                onClear={clear}
            />
        );
    }
}
