import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from '../../ui/slidein/Slidein.tsx';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as PermitEditState from '../state/PermitEditState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitSlideInTexts from '../i18n/PermitSlideInTexts.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as Http from '../../api/Http.ts';
import { css } from '@emotion/css';
import { OperatorTypo } from '../../ui/OperatorTypo.ts';
import { ColorHex } from '../../ui/Colors.ts';

export interface Texts {
    Caption: Translation;
    Heading: Translation;
    PermitPrint: Translation;
    VignettePrint: (lp: string) => string;
}

interface State {
    settings: SettingsState.State;
    layout: PermitEditState.Layout.State;
    server: PermitEditState.Server.State;
}

const PrintLink = (p: { link: string; description: string }) => {
    return (
        <a
            className={css({
                display: 'block',
                textDecoration: 'none',
                ...OperatorTypo.body,
                color: ColorHex.darkblue,
                padding: '4px 48px',
                margin: '0 -48px',
                '&:hover': {
                    backgroundColor: ColorHex.rgba(ColorHex.lightblue, 0.25),
                },
            })}
            target="_blank"
            href={p.link}
            rel="noreferrer"
        >
            {p.description}
        </a>
    );
};

export class PrintSelectionSlideIn extends Flux.Container<State> {
    static displayName = 'PrintSelectionSlideIn';

    key(): string {
        return 'permit-detail-PrintSelectionSlideIn';
    }

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: PermitEditState.Layout.get(this.props.allState),
            server: PermitEditState.Server.get(this.props.allState),
        };
    }

    txt(): Texts {
        return PermitSlideInTexts.print[this.state.settings.language];
    }

    renderElements(): JSX.Element[] {
        const permit = this.state.server.data;
        if (permit) {
            const links = [
                <PrintLink
                    description={this.txt().PermitPrint()}
                    link={Http.OperatorAccount.Permits.pdfPrint(permit.id)}
                    key="permit-pdf"
                />,
            ];
            for (const v of permit.vignetteSideprint) {
                links.push(
                    <PrintLink
                        description={this.txt().VignettePrint(v.licensePlateNr)}
                        link={Http.OperatorAccount.LicensePlates.vignettePdf2(
                            v.id,
                        )}
                        key={`vignettePdf${v.id}`}
                    />,
                );
            }
            return links;
        } else {
            return [];
        }
    }

    render() {
        return (
            <HalfSlideIn open={this.state.layout.showPrintSlideIn} outsideBody>
                <SecondLevelHeader
                    onClose={() =>
                        this.update(store =>
                            PermitEditState.Layout.stateWrite(store, {
                                showPrintSlideIn: false,
                            }),
                        )
                    }
                >
                    <SlideInHeaderTexts
                        subtitle={this.txt().Caption()}
                        title={this.txt().Heading()}
                        hasLeftIcon={false}
                    />
                </SecondLevelHeader>
                <SlideInBody>{this.renderElements()}</SlideInBody>
            </HalfSlideIn>
        );
    }
}
