import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';

export namespace Layout {
    export interface State {
        selectedTomId?: Maybe<number>;
        showTomBaseDataValidation: boolean;
        outOfServiceOpen: boolean;
        outOfServiceDatePickerOpen: boolean;

        outOfServiceConfirmPressedOnError: boolean;
    }
    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'toms-Layout',
        {
            selectedTomId: null,
            showTomBaseDataValidation: false,
            outOfServiceOpen: false,
            outOfServiceDatePickerOpen: false,

            outOfServiceConfirmPressedOnError: false,
        },
    );
}

export enum Tabs {
    TOM,
    UsbSticks,
    Collections,
}

export namespace SelectedTab {
    export interface State {
        activeTab: Tabs;
    }
    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'toms-selected-tab',
        {
            activeTab: Tabs.TOM,
        },
    );
}
