import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import * as ClearanceEditState from '../state/ClearanceEditState.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';

export const receiveClearanceDetail = AsyncRequest.request(
    Http.OperatorAccount.Clearances.detail,
    (store: Flux.Store, res: Response): string => {
        ClearanceEditState.Server.setResponse(store, res);
        return 'ClearanceDetailActions-receiveClearanceDetail';
    },
);

export const editClearance = AsyncRequest.request(
    Http.OperatorAccount.Clearances.edit,
    (store: Flux.Store, res: Response): string => {
        ClearanceEditState.EditResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            ClearanceEditState.Edit.reset(store);
            ClearanceEditState.Server.setResponse(store, res);
            ResultState.refetchSameContext(store, true);
        }
        return 'ClearanceDetailActions-editClearance';
    },
);

export const editStateToPayload = (
    edit: ClearanceEditState.Edit.State,
): Http.OperatorAccount.Clearances.Changes => {
    return {
        validFrom: thenElse(
            edit.validFrom,
            validFrom => Formatter.isoYearMonthDay(validFrom),
            undefined,
        ),
        validTo: thenElse(
            edit.validTo,
            validTo => Formatter.isoYearMonthDay(validTo),
            undefined,
        ),
        contractNumber: edit.contractNumber,
        personalNumber: edit.personalNumber,
        infos: edit.infos,
    };
};

export const deselectClearance = (store: Flux.Store): string => {
    ClearanceEditState.Server.reset(store);
    new ClearancePermitListState.StateSlice(store).selectClearance(null);
    ClearanceEditState.resetAllStates(store);
    return 'ClearanceDetailActions-deselectPermit';
};
