import moment from 'moment';

import * as SettingsState from '../../../common/state/SettingsState.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined, isUndefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from '../../../common/i18n/Text.ts';
import { ButtonSpecialState } from '../../../ui/buttons/IconButton.tsx';
import { LabeledText } from '../../../ui/labeled-elements/LabeledText.tsx';
import { TextField } from '../../../ui/labeled-elements/TextField.tsx';
import {
    ConfirmSaveHeader,
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
} from '../../../ui/slidein/Slidein.tsx';
import * as OutOfServiceActions from '../../actions/OutOfServiceActions.ts';
import { outOfServiceTexts } from '../../i18n/TomTexts.ts';
import * as TomLayoutState from '../../state/TomLayoutState.ts';
import * as TomState from '../../state/TomState.ts';
import { OutOfServiceDateSelection } from './OutOfServiceDateHalfSlideIn.tsx';
import { OutOfServiceMessage } from './OutOfServiceMessage.tsx';
import { css } from '@emotion/css';

export interface OutOfServiceTexts {
    OutOfService: Translation;
    PlanOutOfService: Translation;
    OutOfServiceDateSlideInTitle: Translation;
    StartDate: Translation;
    EndDate: Translation;
    MessageDateConstraints: Translation;
    CustomMessageToggle: Translation;
    MessageTitle: Translation;
    MessageTitleConstraints: Translation;
    MessageBody: Translation;
    MessageBodyConstraints: Translation;
    OutOfServiceMessagePreview: Translation;
    OutOfServiceTitleDefault: Translation;
    OutOfServiceBodyDefault: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: TomLayoutState.Layout.State;
    form: TomState.OutOfService.Form.State;
    response: TomState.OutOfService.Response.State;
}

export class OutOfServiceHalfSlideIn extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: TomLayoutState.Layout.get(this.props.allState),
            form: TomState.OutOfService.Form.get(this.props.allState),
            response: TomState.OutOfService.Response.get(this.props.allState),
        };
    }

    isStartDateValid(): boolean {
        return (
            isDefined(this.state.form.startDate) &&
            this.state.form.startDate.isAfter()
        );
    }

    isEndDateValid(): boolean {
        // in current implementation both are required. otherwise it would be impossible to
        // reactivate a tom if no end date was selected
        return (
            isDefined(this.state.form.endDate) &&
            isDefined(this.state.form.startDate) &&
            this.state.form.endDate.isAfter(this.state.form.startDate)
        );
    }

    isTitleValid(): boolean {
        // max 2 lines of 15 chars each or empty
        if (isUndefined(this.state.form.title)) {
            return true;
        }
        const lines = this.state.form.title.split('\n');
        return lines.length <= 2 && lines.every(l => l.length <= 15);
    }

    isBodyValid(): boolean {
        // max 3 lines of 24 chars each or empty
        if (isUndefined(this.state.form.body)) {
            return true;
        }
        const lines = this.state.form.body.split('\n');
        return lines.length <= 3 && lines.every(l => l.length <= 24);
    }

    isFormValid(): boolean {
        return (
            this.isStartDateValid() &&
            this.isEndDateValid() &&
            this.isTitleValid() &&
            this.isBodyValid()
        );
    }

    hasChanges(): boolean {
        return (
            isDefined(this.state.form.startDate) ||
            isDefined(this.state.form.endDate) ||
            isDefined(this.state.form.title) ||
            isDefined(this.state.form.body)
        );
    }

    confirmButtonSpecialState(): Maybe<ButtonSpecialState> {
        if (this.state.response.pending) {
            return ButtonSpecialState.DISABLED;
        }
        if (
            !this.isFormValid() &&
            this.state.layout.outOfServiceConfirmPressedOnError
        ) {
            return ButtonSpecialState.ERROR;
        }
        if (!this.isFormValid()) {
            return ButtonSpecialState.DISABLED;
        } else {
            return null;
        }
    }

    renderHeader(): JSX.Element {
        const lng = this.state.settings.language;
        const txt = outOfServiceTexts[lng];

        if (this.state.response.pending) {
            return <LoaderHeader title={txt.PlanOutOfService()} />;
        }

        return (
            <ConfirmSaveHeader
                language={this.state.settings.language}
                title={txt.PlanOutOfService()}
                onCancel={() =>
                    this.update(OutOfServiceActions.cancelOutOfService)
                }
                onSave={() => {
                    if (
                        !this.isFormValid() ||
                        !this.state.layout.selectedTomId
                    ) {
                        this.update(store =>
                            TomLayoutState.Layout.stateWrite(store, {
                                outOfServiceConfirmPressedOnError: true,
                            }),
                        );
                    } else {
                        this.update(OutOfServiceActions.setOutOfService, {
                            tomId: this.state.layout.selectedTomId,
                            startDate: Formatter.isoYearMonthDay(
                                this.state.form.startDate ||
                                    moment('1970-01-01'),
                            ),
                            endDate: Formatter.isoYearMonthDay(
                                this.state.form.endDate || moment('1970-01-01'),
                            ),
                            title: this.state.form.title,
                            body: this.state.form.body,
                        });
                    }
                }}
                confirmButtonSpecialState={this.confirmButtonSpecialState()}
            />
        );
    }

    render() {
        const lng = this.state.settings.language;
        const txt = outOfServiceTexts[lng];

        if (this.state.layout.outOfServiceOpen) {
            const showErrors =
                this.state.layout.outOfServiceConfirmPressedOnError;
            return (
                <HalfSlideIn open={true}>
                    <SlideInBody>
                        <div>
                            <OutOfServiceDateSelection
                                isValid={
                                    this.isStartDateValid() &&
                                    this.isEndDateValid()
                                }
                                showErrors={showErrors}
                                allState={this.props.allState}
                            />

                            <div
                                className={css({
                                    textarea: {
                                        height: '48px',
                                    },
                                })}
                            >
                                <TextField
                                    value={this.state.form.title || ''}
                                    label={txt.MessageTitle()}
                                    onChange={v =>
                                        this.update(store =>
                                            TomState.OutOfService.Form.stateWrite(
                                                store,
                                                { title: v as string },
                                            ),
                                        )
                                    }
                                    inputType="text"
                                    multiline={true}
                                    errorText={
                                        showErrors && !this.isTitleValid()
                                            ? txt.MessageTitleConstraints()
                                            : null
                                    }
                                />
                            </div>
                            <div
                                className={css({
                                    textarea: {
                                        height: '72px',
                                    },
                                })}
                            >
                                <TextField
                                    value={this.state.form.body || ''}
                                    label={txt.MessageBody()}
                                    onChange={v =>
                                        this.update(store =>
                                            TomState.OutOfService.Form.stateWrite(
                                                store,
                                                { body: v as string },
                                            ),
                                        )
                                    }
                                    inputType="text"
                                    multiline={true}
                                    errorText={
                                        showErrors && !this.isBodyValid()
                                            ? txt.MessageBodyConstraints()
                                            : null
                                    }
                                />
                            </div>
                            <div className={css({ marginTop: '20px' })}>
                                <LabeledText
                                    label={txt.OutOfServiceMessagePreview()}
                                >
                                    <OutOfServiceMessage
                                        title={this.state.form.title}
                                        body={this.state.form.body}
                                        language={lng}
                                    />
                                </LabeledText>
                            </div>
                        </div>
                    </SlideInBody>
                    {this.renderHeader()}
                </HalfSlideIn>
            );
        } else {
            return <HalfSlideIn open={false} />;
        }
    }
}
