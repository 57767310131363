import { Translations } from '../../common/i18n/Text.ts';
import * as ClearanceEdit from '../components/ClearanceEdit.tsx';

/* tslint:disable:max-line-length */

export const texts: Translations<ClearanceEdit.Texts> = {
    de: {
        editCaption: () => 'Freigabe bearbeiten',
        issued: () => 'Ausgestellt',
        licenseplate: () => 'Kennzeichen',
        badge: () => 'Badge',
        PriceMultiplier: () => 'Preiskategorie',
        deleteConfirmation: () => 'Diese Freigabe wirklich löschen?',
        unableToDelete: () =>
            'Diese Freigabe kann nicht gelöscht werden, weil bereits mindestens eine Bewilligung vorhanden ist.',
        deletePending: () => 'Freigabe wird gelöscht…',
        deleteError: () => 'Fehler beim Löschen der Freigabe',
        permitType: () => 'Bewilligungsart',
    },
    fr: {
        editCaption: () => 'Modifier approbation',
        issued: () => 'Emise',
        licenseplate: () => 'Immatriculation',
        badge: () => 'Badge',
        PriceMultiplier: () => 'Catégorie de prix',
        deleteConfirmation: () => 'Effacer vraiment cette approbation?',
        unableToDelete: () =>
            'Cette approbation ne peut pas être effacée, comme au moins une autorisation est présente.',
        deletePending: () => "L'approbation sera effacée…",
        deleteError: () => "Erreur pendant l'effacement de l'approbation",
        permitType: () => "Type d'autorisation",
    },
    it: {
        editCaption: () => 'Modifica approvazione',
        issued: () => 'Emessa',
        licenseplate: () => 'Targa',
        badge: () => 'Badge',
        PriceMultiplier: () => 'Categoria di prezzo',
        deleteConfirmation: () => 'Eliminare veramente questa approvazione?',
        unableToDelete: () =>
            "Questa approvazione non può essere eliminata, in quanto esiste almeno un'autorizzazione.",
        deletePending: () => "L'approvazione viene eliminata…",
        deleteError: () => "Errore durante l'eliminazione dell'approvazione",
        permitType: () => "Tipo d'autorizzazione",
    },
};
