import { Texts } from '../components/Shared.tsx';
import { Translations } from '../../common/i18n/Text.ts';
/* tslint:disable:typedef max-line-length */

export const texts: Translations<Texts> = {
    de: {
        AdditionalInfos: () => 'Zusatzinformationen',
        AddLpTooltip: () => 'Kennzeichen hinzufügen',
        LicensePlateMissing: () => 'Geben Sie mindestens ein Kennzeichen ein',
        LicensePlates: () => 'Kennzeichen',
        Remark: () => 'Interne Bemerkungen',
        RemoveLpTooltip: () => 'Kennzeichen entfernen',
        Badges: () => 'Badges',
        AddBadgeTooltip: () => 'Badge hinzufügen',
        RemoveBadgeTooltip: () => 'Badge entfernen',
        BadgeMissing: () => 'Erfassen Sie mindestens einen Badge',
    },
    fr: {
        AdditionalInfos: () => 'Informations complémentaires',
        AddLpTooltip: () => 'Ajouter immatriculation',
        LicensePlateMissing: () => 'Saisir au mois une immatriculation',
        LicensePlates: () => 'Immatriculations',
        Remark: () => 'Remarques internes',
        RemoveLpTooltip: () => 'Enlever immatriculation',
        Badges: () => 'Badges',
        AddBadgeTooltip: () => 'Ajouter badge',
        RemoveBadgeTooltip: () => 'Enlever badge',
        BadgeMissing: () => 'Saisir au moins un badge',
    },
    it: {
        AdditionalInfos: () => 'Informazioni aggiuntive',
        AddLpTooltip: () => 'Aggiungi targa',
        LicensePlateMissing: () => 'Inserire almeno una targa',
        LicensePlates: () => 'Targhe',
        Remark: () => 'Osservazioni interne',
        RemoveLpTooltip: () => 'Rimuovi targa',
        Badges: () => 'Badges',
        AddBadgeTooltip: () => 'Aggiungi badge',
        RemoveBadgeTooltip: () => 'Rimuovi badge',
        BadgeMissing: () => 'Registrare almeno un badge',
    },
};

/* tslint:enable:typedef max-line-length */
