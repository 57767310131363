import { isDefined } from './MaybeV2';

export type ParamsObject = { [idx: string]: string | string | null | boolean };

export const stringifyParams = (params: ParamsObject): string => {
    return Object.keys(params)
        .filter(k => isDefined(params[k]))
        .map((k: string) => `${k}=${params[k]}`)
        .join('&');
};
