import { Translations } from '../../common/i18n/Text.ts';
import { SyncAppTexts } from '../components/SyncAppSlideIn.tsx';

/* tslint:disable:max-line-length */
export const syncAppTexts: Translations<SyncAppTexts> = {
    de: {
        AppIntro: () =>
            'Um Daten vom Parkingportal auf eine Parkuhr und wieder zurück zu synchronisieren, wird die Parkingportal Sync Applikation benötigt. Diese können Sie mit untenstehendem Link herunterladen und installieren.',
        AutoUpdate: () => 'Automatische Updates',
        ConfigureUsbSticks: () => 'Aktualisierung von USB Sticks',
        Download: () => 'Herunterladen',
        DownloadLatestVersion: () => 'Neuste Version herunterladen',
        Functionality: () => 'Funktionen',
        IdentifyUsbStick: () => 'USB Stick Identifizierung',
        ImportCollectionData: () => 'Import von Leerungsdaten',
        InstallationAndDownload: () => 'Installation und Download',
        LatestVersion: () => 'Neuste Version',
        NotInstalledHint: () =>
            'Sie haben einen Link verwendet, für den die Parkingportal Sync App notwendig ist. Installieren Sie diese um alle Funktionen verwenden zu können.',
        SlideInHeaderCaption: () => 'Parkingportal Sync Applikation',
        SyncApp: () => 'Parkingportal Sync Applikation',
        SyncAppRequired: () => 'Parkingportal Sync Applikation notwendig',
        SystemRequirementsLabel: () => 'Systemanforderungen',
        SystemRequirementsValue: () => 'Windows 7 x86/x64 oder neuer',
        UpdateTomConfigurations: () =>
            'Aktualisierung von TOMeco Konfigurationen',
        UsageInfo: () =>
            'Die Parkinportal Sync App öffnet automatisch wenn ein entsprechender Link im Portal geklickt wird. Wählen Sie danach ihren Leerungs- oder Unterhaltsschlüssel aus der Liste um die Aktion abzuschliessen.',
    },
    en: {
        AppIntro: () =>
            'To synchronize data between the Parkingportal and any parking meter, the Parkingportal Sync Application is required. You can download and install it using the link below.',
        AutoUpdate: () => 'Automatic updates',
        ConfigureUsbSticks: () => 'USB stick updates',
        Download: () => 'Download',
        DownloadLatestVersion: () => 'Download newest version',
        Functionality: () => 'Functionality',
        IdentifyUsbStick: () => 'USB stick identification',
        ImportCollectionData: () => 'Collection data import',
        InstallationAndDownload: () => 'Installation and Download',
        LatestVersion: () => 'Newset Version',
        NotInstalledHint: () =>
            'You clicked a link that requires the Parkingportal Sync application. Install it to use the entire functionality of the Parkingportal.',
        SlideInHeaderCaption: () => 'Parkingportal Sync application',
        SyncApp: () => 'Parkingportal Sync application',
        SyncAppRequired: () => 'Parkingportal Sync application required',
        SystemRequirementsLabel: () => 'System Requirements',
        SystemRequirementsValue: () => 'Windows 7 x86/x64 or newer',
        UpdateTomConfigurations: () => 'TOMeco configuration updates',
        UsageInfo: () =>
            'The Parkingportal Sync Application opens automatically when a respective link is clicked in the Parkingportal. Afterwards you can choose your Collection- or Maintenance-Stick from a list to complete the action.',
    },
    fr: {
        AppIntro: () =>
            "Synchroniser des données du Parkingportal vers un horodateur et vice-versa nécessite l'application Parkingportal Sync que vous pouvez télécharger et installer via le lien ci-dessous.",
        AutoUpdate: () => 'Mises à jour automatiques',
        ConfigureUsbSticks: () => 'Actualisation de clés USB',
        Download: () => 'Télécharger',
        DownloadLatestVersion: () => 'Télécharger la dernière version',
        Functionality: () => 'Fonctions',
        IdentifyUsbStick: () => 'Identification de la clé USB',
        ImportCollectionData: () => 'Importation de données de collecte',
        InstallationAndDownload: () => 'Installation et téléchargement',
        LatestVersion: () => 'Dernière version',
        NotInstalledHint: () =>
            "Vous avez utilisé un lien nécessitant l'application Parkingportal Sync. Installez cette dernière pour pouvoir utiliser toutes les fonctions.",
        SlideInHeaderCaption: () => 'Application Parkingportal Sync',
        SyncApp: () => 'Application Parkingportal Sync',
        SyncAppRequired: () => 'Application Parkingportal Sync requise',
        SystemRequirementsLabel: () => 'Configuration requise',
        SystemRequirementsValue: () => 'Windows 7 x86/x64 ou plus récent',
        UpdateTomConfigurations: () =>
            'Actualisation des configurations TOMeco',
        UsageInfo: () =>
            "L'application Parkinportal Sync s'ouvre automatiquement en cas de clic sur un lien correspondant dans le portail. Sélectionnez ensuite votre clé de collecte ou de maintenance dans la liste pour terminer l'opération.",
    },
    it: {
        AppIntro: () =>
            "Per sincronizzare i dati che vanno dalla piattaforma Parkingportal a un parchimetro e viceversa, si utilizza l'applicazione Parkingportal Sync, da scaricare e installare tramite il seguente link.",
        AutoUpdate: () => 'Aggiornamento automatico',
        ConfigureUsbSticks: () => 'Aggiornamento chiavette USB',
        Download: () => 'Scaricare',
        DownloadLatestVersion: () => "Scaricare l'ultima versione",
        Functionality: () => 'Funzioni',
        IdentifyUsbStick: () => 'Identificazione chiavetta USB',
        ImportCollectionData: () => 'Importazione dei dati di vuotatura',
        InstallationAndDownload: () => 'Installazione e download',
        LatestVersion: () => 'Ultima versione',
        NotInstalledHint: () =>
            "È stato selezionato un link per il quale è necessaria l'applicazione Parkingportal Sync. Installare l'applicazione per poter utilizzare tutte le funzioni.",
        SlideInHeaderCaption: () => 'Applicazione Parkingportal Sync',
        SyncApp: () => 'Applicazione Parkingportal Sync',
        SyncAppRequired: () => 'Necessaria applicazione Parkingportal Sync',
        SystemRequirementsLabel: () => 'Requisiti di sistema',
        SystemRequirementsValue: () =>
            'Windows 7 x86/x64 o versione successiva',
        UpdateTomConfigurations: () =>
            'Aggiornamento delle configurazioni TOMeco',
        UsageInfo: () =>
            "L'applicazione Parkingportal Sync si apre in automatico cliccando sul link corrispondente nel portale. Selezionare la chiave di vuotatura o manutenzione per terminare l'operazione.",
    },
};
/* tslint:enable:max-line-length */
