import { css } from '@emotion/css';

import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { IconButton24pxType } from '../buttons/IconButton.tsx';
import {
    ActionButton,
    ActionButtons,
    ActionButtonsContainer,
    BelowContent,
    Content,
    HoverableBlock,
    Label,
    LabeledElement,
} from './Internal.tsx';

interface MiniTableProps {
    label: string;
    actionButtons: boolean;
    labelRightText?: string;
    children?: React.ReactNode;
}

export const MiniTable = (p: MiniTableProps): JSX.Element => {
    return (
        <LabeledElement
            actionButtons={
                p.actionButtons ? ActionButtons.one : ActionButtons.none
            }
        >
            <Label
                label={p.label}
                focused={false}
                hovered={false}
                rightText={p.labelRightText}
            />
            {p.children}
        </LabeledElement>
    );
};

const miniTableCss = css({
    position: 'relative',
});

interface MiniTableElementProps {
    focused?: boolean;
    onAction?: () => void;
    onLineItemClick?: () => void;
    actionButton?: IconButton24pxType;
    actionTooltip?: string;
    children?: React.ReactNode;
}

export class MiniTableElement extends HoverableBlock<MiniTableElementProps> {
    static defaultProps: MiniTableElementProps = { focused: false };
    noActionButton(): boolean {
        return !this.props.actionButton || !this.props.onAction;
    }

    renderChevron(): JSX.Element | null {
        if (!!this.props.onLineItemClick && !this.state.actionHovered) {
            return Icons16.chevronRight;
        } else {
            return null;
        }
    }

    isHoverState(): boolean {
        if (this.noActionButton() && !this.props.onLineItemClick) {
            return false;
        }

        if (!this.props.onLineItemClick) {
            return this.state.actionHovered as boolean;
        } else {
            return this.state.itemHovered as boolean;
        }
    }

    renderActionButton(): JSX.Element | null {
        const actionButton = this.props.actionButton;
        const onAction = this.props.onAction;
        if (actionButton === null || actionButton === undefined || !onAction) {
            return null;
        } else {
            return (
                <ActionButtonsContainer>
                    <ActionButton
                        onMouseOver={(): void =>
                            this.setState({ actionHovered: true })
                        }
                        onMouseOut={(): void =>
                            this.setState({ actionHovered: false })
                        }
                        type={actionButton}
                        tooltip={this.props.actionTooltip}
                        onClick={onAction}
                    />
                </ActionButtonsContainer>
            );
        }
    }

    render() {
        return (
            <Clickable
                className={miniTableCss}
                element="div"
                disabled={!this.props.onLineItemClick}
                onMouseOver={() => this.setState({ itemHovered: true })}
                onMouseOut={() => this.setState({ itemHovered: false })}
                onClick={this.props.onLineItemClick}
            >
                <Content
                    icon={this.renderChevron()}
                    focused={this.props.focused || false}
                    userSelectable={false}
                    hovered={this.isHoverState()}
                    empty={false}
                >
                    {this.props.children}
                    {this.renderActionButton()}
                </Content>
                <BelowContent
                    underline={
                        !!this.props.onLineItemClick || !this.noActionButton()
                    }
                    focused={this.props.focused || false}
                    hovered={this.isHoverState()}
                    errorText={null}
                />
            </Clickable>
        );
    }
}

export function MiniTableActionOnlyElement(props: {
    actionButton: IconButton24pxType;
    onAction?: () => void;
    showUnderline: boolean;
    errorText?: string;
    actionTooltip?: string;
    disabled?: boolean;
}): JSX.Element {
    return (
        <div className={miniTableCss}>
            <Content
                focused={false}
                userSelectable={false}
                hovered={false}
                empty={false}
            >
                {props.showUnderline && (
                    <>
                        —
                        {!props.disabled && (
                            <MiniTableActionOnlyButton {...props} />
                        )}
                    </>
                )}
            </Content>

            {!props.showUnderline && !props.disabled && (
                <MiniTableActionOnlyButton {...props} />
            )}

            <BelowContent
                underline={props.showUnderline}
                focused={false}
                hovered={false}
                errorText={props.errorText}
            />
        </div>
    );
}

function MiniTableActionOnlyButton(props: {
    actionButton: IconButton24pxType;
    onAction?: () => void;
    actionTooltip?: string;
}) {
    return (
        <ActionButtonsContainer>
            <ActionButton
                type={props.actionButton}
                onClick={props.onAction}
                tooltip={props.actionTooltip}
                verticalAlignment="69"
            />
        </ActionButtonsContainer>
    );
}
