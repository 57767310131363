import { Duration } from 'dg-web-shared/dto/PermitTimeUnit.ts';
import { timeUnitTexts } from '../i18n/PermitCreateTexts.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';

export interface Texts {
    Hours: (i: number) => string;
    Days: (i: number) => string;
    Weeks: (i: number) => string;
    Months: (i: number) => string;
    Years: (i: number) => string;
}

export const getDurationFromId = (
    uniqueId: Maybe<string>,
    durations: Duration[],
): Maybe<Duration> => {
    if (!uniqueId) {
        return null;
    }
    return durations.filter(d => d.uniqueId === uniqueId)[0];
};

export const getDurationString = (duration: Duration, lang: string): string => {
    switch (duration.type) {
        case 'hour':
            return timeUnitTexts[lang].Hours(duration.quantity);
        case 'day':
            return timeUnitTexts[lang].Days(duration.quantity);
        case 'week':
            return timeUnitTexts[lang].Weeks(duration.quantity);
        case 'month':
            return timeUnitTexts[lang].Months(duration.quantity);
        case 'year':
            return timeUnitTexts[lang].Years(duration.quantity);
        default:
            return '';
    }
};
