import { InputContext } from '../inputs/InputContext';
import { css } from '@emotion/css';
import { Colors } from '../../ui/vars';
import React from 'react';
import { Icon } from '../../ui/icons/Icon';
import { Clickable, ClickHandler } from '../../ui/Clickable';
import { Typo } from '../../ui/typo';

export enum ToggleType {
    checkbox = 1,
    radio,
}

interface Props {
    type: ToggleType;
    context?: InputContext;
    label: React.ReactChild;
    caption?: JSX.Element | string | null;
    disabled?: boolean;
    selected: boolean;
    error?: boolean;
    onClick: ClickHandler;
    key?: React.Key;
}

function getIcon(type: ToggleType, selected: boolean): string {
    if (type === ToggleType.checkbox) {
        return selected ? 'checkbox' : 'checkboxOutline';
    }
    if (type === ToggleType.radio) {
        return selected ? 'radioButtonChecked' : 'radioButtonUnchecked';
    }
    return 'checkbox';
}

export class LabeledToggle extends React.Component<Props> {
    render() {
        return (
            <Clickable
                element="div"
                className={style}
                disabled={this.props.disabled || false}
                onClick={this.props.onClick}
            >
                <div
                    className={iconStyle}
                    data-selected={this.props.selected}
                    data-disabled={this.props.disabled || false}
                    data-context={
                        InputContext[this.props.context || InputContext.form]
                    }
                    data-error={this.props.error || false}
                >
                    <Icon
                        icon={getIcon(this.props.type, this.props.selected)}
                    />
                </div>
                <div className={textStyle}>
                    <div
                        className={`${labelCaptionStyle} ${labelStyle}`}
                        data-selected={this.props.selected}
                        data-disabled={this.props.disabled || false}
                        data-context={
                            InputContext[
                                this.props.context || InputContext.form
                            ]
                        }
                        data-error={this.props.error || false}
                    >
                        {this.props.label}
                    </div>
                    <div
                        className={`${labelCaptionStyle} ${captionStyle}`}
                        data-selected={this.props.selected}
                        data-disabled={this.props.disabled || false}
                        data-context={
                            InputContext[
                                this.props.context || InputContext.form
                            ]
                        }
                    >
                        {this.props.caption}
                    </div>
                </div>
            </Clickable>
        );
    }
}

export const Factory = React.createFactory(LabeledToggle);

const style = css`
    position: relative;

    &[data-disabled='false'] {
        cursor: pointer;
    }
`;

const labelCaptionStyle = css`
    font-family: ${Typo.robotoRegular.fontFamily};
    font-weight: ${Typo.robotoRegular.fontWeight};
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.015em;
`;

const iconStyle = css`
    position: absolute;
    left: -2px;
    top: 16px;
    color: ${Colors.blue};

    &[data-selected='false'][data-context='regular'] {
        color: ${Colors.rgba(Colors.blue, 0.6)};
    }

    &[data-selected='false'][data-context='form'] {
        color: ${Colors.rgba(Colors.action_f, 0.6)};
    }

    &[data-selected='false'][data-context='inverted'] {
        color: ${Colors.rgba(Colors.action_b, 0.6)};
    }

    &[data-selected='false'][data-context='quickcheckout'] {
        color: ${Colors.rgba(Colors.white, 0.6)};
    }

    &[data-selected='true'][data-context='regular'] {
        color: ${Colors.rgba(Colors.blue, 0.8)};
    }

    &[data-selected='true'][data-context='form'] {
        color: ${Colors.rgba(Colors.action_f, 0.8)};
    }

    &[data-selected='true'][data-context='inverted'] {
        color: ${Colors.rgba(Colors.action_b, 0.8)};
    }

    &[data-selected='true'][data-context='quickcheckout'] {
        color: ${Colors.rgba(Colors.white, 0.8)};
    }

    &[data-disabled='true'][data-context='regular'] {
        color: ${Colors.rgba(Colors.blue, 0.4)};
    }

    &[data-disabled='true'][data-context='form'] {
        color: ${Colors.rgba(Colors.action_f, 0.4)};
    }

    &[data-disabled='true'][data-context='inverted'] {
        color: ${Colors.rgba(Colors.action_b, 0.4)};
    }

    &[data-disabled='true'][data-context='quickcheckout'] {
        color: ${Colors.rgba(Colors.white, 0.4)};
    }

    & [data-error='true'] {
        color: ${Colors.error};
    }
`;

const textStyle = css`
    padding-top: ${20 - Typo.smallTopCorrection}px;
    padding-left: 40px;
    padding-right: 32px;
    padding-bottom: 8px;
`;

const labelStyle = css`
    padding-top: 3px;

    &[data-context='regular'] {
        color: ${Colors.blue};
    }

    &[data-context='form'] {
        color: ${Colors.action_f};
    }

    &[data-context='inverted'] {
        color: ${Colors.action_b};
    }

    &[data-disabled='true'][data-context='regular'] {
        color: ${Colors.rgba(Colors.blue, 0.4)};
    }

    &[data-disabled='true'][data-context='form'] {
        color: ${Colors.rgba(Colors.action_f, 0.4)};
    }

    &[data-disabled='true'][data-context='inverted'] {
        color: ${Colors.rgba(Colors.action_b, 0.4)};
    }

    &[data-disabled='true'][data-context='quickcheckout'] {
        color: ${Colors.rgba(Colors.white, 0.4)};
    }

    & a {
        text-decoration: none;
        color: ${Colors.blue};
    }

    &[data-error='true'] {
        color: ${Colors.error};
    }

    &[data-error='true'] a {
        text-decoration: none;
        color: ${Colors.error};
    }
`;

const captionStyle = css`
    margin-top: 1px;

    &[data-context='regular'] {
        color: ${Colors.rgba(Colors.blue, 0.6)};
    }

    &[data-context='form'] {
        color: ${Colors.rgba(Colors.action_f, 0.6)};
    }

    &[data-context='inverted'] {
        color: ${Colors.rgba(Colors.action_b, 0.6)};
    }

    &[data-context='quickcheckout'] {
        color: ${Colors.rgba(Colors.white, 0.6)};
    }

    &[data-disabled='true'][data-context='regular'] {
        color: ${Colors.rgba(Colors.blue, 0.3)};
    }

    &[data-disabled='true'][data-context='form'] {
        color: ${Colors.rgba(Colors.action_f, 0.3)};
    }

    &[data-disabled='true'][data-context='inverted'] {
        color: ${Colors.rgba(Colors.action_b, 0.3)};
    }

    &[data-disabled='true'][data-context='quickcheckout'] {
        color: ${Colors.rgba(Colors.white, 0.3)};
    }
`;
