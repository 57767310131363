export const enum PaymentChannel {
    COOP = 'COOP',
    EASYPARK = 'EASYPARK',
    PARKINGPAY = 'PARKINGPAY',
    PARKINGPORTAL_COUNTER = 'PARKINGPORTAL_COUNTER',
    TWINT = 'TWINT',
    SWISSPASS = 'SWISSPASS',
    TCS = 'TCS',
    PARK_AND_RAIL_WEBVIEW = 'PARK_AND_RAIL_WEBVIEW',
    PARKINGABO = 'PARKINGABO',
    PARKINGABO_COUNTER = 'PARKINGABO_COUNTER',
    QUICKCHECKOUT = 'QUICKCHECKOUT',
    FZAG_PORTAL = 'FZAG_PORTAL',
}
