import { Translation, TranslationWithArgs1 } from '../../common/i18n/Text.ts';
import {
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import { tariffTexts } from '../i18n/TariffTexts.ts';
import * as TariffState from '../state/TariffState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Body } from '../../ui/layout/TabContent.tsx';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import * as Tariff from 'dg-web-shared/model/Tariff.ts';
import { TariffDetailSlideIn } from './TariffDetailSlideIn.tsx';
import { TariffCalculatorDateSlideIn } from './TariffCalculatorDateSlideIn.tsx';
import * as TariffDefinition from 'dg-web-shared/common/tariff-logic/TariffDefinition.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { Icon16 } from '../../ui/icons/Icon.tsx';

export interface TariffTexts {
    Tariff: Translation;
    TariffCard: Translation;
    Name: Translation;
    Holidays: Translation;
    NoHolidays: Translation;
    InheritMode: Translation;
    InheritModeValue: TranslationWithArgs1<TariffDefinition.InheritMode>;
    InheritModeReset: Translation;
    InheritModeInheritTime: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: TariffState.Layout.State;
    tariffs: TariffState.List.State;
}

interface ItemProps {
    allState: Flux.Store;
    tariff: Tariff.TariffData;
    language: string;
}

const TariffItem = (p: ItemProps): JSX.Element => {
    const openSlideIn = () => {
        p.allState.update((store: Flux.Store): string => {
            TariffState.Calculator.reset(store);
            TariffState.Layout.stateWrite(store, {
                selectedTariffId: p.tariff.tariffId,
            });
            return 'TariffState-openDetailSlideIn';
        });
    };

    return (
        <DefaultTableRow onClick={openSlideIn}>
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth.variable}>
                {p.tariff.name}
            </TableColumn>
            <TableColumn width={ColumnWidth._24px} captionFontSize>
                <IconTopOffsetContainer>
                    <Icon16 icon={Icons16.chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </DefaultTableRow>
    );
};

export class TariffsBody extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: TariffState.Layout.get(this.props.allState),
            tariffs: TariffState.List.get(this.props.allState),
        };
    }

    getDependingStateSlices(): AbstractLegacyServerState<any>[] {
        return [this.state.tariffs];
    }

    renderSlideIns(): JSX.Element[] {
        return [
            <TariffDetailSlideIn
                allState={this.props.allState}
                key="tariff-detail-slideIn"
            />,
            <TariffCalculatorDateSlideIn
                allState={this.props.allState}
                key="tariff-calculator-date-slideIn"
            />,
        ];
    }

    isBodyDisabled(): boolean {
        return (
            this.state.layout.dateSelectionOpen ||
            isDefined(this.state.layout.selectedTariffId)
        );
    }

    render() {
        const txt = tariffTexts[this.state.settings.language];
        return (
            <Body
                disabled={this.isBodyDisabled()}
                dependingStateSlices={this.getDependingStateSlices()}
                slideIns={this.renderSlideIns()}
            >
                <Table>
                    <TableHeader>
                        <EmptyTableHeaderColumn
                            width={ColumnWidth._24px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.Name()}
                            direction={SortDirection.Ascending}
                            selected={true}
                            onClick={null}
                            width={ColumnWidth.variable}
                            delimiterBottom={true}
                        />
                        <EmptyTableHeaderColumn
                            width={ColumnWidth._24px}
                            delimiterBottom={true}
                        />
                    </TableHeader>
                    <TableBody>
                        {this.state.tariffs.data.map((t: Tariff.TariffData) => {
                            return (
                                <TariffItem
                                    tariff={t}
                                    language={this.state.settings.language}
                                    allState={this.props.allState}
                                    key={t.tariffId}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </Body>
        );
    }
}
