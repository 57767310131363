import moment from 'moment';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import {
    isDefinedAndDiffersFrom,
    isUndefined,
    Maybe,
} from 'dg-web-shared/lib/MaybeV2.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    FullTomAtTime,
    ParsedTom,
    parseFullTom,
    parseTom,
} from 'dg-web-shared/model/Tom.ts';

export namespace List {
    export type State = ServerStateSlice.ServerState<ParsedTom[]>;

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<ParsedTom[]>(
            'TomState-Toms',
            () => [],
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(fetchToms);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (body: any): ParsedTom[] => {
                if (isUndefined(body)) {
                    return [];
                } else {
                    return body.map(parseTom);
                }
            },
        );

    const fetchToms = AsyncRequest.request(
        Http.OperatorAccount.Toms.get,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            return 'TomState-fetchToms';
        },
    );
}

export function getTomName(toms: ParsedTom[], tomId?: Maybe<number>): string {
    if (isUndefined(tomId)) {
        return '';
    }

    for (const t of toms) {
        if (t.tomId === tomId) {
            return (
                (t.operatorExternalId ? t.operatorExternalId : t.tomId) +
                ' - ' +
                t.name
            );
        }
    }
    return '';
}

export namespace Detail {
    export interface Context {
        tomId: number;
    }

    export type State =
        ContextualServerStateSlice.ContextualState<FullTomAtTime>;

    export const { get, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<
            Context,
            FullTomAtTime
        >({
            key: 'TomState-TomDetail',
            requestGenerator: (context: Context) =>
                Http.OperatorAccount.Toms.getFull(context),
            request: AsyncRequest.request,
            parseBody: parseFullTom,
        });
}

export namespace Edit {
    export interface State {
        name?: Maybe<string>;
        operatorExternalId?: Maybe<string>;
        street?: Maybe<string>;
        zipCode?: Maybe<string>;
        place?: Maybe<string>;
    }

    export const hasChanges = (s: State, tom: ParsedTom): boolean => {
        return (
            isDefinedAndDiffersFrom(s.name, tom.name) ||
            isDefinedAndDiffersFrom(
                s.operatorExternalId,
                tom.operatorExternalId,
            ) ||
            isDefinedAndDiffersFrom(s.street, tom.street) ||
            isDefinedAndDiffersFrom(s.zipCode, tom.zipCode) ||
            isDefinedAndDiffersFrom(s.place, tom.place)
        );
    };

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'TomState-TomDetailSlideIn-Edit',
        {},
    );
}

export namespace EditBaseDataResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'TomState-EditBaseDataResponse',
        () => null,
    );
}

export namespace OutOfService {
    export namespace Form {
        export interface State {
            startDate?: Maybe<moment.Moment>;
            endDate?: Maybe<moment.Moment>;
            title?: Maybe<string>;
            body?: Maybe<string>;
        }

        export const { get, reset, stateWrite } = Flux.generateState<State>(
            'toms-out-of-service-form',
            {},
        );
    }

    export namespace Response {
        export type State = WriteStateSlice.State<void>;
        export const { get, reset, setResponse } = WriteStateSlice.generate(
            'toms-out-of-service-Response',
            () => null,
        );
    }
}
