import { Translation, Translations } from './Text.ts';

export interface Texts {
    MandatoryField: Translation;
}

export const general: Translations<Texts> = {
    de: {
        MandatoryField: () => 'Pflichtfeld',
    },
    fr: {
        MandatoryField: () => 'Champ obligatoire',
    },
    it: {
        MandatoryField: () => 'Campo obbligatorio',
    },
    en: {
        MandatoryField: () => 'Mandatory field',
    },
};

interface LanguageTexts {
    de: string;
    fr: string;
    it: string;
    en: string;
    [idx: string]: string;
}

export const languageTexts: LanguageTexts = {
    de: 'Deutsch',
    en: 'English',
    it: 'Italiano',
    fr: 'Français',
};
