import { useState } from 'react';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as ParkingsState from '../../common/state/ParkingsState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import {
    LabeledText,
    LabeledTextStatus,
} from '../../ui/labeled-elements/LabeledText.tsx';
import { Body } from '../../ui/layout/TabContent.tsx';
import {
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import {
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import { parkingTexts } from '../i18n/ParkingsTexts.ts';
import { BooleanColumn } from './Shared.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { SingleZoneView } from '../../zones/components/ZoneMapView.tsx';
import { css } from '@emotion/css';
import { contentBodySlideInPortal } from '../../ui/layout/Shared.tsx';
import { MountSlideIn } from '../../zones/components/ZoneDetailSlideIn.tsx';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { Icon16 } from '../../ui/icons/Icon.tsx';
import { chevronRight } from 'dg-web-shared/ui/icons/Icons16.tsx';

export interface ParkingTexts {
    Nr: Translation;
    Name: Translation;
    TerminalId: Translation;
    NeedsPermit: Translation;
    Location: Translation;
    CardReader: Translation;
    Amounts: Translation;
    PingWatchdog: Translation;
    Vat: Translation;
    HeaderCaption: Translation;
    TerminalProvider: Translation;
    Account: Translation;
}

function providerToString(
    provider: ParkingsState.TerminalProvider | null,
): string {
    switch (provider) {
        case ParkingsState.TerminalProvider.VB:
            return 'Monocard';
        case ParkingsState.TerminalProvider.PM:
            return 'Zeag';
        case ParkingsState.TerminalProvider.SKIDATA_CLOUD:
        case ParkingsState.TerminalProvider.SKIDATA_CLOUD_V7:
            return 'Skidata';
        case ParkingsState.TerminalProvider.SB:
            return 'Scheidt & Bachmann';
        case ParkingsState.TerminalProvider.DESIGNA:
            return 'PKE Designa';
        case ParkingsState.TerminalProvider.HUB_PARKING:
            return 'HUB Parking';
        case ParkingsState.TerminalProvider.CLOUD_CONNECTOR:
            return 'Cloud Connector';
        case null:
            return '-';
        default:
            return provider as string;
    }
}

export const ParkingDetailSlideIn = contentBodySlideInPortal(
    ({
        selectedParkingId,
        setSelectedParkingId,
    }: {
        selectedParkingId: number | null;
        setSelectedParkingId: (value: number | null) => void;
    }) => {
        const { storeState } = useStore(store => ({
            settings: new SettingsState.StateSlice(store).state,
            permitTypes: PermitTypeState.get(store),
            parkings: ParkingsState.get(store),
            referenceData: OperatorDataState.get(store),
        }));
        /*
         * Do not use <Localized> in this component as for long zone lists render time gets very slow.
         */
        const txt = parkingTexts[storeState.settings.language];
        const parkingFinder = storeState.parkings.data.filter(
            z => z.id === selectedParkingId,
        );
        if (parkingFinder.length > 0) {
            const parking = parkingFinder[0];
            const vatNumber = parking.vatNumber;

            const referenceData = storeState.referenceData.data;
            const accounts = isDefined(referenceData)
                ? referenceData.billingProperties.accounts
                : [];
            const account = parking.dtaAccountId
                ? accounts.find(
                      account => account.dtaAccountId === parking.dtaAccountId,
                  )
                : null;

            return (
                <FullSlideIn open={true}>
                    <SlideInBody>
                        <FullSlideInLeftColumn>
                            <LabeledText label={txt.Name()}>
                                {' '}
                                {parking.name}{' '}
                            </LabeledText>
                            <LabeledText label={txt.TerminalId()}>
                                {' '}
                                {parking.terminalId}{' '}
                            </LabeledText>
                            <LabeledText label={txt.Location()}>
                                {`${parking.zipCode} ${parking.city}`}
                            </LabeledText>
                            <LabeledText label={txt.TerminalProvider()}>
                                {' '}
                                {providerToString(
                                    parking.terminalProvider,
                                )}{' '}
                            </LabeledText>
                            {parking.entryLimit && parking.offlineLimit && (
                                <LabeledText label={txt.Amounts()}>
                                    {`${numberToPrice(
                                        parking.entryLimit,
                                    )} / ${numberToPrice(
                                        parking.offlineLimit,
                                    )}`}
                                </LabeledText>
                            )}
                            {parking.hasLprReport && (
                                <LabeledText
                                    label={
                                        <Localized
                                            de="Kennzeichenerkennungs-report"
                                            fr={
                                                'Rapport de reconnaissance de plaque'
                                            }
                                            it={
                                                'Rapporto di riconoscimento targhe'
                                            }
                                            en={
                                                'License plate recognition report'
                                            }
                                        />
                                    }
                                >
                                    <a
                                        href={`/ui-api/operator-account/parkings/${parking.id}/lpr-report`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Download
                                    </a>
                                </LabeledText>
                            )}
                        </FullSlideInLeftColumn>
                        <FullSlideInRightColumn>
                            {!!parking.geodataText && (
                                <div
                                    className={css({
                                        width: '100%',
                                        height: '400px',
                                    })}
                                >
                                    <SingleZoneView
                                        zone={{
                                            id: parking.id,
                                            externalName: parking.name,
                                            geodataText: parking.geodataText,
                                        }}
                                    />
                                </div>
                            )}
                            <LabeledText
                                label={txt.PingWatchdog()}
                                status={
                                    parking.pingWatchdog
                                        ? LabeledTextStatus.enabled
                                        : LabeledTextStatus.disabled
                                }
                            />
                            <LabeledText
                                label={txt.NeedsPermit()}
                                status={
                                    parking.permitOnly
                                        ? LabeledTextStatus.enabled
                                        : LabeledTextStatus.disabled
                                }
                            />
                            <LabeledText label={txt.Vat()}>
                                {isDefined(vatNumber) ? vatNumber : '-'}
                            </LabeledText>
                            {accounts.length > 1 && isDefined(account) ? (
                                <LabeledText label={txt.Account()}>
                                    {account.iban} | {account.dtaText}
                                </LabeledText>
                            ) : null}
                        </FullSlideInRightColumn>
                    </SlideInBody>
                    <StandardFirstLevelHeader
                        onClose={() => setSelectedParkingId(null)}
                    >
                        <SlideInHeaderTexts
                            title={txt.HeaderCaption()}
                            hasLeftIcon={false}
                        />
                    </StandardFirstLevelHeader>
                </FullSlideIn>
            );
        } else {
            return <FullSlideIn open={false} />;
        }
    },
);

interface ItemProps {
    parking: ParkingsState.ParkingDTO;
    showAccount: boolean;
    account: OperatorDataState.Account | null;
    setSelectedParkingId: (value: number | null) => void;
}

const ParkingItem = ({
    parking,
    showAccount,
    account,
    setSelectedParkingId,
}: ItemProps): JSX.Element => {
    return (
        <DefaultTableRow onClick={() => setSelectedParkingId(parking.id)}>
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth._72px}>{parking.id}</TableColumn>
            <TableColumn width={ColumnWidth.variable}>
                {parking.name}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px} captionFontSize>
                {parking.zipCode + ' ' + parking.city}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px} captionFontSize>
                {providerToString(parking.terminalProvider)}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px} captionFontSize>
                {parking.terminalId}
            </TableColumn>
            <TableColumn width={ColumnWidth._136px} captionFontSize>
                <BooleanColumn b={parking.permitOnly} />
            </TableColumn>
            {showAccount ? (
                <TableColumn width={ColumnWidth._192px} captionFontSize>
                    {isDefined(account) ? account.dtaText : '-'}
                </TableColumn>
            ) : null}
            <TableColumn width={ColumnWidth._24px} captionFontSize>
                <IconTopOffsetContainer>
                    <Icon16 icon={chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </DefaultTableRow>
    );
};

export function ParkingsListBody() {
    const { storeState } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        permitTypes: PermitTypeState.get(store),
        parkings: ParkingsState.get(store),
        referenceData: OperatorDataState.get(store),
    }));

    const [selectedParkingId, setSelectedParkingId] = useState<number | null>(
        null,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function getDependingStateSlices(): AbstractLegacyServerState<any>[] {
        return [
            storeState.permitTypes,
            storeState.parkings,
            storeState.referenceData,
        ];
    }

    const referenceData = storeState.referenceData.data;
    const accounts = isDefined(referenceData)
        ? referenceData.billingProperties.accounts
        : [];
    const txt = parkingTexts[storeState.settings.language];
    return (
        <Body disabled={false} dependingStateSlices={getDependingStateSlices()}>
            <MountSlideIn>
                <ParkingDetailSlideIn
                    selectedParkingId={selectedParkingId}
                    setSelectedParkingId={setSelectedParkingId}
                />
            </MountSlideIn>
            <Table>
                <TableHeader>
                    <EmptyTableHeaderColumn
                        width={ColumnWidth._24px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.Nr()}
                        direction={SortDirection.Ascending}
                        selected={true}
                        width={ColumnWidth._72px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.Name()}
                        width={ColumnWidth.variable}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.Location()}
                        width={ColumnWidth._192px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.TerminalProvider()}
                        width={ColumnWidth._192px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.TerminalId()}
                        width={ColumnWidth._192px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.NeedsPermit()}
                        width={ColumnWidth._136px}
                        delimiterBottom={true}
                    />
                    {accounts.length > 1 ? (
                        <TableHeaderColumn
                            name={txt.Account()}
                            width={ColumnWidth._192px}
                            delimiterBottom={true}
                        />
                    ) : null}
                    <EmptyTableHeaderColumn
                        width={ColumnWidth._24px}
                        delimiterBottom={true}
                    />
                </TableHeader>
                <TableBody>
                    {storeState.parkings.data.map(t => {
                        const account = t.dtaAccountId
                            ? accounts.find(
                                  account =>
                                      account.dtaAccountId === t.dtaAccountId,
                              )
                            : null;

                        return account !== undefined &&
                            t.isOperatorStateActive ? (
                            <ParkingItem
                                parking={t}
                                account={account}
                                showAccount={accounts.length > 1}
                                key={t.id}
                                setSelectedParkingId={setSelectedParkingId}
                            />
                        ) : null;
                    })}
                </TableBody>
            </Table>
        </Body>
    );
}
