import { Box, ClickAwayListener } from '@mui/material';

import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface DropdownProps {
    onClose: () => void;
    open: boolean;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
}

export function Dropdown({ onClose, open, children, sx }: DropdownProps) {
    return (
        (open && (
            <ClickAwayListener onClickAway={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        backgroundColor: theme =>
                            theme.palette.background.default,
                        boxShadow: theme => theme.shadows[3],
                        padding: 2.25,
                        borderRadius: theme => theme.shape.borderRadius,
                        zIndex: theme => theme.zIndex.modal, // to override the sticky table header
                        ...(sx || {}),
                    }}
                >
                    {children}
                </Box>
            </ClickAwayListener>
        )) ||
        null
    );
}
