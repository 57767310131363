import { Translations } from '../../common/i18n/Text.ts';
import {
    ZoneConfigurationChangeType,
    ZoneDetailTexts,
} from '../components/ZoneDetailSlideIn.tsx';
import { ZoneTexts } from '../components/ZoneListBody.tsx';

export const zoneTexts: Translations<ZoneTexts> = {
    de: {
        AllowedTypes: () => 'Zugelassene Schildart',
        CarType: () => 'Motorwagen',
        City: () => 'Ort',
        Duration: () => 'Zeitvorwahl',
        HeaderCaption: () => 'Zone',
        MinMaxParkTime: () => 'Min. / Max. Parkzeit',
        Name: () => 'Zonenname',
        NeedsPermit: () => 'Bewilligungspflicht',
        Nr: () => 'Nr',
        NrLong: () => 'Zonennummer',
        ParkType: () => 'Parkvorgangstyp',
        PermitTypes: () => 'Bewilligungsarten',
        StartStop: () => 'Start / Stop',
        Vat: () => 'MWST.-Nummer',
        ZipCode: () => 'PLZ',
        Account: () => 'Bankkonto',
        EnforcementHubZoneId: () => 'Zonen-ID Kontrollhub',
    },
    fr: {
        AllowedTypes: () => 'Types de plaques admis',
        CarType: () => 'Voiture',
        City: () => 'Lieu',
        Duration: () => 'Durée présélectionnée',
        HeaderCaption: () => 'Zones',
        MinMaxParkTime: () => 'Durée de stationnement min. / max.',
        Name: () => 'Nom zone',
        NeedsPermit: () => 'Autorisation obligatoire',
        Nr: () => 'No',
        NrLong: () => 'N° de zone',
        ParkType: () => 'Type de procédure',
        PermitTypes: () => "Types d'autorisations",
        StartStop: () => 'Start / Stop',
        Vat: () => 'Numéro de TVA',
        ZipCode: () => 'NPA',
        Account: () => 'Compte bancaire',
        EnforcementHubZoneId: () => 'Zonen-ID Kontrollhub',
    },
    it: {
        AllowedTypes: () => 'Tipi di targa consentiti',
        CarType: () => 'Automobile',
        City: () => 'Località',
        Duration: () => 'Durata preselezionata',
        HeaderCaption: () => 'Zona',
        MinMaxParkTime: () => 'Durata di parcheggio min. / max.',
        Name: () => 'Nome zona',
        NeedsPermit: () => 'Autorizzazione obbligatoria',
        Nr: () => 'Nr',
        NrLong: () => ' Nr. zona',
        ParkType: () => 'Tipo di procedura',
        PermitTypes: () => "Tipi d'autorizzazione",
        StartStop: () => 'Start / Stop',
        Vat: () => 'Numero IVA',
        ZipCode: () => 'NAP',
        Account: () => 'Conto bancario',
        EnforcementHubZoneId: () => 'Zonen-ID Kontrollhub',
    },
};

export const zoneDetailTexts: Translations<ZoneDetailTexts> = {
    de: {
        ConfigurationChangeType: (changeType: ZoneConfigurationChangeType) => {
            switch (changeType) {
                case 'tariff':
                    return zoneDetailTexts.de.TariffChange();
                case 'exchangeRate':
                    return zoneDetailTexts.de.ExchangeRateChange();
                case 'other':
                default:
                    return zoneDetailTexts.de.OtherChange();
            }
        },
        EuroExchangeRate: () => 'Euro Wechselkurs',
        ExchangeRateChange: () => 'Wechselkursanpassung',
        NoUpcomingChanges: () => 'Keine bevorstehenden Änderungen',
        OtherChange: () => 'Konfigurationsänderung',
        Tariff: () => 'Tarif',
        ParkingpayTariff: () => 'Parkingpay-Tarif',
        TomTariff: () => 'Tom-Tarif',
        TariffChange: () => 'Tarifänderung',
        UpcomingChanges: () => 'Bevorstehende Änderungen',
        ValidSince: () => 'Gültig seit',
    },
    fr: {
        ConfigurationChangeType: (changeType: ZoneConfigurationChangeType) => {
            switch (changeType) {
                case 'tariff':
                    return zoneDetailTexts.fr.TariffChange();
                case 'exchangeRate':
                    return zoneDetailTexts.fr.ExchangeRateChange();
                case 'other':
                default:
                    return zoneDetailTexts.fr.OtherChange();
            }
        },
        EuroExchangeRate: () => "Taux de change de l'Euro",
        ExchangeRateChange: () => 'Ajustement du taux de change',
        NoUpcomingChanges: () => 'Pas de changements en vue',
        OtherChange: () => 'Changement de configuration',
        Tariff: () => 'Tarif',
        ParkingpayTariff: () => 'Tarif Parkingpay',
        TomTariff: () => 'Tarif Tom',
        TariffChange: () => 'Modification de tarif',
        UpcomingChanges: () => 'Changements à venir',
        ValidSince: () => 'Valable depuis',
    },
    it: {
        ConfigurationChangeType: (changeType: ZoneConfigurationChangeType) => {
            switch (changeType) {
                case 'tariff':
                    return zoneDetailTexts.it.TariffChange();
                case 'exchangeRate':
                    return zoneDetailTexts.it.ExchangeRateChange();
                case 'other':
                default:
                    return zoneDetailTexts.it.OtherChange();
            }
        },
        EuroExchangeRate: () => 'Tasso di cambio euro',
        ExchangeRateChange: () => 'Regolazione del tasso di cambio',
        NoUpcomingChanges: () => 'Nessuna modifica imminente',
        OtherChange: () => 'Modifica di configurazione',
        Tariff: () => 'Tariffa',
        ParkingpayTariff: () => 'Tariffa Parkingpay',
        TomTariff: () => 'Tariffa Tom',
        TariffChange: () => 'Modifica tariffa',
        UpcomingChanges: () => 'Modifiche imminenti',
        ValidSince: () => 'Valido da',
    },
};
