import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import {
    getOrElse,
    isDefined,
    isUndefined,
} from 'dg-web-shared/lib/MaybeV2.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import { NumberSingleSelectionSlideIn } from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import {
    getIdentificationTypesGivenSelection,
    hasBadgeIdentification,
    hasLicensePlateIdentification,
} from '../../common/state/PermitTypeState.ts';
import { Translation } from '../../common/i18n/Text.ts';
import { closePermitCreateSlidin } from './PermitCreate.tsx';
import { AutoActive } from 'product-shared/product-template/ProductOptions.tsx';
import { Localized } from '../../common/components/Localized.tsx';

export interface Texts {
    HeaderCaption: Translation;
    HeaderHeading: Translation;
}

const selectPermitType = (store: Flux.Store, permitTypeId: number): string => {
    const currentPermitConfig = PermitCreateState.Config.get(store);
    const permitType = PermitTypeState.getById(store, permitTypeId);
    if (!permitType) {
        return 'no-permittype-to-select';
    }
    const validDurations = permitType.validDurationsV2 || [];
    const identificationTypes = getIdentificationTypesGivenSelection(
        PermitTypeState.get(store).data,
        [permitTypeId],
    );

    PermitCreateState.Config.stateWrite(store, {
        permitTypeId: permitTypeId,
        startDate: permitType.fromRange.start,
        additionalInfo: null,
        remark: null,
    });

    if (
        permitType.toRange &&
        permitType.toRange.start.isSame(permitType.toRange.end)
    ) {
        PermitCreateState.Config.stateWrite(store, {
            permitTypeId: permitTypeId,
            endDate: permitType.toRange.start,
        });
    }

    // validate start date
    if (isDefined(currentPermitConfig.startDate) && isDefined(permitType)) {
        PermitCreateState.Config.stateWrite(store, {
            startDate: permitType.fromRange.after(currentPermitConfig.startDate)
                ? permitType.fromRange.start
                : currentPermitConfig.startDate,
        });
    }

    // clear identifications not supported by new permit type
    if (!hasLicensePlateIdentification(identificationTypes)) {
        PermitCreateState.Config.stateWrite(store, {
            licensePlates: [],
            vigetteAmount: null,
        });
    }
    if (!hasBadgeIdentification(identificationTypes)) {
        PermitCreateState.Config.stateWrite(store, { badges: [] });
    }

    if (permitType.addInfo != null && permitType.addInfo.length > 0) {
        PermitCreateState.Config.stateWrite(store, {
            additionalInfo: permitType.addInfo,
        });
    }

    // reset end date / duration
    PermitCreateState.Config.stateWrite(store, {
        durationId: null,
    });

    // keep intersecting zones but limit to allowed selection
    const selectedZones = getOrElse(
        currentPermitConfig.onstreetZones,
        [],
    ).filter(zid => permitType.zones.map(z => z.id).indexOf(zid) > -1);
    PermitCreateState.Config.stateWrite(store, {
        onstreetZones:
            permitType.selectZones === 'NO_CHOICE'
                ? null
                : permitType.selectZones === 'SELECT_ONE'
                  ? selectedZones.slice(0, 1)
                  : selectedZones,
    });

    if (validDurations.length === 1) {
        PermitCreateState.Config.stateWrite(store, {
            durationId: validDurations[0].uniqueId,
        });
    }

    PermitCreateState.Layout.stateWrite(store, {
        permitTypeSelectionOpen: false,
    });
    PermitCreateState.Calc.reset(store);
    return 'PermitTypeSelectionSlideIn-selectPermitType';
};

const closeSlidein = (store: Flux.Store) => {
    PermitCreateState.Layout.stateWrite(store, {
        permitTypeSelectionOpen: false,
    });
    if (isUndefined(PermitCreateState.Config.get(store).permitTypeId)) {
        closePermitCreateSlidin(store);
    }
    return 'PermitTypeSelectionSlideIn-close';
};

export function PermitTypeSelectionSlideIn() {
    const { storeState, update } = useStore(s => ({
        config: PermitCreateState.Config.get(s),
        layout: PermitCreateState.Layout.get(s),
        permitTypes: PermitTypeState.get(s),
    }));

    return (
        <NumberSingleSelectionSlideIn
            open={storeState.layout.permitTypeSelectionOpen || false}
            heading={
                <Localized
                    de="Bewilligungsart"
                    fr="Type d'autorisation"
                    it="Tipo d'autorizzazione"
                    en="Permit Type"
                />
            }
            selection={storeState.config.permitTypeId}
            options={options(storeState.permitTypes.data)}
            onSelect={(v: number) =>
                update(store => selectPermitType(store, v))
            }
            onLeftActionClick={() => update(closeSlidein)}
            outsideBody={true}
        />
    );
}

function options(permitTypes: PermitTypeState.PermitType[]) {
    return permitTypes
        .filter(
            (t: PermitTypeState.PermitType) =>
                t.operatorState === 'ACTIVE' &&
                t.autoActive !== AutoActive.STARTS_TRANSACTION,
        )
        .map((t: PermitTypeState.PermitType) => {
            return {
                id: t.permitTypeId,
                displayText: t.description,
            };
        });
}
