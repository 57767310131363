import {
    CloudConnectorPerifheryEventType,
    PeripheryConfig,
} from './CloudConnectorPeripheriesOverview.tsx';
import { Navigate, useParams } from 'react-router-dom';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import { KeyboardCapslock, KeyboardControlKey } from '@mui/icons-material';
import {
    RequestStatus,
    useServerFetch,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { ParkingPortalMenuListItem } from '../mobile/layout/ParkingPortalMenuListItem.tsx';
import { SeparationBar } from '../layout/components/SeparationBar.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { isMobileRoute } from '../layout/components/BreadCrumb.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { Localized } from '../common/components/Localized.tsx';

enum CloudConnectorCommandType {
    BARRIER_UP_ONCE = 'BARRIER_UP_ONCE',
    BARRIER_PERMANENTLY_OPEN = 'BARRIER_PERMANENTLY_OPEN',
}

interface CloudConnectorPeripheryRequest {
    operatorId: number;
    cloudConnectorId: string;
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
    commandType: CloudConnectorCommandType;
    secondaryGate: boolean | null;
    permanentlyOpen: boolean | null;
}

function isCloudConnectorPeripheryType(
    value?: string,
): value is CloudConnectorPeripheryType {
    return Object.values(CloudConnectorPeripheryType).includes(
        value as CloudConnectorPeripheryType,
    );
}

function getCloudConnectorPeripheryType(
    value?: string,
): CloudConnectorPeripheryType | null {
    if (isCloudConnectorPeripheryType(value)) {
        return value;
    }
    return null;
}

export function CloudConnectorPeripheriesDetails() {
    const urlParams = useParams<{
        cloudConnectorId: string;
        peripheryType: string;
        onPremiseId: string;
    }>();
    const cloudConnectorId = urlParams.cloudConnectorId;
    const peripheryType = getCloudConnectorPeripheryType(
        urlParams.peripheryType,
    );
    const onPremiseId = urlParams.onPremiseId
        ? Number(urlParams.onPremiseId)
        : null;

    const [cloudConnectorPeripheryState] = useServerFetch<
        PeripheryConfig,
        {
            cloudConnectorId: string | null;
            peripheryType: CloudConnectorPeripheryType | null;
            onPremiseId: number | null;
        }
    >(
        context => ({
            url: `/ui-api/operator-account/cloud-connector/periphery/${context.cloudConnectorId}/${context.peripheryType}/${context.onPremiseId}`,
        }),
        {
            cloudConnectorId: cloudConnectorId ? cloudConnectorId : null,
            peripheryType: peripheryType ? peripheryType : null,
            onPremiseId: onPremiseId ? onPremiseId : null,
        },
    );
    const isMobile = isMobileRoute();
    return (
        <OperatorAsyncLoadedSection
            requestState={cloudConnectorPeripheryState}
            render={peripheryConfig => (
                <OperatorRoutedModal
                    variant={
                        isMobile ? ModalVariant.MOBILE : ModalVariant.SMALL
                    }
                    backUrl={'..'}
                    render={() => (
                        <OperatorRoutedModalContent
                            header={
                                <HeaderComponent
                                    title={peripheryConfig.name}
                                    editMode={false}
                                    buttons={<HeaderActionCloseButtons />}
                                />
                            }
                            bar={<SeparationBar />}
                            body={
                                <PeripheryDetail
                                    peripheryConfig={peripheryConfig}
                                />
                            }
                            style={{ padding: 0 }}
                        />
                    )}
                />
            )}
        />
    );
}

function PeripheryDetail({
    peripheryConfig,
}: {
    peripheryConfig: PeripheryConfig;
}) {
    const [cloudConnectorRequestState, cloudConnectorRequest] = useServerWrite<
        CloudConnectorPeripheryRequest,
        null
    >(({ operatorId, cloudConnectorId }) => ({
        url: `/ui-api/operator-account/${operatorId}/cloud-connector/${cloudConnectorId}/periphery-command`,
    }));

    switch (cloudConnectorRequestState.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.ERROR:
            return (
                <Box>
                    <RequestError
                        open={
                            cloudConnectorRequestState.status ===
                            RequestStatus.ERROR
                        }
                    />
                    <CommandList
                        peripheryConfig={peripheryConfig}
                        cloudConnectorRequest={cloudConnectorRequest}
                    />
                </Box>
            );
        case RequestStatus.PENDING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case RequestStatus.SUCCESS:
            return <Navigate to={'..'} />;
    }
}

function CommandList({
    peripheryConfig,
    cloudConnectorRequest,
}: {
    peripheryConfig: PeripheryConfig;
    cloudConnectorRequest: (args: CloudConnectorPeripheryRequest) => void;
}) {
    const { currentLogin } = useOperatorContext();
    const barrierPermanentlyOpen = peripheryConfig.events.some(
        event =>
            event.eventType ===
            CloudConnectorPerifheryEventType.BARRIER_PERMANENTLY_OPEN,
    );

    switch (peripheryConfig.peripheryType) {
        case CloudConnectorPeripheryType.ENTRY:
            return (
                <>
                    <ParkingPortalMenuListItem
                        text={<OpenBarrier />}
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_UP_ONCE,
                                secondaryGate: false,
                                permanentlyOpen: null,
                            })
                        }
                        icons={[<KeyboardControlKey key={0} />]}
                        hideChevron
                    />
                    <ParkingPortalMenuListItem
                        text={
                            barrierPermanentlyOpen ? (
                                <CloseBarrier />
                            ) : (
                                <BarrierPermanentlyOpen />
                            )
                        }
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_PERMANENTLY_OPEN,
                                secondaryGate: null,
                                permanentlyOpen: !barrierPermanentlyOpen,
                            })
                        }
                        icons={[<KeyboardCapslock key={0} />]}
                        hideChevron
                    />
                </>
            );
        case CloudConnectorPeripheryType.EXIT:
            return (
                <>
                    <ParkingPortalMenuListItem
                        text={<OpenBarrier />}
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_UP_ONCE,
                                secondaryGate: false,
                                permanentlyOpen: null,
                            })
                        }
                        icons={[<KeyboardControlKey key={0} />]}
                        hideChevron
                    />
                    <ParkingPortalMenuListItem
                        text={
                            barrierPermanentlyOpen ? (
                                <CloseBarrier />
                            ) : (
                                <BarrierPermanentlyOpen />
                            )
                        }
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_PERMANENTLY_OPEN,
                                secondaryGate: null,
                                permanentlyOpen: !barrierPermanentlyOpen,
                            })
                        }
                        icons={[<KeyboardCapslock key={0} />]}
                        hideChevron
                    />
                </>
            );
        case CloudConnectorPeripheryType.PAY_STATION:
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
        case CloudConnectorPeripheryType.ACCESS:
            return <Navigate to={'..'} />;
    }
}

function OpenBarrier() {
    return (
        <Localized
            de="Barriere öffnen"
            fr="Ouvrir la barrière"
            it="Barriera aperta"
            en="Open barrier"
        />
    );
}

function BarrierPermanentlyOpen() {
    return (
        <Localized
            de="Barriere dauer auf"
            fr="Barrière ouverte en permanence"
            it="Barriera permanentemente aperta"
            en="Barrier permanently open"
        />
    );
}

function CloseBarrier() {
    return (
        <Localized
            de="Barriere schliessen"
            fr="Fermer la barrière"
            it="Barriera chiusa"
            en="Close barrier"
        />
    );
}

function RequestError({ open }: { open: boolean }) {
    if (open) {
        return (
            <Alert severity="error">
                <Localized
                    de="Die Aktion konnte nicht ausgeführt werden."
                    fr="L'action n'a pas pu être réalisée."
                    it="Non è stato possibile eseguire l'azione."
                    en="The action could not be carried out."
                />
            </Alert>
        );
    }
    return null;
}
