import moment from 'moment';
import { Maybe, isDefined } from 'dg-web-shared/lib/MaybeV2.ts';

export const toDateConstraint = (
    fromDate: moment.Moment,
    toDate: Maybe<moment.Moment>,
) => (isDefined(toDate) && toDate.isBefore(fromDate) ? fromDate : toDate);

export const fromDateConstraint = (
    toDate: moment.Moment,
    fromDate: Maybe<moment.Moment>,
) => (isDefined(fromDate) && fromDate.isAfter(toDate) ? toDate : fromDate);
