import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as UsbStickState from '../state/UsbStickState.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { UsbStickDetail } from 'dg-web-shared/model/UsbStick.ts';

export const editBaseData = AsyncRequest.request(
    Http.OperatorAccount.UsbSticks.editBaseData,
    (store: Flux.Store, res: Response): string => {
        UsbStickState.EditBaseDataResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            UsbStickState.Edit.reset(store);
            UsbStickState.List.reset(store);
            UsbStickState.Detail.refetchSameContext(store, true);
            UsbStickState.Layout.reset(store);
        }
        return 'UsbStickActions-editBaseData';
    },
);

export const makeEditPayload = (
    edit: UsbStickState.Edit.State,
    usbStick: UsbStickDetail,
): Http.OperatorAccount.UsbSticks.EditBaseDataPayload => {
    return {
        name: getOrElse(edit.name, usbStick.name),
    };
};
