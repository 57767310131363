import { GeoJsonObject, Point } from 'geojson';
import { parse } from 'wellknown';

export function geoJson(wkt: string | null): WktParseResult | null {
    if (wkt) {
        const result = parse(wkt);

        if (result) {
            // reverse coordinates of single points thanks to conflicting standards.
            // see: https://gis.stackexchange.com/questions/246102/leaflet-reads-geojson-x-y-as-y-x-how-can-i-correct-this
            if ((result as GeoJsonObject).type === 'Point') {
                (result as Point).coordinates.reverse();
            }

            return {
                type: WktParseResultTag.OK,
                result: result as GeoJsonObject,
            };
        }

        return {
            type: WktParseResultTag.ERROR,
            message: `Wellknown text parsing failed on [${wkt}]`,
        };
    }
    return null;
}

export enum WktParseResultTag {
    OK = 'OK',
    ERROR = 'ERROR',
}

export type WktParseResult = WktParseResultOk | WktParseResultError;

export interface WktParseResultOk {
    type: WktParseResultTag.OK;
    result: GeoJsonObject;
}

export interface WktParseResultError {
    type: WktParseResultTag.ERROR;
    message: string;
}
