import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import {
    ConfirmationHeader,
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { operatorLoginsListTexts } from '../i18n/OperatorLoginsTexts.ts';
import { OperatorLoginsListTexts } from './OperatorLoginsList.tsx';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as Http from '../../api/Http.ts';
import * as AuthenticationActions from '../../app/actions/AuthenticationActions.ts';
import { Validation } from './Validation.ts';
import { FormValidation } from 'dg-web-shared/lib/FormValidation.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';

const cn = ElementNamer('OperatorChangePassword');

interface OperatorChangePasswordState {
    layout: OperatorLoginsState.Layout.State;
    operatorLoginsList: CommonOperatorLoginsState.List.State;
    passwordEdit: OperatorLoginsState.PasswordEdit.State;
    editResponse: OperatorLoginsState.EditCreateResponse.State;
    settings: SettingsState.State;
}

export const closeSlideIn = (store: Flux.Store): string => {
    OperatorLoginsState.Layout.stateWrite(store, { editPasswordOpen: false });
    OperatorLoginsState.PasswordEdit.reset(store);
    return 'OperatorLogins-closeSlideIn';
};

export const savePassword = AsyncRequest.request(
    Http.OperatorAccount.OperatorLogins.editPassword,
    (store: Flux.Store, res: Response): string => {
        OperatorLoginsState.EditCreateResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            if (
                OperatorLoginsState.Layout.get(store).selectedOperatorLogin ==
                    CurrentOperatorLoginState.get(store).data?.loginId &&
                CurrentOperatorLoginState.get(store).data?.isAdmin
            ) {
                AuthenticationActions.logout(store);
            }
            closeSlideIn(store);
        }
        return 'OperatorLoginsDetailSlideIn-save';
    },
);

export class OperatorChangePasswordSlideIn extends Flux.Container<OperatorChangePasswordState> {
    static displayName: string = cn('');

    stateSelector(): OperatorChangePasswordState {
        return {
            layout: OperatorLoginsState.Layout.get(this.props.allState),
            operatorLoginsList: CommonOperatorLoginsState.List.get(
                this.props.allState,
            ),
            passwordEdit: OperatorLoginsState.PasswordEdit.get(
                this.props.allState,
            ),
            editResponse: OperatorLoginsState.EditCreateResponse.get(
                this.props.allState,
            ),
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }

    txt(): OperatorLoginsListTexts {
        return operatorLoginsListTexts[this.state.settings.language];
    }

    getLoginId(): number {
        return this.state.layout.selectedOperatorLogin
            ? this.state.layout.selectedOperatorLogin
            : -1;
    }

    formIsValid() {
        return (
            Validation.PasswordForm.validateForm(this.state).isValid &&
            this.state.passwordEdit.password1 ===
                this.state.passwordEdit.password2
        );
    }

    renderHeader(): JSX.Element {
        if (this.state.editResponse.pending) {
            return <LoaderHeader />;
        } else {
            return (
                <ConfirmationHeader
                    language={this.state.settings.language}
                    title={this.txt().savePassword()}
                    onCancel={() => this.update(closeSlideIn)}
                    onConfirm={() => {
                        if (this.formIsValid()) {
                            if (this.state.layout.createEnabled) {
                                this.update(store =>
                                    OperatorLoginsState.Layout.stateWrite(
                                        store,
                                        { editPasswordOpen: false },
                                    ),
                                );
                            } else if (this.state.passwordEdit.password1) {
                                this.update(savePassword, {
                                    loginId: this.getLoginId(),
                                    password: this.state.passwordEdit.password1,
                                });
                            }
                        } else {
                            this.update(store =>
                                OperatorLoginsState.Layout.stateWrite(store, {
                                    showPasswordErrors: true,
                                }),
                            );
                        }
                    }}
                    confirmButtonSpecialState={
                        !this.formIsValid() ? ButtonSpecialState.DISABLED : null
                    }
                />
            );
        }
    }

    renderBody(): JSX.Element {
        const formErrors = Validation.PasswordForm.validateForm(
            this.state,
        ).errorTexts;
        const pw2Error = FormValidation.errorText(
            this.state.layout.showPasswordErrors,
            formErrors,
            t => t.password2 && t.password2(),
        );

        return (
            <SlideInBody>
                <TextField
                    inputType="password"
                    value={
                        this.state.passwordEdit.password1
                            ? this.state.passwordEdit.password1
                            : ''
                    }
                    label={this.txt().password1()}
                    onChange={(v: string) => {
                        this.props.allState.update(store =>
                            OperatorLoginsState.PasswordEdit.stateWrite(store, {
                                password1: v.replace(/\s/g, ''),
                            }),
                        );
                    }}
                    errorText={FormValidation.errorText(
                        this.state.layout.showPasswordErrors,
                        formErrors,
                        t => t.password1 && t.password1(),
                    )}
                />
                <TextField
                    inputType="password"
                    value={
                        this.state.passwordEdit.password2
                            ? this.state.passwordEdit.password2
                            : ''
                    }
                    label={this.txt().password2()}
                    onChange={(v: string) =>
                        this.props.allState.update(store =>
                            OperatorLoginsState.PasswordEdit.stateWrite(store, {
                                password2: v.replace(/\s/g, ''),
                            }),
                        )
                    }
                    errorText={pw2Error}
                />
            </SlideInBody>
        );
    }

    render() {
        return (
            <HalfSlideIn open={this.state.layout.editPasswordOpen}>
                {this.renderBody()}
                {this.renderHeader()}
            </HalfSlideIn>
        );
    }
}
