import moment from 'moment';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import { generateState } from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as Http from '../api/Http.ts';
import * as AsyncRequest from '../AsyncRequest.ts';
import { EnforcedLicensePlate } from 'enforcement-result/src/EnforcementTypes.ts';

export const enum ResponseResult {
    POSITIVE = 'POSITIVE',
    FUZZY_POSITIVE = 'FUZZY_POSITIVE',
    NEGATIVE = 'NEGATIVE',
}

export interface EnforcementItem {
    id: number;
    identification: string;
    enforcementTime: string;
    zoneName: string;
    responseResult: ResponseResult;
    enforcerName: string;
    locationName: string;
}

export namespace List {
    interface Context {
        validFrom: Maybe<moment.Moment>;
        validTo: Maybe<moment.Moment>;
        zones: Maybe<number[]>;
        search: Maybe<string>;
        lang: string;
    }

    export interface Data {
        length: number;
        data: EnforcementItem[];
    }

    export type State = ContextualServerStateSlice.ContextualState<Data>;

    export const { get, getState, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<Context, Data>({
            key: 'enforcement-log-list-blabla',
            requestGenerator: (context: Context) =>
                Http.OperatorAccount.EnforcementLog.list(context),
            request: AsyncRequest.request,
            parseBody: b => b,
            debounceTime: 300,
        });
}

export namespace Filter {
    export interface State {
        filterSlideInVisible: boolean;

        validFrom: moment.Moment | null;
        validTo: moment.Moment | null;
        validDateSelectionVisible: boolean;

        zones: number[];
        zonesSelectionVisible: boolean;

        search: string | null;
    }

    export const { set, get, reset, stateWrite } = generateState<State>(
        'enforcement-log-Filter',
        {
            filterSlideInVisible: false,
            validFrom: null,
            validTo: null,
            validDateSelectionVisible: false,

            zones: [],
            zonesSelectionVisible: false,

            search: null,
        },
    );
}

export namespace FilterSummary {
    export interface State {
        hover: boolean;
    }

    export const { get, stateWrite } = generateState<State>(
        'enforcement-log-FilterSummary',
        { hover: false },
    );
}

export namespace Layout {
    export interface State {
        selectedItem: Maybe<number>;
    }

    export const { set, get, reset, stateWrite } = generateState<State>(
        'enforcement-log-Detail',
        { selectedItem: undefined },
    );
}

export namespace Detail {
    interface Context {
        id: number;
    }

    export interface Data {
        id: number;
        identification: string;
        enforcementTime: string;
        zoneName: string;
        responseResult: ResponseResult;
        enforcerName: string;
        locationName: string;
        result: EnforcedLicensePlate[] | null;
        rawIdentification: string;
        recognitionType: string;
        clientName: string;
        clientVersion: string;
        authType: string;
    }

    export type State = ContextualServerStateSlice.ContextualState<Data>;

    export const { get, getState, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<Context, Data>({
            key: 'enforcement-log-detail-state',
            requestGenerator: (context: Context) =>
                Http.OperatorAccount.EnforcementLog.detail(context),
            request: AsyncRequest.request,
            parseBody: b => b,
            debounceTime: 300,
        });
}
