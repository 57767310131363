import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    HalfSlideIn,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../../ui/slidein/Slidein.tsx';
import { isDefined, isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { collectionDetailTexts } from '../../i18n/CollectionsTexts.ts';
import * as TomState from '../../state/TomState.ts';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { LabeledText } from '../../../ui/labeled-elements/LabeledText.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import {
    TomCollection,
    TomCollectionV2,
} from 'dg-web-shared/model/TomCollection.ts';
import { closeCollectionDetail } from '../../actions/CollectionsActions.ts';
import { coinCurrency, coinName } from 'dg-web-shared/model/Coins.ts';
import { css } from '@emotion/css';

export interface CollectionDetailTexts {
    SlideInHeaderCaption: Text.Translation;
    ParkingMeter: Text.Translation;
    CollectionTime: Text.Translation;
    CollectionStickId: Text.Translation;
    CollectionNumber: Text.Translation;
    TransactionCount: Text.Translation;
    CollectedAmount: Text.Translation;
    PaidWith: Text.Translation;
    Total: Text.Translation;
    Token: Text.Translation;
    CollectedAmountChf: Text.Translation;
    CollectedAmountEuro: Text.Translation;
    CollectedAmountToken: Text.Translation;
    RejectedCoinsCount: Text.Translation;
    BatteryVoltage: Text.Translation;
}

const fscn = ElementNamer('CollectionDetailSlideIn');

interface CollectionDetailSlideInState {
    settings: SettingsState.State;
    detail: TomCollectionsState.Detail.State;
    toms: TomState.List.State;
}

export class CollectionDetailSlideIn extends Flux.Container<CollectionDetailSlideInState> {
    static displayName: string = fscn('');

    stateSelector(): CollectionDetailSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            detail: TomCollectionsState.Detail.get(this.props.allState),
            toms: TomState.List.get(this.props.allState),
        };
    }

    render() {
        const lng = this.state.settings.language;
        const txt = collectionDetailTexts[lng];
        const collection = this.state.detail.selectedCollection;

        if (isUndefined(collection)) {
            return <HalfSlideIn open={false} />;
        } else {
            const hasValidCoinsCount =
                isDefined(collection.validCoinsCount) &&
                collection.validCoinsCount > 0;
            const rejectionPercentage = hasValidCoinsCount
                ? Math.round(
                      (collection.rejectedCoinsCount /
                          (collection.validCoinsCount as number)) *
                          100,
                  )
                : 0;
            return (
                <HalfSlideIn open={true}>
                    <SlideInBody>
                        <LabeledText label={txt.CollectionNumber()}>
                            {collection.collectionNumber}
                        </LabeledText>
                        <LabeledText label={txt.ParkingMeter()}>
                            {TomState.getTomName(
                                this.state.toms.data,
                                collection.tomId,
                            )}
                        </LabeledText>
                        <LabeledText label={txt.CollectionTime()}>
                            {Date.Formatter.dayMonthYearHourMinute(
                                collection.collectionTime,
                            )}
                        </LabeledText>
                        <LabeledText label={txt.CollectedAmount()}>
                            {NumberFormatter.numberToPrice(
                                collection.collectedAmount,
                                true,
                            )}
                        </LabeledText>
                        {isDefined(collection.validCoinsCount) ? (
                            <DetailedCollectedAmountDetail
                                collection={collection}
                                language={lng}
                            />
                        ) : (
                            <CollectedAmountDetail
                                collection={collection}
                                language={lng}
                            />
                        )}
                        <LabeledText label={txt.TransactionCount()}>
                            {collection.transactionCount}
                        </LabeledText>
                        <LabeledText label={txt.RejectedCoinsCount()}>
                            {collection.rejectedCoinsCount}
                            {hasValidCoinsCount &&
                                ` (${rejectionPercentage} %)`}
                        </LabeledText>
                        <LabeledText label={txt.CollectionStickId()}>
                            {collection.stickDescription}
                        </LabeledText>
                        <LabeledText label={txt.BatteryVoltage()}>
                            {collection.batteryVoltage + ' V'}
                        </LabeledText>
                    </SlideInBody>
                    <StandardFirstLevelHeader
                        onClose={() => this.update(closeCollectionDetail)}
                    >
                        <SlideInHeaderTexts
                            title={txt.SlideInHeaderCaption()}
                            hasLeftIcon={false}
                        />
                    </StandardFirstLevelHeader>
                </HalfSlideIn>
            );
        }
    }
}

interface CollectedAmountDetailProps {
    collection: TomCollection;
    language: string;
}

function CollectedAmountDetail(
    props: CollectedAmountDetailProps,
): JSX.Element | null {
    const txt = collectionDetailTexts[props.language];
    return props.collection.collectedAmountEuro > 0 ||
        props.collection.collectedAmountEuro > 0 ? (
        <LabeledText label={txt.PaidWith()}>
            {props.collection.collectedAmountChf > 0 && (
                <div>
                    {coinCurrency('CH', props.language)}{' '}
                    {NumberFormatter.numberToPrice(
                        props.collection.collectedAmountChf,
                        false,
                    )}
                </div>
            )}
            {props.collection.collectedAmountEuro > 0 && (
                <div>
                    {coinCurrency('EU', props.language)}{' '}
                    {NumberFormatter.numberToPrice(
                        props.collection.collectedAmountEuro,
                        false,
                    )}
                </div>
            )}
            {props.collection.collectedAmountToken > 0 && (
                <div>
                    {coinCurrency('TK', props.language)}{' '}
                    {NumberFormatter.numberToPrice(
                        props.collection.collectedAmountToken,
                        false,
                    )}
                </div>
            )}
        </LabeledText>
    ) : null;
}

function getCurrencyStyle(hideData: boolean) {
    return css([
        { width: '30%', marginRight: '50px' },
        hideData && {
            display: 'none',
        },
    ]);
}
/**
 * For collection data with count information for each coin type
 */
function DetailedCollectedAmountDetail(
    props: CollectedAmountDetailProps,
): JSX.Element {
    const txt = collectionDetailTexts[props.language];
    const collection: TomCollectionV2 = props.collection as TomCollectionV2;
    const coinEntryStyle = css({
        marginLeft: '50px',
        display: 'flex',
        justifyContent: 'space-between',
        '&:nth-child(2)': { marginTop: '0px' },
    });
    const currencyCaptionStyle = css({ height: '100%', float: 'left' });

    return (
        <LabeledText label={txt.PaidWith()}>
            <div className={css({ display: 'flex', width: '100%' })}>
                <div
                    className={getCurrencyStyle(
                        collection.collectedAmountChf === 0,
                    )}
                >
                    <div className={currencyCaptionStyle}>
                        {coinCurrency('CH', props.language)}
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{txt.Total()}</div>
                        <div>
                            {NumberFormatter.numberToPrice(
                                collection.collectedAmountChf,
                                false,
                            )}
                        </div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('CH005A', props.language)}</div>
                        <div>{collection.chf_005Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('CH010A', props.language)}</div>
                        <div>{collection.chf_010Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('CH020A', props.language)}</div>
                        <div>{collection.chf_020Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('CH050A', props.language)}</div>
                        <div>{collection.chf_050Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('CH100A', props.language)}</div>
                        <div>{collection.chf_100Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('CH200A', props.language)}</div>
                        <div>{collection.chf_200Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('CH500A', props.language)}</div>
                        <div>{collection.chf_500Count}</div>
                    </div>
                </div>
                <div
                    className={getCurrencyStyle(
                        collection.collectedAmountEuro === 0,
                    )}
                >
                    <div className={currencyCaptionStyle}>
                        {coinCurrency('EU', props.language)}
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{txt.Total()}</div>
                        <div>
                            {NumberFormatter.numberToPrice(
                                collection.collectedAmountEuro,
                                false,
                            )}
                        </div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('EU010A', props.language)}</div>
                        <div>{collection.eur_010Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('EU020A', props.language)}</div>
                        <div>{collection.eur_020Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('EU050A', props.language)}</div>
                        <div>{collection.eur_050Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('EU100A', props.language)}</div>
                        <div>{collection.eur_100Count}</div>
                    </div>
                    <div className={coinEntryStyle}>
                        <div>{coinName('EU200A', props.language)}</div>
                        <div>{collection.eur_200Count}</div>
                    </div>
                </div>
            </div>
            <div className={css({ display: 'flex', width: '100%' })}>
                <div
                    className={getCurrencyStyle(
                        collection.collectedAmountToken === 0,
                    )}
                >
                    <div className={currencyCaptionStyle}>{txt.Token()}</div>
                    <div className={coinEntryStyle}>
                        <div>{txt.Total()}:</div>
                        <div>
                            {coinCurrency('TK', props.language)}{' '}
                            {NumberFormatter.numberToPrice(
                                collection.collectedAmountToken,
                                false,
                            )}
                        </div>
                    </div>
                    <div
                        className={coinEntryStyle}
                        data-hide={collection.tk_1Count === 0}
                    >
                        <div>{coinName('TK001A', props.language)}:</div>
                        <div>{collection.tk_1Count}</div>
                    </div>
                    <div
                        className={coinEntryStyle}
                        data-hide={collection.tk_2Count === 0}
                    >
                        <div>{coinName('TK002A', props.language)}:</div>
                        <div>{collection.tk_2Count}</div>
                    </div>
                    <div
                        className={coinEntryStyle}
                        data-hide={collection.tk_3Count === 0}
                    >
                        <div>{coinName('TK003A', props.language)}:</div>
                        <div>{collection.tk_3Count}</div>
                    </div>
                </div>
            </div>
        </LabeledText>
    );
}
