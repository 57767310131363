import { Translations, Translation } from '../../../common/i18n/Text.ts';

interface Texts {
    noRowsDescription: Translation;
    noRowsTitle: Translation;
    tooManyRowsDescription: Translation;
    tooManyRowsTitle: Translation;
}

export const texts: Translations<Texts> = {
    de: {
        noRowsDescription: () =>
            'Bitte überprüfen Sie die ausgewählten Filterkriterien.',
        noRowsTitle: () => 'Keine Resultate gefunden.',
        tooManyRowsDescription: () =>
            'Bitte schränken Sie die Suche mit den Filterkriterien weiter ein.',
        tooManyRowsTitle: () => 'Zu viele Resultate.',
    },
    fr: {
        noRowsDescription: () =>
            'Vérifier dans le filtre les critères selectionnés.',
        noRowsTitle: () => 'Aucun résultat relevé.',
        tooManyRowsDescription: () =>
            'Restreindre la recherche avec les critères du filtre.',
        tooManyRowsTitle: () => 'Trop de résultats.',
    },
    it: {
        noRowsDescription: () => 'Verificare nel filtro i criteri selezionati.',
        noRowsTitle: () => 'Nessun risultato trovato.',
        tooManyRowsDescription: () =>
            'Restringere il campo di ricerca utilizzando i criteri del filtro.',
        tooManyRowsTitle: () => 'Troppi risultati.',
    },
};
