import { Validators } from './String';
import { LicensePlateErrorTexts } from '../common/components/translations/LicensePlateErrorTexts.ts';

export interface LicensePlate {
    licensePlateCountryId: string;
    licensePlateNr: string;
}

export function hasState(
    states: LicensePlateState[],
    state: LicensePlateState,
): boolean {
    return states.indexOf(state) !== -1;
}

export enum LicensePlateState {
    NOT_UPPERCASE = 0,
    HAS_WHITESPACE,
    NO_VALID_CANTON,
    LESS_THAN_THREE_CHARS,
    HAS_MORE_THAN_SIX_NUMBERS,
    HAS_MORE_THAN_FIVE_NUMBERS,
    LETTERS_AFTER_CANTON,
    LETTERS_AFTER_COUNTRY_FL,
    COUNTRY_MUST_BE_FL,
}

const CANTONS = [
    'ZH',
    'BE',
    'LU',
    'UR',
    'SZ',
    'OW',
    'NW',
    'GL',
    'ZG',
    'FR',
    'SO',
    'BS',
    'BL',
    'SH',
    'AR',
    'AI',
    'SG',
    'GR',
    'AG',
    'TG',
    'TI',
    'VD',
    'VS',
    'NE',
    'GE',
    'JU',
];

function lessThanThreeChars(value: string): boolean {
    return value.length > 0 && value.length < 3;
}

function lpStatesCH(value: string): LicensePlateState[] {
    const states: LicensePlateState[] = [];
    if (!Validators.isUpperCase(value)) {
        states.push(LicensePlateState.NOT_UPPERCASE);
    }
    if (Validators.hasWhiteSpace(value)) {
        states.push(LicensePlateState.HAS_WHITESPACE);
    }
    if (lessThanThreeChars(value)) {
        states.push(LicensePlateState.LESS_THAN_THREE_CHARS);
    }
    // This check has to be after 'lessThanThreeChars' and 'isUpperCase' so Z & U don't get excluded from these validations
    if (['Z', 'U'].indexOf(value[value.length - 1]) > -1) {
        value = value.substr(0, value.length - 1);
    }
    if (['CD', 'CC', 'AT'].indexOf(value.substring(0, 2)) !== -1) {
        value = value.substring(2);
    }
    if (
        value.length > 0 &&
        CANTONS.indexOf(value.substr(0, 2)) === -1 &&
        !(
            value[0] === 'M' &&
            Validators.isNumber(value.substr(1, value.length))
        )
    ) {
        states.push(LicensePlateState.NO_VALID_CANTON);
    }
    if (
        value.length === 2 ||
        !Validators.isNumber(value.substr(2, value.length))
    ) {
        states.push(LicensePlateState.LETTERS_AFTER_CANTON);
    }
    if (value.length > 8) {
        states.push(LicensePlateState.HAS_MORE_THAN_SIX_NUMBERS);
    }
    return states;
}

function lpStatesFL(value: string): LicensePlateState[] {
    if (['U'].indexOf(value[value.length - 1]) > -1) {
        value = value.substr(0, value.length - 1);
    }
    const states: LicensePlateState[] = [];
    if (!Validators.isUpperCase(value)) {
        states.push(LicensePlateState.NOT_UPPERCASE);
    }
    if (Validators.hasWhiteSpace(value)) {
        states.push(LicensePlateState.HAS_WHITESPACE);
    }
    if (lessThanThreeChars(value)) {
        states.push(LicensePlateState.LESS_THAN_THREE_CHARS);
    }
    if (value.substr(0, 2) !== 'FL') {
        states.push(LicensePlateState.COUNTRY_MUST_BE_FL);
    }
    if (
        value.length === 2 ||
        !Validators.isNumber(value.substr(2, value.length))
    ) {
        states.push(LicensePlateState.LETTERS_AFTER_COUNTRY_FL);
    }
    if (value.length > 7) {
        states.push(LicensePlateState.HAS_MORE_THAN_FIVE_NUMBERS);
    }
    return states;
}

function foreignLpStates(value: string): LicensePlateState[] {
    const states: LicensePlateState[] = [];
    if (!Validators.isUpperCase(value)) {
        states.push(LicensePlateState.NOT_UPPERCASE);
    }
    if (lessThanThreeChars(value)) {
        states.push(LicensePlateState.LESS_THAN_THREE_CHARS);
    }
    if (Validators.hasWhiteSpace(value)) {
        states.push(LicensePlateState.HAS_WHITESPACE);
    }

    return states;
}

export function getLicensePlateStates(
    plate: LicensePlate,
): LicensePlateState[] {
    switch (plate.licensePlateCountryId.toUpperCase()) {
        case 'CH':
            return lpStatesCH(plate.licensePlateNr);
        case 'FL':
            return lpStatesFL(plate.licensePlateNr);
        default:
            return foreignLpStates(plate.licensePlateNr);
    }
}

export function licensePlateErrorText(
    plate: LicensePlate,
    texts: LicensePlateErrorTexts,
): string {
    const states = getLicensePlateStates(plate);

    if (hasState(states, LicensePlateState.NOT_UPPERCASE)) {
        return texts.AllLettersUppercase();
    } else if (hasState(states, LicensePlateState.HAS_WHITESPACE)) {
        return texts.NoWhiteSpace();
    } else if (hasState(states, LicensePlateState.LESS_THAN_THREE_CHARS)) {
        return texts.MinThreeLetters();
    } else if (hasState(states, LicensePlateState.LETTERS_AFTER_CANTON)) {
        return texts.LettersAfterCanton();
    } else if (hasState(states, LicensePlateState.LETTERS_AFTER_COUNTRY_FL)) {
        return texts.LettersAfterCountryFL();
    } else if (hasState(states, LicensePlateState.NO_VALID_CANTON)) {
        return texts.NoValidCanton();
    } else if (hasState(states, LicensePlateState.HAS_MORE_THAN_SIX_NUMBERS)) {
        return texts.HasMoreThanSixNumbers();
    } else if (hasState(states, LicensePlateState.HAS_MORE_THAN_FIVE_NUMBERS)) {
        return texts.HasMoreThanFiveNumbers();
    } else if (hasState(states, LicensePlateState.COUNTRY_MUST_BE_FL)) {
        return texts.CountryMustBeFL();
    }
    return '';
}
