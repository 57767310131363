import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export function MaterialBar({
    Icon,
    title,
    onClick,
}: {
    Icon?: OverridableComponent<SvgIconTypeMap<object>> & {
        muiName: string;
    };
    title: React.ReactNode;
    onClick?: () => void;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme => theme.palette.blue.main,
                color: theme =>
                    theme.palette.getContrastText(theme.palette.blue.main),
                padding: theme => theme.spacing(3 / 8, 3),
                '&:hover': {
                    color: theme =>
                        onClick ? theme.palette.blue.electric : undefined,
                    cursor: onClick ? 'pointer' : undefined,
                },
            }}
            onClick={onClick}
        >
            {Icon && <Icon />}
            <Typography style={{ fontSize: 16, marginLeft: 4 }}>
                {title}
            </Typography>
        </Box>
    );
}
