import { Body } from '../../ui/layout/TabContent.tsx';
import { ParkingDetailSlideIn } from './ParkingsListBody.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { ZoneMapView } from '../../zones/components/ZoneMapView.tsx';
import { ResultsError } from '../../ui/table/ResultsError.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import * as ParkingsState from '../../common/state/ParkingsState.ts';
import { useState } from 'react';
import { MountSlideIn } from '../../zones/components/ZoneDetailSlideIn.tsx';

export function ParkingsMapBody() {
    const { storeState } = useStore(store => ({
        parkings: ParkingsState.get(store),
    }));

    const [selectedParkingId, setSelectedParkingId] = useState<number | null>(
        null,
    );

    const filteredParkings = storeState.parkings.pending
        ? null
        : storeState.parkings.data
              .filter(
                  parking =>
                      parking.geodataText && parking.isOperatorStateActive,
              )
              .map(parking => {
                  return {
                      id: parking.id,
                      externalName: parking.name,
                      geodataText: parking.geodataText,
                  };
              });

    return (
        <Body disabled={false} loading={!filteredParkings}>
            <MountSlideIn>
                <ParkingDetailSlideIn
                    selectedParkingId={selectedParkingId}
                    setSelectedParkingId={setSelectedParkingId}
                />
            </MountSlideIn>
            {filteredParkings &&
                (filteredParkings?.length > 0 ? (
                    <ZoneMapView
                        zonesData={filteredParkings}
                        onZoneClick={(id: number) => {
                            setSelectedParkingId(id);
                        }}
                    />
                ) : (
                    <ResultsError
                        title={
                            <Localized
                                de="Keine Geodaten vorhanden."
                                fr="Aucune donnée de géolocalisation disponible."
                                it="Nessun dato di geolocalizzazione disponibile."
                                en="No geodata available."
                            />
                        }
                        description={
                            <Localized
                                de="Bitte nehmen Sie Kontakt mit unserem Helpdesk auf, um die entsprechenden Daten konfigurieren zu lassen."
                                fr="Veuillez contacter notre helpdesk pour faire configurer les données pertinentes."
                                it="Per far configurare i relativi dati voglia contattare il nostro helpdesk."
                                en="Please contact our helpdesk to have the respective data configured."
                            />
                        }
                    />
                ))}
        </Body>
    );
}
