import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as TomState from '../state/TomState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { localeCompare } from 'dg-web-shared/lib/String.ts';
import { ParsedTom } from 'dg-web-shared/model/Tom.ts';
import {
    NumberMultiSelectionSlideIn,
    Option,
} from '../../ui/slidein/MultiSelectionSlideIn.tsx';
import { tomFilterTexts } from '../i18n/TomTexts.ts';

const cn = ElementNamer('TomFilterHalfSlideIn');

export interface TomFilter {
    selectedToms: number[];
}

interface TomFilterHalfSlideInProps extends Flux.ContainerProps {
    filter: TomFilter;
    open: boolean;
    setSelection: (selectedToms: number[]) => void;
    onClose: () => void;
    title: string;
}

interface State {
    settings: SettingsState.State;
    toms: TomState.List.State;
}

export class TomFilterHalfSlideIn extends Flux.ContainerWithProps<
    TomFilterHalfSlideInProps,
    State
> {
    static displayName: string = cn('');

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            toms: TomState.List.get(this.props.allState),
        };
    }

    renderOptions(): Option<number>[] {
        return this.state.toms.data
            .filter(tom => tom.hasTransactions)
            .map((tom: ParsedTom) => {
                return {
                    id: tom.tomId,
                    displayText: this.getDisplayText(tom),
                };
            })
            .sort((a, b) => localeCompare(a.displayText, b.displayText));
    }

    private getDisplayText(tom: ParsedTom) {
        const texts = tomFilterTexts[this.state.settings.language];
        return tom.dismantledAtDate
            ? `${tom.operatorExternalId || tom.tomId} - ${
                  tom.name
              } - ${texts.dismantled()}`
            : `${tom.operatorExternalId || tom.tomId} - ${tom.name}`;
    }

    render() {
        const selection = this.props.filter.selectedToms;
        return (
            <NumberMultiSelectionSlideIn
                open={this.props.open}
                heading={this.props.title}
                selection={selection}
                options={this.renderOptions()}
                setNewSelection={selection =>
                    this.props.setSelection(selection)
                }
                onLeftActionClick={() => this.props.onClose()}
                outsideBody={false}
            />
        );
    }
}

export function tomSelectionText(
    selectedToms: number[],
    totalTomCount: number,
): string | null {
    if (!selectedToms.length) {
        return null;
    }
    return `${selectedToms.length} / ${totalTomCount}`;
}
