import { Translation, TranslationWithArgs1 } from '../../common/i18n/Text.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import {
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import { tomSyncStatusTexts, tomTexts } from '../i18n/TomTexts.ts';
import * as TomLayoutState from '../state/TomLayoutState.ts';
import * as FirmwareState from '../../firmware/state/SimpleTomFirmwareState.ts';
import * as FirmwareUtils from '../../firmware/FirmwareUtil.ts';
import * as TomState from '../state/TomState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Body } from '../../ui/layout/TabContent.tsx';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { TomSyncStatus } from './TomSyncStatus.tsx';
import * as Tom from 'dg-web-shared/model/Tom.ts';
import { ParsedTom, TomStatus } from 'dg-web-shared/model/Tom.ts';
import { TomDetailSlideIn } from './TomDetailSlideIn.tsx';
import { OutOfServiceHalfSlideIn } from './out-of-service/OutOfServiceHalfSlideIn.tsx';
import { OutOfServiceDateHalfSlideIn } from './out-of-service/OutOfServiceDateHalfSlideIn.tsx';
import { SyncAppSlideIn } from '../../sync-app/components/SyncAppSlideIn.tsx';
import * as SyncAppHelpSlideInState from '../../sync-app/state/SyncAppHelpSlideInState.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    TomFirmwareStatus,
    TomStatusIconPositioning,
} from './TomFirmwareStatus.tsx';
import {
    FilterLayout,
    FilterLayoutBody,
    FilterLayoutHeader,
} from '../../ui/filter/FilterLayout.tsx';
import {
    ButtonDropdown,
    ButtonDropdownItem,
    ButtonDropdownLinkItem,
} from '../../ui/slidein/ButtonDropdown.tsx';
import { RestrictedComponent } from '../../app/components/RestrictedComponent.tsx';
import { CustomPermissionCheckers } from '../../app/state/Permission.ts';
import { TomOperationStatusLabel } from './TomError.tsx';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { Icon16 } from '../../ui/icons/Icon.tsx';
import { chevronRight } from 'dg-web-shared/ui/icons/Icons16.tsx';

export interface TomTexts {
    TabTitle: Translation;
    InternalId: Translation;
    OperatorExternalId: Translation;
    Tom: Translation;
    Name: Translation;
    PaymentType: Translation;
    CashboxType: Translation;
    Address: Translation;
    Place: Translation;
    ZipCode: Translation;
    Location: Translation;
    State: Translation;
    StateMessages: Translation;
    LastContact: Translation;
    LastCashBoxLevel: Translation;
    LastBatteryVoltage: Translation;
    LastVoltage: Translation;

    InOperationSince: Translation;
    LastCollection: Translation;
    LastCollectionNever: Translation;

    CreationDate: Translation;
    FirmwareVersion: Translation;
    SyncStatus: Translation;
    SlideInHeaderCaption: Translation;

    Zone: Translation;
    Permissions: Translation;
    AcceptedCoins: Translation;
    AcceptedTokens: Translation;
    PaymentTransactionPeriod: Translation;
    Spaces: Translation;

    PayByPlateTimelimitNote: Translation;
    PayBySpaceTimelimitNote: Translation;

    Actions: Translation;
    CSVExport: Translation;
    WriteToStick: Translation;
    SaveEdit: Translation;
    FirmwareStatus: Translation;
    UpdateStick: Translation;
    PaymentTypeOptions: TranslationWithArgs1<Tom.TomPaymentType>;
    CashboxTypeOptions: TranslationWithArgs1<Tom.TomModelType>;
}

interface State {
    settings: SettingsState.State;
    layout: TomLayoutState.Layout.State;
    toms: TomState.List.State;
    firmwares: FirmwareState.List.State;
    syncSlideIn: SyncAppHelpSlideInState.Layout.State;
}

interface ItemProps {
    allState: Flux.Store;
    tom: Tom.ParsedTom;
    language: string;
    showOperatorExternalId: boolean;
    showPaymentType: boolean;
    showCashboxType: boolean;
    showSyncState: boolean;
}

const openUsbApp = function (
    operatorId: number,
    tomIds: number[],
    firmware: string,
) {
    let uri = 'parkingportal-sync://?action=multiTomUpdate';
    uri += '&operatorId=' + encodeURIComponent(operatorId.toString());
    uri += '&tomIds=' + encodeURIComponent(tomIds.join(','));
    uri += '&firmwareVersion=' + encodeURIComponent(firmware);
    window.location.assign(uri);
};

const TomItem = (p: ItemProps): JSX.Element => {
    const txt = tomTexts[p.language];
    return (
        <DefaultTableRow
            onClick={() =>
                p.allState.update(store =>
                    TomLayoutState.Layout.stateWrite(store, {
                        selectedTomId: p.tom.tomId,
                    }),
                )
            }
        >
            <TableColumn width={ColumnWidth._24px}>
                <IconTopOffsetContainer>
                    <TomOperationStatusLabel
                        tom={p.tom}
                        language={p.language}
                    />
                </IconTopOffsetContainer>
            </TableColumn>
            <TableColumn width={ColumnWidth._72px}>{p.tom.tomId}</TableColumn>
            <Conditional c={p.showOperatorExternalId}>
                <TableColumn width={ColumnWidth._72px}>
                    {p.tom.operatorExternalId}
                </TableColumn>
            </Conditional>
            <TableColumn width={ColumnWidth.variable}>{p.tom.name}</TableColumn>
            <Conditional c={p.showPaymentType}>
                <TableColumn width={ColumnWidth._136px}>
                    {txt.PaymentTypeOptions(p.tom.paymentType)}
                </TableColumn>
            </Conditional>
            <Conditional c={p.showCashboxType}>
                <TableColumn width={ColumnWidth._136px}>
                    {txt.CashboxTypeOptions(p.tom.model)}
                </TableColumn>
            </Conditional>
            <TableColumn width={ColumnWidth._136px}>
                {p.tom.lastCashBoxLevel
                    ? NumberFormatter.numberToPrice(
                          p.tom.lastCashBoxLevel / 100,
                          true,
                      )
                    : ' - '}
            </TableColumn>
            <TableColumn width={ColumnWidth._136px}>
                {p.tom.lastCollection
                    ? Date.Formatter.durationFromNow(
                          p.tom.lastCollection,
                          p.language,
                      )
                    : txt.LastCollectionNever()}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px}>
                {p.tom.lastBatteryVoltage
                    ? (p.tom.lastBatteryVoltage / 1000).toFixed(3) + ' V'
                    : '-'}
            </TableColumn>
            <TableColumn width={ColumnWidth._136px}>
                <TomFirmwareStatus tom={p.tom} language={p.language} />
            </TableColumn>
            <Conditional c={p.showSyncState}>
                <TableColumn width={ColumnWidth._136px}>
                    <TomStatusIconPositioning
                        label={
                            <TomSyncStatus
                                status={p.tom.synchronizationStatus}
                            />
                        }
                        text={tomSyncStatusTexts[
                            p.language
                        ].SynchronizationStatus(p.tom.synchronizationStatus)}
                    />
                </TableColumn>
            </Conditional>
            <TableColumn width={ColumnWidth._24px} captionFontSize>
                <IconTopOffsetContainer>
                    <Icon16 icon={chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </DefaultTableRow>
    );
};

export class TomsBody extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: TomLayoutState.Layout.get(this.props.allState),
            toms: TomState.List.get(this.props.allState),
            firmwares: FirmwareState.List.get(this.props.allState),
            syncSlideIn: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
        };
    }

    getDependingStateSlices(): AbstractLegacyServerState<ParsedTom[]>[] {
        return [this.state.toms];
    }

    renderSlideIns(): JSX.Element[] {
        return [
            <TomDetailSlideIn
                allState={this.props.allState}
                key="tom-detail"
            />,
            <SyncAppSlideIn allState={this.props.allState} key="sync-app" />,
            <OutOfServiceHalfSlideIn
                allState={this.props.allState}
                key="tom-plan-out-of-service"
            />,
            <OutOfServiceDateHalfSlideIn
                allState={this.props.allState}
                key="out-of-service-dates"
            />,
        ];
    }

    isBodyDisabled(): boolean {
        return (
            this.state.syncSlideIn.open || this.state.layout.outOfServiceOpen
        );
    }

    render() {
        const txt = tomTexts[this.state.settings.language];
        const tomsToDisplay = this.state.toms.data.filter(
            t => t.state === TomStatus.OPERATIONAL,
        );
        const firmwares = this.state.firmwares.data;
        const tomsWithOldFirmware = tomsToDisplay.filter(
            t => t.firmwareId !== t.targetFirmwareId,
        );

        const idsOfDisplayedToms = tomsToDisplay.map(tom => tom.tomId);
        // show operator external id in table if at least one tom has it set
        const showOperatorExternalId = !!tomsToDisplay.find(
            t => !!t.operatorExternalId,
        );

        const showPaymentType = !tomsToDisplay
            .map(t => t.paymentType)
            .every((val, _i, arr) => val === arr[0]);
        const showCashboxType = !tomsToDisplay
            .map(t => t.model)
            .every((val, _i, arr) => val === arr[0]);
        const showSyncState = !!tomsToDisplay.find(t => !t.lastOnlineStatus);
        return (
            <Body
                disabled={this.isBodyDisabled()}
                dependingStateSlices={this.getDependingStateSlices()}
                slideIns={this.renderSlideIns()}
            >
                <FilterLayout>
                    <FilterLayoutHeader
                        filterSummary={<div />}
                        rightDropdown={
                            <ButtonDropdown label={txt.Actions()}>
                                <RestrictedComponent
                                    route={OperatorAppRoutes.ParkingMeters}
                                    permissionChecker={
                                        CustomPermissionCheckers.syncAppInteractionAllowed
                                    }
                                >
                                    <div>
                                        <ButtonDropdownItem
                                            disabled={
                                                tomsWithOldFirmware.length === 0
                                            }
                                            onClick={
                                                () =>
                                                    openUsbApp(
                                                        tomsToDisplay[0]
                                                            .operatorId,
                                                        idsOfDisplayedToms,
                                                        FirmwareUtils.getVersionString(
                                                            firmwares.find(
                                                                t =>
                                                                    t.firmwareId ===
                                                                    tomsWithOldFirmware[0]
                                                                        .targetFirmwareId,
                                                            )!,
                                                        ),
                                                    )
                                                // this.readCollectionData()
                                            }
                                            label={txt.UpdateStick()}
                                        />
                                        <ButtonDropdownLinkItem
                                            label={txt.CSVExport()}
                                            href="/ui-api/operator-account/taxomex/tom/csv"
                                        />
                                    </div>
                                </RestrictedComponent>
                            </ButtonDropdown>
                        }
                    />
                    <FilterLayoutBody>
                        <Table>
                            <TableHeader>
                                <EmptyTableHeaderColumn
                                    width={ColumnWidth._24px}
                                    delimiterBottom={true}
                                />
                                <TableHeaderColumn
                                    name={txt.InternalId()}
                                    direction={SortDirection.Ascending}
                                    selected={true}
                                    onClick={null}
                                    width={ColumnWidth._72px}
                                    delimiterBottom={true}
                                />
                                <Conditional c={showOperatorExternalId}>
                                    <TableHeaderColumn
                                        name={txt.OperatorExternalId()}
                                        onClick={null}
                                        width={ColumnWidth._72px}
                                        delimiterBottom={true}
                                    />
                                </Conditional>
                                <TableHeaderColumn
                                    name={txt.Name()}
                                    onClick={null}
                                    width={ColumnWidth.variable}
                                    delimiterBottom={true}
                                />
                                <Conditional c={showPaymentType}>
                                    <TableHeaderColumn
                                        name={txt.PaymentType()}
                                        onClick={null}
                                        width={ColumnWidth._136px}
                                        delimiterBottom={true}
                                    />
                                </Conditional>
                                <Conditional c={showCashboxType}>
                                    <TableHeaderColumn
                                        name={txt.CashboxType()}
                                        onClick={null}
                                        width={ColumnWidth._136px}
                                        delimiterBottom={true}
                                    />
                                </Conditional>
                                <TableHeaderColumn
                                    name={txt.LastCashBoxLevel()}
                                    onClick={null}
                                    width={ColumnWidth._136px}
                                    delimiterBottom={true}
                                />
                                <TableHeaderColumn
                                    name={txt.LastCollection()}
                                    onClick={null}
                                    width={ColumnWidth._136px}
                                    delimiterBottom={true}
                                />
                                <TableHeaderColumn
                                    name={txt.LastVoltage()}
                                    onClick={null}
                                    width={ColumnWidth._108px}
                                    delimiterBottom={true}
                                />
                                <TableHeaderColumn
                                    name={txt.FirmwareStatus()}
                                    onClick={null}
                                    width={ColumnWidth._136px}
                                    delimiterBottom={true}
                                />
                                <Conditional c={showSyncState}>
                                    <TableHeaderColumn
                                        name={txt.SyncStatus()}
                                        onClick={null}
                                        width={ColumnWidth._136px}
                                        delimiterBottom={true}
                                    />
                                </Conditional>
                                <EmptyTableHeaderColumn
                                    width={ColumnWidth._24px}
                                    delimiterBottom={true}
                                />
                            </TableHeader>
                            <TableBody>
                                {tomsToDisplay.map((t: Tom.ParsedTom) => {
                                    return (
                                        <TomItem
                                            tom={t}
                                            language={
                                                this.state.settings.language
                                            }
                                            allState={this.props.allState}
                                            showOperatorExternalId={
                                                showOperatorExternalId
                                            }
                                            showPaymentType={showPaymentType}
                                            showCashboxType={showCashboxType}
                                            showSyncState={showSyncState}
                                            key={t.tomId}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </FilterLayoutBody>
                </FilterLayout>
            </Body>
        );
    }
}
