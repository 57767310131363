import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { PermitItem } from '../permit/PermitItem.tsx';
import { Permit } from '../../state/ResultState.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../../ui/Colors.ts';

interface Props {
    permits: Permit[];
    expanded: boolean;
    update: Flux.Updater;
}

const ClearancePermitsListContainer = (p: {
    expanded: boolean;
    children?: React.ReactNode;
}) => (
    <div
        className={css({
            position: 'relative',
            display: p.expanded ? 'block' : 'none',
            paddingLeft: '24px',
            marginBottom: p.expanded ? '12px' : 0,
        })}
    >
        <div
            className={css({
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.45)',
            })}
        >
            {p.children}
        </div>
        <div
            className={css({
                position: 'absolute',
                left: '20px',
                width: '4px',
                top: '0px',
                bottom: 0,
                background: ColorHex.darkblue,
            })}
        />
    </div>
);

export const ClearancePermitsList = (p: Props) => {
    if (p.permits.length === 0 || !p.expanded) {
        return <noscript />;
    }
    return (
        <ClearancePermitsListContainer expanded={true}>
            {p.permits.map((permit: Permit) => (
                <PermitItem permit={permit} update={p.update} key={permit.id} />
            ))}
        </ClearancePermitsListContainer>
    );
};
