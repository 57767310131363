import { Translation, Translations } from '../../../lib/Text.ts';

interface Texts {
    AllLettersUppercase: Translation;
}

export const generalTexts: Translations<Texts> = {
    de: {
        AllLettersUppercase: (): string => 'Nur Grossbuchstaben erlaubt.',
    },
    fr: {
        AllLettersUppercase: (): string =>
            'Seulement lettres capitales autorisées.',
    },
    it: {
        AllLettersUppercase: (): string =>
            'Sono consentite solo lettere maiuscole.',
    },
    en: {
        AllLettersUppercase: (): string =>
            'Only uppercase letters are allowed.',
    },
};

export interface LicensePlateErrorTexts {
    NoWhiteSpace: Translation;
    MinThreeLetters: Translation;
    AllLettersUppercase: Translation;
    LettersAfterCanton: Translation;
    LettersAfterCountryFL: Translation;
    NoValidCanton: Translation;
    HasMoreThanSixNumbers: Translation;
    HasMoreThanFiveNumbers: Translation;
    CountryMustBeFL: Translation;
}

export const errors: Translations<LicensePlateErrorTexts> = {
    de: {
        AllLettersUppercase: () => generalTexts.de.AllLettersUppercase(),
        CountryMustBeFL: () => 'Das Kennzeichen muss immer mit FL beginnen',
        HasMoreThanFiveNumbers: () => 'Maximal 5 Zahlen erlaubt',
        HasMoreThanSixNumbers: () => 'Maximal 6 Zahlen erlaubt',
        LettersAfterCanton: () => 'Nur Zahlen nach Kantonskürzel erlaubt',
        LettersAfterCountryFL: () => 'Nur Zahlen nach FL-Kürzel erlaubt',
        MinThreeLetters: () => 'Mindestens 3 Zeichen eingeben',
        NoValidCanton: () => 'Keine gültige Kantonsbezeichnung',
        NoWhiteSpace: () => 'Keine Leerzeichen erlaubt',
    },
    fr: {
        AllLettersUppercase: () => generalTexts.fr.AllLettersUppercase(),
        CountryMustBeFL: () =>
            "L'immatriculation doit toujours commencer avec FL",
        HasMoreThanFiveNumbers: () => 'Maximum 5 chiffres autorisés',
        HasMoreThanSixNumbers: () => 'Maximum 6 chiffres autorisés',
        LettersAfterCanton: () =>
            'Seulement des chiffres autorisés après le code canton',
        LettersAfterCountryFL: () =>
            'Seulement des chiffres autorisés après le code FL',
        MinThreeLetters: () => 'Saisir au moins 3 caractères',
        NoValidCanton: () => "Le code canton n'est pas valable",
        NoWhiteSpace: () => 'Espaces pas autorisées',
    },
    it: {
        AllLettersUppercase: () => generalTexts.it.AllLettersUppercase(),
        CountryMustBeFL: () => 'La targa deve sempre cominciare con FL',
        HasMoreThanFiveNumbers: () => 'Sono consentite al massimo 5 cifre',
        HasMoreThanSixNumbers: () => 'Sono consentite al massimo 6 cifre',
        LettersAfterCanton: () =>
            'Sono consentite solo cifre dopo il codice del cantone',
        LettersAfterCountryFL: () =>
            'Sono consentite solo cifre dopo il codice FL',
        MinThreeLetters: () => 'Inserire almeno 3 caratteri',
        NoValidCanton: () => 'Il codice del cantone non è valido',
        NoWhiteSpace: () => 'Non sono consentiti spazi',
    },
    en: {
        AllLettersUppercase: () => generalTexts.en.AllLettersUppercase(),
        CountryMustBeFL: () => 'The license plate must always start with FL',
        HasMoreThanFiveNumbers: () => 'A maximum of 5 digits are allowed',
        HasMoreThanSixNumbers: () => 'A maximum of 6 digits are allowed',
        LettersAfterCanton: () =>
            'Only digits after the canton code are allowed',
        LettersAfterCountryFL: () =>
            'Only digits after the code FL are allowed',
        MinThreeLetters: () => 'Please enter at least 3 characters',
        NoValidCanton: () => 'The canton code is invalid',
        NoWhiteSpace: () => 'No space allowed',
    },
};
