import { Box } from '@mui/material';

export function ParkingPortalLayout({
    children,
    style,
}: {
    children?: React.ReactNode | React.ReactNodeArray;
    style?: React.CSSProperties;
}) {
    return (
        <Box
            sx={theme => ({
                ...layoutStyles(),
                padding: theme.spacing(2),
                ...style,
            })}
        >
            {children}
        </Box>
    );
}

function layoutStyles() {
    return {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
    };
}
