import moment from 'moment';

import { Parser } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import {
    getOrNull,
    isDefined,
    isUndefined,
} from 'dg-web-shared/lib/MaybeV2.ts';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import * as Http from '../../api/Http.ts';
import {
    isActive,
    isExpired,
    isExpiredOld,
    isFuture,
    isStornod,
} from '../../clearance-permit-list/state/PermitsState.ts';
import { Localized } from '../../common/components/Localized.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { TextButton } from '../../ui/buttons/TextButton.tsx';
import { Color } from '../../ui/Colors.ts';
import {
    ButtonDropdown,
    ButtonDropdownItem,
    ButtonDropdownLinkItem,
    ButtonDropdownSeparator,
} from '../../ui/slidein/ButtonDropdown.tsx';
import {
    SlideInHeader,
    SlideInHeaderButtonsContainer,
    SlideInHeaderIcon,
    SlideInHeaderTexts,
} from '../../ui/slidein/Slidein.tsx';
import { deselectPermit } from '../actions/PermitDetailActions.tsx';
import * as PermitSlideInTexts from '../i18n/PermitSlideInTexts.ts';
import { Address } from '../state/AddressState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import { Permit } from '../state/PermitEditState.ts';
import { EmDash, Nbsp } from 'dg-web-shared/lib/Punctuation.ts';
import { DateTime } from 'luxon';
import { PaymentChannel } from '../../common/models/PaymentChannel.ts';

export function PermitViewHeader(props: {
    oldVersions: PermitEditState.SubstitutedContractRight[];
    versionBrowsingMode: PermitEditState.VersionBrowsingMode;
    setVersionBrowsingMode: (m: PermitEditState.VersionBrowsingMode) => void;
}) {
    const oldVersionsCount = props.oldVersions.length;

    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
    }));

    const txt = PermitSlideInTexts.header[storeState.settings.language];

    return (
        <SlideInHeader
            onClose={() => {
                update(deselectPermit);
            }}
            backgroundColor={getColor(
                props.versionBrowsingMode.tag,
                storeState.selectedPermit,
            )}
            backgroundOpacity={0.15}
        >
            {props.versionBrowsingMode.tag ===
                PermitEditState.VersionBrowsingModeTag.INACTIVE && (
                <>
                    <SlideInHeaderIcon
                        icon={
                            <HeaderIcon
                                selectedPermit={storeState.selectedPermit}
                            />
                        }
                        color={getColor(
                            props.versionBrowsingMode.tag,
                            storeState.selectedPermit,
                        )}
                    />

                    <SlideInHeaderTexts
                        title={getStateString(
                            storeState.settings.language,
                            storeState.selectedPermit,
                        )}
                        hasLeftIcon={true}
                    />
                </>
            )}

            <VersionHeader
                oldVersions={props.oldVersions}
                versionBrowsingMode={props.versionBrowsingMode}
            />

            <SlideInHeaderButtonsContainer>
                {oldVersionsCount > 0 &&
                    props.versionBrowsingMode.tag ===
                        PermitEditState.VersionBrowsingModeTag.INACTIVE && (
                        <TextButton
                            backgroundColor={Color.versionLatest}
                            label={
                                <Localized
                                    de={`Änderungen (${oldVersionsCount})`}
                                    fr={`Historique (${oldVersionsCount})`}
                                    it={`Cronologia (${oldVersionsCount})`}
                                    en={`History (${oldVersionsCount})`}
                                />
                            }
                            onClick={props.setVersionBrowsingMode.bind(null, {
                                tag: PermitEditState.VersionBrowsingModeTag
                                    .LATEST,
                            })}
                        />
                    )}

                {props.versionBrowsingMode.tag !==
                    PermitEditState.VersionBrowsingModeTag.INACTIVE && (
                    <>
                        <TextButton
                            backgroundColor={Color.white}
                            color={Color.darkblue}
                            backgroundColorFadeWhenDisabled
                            colorFadeWhenDisabled
                            label={
                                <Localized
                                    de="Ältere Version"
                                    fr="Précédente"
                                    it="Precedente"
                                    en="Older version"
                                />
                            }
                            disabled={
                                props.versionBrowsingMode.tag ===
                                    PermitEditState.VersionBrowsingModeTag
                                        .OLDER &&
                                props.versionBrowsingMode.oldVersionIndex ===
                                    oldVersionsCount - 1
                            }
                            onClick={() => {
                                if (
                                    props.versionBrowsingMode.tag ===
                                    PermitEditState.VersionBrowsingModeTag
                                        .LATEST
                                ) {
                                    props.setVersionBrowsingMode({
                                        tag: PermitEditState
                                            .VersionBrowsingModeTag.OLDER,
                                        oldVersionIndex: 0,
                                    });
                                } else if (
                                    props.versionBrowsingMode.tag ===
                                    PermitEditState.VersionBrowsingModeTag.OLDER
                                ) {
                                    props.setVersionBrowsingMode({
                                        tag: PermitEditState
                                            .VersionBrowsingModeTag.OLDER,
                                        oldVersionIndex:
                                            props.versionBrowsingMode
                                                .oldVersionIndex + 1,
                                    });
                                }
                            }}
                        />

                        <TextButton
                            backgroundColor={Color.white}
                            color={Color.darkblue}
                            backgroundColorFadeWhenDisabled
                            colorFadeWhenDisabled
                            label={
                                <Localized
                                    de="Neuere Version"
                                    fr="Suivant"
                                    it="Successiva"
                                    en="Newer version"
                                />
                            }
                            disabled={
                                props.versionBrowsingMode.tag ===
                                PermitEditState.VersionBrowsingModeTag.LATEST
                            }
                            onClick={() => {
                                if (
                                    props.versionBrowsingMode.tag ===
                                    PermitEditState.VersionBrowsingModeTag.OLDER
                                ) {
                                    if (
                                        props.versionBrowsingMode
                                            .oldVersionIndex === 0
                                    ) {
                                        props.setVersionBrowsingMode({
                                            tag: PermitEditState
                                                .VersionBrowsingModeTag.LATEST,
                                        });
                                    } else {
                                        props.setVersionBrowsingMode({
                                            tag: PermitEditState
                                                .VersionBrowsingModeTag.OLDER,
                                            oldVersionIndex:
                                                props.versionBrowsingMode
                                                    .oldVersionIndex - 1,
                                        });
                                    }
                                }
                            }}
                        />

                        <TextButton
                            label={
                                <Localized
                                    de="Schliessen"
                                    fr="Fermer"
                                    it="Chiudi"
                                    en="Close"
                                />
                            }
                            onClick={props.setVersionBrowsingMode.bind(null, {
                                tag: PermitEditState.VersionBrowsingModeTag
                                    .INACTIVE,
                            })}
                        />
                    </>
                )}

                {props.versionBrowsingMode.tag ===
                    PermitEditState.VersionBrowsingModeTag.INACTIVE && (
                    <ButtonDropdown label={txt.EditLabel()}>
                        <PrintMenuItem />
                        <ButtonDropdownSeparator />
                        {storeState.selectedPermit.data
                            ?.activatablePermitType && (
                            <>
                                <ExportActivationsItem />
                                <ButtonDropdownSeparator />
                            </>
                        )}
                        <RenewMenuItem />
                        <CopyMenuItem />
                        <TerminateMenuItem />
                        <StornoMenuItem />
                        <RefundMenuItem />
                    </ButtonDropdown>
                )}
            </SlideInHeaderButtonsContainer>
        </SlideInHeader>
    );
}

function VersionHeader(props: {
    oldVersions: PermitEditState.SubstitutedContractRight[];
    versionBrowsingMode: PermitEditState.VersionBrowsingMode;
}): JSX.Element | null {
    if (
        props.versionBrowsingMode.tag ===
        PermitEditState.VersionBrowsingModeTag.INACTIVE
    ) {
        return null;
    }

    const length = props.oldVersions.length;
    const index =
        props.versionBrowsingMode.tag ===
        PermitEditState.VersionBrowsingModeTag.OLDER
            ? props.versionBrowsingMode.oldVersionIndex
            : -1;

    const fromDatestamp =
        props.versionBrowsingMode.tag ===
        PermitEditState.VersionBrowsingModeTag.LATEST
            ? moment(props.oldVersions[0].substitutedAt).format(
                  'DD.MM.YYYY HH:mm',
              )
            : moment(props.oldVersions[index].createdAt).format(
                  'DD.MM.YYYY HH:mm',
              );
    const toDatestamp =
        props.versionBrowsingMode.tag ===
        PermitEditState.VersionBrowsingModeTag.OLDER
            ? moment(props.oldVersions[index].substitutedAt).format(
                  'DD.MM.YYYY HH:mm',
              )
            : '';

    const olderDuration = `(${fromDatestamp} - ${toDatestamp})`;
    const olderCounter = `${Nbsp}${index + 1}/${length}`;
    const separation = `${Nbsp}${EmDash}${Nbsp}`;

    const subtitleMessage =
        props.versionBrowsingMode.tag ===
        PermitEditState.VersionBrowsingModeTag.LATEST
            ? {
                  de: `(seit ${fromDatestamp})`,
                  fr: `(depuis ${fromDatestamp})`,
                  it: `(dal ${fromDatestamp})`,
                  en: `(since ${fromDatestamp})`,
              }
            : {
                  de: `${olderDuration}`,
                  fr: `${olderDuration}`,
                  it: `${olderDuration}`,
                  en: `${olderDuration}`,
              };

    const titleMessage =
        props.versionBrowsingMode.tag ===
        PermitEditState.VersionBrowsingModeTag.LATEST
            ? {
                  de: `Änderungsverlauf${separation}aktuelle Version`,
                  fr: `Historique des modifications${separation}version actuelle`,
                  it: `Cronologia modifiche${separation}versione attuale`,
                  en: `Changes history${separation}current version`,
              }
            : {
                  de: `Änderungsverlauf${separation}alte Version${olderCounter}`,
                  fr: `Historique des modifications${separation}ancienne version${olderCounter}`,
                  it: `Cronologia modifiche${separation}vecchia versione${olderCounter}`,
                  en: `Changes history${separation}old version${olderCounter}`,
              };

    return (
        <SlideInHeaderTexts
            title={<Localized {...titleMessage} />}
            subtitle={<Localized {...subtitleMessage} />}
            hasLeftIcon={false}
        />
    );
}

function PrintMenuItem(): JSX.Element | null {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
    }));

    const txt = PermitSlideInTexts.header[storeState.settings.language];
    const permit = storeState.selectedPermit.data;

    if (permit) {
        if (permit.vignetteSideprint.length === 0) {
            return (
                <ButtonDropdownLinkItem
                    label={txt.PrintTooltip()}
                    href={Http.OperatorAccount.Permits.pdfPrint(permit.id)}
                />
            );
        } else {
            return (
                <ButtonDropdownItem
                    label={txt.PrintTooltip()}
                    onClick={() => {
                        update(store =>
                            PermitEditState.Layout.stateWrite(store, {
                                showPrintSlideIn: true,
                            }),
                        );
                    }}
                />
            );
        }
    }

    return null;
}

function ExportActivationsItem() {
    const { storeState } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
    }));

    return (
        <ButtonDropdownLinkItem
            href={`/ui-api/operator-account/permits/${storeState.selectedPermit.data?.id}/activations-export?lang=${storeState.settings.language}`}
            label={
                <Localized
                    de="Aktivierungen exportieren"
                    fr="Exporter les activations"
                    it="Esporta attivazioni"
                    en="Export activations"
                />
            }
        />
    );
}

function RenewMenuItem() {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
        currentLogin: CurrentOperatorLoginState.get(s),
    }));

    const txt = PermitSlideInTexts.header[storeState.settings.language];
    const p = storeState.selectedPermit.data;

    if (!loginCanWrite(storeState.currentLogin) || !p || !p.renewable) {
        return null;
    } else {
        return (
            <ButtonDropdownItem
                label={txt.RenewTooltip()}
                onClick={() => {
                    update(store => renew(store, p));
                }}
            />
        );
    }
}

function CopyMenuItem() {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
        currentLogin: CurrentOperatorLoginState.get(s),
    }));

    const txt = PermitSlideInTexts.header[storeState.settings.language];
    const p = storeState.selectedPermit.data;

    if (!loginCanWrite(storeState.currentLogin) || !p) {
        return null;
    } else {
        return (
            <ButtonDropdownItem
                label={txt.CopyPermitLabel()}
                onClick={() => {
                    update(store => copy(store, p));
                }}
            />
        );
    }
}

function TerminateMenuItem() {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
        currentLogin: CurrentOperatorLoginState.get(s),
    }));

    const txt = PermitSlideInTexts.header[storeState.settings.language];
    const p = storeState.selectedPermit.data;

    const openTerminateSlideIn = (store: Flux.Store) => {
        PermitEditState.Edit.stateWrite(store, {
            action: PermitEditState.Edit.Action.terminate,
        });

        return 'openTerminateSlideIn';
    };

    if (
        !loginCanWrite(storeState.currentLogin) ||
        !p ||
        isExpiredOld(p, moment()) ||
        isStornod(p) ||
        moment().isAfter(Parser.isoToMoment(p.validTo).subtract(1, 'day'))
    ) {
        return null;
    }

    return (
        <ButtonDropdownItem
            label={txt.TerminateTooltip()}
            onClick={() => {
                update(openTerminateSlideIn, p);
            }}
        />
    );
}

function StornoMenuItem() {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
        currentLogin: CurrentOperatorLoginState.get(s),
    }));

    const txt = PermitSlideInTexts.header[storeState.settings.language];
    const p = storeState.selectedPermit.data;

    if (
        !loginCanWrite(storeState.currentLogin) ||
        !p ||
        (!isActive(p, moment()) && !isFuture(p, moment()))
    ) {
        return null;
    }

    return (
        <ButtonDropdownItem
            label={txt.StornoTooltip()}
            onClick={() => {
                update(store =>
                    PermitEditState.Edit.stateWrite(store, {
                        action: PermitEditState.Edit.Action.storno,
                    }),
                );
            }}
        />
    );
}

function RefundMenuItem() {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        selectedPermit: PermitEditState.Server.get(s),
        currentLogin: CurrentOperatorLoginState.get(s),
    }));

    const txt = PermitSlideInTexts.header[storeState.settings.language];
    const p = storeState.selectedPermit.data;

    if (
        !loginCanIssueRefunds(storeState.currentLogin) ||
        !loginCanWrite(storeState.currentLogin) ||
        !p ||
        !permitIsRefundable(p) ||
        isUndefined(p.paidByCustomer) ||
        p.refunded
    ) {
        return null;
    }

    if (p.customer?.accountTerminatedAt) {
        return (
            <ButtonDropdownItem
                disabled={true}
                label={
                    <Localized
                        de="Rückzahlung nicht möglich: Benutzerkonto wurde gekündigt"
                        fr="Remboursement impossible: le compte utilisateur a été annulé"
                        it="Rimborso non possibile: il conto utente è stato annullato"
                        en="Refund not possible: customer account was canceled"
                    />
                }
                onClick={() => null}
            />
        );
    }

    if (p.customer?.isLocked) {
        return (
            <ButtonDropdownItem
                disabled={true}
                label={
                    <Localized
                        de="Rückzahlung nicht möglich: Benutzerkonto wurde gesperrt"
                        fr="Remboursement impossible: le compte utilisateur a été bloqué"
                        it="Rimborso non possibile: il conto utente è stato bloccato"
                        en="Refund not possible: customer account was locked"
                    />
                }
                onClick={() => null}
            />
        );
    }

    return (
        <ButtonDropdownItem
            label={txt.refundTooltip()}
            onClick={() => {
                update(store =>
                    PermitEditState.Edit.stateWrite(store, {
                        action: PermitEditState.Edit.Action.refund,
                    }),
                );
            }}
        />
    );
}

function HeaderIcon(props: {
    selectedPermit: PermitEditState.Server.State;
}): JSX.Element {
    const p = props.selectedPermit.data;

    if (!p) {
        return Icons24.permitState.active;
    } else if (isActive(p, moment())) {
        return Icons24.permitState.active;
    } else if (isFuture(p, moment())) {
        return Icons24.permitState.future;
    } else if (isStornod(p)) {
        return Icons24.permitState.canceled;
    } else if (isExpiredOld(p, moment())) {
        return Icons24.permitState.expired;
    } else {
        return Icons24.permitState.future;
    }
}

function renew(store: Flux.Store, permit: PermitEditState.Permit): string {
    const permitType = PermitTypeState.getById(store, permit.permitTypeId);
    if (isDefined(permitType)) {
        PermitCreateState.resetAllState(store);
        PermitCreateState.Layout.stateWrite(store, { createEnabled: true });
        PermitCreateState.Config.stateWrite(store, {
            permitTypeId: permit.permitTypeId,
            startDate: permitType.fromRange.after(
                Parser.isoToMoment(permit.validTo),
            )
                ? permitType.fromRange.start.clone()
                : Parser.isoToMoment(permit.validTo).add(1, 'day'),
            durationId:
                permitType.validDurationsV2 &&
                permitType.validDurationsV2.length === 1
                    ? permitType.validDurationsV2[0].uniqueId
                    : null,
            licensePlates: permit.licensePlates,
            badges: permit.badges,
            remark: permit.remark,
            additionalInfo: permit.additionalInfo,
            address: { ...(getOrNull(permit.address) as Address) },
        });
    }
    return 'PermitViewHeader-renew';
}

function copy(store: Flux.Store, permit: PermitEditState.Permit): string {
    const permitType = PermitTypeState.getById(store, permit.permitTypeId);
    const validFrom = Parser.isoToMoment(permit.validFrom);

    if (isDefined(permitType)) {
        PermitCreateState.resetAllState(store);
        PermitCreateState.Layout.stateWrite(store, { createEnabled: true });
        PermitCreateState.Config.stateWrite(store, {
            startDate: permitType.fromRange.after(validFrom)
                ? permitType.fromRange.start.clone()
                : validFrom,
            licensePlates: permit.licensePlates,
            badges: permit.badges,
            remark: permit.remark,
            additionalInfo: permit.additionalInfo,
            address: { ...(getOrNull(permit.address) as Address) },
            onstreetZones: permit.onstreetZones.map(z => z.id),
        });

        if (isDefined(permitType.toRange) && isDefined(permit.validTo)) {
            const validTo = Parser.isoToMoment(permit.validTo);
            PermitCreateState.Config.stateWrite(store, {
                endDate: permitType.toRange.after(validTo)
                    ? permitType.toRange.end.clone()
                    : validTo,
            });
        }
    }

    PermitCreateState.Layout.stateWrite(store, {
        permitTypeSelectionOpen: true,
    });

    return 'PermitViewHeader-renew';
}

function loginCanIssueRefunds(currentLogin: CurrentOperatorLoginState.State) {
    return currentLogin.data && currentLogin.data.permissions.refundPermits;
}

function loginCanWrite(currentLogin: CurrentOperatorLoginState.State): boolean {
    return !!currentLogin.data && currentLogin.data.permissions.permitsWrite;
}

function permitIsRefundable(p: Permit) {
    if (
        p.paymentChannel !== PaymentChannel.PARKINGPAY &&
        p.paymentChannel !== PaymentChannel.TWINT &&
        p.paymentChannel !== PaymentChannel.QUICKCHECKOUT
    ) {
        return false;
    }
    if (isStornod(p)) {
        return true;
    }
    return (
        DateTime.fromISO(p.validTo) >= DateTime.now().minus({ months: 3 }) &&
        isExpired({ validTo: p.validTo, validFrom: p.validFrom })
    );
}

function getColor(
    versionBrowsingModeTag: PermitEditState.VersionBrowsingModeTag,
    selectedPermit: PermitEditState.Server.State,
): Color {
    switch (versionBrowsingModeTag) {
        case PermitEditState.VersionBrowsingModeTag.INACTIVE:
            if (!selectedPermit.data) {
                return Color.bActive;
            }

            if (isActive(selectedPermit.data, moment())) {
                return Color.bActive;
            }

            if (isFuture(selectedPermit.data, moment())) {
                return Color.bFuture;
            }

            if (isStornod(selectedPermit.data)) {
                return Color.bCanceled;
            }

            if (isExpiredOld(selectedPermit.data, moment())) {
                return Color.bExpired;
            }

            return Color.bCanceled;

        case PermitEditState.VersionBrowsingModeTag.LATEST:
            return Color.versionLatest;

        case PermitEditState.VersionBrowsingModeTag.OLDER:
            return Color.bExpired;
    }
}

function getStateString(
    language: string,
    selectedPermit: PermitEditState.Server.State,
): string {
    const txt = PermitSlideInTexts.header[language];

    const p = selectedPermit.data;
    if (!p) {
        return '...';
    } else if (isActive(p, moment())) {
        return txt.Active();
    } else if (isFuture(p, moment())) {
        return txt.Future();
    } else if (isStornod(p)) {
        return txt.Stornod();
    } else if (isExpiredOld(p, moment())) {
        return txt.Expired();
    } else {
        return 'NOT IMPLEMENTED';
    }
}
