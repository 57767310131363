import { OperatorDataBody } from './OperatorDataBody.tsx';
import { Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { getNavigationText } from '../../layout/components/BreadCrumb.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';

export function OperatorMasterData() {
    const { store } = useStore(() => null);
    return (
        <Container>
            <Tabs>
                <TabItem
                    active={true}
                    description={getNavigationText(OperatorAppRoutes.Home)}
                    onClick={null}
                />
            </Tabs>
            <OperatorDataBody allState={store} />
        </Container>
    );
}
