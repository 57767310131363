import { Translations, Translation } from '../common/i18n/Text.ts';
import { SupportTextBlockTexts } from './SupportTextBlock.tsx';
import { ContentTextBlockTexts } from './ContentTextBlock.tsx';

const Contact = (p: { lang: 'de' | 'fr' | 'it' }) => (
    <div>
        Digitalparking {p.lang === 'de' ? 'AG' : 'SA'}
        <br /> Rütistrasse 13
        <br /> 8952 Schlieren
        <br /> 0848 330 444
        <br />
        <a href="mailto:info@parkingportal.ch">info@parkingportal.ch</a>
    </div>
);

export const supportTextBlockTexts: Translations<SupportTextBlockTexts> = {
    de: {
        header: () => 'Beratung',
        body: () => <Contact lang="de" />,
    },
    fr: {
        header: () => 'Conseil',
        body: () => <Contact lang="fr" />,
    },
    it: {
        header: () => 'Consulenza',
        body: () => <Contact lang="it" />,
    },
};

export const contentTextBlockTexts: Translations<ContentTextBlockTexts> = {
    de: {
        header: () => 'Monitoring von Parksystemen',
        body: () => (
            <div>
                <p>
                    Mit dem Parkingportal können Sie auf gerätebasierte Systeme
                    und auf digitale Lösungen wie Parkingpay zugreifen. So
                    können Sie den Übergang in die digitale
                    Parkraumbewirtschaftung effizient und kostengünstig
                    meistern.
                </p>
                <p>
                    Das Parkingportal basiert auf einer Parking-Datenbank,
                    welche in enger Zusammenarbeit mit den führenden
                    Parkingsystemanbietern betrieben wird. Mit diesen Daten
                    können Geräte und Zahlungsströme aus verschiedenen Systemen
                    und Kanälen analysiert und verwaltet werden.
                </p>
            </div>
        ),
        footerLeft: () => 'Mehr Informationen auf digitalparking.ch',
    },
    fr: {
        header: () => 'Supervision des systèmes de parking',
        body: () => (
            <div>
                <p>
                    Avec le Parkingportal vous avez la possibilité d’accéder aux
                    systèmes basés sur des appareils et aux applications de
                    paiement numériques, telles que Parkingpay. Vous pouvez
                    ainsi gérer efficacement et à moindre coût la transition
                    vers l’exploitation numérique des places de stationnement.
                </p>
                <p>
                    Le Parkingportal se base sur une banque de données de
                    parking exploitée en collaboration étroite avec les
                    principaux prestataires du domaine des systèmes de parking.
                    Ces données permettent d’analyser et de gérer les flux
                    d’appareils et de paiement de différents systèmes et canaux.
                </p>
            </div>
        ),
        footerLeft: () => "Plus d'informations sur digitalparking.ch",
    },
    it: {
        header: () => 'Monitoraggio dei sistemi di parcheggio',
        body: () => (
            <div>
                <p>
                    Con il Parkingportal potete accedere sia ai sistemi basati
                    su dispositivi, che alle soluzioni digitali come Parkingpay.
                    Ciò vi permette di gestire in modo efficiente e conveniente
                    il passaggio alla gestione digitale dei pacheggi.
                </p>
                <p>
                    Il Parkingportal si basa su una banca dati parcheggi che
                    viene gestita in stretta collaborazione con i principali
                    fornitori di sistemi di parcheggio. Tali dati consentono di
                    analizzare e gestire apparecchi e flussi di pagamento
                    provenienti da diversi sistemi e canali.
                </p>
            </div>
        ),
        footerLeft: () => 'Maggiori informazioni su digitalparking.ch',
    },
};

export interface LoginBlockTexts {
    forgotPassword: Translation;
    header: Translation;
    invalidCredentials: Translation;
    password: Translation;
    saveLogin: Translation;
    submitLabel: Translation;
    username: Translation;
}

export const loginBlockTexts: Translations<LoginBlockTexts> = {
    de: {
        forgotPassword: () => 'Passwort vergessen?',
        header: () => 'Login',
        invalidCredentials: () => 'Benutzername oder Passwort sind ungültig.',
        password: () => 'Passwort',
        saveLogin: () => 'Login speichern',
        submitLabel: () => 'Login',
        username: () => 'Username',
    },
    fr: {
        forgotPassword: () => 'Mot de passe oublié?',
        header: () => 'Login',
        invalidCredentials: () =>
            "Le nom d'utilisateur ou le mot de passe ne sont valables.",
        password: () => 'Mot de passe',
        saveLogin: () => 'Rester connecté',
        submitLabel: () => 'Login',
        username: () => "Nom d'utilisateur",
    },
    it: {
        forgotPassword: () => 'Password dimenticata?',
        header: () => 'Login',
        invalidCredentials: () => 'Nome utente o password non validi.',
        password: () => 'Password',
        saveLogin: () => 'Rimanere connessi',
        submitLabel: () => 'Login',
        username: () => 'Nome utente',
    },
};
