import { Translations } from '../../common/i18n/Text.ts';
import { AddressTexts } from '../components/Address.tsx';

export const addressTexts: Translations<AddressTexts> = {
    de: {
        address: () => 'Adresse',
        addNewAddress: () => 'Neue Adresse hinzufügen',
        selectAddress: () => 'Bestehende Adresse übernehmen',
        editAddress: () => 'Adresse bearbeiten',
        insertAddress: () => 'Adresse hinzufügen',

        gender: () => 'Anrede',
        male: () => 'Herr',
        female: () => 'Frau',

        firstname: () => 'Vorname',
        lastname: () => 'Nachname',
        company1: () => 'Firma',
        company2: () => 'Zusatz Firma',
        address1: () => 'Adresse',
        address2: () => 'Zusatz Adresse',
        zipcode: () => 'PLZ',
        city: () => 'Ort',
        country: () => 'Land',
        language: () => 'Sprache',
        phone: () => 'Telefon',
        email: () => 'E-Mail',
    },
    fr: {
        address: () => 'Adresse',
        addNewAddress: () => 'Saisir nouvelle adresse',
        selectAddress: () => 'Reprendre adresse existant',
        editAddress: () => "Modifier l'adresse",
        insertAddress: () => 'Ajouter adresse',

        gender: () => 'Titre',
        male: () => 'Monsieur',
        female: () => 'Madame',

        firstname: () => 'Prénom',
        lastname: () => 'Nom',
        company1: () => 'Entreprise',
        company2: () => 'Entreprise (2. ligne)',
        address1: () => 'Rue',
        address2: () => 'Rue suppl.',
        zipcode: () => 'NPA',
        city: () => 'Localité',
        country: () => 'Pays',
        language: () => 'Langue',
        phone: () => 'Téléphone',
        email: () => 'E-mail',
    },
    it: {
        address: () => 'Indirizzo',
        addNewAddress: () => 'Inserisci nuovo indirizzo',
        selectAddress: () => 'Riprendi indirizzo esistente',
        editAddress: () => 'Modifica indirizzo',
        insertAddress: () => 'Aggiungi indirizzo',

        gender: () => 'Titolo',
        male: () => 'Signor',
        female: () => 'Signora',

        firstname: () => 'Nome',
        lastname: () => 'Cognome',
        company1: () => 'Società',
        company2: () => 'Società (2. riga)',
        address1: () => 'Via',
        address2: () => 'Via suppl.',
        zipcode: () => 'NAP',
        city: () => 'Località',
        country: () => 'Nazione',
        language: () => 'Lingua',
        phone: () => 'Telefono',
        email: () => 'E-mail',
    },
};
