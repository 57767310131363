import { Translations } from '../../common/i18n/Text.ts';
import { ParkingTexts } from '../components/ParkingsListBody.tsx';
import { zoneTexts } from '../../zones/i18n/ZoneTexts.ts';

/* tslint:disable */
export const parkingTexts: Translations<ParkingTexts> = {
    de: {
        Amounts: () => 'Min Betrag / Max. Offline Betrag',
        CardReader: () => 'Kartenleser',
        HeaderCaption: () => 'Parking - Detail',
        Location: () => 'Ort',
        Name: () => 'Parkingname',
        NeedsPermit: () => 'Bewilligungspflicht',
        Nr: () => zoneTexts.de.Nr(),
        PingWatchdog: () => 'Überwachung Parking-Status',
        TerminalId: () => 'Terminal ID',
        Vat: () => zoneTexts.de.Vat(),
        TerminalProvider: () => 'Anlagetyp',
        Account: () => 'Bankkonto',
    },
    fr: {
        Amounts: () => 'Montant min. / Montant max. offline',
        CardReader: () => 'Lecteur de cartes',
        HeaderCaption: () => 'Parkings - Détails',
        Location: () => 'Localité',
        Name: () => 'Nom parking',
        NeedsPermit: () => 'Autorisation obligatoire',
        Nr: () => zoneTexts.fr.Nr(),
        PingWatchdog: () => 'Surveillance état parking',
        TerminalId: () => 'Terminal ID',
        Vat: () => zoneTexts.fr.Vat(),
        TerminalProvider: () => "Type d'installation",
        Account: () => 'Compte bancaire',
    },
    it: {
        Amounts: () => 'Importo min. / Importo max. offline',
        CardReader: () => 'Lettore di carte',
        HeaderCaption: () => 'Parcheggio - Dettagli',
        Location: () => 'Località',
        Name: () => 'Nome parcheggio',
        NeedsPermit: () => 'Autorizzazione obbligatoria',
        Nr: () => zoneTexts.it.Nr(),
        PingWatchdog: () => 'Monitoraggio stato parcheggio',
        TerminalId: () => 'Terminal ID',
        Vat: () => zoneTexts.it.Vat(),
        TerminalProvider: () => "Tipo d'impianto",
        Account: () => 'Conto bancario',
    },
};
/* tslint:enable */
