import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as OperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import {
    getLoginById,
    getLoginTitle,
} from '../../common/state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import {
    IconButton40px,
    IconButton40pxType,
} from '../../ui/buttons/IconButton.tsx';
import { ChevronRightColumn } from '../../ui/table/ColumImplementations.tsx';
import {
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import * as CustomerClearanceTexts from '../i18n/CustomerClearanceTexts.ts';
import * as CustomerClearanceState from '../state/CustomerClearanceState.ts';
import { ModalSpecialTabResultRefresher } from '../../ui/layout/TabContent.tsx';
import { css } from '@emotion/css';

export interface CustomerClearanceTableTexts {
    createdDate: Translation;
    createdBy: Translation;
    customerNr: Translation;
    remark: Translation;
    permitTypeName: Translation;
}

interface ColumnHeaderProps {
    name: string;
    width: ColumnWidth;
    active?: boolean;
}

const ColumnHeader = (p: ColumnHeaderProps) => {
    return (
        <TableHeaderColumn
            name={p.name}
            direction={p.active ? SortDirection.Ascending : null}
            onClick={null}
            selected={p.active ? true : false}
            width={p.width}
        />
    );
};

interface CustomerClearanceTableHeaderProps {
    texts: CustomerClearanceTableTexts;
}

const CustomerClearanceTableHeader = (
    p: CustomerClearanceTableHeaderProps,
): JSX.Element => {
    return (
        <TableHeader>
            <EmptyTableHeaderColumn width={ColumnWidth._24px} />
            <ColumnHeader
                name={p.texts.createdDate()}
                width={ColumnWidth._136px}
            />
            <ColumnHeader
                name={p.texts.createdBy()}
                width={ColumnWidth._136px}
            />
            <ColumnHeader
                name={p.texts.customerNr()}
                width={ColumnWidth._208px}
            />
            <ColumnHeader name={p.texts.remark()} width={ColumnWidth._208px} />
            <ColumnHeader
                name={p.texts.permitTypeName()}
                width={ColumnWidth.variable}
            />
            <EmptyTableHeaderColumn width={ColumnWidth._24px} />
        </TableHeader>
    );
};

interface CustomerClearanceItemProps {
    clearance: CustomerClearanceState.CustomerClearance;
    operatorLogins: OperatorLoginsState.OperatorLogin[];
    texts: CustomerClearanceTableTexts;
    update: Flux.Updater;
}

const CustomerClearanceItem = (p: CustomerClearanceItemProps): JSX.Element => {
    return (
        <DefaultTableRow
            onClick={() =>
                p.update(store =>
                    CustomerClearanceState.Detail.stateWrite(store, {
                        selectedClearance: p.clearance,
                    }),
                )
            }
        >
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth._136px}>
                {Formatter.dayMonthYear(p.clearance.created)}
            </TableColumn>
            <TableColumn width={ColumnWidth._136px}>
                {thenElse(
                    getLoginById(p.operatorLogins, p.clearance.adminId),
                    l => getLoginTitle(l),
                    '',
                )}
            </TableColumn>
            <TableColumn width={ColumnWidth._208px}>
                {p.clearance.customerNr}
            </TableColumn>
            <TableColumn width={ColumnWidth._208px}>
                {CustomerClearanceState.remark(p.clearance)}
            </TableColumn>
            <TableColumn width={ColumnWidth.variable}>
                {p.clearance.permitTypeName}
            </TableColumn>
            <ChevronRightColumn />
        </DefaultTableRow>
    );
};

interface CustomerClearanceTableState {
    settings: SettingsState.State;
    customerClearances: CustomerClearanceState.List.State;
    operatorData: OperatorDataState.State;
    operatorLogins: OperatorLoginsState.List.State;
}

export class CustomerClearanceTable extends Flux.Container<CustomerClearanceTableState> {
    stateSelector(): CustomerClearanceTableState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            customerClearances: CustomerClearanceState.List.get(
                this.props.allState,
            ),
            operatorData: OperatorDataState.get(this.props.allState),
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
        };
    }

    render() {
        const texts =
            CustomerClearanceTexts.customerClearanceTableTexts[
                this.state.settings.language
            ];
        return (
            <>
                <ModalSpecialTabResultRefresher
                    onRefreshClick={() =>
                        this.update(
                            CustomerClearanceState.List.forceRefetch,
                            true,
                        )
                    }
                    successRequest={
                        this.state.customerClearances.statusCode.statusCode ===
                        200
                    }
                    pendingRequest={this.state.customerClearances.pending}
                />
                <div>
                    <div
                        className={css({
                            position: 'absolute',
                            height: '32px',
                            top: '15px',
                            right: '21px',
                        })}
                    >
                        {this.state.operatorData.data &&
                            this.state.operatorData.data
                                .hasAWhitelistCustomerPermittype && (
                                <IconButton40px
                                    type={IconButton40pxType.add}
                                    onClick={() =>
                                        this.update(store =>
                                            CustomerClearanceState.Add.stateWrite(
                                                store,
                                                {
                                                    createCustomerClearanceOpen:
                                                        true,
                                                },
                                            ),
                                        )
                                    }
                                />
                            )}
                    </div>
                    <div
                        className={css({
                            position: 'absolute',
                            top: '66px',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                        })}
                    >
                        <Table>
                            <CustomerClearanceTableHeader texts={texts} />
                            <TableBody>
                                {this.state.customerClearances.data.map(c => (
                                    <CustomerClearanceItem
                                        key={c.contractId}
                                        clearance={c}
                                        operatorLogins={
                                            this.state.operatorLogins.data
                                        }
                                        texts={texts}
                                        update={this.props.allState.update}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </>
        );
    }
}
