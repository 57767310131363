import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import moment from 'moment';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import { generateState } from 'dg-web-shared/lib/Flux.tsx';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import { request } from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';

export interface Permit {
    description: string;
    validFrom: string;
    validTo: string;
    id: number;
    operatorName: string;
    operatorId: number;
}

export interface Clearance {
    description: string;
    validTo: string;
    operatorName: string;
    operatorId: number;
}

export interface LicensePlate {
    id: number;
    adminId: Maybe<number>;
    licensePlateNr: string;
    typeDescriptions: string[];
    type: LicensePlateType;
    country: string;
    customerAccounts: Customer[];
    vignettes: Vignette[];
    vignettePrintPossible: boolean;
    modifyState:
        | 'modifiable'
        | 'hasVignetteNotModifiable'
        | 'noItemsNotModifiable';
    foreignEntries: boolean;
    activePermits: Permit[];
    activeClearances: Clearance[];
}

export interface Customer {
    id: number;
    isCompany: boolean;
    customerNr: number;
    firstName: string;
    lastName: string;
    company1: string;
    company2: string;
    street1: string;
    zipCode: string;
    city: string;
    countryId: string;
}

export interface Vignette {
    id: number;
    labelNr: Maybe<string>;
    createDate: moment.Moment;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseVignettes = (vignettes: any): Vignette[] => {
    if (!vignettes) {
        return [];
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return vignettes.map((v: any) => {
            const vcopy = Object.assign({}, v);
            vcopy.createDate = Parser.isoToMoment(v.createDate);
            return vcopy as Vignette;
        });
    }
};

export const parseBody = (body: LicensePlate): LicensePlate => {
    return {
        id: body.id,
        adminId: body.adminId,
        licensePlateNr: body.licensePlateNr,
        type: body.type,
        typeDescriptions: body.typeDescriptions,
        country: body.country,
        vignettePrintPossible: body.vignettePrintPossible,
        vignettes: parseVignettes(body.vignettes),
        customerAccounts: body.customerAccounts || [],
        modifyState: body.modifyState,
        foreignEntries: body.foreignEntries,
        activePermits: body.activePermits,
        activeClearances: body.activeClearances,
    };
};

export namespace CarTypeResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate<void>(
        'clearance-permit-list-LicensePlateState.CarTypeEditResponse',
        parseBody,
    );
}

export namespace CarTypeState {
    export interface State {
        open: boolean;
        type1: Maybe<string>;
        type2: Maybe<string>;
    }

    export const { set, get, reset, stateWrite } = generateState<State>(
        'permit-detail-LicensePlateState.CarTypeEdit',
        {
            open: false,
            type1: null,
            type2: null,
        },
    );
}

export namespace SendVignetteResponse {
    export type State = WriteStateSlice.State<LicensePlate>;
    export const { get, reset, setResponse } =
        WriteStateSlice.generate<LicensePlate>(
            'clearance-permit-list-VignetteEntry.SendVignetteResponse',
            parseBody,
        );
}

export namespace ServerDataState {
    interface Context {
        lpId: number;
    }

    export type State = ContextualServerStateSlice.ContextualState<
        Maybe<LicensePlate>
    >;

    export const { get, getState, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<
            Context,
            Maybe<LicensePlate>
        >({
            key: 'clearance-permit-list-LicensePlateServer',
            requestGenerator: Http.OperatorAccount.LicensePlates.get,
            request,
            parseBody: parseBody,
        });
}

export namespace VignetteEntryState {
    export interface State {
        vignetteNr: Maybe<string>;
        vignetteIdToReplace: Maybe<number>;
        open: boolean;
        confirmPressedWhileError: boolean;
    }

    export const { get, stateWrite, reset } = generateState<State>(
        'permit-detail-LicensePlateState.VignetteEntry',
        {
            confirmPressedWhileError: false,
            open: false,
            vignetteNr: null,
            vignetteIdToReplace: null,
        },
    );
}

export namespace NewLicensePlateTargetState {
    export interface State {
        licensePlateNr: string;
        type: LicensePlateType;
        country: string;
        open: boolean;
        countrySlideInOpen: boolean;
        formValid: boolean;
    }

    export const { get, reset, stateWrite } = generateState<State>(
        'licensePlateSearchState-NewLicensePlateTarget',
        {
            licensePlateNr: '',
            type: LicensePlateType.CAR,
            country: 'CH',
            open: false,
            countrySlideInOpen: false,
            formValid: false,
        },
    );
}

export namespace EditResponse {
    interface Data {
        status: 'ok' | 'existingVignettes';
    }

    export type State = WriteStateSlice.State<Data | null>;

    export const { get, setResponse, reset } = WriteStateSlice.generate<Data>(
        'licensePlateSearchState-Detail',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (b: any): Data => b,
    );
}
