import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import { ZoneFilterHalfSlideIn } from './ZoneFilterHalfSlideIn.tsx';

export function OperatorLoginZoneRestraintSlideIn() {
    const { storeState, update } = Flux.useStore(s => {
        return {
            layout: OperatorLoginsState.Layout.get(s),
            operatorLoginsList: CommonOperatorLoginsState.List.get(s),
            edit: OperatorLoginsState.Edit.get(s),
        };
    });
    const selectedId = storeState.layout.selectedOperatorLogin;
    const login = CommonOperatorLoginsState.getLoginById(
        storeState.operatorLoginsList.data,
        selectedId ? selectedId : -1,
    );
    if (!login && !storeState.layout.createEnabled) {
        return null;
    }

    const allowedZonesIds = prepareAllowedZones(storeState.edit, login);
    return (
        <ZoneFilterHalfSlideIn
            filter={allowedZonesIds}
            open={storeState.layout.zoneSelectionOpen}
            setSelection={(v: number[]) =>
                update(store =>
                    OperatorLoginsState.Edit.stateWrite(store, {
                        allowedZones: v,
                    }),
                )
            }
            onClose={() =>
                update(store =>
                    OperatorLoginsState.Layout.stateWrite(store, {
                        zoneSelectionOpen: false,
                    }),
                )
            }
            filterZonesWithoutRelevanceTag={true}
            showInfoText={true}
        />
    );
}

export function prepareAllowedZones(
    edit: OperatorLoginsState.Edit.State,
    login?: CommonOperatorLoginsState.OperatorLogin | null,
) {
    return edit.allowedZones
        ? edit.allowedZones
        : login
          ? login.allowedZones
          : [];
}
