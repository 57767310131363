import { Translations } from '../../common/i18n/Text.ts';
import { ValidDateFilterTexts } from '../components/filtered-permits/ValidDateFilter.tsx';
import { PermitTypeFilterTexts } from '../components/filtered-permits/PermitTypeFilter.tsx';
import { OperatorLoginFilterTexts } from '../components/filtered-permits/OperatorLoginFilter.tsx';
import { IssueDateFilterTexts } from '../components/filtered-permits/IssueDateFilter.tsx';
import { FilteredPermitsListTexts } from '../components/filtered-permits/FilteredPermitsList.tsx';
import { FilterSummaryTexts } from '../components/filtered-permits/FilteredPermitsFilterSummary.tsx';
import { FilteredPermitsFilterHalfSlideInTexts } from '../components/filtered-permits/FilteredPermitsFilterHalfSlideIn.tsx';
/* tslint:disable */

export const filteredPermitsFilterHalfSlideInTexts: Translations<FilteredPermitsFilterHalfSlideInTexts> =
    {
        de: {
            title: () => 'Filter',
        },
        fr: {
            title: () => 'Filtre',
        },
        it: {
            title: () => 'Filtro',
        },
    };

export const filteredPermitsListTexts: Translations<FilteredPermitsListTexts> =
    {
        de: {
            filterGuideCreateDate: () => 'Ausstelldatum',
            filterGuideExportDescription: () =>
                'Mit einem aktiven Filter können Sie die angezeigte Bewilligungsliste als CSV herunterladen.',
            filterGuideExportTitle: () => 'Liste exportieren',
            filterGuideIntro: () =>
                'Mittels Filter können Sie Listen von den gewünschten Bewilligungen erzeugen.',
            filterGuideListDescription: () =>
                'Folgende Filterkriterien sind verfügbar:',
            filterGuideTitle: () => 'Liste anzeigen',
            filterGuideType: () => 'Typ',
            filterGuideUser: () => 'Erfasst von',
            filterGuideValidity: () => 'Gültigkeit',
            filterGuideCategory: () => 'Kategorie',
            filterGuidePrice: () => 'Preis',
            filterGuideState: () => 'Status',
            hasMany: () => 'mehrere',
            headerChannel: () => 'Kanal',
            headerIssuedDate: () => 'Erfasst am',
            headerOperatorLoginName: () => 'Erfasst von',
            headerPermitId: () => 'Nr.',
            headerPermitTypeName: () => 'Bewilligungsart',
            headerValidFrom: () => 'Von',
            headerValidTo: () => 'Bis',
            headerZoneName: () => 'Zone',
            noRowsDescription: () =>
                'Bitte überprüfen Sie die ausgewählte Filterkriterien.',
            noRowsTitle: () => 'Keine Resultate gefunden.',
            onlineUser: () => 'Online-Benutzer',
            tooManyRowsDescription: () =>
                'Sie können die Liste als CSV herunterladen oder die Filterkriterien weiter einschränken.',
            tooManyRowsTitle: () => 'Zu viele Resultate.',
        },
        fr: {
            filterGuideCreateDate: () => "Date d'émission",
            filterGuideExportDescription: () =>
                'Avec un filtre actif vous pouvez télécharger la liste des autorisations en format CSV.',
            filterGuideExportTitle: () => 'Exporter la liste',
            filterGuideIntro: () =>
                'Au moyen du filtre vous pouvez générer des listes avec les autorisations désirées.',
            filterGuideListDescription: () =>
                'Les critères suivants sont disponibles:',
            filterGuideTitle: () => 'Afficher la liste',
            filterGuideType: () => 'Type',
            filterGuideUser: () => 'Saisie de',
            filterGuideValidity: () => 'Validité',
            filterGuideCategory: () => 'Catégorie',
            filterGuidePrice: () => 'Prix',
            filterGuideState: () => 'État',
            hasMany: () => 'plusieurs',
            headerChannel: () => 'Canal',
            headerIssuedDate: () => 'Saisie le',
            headerOperatorLoginName: () => 'Saisie de',
            headerPermitId: () => 'No',
            headerPermitTypeName: () => "Type d'autorisation",
            headerValidFrom: () => 'Du',
            headerValidTo: () => 'Au',
            headerZoneName: () => 'Zone',
            noRowsDescription: () =>
                'Vérifier dans le filtre les critères selectionnés.',
            noRowsTitle: () => 'Aucun résultat relevé.',
            onlineUser: () => 'Utilisateur en ligne',
            tooManyRowsDescription: () =>
                'Vous avez la possibilitè de télécharger la liste en format CSV ou de resteindre la recherche avec les critères.',
            tooManyRowsTitle: () => 'Trop de résultats.',
        },
        it: {
            filterGuideCreateDate: () => "Data d'emissione",
            filterGuideExportDescription: () =>
                'Con un filtro attivo potete scaricare la lista delle autorizzazioni in formato CSV. ',
            filterGuideExportTitle: () => 'Esportare la lista',
            filterGuideIntro: () =>
                'Tramite il filtro potete generare delle liste con le autorizzazioni desiderate.',
            filterGuideListDescription: () =>
                'Sono disponibili i seguenti criteri:',
            filterGuideTitle: () => 'Visualizzare la lista',
            filterGuideType: () => 'Tipo',
            filterGuideUser: () => 'Registrata da',
            filterGuideValidity: () => 'Validità',
            filterGuideCategory: () => 'Categoria',
            filterGuidePrice: () => 'Prezzo',
            filterGuideState: () => 'Stato',
            hasMany: () => 'diversi',
            headerChannel: () => 'Canale',
            headerIssuedDate: () => 'Registrata il',
            headerOperatorLoginName: () => 'Registrata da',
            headerPermitId: () => 'Nr.',
            headerPermitTypeName: () => "Tipo d'autorizzazione",
            headerValidFrom: () => 'Dal',
            headerValidTo: () => 'Al',
            headerZoneName: () => 'Zona',
            noRowsDescription: () =>
                'Verificare nel filtro i criteri selezionati.',
            noRowsTitle: () => 'Nessun risultato trovato.',
            onlineUser: () => 'Utente online',
            tooManyRowsDescription: () =>
                'Avete la possibilità di scaricare la lista in formato CSV oppure restringere il campo di ricerca tramite i criteri.',
            tooManyRowsTitle: () => 'Troppi risultati.',
        },
    };

export const filterSummaryTexts: Translations<FilterSummaryTexts> = {
    de: {
        validDate: () => 'Gültigkeit',
        zone: () => 'Zone',
    },
    fr: {
        validDate: () => 'Validité',
        zone: () => 'Zone',
    },
    it: {
        validDate: () => 'Validità',
        zone: () => 'Zona',
    },
};

export const validDateFilterTexts: Translations<ValidDateFilterTexts> = {
    de: {
        filterTitle: () => 'Gültigkeit',
        from: () => 'Von',
        to: () => 'Bis',
    },
    fr: {
        filterTitle: () => 'Validité',
        from: () => 'Du',
        to: () => 'Au',
    },
    it: {
        filterTitle: () => 'Validità',
        from: () => 'Dal',
        to: () => 'Al',
    },
};

export const permitTypeFilterTexts: Translations<PermitTypeFilterTexts> = {
    de: {
        filterTitle: () => 'Typ',
        inactive: () => 'INAKTIV',
    },
    fr: {
        filterTitle: () => 'Type',
        inactive: () => 'INACTIVE',
    },
    it: {
        filterTitle: () => 'Tipo',
        inactive: () => 'INATTIVO',
    },
};

export const operatorLoginFilterTexts: Translations<OperatorLoginFilterTexts> =
    {
        de: {
            filterTitle: () => 'Erfasst von',
            onlineUser: () => 'Online-Benutzer',
        },
        fr: {
            filterTitle: () => 'Saisie de',
            onlineUser: () => 'Utilisateur en ligne',
        },
        it: {
            filterTitle: () => 'Registrata da',
            onlineUser: () => 'Utente online',
        },
    };

export const issueDateFilterTexts: Translations<IssueDateFilterTexts> = {
    de: {
        filterTitle: () => 'Ausstelldatum',
        from: () => 'Von',
        to: () => 'Bis',
    },
    fr: {
        filterTitle: () => "Date d'émission",
        from: () => 'Du',
        to: () => 'Au',
    },
    it: {
        filterTitle: () => "Data d'emissione",
        from: () => 'Dal',
        to: () => 'Al',
    },
};

/* tslint:enable */
