import * as Flux from 'dg-web-shared/lib/Flux.tsx';

export function executeDelayed(
    store: Flux.Store,
    action: (store: Flux.Store) => string,
    id: string,
    timeout = 10000,
): void {
    if (!timeoutIds[id]) {
        timeoutIds[id] = [];
    }
    const timeoutCallback = window.setTimeout(() => {
        store.update(action);
        if (timeoutIds[id]) {
            timeoutIds[id].shift();
        }
    }, timeout);
    timeoutIds[id].push(timeoutCallback);
}

const timeoutIds: { [key: string]: number[] } = {};

export function reloadDelayedMulti(
    store: Flux.Store,
    action: (store: Flux.Store) => void,
    id: string,
    timeouts: number[],
): void {
    const resetAction = (store: Flux.Store) => {
        action(store);
        return 'delayed-reload-data-' + id;
    };
    if (timeoutIds[id]) {
        // cancel all other timeouts for the same reloader
        timeoutIds[id].forEach(t => window.clearTimeout(t));
    }

    timeouts.forEach(t => executeDelayed(store, resetAction, id, t));
}
