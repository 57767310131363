import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { NumberMultiSelectionSlideIn } from '../../ui/slidein/MultiSelectionSlideIn.tsx';
import { isUndefined, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { ProductState, ProductType } from './OperatorLoginsList.tsx';

interface OperatorLoginProductTypeSlideInState {
    layout: OperatorLoginsState.Layout.State;
    operatorLoginsList: CommonOperatorLoginsState.List.State;
    edit: OperatorLoginsState.Edit.State;
    settings: SettingsState.State;
}

export function OperatorLoginProductTypeSlideIn({
    products,
}: {
    products: ProductType[];
}) {
    const { storeState, update } =
        useStore<OperatorLoginProductTypeSlideInState>(store => {
            return {
                layout: OperatorLoginsState.Layout.get(store),
                operatorLoginsList: CommonOperatorLoginsState.List.get(store),
                edit: OperatorLoginsState.Edit.get(store),
                settings: new SettingsState.StateSlice(store).state,
            };
        });

    const selectedId = storeState.layout.selectedOperatorLogin;
    const login = CommonOperatorLoginsState.getLoginById(
        storeState.operatorLoginsList.data,
        selectedId ? selectedId : -1,
    );

    if (isUndefined(login) && !storeState.layout.createEnabled) {
        return null;
    } else {
        const allowedProductTypes = storeState.edit.allowedProductTypes
            ? storeState.edit.allowedProductTypes
            : thenElse(login, l => l.allowedProductTypes, []);

        return (
            <NumberMultiSelectionSlideIn
                open={storeState.layout.productTypeSelectionOpen}
                heading={
                    <Localized
                        de="Produkttypen"
                        fr="Types de produits"
                        it="Tipi di prodotto"
                        en="Product types"
                    />
                }
                selection={allowedProductTypes}
                options={products
                    .sort((a, b) =>
                        localizedProductName(
                            a,
                            storeState.settings.language,
                        ).localeCompare(
                            localizedProductName(
                                b,
                                storeState.settings.language,
                            ),
                        ),
                    )
                    .map(pt => ({
                        id: pt.contractTemplateId,
                        displayText: <Localized {...pt.productName} />,
                        disabled: pt.operatorState === ProductState.DRAFT,
                    }))}
                onLeftActionClick={() =>
                    update(store =>
                        OperatorLoginsState.Layout.stateWrite(store, {
                            productTypeSelectionOpen: false,
                        }),
                    )
                }
                setNewSelection={(v: number[]) =>
                    update(store =>
                        OperatorLoginsState.Edit.stateWrite(store, {
                            allowedProductTypes: v,
                        }),
                    )
                }
                infoText={
                    products.filter(
                        product => product.operatorState != ProductState.DRAFT,
                    ).length != allowedProductTypes.length ? (
                        <Localized
                            de="Neue Produkttypen werden nicht automatisch zugelassen."
                            fr="Les nouveaux types de produits ne sont pas automatiquement autorisés."
                            it="I nuovi tipi di prodotto non vengono autorizzati automaticamente."
                            en="New product types are not automatically allowed."
                        />
                    ) : null
                }
            />
        );
    }
}

function localizedProductName(product: ProductType, language: string): string {
    switch (language) {
        case 'fr':
            return product.productName.fr;
        case 'it':
            return product.productName.it;
        case 'en':
            return product.productName.en;
        default:
            return product.productName.de;
    }
}
