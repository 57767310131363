import { css } from '@emotion/css';

export type IFrameUrl = (language: string) => string;

export interface IFrameProps {
    url: IFrameUrl;
    language: string;
}

export const IFrame: React.FC<IFrameProps> = ({ url, language }) => {
    return (
        <iframe
            src={url(language)}
            className={css({
                width: '100%',
                height: '100%',
                border: 'none',
                margin: '50px 0 0',
                padding: 0,
                position: 'absolute',
                overflow: 'hidden',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            })}
        />
    );
};
