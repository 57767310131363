import * as Text from '../../../common/i18n/Text.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import * as PermitTypeState from '../../../common/state/PermitTypeState.ts';
import * as FilteredClearancesState from '../../state/FilteredClearancesState.ts';
import {
    clearPermitTypes,
    setPermitTypes,
} from '../../actions/FilteredClearancesActions.ts';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { Localized } from '../../../common/components/Localized.tsx';
import {
    filterInactiveUnusedPermits,
    flagInactivePermits,
    PermitTypeSelectionSlideIn,
} from '../../../ui/slidein/PermitSelectionSlideIn.tsx';
import { AutoActive } from 'product-shared/product-template/ProductOptions.tsx';

export interface PermitTypeFilterTexts {
    filterTitle: Text.Translation;
    inactive: Text.Translation;
}

enum CleareanceType {
    ZONE_CLEARANCE = 'ZONE CLEARANCE',
    PERMIT_CLEARANCE = 'PERMIT CLEARANCE',
}

export const permitTypeSelection = (
    permitTypes: PermitTypeState.PermitType[],
    selectedPt: number[] | null,
    withWhitelist: boolean,
) =>
    selectedPt && selectedPt.length > 0
        ? `${selectedPt.length}/${
              permitTypes.filter(
                  pt =>
                      pt.operatorState === 'ACTIVE' &&
                      (!withWhitelist || pt.needsWhitelist),
              ).length
          }`
        : '';

export function PermitTypeFilterHalfSlideIn() {
    const { storeState, update } = useStore(store => ({
        filter: FilteredClearancesState.Filter.get(store),
        permitTypes: PermitTypeState.get(store),
    }));

    return (
        <PermitTypeSelectionSlideIn
            open={storeState.filter.permitTypeSelectionVisible}
            heading={<Localized de="Typ" fr="Type" it="Tipo" en="Type" />}
            selection={storeState.filter.permitTypes || []}
            selectablePermits={filterInactiveUnusedPermits(
                storeState.permitTypes.data,
            )
                .filter(permit => permit.needsWhitelist)
                .map(permit => {
                    return {
                        id: permit.id,
                        displayText: flagInactivePermits(permit),
                        group:
                            permit.autoActive === AutoActive.STARTS_TRANSACTION
                                ? CleareanceType.ZONE_CLEARANCE
                                : CleareanceType.PERMIT_CLEARANCE,
                    };
                })}
            onLeftActionClick={() =>
                update(store =>
                    FilteredClearancesState.Filter.stateWrite(store, {
                        permitTypeSelectionVisible: false,
                    }),
                )
            }
            setNewSelection={v => update(store => setPermitTypes(store, v))}
            filterGroups={[
                {
                    id: CleareanceType.PERMIT_CLEARANCE,
                    displayName: (
                        <Localized
                            de="BEWILLIGUNGSFREIGABE"
                            fr="APPROBATION AUTORISATION"
                            it="APPROVAZIONE AUTORIZZAZIONE"
                            en="PERMIT CLEARANCE"
                        />
                    ),
                },
                {
                    id: CleareanceType.ZONE_CLEARANCE,
                    displayName: (
                        <Localized
                            de="ZONENFREIGABE"
                            fr="APPROBATION DE ZONE"
                            it="APPROVAZIONE DI ZONA"
                            en="ZONE CLEARANCE"
                        />
                    ),
                },
            ]}
        />
    );
}

export function PermitTypeFilterSelection() {
    const { storeState, update } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: FilteredClearancesState.Filter.get(store),
        permitTypes: PermitTypeState.get(store),
    }));

    const clear = storeState.filter.permitTypes
        ? () => update(clearPermitTypes)
        : null;

    return (
        <SingleSelection
            label={<Localized de="Typ" fr="Type" it="Tipo" en="Type" />}
            focused={false}
            selection={permitTypeSelection(
                storeState.permitTypes.data,
                storeState.filter.permitTypes,
                true,
            )}
            onClick={() =>
                update(store =>
                    FilteredClearancesState.Filter.stateWrite(store, {
                        permitTypeSelectionVisible: true,
                    }),
                )
            }
            onClear={clear}
        />
    );
}
