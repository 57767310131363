import * as OperatorDataLayoutState from '../state/OperatorDataLayoutState.ts';
import {
    Body,
    ColumnContent,
    LeftColumn,
    RightColumn,
} from '../../ui/layout/TabContent.tsx';
import {
    LabeledText,
    LabeledTextStatus,
} from '../../ui/labeled-elements/LabeledText.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import {
    InvoiceAddress,
    OrganizationAddress,
} from '../../common/state/OperatorDataState.ts';
import {
    HalfSlideIn,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { BaseAbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { languageTexts } from '../../common/i18n/GeneralTexts.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { syncAppTexts } from '../../sync-app/i18n/SyncAppTexts.ts';
import { SyncAppSlideIn } from '../../sync-app/components/SyncAppSlideIn.tsx';
import * as SyncAppHelpSlideInState from '../../sync-app/state/SyncAppHelpSlideInState.ts';
import * as SyncAppActions from '../../sync-app/actions/SyncAppActions.ts';
import { Localized } from '../../common/components/Localized.tsx';
import { texts } from '../../operator-logins/components/OperatorLoginsDetailSlideIn.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';

interface State {
    settings: SettingsState.State;
    layout: OperatorDataLayoutState.Layout.State;
    referenceData: OperatorDataState.State;
    currentLogin: CurrentOperatorLoginState.State;
    syncAppSlideInLayout: SyncAppHelpSlideInState.Layout.State;
}

export class BillingPropertiesSlideIn extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: OperatorDataLayoutState.Layout.get(this.props.allState),
            referenceData: OperatorDataState.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            syncAppSlideInLayout: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
        };
    }

    render() {
        const data = this.state.referenceData.data;
        if (isUndefined(data)) {
            return null;
        } else {
            const billingProperties = data.billingProperties;
            return (
                <HalfSlideIn open={this.state.layout.billingPropsOpen || false}>
                    <SlideInBody>
                        <Conditional c={billingProperties.billAsMail}>
                            <LabeledText
                                label={
                                    <Localized
                                        de="Abrechnung in Papierform"
                                        fr="Décompte au format papier"
                                        it="Conteggio cartaceo"
                                        en="Paper invoice"
                                    />
                                }
                                status={LabeledTextStatus.enabled}
                            />
                        </Conditional>
                        <LabeledText
                            label={
                                billingProperties.accounts.length > 1 ? (
                                    <Localized
                                        de="Bankkonti"
                                        fr="Comptes bancaires"
                                        it="Conti bancari"
                                        en="Bank accounts"
                                    />
                                ) : (
                                    <Localized
                                        de="Bankkonto"
                                        fr="Compte bancaire"
                                        it="Conto bancario"
                                        en="Bank account"
                                    />
                                )
                            }
                        >
                            {billingProperties.accounts.map(account => (
                                <div
                                    key={'acc' + account.iban + account.dtaText}
                                >
                                    {account.iban} | {account.dtaText}
                                </div>
                            ))}
                        </LabeledText>
                    </SlideInBody>
                    <StandardFirstLevelHeader
                        onClose={() =>
                            this.update(store => {
                                return OperatorDataLayoutState.Layout.stateWrite(
                                    store,
                                    { billingPropsOpen: false },
                                );
                            })
                        }
                    >
                        <SlideInHeaderTexts
                            title={
                                <Localized
                                    de="Zahlungskriterien"
                                    fr="Modalité de paiement"
                                    it="Modalità di pagamento"
                                    en="Method of payment"
                                />
                            }
                            hasLeftIcon={false}
                        />
                    </StandardFirstLevelHeader>
                </HalfSlideIn>
            );
        }
    }
}

const getStatus = (b: boolean): LabeledTextStatus =>
    b ? LabeledTextStatus.enabled : LabeledTextStatus.disabled;

function FieldStatus({
    fieldSetting,
}: {
    fieldSetting: OperatorDataState.FieldSetting;
}) {
    switch (fieldSetting) {
        case OperatorDataState.FieldSetting.hidden:
            return (
                <Localized
                    de="Deaktiviert"
                    fr="Désactivé"
                    it="Disattivato"
                    en="Deactivated"
                />
            );
        case OperatorDataState.FieldSetting.optional:
            return (
                <Localized
                    de="Optional"
                    fr="Optionnel"
                    it="Opzionale"
                    en="Optional"
                />
            );
        case OperatorDataState.FieldSetting.required:
            return (
                <Localized
                    de="Zwingend"
                    fr="Obligatoire"
                    it="Obbligatorio"
                    en="Required"
                />
            );
        default:
            console.error(
                `Unknown FieldSetting '${fieldSetting}' could not be mapped`,
            );
            return null;
    }
}

export class LpPermitPropertiesSlideIn extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: OperatorDataLayoutState.Layout.get(this.props.allState),
            referenceData: OperatorDataState.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            syncAppSlideInLayout: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
        };
    }

    render() {
        const data = this.state.referenceData.data;
        if (isUndefined(data)) {
            return null;
        } else {
            const settings = data.licensePlatePermitSettings;
            return (
                <HalfSlideIn open={this.state.layout.permitPropsOpen || false}>
                    <SlideInBody>
                        <LabeledText
                            label={
                                <Localized
                                    de="Aktiv"
                                    fr="Actif"
                                    it="Attivo"
                                    en="Active"
                                />
                            }
                            status={getStatus(settings.active)}
                        />
                        <LabeledText
                            label={
                                <Localized
                                    de="Personalnummer"
                                    fr="Numéro d'employé"
                                    it="Numero collaboratore"
                                    en="Personnel number"
                                />
                            }
                        >
                            <FieldStatus
                                fieldSetting={settings.whitelistPersonalNumber}
                            />
                        </LabeledText>
                        <LabeledText
                            label={
                                <Localized
                                    de="Vertragsnummer"
                                    fr="Numéro de contrat"
                                    it="Numero contratto"
                                    en="Contract number"
                                />
                            }
                        >
                            <FieldStatus
                                fieldSetting={settings.whitelistContractNumber}
                            />
                        </LabeledText>
                        <LabeledText
                            label={
                                <Localized
                                    de="Kontrollzeit Bewilligungspool"
                                    fr="Temps de contrôle autorisations pool"
                                    it="Tempo di controllo autorizzazioni pool"
                                    en="Control time Permitpool"
                                />
                            }
                        >
                            {settings.poolEnforcementTime / 60} min
                        </LabeledText>
                    </SlideInBody>
                    <StandardFirstLevelHeader
                        onClose={() =>
                            this.update(store =>
                                OperatorDataLayoutState.Layout.stateWrite(
                                    store,
                                    { permitPropsOpen: false },
                                ),
                            )
                        }
                    >
                        <SlideInHeaderTexts
                            title={
                                <Localized
                                    de="Bewilligungseinstellungen"
                                    fr="Réglage autorisations"
                                    it="Configurazione autorizzazioni"
                                    en="Permit settings"
                                />
                            }
                            hasLeftIcon={false}
                        />
                    </StandardFirstLevelHeader>
                </HalfSlideIn>
            );
        }
    }
}

export class OperatorDataBody extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: OperatorDataLayoutState.Layout.get(this.props.allState),
            referenceData: OperatorDataState.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            syncAppSlideInLayout: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
        };
    }

    renderSlideIns(): JSX.Element[] {
        return [
            <BillingPropertiesSlideIn
                allState={this.props.allState}
                key="billingSlideIn"
            />,
            <LpPermitPropertiesSlideIn
                allState={this.props.allState}
                key="lpPermitSlideIn"
            />,
            <SyncAppSlideIn
                allState={this.props.allState}
                key="syncAppSlideIn"
            />,
        ];
    }

    getDependingStateSlices(): BaseAbstractLegacyServerState[] {
        return [this.state.referenceData];
    }

    render() {
        const syncAppTxt = syncAppTexts[this.state.settings.language];
        const data = this.state.referenceData.data;
        const isAdmin =
            this.state.currentLogin.data &&
            this.state.currentLogin.data.isAdmin;
        if (isUndefined(data)) {
            return null;
        } else {
            return (
                <Body
                    disabled={
                        this.state.layout.permitPropsOpen ||
                        this.state.layout.billingPropsOpen ||
                        this.state.syncAppSlideInLayout.open
                    }
                    slideIns={this.renderSlideIns()}
                    dependingStateSlices={this.getDependingStateSlices()}
                >
                    <ColumnContent>
                        <LeftColumn>
                            <LabeledText
                                label={
                                    <Localized
                                        de="Bezeichnung"
                                        fr="Désignation"
                                        it="Denominazione"
                                        en="Denomination"
                                    />
                                }
                            >
                                {data.operatorName}
                            </LabeledText>
                            <LabeledText
                                label={
                                    <Localized
                                        de="Betreiber"
                                        fr="Exploitant"
                                        it="Gestore"
                                        en="Operator"
                                    />
                                }
                            >
                                <Address address={data.mgmtOrgAddress} />
                            </LabeledText>
                            {data.invoiceAddress.name1 && (
                                <LabeledText
                                    label={
                                        <Localized
                                            de="Versandadresse"
                                            fr="Adresse d'envoi"
                                            it="Indirizzo d'invio"
                                            en="Mailing address"
                                        />
                                    }
                                >
                                    <Address address={data.invoiceAddress} />
                                </LabeledText>
                            )}
                            {data.ownerOrgAddress && (
                                <LabeledText
                                    label={
                                        <Localized
                                            de="Eigentümer"
                                            fr="Propriétaire"
                                            it="Proprietario"
                                            en="Owner"
                                        />
                                    }
                                >
                                    <Address address={data.ownerOrgAddress} />
                                </LabeledText>
                            )}
                            <LabeledText
                                label={
                                    <Localized
                                        de="Korrespondenzsprache"
                                        fr="Langue de correspondance"
                                        it="Lingua di corrispondenza"
                                        en="Correspondence language"
                                    />
                                }
                            >
                                {languageTexts[this.state.settings.language]}
                            </LabeledText>
                            <Conditional c={data.hasParkingpayModule}>
                                <SingleSelection
                                    label={
                                        <Localized
                                            de="Zahlungskriterien"
                                            fr="Modalité de paiement"
                                            it="Modalità di pagamento"
                                            en="Method of payment"
                                        />
                                    }
                                    onClick={() =>
                                        this.update(store =>
                                            OperatorDataLayoutState.Layout.stateWrite(
                                                store,
                                                { billingPropsOpen: true },
                                            ),
                                        )
                                    }
                                    focused={
                                        this.state.layout.billingPropsOpen ||
                                        false
                                    }
                                    selection={
                                        <Localized
                                            de="Einstellungen"
                                            fr="Configuration"
                                            it="Configurazione"
                                            en="Settings"
                                        />
                                    }
                                />
                            </Conditional>
                            <Conditional
                                c={
                                    data.hasTaxomexModule &&
                                    (this.state.currentLogin.data?.permissions
                                        .syncApp ||
                                        false)
                                }
                            >
                                <SingleSelection
                                    label={syncAppTxt.SyncApp()}
                                    onClick={() =>
                                        this.update(
                                            SyncAppActions.openSyncAppSlideIn,
                                        )
                                    }
                                    focused={
                                        this.state.syncAppSlideInLayout.open
                                    }
                                    selection={syncAppTxt.InstallationAndDownload()}
                                />
                            </Conditional>
                        </LeftColumn>
                        <RightColumn>
                            <Conditional c={data.hasParkingpayModule}>
                                <div>
                                    <LabeledText
                                        label={
                                            <Localized
                                                de="Bewilligungen (Badge)"
                                                fr="Autorisations (badge)"
                                                it="Autorizzazioni (badge)"
                                                en="Permits (badge)"
                                            />
                                        }
                                        status={LabeledTextStatus.disabled}
                                    />
                                    <Conditional
                                        c={
                                            data.licensePlatePermitSettings
                                                .active
                                        }
                                    >
                                        <SingleSelection
                                            label={
                                                <Localized
                                                    de="Bewilligungen (Kennzeichen)"
                                                    fr="Autorisations (immatriculation)"
                                                    it="Autorizzazioni (targa)"
                                                    en="Permits (license plate)"
                                                />
                                            }
                                            status={
                                                data.licensePlatePermitSettings
                                                    .active
                                                    ? LabeledTextStatus.enabled
                                                    : LabeledTextStatus.disabled
                                            }
                                            onClick={() =>
                                                this.update(store =>
                                                    OperatorDataLayoutState.Layout.stateWrite(
                                                        store,
                                                        {
                                                            permitPropsOpen:
                                                                true,
                                                        },
                                                    ),
                                                )
                                            }
                                            focused={
                                                this.state.layout
                                                    .permitPropsOpen || false
                                            }
                                            selection={
                                                <Localized
                                                    de="Einstellungen"
                                                    fr="Configuration"
                                                    it="Configurazione"
                                                    en="Settings"
                                                />
                                            }
                                        />
                                    </Conditional>
                                </div>
                            </Conditional>
                            <Conditional c={isAdmin || false}>
                                <LabeledText
                                    label={
                                        <Localized
                                            de="Api Key"
                                            fr="Api Key"
                                            it="Api Key"
                                            en="Api Key"
                                        />
                                    }
                                >
                                    {data.apiKey}
                                </LabeledText>
                            </Conditional>
                            <TextField
                                inputType="text"
                                value={String(
                                    this.state.currentLogin.data
                                        ?.maxSoftAnonymizeCheckinPublicPermitAfterDays ??
                                        'Loading...',
                                )}
                                label={texts(
                                    this.state,
                                ).softAnonymizeCheckinPublicPermitAfterDaysLabel()}
                                onChange={() => null}
                                disabled={true}
                            />
                        </RightColumn>
                    </ColumnContent>
                </Body>
            );
        }
    }
}

function Address({
    address,
}: {
    address: InvoiceAddress | OrganizationAddress;
}) {
    return (
        <div>
            {address.name1 && <div>{address.name1}</div>}
            {address.name2 && <div>{address.name2}</div>}
            {address.street && <div>{address.street}</div>}
            {(address.zipCode || address.city) && (
                <div>{`${address.zipCode} ${address.city}`}</div>
            )}
        </div>
    );
}
