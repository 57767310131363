import { selectState, Store } from 'dg-web-shared/lib/Flux.tsx';
import * as MasterDataZonesState from '../common/state/MasterDataZonesState.ts';
import { SingleSelection } from '../ui/labeled-elements/SingleSelection.tsx';
import { DateRangeSingleSelection } from '../ui/slidein/DateRangePickerSlideIn.tsx';
import {
    HalfSlideIn,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../ui/slidein/Slidein.tsx';
import * as EnforcementLogState from './EnforcementLogState.ts';
import { Localized } from '../common/components/Localized.tsx';

interface FilterHalfSlideInState {
    filter: EnforcementLogState.Filter.State;
    zones: MasterDataZonesState.State;
}

export const FilterHalfSlideIn = selectState(
    (store: Store): FilterHalfSlideInState => {
        return {
            filter: EnforcementLogState.Filter.get(store),
            zones: MasterDataZonesState.get(store),
        };
    },
    p => {
        return (
            <HalfSlideIn open={p.filter.filterSlideInVisible}>
                <StandardFirstLevelHeader
                    onClose={() =>
                        p.update(store =>
                            EnforcementLogState.Filter.stateWrite(store, {
                                filterSlideInVisible: false,
                            }),
                        )
                    }
                    title={
                        <Localized
                            de="Filter"
                            fr="Filtre"
                            it="Filtro"
                            en="Filter"
                        />
                    }
                />
                <SlideInBody>
                    <DateRangeSingleSelection
                        title={
                            <Localized
                                de="Kontrolldatum"
                                fr="Heure du contrôle"
                                it="Ora del controllo"
                                en="Control time"
                            />
                        }
                        validFrom={p.filter.validFrom}
                        validTo={p.filter.validTo}
                        onClear={() =>
                            p.update(store =>
                                EnforcementLogState.Filter.stateWrite(store, {
                                    validFrom: null,
                                    validTo: null,
                                }),
                            )
                        }
                        onClick={() =>
                            p.update(store =>
                                EnforcementLogState.Filter.stateWrite(store, {
                                    validDateSelectionVisible: true,
                                }),
                            )
                        }
                    />
                    <SingleSelection
                        label={
                            <Localized
                                de="Zonen"
                                fr="Zones"
                                it="Zone"
                                en="Zones"
                            />
                        }
                        focused={false}
                        selection={
                            p.filter.zones.length > 0
                                ? `${p.filter.zones.length}/${p.zones.data.length}`
                                : null
                        }
                        onClick={() =>
                            p.update(store =>
                                EnforcementLogState.Filter.stateWrite(store, {
                                    zonesSelectionVisible: true,
                                }),
                            )
                        }
                        onClear={
                            p.filter.zones.length > 0
                                ? () =>
                                      p.update(store =>
                                          EnforcementLogState.Filter.stateWrite(
                                              store,
                                              { zones: [] },
                                          ),
                                      )
                                : null
                        }
                    />
                </SlideInBody>
            </HalfSlideIn>
        );
    },
);
