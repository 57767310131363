import * as superagent from 'superagent';
import { selectState, Updater } from 'dg-web-shared/lib/Flux.tsx';
import {
    generateWriteStateSlice,
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices.ts';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { request } from '../AsyncRequest.ts';
import { Spinner } from '../ui/loaders/Spinner.tsx';
import { ChangePasswordForm } from './ChangePasswordForm.tsx';
import { passwordChangeTokenTexts } from './i18n.ts';
import { ServerCommunicationError } from './ServerCommunicationError.tsx';
import { Success } from './Success.tsx';
import { PasswordChangeTokenSource } from './ChangePasswordController.tsx';

export const ConnectedChangePasswordFormController = selectState<Props, State>(
    (store, props) => ({
        httpPostNewPassword: makeHttpPostNewPassword(store.update, props.token),
        postTokenizedPasswordChangeRemoteRequest:
            PostTokenizedPasswordChangeRemoteRequestState.get(store),
    }),
    props => <ChangePasswordFormController {...props} />,
);

function ChangePasswordFormController(props: Props & State): JSX.Element {
    const t = passwordChangeTokenTexts[props.language];

    switch (props.postTokenizedPasswordChangeRemoteRequest.status) {
        case RequestStatus.NEVER_EXECUTED:
            return (
                <ChangePasswordForm
                    language={props.language}
                    operatorLogin={props.operatorLogin}
                    onSave={props.httpPostNewPassword}
                />
            );

        case RequestStatus.PENDING:
            return <Spinner loading={true} />;

        case RequestStatus.ERROR:
            return (
                <ServerCommunicationError
                    language={props.language}
                    message={t.httpRequestFailed(
                        props.postTokenizedPasswordChangeRemoteRequest
                            .httpStatus,
                    )}
                />
            );

        case RequestStatus.SUCCESS:
            return <Success language={props.language} source={props.source} />;
    }
}

interface Props {
    language: Language;
    token: string;
    operatorLogin: { firstName: string; lastName: string };
    source: PasswordChangeTokenSource;
}

namespace PostTokenizedPasswordChangeRemoteRequestState {
    export type State = ServerRequestState<object>;

    export const { get, setResponse } = generateWriteStateSlice<object>({
        key: 'change-password-form-controller-post-tokenized-password-change-remote-request',
    });
}

interface State {
    postTokenizedPasswordChangeRemoteRequest: PostTokenizedPasswordChangeRemoteRequestState.State;
    httpPostNewPassword: (password: string) => void;
}

function makeHttpPostNewPassword(
    update: Updater,
    token: string,
): (password: string) => void {
    return password => {
        update(
            request(
                (data: { password: string; token: string }) =>
                    superagent
                        .post(
                            '/ui-api/operator-account/tokenized-password-change',
                        )
                        .send(data),

                (store, res): string => {
                    PostTokenizedPasswordChangeRemoteRequestState.setResponse(
                        store,
                        res,
                    );

                    return 'tokenized-password-change';
                },
            ),
            { password, token },
        );
    };
}
