import { selectState, Store } from 'dg-web-shared/lib/Flux.tsx';
import {
    Language,
    languageFromString,
    makeLocalizedText,
    Message,
} from 'dg-web-shared/lib/Localized.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../../../ui/filter/FilterSummary.tsx';
import {
    Option,
    StringMultiSelectionSlideIn,
} from '../../../ui/slidein/MultiSelectionSlideIn.tsx';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import { OperatorAccount } from '../../../api/Http.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';

export const StatusFilterSelection = selectState(mapStateSlices, props => {
    const t = makeLocalizedText(props.language);

    return (
        <SingleSelection
            label={t(messages.filterTitle)}
            focused={false}
            selection={selectionRatio(props.filter.status)}
            onClick={() => {
                props.update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        statusSelectionVisible: true,
                    }),
                );
            }}
            onClear={
                props.filter.status
                    ? () => {
                          props.update(store =>
                              FilteredPermitsState.Filter.stateWrite(store, {
                                  status: null,
                              }),
                          );

                          props.update(FilteredPermitsState.List.reset);
                      }
                    : null
            }
        />
    );
});

export const StatusFilterSummary = selectState(mapStateSlices, props => {
    const t = makeLocalizedText(props.language);
    return (
        <Conditional c={!!props.filter.status}>
            <FilterSummaryItem hasSeparator={true}>
                <FilterSummaryItemTitle title={t(messages.filterTitle)} />
                <FilterSummaryItemSubTitle
                    title={
                        '\u00A0' + (selectionRatio(props.filter.status) || '-')
                    }
                />
            </FilterSummaryItem>
        </Conditional>
    );
});

export const StatusFilterHalfSlideIn = selectState(mapStateSlices, props => {
    const language = languageFromString(props.language);
    const t = makeLocalizedText(language);

    return (
        <StringMultiSelectionSlideIn
            open={props.filter.statusSelectionVisible}
            heading={t(messages.filterTitle)}
            selection={props.filter.status || []}
            options={filterOptions(language)}
            onLeftActionClick={() => {
                props.update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        statusSelectionVisible: false,
                    }),
                );
            }}
            setNewSelection={v => {
                const status =
                    v.length === 0
                        ? null
                        : (v as OperatorAccount.Permits.Status[]);

                props.update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        status,
                    }),
                );

                props.update(FilteredPermitsState.List.reset);
            }}
        />
    );
});

function selectionRatio(status: OperatorAccount.Permits.Status[] | null) {
    return status
        ? `${status.length}/${
              Object.keys(OperatorAccount.Permits.Status).length
          }`
        : null;
}

function filterOptions(language: Language): Option<string>[] {
    const t = makeLocalizedText(language);

    return Object.keys(OperatorAccount.Permits.Status).map((id: string) => ({
        id,
        displayText: t(messages[id as OperatorAccount.Permits.Status]),
    }));
}

function mapStateSlices(store: Store) {
    return {
        language: languageFromString(
            new SettingsState.StateSlice(store).state.language,
        ),
        filter: FilteredPermitsState.Filter.get(store),
    };
}

const messages: Messages = {
    filterTitle: {
        de: 'Status',
        fr: 'État',
        it: 'Stato',
        en: 'Status',
    },
    [OperatorAccount.Permits.Status.VALID]: {
        de: 'gültig',
        fr: 'valable',
        it: 'valida',
        en: 'valid',
    },
    [OperatorAccount.Permits.Status.EXPIRED]: {
        de: 'abgelaufen',
        fr: 'expirée',
        it: 'scaduta',
        en: 'expired',
    },
    [OperatorAccount.Permits.Status.CANCELLED]: {
        de: 'storniert',
        fr: 'annulée',
        it: 'annullata',
        en: 'cancelled',
    },
    [OperatorAccount.Permits.Status.PLANNED]: {
        de: 'geplant',
        fr: 'prévue',
        it: 'prevista',
        en: 'planned',
    },
};

interface Messages {
    filterTitle: Message;
    [OperatorAccount.Permits.Status.VALID]: Message;
    [OperatorAccount.Permits.Status.EXPIRED]: Message;
    [OperatorAccount.Permits.Status.CANCELLED]: Message;
    [OperatorAccount.Permits.Status.PLANNED]: Message;
}
