import { Box, IconButton, Tooltip } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { DateTime } from 'luxon';

import {
    RequestStatus,
    ServerRequestState,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Localized } from '../common/components/Localized.tsx';
import { useState } from 'react';

export function LastUpdateTimestampAndRefreshAsyncLoadedSection<D, ED>({
    requestState,
    render,
    onRefresh,
}: {
    requestState: ServerRequestState<D, ED>;
    render: (data: D, timestamp: string | null) => JSX.Element;
    onRefresh: () => void;
}) {
    const [timestamp, setTimestamp] = useState<string | null>(null);
    useServerSuccessEffect(requestState, () => {
        setTimestamp(DateTime.local().toFormat('dd.MM.yyyy – HH:mm:ss'));
    });

    switch (requestState.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.ERROR:
            return null;
        case RequestStatus.PENDING:
            return (
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            color: theme => theme.palette.blue.main,
                            fontSize: 14,
                            fontWeight: 600,
                            paddingTop: 0.5,
                        }}
                    >
                        <Localized
                            de="Suche läuft..."
                            fr="Recherche..."
                            it="Ricerca in corso..."
                            en="Search in progress..."
                        />
                    </Box>
                    <RefreshButton onClick={onRefresh} />
                </Box>
            );
        case RequestStatus.SUCCESS:
            return render(requestState.data, timestamp);
    }
}

export function LastUpdateTimestampAndRefresh({
    resultsCount,
    eof,
    onRefresh,
    timestamp,
}: {
    resultsCount: number;
    eof: boolean;
    onRefresh: () => void;
    timestamp: string | null;
}) {
    const adjustedResultCount = eof ? resultsCount : resultsCount - 1;
    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    color: theme => theme.palette.blue.main,
                    fontSize: 14,
                    fontWeight: 600,
                    paddingTop: 0.5,
                }}
            >
                {!eof && <>{'> '}</>}
                {adjustedResultCount < 2 ? (
                    <Localized
                        de={`${adjustedResultCount} gefunden`}
                        fr={`${adjustedResultCount} trouvé`}
                        it={`${adjustedResultCount} trovato`}
                        en={`${adjustedResultCount} found`}
                    />
                ) : (
                    <Localized
                        de={`${adjustedResultCount} gefunden`}
                        fr={`${adjustedResultCount} trouvés`}
                        it={`${adjustedResultCount} trovati`}
                        en={`${adjustedResultCount} found`}
                    />
                )}
            </Box>
            <RefreshButton onClick={onRefresh} timestamp={timestamp} />
        </Box>
    );
}

export function RefreshButton({
    onClick,
    timestamp,
}: {
    onClick: () => void;
    timestamp?: string | null;
}) {
    return (
        <IconButton
            sx={{
                marginLeft: 2,
                padding: 0.75,
                fontSize: 32,
            }}
            color="primary"
            size="large"
            onClick={onClick}
        >
            <Tooltip
                title={
                    timestamp ? (
                        <Localized
                            de={`letzte Aktualisierung: ${timestamp}`}
                            fr={`dernière actualisation: ${timestamp}`}
                            it={`ultimo aggiornamento: ${timestamp}`}
                            en={`last update: ${timestamp}`}
                        />
                    ) : null
                }
            >
                <Refresh />
            </Tooltip>
        </IconButton>
    );
}
