import * as SettingsState from '../../common/state/SettingsState.ts';
import {
    NavbarDropdown,
    NavbarDropdownBody,
    NavbarDropdownHeader,
    NavbarDropdownItem,
    NavbarDropdownVariant,
} from './NavbarDropdown.tsx';
import { CurrentOperatorLoginText } from './CurrentOperatorLogin.tsx';
import * as NavbarState from '../state/NavbarState.ts';
import * as AuthenticationActions from '../../app/actions/AuthenticationActions.ts';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { ALLOWED_LANGUAGE_CODES } from '../../common/i18n/Text.ts';
import { Localized } from '../../common/components/Localized.tsx';
import { css } from '@emotion/css';
import { useUpdate } from 'dg-web-shared/lib/Flux.tsx';
import { useState } from 'react';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';

export function CurrentOperatorLoginDropdown() {
    const update = useUpdate();

    const { settings } = useOperatorContext();

    const [show, setShow] = useState(false);

    return (
        <NavbarDropdown onClose={() => setShow(false)}>
            <NavbarDropdownHeader
                variant={NavbarDropdownVariant.Right}
                open={show}
                onClick={() => setShow(!show)}
            >
                <CurrentOperatorLoginText />
            </NavbarDropdownHeader>
            <NavbarDropdownBody
                open={show}
                variant={NavbarDropdownVariant.Right}
            >
                <LanguageSelector
                    language={settings.language}
                    onChange={language =>
                        update(store => {
                            new SettingsState.StateSlice(store).writeLanguage(
                                language,
                            );
                            return NavbarState.CurrentOperatorLogin.stateWrite(
                                store,
                                {
                                    showCurrentOperatorLoginDropdown: false,
                                },
                            );
                        })
                    }
                />
                <NavbarDropdownItem
                    label="Logout"
                    onClick={() => update(AuthenticationActions.logout)}
                    selected={false}
                />
            </NavbarDropdownBody>
        </NavbarDropdown>
    );
}

interface LanguageSelectorProps {
    language: string;
    onChange: (language: string) => void;
}

function LanguageSelector(props: LanguageSelectorProps): JSX.Element {
    return (
        <div
            className={css({
                padding: '12px 14px',
            })}
        >
            <span className={css({ marginRight: 5 })}>
                <Localized de="Sprache" fr="Langue" it="Lingua" en="Language" />
                :
            </span>
            {ALLOWED_LANGUAGE_CODES.map(lng => (
                <Clickable
                    key={lng}
                    element="span"
                    disabled={props.language === lng}
                    onClick={() => props.onChange(lng)}
                    className={css({
                        padding: '0 7px',
                        color: props.language === lng ? '#2E91DF' : undefined,
                    })}
                >
                    {lng}
                </Clickable>
            ))}
        </div>
    );
}
