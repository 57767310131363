import { DivGenerator, HasChildren } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as AbstractServerStateSlice from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { ContainerLoaderBar } from '../loaders/ContainerLoaderBar.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Mousetrap } from './Mousetrap.tsx';
import * as ReactDOM from 'react-dom';
import { refreshButtonModalId } from './TabContent.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import React from 'react';

export interface ContentBodyProps {
    disabled: boolean;
    children?: React.ReactNode;
    slideIns?: JSX.Element | JSX.Element[] | null;
    loading?: Maybe<boolean>;
    dependingStateSlices?: Maybe<
        AbstractServerStateSlice.BaseAbstractLegacyServerState[]
    >;
    specialBorderColor?: 'yellow' | null;
}

export const ContentBody = (p: ContentBodyProps) => {
    return (
        <div
            className={css({
                borderTop: `4px solid ${
                    p.specialBorderColor === 'yellow'
                        ? ColorHex.fPendent
                        : ColorHex.darkblue
                }`,
                borderTopColor: p.disabled
                    ? ColorHex.rgba(ColorHex.darkblue, 0.3)
                    : undefined,
                position: 'relative',
                left: '20px',
                top: '2px',
                height: '100%',
                width: 'calc(100% - 40px)',
                ...paper(2),
            })}
        >
            <ContainerLoaderBar
                loading={p.loading}
                stateSlices={p.dependingStateSlices}
            />
            {p.children}
            <Mousetrap enabled={p.disabled} />
            <div id="layout-content-body-slideins">{p.slideIns || null}</div>
        </div>
    );
};

export function contentBodySlideInPortal<P>(Comp: React.ComponentType<P>) {
    return class Portal extends React.Component<P> {
        el: HTMLDivElement;
        modalRoot: HTMLElement;

        constructor(props: P) {
            super(props);
            this.modalRoot = document.getElementById(
                'layout-content-body-slideins',
            ) as HTMLElement;
            this.el = document.createElement('div');
        }

        componentDidMount() {
            this.modalRoot.appendChild(this.el);
        }

        componentWillUnmount() {
            this.modalRoot.removeChild(this.el);
        }

        render(): JSX.Element | null {
            return ReactDOM.createPortal(<Comp {...this.props} />, this.el);
        }
    };
}

export const ColumnContent = DivGenerator(
    css({
        position: 'relative',
        padding: '24px 48px 0px',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
    }),
);
export const LeftColumn = DivGenerator(
    css({
        position: 'relative',
        height: '100%',
        width: '50%',
        float: 'left',
        paddingTop: '16px',
        paddingRight: '48px',
        '&::after': {
            position: 'absolute',
            content: '" "',
            right: '0px',
            top: '32px',
            bottom: '32px',
            width: '1px',
            background: ColorHex.rgba(ColorHex.darkblue, 0.25),
        },
    }),
);
export const RightColumn = DivGenerator(
    css({
        position: 'relative',
        height: '100%',
        width: '50%',
        float: 'left',
        paddingTop: '16px',
        paddingLeft: '47px',
    }),
);

export function TabsContainer(p: HasChildren) {
    return (
        <div
            className={css({
                position: 'absolute',
                left: '20px',
                top: '2px',
                width: '100%',
                height: '50px',
            })}
        >
            {p.children}
            <div id={refreshButtonModalId} />
        </div>
    );
}

export const TabsBody = DivGenerator(
    css({
        position: 'absolute',
        top: '52px',
        width: '100%',
        bottom: '24px',
    }),
);

export const SingleContentBody = DivGenerator(
    css({
        position: 'absolute',
        top: '0px',
        width: '100%',
        bottom: '24px',
    }),
);
