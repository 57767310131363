import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as PermitCreateTexts from '../i18n/PermitCreateTexts.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { getDurationString } from './PermitTimeUnitHelpers.ts';
import {
    Option,
    StringSingleSelectionSlideIn,
} from '../../ui/slidein/SingleSelectionSlideIn.tsx';

export interface Texts {
    DurationCaption: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: PermitCreateState.Layout.State;
    config: PermitCreateState.Config.State;
    permitType: Maybe<PermitTypeState.PermitType>;
}

export class PermitCreateValidityDurationSlideIn extends Flux.Container<State> {
    static displayName = 'PermitCreateValidityDurationSlideIn';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: PermitCreateState.Layout.get(this.props.allState),
            config: PermitCreateState.Config.get(this.props.allState),
            permitType: PermitTypeState.getById(
                this.props.allState,
                PermitCreateState.Config.get(this.props.allState).permitTypeId,
            ),
        };
    }

    txt(): Texts {
        return PermitCreateTexts.validityDuration[this.state.settings.language];
    }

    getDurationOptions(): Option<string>[] {
        const t = this.state.permitType;
        if (isDefined(t)) {
            if (t.validDurationsV2) {
                return t.validDurationsV2.map(d => {
                    return {
                        id: d.uniqueId,
                        displayText: getDurationString(
                            d,
                            this.state.settings.language,
                        ),
                    };
                });
            }
        }
        return [];
    }

    onDurationSelected(uniqueId: string): void {
        this.update((store: Flux.Store): string => {
            PermitCreateState.Config.stateWrite(store, {
                durationId: uniqueId,
            });
            PermitCreateState.Layout.stateWrite(store, {
                validityDurationPickerOpen: false,
            });
            PermitCreateState.Calc.reset(store);
            return 'PermitCreateValidityDurationSlideIn-selectDuration';
        });
    }

    close(): void {
        this.update(store =>
            PermitCreateState.Layout.stateWrite(store, {
                validityDurationPickerOpen: false,
            }),
        );
    }

    render() {
        const selectedDuration = this.state.config.durationId;
        const open = this.state.layout.validityDurationPickerOpen;
        return (
            <StringSingleSelectionSlideIn
                open={open}
                heading={this.txt().DurationCaption()}
                selection={selectedDuration}
                options={this.getDurationOptions()}
                onLeftActionClick={() => this.close()}
                onSelect={(duration: string) =>
                    this.onDurationSelected(duration)
                }
                outsideBody
            />
        );
    }
}
