import { Translations } from '../../common/i18n/Text.ts';
import {
    FinishedTransactionsListTexts,
    ParkTransactionsListTexts,
} from '../components/FinishedTransactionsList.tsx';
import * as Guide from '../components/Guide.tsx';
import { ParkTransactionsTexts } from '../components/ParkTransactionsOverview.tsx';
import { DateFilterTexts } from '../components/DateFilter.tsx';
import { FilterHalfSlideInTexts } from '../components/FilterHalfSlideIn.tsx';
import { FilterSummaryTexts } from '../components/ParkTransactionsFilterSummary.tsx';
import { TransactionDetailTexts } from '../components/TomTransactionDetailSlideIn.tsx';

/* tslint:disable */

export const filterHalfSlideInTexts: Translations<FilterHalfSlideInTexts> = {
    de: {
        title: () => 'Filter',
    },
    fr: {
        title: () => 'Filtre',
    },
    it: {
        title: () => 'Filtro',
    },
};

export const parkTransactionsTexts: Translations<ParkTransactionsTexts> = {
    de: {
        tabtitleFinishedTransactions: () => 'Transaktionen',
        tabtitleTransactionStatistics: () => 'Statistiken',
        ExportTitle: () => 'Exportieren',
    },
    fr: {
        tabtitleFinishedTransactions: () => 'Transactions',
        tabtitleTransactionStatistics: () => 'Statistiques',
        ExportTitle: () => 'Exporter',
    },
    it: {
        tabtitleFinishedTransactions: () => 'Transazioni',
        tabtitleTransactionStatistics: () => 'Statistiche',
        ExportTitle: () => 'Esporta',
    },
};

export const parkTransactionsListTexts: Translations<ParkTransactionsListTexts> =
    {
        de: {
            headerDateFrom: () => 'Start',
            headerDateTo: () => 'Stop',
            headerDuration: () => 'Dauer',
            headerParkingMeterName: () => 'Parkuhr',
            headerParkingSpace: () => 'Kennzeichen / Parkfeld',
            headerPrice: () => 'CHF',
            headerTransactionNumber: () => 'Transaktionsnummer',
        },
        fr: {
            headerDateFrom: () => 'Début',
            headerDateTo: () => 'Fin',
            headerDuration: () => 'Durée',
            headerParkingMeterName: () => 'Horodateur',
            headerParkingSpace: () => 'Immatriculation / place',
            headerPrice: () => 'CHF',
            headerTransactionNumber: () => 'Numéro de transaction',
        },
        it: {
            headerDateFrom: () => 'Inizio',
            headerDateTo: () => 'Fine',
            headerDuration: () => 'Durata',
            headerParkingMeterName: () => 'Parchimetro',
            headerParkingSpace: () => 'Targa / posto',
            headerPrice: () => 'CHF',
            headerTransactionNumber: () => 'Numero di transazione',
        },
    };

export const finishedTransactionsListTexts: Translations<FinishedTransactionsListTexts> =
    {
        de: {
            noRowsDescription: () =>
                'Bitte überprüfen Sie die ausgewählten Filterkriterien.',
            noRowsTitle: () => 'Keine Resultate gefunden.',
            tooManyRowsDescription: () =>
                'Bitte schränken Sie die Suche mit den Filterkriterien weiter ein oder exportieren Sie die Suchergebnisse zur Verarbeitung.',
            tooManyRowsTitle: () => 'Zu viele Resultate.',
        },
        fr: {
            noRowsDescription: () =>
                'Vérifier dans le filtre les critères selectionnés.',
            noRowsTitle: () => 'Aucun résultat relevé.',
            tooManyRowsDescription: () =>
                'Restreignez la recherche avec les critères du filtre ou exportez les résultats pour traitement.',
            tooManyRowsTitle: () => 'Trop de résultats.',
        },
        it: {
            noRowsDescription: () =>
                'Verificare nel filtro i criteri selezionati.',
            noRowsTitle: () => 'Nessun risultato trovato.',
            tooManyRowsDescription: () =>
                "Restringere il campo di ricerca utilizzando i criteri del filtro o esportare i risultati per l'elaborazione.",
            tooManyRowsTitle: () => 'Troppi risultati.',
        },
    };

export const parkTransactionsFilterSummaryTexts: Translations<FilterSummaryTexts> =
    {
        de: {
            dateFrom: () => 'Von',
            dateTo: () => 'Bis',
            parkingMeters: () => 'Parkuhren',
            unlimited: () => 'unbegrenzt',
        },
        fr: {
            dateFrom: () => 'Du',
            dateTo: () => 'Au',
            parkingMeters: () => 'Horodateurs',
            unlimited: () => 'illimitée',
        },
        it: {
            dateFrom: () => 'Dal',
            dateTo: () => 'Al',
            parkingMeters: () => 'Parchimetri',
            unlimited: () => 'illimitato',
        },
    };

export const dateFilterTexts: Translations<DateFilterTexts> = {
    de: {
        filterTitle: () => 'Parkzeit',
        from: () => 'Von',
        to: () => 'Bis',
        unlimited: () => 'unbegrenzt',
    },
    fr: {
        filterTitle: () => 'Date du stationnement',
        from: () => 'Du',
        to: () => 'Au',
        unlimited: () => 'illimitée',
    },
    it: {
        filterTitle: () => 'Data della sosta',
        from: () => 'Dal',
        to: () => 'Al',
        unlimited: () => 'illimitato',
    },
};

export const transactionDetailTexts: Translations<TransactionDetailTexts> = {
    de: {
        InheritedTime: () => 'Erbzeit',
        MaximumTimeReached: () => 'Maximalzeit erreicht',
        Net: () => 'Netto',
        NoInheritedTime: () => 'Keine Erbzeit',
        PayedWith: () => 'Bezahlt mit',
        SlideInHeaderCaption: () => 'Transaktionsdetail',
        Tariff: () => 'Tarif',
        UnknownTariff: () => 'Tarif unbekannt',
    },
    fr: {
        InheritedTime: () => 'Temps hérité',
        MaximumTimeReached: () => 'Durée maximale atteinte',
        Net: () => 'Net',
        NoInheritedTime: () => 'Aucun temps hérité',
        PayedWith: () => 'Payé par',
        SlideInHeaderCaption: () => 'Détail de la transaction',
        Tariff: () => 'Tarif',
        UnknownTariff: () => 'Tarif inconnu',
    },
    it: {
        InheritedTime: () => 'Durata residua',
        MaximumTimeReached: () => 'Durata massima raggiunta',
        Net: () => 'Netto',
        NoInheritedTime: () => 'Non viene riportata',
        PayedWith: () => 'Pagato con',
        SlideInHeaderCaption: () => 'Dettagli della transazione',
        Tariff: () => 'Tariffa',
        UnknownTariff: () => 'Tariffa sconosciuta',
    },
};

export const guideTexts: Translations<Guide.Texts> = {
    de: {
        Header: () => 'Anzeigen',
        Intro: () =>
            'Mittels Filter können Sie Liste von den abgeschlossenen Parkvorgänge erzeugen.',
        ListDescription: () => 'Folgende Filterkriterien sind verfügbar:',
        ParkingMeter: () => 'Parkuhr',
        ValidDate: () => 'Parkzeit',
    },
    fr: {
        Header: () => 'Afficher',
        Intro: () =>
            'Au moyen du filtre vous pouvez génerer des listes avec les procédures terminées.',
        ListDescription: () => 'Les critères suivants sont disponibles:',
        ParkingMeter: () => 'Horodateur',
        ValidDate: () => 'Date du stationnement',
    },
    it: {
        Header: () => 'Visualizzare',
        Intro: () =>
            'Tramite il filtro potete generare delle liste con le procedure concluse.',
        ListDescription: () => 'Sono disponibili i seguenti criteri:',
        ParkingMeter: () => 'Parchimetro',
        ValidDate: () => 'Data della sosta',
    },
};

/* tslint:enable */
