import { css } from '@emotion/css';
import { Icon16 } from '../../../ui/icons/Icon.tsx';

interface Props {
    icon: JSX.Element;
    value: string | null | undefined;
}

export const InfoItem = (p: Props): JSX.Element | null => {
    if (p.value) {
        return (
            <div className={css({ position: 'relative' })}>
                <div className={css({ position: 'absolute', left: 0 })}>
                    <Icon16 icon={p.icon} />
                </div>
                <div className={css({ marginLeft: '24px' })}>{p.value}</div>
            </div>
        );
    } else {
        return null;
    }
};
