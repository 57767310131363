import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../../../ui/filter/FilterSummary.tsx';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import {
    collectionsFilterTexts,
    dateFilterTexts,
} from '../../i18n/CollectionsTexts.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as CollectionsActions from '../../actions/CollectionsActions.ts';
import * as TomState from '../../state/TomState.ts';
import { ParsedTom } from 'dg-web-shared/model/Tom.ts';
import { FilterButtonWithSummary } from '../../../ui/filter/FilterButtonWithSummary.tsx';
import { tomSelectionText } from '../TomFilterHalfSlideIn.tsx';
import * as UsbStickState from '../../../usb-sticks/state/UsbStickState.ts';
import { UsbStick } from 'dg-web-shared/model/UsbStick.ts';
import { filterSticks } from './CollectionSticksFilter.tsx';

export interface FilterSummaryTexts {
    dateFrom: Text.Translation;
    dateTo: Text.Translation;
    parkingMeters: Text.Translation;
    Amount: Text.Translation;
    WithoutEmptyCollections: Text.Translation;
    Sticks: Text.Translation;
}

interface SummaryElementProps {
    filter: TomCollectionsState.Filter.State;
    texts: FilterSummaryTexts;
    language: string;
}

const DateFilterSummary = (p: SummaryElementProps): JSX.Element => (
    <Conditional c={TomCollectionsState.Filter.dateFilterActive(p.filter)}>
        <FilterSummaryItem hasSeparator={false}>
            <FilterSummaryItemTitle title={p.texts.dateFrom()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    Formatter.openRange(
                        p.filter.validFrom,
                        p.filter.validTo,
                        Formatter.dayMonthYear,
                        dateFilterTexts[p.language].unlimited(),
                    )
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

interface StickFilterSummaryProps extends SummaryElementProps {
    sticks: UsbStick[];
}

const SticksFilterSummary = (p: StickFilterSummaryProps): JSX.Element => (
    <Conditional c={TomCollectionsState.Filter.sticksFilterActive(p.filter)}>
        <FilterSummaryItem
            hasSeparator={
                TomCollectionsState.Filter.dateFilterActive(p.filter) ||
                TomCollectionsState.Filter.tomFilterActive(p.filter)
            }
        >
            <FilterSummaryItemTitle title={p.texts.Sticks()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    tomSelectionText(p.filter.sticks, p.sticks.length)
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

interface TomFilterSummaryProps extends SummaryElementProps {
    toms: ParsedTom[];
}

const TomsFilterSummary = (p: TomFilterSummaryProps): JSX.Element => (
    <Conditional c={TomCollectionsState.Filter.tomFilterActive(p.filter)}>
        <FilterSummaryItem
            hasSeparator={TomCollectionsState.Filter.dateFilterActive(p.filter)}
        >
            <FilterSummaryItemTitle title={p.texts.parkingMeters()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' + tomSelectionText(p.filter.toms, p.toms.length)
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

const EmptyCollectionsFilterSummary = (p: SummaryElementProps): JSX.Element => (
    <Conditional
        c={TomCollectionsState.Filter.emptyCollectionsFilterActive(p.filter)}
    >
        <FilterSummaryItem
            hasSeparator={
                TomCollectionsState.Filter.dateFilterActive(p.filter) ||
                TomCollectionsState.Filter.tomFilterActive(p.filter) ||
                TomCollectionsState.Filter.sticksFilterActive(p.filter)
            }
        >
            <FilterSummaryItemTitle title={p.texts.Amount()} />
            <FilterSummaryItemSubTitle
                title={'\u00A0' + p.texts.WithoutEmptyCollections()}
            />
        </FilterSummaryItem>
    </Conditional>
);

interface CollectionsFilterSummaryState {
    settings: SettingsState.State;
    filter: TomCollectionsState.Filter.State;
    toms: TomState.List.State;
    sticks: UsbStickState.List.State;
}

export class CollectionsFilterSummary extends Flux.Container<CollectionsFilterSummaryState> {
    stateSelector(): CollectionsFilterSummaryState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: TomCollectionsState.Filter.get(this.props.allState),
            toms: TomState.List.get(this.props.allState),
            sticks: UsbStickState.List.get(this.props.allState),
        };
    }

    render() {
        const texts = collectionsFilterTexts[this.state.settings.language];
        return (
            <FilterButtonWithSummary
                onFilterClick={() =>
                    this.update(store =>
                        TomCollectionsState.Filter.stateWrite(store, {
                            filterSlideInVisible: true,
                        }),
                    )
                }
                clearFilter={() => this.update(CollectionsActions.clearFilter)}
            >
                {TomCollectionsState.Filter.filterActive(this.state.filter) && [
                    <DateFilterSummary
                        key="date"
                        filter={this.state.filter}
                        texts={texts}
                        language={this.state.settings.language}
                    />,
                    <TomsFilterSummary
                        key="tom"
                        filter={this.state.filter}
                        texts={texts}
                        toms={this.state.toms.data}
                        language={this.state.settings.language}
                    />,
                    <SticksFilterSummary
                        key="sticks"
                        filter={this.state.filter}
                        texts={texts}
                        sticks={this.state.sticks.data.filter(filterSticks)}
                        language={this.state.settings.language}
                    />,
                    <EmptyCollectionsFilterSummary
                        key="emptyCollections"
                        filter={this.state.filter}
                        texts={texts}
                        language={this.state.settings.language}
                    />,
                ]}
            </FilterButtonWithSummary>
        );
    }
}
