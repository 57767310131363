import { Message } from 'dg-web-shared/lib/Localized.ts';
import {
    Navigate,
    Outlet,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';

export interface TicketRecodeCondition {
    recodeConditionId: string;
    recodeConditionDescription: Message;
    zoneDescription: string;
    ticketApiString: string;
    ticketId: string;
    tariffName: string;
}

export enum RecodeError {
    PARSING_FAILED = 'PARSING_FAILED',
    IDENTIFICATION_UNKNOWN = 'IDENTIFICATION_UNKNOWN',
    ZONE_NOT_AVAILABLE = 'ZONE_NOT_AVAILABLE',
    ALREADY_PAID = 'ALREADY_PAID',
}

export function CloudConnectorRecodeTicketConditionsOutlet() {
    const { ticketScan } = useParams<{ ticketScan: string }>();
    const [applicableRecodeConditionListState] = useServerFetch<
        TicketRecodeCondition[],
        {
            ticketScan: string;
        },
        RecodeError
    >(
        ({ ticketScan }) => ({
            url: `/ui-api/operator-account/cloud-connector/recode/ticket/${ticketScan}`,
        }),
        ticketScan ? { ticketScan: ticketScan } : null,
    );
    return (
        <OperatorAsyncLoadedSection
            requestState={applicableRecodeConditionListState}
            render={recodeConditions => (
                <RecodeTicketOutlet
                    context={{ recodeConditions: recodeConditions }}
                />
            )}
            renderError={recodeError => (
                <Navigate to={`../recode-error/${recodeError}`} />
            )}
        />
    );
}

export function useRecodeTicketOutlet() {
    return useOutletContext<RecodeTicketOutletContext>();
}

function RecodeTicketOutlet({
    context,
}: {
    context: RecodeTicketOutletContext;
}) {
    return <Outlet context={context} />;
}

interface RecodeTicketOutletContext {
    recodeConditions: TicketRecodeCondition[];
}
