import { HolidayTexts } from '../logic/Holidays.ts';
import { DateTime } from 'luxon';
import { Translations } from 'dg-web-shared/lib/Text.ts';
import { SpecialDaysTexts } from '../logic/specialDays.ts';

/* tslint:disable */
export const holidayTexts: Translations<HolidayTexts> = {
    en: {
        NewYear: () => 'New Year',
        Berchtoldstag: () => 'Berchtoldstag',
        Epiphany: () => 'Epiphany',
        MariaLichtmess: () => 'Presentation of Jesus at the Temple',
        StJosef: () => 'Saint Joseph',
        LaborDay: () => 'Labor Day',
        StPeterAndPaul: () => 'Saints Peter and Paul',
        NationalDayCH: () => 'National Day of Switzerland',
        AssumptionOfMary: () => 'Assumption of Mary',
        NationalDayLI: () => 'National Day of Liechtenstein',
        MaryBirth: () => 'Nativity of Mary',
        AllSaintsDay: () => "All Saints' Day",
        AndreasTag: () => "Saint Andrew's Day",
        ConceptionOfMary: () => 'Conception of Mary',
        ChristmasEve: () => 'Christmas Eve',
        Christmas: () => 'Christmas',
        StStephensDay: () => "Saint Stephen's Day",
        NewYearsEve: () => "New Year's Eve",
        // variable in every year
        HilariFriday: () => 'Hilari Firday',
        HilariSaturday: () => 'Hilari Saturday',
        GoodFriday: () => 'Good Friday',
        Easter: () => 'Easter',
        EasterMonday: () => 'Easter Monday',
        Sechselauten: () => 'Sechseläuten',
        AscensionDay: () => 'Ascension',
        Pentecost: () => 'Pentecost',
        PentecostMonday: () => 'Pentecost Monday',
        CorpusChristi: () => 'Corpus Christi',
        Maienzug: () => 'Maienzug',
        KnabenschiessenMonday: () => 'Knabenschiessen',
        BettagSunday: () => 'Bettag',
        BettagMonday: () => 'Bettag Monday',
        BettagGeneva: () => 'Bettag Geneva',
    },
    it: {
        NewYear: () => 'Capodanno',
        Berchtoldstag: () => 'San Basilio',
        Epiphany: () => 'Epifania',
        MariaLichtmess: () => 'Presentazione di Gesù al tempio',
        StJosef: () => 'San Giuseppe',
        LaborDay: () => 'Festa del lavoro',
        StPeterAndPaul: () => 'SS. Pietro e Paolo',
        NationalDayCH: () => 'Festa nazionale svizzera',
        AssumptionOfMary: () => 'Assunzione',
        NationalDayLI: () => 'Festa dello Stato Liechtenstein',
        MaryBirth: () => 'Natività di Maria Vergine',
        AllSaintsDay: () => 'Tutti i Santi', // Allerheiligen
        AndreasTag: () => 'Andrea apostolo',
        ConceptionOfMary: () => 'Immacolata Concezione', // Mariä Empfängnis
        ChristmasEve: () => 'Vigilia di Natale',
        Christmas: () => 'Natale',
        StStephensDay: () => 'Santo Stefano',
        NewYearsEve: () => 'San Silvestro',
        // variable in every year
        HilariFriday: () => 'Venerdi Hilari',
        HilariSaturday: () => 'Sabato Hilari',
        GoodFriday: () => 'Venerdì santo',
        Easter: () => 'Pasqua',
        EasterMonday: () => 'Lunedì di Pasqua',
        Sechselauten: () => 'Sechseläuten',
        AscensionDay: () => 'Ascensione',
        Pentecost: () => 'Pentecoste',
        PentecostMonday: () => 'Lunedì di Pentecoste',
        CorpusChristi: () => 'Corpus Domini',
        Maienzug: () => 'Maienzug',
        KnabenschiessenMonday: () => 'Knabenschiessen',
        BettagSunday: () => 'Digiuno federale',
        BettagMonday: () => 'Lunedì di digiuno federale',
        BettagGeneva: () => 'Digiuno ginevrino',
    },
    fr: {
        NewYear: () => 'Nouvel an',
        Berchtoldstag: () => 'Saint Berchtold',
        Epiphany: () => 'Epiphanie',
        MariaLichtmess: () => 'Présentation de Jésus au temple',
        StJosef: () => 'Saint Joseph',
        LaborDay: () => 'Fête du travail',
        StPeterAndPaul: () => 'Sts Pierre et Paul',
        NationalDayCH: () => 'Fête nationale suisse',
        AssumptionOfMary: () => 'Assomption',
        NationalDayLI: () => "Fête d'État Liechtenstein",
        MaryBirth: () => 'Nativité de Marie',
        AllSaintsDay: () => 'La Toussaint', // Allerheiligen
        AndreasTag: () => 'Saint André',
        ConceptionOfMary: () => 'Immaculée Conception', // Mariä Empfängnis
        ChristmasEve: () => 'Réveillon de Noël',
        Christmas: () => 'Noël',
        StStephensDay: () => 'Saint Etienne',
        NewYearsEve: () => 'Saint-Sylvestre',
        // variable in every year
        HilariFriday: () => 'Vendredi Hilari',
        HilariSaturday: () => 'Samedi Hilari',
        GoodFriday: () => 'Vendredi saint',
        Easter: () => 'Paques',
        EasterMonday: () => 'Lundi de Paques',
        Sechselauten: () => 'Sechseläuten',
        AscensionDay: () => 'Ascension',
        Pentecost: () => 'Pentecôte',
        PentecostMonday: () => 'Lundi de Pentecôte',
        CorpusChristi: () => 'Fête-Dieu',
        Maienzug: () => 'Maienzug',
        KnabenschiessenMonday: () => 'Knabenschiessen',
        BettagSunday: () => 'Jeûne fédérale',
        BettagMonday: () => 'Lundi du Jeûne fédérale',
        BettagGeneva: () => 'Jeûne genevois',
    },
    de: {
        NewYear: () => 'Neujahr',
        Berchtoldstag: () => 'Berchtoldstag',
        Epiphany: () => 'Heilige Drei Könige',
        MariaLichtmess: () => 'Mariä Lichtmess',
        StJosef: () => 'St. Josef',
        LaborDay: () => 'Tag der Arbeit',
        StPeterAndPaul: () => 'St. Peter und Paul',
        NationalDayCH: () => 'Nationalfeiertag Schweiz',
        AssumptionOfMary: () => 'Mariä Himmelfahrt',
        NationalDayLI: () => 'Nationalfeiertag Liechtenstein',
        MaryBirth: () => 'Mariä Geburt',
        AllSaintsDay: () => 'Allerheiligen',
        AndreasTag: () => 'Andreastag',
        ConceptionOfMary: () => 'Mariä Empfängnis',
        ChristmasEve: () => 'Heiligabend',
        Christmas: () => 'Weihnachten',
        StStephensDay: () => 'Stephanstag',
        NewYearsEve: () => 'Silvester',
        // variable in every year
        HilariFriday: () => 'Hilari-Freitag',
        HilariSaturday: () => 'Hilari-Samstag',
        GoodFriday: () => 'Karfreitag',
        Easter: () => 'Ostern',
        EasterMonday: () => 'Ostermontag',
        Sechselauten: () => 'Sechseläuten',
        AscensionDay: () => 'Auffahrt',
        Pentecost: () => 'Pfingsten',
        PentecostMonday: () => 'Pfingstmontag',
        CorpusChristi: () => 'Fronleichnam',
        Maienzug: () => 'Maienzug',
        KnabenschiessenMonday: () => 'Knabenschiessen',
        BettagSunday: () => 'Bettag',
        BettagMonday: () => 'Bettagsmontag',
        BettagGeneva: () => 'Genfer Bettag',
    },
};

export const specialDaysTexts: Translations<SpecialDaysTexts> = {
    en: {
        Repeating: () => `Repeating`,
        RepeatingValidationError: () =>
            `Special days must have a valid day of year and reference a day profile.`,
        RepeatingValidationErrorNoProfileSelection: () =>
            `Special days must have a valid day of year.`,
        AddRepeatingSpecialDay: () => `Add Repeating Special Day`,
        DayOfYear: () => `Day of Year`,
        Fixed: () => `Fixed`,
        FixedValidationError: () =>
            `Special dates must have a valid date and reference a day profile.`,
        FixedValidationErrorNoProfileSelection: () =>
            `Special dates must have a valid date.`,
        AddFixedSpecialDay: () => `Add Special Date`,
        Date: () => `Date`,
        DayProfile: () => `Day Profile`,
        DefaultDayProfile: () => `Default Day Profile for Special Days`,
        SelectDayProfile: () => `Choose day profile`,
        Holiday: () => `Holiday`,
        Holidays: () => `Holidays`,
        AddHoliday: () => `Select Holiday List`,
        SelectHoliday: () => `Select a holiday`,
        FixedHolidayTitle: () => `Fixed Date`,
        FixedHoliday: () => `fixed date`,
        FixedHolidayDate: (date: DateTime) => date.toFormat('dd.MM.'),
        VariableHolidayTitle: () => `Variable Date`,
        VariableHoliday: () => `variable date`,
        VariableHolidayDateInYear: (date: DateTime) =>
            `${date.year} on ${date.toFormat('dd.MM.')}`,
        VariableHolidayUntil: (year: number) => `until ${year}`,
        VariableHolidayHint: () =>
            `Holidays with variable date have limited support on parking devices due to storage limitations.`,
        VariableHolidayHintTom: (year: number) =>
            `This tariff supports all defined holidays until the end of ${year} on a TOMeco, given that is would be loaded onto the device now.`,
    },
    de: {
        Repeating: () => `Wiederholend`,
        RepeatingValidationError: () =>
            `Spezialtage müssen einen validen Jahrestag und ein Tagesprofil referenzieren.`,
        RepeatingValidationErrorNoProfileSelection: () =>
            `Spezialtage müssen einen validen Jahrestag referenzieren.`,
        AddRepeatingSpecialDay: () => `Spezialtag hinzufügen`,
        DayOfYear: () => `Tag im Jahr`,
        Fixed: () => `Einmalig`,
        FixedValidationError: () =>
            `Spezialdaten müssen ein valides Datum und Tagesprofil referenzieren.`,
        FixedValidationErrorNoProfileSelection: () =>
            `Spezialdaten müssen ein valides Datum referenzieren.`,
        AddFixedSpecialDay: () => `Spezialdatum hinzufügen`,
        Date: () => `Datum`,
        DayProfile: () => `Tagesprofil`,
        DefaultDayProfile: () => `Standardtagesprofil für Spezialtage`,
        SelectDayProfile: () => `Tagesprofil`,
        Holiday: () => `Feiertag`,
        Holidays: () => `Feiertage`,
        AddHoliday: () => `Feiertagsliste Auswählen`,
        SelectHoliday: () => `Feiertag auswählen`,
        FixedHolidayTitle: () => `Fixes Datum`,
        FixedHoliday: () => `fixes Datum`,
        FixedHolidayDate: (date: DateTime) => date.toFormat('dd.MM.'),
        VariableHolidayTitle: () => `Variables Datum`,
        VariableHoliday: () => `variables Datum`,
        VariableHolidayDateInYear: (date: DateTime) =>
            `${date.year} am ${date.toFormat('dd.MM.')}`,
        VariableHolidayUntil: (year: number) => `bis ${year}`,
        VariableHolidayHint: () =>
            `Feiertage mit variablem Datum können auf einer Parkuhr nur begrenzt gespeichert werden.`,
        VariableHolidayHintTom: (year: number) =>
            `Dieser Tarif unterstützt alle definierten Feiertage bis Ende ${year} auf einem TOMeco, sofern er jetzt auf die Parkuhr geladen würde.`,
    },
    fr: {
        Repeating: () => `Jours récurrents`,
        RepeatingValidationError: () =>
            `Les jours spéciaux récurrents doivent faire référence à un jour de l'année et à un profil valides.`,
        RepeatingValidationErrorNoProfileSelection: () =>
            `Les jours spéciaux récurrents doivent faire référence à un jour de l'année valide.`,
        AddRepeatingSpecialDay: () => `Ajouter jours spéciaux`,
        DayOfYear: () => `Jour de l'année`,
        Fixed: () => `Jours uniques`,
        FixedValidationError: () =>
            `Les jours spéciaux uniques doivent faire référence à une date et à un profil valides.`,
        FixedValidationErrorNoProfileSelection: () =>
            `Les jours spéciaux uniques doivent faire référence à une date valide.`,
        AddFixedSpecialDay: () => `Ajouter date spéciale`,
        Date: () => `Date`,
        DayProfile: () => `Profil du jour`,
        DefaultDayProfile: () => `Profil standard`,
        SelectDayProfile: () => `profil du jour`,
        Holiday: () => `jour férié`,
        Holidays: () => `Jours fériés`,
        AddHoliday: () => `Listes`,
        SelectHoliday: () => `sélectionner la liste des jours fériés`,
        FixedHolidayTitle: () => `Dates fixes`,
        FixedHoliday: () => `date fixe`,
        FixedHolidayDate: (date: DateTime) => date.toFormat('dd.MM.'),
        VariableHolidayTitle: () => `Dates variables`,
        VariableHoliday: () => `date variable`,
        VariableHolidayDateInYear: (date: DateTime) =>
            `${date.year} le ${date.toFormat('dd.MM.')}`,
        VariableHolidayUntil: (year: number) => `jusqu'à ${year}`,
        VariableHolidayHint: () =>
            `Feiertage mit variablem Datum können auf einer Parkuhr nur begrenzt gespeichert werden.`,
        VariableHolidayHintTom: (year: number) =>
            `Dieser Tarif unterstützt alle definierten Feiertage bis Ende ${year} auf einem TOM Eco, sofern er jetzt auf die Parkuhr geladen würde.`,
    },
    it: {
        Repeating: () => `Giorni ricorrenti`,
        RepeatingValidationError: () =>
            `I giorni speciali ricorrenti devono fare riferimento ad un giorno dell'anno e a un profilo validi.`,
        RepeatingValidationErrorNoProfileSelection: () =>
            `I giorni speciali ricorrenti devono fare riferimento ad un giorno dell'anno valido.`,
        AddRepeatingSpecialDay: () => `Aggiungi giorno speciale`,
        DayOfYear: () => `Giorno dell'anno`,
        Fixed: () => `Giorni singoli`,
        FixedValidationError: () =>
            `I giorni speciali singoli devono fare riferimento ad una data e a un profilo validi.`,
        FixedValidationErrorNoProfileSelection: () =>
            `I giorni speciali singoli devono fare riferimento ad una data valida.`,
        AddFixedSpecialDay: () => `Aggiungi data speciale`,
        Date: () => `Data`,
        DayProfile: () => `Profilo giorno`,
        DefaultDayProfile: () => `Profilo standard`,
        SelectDayProfile: () => `profilo giorno`,
        Holiday: () => `Giorno festivo`,
        Holidays: () => `Giorni festivi`,
        AddHoliday: () => `Liste`,
        SelectHoliday: () => `selezionare la lista dei giorni festivi`,
        FixedHolidayTitle: () => `Giorni fissi`,
        FixedHoliday: () => `giorno fisso`,
        FixedHolidayDate: (date: DateTime) => date.toFormat('dd.MM.'),
        VariableHolidayTitle: () => `Giorni variabili`,
        VariableHoliday: () => `giorno variabile`,
        VariableHolidayDateInYear: (date: DateTime) =>
            `${date.year} il ${date.toFormat('dd.MM.')}`,
        VariableHolidayUntil: (year: number) => `fino al ${year}`,
        VariableHolidayHint: () =>
            `I giorni festivi con date variabili possono essere memorizzati su un parchimetro solo in misura limitata.`,
        VariableHolidayHintTom: (year: number) =>
            `Questa tariffa supporta tutte le festività definite fino alla fine del ${year} su un TOMeco, a condizione che venga caricata sul parchimetro ora.`,
    },
};
