import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as AuthenticationState from '../state/AuthenticationState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { Outlet, useMatches } from 'react-router-dom';
import { createContext, useContext } from 'react';
import { css } from '@emotion/css';
import { Navbar } from '../../layout/components/Navbar.tsx';
import * as ParkingsState from '../../common/state/ParkingsState.ts';
import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as TariffState from '../../tariffs/state/TariffState.ts';
import { isRouteNameMatch } from '../../layout/components/BreadCrumb.tsx';
import { isRouteAllowed } from './OperatorRoutes.tsx';

export type OperatorContextState = Readonly<{
    settings: SettingsState.State;
    authentication: AuthenticationState.AuthStatus;
    operatorData: OperatorDataState.OperatorData;
    currentLogin: CurrentOperatorLoginState.CurrentOperatorLogin;
}>;
export const OperatorContext = createContext<OperatorContextState>(
    {} as OperatorContextState, // This is technically wrong but we ensure it's set before we render more stuff.
);

export function useOperatorContext() {
    return useContext(OperatorContext);
}

export function OperatorContextProvider({
    settings,
    authentication,
    operatorData,
    currentLogin,
}: OperatorContextState) {
    const matchedRouteNames = useMatches().filter(isRouteNameMatch);
    const currentRouteName =
        matchedRouteNames[matchedRouteNames.length - 1].handle.routeName;

    if (!isRouteAllowed(operatorData, currentLogin, currentRouteName)) {
        return <div>not allowed</div>;
    }
    return (
        <OperatorContext.Provider
            value={{
                settings: settings,
                authentication: authentication,
                operatorData: operatorData,
                currentLogin: currentLogin,
            }}
        >
            <Outlet />
        </OperatorContext.Provider>
    );
}

export function BaseLayoutAndData() {
    const { storeState } = useStore(s => ({
        // These need to be refetched if the login changes
        parkings: ParkingsState.get(s),
        zones: MasterDataZonesState.get(s),
        permitTypes: PermitTypeState.get(s),
        tariffs: TariffState.List.get(s),
    }));
    return (
        <div
            className={css({
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                minWidth: 1024,
                overflow: 'hidden',
            })}
        >
            <Outlet />
            <Navbar
                parkings={storeState.parkings}
                zones={storeState.zones}
                permitTypes={storeState.permitTypes}
                tariffs={storeState.tariffs}
            />
        </div>
    );
}
