import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { Colors } from 'dg-web-shared/ui/vars.ts';

export function ConfirmationDialog({
    open,
    onConfirm,
    onCancel,
    confirmLabel,
    cancelLabel,
    title,
    children,
}: {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    confirmLabel: JSX.Element;
    cancelLabel: JSX.Element;
    title: JSX.Element;
    children: JSX.Element;
}) {
    return (
        <Dialog open={open}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '52px',
                    background: Colors.yellow,
                    color: Colors.white,
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    padding: '0px 24px',
                }}
            >
                <p>{title}</p>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: '10px 24px',
                }}
            >
                {children}
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '0px 24px 20px 0px',
                }}
            >
                <Button onClick={onCancel}>{cancelLabel}</Button>
                <Button
                    onClick={onConfirm}
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: '5px' }}
                >
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
