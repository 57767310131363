import { CSSInterpolation } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import { red } from '@mui/material/colors';
import { alertClasses } from '@mui/material/Alert';

declare module '@mui/material/styles' {
    interface Palette {
        whitesmoke: Palette['primary'];
        blue: Palette['primary'];
        white: Palette['primary'];
    }

    interface PaletteOptions {
        whitesmoke: PaletteOptions['primary'];
        blue: PaletteOptions['primary'];
        white: PaletteOptions['primary'];
    }

    interface PaletteColor {
        medium?: string;
        electric?: string;
        electriclight?: string;
    }

    interface SimplePaletteColorOptions {
        medium?: string;
        electric?: string;
        electriclight?: string;
    }

    interface ComponentsOverrides {
        ReactHookFormValidFromInput: CSSInterpolation;
        SearchInputField: CSSInterpolation;
    }

    interface Components {
        ReachHookFormValidFromInput?: {
            styleOverrides?: ComponentsOverrides['ReactHookFormValidFromInput'];
        };
        SearchInputField?: {
            styleOverrides?: ComponentsOverrides['SearchInputField'];
        };
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        white: true;
    }
}

export const opertorZIndex = {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
};

const blue = 'rgb(50, 73, 107)';
const lightblue = 'rgb(224, 228, 233)';
const mediumblue = 'rgb(153, 164, 181)';
const electricblue = 'rgb(79, 154, 219)';
const electricbluelight = 'rgb(220,235,248)';
const white = '#fff';

export const defaultOperatorMuiTheme = createTheme({
    typography: {
        fontFamily: ['Roboto'].join(','),
        fontWeightMedium: 600,
        fontWeightBold: 800,
        h3: {
            fontSize: 26,
            color: blue,
            fontWeight: 500,
        },
        h5: {
            textTransform: 'uppercase',
            color: blue,
            fontWeight: 600,
            fontSize: 16,
        },
        h6: {
            color: blue,
            fontSize: 16,
        },
        body2: {
            fontSize: 14,
        },
    },
    palette: {
        common: {
            white: white,
        },
        background: {
            default: white,
        },
        primary: {
            main: blue,
        },
        secondary: {
            main: '#93a0b2',
        },
        whitesmoke: {
            main: '#f5f5f5',
        },
        blue: {
            main: blue,
            light: lightblue,
            medium: mediumblue,
            electric: electricblue,
            electriclight: electricbluelight,
        },
        white: {
            main: white,
        },
    },
    shape: {
        borderRadius: 2,
    },
    zIndex: opertorZIndex,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    lineHeight: 1.5,
                },
                h1: {
                    fontSize: '2em',
                    margin: '0.67em 0',
                    '&:first-child': {
                        marginTop: 0,
                    },
                },
                h3: {
                    '&:first-child': {
                        marginTop: 0,
                    },
                },
                a: {
                    '&, &:hover': {
                        textDecoration: 'none',
                    },
                    '&:hover': {
                        color: '#1e70bf',
                    },
                },
                sup: {
                    fontSize: '75%',
                    lineHeight: 0,
                    position: 'relative',
                    verticalAlign: 'baseline',
                    top: '-0.5em',
                },
                code: {
                    fontFamily: 'monospace, monospace',
                    fontSize: '1em',
                },
                b: {
                    fontWeight: 'bolder',
                },
                strong: {
                    fontWeight: 'bolder',
                },
                body: {
                    fontWeight: 400,
                    minWidth: 320,
                    height: '100vh',
                    // scrollbars styles from semantic-ui
                    '& ::-webkit-scrollbar': {
                        '-webkit-appearance': 'none',
                        width: 10,
                        height: 10,
                    },
                    '& ::-webkit-scrollbar-track': {
                        background: 'rgba(0, 0, 0, 0.1)',
                        borderRadius: 0,
                    },
                    '& ::-webkit-scrollbar-thumb': {
                        cursor: 'pointer',
                        borderRadius: 5,
                        background: 'rgba(0, 0, 0, 0.25)',
                        transition: 'color 0.2s ease',
                    },
                    '& ::-webkit-scrollbar-thumb:window-inactive': {
                        background: 'rgba(0, 0, 0, 0.15)',
                    },
                    '& ::-webkit-scrollbar-thumb:hover': {
                        background: 'rgba(128, 135, 139, 0.8)',
                    },
                },
                button: {
                    textTransform: 'none',
                },
                select: {
                    textTransform: 'none',
                },
            },
        },
        MuiScopedCssBaseline: {
            styleOverrides: {
                root: {
                    '& *': {
                        letterSpacing: '0.04em',
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
                InputLabelProps: {
                    shrink: true,
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: mediumblue,
                    borderRadius: 4,
                    fontSize: 15,
                    fontWeight: 500,

                    ':hover': {
                        backgroundColor: blue,
                        color: electricblue,
                    },
                    '::after, ::before': {
                        display: 'none',
                    },
                    '&.Mui-focused': {
                        backgroundColor: blue,
                        color: white,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    color: blue,
                    fontWeight: 500,
                    '&.Mui-disabled': {
                        color: blue,
                        textFillColor: blue,

                        '&::before': {
                            borderBottom: 'none',
                        },
                    },
                },
                input: {
                    color: blue,
                    '&.Mui-disabled': {
                        color: blue,
                        textFillColor: 'inherit',
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
            styleOverrides: {
                standard: {
                    // this should be using the root slot and a callback instead of a variant but as of 5.4.1 root does not exist
                    fontWeight: 500,
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 0px)': {
                        maxWidth: 1280,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState: { variant, color } }) => ({
                    ':hover': {
                        backgroundColor: electricblue,
                        color: white,
                        borderColor: white,
                    },
                    '&.MuiButton-outlinedError:hover': {
                        backgroundColor: red[400],
                        color: white,
                        borderColor: white,
                    },
                    ...(variant === 'contained' &&
                        color === 'primary' && {
                            backgroundColor: blue,
                        }),
                    ...(variant === 'outlined' && {
                        borderColor: blue,

                        '&.MuiButton-outlinedWhite': {
                            borderColor: white,

                            '&:hover': {
                                backgroundColor: white,
                                color: electricblue,
                            },
                        },

                        '&.MuiButton-outlinedError': {
                            borderColor: red[400],
                        },

                        '&:hover': {
                            borderColor: electricblue,
                        },
                    }),
                }),
            },
        },
        MuiChip: {
            styleOverrides: {
                root: ({ ownerState: { variant } }) => ({
                    ...(variant === 'filled' && {
                        backgroundColor: lightblue,
                        color: blue,
                        fontSize: 13,
                        fontWeight: 500,
                        textTransform: 'uppercase',

                        ':hover': {
                            backgroundColor: electricblue,
                            color: white,
                        },
                    }),
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 500,
                    color: blue,
                    height: 50,
                    paddingTop: 15,
                    paddingBottom: 14,
                    paddingLeft: 24,
                    paddingRight: 24,
                },
                head: {
                    fontSize: 12,
                    fontWeight: 400,
                    color: blue,
                    textTransform: 'uppercase',
                    height: 35,
                    paddingTop: 7,
                    paddingBottom: 5,
                    paddingLeft: 24,
                    paddingRight: 24,
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    '.MuiTableRow-hover:hover': {
                        backgroundColor: electricbluelight,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: ({ ownerState: { color } }) => {
                    return {
                        ...(color === 'primary' && {
                            backgroundColor: 'none',

                            ':hover': {
                                background: 'none',

                                '.MuiSvgIcon-root': {
                                    color: electricblue,
                                },
                            },
                            '.MuiSvgIcon-root': {
                                fontSize: 'inherit',
                            },
                        }),
                    };
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    ':hover': {
                        '.MuiCheckbox-root': {
                            backgroundColor: electricblue,
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    fontWeight: 400,
                    color: blue,
                    textTransform: 'uppercase',
                    '&.Mui-disabled': {
                        color: blue,
                        textFillColor: 'inherit',
                    },
                },
            },
            defaultProps: {
                shrink: true,
            },
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 40,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 160,
                    boxShadow: 'none',
                    border: 'none',
                    opacity: 1,
                    padding: '8px 16px',
                    minHeight: 40,
                    color: white,

                    '&.Mui-selected': {
                        backgroundColor: mediumblue,
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    '& .MuiButton-root': {
                        minWidth: 120,
                    },
                    '& *': {
                        letterSpacing: '0.04em',

                        '&:focus': {
                            outline: 'none',
                        },
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                subheader: {
                    fontSize: 16,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: ({ ownerState: { elevation }, theme }) => ({
                    ...(elevation === 0 && {
                        borderColor: theme.palette.blue.main,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        '&:hover': {
                            borderColor: theme.palette.blue.electric,
                            backgroundColor: theme.palette.blue.electric,
                        },
                        '&:hover .MuiTypography-root': {
                            color: theme.palette.common.white,
                        },
                        '&:hover .MuiSvgIcon-root': {
                            fill: theme.palette.common.white,
                        },
                        '& .MuiSvgIcon-root': {
                            transition: 'none',
                        },
                    }),
                }),
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 13,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: ({ theme }) => ({
                    margin: theme.spacing(3),
                    [`&.${alertClasses.filled}.${alertClasses.colorInfo}`]: {
                        backgroundColor: electricblue,
                    },
                }),
            },
            defaultProps: {
                variant: 'filled',
            },
        },
    },
});
