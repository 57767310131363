import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { OutsideClickable } from '../../ui/OutsideClickable.tsx';
import { getOrElse, isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { css } from '@emotion/css';
import { opertorZIndex } from '../../app/config/themes/DefaultOperatorMuiTheme.ts';
import { ColorHex } from '../../ui/Colors.ts';
import { expanded } from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Icon16 } from '../../ui/icons/Icon.tsx';

export enum NavbarDropdownVariant {
    Left,
    Right,
}
const variant = (v: NavbarDropdownVariant): string =>
    v === NavbarDropdownVariant.Left ? 'left' : 'right';

interface NavbarDropdownHeaderProps {
    open: boolean;
    onClick: ClickHandler;
    children?: React.ReactNode;
    variant?: NavbarDropdownVariant;
    hasDropDownIcon?: Maybe<boolean>;
}
export const NavbarDropdownHeader = (
    p: NavbarDropdownHeaderProps,
): JSX.Element => (
    <Clickable
        element="div"
        className={css({
            height: '100%',
            background: p.open ? ColorHex.darkblue : 'none',
            minWidth:
                p.variant !== NavbarDropdownVariant.Left ? 240 : undefined,
            fontFamily: 'Roboto',
            fontSize: 13,
            lineHeight: '16px',
            letterSpacing: '0.02em',
            display: 'flex',
            justifyContent: 'flex-end',
            '&:hover': {
                background: ColorHex.darkblue,
            },
        })}
        onClick={p.onClick}
        data-variant={variant(
            getOrElse<NavbarDropdownVariant>(
                p.variant,
                NavbarDropdownVariant.Left,
            ),
        )}
    >
        <div
            className={css({
                marginRight: getOrElse(p.hasDropDownIcon, true) ? 0 : 40,
                display: 'flex',
                alignItems: 'center',
            })}
        >
            {p.children}
        </div>
        <Conditional c={getOrElse(p.hasDropDownIcon, true)}>
            <div
                className={css({
                    width: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 12,
                })}
            >
                <Icon16 icon={expanded} />
            </div>
        </Conditional>
    </Clickable>
);

interface NavbarDropdownTextHeaderProps {
    children?: React.ReactNode;
}

export const NavbarDropdownTextHeader = (
    p: NavbarDropdownTextHeaderProps,
): JSX.Element => (
    <div
        className={css({
            padding: '0px 8px',
            fontWeight: 500,
        })}
    >
        {p.children}
    </div>
);

interface NavbarDropdownBodyProps {
    children?: React.ReactNode;
    open: boolean;
    variant: NavbarDropdownVariant;
}

export const NavbarDropdownBody = (p: NavbarDropdownBodyProps): JSX.Element =>
    !p.open ? (
        <noscript />
    ) : (
        <div
            data-variant={variant(p.variant)}
            className={css({
                zIndex: opertorZIndex.appBar,
                minWidth: 250,
                background: ColorHex.navbar,
                position: 'absolute',
                top: '100%',
                border: `2px solid ${ColorHex.white}`,
                right: p.variant !== NavbarDropdownVariant.Left ? 0 : undefined,
                left: p.variant !== NavbarDropdownVariant.Left ? undefined : 0,
            })}
        >
            {p.children}
        </div>
    );

interface NavbarDropdownItemProps {
    onClick: ClickHandler;
    label: React.ReactNode;
    selected: boolean;
}

export const NavbarDropdownItem = (p: NavbarDropdownItemProps): JSX.Element => (
    <Clickable
        element="div"
        className={css({
            padding: '12px 14px',
            background: p.selected ? ColorHex.lightblue : undefined,
            ':hover': {
                background: 'rgba(79,154,219,0.7)',
            },
        })}
        onClick={p.onClick}
        data-selected={p.selected}
    >
        {p.label}
    </Clickable>
);

export const NavbarDropdownItemForeignLink = (p: {
    label: string | React.ReactNode;
    link: string;
}): JSX.Element => (
    <a
        href={p.link}
        className={css({
            fontSize: 14,
            fontFamily: 'Roboto',
            color: ColorHex.white,
            padding: '12px 14px',
            display: 'block',
        })}
        target="_blank"
        rel="noreferrer"
    >
        {p.label}
    </a>
);

interface NavbarDropdownProps {
    onClose: (() => void) | null;
    children?: React.ReactNode;
}

export const NavbarDropdown = (p: NavbarDropdownProps): JSX.Element => {
    return (
        <OutsideClickable
            onClickOutside={p.onClose ? () => p.onClose && p.onClose() : null}
        >
            <div
                className={css({
                    height: '100%',
                    position: 'relative',
                })}
            >
                {p.children}
            </div>
        </OutsideClickable>
    );
};

interface NavbarDropdownApplicationItemProps {
    children?: React.ReactNode;
    label: string | React.ReactNode;
    logoImgSrc?: Maybe<string>;
}

export const NavbarDropdownApplicationItem = (
    p: NavbarDropdownApplicationItemProps,
): JSX.Element => (
    <div
        className={css({
            height: 48,
            minWidth: 200,
            padding: '16px 32px 12px 12px',
            color: '#F000000',
            position: 'relative',
            background: '#32496B',
        })}
    >
        {isDefined(p.logoImgSrc) && (
            <img
                src={p.logoImgSrc}
                className={css({
                    width: 48,
                    height: 48,
                    position: 'absolute',
                    left: 4,
                    top: 0,
                })}
            />
        )}
        <span>{p.label}</span>
    </div>
);
