import { envIsProduction, envIsTest } from 'dg-web-shared/lib/Environment.ts';

/**
 * returns property ID for Google Analytics
 */
export function getSentryProjectDSN(): string | undefined {
    if (envIsProduction()) {
        return 'https://4197de44550740f1aef584f07762be22@app.getsentry.com/99306';
    } else if (envIsTest()) {
        return 'https://8ae702ee405a45eeafc20acf8c795287@app.getsentry.com/99305';
    }
}

export function getApiToken(): string {
    return 'opdev123';
}
