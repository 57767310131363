import { QrCodeScannerFooter } from './QRScanButton.tsx';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { Localized } from '../common/components/Localized.tsx';

export function CloudConnectorRecoder() {
    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized de="Recode" fr="Recode" it="Recode" en="Recode" />
            }
            backTo={'..'}
        >
            <QrCodeScannerFooter />
        </ParkingPortalLayoutWithHeader>
    );
}
