import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import {
    ButtonSpecialState,
    IconButton40px,
    IconButton40pxType,
} from '../buttons/IconButton.tsx';

interface FilterStatusIconProps {
    clickable: boolean;
    active: boolean;
    tooltip?: Maybe<string>;
    onClick?: Maybe<ClickHandler>;
}

const iconType = (active: boolean): IconButton40pxType =>
    active
        ? IconButton40pxType.filter_active
        : IconButton40pxType.filter_inactive;

export const FilterStatusIcon = (p: FilterStatusIconProps) => {
    const onClick = p.onClick;
    return (
        <IconButton40px
            type={iconType(p.active)}
            onClick={p.clickable && isDefined(onClick) ? onClick : () => {}}
            specialState={!p.clickable ? ButtonSpecialState.FLAT : null}
            tooltip={isDefined(onClick) ? p.tooltip : null}
            tooltipRight={true}
        />
    );
};
