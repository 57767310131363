import {
    selectState,
    Store,
    UpdateProp,
    Updater,
} from 'dg-web-shared/lib/Flux.tsx';
import {
    ConfirmationHeader,
    ErrorHeader,
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import * as SettingsState from '../state/SettingsState.ts';

import {
    Notification,
    NotificationColor,
    NotificationIcon,
} from '../../ui/notifications/Notification.tsx';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { LicensePlateTrippleEntry } from './LicensePlateTrippleEntry.tsx';
import { LicensePlateCountrySlideIn } from './LicensePlateCountrySlideIn.tsx';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { request, Response } from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import {
    Clearance,
    EditResponse,
    NewLicensePlateTargetState,
    Permit,
    ServerDataState,
} from '../state/LicensePlateState.ts';
import * as PermitEditState from '../../permit-detail/state/PermitEditState.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';
import { editTexts } from 'dg-web-shared/common/components/translations/LicensePlateTexts.ts';
import { Entity as PermitEditEntityState } from '../../permit-detail/state/LicensePlateState.ts';

interface State {
    settings: SettingsState.State;
    editResponse: EditResponse.State;
    vignetteTarget: NewLicensePlateTargetState.State;
    detail: ServerDataState.State;
}

const reassignVignette = request(
    Http.OperatorAccount.LicensePlateSearch.reassignVignettesRequest,
    (store: Store, res: Response): string => {
        EditResponse.setResponse(store, res);

        const state = EditResponse.get(store);
        if (state.successData && state.successData.status === 'ok') {
            NewLicensePlateTargetState.reset(store);
            PermitEditState.Server.reset(store);
            ServerDataState.refetchSameContext(store, true);
            PermitEditEntityState.stateWrite(store, { id: null });
        }
        return 'reassignVignette';
    },
);

const PermitItem = (p: Permit) => {
    return (
        <div>
            <div>{p.description}</div>
            <div>{`${Formatter.dayMonthYear(
                Parser.isoToMoment(p.validFrom),
            )} - ${Formatter.dayMonthYear(Parser.isoToMoment(p.validTo))} (#${
                p.id
            })`}</div>
        </div>
    );
};

const ClearanceItem = (p: Clearance) => {
    return (
        <div>
            <div>{p.description}</div>
            <div>{`bis ${Formatter.dayMonthYear(
                Parser.isoToMoment(p.validTo),
            )}`}</div>
        </div>
    );
};

const Header = (p: State & UpdateProp) => {
    if (p.editResponse.pending) {
        return <LoaderHeader />;
    } else {
        return (
            <ConfirmationHeader
                language={p.settings.language}
                title={editTexts[p.settings.language].NewPlate()}
                confirmButtonSpecialState={
                    !p.vignetteTarget.formValid
                        ? ButtonSpecialState.DISABLED
                        : null
                }
                onCancel={() =>
                    p.update(store =>
                        NewLicensePlateTargetState.stateWrite(store, {
                            open: false,
                        }),
                    )
                }
                onConfirm={() => {
                    if (p.vignetteTarget.formValid) {
                        p.update(reassignVignette, {
                            oldId: p.detail.data ? p.detail.data.id : 0,
                            licensePlateNr: p.vignetteTarget
                                .licensePlateNr as string,
                            type: p.vignetteTarget.type as LicensePlateType,
                            country: p.vignetteTarget.country as string,
                        });
                    }
                }}
            />
        );
    }
};

const Body = (p: State & UpdateProp) => {
    if (!p.detail.data) {
        return null;
    }
    const texts = editTexts[p.settings.language];
    return (
        <SlideInBody>
            <LicensePlateTrippleEntry
                language={p.settings.language}
                licensePlateType={p.vignetteTarget.type}
                licensePlateNr={p.vignetteTarget.licensePlateNr}
                country={p.vignetteTarget.country}
                allowedLicensePlateTypes={[p.detail.data.type]}
                onCountrySlideInToggle={() =>
                    p.update(store =>
                        NewLicensePlateTargetState.stateWrite(store, {
                            countrySlideInOpen: true,
                        }),
                    )
                }
                onLicensePlateNumberChange={(v: string, formValid: boolean) => {
                    p.update(store =>
                        NewLicensePlateTargetState.stateWrite(store, {
                            licensePlateNr: v,
                            formValid,
                        }),
                    );
                }}
                onTypeSelect={(type: LicensePlateType) => {
                    p.update(store =>
                        NewLicensePlateTargetState.stateWrite(store, { type }),
                    );
                }}
                showErrors={true}
                outsideLicensePlateNrErrorGen={null}
                hideTypeSelectionIfPossible
            />
            <Notification
                icon={NotificationIcon.info}
                title={texts.ReplaceHeader()}
                color={NotificationColor.blue}
            >
                {p.detail.data.vignettes.length > 0
                    ? texts.ReplaceWithVignetteBody(
                          p.detail.data.licensePlateNr,
                      )
                    : texts.ReplaceWithoutVignetteBody(
                          p.detail.data.licensePlateNr,
                      )}
            </Notification>

            {p.detail.data.foreignEntries && (
                <Notification
                    icon={NotificationIcon.info}
                    title={texts.ForeignElementsHeader()}
                    color={NotificationColor.error}
                >
                    {texts.ForeignElementsBody(p.detail.data.licensePlateNr)}
                </Notification>
            )}

            {p.detail.data.activeClearances.length > 0 && (
                <LabeledText label={texts.Clearances()}>
                    {p.detail.data.activeClearances.map(ai => (
                        <ClearanceItem key={ai.description} {...ai} />
                    ))}
                </LabeledText>
            )}
            {p.detail.data.activePermits.length > 0 && (
                <LabeledText label={texts.Permits()}>
                    {p.detail.data.activePermits.map(ai => (
                        <PermitItem key={ai.id} {...ai} />
                    ))}
                </LabeledText>
            )}
        </SlideInBody>
    );
};

interface ErrorProps {
    language: string;
    open: boolean;
    update: Updater;
    header: string;
    body: string;
}

const Error = (p: ErrorProps) => {
    return (
        <HalfSlideIn open={p.open}>
            <ErrorHeader
                title={editTexts[p.language].Error()}
                onCancel={() =>
                    p.update((store: Store): string => {
                        EditResponse.reset(store);
                        NewLicensePlateTargetState.stateWrite(store, {
                            open: false,
                        });
                        return 'LicensePlateEditSlideInCancelHeader';
                    })
                }
                language={p.language}
            />
            <SlideInBody>
                <Notification
                    icon={NotificationIcon.error}
                    title={p.header}
                    color={NotificationColor.error}
                >
                    {p.body}
                </Notification>
            </SlideInBody>
        </HalfSlideIn>
    );
};

export const LicensePlateEditSlideIn = selectState<
    { outsideBody: boolean },
    State
>(
    (store: Store): State => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            editResponse: EditResponse.get(store),
            vignetteTarget: NewLicensePlateTargetState.get(store),
            detail: ServerDataState.getState(store),
        };
    },
    p => {
        const errorProps = {
            language: p.settings.language,
            update: p.update,
            open: p.vignetteTarget.open,
        };
        const texts = editTexts[p.settings.language];
        if (p.detail.pending) {
            return (
                <HalfSlideIn
                    open={p.vignetteTarget.open}
                    outsideBody={p.outsideBody}
                >
                    <LoaderHeader />
                </HalfSlideIn>
            );
        }
        if (p.editResponse.statusCode.cls.error || !p.detail.data) {
            return <Error {...errorProps} header="Programmfehler" body="" />;
        } else if (
            p.editResponse.successData &&
            p.editResponse.successData.status === 'existingVignettes'
        ) {
            return (
                <Error
                    {...errorProps}
                    header={texts.NewLpHasVignetteErrorHeader()}
                    body={texts.NewLpHasVignetteErrorBody(
                        p.detail.data.licensePlateNr,
                        p.vignetteTarget.licensePlateNr,
                    )}
                />
            );
        } else if (p.detail.data.modifyState === 'hasVignetteNotModifiable') {
            return (
                <Error
                    {...errorProps}
                    header={texts.HasVignettesHeader()}
                    body={texts.HasVignettesBody(p.detail.data.licensePlateNr)}
                />
            );
        } else if (p.detail.data.modifyState === 'noItemsNotModifiable') {
            return (
                <Error
                    {...errorProps}
                    header={texts.NoItemsHeader()}
                    body={texts.NoItemsBody(p.detail.data.licensePlateNr)}
                />
            );
        }

        return (
            <HalfSlideIn
                open={p.vignetteTarget.open}
                outsideBody={p.outsideBody}
            >
                <Header {...p} />
                <Body {...p} />
            </HalfSlideIn>
        );
    },
);

export const LicensePlateEditCountrySlideIn = selectState<
    { outsideBody: boolean },
    State
>(
    (store: Store): State => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            editResponse: EditResponse.get(store),
            vignetteTarget: NewLicensePlateTargetState.get(store),
            detail: ServerDataState.getState(store),
        };
    },
    p => {
        return (
            <LicensePlateCountrySlideIn
                onClose={() =>
                    p.update(store =>
                        NewLicensePlateTargetState.stateWrite(store, {
                            countrySlideInOpen: false,
                        }),
                    )
                }
                onSelect={(country: string, formValid) => {
                    p.update(store =>
                        NewLicensePlateTargetState.stateWrite(store, {
                            country,
                            formValid,
                        }),
                    );
                }}
                country={p.vignetteTarget.country}
                licensePlateType={p.vignetteTarget.type}
                licensePlateNr={p.vignetteTarget.licensePlateNr}
                outsideBody={p.outsideBody}
                language={p.settings.language}
                open={p.vignetteTarget.countrySlideInOpen}
            />
        );
    },
);
