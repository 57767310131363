import { css } from '@emotion/css';
import moment from 'moment';

import * as superagent from 'superagent';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    getOrNull,
    isDefined,
    isUndefined,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import * as FilteredPermitsState from '../../clearance-permit-list/state/FilteredPermitsState.ts';
import {
    isActive,
    isFuture,
} from '../../clearance-permit-list/state/PermitsState.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';
import { Localized } from '../../common/components/Localized.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { ColorHex } from '../../ui/Colors.ts';
import {
    ConfirmationHeader,
    ConfirmSaveHeader,
    ErrorHeader,
    LoaderHeader,
} from '../../ui/slidein/Slidein.tsx';
import { editPermit } from '../actions/PermitDetailActions.tsx';
import * as PermitSlideInTexts from '../i18n/PermitSlideInTexts.ts';
import * as AddressState from '../state/AddressState.ts';
import * as BadgeState from '../state/BadgeState.ts';
import * as LicensePlateState from '../state/LicensePlateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import {
    Edit,
    Permit,
    SubstitutedContractRight,
} from '../state/PermitEditState.ts';
import { clearAddress } from './Address.tsx';
import { PermitVersion } from './PermitVersion.tsx';
import { PermitViewHeader } from './PermitViewHeader.tsx';
import { useState } from 'react';
import { transactionDetailSlideInTexts } from '../../park-transactions-onstreet/OnstreetTransactionDetailSlideIn.tsx';

function removeLicensePlate(store: Flux.Store, id: number) {
    const server = PermitEditState.Server.get(store).data;
    const editLps = PermitEditState.Edit.get(store).licensePlates;

    if (isDefined(server)) {
        if (!editLps) {
            PermitEditState.Edit.stateWrite(store, {
                licensePlates: server.licensePlates.filter(lp => lp.id !== id),
            });
        } else {
            PermitEditState.Edit.stateWrite(store, {
                licensePlates: editLps.filter(lp => lp.id !== id),
            });
        }

        PermitEditState.Validation.reset(store);
    }

    return 'PermitEdit-removeLicensePlate';
}

function removeBadge(store: Flux.Store, id: number) {
    const server = PermitEditState.Server.get(store).data;
    const editBadges = PermitEditState.Edit.get(store).badges;

    if (isDefined(server)) {
        if (!editBadges) {
            PermitEditState.Edit.stateWrite(store, {
                badges: server.badges.filter(badge => badge.id !== id),
            });
        } else {
            PermitEditState.Edit.stateWrite(store, {
                badges: editBadges.filter(badge => badge.id !== id),
            });
        }

        PermitEditState.Validation.reset(store);
    }

    return 'PermitEdit-removeLicensePlate';
}

function clearPermitAddress(store: Flux.Store): string {
    const server = PermitEditState.Server.get(store);

    clearAddress(store);

    PermitEditState.Edit.stateWrite(store, {
        addressId:
            isDefined(server.data) && isDefined(server.data.address)
                ? 0
                : undefined,
        address: undefined,
    });

    return 'PermitEdit-clearPermitAddress';
}

function editAddress(store: Flux.Store): string {
    const adr = PermitEditState.Edit.get(store).address
        ? PermitEditState.Edit.get(store).address
        : (thenElse(
              PermitEditState.Server.get(store).data,
              p => getOrNull(p.address),
              null,
          ) as AddressState.Address);

    AddressState.Edit.stateWrite(store, {
        address: { ...(adr as AddressState.Address) },
    });

    AddressState.Select.stateWrite(store, {
        addressSelected:
            isDefined(adr) &&
            !(getOrNull(PermitEditState.Edit.get(store).addressId) === 0),
        selectAddressOpen: true,
    });

    return 'PermitEdit-editAddress';
}

function cancelEdit(store: Flux.Store): string {
    PermitEditState.Edit.reset(store);
    PermitEditState.Validation.reset(store);
    return 'cancel-permit-edit';
}

export function PermitEdit() {
    const { storeState } = Flux.useStore(s => ({
        server: PermitEditState.Server.get(s),
        currentLogin: CurrentOperatorLoginState.get(s),
    }));

    const permit = storeState.server.data;
    const currentLogin = storeState.currentLogin.data;

    if (!!permit && !!currentLogin) {
        return <Content permit={permit} currentLogin={currentLogin} />;
    } else {
        return null;
    }
}

function Content(props: {
    permit: Permit;
    currentLogin: CurrentOperatorLoginState.CurrentOperatorLogin;
}) {
    const [versionBrowsingMode, setVersionBrowsingMode] =
        useState<PermitEditState.VersionBrowsingMode>({
            tag: PermitEditState.VersionBrowsingModeTag.INACTIVE,
        });

    const [httpGetVersions] = useServerFetch<
        SubstitutedContractRight[],
        { permitId: number; currentVersionSnapshot: string }
    >(
        {
            request: c =>
                superagent.get(
                    `/ui-api/operator-account/permits/${c.permitId}/substituted`,
                ),
        },
        {
            permitId: props.permit.id,
            currentVersionSnapshot: JSON.stringify([
                props.permit.licensePlates,
                props.permit.badges,
                props.permit.onstreetZones,
                props.permit.offstreetZones,
            ]),
        },
    );

    const { storeState, update } = Flux.useStore(s => ({
        edit: PermitEditState.Edit.get(s),
    }));

    const editChanges = {
        licensePlates: storeState.edit.licensePlates,
        badges: storeState.edit.badges,
        additionalInfo: storeState.edit.additionalInfo,
        remark: storeState.edit.remark,
        address: storeState.edit.address,
        addressId: storeState.edit.addressId,
        onstreetZones: storeState.edit.onstreetZones,
        offstreetZones: storeState.edit.offstreetZones,
    };

    function emptyToUndefined<T>(xs: T[]): T[] | undefined {
        return xs.length === 0 ? undefined : xs;
    }

    const versionChanges =
        httpGetVersions.status === RequestStatus.SUCCESS &&
        versionBrowsingMode.tag === PermitEditState.VersionBrowsingModeTag.OLDER
            ? {
                  licensePlates: emptyToUndefined(
                      httpGetVersions.data[versionBrowsingMode.oldVersionIndex]
                          .licensePlates,
                  ),

                  badges: emptyToUndefined(
                      httpGetVersions.data[versionBrowsingMode.oldVersionIndex]
                          .badges,
                  )?.map(
                      (b): PermitEditState.Badge => ({
                          id: b.id,
                          badgeLabelNr: b.labelNr,
                      }),
                  ),

                  onstreetZones: emptyToUndefined(
                      httpGetVersions.data[versionBrowsingMode.oldVersionIndex]
                          .onstreetZones,
                  ),

                  offstreetZones: emptyToUndefined(
                      httpGetVersions.data[versionBrowsingMode.oldVersionIndex]
                          .offstreetZones,
                  ),
              }
            : {};

    return (
        <>
            <PermitVersion
                disabled={!!storeState.edit.action}
                canBeChanged={
                    versionBrowsingMode.tag ===
                        PermitEditState.VersionBrowsingModeTag.INACTIVE &&
                    props.currentLogin.permissions.permitsWrite &&
                    (isActive(props.permit, moment()) ||
                        isFuture(props.permit, moment()))
                }
                remarkCanBeChanged={
                    versionBrowsingMode.tag ===
                        PermitEditState.VersionBrowsingModeTag.INACTIVE &&
                    props.currentLogin.permissions.permitsWrite
                }
                permit={props.permit}
                changes={
                    versionBrowsingMode.tag ===
                    PermitEditState.VersionBrowsingModeTag.OLDER
                        ? versionChanges
                        : editChanges
                }
                onEditLicensePlate={(id: number) => {
                    update(store => LicensePlateState.Entity.edit(store, id));
                }}
                onEditZones={(zones: PermitEditState.Zone[]) => {
                    const onstreetZones = zones.filter(
                        z => !z.offstreet,
                    ) as PermitEditState.OnstreetZone[];
                    const offstreetZones = zones.filter(
                        z => z.offstreet,
                    ) as PermitEditState.OffstreetZone[];

                    update(store =>
                        Edit.stateWrite(store, {
                            onstreetZones,
                            offstreetZones,
                        }),
                    );
                }}
                onRemoveLicensePlate={(id: number) => {
                    update(store => removeLicensePlate(store, id));
                }}
                onAddLicensePlate={() => {
                    update(store =>
                        LicensePlateState.AddTripple.stateWrite(store, {
                            createLpOpen: true,
                        }),
                    );
                }}
                onRemoveBadge={(id: number) => {
                    update(store => removeBadge(store, id));
                }}
                onAddBadge={() => {
                    update(store =>
                        BadgeState.Add.stateWrite(store, {
                            createBadgeOpen: true,
                        }),
                    );
                }}
                onEditAddress={() => {
                    update(editAddress);
                }}
                onClearAddress={() => {
                    update(clearPermitAddress);
                }}
                onChangeAdditionalInfo={(additionalInfo: string) => {
                    update(store =>
                        Edit.stateWrite(store, {
                            additionalInfo,
                        }),
                    );
                }}
                onChangeRemark={(remark: string) => {
                    update(store => Edit.stateWrite(store, { remark }));
                }}
            />

            <PermitEditHeader
                permit={props.permit}
                oldVersions={
                    httpGetVersions.status === RequestStatus.SUCCESS
                        ? httpGetVersions.data
                        : []
                }
                versionBrowsingMode={versionBrowsingMode}
                setVersionBrowsingMode={setVersionBrowsingMode}
            />

            {versionBrowsingMode.tag !==
                PermitEditState.VersionBrowsingModeTag.INACTIVE && (
                <div
                    className={css({
                        color: ColorHex.darkblue,
                        fontSize: '11.5px',
                        fontWeight: 400,
                        position: 'relative',
                        width: '100%',
                        top: '64px',
                        textAlign: 'center',
                    })}
                >
                    <Localized
                        de="Dieser Änderungsverlauf betrifft nur Änderungen von Kennzeichen/Badges und Zonen/Parkings."
                        fr="Cet historique ne concerne que les changements de plaques d'immatriculation/badges et de zones/parkings."
                        it="Questa cronologia riguarda solo le modifiche di targhe/badge e zone/parcheggi."
                        en="This history only concerns changes to license plates/badges and zones/parkings."
                    />
                </div>
            )}
        </>
    );
}

function PermitEditHeader(props: {
    permit: Permit;
    oldVersions: SubstitutedContractRight[];
    versionBrowsingMode: PermitEditState.VersionBrowsingMode;
    setVersionBrowsingMode: (m: PermitEditState.VersionBrowsingMode) => void;
}): JSX.Element {
    const { storeState, update } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        edit: PermitEditState.Edit.get(s),
        server: PermitEditState.Server.get(s),
        editResponse: PermitEditState.EditResponse.get(s),
        validation: PermitEditState.Validation.get(s),
        clearancePermitList: new ClearancePermitListState.StateSlice(s).state,
    }));

    const [refundError, setRefundError] = useState<string | null>(null);
    const txt = PermitSlideInTexts.body[storeState.settings.language];

    if (storeState.editResponse.pending || storeState.validation.pending) {
        return (
            <LoaderHeader
                title={
                    storeState.validation.pending ? (
                        <Localized
                            de="Validieren..."
                            fr="Valider..."
                            it="Convalida..."
                            en="Validating..."
                        />
                    ) : (
                        <Localized
                            de="Speichern..."
                            fr="Enregistrer..."
                            it="Salva..."
                            en="Saving..."
                        />
                    )
                }
            />
        );
    } else if (
        PermitEditState.editsHaveBeenMade(storeState.edit, storeState.server)
    ) {
        if (storeState.editResponse.errorData) {
            return (
                <ErrorHeader
                    language={storeState.settings.language}
                    title={
                        <Localized
                            de="Fehler beim Bearbeiten der Bewilligung"
                            fr="Erreur lors de la modification du permis"
                            it="Errore durante la modifica del permesso"
                            en="Error on editing the permit"
                        />
                    }
                    onCancel={() => update(cancelEdit)}
                />
            );
        }

        const editPayload = PermitEditState.makeEditPayload(
            storeState.edit,
            storeState.server,
            storeState.editResponse,
            storeState.validation,
        );

        return (
            <ConfirmSaveHeader
                language={storeState.settings.language}
                title={txt.EditCaption()}
                confirmButtonSpecialState={
                    isUndefined(editPayload)
                        ? ButtonSpecialState.DISABLED
                        : null
                }
                onCancel={() => update(cancelEdit)}
                onSave={() => {
                    if (editPayload) {
                        update(editPermit, {
                            id:
                                storeState.clearancePermitList
                                    .selectedPermitId || 0,
                            changes: editPayload,
                        });
                    }
                }}
            />
        );
    } else if (storeState.edit.action === PermitEditState.Edit.Action.refund) {
        if (refundError) {
            return (
                <ErrorHeader
                    language={storeState.settings.language}
                    title={refundError}
                    onCancel={() => {
                        setRefundError(null);
                        update(cancelEdit);
                    }}
                />
            );
        } else {
            return (
                <ConfirmationHeader
                    language={storeState.settings.language}
                    subtitle={txt.RefundConfirmationCaption()}
                    title={txt.RefundConfirmationHeading()}
                    onCancel={() => {
                        update(cancelEdit);
                    }}
                    onConfirm={() => {
                        update(
                            AsyncRequest.request(
                                Http.OperatorAccount.Permits.refund,
                                (store: Flux.Store, res: Response): string => {
                                    PermitEditState.EditResponse.setResponse(
                                        store,
                                        res,
                                    );
                                    if (res.statusCode.cls.success) {
                                        PermitEditState.Edit.reset(store);
                                        PermitEditState.Server.setResponse(
                                            store,
                                            res,
                                        );
                                        ResultState.refetchSameContext(
                                            store,
                                            true,
                                        );
                                        FilteredPermitsState.List.reset(store);
                                    } else if (res.statusCode.cls.error) {
                                        const defaultError =
                                            transactionDetailSlideInTexts[
                                                storeState.settings.language
                                            ].errorCaption;
                                        setRefundError(
                                            res.body?.message
                                                ? res.body.message
                                                : defaultError,
                                        );
                                    }
                                    return 'PermitEdit-refundPermit';
                                },
                            ),
                            {
                                id:
                                    storeState.clearancePermitList
                                        .selectedPermitId || 0,
                                amount: props.permit.price,
                            },
                        );
                    }}
                />
            );
        }
    } else {
        return (
            <PermitViewHeader
                oldVersions={props.oldVersions}
                versionBrowsingMode={props.versionBrowsingMode}
                setVersionBrowsingMode={props.setVersionBrowsingMode}
            />
        );
    }
}
