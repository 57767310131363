import { css } from '@emotion/css';

import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import {
    MasterDataZone,
    MasterDataZoneRelevanceTag,
} from '../../common/state/MasterDataZonesState.ts';
import { ParkingDTO } from '../../common/state/ParkingsState.ts';
import { ColorHex } from '../Colors.ts';
import { Icon16 } from '../icons/Icon.tsx';
import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
    SlideInInfoBox,
} from './Slidein.tsx';
import { SubheaderContainer } from './SlideInSubheader.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export type SelectableZone = {
    id: number;
    code: number;
    name: string;
    postalCode: string;
    place: string;
    isActive: boolean;
};

export function selectableOnstreetZone(z: MasterDataZone): SelectableZone {
    return {
        id: z.id,
        code: z.zoneCode,
        name: z.zoneName,
        postalCode: z.zipCode,
        place: z.city,
        isActive: z.relevance.tag === MasterDataZoneRelevanceTag.ACTIVE,
    };
}

export function selectableOffstreetZone(z: ParkingDTO): SelectableZone {
    return {
        id: z.id,
        code: Number.MAX_SAFE_INTEGER,
        name: z.name,
        postalCode: z.zipCode,
        place: z.city,
        isActive: z.isOperatorStateActive,
    };
}

export function ZonesSelectionSlideIn(props: {
    open: boolean;
    onClose: () => void;
    zones: SelectableZone[];
    selectedIds: number[];
    title: JSX.Element;
    onSelectedIdsChange: (selectedIds: number[]) => void;
    infoText?: JSX.Element | null;
}) {
    const groups = gatherGroups(props.zones);
    const selectedIds = new Set(props.selectedIds);

    return (
        <HalfSlideIn open={props.open} outsideBody={false}>
            <SecondLevelHeader onClose={props.onClose}>
                <SlideInHeaderTexts title={props.title} hasLeftIcon={false} />
            </SecondLevelHeader>
            <SubheaderContainer>
                {props.selectedIds.length !== props.zones.length ? (
                    <SelectAllButton
                        totalCount={props.zones.length}
                        onClick={props.onSelectedIdsChange.bind(
                            null,
                            props.zones.map(z => z.id),
                        )}
                    />
                ) : (
                    <SelectNoneButton
                        onClick={props.onSelectedIdsChange.bind(null, [])}
                    />
                )}
            </SubheaderContainer>
            <SlideInBody hasSubheader>
                {props.infoText && (
                    <SlideInInfoBox>{props.infoText}</SlideInInfoBox>
                )}
                {groups.length === 1
                    ? groups[0].map(z => (
                          <IndividualZone
                              zone={z}
                              checked={selectedIds.has(z.id)}
                              onChange={v => {
                                  if (v) {
                                      selectedIds.add(z.id);
                                  } else {
                                      selectedIds.delete(z.id);
                                  }

                                  props.onSelectedIdsChange(
                                      Array.from(selectedIds),
                                  );
                              }}
                              key={z.id}
                          />
                      ))
                    : groups.map(g => (
                          <ZonesGroup
                              key={g.join('')}
                              zones={g}
                              selectedId={selectedIds}
                              onSelect={ids => {
                                  for (const id of ids) {
                                      selectedIds.add(id);
                                  }

                                  props.onSelectedIdsChange(
                                      Array.from(selectedIds),
                                  );
                              }}
                              onDeselect={ids => {
                                  for (const id of ids) {
                                      selectedIds.delete(id);
                                  }

                                  props.onSelectedIdsChange(
                                      Array.from(selectedIds),
                                  );
                              }}
                          />
                      ))}
            </SlideInBody>
        </HalfSlideIn>
    );
}

function SelectAllButton(props: { totalCount: number; onClick: () => void }) {
    return (
        <Clickable
            element="div"
            onClick={props.onClick}
            className={togglerStyle}
        >
            <div className={iconContainerStyle}>
                <Icon16 icon={Icons16.checkbox.all} />
            </div>

            <span className={css({ ...Typo.robotoRegular })}>
                <Localized de="alle" fr="tous" it="tutti" en="all" />
            </span>

            <span
                className={css({
                    ...Typo.robotoBold,
                    marginLeft: '4px',
                })}
            >
                {props.totalCount}
            </span>
        </Clickable>
    );
}

function SelectNoneButton(props: { onClick: () => void }) {
    return (
        <Clickable
            element="div"
            onClick={props.onClick}
            className={togglerStyle}
        >
            <div className={iconContainerStyle}>
                <Icon16 icon={Icons16.checkbox.none} />
            </div>

            <span>
                <Localized de="keine" fr="aucun" it="nessuno" en="none" />
            </span>
        </Clickable>
    );
}

function ZonesGroup(props: {
    zones: SelectableZone[];
    selectedId: Set<number>;
    onSelect: (ids: number[]) => void;
    onDeselect: (ids: number[]) => void;
}): JSX.Element {
    const postalCode = props.zones[0].postalCode;
    const place = props.zones[0].place;

    const allSelected = props.zones.every(z => props.selectedId.has(z.id));

    return (
        <>
            <Clickable
                element="div"
                className={css({
                    display: 'flex',
                    marginTop: '8px',
                    marginBottom: '8px',
                    fontSize: '15px',
                    color: ColorHex.rgba(ColorHex.darkblue, 0.7),
                    ...Typo.robotoBold,
                })}
                onClick={() => {
                    const ids = props.zones.map(z => z.id);

                    if (allSelected) {
                        props.onDeselect(ids);
                    } else {
                        props.onSelect(ids);
                    }
                }}
            >
                <div className={css({ marginRight: '16px' })}>
                    <Icon16
                        icon={
                            allSelected
                                ? Icons16.checkbox.on
                                : Icons16.checkbox.off
                        }
                    />
                </div>

                <span>
                    {postalCode} {place}
                </span>
            </Clickable>

            {props.zones.map(z => (
                <IndividualZone
                    zone={z}
                    checked={props.selectedId.has(z.id)}
                    onChange={v => {
                        if (v) {
                            props.onSelect([z.id]);
                        } else {
                            props.onDeselect([z.id]);
                        }
                    }}
                    key={z.id}
                />
            ))}
        </>
    );
}

function IndividualZone(props: {
    zone: SelectableZone;
    checked: boolean;
    onChange: (v: boolean) => void;
}): JSX.Element {
    return (
        <Clickable
            element="div"
            className={css(
                {
                    padding: '4px 48px',
                    margin: '0 -48px',
                    display: 'flex',
                    fontSize: '13px',
                    '&:hover': {
                        background: ColorHex.rgba(ColorHex.lightblue, 0.25),
                    },
                },
                localTypographyStyle,
            )}
            onClick={props.onChange.bind(null, !props.checked)}
        >
            <div className={css({ marginRight: '16px' })}>
                <Icon16
                    icon={
                        props.checked
                            ? Icons16.checkbox.on
                            : Icons16.checkbox.off
                    }
                />
            </div>

            <span className={css({ minWidth: '32px' })}>
                {props.zone.code === Number.MAX_SAFE_INTEGER
                    ? ' '
                    : props.zone.code}
            </span>

            <span>{props.zone.name}</span>

            {!props.zone.isActive && (
                <span
                    className={css({
                        marginLeft: '4px',
                        ...Typo.robotoBold,
                    })}
                >
                    <Localized
                        de=" — INAKTIV"
                        fr=" — INACTIVE"
                        it=" — INATTIVA"
                        en=" — INACTIVE"
                    />
                </span>
            )}
        </Clickable>
    );
}

function gatherGroups(zones: SelectableZone[]): SelectableZone[][] {
    const zonesByKey: { [key: string]: SelectableZone[] } = {};

    for (const zone of zones) {
        if (!zonesByKey[groupKey(zone)]) {
            zonesByKey[groupKey(zone)] = [];
        }

        zonesByKey[groupKey(zone)].push(zone);
    }

    const keys = Object.keys(zonesByKey);
    keys.sort();

    const result: SelectableZone[][] = [];
    for (const k of keys) {
        zonesByKey[k].sort((a, b) =>
            a.code === b.code ? a.name.localeCompare(b.name) : a.code - b.code,
        );

        result.push(zonesByKey[k]);
    }
    return result;
}

function groupKey(zone: SelectableZone) {
    return zone.postalCode.trim() + '|' + zone.place.trim();
}

const iconContainerStyle = css({
    marginTop: '4px',
    marginRight: '16px',
});

const localTypographyStyle = css({
    color: ColorHex.rgba(ColorHex.darkblue, 0.7),
    lineHeight: '16px',
    letterSpacing: '0.01em',
    ...Typo.robotoRegular,
});

const togglerStyle = css(
    {
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        fontSize: '10.5px',
    },
    localTypographyStyle,
);
