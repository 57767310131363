import { css } from '@emotion/css';

import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Icon24 } from '../icons/Icon.tsx';

const arrowSize = 11;

export enum NotificationIcon {
    error,
    info,
}

export enum NotificationColor {
    blue,
    error,
    green,
}

export const NotificationBodyList = (p: {
    children?: React.ReactNode;
}): JSX.Element => (
    <ul
        className={css({
            margin: '12px 0',
            '& li': {
                margin: 4,
            },
        })}
    >
        {p.children}
    </ul>
);

export interface NotificationProps {
    title: string | JSX.Element;
    color: NotificationColor;
    icon: NotificationIcon;
    closeHandler?: Maybe<() => void>;
    children?: React.ReactNode;
}

export const Notification = (p: NotificationProps) => {
    const dataColor =
        NotificationColor[p.color] === 'error'
            ? Colors.error
            : NotificationColor[p.color] === 'blue'
              ? Colors.darkblue
              : undefined;

    return (
        <div
            className={css({
                ...paper(2),
                position: 'relative',
                borderRadius: 8,
                marginTop: 11,
                paddingBottom: 2,
                marginBottom: 24,
            })}
        >
            <div
                className={css({
                    width: 0,
                    height: 0,
                    borderColor: 'transparent',
                    position: 'absolute',
                    top: -arrowSize,
                    borderLeftColor: dataColor,
                })}
            />
            <div
                className={css({
                    position: 'relative',
                    minHeight: 48,
                    borderTopRightRadius: 8,
                    borderTopLeftRadius: 8,
                    color: Colors.white,
                    background: dataColor,
                })}
            >
                <div
                    className={css({
                        left: 16,
                        top: 12,
                        position: 'absolute',
                    })}
                >
                    <Icon24
                        icon={
                            p.icon === NotificationIcon.error
                                ? Icons24.error
                                : Icons24.info_b
                        }
                    />
                </div>
                <div
                    className={css({
                        marginLeft: 36,
                        ...Typo.heading4,
                        paddingTop: 12,
                        paddingLeft: 14,
                        paddingBottom: 12,
                        paddingRight: 16,
                    })}
                >
                    {p.title}
                </div>
            </div>
            <div
                className={css({
                    ...Typo.body,
                    background: Colors.white,
                    color: Colors.darkblue,
                    marginLeft: 2,
                    marginRight: 2,
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    padding: '16px 14px 14px 14px',
                })}
            >
                {p.children}
            </div>
        </div>
    );
};
