import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Updater, useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as HttpResponse from 'dg-web-shared/lib/HttpResponse.ts';
import {
    getOrElse,
    isDefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { forceDecimal } from 'dg-web-shared/lib/StringConversions.ts';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { Localized } from '../../common/components/Localized.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import {
    Notification,
    NotificationColor,
    NotificationIcon,
} from '../../ui/notifications/Notification.tsx';
import {
    ErrorHeader,
    HalfSlideIn,
    LoaderHeader,
    SecondLevelHeader,
    SlideInHeaderButtonsContainer,
} from '../../ui/slidein/Slidein.tsx';
import { Switch, SwitchType } from '../../ui/switches/Switches.tsx';
import * as PermitSlideInTexts from '../i18n/PermitSlideInTexts.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import {
    isPermitRefundBookable,
    permitAccountLocked,
    permitHasAccount,
    permitHasCustomer,
} from '../state/PermitEditState.ts';
import { PaymentChannel } from '../../common/models/PaymentChannel.ts';
import {
    TerminateBody,
    TerminateHeaderButton,
} from './TerminateDateSlideInContent.tsx';
import { StornoBody, StornoHeaderButton } from './StornoSlideInContent.tsx';

const closeSlideIn = (store: Flux.Store): string => {
    PermitEditState.Edit.stateWrite(store, {
        action: undefined,
        to: undefined,
    });
    PermitEditState.Terminate.reset(store);
    PermitEditState.Refund.reset(store);
    PermitEditState.PermitCancellationResponse.reset(store);
    return 'TerminateDateSlideIn-cancel';
};

export function PermitCancellationSlideIn() {
    const { storeState, update } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        server: PermitEditState.Server.get(s),
        edit: PermitEditState.Edit.get(s),
        refund: PermitEditState.Refund.get(s),
        terminate: PermitEditState.Terminate.get(s),
        permitCancellationResponse:
            PermitEditState.PermitCancellationResponse.get(s),
        referenceData: OperatorDataState.get(s),
        currentOperatorLogin: CurrentOperatorLoginState.get(s),
    }));

    const confirmRefundAllowed =
        storeState.currentOperatorLogin.pending ||
        !storeState.currentOperatorLogin.data
            ? false
            : storeState.currentOperatorLogin.data.permissions.refundPermits;
    const isTerminationProcess =
        storeState.edit.action === PermitEditState.Edit.Action.terminate;

    function isFormValid() {
        const amount = storeState.refund.amount;
        const permit = storeState.server.data;

        if (
            storeState.refund.doRefund &&
            (!isDefined(amount) || Number(amount) <= 0)
        ) {
            return false;
        }

        if (
            isDefined(permit) &&
            isDefined(amount) &&
            permit.price < Number(amount)
        ) {
            return false;
        }

        return true;
    }

    return (
        <HalfSlideIn
            open={
                isTerminationProcess ||
                storeState.edit.action === PermitEditState.Edit.Action.storno
            }
            outsideBody
        >
            <Header
                settings={storeState.settings}
                isPending={storeState.permitCancellationResponse.pending}
                isTerminationProcess={isTerminationProcess}
                statusCode={storeState.permitCancellationResponse.statusCode}
                onClose={() => update(closeSlideIn)}
                headerButton={
                    isTerminationProcess ? (
                        <TerminateHeaderButton
                            permit={storeState.server.data}
                            terminate={storeState.terminate}
                            refund={storeState.refund}
                            refData={storeState.referenceData.data}
                            isFormValid={isFormValid()}
                            update={update}
                        />
                    ) : (
                        <StornoHeaderButton
                            permit={storeState.server.data}
                            refund={storeState.refund}
                            refData={storeState.referenceData.data}
                            update={update}
                        />
                    )
                }
            />
            {isTerminationProcess ? (
                <TerminateBody
                    permit={storeState.server.data}
                    confirmRefundAllowed={confirmRefundAllowed}
                    terminate={storeState.terminate}
                    refund={storeState.refund}
                    settings={storeState.settings}
                    isFormValid={isFormValid()}
                    update={update}
                />
            ) : (
                <StornoBody
                    permit={storeState.server.data}
                    confirmRefundAllowed={confirmRefundAllowed}
                    refund={storeState.refund}
                    settings={storeState.settings}
                    isFormValid={isFormValid()}
                    update={update}
                />
            )}
        </HalfSlideIn>
    );
}

export function Header({
    settings,
    isPending,
    isTerminationProcess,
    statusCode,
    headerButton,
    onClose,
}: {
    settings: SettingsState.State;
    isPending: boolean;
    isTerminationProcess: boolean;
    statusCode: HttpResponse.StatusCode;
    headerButton: JSX.Element;
    onClose: () => void;
}) {
    if (isPending) {
        return <LoaderHeader />;
    } else if (statusCode.cls.error) {
        return (
            <ErrorHeader
                language={settings.language}
                title={
                    statusCode.forbidden ? (
                        <Localized
                            de="Fehler: Keine Berechtigung"
                            fr="Erreur: Pas d'autorisation"
                            it="Errore: Nessuna autorizzazione"
                            en="Error: Forbidden"
                        />
                    ) : statusCode.notFound ? (
                        <Localized
                            de="Rückzahlung nicht möglich; ursprüngliches Zahlungsmittel nicht mehr verfügbar."
                            fr="Remboursement impossible; moyen de paiement original plus disponible."
                            it="Il rimborso non è possibile; il mezzo di pagamento originario non è più disponibile."
                            en="Refund not possible; original means of payment no longer available."
                        />
                    ) : (
                        <Localized
                            de={`Fehler beim ${
                                isTerminationProcess ? `Beenden` : `Stornieren`
                            } der Bewilligung.`}
                            fr={`Erreur pendant le processous pour ${
                                isTerminationProcess ? `terminer` : `annuler`
                            } l'autorisation.`}
                            it={`Errore durante il processo per ${
                                isTerminationProcess ? `terminare` : `annullare`
                            } l'autorizzazione`}
                            en={`Failed to ${
                                isTerminationProcess ? `terminate` : `cancel`
                            } the permit.`}
                        />
                    )
                }
                onCancel={onClose}
            />
        );
    } else {
        return (
            <SecondLevelHeader
                title={
                    isTerminationProcess ? (
                        <Localized
                            de="Bewilligung beenden"
                            fr="Terminer l'autorisation"
                            it="Terminare autorizzazione"
                            en="Terminate permit"
                        />
                    ) : (
                        <Localized
                            de="Bewilligung stornieren"
                            fr="Annuler l'autorisation"
                            it="Annullare l'autorizzazione"
                            en="Cancel permit"
                        />
                    )
                }
                onClose={onClose}
            >
                <SlideInHeaderButtonsContainer>
                    {headerButton}
                </SlideInHeaderButtonsContainer>
            </SecondLevelHeader>
        );
    }
}

export function RefundContent({
    permit,
    confirmRefundAllowed,
    onlyFullRefundAllowed,
    refund,
    settings,
    isFormValid,
    update,
}: {
    permit: PermitEditState.Permit;
    confirmRefundAllowed: boolean;
    onlyFullRefundAllowed: boolean;
    refund: PermitEditState.Refund.State;
    settings: SettingsState.State;
    isFormValid: boolean;
    update: Updater;
}) {
    const txt = PermitSlideInTexts.body[settings.language];
    return (
        <>
            <LabeledText
                label={
                    <Localized
                        de="Bewilligungspreis"
                        fr="Prix de l'autorisation"
                        it="Prezzo autorizzazione"
                        en="Permit price"
                    />
                }
            >
                {PermitEditState.getPermitPrice(
                    permit,
                    txt.RefundedNote,
                    settings.language,
                )}
            </LabeledText>

            <CheckBox
                permit={permit}
                refund={refund}
                confirmRefundAllowed={confirmRefundAllowed}
                onlyFullRefundAllowed={onlyFullRefundAllowed}
                update={update}
            />
            <CheckboxNotification
                permit={permit}
                confirmRefundAllowed={confirmRefundAllowed}
            />
            {!onlyFullRefundAllowed && (
                <AmountField
                    permit={permit}
                    confirmRefundAllowed={confirmRefundAllowed}
                    refund={refund}
                    isFormValid={isFormValid}
                    settings={settings}
                    update={update}
                />
            )}
            <RefundAmountNotification
                permit={permit}
                confirmRefundAllowed={confirmRefundAllowed}
                onlyFullRefundAllowed={onlyFullRefundAllowed}
                refund={refund}
            />
            <TextField
                value={getOrElse(refund.remark, permit.remark || '')}
                label={txt.RemarkLabel()}
                onChange={(v: string) =>
                    update(store =>
                        PermitEditState.Refund.stateWrite(store, {
                            remark: v,
                        }),
                    )
                }
                inputType="text"
            />
        </>
    );
}

function AmountField({
    permit,
    confirmRefundAllowed,
    refund,
    isFormValid,
    settings,
    update,
}: {
    permit: PermitEditState.Permit;
    confirmRefundAllowed: boolean;
    refund: PermitEditState.Refund.State;
    isFormValid: boolean;
    settings: SettingsState.State;
    update: Updater;
}) {
    if (
        isDefined(permit) &&
        confirmRefundAllowed &&
        isPermitRefundBookable(permit) &&
        refund.doRefund
    ) {
        return (
            <TextField
                value={getOrElse(refund.amount, '')}
                label={PermitSlideInTexts.body[settings.language].AmountLabel()}
                onChange={(v: string) =>
                    update(store =>
                        PermitEditState.Refund.stateWrite(store, {
                            amount: forceDecimal(v),
                        }),
                    )
                }
                inputType="text"
                errorText={
                    refund.confirmPressed && !isFormValid ? (
                        <Localized
                            de="Ungültiger Betrag"
                            fr="Montant pas valable"
                            it="Importo non valido"
                            en="Invalid amount"
                        />
                    ) : null
                }
            />
        );
    } else {
        return null;
    }
}

function RefundAmountNotification({
    permit,
    confirmRefundAllowed,
    onlyFullRefundAllowed,
    refund,
}: {
    permit: Maybe<PermitEditState.Permit>;
    confirmRefundAllowed: boolean;
    onlyFullRefundAllowed: boolean;
    refund: PermitEditState.Refund.State;
}) {
    if (
        isDefined(permit) &&
        confirmRefundAllowed &&
        isPermitRefundBookable(permit) &&
        refund.doRefund
    ) {
        if (
            permit.customer ||
            permit.paymentChannel === PaymentChannel.TWINT ||
            permit.paymentChannel == PaymentChannel.QUICKCHECKOUT
        ) {
            const amount = onlyFullRefundAllowed
                ? numberToPrice(Number(permit.price))
                : numberToPrice(Number(refund.amount));
            const account = PermitEditState.getCustomerAccountString(permit);
            return (
                <Notification
                    color={NotificationColor.blue}
                    icon={NotificationIcon.info}
                    title={
                        <Localized
                            de="Rückzahlungs-Informationen"
                            fr="Informations remboursement"
                            it="Informazioni rimborso"
                            en="Reimbursement informations"
                        />
                    }
                >
                    {permit.externalPayment ? (
                        <Localized
                            de={`Der Betrag von ${amount} wird dem verwendeten Zahlungsmittel gutgeschrieben.`}
                            fr={`Le montant de ${amount} est crédité sur le moyen de paiement utilisé.`}
                            it={`L'importo di ${amount} viene accreditato sul mezzo di pagamento utilizzato.`}
                            en={`The amount of ${amount} will be credited to the used means of payment.`}
                        />
                    ) : (
                        <Localized
                            de={`Der Betrag von ${amount} wird dem Parkingpay-Konto ${account} gutgeschrieben.`}
                            fr={`Le montant de ${amount} sera crédité sur le compte Parkingpay ${account}.`}
                            it={`L'importo di ${amount} verrà accreditato sul conto Parkingpay ${account}.`}
                            en={`The amount of ${amount} will be credited to the Parkingpay account ${account}.`}
                        />
                    )}
                </Notification>
            );
        } else {
            return (
                <Notification
                    color={NotificationColor.error}
                    icon={NotificationIcon.info}
                    title={
                        <Localized
                            de="Keine Automatische Rückzahlung!"
                            fr="Pas de remboursement automatique!"
                            it="Nessun rimborso automatico!"
                            en="No automatic refund!"
                        />
                    }
                >
                    <Localized
                        de="Dies ist eine Schalterbewilligung. Die Rückzahlung wird nur in der Bemerkung vermerkt."
                        fr="Il s'agit d'un autorisation de guichet. Le remboursement est seulement noté dans la remarque."
                        it="Questo è un autorizzazioni sportello. Il rimborso è indicato solo nel commento."
                        en="This is a counter permit. The refund is only noted in the remark."
                    />
                </Notification>
            );
        }
    } else {
        return null;
    }
}

function CheckBox({
    permit,
    confirmRefundAllowed,
    onlyFullRefundAllowed,
    refund,
    update,
}: {
    permit: PermitEditState.Permit;
    confirmRefundAllowed: boolean;
    onlyFullRefundAllowed: boolean;
    refund: PermitEditState.Refund.State;
    update: Updater;
}) {
    return (
        <div>
            <Switch
                label={
                    onlyFullRefundAllowed ? (
                        <Localized
                            de="Voller Betrag zurückzahlen"
                            fr="Rembourser le montant total"
                            it="Rimborsare l'intero importo"
                            en="Refund full amount"
                        />
                    ) : (
                        <Localized
                            de="Restbetrag zurückzahlen"
                            fr="Rembourser le montant résiduel"
                            it="Rimborsare importo residuo"
                            en="Reimburse balance"
                        />
                    )
                }
                onClick={
                    confirmRefundAllowed && isPermitRefundBookable(permit)
                        ? () =>
                              update(store =>
                                  PermitEditState.Refund.stateWrite(store, {
                                      doRefund: !refund.doRefund,
                                  }),
                              )
                        : () => null
                }
                selected={thenElse(
                    refund.doRefund,
                    r => r,
                    confirmRefundAllowed && isPermitRefundBookable(permit),
                )}
                type={SwitchType.Checkbox}
                disabled={
                    !confirmRefundAllowed ||
                    !isPermitRefundBookable(permit) ||
                    (!!permit.customer && !!permit.customer.accountTerminatedAt)
                }
            />
        </div>
    );
}

function CheckboxNotification({
    permit,
    confirmRefundAllowed,
}: {
    permit: PermitEditState.Permit;
    confirmRefundAllowed: boolean;
}) {
    if (!permit) {
        return null;
    }

    if (!!permit.customer && !!permit.customer.accountTerminatedAt) {
        return (
            <NotificationShort
                title={
                    <Localized
                        de="Benutzerkonto wurde gekündigt"
                        fr="le compte utilisateur a été annulé"
                        it="Il conto utente è stato annullato"
                        en="Customer account was canceled"
                    />
                }
                body={
                    <Localized
                        de="Es ist nicht möglich, eine Rückzahlung auf ein gekündigtes Konto vorzunehmen."
                        fr="Il n'est pas possible d'effectuer un remboursement sur un compte annulé."
                        it="Non è possibile effettuare un rimborso su un conto annullato."
                        en="It is not possible to make a refund to a cancelled account."
                    />
                }
            />
        );
    }

    if (confirmRefundAllowed && isPermitRefundBookable(permit)) {
        return null;
    }

    if (!permitHasAccount(permit) || !permitHasCustomer(permit)) {
        return (
            <NotificationShort
                title={<RefundNotPossibleTitle />}
                body={
                    <Localized
                        de="Diese Bewilligung wurde nicht online gekauft, deswegen kann der Betrag nicht automatisch zurückbezahlt werden."
                        fr="Cette autorisation n'a pas été achetée en ligne; pour cette raison le montant ne peut pas être remboursé automatiquement."
                        it="Quest'autorizzazione non è stata acquistata online; per questo motivo l'importo non può essere rimborsato automaticamente."
                        en="This permit was not bought online; because of that, the amount cannot be automatically refunded."
                    />
                }
            />
        );
    }

    if (permitAccountLocked(permit)) {
        const account = PermitEditState.getCustomerAccountString(permit);
        return (
            <NotificationShort
                title={<RefundNotPossibleTitle />}
                body={
                    <Localized
                        de={`Das Parkingpay-Konto ${account}, mit dem diese Bewilligung gekauft wurde, ist nicht mehr aktiv, deswegen kann der Betrag nicht automatisch zurückbezahlt werden.`}
                        fr={`Le compte Parkingpay ${account}, avec le quelle cette autorisation a été achetée, n'est plus active; pour cette raison le montant ne peut pas être remboursé automatiquement.`}
                        it={`Il conto Parkingpay ${account}, con il quale è stata acquistata quest'autorizzazione, non è più attivo; per questo motivo l'importo non può essere rimborsato automaticamente.`}
                        en={`The Parkingpay account ${account}, that bought this Permit, is not active anymore; because of that, the amount cannot be automatically refunded.`}
                    />
                }
            />
        );
    }

    if (confirmRefundAllowed) {
        return (
            <NotificationShort
                title={
                    <Localized
                        de="Rückzahlung nicht mehr möglich"
                        fr="Remboursement pas possible"
                        it="Rimborso non possibile"
                        en="Refund not possible anymore"
                    />
                }
                body={
                    <Localized
                        de="Für diese Bewilligung wurde bereits eine Rückzahlung getätigt."
                        fr="Pour cette autorisation un remboursement a déjà été effectué."
                        it="Per quest'autorizzazione è già stato effettuato un rimborso."
                        en="A refund has already been made for this permit."
                    />
                }
            />
        );
    }

    return (
        <NotificationShort
            title={<RefundNotPossibleTitle />}
            body={
                <Localized
                    de="Ihr Zugang ist für die Rückzahlung von Bewilligungen nicht berechtigt."
                    fr="Votre accès n'est pas activé pour effectuer des remboursements des autorisations."
                    it="Il suo accesso non é abilitato per effettuare dei rimborsi di autorizzazioni."
                    en="Your access is not enabled to carry out authorization refunds."
                />
            }
        />
    );
}

function RefundNotPossibleTitle() {
    return (
        <Localized
            de="Rückzahlung nicht möglich"
            fr="Remboursement pas possible"
            it="Rimborso non possibile"
            en="Refund not possible"
        />
    );
}

function NotificationShort({
    title,
    body,
}: {
    title: JSX.Element | string;
    body: JSX.Element | string;
}) {
    return (
        <Notification
            color={NotificationColor.blue}
            icon={NotificationIcon.info}
            title={title}
        >
            {body}
        </Notification>
    );
}
