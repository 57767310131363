import { Switch, SwitchType } from '../switches/Switches.tsx';
import { DivGenerator } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import React from 'react';

export interface Option<T extends number> {
    id: T;
    displayText: string;
}

interface SlideInSubheaderProps<T extends number> {
    options: Option<T>[];
    value: T;
    onSelect(value: T): void;
}

export const SubheaderContainer = DivGenerator(
    css({
        position: 'absolute',
        top: '64px',
        width: '100%',
        height: '40px',
        backgroundColor: ColorHex.rgba(ColorHex.lightblue, 0.1),
        padding: '7px 48px 0',
        borderBottom: `1px solid ${ColorHex.rgba(ColorHex.darkblue, 0.25)}`,
    }),
);

export class SlideInSubheader extends React.Component<
    SlideInSubheaderProps<number>
> {
    static displayName = 'SlideInSubheader';

    render() {
        return (
            <SubheaderContainer>
                {this.props.options.map(option => {
                    return (
                        <Switch
                            type={SwitchType.RadioButton}
                            key={option.id}
                            label={option.displayText}
                            selected={this.props.value === option.id}
                            disabled={false}
                            onClick={() => this.props.onSelect(option.id)}
                        />
                    );
                })}
            </SubheaderContainer>
        );
    }
}
