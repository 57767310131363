import { CancelButton, DiscardChangesButton } from './DiscardChangesButton.tsx';
import { SubmitButton } from '../../ui/buttons/SubmitButton.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { useNavigate } from 'react-router-dom';

export function HeaderCancelSaveButtons({
    onCancel,
    onSave,
    loading,
    saveDisabled,
    noDiscardDialog,
}: {
    onCancel?: () => void;
    onSave?: () => void;
    loading: boolean;
    saveDisabled?: boolean;
    noDiscardDialog?: boolean;
}) {
    const navigate = useNavigate();
    return (
        <>
            {noDiscardDialog ? (
                <CancelButton
                    onClick={() => (onCancel ? onCancel() : navigate('..'))}
                    disabled={loading}
                />
            ) : (
                <DiscardChangesButton
                    onReset={() => (onCancel ? onCancel() : navigate('..'))}
                    disabled={loading}
                />
            )}
            <SubmitButton
                loading={loading}
                onClick={onSave}
                label={
                    <Localized
                        de="Speichern"
                        fr="Sauvegarder"
                        it="Salva"
                        en="Save"
                    />
                }
                disabled={saveDisabled}
            />
        </>
    );
}
