import moment from 'moment';
import { isDefined, Maybe } from '../lib/MaybeV2';
import { parsePermissionTag, PermissionTag } from './PermissionTag';
import { TomLanguage } from './TomLanguage.ts';

export type UsbStickType = 'collection' | 'maintenance';
export namespace UsbStickType {
    export const COLLECTION: UsbStickType = 'collection';
    export const MAINTENANCE: UsbStickType = 'maintenance';
}

export enum UsbStickState {
    planning,
    commissioned,
    invalidated,
    deleted,
    unknown,
}

export interface UsbStick {
    stickId?: Maybe<number>;
    operatorId?: Maybe<number>;
    type: UsbStickType;
    state: UsbStickState;
    name: string;
    lastWrite?: Maybe<moment.Moment>;
    needsSynchronization?: Maybe<boolean>;
    whitelistStickId?: Maybe<number>;
    usbSerialNumber?: Maybe<string>;
    usbVendorId?: Maybe<number>;
    usbProductId?: Maybe<number>;
}

export interface UsbStickDetail extends UsbStick {
    configurations: UsbStickConfiguration[];
}

export interface UsbStickConfiguration {
    stickId?: Maybe<number>;
    configurationId?: Maybe<number>;
    version: number;
    operatorId: number;
    authenticationCodeVersion?: number;
    pinCode?: Maybe<string>;
    language: TomLanguage;
    expiryDate?: Maybe<moment.Moment>;
    tags: PermissionTag[];
    lastWrite?: Maybe<moment.Moment>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUsbStick(t: any): UsbStick {
    const parseUsbStickState = (serverStickState: string): UsbStickState => {
        const mapping: { [serverState: string]: UsbStickState } = {
            planning: UsbStickState.planning,
            commissioned: UsbStickState.commissioned,
            invalidated: UsbStickState.invalidated,
            deleted: UsbStickState.deleted,
        };
        if (!isDefined(mapping[serverStickState])) {
            return UsbStickState.unknown;
        }
        return mapping[serverStickState];
    };

    return Object.assign(t, {
        stickId: t.stickId,
        operatorId: t.operatorId,
        type: t.stickType as UsbStickType,
        state: parseUsbStickState(t.state),
        name: t.name,
        lastWrite: t.lastWrite ? moment(t.lastWrite) : null,
        needsSynchronization: t.needsSynchronization,
        usbSerialNumber: t.usbSerialNumber,
        usbProductId: t.usbProductId,
        usbVendorId: t.usbVendorId,
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseStickDetail(t: any): UsbStickDetail {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const baseStick: any = parseUsbStick(t);

    return <UsbStickDetail>Object.assign(baseStick, {
        // extended properties for detail view of usb stick
        configurations: t.configurations.map(parseStickConfiguration),
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseStickConfiguration(c: any): UsbStickConfiguration {
    return Object.assign(c, {
        stickId: c.stickId,
        configurationId: c.configurationId,
        version: c.version,
        operatorId: c.operatorId,
        authenticationCodeVersion: c.authenticationCodeVersion,
        pinCode: c.pinCode,
        language: c.language,
        expiryDate: c.expiryDate ? moment(c.expiryDate) : null,
        tags: c.tags.map(parsePermissionTag),
        lastWrite: c.lastWrite ? moment(c.lastWrite) : null,
    });
}
