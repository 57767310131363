import * as LicensePlateType from 'dg-web-shared/dto/LicensePlateType.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';

export type MasterDataZone = {
    id: number;
    zipCode: string;
    licensePlateTypes: LicensePlateType.LicensePlateType[];
    city: string;
    zoneName: string;
    zoneCode: number;
    needsPermit: boolean;
    transactionType: TransactionType | null;
    vatNumber: string | null;
    dtaAccountId: number;
    isFromForeignMandant: boolean;
    relevance: MasterDataZoneRelevance;
    hideInEnforcement: boolean;
    geodataText: string | null;
    signalisationPdfOnstreet: string | null;
};

export enum TransactionType {
    start_stop = 'start_stop',
    duration = 'duration',
}

type MasterDataZoneRelevance =
    | MasterDataZoneRelevanceActive
    | MasterDataZoneRelevanceHasNonActiveUsages;

type MasterDataZoneRelevanceActive = { tag: MasterDataZoneRelevanceTag.ACTIVE };

type MasterDataZoneRelevanceHasNonActiveUsages = {
    tag: MasterDataZoneRelevanceTag.HAS_NON_ACTIVE_USAGES;
    hasCheckIns: boolean;
    hasEnforcementLogEntries: boolean;
};

export enum MasterDataZoneRelevanceTag {
    ACTIVE = 'ACTIVE',
    HAS_NON_ACTIVE_USAGES = 'HAS_NON_ACTIVE_USAGES',
}

export type State = ServerStateSlice.ServerState<MasterDataZone[]>;

export const { get, reset, setResponse } = ServerStateSlice.generateServerState<
    MasterDataZone[]
>(
    'common-MasterDataZones',
    () => [],
    (store: Flux.Store, state: State) => {
        if (state.shouldFetch) {
            store.update(fetchZones);
        }
    },
    (body: any): MasterDataZone[] =>
        body?.map((z: any): MasterDataZone => z) ?? [],
);

const fetchZones = AsyncRequest.request(
    Http.OperatorAccount.MasterDataZones.get,
    (store: Flux.Store, res: Response): string => {
        setResponse(store, res);
        return 'ReferenceDataState-fetchMasterDataZones';
    },
);

export const fullZoneName = (z: MasterDataZone) =>
    `${z.zipCode} ${z.city} — ${z.zoneCode} ${z.zoneName}`;
