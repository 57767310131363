export type PermitSelectZone = 'NO_CHOICE' | 'SELECT_ONE' | 'SELECT_MANY';

export function parseSelectOptionType(selectZone: number): PermitSelectZone {
    switch (selectZone) {
        case 2:
            return 'SELECT_ONE';
        case 0:
            return 'SELECT_MANY';
        default:
            return 'NO_CHOICE';
    }
}
