import Alert from '@mui/material/Alert';
import { Localized } from '../common/components/Localized.tsx';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { GenericFormError } from 'dg-web-shared/common/utils/FormHooksUtils.tsx';

export function GenericFormSubmitError({
    error,
    submitState,
}: {
    error: GenericFormError | null;
    submitState?: ServerRequestState<unknown, unknown>;
}) {
    if (!error) {
        return null;
    }

    if (error.isGenericServerError) {
        return (
            <Alert severity="error" sx={{ margin: '0 0 16px 0' }}>
                <>
                    <Localized
                        de="The request returned an error: status "
                        fr="The request returned an error: status "
                        it="The request returned an error: status "
                        en="The request returned an error: status "
                    />
                    {submitState?.status === RequestStatus.ERROR &&
                        submitState.httpStatusCode}
                </>
            </Alert>
        );
    }

    return (
        <>
            {error.issues.map((e, i) => (
                <Alert key={i} severity="error" sx={{ margin: '0 0 16px 0' }}>
                    <Localized {...e.issue.humanReadableMessage.byLanguage} />
                </Alert>
            ))}
        </>
    );
}
