import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as LicensePlateState from '../state/LicensePlateState.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as AddLicensePlateTexts from '../i18n/AddLicensePlateTexts.ts';
import { LicensePlateCountrySlideIn } from '../../common/components/LicensePlateCountrySlideIn.tsx';

export interface Texts {
    Heading: Translation;
    Caption: Translation;
}

interface State {
    settings: SettingsState.State;
    addLp: LicensePlateState.AddTripple.State;
}

export class AddLicensePlateCountrySlideIn extends Flux.Container<State> {
    static displayName = 'LicensePlateCountrySlideIn';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            addLp: LicensePlateState.AddTripple.get(this.props.allState),
        };
    }

    txt(): Texts {
        return AddLicensePlateTexts.countrySelection[
            this.state.settings.language
        ];
    }

    render() {
        return (
            <LicensePlateCountrySlideIn
                language={this.state.settings.language}
                open={this.state.addLp.lpCountrySelectionOpen}
                onSelect={(v: string, formValid: boolean) =>
                    this.props.allState.update((store: Flux.Store): string => {
                        LicensePlateState.AddTripple.stateWrite(store, {
                            country: v,
                            lpCountrySelectionOpen: false,
                            formValid,
                        });
                        return 'LicensePlateCountrySlideIn-SelectLicensePlateCountry';
                    })
                }
                country={this.state.addLp.country}
                licensePlateType={this.state.addLp.type}
                licensePlateNr={this.state.addLp.licensePlateNr}
                onClose={() =>
                    this.update(store =>
                        LicensePlateState.AddTripple.stateWrite(store, {
                            lpCountrySelectionOpen: false,
                        }),
                    )
                }
                outsideBody
            />
        );
    }
}
