import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { NumberMultiSelectionSlideIn } from '../../ui/slidein/MultiSelectionSlideIn.tsx';
import { NumberSingleSelectionSlideIn } from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import { zoneToString } from './PermitCreate.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { PermitSelectZone } from '../../app/state/PermitSelectZone.ts';

export function ZonesSelection({
    open,
    selectZoneType,
    zones,
    selection,
    onClose,
    onSingleSelect,
    onMultiSelect,
}: {
    open: boolean;
    selectZoneType: PermitSelectZone;
    zones: PermitTypeState.Zone[];
    selection: number[] | null;
    onClose: () => void;
    onSingleSelect: (id: number) => void;
    onMultiSelect: (ids: number[]) => void;
}) {
    const options = zones.map(z => {
        return {
            displayText: zoneToString(z) as string,
            id: z.id,
            disabled: z.restrainedZone,
        };
    });

    if (selectZoneType === 'SELECT_ONE') {
        return (
            <NumberSingleSelectionSlideIn
                heading={<ZoneSelectionText />}
                onLeftActionClick={onClose}
                onSelect={onSingleSelect}
                open={open}
                options={options}
                selection={selection && selection[0]}
                outsideBody
            />
        );
    } else {
        return (
            <NumberMultiSelectionSlideIn
                heading={<ZoneSelectionText />}
                onLeftActionClick={onClose}
                setNewSelection={onMultiSelect}
                open={open}
                options={options}
                selection={selection || []}
                outsideBody
            />
        );
    }
}

export function ZoneSelectionSlideIn() {
    const { storeState, update } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        layout: PermitCreateState.Layout.get(s),
        config: PermitCreateState.Config.get(s),
        permitType: PermitTypeState.getById(
            s,
            PermitCreateState.Config.get(s).permitTypeId,
        ),
    }));

    const permitType = storeState.permitType;
    if (!permitType) {
        return null;
    } else {
        return (
            <ZonesSelection
                open={storeState.layout.zonePickerOpen}
                selection={storeState.config.onstreetZones}
                selectZoneType={permitType.selectZones}
                zones={permitType.zones}
                onClose={() =>
                    update(store =>
                        PermitCreateState.Layout.stateWrite(store, {
                            zonePickerOpen: false,
                        }),
                    )
                }
                onSingleSelect={(id: number) =>
                    update((store): string => {
                        PermitCreateState.Config.stateWrite(store, {
                            onstreetZones: [id],
                        });
                        PermitCreateState.Layout.stateWrite(store, {
                            zonePickerOpen: false,
                        });
                        return 'PermitCreateZoneSelectionSlideIn-selectZone';
                    })
                }
                onMultiSelect={(ids: number[]) =>
                    update((store): string => {
                        PermitCreateState.Config.stateWrite(store, {
                            onstreetZones: ids,
                        });
                        return 'PermitCreateZoneSelectionSlideIn-selectZone';
                    })
                }
            />
        );
    }
}

function ZoneSelectionText() {
    return (
        <Localized
            de="Zonenauswahl"
            fr="Sélection de la zone"
            it="Selezione della zona"
            en="Zone selection"
        />
    );
}
