import { css } from '@emotion/css';
import { ColorHex } from '../ui/Colors.ts';

import bg from './bg_landingpage.jpg';

export function AlternativeLanding(props: Props) {
    return (
        <div className={topStyle}>
            <div className={contentStyle}>{props.children}</div>
        </div>
    );
}

interface Props {
    children: React.ReactNode;
}

const topStyle = css`
    background-image: url(${bg});
    background-clolr: ${ColorHex.black};
    background-size: cover;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const contentStyle = css`
    align-self: center;
    background-color: ${ColorHex.darkblue};
    color: ${ColorHex.white};
    width: 320px;
    height: 420px;
`;
