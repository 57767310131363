import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ParkTransactionsState from '../state/ParkTransactionsState.ts';
import moment from 'moment';
import {
    fromDateConstraint,
    toDateConstraint,
} from '../../clearance-permit-list/actions/shared.ts';
import { TomTransaction } from 'dg-web-shared/model/TomTransaction.ts';

export const setValidFrom = (s: Flux.Store, d: moment.Moment): string => {
    ParkTransactionsState.Filter.stateWrite(s, {
        validFrom: d,
        validTo: toDateConstraint(
            d,
            ParkTransactionsState.Filter.get(s).validTo,
        ),
    });
    ParkTransactionsState.List.Completed.reset(s);
    return 'park-transactions-setDateFrom';
};

export const setValidTo = (s: Flux.Store, d: moment.Moment): string => {
    ParkTransactionsState.Filter.stateWrite(s, {
        validTo: d,
        validFrom: fromDateConstraint(
            d,
            ParkTransactionsState.Filter.get(s).validFrom,
        ),
    });
    ParkTransactionsState.List.Completed.reset(s);
    return 'park-transactions-setDateTo';
};

export const clearValidDate = (s: Flux.Store) => {
    ParkTransactionsState.Filter.stateWrite(s, {
        validFrom: null,
        validTo: null,
    });
    ParkTransactionsState.List.Completed.reset(s);
    return 'park-transactions-clearDateFrom';
};

export const clearToms = (s: Flux.Store) => {
    ParkTransactionsState.Filter.stateWrite(s, {
        toms: [],
    });
    ParkTransactionsState.List.Completed.reset(s);
    return 'park-transactions-clearToms';
};

export const setToms = (s: Flux.Store, ids: number[]) => {
    ParkTransactionsState.Filter.stateWrite(s, {
        toms: ids,
    });
    ParkTransactionsState.List.Completed.reset(s);
    return 'park-transactions-setToms';
};

export const openTomsFilter = (s: Flux.Store) => {
    ParkTransactionsState.Filter.stateWrite(s, {
        tomSelectionVisible: true,
    });
    return 'park-transactions-tomsSelection-openFilter';
};

export const closeTomsFilter = (s: Flux.Store) => {
    ParkTransactionsState.Filter.stateWrite(s, {
        tomSelectionVisible: false,
    });
    return 'park-transactions-tomsSelection-closeFilter';
};

export const clearFilter = (s: Flux.Store) => {
    ParkTransactionsState.Filter.stateWrite(s, {
        validFrom: null,
        validTo: null,
        toms: [],
    });
    ParkTransactionsState.List.Completed.reset(s);
    return 'park-transactions-clearFilter';
};

export const openTransactionDetail = (s: Flux.Store, t: TomTransaction) => {
    ParkTransactionsState.Detail.stateWrite(s, {
        selectedTransaction: t,
    });
    return 'park-transactions-openTransactionDetail';
};

export const closeTransactionDetail = (s: Flux.Store) => {
    ParkTransactionsState.Detail.reset(s);
    return 'park-transactions-closeTransactionDetail';
};
