import * as Flux from 'dg-web-shared/lib/Flux.tsx';

export namespace LoginState {
    export interface State {
        username: string | null;
        password: string | null;
        longSession: boolean;
        showCredentialsError: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'landing-page/LandingPageState.LoginState',
        {
            username: null,
            password: '',
            longSession: false,
            showCredentialsError: false,
        },
    );
}
