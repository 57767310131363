import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore, useUpdate } from 'dg-web-shared/lib/Flux.tsx';
import { Conditional, ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as MetaState from '../../common/state/MetaState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { Spinner } from '../../ui/loaders/Spinner.tsx';
import {
    ConfirmationHeader,
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from '../../ui/slidein/Slidein.tsx';
import { RadioButton } from '../../ui/switches/Switches.tsx';
import { addressTexts } from '../i18n/AddressTexts.ts';
import * as AddressState from '../state/AddressState.ts';
import { isFemale, isMale } from '../state/AddressState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import * as emailValidator from 'email-validator';
import { Localized } from '../../common/components/Localized.tsx';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { isExpired } from '../../clearance-permit-list/state/PermitsState.ts';

interface TextProps {
    settings: SettingsState.State;
}

const texts = (s: TextProps) => addressTexts[s.settings.language];

export const clearAddress = (store: Flux.Store): void => {
    AddressState.Edit.reset(store);
    AddressState.Select.reset(store);
    AddressState.ServerList.reset(store);
};

export interface AddressTexts {
    address: Translation;
    addNewAddress: Translation;
    selectAddress: Translation;
    editAddress: Translation;
    insertAddress: Translation;

    gender: Translation;
    male: Translation;
    female: Translation;

    firstname: Translation;
    lastname: Translation;
    company1: Translation;
    company2: Translation;
    address1: Translation;
    address2: Translation;
    zipcode: Translation;
    city: Translation;
    country: Translation;
    language: Translation;
    phone: Translation;
    email: Translation;
}

interface AddressProps {
    address: AddressState.Address;
    txt: AddressTexts;
}

const Address = (p: AddressProps): JSX.Element => {
    const visible = (s: string | null | undefined) => !!s && s.length > 0;
    const AddressLine = (p: { s: string | null | undefined }) => (
        <Conditional c={visible(p.s)}>
            <div>{p.s}</div>
        </Conditional>
    );
    const country = p.address.country ?? '';
    const zipcode = p.address.zipcode ?? '';
    const city = p.address.city ?? '';
    return (
        <div>
            <AddressLine s={p.address.company1} />
            <AddressLine s={p.address.company2} />
            <div>{`${p.address.firstname || ''} ${
                p.address.lastname || ''
            }`}</div>
            <AddressLine s={p.address.address1} />
            <AddressLine s={p.address.address2} />
            <div>{`${country} ${
                country && zipcode ? '‒' : ''
            } ${zipcode} ${city}`}</div>
        </div>
    );
};

interface AddressSelectionProps {
    address: AddressState.Address | null | undefined;
    onEdit: () => void;
    onClear: (() => void) | null;
    disabled: boolean;
    showRequiredError: boolean;
}

interface AddressSelectionState {
    settings: SettingsState.State;
    permitConfig: PermitCreateState.Config.State;
    permitServer: PermitEditState.Server.State;
    edit: AddressState.Edit.State;
}

export function AddressSelection(p: AddressSelectionProps) {
    const { storeState } = useStore<AddressSelectionState>(s => ({
        settings: new SettingsState.StateSlice(s).state,
        permitConfig: PermitCreateState.Config.get(s),
        permitServer: PermitEditState.Server.get(s),
        edit: AddressState.Edit.get(s),
    }));
    return (
        <SingleSelection
            focused={false}
            disabled={false}
            label={texts(storeState).address()}
            selection={
                !p.address ? null : (
                    <Address address={p.address} txt={texts(storeState)} />
                )
            }
            onClick={p.onEdit}
            onClear={!p.disabled ? p.onClear : undefined}
            errorText={
                p.showRequiredError && !p.address ? <RequiredField /> : null
            }
        />
    );
}

interface AddressSlideInState {
    settings: SettingsState.State;
    select: AddressState.Select.State;
    formValues: AddressState.Address;
    validation: Validation;
    formHasChanges: boolean;
    showAddressEditForm: boolean;
    permitCreate: PermitCreateState.Config.State;
    permitServer: PermitEditState.Server.State;
    permitEdit: PermitEditState.Edit.State;
    permitCreateLayout: PermitCreateState.Layout.State;
}

const scn = ElementNamer('AddressSlideIn');

export function AddressSlideIn() {
    const { storeState } = useStore<AddressSlideInState>(s => {
        const permitCreateLayout = PermitCreateState.Layout.get(s);
        const permitCreate = PermitCreateState.Config.get(s);
        const permitServer = PermitEditState.Server.get(s);
        const permitEdit = PermitEditState.Edit.get(s);
        const edit = AddressState.Edit.get(s);
        const select = AddressState.Select.get(s);
        const login = CurrentOperatorLoginState.get(s);
        const settings = new SettingsState.StateSlice(s).state;
        const currentlySavedState = permitCreateLayout.createEnabled
            ? permitCreate.address
            : permitServer.data?.address;

        const formValues: AddressState.Address = {
            ...(currentlySavedState ?? ({} as AddressState.Address)),
            ...edit.address,
            language: edit.address.language || settings.language,
        };

        const formHasChanges = currentlySavedState
            ? AddressState.Edit.hasChanges(currentlySavedState, formValues)
            : false;

        const validation = validateValues(
            login.data ? login.data.mandantId : 0,
            formValues,
        );

        const showAddressEditForm =
            select.addressSelected ||
            !!permitCreate.address ||
            !!permitEdit.address;
        return {
            settings: new SettingsState.StateSlice(s).state,
            select,
            formValues,
            permitCreate,
            permitServer,
            permitEdit,
            permitCreateLayout,
            formHasChanges,
            showAddressEditForm,
            validation,
        };
    });
    const isInCreateMode = Boolean(storeState.permitCreate.permitTypeId);
    const readonly = storeState.permitServer.data
        ? isExpired(storeState.permitServer.data) && !isInCreateMode
        : false;

    return (
        <HalfSlideIn open={storeState.select.selectAddressOpen} outsideBody>
            <AddressSlideInBody {...storeState} readonly={readonly} />
            <AddressSlideInHeader {...storeState} readonly={readonly} />
        </HalfSlideIn>
    );
}

function AddressSlideInHeader(p: AddressSlideInState & { readonly: boolean }) {
    const update = useUpdate();
    const atxt = texts(p);

    const saveAddress = (store: Flux.Store): string => {
        const server = p.permitServer.data;

        if (!!server && !p.permitCreateLayout.createEnabled) {
            PermitEditState.Edit.stateWrite(store, {
                address: p.formValues,
                addressId: undefined,
            });
        } else {
            PermitCreateState.Config.stateWrite(store, {
                address: p.formValues,
            });
        }

        AddressState.Select.stateWrite(store, {
            selectAddressOpen: false,
            addressSelected: true,
        });
        return scn('-saveAddress');
    };

    const cancel = (store: Flux.Store): string => {
        AddressState.Select.reset(store);
        AddressState.Edit.reset(store);
        if (p.permitCreate.address) {
            AddressState.Edit.stateWrite(store, {
                address: { ...p.permitCreate.address },
            });
        }
        return scn('-cancel');
    };

    const hasServerAddress = !!p.permitServer.data?.address;
    if (p.showAddressEditForm && !p.readonly) {
        const heading = hasServerAddress
            ? atxt.editAddress()
            : atxt.insertAddress();
        return (
            <ConfirmationHeader
                language={p.settings.language}
                title={heading}
                onCancel={() => update(cancel)}
                onConfirm={
                    p.validation.isValid
                        ? () => update(saveAddress)
                        : () => null
                }
                confirmButtonSpecialState={
                    !p.validation.isValid ? ButtonSpecialState.ERROR : null
                }
            />
        );
    }
    return (
        <SecondLevelHeader onClose={() => update(cancel)}>
            <SlideInHeaderTexts title={atxt.address()} hasLeftIcon={false} />
        </SecondLevelHeader>
    );
}

function AddressSlideInBody(p: AddressSlideInState & { readonly: boolean }) {
    return (
        <SlideInBody>
            {p.showAddressEditForm ? (
                <AddressEditColumn
                    readonly={p.readonly}
                    formValues={p.formValues}
                    validation={p.validation}
                />
            ) : (
                <AddressSelectionColumn />
            )}
        </SlideInBody>
    );
}

interface AddressSelectionColumnState {
    settings: SettingsState.State;
    serverList: AddressState.ServerList.State;
}

const ascn = ElementNamer('AddressSelectionColumn');
const setAddress = (
    store: Flux.Store,
    address: Partial<AddressState.Address>,
): string => {
    AddressState.Select.stateWrite(store, { addressSelected: true });
    AddressState.Edit.stateWrite(store, { address: { ...address } });
    return ascn('-selectedAddress');
};

function AddressSelectionColumn() {
    const { storeState, update } = useStore<AddressSelectionColumnState>(s => {
        return {
            settings: new SettingsState.StateSlice(s).state,
            serverList: AddressState.ServerList.get(s),
        };
    });
    return (
        <div>
            <SingleSelection
                focused={false}
                label={texts(storeState).addNewAddress()}
                selection={null}
                onClick={() =>
                    update(store =>
                        setAddress(
                            store,
                            AddressState.Edit.initializeAddress(),
                        ),
                    )
                }
            />
            {(() => {
                if (storeState.serverList.pending) {
                    return <Spinner loading={true} />;
                }
                return storeState.serverList.data.map((a, idx) => (
                    <SingleSelection
                        key={a.customerNr ?? idx}
                        focused={false}
                        label={texts(storeState).selectAddress()}
                        selection={
                            <Address address={a} txt={texts(storeState)} />
                        }
                        onClick={() => update(store => setAddress(store, a))}
                    />
                ));
            })()}
        </div>
    );
}

function AddressEditColumn(p: {
    formValues: AddressState.Address;
    validation: Validation;
    readonly?: boolean;
}) {
    const { storeState, update, store } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        languages: MetaState.Languages.get(s),
    }));
    const write = (a: Partial<AddressState.Address>) =>
        update(store => AddressState.Edit.writeAddress(store, a));
    const atxt = texts(storeState);
    const countryName = (c: string) =>
        MetaState.AddressCountries.getName(
            store,
            c,
            storeState.settings.language,
        );

    return (
        <div>
            <LabeledText label={atxt.gender()}>
                <div>
                    <RadioButton
                        label={atxt.male()}
                        selected={isMale(p.formValues.gender)}
                        disabled={p.readonly}
                        onClick={() =>
                            write({
                                gender: isMale(p.formValues.gender) ? '' : 'm',
                            })
                        }
                    />
                </div>
                <div>
                    <RadioButton
                        label={atxt.female()}
                        selected={isFemale(p.formValues.gender)}
                        disabled={p.readonly}
                        onClick={() =>
                            write({
                                gender: isFemale(p.formValues.gender)
                                    ? ''
                                    : 'f',
                            })
                        }
                    />
                </div>
            </LabeledText>
            <TextField
                inputType="text"
                value={p.formValues.firstname ?? ''}
                label={texts(storeState).firstname()}
                onChange={v => write({ firstname: v })}
                errorText={p.validation.firstname}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.lastname ?? ''}
                label={texts(storeState).lastname()}
                onChange={v => write({ lastname: v })}
                errorText={p.validation.lastname}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.company1 ?? ''}
                label={texts(storeState).company1()}
                onChange={v => write({ company1: v })}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.company2 ?? ''}
                label={texts(storeState).company2()}
                onChange={v => write({ company2: v })}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.address1 ?? ''}
                label={texts(storeState).address1()}
                onChange={v => write({ address1: v })}
                errorText={p.validation.address1}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.address2 ?? ''}
                label={texts(storeState).address2()}
                onChange={v => write({ address2: v })}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.zipcode ?? ''}
                label={texts(storeState).zipcode()}
                onChange={v => write({ zipcode: v })}
                errorText={p.validation.zipcode}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.city ?? ''}
                label={texts(storeState).city()}
                onChange={v => write({ city: v })}
                errorText={p.validation.city}
                disabled={p.readonly}
            />
            <SingleSelection
                focused={false}
                label={texts(storeState).country()}
                selection={
                    p.formValues.country
                        ? countryName(p.formValues.country)
                        : null
                }
                onClick={() =>
                    update(store =>
                        AddressState.Edit.stateWrite(store, {
                            selectAddressCountryOpen: true,
                        }),
                    )
                }
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.email ?? ''}
                label={texts(storeState).email()}
                onChange={v => write({ email: v })}
                errorText={p.validation.email}
                disabled={p.readonly}
            />
            <TextField
                inputType="text"
                value={p.formValues.phone ?? ''}
                label={texts(storeState).phone()}
                onChange={v => write({ phone: v })}
                disabled={p.readonly}
            />
            <LabeledText label={texts(storeState).language()}>
                <LanguageOptions
                    settings={storeState.settings}
                    languages={storeState.languages}
                    formValues={p.formValues}
                    disabled={p.readonly}
                />
            </LabeledText>
        </div>
    );
}

interface Validation {
    gender?: React.ReactNode;
    firstname?: React.ReactNode;
    lastname?: React.ReactNode;
    address1?: React.ReactNode;
    city?: React.ReactNode;
    zipcode?: React.ReactNode;
    email?: React.ReactNode;
    isValid: boolean;
}

function validateValues(
    operatorId: number,
    formValues: AddressState.Address,
): Validation {
    if (operatorId !== 93) {
        return { isValid: true };
    }
    const firstname =
        !formValues.firstname && !formValues.company1 ? (
            <RequiredField />
        ) : undefined;
    const lastname =
        !formValues.lastname && !formValues.company1 ? (
            <RequiredField />
        ) : undefined;
    const address1 = !formValues.address1 ? <RequiredField /> : undefined;
    const city = !formValues.city ? <RequiredField /> : undefined;
    const zipcode = !formValues.zipcode ? <RequiredField /> : undefined;
    const email = validateEmail(formValues.email);
    return {
        firstname,
        lastname,
        address1,
        city,
        zipcode,
        email,
        isValid:
            !address1 && !city && !zipcode && !email && !firstname && !lastname,
    };
}

function validateEmail(email: string | null | undefined) {
    if (!email) {
        return <RequiredField />;
    } else if (!emailValidator.validate(email)) {
        return (
            <Localized
                de="Ungültige E-Mail"
                fr="Ungültige E-Mail"
                it="Ungültige E-Mail"
                en="Ungültige E-Mail"
            />
        );
    } else {
        return null;
    }
}

function RequiredField() {
    return (
        <Localized
            de="Pflichtfeld"
            fr="Pflichtfeld"
            it="Pflichtfeld"
            en="Pflichtfeld"
        />
    );
}

function LanguageOptions(p: {
    settings: SettingsState.State;
    languages: MetaState.Languages.State;
    formValues: AddressState.Address;
    disabled?: boolean;
}) {
    const update = useUpdate();
    return (
        <>
            {p.languages.data.map(lang => (
                <div key={lang.shortcut}>
                    <RadioButton
                        label={lang.name[p.settings.language]}
                        selected={p.formValues.language === lang.shortcut}
                        disabled={p.disabled}
                        onClick={() => {
                            return update(store =>
                                AddressState.Edit.writeAddress(store, {
                                    language: lang.shortcut,
                                }),
                            );
                        }}
                    />
                </div>
            ))}
        </>
    );
}
