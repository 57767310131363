import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as TomState from '../state/TomState.ts';
import * as TomLayoutState from '../state/TomLayoutState.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';

export const cancelOutOfService = (s: Flux.Store): string => {
    TomLayoutState.Layout.stateWrite(s, {
        outOfServiceOpen: false,
        outOfServiceDatePickerOpen: false,
        outOfServiceConfirmPressedOnError: false,
    });
    TomState.OutOfService.Form.reset(s);
    TomState.OutOfService.Response.reset(s);
    return 'tom-outOfService-cancel';
};

export const setOutOfService = AsyncRequest.request(
    Http.OperatorAccount.Toms.setOutOfService,
    (store: Flux.Store, res: Response): string => {
        TomState.OutOfService.Response.setResponse(store, res);
        if (res.statusCode.cls.success) {
            TomLayoutState.Layout.stateWrite(store, {
                outOfServiceOpen: false,
            });
            TomState.Detail.refetchSameContext(store, true);
        }
        return 'tom-outOfService-set';
    },
);
