import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { Maybe, thenElse, getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { css, keyframes } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars.ts';

const width = 100;
const height = width;
const paddingBottom = 24;
const rotate = keyframes`
100% {
    transform: rotate(360deg);
}
`;
const dash = keyframes`
0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
}
50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
}
100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
}
`;

interface SpinnerProps {
    loading?: Maybe<boolean>;
    stateSlices?: Maybe<AbstractLegacyServerState<any>[]>;
}

export function Spinner(props: SpinnerProps): JSX.Element {
    const loadsPending: boolean = thenElse<
        AbstractLegacyServerState<any>[],
        boolean
    >(
        props.stateSlices,
        (slices: AbstractLegacyServerState<any>[]) =>
            slices.some(
                (slice: AbstractLegacyServerState<any>) => slice.pending,
            ),
        false,
    );
    const loading = getOrElse(props.loading, false) || loadsPending;

    return (
        <div
            className={css({
                position: 'absolute',
                width,
                height,
                top: '50%',
                left: '50%',
                marginLeft: -(width / 2),
                marginTop: -height - paddingBottom,
                display: !loading ? 'none' : undefined,
            })}
        >
            <svg
                className={css({
                    animation: `${rotate} 2s linear infinite`,
                    width,
                    height,
                    position: 'relative',
                })}
            >
                <circle
                    className={css({
                        strokeDasharray: '1, 200',
                        strokeDashoffset: 0,
                        stroke: Colors.lightblue,
                        animation: `${dash} 1.5s ease-in-out infinite`,
                        strokeLinecap: 'round',
                    })}
                    cx={50}
                    cy={50}
                    r={20}
                    fill="none"
                    strokeWidth={3}
                    strokeMiterlimit={'10'}
                />
            </svg>
        </div>
    );
}
