import { Box, TextField, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Localized } from '../../common/components/Localized.tsx';
import { ParkingPortalFooter } from '../layout/ParkingPortalFooter.tsx';
import { ParkingPortalLogoHeader } from '../layout/ParkingPortalLogoHeader.tsx';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as AuthenticationState from '../../app/state/AuthenticationState.ts';
import { LoginState } from '../../landing-page/LandingPageState.ts';
import * as AuthenticationActions from '../../app/actions/AuthenticationActions.ts';

function setUsername(store: Flux.Store, username: string) {
    LoginState.stateWrite(store, {
        username: username,
        showCredentialsError: false,
    });
    return 'setUsername';
}

function setPassword(store: Flux.Store, password: string) {
    LoginState.stateWrite(store, {
        password: password,
        showCredentialsError: false,
    });
    return 'setPassword';
}

export function MobileLoginPage() {
    const theme = useTheme();
    const { storeState, update } = useStore(s => ({
        authentication: new AuthenticationState.StateSlice(s).state,
        login: LoginState.get(s),
    }));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <ParkingPortalLogoHeader />
            <Box
                sx={{
                    padding: 3,
                }}
            >
                <TextField
                    label={
                        <Localized
                            de="Benutzername"
                            fr="Nom utilisateur"
                            it="Nome utente"
                            en="Username"
                        />
                    }
                    value={storeState.login.username}
                    onChange={v =>
                        update(store => setUsername(store, v.target.value))
                    }
                    fullWidth={true}
                    margin="normal"
                />
                <TextField
                    label={
                        <Localized
                            de="Passwort"
                            fr="Mot de passe"
                            it="Password"
                            en="Password"
                        />
                    }
                    value={storeState.login.password}
                    onChange={v =>
                        update(store => setPassword(store, v.target.value))
                    }
                    fullWidth={true}
                    type="password"
                    error={storeState.login.showCredentialsError}
                    helperText={
                        storeState.login.showCredentialsError ? (
                            <Localized
                                de="Benutzername oder Passwort sind ungültig."
                                fr="Le nom d'utilisateur ou le mot de passe ne sont valables."
                                it="Nome utente o password non validi."
                                en="Invalid username or password."
                            />
                        ) : undefined
                    }
                    autoComplete="current-password"
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(5),
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                            !storeState.login.username ||
                            !storeState.login.password ||
                            storeState.login.showCredentialsError
                        }
                        loading={storeState.authentication.statusPending}
                        onClick={() =>
                            update(AuthenticationActions.login, {
                                username: storeState.login.username || '',
                                password: storeState.login.password || '',
                                longSession: storeState.login.longSession,
                            })
                        }
                    >
                        <Localized
                            de="Anmelden"
                            fr="Connexion"
                            it="Accesso"
                            en="Login"
                        />
                    </LoadingButton>
                </Box>
            </Box>
            <ParkingPortalFooter />
            <Outlet />
        </Box>
    );
}
