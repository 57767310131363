import { ReactNode } from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

export function ParkingPortalMenuListItem({
    icons,
    text,
    supText,
    onClick,
    hideChevron,
}: {
    icons?: ReactNode[];
    text?: JSX.Element | ReactNode;
    supText?: JSX.Element | string;
    onClick?: (e?: MouseEvent) => void;
    hideChevron?: boolean;
}) {
    return (
        <ParkingPortalListItem onClick={onClick}>
            <Box>
                {supText && (
                    <Typography variant="overline">{supText}</Typography>
                )}
                {text && (
                    <Typography
                        fontWeight="bold"
                        sx={theme => ({
                            textTransform: supText ? undefined : 'uppercase',
                            color: theme.palette.primary.main,
                        })}
                    >
                        {text}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {icons}
                {!hideChevron && onClick && <ParkingPortalListChevron />}
            </Box>
        </ParkingPortalListItem>
    );
}

export function ParkingPortalListChevron({ disabled }: { disabled?: boolean }) {
    return (
        <ChevronRight
            style={{ fontSize: 36 }}
            color={disabled ? 'disabled' : 'primary'}
        />
    );
}

export function ParkingPortalListItem({
    onClick,
    children,
    variableHeight,
    colorVariant = 'white',
}: {
    onClick?: () => void;
    children: ReactNode;
    variableHeight?: boolean;
    colorVariant?: 'white' | 'primary';
}) {
    const borderStyle = (theme: Theme) =>
        `solid 1px ${theme.palette.grey[300]}`;
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: theme.spacing(3),
                height: variableHeight ? undefined : 80,
                minHeight: 80,
                cursor: onClick ? 'pointer' : 'default',
                borderBottom: borderStyle,
                backgroundColor:
                    colorVariant === 'primary'
                        ? theme.palette.primary.light
                        : undefined,
                ['&:hover']: onClick
                    ? {
                          backgroundColor: alpha(
                              theme.palette.primary.main,
                              colorVariant === 'primary' ? 0.25 : 0.05,
                          ),
                      }
                    : undefined,
            })}
            onClick={onClick}
        >
            {children}
        </Box>
    );
}
