import { Translations } from '../../common/i18n/Text.ts';
import { OperatorLoginsListTexts } from '../components/OperatorLoginsList.tsx';
import { LoginMetaTexts } from '../components/LoginMeta.tsx';

export const operatorLoginsListTexts: Translations<OperatorLoginsListTexts> = {
    de: {
        activeUsers: () => 'Benutzer',
        administrators: () => 'Administrator',
        superusers: () => 'Superusers',
        all: () => 'alle',
        softAnonymizeCheckinPublicPermitAfterDaysLabel: () =>
            'Anonymisierung der Parkvorgänge nach (Tagen)',
        inactiveUsers: () => 'Deaktivierte Benutzer',
        none: () => 'keine',
        onlyEnforcement: () => 'Nur Parkingcheck',
        password1: () => 'Neues Passwort',
        password2: () => 'Bestätigung',
        permActive: () => 'Aktiv',
        permActivityStatement: () => 'Kontoauszug',
        permEnforcementLog: () => 'Kontroll-History',
        permParkingMeterAlerts: () => 'Parkuhren - Benachrichtigungen',
        permParkingMeterWrite: () => 'Parkuhren - Ändern',
        permParkingpayModule: () => 'Modul digitale Kanäle',
        permPermitsRead: () => 'Bewilligungen - Anzeigen',
        permPermitsWrite: () => 'Bewilligungen - Erfassen / Ändern',
        permRefundPermits: () => 'Bewilligungen - Zurückzahlen',
        permSyncApp: () => 'Sync Applikation',
        permTaxomexModule: () => 'TOMeco Modul',
        permTwintQrCodePairing: () => 'Zuweisung TWINT QR-Code',
        permUsbStickWrite: () => 'USB Sticks - Ändern',
        permWhitelistCreate: () => 'Freigaben - Erfassen',
        permWhitelistDelete: () => 'Freigaben - Löschen',
        permissions: () => 'Zugriffsrechte',
        permitTypes: () => 'Bewilligungsarten',
        saveEdit: () => 'Änderungen speichern?',
        saveNew: () => 'Neues Login speichern?',
        savePassword: () => 'Passwort ändern',
        some: () => 'mehrere',
        userPermissionEdit: () => 'Logins erfassen / ändern',
        zones: () => 'Zonen',
    },
    fr: {
        activeUsers: () => 'Utilisateurs',
        administrators: () => 'Administrateur',
        superusers: () => 'Superutilisateurs',
        all: () => 'toutes',
        softAnonymizeCheckinPublicPermitAfterDaysLabel: () =>
            'Anonymiser les procédures plus anciennes que (jours)',
        inactiveUsers: () => 'Utilisateurs inactifs',
        none: () => 'aucune',
        onlyEnforcement: () => 'Seulement Parkingcheck',
        password1: () => 'Nouveau mot de passe',
        password2: () => 'Confirmation',
        permActive: () => 'Actif',
        permActivityStatement: () => 'Relevés de compte',
        permEnforcementLog: () => 'Historique des contrôles',
        permParkingMeterAlerts: () => 'Parcmètres - Notifications',
        permParkingMeterWrite: () => 'Parcmètres - Modifier',
        permParkingpayModule: () => 'Module canaux numériques',
        permPermitsRead: () => 'Autorisations - Afficher',
        permPermitsWrite: () => 'Autorisations - Saisir / modifier',
        permRefundPermits: () => 'Autorisations - Rembourser',
        permSyncApp: () => 'Synchroniser application',
        permTaxomexModule: () => 'Module TOMeco',
        permTwintQrCodePairing: () => 'Attribution code QR TWINT',
        permUsbStickWrite: () => 'Sticks USB - Modifier',
        permWhitelistCreate: () => 'Approbations - Saisir',
        permWhitelistDelete: () => 'Approbations - Effacer',
        permissions: () => "Droits d'accès",
        permitTypes: () => "Types d'autorisations",
        saveEdit: () => 'Enregistrer les modifications?',
        saveNew: () => 'Enregistrer le nouveau login?',
        savePassword: () => 'Modifier le mot de passe',
        some: () => 'plusieurs',
        userPermissionEdit: () => 'Saisir / modifier utilisateur',
        zones: () => 'Zones',
    },
    it: {
        activeUsers: () => 'Utenti',
        administrators: () => 'Amministratore',
        superusers: () => 'Superusers',
        all: () => 'tutti',
        softAnonymizeCheckinPublicPermitAfterDaysLabel: () =>
            'Rendere anonime le procedure più vecchie di (giorni)',
        inactiveUsers: () => 'Utenti inattivi',
        none: () => 'nessuna',
        onlyEnforcement: () => 'Solo Parkingcheck',
        password1: () => 'Nuova password',
        password2: () => 'Conferma',
        permActive: () => 'Attivo',
        permActivityStatement: () => 'Estratto conto',
        permEnforcementLog: () => 'Storico controlli',
        permParkingMeterAlerts: () => 'Notifiche parchimetri',
        permParkingMeterWrite: () => 'Modificare parchimetri',
        permParkingpayModule: () => 'Modulo canali digitali',
        permPermitsRead: () => 'Visualizzare autorizzazioni',
        permPermitsWrite: () => 'Registrare / modificare autorizzazioni',
        permRefundPermits: () => 'Rimborsare autorizzazioni',
        permSyncApp: () => 'Applicazione di sincronizzazione',
        permTaxomexModule: () => 'Modulo TOM eco attivo',
        permTwintQrCodePairing: () => 'Assegnare codice QR TWINT',
        permUsbStickWrite: () => 'Modificare stick USB',
        permWhitelistCreate: () => 'Registrare approvazioni',
        permWhitelistDelete: () => 'Cancellare approvazioni',
        permissions: () => "Diritti d'accesso",
        permitTypes: () => "Tipi d'autorizzazione",
        saveEdit: () => 'Salvare le modifiche?',
        saveNew: () => 'Salvare il nuovo login?',
        savePassword: () => 'Modifica password',
        some: () => 'diversi',
        userPermissionEdit: () => 'Registrare / Modificare utente',
        zones: () => 'Zone',
    },
};

export const loginMetaTexts: Translations<LoginMetaTexts> = {
    de: {
        description: () => 'Beschreibung',
        email: () => 'E-Mail',
        firstname: () => 'Vorname',
        lastname: () => 'Nachname',
        password: () => 'Passwort',
        subTitle: () => 'Benutzer',
        username: () => 'Benutzername',
        passwordEmpty: () => 'Passwort darf nicht leer sein',
        telnr: () => 'Telefon',
        duplicateUsername: () => 'Dieser Benutzername ist bereits belegt',
    },
    fr: {
        description: () => 'Description',
        email: () => 'E-mail',
        firstname: () => 'Prénom',
        lastname: () => 'Nom',
        password: () => 'Mot de passe',
        subTitle: () => 'Utilisateur',
        username: () => "Nom d'utilisateur",
        passwordEmpty: () => 'Le mot de passe ne peut pas être vide',
        telnr: () => 'Téléphone',
        duplicateUsername: () => "Ce nom d'utilisateur est déjà utilisé",
    },
    it: {
        description: () => 'Descrizione',
        email: () => 'E-mail',
        firstname: () => 'Nome',
        lastname: () => 'Cognome',
        password: () => 'Password',
        subTitle: () => 'Utente',
        username: () => 'Nome utente',
        passwordEmpty: () => 'La password non può essere vuota',
        telnr: () => 'Telefono',
        duplicateUsername: () => 'Questo nome utente é già in uso',
    },
};
