import { Icon24 } from '../icons/Icon.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { texts } from './i18n/FilterTexts.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';

export const FilterLayout = (p: {
    children?: React.ReactNode;
}): JSX.Element => {
    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            })}
        >
            {p.children}
        </div>
    );
};

export function FilterLayoutHeader(p: {
    filterSummary?: React.ReactNode;
    rightDropdown?: React.ReactNode;
    secondRightDropdown?: React.ReactNode;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                marginTop: '13px',
                marginBottom: '10px',
                height: '32px',
            })}
        >
            {p.filterSummary && (
                <div className={css({ flexGrow: 1, marginLeft: '24px' })}>
                    {p.filterSummary}
                </div>
            )}

            {p.rightDropdown && (
                <div
                    className={css({
                        marginRight: '4px',
                        display: 'flex',
                    })}
                >
                    {p.rightDropdown}
                </div>
            )}

            {p.secondRightDropdown && (
                <div
                    className={css({
                        marginRight: '20px',
                        display: 'flex',
                    })}
                >
                    {p.secondRightDropdown}
                </div>
            )}
        </div>
    );
}

export const FilterLayoutBody = (p: {
    children?: React.ReactNode;
}): JSX.Element => {
    return (
        <div className={css({ flex: 1, position: 'relative' })}>
            {p.children}
        </div>
    );
};

interface ResultsErrorProps {
    title: string;
    description: string;
}

const ResultsError = (p: ResultsErrorProps) => (
    <div
        className={css({
            textAlign: 'center',
            height: '80px',
            position: 'absolute',
            left: '0',
            right: '0',
            top: '50%',
            marginTop: '-40px',
        })}
    >
        <div className={css({ color: ColorHex.error })}>
            <Icon24 icon={Icons24.error} />
        </div>
        <div
            className={css({
                ...OperatorTypo.headingTwo,
                color: ColorHex.error,
            })}
        >
            {p.title}
        </div>
        <div
            className={css({
                ...OperatorTypo.headingOne,
                color: ColorHex.typo,
            })}
        >
            {p.description}
        </div>
    </div>
);

interface HasLanguage {
    lang: string;
}

export const TooManyRows = (p: HasLanguage) => (
    <ResultsError
        title={texts[p.lang].tooManyRowsTitle()}
        description={texts[p.lang].tooManyRowsDescription()}
    />
);

export const NoRows = (p: HasLanguage) => (
    <ResultsError
        title={texts[p.lang].noRowsTitle()}
        description={texts[p.lang].noRowsDescription()}
    />
);
