import { useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { Add, FilterAlt, Remove } from '@mui/icons-material';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import { Localized, useLocalized } from '../common/components/Localized.tsx';
import {
    FilterConfig,
    FilterDateField,
    FilterDateRangeField,
    FilterField,
    FilterPlzField,
    FilterSelectionField,
    FilterZoneField,
} from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { Dropdown } from 'dg-web-shared/common/components/material-ui/Dropdown.tsx';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { BackendFilterType } from '../shared-mui-components/filter/OperatorFilterBackendConfiguration.tsx';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { css } from '@emotion/css';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';

export function OperatorParkingaboUsersFilters({
    activeFilters,
    filterConfig,
    onFiltersStateChange,
    pending,
}: {
    activeFilters: FilterConfig;
    filterConfig: FilterConfig;
    onFiltersStateChange: (activeFilters: FilterConfig) => void;
    pending: boolean;
}) {
    const theme = useTheme();
    const [filterListOpen, setFilterListOpen] = useState(false);
    const language = useOperatorLanguage();
    const addableFilters = getAddableFilters(filterConfig, activeFilters);
    const [initiallyOpen, setInitiallyOpen] = useState(false);

    if (pending)
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.SelectableList}
            />
        );

    return (
        <Box>
            <Box style={{ display: 'inline-block', position: 'relative' }}>
                <IconButton
                    disabled={addableFilters.length <= 0}
                    onClick={() => setFilterListOpen(!filterListOpen)}
                    color="primary"
                    sx={{
                        marginRight: theme.spacing(1),
                        padding: theme.spacing(0.75),
                        '&.Mui-disabled': {
                            color: theme.palette.blue.medium,
                        },
                        fontSize: 32,
                    }}
                    size="large"
                >
                    <FilterAlt />
                </IconButton>
                <Dropdown
                    sx={{ minWidth: 200 }}
                    open={filterListOpen}
                    onClose={() => setFilterListOpen(false)}
                >
                    <DropdownHeader
                        title={
                            <Localized
                                de="Filterkriterien"
                                fr="Critères de filtrage"
                                it="Criteri filtro"
                                en="Filter Criteria"
                            />
                        }
                    />
                    {addableFilters.map((filterName: string) => {
                        const toggle = () => {
                            onFiltersStateChange(
                                toggleFilterReducer(
                                    activeFilters,
                                    filterConfig,
                                    filterName,
                                ),
                            );
                            setFilterListOpen(false);
                            setInitiallyOpen(true);
                        };
                        return (
                            <Chip
                                key={filterName}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: theme.spacing(2),
                                }}
                                sx={{
                                    ':hover': {
                                        color: theme.palette.common.white,
                                    },
                                }}
                                label={
                                    filterConfig[filterName].displayName[
                                        language
                                    ]
                                }
                                clickable
                                deleteIcon={
                                    <Add style={{ color: 'inherit' }} />
                                }
                                onDelete={toggle}
                                onClick={toggle}
                            />
                        );
                    })}
                </Dropdown>
            </Box>
            <Box style={{ display: 'inline-flex' }}>
                {Object.keys(activeFilters).map((filterName: string) => (
                    <FilterDropdown
                        key={filterName}
                        filterConfig={filterConfig}
                        filterName={filterName}
                        activeFilters={activeFilters}
                        onFiltersStateChange={onFiltersStateChange}
                        initiallyOpen={initiallyOpen}
                    />
                ))}
            </Box>
        </Box>
    );
}

function getAddableFilters(
    filterConfig: FilterConfig,
    activeFilters: FilterConfig,
) {
    const addableFilters = Object.keys(filterConfig).filter(
        filterName => !Object.keys(activeFilters).includes(filterName),
    );

    return addableFilters.filter(filterName => {
        const filterField = filterConfig[filterName];
        switch (filterField.type) {
            case BackendFilterType.SELECTION:
            case BackendFilterType.DATE:
            case BackendFilterType.DATE_RANGE:
                return true;
            case BackendFilterType.ZONE:
                return (
                    filterField.filters.reduce(
                        (acc, curr) => acc + Object.keys(curr.values).length,
                        0,
                    ) > 0
                );
        }
    });
}

function getLabel(
    filterTitle: Message,
    activeFilterField: FilterField,
): JSX.Element {
    switch (activeFilterField.type) {
        case BackendFilterType.SELECTION:
            return (
                <>
                    <Localized {...filterTitle} />{' '}
                    {fieldHasValues(activeFilterField)
                        ? Object.keys(activeFilterField.values).length
                        : ''}
                </>
            );
        case BackendFilterType.DATE:
        case BackendFilterType.DATE_RANGE:
            return <Localized {...filterTitle} />;
        case BackendFilterType.ZONE:
            return (
                <>
                    <Localized {...filterTitle} />{' '}
                    {fieldHasValues(activeFilterField)
                        ? activeFilterField.filters.reduce((acc, curr) => {
                              return acc + Object.keys(curr.values).length;
                          }, 0)
                        : ''}
                </>
            );
    }
}

function fieldHasValues(activeFilterField: FilterField) {
    switch (activeFilterField.type) {
        case BackendFilterType.SELECTION:
            return Object.keys(activeFilterField.values).length > 0;
        case BackendFilterType.DATE:
            return activeFilterField.value?.toISODate() != null;
        case BackendFilterType.DATE_RANGE:
            return (
                activeFilterField.fromValue?.toISODate() != null &&
                activeFilterField.toValue?.toISODate() != null
            );
        case BackendFilterType.ZONE:
            return (
                activeFilterField.filters.reduce(
                    (acc, curr) => acc + Object.keys(curr.values).length,
                    0,
                ) > 0
            );
    }
}

function FilterDropdown({
    filterName,
    activeFilters,
    filterConfig,
    onFiltersStateChange,
    initiallyOpen,
}: {
    filterName: string;
    activeFilters: FilterConfig;
    filterConfig: FilterConfig;
    onFiltersStateChange: (activeFilters: FilterConfig) => void;
    initiallyOpen: boolean;
}) {
    const [open, setOpen] = useState(initiallyOpen);
    return (
        <Box style={{ position: 'relative' }}>
            <Chip
                sx={theme => ({
                    marginLeft: 1,
                    marginRight: 1,
                    color: fieldHasValues(activeFilters[filterName])
                        ? theme.palette.common.white
                        : undefined,
                    backgroundColor: fieldHasValues(activeFilters[filterName])
                        ? theme.palette.blue.main
                        : theme.palette.blue.medium,
                    ':hover': {
                        backgroundColor: theme.palette.blue.electric,
                    },
                    '& .MuiChip-deleteIcon': {
                        '&:hover': {
                            backgroundColor: theme.palette.blue.main,
                            borderRadius: '10px',
                        },
                    },
                })}
                key={filterName}
                clickable
                label={getLabel(
                    filterConfig[filterName].displayName,
                    activeFilters[filterName],
                )}
                onClick={() => setOpen(!open)}
                onDelete={() =>
                    onFiltersStateChange(
                        toggleFilterReducer(
                            activeFilters,
                            filterConfig,
                            filterName,
                        ),
                    )
                }
                deleteIcon={<Remove style={{ color: 'inherit' }} />}
            />
            <Dropdown
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    position: 'fixed',
                    minWidth: 180,
                    maxWidth: 450,
                    marginLeft: 1,
                    maxHeight: '70%',
                    overflowY: 'auto',
                }}
            >
                <DropdownHeader
                    title={
                        <Localized {...filterConfig[filterName].displayName} />
                    }
                />
                <FormControl component="fieldset" fullWidth={true}>
                    <FilterDropdownContent
                        key={filterName}
                        filterConfig={filterConfig}
                        filterName={filterName}
                        activeFilters={activeFilters}
                        onFiltersStateChange={onFiltersStateChange}
                    />
                </FormControl>
            </Dropdown>
        </Box>
    );
}

function FilterDropdownContent({
    filterName,
    activeFilters,
    filterConfig,
    onFiltersStateChange,
}: {
    filterName: string;
    activeFilters: FilterConfig;
    filterConfig: FilterConfig;
    onFiltersStateChange: (activeFilters: FilterConfig) => void;
}) {
    const activeFilterField = activeFilters[filterName];

    function toggleValue(filterName: string, valueName: string) {
        onFiltersStateChange(
            toggleFilterValueReducer(
                activeFilters,
                filterConfig,
                filterName,
                valueName,
            ),
        );
    }

    switch (activeFilterField.type) {
        case BackendFilterType.SELECTION:
            return (
                <FilterSelectionDropdownContent
                    key={filterName}
                    filterName={filterName}
                    filterConfig={filterConfig}
                    activeFilterField={activeFilterField}
                    onToggleValue={(valueName: string) =>
                        toggleValue(filterName, valueName)
                    }
                />
            );
        case BackendFilterType.DATE:
            return (
                <FilterDatePickerDropdownContent
                    key={filterName}
                    activeFilterField={activeFilterField}
                    onToggleValue={(valueName: string) =>
                        toggleValue(filterName, valueName)
                    }
                    onValueChange={(
                        activeFilterDateField: FilterDateField,
                        newValue: DateTime,
                    ) =>
                        onFiltersStateChange(
                            replaceDateFilterValue(
                                activeFilters,
                                activeFilterDateField,
                                filterName,
                                newValue,
                            ),
                        )
                    }
                />
            );
        case BackendFilterType.DATE_RANGE:
            return (
                <FilterDateRangePickerDropdownContent
                    key={filterName}
                    activeFilterField={activeFilterField}
                    updateFilter={(
                        fromValue: DateTime | null,
                        toValue: DateTime | null,
                    ) => {
                        const newFilterField = { ...activeFilterField };
                        const newConfig = { ...activeFilters };
                        newFilterField.fromValue = fromValue;
                        newFilterField.toValue = toValue;
                        newConfig[filterName] = newFilterField;
                        onFiltersStateChange(newConfig);
                    }}
                />
            );
        case BackendFilterType.ZONE:
            return (
                <FilterZoneDropdownContent
                    key={filterName}
                    filterName={filterName}
                    filterConfig={filterConfig}
                    activeFilterField={activeFilterField}
                    onOverrideWithGroup={() => {
                        /* not implemented yet */
                    }}
                    onToggleValue={(valueName: string) =>
                        toggleValue(filterName, valueName)
                    }
                />
            );
    }
}

const SELECTION_FILTER_VALUE_NONE = 'SELECT_NONE';

function FilterSelectionDropdownContent({
    filterConfig,
    filterName,
    activeFilterField,
    onToggleValue,
}: {
    filterConfig: FilterConfig;
    filterName: string;
    activeFilterField: FilterSelectionField;
    onToggleValue: (valueName: string) => void;
}) {
    const theme = useTheme();
    const filterField = filterConfig[filterName];

    if (
        filterField.type === BackendFilterType.DATE ||
        filterField.type === BackendFilterType.DATE_RANGE ||
        filterField.type === BackendFilterType.ZONE
    ) {
        throw new Error('Filter field cannot be date or date_range');
    }

    const sortValues = (a: string, b: string): number => {
        const displayA = useLocalized(filterField.values[a].displayName);
        const displayB = useLocalized(filterField.values[b].displayName);
        if (displayA < displayB) {
            return -1;
        } else if (displayA > displayB) {
            return 1;
        } else {
            return 0;
        }
    };

    return (
        <FormGroup>
            {Object.keys(filterField.values)
                .sort(sortValues)
                .map(valueName => (
                    <FormControlLabel
                        sx={{
                            margin: 0,
                            marginLeft: theme.spacing(-1),
                        }}
                        key={valueName}
                        control={
                            <Checkbox
                                checked={Object.keys(
                                    activeFilterField.values,
                                ).includes(valueName)}
                                onChange={() => onToggleValue(valueName)}
                                name={valueName}
                            />
                        }
                        label={
                            <Box
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: theme.palette.blue.main,
                                }}
                            >
                                {valueName === SELECTION_FILTER_VALUE_NONE ? (
                                    <i>
                                        <Localized
                                            {...filterField.values[valueName]
                                                .displayName}
                                        />
                                    </i>
                                ) : (
                                    <Localized
                                        {...filterField.values[valueName]
                                            .displayName}
                                    />
                                )}
                            </Box>
                        }
                    />
                ))}
        </FormGroup>
    );
}

function FilterDatePickerDropdownContent({
    activeFilterField,
    onValueChange,
    onToggleValue,
}: {
    activeFilterField: FilterDateField;
    onToggleValue: (valueName: string) => void;
    onValueChange: (
        activeFilterDateField: FilterDateField,
        newValue: DateTime,
    ) => void;
}) {
    const [date, setDate] = useState<DateTime>(
        activeFilterField.value?.toISODate()
            ? activeFilterField.value
            : DateTime.now(),
    );

    if (!activeFilterField.value?.toISODate()) {
        onToggleValue(date.toISODate());
    }

    const handleDateChange = (newDate: DateTime | null) => {
        if (newDate) {
            onValueChange(activeFilterField, newDate);
            setDate(newDate);
        }
    };

    return (
        <FormGroup>
            <DesktopDatePicker
                format="dd.MM.yyyy"
                value={date}
                disableFuture={activeFilterField.disableSelectionInFuture}
                onChange={handleDateChange}
                slotProps={{ textField: { size: 'medium' } }}
            />
        </FormGroup>
    );
}

function FilterDateRangePickerDropdownContent({
    activeFilterField,
    updateFilter,
}: {
    activeFilterField: FilterDateRangeField;
    updateFilter: (
        fromValue: DateTime | null,
        toValue: DateTime | null,
    ) => void;
}) {
    useEffect(() => {
        if (!activeFilterField.fromValue && !activeFilterField.toValue) {
            const now = DateTime.now();
            updateFilter(now, now);
        }
    }, []);

    return (
        <Stack spacing={2}>
            <DesktopDatePicker
                label={
                    <Localized de="ab" fr="à partir du" it="dal" en="from" />
                }
                format="dd.MM.yyyy"
                value={activeFilterField.fromValue}
                disableFuture={activeFilterField.disableSelectionInFuture}
                onChange={date => {
                    const overwriteToValueToNull =
                        !!activeFilterField.toValue &&
                        !!date &&
                        date > activeFilterField.toValue;
                    updateFilter(
                        date,
                        overwriteToValueToNull
                            ? null
                            : activeFilterField.toValue,
                    );
                }}
                slotProps={{ textField: { size: 'medium' } }}
            />
            <DesktopDatePicker
                label={<Localized de="bis" fr="jusqu'à" it="fino a" en="to" />}
                format="dd.MM.yyyy"
                value={activeFilterField.toValue}
                disableFuture={activeFilterField.disableSelectionInFuture}
                onChange={date =>
                    updateFilter(activeFilterField.fromValue, date)
                }
                slotProps={{ textField: { size: 'medium' } }}
                minDate={
                    activeFilterField.fromValue != null
                        ? activeFilterField.fromValue
                        : undefined
                }
            />
        </Stack>
    );
}

function FilterZoneDropdownContent({
    filterConfig,
    filterName,
    activeFilterField,
    onOverrideWithGroup,
    onToggleValue,
}: {
    filterConfig: FilterConfig;
    filterName: string;
    activeFilterField: FilterZoneField;
    onOverrideWithGroup: (valueNames: string[]) => void;
    onToggleValue: (valueName: string) => void;
}) {
    const filterField = filterConfig[filterName];
    const language = useOperatorLanguage();

    if (
        filterField.type === BackendFilterType.DATE ||
        filterField.type === BackendFilterType.DATE_RANGE ||
        filterField.type === BackendFilterType.SELECTION
    ) {
        throw new Error('Filter field cannot be date or date_range');
    }

    return (
        <FormGroup>
            {filterField.filters.map(plzConfig => (
                <Box key={plzConfig.displayName.en} sx={{ width: '100%' }}>
                    <Typography
                        sx={{
                            marginTop: 1,
                            fontWeight: 600,
                            fontSize: 16,
                            color: theme => theme.palette.primary.main,
                        }}
                        onClick={() =>
                            onOverrideWithGroup(Object.keys(plzConfig.values))
                        }
                    >
                        <Localized {...plzConfig.displayName} />
                    </Typography>
                    <Stack>
                        {Object.keys(plzConfig.values)
                            .sort((first, second) =>
                                plzConfig.values[first].displayName[
                                    language
                                ].localeCompare(
                                    plzConfig.values[second].displayName[
                                        language
                                    ],
                                    language,
                                    { numeric: true },
                                ),
                            )
                            .map(valueName => (
                                <FormControlLabel
                                    sx={{
                                        margin: 0,
                                        marginLeft: theme => theme.spacing(-1),
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                    key={valueName}
                                    control={
                                        <Checkbox
                                            checked={
                                                activeFilterField.filters.filter(
                                                    filter =>
                                                        Object.keys(
                                                            filter.values,
                                                        ).includes(valueName),
                                                ).length > 0
                                            }
                                            onChange={() =>
                                                onToggleValue(valueName)
                                            }
                                            name={valueName}
                                        />
                                    }
                                    classes={{
                                        label: css({
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        }),
                                    }}
                                    label={
                                        <Box
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                color: theme =>
                                                    theme.palette.blue.main,
                                            }}
                                        >
                                            <Localized
                                                {...plzConfig.values[valueName]
                                                    .displayName}
                                            />
                                        </Box>
                                    }
                                />
                            ))}
                    </Stack>
                </Box>
            ))}
        </FormGroup>
    );
}

function DropdownHeader({ title }: { title: React.ReactNode }) {
    return (
        <Box sx={{ marginBottom: 2, width: '100%' }}>
            <Typography variant="h5">{title}</Typography>
        </Box>
    );
}

function getFilterValue(
    filterConfig: FilterConfig,
    filterName: string,
    valueName: string,
) {
    const filterField: FilterField = { ...filterConfig[filterName] };
    switch (filterField.type) {
        case BackendFilterType.SELECTION:
            return filterField.values[valueName];
        case BackendFilterType.DATE:
        case BackendFilterType.DATE_RANGE:
            throw new Error(
                'Tried to get filter value object from FilterDateField',
            );
        case BackendFilterType.ZONE: {
            const filterValue = filterField.filters.reduce(
                (value, filter) => filter.values[valueName] ?? value,
                undefined as { displayName: Message } | undefined,
            );
            if (!filterValue) {
                throw new Error(
                    'could not rebuild filter value from filter config',
                );
            }
            return filterValue;
        }
    }
}

function toggleFilterValueReducer(
    activeFilters: FilterConfig,
    filterConfig: FilterConfig,
    filterName: string,
    valueName: string,
): FilterConfig {
    const newFilterField: FilterField = { ...activeFilters[filterName] };
    switch (newFilterField.type) {
        case BackendFilterType.SELECTION: {
            const newValues = { ...newFilterField.values };
            const index = Object.keys(newValues).indexOf(valueName);
            if (index > -1) {
                delete newValues[valueName];
            } else {
                newValues[valueName] = getFilterValue(
                    filterConfig,
                    filterName,
                    valueName,
                );
            }
            newFilterField.values = newValues;
            break;
        }
        case BackendFilterType.DATE:
            newFilterField.value = DateTime.fromISO(valueName);
            break;
        case BackendFilterType.DATE_RANGE:
            throw new Error(
                'Tried to toggle filters value of date range filter',
            );
        case BackendFilterType.ZONE:
            newFilterField.filters = newFilterField.filters.map(filter => {
                const newVal = { ...filter.values };
                if (Object.keys(newVal).indexOf(valueName) > -1) {
                    delete newVal[valueName];
                } else if (
                    valueExistsInConfig(
                        filterConfig,
                        filter,
                        filterName,
                        valueName,
                    )
                ) {
                    newVal[valueName] = getFilterValue(
                        filterConfig,
                        filterName,
                        valueName,
                    );
                }
                return {
                    displayName: filter.displayName,
                    values: newVal,
                };
            });
    }

    const newConfig = { ...activeFilters };
    newConfig[filterName] = newFilterField;
    return newConfig;
}

function valueExistsInConfig(
    filterConfig: FilterConfig,
    currentFilter: FilterPlzField,
    filterName: string,
    valueName: string,
): boolean {
    const filterConfigField = { ...filterConfig[filterName] };
    if (filterConfigField.type === BackendFilterType.ZONE) {
        const plzFilterConfigField = filterConfigField.filters.filter(
            configFilter =>
                configFilter.displayName.en === currentFilter.displayName.en,
        );
        const valueFilter = plzFilterConfigField.filter(
            plzFilter => !!plzFilter.values[valueName],
        );
        if (valueFilter.length > 0) {
            return true;
        }
    }
    return false;
}

function replaceDateFilterValue(
    activeFilters: FilterConfig,
    activeFilterDateField: FilterDateField,
    filterName: string,
    newValue: DateTime,
): FilterConfig {
    const newFilterDateField = { ...activeFilterDateField };
    const newConfig = { ...activeFilters };

    newFilterDateField.value = newValue;
    newConfig[filterName] = newFilterDateField;

    return newConfig;
}

function getEmptyFilter(filterConfig: FilterConfig, filterName: string) {
    const filterField: FilterField = { ...filterConfig[filterName] };
    switch (filterField.type) {
        case BackendFilterType.SELECTION:
            filterField.values = { ...filterField.values };
            filterField.values = {};
            break;
        case BackendFilterType.DATE:
            filterField.value = null;
            break;
        case BackendFilterType.ZONE:
            filterField.filters = filterField.filters.map(filter => ({
                displayName: filter.displayName,
                values: {},
            }));
    }
    return filterField;
}

export function toggleFilterReducer(
    activeFilters: FilterConfig,
    filterConfig: FilterConfig,
    filterName: string,
): FilterConfig {
    const newConfig = { ...activeFilters };
    if (newConfig[filterName]) {
        delete newConfig[filterName];
    } else {
        newConfig[filterName] = getEmptyFilter(filterConfig, filterName);
    }
    return newConfig;
}
