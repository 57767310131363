import { TextColumnContent, TextLeftColumn } from '../../../ui/layout/Text.tsx';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { collectionsGuideTexts } from '../../i18n/CollectionsTexts.ts';

interface GuideProps {
    login: CurrentOperatorLoginState.State;
    language: string;
}

export interface CollectionGuideTexts {
    Header: Translation;
    Intro: Translation;
    ListDescription: Translation;
    ValidDate: Translation;
    ParkingMeters: Translation;
    UsbSticks: Translation;
}

export const Guide = (p: GuideProps) => {
    const login = p.login.data;
    const texts = collectionsGuideTexts[p.language];
    if (isDefined(login)) {
        return (
            <TextColumnContent>
                <TextLeftColumn>
                    <h1>{texts.Header()}</h1>
                    <h3>{texts.Intro()}</h3>
                    <h3>{texts.ListDescription()}</h3>
                    <ul>
                        <li>{texts.ValidDate()}</li>
                        <li>{texts.ParkingMeters()}</li>
                        <li>{texts.UsbSticks()}</li>
                    </ul>
                </TextLeftColumn>
            </TextColumnContent>
        );
    } else {
        return <noscript />;
    }
};
