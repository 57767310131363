import { NavbarDropdownTextHeader } from './NavbarDropdown.tsx';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { css } from '@emotion/css';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';
import { Params, useMatches } from 'react-router-dom';
import { Icon16, Icon24 } from '../../ui/icons/Icon.tsx';
import { chevronBreadcrumb } from 'dg-web-shared/ui/icons/Icons16.tsx';
import { menu } from 'dg-web-shared/ui/icons/Icons24.tsx';
import { OffstreetTransactionsTitle } from '../../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';

import parkingPortalLogo from '../../../assets/parkingportal_logo_white.svg';

const MenuIcon = () => <Icon24 icon={menu} />;

const ParkingPortalLogo = () => (
    <img
        src={parkingPortalLogo}
        className={css({
            width: 136,
            height: 48,
        })}
    />
);

function Chevron() {
    return (
        <div
            className={css({
                height: 24,
                width: 24,
                textAlign: 'center',
                paddingTop: 4,
                lineHeight: '20px',
            })}
        >
            <Icon16 icon={chevronBreadcrumb} />
        </div>
    );
}

type MatchWithTitle = ReturnType<typeof useMatches>[0] & {
    handle: {
        title: JSX.Element;
    };
};

function isTitleMatch(
    match: ReturnType<typeof useMatches>[0],
): match is MatchWithTitle {
    return (
        Boolean(match.handle) && Boolean((match as MatchWithTitle).handle.title)
    );
}

export function isMobileRoute() {
    return useMatches().some(match =>
        Boolean(
            match.handle &&
                (
                    match.handle as {
                        isMobile: boolean;
                    }
                ).isMobile,
        ),
    );
}

export type MatchWithRouteName = ReturnType<typeof useMatches>[0] & {
    handle: {
        routeName: OperatorAppRoutes;
    };
};

export function isRouteNameMatch(
    match: ReturnType<typeof useMatches>[0],
): match is MatchWithRouteName {
    return (
        Boolean(match.handle) &&
        Boolean((match as MatchWithRouteName).handle.routeName)
    );
}

export function BreadCrumb() {
    const { authentication } = useOperatorContext();
    const { operatorData } = useOperatorContext();
    const crumbsWithNavText = useMatches().filter(crumb =>
        hasNavigationText(crumb, operatorData),
    );

    return (
        <div className={css({ display: 'flex' })}>
            <div
                className={css({
                    padding: 12,
                    height: 48,
                    display: 'flex',
                })}
            >
                <MenuIcon />
            </div>
            <ParkingPortalLogo />
            {authentication.authorized && (
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        height: 48,
                    })}
                >
                    {crumbsWithNavText.map((crumb, index) => (
                        <NavbarDropdownText
                            key={crumb.id}
                            crumb={crumb}
                            operatorData={operatorData}
                            lastElement={index < crumbsWithNavText.length - 1}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

function NavbarDropdownText({
    crumb,
    operatorData,
    lastElement,
}: {
    crumb: {
        id: string;
        pathname: string;
        params: Params;
        data: unknown;
        handle: unknown;
    };
    operatorData: OperatorDataState.OperatorData;
    lastElement: boolean;
}) {
    if (isTitleMatch(crumb)) {
        return (
            <>
                <NavbarDropdownTextHeader>
                    {crumb.handle.title}
                </NavbarDropdownTextHeader>
                {lastElement && <Chevron />}
            </>
        );
    }

    if (isRouteNameMatch(crumb)) {
        return (
            <>
                <NavbarDropdownTextHeader>
                    {getNavigationText(crumb.handle.routeName, operatorData)}
                </NavbarDropdownTextHeader>
                {lastElement && <Chevron />}
            </>
        );
    }

    return null;
}

function hasNavigationText(
    crumb: {
        id: string;
        pathname: string;
        params: Params;
        data: unknown;
        handle: unknown;
    },
    operatorData: OperatorDataState.OperatorData,
): boolean {
    if (isTitleMatch(crumb)) {
        return true;
    }

    if (isRouteNameMatch(crumb)) {
        return getNavigationText(crumb.handle.routeName, operatorData) !== null;
    }

    return false;
}

export function getNavigationText(
    route: OperatorAppRoutes,
    operatorData?: OperatorDataState.OperatorData,
): JSX.Element | null {
    switch (route) {
        case OperatorAppRoutes.Home:
            return (
                <Localized
                    de="Betreiberkonto"
                    fr="Compte exploitant"
                    it="Conto gestore"
                    en="Operator account"
                />
            );
        case OperatorAppRoutes.Zones:
            return <Localized de="Zonen" fr="Zones" it="Zone" en="Zones" />;
        case OperatorAppRoutes.Parkings:
            return (
                <Localized
                    de="Parkings"
                    fr="Parkings"
                    it="Parcheggi"
                    en="Parkings"
                />
            );
        case OperatorAppRoutes.CloudConnectorPeripheries:
            return (
                <Localized
                    de="Geräte"
                    fr="Appareils"
                    it="Apparecchi"
                    en="Devices"
                />
            );
        case OperatorAppRoutes.PayStationStatement:
            return (
                <Localized
                    de="Kassen-Abrechnung"
                    fr="Comptabilité de trésorerie"
                    it="Contabilità di cassa"
                    en="Paystation statement"
                />
            );
        case OperatorAppRoutes.PayStationCollections:
            return (
                <Localized
                    de="Kassen-Leerungen"
                    fr="Vidange de caisse"
                    it="Svuotamento cassa"
                    en="Paystation emptying"
                />
            );
        case OperatorAppRoutes.PeripheryHistory:
            return (
                <Localized
                    de="Geräte-History"
                    fr="Historique des appareils"
                    it="Storico apparecchi"
                    en="Devices History"
                />
            );
        case OperatorAppRoutes.ClearancesPermitsList:
            return operatorData?.licensePlatePermitSettings.whitelistActive ? (
                <Localized
                    de="Bewilligungen & Freigaben"
                    fr="Autorisations & approbations"
                    it="Autorizzazioni & approvazioni"
                    en="Authorizations & Approvals"
                />
            ) : (
                <Localized
                    de="Bewilligungen"
                    fr="Autorisations"
                    it="Autorizzazioni"
                    en="Authorizations"
                />
            );
        case OperatorAppRoutes.ClearanceRequests:
            return (
                <Localized
                    de="Anträge"
                    fr="Demandes"
                    it="Richieste"
                    en="Requests"
                />
            );
        case OperatorAppRoutes.Logins:
            return (
                <Localized
                    de="Zugriffsrechte"
                    fr="Droits d'accès"
                    it="Diritti d'accesso"
                    en="Access Rights"
                />
            );
        case OperatorAppRoutes.ActivityStatement:
            return (
                <Localized
                    de="Kontoauszug"
                    fr="Relevés de compte"
                    it="Estratto conto"
                    en="Account Balance"
                />
            );
        case OperatorAppRoutes.CustomerAccounts:
            return (
                <Localized
                    de="Benutzerkonti"
                    fr="Comptes utilisateur"
                    it="Conti utente"
                    en="Customer Accounts"
                />
            );
        case OperatorAppRoutes.SpecialReports:
            return (
                <Localized
                    de="Spezialreporte"
                    fr="Rapports spéciaux"
                    it="Liste personalizzate"
                    en="Special Reports"
                />
            );
        case OperatorAppRoutes.PermitTypes:
            return (
                <Localized
                    de="Bewilligungsarten"
                    fr="Types d'autorisations"
                    it="Tipi d'autorizzazione"
                    en="Permit Types"
                />
            );
        case OperatorAppRoutes.TomTransactions:
            return (
                <Localized
                    de="TOMeco-Transaktionen"
                    fr="Transactions TOMeco"
                    it="Transazioni TOMeco"
                    en="TOMeco transactions"
                />
            );
        case OperatorAppRoutes.ParkingMeters:
            return (
                <Localized
                    de="TOMeco-Parkuhren"
                    fr="Horodateurs TOMeco"
                    it="Parchimetri TOMeco"
                    en="TOMeco parking meters"
                />
            );
        case OperatorAppRoutes.Tariffs:
            return (
                <Localized de="Tarife" fr="Tarifs" it="Tariffe" en="Tariffs" />
            );
        case OperatorAppRoutes.UsbSticks:
            return (
                <Localized
                    de="USB Sticks"
                    fr="Clés USB"
                    it="Chiavette USB"
                    en="USB Sticks"
                />
            );
        case OperatorAppRoutes.PermissionTags:
            return (
                <Localized
                    de="Berechtigungen"
                    fr="Permissions"
                    it="Diritti"
                    en="Permissions"
                />
            );
        case OperatorAppRoutes.ControlHistory:
            return (
                <Localized
                    de="Kontroll-History"
                    fr="Historique des contrôles"
                    it="Storico controlli"
                    en="Enforcement History"
                />
            );
        case OperatorAppRoutes.Parkmonitor:
            return (
                <Localized
                    de="Parkmonitor"
                    fr="Parkmonitor"
                    it="Parkmonitor"
                    en="Parkmonitor"
                />
            );
        case OperatorAppRoutes.MiOffice:
            return (
                <Localized
                    de="MiOffice"
                    fr="MiOffice"
                    it="MiOffice"
                    en="MiOffice"
                />
            );
        case OperatorAppRoutes.ParkingaboUsers:
            return (
                <Localized
                    de="Benutzer"
                    fr="Utilisateurs"
                    it="Utenti"
                    en="Users"
                />
            );
        case OperatorAppRoutes.ParkingaboProducts:
            return (
                <Localized
                    de="Produkte"
                    fr="Produits"
                    it="Prodotti"
                    en="Products"
                />
            );
        case OperatorAppRoutes.ParkingaboProductTypes:
            return (
                <Localized
                    de="Produkttypen"
                    fr="Types de produits"
                    it="Tipi di prodotto"
                    en="Product types"
                />
            );
        case OperatorAppRoutes.ParkingaboUserCategories:
            return (
                <Localized
                    de="Benutzerkategorien"
                    fr="Catégories d'utilisateurs"
                    it="Categorie utenti"
                    en="User categories"
                />
            );
        case OperatorAppRoutes.Statistics:
            return (
                <Localized
                    de="Statistiken"
                    fr="Statistiques"
                    it="Statistiche"
                    en="Statistics"
                />
            );
        case OperatorAppRoutes.OnstreetTransactions:
            return (
                <Localized
                    de="Parkvorgänge"
                    fr="Procédures"
                    it="Procedure"
                    en="Procedures"
                />
            );
        case OperatorAppRoutes.OffstreetTransactions:
            return operatorData?.existingOffstreetTransactionsType ? (
                <OffstreetTransactionsTitle
                    offstreetTransactionsType={
                        operatorData.existingOffstreetTransactionsType
                    }
                />
            ) : null;
        case OperatorAppRoutes.OffstreetTransactionsTicketDetail:
        case OperatorAppRoutes.OffstreetTransactionsEntryWithoutExitDetail:
        case OperatorAppRoutes.OffstreetTransactionsContractDetail:
        case OperatorAppRoutes.ParkingaboProductsDetail:
        case OperatorAppRoutes.ParkingaboUsersAdd:
        case OperatorAppRoutes.ParkingaboUsersInvite:
        case OperatorAppRoutes.ParkingaboUsersDetail:
        case OperatorAppRoutes.ParkingaboUsersProducts:
        case OperatorAppRoutes.ParkingaboUsersProductsAdd:
        case OperatorAppRoutes.ParkingaboUsersProductsDetail:
        case OperatorAppRoutes.ParkingaboUsersVehicles:
        case OperatorAppRoutes.ParkingaboUsersVehiclesAdd:
        case OperatorAppRoutes.ParkingaboUsersVehiclesDetail:
        case OperatorAppRoutes.ParkingaboUsersAccountStatement:
        case OperatorAppRoutes.ParkingaboProductTypesDetail:
        case OperatorAppRoutes.ParkingaboQuotasDetail:
        case OperatorAppRoutes.MobileHomePage:
        case OperatorAppRoutes.MobileCloudConnectorPeripheries:
        case OperatorAppRoutes.MobileCloudConnectorSettings:
        case OperatorAppRoutes.CloudConnectorPeripheriesDetail:
        case OperatorAppRoutes.CloudConnectorRecode:
        case OperatorAppRoutes.CloudConnectorRecodeTicket:
        case OperatorAppRoutes.CloudConnectorRecodeTicketCondition:
        case OperatorAppRoutes.CloudConnectorRecodeError:
            return null;
    }
}
