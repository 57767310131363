import { TokenNames } from '../Coins';
import { Translations } from '../../lib/Text';

export const tokenNameTexts: Translations<TokenNames> = {
    de: {
        TK001A: () => 'Silberjeton 28mm',
        TK002A: () => 'Messingjeton 22mm, dünn',
        TK003A: () => 'Messingjeton 22mm, dick',
    },
    en: {
        TK001A: () => 'Token silver 28mm',
        TK002A: () => 'Token brass 22mm, thin',
        TK003A: () => 'Token brass 22mm, thick',
    },
    fr: {
        TK001A: () => 'Jeton argent 28mm',
        TK002A: () => 'Jeton laiton 22mm, mince',
        TK003A: () => 'Jeton laiton 22mm, gros',
    },
    it: {
        TK001A: () => 'Gettone argento 28mm',
        TK002A: () => 'Gettone ottone 22mm, fine',
        TK003A: () => 'Gettone ottone 22mm, grosso',
    },
};
