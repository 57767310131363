import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import { Localized } from '../../common/components/Localized.tsx';
import { useState } from 'react';
import { DiscardChangesDialogProps } from 'dg-web-shared/common/components/material-ui/routed-modal/RoutedModal.tsx';

export function DiscardChangesButton({
    onReset,
    disabled,
}: {
    onReset: () => void;
    disabled?: boolean;
}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <CancelButton onClick={() => setOpen(true)} disabled={disabled} />
            <DiscardChangesDialog
                open={open}
                onLeave={() => {
                    setOpen(false);
                    onReset();
                }}
                onStay={() => setOpen(false)}
            />
        </>
    );
}

export function CancelButton({
    onClick,
    disabled,
}: {
    onClick: () => void;
    disabled?: boolean;
}) {
    return (
        <Button
            onClick={onClick}
            variant="outlined"
            color={'white'}
            disabled={disabled}
        >
            <Localized de="Abbrechen" fr="Annuler" it="Annulla" en="Cancel" />
        </Button>
    );
}

export function DiscardChangesDialog({
    open,
    onLeave,
    onStay,
}: DiscardChangesDialogProps) {
    return (
        <Dialog open={open} maxWidth="xs">
            <DialogTitle>
                <Localized
                    de="Änderungen verwerfen"
                    fr="Rejeter les changements"
                    it="Rifiutare le modifiche"
                    en="Discard changes"
                />
            </DialogTitle>
            <DialogContent>
                <Localized
                    de="Wenn Sie jetzt weiter machen, gehen alle Änderungen verloren."
                    fr="Si vous continuez maintenant, tous les changements seront perdus."
                    it="Se adesso continua, tutte le modifiche andranno perse."
                    en="If you continue now, all changes will be lost."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onStay}>
                    <Localized
                        de="Zurück"
                        fr="Retour"
                        it="Indietro"
                        en="Back"
                    />
                </Button>
                <Button onClick={onLeave} variant="outlined" color="error">
                    <Localized
                        de="Weiter"
                        fr="Continuer"
                        it="Continua"
                        en="Continue"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
