import * as superagent from 'superagent';
import {
    makePendingResponse,
    makeTimeoutResponse,
    Response,
    SuperagentResponse,
} from 'dg-web-shared/lib/HttpResponse.ts';
import { Store, Update } from 'dg-web-shared/lib/Flux.tsx';
import * as AuthenticationState from './app/state/AuthenticationState.ts';

export type { Response } from 'dg-web-shared/lib/HttpResponse.ts';

export type RawRequest = superagent.Request<unknown, unknown>;

export interface RequestWrite {
    (store: Store, res: Response): string;
}

export function makeResponse(
    err: unknown,
    req: RawRequest,
    sRes: superagent.Response<unknown>,
): Response {
    let res: Response;

    if (err && !sRes) {
        res = makeTimeoutResponse(req);
    } else {
        res = new SuperagentResponse(req, sRes);
    }

    return res;
}

export function globalRequestBehaviorUpdate(store: Store, res: Response): void {
    const statusCode = res.statusCode;
    // avoid recursive import by using the key of the Auth State Slice...
    const auth: AuthenticationState.State = store.get(
        'app-AuthenticationState',
    );
    if (statusCode.unauthorized && auth.status.authorized) {
        window.location.reload();
    }
}

export function request<V>(
    reqGen: (args: V) => RawRequest,
    requestUpdate: RequestWrite,
): Update<V> {
    return (store: Store, args?: V): string => {
        const req = reqGen(args!);

        const pendingName =
            requestUpdate(store, makePendingResponse(req)) +
            '-requestPending-log-ignore';
        req.end((err: unknown, superagentRes: superagent.Response<unknown>) => {
            const res = makeResponse(err, req, superagentRes);
            globalRequestBehaviorUpdate(store, res);
            store.update(
                (store: Store, res?: Response) =>
                    requestUpdate(store, res!) + '-requestReturned-log-ignore',
                res,
            );
        });
        return pendingName;
    };
}
