import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import * as OperatorLoginsState from '../../../common/state/OperatorLoginsState.ts';
import * as PermitTypeState from '../../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { FilterButtonWithSummary } from '../../../ui/filter/FilterButtonWithSummary.tsx';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../../../ui/filter/FilterSummary.tsx';
import * as FilteredPermitsActions from '../../actions/FilteredPermitsActions.ts';
import {
    filterSummaryTexts,
    issueDateFilterTexts,
    operatorLoginFilterTexts,
    permitTypeFilterTexts,
} from '../../i18n/FilteredPermitsTexts.ts';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import { CostFilterSummary } from './CostFilter.tsx';
import { operatorLoginSelection } from './OperatorLoginFilter.tsx';
import { permitTypeSelection } from './PermitTypeFilter.tsx';
import { StatusFilterSummary } from './StatusFilter.tsx';
import { TimeLimitFilterSummary } from './TimeLimitFilter.tsx';
import { ChannelFilterSummary } from './ChannelFilter.tsx';

export interface FilterSummaryTexts {
    validDate: Text.Translation;
    zone: Text.Translation;
}

interface FilteredPermitsFilterSummaryState {
    settings: SettingsState.State;
    filter: FilteredPermitsState.Filter.State;
    filterSummary: FilteredPermitsState.FilterSummary.State;
}

export class FilteredPermitsFilterSummary extends Flux.Container<FilteredPermitsFilterSummaryState> {
    stateSelector(): FilteredPermitsFilterSummaryState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            filterSummary: FilteredPermitsState.FilterSummary.get(
                this.props.allState,
            ),
        };
    }

    render() {
        const texts = filterSummaryTexts[this.state.settings.language];
        return (
            <FilterButtonWithSummary
                onFilterClick={() =>
                    this.update(store =>
                        FilteredPermitsState.Filter.stateWrite(store, {
                            filterSlideInVisible: true,
                        }),
                    )
                }
                clearFilter={() =>
                    this.update(FilteredPermitsActions.clearFilter)
                }
            >
                {FilteredPermitsState.Filter.filterActive(
                    this.state.filter,
                ) && (
                    <>
                        <ValidDateFilterSummary
                            filter={this.state.filter}
                            texts={texts}
                        />
                        <PermitTypeFilterSummary
                            allState={this.props.allState}
                        />
                        <OperatorLoginFilterSummary
                            allState={this.props.allState}
                        />
                        <IssueDateFilterSummary
                            allState={this.props.allState}
                        />
                        <TimeLimitFilterSummary />
                        <ChannelFilterSummary />
                        <CostFilterSummary />
                        <StatusFilterSummary />
                    </>
                )}
            </FilterButtonWithSummary>
        );
    }
}

interface SummaryElementProps {
    filter: FilteredPermitsState.Filter.State;
    texts: FilterSummaryTexts;
}

const ValidDateFilterSummary = (p: SummaryElementProps): JSX.Element => (
    <Conditional
        c={FilteredPermitsState.Filter.validDateFilterActive(p.filter)}
    >
        <FilterSummaryItem hasSeparator={false}>
            <FilterSummaryItemTitle title={p.texts.validDate()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    Formatter.openRange(
                        p.filter.validDateFrom,
                        p.filter.validDateTo,
                        Formatter.dayMonthYear,
                    )
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

interface PermitTypeFilterSummaryState {
    filter: FilteredPermitsState.Filter.State;
    settings: SettingsState.State;
    permitTypes: PermitTypeState.State;
}

class PermitTypeFilterSummary extends Flux.Container<PermitTypeFilterSummaryState> {
    stateSelector(): PermitTypeFilterSummaryState {
        return {
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            settings: new SettingsState.StateSlice(this.props.allState).state,
            permitTypes: PermitTypeState.get(this.props.allState),
        };
    }

    render() {
        return (
            <Conditional
                c={FilteredPermitsState.Filter.permitTypeFilterActive(
                    this.state.filter,
                )}
            >
                <FilterSummaryItem
                    hasSeparator={FilteredPermitsState.Filter.validDateFilterActive(
                        this.state.filter,
                    )}
                >
                    <FilterSummaryItemTitle
                        title={permitTypeFilterTexts[
                            this.state.settings.language
                        ].filterTitle()}
                    />
                    <FilterSummaryItemSubTitle
                        title={
                            '\u00A0' +
                            permitTypeSelection(
                                this.state.permitTypes.data,
                                this.state.filter.permitTypes,
                            )
                        }
                    />
                </FilterSummaryItem>
            </Conditional>
        );
    }
}

interface OperatorLoginFilterSummaryState {
    filter: FilteredPermitsState.Filter.State;
    settings: SettingsState.State;
    operatorLogins: OperatorLoginsState.List.State;
}

class OperatorLoginFilterSummary extends Flux.Container<OperatorLoginFilterSummaryState> {
    stateSelector(): OperatorLoginFilterSummaryState {
        return {
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            settings: new SettingsState.StateSlice(this.props.allState).state,
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
        };
    }

    render() {
        return (
            <Conditional
                c={FilteredPermitsState.Filter.operatorLoginFilterActive(
                    this.state.filter,
                )}
            >
                <FilterSummaryItem
                    hasSeparator={
                        FilteredPermitsState.Filter.validDateFilterActive(
                            this.state.filter,
                        ) ||
                        FilteredPermitsState.Filter.permitTypeFilterActive(
                            this.state.filter,
                        )
                    }
                >
                    <FilterSummaryItemTitle
                        title={operatorLoginFilterTexts[
                            this.state.settings.language
                        ].filterTitle()}
                    />
                    <FilterSummaryItemSubTitle
                        title={
                            '\u00A0' +
                            operatorLoginSelection(
                                this.state.operatorLogins.data,
                                this.state.filter.operatorLogins,
                            )
                        }
                    />
                </FilterSummaryItem>
            </Conditional>
        );
    }
}

interface IssueDateFilterSummaryState {
    filter: FilteredPermitsState.Filter.State;
    settings: SettingsState.State;
}

class IssueDateFilterSummary extends Flux.Container<IssueDateFilterSummaryState> {
    stateSelector(): IssueDateFilterSummaryState {
        return {
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        return (
            <Conditional
                c={FilteredPermitsState.Filter.issueDateFilterActive(
                    this.state.filter,
                )}
            >
                <FilterSummaryItem
                    hasSeparator={
                        FilteredPermitsState.Filter.validDateFilterActive(
                            this.state.filter,
                        ) ||
                        FilteredPermitsState.Filter.permitTypeFilterActive(
                            this.state.filter,
                        ) ||
                        FilteredPermitsState.Filter.operatorLoginFilterActive(
                            this.state.filter,
                        )
                    }
                >
                    <FilterSummaryItemTitle
                        title={issueDateFilterTexts[
                            this.state.settings.language
                        ].filterTitle()}
                    />
                    <FilterSummaryItemSubTitle
                        title={
                            '\u00A0' +
                            Formatter.openRange(
                                this.state.filter.issueDateFrom,
                                this.state.filter.issueDateTo,
                                Formatter.dayMonthYear,
                            )
                        }
                    />
                </FilterSummaryItem>
            </Conditional>
        );
    }
}
