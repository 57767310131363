import moment from 'moment';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined, Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';

export interface Clearance {
    id: number;
    validFrom: moment.Moment;
    validTo: moment.Moment;
    permitTypeName: string;
    additionalInfos: string;
    licensePlateNr: Maybe<string>;
    licensePlateType: Maybe<LicensePlateType>;
    licensePlateCountry: Maybe<string>;
    badgeLabelNr: Maybe<string>;
    created: moment.Moment;
    operatorLoginId: number;
    customerNr: string | null;
}

export namespace List {
    const parseClearance = (p: Clearance): Clearance => {
        return {
            id: p.id,
            validFrom: Parser.isoToMoment(<any>p.validFrom),
            validTo: Parser.isoToMoment(<any>p.validTo),
            permitTypeName: p.permitTypeName,
            additionalInfos: p.additionalInfos,
            licensePlateNr: p.licensePlateNr,
            licensePlateType: p.licensePlateType,
            licensePlateCountry: p.licensePlateCountry,
            badgeLabelNr: p.badgeLabelNr,
            created: Parser.isoToMoment(<any>p.created),
            operatorLoginId: p.operatorLoginId,
            customerNr: p.customerNr,
        };
    };

    export type Data = { size: Maybe<number>; data: Clearance[] };

    export type State = ServerStateSlice.ServerState<Data>;

    const shouldFetch = (store: Flux.Store) =>
        Filter.filterActive(Filter.get(store));

    export const { get, reset, setResponse, forceRefetch } =
        ServerStateSlice.generateServerState<Data>(
            'clearance-permit-list-FilteredClearancesState.List',
            () => {
                return { size: null, data: [] };
            },
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch && shouldFetch(store)) {
                    store.update(fetchClearances(store));
                }
            },
            (body: any): Data => {
                return { size: body.size, data: body.data.map(parseClearance) };
            },
        );

    const fetchClearances = (store: Flux.Store) =>
        AsyncRequest.request(
            () =>
                Http.OperatorAccount.Clearances.FilteredList.get({
                    permitType: Filter.get(store).permitTypes,
                    issuedBy: thenElse(
                        Filter.get(store).operatorLogins,
                        l =>
                            l.map(id =>
                                id === Filter.OperatorLoginFilterOnlineOption
                                    ? null
                                    : id,
                            ),
                        null,
                    ),
                    issueDateFrom: Filter.get(store).issueDateFrom,
                    issueDateTo: Filter.get(store).issueDateTo,
                    validDateFrom: Filter.get(store).validDateFrom,
                    validDateTo: Filter.get(store).validDateTo,
                    sortField: 'created',
                    withOrWithoutPermits: Filter.get(store).permits,
                }),
            setResponse,
        );
}

export namespace Filter {
    export const OperatorLoginFilterOnlineOption = -99;

    export interface State {
        filterSlideInVisible: boolean;

        permitTypes: number[] | null;
        permitTypeSelectionVisible: boolean;

        operatorLogins: Maybe<number[]>;
        operatorLoginSelectionVisible: boolean;

        issueDateFrom: Maybe<moment.Moment>;
        issueDateTo: Maybe<moment.Moment>;
        validDateFrom: Maybe<moment.Moment>;
        validDateTo: Maybe<moment.Moment>;
        issueDateSelectionVisible: boolean;
        validDateSelectionVisible: boolean;
        permits: string | null;
        permitsSelectionVisible: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'clearance-permit-list-FilteredClearancesState.Filter',
        {
            filterSlideInVisible: false,

            permitTypes: null,
            permitTypeSelectionVisible: false,

            operatorLogins: null,
            operatorLoginSelectionVisible: false,

            issueDateFrom: null,
            issueDateTo: null,
            validDateFrom: null,
            validDateTo: null,
            issueDateSelectionVisible: false,
            validDateSelectionVisible: false,
            permits: null,
            permitsSelectionVisible: false,
        },
    );

    export const permitTypeFilterActive = (s: State): boolean =>
        thenElse(s.permitTypes, pt => pt.length > 0, false);

    export const operatorLoginFilterActive = (s: State): boolean =>
        thenElse(s.operatorLogins, ol => ol.length > 0, false);

    export const issueDateFilterActive = (s: State): boolean =>
        isDefined(s.issueDateFrom) || isDefined(s.issueDateTo);

    export const permitsFilterActive = (s: State): boolean =>
        isDefined(s.permits);

    export const validDateFilterActive = (s: State): boolean =>
        isDefined(s.validDateFrom) || isDefined(s.validDateTo);
    export const filterActive = (s: State): boolean =>
        permitTypeFilterActive(s) ||
        operatorLoginFilterActive(s) ||
        issueDateFilterActive(s) ||
        permitsFilterActive(s) ||
        validDateFilterActive(s);
}

export namespace FilterSummary {
    export interface State {
        hover: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'clearance-permit-list-FilteredClearancesState.FilterSummary',
        {
            hover: false,
        },
    );
}
