import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import * as PermitCreateState from './PermitCreateState.ts';
import * as PermitEditState from './PermitEditState.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import {
    Maybe,
    isDefinedAndDiffersFrom,
    thenElse,
    getOrElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { editContextEnabled } from '../components/PermitSlideIn.tsx';

export type gender = 'm' | 'f' | '' | undefined | null;

export const isMale = (g: gender): boolean => g === 'm';
export const isFemale = (g: gender): boolean => g === 'f';

export interface Address {
    gender: gender;
    firstname: string | undefined | null;
    lastname: string | undefined | null;
    company1: string | undefined | null;
    company2: string | undefined | null;
    address1: string | undefined | null;
    address2: string | undefined | null;
    city: string | undefined | null;
    zipcode: string | undefined | null;
    country: string | undefined | null;
    language: string | undefined | null;
    phone: string | undefined | null;
    email: string | undefined | null;
}

export interface CustomerAddress extends Address {
    customerNr: number;
}

export interface PermitAddress extends Address {
    id: Maybe<number>;
}

const sn = ElementNamer('permit-detail-AddressState');

export namespace Select {
    export interface State {
        selectAddressOpen: boolean;
        addressSelected: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('Select'),
        {
            selectAddressOpen: false,
            addressSelected: false,
        },
    );
}

export const getLicensePlates = (store: Flux.Store) => {
    const editEnabled = editContextEnabled({
        permitCreateLayout: PermitCreateState.Layout.get(store),
        clearancePermitList: new ClearancePermitListState.StateSlice(store)
            .state,
    });
    return editEnabled
        ? getOrElse(
              PermitEditState.Edit.get(store).licensePlates,
              thenElse(
                  PermitEditState.Server.get(store).data,
                  d => d.licensePlates,
                  [],
              ),
          )
        : PermitCreateState.Config.get(store).licensePlates;
};

export namespace ServerList {
    export type State = ServerStateSlice.ServerState<CustomerAddress[]>;

    export const { get, setResponse, reset } =
        ServerStateSlice.generateServerState<CustomerAddress[]>(
            sn('ServerList'),
            () => [],
            (store: Flux.Store, state: State) => {
                const lps = getLicensePlates(store);
                if (state.shouldFetch && lps.length > 0) {
                    store.update(
                        fetchAddresses,
                        lps.map(lp => lp.id),
                    );
                }
            },
            b => b,
        );

    const fetchAddresses = AsyncRequest.request(
        Http.OperatorAccount.Address.getListByLpIds,
        setResponse,
    );
}

export namespace Edit {
    export const initializeAddress = (): Partial<Address> => {
        return {
            country: 'CH',
            language: null,
        };
    };

    export interface State {
        address: Partial<Address>;
        selectAddressCountryOpen: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('Edit'),
        {
            address: initializeAddress(),
            selectAddressCountryOpen: false,
        },
    );

    export const writeAddress = (store: Flux.Store, adr: Partial<Address>) =>
        stateWrite(store, { address: { ...get(store).address, ...adr } });

    export const getAddress = (s: State): Address => {
        return {
            gender: s.address.gender,
            firstname: s.address.firstname,
            lastname: s.address.lastname,
            company1: s.address.company1,
            company2: s.address.company2,
            address1: s.address.address1,
            address2: s.address.address2,
            city: s.address.city,
            zipcode: s.address.zipcode,
            country: s.address.country,
            language: s.address.language,
            phone: s.address.phone,
            email: s.address.email,
        };
    };

    export const hasChanges = (s: Address, a: Address) =>
        isDefinedAndDiffersFrom(s.gender, a.gender) ||
        isDefinedAndDiffersFrom(s.firstname, a.firstname) ||
        isDefinedAndDiffersFrom(s.lastname, a.lastname) ||
        isDefinedAndDiffersFrom(s.company1, a.company1) ||
        isDefinedAndDiffersFrom(s.company2, a.company2) ||
        isDefinedAndDiffersFrom(s.address1, a.address1) ||
        isDefinedAndDiffersFrom(s.address2, a.address2) ||
        isDefinedAndDiffersFrom(s.city, a.city) ||
        isDefinedAndDiffersFrom(s.zipcode, a.zipcode) ||
        isDefinedAndDiffersFrom(s.country, a.country) ||
        isDefinedAndDiffersFrom(s.language, a.language) ||
        isDefinedAndDiffersFrom(s.phone, a.phone) ||
        isDefinedAndDiffersFrom(s.email, a.email);
}

export const resetAll = (store: Flux.Store) => {
    Select.reset(store);
    ServerList.reset(store);
    Edit.reset(store);
};
