import { ParkingSpace } from 'dg-web-shared/model/TomConfiguration.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export interface ParkingSpacesProps {
    parkingSpaces: ParkingSpace[];
}

export function ParkingSpaces(props: ParkingSpacesProps): JSX.Element {
    return (
        <div
            className={css({
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                ...Typo.robotoThin,
            })}
        >
            {props.parkingSpaces.map(
                (parkingSpace: ParkingSpace, spaceIndex: number) => {
                    if (parkingSpace.enabled) {
                        return (
                            <ActiveParkingSpace
                                parkingSpace={parkingSpace}
                                key={spaceIndex}
                            />
                        );
                    } else {
                        return <InactiveParkingSpace key={spaceIndex} />;
                    }
                },
            )}
        </div>
    );
}

interface ParkingSpaceProps {
    parkingSpace: ParkingSpace;
}

const spaceDetailStyle = css({
    margin: '3px',
    display: 'flex',
    textAlign: 'left',
    lineHeight: '50px',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
    '&:nth-child(n+6)': { flexDirection: 'row-reverse' },
});

function ActiveParkingSpace(props: ParkingSpaceProps): JSX.Element {
    return (
        <div className={spaceDetailStyle}>
            <div
                className={css({
                    backgroundColor: ColorHex.rgba(ColorHex.darkblue, 0.1),
                    height: '50px',
                    width: '50px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                })}
            >
                {props.parkingSpace.label}
            </div>
        </div>
    );
}

function InactiveParkingSpace(): JSX.Element {
    return (
        <div className={spaceDetailStyle}>
            <div
                className={css({
                    backgroundColor: ColorHex.white,
                    border: `1px solid ${ColorHex.rgba(
                        ColorHex.darkblue,
                        0.25,
                    )}`,
                    boxSizing: 'border-box',
                    height: '50px',
                    width: '50px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                })}
            />
        </div>
    );
}
