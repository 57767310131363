import { Box, useTheme } from '@mui/material';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon.tsx';

export function ParkingPortalLogoHeader() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                background: theme.palette.primary.main,
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            }}
        >
            <ParkingPortalLogo width={300} height={200} />
        </Box>
    );
}

function ParkingPortalLogo({
    width,
    height,
}: {
    width: number;
    height: number;
}) {
    return (
        <Box
            sx={{
                width: width,
                height: height,
            }}
        >
            <SvgIcon icon={'parkingPortal'} width={547} height={325} />
        </Box>
    );
}
