import { MaterialThemeProvider } from 'dg-web-shared/common/hooks/MaterialThemeProvider.tsx';
import { useOperatorLanguage } from '../../common/state/SettingsState.ts';
import { defaultOperatorMuiTheme } from '../config/themes/DefaultOperatorMuiTheme.ts';

export function OperatorThemeProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const language = useOperatorLanguage();
    return (
        <MaterialThemeProvider
            theme={defaultOperatorMuiTheme}
            language={language}
        >
            {children}
        </MaterialThemeProvider>
    );
}
