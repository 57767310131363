import { Maybe, thenElse, thenOrNull } from 'dg-web-shared/lib/MaybeV2.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import {
    OnstreetTransaction,
    OnstreetTransactionChannel,
    OnstreetTransactionRequestSearchParams,
} from './OnstreetTransactionModel.ts';
import moment from 'moment';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import * as Punctuation from 'dg-web-shared/lib/Punctuation.ts';
import { DateTime } from 'luxon';

export function filterStateToRequestParameters(args: {
    validFrom: Maybe<moment.Moment>;
    validTo: Maybe<moment.Moment>;
    zones: Maybe<number[]>;
    search: Maybe<string>;
    status: Maybe<string>;
    lang?: string;
}): OnstreetTransactionRequestSearchParams {
    return {
        validFrom: thenElse(
            args.validFrom,
            from => Formatter.isoYearMonthDay(from),
            null,
        ),
        validTo: thenElse(
            args.validTo,
            from => Formatter.isoYearMonthDay(from),
            null,
        ),
        zones: thenOrNull(args.zones, z => JSON.stringify(z)),
        search: args.search ?? null,
        status: args.status ?? null,
        lang: args.lang ?? null,
    };
}

function getTransactionPrice(price: number, showCurrency: boolean) {
    return numberToPrice(price, showCurrency);
}

export function getRefundAmount(t: OnstreetTransaction, showCurrency: boolean) {
    return t.refund ? getTransactionPrice(t.refund.amount, showCurrency) : null;
}

export function getPrice(t: OnstreetTransaction, showCurrency: boolean) {
    return t.isRunning ? '' : getTransactionPrice(t.price, showCurrency);
}

export function getChannelTitle(t: OnstreetTransaction) {
    switch (t.channel) {
        case OnstreetTransactionChannel.Parkingpay:
        case OnstreetTransactionChannel.TWINT:
        case OnstreetTransactionChannel.EasyPark:
            return t.channel;
        case OnstreetTransactionChannel.Pnrail:
            return 'P+Rail';
    }
}

export function getZoneName(t: OnstreetTransaction) {
    return `${t.zoneCode} | ${getZoneString(t)} — ${t.location}`;
}

function getZoneString(t: OnstreetTransaction) {
    return t.zoneName.replace(/([/])/g, '$1' + Punctuation.Zwsp);
}

export function getTransactionDateTime(isoDateString: string) {
    return Formatter.dayMonthYearHourMinute(DateTime.fromISO(isoDateString));
}

export function getFromDate(t: OnstreetTransaction) {
    return getTransactionDateTime(t.tbegin);
}

export function getToDate(t: OnstreetTransaction) {
    return t.tend ? getTransactionDateTime(t.tend) : '';
}

export function getDuration(t: OnstreetTransaction, language: string) {
    const from = DateTime.fromISO(t.tbegin);
    if (!t.tend) {
        return '';
    }
    const to = DateTime.fromISO(t.tend);
    return Formatter.getDuration(from, to, Formatter.durationTexts[language]);
}
