import { useParams } from 'react-router-dom';
import { RecodeError } from './CloudConnectorRecodeTicketConditionsOutlet.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { Alert } from '@mui/material';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';

function isRecodeErrorType(value?: string): value is RecodeError {
    return Object.values(RecodeError).includes(value as RecodeError);
}

function getRecodeErrorType(value?: string): RecodeError | null {
    if (isRecodeErrorType(value)) {
        return value;
    }
    return null;
}

export function CloudConnectorRecodeError() {
    const urlParams = useParams<{ recodeError: string }>();
    const recodeError = getRecodeErrorType(urlParams.recodeError);
    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Umcodieren fehlgeschlagen"
                    fr="Échec du recodage"
                    it="Ricodifica fallita"
                    en="Recoding failed"
                />
            }
            backTo={'..'}
        >
            <RecodeErrorBody recodeError={recodeError} />
        </ParkingPortalLayoutWithHeader>
    );
}

function RecodeErrorBody({ recodeError }: { recodeError: RecodeError | null }) {
    switch (recodeError) {
        case RecodeError.PARSING_FAILED:
            return <RecodeParsingFailed />;
        case RecodeError.IDENTIFICATION_UNKNOWN:
            return <RecodeIdentificationUnknown />;
        case RecodeError.ZONE_NOT_AVAILABLE:
            return <RecodeZoneNotAvailable />;
        case RecodeError.ALREADY_PAID:
            return <RecodeAlreadyPayed />;
        case null:
            return <UnexpectedError />;
    }
}

function RecodeParsingFailed() {
    return (
        <Alert severity="error">
            <Localized
                de="Das Ticket konnte nicht richtig geparsed werden."
                fr="Le ticket n'a pas pu être analysé correctement."
                it="Il ticket non è stato analizzato correttamente."
                en="The ticket could not be parsed correctly."
            />
        </Alert>
    );
}

function RecodeIdentificationUnknown() {
    return (
        <Alert severity="error">
            <Localized
                de="Die Identifikation des Tickets ist unbekannt."
                fr="L'identification du ticket est inconnue."
                it="L'identificazione del ticket è sconosciuta."
                en="The ticket identification is unknown."
            />
        </Alert>
    );
}

function RecodeZoneNotAvailable() {
    return (
        <Alert severity="error">
            <Localized
                de="Keine Umkodierungsbedingungen ist verfügbar."
                fr="Aucune condition de recodage n'est disponible."
                it="Nessuna condizione di ricodifica è disponibile."
                en="No recoding conditions are available."
            />
        </Alert>
    );
}

function RecodeAlreadyPayed() {
    return (
        <Alert severity="error">
            <Localized
                de="Das Ticket wurde bereits bezahlt."
                fr="Le ticket a déjà été payé."
                it="Il ticket è già stato pagato."
                en="The ticket has already been paid."
            />
        </Alert>
    );
}

function UnexpectedError() {
    return (
        <Alert severity="error">
            <Localized
                de="Ein unerwarteter Fehler ist aufgetreten."
                fr="Une erreur inattendue s'est produite."
                it="Si è verificato un errore imprevisto."
                en="An unexpected error has occurred."
            />
        </Alert>
    );
}
