import { selectState, Store } from 'dg-web-shared/lib/Flux.tsx';
import {
    Language,
    languageFromString,
    makeLocalizedText,
    Message,
} from 'dg-web-shared/lib/Localized.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../../../ui/filter/FilterSummary.tsx';
import { StringSingleSelectionSlideIn } from '../../../ui/slidein/SingleSelectionSlideIn.tsx';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import { OperatorAccount } from '../../../api/Http.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';

export const CostFilterSelection = selectState(mapStateSlices, props => {
    const t = makeLocalizedText(props.language);

    return (
        <SingleSelection
            label={t(messages.filterTitle)}
            focused={false}
            selection={
                props.filter.cost ? t(messages[props.filter.cost]) : null
            }
            onClick={() => {
                props.update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        costSelectionVisible: true,
                    }),
                );
            }}
            onClear={
                props.filter.cost
                    ? () => {
                          props.update(store =>
                              FilteredPermitsState.Filter.stateWrite(store, {
                                  cost: null,
                              }),
                          );

                          props.update(FilteredPermitsState.List.reset);
                      }
                    : null
            }
        />
    );
});

export const CostFilterSummary = selectState(mapStateSlices, props => {
    const t = makeLocalizedText(props.language);
    return (
        <Conditional c={!!props.filter.cost}>
            <FilterSummaryItem hasSeparator={true}>
                <FilterSummaryItemTitle title={t(messages.filterTitle)} />
                <FilterSummaryItemSubTitle
                    title={
                        '\u00A0' +
                        (props.filter.cost
                            ? t(messages[props.filter.cost])
                            : '-')
                    }
                />
            </FilterSummaryItem>
        </Conditional>
    );
});

export const CostFilterHalfSlideIn = selectState(mapStateSlices, props => {
    const language = languageFromString(props.language);
    const t = makeLocalizedText(language);

    return (
        <StringSingleSelectionSlideIn
            open={props.filter.costSelectionVisible}
            heading={t(messages.filterTitle)}
            selection={props.filter.cost}
            options={filterOptions(language)}
            onLeftActionClick={() => {
                props.update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        costSelectionVisible: false,
                    }),
                );
            }}
            onSelect={v => {
                props.update(store =>
                    FilteredPermitsState.Filter.stateWrite(store, {
                        cost: v as OperatorAccount.Permits.Cost,
                        costSelectionVisible: false,
                    }),
                );

                props.update(FilteredPermitsState.List.reset);
            }}
        />
    );
});

function filterOptions(
    language: Language,
): { id: string; displayText: string }[] {
    const t = makeLocalizedText(language);

    return Object.keys(OperatorAccount.Permits.Cost).map((id: string) => ({
        id,
        displayText: t(messages[id as OperatorAccount.Permits.Cost]),
    }));
}

function mapStateSlices(store: Store) {
    return {
        language: languageFromString(
            new SettingsState.StateSlice(store).state.language,
        ),
        filter: FilteredPermitsState.Filter.get(store),
    };
}

const messages: Messages = {
    filterTitle: {
        de: 'Preis',
        fr: 'Prix',
        it: 'Prezzo',
        en: 'Price',
    },
    [OperatorAccount.Permits.Cost.FREE]: {
        de: 'kostenlos',
        fr: 'gratuite',
        it: 'gratuita',
        en: 'free',
    },
    [OperatorAccount.Permits.Cost.CHARGEABLE]: {
        de: 'kostenpflichtige',
        fr: 'payante',
        it: 'a pagamento',
        en: 'chargable',
    },
};

interface Messages {
    filterTitle: Message;
    [OperatorAccount.Permits.Cost.CHARGEABLE]: Message;
    [OperatorAccount.Permits.Cost.FREE]: Message;
}
