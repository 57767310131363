import { css } from '@emotion/css';

import { getOrElse, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';

export interface BulletListProps {
    ordered?: Maybe<boolean>;
    children?: React.ReactNode;
}

export function BulletList(props: BulletListProps): JSX.Element {
    const ordered = getOrElse(props.ordered, false);

    const styles = {
        ...Typo.robotoLight,
        color: Colors.darkblue,
        margin: 0,
        paddingLeft: 30,
    };

    if (ordered) {
        return <ol className={css(styles)}>{props.children}</ol>;
    } else {
        return (
            <ul
                className={css({
                    ...styles,
                    listStyleType: 'square',
                })}
            >
                {props.children}
            </ul>
        );
    }
}

export interface ItemProps {
    children?: React.ReactNode;
}

export function Item(props: ItemProps): JSX.Element {
    return <li>{props.children}</li>;
}
