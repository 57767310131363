import { isDefined, isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    SlideInHeaderButtonsContainer,
    SlideInHeaderIcon,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import * as ClearanceEditState from '../state/ClearanceEditState.ts';
import { Translation } from '../../common/i18n/Text.ts';
import { deselectClearance } from '../actions/ClearanceDetailActions.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { isActive } from '../../clearance-permit-list/state/LicensePlateBadgeClearancesState.ts';
import * as ClearanceViewHeaderTexts from '../i18n/ClearanceViewHeaderTexts.tsx';
import { Color } from '../../ui/Colors.ts';
import {
    ButtonDropdown,
    ButtonDropdownItem,
} from '../../ui/slidein/ButtonDropdown.tsx';

export interface Texts {
    active: Translation;
    expired: Translation;
    clearance: Translation;
    terminateTooltip: Translation;
    editLabel: Translation;
}

interface State {
    settings: SettingsState.State;
    selectedClearance: ClearanceEditState.Server.State;
}

export class ClearanceViewHeader extends Flux.Container<State> {
    static displayName = 'PermitViewHeader';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            selectedClearance: ClearanceEditState.Server.get(
                this.props.allState,
            ),
        };
    }

    getTexts(): Texts {
        return ClearanceViewHeaderTexts.texts[this.state.settings.language];
    }

    getStatus(): string {
        const c = this.state.selectedClearance.data;
        const t = this.getTexts();
        if (isDefined(c) && isActive(c)) {
            return t.active();
        } else {
            return t.expired();
        }
    }

    getIcon(): JSX.Element {
        const p = this.state.selectedClearance.data;
        if (isUndefined(p)) {
            return Icons24.clearanceState.active;
        } else if (isActive(p)) {
            return Icons24.clearanceState.active;
        } else {
            return Icons24.clearanceState.inactive;
        }
    }

    render() {
        return (
            <StandardFirstLevelHeader
                onClose={() => this.props.allState.update(deselectClearance)}
            >
                <SlideInHeaderIcon
                    icon={this.getIcon()}
                    color={Color.darkblue}
                />
                <SlideInHeaderTexts
                    title={this.getStatus()}
                    hasLeftIcon={true}
                />
                <SlideInHeaderButtonsContainer>
                    <ButtonDropdown label={this.getTexts().editLabel()}>
                        <ButtonDropdownItem
                            label={this.getTexts().terminateTooltip()}
                            onClick={() =>
                                this.update(store =>
                                    ClearanceEditState.Delete.stateWrite(
                                        store,
                                        {
                                            deletePressed: true,
                                        },
                                    ),
                                )
                            }
                        />
                    </ButtonDropdown>
                </SlideInHeaderButtonsContainer>
            </StandardFirstLevelHeader>
        );
    }
}
