import moment from 'moment';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as TomCollectionsState from '../state/TomCollectionsState.ts';
import {
    fromDateConstraint,
    toDateConstraint,
} from '../../clearance-permit-list/actions/shared.ts';
import { TomCollection } from 'dg-web-shared/model/TomCollection.ts';

export const setValidFrom = (s: Flux.Store, d: moment.Moment): string => {
    TomCollectionsState.Filter.stateWrite(s, {
        validFrom: d,
        validTo: toDateConstraint(d, TomCollectionsState.Filter.get(s).validTo),
    });
    TomCollectionsState.List.reset(s);
    return 'collections-setDateFrom';
};

export const setValidTo = (s: Flux.Store, d: moment.Moment): string => {
    TomCollectionsState.Filter.stateWrite(s, {
        validTo: d,
        validFrom: fromDateConstraint(
            d,
            TomCollectionsState.Filter.get(s).validFrom,
        ),
    });
    TomCollectionsState.List.reset(s);
    return 'collections-setDateTo';
};

export const clearValidDate = (s: Flux.Store) => {
    TomCollectionsState.Filter.stateWrite(s, {
        validFrom: null,
        validTo: null,
    });
    TomCollectionsState.List.reset(s);
    return 'collections-clearDateFrom';
};

export const clearToms = (s: Flux.Store) => {
    TomCollectionsState.Filter.stateWrite(s, {
        toms: [],
    });
    TomCollectionsState.List.reset(s);
    return 'collections-clearToms';
};

export const clearSticks = (s: Flux.Store) => {
    TomCollectionsState.Filter.stateWrite(s, {
        sticks: [],
    });
    TomCollectionsState.List.reset(s);
    return 'collections-clearToms';
};

export const setToms = (s: Flux.Store, ids: number[]) => {
    TomCollectionsState.Filter.stateWrite(s, {
        toms: ids,
    });
    TomCollectionsState.List.reset(s);
    return 'collections-setToms';
};

export const closeTomFilter = (s: Flux.Store) => {
    TomCollectionsState.Filter.stateWrite(s, {
        tomSelectionVisible: false,
    });
    return 'collections-tomSelection-closeFilter';
};

export const setHideEmptyCollections = (s: Flux.Store, value: boolean) => {
    TomCollectionsState.Filter.stateWrite(s, {
        hideEmptyCollections: value,
    });
    TomCollectionsState.List.reset(s);
    return 'collections-setHideEmptyCollections';
};

export const clearFilter = (s: Flux.Store) => {
    TomCollectionsState.Filter.stateWrite(s, {
        validFrom: null,
        validTo: null,
        toms: [],
    });
    TomCollectionsState.List.reset(s);
    return 'collections-clearFilter';
};

export const openCollectionDetail = (s: Flux.Store, t: TomCollection) => {
    TomCollectionsState.Detail.stateWrite(s, {
        selectedCollection: t,
    });
    return 'collections-openCollectionDetail';
};

export const closeCollectionDetail = (s: Flux.Store) => {
    TomCollectionsState.Detail.reset(s);
    return 'collections-closeCollectionDetail';
};
