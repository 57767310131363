import { Box } from '@mui/material';
import { SeparationBar } from './SeparationBar.tsx';

export function OperatorRoutedModalContent({
    header,
    bar,
    body,
    style,
}: {
    header: JSX.Element;
    bar?: JSX.Element;
    body: JSX.Element;
    style?: React.CSSProperties;
}) {
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box>
                {header}
                {bar ? bar : <SeparationBar />}
            </Box>
            <Box
                sx={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 24px',
                    flexGrow: 1,
                    ...style,
                }}
            >
                {body}
            </Box>
        </Box>
    );
}
