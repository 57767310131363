import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import {
    CheckCircle,
    Dangerous,
    Dns,
    ReportProblem,
} from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import { Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import Alert from '@mui/material/Alert';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';

interface CloudConnectorParkingData {
    name: string;
    zoneId: number;
    subzones: ConfiguredSubzone[];
    peripheryConfigs: PeripheryConfig[];
}

interface ConfiguredSubzone {
    zoneBarrierGateSubzoneId: number;
    zoneId: number;
    name: string;
}

export interface PeripheryConfig {
    cloudConnectorId: string;
    onPremiseId: number;
    peripheryType: CloudConnectorPeripheryType;
    zoneBarrierGateSubzoneId: number | null;
    name: string;
    events: CloudConnectorPeripheryEvent[];
}

interface CloudConnectorPeripheryEvent {
    onPremiseId: number;
    peripheryType: CloudConnectorPeripheryType;
    eventType: CloudConnectorPerifheryEventType;
    category: CloudConnectorPeripheryStateEventTypeCategory;
}

export enum CloudConnectorPerifheryEventType {
    BARRIER_PERMANENTLY_OPEN = 'BARRIER_PERMANENTLY_OPEN',
    BARRIER_BREAK = 'BARRIER_BREAK',
    DEACTIVATED = 'DEACTIVATED',
    OUT_OF_ORDER = 'OUT_OF_ORDER',
    DISCONNECTED = 'DISCONNECTED',
    TICKET_ROLL_WARN = 'TICKET_ROLL_WARN',
    NO_TICKETS = 'NO_TICKETS',
    COIN_SYSTEM_ISSUE = 'COIN_SYSTEM_ISSUE',
    BILL_SYSTEM_ISSUE = 'BILL_SYSTEM_ISSUE',
    CREDIT_CARD_SYSTEM_ISSUE = 'CREDIT_CARD_SYSTEM_ISSUE',
    DOOR_OPEN = 'DOOR_OPEN',
    BREAK_IN_ALARM = 'BREAK_IN_ALARM',
}

enum CloudConnectorPeripheryStateEventTypeCategory {
    WARNING = 'WARNING',
    ALARM = 'ALARM',
}

enum PeripheryStatus {
    OKAY = 'OKAY',
    ERROR = 'ERROR',
    ALERT = 'ALERT',
}

function CloudConnectorPeripheries() {
    const { currentLogin } = useOperatorContext();
    const [refetchCounter, setRefetchCounter] = useState(0);
    const [cloudConnectorZoneListState] = useServerFetch<
        CloudConnectorParkingData[],
        { operatorId: number; refetchCounter: number }
    >(
        ({ operatorId }) => ({
            url: `/ui-api/operator-account/${operatorId}/cloud-connector/zone-list`,
        }),
        { operatorId: currentLogin.mandantId, refetchCounter: refetchCounter },
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setRefetchCounter(prevCount => prevCount + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    if (cloudConnectorZoneListState.status === RequestStatus.ERROR) {
        return (
            <Alert severity="error">
                <Localized
                    de="ERROR - Die Daten konnten nicht geladen werden."
                    fr="ERREUR - Les données n'ont pas pu être téléchargées."
                    it="ERRORE - Non è stato possibile caricare i dati."
                    en="ERROR - Data could not be loaded."
                />
            </Alert>
        );
    }

    if (
        cloudConnectorZoneListState.status === RequestStatus.NEVER_EXECUTED ||
        cloudConnectorZoneListState.data == null
    ) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <Box sx={{ paddingBottom: '24px' }}>
            {cloudConnectorZoneListState.data.map(parking => (
                <ParkingSegment parkingData={parking} key={parking.zoneId} />
            ))}
            <Outlet />
        </Box>
    );
}

export function CloudConnectorPeripheriesOverviewDesktop() {
    return (
        <MainContent>
            <HeaderWithButtons
                title={
                    <Localized
                        de="Geräte"
                        fr="Appareils"
                        it="Apparecchi"
                        en="Devices"
                    />
                }
                icon={Dns}
            />
            <CloudConnectorPeripheries />
        </MainContent>
    );
}

export function CloudConnectorPeripheriesOverviewMobile() {
    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Geräte"
                    fr="Appareils"
                    it="Apparecchi"
                    en="Devices"
                />
            }
            scrollable
            backTo={'..'}
        >
            <CloudConnectorPeripheries />
        </ParkingPortalLayoutWithHeader>
    );
}

function ParkingSegment({
    parkingData,
}: {
    parkingData: CloudConnectorParkingData;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '24px 24px 0px  24px',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    alignContent: 'center',
                    height: '30px',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: theme => theme.palette.white.main,
                    backgroundColor: theme => theme.palette.primary.main,
                }}
            >
                {parkingData.name}
            </Box>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    padding: '24px 24px 0px 12px',
                    backgroundColor: theme => theme.palette.blue.light,
                }}
                direction={{ sm: 'column', md: 'row' }}
                spacing={1}
            >
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.ENTRY,
                    )}
                />
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.PAY_STATION,
                    )}
                />
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.EXIT,
                    )}
                />
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.ACCESS,
                    )}
                />
            </Stack>
            <TransitSubzones
                configs={parkingData.peripheryConfigs.filter(
                    peripheryConfig =>
                        peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.TRANSIT_ENTRY ||
                        peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.TRANSIT_EXIT,
                )}
                subzones={parkingData.subzones}
            />
        </Box>
    );
}

function TransitSubzones({
    configs,
    subzones,
}: {
    configs: PeripheryConfig[];
    subzones: ConfiguredSubzone[];
}) {
    if (configs.length === 0) return null;

    return (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '0px 24px 0px 12px',
                backgroundColor: theme => theme.palette.blue.light,
            }}
        >
            {subzones.map(subzone => (
                <TransitSubzone
                    key={subzone.zoneBarrierGateSubzoneId}
                    configs={configs.filter(
                        peripheryConfig =>
                            (peripheryConfig.peripheryType ===
                                CloudConnectorPeripheryType.TRANSIT_ENTRY ||
                                peripheryConfig.peripheryType ===
                                    CloudConnectorPeripheryType.TRANSIT_EXIT) &&
                            peripheryConfig.zoneBarrierGateSubzoneId ==
                                subzone.zoneBarrierGateSubzoneId,
                    )}
                    subzone={subzone}
                />
            ))}
        </Stack>
    );
}

function TransitSubzone({
    configs,
    subzone,
}: {
    configs: PeripheryConfig[];
    subzone: ConfiguredSubzone;
}) {
    if (configs.length === 0) return null;

    return (
        <Box>
            <Box
                sx={{
                    fontSize: '20px',
                    paddingLeft: '12px',
                    fontWeight: 700,
                    color: theme => theme.palette.primary.main,
                }}
            >
                {subzone.name}
            </Box>
            <Stack
                sx={{
                    display: 'flex',
                    width: '100%',
                    backgroundColor: theme => theme.palette.blue.light,
                }}
                direction={{ sm: 'column', md: 'row' }}
            >
                {configs.map(peripheryConfig => (
                    <Box
                        key={`${peripheryConfig.zoneBarrierGateSubzoneId} - ${peripheryConfig.onPremiseId}`}
                        sx={theme => ({
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            paddingLeft: '12px',
                            paddingBottom: '12px',
                            [theme.breakpoints.up('md')]: { width: '25%' },
                        })}
                    >
                        <PeripheryDisplay
                            key={`${peripheryConfig.onPremiseId}-${peripheryConfig.peripheryType}`}
                            peripheryConfig={peripheryConfig}
                        />
                    </Box>
                ))}
            </Stack>
        </Box>
    );
}

function PeripheryColumn({ configs }: { configs: PeripheryConfig[] }) {
    if (configs.length === 0) return null;

    return (
        <Box
            sx={theme => ({
                display: 'flex',
                flexDirection: 'column',
                marginTop: '0px !important',
                marginLeft: '0px !important',
                width: '100%',
                paddingLeft: '12px',
                paddingBottom: '12px',
                [theme.breakpoints.up('md')]: { width: '25%' },
            })}
        >
            {configs.map(peripheryConfig => (
                <PeripheryDisplay
                    key={`${peripheryConfig.onPremiseId}-${peripheryConfig.peripheryType}`}
                    peripheryConfig={peripheryConfig}
                />
            ))}
        </Box>
    );
}

function PeripheryDisplay({
    peripheryConfig,
}: {
    peripheryConfig: PeripheryConfig;
}) {
    const mainEvent = getMainEventfromEvents(peripheryConfig.events);
    const navigate = useNavigate();
    const hasDetail = hasPeripheryDetail(peripheryConfig.peripheryType);
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '288px',
                height: '80px',
                borderRadius: '12px',
                marginBottom: '12px',
                cursor: hasDetail ? 'pointer' : 'default',
                overflow: 'hidden',
            }}
            onClick={
                hasDetail
                    ? () =>
                          navigate(
                              generatePath(
                                  ':cloudConnectorId/:peripheryType/:onPremiseId/detail',
                                  {
                                      cloudConnectorId:
                                          peripheryConfig.cloudConnectorId,
                                      peripheryType:
                                          peripheryConfig.peripheryType,
                                      onPremiseId:
                                          peripheryConfig.onPremiseId.toString(),
                                  },
                              ),
                          )
                    : undefined
            }
        >
            <StatusIconContent
                status={getPeripheryStatefromMainCategory(
                    mainEvent?.category ?? null,
                )}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingLeft: '24px',
                    backgroundColor: theme => theme.palette.common.white,
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: theme => theme.palette.primary.main,
                    }}
                >
                    <PeripheryDisplayName
                        peripheryType={peripheryConfig.peripheryType}
                        name={peripheryConfig.name}
                        onPremiseId={peripheryConfig.onPremiseId}
                    />
                </Box>
                <Box
                    sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: theme => theme.palette.primary.main,
                    }}
                >
                    {mainEvent && (
                        <EventTypeText eventType={mainEvent.eventType} />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

function hasPeripheryDetail(type: CloudConnectorPeripheryType): boolean {
    switch (type) {
        case CloudConnectorPeripheryType.ENTRY:
        case CloudConnectorPeripheryType.EXIT:
            return true;
        case CloudConnectorPeripheryType.PAY_STATION:
        case CloudConnectorPeripheryType.ACCESS:
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
            return false;
    }
}

function EventTypeText({
    eventType,
}: {
    eventType: CloudConnectorPerifheryEventType;
}): JSX.Element {
    switch (eventType) {
        case CloudConnectorPerifheryEventType.BARRIER_PERMANENTLY_OPEN:
            return (
                <Localized
                    de="Schranke dauerhaft geöffnet"
                    fr="Barrière ouverte en permanence"
                    it="Barriera permanentemente aperta"
                    en="Barrier permanently open"
                />
            );
        case CloudConnectorPerifheryEventType.BARRIER_BREAK:
            return (
                <Localized
                    de="Schranke defekt"
                    fr="Barrière cassée"
                    it="Barriera rotta"
                    en="Barrier broken"
                />
            );
        case CloudConnectorPerifheryEventType.DEACTIVATED:
            return (
                <Localized
                    de="Deaktiviert"
                    fr="Désactivé"
                    it="Disattivato"
                    en="Deactivated"
                />
            );
        case CloudConnectorPerifheryEventType.OUT_OF_ORDER:
            return (
                <Localized
                    de="Außer Betrieb"
                    fr="Hors service"
                    it="Fuori servizio"
                    en="Out of order"
                />
            );
        case CloudConnectorPerifheryEventType.DISCONNECTED:
            return (
                <Localized
                    de="Getrennt"
                    fr="Déconnecté"
                    it="Disconnesso"
                    en="Disconnected"
                />
            );
        case CloudConnectorPerifheryEventType.TICKET_ROLL_WARN:
            return (
                <Localized
                    de="Ticketrollenwarnung"
                    fr="Avertissement de rouleau de tickets"
                    it="Avviso di rotolo di biglietti"
                    en="Ticket roll warning"
                />
            );
        case CloudConnectorPerifheryEventType.NO_TICKETS:
            return (
                <Localized
                    de="Keine Tickets"
                    fr="Aucun ticket"
                    it="Nessun biglietto"
                    en="No tickets"
                />
            );
        case CloudConnectorPerifheryEventType.COIN_SYSTEM_ISSUE:
            return (
                <Localized
                    de="Problem mit dem Münzsystem"
                    fr="Problème avec le système de pièces"
                    it="Problema con il sistema delle monete"
                    en="Coin system issue"
                />
            );
        case CloudConnectorPerifheryEventType.BILL_SYSTEM_ISSUE:
            return (
                <Localized
                    de="Problem mit dem Banknotensystem"
                    fr="Problème avec le système de billets"
                    it="Problema con il sistema delle banconote"
                    en="Bill system issue"
                />
            );
        case CloudConnectorPerifheryEventType.CREDIT_CARD_SYSTEM_ISSUE:
            return (
                <Localized
                    de="Problem mit dem Kreditkartensystem"
                    fr="Problème avec le système de cartes de crédit"
                    it="Problema con il sistema delle carte di credito"
                    en="Credit card system issue"
                />
            );
        case CloudConnectorPerifheryEventType.DOOR_OPEN:
            return (
                <Localized
                    de="Tür geöffnet"
                    fr="Porte ouverte"
                    it="Porta aperta"
                    en="Door open"
                />
            );
        case CloudConnectorPerifheryEventType.BREAK_IN_ALARM:
            return (
                <Localized
                    de="Einbruchsalarm"
                    fr="Alarme d'intrusion"
                    it="Allarme di intrusione"
                    en="Break-in alarm"
                />
            );
    }
}

function getMainEventfromEvents(events: CloudConnectorPeripheryEvent[]) {
    const alarms = events.filter(
        event =>
            event.category ===
            CloudConnectorPeripheryStateEventTypeCategory.ALARM,
    );
    if (alarms.length > 0) {
        return alarms[0];
    }
    const warnings = events.filter(
        event =>
            event.category ===
            CloudConnectorPeripheryStateEventTypeCategory.WARNING,
    );
    if (warnings.length > 0) {
        return warnings[0];
    }
    return null;
}

function getPeripheryStatefromMainCategory(
    category: CloudConnectorPeripheryStateEventTypeCategory | null,
) {
    switch (category) {
        case CloudConnectorPeripheryStateEventTypeCategory.ALARM:
            return PeripheryStatus.ERROR;
        case CloudConnectorPeripheryStateEventTypeCategory.WARNING:
            return PeripheryStatus.ALERT;
        case null:
            return PeripheryStatus.OKAY;
    }
}

export function PeripheryDisplayName({
    peripheryType,
    onPremiseId,
    name,
}: {
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
    name: string | null;
}) {
    return (
        <>
            <PeripheryPrefix
                peripheryType={peripheryType}
                onPremiseId={onPremiseId}
            />
            {name ? ` - ${name}` : ''}
        </>
    );
}

function PeripheryPrefix({
    peripheryType,
    onPremiseId,
}: {
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
}) {
    switch (peripheryType) {
        case CloudConnectorPeripheryType.ENTRY:
            return (
                <Localized
                    de={`E${onPremiseId}`}
                    fr={`E${onPremiseId}`}
                    it={`E${onPremiseId}`}
                    en={`E${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.EXIT:
            return (
                <Localized
                    de={`A${onPremiseId}`}
                    fr={`S${onPremiseId}`}
                    it={`U${onPremiseId}`}
                    en={`E${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.PAY_STATION:
            return (
                <Localized
                    de={`K${onPremiseId}`}
                    fr={`C${onPremiseId}`}
                    it={`C${onPremiseId}`}
                    en={`P${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
            return (
                <Localized
                    de={`TE${onPremiseId}`}
                    fr={`TE${onPremiseId}`}
                    it={`TE${onPremiseId}`}
                    en={`TE${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
            return (
                <Localized
                    de={`TA${onPremiseId}`}
                    fr={`TS${onPremiseId}`}
                    it={`TU${onPremiseId}`}
                    en={`TE${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.ACCESS:
            return (
                <Localized
                    de={`Z${onPremiseId}`}
                    fr={`Z${onPremiseId}`}
                    it={`Z${onPremiseId}`}
                    en={`Z${onPremiseId}`}
                />
            );
    }
}

function getStatusColor(status: PeripheryStatus, theme: Theme): string {
    switch (status) {
        case PeripheryStatus.OKAY:
            return theme.palette.success.main;
        case PeripheryStatus.ALERT:
            return theme.palette.warning.main;
        case PeripheryStatus.ERROR:
            return theme.palette.error.main;
    }
}

function StatusIconContent({ status }: { status: PeripheryStatus }) {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '80px',
                width: '80px',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                backgroundColor: theme => getStatusColor(status, theme),
            }}
        >
            <StatusIcon status={status} />
        </Box>
    );
}

function StatusIcon({ status }: { status: PeripheryStatus }) {
    switch (status) {
        case PeripheryStatus.OKAY:
            return (
                <CheckCircle
                    sx={{
                        fontSize: '55px',
                        color: theme => theme.palette.common.white,
                    }}
                />
            );
        case PeripheryStatus.ALERT:
            return (
                <ReportProblem
                    sx={{
                        fontSize: '55px',
                        color: theme => theme.palette.common.white,
                    }}
                />
            );
        case PeripheryStatus.ERROR:
            return (
                <Dangerous
                    sx={{
                        fontSize: '55px',
                        color: theme => theme.palette.common.white,
                    }}
                />
            );
    }
}
