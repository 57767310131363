import moment from 'moment';
import {
    TomSynchronizationStatus,
    TomSyncReason,
} from 'dg-web-shared/model/Tom.ts';
import {
    Translation,
    TranslationWithArgs1,
    TranslationWithArgs2,
} from '../../common/i18n/Text.ts';
import { OperatorBadge } from './OperatorBadge.tsx';

export interface TomSyncStatusTexts {
    CurrentSynchronizationStatus: Translation;
    SynchronizationStatus: TranslationWithArgs1<TomSynchronizationStatus>;
    InSync: Translation;
    NeedsUpdate: Translation;
    Updating: Translation;
    Error: Translation;
    Reason: Translation;
    NewConfiguration: Translation;
    OutOfSpecialDays: Translation;
    OutOfHolidays: Translation;
    NotAllConfigsOnTom: Translation;
    NextUpdateNecessary: Translation;
    NoUpdateNeeded: Translation;
    UpdateBefore: TranslationWithArgs1<moment.Moment>;
    ConfigurationExpiryValue: TranslationWithArgs1<moment.Moment>;

    SyncUntil: TranslationWithArgs1<moment.Moment>;
    SyncReason: Translation;
    SyncReasonValue: TranslationWithArgs1<TomSyncReason>;
    SyncReasonNewTomConfig: Translation;
    SyncReasonNewZoneConfig: Translation;
    SyncReasonOutOfSpecialDays: Translation;
    SyncReasonOutOfHolidays: Translation;
    SyncReasonNotAllConfigsSynced: Translation;

    SyncReasonUntil: TranslationWithArgs2<TomSyncReason, moment.Moment>;
    SyncReasonNewTomConfigUntil: TranslationWithArgs1<moment.Moment>;
    SyncReasonNewZoneConfigUntil: TranslationWithArgs1<moment.Moment>;
    SyncReasonOutOfSpecialDaysUntil: TranslationWithArgs1<moment.Moment>;
    SyncReasonOutOfHolidaysUntil: TranslationWithArgs1<moment.Moment>;
    SyncReasonNotAllConfigsSyncedUntil: TranslationWithArgs1<moment.Moment>;
}

export function TomSyncStatus(props: {
    status: TomSynchronizationStatus;
}): JSX.Element {
    return (
        <OperatorBadge
            circular
            empty
            color={
                props.status === 'in_sync'
                    ? 'green'
                    : props.status === 'updating'
                      ? 'yellow'
                      : 'red'
            }
        />
    );
}
