import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function SubTitle({
    children,
    noMarginTop,
}: {
    children: React.ReactNode;
    noMarginTop?: boolean;
}) {
    return (
        <div
            className={css({
                ...Typo.captionC3,
                color: ColorHex.darkblue,
                marginTop: noMarginTop ? 0 : '24px',
            })}
        >
            {children}
        </div>
    );
}

export function Title({
    children,
    noMarginTop,
}: {
    children: React.ReactNode;
    noMarginTop?: boolean;
}) {
    return (
        <div
            className={css({
                ...Typo.captionC3,
                fontSize: '12.5px',
                color: ColorHex.darkblue,
                marginTop: noMarginTop ? 0 : '24px',
            })}
        >
            {children}
        </div>
    );
}
