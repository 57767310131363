export interface PermissionTag {
    tagId: number;
    operatorId: number;
    name: string;
}

export function parsePermissionTag(t: any): PermissionTag {
    return Object.assign(t, {
        tagId: t.tagId,
        operatorId: t.operatorId,
        name: t.name,
    });
}
