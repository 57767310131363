import { Translation } from '../../common/i18n/Text.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import {
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import { zoneTexts } from '../i18n/ZoneTexts.ts';
import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Body } from '../../ui/layout/TabContent.tsx';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { MountSlideIn, ZoneDetailSlideIn } from './ZoneDetailSlideIn.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import { isDefined, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { useState } from 'react';
import { css } from '@emotion/css';
import { Icon16 } from '../../ui/icons/Icon.tsx';

export interface ZoneTexts {
    Nr: Translation;
    NrLong: Translation;
    Name: Translation;
    AllowedTypes: Translation;
    EnforcementHubZoneId: Translation;
    NeedsPermit: Translation;
    PermitTypes: Translation;
    MinMaxParkTime: Translation;
    Vat: Translation;
    ParkType: Translation;
    HeaderCaption: Translation;
    StartStop: Translation;
    Duration: Translation;
    CarType: Translation;
    ZipCode: Translation;
    City: Translation;
    Account: Translation;
}

interface ItemProps {
    zone: MasterDataZonesState.MasterDataZone;
    language: string;
    hasParkingpayModule: boolean;
    showAccount: boolean;
    account: OperatorDataState.Account | null;
    setSelectedZoneId: (value: number | null) => void;
}

function ZoneItem(p: ItemProps): JSX.Element {
    const txt = zoneTexts[p.language];
    return (
        <DefaultTableRow
            onClick={() => {
                p.setSelectedZoneId(p.zone.id);
            }}
        >
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth._72px}>
                {p.zone.zoneCode}
            </TableColumn>
            <TableColumn width={ColumnWidth.variable}>
                {p.zone.zoneName}
            </TableColumn>
            <TableColumn width={ColumnWidth._108px}>
                {p.zone.zipCode}
            </TableColumn>
            <TableColumn width={ColumnWidth._136px}>{p.zone.city}</TableColumn>
            <Conditional c={p.hasParkingpayModule}>
                <TableColumn width={ColumnWidth._136px} captionFontSize>
                    {txt.CarType()}
                </TableColumn>
            </Conditional>
            {p.showAccount ? (
                <TableColumn width={ColumnWidth._136px} captionFontSize>
                    {isDefined(p.account) ? p.account.dtaText : '-'}
                </TableColumn>
            ) : null}
            <TableColumn width={ColumnWidth._24px} captionFontSize>
                <IconTopOffsetContainer>
                    <Icon16 icon={Icons16.chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </DefaultTableRow>
    );
}

export function IconTopOffsetContainer(p: { children?: React.ReactNode }) {
    return (
        <div
            className={css({
                marginTop: '4px',
            })}
        >
            {p.children}
        </div>
    );
}
export function ZoneListBody() {
    const { storeState } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        permitTypes: PermitTypeState.get(store),
        zones: MasterDataZonesState.get(store),
        referenceData: OperatorDataState.get(store),
    }));

    const [selectedZoneId, setSelectedZoneId] = useState<number | null>(null);

    function getDependingStateSlices(): AbstractLegacyServerState<any>[] {
        return [
            storeState.permitTypes,
            storeState.zones,
            storeState.referenceData,
        ];
    }

    const txt = zoneTexts[storeState.settings.language];
    const hasParkingpayModule = thenElse(
        storeState.referenceData.data,
        d => d.hasParkingpayModule,
        false,
    );
    const accounts = isDefined(storeState.referenceData.data)
        ? storeState.referenceData.data.billingProperties.accounts
        : [];
    return (
        <Body disabled={false} dependingStateSlices={getDependingStateSlices()}>
            <MountSlideIn>
                <ZoneDetailSlideIn
                    zoneId={selectedZoneId}
                    setSelectedZoneId={setSelectedZoneId}
                />
            </MountSlideIn>
            <Table>
                <TableHeader>
                    <EmptyTableHeaderColumn
                        width={ColumnWidth._24px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.Nr()}
                        onClick={null}
                        width={ColumnWidth._72px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.Name()}
                        direction={null}
                        onClick={null}
                        selected={false}
                        width={ColumnWidth.variable}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.ZipCode()}
                        direction={SortDirection.Ascending}
                        onClick={null}
                        selected={true}
                        width={ColumnWidth._108px}
                        delimiterBottom={true}
                    />
                    <TableHeaderColumn
                        name={txt.City()}
                        direction={SortDirection.Ascending}
                        onClick={null}
                        selected={true}
                        width={ColumnWidth._136px}
                        delimiterBottom={true}
                    />
                    <Conditional c={hasParkingpayModule}>
                        <TableHeaderColumn
                            name={txt.AllowedTypes()}
                            direction={null}
                            onClick={null}
                            selected={false}
                            width={ColumnWidth._136px}
                            delimiterBottom={true}
                        />
                    </Conditional>
                    <Conditional c={accounts.length > 1}>
                        <TableHeaderColumn
                            name={txt.Account()}
                            direction={null}
                            onClick={null}
                            selected={false}
                            width={ColumnWidth._136px}
                            delimiterBottom={true}
                        />
                    </Conditional>
                    <EmptyTableHeaderColumn
                        width={ColumnWidth._24px}
                        delimiterBottom={true}
                    />
                </TableHeader>
                <TableBody>
                    {storeState.zones.data
                        .filter(
                            z =>
                                !z.isFromForeignMandant &&
                                z.relevance.tag === 'ACTIVE',
                        )
                        .map((t: MasterDataZonesState.MasterDataZone) => {
                            const account = isDefined(
                                storeState.referenceData.data,
                            )
                                ? storeState.referenceData.data.billingProperties.accounts.find(
                                      account =>
                                          account.dtaAccountId ===
                                          t.dtaAccountId,
                                  )
                                : null;
                            return (
                                <ZoneItem
                                    zone={t}
                                    account={account || null}
                                    showAccount={accounts.length > 1}
                                    language={storeState.settings.language}
                                    hasParkingpayModule={hasParkingpayModule}
                                    key={t.id}
                                    setSelectedZoneId={setSelectedZoneId}
                                />
                            );
                        })}
                </TableBody>
            </Table>
        </Body>
    );
}
