import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Box, SvgIconTypeMap } from '@mui/material';
import { MaterialTitle } from '../layout/components/MaterialTitle.tsx';

export function HeaderWithButtons({
    title,
    icon,
    headerButtons,
}: {
    title: React.ReactNode;
    icon: OverridableComponent<SvgIconTypeMap> & {
        muiName: string;
    };
    headerButtons?: JSX.Element;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px 25px',
            }}
        >
            <MaterialTitle Icon={icon} title={title} />
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {headerButtons}
            </Box>
        </Box>
    );
}
