import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import * as ParkingsState from '../../common/state/ParkingsState.ts';
import {
    selectableOffstreetZone,
    selectableOnstreetZone,
    SelectableZone,
    ZonesSelectionSlideIn,
} from '../../ui/slidein/ZonesSelectionSlideIn.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';

interface ZoneFilterHalfSlideInProps {
    filter: number[];
    open: boolean;
    setSelection: (zoneIds: number[]) => void;
    onClose: () => void;
    filterZonesWithoutRelevanceTag?: boolean;
    showInfoText: boolean;
}

export function ZoneFilterHalfSlideIn(props: ZoneFilterHalfSlideInProps) {
    const { storeState } = useStore(s => ({
        zones: MasterDataZonesState.get(s),
        parkings: ParkingsState.get(s),
    }));
    const selectableZones = zones(
        storeState.zones,
        storeState.parkings,
        props.filterZonesWithoutRelevanceTag || false,
    );

    const selectedIds = props.filter.filter(selectedId =>
        selectableZones.map(zone => zone.id).includes(selectedId),
    );

    return (
        <ZonesSelectionSlideIn
            open={props.open}
            selectedIds={selectedIds}
            title={
                <Localized
                    de="Zonen / Parkings"
                    fr="Zones / Parkings"
                    it="Zone / Parcheggi"
                    en="Zones / Parkings"
                />
            }
            zones={selectableZones}
            onSelectedIdsChange={selection => props.setSelection(selection)}
            onClose={props.onClose}
            infoText={
                props.showInfoText &&
                selectedIds.length != selectableZones.length ? (
                    <Localized
                        de="Neue Zonen/Parkings werden nicht automatisch zugelassen."
                        fr="Les nouveaux zones/parkings ne sont pas automatiquement autorisés."
                        it="I nuovi zone/parcheggi non vengono autorizzati automaticamente."
                        en="New zones/parkings are not automatically allowed."
                    />
                ) : null
            }
        />
    );
}

export function zones(
    zones: MasterDataZonesState.State,
    parkings: ParkingsState.State,
    filterZonesWithoutRelevanceTag: boolean,
): SelectableZone[] {
    return zones.data
        .filter(z =>
            filterZonesWithoutRelevanceTag
                ? !z.isFromForeignMandant
                : !z.isFromForeignMandant &&
                  (z.relevance.tag ===
                      MasterDataZonesState.MasterDataZoneRelevanceTag.ACTIVE ||
                      (z.relevance.tag ===
                          MasterDataZonesState.MasterDataZoneRelevanceTag
                              .HAS_NON_ACTIVE_USAGES &&
                          z.relevance.hasCheckIns)) &&
                  z.transactionType !== null,
        )
        .map(selectableOnstreetZone)
        .concat(parkings.data.map(selectableOffstreetZone));
}
