import { css, keyframes } from '@emotion/css';

import { Colors } from 'dg-web-shared/ui/vars.ts';

const loaderbar = keyframes`
    0%, 10% {
        left: 0;
        right: 100%;
    }
    50% {
        left: 0;
        right: 0;
    }
    90%, 100% {
        right: 0;
        left: 100%;
    }
`;

export const LoaderBar = () => (
    <div
        className={css({
            width: '100%',
            height: 4,
            background: Colors.darkblue,
            position: 'relative',
            overflow: 'hidden',
        })}
    >
        <div
            className={css({
                position: 'absolute',
                height: '100%',
                background: Colors.lightblue,
                animation: `${loaderbar} 1.7s infinite ease-in-out`,
            })}
        />
    </div>
);
