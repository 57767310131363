import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { useState } from 'react';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { ZoneListBody } from './ZoneListBody.tsx';
import { Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { ZoneMapBody } from './ZoneMapBody.tsx';

enum ZoneTab {
    MAP = 'map',
    ZONE_LIST = 'zone list',
}

export function Zones() {
    const { storeState } = useStore(store => ({
        currentLogin: CurrentOperatorLoginState.get(store),
    }));

    const [activeTab, setActiveTab] = useState(ZoneTab.MAP);

    // if not logged in
    if (!storeState.currentLogin.data) {
        return null;
    }

    return (
        <Container>
            <Tabs>
                <TabItem
                    active={activeTab === ZoneTab.MAP}
                    description={
                        <Localized de="Karte" fr="Plan" it="Mappa" en="Map" />
                    }
                    onClick={() => {
                        setActiveTab(ZoneTab.MAP);
                    }}
                />
                <TabItem
                    active={activeTab === ZoneTab.ZONE_LIST}
                    description={
                        <Localized
                            de="Zonenliste"
                            fr="Liste des zones"
                            it="Lista zone"
                            en="Zone list"
                        />
                    }
                    onClick={() => {
                        setActiveTab(ZoneTab.ZONE_LIST);
                    }}
                />
            </Tabs>
            {activeTab === ZoneTab.MAP && <ZoneMapBody />}
            {activeTab === ZoneTab.ZONE_LIST && <ZoneListBody />}
        </Container>
    );
}
