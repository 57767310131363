import { css } from '@emotion/css';
import { Localized } from '../common/components/Localized.tsx';
import { NavLink } from 'react-router-dom';
import { ColorHex } from '../ui/Colors.ts';
import { OperatorTypo } from '../ui/OperatorTypo.ts';

export function StatisticsMovedInfo() {
    return (
        <div
            className={css({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                color: ColorHex.darkblue,
                ...OperatorTypo.headingOne,
            })}
        >
            <div className={css({ maxWidth: '600px', textAlign: 'center' })}>
                <Localized
                    de="Die Statistiken sind umgezogen. Die neue konsolidierte Version der Statistiken finden Sie unter "
                    fr="Les statistiques ont été déplacées ; la nouvelle version consolidée des statistiques se trouve dans "
                    it="Le statistiche hanno traslocato; la nuova versione consolidata delle statistiche si trova in "
                    en="The statistics have moved; the new consolidated version of the statistics can be found in "
                />
                <NavLink
                    to={'/statistics'}
                    className={css({
                        color: ColorHex.darkblue,
                        textDecoration: 'underline',
                        fontWeight: 'normal',
                    })}
                >
                    <Localized
                        de="Allgemein → Statistiken"
                        fr="Général → Statistiques"
                        it="Generale → Statistiche"
                        en="General → Statistics"
                    />
                </NavLink>
                .
            </div>
        </div>
    );
}
