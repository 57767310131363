import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as CollectionsActions from '../../actions/CollectionsActions.ts';
import { tomFilterTexts } from '../../i18n/TomTexts.ts';
import { TomFilter, TomFilterHalfSlideIn } from '../TomFilterHalfSlideIn.tsx';

const cn = ElementNamer('TomFilterTransactionListSlideIn');

interface State {
    settings: SettingsState.State;
    filter: TomCollectionsState.Filter.State;
}

export class TomFilterCollectionsListSlideIn extends Flux.Container<State> {
    static displayName: string = cn('');

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: TomCollectionsState.Filter.get(this.props.allState),
        };
    }

    render() {
        const texts = tomFilterTexts[this.state.settings.language];
        const filter: TomFilter = {
            selectedToms: this.state.filter.toms,
        };
        return (
            <TomFilterHalfSlideIn
                allState={this.props.allState}
                filter={filter}
                open={this.state.filter.tomSelectionVisible}
                setSelection={(tomIds: number[]) =>
                    this.update(store =>
                        CollectionsActions.setToms(store, tomIds),
                    )
                }
                onClose={() => this.update(CollectionsActions.closeTomFilter)}
                title={texts.filterTitle()}
            />
        );
    }
}
