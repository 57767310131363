import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import {
    NumberMultiSelectionSlideIn,
    Option,
} from '../../ui/slidein/MultiSelectionSlideIn.tsx';
import * as CustomerClearanceTexts from '../i18n/CustomerClearanceTexts.ts';
import * as CustomerClearanceState from '../state/CustomerClearanceState.ts';

const cn = ElementNamer('PermitTypeFilterSlideIn');

export interface PermitTypeSelectionTexts {
    slideInHeader: Translation;
}

export const setPermitTypes = (s: Flux.Store, pt: number[]): string => {
    CustomerClearanceState.Add.stateWrite(s, {
        permitTypeIds: pt,
    });
    return cn('setPermitTypes');
};

interface PermitTypeSelectionSlideInState {
    settings: SettingsState.State;
    permitTypes: PermitTypeState.State;
    add: CustomerClearanceState.Add.State;
}

export class PermitTypeSelectionSlideIn extends Flux.Container<PermitTypeSelectionSlideInState> {
    static displayName: string = cn('');

    stateSelector(): PermitTypeSelectionSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            permitTypes: PermitTypeState.get(this.props.allState),
            add: CustomerClearanceState.Add.get(this.props.allState),
        };
    }

    renderOptions(): Option<number>[] {
        return this.state.permitTypes.data
            .filter(pt => pt.isWhitelistCustomer)
            .map(pt => {
                return {
                    id: pt.id,
                    displayText: pt.description,
                };
            });
    }

    render() {
        return (
            <NumberMultiSelectionSlideIn
                open={!!this.state.add.permitTypeSelectionOpen}
                heading={CustomerClearanceTexts.permitTypeSelectionTexts[
                    this.state.settings.language
                ].slideInHeader()}
                selection={this.state.add.permitTypeIds || []}
                options={this.renderOptions()}
                onLeftActionClick={() =>
                    this.update(store =>
                        CustomerClearanceState.Add.stateWrite(store, {
                            permitTypeSelectionOpen: false,
                        }),
                    )
                }
                setNewSelection={v =>
                    this.update(store => setPermitTypes(store, v))
                }
            />
        );
    }
}
