import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { ResultsTable } from './ResultsTable.tsx';
import { Filter, List } from './ClearanceRequestsState.ts';
import { Guide } from './Guide.tsx';
import { NoRows } from '../ui/filter/FilterLayout.tsx';
import { ModalSpecialTabResultRefresher } from '../ui/layout/TabContent.tsx';

const filter = (store: Flux.Store) => Filter.get(store);
export const ClearanceRequestsList = Flux.selectState(
    (store, props: { pendingOnly?: boolean }) => ({
        settings: new SettingsState.StateSlice(store).state,
        clearanceRequests: List.get(
            store,
            props.pendingOnly
                ? Filter.pendingOnly
                : !Filter.filterActive(filter(store)) &&
                    !Filter.searchActive(filter(store))
                  ? null
                  : {
                        ...filter(store),
                    },
        ),
        filter: props.pendingOnly ? Filter.pendingOnly : Filter.get(store),
    }),
    p => {
        const clearanceRequestsRefresher = (
            <ModalSpecialTabResultRefresher
                onRefreshClick={() =>
                    p.update(store => List.refetchSameContext(store, true))
                }
                successRequest={
                    p.clearanceRequests.statusCode.statusCode === 200
                }
                pendingRequest={p.clearanceRequests.pending}
            />
        );

        if (!Filter.filterActive(p.filter) && !Filter.searchActive(p.filter)) {
            return <Guide {...p} />;
        }

        if (p.clearanceRequests.pending || !p.clearanceRequests.data) {
            return clearanceRequestsRefresher;
        }

        if (p.clearanceRequests.data.length < 1) {
            return (
                <>
                    {clearanceRequestsRefresher}
                    <NoRows lang={p.settings.language} />
                </>
            );
        }

        return (
            <>
                {clearanceRequestsRefresher}
                <ResultsTable
                    {...p}
                    clearanceRequests={p.clearanceRequests.data}
                />
            </>
        );
    },
);
