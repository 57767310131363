import moment from 'moment';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Filter } from './ClearanceRequestsState.ts';
import {
    toDateConstraint,
    fromDateConstraint,
} from '../clearance-permit-list/actions/shared.ts';

export const setValidFrom = (s: Flux.Store, d: moment.Moment) => {
    Filter.stateWrite(s, {
        dateFrom: d,
        dateTo: toDateConstraint(d, Filter.get(s).dateTo) || null,
    });
    return 'clearance-requests.setValidFrom';
};

export const setValidTo = (s: Flux.Store, d: moment.Moment) => {
    Filter.stateWrite(s, {
        dateTo: d,
        dateFrom: fromDateConstraint(d, Filter.get(s).dateFrom) || null,
    });
    return 'clearance-requests.setValidTo';
};

export const clearValidDate = (s: Flux.Store) => {
    Filter.stateWrite(s, {
        dateFrom: null,
        dateTo: null,
    });
    return 'clearance-requests.clearValidDate';
};
