import moment from 'moment';
import * as Date from '../lib/Date';
import { Maybe } from '../lib/MaybeV2';

export interface TomCollection {
    collectionId: number;
    tomId: number;
    tomExternalOperatorId: string | null;
    tomName: string;
    collectionTime: moment.Moment;
    stickDescription: number;
    collectionStickId: number;
    collectionNumber: number;
    transactionCount: number;
    collectedAmount: number;
    collectedAmountChf: number;
    collectedAmountEuro: number;
    collectedAmountToken: number;
    rejectedCoinsCount: number;
    batteryVoltage: number;
    validCoinsCount?: Maybe<number>; // used to distinguish version
}

export interface TomCollectionV2 extends TomCollection {
    validCoinsCount: number;
    chf_005Count: number;
    chf_010Count: number;
    chf_020Count: number;
    chf_050Count: number;
    chf_100Count: number;
    chf_200Count: number;
    chf_500Count: number;
    eur_010Count: number;
    eur_020Count: number;
    eur_050Count: number;
    eur_100Count: number;
    eur_200Count: number;
    tk_1Count: number;
    tk_2Count: number;
    tk_3Count: number;
}

export function parseTomCollection(t: any): TomCollection {
    return Object.assign(t, {
        collectionTime: Date.Parser.isoToMoment(t.collectionTime),
    });
}
