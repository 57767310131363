import { DateTime } from 'luxon';

interface TomTransactionUnparsed
    extends Omit<TomTransaction, 'startTime' | 'expirationTime'> {
    startTime: string;
    expirationTime: string;
}

export interface TomTransaction {
    transactionId: number;
    tomId: number;
    transactionNumber: number;
    label: string;
    tariffId: number;
    inheritedTime: number;
    paidAmount: number;
    paidAmountChf: number;
    paidAmountEuro: number;
    paidAmountToken: number;
    startTime: DateTime;
    expirationTime: DateTime;
    awardedInheritedTime: number;
    maximumParkingTimeReached: boolean;
}

export function parseTomTransaction(t: TomTransactionUnparsed): TomTransaction {
    return {
        ...t,
        startTime: DateTime.fromISO(t.startTime),
        expirationTime: DateTime.fromISO(t.expirationTime),
    };
}
