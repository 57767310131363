import { Box, Divider, Menu, MenuItem, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsVariantOverrides } from '@mui/material/Button/Button';
import React, { useState } from 'react';

export type DropdownItem = Item | Divide;

interface Item {
    key: string;
    label: JSX.Element;
    tooltip?: JSX.Element;
    onClickCallback: () => void;
    disabled: boolean;
    divider?: false;
    sx?: SxProps;
}

interface Divide {
    key: string;
    divider: true;
}

export function ButtonWithDropdown({
    label,
    isLoading,
    dropdownItems,
    variant = 'contained',
    startIcon,
    itemVariant,
}: {
    label: React.ReactNode;
    isLoading?: boolean;
    dropdownItems: DropdownItem[];
    variant?: OverridableStringUnion<
        'text' | 'outlined' | 'contained',
        ButtonPropsVariantOverrides
    >;
    itemVariant?: 'action' | 'select';
    startIcon?: React.ReactNode;
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <LoadingButton
                variant={variant}
                loading={isLoading}
                disableElevation
                onClick={handleClick}
                startIcon={startIcon}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {label}
            </LoadingButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {dropdownItems.map(item => {
                    if (item.divider) {
                        return <Divider key={item.key} />;
                    } else {
                        if (item.tooltip) {
                            return (
                                <Tooltip
                                    key={item.key}
                                    title={item.tooltip}
                                    placement="right"
                                >
                                    <MenuItem
                                        onClick={() => {
                                            if (!item.disabled) {
                                                handleClose();
                                                item.onClickCallback();
                                            }
                                        }}
                                        disabled={item.disabled}
                                        style={{ pointerEvents: 'auto' }}
                                        disableRipple
                                    >
                                        <ItemBox itemVariant={itemVariant}>
                                            {item.label}
                                        </ItemBox>
                                    </MenuItem>
                                </Tooltip>
                            );
                        }
                        return (
                            <MenuItem
                                sx={item.sx}
                                key={item.key}
                                onClick={() => {
                                    handleClose();
                                    item.onClickCallback();
                                }}
                                disabled={item.disabled}
                                disableRipple
                            >
                                <ItemBox itemVariant={itemVariant}>
                                    {item.label}
                                </ItemBox>
                            </MenuItem>
                        );
                    }
                })}
            </Menu>
        </>
    );
}

function ItemBox({
    children,
    itemVariant,
}: {
    children: React.ReactNode;
    itemVariant?: 'action' | 'select';
}) {
    return (
        <Box
            sx={{
                color: theme => theme.palette.blue.main,
                textTransform:
                    itemVariant == 'action' ? 'uppercase' : undefined,
                fontWeight: '500',
                fontSize: itemVariant == 'action' ? '15px' : undefined,
            }}
        >
            {children}
        </Box>
    );
}
