import {
    ColumnWidth,
    EmptyTableHeaderColumn,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
    TableRow,
} from '../ui/table/Table.tsx';
import * as EnforcementLogState from './EnforcementLogState.ts';
import { ResponseResult } from './EnforcementLogState.ts';
import { Updater } from 'dg-web-shared/lib/Flux.tsx';

import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';
import { NoRows, TooManyRows } from '../ui/filter/FilterLayout.tsx';
import { IconTopOffsetContainer } from '../zones/components/ZoneListBody.tsx';
import { css } from '@emotion/css';
import { OperatorTypo } from '../ui/OperatorTypo.ts';
import { ColorHex } from '../ui/Colors.ts';
import { Localized } from '../common/components/Localized.tsx';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { Icon16 } from '../ui/icons/Icon.tsx';
import { chevronRight } from 'dg-web-shared/ui/icons/Icons16.tsx';
import { makeLocalizedText } from 'dg-web-shared/lib/Localized.ts';

interface Props {
    data: EnforcementLogState.List.Data;
    update: Updater;
}

const ResultsTableHeader = () => (
    <TableHeader>
        <EmptyTableHeaderColumn
            width={ColumnWidth._48px}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={
                <Localized
                    de="Kontrollzeit"
                    fr="Heure du contrôle"
                    it="Ora del controllo"
                    en="Control time"
                />
            }
            width={ColumnWidth._192px}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={
                <Localized
                    de="Kennzeichen"
                    fr="Immatriculation"
                    it="Targa"
                    en="License plate"
                />
            }
            width={ColumnWidth._192px}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={<Localized de="Zone" fr="Zone" it="Zona" en="Zone" />}
            width={ColumnWidth.variable}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={<Localized de="Ort" fr="Localité" it="Località" en="Place" />}
            width={ColumnWidth._136px}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={
                <Localized
                    de="Kontrolleur"
                    fr="Contrôleur"
                    it="Controllore"
                    en="Controller"
                />
            }
            width={ColumnWidth._192px}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={
                <Localized
                    de="Resultat"
                    fr="Résultat"
                    it="Risultato"
                    en="Resultat"
                />
            }
            width={ColumnWidth._72px}
            delimiterBottom={false}
        />
        <EmptyTableHeaderColumn
            width={ColumnWidth._48px}
            delimiterBottom={false}
        />
    </TableHeader>
);

const rowColorStyle = (responseResult: ResponseResult) => {
    switch (responseResult) {
        case ResponseResult.POSITIVE:
            return {
                backgroundColor: ColorHex.rgba(ColorHex.bActive, 0.1),
                '&:hover': {
                    backgroundColor: ColorHex.rgba(ColorHex.bActive, 0.2),
                },
            };
        case ResponseResult.FUZZY_POSITIVE:
            return {
                backgroundColor: ColorHex.rgba(ColorHex.fPendent, 0.1),
                '&:hover': {
                    backgroundColor: ColorHex.rgba(ColorHex.fPendent, 0.2),
                },
            };
        case ResponseResult.NEGATIVE:
            return {
                backgroundColor: ColorHex.rgba(ColorHex.bCanceled, 0.1),
                '&:hover': {
                    backgroundColor: ColorHex.rgba(ColorHex.bCanceled, 0.2),
                },
            };
    }
};

export function enforcementLogResponseString(result: ResponseResult): string {
    const localize = makeLocalizedText(useOperatorLanguage());
    switch (result) {
        case ResponseResult.POSITIVE:
            return localize({
                de: 'positiv',
                fr: 'positif',
                it: 'positivo',
                en: 'positive',
            });
        case ResponseResult.FUZZY_POSITIVE:
            return localize({
                de: 'vielleicht positiv',
                fr: 'peut-être positif',
                it: 'forse positivo',
                en: 'maybe positive',
            });
        case ResponseResult.NEGATIVE:
            return localize({
                de: 'negativ',
                fr: 'négatif',
                it: 'negativo',
                en: 'negative',
            });
    }
}

const Item = ({
    item,
    onClick,
}: {
    item: EnforcementLogState.EnforcementItem;
    onClick: () => void;
}): JSX.Element => {
    return (
        <TableRow
            className={css({
                ...rowColorStyle(item.responseResult),
                ...OperatorTypo.body,
                color: ColorHex.darkblue,
            })}
            onClick={onClick}
        >
            <TableColumn width={ColumnWidth._48px} />
            <TableColumn width={ColumnWidth._192px}>
                {Formatter.dayMonthYearHourMinuteSecond(
                    Parser.isoToMoment(item.enforcementTime),
                )}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px}>
                {item.identification}
            </TableColumn>
            <TableColumn width={ColumnWidth.variable}>
                {item.zoneName}
            </TableColumn>
            <TableColumn width={ColumnWidth._136px}>
                {item.locationName}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px}>
                {item.enforcerName}
            </TableColumn>
            <TableColumn width={ColumnWidth._72px}>
                {enforcementLogResponseString(item.responseResult)}
            </TableColumn>
            <TableColumn width={ColumnWidth._48px}>
                <IconTopOffsetContainer>
                    <Icon16 icon={chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </TableRow>
    );
};

const ResultsTableBody = (p: Props) => {
    return (
        <TableBody>
            {p.data.data.map(t => (
                <Item
                    key={t.id}
                    item={t}
                    onClick={() =>
                        p.update(store =>
                            EnforcementLogState.Layout.stateWrite(store, {
                                selectedItem: t.id,
                            }),
                        )
                    }
                />
            ))}
        </TableBody>
    );
};

export const EnforcementLogResultList = (p: Props): JSX.Element => {
    const language = useOperatorLanguage();
    if (p.data.length === 0) {
        return <NoRows lang={language} />;
    } else if (p.data.length > 0 && p.data.data.length === 0) {
        return <TooManyRows lang={language} />;
    }
    return (
        <Table>
            <ResultsTableHeader />
            <ResultsTableBody {...p} />
        </Table>
    );
};
