import { Translation } from '../../../common/i18n/Text.ts';
import { identifierTexts } from '../../i18n/IdentifierTexts.ts';
import { LicensePlate } from 'dg-web-shared/dto/LicensePlate.ts';
import { Badge } from '../../state/FilteredPermitsState.ts';

export interface IdentifierTexts {
    licenseplate: Translation;
    licenseplates: Translation;
    badge: Translation;
    badges: Translation;
}

const texts = (l: string) => identifierTexts[l];

const description = (hasBadges: boolean, lp: Translation, b: Translation) =>
    lp() + (hasBadges ? ` / ${b()}` : '');

export const getDescriptionSingle = (hasBadges: boolean, l: string) =>
    description(hasBadges, texts(l).licenseplate, texts(l).badge);

export const getDescriptionMany = (hasBadges: boolean, l: string) =>
    description(hasBadges, texts(l).licenseplates, texts(l).badges);

export const formatAsLine = (
    lp: LicensePlate[],
    b: Badge[],
    multipleText: Translation,
) =>
    lp.length + b.length > 1
        ? multipleText()
        : lp.length === 1
          ? lp[0].licensePlateNr
          : b.length === 1
            ? b[0].labelNr
            : '';
