import { OperatorLoginType } from '../../app/state/OperatorLoginType.ts';
import {
    Form,
    FormValidation,
    FormValidators,
} from 'dg-web-shared/lib/FormValidation.ts';
import {
    getOrElse,
    isDefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { ApiError } from 'dg-web-shared/dto/ApiError.ts';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';

export namespace Validation {
    export namespace LoginForm {
        function validators(
            maxAnonymizeCheckinDataAfterWeeks: number,
        ): Form<OperatorLoginsState.Edit.State> {
            return {
                firstName: [FormValidators.NotEmpty],
                lastName: [FormValidators.NotEmpty],
                username: [FormValidators.NotEmpty],
                email: [FormValidators.EmailValid],
                softAnonymizeCheckinPublicPermitAfterDays: [
                    FormValidators.NotEmpty,
                    FormValidators.Minimum(0),
                    FormValidators.Maximum(maxAnonymizeCheckinDataAfterWeeks),
                ],
            };
        }

        interface Props {
            login: Maybe<CommonOperatorLoginsState.OperatorLogin>;
            edit: OperatorLoginsState.Edit.State;
            editCreateResponse: OperatorLoginsState.EditCreateResponse.State;
            settings: SettingsState.State;
            maxAnonymizeCheckinPublicPermitAfterDays: number;
        }

        export const getFieldValues = (p: {
            login: Maybe<CommonOperatorLoginsState.OperatorLogin>;
            edit: OperatorLoginsState.Edit.State;
            maxAnonymizeCheckinPublicPermitAfterDays: number;
        }) => ({
            firstName: getOrElse(
                p.edit.firstName,
                thenElse(p.login, l => l.firstName, ''),
            ),
            lastName: getOrElse(
                p.edit.lastName,
                thenElse(p.login, l => l.lastName, ''),
            ),
            username: getOrElse(
                p.edit.username,
                thenElse(p.login, l => l.username, ''),
            ),
            email: getOrElse(
                p.edit.email,
                thenElse(p.login, l => l.email, ''),
            ) as string,
            softAnonymizeCheckinPublicPermitAfterDays:
                p.edit.softAnonymizeCheckinPublicPermitAfterDays === null
                    ? p.login
                        ? String(
                              p.login.softAnonymizeCheckinPublicPermitAfterDays,
                          )
                        : String(p.maxAnonymizeCheckinPublicPermitAfterDays)
                    : p.edit.softAnonymizeCheckinPublicPermitAfterDays,
        });

        export const validateForm = (p: Props) => {
            const isUserAdmin =
                p.login &&
                (p.login.operatorLoginType === OperatorLoginType.Admin ||
                    p.login.permissions.userPermissionEdit);

            const wasParkingMeterAlertsEnabled =
                p.edit && p.edit.permParkingMeterAlerts === true;

            const wasParkingMeterAlertsDisabled =
                p.edit && p.edit.permParkingMeterAlerts === false;

            const isParkingMeterAlertsAlreadyEnabled =
                p.login &&
                p.login.permissions &&
                p.login.permissions.parkingMeterAlerts === true;

            const validatorsWithMailRequired = Object.assign(
                {},
                validators(p.maxAnonymizeCheckinPublicPermitAfterDays),
                { email: [FormValidators.NotEmpty, FormValidators.EmailValid] },
            );

            const validatorsWithoutMailRequired = validators(
                p.maxAnonymizeCheckinPublicPermitAfterDays,
            );

            return FormValidation.validate(
                {
                    ...getFieldValues({
                        edit: p.edit,
                        login: p.login,
                        maxAnonymizeCheckinPublicPermitAfterDays:
                            p.maxAnonymizeCheckinPublicPermitAfterDays,
                    }),
                },
                // Setting eMail required or not
                isUserAdmin
                    ? validatorsWithMailRequired
                    : wasParkingMeterAlertsEnabled
                      ? validatorsWithMailRequired
                      : wasParkingMeterAlertsDisabled
                        ? validatorsWithoutMailRequired
                        : isParkingMeterAlertsAlreadyEnabled
                          ? validatorsWithMailRequired
                          : validators(
                                p.maxAnonymizeCheckinPublicPermitAfterDays,
                            ),
                p.settings.language,
            );
        };
        export const usernameIsDuplicate = (p: Props) =>
            p.editCreateResponse.statusCode.badRequest &&
            isDefined(p.editCreateResponse.errorData) &&
            (p.editCreateResponse.errorData.message as ApiError) ===
                'DUPLICATE_OPERATOR_LOGIN_USERNAME';
    }

    export namespace PasswordForm {
        const validators = (
            s: OperatorLoginsState.PasswordEdit.State,
        ): Form<OperatorLoginsState.PasswordEdit.State> => ({
            password1: [FormValidators.NotEmpty],
            password2: [
                FormValidators.NotEmpty,
                FormValidators.Equals(s.password1),
            ],
        });
        export const validateForm = (p: {
            settings: SettingsState.State;
            passwordEdit: OperatorLoginsState.PasswordEdit.State;
        }) =>
            FormValidation.validate(
                p.passwordEdit,
                validators(p.passwordEdit),
                p.settings.language,
            );
    }
}
