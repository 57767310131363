export namespace Colors {
    export const lightBlue = '#3588DB';
    export const darkBlue = '#1E2B40';
    export const red = '#B40000';
    export const green = '#468033';
    export const grey = '#606060';
    export const greyBackground = '#EDEDED';
    export const white = '#FFFFFF';
    export const black = '#000000';
    export const yellow = '#FFAE00';
    export const lightYellow = 'rgba(255, 174, 0, 0.5)';
    export const border = '#95989A';
    export const error = '#A60000';
    export const infoCaption = '#AECFF1';
    export const infoText = '#D7E7F8';
}

export namespace Typo {
    export const robotoLight = {
        fontFamily: 'Roboto',
        fontWeight: 300,
    };

    export const robotoRegular = {
        fontFamily: 'Roboto',
        fontWeight: 400,
    };

    export const robotoMedium = {
        fontFamily: 'Roboto',
        fontWeight: 500,
    };

    export const robotoMono = {
        fontFamily: "'Roboto Mono', monospace",
        fontWeight: 500,
    };

    export const label = {
        ...robotoRegular,
        fontSize: '11.5px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
    };

    export const caption = {
        ...robotoMedium,
        fontSize: '10.5px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
    };
}
