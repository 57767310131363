import { Tooltip } from '@mui/material';
import { Localized } from '../common/components/Localized.tsx';
import {
    CheckCircleOutline,
    HighlightOff,
    RemoveCircleOutline,
    Schedule,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import { Message } from 'dg-web-shared/lib/Localized.ts';

export const enum OperatorProductStatus {
    ACTIVE = 'ACTIVE',
    FUTURE = 'FUTURE',
    EXPIRED = 'EXPIRED',
    STORNOED = 'STORNOED',
}

export function OperatorProductStatusIcon({
    status,
}: {
    status: OperatorProductStatus;
}): JSX.Element {
    switch (status) {
        case OperatorProductStatus.ACTIVE:
            return (
                <ProductsStateTooltip
                    title={
                        <Localized
                            de="gültig"
                            fr="valable"
                            it="valido"
                            en="valid"
                        />
                    }
                >
                    <CheckCircleOutline color="success" />
                </ProductsStateTooltip>
            );
        case OperatorProductStatus.FUTURE:
            return (
                <ProductsStateTooltip
                    title={
                        <Localized
                            de="zukünftig gültig"
                            fr="Valable dans le futur"
                            it="valido in futuro"
                            en="Valid in the future"
                        />
                    }
                >
                    <Schedule color="warning" />
                </ProductsStateTooltip>
            );
        case OperatorProductStatus.EXPIRED:
            return (
                <ProductsStateTooltip
                    title={
                        <Localized
                            de="abgelaufen"
                            fr="expirée"
                            it="scaduta"
                            en="expired"
                        />
                    }
                >
                    <RemoveCircleOutline color="disabled" />
                </ProductsStateTooltip>
            );
        case OperatorProductStatus.STORNOED:
            return (
                <ProductsStateTooltip
                    title={
                        <Localized
                            de="storniert"
                            fr="annulée"
                            it="annullata"
                            en="cancelled"
                        />
                    }
                >
                    <HighlightOff color="error" />
                </ProductsStateTooltip>
            );
    }
}

export function generateProductStateText(
    status: OperatorProductStatus,
): Message {
    switch (status) {
        case OperatorProductStatus.ACTIVE:
            return { de: 'Gültig', fr: 'Valable', it: 'Valido', en: 'Valid' };
        case OperatorProductStatus.FUTURE:
            return {
                de: 'Zukünftig gültig',
                fr: 'Valable dans le futur',
                it: 'Valido in futuro',
                en: 'Valid in the future',
            };
        case OperatorProductStatus.EXPIRED:
            return {
                de: 'Abgelaufen',
                fr: 'Expirée',
                it: 'Scaduta',
                en: 'Expired',
            };
        case OperatorProductStatus.STORNOED:
            return {
                de: 'Storniert',
                fr: 'Annulée',
                it: 'Annullata',
                en: 'Cancelled',
            };
    }
}

function ProductsStateTooltip({
    title,
    children,
}: {
    title: React.ReactChild | React.ReactFragment | React.ReactPortal;
    children: React.ReactElement;
}) {
    return (
        <Tooltip title={title} placement="right">
            {children}
        </Tooltip>
    );
}

/**
 * This function calculates the product state based on the product properties. We would like to do this calculation
 * only in the backend, but there was no time to refactor the subscriber part, so we keep it like this for now.
 */
export function determineProductStatus(
    isStorno: boolean,
    validFrom: DateTime,
    validTo: DateTime | null,
): OperatorProductStatus {
    if (isStorno) {
        return OperatorProductStatus.STORNOED;
    }
    if (validFrom > DateTime.now()) {
        return OperatorProductStatus.FUTURE;
    }
    if (validTo && validTo < DateTime.now()) {
        return OperatorProductStatus.EXPIRED;
    }
    return OperatorProductStatus.ACTIVE;
}
