import { Translation, Translations } from '../../common/i18n/Text.ts';
import * as PermitCreateValidityDurationSlideIn from '../components/PermitCreateValidityDurationSlideIn.tsx';
import * as PermitCreateValidityFromDateSlideIn from '../components/PermitCreateValidityFromDateSlideIn.tsx';
import * as PermitCreateValidityToDateSlideIn from '../components/PermitCreateValidityToDateSlideIn.tsx';
import * as PermitTimeUnitHelpers from '../components/PermitTimeUnitHelpers.ts';
import * as PermitTypeSelectionSlideIn from '../components/PermitTypeSelectionSlideIn.tsx';
import * as VignetteAmountSlideIn from '../components/VignetteAmountSlideIn.tsx';
import * as PermitSlideInTexts from './PermitSlideInTexts.ts';

export const texts: Translations<{
    HeaderCaption: Translation;
    StartDate: Translation;
    Duration: Translation;
    EndDate: Translation;
    ValidZones: Translation;
    Price: Translation;
    FixedZones: (zoneCount: number) => string;
    VignetteToInvoice: Translation;
    Created: Translation;
    PriceNotAvailable: Translation;
    DateNotAvailable: Translation;
    ValidityIntervalMissing: Translation;
    PermitTypeMissing: Translation;
    MissingZone: Translation;
    Address: Translation;
    UntilRecall: Translation;
}> = {
    de: {
        EndDate: () => PermitSlideInTexts.body.de.EndDate(),
        HeaderCaption: () => 'Bewilligung erfassen',
        StartDate: () => PermitSlideInTexts.body.de.StartDate(),
        Duration: () => 'Dauer',
        ValidZones: () => PermitSlideInTexts.body.de.ValidZones(),
        Price: () => PermitSlideInTexts.body.de.Price(),
        FixedZones: (zoneCount: number) =>
            PermitSlideInTexts.body.de.FixedZones(zoneCount),
        Created: () => 'Ausgestellt',
        VignetteToInvoice: () => 'Zu verechnende Vignette(n)',
        PriceNotAvailable: () => 'noch nicht berechenbar',
        DateNotAvailable: () => 'wählen Sie eine Dauer aus',
        ValidityIntervalMissing: () =>
            'Wählen Sie den Gültigkeitszeitraum der Bewilligung',
        PermitTypeMissing: () => 'Bitte wählen Sie eine Bewilligunsart',
        MissingZone: () => 'MasterDataZone wählen',
        Address: () => 'Adresse',
        UntilRecall: () => 'auf Widerruf',
    },
    fr: {
        Created: () => 'Emise',
        DateNotAvailable: () => 'sélectionner une durée',
        Duration: () => 'Durée',
        EndDate: () => PermitSlideInTexts.body.fr.EndDate(),
        FixedZones: (zoneCount: number) =>
            PermitSlideInTexts.body.fr.FixedZones(zoneCount),
        HeaderCaption: () => 'Saisir autorisation',
        Price: () => PermitSlideInTexts.body.fr.Price(),
        PriceNotAvailable: () => 'pas encore chiffrable',
        StartDate: () => PermitSlideInTexts.body.fr.StartDate(),
        ValidityIntervalMissing: () =>
            "Selectionner la période de validité de l'autorisation",
        ValidZones: () => PermitSlideInTexts.body.fr.ValidZones(),
        VignetteToInvoice: () => 'Vignette(s) à encaisser',
        PermitTypeMissing: () => "Sélectionner un type d'autorisation",
        MissingZone: () => 'sélectionner une zone',
        Address: () => 'Adresse',
        UntilRecall: () => "jusqu'à révocation",
    },
    it: {
        Created: () => 'Emessa',
        DateNotAvailable: () => 'selezionare una durata',
        Duration: () => 'Durata',
        EndDate: () => PermitSlideInTexts.body.it.EndDate(),
        FixedZones: (zoneCount: number) =>
            PermitSlideInTexts.body.it.FixedZones(zoneCount),
        HeaderCaption: () => 'Registrare autorizzazione',
        Price: () => PermitSlideInTexts.body.it.Price(),
        PriceNotAvailable: () => 'non ancora calcolabile',
        StartDate: () => PermitSlideInTexts.body.it.StartDate(),
        ValidityIntervalMissing: () =>
            "Selezionare il periodo di validità dell'autorizzazione",
        ValidZones: () => PermitSlideInTexts.body.it.ValidZones(),
        VignetteToInvoice: () => 'Vignetta/e da fatturare',
        PermitTypeMissing: () => "Selezionare un tipo d'autorizzazione",
        MissingZone: () => 'selezionare una zona',
        Address: () => 'Adresse',
        UntilRecall: () => 'a revoca',
    },
};

export const timeUnitTexts: Translations<PermitTimeUnitHelpers.Texts> = {
    de: {
        Hours: (i: number) => (i === 1 ? `${i} Stunde` : `${i} Stunden`),
        Days: (i: number) => (i === 1 ? `${i} Tag` : `${i} Tage`),
        Weeks: (i: number) => (i === 1 ? `${i} Woche` : `${i} Wochen`),
        Months: (i: number) => (i === 1 ? `${i} Monat` : `${i} Monate`),
        Years: (i: number) => (i === 1 ? `${i} Jahr` : `${i} Jahre`),
    },
    fr: {
        Hours: (i: number) => (i === 1 ? `${i} heur` : `${i} heures`),
        Days: (i: number) => (i === 1 ? `${i} jour` : `${i} jours`),
        Months: (i: number) => (i === 1 ? `${i} mois` : `${i} mois`),
        Weeks: (i: number) => (i === 1 ? `${i} semaine` : `${i} semaines`),
        Years: (i: number) => (i === 1 ? `${i} année` : `${i} années`),
    },
    it: {
        Hours: (i: number) => (i === 1 ? `${i} ora` : `${i} ore`),
        Days: (i: number) => (i === 1 ? `${i} giorno` : `${i} giorni`),
        Months: (i: number) => (i === 1 ? `${i} mese` : `${i} mesi`),
        Weeks: (i: number) => (i === 1 ? `${i} settimana` : `${i} settimane`),
        Years: (i: number) => (i === 1 ? `${i} anno` : `${i} anni`),
    },
};

export const validityFromDate: Translations<PermitCreateValidityFromDateSlideIn.Texts> =
    {
        de: {
            FromCaption: () => 'Beginn',
        },
        fr: {
            FromCaption: () => 'Début',
        },
        it: {
            FromCaption: () => 'Inizio',
        },
    };

export const validityToDate: Translations<PermitCreateValidityToDateSlideIn.Texts> =
    {
        de: {
            ToCaption: () => 'Ende',
        },
        fr: {
            ToCaption: () => 'Fin',
        },
        it: {
            ToCaption: () => 'Fine',
        },
    };

export const validityDuration: Translations<PermitCreateValidityDurationSlideIn.Texts> =
    {
        de: {
            DurationCaption: () => 'Dauer',
        },
        fr: {
            DurationCaption: () => 'Durée',
        },
        it: {
            DurationCaption: () => 'Durata',
        },
    };

export const permitTypeSelection: Translations<PermitTypeSelectionSlideIn.Texts> =
    {
        de: {
            HeaderCaption: () => 'Wählen Sie die Bewilligungsart',
            HeaderHeading: () => 'Bewilligungsart',
        },
        fr: {
            HeaderCaption: () => "Selectionner le type d'autorisation",
            HeaderHeading: () => "Type d'autorisation",
        },
        it: {
            HeaderCaption: () => "Selezionare il tipo d'autorizzazione",
            HeaderHeading: () => "Tipo d'autorizzazione",
        },
    };

export const vignetteAmount: Translations<VignetteAmountSlideIn.Texts> = {
    de: {
        Caption: () => 'Vignettengepreis',
        Heading: () => 'Anzahl Vignetten',
        Vignette: (i: number) => (i === 1 ? `${i} Vignette` : `${i} Vignetten`),
    },
    fr: {
        Caption: () => 'Prix vignette',
        Heading: () => 'Quantité de vignettes',
        Vignette: (i: number) => `${i} vignettes`,
    },
    it: {
        Caption: () => 'Prezzo vignetta',
        Heading: () => 'Quantità di vignette',
        Vignette: (i: number) => `${i} vignette`,
    },
};
