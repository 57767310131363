import { css } from '@emotion/css';

import { getOrElse, isUndefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional, DivGenerator } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    Clickable,
    ClickHandler,
    ClickableDivProps,
} from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { ColorHex } from '../Colors.ts';
import { Icon16 } from '../icons/Icon.tsx';

export enum ColumnWidth {
    _24px,
    _48px,
    _72px,
    _108px,
    _136px,
    _192px,
    _208px,
    _312px,
    variable,
}

export enum ColAlign {
    left,
    right,
}

export enum ColVerticalAlign {
    top,
    middle,
    bottom,
}

export enum SortDirection {
    Ascending,
    Descending,
}

export const Table = DivGenerator(css({ width: '100%', height: '100%' }));
export function TableBody({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return (
        <div
            className={css([
                {
                    position: 'absolute',
                    top: 60,
                    bottom: 0,
                    width: '100%',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    color: ColorHex.darkblue,
                },
                className,
            ])}
        >
            {children}
        </div>
    );
}

export const TableHeader = (p: {
    children?: React.ReactNode;
    className?: string;
}): JSX.Element => {
    return (
        <div
            className={css([
                {
                    position: 'absolute',
                    width: '100%',
                    height: 60,
                    top: 0,
                    overflow: 'hidden',
                },
                p.className,
            ])}
        >
            <div
                className={css({
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: '100%',
                    paddingRight: '1000px',
                    marginRight: '-1000px',
                })}
            >
                <div
                    className={css({
                        display: 'table',
                        width: '100%',
                        height: '100%',
                        color: '#32496b',
                        fontSize: 10.5,
                        lineHeight: '16px',
                        letterSpacing: '0.04em',
                        textTransform: 'uppercase',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                    })}
                >
                    {p.children}
                </div>
            </div>
        </div>
    );
};

interface HeaderColumnProps {
    name: React.ReactNode;
    width: ColumnWidth;
    selected?: boolean;
    direction?: Maybe<SortDirection>;
    align?: Maybe<ColAlign>;
    onClick?: Maybe<ClickHandler>;
    delimiterBottom?: boolean;
    className?: string;
}

export const TableHeaderColumn = (p: HeaderColumnProps): JSX.Element => (
    <Clickable
        element="div"
        className={css([
            {
                display: 'table-cell',
                paddingTop: 10,
                paddingLeft: 10,
                position: 'relative',
                paddingRight:
                    ColAlign[getOrElse(p.align, ColAlign.left)] ===
                    ColAlign[ColAlign.right]
                        ? 10
                        : undefined,
                textAlign:
                    ColAlign[getOrElse(p.align, ColAlign.left)] ===
                    ColAlign[ColAlign.right]
                        ? 'right'
                        : undefined,
                width: ColumnWidth[p.width].substr(1),
                maxWidth: ColumnWidth[p.width].substr(1),
                '&::before': {
                    content: '" "',
                    position: 'absolute',
                    background: ColorHex.rgba(
                        ColorHex.darkblue,
                        p.selected ? 1 : 0.4,
                    ),
                    top: p.delimiterBottom ? 'auto' : 0,
                    bottom: p.delimiterBottom ? 0 : undefined,
                    left: 1,
                    right: 1,
                    height: 4,
                },
                '&:hover': {
                    '&::before': {
                        background: !isUndefined(p.onClick)
                            ? ColorHex.rgba(ColorHex.darkblue, 1)
                            : undefined,
                    },
                },
                fontSize: p.selected ? 10.5 : undefined,
                lineHeight: p.selected ? '16px' : undefined,
                letterSpacing: p.selected ? '0.04em' : undefined,
                textTransform: p.selected ? 'uppercase' : undefined,
                fontWeight: p.selected ? 700 : undefined,
            },
            p.className,
        ])}
        disabled={isUndefined(p.onClick)}
        onClick={p.onClick}
    >
        <div
            className={css({
                display: 'inline-block',
                verticalAlign: 'top',
                marginTop: 5,
                fontWeight: p.selected ? 700 : 'normal',
            })}
        >
            {p.name}
        </div>
        <Conditional c={p.selected || false}>
            <div
                className={css({
                    display: 'inline-block',
                    verticalAlign: 'top',
                    width: '16px',
                    height: '16px',
                    marginTop: '4px',
                })}
            >
                <Icon16
                    icon={
                        p.direction === SortDirection.Ascending
                            ? Icons16.sort.arrowDown
                            : Icons16.sort.arrowUp
                    }
                />
            </div>
        </Conditional>
    </Clickable>
);

interface EmptyHeaderColumnProps {
    width: ColumnWidth;
    delimiterBottom?: boolean;
}

export const EmptyTableHeaderColumn = (
    p: EmptyHeaderColumnProps,
): JSX.Element => {
    return (
        <div
            className={css([
                {
                    display: 'table-cell',
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    width: ColumnWidth[p.width].substr(1),
                    maxWidth: ColumnWidth[p.width].substr(1),
                    position: 'relative',
                    '&::before': {
                        content: '" "',
                        position: 'absolute',
                        background: ColorHex.rgba(ColorHex.darkblue, 0.4),
                        top: 0,
                        left: '1px',
                        right: '1px',
                        height: '4px',
                    },
                },
                p.delimiterBottom && {
                    '&::before': {
                        top: 'auto',
                        bottom: 0,
                    },
                },
            ])}
        />
    );
};

interface TableRowProps {
    children?: React.ReactNode;
}

export const TableRow = (p: TableRowProps & ClickableDivProps): JSX.Element => {
    const propsCopy = Object.assign({}, p);
    delete propsCopy['className'];
    return (
        <Clickable
            element="div"
            className={css([
                p.className,
                {
                    display: 'table !important',
                    minHeight: '40px !important',
                    width: '100% !important',
                },
            ])}
            {...propsCopy}
        />
    );
};

interface DefaultTableRowProps {
    hoverable?: boolean;
    onClick?: ClickHandler;
    children?: React.ReactNode;
    backgroundColor?: string;
}

export const DefaultTableRow = (p: DefaultTableRowProps): JSX.Element => {
    return (
        <TableRow
            data-hoverable={p.hoverable !== false}
            onClick={p.onClick}
            disabled={isUndefined(p.onClick)}
            className={css([
                {
                    background: p.backgroundColor
                        ? ColorHex.rgba(p.backgroundColor, 0.1)
                        : ColorHex.rgba(ColorHex.lightblue, 0.1),
                    color: ColorHex.darkblue,
                    '&:hover': {
                        background:
                            p.hoverable !== false
                                ? ColorHex.rgba(ColorHex.lightblue, 0.2)
                                : undefined,
                    },
                },
            ])}
        >
            {p.children}
        </TableRow>
    );
};

interface TableColumnProps {
    width: ColumnWidth;
    align?: Maybe<ColAlign>;
    verticalAlign?: Maybe<ColVerticalAlign>;
    captionFontSize?: Maybe<boolean>;
    noPadding?: Maybe<boolean>;
    children?: React.ReactNode;
    className?: string;
}

export const TableColumn = (p: TableColumnProps): JSX.Element => {
    const vAlign =
        ColVerticalAlign[getOrElse(p.verticalAlign, ColVerticalAlign.middle)];

    return (
        <div
            className={css([
                {
                    width: ColumnWidth[p.width].substr(1),
                    maxWidth: ColumnWidth[p.width].substr(1),
                    display: 'table-cell',
                    fontSize: p.captionFontSize ? 10.5 : 13,
                    lineHeight: '16px',
                    letterSpacing: p.captionFontSize ? '0.04em' : '0.01em',
                    borderBottom: `solid 1px ${ColorHex.white}`,
                    paddingLeft: p.noPadding ? 0 : 10,
                    textTransform: p.captionFontSize ? 'uppercase' : undefined,
                    paddingRight:
                        ColAlign[getOrElse(p.align, ColAlign.left)] ===
                        ColAlign[ColAlign.right]
                            ? 10
                            : undefined,
                    textAlign:
                        ColAlign[getOrElse(p.align, ColAlign.left)] ===
                        ColAlign[ColAlign.right]
                            ? 'right'
                            : undefined,
                    verticalAlign: vAlign,
                    paddingBottom:
                        vAlign === ColVerticalAlign[ColVerticalAlign.bottom]
                            ? 3
                            : undefined,
                },
                p.className,
            ])}
        >
            {p.children}
        </div>
    );
};
