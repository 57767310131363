import moment from 'moment';

import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { Store, Updater, useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as Url from 'dg-web-shared/lib/Url.ts';
import * as Http from '../api/Http.ts';
import * as SettingsState from '../common/state/SettingsState.ts';
import { FilterButtonWithSummary } from '../ui/filter/FilterButtonWithSummary.tsx';
import {
    FilterLayout,
    FilterLayoutBody,
    FilterLayoutHeader,
} from '../ui/filter/FilterLayout.tsx';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../ui/filter/FilterSummary.tsx';
import {
    Body,
    Container,
    ModalSpecialTabResultRefresher,
    Tabs,
} from '../ui/layout/TabContent.tsx';
import { TextColumnContent, TextLeftColumn } from '../ui/layout/Text.tsx';
import { Search } from '../ui/search/Search.tsx';
import {
    ButtonDropdown,
    ButtonDropdownLinkItem,
} from '../ui/slidein/ButtonDropdown.tsx';
import { DateRangePickerSlideIn } from '../ui/slidein/DateRangePickerSlideIn.tsx';
import {
    SelectableZone,
    ZonesSelectionSlideIn,
} from '../ui/slidein/ZonesSelectionSlideIn.tsx';
import { EnforcementLogDetailSlideIn } from './EnforcementLogDetailSlideIn.tsx';
import { FilterHalfSlideIn } from './EnforcementLogFilterSlideIns.tsx';
import { EnforcementLogResultList } from './EnforcementLogResultList.tsx';
import * as EnforcementLogState from './EnforcementLogState.ts';
import { Localized } from '../common/components/Localized.tsx';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import * as OperatorDataState from '../common/state/OperatorDataState.ts';

interface State {
    filter: EnforcementLogState.Filter.State;
    list: EnforcementLogState.List.State;
    settings: SettingsState.State;
    operator: OperatorDataState.OperatorData | null;
}

function slideInOpen(filter: EnforcementLogState.Filter.State): boolean {
    return filter.filterSlideInVisible;
}

function noFilter(filter: EnforcementLogState.Filter.State): boolean {
    return (
        (!filter.search || filter.search.length < 3) &&
        filter.validFrom == null &&
        filter.validTo == null &&
        filter.zones.length === 0
    );
}

function BodyContent({
    storeState,
    update,
}: {
    storeState: State;
    update: Updater;
}) {
    if (!storeState.list.data) {
        return <Guide />;
    }
    return (
        <EnforcementLogResultList data={storeState.list.data} update={update} />
    );
}

function DateFilterSummary({
    filter,
}: {
    filter: EnforcementLogState.Filter.State;
}) {
    if (!!filter.validFrom || !!filter.validTo) {
        return (
            <FilterSummaryItem hasSeparator={false}>
                <FilterSummaryItemTitle
                    title={
                        <Localized
                            de="Kontrolldatum"
                            fr="Heure du contrôle"
                            it="Ora del controllo"
                            en="Control time"
                        />
                    }
                />
                <FilterSummaryItemSubTitle
                    title={
                        '\u00A0' +
                        Formatter.openRange(
                            filter.validFrom,
                            filter.validTo,
                            Formatter.dayMonthYear,
                        )
                    }
                />
            </FilterSummaryItem>
        );
    }
    return null;
}

function ZoneFilterSummary({
    zones,
    filter,
}: {
    zones: SelectableZone[];
    filter: EnforcementLogState.Filter.State;
}) {
    const filterActive = filter.zones.length > 0;
    if (filterActive) {
        return (
            <FilterSummaryItem hasSeparator={filterActive}>
                <FilterSummaryItemTitle
                    title={
                        <Localized de="Zonen" fr="Zones" it="Zone" en="Zones" />
                    }
                />
                <FilterSummaryItemSubTitle
                    title={'\u00A0' + `${filter.zones.length}/${zones.length}`}
                />
            </FilterSummaryItem>
        );
    }
    return null;
}

function ZoneFilterSlideIn({
    zones,
    storeState,
    update,
}: {
    zones: SelectableZone[] | null;
    storeState: State;
    update: Updater;
}): JSX.Element | null {
    if (!zones) {
        return null;
    }

    return (
        <ZonesSelectionSlideIn
            open={storeState.filter.zonesSelectionVisible}
            selectedIds={storeState.filter.zones}
            title={<Localized de="Zonen" fr="Zones" it="Zone" en="Zones" />}
            zones={zones}
            onSelectedIdsChange={z => {
                update(store =>
                    EnforcementLogState.Filter.stateWrite(store, { zones: z }),
                );
            }}
            onClose={() => {
                update(store =>
                    EnforcementLogState.Filter.stateWrite(store, {
                        zonesSelectionVisible: false,
                    }),
                );
            }}
        />
    );
}

function DateFilterSlideIn({
    storeState,
    update,
}: {
    storeState: State;
    update: Updater;
}) {
    return (
        <DateRangePickerSlideIn
            language={storeState.settings.language}
            open={storeState.filter.validDateSelectionVisible}
            title={
                <Localized
                    de="Kontrolldatum"
                    fr="Heure du contrôle"
                    it="Ora del controllo"
                    en="Control time"
                />
            }
            validFrom={storeState.filter.validFrom}
            validTo={storeState.filter.validTo}
            onClose={() =>
                update(store =>
                    EnforcementLogState.Filter.stateWrite(store, {
                        validDateSelectionVisible: false,
                    }),
                )
            }
            onSelectFrom={(d: moment.Moment) => {
                update(store =>
                    EnforcementLogState.Filter.stateWrite(store, {
                        validFrom: d,
                    }),
                );
            }}
            onSelectTo={(d: moment.Moment) => {
                update(store =>
                    EnforcementLogState.Filter.stateWrite(store, {
                        validTo: d,
                    }),
                );
            }}
        />
    );
}

function FilterSummary({
    storeState,
    zones,
    update,
}: {
    storeState: State;
    zones: SelectableZone[] | null;
    update: Updater;
}) {
    const hasFilter =
        !!storeState.filter.validFrom ||
        !!storeState.filter.validTo ||
        storeState.filter.zones.length > 0;
    return (
        <FilterButtonWithSummary
            onFilterClick={() =>
                update(store =>
                    EnforcementLogState.Filter.stateWrite(store, {
                        filterSlideInVisible: true,
                    }),
                )
            }
            clearFilter={() =>
                update(store =>
                    EnforcementLogState.Filter.stateWrite(store, {
                        validFrom: null,
                        validTo: null,
                        zones: [],
                    }),
                )
            }
        >
            {hasFilter &&
                zones && [
                    <DateFilterSummary key="date" filter={storeState.filter} />,
                    <ZoneFilterSummary
                        key="zones"
                        zones={zones}
                        filter={storeState.filter}
                    />,
                ]}
        </FilterButtonWithSummary>
    );
}

function Guide() {
    return (
        <TextColumnContent>
            <TextLeftColumn>
                <h1>
                    <Localized
                        de="Suchen"
                        fr="Rechercher"
                        it="Cercare"
                        en="Search"
                    />
                </h1>
                <h3>
                    <Localized
                        de="Mittels Suchfeld und/oder Filter können Sie Kontrollabfragen suchen und anzeigen."
                        fr="Avec le champ de recherche et/ou le filtre vous pouvez rechercher et afficher les activités de contrôle."
                        it="Con il campo di ricerca e/o il filtro potete cercare e visualizzare le attività di controllo."
                        en="With the search field and/or the filter you can search and display the control history."
                    />
                </h3>
                <h3>
                    <Localized
                        de="Folgende Suchkriterien sind im Suchfeld verfügbar:"
                        fr="Les critères suivants sont disponibles dans le champ de recherche:"
                        it="Sono disponibili i seguenti criteri:"
                        en="The following criteria are available in the search field:"
                    />
                </h3>

                <ul>
                    <li>
                        <Localized
                            de="Kennzeichen"
                            fr="Immatriculation"
                            it="Numero di targa"
                            en="License plate"
                        />
                    </li>
                </ul>
            </TextLeftColumn>
        </TextColumnContent>
    );
}

export function EnforcementLog() {
    const { storeState, update } = useStore((store: Store): State => {
        const filter = EnforcementLogState.Filter.get(store);
        return {
            filter,
            list: EnforcementLogState.List.get(
                store,
                noFilter(filter)
                    ? null
                    : {
                          validFrom: filter.validFrom,
                          validTo: filter.validTo,
                          zones: filter.zones,
                          search: filter.search,
                          lang: new SettingsState.StateSlice(store).state
                              .language,
                      },
            ),
            settings: new SettingsState.StateSlice(store).state,
            operator: OperatorDataState.get(store).data,
        };
    });

    const [enforcementZones] = useServerFetch<SelectableZone[], object>(
        () => ({
            url: `/ui-api/operator-account/control-history-zones`,
        }),
        {},
    );

    return (
        <Container>
            <Tabs>
                <Search
                    searchValue={storeState.filter.search || ''}
                    onSearchFieldChange={v => {
                        update(store =>
                            EnforcementLogState.Filter.stateWrite(store, {
                                search: v === '' ? null : v,
                            }),
                        );
                    }}
                    onSelect={() => null}
                    selected={true}
                    language={storeState.settings.language}
                    mousetrap={false}
                />
                {!noFilter(storeState.filter) && (
                    <ModalSpecialTabResultRefresher
                        onRefreshClick={() =>
                            update(store =>
                                EnforcementLogState.List.refetchSameContext(
                                    store,
                                    true,
                                ),
                            )
                        }
                        successRequest={
                            storeState.list.statusCode.statusCode === 200
                        }
                        pendingRequest={storeState.list.pending}
                    />
                )}
            </Tabs>
            <Body
                disabled={slideInOpen(storeState.filter)}
                loading={
                    storeState.list.pending ||
                    enforcementZones.status === RequestStatus.PENDING
                }
                slideIns={[
                    <EnforcementLogDetailSlideIn key="detail" />,
                    <FilterHalfSlideIn key="filter" />,
                    <DateFilterSlideIn
                        storeState={storeState}
                        update={update}
                        key="date"
                    />,
                    <ZoneFilterSlideIn
                        zones={enforcementZones.data}
                        storeState={storeState}
                        update={update}
                        key="zone"
                    />,
                ]}
            >
                <FilterLayout>
                    <FilterLayoutHeader
                        filterSummary={
                            <FilterSummary
                                storeState={storeState}
                                zones={enforcementZones.data}
                                update={update}
                            />
                        }
                        rightDropdown={
                            <ButtonDropdown
                                label={
                                    <Localized
                                        de="Exportieren"
                                        fr="Exporter"
                                        it="Esporta"
                                        en="Export"
                                    />
                                }
                            >
                                <ButtonDropdownLinkItem
                                    label={
                                        <Localized
                                            de="Exportieren"
                                            fr="Exporter"
                                            it="Esporta"
                                            en="Export"
                                        />
                                    }
                                    disabled={noFilter(storeState.filter)}
                                    href={`/ui-api/operator-account/control-history/export?${Url.encodeData(
                                        Http.OperatorAccount.EnforcementLog.listQueryParams(
                                            {
                                                validFrom:
                                                    storeState.filter.validFrom,
                                                validTo:
                                                    storeState.filter.validTo,
                                                lang: storeState.settings
                                                    .language,
                                                zones: storeState.filter.zones,
                                                search: storeState.filter
                                                    .search,
                                            },
                                        ),
                                    )}`}
                                />
                                <ButtonDropdownLinkItem
                                    label={
                                        <Localized
                                            de="Statistik pro Kontrolleur"
                                            fr="Statistique par contrôleur"
                                            it="Statistica per controllore"
                                            en="Control zones statistics"
                                        />
                                    }
                                    disabled={noFilter(storeState.filter)}
                                    href={`/ui-api/operator-account/control-history/login-csv-export?${Url.encodeData(
                                        Http.OperatorAccount.EnforcementLog.listQueryParams(
                                            {
                                                validFrom:
                                                    storeState.filter.validFrom,
                                                validTo:
                                                    storeState.filter.validTo,
                                                lang: storeState.settings
                                                    .language,
                                                zones: storeState.filter.zones,
                                                search: storeState.filter
                                                    .search,
                                            },
                                        ),
                                    )}`}
                                />
                                <ButtonDropdownLinkItem
                                    label={
                                        <Localized
                                            de="Statistik pro Kontrollzone"
                                            fr="Statistique par zone de contrôle"
                                            it="Statistica per zona di controllo"
                                            en="Control zones statistics"
                                        />
                                    }
                                    disabled={noFilter(storeState.filter)}
                                    href={`/ui-api/operator-account/control-history/zone-csv-export?${Url.encodeData(
                                        Http.OperatorAccount.EnforcementLog.listQueryParams(
                                            {
                                                validFrom:
                                                    storeState.filter.validFrom,
                                                validTo:
                                                    storeState.filter.validTo,
                                                lang: storeState.settings
                                                    .language,
                                                zones: storeState.filter.zones,
                                                search: storeState.filter
                                                    .search,
                                            },
                                        ),
                                    )}`}
                                />
                                {storeState.operator &&
                                    storeState.operator.hasNightParking && (
                                        <ButtonDropdownLinkItem
                                            label={
                                                <Localized
                                                    de="Statistik Nachtparkieren"
                                                    fr="Statistique stationnement nocturne"
                                                    it="Statistica parcheggio notturno "
                                                    en="Night parking statistic"
                                                />
                                            }
                                            disabled={noFilter(
                                                storeState.filter,
                                            )}
                                            href={`/ui-api/operator-account/control-history/night-parking-export?${Url.encodeData(
                                                Http.OperatorAccount.EnforcementLog.listQueryParams(
                                                    {
                                                        validFrom:
                                                            storeState.filter
                                                                .validFrom,
                                                        validTo:
                                                            storeState.filter
                                                                .validTo,
                                                        lang: storeState
                                                            .settings.language,
                                                        zones: storeState.filter
                                                            .zones,
                                                        search: storeState
                                                            .filter.search,
                                                    },
                                                ),
                                            )}`}
                                        />
                                    )}
                            </ButtonDropdown>
                        }
                    />
                    <FilterLayoutBody>
                        <BodyContent storeState={storeState} update={update} />
                    </FilterLayoutBody>
                </FilterLayout>
            </Body>
        </Container>
    );
}
