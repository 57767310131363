import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { Icon16 } from '../icons/Icon.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from './Slidein.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';
import { Localized } from '../../common/components/Localized.tsx';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export function filterInactiveUnusedPermits(
    permitTypeData: PermitTypeState.PermitType[],
) {
    return permitTypeData.filter(permit => {
        return (
            permit.operatorState === 'ACTIVE' ||
            (permit.operatorState === 'INACTIVE' &&
                permit.hasNonArchivedContracts)
        );
    });
}

export function flagInactivePermits(permit: PermitTypeState.PermitType) {
    return permit.operatorState === 'INACTIVE' ? (
        <>
            {`${permit.description} — `}
            <Localized de="INAKTIV" fr="INACTIVE" it="INATTIVO" en="INACTIVE" />
        </>
    ) : (
        permit.description
    );
}

interface FilterGroup {
    id: string;
    displayName: React.ReactNode;
}

interface PermitTypeSelectionSlideInProps {
    open: boolean;
    caption?: string;
    heading: React.ReactNode;
    selection: number[];
    selectablePermits: PermitSelection[];
    onLeftActionClick: () => void;
    setNewSelection: (selection: number[]) => void;
    filterGroups: FilterGroup[];
    outsideBody?: boolean;
}

interface PermitSelection {
    id: number;
    displayText: React.ReactNode;
    group: string;
    hasSpacingBottom?: boolean;
}

interface EnrichedPermitSelection extends PermitSelection {
    selected: boolean;
}

enum CheckboxState {
    UNCHECKED = 'unchecked',
    CHECKED = 'checked',
    MIXED = 'mixed',
    HIDDEN = 'hidden',
}

export function PermitTypeSelectionSlideIn({
    open,
    caption,
    heading,
    selection,
    selectablePermits,
    onLeftActionClick,
    setNewSelection,
    filterGroups,
    outsideBody,
}: PermitTypeSelectionSlideInProps) {
    const enrichedSelectablePermits = selectablePermits.map(option => {
        return { ...option, selected: selection.includes(option.id) };
    });

    const allFilterGroup: FilterGroup = {
        id: 'ALL',
        displayName:
            selection.length === selectablePermits.length ? (
                <Localized de="KEINE" fr="AUCUNES" it="NESSUNO" en="NONE" />
            ) : (
                <Localized de="ALLE" fr="TOUS" it="TUTTI" en="ALL" />
            ),
    };

    const processedFilterGroups = processFilterGroups(
        [allFilterGroup, ...filterGroups],
        enrichedSelectablePermits,
    );

    return (
        <HalfSlideIn open={open} outsideBody={outsideBody}>
            <SecondLevelHeader onClose={onLeftActionClick}>
                <SlideInHeaderTexts
                    subtitle={caption}
                    title={heading}
                    hasLeftIcon={false}
                />
            </SecondLevelHeader>
            <div
                className={css({
                    position: 'absolute',
                    display: 'flex',
                    top: '64px',
                    width: '100%',
                    height: '40px',
                    backgroundColor: ColorHex.rgba(ColorHex.lightblue, 0.1),
                    padding: '7px 48px 0',
                    borderBottom:
                        '1px solid ' + ColorHex.rgba(ColorHex.darkblue, 0.25),
                })}
            >
                {processedFilterGroups.map(group => {
                    return (
                        <ClickableGroupSelectionSwitch
                            key={group.id}
                            checkboxState={group.checkboxState}
                            groupLength={group.groupMaxLength}
                            onClick={() =>
                                toggleFilterGroup(
                                    group,
                                    enrichedSelectablePermits,
                                    selection,
                                    setNewSelection,
                                )
                            }
                            groupName={group.displayName}
                        />
                    );
                })}
            </div>
            <SlideInBody hasSubheader>
                {selectablePermits.map(
                    (option: PermitSelection, key: number) => {
                        const selected = selection.indexOf(option.id) > -1;
                        return (
                            <Clickable
                                key={`clickable-${key}`}
                                element="div"
                                className={css({
                                    ...OperatorTypo.headingOne,
                                    ...Typo.robotoMedium,
                                    lineHeight: '1.5em',
                                    color: ColorHex.rgba(
                                        ColorHex.darkblue,
                                        0.8,
                                    ),
                                    background: selected
                                        ? ColorHex.rgba(ColorHex.darkblue, 0.2)
                                        : 'none',
                                    padding: '4px 48px ',
                                    margin: '0 -48px',
                                    '&:hover': {
                                        background: ColorHex.darkblue,
                                        color: 'white',
                                    },

                                    marginBottom: option.hasSpacingBottom
                                        ? '25px'
                                        : '0px',
                                })}
                                onClick={() =>
                                    toggleItem(
                                        option.id,
                                        selection,
                                        setNewSelection,
                                    )
                                }
                            >
                                <div
                                    className={css({
                                        position: 'relative',
                                        top: '3px',
                                        marginRight: '16px',
                                        display: 'inline-block',
                                        verticalAlign: 'baseline',
                                    })}
                                >
                                    <Icon16
                                        icon={
                                            selected
                                                ? Icons16.checkbox.on
                                                : Icons16.checkbox.off
                                        }
                                    />
                                </div>
                                <span>{option.displayText}</span>
                            </Clickable>
                        );
                    },
                )}
            </SlideInBody>
        </HalfSlideIn>
    );
}

function toggleItem(
    id: number,
    selection: number[],
    setNewSelection: (selection: number[]) => void,
): void {
    if (selection.includes(id)) {
        setNewSelection(selection.filter(i => i !== id));
    } else {
        setNewSelection([...selection, id]);
    }
}

function toggleFilterGroup(
    filterGroup: ProcessedFilterGroup,
    selectablePermits: EnrichedPermitSelection[],
    selections: number[],
    setNewSelection: (selection: number[]) => void,
) {
    const groupSelectablePermits = selectablePermits
        .filter(
            permit =>
                filterGroup.id === permit.group || filterGroup.id === 'ALL',
        )
        .map(permit => permit.id);

    if (filterGroup.checkboxState === CheckboxState.CHECKED) {
        setNewSelection(
            selections.filter(
                selection => !groupSelectablePermits.includes(selection),
            ),
        );
    } else {
        setNewSelection(
            removeDuplicates([...selections, ...groupSelectablePermits]),
        );
    }
}

function removeDuplicates<T>(array: T[]) {
    return array.filter((value, index) => array.indexOf(value) === index);
}

function ClickableGroupSelectionSwitch({
    checkboxState,
    onClick,
    groupName,
    groupLength,
}: {
    checkboxState: CheckboxState;
    onClick: () => void;
    groupName: React.ReactNode;
    groupLength?: number | null;
}) {
    if (checkboxState === CheckboxState.HIDDEN) {
        return null;
    }

    return (
        <Clickable
            element="div"
            className={css({
                ...Typo.captionC1,
                color: ColorHex.rgba(ColorHex.darkblue, 0.7),
                paddingTop: '2px',
                marginRight: '20px',
                '&:last-child': { marginRight: '0px' },
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            })}
            onClick={() => onClick()}
        >
            <div
                className={css({
                    position: 'relative',
                    top: '4px',
                    marginRight: '16px',
                    display: 'inline-block',
                    verticalAlign: 'baseline',
                })}
            >
                <CheckboxIcon checkboxState={checkboxState} />
            </div>
            <span>{groupName}</span>
            {groupLength && (
                <span className={css({ ...Typo.captionC2 })}>
                    {' ' + groupLength}
                </span>
            )}
        </Clickable>
    );
}

function CheckboxIcon({ checkboxState }: { checkboxState: CheckboxState }) {
    switch (checkboxState) {
        case CheckboxState.CHECKED:
            return <Icon16 icon={Icons16.checkbox.all} />;
        case CheckboxState.MIXED:
            return <Icon16 icon={Icons16.checkbox.mixed} />;
        default:
            return <Icon16 icon={Icons16.checkbox.none} />;
    }
}

interface ProcessedFilterGroup {
    id: string;
    displayName: React.ReactNode;
    checkboxState: CheckboxState;
    groupMaxLength: number;
}

function processFilterGroups(
    filterGroups: FilterGroup[],
    selectablePermits: EnrichedPermitSelection[],
): ProcessedFilterGroup[] {
    return filterGroups.map(filterGroup => {
        const groupSelectablePermits = selectablePermits.filter(
            permit =>
                filterGroup.id === permit.group || filterGroup.id === 'ALL',
        );
        const groupMaxLength = groupSelectablePermits.length;
        const selectedLength = groupSelectablePermits.filter(
            permit => permit.selected,
        ).length;
        const checkboxState = calculateCheckboxState(
            groupMaxLength,
            selectedLength,
            selectablePermits.length,
            filterGroup.id === 'ALL',
        );
        return {
            id: filterGroup.id,
            displayName: filterGroup.displayName,
            checkboxState,
            groupMaxLength,
        };
    });
}

function calculateCheckboxState(
    groupMaxLength: number,
    selectedLength: number,
    selectablePermitsLength: number,
    isAll: boolean,
): CheckboxState {
    // if group is empty or if there is one group or less --> dont display group (except for ALL group)
    if (
        groupMaxLength === 0 ||
        (groupMaxLength === selectablePermitsLength && !isAll)
    ) {
        return CheckboxState.HIDDEN;
    }
    switch (selectedLength) {
        case groupMaxLength:
            return CheckboxState.CHECKED;
        case 0:
            return CheckboxState.UNCHECKED;
        default:
            return CheckboxState.MIXED;
    }
}
