import { css } from '@emotion/css';

import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Localized } from './Localized.tsx';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { TerminalProvider } from '../state/ParkingsState.ts';
import { Icon16 } from 'dg-web-shared/ui/icons/Icon.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export enum BarrierGateIdentification {
    BADGE = 'BADGE',
    LICENSE_PLATE = 'LICENSE_PLATE',
    QR_CODE = 'QR_CODE',
    TICKET = 'TICKET',
}

export const IdentificationLicensePlate = ({
    licensePlateNr,
    type,
    country,
    inlineMode,
}: {
    licensePlateNr: string;
    type?: LicensePlateType | null;
    country?: string | null;
    inlineMode: boolean;
}) => (
    <div
        className={css({
            display: 'flex',
            alignItems: 'center',
        })}
    >
        <div
            className={css({
                ...(inlineMode
                    ? {
                          fontSize: '13px',
                          lineHeight: '16px',
                          letterSpacing: '0.01em',
                      }
                    : {
                          fontSize: '16px',
                          lineHeight: '22px',
                          ...Typo.robotoMedium,
                      }),
            })}
        >
            {licensePlateNr}
        </div>
        {country && type
            ? [
                  <div
                      key="c"
                      className={css({
                          ...Typo.captionC2,
                          paddingLeft: '6px',
                      })}
                  >
                      {country}
                  </div>,
                  <div
                      key="t"
                      className={css({
                          paddingLeft: '6px',
                      })}
                  >
                      <IdentificationIcon16
                          identificationType={
                              BarrierGateIdentification.LICENSE_PLATE
                          }
                          licensePlateType={type}
                      />
                  </div>,
              ]
            : null}
    </div>
);

export function IdentificationBadge({
    badgeLabelNr,
    barrierGateProvider,
    rfidDecimal,
    inlineMode,
}: {
    badgeLabelNr: string;
    barrierGateProvider?: TerminalProvider | null;
    rfidDecimal: string | null;
    inlineMode: boolean;
}) {
    const decimalProviderLabel =
        barrierGateProvider === TerminalProvider.SKIDATA_CLOUD ||
        barrierGateProvider === TerminalProvider.SKIDATA_CLOUD_V7 ? (
            <Localized
                de="Skidata-Nr."
                fr="N° Skidata"
                it="Nr. Skidata"
                en="Skidata Nr"
            />
        ) : barrierGateProvider === TerminalProvider.SB ? (
            <Localized
                de="Scheidt & Bachmann-Nr."
                fr="N° Scheidt & Bachmann"
                it="Nr. Scheidt & Bachmann"
                en="Scheidt & Bachmann Nr"
            />
        ) : null;

    return (
        <>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                <div
                    className={css({
                        ...(inlineMode
                            ? {
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  letterSpacing: '0.01em',
                              }
                            : {
                                  fontSize: '16px',
                                  lineHeight: '22px',
                                  ...Typo.robotoMedium,
                              }),
                    })}
                >
                    {badgeLabelNr}
                </div>

                <div
                    className={css({
                        paddingLeft: '6px',
                        position: 'relative',
                    })}
                >
                    <IdentificationIcon16
                        identificationType={BarrierGateIdentification.BADGE}
                        licensePlateType={null}
                    />
                </div>
            </div>

            {!inlineMode && rfidDecimal && decimalProviderLabel && (
                <div>
                    ({decimalProviderLabel} {rfidDecimal})
                </div>
            )}
        </>
    );
}

export function IdentificationQr({
    identificationQrCodeId,
}: {
    identificationQrCodeId: string;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                alignItems: 'center',
            })}
        >
            <div className={css({ marginRight: '6px' })}>
                {identificationQrCodeId}
            </div>
            <IdentificationIcon16
                identificationType={BarrierGateIdentification.QR_CODE}
                licensePlateType={null}
            />
        </div>
    );
}

export function IdentificationIcon16({
    identificationType,
    licensePlateType,
}: {
    identificationType: BarrierGateIdentification | null;
    licensePlateType: LicensePlateType | null;
}) {
    if (identificationType === null) {
        return <>{EmDash}</>;
    }
    return (
        <Icon16
            icon={
                <IdentificationIcon
                    identificationType={identificationType}
                    licensePlateType={licensePlateType}
                />
            }
        />
    );
}

export function IdentificationIcon({
    identificationType,
    licensePlateType,
}: {
    identificationType: BarrierGateIdentification;
    licensePlateType: LicensePlateType | null;
}): JSX.Element {
    switch (identificationType) {
        case BarrierGateIdentification.LICENSE_PLATE:
            switch (licensePlateType) {
                case LicensePlateType.CAR:
                    return Icons16.licensePlateType.car;
                case LicensePlateType.MOTORCYCLE:
                    return Icons16.licensePlateType.motorcycle;
                case null:
            }
            return Icons16.licensePlateType.car;
        case BarrierGateIdentification.BADGE:
            return Icons16.identifier.badge;
        case BarrierGateIdentification.QR_CODE:
            return Icons16.identifier.qrCode;
        case BarrierGateIdentification.TICKET:
            return Icons16.identifier.ticket;
    }
}
