import {
    Box,
    Button,
    Card,
    CardActionArea,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import { Dns, Savings, Settings } from '@mui/icons-material';
import { Localized } from '../common/components/Localized.tsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { RestrictedComponent } from '../app/components/RestrictedComponent.tsx';
import { OperatorAppRoutes } from '../app/config/OperatorRoutingConfig.tsx';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon.tsx';

export function MobileHomePage() {
    const { operatorData } = useOperatorContext();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <Header />
            <Box
                sx={{
                    padding: theme => theme.spacing(4),
                    backgroundColor: theme => theme.palette.blue.light,
                    flexGrow: 1,
                }}
            >
                <Typography
                    variant="h2"
                    sx={theme => ({
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(3),
                        textAlign: 'center',
                        marginBottom: theme.spacing(1),
                        color: theme => theme.palette.primary.main,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontSize: '34px',
                        fontWeight: 700,
                    })}
                >
                    {operatorData.operatorName}
                </Typography>
                <Stack spacing={2}>
                    <RestrictedComponent
                        route={
                            OperatorAppRoutes.MobileCloudConnectorPeripheries
                        }
                    >
                        <MenuItem
                            to={'offstreet-devices'}
                            label={
                                <Localized
                                    de="Geräte"
                                    fr="Appareils"
                                    it="Apparecchi"
                                    en="Devices"
                                />
                            }
                            icon={
                                <Dns
                                    sx={{
                                        fontSize: '32px',
                                        color: theme =>
                                            theme.palette.primary.main,
                                    }}
                                />
                            }
                        />
                    </RestrictedComponent>
                    <RestrictedComponent
                        route={OperatorAppRoutes.CloudConnectorRecode}
                    >
                        <MenuItem
                            to={'recode'}
                            label={
                                <Localized
                                    de="Umkodierung"
                                    fr="Recodage"
                                    it="Ricodifica"
                                    en="Recoding"
                                />
                            }
                            icon={
                                <Savings
                                    sx={{
                                        fontSize: '32px',
                                        color: theme =>
                                            theme.palette.primary.main,
                                    }}
                                />
                            }
                        />
                    </RestrictedComponent>
                </Stack>
            </Box>
        </Box>
    );
}

function MenuItem({
    to,
    label,
    icon,
}: {
    to: string;
    label: JSX.Element;
    icon: JSX.Element;
}) {
    return (
        <Link component={RouterLink} to={to} underline="none">
            <Card elevation={0} sx={{ border: 0 }}>
                <CardActionArea
                    sx={theme => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingX: theme.spacing(2),
                        paddingY: theme.spacing(3),
                        backgroundColor: theme =>
                            theme.palette.primary.contrastText,
                        ['&:hover']: {
                            backgroundColor: theme =>
                                theme.palette.primary.light,
                        },
                    })}
                >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {icon}
                        <Typography
                            sx={{
                                marginLeft: '24px',
                                fontSize: '24px',
                                fontWeight: 700,
                                color: theme => theme.palette.primary.main,
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>
                </CardActionArea>
            </Card>
        </Link>
    );
}

export function Header() {
    const navigate = useNavigate();
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                padding: theme => theme.spacing(3),
            })}
        >
            <Box
                sx={{ paddingRight: theme => theme.spacing(2) }}
                onClick={() => navigate('/mobile')}
                width={246}
                height={50}
            >
                <ParkingportalLogo />
            </Box>

            <Link component={RouterLink} to={'settings'}>
                <Button
                    sx={{
                        width: 40,
                        height: 40,
                        minWidth: 40,
                        backgroundColor: theme =>
                            theme.palette.primary.contrastText,
                        ['&:hover']: {
                            backgroundColor: theme =>
                                theme.palette.primary.light,
                        },
                    }}
                >
                    <Settings />
                </Button>
            </Link>
        </Box>
    );
}

function ParkingportalLogo() {
    return <SvgIcon icon={'parkingportal'} width={507} height={103} />;
}
