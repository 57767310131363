import { Translation, Translations } from 'dg-web-shared/lib/Text.ts';

export interface TomOperationStatusTexts {
    OperationStatus: Translation;
    StatusMessages: Translation;
    InService: Translation;
    OutOfService: Translation;
    Error: Translation;
    Errors: Translation;
    Alarm: Translation;
    Alarms: Translation;
    Troubleshooting: Translation;
    Offline: Translation;
    LastContact: Translation;
}

export const tomOperationStatusTexts: Translations<TomOperationStatusTexts> = {
    en: {
        OperationStatus: () => 'Operation Status',
        StatusMessages: () => 'Status messages',
        Error: () => 'Error',
        Errors: () => 'Errors',
        Alarm: () => 'Alarm',
        Alarms: () => 'Alarms',
        Troubleshooting: () => 'Troubleshooting',
        InService: () => 'In Service',
        OutOfService: () => 'Out of Service',
        Offline: () => 'Offline',
        LastContact: () => 'Last status update',
    },
    de: {
        OperationStatus: () => 'Betriebsstatus',
        StatusMessages: () => 'Statusmeldungen',
        Error: () => 'Fehler',
        Errors: () => 'Fehler',
        Alarm: () => 'Alarm',
        Alarms: () => 'Alarme',
        Troubleshooting: () => 'Fehlerbehebung',
        InService: () => 'in Betrieb',
        OutOfService: () => 'ausser Betrieb',
        Offline: () => 'offline',
        LastContact: () => 'Letzte Statusmeldung',
    },
    fr: {
        OperationStatus: () => "État d'exploitation",
        StatusMessages: () => 'Messages de statut',
        Error: () => 'Fehler',
        Errors: () => 'Fehler',
        Alarm: () => 'Alarm',
        Alarms: () => 'Alarme',
        Troubleshooting: () => 'Correction des anomalies',
        InService: () => 'en service',
        OutOfService: () => 'hors service',
        Offline: () => 'offline',
        LastContact: () => "Dernier message d'état",
    },
    it: {
        OperationStatus: () => 'Stato operativo',
        StatusMessages: () => 'Messaggi di stato',
        Error: () => 'Errore',
        Errors: () => 'Errori',
        Alarm: () => 'Allarme',
        Alarms: () => 'Allarmi',
        Troubleshooting: () => 'Risoluzione errori',
        InService: () => 'in servizio',
        OutOfService: () => 'fuori servizio',
        Offline: () => 'offline',
        LastContact: () => 'Ultimo messaggio di stato',
    },
};
