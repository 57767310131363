import { Translation, Translations } from '../../common/i18n/Text.ts';
import * as PrintSelectionSlideIn from '../components/PrintSelectionSlideIn.tsx';

export const header: Translations<{
    Active: Translation;
    Expired: Translation;
    Future: Translation;
    Stornod: Translation;
    TerminateTooltip: Translation;
    StornoTooltip: Translation;
    refundTooltip: Translation;
    RenewTooltip: Translation;
    PrintTooltip: Translation;
    CopyPermitLabel: Translation;
    EditLabel: Translation;
}> = {
    de: {
        refundTooltip: () => 'Bewilligung zurückzahlen',
        Active: () => 'Gültige Bewilligung',
        Expired: () => 'Abgelaufene Bewilligung',
        Future: () => 'Zukünftig gültige Bewilligung',
        PrintTooltip: () => 'Dokument(e) drucken',
        RenewTooltip: () => 'Bewilligung erneuern',
        Stornod: () => 'Stornierte Bewilligung',
        StornoTooltip: () => 'Bewilligung stornieren',
        TerminateTooltip: () => 'Bewilligung beenden',
        CopyPermitLabel: () => 'Bewilligung kopieren',
        EditLabel: () => 'Bearbeiten',
    },
    fr: {
        refundTooltip: () => 'Rembourser autorisation',
        Active: () => 'Autorisation valable',
        Expired: () => 'Autorisation échue',
        Future: () => 'Autorisation valable dans le future',
        PrintTooltip: () => 'Imprimer document(s)',
        RenewTooltip: () => 'Renouveler autorisation',
        Stornod: () => 'Autorisation annulée',
        StornoTooltip: () => "Annuler l'autorisation",
        TerminateTooltip: () => "Terminer l'autorisation",
        CopyPermitLabel: () => "Copier l'autorisation",
        EditLabel: () => 'Modifier',
    },
    it: {
        refundTooltip: () => 'Rimborsare autorizzazione',
        Active: () => 'Autorizzazione valida',
        Expired: () => 'Autorizzazione scaduta',
        Future: () => 'Autorizzazione valida in futuro',
        PrintTooltip: () => 'Stampare documento/i',
        RenewTooltip: () => 'Rinnova autorizzazione',
        Stornod: () => 'Autorizzazione annullata',
        StornoTooltip: () => "Annullare l'autorizzazione",
        TerminateTooltip: () => 'Terminare autorizzazione',
        CopyPermitLabel: () => 'Copia autorizzazione',
        EditLabel: () => 'Modificare',
    },
};

export const body: Translations<{
    PermitType: Translation;
    StartDate: Translation;
    EndDate: Translation;
    ValidZones: Translation;
    Price: Translation;
    PriceMultiplier: Translation;
    RefundedNote: (amount: string) => string;
    RefundedBy: Translation;
    RemarkLabel: Translation;
    AmountLabel: Translation;
    FixedZones: (zoneCount: number) => string;
    Created: Translation;
    OwnedBy: Translation;
    PermitId: Translation;
    StornoConfirmationCaption: Translation;
    StornoConfirmationHeading: Translation;
    RefundConfirmationCaption: Translation;
    RefundConfirmationHeading: Translation;
    EditCaption: Translation;
    VignetteInvoiced: Translation;
    OnlineUser: Translation;
    UntilRecall: Translation;
    Channel: Translation;
}> = {
    de: {
        PermitType: () => 'Bewilligungsart',
        PermitId: () => 'Bewilligungsnummer',
        StartDate: () => 'Beginn',
        EndDate: () => 'Ende',
        ValidZones: () => 'Gültig in',
        Price: () => 'Gebühr',
        PriceMultiplier: () => 'Preiskategorie',
        RefundedNote: (amount: string) => `(davon ${amount} zurückbezahlt)`,
        RefundedBy: () => 'Rückbezahlt',
        RemarkLabel: () => 'Interne Bemerkungen',
        AmountLabel: () => 'Restbetrag',
        FixedZones: (zoneCount: number) => `${zoneCount} fixe Zonen`,
        Created: () => 'Ausgestellt',
        OwnedBy: () => 'Parkingpay-Konto',
        StornoConfirmationCaption: () =>
            'Der Preis der Bewilligung wird nicht automatisch auf das Benutzerkonto zurückbezahlt',
        StornoConfirmationHeading: () => 'Diese Bewilligung stornieren?',
        RefundConfirmationCaption: () => '',
        RefundConfirmationHeading: () => 'Diese Bewilligung zurückzahlen?',
        EditCaption: () => 'Bewilligung bearbeiten',
        VignetteInvoiced: () => 'Verrechnete Vignetten',
        OnlineUser: () => 'ONLINE BENUTZER',
        UntilRecall: () => 'auf Widerruf',
        Channel: () => 'Kanal',
    },
    fr: {
        PermitType: () => "Type d'autorisation",
        PermitId: () => 'Numéro autorisation',
        Created: () => 'Emise',
        OwnedBy: () => 'Compte Parkingpay',
        EditCaption: () => 'Modifier autorisation',
        EndDate: () => 'Fin',
        FixedZones: (zoneCount: number) => `${zoneCount} zones fixes`,
        OnlineUser: () => 'UTILISATEUR EN LIGNE',
        Price: () => 'Prix',
        PriceMultiplier: () => 'Catégorie de prix',
        RefundedNote: (amount: string) => `(dont ${amount} remboursé)`,
        RefundedBy: () => 'Remboursée',
        RemarkLabel: () => 'Remarques internes',
        AmountLabel: () => 'Montant résiduel',
        RefundConfirmationCaption: () => '',
        RefundConfirmationHeading: () => 'Rembourser cette autorisation?',
        StartDate: () => 'Début',
        StornoConfirmationCaption: () =>
            "Le prix de l'autorisation ne sera pas remboursé automatiquement sur le compte de l'utilisateur.",
        StornoConfirmationHeading: () => 'Annuler cette autorisation?',
        ValidZones: () => 'Valable pour',
        VignetteInvoiced: () => 'Vignette(s) encaissée(s)',
        UntilRecall: () => "jusqu'à révocation",
        Channel: () => 'Canal',
    },
    it: {
        PermitType: () => "Tipo d'autorizzazione",
        PermitId: () => 'Numero autorizzazione',
        Created: () => 'Emessa',
        OwnedBy: () => 'Conto Parkingpay',
        EditCaption: () => 'Modifica autorizzazione',
        EndDate: () => 'Fine',
        FixedZones: (zoneCount: number) => `${zoneCount} zone fisse`,
        OnlineUser: () => 'UTENTE ONLINE',
        Price: () => 'Prezzo',
        PriceMultiplier: () => 'Categoria di prezzo',
        RefundedNote: (amount: string) => `(di cui ${amount} rimborsati)`,
        RefundedBy: () => 'Rimborsata',
        RemarkLabel: () => 'Osservazioni interne',
        AmountLabel: () => 'Importo residuo',
        RefundConfirmationCaption: () => '',
        RefundConfirmationHeading: () => "Rimborsare quest'autorizzazione?",
        StartDate: () => 'Inizio',
        StornoConfirmationCaption: () =>
            "Il prezzo dell'autorizzazione non verrà rimborsato automaticamente sul conto dell'utente",
        StornoConfirmationHeading: () => "Annullare quest'autorizzazione?",
        ValidZones: () => 'Valida a',
        VignetteInvoiced: () => 'Vignetta/e fatturate',
        UntilRecall: () => 'a revoca',
        Channel: () => 'Canale',
    },
};

export const print: Translations<PrintSelectionSlideIn.Texts> = {
    de: {
        Caption: () => 'Belege drucken',
        Heading: () => 'Belege zum drucken auswählen',
        PermitPrint: () => 'Bewilligung drucken',
        VignettePrint: (lpNr: string) => `Beiblatt Vignette (${lpNr})`,
    },
    fr: {
        Caption: () => 'Imprimer documents',
        Heading: () => 'Sélectionner les document à imprimer',
        PermitPrint: () => 'Imprimer autorisation',
        VignettePrint: (lpNr: string) => `Feuille jointe vignette (${lpNr})`,
    },
    it: {
        Caption: () => 'Stampa documenti',
        Heading: () => 'Selezionare i documenti da stampare',
        PermitPrint: () => 'Stampa autorizzazione',
        VignettePrint: (lpNr: string) =>
            `Foglio accompagnatorio vignetta (${lpNr})`,
    },
};
