import { css } from '@emotion/css';

import { getOrElse, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional, HasChildren } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { OutsideClickable } from '../OutsideClickable.tsx';
import { DropdownButton } from '../buttons/TextButton.tsx';
import React from 'react';

const itemCss = (disabled?: boolean | null) =>
    css({
        padding: '8px 25px',
        ...Typo.button,
        ...Typo.robotoRegular,
        color: Colors.darkblue,
        display: 'block',
        textDecoration: 'none',
        '&:hover': {
            background: Colors.rgba(Colors.lightblue, 0.3),
        },
        ...(disabled && {
            color: Colors.rgba(Colors.darkblue, 0.6),
            '&:hover': {
                color: Colors.rgba(Colors.darkblue, 0.6),
                background: Colors.white,
            },
        }),
    });

interface ButtonDropdownItemProps {
    onClick: ClickHandler;
    label: string | JSX.Element;
    disabled?: Maybe<boolean>;
}

export const ButtonDropdownItem = (p: ButtonDropdownItemProps) => {
    const button = (
        <div className={itemCss(p.disabled)} data-disabled={p.disabled}>
            {p.label}
        </div>
    );

    if (!p.disabled) {
        return (
            <Clickable element="div" onClick={() => p.onClick()}>
                {button}
            </Clickable>
        );
    } else {
        return button;
    }
};

interface ButtonDropdownLinkItemProps {
    href: string;
    label: React.ReactNode;
    disabled?: boolean;
}

export const ButtonDropdownLinkItem = (p: ButtonDropdownLinkItemProps) => (
    <a
        className={itemCss(p.disabled)}
        href={p.disabled ? undefined : p.href}
        target="_blank"
        rel="noreferrer"
    >
        {p.label}
    </a>
);

export const ButtonDropdownSeparator = () => (
    <div
        className={css({
            height: 7,
            margin: '0 25px 9px 25px',
            borderBottom: `1px solid ${Colors.rgba(Colors.darkblue, 0.2)}`,
        })}
    />
);

type ButtonDropdownItemsProps = HasChildren;
export const ButtonDropdownItems = (p: ButtonDropdownItemsProps) => (
    <div
        className={css({
            position: 'absolute',
            zIndex: 1000,
            ...paper(2),
            top: 28,
            right: 4,
            minWidth: 320,
            background: Colors.white,
            borderTop: `4px solid ${Colors.middleblue}`,
            padding: '8px 0',
        })}
    >
        {p.children}
    </div>
);

interface ButtonDropdownProps extends HasChildren {
    label: React.ReactNode;
}

interface ButtonDropdownState {
    open: boolean;
}

export class ButtonDropdown extends React.Component<
    ButtonDropdownProps,
    ButtonDropdownState
> {
    constructor(p: ButtonDropdownProps) {
        super(p);
        this.state = {
            open: false,
        };
    }

    renderItems() {
        return React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
                // might be a ButtonDropdownItem with disabled flag. Otherwise, the flag is ignored
                const p = child.props as ButtonDropdownItemProps;
                return (
                    <Clickable
                        element="div"
                        onClick={() => this.setState({ open: false })}
                        disabled={getOrElse(p.disabled, false)}
                    >
                        {child}
                    </Clickable>
                );
            } else {
                return child as React.ReactChild;
            }
        });
    }

    render() {
        const outsideClick = (e: Event) => {
            if (this.state.open) {
                this.setState({ open: false });
                e.stopPropagation();
            }
        };
        return (
            <OutsideClickable onClickOutside={outsideClick}>
                <div
                    className={css({
                        position: 'relative',
                    })}
                >
                    <DropdownButton
                        label={this.props.label}
                        onClick={() =>
                            this.setState({ open: !this.state.open })
                        }
                        open={this.state.open}
                    />
                    <Conditional c={this.state.open}>
                        <ButtonDropdownItems>
                            {this.renderItems()}
                        </ButtonDropdownItems>
                    </Conditional>
                </div>
            </OutsideClickable>
        );
    }
}
