import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ClearancePermitListState from '../state/ClearancePermitListState.ts';

export const selectPermit = (store: Flux.Store, id: number): string => {
    new ClearancePermitListState.StateSlice(store).selectPermit(id);
    return 'ClearenacePermitListActions-selectPermit';
};

export const selectClearance = (store: Flux.Store, id: number): string => {
    new ClearancePermitListState.StateSlice(store).selectClearance(id);
    return 'ClearenacePermitListActions-selectClearance';
};
