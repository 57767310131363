import {
    ConfirmationHeader,
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
} from './Slidein.tsx';
import { Option, SlideInSubheader } from './SlideInSubheader.tsx';
import { ButtonSpecialState } from '../buttons/IconButton.tsx';
import { Translation, Translations } from '../../common/i18n/Text.ts';
import {
    getOrElse,
    isDefined,
    isUndefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { DatePicker, DateSelectHandler } from '../date-picker/DatePicker.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DivGenerator } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Logger from 'dg-web-shared/lib/Logger.ts';
import moment from 'moment';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import React from 'react';

const texts: Translations<DatePickerSlideInTexts> = {
    de: {
        ModeDefined: (): string => 'Definiert',
        ModeMaximum: (): string => 'Maximale Dauer',
    },
    it: {
        ModeDefined: (): string => 'Definita',
        ModeMaximum: (): string => 'Durata massima',
    },
    fr: {
        ModeDefined: (): string => 'Définie',
        ModeMaximum: (): string => 'Durée maximale',
    },
};

export interface DatePickerSlideInTexts {
    ModeDefined: Translation;
    ModeMaximum: Translation;
}

interface DatePickerSlideInProps {
    open: boolean;
    caption: string;
    outsideBody: boolean;
    selectedDate: Maybe<moment.Moment>;
    onSelect: DateSelectHandler;
    onClose: () => void;
    minDate?: Maybe<moment.Moment>;
    maxDate?: Maybe<moment.Moment>;
    canSelectMaxDate?: boolean;
    language: string;
}

enum DateSelectionMethod {
    DEFINED,
    MAXIMUM,
}

const containerStyle = css({
    width: 280,
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const SlideInDatePickerContainer = DivGenerator(containerStyle);

interface DatePickerSlideInState {
    selectionMethod: DateSelectionMethod;
    selectedDate: Maybe<moment.Moment>;
}

export class DatePickerSlideIn extends React.Component<
    DatePickerSlideInProps,
    DatePickerSlideInState
> {
    static displayName = 'DatePickerSlideIn';

    constructor(props: DatePickerSlideInProps) {
        super(props);
        this.state = DatePickerSlideIn.getInitialState(props);
    }

    static getInitialState(
        props: DatePickerSlideInProps,
    ): DatePickerSlideInState {
        const selectionMethod: DateSelectionMethod =
            props.canSelectMaxDate && props.selectedDate === props.maxDate
                ? DateSelectionMethod.MAXIMUM
                : DateSelectionMethod.DEFINED;
        return {
            selectionMethod,
            selectedDate: props.selectedDate,
        };
    }

    txt(): DatePickerSlideInTexts {
        return texts[this.props.language];
    }

    onChangeSelectionMethod(selectedMethod: DateSelectionMethod): void {
        if (selectedMethod === DateSelectionMethod.MAXIMUM) {
            if (isUndefined(this.props.maxDate)) {
                Logger.warning(
                    'required prop maxDate is missing with canSelectMaxDate',
                );
                return;
            }
            const maxDate: moment.Moment = this.props.maxDate;
            this.setState({
                selectionMethod: selectedMethod,
                selectedDate: maxDate,
            });
            this.props.onSelect(maxDate);
        } else {
            this.setState({
                selectedDate: null,
                selectionMethod: selectedMethod,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: DatePickerSlideInProps): void {
        if (this.props.open !== nextProps.open) {
            // reset selectedDate when opening
            this.setState(DatePickerSlideIn.getInitialState(nextProps));
        }
    }

    render() {
        const selectionMethodOptions: Option<DateSelectionMethod>[] = [
            {
                id: DateSelectionMethod.DEFINED,
                displayText: this.txt().ModeDefined(),
            },
            {
                id: DateSelectionMethod.MAXIMUM,
                displayText: this.txt().ModeMaximum(),
            },
        ];
        const showSubheader: boolean = getOrElse<boolean>(
            this.props.canSelectMaxDate,
            false,
        );
        const hidePicker =
            this.state.selectionMethod === DateSelectionMethod.MAXIMUM;

        return (
            <HalfSlideIn
                open={this.props.open}
                outsideBody={this.props.outsideBody}
            >
                <SecondLevelHeader onClose={this.props.onClose}>
                    <SlideInHeaderTexts
                        title={this.props.caption}
                        hasLeftIcon={false}
                    />
                </SecondLevelHeader>
                {showSubheader && (
                    <SlideInSubheader
                        options={selectionMethodOptions}
                        value={this.state.selectionMethod}
                        onSelect={method =>
                            this.onChangeSelectionMethod(method)
                        }
                    />
                )}
                <SlideInBody hasSubheader={showSubheader}>
                    <SlideInDatePickerContainer>
                        <SelectedDateDisplay
                            selectedDate={this.props.selectedDate}
                            caption={this.props.caption}
                        />
                        {!hidePicker && (
                            <DatePicker
                                selectedDate={this.state.selectedDate}
                                currentDate={getOrElse(
                                    this.props.minDate,
                                    moment(),
                                )}
                                onSelect={this.props.onSelect}
                                minDate={this.props.minDate}
                                maxDate={this.props.maxDate}
                                language={this.props.language}
                            />
                        )}
                    </SlideInDatePickerContainer>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

interface ConfirmableProps {
    open: boolean;
    title: string;
    outsideBody: boolean;
    defaultValue: Maybe<moment.Moment>;
    minDate?: Maybe<moment.Moment>;
    maxDate?: Maybe<moment.Moment>;
    onCancel: () => void;
    onConfirm: (selectedDate: moment.Moment) => void;
    language: string;
}

interface ConfirmableState {
    selectedDate: Maybe<moment.Moment>;
}

export class ConfirmableDatePickerSlideIn extends React.Component<
    ConfirmableProps,
    ConfirmableState
> {
    static displayName = 'ConfirmableDatePickerSlideIn';

    constructor(props: ConfirmableProps) {
        super(props);
        this.state = { selectedDate: null };
    }

    selectedDateString(selectedDate: Maybe<moment.Moment>): string {
        return thenElse(selectedDate, d => Formatter.dayMonthYear(d), '');
    }

    render() {
        const selectedDate = getOrElse(
            this.state.selectedDate,
            this.props.defaultValue,
        );
        return (
            <HalfSlideIn
                open={this.props.open}
                outsideBody={this.props.outsideBody}
            >
                <ConfirmationHeader
                    language={this.props.language}
                    title={this.props.title}
                    onCancel={() => {
                        this.setState({ selectedDate: null });
                        this.props.onCancel();
                    }}
                    confirmButtonSpecialState={
                        isUndefined(selectedDate)
                            ? ButtonSpecialState.DISABLED
                            : null
                    }
                    onConfirm={() => {
                        if (isDefined(selectedDate)) {
                            this.setState({ selectedDate: null });
                            this.props.onConfirm(selectedDate);
                        }
                    }}
                />
                <SlideInBody>
                    <div className={containerStyle}>
                        <DatePicker
                            selectedDate={selectedDate}
                            currentDate={moment()}
                            onSelect={(v: moment.Moment) =>
                                this.setState({ selectedDate: v })
                            }
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            language={this.props.language}
                        />
                    </div>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

interface SelectedDateDisplayProps {
    selectedDate: Maybe<moment.Moment>;
    caption: string;
}

function SelectedDateDisplay(props: SelectedDateDisplayProps): JSX.Element {
    const dateString = thenElse(
        props.selectedDate,
        d => Formatter.dayMonthYear(d),
        '',
    );
    return (
        <div
            className={css({
                ...Typo.captionC1,
                marginBottom: 20,
            })}
        >
            {props.caption}
            <span
                className={css({
                    ...Typo.captionC2,
                    marginLeft: 5,
                })}
            >
                {dateString}
            </span>
        </div>
    );
}
