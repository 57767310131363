import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as TomState from '../state/TomState.ts';
import * as TomLayoutState from '../state/TomLayoutState.ts';
import { ParsedTom } from 'dg-web-shared/model/Tom.ts';

export const editBaseData = AsyncRequest.request(
    Http.OperatorAccount.Toms.editBaseData,
    (store: Flux.Store, res: Response): string => {
        TomState.EditBaseDataResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            TomState.Edit.reset(store);
            TomState.List.reset(store);
            TomState.Detail.refetchSameContext(store, true);
            TomLayoutState.Layout.stateWrite(store, {
                showTomBaseDataValidation: false,
            });
        } else {
            TomLayoutState.Layout.stateWrite(store, {
                showTomBaseDataValidation: true,
            });
        }
        return 'TomAction-editBaseData';
    },
);

export const makeEditPayload = (
    edit: TomState.Edit.State,
    tom: ParsedTom | null,
): Http.OperatorAccount.Toms.EditBaseDataPayload => {
    return emptyStringsToNull({
        name: getOrElse(edit.name, tom ? tom.name : ''),
        operatorExternalId: getOrElse(
            edit.operatorExternalId,
            tom ? tom.operatorExternalId : '',
        ),
        street: getOrElse(edit.street, tom ? tom.street : ''),
        zipCode: getOrElse(edit.zipCode, tom ? tom.zipCode : ''),
        place: getOrElse(edit.place, tom ? tom.place : ''),
    });
};

interface GenericObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

function emptyStringsToNull<V extends GenericObject>(
    obj: V,
    exceptionKeys: string[] = [],
    applyToNestedObjects = false,
): V {
    const values = Object.assign({}, obj);
    Object.keys(values).forEach(key => {
        if (values[key] === '' && exceptionKeys.indexOf(key) === -1) {
            (values as GenericObject)[key] = null;
        } else if (applyToNestedObjects && values[key] instanceof Object) {
            (values as GenericObject)[key] = emptyStringsToNull(
                values[key],
                exceptionKeys,
                applyToNestedObjects,
            );
        }
    });
    return values;
}
