import { Translations, Translation } from '../../common/i18n/Text.ts';
/* tslint:disable:typedef max-line-length */

interface Texts {
    AddLicensePlateToPermit: Translation;
}

export const permitLicensePlateSlideInTexts: Translations<Texts> = {
    de: {
        AddLicensePlateToPermit: () => 'Kennzeichen zur Bewilligung hinzufügen',
    },
    fr: {
        AddLicensePlateToPermit: () =>
            "Ajouter l'immatriculation à l'autorisation",
    },
    it: {
        AddLicensePlateToPermit: () => "Aggiungere la targa all'autorizzazione",
    },
};

/* tslint:enable:typedef max-line-length */
