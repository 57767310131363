import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as PermitCreateState from '../../../permit-detail/state/PermitCreateState.ts';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import * as ClearanceCreateState from '../../../clearance-detail/state/ClearanceCreateState.ts';
import { Translation } from '../../../common/i18n/Text.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { addButtonTexts } from '../../i18n/ClearancePermitListTexts.tsx';
import * as OperatorDataState from '../../../common/state/OperatorDataState.ts';
import {
    ButtonDropdown,
    ButtonDropdownItem,
    ButtonDropdownSeparator,
} from '../../../ui/slidein/ButtonDropdown.tsx';

interface State {
    settings: SettingsState.State;
    operatorData: OperatorDataState.State;
    currentLogin: CurrentOperatorLoginState.State;
    permitCreateLayout: PermitCreateState.Layout.State;
}

export interface Texts {
    AddMenuTitle: Translation;
    AddClearance: Translation;
    AddPermit: Translation;
    NewPermitTooltip: Translation;
}

interface Props {
    label: React.ReactNode;
    children?: React.ReactNode;
}

export const AddMenu = Flux.selectState<Props, State>(
    (store: Flux.Store) => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            operatorData: OperatorDataState.get(store),
            currentLogin: CurrentOperatorLoginState.get(store),
            permitCreateLayout: PermitCreateState.Layout.get(store),
        };
    },
    (p): JSX.Element | null => {
        const login = p.currentLogin.data;
        const operator = p.operatorData.data;
        const whitelistCreateAllowed =
            operator &&
            operator.licensePlatePermitSettings.whitelistActive &&
            login &&
            login.permissions.whitelistCreate;
        const permitAllowed = login && login.permissions.permitsWrite;
        const texts = addButtonTexts[p.settings.language];
        if (
            p.permitCreateLayout.createEnabled ||
            (!permitAllowed && !whitelistCreateAllowed)
        ) {
            return null;
        } else {
            return (
                <ButtonDropdown label={p.label}>
                    {whitelistCreateAllowed && (
                        <ButtonDropdownItem
                            label={texts.AddClearance()}
                            onClick={() =>
                                p.update(store =>
                                    ClearanceCreateState.Layout.stateWrite(
                                        store,
                                        { createEnabled: true },
                                    ),
                                )
                            }
                        />
                    )}
                    {permitAllowed && (
                        <ButtonDropdownItem
                            label={texts.AddPermit()}
                            onClick={() =>
                                p.update(store =>
                                    PermitCreateState.Layout.stateWrite(store, {
                                        createEnabled: true,
                                        permitTypeSelectionOpen: true,
                                    }),
                                )
                            }
                        />
                    )}
                    {(whitelistCreateAllowed || permitAllowed) &&
                        p.children && <ButtonDropdownSeparator />}
                    {p.children}
                </ButtonDropdown>
            );
        }
    },
);
