import { Box, Popper, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ALLOWED_LANGUAGE_CODES } from '../../common/i18n/Text.ts';
import { Theme } from '@mui/material/styles';
import { Store, useStore, useUpdate } from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';

export function ParkingPortalFooter() {
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                height: 54,
            })}
        >
            <LanguageChooser />
        </Box>
    );
}

function setLanguage(store: Store, lang: string) {
    new SettingsState.StateSlice(store).writeLanguage(lang);
    return 'setLanguage';
}

function LanguageChooser() {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const update = useUpdate();

    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    }));

    function handleToggle(evt: MouseEvent<HTMLElement>) {
        setAnchorEl(evt.currentTarget);
        setPopoverOpen(o => !o);
    }

    function generateStyle(theme: Theme) {
        return {
            border: 'solid 1px',
            borderBottom: 'solid 0px',
            borderColor: theme.palette.primary.contrastText,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            paddingX: theme.spacing(1),
            paddingY: theme.spacing(0.5),
            textAlign: 'center',
            textTransform: 'uppercase',
            cursor: 'pointer',
            width: 36,
        };
    }

    return (
        <>
            <Typography
                component="button"
                onClick={handleToggle}
                variant="body2"
                sx={theme => ({
                    ...generateStyle(theme),
                    borderBottom: 'solid 1px',
                })}
            >
                {storeState.settings.language}
            </Typography>
            <Popper anchorEl={anchorEl} open={popoverOpen} placement="top-end">
                {ALLOWED_LANGUAGE_CODES.filter(language => {
                    return language !== storeState.settings.language;
                }).map(language => (
                    <Typography
                        component="div"
                        variant="body2"
                        key={language}
                        onClick={() => {
                            update(store => setLanguage(store, language));
                            setPopoverOpen(false);
                        }}
                        sx={generateStyle}
                    >
                        {language.toUpperCase()}
                    </Typography>
                ))}
            </Popper>
        </>
    );
}
