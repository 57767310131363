import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { RestrictedComponent } from '../../app/components/RestrictedComponent.tsx';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { CustomPermissionCheckers } from '../../app/state/Permission.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Body, Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import { texts } from '../i18n/CustomerAccountsTexts.tsx';
import * as CustomerAccountState from '../state/CustomerAccountState.ts';
import { CustomerClearanceCreateSlideIn } from './CustomerClearanceCreateSlideIn.tsx';
import { CustomerClearanceDetailSlideIn } from './CustomerClearanceDetailSlideIn.tsx';
import { CustomerClearanceTable } from './CustomerClearanceTable.tsx';
import { PermitTypeSelectionSlideIn } from './PermitTypeSelectionSlideIn.tsx';

interface CustomerAccountsState {
    layout: CustomerAccountState.Layout.State;
    filter: CustomerAccountState.Filter.State;
    list: CustomerAccountState.List.State;
    settings: SettingsState.State;
    operatorData: OperatorDataState.State;
    currentLogin: CurrentOperatorLoginState.State;
}

export interface Texts {
    CustomerNr: Translation;
    Name: Translation;
    Company: Translation;
    Active: Translation;
    Locked: Translation;
    Balance: Translation;
    PaymentType: Translation;
    ActivatedOn: Translation;
    LockedValue: Translation;
    NotLockedValue: Translation;
    DetailHeader: Translation;

    CustomerSearchTitle: Translation;
    CustomerWhitelistTitle: Translation;
}

export class CustomerAccounts extends Flux.Container<CustomerAccountsState> {
    stateSelector(): CustomerAccountsState {
        const layout = CustomerAccountState.Layout.get(this.props.allState);
        const filter = CustomerAccountState.Filter.get(this.props.allState);
        const operatorData = OperatorDataState.get(this.props.allState);
        const currentLogin = CurrentOperatorLoginState.get(this.props.allState);

        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout,
            list: CustomerAccountState.List.get(
                this.props.allState,
                filter.search &&
                    CustomerAccountState.Filter.searchActive(filter)
                    ? {
                          search: filter.search,
                      }
                    : null,
            ),
            filter,
            operatorData,
            currentLogin,
        };
    }

    txt(): Texts {
        return texts[this.state.settings.language];
    }

    renderConditionalTabs() {
        const operator = this.state.operatorData.data;
        const currentLogin = this.state.currentLogin.data;

        if (isDefined(operator) && isDefined(currentLogin)) {
            return (
                <RestrictedComponent
                    route={OperatorAppRoutes.CustomerAccounts}
                    permissionChecker={
                        CustomPermissionCheckers.customerWhitelistAllowed
                    }
                >
                    <TabItem
                        active={true}
                        description={this.txt().CustomerWhitelistTitle()}
                        onClick={null}
                    />
                </RestrictedComponent>
            );
        }
    }

    render() {
        return (
            <Container>
                <Tabs>{this.renderConditionalTabs()}</Tabs>
                <Body
                    disabled={false}
                    dependingStateSlices={[this.state.list]}
                    slideIns={
                        <div>
                            <CustomerClearanceCreateSlideIn
                                allState={this.props.allState}
                            />
                            <CustomerClearanceDetailSlideIn
                                allState={this.props.allState}
                            />
                            <PermitTypeSelectionSlideIn
                                allState={this.props.allState}
                            />
                        </div>
                    }
                >
                    <CustomerClearanceTable allState={this.props.allState} />
                </Body>
            </Container>
        );
    }
}
