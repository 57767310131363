import { isUndefined, Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as PermitCreateTexts from '../i18n/PermitCreateTexts.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import moment from 'moment';
import { DatePickerSlideIn } from '../../ui/slidein/DatePickerSlideIn.tsx';

export interface Texts {
    FromCaption: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: PermitCreateState.Layout.State;
    config: PermitCreateState.Config.State;
    permitType: Maybe<PermitTypeState.PermitType>;
    calc: PermitCreateState.Calc.State;
}

export class PermitCreateValidityFromDateSlideIn extends Flux.Container<State> {
    static displayName = 'PermitCreateValidityFromDateSlideIn';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: PermitCreateState.Layout.get(this.props.allState),
            config: PermitCreateState.Config.get(this.props.allState),
            permitType: PermitTypeState.getById(
                this.props.allState,
                PermitCreateState.Config.get(this.props.allState).permitTypeId,
            ),
            calc: PermitCreateState.Calc.get(this.props.allState),
        };
    }

    txt(): Texts {
        return PermitCreateTexts.validityFromDate[this.state.settings.language];
    }

    onDateSelected(selectedDate: moment.Moment): void {
        this.update((store: Flux.Store): string => {
            PermitCreateState.Config.stateWrite(store, {
                startDate: selectedDate,
            });
            PermitCreateState.Layout.stateWrite(store, {
                validityFromDatePickerOpen: false,
            });
            PermitCreateState.Calc.reset(store);
            return 'PermitCreateValidityFromDateSlideIn-selectDate';
        });
    }

    render() {
        const permitType = this.state.permitType;

        if (isUndefined(permitType)) {
            return null;
        } else {
            // don't allow selection of date after selected end date
            const maxDate = thenElse(
                this.state.config.endDate,
                endDate => moment.min(permitType.fromRange.end, endDate),
                permitType.fromRange.end,
            );

            return (
                <DatePickerSlideIn
                    open={this.state.layout.validityFromDatePickerOpen}
                    caption={this.txt().FromCaption()}
                    onClose={() =>
                        this.update(store =>
                            PermitCreateState.Layout.stateWrite(store, {
                                validityFromDatePickerOpen: false,
                            }),
                        )
                    }
                    selectedDate={
                        this.state.calc.data?.validFrom ??
                        this.state.config.startDate
                    }
                    onSelect={(selectedDate: moment.Moment) =>
                        this.onDateSelected(selectedDate)
                    }
                    minDate={permitType.fromRange.start}
                    maxDate={maxDate}
                    language={this.state.settings.language}
                    outsideBody
                />
            );
        }
    }
}
