import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import moment from 'moment';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import * as Http from '../../api/Http.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import { FieldSetting } from '../../common/state/OperatorDataState.ts';
import {
    getLicensePlateStates,
    LicensePlateState,
} from 'dg-web-shared/lib/LicensePlateValidation.ts';
import {
    getRfidCardStates,
    RfidCardState,
} from 'dg-web-shared/lib/RfidCardValidation.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';

type ClearanceCreateDTO = Http.OperatorAccount.Clearances.ClearanceCreateDTO;

export interface Overlap {
    id: number;
    permitTypeId: number;
    description: string;
    validFrom: moment.Moment;
    validTo: moment.Moment;
    operatorLoginId: number;
}

export interface Overlaps {
    lp: Overlap[];
    badge: Overlap[];
}

export namespace Layout {
    export interface State {
        createEnabled: boolean;
        lpCountrySelectionOpen: boolean;
        confirmPressedWhileError: boolean;
        validFromDatePickerOpen: boolean;
        validToDatePickerOpen: boolean;
        permitTypeSelectionOpen: boolean;
        priceMultiplierOfPermitTypeOpen: Maybe<number>;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'clearances-CleraranceCreateState.Layout',
        {
            createEnabled: false,
            confirmPressedWhileError: false,
            lpCountrySelectionOpen: false,
            validFromDatePickerOpen: false,
            validToDatePickerOpen: false,
            permitTypeSelectionOpen: false,
            priceMultiplierOfPermitTypeOpen: null,
        },
    );
}

export namespace Create {
    export interface State {
        badgeLabelNr: string;
        licensePlateNumber: string;
        type: LicensePlateType;
        country: string;
        permitTypes: number[];
        validFrom: moment.Moment;
        validTo: Maybe<moment.Moment>;
        personalNumber: Maybe<string>;
        contractNumber: Maybe<string>;
        infos: { [idx: string]: string };
        priceModifierIds: { [idx: number]: number };
        carTypeDescriptions: string[];
        licensePlateValid: boolean;
    }

    export const s = Flux.generateState<State>(
        'clearances-CleraranceCreateState.Clearances',
        {
            badgeLabelNr: '',
            licensePlateNumber: '',
            type: LicensePlateType.CAR,
            country: 'CH',
            permitTypes: [],
            validFrom: moment().startOf('day'),
            validTo: null,
            personalNumber: null,
            contractNumber: null,
            infos: {},
            priceModifierIds: {},
            carTypeDescriptions: [],
            licensePlateValid: false,
        },
    );

    export const reset = s.reset;
    export const get = s.get;
    export const stateWrite = (
        store: Flux.Store,
        state: Partial<State>,
    ): string => {
        const name = s.stateWrite(store, state);
        Validation.reset(store);
        CarType.reset(store);
        return name;
    };

    export const stateWriteWithoutReset = s.stateWrite;

    export const setAdditionalInfo = (
        store: Flux.Store,
        args: { info: string; permitTypeId?: number },
    ) => {
        s.set(store, (s: State): State => {
            s.infos[!args.permitTypeId ? 0 : args.permitTypeId] = args.info;
            return s;
        });
        Validation.reset(store);
        return 'ClearanceCreateSetAdditionalInfo';
    };

    const getWhitelistGroup = (
        types: PermitTypeState.PermitType[],
        typeId: number,
    ): Maybe<number> => {
        for (const t of types) {
            if (t.id === typeId) {
                return t.whitelistGroup;
            }
        }
    };

    export const setPriceMultiplier = (
        store: Flux.Store,
        args: { id: number; factor: number },
    ): string => {
        s.set(store, (s: State): State => {
            s.priceModifierIds = { ...s.priceModifierIds };
            s.priceModifierIds[args.id] = args.factor;
            return s;
        });
        Validation.reset(store);
        return 'ClearanceCreateState-setPriceMultiplier';
    };

    export const setSelectedPermitTypes = (
        store: Flux.Store,
        newIds: number[],
    ): string => {
        const create = get(store);
        const permitTypes = PermitTypeState.get(store).data;
        // if we add exactly one permitTypeId to the array, we look for whitelistgroup and add all permitTypes
        // in the same group
        if (
            newIds.length === 1 ||
            newIds.length - create.permitTypes.length === 1 ||
            isDefined(permitTypes)
        ) {
            const missingId = newIds.filter(
                (i: number) => create.permitTypes.indexOf(i) === -1,
            )[0];
            const group = getWhitelistGroup(permitTypes, missingId);
            if (isDefined(group)) {
                for (const type of permitTypes) {
                    if (
                        type.whitelistGroup === group &&
                        newIds.indexOf(type.id) === -1
                    ) {
                        newIds.push(type.id);
                    }
                }
            }
        }
        stateWrite(store, { permitTypes: newIds });
        Validation.reset(store);
        return 'ClearanceCreateState-setSelectedPermitTypeIds';
    };

    export const setCarTypeDescription = (
        store: Flux.Store,
        args: { value: string; second: boolean },
    ): string => {
        const copy = get(store).carTypeDescriptions.slice();
        if (args.second && copy.length > 1) {
            copy[1] = args.value;
        } else {
            if (copy.length === 0) {
                copy.push(args.value);
            } else {
                copy[0] = args.value;
            }
        }
        return stateWriteWithoutReset(store, { carTypeDescriptions: copy });
    };
}

export namespace Validation {
    interface Data {
        overlappingClearances: Overlaps;
        permitTypesWithMissingPriceMultipliers: number[];
    }

    const validateClearance = AsyncRequest.request(
        Http.OperatorAccount.Clearances.validate,
        (store: Flux.Store, res: Response): string => {
            setResponse(store, res);
            return 'PermitCreateState-calcPrice';
        },
    );
    const sideEffects = (store: Flux.Store, state: State): void => {
        const operatorData = OperatorDataState.get(store).data;
        const permitTypes = PermitTypeState.get(store).data;
        let payload: Maybe<ClearanceCreateDTO> = null;
        if (isDefined(operatorData)) {
            payload = makeUnvalidatedPayload(
                Create.get(store),
                operatorData.licensePlatePermitSettings,
                permitTypes,
                CreateResponse.get(store),
                state,
            ).payload;
        }
        if (state.shouldFetch && isDefined(payload)) {
            store.update(validateClearance, payload);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parseOverlap = (ol: any): Overlap => {
        return {
            id: ol.id,
            description: ol.description,
            permitTypeId: ol.permitTypeId,
            validFrom: Parser.isoToMoment(ol.validFrom),
            validTo: Parser.isoToMoment(ol.validTo),
            operatorLoginId: ol.operatorLoginId,
        };
    };

    const parseBody = (body: Data): Data => {
        return {
            overlappingClearances: {
                lp: body.overlappingClearances.lp.map(parseOverlap),
                badge: body.overlappingClearances.badge.map(parseOverlap),
            },
            permitTypesWithMissingPriceMultipliers:
                body.permitTypesWithMissingPriceMultipliers,
        };
    };

    export type State = ServerStateSlice.ServerState<Maybe<Data>>;
    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<Maybe<Data>>(
            'clearance-CleraranceCreateState.Vallidation',
            () => null,
            sideEffects,
            parseBody,
        );
}

export namespace CreateResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'clearance-CleraranceCreateState.CreateResponse',
        () => null,
    );
}

export type FormError =
    | 'lpEmpty'
    | 'badgeEmpty'
    | 'contractNumberMissing'
    | 'personalNumberMissing'
    | 'noPermitTypes';

export interface FormErrors {
    lp: LicensePlateState[];
    badge: RfidCardState[];
    other: FormError[];
}

const formErrors = (
    state: Create.State,
    lpPermitSettings: Maybe<OperatorDataState.LicensePlatePermitSettings>,
    permitTypes: PermitTypeState.PermitType[],
): FormErrors => {
    const identSelected = PermitTypeState.getIdentificationTypesGivenSelection(
        permitTypes,
        state.permitTypes,
    );
    const hasLpIdentification = identSelected.indexOf('LicensePlate') > -1;
    const hasBadgeIdentification = identSelected.indexOf('Badge') > -1;
    const errors: FormErrors = {
        lp: hasLpIdentification
            ? getLicensePlateStates({
                  licensePlateNr: state.licensePlateNumber,
                  licensePlateCountryId: state.country,
              })
            : [],
        badge: hasBadgeIdentification
            ? getRfidCardStates(state.badgeLabelNr)
            : [],
        other: [],
    };
    if (state.permitTypes.length === 0) {
        errors.other.push('noPermitTypes');
    }
    if (isDefined(lpPermitSettings)) {
        if (
            lpPermitSettings.whitelistContractNumber ===
                FieldSetting.required &&
            !state.contractNumber
        ) {
            errors.other.push('contractNumberMissing');
        }
        if (
            lpPermitSettings.whitelistPersonalNumber ===
                FieldSetting.required &&
            !state.personalNumber
        ) {
            errors.other.push('personalNumberMissing');
        }
    }

    if (hasLpIdentification && !state.licensePlateNumber) {
        errors.other.push('lpEmpty');
    }
    if (hasBadgeIdentification && !state.badgeLabelNr) {
        errors.other.push('badgeEmpty');
    }
    return errors;
};

interface PayloadAndFormErrors {
    formErrors: FormErrors;
    payload: Maybe<ClearanceCreateDTO>;
}

export const makeUnvalidatedPayload = (
    state: Create.State,
    lpPermitSettings: OperatorDataState.LicensePlatePermitSettings | null,
    permitTypes: PermitTypeState.PermitType[],
    responseState: CreateResponse.State,
    State: Validation.State,
): PayloadAndFormErrors => {
    const errors = formErrors(state, lpPermitSettings, permitTypes);
    const res = {
        badgeLabelNr: state.badgeLabelNr,
        licensePlateNumber: state.licensePlateNumber,
        type: state.type,
        country: state.country,
        permitTypes: state.permitTypes,
        validFrom: state.validFrom,
        validTo: state.validTo,
        personalNumber: state.personalNumber,
        contractNumber: state.contractNumber,
        infos: state.infos,
        priceModifierIds: state.priceModifierIds,
        carTypeDescriptions: state.carTypeDescriptions,
    };
    if (
        errors.badge.length > 0 ||
        errors.lp.length > 0 ||
        errors.other.length > 0 ||
        responseState.pending ||
        State.pending ||
        (lpPermitSettings && !lpPermitSettings.whitelistActive)
    ) {
        return {
            formErrors: errors,
            payload: null,
        };
    } else {
        return {
            formErrors: errors,
            payload: res,
        };
    }
};

export namespace CarType {
    const getCarType = AsyncRequest.request(
        Http.OperatorAccount.Clearances.getCarType,
        (store: Flux.Store, res: Response): string => {
            const actionName = setResponse(store, res);
            const create = Create.get(store);
            const state = get(store);
            if (
                res.statusCode.cls.success &&
                create.carTypeDescriptions.length === 0 &&
                state.data
            ) {
                Create.stateWriteWithoutReset(store, {
                    carTypeDescriptions: state.data.carTypes,
                });
            }
            return actionName;
        },
    );

    const sideEffects = (store: Flux.Store, state: State): void => {
        const config = Create.get(store);
        const operator = OperatorDataState.get(store);
        const carTypeEnabled =
            operator.data && operator.data.settings.carTypeDescription;
        if (carTypeEnabled && config.licensePlateNumber && state.shouldFetch) {
            store.update(getCarType, {
                lpNr: config.licensePlateNumber,
                type: config.type,
                country: config.country,
            });
        }
    };

    export type State = ServerStateSlice.ServerState<{ carTypes: string[] }>;
    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<{
            carTypes: string[];
        }>(
            'clearance-CleraranceCreateState.CarType',
            () => ({ carTypes: [] }),
            sideEffects,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (body: any): { carTypes: string[] } => body,
        );
}

export const resetAllStates = (store: Flux.Store) => {
    Layout.reset(store);
    Create.reset(store);
    Validation.reset(store);
    CreateResponse.reset(store);
    CarType.reset(store);
};
