import { useState } from 'react';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import {
    getOrElse,
    isDefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { operatorAppApiUrl } from '../../api/Http.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { ConfirmationHeader, HalfSlideIn } from '../../ui/slidein/Slidein.tsx';
import * as BadgeState from '../state/BadgeState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import { useForm } from 'react-hook-form';
import {
    AddBadgeSlideInBody,
    BadgeFields,
    BadgePayload,
} from '../AddBadgeSlideInBody.tsx';
import {
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Localized } from '../../common/components/Localized.tsx';

export interface Badge {
    id: number;
    badgeLabelNr: string;
}

enum DisplayMode {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

const addBadgeToPermit = (store: Flux.Store, badge: Badge) => {
    const editState = PermitEditState.Server.get(store).data;
    const createEnabled = PermitCreateState.Layout.get(store).createEnabled;

    if (isDefined(editState) && !createEnabled) {
        PermitEditState.Edit.set(store, (s: PermitEditState.Edit.State) => {
            const editedBadges = s.badges;
            const copy = editedBadges
                ? editedBadges.slice()
                : editState.badges.slice();
            if (isDefined(copy)) {
                copy.push({
                    id: badge.id,
                    badgeLabelNr: badge.badgeLabelNr,
                });
                s.badges = copy;
            }
            return s;
        });
        BadgeState.resetAllStates(store);
        PermitEditState.Validation.reset(store);
    } else {
        PermitCreateState.Config.set(
            store,
            (s: PermitCreateState.Config.State) => {
                const copy = s.badges.slice();
                copy.push(badge);
                s.badges = copy;
                return s;
            },
        );
        BadgeState.resetAllStates(store);
        PermitCreateState.Calc.reset(store);
    }

    return 'AddBadgeSlideIn-addBadgeToPermit';
};

export function AddBadgeSlideIn() {
    const { storeState, update } = useStore(store => {
        const permitCreateStateConfig = PermitCreateState.Config.get(store);
        const permitEditStateConfig = PermitEditState.Edit.get(store);

        const displayMode = isDefined(permitCreateStateConfig.permitTypeId)
            ? DisplayMode.CREATE
            : DisplayMode.EDIT;

        let permitTypeId: Maybe<number>;
        let selectedBadges: Badge[];
        if (displayMode === DisplayMode.CREATE) {
            permitTypeId = permitCreateStateConfig.permitTypeId;
            selectedBadges = permitCreateStateConfig.badges;
        } else {
            const permitData: Maybe<PermitEditState.Permit> =
                PermitEditState.Server.get(store).data;
            permitTypeId = thenElse(permitData, d => d.permitTypeId, null);
            const permitBadges: Badge[] = thenElse(
                permitData,
                permit => permit.badges,
                [],
            );

            // after edits, the selected plates will be in the EditStateConfig
            selectedBadges = getOrElse(
                permitEditStateConfig.badges,
                permitBadges,
            );
        }
        const permitType: Maybe<PermitTypeState.PermitType> =
            PermitTypeState.getById(store, permitTypeId);

        return {
            mode: displayMode,
            settings: new SettingsState.StateSlice(store).state,
            relevantPermitType: permitType,
            addBadge: BadgeState.Add.get(store),
            selectedBadges: selectedBadges,
        };
    });

    const [confirmPressedWhileError, setConfirmPressedWhileError] =
        useState(false);

    const form = useForm<BadgePayload>({
        defaultValues: {
            [BadgeFields.badgeLabelNr]: '',
        },
        mode: 'onBlur',
    });
    const badgeLabelNr = form.watch(BadgeFields.badgeLabelNr);

    const [badgeResponse, sendBadge, resetSendBadge] = useServerWrite<
        BadgePayload,
        Badge
    >(() => ({ url: operatorAppApiUrl('/badge') }));

    function closeSlideIn(store: Flux.Store) {
        form.reset(); // The old slide-ins don't have a proper lifecycle so we manually reset
        resetSendBadge();
        BadgeState.Add.reset(store);
        return 'AddBadgeSlideIn-cancel';
    }

    useServerSuccessEffect(badgeResponse, badge => {
        update(store => {
            addBadgeToPermit(store, badge);
            return closeSlideIn(store);
        });
    });

    function formIsValid() {
        return (
            form.formState.isValid &&
            !storeState.selectedBadges.some(plate => {
                return plate.badgeLabelNr === badgeLabelNr;
            })
        );
    }

    return (
        <HalfSlideIn
            open={storeState.addBadge.createBadgeOpen || false}
            outsideBody
        >
            <ConfirmationHeader
                language={storeState.settings.language}
                subtitle={
                    <Localized
                        de={'Badge hinzufügen'}
                        fr={'Ajouter badge'}
                        it={'Aggiungere badge'}
                        en={'Add badge'}
                    />
                }
                title={
                    <Localized
                        de={'Eingabe des Badges'}
                        fr={'Saisir badge'}
                        it={'Registrazione badge'}
                        en={'Enter badge'}
                    />
                }
                onCancel={() => update(closeSlideIn)}
                confirmButtonSpecialState={buttonSpecialSate(
                    confirmPressedWhileError,
                    formIsValid(),
                )}
                onConfirm={() => {
                    if (form.formState.isValid) {
                        form.handleSubmit(sendBadge)();
                    } else {
                        setConfirmPressedWhileError(true);
                    }
                }}
            />
            {storeState.relevantPermitType && (
                <AddBadgeSlideInBody
                    form={form}
                    preSelectedBadges={storeState.selectedBadges}
                    badgeResponse={badgeResponse}
                />
            )}
        </HalfSlideIn>
    );
}

function buttonSpecialSate(
    confirmPressedWhileError: boolean | null | undefined,
    isValid: boolean,
): ButtonSpecialState | null {
    if (confirmPressedWhileError && !isValid) {
        return ButtonSpecialState.ERROR;
    } else if (!isValid) {
        return ButtonSpecialState.DISABLED;
    } else {
        return null;
    }
}
