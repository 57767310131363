import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';

interface DurationFieldProps {
    label: string;
    errorText?: Maybe<string>;
    value: string;
    durationsAvailable: number;
    onClick(): void;
}

export function DurationField(props: DurationFieldProps): JSX.Element {
    const canSelectDuration = props.durationsAvailable > 0;
    const onlyOneDurationAvailable = props.durationsAvailable === 1;

    if (!canSelectDuration) {
        return <span />;
    }

    if (onlyOneDurationAvailable) {
        return <LabeledText label={props.label}>{props.value}</LabeledText>;
    } else {
        return (
            <SingleSelection
                focused={false}
                label={props.label}
                onClick={props.onClick}
                errorText={props.errorText}
                selection={props.value}
            />
        );
    }
}

interface EndDateFieldProps {
    label: string;
    selectedValue: string | null;
    calculatedValue: string | null;
    errorText?: Maybe<string>;
    endDateSelectable: boolean;
    onClick(): void;
}

export function EndDateField(props: EndDateFieldProps): JSX.Element {
    if (props.calculatedValue) {
        return (
            <LabeledText label={props.label}>
                {props.calculatedValue}
            </LabeledText>
        );
    } else {
        if (props.endDateSelectable) {
            return (
                <SingleSelection
                    focused={false}
                    label={props.label}
                    onClick={props.onClick}
                    errorText={props.errorText}
                    selection={props.selectedValue}
                />
            );
        } else {
            return (
                <LabeledText label={props.label}>
                    {props.selectedValue}
                </LabeledText>
            );
        }
    }
}
