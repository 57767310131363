import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Icon24 } from '../icons/Icon.tsx';

import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';

interface ResultsErrorProps {
    title: React.ReactNode;
    description: React.ReactNode;
}

export const ResultsError = (p: ResultsErrorProps) => (
    <div
        className={css({
            textAlign: 'center',
            height: 80,
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            marginTop: -40,
        })}
    >
        <div className={css({ color: ColorHex.error })}>
            <Icon24 icon={Icons24.error} />
        </div>
        <div
            className={css({
                ...OperatorTypo.headingTwo,
                color: ColorHex.error,
            })}
        >
            {p.title}
        </div>
        <div
            className={css({
                ...OperatorTypo.headingOne,
                color: ColorHex.typo,
            })}
        >
            {p.description}
        </div>
    </div>
);
