import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import { Localized } from '../common/components/Localized.tsx';
import {
    PARKINGABO_BADGE_LENGTH,
    parkingaboBadgeErrorText,
    rfidCardErrorText,
} from 'dg-web-shared/lib/RfidCardValidation.ts';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField.tsx';
import Alert from '@mui/material/Alert';
import { OperatorFormSelect } from '../ui/form/OperatorFormSelect.tsx';
import { ParkingaboUser } from './OperatorParkingaboUsersForm.tsx';
import { AppMode } from 'product-shared/tenant/TenantEnums.ts';
import { ParkingaboUserBadgeType } from 'dg-web-shared/common/models/Vehicle.tsx';
import {
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { GenericFormSubmitError } from './ParkingaboUsersUtils.tsx';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { useEffect } from 'react';
import { badgeTypeOptions } from 'product-shared/tenant/TenantVehicleIdentificationUtils.ts';

enum Fields {
    BadgeLabelNr = 'badgeLabelNr',
    BadgeType = 'badgeType',
}

const PARKINGABO_BADGE_URL = 'https://parkingabo.ch/b/';

export function BadgeConfirmationDialog({
    onAbort,
    onSave,
    parkingaboUser,
    language,
    badgeRequired,
    mandatoryFieldMessage,
}: {
    onAbort: () => void;
    onSave: (badgeLabelNr: string, badgeType: ParkingaboUserBadgeType) => void;
    parkingaboUser: ParkingaboUser;
    language: Language;
    badgeRequired: boolean;
    mandatoryFieldMessage: string;
}) {
    const isInvitationMode =
        parkingaboUser.tenant.appMode === AppMode.NO_SIGNUP &&
        parkingaboUser.onboardingCompleteTime === null;
    const [badgeLabelValidationState, fetchBadgeValidation] = useServerWrite<
        { badgeType: ParkingaboUserBadgeType; badgeLabelNr: string },
        null,
        ValidationData
    >(({ badgeLabelNr }) => ({
        url: `/ui-api/operator-account/parkingabo/badge/${badgeLabelNr}/validation`,
    }));

    const {
        formInfo: { control, handleSubmit, watch, reset, trigger },
        formState: { isValid },
        genericSubmitError,
    } = useEasyForm(undefined, badgeLabelValidationState, language, {
        defaultValues: {
            [Fields.BadgeLabelNr]: '',
            [Fields.BadgeType]: ParkingaboUserBadgeType.ParkingaboBadge,
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const selectedBadgeType = watch(Fields.BadgeType);
    const isParkingaboBadge =
        selectedBadgeType === ParkingaboUserBadgeType.ParkingaboBadge;
    const badgeLabelInput = watch(Fields.BadgeLabelNr);

    useEffect(() => {
        if (!badgeLabelInput) return;

        const timeoutId = setTimeout(() => {
            if (
                isParkingaboBadge &&
                badgeLabelInput.startsWith(PARKINGABO_BADGE_URL) &&
                badgeLabelInput.length ===
                    PARKINGABO_BADGE_LENGTH + PARKINGABO_BADGE_URL.length
            ) {
                const labelWithoutUrl = badgeLabelInput.replace(
                    PARKINGABO_BADGE_URL,
                    '',
                );
                onSave(labelWithoutUrl, selectedBadgeType);
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [badgeLabelInput, selectedBadgeType]);

    useServerSuccessEffect(badgeLabelValidationState, () =>
        onSave(badgeLabelInput, selectedBadgeType),
    );

    useEffect(() => {
        trigger();
    }, [selectedBadgeType]);

    return (
        <Dialog maxWidth="xs" fullWidth open>
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '52px',
                    backgroundColor: theme => theme.palette.blue.main,
                    color: theme => theme.palette.white.main,
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    padding: '0px 24px',
                }}
            >
                <p>
                    <Localized
                        de="Badge hinzufügen"
                        fr="Ajouter badge"
                        it="Aggiungi badge"
                        en="Add badge"
                    />
                </p>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: '10px 24px',
                }}
            >
                <Box sx={{ margin: '16px 0' }}>
                    <GenericFormSubmitError
                        error={genericSubmitError}
                        submitState={badgeLabelValidationState}
                    />
                    {isInvitationMode && (
                        <Alert
                            severity="warning"
                            sx={{ margin: 0, marginBottom: 3 }}
                        >
                            <Localized
                                de="Um einen Benutzer einzuladen, muss man zwingend einen Parkingabo-Badge verwenden."
                                fr="Pour inviter un utilisateur, il faut obligatoirement utiliser un badge Parkingabo."
                                it="Per poter invitare un utente, è necessario utilizzare un badge Parkingabo."
                                en="To invite a user, you must use a Parkingabo badge."
                            />
                        </Alert>
                    )}
                    {isParkingaboBadge && (
                        <Alert
                            severity="info"
                            sx={{ margin: 0, marginBottom: 3 }}
                        >
                            <Localized
                                de="Tipp: Sparen Sie sich die manuelle Eingabe, indem Sie einen QR-Reader verwenden."
                                fr="Conseil: évitez la saisie manuelle en utilisant un lecteur de QR."
                                it="Consiglio: si risparmi la fatica dell'inserimento manuale utilizzando un lettore QR."
                                en="Tip: save yourself the manual entry by using a QR reader."
                            />
                        </Alert>
                    )}
                    <Box sx={{ marginTop: '24px' }}>
                        <OperatorFormSelect
                            label={
                                <Localized
                                    de="Badge-Typ"
                                    fr="Type de badge"
                                    it="Tipo di badge"
                                    en="Badge-Type"
                                />
                            }
                            name={Fields.BadgeType}
                            control={control}
                            options={badgeTypeOptions(language)}
                            readonly={isInvitationMode}
                            rules={{
                                required: {
                                    value: true,
                                    message: mandatoryFieldMessage,
                                },
                            }}
                        />
                    </Box>
                    <ReactHookFormTextField
                        autoFocus
                        sx={{ marginTop: '24px' }}
                        name={Fields.BadgeLabelNr}
                        control={control}
                        label={
                            <Localized
                                de="BADGE-NR."
                                fr="N° BADGE"
                                it="NR. BADGE"
                                en="BADGE NR."
                            />
                        }
                        rules={{
                            validate: {
                                rfidFormat: (value: string) => {
                                    switch (selectedBadgeType) {
                                        case ParkingaboUserBadgeType.ParkingpayBadge:
                                            return (
                                                rfidCardErrorText(
                                                    value,
                                                    language,
                                                ) || true
                                            );
                                        case ParkingaboUserBadgeType.ParkingaboBadge:
                                            return (
                                                parkingaboBadgeErrorText(
                                                    value,
                                                    language,
                                                ) || true
                                            );
                                    }
                                },
                            },
                            required: badgeRequired
                                ? mandatoryFieldMessage
                                : false,
                        }}
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '0px 24px 20px 0px',
                }}
            >
                <Button
                    onClick={() => {
                        reset();
                        onAbort();
                    }}
                >
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                </Button>
                <Button
                    onClick={handleSubmit(formValues => {
                        fetchBadgeValidation({
                            badgeType: formValues.badgeType,
                            badgeLabelNr: formValues.badgeLabelNr,
                        });
                    })}
                    disabled={
                        !isValid ||
                        badgeLabelValidationState.status ===
                            RequestStatus.PENDING
                    }
                    variant="outlined"
                    sx={{
                        marginLeft: '5px',
                        backgroundColor: theme => theme.palette.blue.main,
                        color: theme => theme.palette.white.main,
                        '&.Mui-disabled': {
                            color: theme => theme.palette.blue.medium,
                        },
                    }}
                >
                    <Localized
                        de="Bestätigen"
                        fr="Confirmer"
                        it="Conferma"
                        en="Confirm"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
