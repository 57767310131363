import { Popover } from '@mui/material';
import { useState } from 'react';

export function OperatorPopup({
    trigger,
    children,
}: {
    trigger: React.ReactNode;
    children: React.ReactNode;
}) {
    const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(
        null,
    );
    const [clicked, setClicked] = useState<boolean>(false);

    function handleOnEnter(e: React.MouseEvent<HTMLDivElement>) {
        setAnchorElement(e.currentTarget);
    }
    function handleOnLeave() {
        if (!clicked) {
            setAnchorElement(null);
        }
    }
    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        setAnchorElement(clicked ? null : e.currentTarget);
        setClicked(clicked => !clicked);
    }

    return (
        <>
            <div
                onMouseEnter={handleOnEnter}
                onMouseLeave={handleOnLeave}
                onClick={handleClick}
            >
                {trigger}
            </div>
            <Popover
                open={Boolean(anchorElement) || clicked}
                disableRestoreFocus
                sx={{
                    pointerEvents: 'none',
                }}
                onClose={handleOnLeave}
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: 12 }}>{children}</div>
            </Popover>
        </>
    );
}
