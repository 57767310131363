import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { ClearancesList } from '../clearances-list/ClearancesList.tsx';
import { PermitsList } from '../permits-list/PermitsList.tsx';
import * as ResultState from '../../state/ResultState.ts';
import {
    ColumnWidth,
    EmptyTableHeaderColumn,
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
} from '../../../ui/table/Table.tsx';
import {
    TextColumnContent,
    TextLeftColumn,
    TextRightColumn,
} from '../../../ui/layout/Text.tsx';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as Text from '../../../common/i18n/Text.ts';
import { resultsListTexts } from '../../i18n/ClearancePermitListTexts.tsx';
import { CreateGuide } from '../shared/Guides.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as SearchState from '../../state/SearchState.ts';
import * as SearchEntitySelection from '../../../ui/search/SearchEntitySelection.tsx';
import { AddMenu } from '../shared/AddMenu.tsx';
import * as OperatorDataState from '../../../common/state/OperatorDataState.ts';
import { FieldSetting } from '../../../common/state/OperatorDataState.ts';
import { ModalSpecialTabResultRefresher } from '../../../ui/layout/TabContent.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../../ui/Colors.ts';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import { Icon24 } from '../../../ui/icons/Icon.tsx';
import { error } from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Localized } from '../../../common/components/Localized.tsx';

interface ResultsListState {
    search: SearchState.State;
    result: ResultState.State;
    login: CurrentOperatorLoginState.State;
    settings: SettingsState.State;
}

export interface ResultsListTexts {
    noResultsTitle: Text.Translation;
    noResultsDescription: Text.Translation;
    searchGuide: (
        whitelistCreate: boolean,
        permitsWrite: boolean,
    ) => JSX.Element;
    Identification: Text.Translation;
    From: Text.Translation;
    To: Text.Translation;
    PermitType: Text.Translation;
    Info: Text.Translation;
}

export const Guide = (p: ResultsListState) => {
    const login = p.login.data;
    const texts = resultsListTexts[p.settings.language];
    if (isDefined(login)) {
        return (
            <TextColumnContent>
                <TextLeftColumn>
                    {texts.searchGuide(
                        login.permissions.whitelistCreate,
                        login.permissions.permitsWrite,
                    )}
                </TextLeftColumn>
                <TextRightColumn>
                    <CreateGuide login={login} lang={p.settings.language} />
                </TextRightColumn>
            </TextColumnContent>
        );
    } else {
        return <noscript />;
    }
};

const setEntity = (
    store: Flux.Store,
    entity: SearchState.SearchEntity,
): string => {
    return SearchState.stateWrite(store, { searchEntity: entity });
};

interface EntitySelectProps {
    entity: SearchState.SearchEntity;
    label: React.ReactNode;
}

function EntitySelect(p: EntitySelectProps) {
    const { storeState, update } = useStore(store => ({
        searchEntity: SearchState.get(store).searchEntity,
    }));
    return (
        <SearchEntitySelection.Button
            label={p.label}
            onClick={() => update(store => setEntity(store, p.entity))}
            selected={storeState.searchEntity === p.entity}
        />
    );
}

function EntityButtons() {
    const { storeState } = useStore(store => ({
        login: CurrentOperatorLoginState.get(store),
        operator: OperatorDataState.get(store),
    }));

    const whitelist =
        storeState.login.data &&
        storeState.login.data.permissions.whitelistCreate;

    const licensePlatePermitSettings =
        storeState.operator.data?.licensePlatePermitSettings;

    return (
        <SearchEntitySelection.Container
            menu={
                <AddMenu
                    label={
                        <Localized
                            de="Erfassen"
                            fr="Saisir"
                            it="Registra"
                            en="Capture"
                        />
                    }
                />
            }
        >
            <EntitySelect
                label={
                    <Localized
                        de="Kennzeichen"
                        fr="Immatriculation"
                        it="Targa"
                        en="License plate"
                    />
                }
                entity="LicensePlate"
            />
            <EntitySelect
                label={
                    <Localized
                        de="Badgenummer"
                        fr="Numéro badge"
                        it="Numero badge"
                        en="Badge number"
                    />
                }
                entity="Badge"
            />

            {licensePlatePermitSettings?.whitelistContractNumber !==
                FieldSetting.hidden &&
                whitelist && (
                    <EntitySelect
                        label={
                            <Localized
                                de="Vertragsnummer"
                                fr="Numéro de contrat"
                                it="Numero contratto"
                                en="Contract number"
                            />
                        }
                        entity="ContractNr"
                    />
                )}
            {licensePlatePermitSettings?.whitelistPersonalNumber !==
                FieldSetting.hidden &&
                whitelist && (
                    <EntitySelect
                        label={
                            <Localized
                                de="Personalnummer"
                                fr="Numéro d'employé"
                                it="Numero collaboratore"
                                en="Personal number"
                            />
                        }
                        entity="PersonalNr"
                    />
                )}
            {whitelist && (
                <EntitySelect
                    label={
                        <Localized
                            de="Freigabe-Infos"
                            fr="Info approbation"
                            it="Info approvazione"
                            en="Clearance info"
                        />
                    }
                    entity="ClearanceInfo"
                />
            )}

            <EntitySelect
                label={
                    <Localized
                        de="Bewilligungsnummer"
                        fr="Numéro autorisation"
                        it="Numero autorizzazione"
                        en="Permit number"
                    />
                }
                entity="PermitId"
            />
            <EntitySelect
                label={
                    <Localized
                        de="Person / Firma"
                        fr="Personne / Entreprise"
                        it="Persona / Società"
                        en="Person / Company"
                    />
                }
                entity="CustomerName"
            />
        </SearchEntitySelection.Container>
    );
}

const ResultListContent = Flux.selectState(
    (store: Flux.Store, props: ResultListProps) => {
        const search = SearchState.get(store);

        return {
            search,
            result: props.result,
            login: CurrentOperatorLoginState.get(store),
            settings: new SettingsState.StateSlice(store).state,
        };
    },
    (p): JSX.Element | null => {
        const texts = resultsListTexts[p.settings.language];

        const resultListRefresher = (
            <ModalSpecialTabResultRefresher
                onRefreshClick={() =>
                    p.update(store =>
                        ResultState.refetchSameContext(store, true),
                    )
                }
                successRequest={p.result.statusCode.statusCode === 200}
                pendingRequest={p.result.pending}
            />
        );
        if (p.search.searchValue.length < 3) {
            return <Guide {...p} />;
        }
        if (p.result.pending) {
            return resultListRefresher;
        }

        if (
            p.result.data &&
            p.result.data.clearances.length === 0 &&
            p.result.data.permitsWithoutClearances.length === 0
        ) {
            return (
                <>
                    {resultListRefresher}
                    <div
                        className={css({
                            textAlign: 'center',
                            height: '80px',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: '50%',
                            marginTop: '-40px',
                        })}
                    >
                        <div
                            className={css({
                                color: ColorHex.error,
                            })}
                        >
                            <Icon24 icon={error} />
                        </div>
                        <div
                            className={css({
                                ...OperatorTypo.headingTwo,
                                color: ColorHex.error,
                            })}
                        >
                            {texts.noResultsTitle()}
                        </div>
                        <div
                            className={css({
                                ...OperatorTypo.headingOne,
                                color: ColorHex.typo,
                            })}
                        >
                            {texts.noResultsDescription()}
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                {resultListRefresher}
                <div>
                    <Table>
                        <TableHeader>
                            <EmptyTableHeaderColumn
                                width={ColumnWidth._24px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.Identification()}
                                width={ColumnWidth._192px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.From()}
                                width={ColumnWidth._72px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.To()}
                                width={ColumnWidth._72px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.PermitType()}
                                width={ColumnWidth.variable}
                                delimiterBottom={false}
                            />

                            <TableHeaderColumn
                                name={texts.Info()}
                                width={ColumnWidth._312px}
                                delimiterBottom={false}
                                selected={false}
                            />
                            <EmptyTableHeaderColumn
                                width={ColumnWidth._24px}
                                delimiterBottom={false}
                            />
                        </TableHeader>
                        <TableBody>
                            <ClearancesList
                                update={p.update}
                                clearances={
                                    p.result.data
                                        ? p.result.data.clearances
                                        : []
                                }
                            />
                            <PermitsList
                                language={p.settings.language}
                                update={p.update}
                                permits={
                                    p.result.data
                                        ? p.result.data.permitsWithoutClearances
                                        : []
                                }
                                hasClearances={
                                    (p.result.data &&
                                        p.result.data.clearances.length >
                                            0) as boolean
                                }
                            />
                        </TableBody>
                    </Table>
                </div>
            </>
        );
    },
);

interface ResultListProps {
    result: ResultState.State;
}

export const ResultList = (p: ResultListProps): JSX.Element => {
    return (
        <div
            className={css({
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
            })}
        >
            <EntityButtons />
            <div
                className={css({
                    position: 'relative',
                    flex: 1,
                    width: '100%',
                })}
            >
                <ResultListContent result={p.result} />
            </div>
        </div>
    );
};
