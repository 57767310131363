import * as SettingsState from '../common/state/SettingsState.ts';
import * as MasterDataZonesState from '../common/state/MasterDataZonesState.ts';
import * as CurrentOperatorLoginState from '../common/state/CurrentOperatorLoginState.ts';
import * as Url from 'dg-web-shared/lib/Url.ts';
import { Body, Container, Tabs } from '../ui/layout/TabContent.tsx';
import {
    ButtonDropdown,
    ButtonDropdownLinkItem,
} from '../ui/slidein/ButtonDropdown.tsx';
import { Localized } from '../common/components/Localized.tsx';
import {
    ServerRequestState,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { Search } from '../ui/search/Search.tsx';
import { useEffect, useState } from 'react';
import {
    OnstreetTransactionFilter,
    ParkTransactionsFilterSummary,
    useOnstreetTransactionFilter,
} from './OnstreetTransactionFilter.tsx';
import {
    FilterLayout,
    FilterLayoutBody,
    FilterLayoutHeader,
} from '../ui/filter/FilterLayout.tsx';
import { filterStateToRequestParameters } from './OnstreetTransactionUtils.ts';
import { OnstreetTransactionFilterSlideIns } from './OnstreetTransactionFilterSlideIns.tsx';
import { OnstreetTransactionList } from './OnstreetTransactionList.tsx';
import {
    OnstreetTransaction,
    OnstreetTransactionRequestSearchParams,
    OnstreetTransactionResponse,
} from './OnstreetTransactionModel.ts';
import { TransactionDetailSlideIn } from './OnstreetTransactionDetailSlideIn.tsx';

function isBodyDisabled(
    selectedTab: SelectedTab,
    filterSlideInVisible: boolean,
    selectedTransactionDetail: OnstreetTransaction | null | undefined,
): boolean {
    switch (selectedTab) {
        case SelectedTab.TRANSACTIONS:
            return Boolean(selectedTransactionDetail) || filterSlideInVisible;
    }
}

export const enum SelectedTab {
    TRANSACTIONS = 'TRANSACTIONS',
}

export function OnstreetTransactions() {
    const { storeState } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        zones: MasterDataZonesState.get(store),
        currentLogin: CurrentOperatorLoginState.get(store),
    }));

    const [selectedTab, setSelectedTab] = useState<SelectedTab>(
        SelectedTab.TRANSACTIONS,
    );
    const [selectedTransactionDetail, setOpenTransactionDetail] =
        useState<OnstreetTransaction | null>(null);
    const [showFilterSlideIn] = useState<boolean>(false);

    const filter = useOnstreetTransactionFilter();

    const [searchString, setSearchString] = useState(filter.state.search ?? '');
    const searchValue = searchString.length > 2 ? searchString : null;
    useEffect(() => {
        const timer = setTimeout(() => {
            filter.setSearchValue(searchValue);
        }, 400);

        return () => {
            clearTimeout(timer);
        };
    }, [searchValue]);

    const params = filterStateToRequestParameters({
        validFrom: filter.state.validFrom,
        validTo: filter.state.validTo,
        zones: filter.state.zoneIds.length === 0 ? null : filter.state.zoneIds,
        search: filter.state.search,
        status: filter.state.status,
        lang: storeState.settings.language,
    });

    const [transactionListState, refetchTransactionList] = useServerFetch<
        OnstreetTransactionResponse,
        OnstreetTransactionRequestSearchParams
    >(
        () => ({
            url: `/ui-api/operator-account/onstreet-transactions?${Url.encodeData(
                params,
            )}`,
        }),
        params,
    );

    return (
        <Container>
            <Tabs>
                <Search
                    searchValue={searchString}
                    onSearchFieldChange={setSearchString}
                    onSelect={() => setSelectedTab(SelectedTab.TRANSACTIONS)}
                    selected={selectedTab === SelectedTab.TRANSACTIONS}
                    language={storeState.settings.language}
                    mousetrap={false}
                />
            </Tabs>
            <Body
                disabled={isBodyDisabled(
                    selectedTab,
                    showFilterSlideIn,
                    selectedTransactionDetail,
                )}
                dependingStateSlices={
                    selectedTab == SelectedTab.TRANSACTIONS
                        ? [storeState.zones]
                        : []
                }
                slideIns={
                    <>
                        <TransactionDetailSlideIn
                            transaction={selectedTransactionDetail}
                            refetchTransactionList={refetchTransactionList}
                            onClose={() => setOpenTransactionDetail(null)}
                            language={storeState.settings.language}
                        />
                        <OnstreetTransactionFilterSlideIns
                            selectedTab={selectedTab}
                            language={storeState.settings.language}
                            filter={filter}
                        />
                    </>
                }
            >
                <TabBody
                    transactionListState={transactionListState}
                    refetchTransactionList={refetchTransactionList}
                    selectedTab={selectedTab}
                    language={storeState.settings.language}
                    filter={filter}
                    zones={storeState.zones}
                    setOpenTransactionDetail={setOpenTransactionDetail}
                />
            </Body>
        </Container>
    );
}

function TabBody({
    transactionListState,
    refetchTransactionList,
    selectedTab,
    language,
    filter,
    zones,
    setOpenTransactionDetail,
}: {
    transactionListState: ServerRequestState<
        OnstreetTransactionResponse,
        OnstreetTransactionResponse
    >;
    refetchTransactionList: () => void;
    selectedTab: SelectedTab;
    language: string;
    filter: OnstreetTransactionFilter;
    zones: MasterDataZonesState.State;
    setOpenTransactionDetail: (transaction: OnstreetTransaction) => void;
}): JSX.Element | null {
    switch (selectedTab) {
        case SelectedTab.TRANSACTIONS:
            return (
                <TransactionsBody
                    transactionListState={transactionListState}
                    refetchTransactionList={refetchTransactionList}
                    language={language}
                    filter={filter}
                    zones={zones}
                    setOpenTransactionDetail={setOpenTransactionDetail}
                />
            );

        default:
            return null;
    }
}

function TransactionsBody({
    transactionListState,
    refetchTransactionList,
    language,
    filter,
    zones,
    setOpenTransactionDetail,
}: {
    transactionListState: ServerRequestState<
        OnstreetTransactionResponse,
        OnstreetTransactionResponse
    >;
    refetchTransactionList: () => void;
    language: string;
    filter: OnstreetTransactionFilter;
    zones: MasterDataZonesState.State;
    setOpenTransactionDetail: (transaction: OnstreetTransaction) => void;
}): JSX.Element {
    const params = filterStateToRequestParameters({
        validFrom: filter.state.validFrom,
        validTo: filter.state.validTo,
        zones: filter.state.zoneIds.length === 0 ? null : filter.state.zoneIds,
        search: filter.state.search,
        status: filter.state.status,
        lang: language,
    });

    return (
        <FilterLayout>
            <FilterLayoutHeader
                filterSummary={
                    <ParkTransactionsFilterSummary
                        language={language}
                        filter={filter}
                        zones={zones}
                    />
                }
                secondRightDropdown={
                    <ButtonDropdown
                        label={
                            <Localized
                                de="Exportieren"
                                fr="Exporter"
                                it="Esporta"
                                en="Export"
                            />
                        }
                    >
                        <ButtonDropdownLinkItem
                            label={
                                <Localized
                                    de="Parkvorgänge"
                                    fr="Procédures"
                                    it="Procedure"
                                    en="Procedures"
                                />
                            }
                            disabled={
                                !filter.state.filterActive &&
                                !filter.state.searchActive
                            }
                            href={`/ui-api/operator-account/onstreet-transactions/csv-export?${Url.encodeData(
                                params,
                            )}`}
                        />
                    </ButtonDropdown>
                }
            />
            <FilterLayoutBody>
                <OnstreetTransactionList
                    transactionListState={transactionListState}
                    refetchTransactionList={refetchTransactionList}
                    setOpenTransactionDetail={setOpenTransactionDetail}
                    language={language}
                    filter={filter}
                />
            </FilterLayoutBody>
        </FilterLayout>
    );
}
