import { Box } from '@mui/material';

export function SeparationBar() {
    return (
        <Box
            sx={{
                backgroundColor: theme => theme.palette.blue.main,
                height: '3px',
                width: '100%',
                flexShrink: 0,
            }}
        />
    );
}
