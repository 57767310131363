import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { LoadingSpinnerPresets } from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import {
    EmptyResults,
    FetchError,
    ResultLimitAlert,
} from '../shared-mui-components/ListMessages.tsx';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DateTime } from 'luxon';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { FiltersBox } from '../shared-mui-components/filter/FiltersBox.tsx';
import { useProductFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import {
    OperatorProductStatus,
    OperatorProductStatusIcon,
} from './OperatorProductState.tsx';
import { CloudDownload, LocalParking } from '@mui/icons-material';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import {
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import { OperatorProductDetailModal } from './OperatorProductDetailModal.tsx';
import React from 'react';

export function OperatorProducts() {
    return <ProductLayout />;
}

interface ProductsResultLine {
    contractId: number;
    status: OperatorProductStatus;
    name: Message;
    validFrom: string;
    validTo: string | null;
    createdBy: string;
    createdAt: string;
}

export interface ProductSearchResult {
    eof: boolean;
    results: ProductsResultLine[];
}

function ProductLayout() {
    const { search } = useLocation();
    const language = useOperatorLanguage();
    const paramsWithLanguage = React.useMemo(() => {
        const params = new URLSearchParams(search);
        params.append('language', language);
        return params;
    }, [search, language]);

    const {
        filterConfig,
        activeFilters,
        listState,
        refetchList,
        searchText,
        filterConfigState,
    } = useProductFilterConfiguration();

    return (
        <MainContent>
            <HeaderWithButtons
                title={
                    <Localized
                        de="Produkte"
                        fr="Produits"
                        it="Prodotti"
                        en="Products"
                    />
                }
                icon={LocalParking}
                headerButtons={
                    listState.status === RequestStatus.SUCCESS &&
                    listState.data.results.length > 0 ? (
                        <Button
                            variant="outlined"
                            startIcon={<CloudDownload />}
                            href={`/ui-api/operator-account/product/export?${paramsWithLanguage.toString()}`}
                        >
                            <Localized
                                de="Exportieren"
                                fr="Exporter"
                                it="Esporta"
                                en="Export"
                            />
                        </Button>
                    ) : undefined
                }
            />
            <FiltersBox
                searchText={searchText}
                activeFilters={activeFilters}
                filterConfig={filterConfig}
                requestState={listState}
                refetch={refetchList}
                pending={filterConfigState.status === RequestStatus.PENDING}
            />
            <OperatorProductsList
                listState={listState}
                refetchList={refetchList}
            />

            <Outlet context={refetchList} />
        </MainContent>
    );
}

function OperatorProductsList({
    listState,
    refetchList,
}: {
    listState: ServerRequestState<ProductSearchResult, object>;
    refetchList: () => void;
}) {
    const { search } = useLocation();
    const navigate = useNavigate();
    return (
        <OperatorAsyncLoadedSection
            requestState={listState}
            pendingLoaderPreset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            render={results => {
                const isEmpty = results.results.length === 0;
                if (isEmpty) {
                    return <EmptyResults />;
                }

                return (
                    <>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Localized
                                                de="Status"
                                                fr="État"
                                                it="Stato"
                                                en="State"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Bezeichnung"
                                                fr="Désignation"
                                                it="Denominazione"
                                                en="Denomination"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Beginn"
                                                fr="Début"
                                                it="Inizio"
                                                en="Start"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Ende"
                                                fr="Fin"
                                                it="Fine"
                                                en="End"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="ID"
                                                fr="ID"
                                                it="ID"
                                                en="ID"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Benutzer"
                                                fr="Utilisateur"
                                                it="Utente"
                                                en="User"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Erstellt am"
                                                fr="Créé le"
                                                it="Creato il"
                                                en="Created at"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.results.map(product => (
                                        <TableRow
                                            key={product.contractId}
                                            hover
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                navigate(
                                                    `/parkingabo/products/${product.contractId}/detail${search}`,
                                                )
                                            }
                                        >
                                            <TableCell>
                                                <OperatorProductStatusIcon
                                                    status={product.status}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Localized {...product.name} />
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.dayMonthYearHourMinute(
                                                    DateTime.fromISO(
                                                        product.validFrom,
                                                    ),
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {product.validTo
                                                    ? Formatter.dayMonthYearHourMinute(
                                                          DateTime.fromISO(
                                                              product.validTo,
                                                          ),
                                                      )
                                                    : '-'}
                                            </TableCell>
                                            <TableCell>
                                                {product.contractId}
                                            </TableCell>
                                            <TableCell>
                                                {product.createdBy}
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.dayMonthYearHourMinute(
                                                    DateTime.fromISO(
                                                        product.createdAt,
                                                    ),
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <ResultLimitAlert
                                eof={results.eof}
                                numberOfResults={results.results.length}
                                hasExport={true}
                            />
                        </TableContainer>
                    </>
                );
            }}
            renderError={() => <FetchError refetchList={refetchList} />}
        />
    );
}

type ProductDetailContextType = { refetchList: () => void };

export function OperatorProductDetail() {
    const { refetchList } = useOutletContext<ProductDetailContextType>();
    const { search } = useLocation();

    return (
        <OperatorProductDetailModal
            backUrl={`/parkingabo/products${search}`}
            onProductChange={refetchList}
        />
    );
}
