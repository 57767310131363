import moment from 'moment';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';

interface ClearanceDates {
    validFrom: string;
    validTo: string;
}

export const isActive = (dates: ClearanceDates): boolean => {
    return moment(new Date(dates.validTo)).isAfter(moment());
};

export const clearanceStatusString = (p: ClearanceDates) =>
    isActive(p) ? 'active' : 'expired';

export const clearanceStatusIcon = (p: ClearanceDates) =>
    isActive(p)
        ? Icons24.clearanceState.active
        : Icons24.clearanceState.inactive;
