import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { FullZone } from 'dg-web-shared/model/Zone.ts';

export namespace Detail {
    export interface Context {
        zoneId: number;
    }

    export type State = ContextualServerStateSlice.ContextualState<FullZone>;

    export const { get } = ContextualServerStateSlice.generateContextualState<
        Context,
        FullZone
    >({
        key: 'ZoneDetailState-ZoneDetail',
        requestGenerator: (context: Context) =>
            Http.OperatorAccount.PermitZones.getFull(context),
        request: AsyncRequest.request,
        parseBody: body => body,
    });
}
