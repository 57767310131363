import { useState } from 'react';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { ParkingsListBody } from './ParkingsListBody.tsx';
import { ParkingsMapBody } from './ParkingsMapBody.tsx';

enum ParkingsTab {
    MAP = 'map',
    PARKINGS_LIST = 'zone list',
}

export function Parkings() {
    const { storeState } = useStore(store => ({
        currentLogin: CurrentOperatorLoginState.get(store),
    }));

    const [activeTab, setActiveTab] = useState(ParkingsTab.MAP);

    // if not logged in
    if (!storeState.currentLogin.data) {
        return null;
    }

    return (
        <Container>
            <Tabs>
                <TabItem
                    active={activeTab === ParkingsTab.MAP}
                    description={
                        <Localized de="Karte" fr="Plan" it="Mappa" en="Map" />
                    }
                    onClick={() => setActiveTab(ParkingsTab.MAP)}
                />
                <TabItem
                    active={activeTab === ParkingsTab.PARKINGS_LIST}
                    description={
                        <Localized
                            de="Parkingliste"
                            fr="Liste des parkings"
                            it="Lista parcheggi"
                            en="Parkings list"
                        />
                    }
                    onClick={() => setActiveTab(ParkingsTab.PARKINGS_LIST)}
                />
            </Tabs>
            {activeTab === ParkingsTab.MAP && <ParkingsMapBody />}
            {activeTab === ParkingsTab.PARKINGS_LIST && <ParkingsListBody />}
        </Container>
    );
}
