import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { TextField } from '../ui/labeled-elements/TextField.tsx';
import { css } from '@emotion/css';
import { ResetPasswordTexts, resetPasswordTexts } from './i18n.ts';
import { BasicButton } from '../ui/buttons/BasicButton.tsx';
import { OperatorTypo } from '../ui/OperatorTypo.ts';
import {
    PostResetPasswordRemoteRequestState,
    ResponseFailureCode,
} from './ResetPasswordState.ts';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices.ts';
import { Spinner } from '../ui/loaders/Spinner.tsx';
import { ColorHex } from '../ui/Colors.ts';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { languageFromString } from 'dg-web-shared/lib/Text.ts';
import postUsername = PostResetPasswordRemoteRequestState.postUsername;

export function ResetPassword() {
    const { storeState, update } = useStore(s => ({
        remoteReset: PostResetPasswordRemoteRequestState.get(s),
    }));

    const [searchParams] = useSearchParams();

    const language = searchParams.get('language') ?? 'de';

    const [username, setUsername] = useState(
        searchParams.get('username') ?? '',
    );
    const [hasEdited, setHasEdited] = useState(false);

    const t = resetPasswordTexts[language];

    function submit() {
        if (isValid(username)) {
            update(postUsername, {
                username: username,
                language: languageFromString(language),
                source: 'WEB',
            });
        }
    }

    switch (storeState.remoteReset.status) {
        case RequestStatus.PENDING:
            return <Spinner loading={true} />;
        case RequestStatus.SUCCESS:
            return (
                <Message
                    message={t.passwordResetRequestSucceeded(username)}
                    texts={t}
                    status={MessageState.SUCCESS}
                />
            );
        case RequestStatus.ERROR:
            return (
                <Message
                    message={failureMessage(
                        t,
                        username,
                        storeState.remoteReset,
                    )}
                    texts={t}
                    status={MessageState.FAILURE}
                />
            );

        case RequestStatus.NEVER_EXECUTED:
            return (
                <div
                    className={css({
                        transform: 'translateX(0)',
                    })}
                >
                    <p
                        className={css({
                            ...OperatorTypo.landingPageTitle,
                            margin: '25px 25px 0px 25px',
                        })}
                    >
                        {t.resetPassword()}
                    </p>

                    <div
                        className={css({
                            margin: '0 25px 0 25px',
                        })}
                        onKeyDown={e => {
                            if (e && e.keyCode === 13) {
                                submit();
                            }
                        }}
                    >
                        <TextField
                            value={username}
                            label={t.username()}
                            onBlur={() => setHasEdited(true)}
                            inputType="text"
                            context="darkblue"
                            errorText={
                                hasEdited && !isValid(username)
                                    ? t.requiredField()
                                    : ''
                            }
                            onChange={setUsername}
                        />
                    </div>

                    <div
                        className={css({
                            alignSelf: 'stretch',
                            marginTop: '25px',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        })}
                    >
                        <BasicButton
                            label={t.cancel()}
                            onClick={close}
                            context="darkblue"
                        />

                        <BasicButton
                            label={t.confirm()}
                            onClick={submit}
                            disabled={!isValid(username)}
                            context="darkblue"
                        />
                    </div>
                </div>
            );
    }
}

function isValid(username: string): boolean {
    return username.length > 0;
}

function close() {
    window.location.href = '/';
}

enum MessageState {
    SUCCESS = 'success',
    FAILURE = 'failure',
}

const Message = (p: {
    message: string;
    texts: ResetPasswordTexts;
    status: MessageState;
}) => (
    <div
        className={css({
            display: 'flex',
            flexDirection: 'column',
        })}
    >
        <div
            className={css({
                ...Typo.robotoLight,

                fontSize: '22px',
                lineHeight: '30px',
                color:
                    p.status === MessageState.SUCCESS
                        ? ColorHex.white
                        : ColorHex.errorBright,
                margin: '25px 25px 0px 25px',
            })}
        >
            {p.message}
        </div>

        <div
            className={css({
                alignSelf: 'center',
                marginTop: '48px',
            })}
        >
            <BasicButton
                label={p.texts.close()}
                onClick={close}
                context="darkblue"
            />
        </div>
    </div>
);

function failureMessage(
    texts: ResetPasswordTexts,
    username: string,
    remoteState: PostResetPasswordRemoteRequestState.State,
): string {
    if (remoteState.status !== RequestStatus.ERROR) {
        throw Error('Do not call failure messages without error');
    }

    if (remoteState.data === null || !remoteState.data.code) {
        return texts.passwordResetRequestFailed(remoteState.httpStatus);
    }

    switch (remoteState.data.code) {
        case ResponseFailureCode.NO_EMAIL_FOR_USER:
            return texts.passwordResetRequestFailedNoEmailUser(
                username,
                remoteState.data.organizationAdminName,
            );

        case ResponseFailureCode.NO_EMAIL_FOR_ADMIN_USER:
            return texts.passwordResetRequestFailedNoEmailAdmin(username);
    }
}
