import { DivGenerator } from 'dg-web-shared/lib/ReactHelpers.tsx';

import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';

export const Paragraph = DivGenerator(
    css({
        ...Typo.styledHtmlTags,
        color: Colors.darkblue,
        margin: '1em 0',
    }),
);

export function TextColumnContent(p: { children?: React.ReactNode }) {
    return (
        <div className={css(Typo.styledHtmlTags)}>
            <div
                className={css({
                    color: ColorHex.typo,
                    display: 'flex',
                    marginLeft: '272px',
                    marginTop: '136px',
                })}
            >
                {p.children}
            </div>
        </div>
    );
}

export function TextLeftColumn(p: { children?: React.ReactNode }) {
    return (
        <div
            className={css({
                width: styleColumns.width,
                paddingRight: styleColumns.paddingToNextColumn,
            })}
        >
            {p.children}
        </div>
    );
}

export function TextRightColumn(p: { children?: React.ReactNode }) {
    return (
        <div
            className={css({
                width: styleColumns.width,
                paddingLeft: styleColumns.paddingToNextColumn,
            })}
        >
            {p.children}
        </div>
    );
}

const styleColumns = { width: '368px', paddingToNextColumn: '24px' };
