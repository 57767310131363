import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ParkTransactionsState from '../state/ParkTransactionsState.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as ParkTransactionsActions from '../actions/ParkTransactionsActions.ts';
import { tomFilterTexts } from '../../tom/i18n/TomTexts.ts';
import {
    TomFilter,
    TomFilterHalfSlideIn,
} from '../../tom/components/TomFilterHalfSlideIn.tsx';

const cn = ElementNamer('TomFilterTransactionListSlideIn');

interface State {
    settings: SettingsState.State;
    filter: ParkTransactionsState.Filter.State;
}

export class TomFilterTransactionListSlideIn extends Flux.Container<State> {
    static displayName: string = cn('');

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: ParkTransactionsState.Filter.get(this.props.allState),
        };
    }

    render() {
        const texts = tomFilterTexts[this.state.settings.language];
        const filter: TomFilter = {
            selectedToms: this.state.filter.toms,
        };
        return (
            <TomFilterHalfSlideIn
                allState={this.props.allState}
                filter={filter}
                open={this.state.filter.tomSelectionVisible}
                setSelection={(tomIds: number[]) =>
                    this.update(store =>
                        ParkTransactionsActions.setToms(store, tomIds),
                    )
                }
                onClose={() =>
                    this.update(ParkTransactionsActions.closeTomsFilter)
                }
                title={texts.filterTitle()}
            />
        );
    }
}
