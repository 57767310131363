import { Translations } from '../../common/i18n/Text.ts';
import { Texts } from '../components/CustomerAccounts.tsx';

export const texts: Translations<Texts> = {
    de: {
        CustomerNr: () => 'Konto-Nr',
        Name: () => 'Name',
        Company: () => 'Firma',
        Active: () => 'Aktiv',
        Locked: () => 'Gesperrt',
        Balance: () => 'Saldo',
        PaymentType: () => 'aktive Zahlungsweise',
        ActivatedOn: () => 'Aktiviert am',
        LockedValue: () => 'Ja',
        NotLockedValue: () => 'Nein',
        DetailHeader: () => 'Benutzerkonto',
        CustomerSearchTitle: () => 'Suche',
        CustomerWhitelistTitle: () => 'Kontofreigaben',
    },
    fr: {
        CustomerNr: () => 'No compte',
        Name: () => 'Nom',
        Company: () => 'Entreprise',
        Active: () => 'Actif',
        Locked: () => 'Bloqué',
        Balance: () => 'Solde',
        PaymentType: () => 'Mode de paiement acitf',
        ActivatedOn: () => 'Activé le',
        LockedValue: () => 'Oui',
        NotLockedValue: () => 'No',
        DetailHeader: () => 'Compte utilisateur',
        CustomerSearchTitle: () => 'Recherche',
        CustomerWhitelistTitle: () => 'Approbations compte',
    },
    it: {
        CustomerNr: () => 'Nr. conto',
        Name: () => 'Name',
        Company: () => 'Società',
        Active: () => 'Attivo',
        Locked: () => 'Bloccato',
        Balance: () => 'Saldo',
        PaymentType: () => 'Modalità di pagamento attiva',
        ActivatedOn: () => 'Attivato il',
        LockedValue: () => 'Si',
        NotLockedValue: () => 'No',
        DetailHeader: () => 'Conto utente',
        CustomerSearchTitle: () => 'Ricerca',
        CustomerWhitelistTitle: () => 'Approvazioni conto',
    },
};
