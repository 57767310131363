import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { IconButton24pxType } from '../buttons/IconButton.tsx';
import {
    ActionButton,
    ActionButtons,
    ActionButtonsContainer,
    BelowContent,
    Content,
    HoverableBlock,
    Label,
    LabeledElement,
} from './Internal.tsx';
import { LabeledTextStatus, LabeledTextStatusIcon } from './LabeledText.tsx';

interface Props {
    label: React.ReactNode;
    rightLabelText?: string;
    selection: React.ReactNode;
    onClear?: (() => void) | null;
    status?: LabeledTextStatus;
    errorText?: React.ReactNode;
    focused: boolean;
    onClick?: () => void;
    disabled?: boolean;
    toolTip?: React.ReactNode;
    toolTipRight?: boolean;
}

export class SingleSelection extends HoverableBlock<Props> {
    renderActionButton(): JSX.Element | null {
        return this.props.onClear ? (
            <ActionButtonsContainer>
                <ActionButton
                    type={IconButton24pxType.clear}
                    onMouseOver={() => this.setState({ actionHovered: true })}
                    onMouseOut={() => this.setState({ actionHovered: false })}
                    onClick={this.props.onClear}
                />
            </ActionButtonsContainer>
        ) : null;
    }

    isHovered(): boolean {
        return this.state.actionHovered || this.state.itemHovered || false;
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        if (!nextProps.onClear && this.state.actionHovered) {
            this.setState({ actionHovered: false });
        }
    }

    isDisabled(): boolean {
        return !!this.props.disabled;
    }

    render() {
        return (
            <LabeledElement
                onClick={this.isDisabled() ? undefined : this.props.onClick}
                onMouseOver={() => this.setState({ itemHovered: true })}
                onMouseOut={() => this.setState({ itemHovered: false })}
                actionButtons={
                    this.props.onClear ? ActionButtons.one : ActionButtons.none
                }
            >
                <Label
                    disabled={this.isDisabled()}
                    label={this.props.label}
                    rightText={this.props.rightLabelText}
                    hovered={this.isHovered()}
                    focused={this.props.focused}
                    toolTip={this.props.toolTip}
                    toolTipRight={this.props.toolTipRight}
                />
                <LabeledTextStatusIcon status={this.props.status} />
                <Content
                    icon={
                        !this.state.actionHovered && !this.props.disabled
                            ? Icons16.chevronRight
                            : null
                    }
                    focused={this.props.focused}
                    empty={!this.props.selection}
                    userSelectable={false}
                    hovered={this.isHovered()}
                    disabled={this.isDisabled()}
                >
                    {!this.props.selection ? '—' : this.props.selection}
                    {!this.props.disabled && this.renderActionButton()}
                </Content>
                <BelowContent
                    underline={true}
                    focused={this.props.focused}
                    hovered={this.isHovered()}
                    errorText={this.props.errorText}
                />
            </LabeledElement>
        );
    }
}
