import { css } from '@emotion/css';

import { Colors } from 'dg-web-shared/ui/vars.ts';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { UiContext } from '../Context.ts';
import React from 'react';

/*
 A generic Button div that implements a ClickableDiv with a "pressed"
 state and correct handling of DOM props (role, type, elevation) plus
 the touchRippleColor
 */

export interface Props {
    onClick: ClickHandler;
    label: string;
    disabled?: boolean;
    link?: string;
    context?: UiContext;
}

interface State {
    pressed: boolean;
}

export class BasicButton extends React.Component<Props, State> {
    static defaultProps: Props = {
        disabled: false,
        onClick: () => {},
        label: '',
        link: undefined,
    };

    constructor(props: Props) {
        super(props);
        this.state = { pressed: false };
    }

    getDepth(): number {
        if (this.props.disabled) {
            return 0;
        }
        if (this.state.pressed) {
            return 3;
        }
        return 2;
    }

    render() {
        return (
            <Clickable
                element={this.props.link ? 'a' : 'button'}
                target={this.props.link ? '_blank' : undefined}
                href={this.props.link}
                className={css({
                    border: 0,
                    overflow: 'visible',
                    outline: 'none',
                    height: 40,
                    padding: '0 15px',
                    borderRadius: 2,
                    fontSize: 16,
                    lineHeight: '22px',
                    background: Colors.darkblue,
                    color: Colors.white,
                    '&:hover': {
                        background: Colors.lightblue,
                    },
                    ...(this.getDepth() === 0 && {
                        boxShadow: 'none',
                    }),
                    ...(this.getDepth() === 2 && {
                        boxShadow: `0 2px 2px rgba(${Colors.black} 0.20)`,
                    }),
                    ...(this.props.disabled && {
                        opacity: 0.4,
                        pointerEvents: 'none',
                    }),
                    ...(this.props.context === 'darkblue' && {
                        background: Colors.white,
                        color: Colors.darkblue,
                    }),
                    ...(this.props.link && {
                        textDecoration: 'none',
                        display: 'inline-block',
                        paddingTop: 11,
                    }),
                })}
                onClick={this.props.onClick}
                onClickStart={(): void => this.setState({ pressed: true })}
                onClickEnd={(): void => this.setState({ pressed: false })}
                disabled={this.props.disabled}
            >
                {this.props.label}
            </Clickable>
        );
    }
}
