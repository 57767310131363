import {
    Translation,
    Translations,
    TranslationWithArgs1,
    TranslationWithArgs2,
} from '../common/i18n/Text.ts';

export const resetPasswordTexts: Translations<ResetPasswordTexts> = {
    de: {
        cancel: () => 'Abbrechen',
        close: () => 'Schliessen',
        confirm: () => 'Bestätigen',
        passwordResetRequestFailed: httpStatus =>
            `Die Anfrage konnte nicht übermittelt werden (HTTP ${httpStatus}). Bitte versuchen Sie später nochmals.`,
        passwordResetRequestFailedNoEmailAdmin: username =>
            `Wir können den Vorgang zum Zurücksetzen des Kennworts nicht starten, da beim Benutzer "${username}" keine E-Mail-Adresse hintergelegt ist. Bitte wenden Sie sich an unseren Parkingportal-Helpdesk.`,
        passwordResetRequestFailedNoEmailUser: (username, adminName) =>
            `Wir können den Vorgang zum Zurücksetzen des Kennworts nicht starten, da beim Benutzer "${username}" keine E-Mail-Adresse hintergelegt ist. Bitte wenden Sie sich an Ihren Administrator (${adminName}).`,
        passwordResetRequestSucceeded: username =>
            `Wir haben Ihre Anfrage erhalten; falls der Benutzername "${username}" existiert, werden wir an die zugehörige E-Mail-Adresse einen Link senden.`,
        requiredField: () => 'Pflichtfeld',
        resetPassword: () => 'Passwort zurücksetzen',
        username: () => 'Benutzername',
    },
    fr: {
        cancel: () => 'Annuler',
        close: () => 'Fermer',
        confirm: () => 'Confirmer',
        passwordResetRequestFailed: httpStatus =>
            `La requête n'a pas pu être envoyée (HTTP ${httpStatus}). Veuillez réessayer plus tard.`,
        passwordResetRequestFailedNoEmailAdmin: username =>
            `Nous ne pouvons pas démarrer le processus de réinitialisation du mot de passe car pour l'utilisateur "${username}" il y a aucune adresse e-mail configurée. Veuillez contacter notre helpdesk Parkingportal.`,
        passwordResetRequestFailedNoEmailUser: (username, adminName) =>
            `Nous ne pouvons pas démarrer le processus de réinitialisation du mot de passe car pour l'utilisateur "${username}" il y a aucune adresse e-mail configurée. Veuillez contacter votre administrateur (${adminName}).`,
        passwordResetRequestSucceeded: username =>
            `Nous avons reçu votre demande. Si le nom d'utilisateur "${username}" existe, nous allons vous envoyer un lien à l'adresse e-mail associée.`,
        requiredField: () => 'Champ obligatoire',
        resetPassword: () => 'Réinitialiser MDP',
        username: () => "Nom d'utilisateur",
    },
    it: {
        cancel: () => 'Annulla',
        close: () => 'Chiudi',
        confirm: () => 'Conferma',
        passwordResetRequestFailed: httpStatus =>
            `La richiesta non ha potuto essere inviata (HTTP ${httpStatus}). Vogliate riprovare più tardi.`,
        passwordResetRequestFailedNoEmailAdmin: (username: string) =>
            `Non è possibile avviare la procedura di reimpostazione della password perché per l'utente "${username}" non è configurato alcun indirizzo e-mail. Vogliate di contattare il nostro helpdesk Parkingportal.`,
        passwordResetRequestFailedNoEmailUser: (
            username: string,
            adminName: string,
        ) =>
            `Non è possibile avviare la procedura di reimpostazione della password perché per l'utente "${username}" non è configurato alcun indirizzo e-mail. Vogliate di contattare il vostro amministratore (${adminName}).`,
        passwordResetRequestSucceeded: (username: string) =>
            `Abbiamo ricevuto la sua richiesta; se il nome utente "${username}" esiste, invieremo un link all'indirizzo e-mail associato.`,
        requiredField: () => 'Campo obbligatorio',
        resetPassword: () => 'Reimposta password',
        username: () => 'Nome utente',
    },
};

export interface ResetPasswordTexts {
    cancel: Translation;
    close: Translation;
    confirm: Translation;
    passwordResetRequestFailed: TranslationWithArgs1<number>;
    passwordResetRequestFailedNoEmailAdmin: TranslationWithArgs1<string>;
    passwordResetRequestFailedNoEmailUser: TranslationWithArgs2<string, string>;
    passwordResetRequestSucceeded: TranslationWithArgs1<string>;
    requiredField: Translation;
    resetPassword: Translation;
    username: Translation;
}
