import { css } from '@emotion/css';

import {
    getOrElse,
    getOrNull,
    isDefined,
    Maybe,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { Nbsp } from 'dg-web-shared/lib/Punctuation.ts';
import { Conditional, HasChildren } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Color, ColorHex, colorString } from '../Colors.ts';
import { Icon24, Icon30 } from '../icons/Icon.tsx';
import { OperatorTypo } from '../OperatorTypo.ts';

export type BackgroundOpacity = 0.1 | 0.15;

interface SlideInHeaderContainerProps extends HasChildren {
    borderBottomSize?: Maybe<'small' | 'large'>;
    borderBottomColor?: Maybe<Color>;
}

export const SlideInHeaderContainer = (p: SlideInHeaderContainerProps) => (
    <div
        className={css({
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '64px',
            display: 'flex',
        })}
    >
        {p.children}
        <Conditional c={isDefined(p.borderBottomSize)}>
            <div
                className={css([
                    {
                        position: 'absolute',
                        top: '60px',
                        bottom: '64px',
                        width: '100%',
                        left: 0,
                        borderTopStyle: 'solid',
                        borderTopWidth: 0,
                        borderTopColor:
                            ColorHex[
                                colorString(
                                    getOrElse(
                                        p.borderBottomColor,
                                        Color.darkblue,
                                    ),
                                )
                            ],
                    },
                    p.borderBottomSize === 'small' && {
                        borderTopWidth: '2px',
                    },
                    p.borderBottomSize === 'large' && {
                        borderTopWidth: '4px',
                    },
                ])}
            />
        </Conditional>
    </div>
);

interface SlideInBodyContainerProps extends HasChildren {
    backgroundColor: Color;
    backgroundOpacity?: Maybe<BackgroundOpacity>;
}

export const SlideInBodyContainer = (p: SlideInBodyContainerProps) => {
    const backgroundOpacity = getOrNull(p.backgroundOpacity);
    return (
        <div
            className={css([
                {
                    width: '100%',
                    height: '60px',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    backgroundColor: ColorHex[colorString(p.backgroundColor)],
                },
                backgroundOpacity && {
                    backgroundColor: ColorHex.rgba(
                        ColorHex[colorString(p.backgroundColor)],
                        backgroundOpacity,
                    ),
                },
            ])}
        >
            {p.children}
        </div>
    );
};

interface SlideInHeaderTitleContainerProps extends HasChildren {
    color: Color;
    hasLeftIcon?: Maybe<boolean>;
}

export const SlideInHeaderTitleContainer = (
    p: SlideInHeaderTitleContainerProps,
) => (
    <div
        className={css({
            flex: 1,
            display: 'flex',
            color: ColorHex[colorString(p.color)],
            alignItems: 'baseline',
            marginLeft: getOrElse(p.hasLeftIcon, false) ? 0 : '16px',
        })}
    >
        {p.children}
    </div>
);

interface SlideInHeaderTitleIconProps {
    icon: JSX.Element;
    color: Color;
}

export const SlideInHeaderTitleIcon = (p: SlideInHeaderTitleIconProps) => (
    <div
        className={css({
            flexBasis: '30px',
            marginLeft: '16px',
            marginTop: '9px',
            color: ColorHex[colorString(p.color)],
        })}
    >
        <Icon30 icon={p.icon} />
    </div>
);

interface SlideInHeaderTitleProps {
    title: React.ReactNode;
}

export const SlideInHeaderTitle = (p: SlideInHeaderTitleProps) => (
    <div className={css({ ...OperatorTypo.headingTwo, marginLeft: '10px' })}>
        {p.title}
    </div>
);

interface SlideInHeaderSubtitleProps {
    subtitle: React.ReactNode;
}

export const SlideInHeaderSubtitle = (p: SlideInHeaderSubtitleProps) => (
    <div className={css({ ...OperatorTypo.body })}>
        {Nbsp}
        {Nbsp}
        {p.subtitle}
    </div>
);

interface SlideInHeaderCloseProps {
    onClose?: Maybe<ClickHandler>;
}

export const SlideInHeaderClose = (p: SlideInHeaderCloseProps) => (
    <Clickable
        element="div"
        className={css({
            width: '56px',
            height: '60px',
            background: ColorHex.darkblue,
            color: ColorHex.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        })}
        onClick={p.onClose}
    >
        <Icon24 icon={Icons24.clear_b} />
    </Clickable>
);

type SlideInHeaderButtonsContainerProps = HasChildren;
export const SlideInHeaderButtonsContainer = (
    p: SlideInHeaderButtonsContainerProps,
) => (
    <div
        className={css({
            display: 'flex',
            flexFlow: 'row nowrap',
            marginRight: '12px',
            position: 'relative',
        })}
    >
        {p.children}
    </div>
);

type SlideInHeaderLoaderContainerProps = HasChildren;
export const SlideInHeaderLoaderContainer = (
    p: SlideInHeaderLoaderContainerProps,
) => (
    <div className={css({ position: 'absolute', bottom: 0, width: '100%' })}>
        {p.children}
    </div>
);
