import { getOrElse, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { getOperationStatusLabelColor } from './TomDetailSlideIn.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import { ParsedTom } from 'dg-web-shared/model/Tom.ts';
import { OperatorBadge } from './OperatorBadge.tsx';
import { OperatorPopup } from './OperatorPopup.tsx';
import {
    OperationStatusDetail,
    OUT_OF_SERVICE_ALARM_CODE,
} from 'taxomex-shared/tom-operation-status/OperationStatusDetail.tsx';
import {
    AlertDefinition,
    BackendType,
    TomAlertMap,
    TomAlertType,
} from 'taxomex-shared/tom-error/TomAlertMap.tsx';
import { tomOperationStatusTexts } from 'taxomex-shared/tom-operation-status/tomOperationStatusTexts.ts';

export interface ErrorDefinition {
    key: string;
    id: number;
    description: {
        de: string;
        en: string;
        fr: string;
        it: string;
    };
    troubleshooting: {
        de: string;
        en: string;
        fr: string;
        it: string;
    };
}

export interface TomErrorTexts {
    Error: Translation;
    Alarm: Translation;
    Troubleshooting: Translation;
}

export function TomError({
    code,
    language,
    type,
}: {
    code: number;
    language: string;
    type: TomAlertType;
}) {
    return (
        <TomAlertMap
            code={code}
            language={language}
            type={type}
            backendType={BackendType.OPERATOR}
            render={alertDef => (
                <TomErrorPopup
                    code={code}
                    alertDef={alertDef}
                    language={language}
                    type={type}
                />
            )}
        />
    );
}

function TomErrorPopup({
    code,
    alertDef,
    language,
    type,
}: {
    code: number;
    alertDef: AlertDefinition | null;
    language: string;
    type: TomAlertType;
}) {
    if (!alertDef) {
        return (
            <OperatorBadge
                color={type === TomAlertType.ERROR ? 'red' : 'yellow'}
            >
                {code.toString()}
            </OperatorBadge>
        );
    }
    const txt = tomOperationStatusTexts[language];
    const typeText = TomAlertType.ERROR ? txt.Error() : txt.Alarm();
    const lng = language as 'de' | 'fr' | 'it' | 'en';
    const description = alertDef.description[lng] || alertDef.description.de;
    const troubleshooting =
        alertDef.troubleshooting[lng] || alertDef.troubleshooting.de;
    return (
        <OperatorPopup
            trigger={
                <OperatorBadge
                    color={type === TomAlertType.ERROR ? 'red' : 'yellow'}
                >
                    {alertDef.id}
                </OperatorBadge>
            }
        >
            <div
                style={{
                    padding: 0,
                    fontSize: '1.14285714em',
                    lineHeight: 1.2,
                    fontWeight: 'bold',
                }}
            >
                {typeText} – {description}
            </div>
            <p>
                <code>{alertDef.key}</code>
            </p>

            <b>{txt.Troubleshooting()}</b>
            <p style={{ whiteSpace: 'pre' }}>{troubleshooting}</p>
        </OperatorPopup>
    );
}

export function TomAlertList({
    errorCodes,
    alarmCodes,
    language,
}: {
    errorCodes: number[];
    alarmCodes: number[];
    language: string;
}) {
    return (
        <div>
            {errorCodes.map(e => (
                <TomError
                    code={e}
                    key={e}
                    type={TomAlertType.ERROR}
                    language={language}
                />
            ))}
            {alarmCodes
                .filter(a => a !== OUT_OF_SERVICE_ALARM_CODE)
                .map(e => (
                    <TomError
                        code={e}
                        key={e}
                        type={TomAlertType.ALARM}
                        language={language}
                    />
                ))}
        </div>
    );
}

interface TomOperationStatusLabelProps {
    tom: ParsedTom;
    language: string;
    noPopup?: Maybe<boolean>;
}

export function TomOperationStatusLabel({
    tom,
    language,
    noPopup,
}: TomOperationStatusLabelProps): JSX.Element | null {
    const showLabel = !!tom.lastOnlineStatus; // only show for toms that have online reporting
    if (!showLabel) {
        return null;
    }
    const labelColor = getOperationStatusLabelColor(tom);
    const showPopup = !getOrElse(noPopup, false);
    const trigger = <OperatorBadge circular color={labelColor} empty />;

    if (showPopup) {
        return (
            <OperatorPopup trigger={trigger}>
                <OperationStatusDetail
                    tom={tom}
                    language={language}
                    showLabel={false}
                    tomAlertList={
                        <TomAlertList
                            errorCodes={tom.errorCodes}
                            alarmCodes={tom.alarmCodes}
                            language={language}
                        />
                    }
                />
            </OperatorPopup>
        );
    } else {
        return trigger;
    }
}
