import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { ClearanceCreateSlideIn } from '../../clearance-detail/components/ClearanceCreateSlideIn.tsx';
import { ClearanceEditValidityFromDateSlideIn } from '../../clearance-detail/components/ClearanceEditValidityFromDateSlideIn.tsx';
import { ClearanceEditValidityToDateSlideIn } from '../../clearance-detail/components/ClearanceEditValidityToDateSlideIn.tsx';
import {
    ClearanceLpCountrySlideIn,
    ClearancePermitTypeSelectionSlideIn,
    ClearanceValidFromSlideIn,
    ClearanceValidToSlideIn,
    PriceMultiplierSelectionSlideIn,
} from '../../clearance-detail/components/ClearanceSecondLevelSlideIns.tsx';
import { ClearanceSlideIn } from '../../clearance-detail/components/ClearanceSlideIn.tsx';
import { LicensePlateDetailLevel2SlideIns } from '../../common/components/LicensePlateDetailSlideIn.tsx';
import { NoPermission } from '../../common/components/NoPermission.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as MetaState from '../../common/state/MetaState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypesState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { AddBadgeSlideIn } from '../../permit-detail/components/AddBadgeSlideIn.tsx';
import { AddLicensePlateCountrySlideIn } from '../../permit-detail/components/AddLicensePlateCountrySlidein.tsx';
import { AddLicensePlateSlideIn } from '../../permit-detail/components/AddLicensePlateSlideIn.tsx';
import { AddressSlideIn } from '../../permit-detail/components/Address.tsx';
import {
    AddressCountrySelectionSlideIn,
    setAddressCountry,
} from '../../permit-detail/components/AddressCountrySelectionSlideIn.tsx';
import { CounterPaymentChannelSelectionSlideIn } from '../../permit-detail/components/CounterPaymentChannel.tsx';
import { PermitCreateValidityDurationSlideIn } from '../../permit-detail/components/PermitCreateValidityDurationSlideIn.tsx';
import { PermitCreateValidityFromDateSlideIn } from '../../permit-detail/components/PermitCreateValidityFromDateSlideIn.tsx';
import { PermitCreateValidityToDateSlideIn } from '../../permit-detail/components/PermitCreateValidityToDateSlideIn.tsx';
import { PermitLicensePlateSlidein } from '../../permit-detail/components/PermitLicensePlateSlidein.tsx';
import { PermitSlideIn } from '../../permit-detail/components/PermitSlideIn.tsx';
import { PermitTypeSelectionSlideIn } from '../../permit-detail/components/PermitTypeSelectionSlideIn.tsx';
import { PrintSelectionSlideIn } from '../../permit-detail/components/PrintSelectionSlideIn.tsx';
import { PermitCancellationSlideIn } from '../../permit-detail/components/PermitCancellationSlideIn.tsx';
import { VignetteAmountSlideIn } from '../../permit-detail/components/VignetteAmountSlideIn.tsx';
import { ZoneSelectionSlideIn } from '../../permit-detail/components/ZoneSelectionSlideIn.tsx';
import * as AddressState from '../../permit-detail/state/AddressState.ts';
import * as PermitCreateState from '../../permit-detail/state/PermitCreateState.ts';
import { Body, Container, TabItem, Tabs } from '../../ui/layout/TabContent.tsx';
import { selectFilteredClearancesTab } from '../actions/FilteredClearancesActions.ts';
import { selectFilteredPermitsTab } from '../actions/FilteredPermitsActions.ts';
import { permitListTexts } from '../i18n/ClearancePermitListTexts.tsx';
import * as ClearancePermitListState from '../state/ClearancePermitListState.ts';
import { SelectedTab } from '../state/ClearancePermitListState.ts';
import * as FilteredClearancesState from '../state/FilteredClearancesState.ts';
import * as FilteredPermitsState from '../state/FilteredPermitsState.ts';
import * as SearchState from '../state/SearchState.ts';
import {
    FilteredClearances,
    renderClearanceFilterSlideIns,
} from './filtered-clearances/FilteredClearances.tsx';
import {
    FilteredPermits,
    renderPermitFilterSlideIns,
} from './filtered-permits/FilteredPermits.tsx';
import { ResultList } from './results/ResultsList.tsx';
import { SearchContainer } from './search/Search.tsx';
import * as ResultState from '../state/ResultState.ts';
import { BaseAbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { ClearanceRequestsList } from '../../clearance-requests/ClearanceRequestsList.tsx';
import { DetailSlideIn } from '../../clearance-requests/Detail.tsx';
import * as ClearanceRequestsState from '../../clearance-requests/ClearanceRequestsState.ts';
import { ClearanceRequest } from '../../common/models/ClearanceRequest.ts';
import { ContextualState } from 'dg-web-shared/lib/ContextualServerStateSlice.ts';

interface ClearancePermitListState {
    permitCreateLayout: PermitCreateState.Layout.State;
    createConfig: PermitCreateState.Config.State;
    permitTypes: PermitTypesState.State;
    lpCountries: MetaState.LicensePlateCountries.State;
    addressCountries: MetaState.AddressCountries.State;
    operatorData: OperatorDataState.State;
    currentLogin: CurrentOperatorLoginState.State;
    search: SearchState.State;
    settings: SettingsState.State;
    layout: ClearancePermitListState.Layout.State;
    filteredPermitsFilter: FilteredPermitsState.Filter.State;
    filteredPermitsList: FilteredPermitsState.List.State;
    filteredClearancesFilter: FilteredClearancesState.Filter.State;
    filteredClearancesList: FilteredClearancesState.List.State;
    clearanceRequests: ContextualState<ClearanceRequest[]>;
    addressEdit: AddressState.Edit.State;
    result: ResultState.State;
}

export interface Texts {
    CSVDownloadTooltip: Translation;
    FilterTooltip: Translation;
    HideWhitelists: Translation;
    listTabHeader: Translation;
    permitListTabHeader: Translation;
    clearancesListTabHeader: Translation;
    clearancesRequestHeader: Translation;
    pending: Translation;
}

export const CLEARANCE_PERMIT_SLIDEIN_ID =
    'ClearancePermitListSlideinContainer';

export class ClearancePermitList extends Flux.Container<ClearancePermitListState> {
    stateSelector(): ClearancePermitListState {
        const search = SearchState.get(this.props.allState);
        const context =
            search.searchEntity &&
            search.searchValue &&
            search.searchValue.length > 2
                ? { search: search.searchValue, entity: search.searchEntity }
                : null;
        return {
            permitCreateLayout: PermitCreateState.Layout.get(
                this.props.allState,
            ),
            createConfig: PermitCreateState.Config.get(this.props.allState),
            permitTypes: PermitTypesState.get(this.props.allState),
            lpCountries: MetaState.LicensePlateCountries.get(
                this.props.allState,
            ),
            addressCountries: MetaState.AddressCountries.get(
                this.props.allState,
            ),
            operatorData: OperatorDataState.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            search: search,
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ClearancePermitListState.Layout.get(this.props.allState),
            filteredPermitsFilter: FilteredPermitsState.Filter.get(
                this.props.allState,
            ),
            filteredPermitsList: FilteredPermitsState.List.get(
                this.props.allState,
            ),
            filteredClearancesFilter: FilteredClearancesState.Filter.get(
                this.props.allState,
            ),
            filteredClearancesList: FilteredClearancesState.List.get(
                this.props.allState,
            ),
            clearanceRequests: ClearanceRequestsState.List.get(
                this.props.allState,
                ClearanceRequestsState.Filter.pendingOnly,
            ),
            addressEdit: AddressState.Edit.get(this.props.allState),
            result: ResultState.get(this.props.allState, context),
        };
    }

    txt(): Texts {
        return permitListTexts[this.state.settings.language];
    }

    getDependingStateSlices(): BaseAbstractLegacyServerState[] {
        switch (this.state.layout.selectedTab) {
            case SelectedTab.Search:
                return [this.state.result];
            case SelectedTab.FilteredPermits:
                return [this.state.filteredPermitsList];
            case SelectedTab.FilteredClearances:
                return [this.state.filteredClearancesList];
            default:
                return [];
        }
    }

    renderTabsBody(): JSX.Element | null {
        switch (this.state.layout.selectedTab) {
            case SelectedTab.Search:
                return <ResultList result={this.state.result} />;

            case SelectedTab.FilteredPermits:
                return <FilteredPermits allState={this.props.allState} />;

            case SelectedTab.FilteredClearances:
                return <FilteredClearances allState={this.props.allState} />;

            case SelectedTab.ClearanceRequests:
                return <ClearanceRequestsList pendingOnly={true} />;

            default:
                return null;
        }
    }

    renderOutsideSlideIns(): JSX.Element {
        return (
            <div>
                <PermitSlideIn allState={this.props.allState} />
                <PermitCreateValidityFromDateSlideIn
                    allState={this.props.allState}
                />
                <PermitCreateValidityToDateSlideIn
                    allState={this.props.allState}
                />
                <PermitCreateValidityDurationSlideIn
                    allState={this.props.allState}
                />
                <ZoneSelectionSlideIn />
                <PermitLicensePlateSlidein allState={this.props.allState} />
                <AddLicensePlateSlideIn allState={this.props.allState} />
                <AddBadgeSlideIn />
                <AddressSlideIn />
                <AddressCountrySelectionSlideIn
                    allState={this.props.allState}
                    onSelect={v =>
                        this.update(store => setAddressCountry(store, v))
                    }
                    open={this.state.addressEdit.selectAddressCountryOpen}
                    onLeftActionClick={() =>
                        this.update(store =>
                            AddressState.Edit.stateWrite(store, {
                                selectAddressCountryOpen: false,
                            }),
                        )
                    }
                />
                <LicensePlateDetailLevel2SlideIns outsideBody />
                <VignetteAmountSlideIn allState={this.props.allState} />
                <PrintSelectionSlideIn allState={this.props.allState} />
                <PermitCancellationSlideIn />
                <AddLicensePlateCountrySlideIn allState={this.props.allState} />
                <ClearanceSlideIn allState={this.props.allState} />
                <ClearanceCreateSlideIn allState={this.props.allState} />
                <ClearanceLpCountrySlideIn allState={this.props.allState} />
                <ClearanceValidFromSlideIn allState={this.props.allState} />
                <ClearanceValidToSlideIn allState={this.props.allState} />
                <PriceMultiplierSelectionSlideIn
                    allState={this.props.allState}
                />
                <ClearancePermitTypeSelectionSlideIn
                    allState={this.props.allState}
                />
                <ClearanceEditValidityFromDateSlideIn
                    allState={this.props.allState}
                />
                <ClearanceEditValidityToDateSlideIn
                    allState={this.props.allState}
                />
                <PermitTypeSelectionSlideIn />

                <CounterPaymentChannelSelectionSlideIn />
                <div id={CLEARANCE_PERMIT_SLIDEIN_ID} />
            </div>
        );
    }

    renderInsideSlideIns(): JSX.Element[] {
        let slideIns: JSX.Element[] = [];

        switch (this.state.layout.selectedTab) {
            case SelectedTab.FilteredPermits:
                slideIns = slideIns.concat(
                    renderPermitFilterSlideIns(this.props.allState),
                );
                break;
            case SelectedTab.FilteredClearances:
                slideIns = slideIns.concat(
                    renderClearanceFilterSlideIns(this.props.allState),
                );
                break;
            case SelectedTab.ClearanceRequests:
                slideIns = slideIns.concat([
                    <DetailSlideIn key="clearenceRequestDetail" />,
                ]);
                break;
            default:
        }

        return slideIns;
    }

    bodyDisabled(): boolean {
        const createEnabled = this.state.permitCreateLayout.createEnabled;
        switch (this.state.layout.selectedTab) {
            case SelectedTab.Search:
                return false;
            case SelectedTab.FilteredPermits:
                return (
                    createEnabled ||
                    this.state.filteredPermitsFilter.filterSlideInVisible
                );
            case SelectedTab.FilteredClearances:
                return (
                    createEnabled ||
                    this.state.filteredClearancesFilter.filterSlideInVisible
                );
            default:
                return false;
        }
    }

    render() {
        const operator = this.state.operatorData.data;
        const login = this.state.currentLogin.data;
        const layout = this.state.layout;
        const texts = permitListTexts[this.state.settings.language];

        if (isDefined(operator) && isDefined(login)) {
            const loginIsNotAllowed =
                !login.permissions.permitsRead &&
                !login.permissions.whitelistCreate;
            if (
                !operator.licensePlatePermitSettings.active ||
                loginIsNotAllowed
            ) {
                return <NoPermission />;
            }
            const filteredPermitsResultSize =
                this.state.filteredPermitsList.data.size;
            const filteredClearancesResultSize =
                this.state.filteredClearancesList.data.size;
            const openClearanceRequestsCount = this.state.clearanceRequests.data
                ? this.state.clearanceRequests.data.length
                : null;
            return (
                <Container>
                    <Tabs>
                        <SearchContainer allState={this.props.allState} />
                        <TabItem
                            active={
                                layout.selectedTab ===
                                SelectedTab.FilteredPermits
                            }
                            description={thenElse(
                                this.state.operatorData.data,
                                d =>
                                    d.licensePlatePermitSettings.whitelistActive
                                        ? texts.permitListTabHeader()
                                        : texts.listTabHeader(),
                                '',
                            )}
                            rightText={
                                isDefined(filteredPermitsResultSize) &&
                                filteredPermitsResultSize > 0
                                    ? filteredPermitsResultSize.toString()
                                    : null
                            }
                            onClick={() =>
                                this.update(selectFilteredPermitsTab)
                            }
                        />
                        <Conditional
                            c={
                                operator.licensePlatePermitSettings
                                    .whitelistActive
                            }
                        >
                            <TabItem
                                active={
                                    layout.selectedTab ===
                                    SelectedTab.FilteredClearances
                                }
                                description={texts.clearancesListTabHeader()}
                                rightText={
                                    isDefined(filteredClearancesResultSize) &&
                                    filteredClearancesResultSize > 0
                                        ? filteredClearancesResultSize.toString()
                                        : null
                                }
                                onClick={() =>
                                    this.update(selectFilteredClearancesTab)
                                }
                            />
                        </Conditional>
                        <Conditional
                            c={
                                operator.licensePlatePermitSettings
                                    .hasClearanceRequests
                            }
                        >
                            <TabItem
                                active={
                                    layout.selectedTab ===
                                    SelectedTab.ClearanceRequests
                                }
                                description={texts.clearancesRequestHeader()}
                                rightText={openClearanceRequestsCount?.toString()}
                                onClick={() =>
                                    this.update(store =>
                                        ClearancePermitListState.Layout.stateWrite(
                                            store,
                                            {
                                                selectedTab:
                                                    ClearancePermitListState
                                                        .SelectedTab
                                                        .ClearanceRequests,
                                            },
                                        ),
                                    )
                                }
                                specialColor={
                                    openClearanceRequestsCount &&
                                    openClearanceRequestsCount > 0
                                        ? 'yellow'
                                        : null
                                }
                            />
                        </Conditional>
                    </Tabs>
                    <Body
                        disabled={this.bodyDisabled()}
                        slideIns={this.renderInsideSlideIns()}
                        dependingStateSlices={this.getDependingStateSlices()}
                        specialBorderColor={
                            openClearanceRequestsCount &&
                            openClearanceRequestsCount > 0 &&
                            this.state.layout.selectedTab ===
                                SelectedTab.ClearanceRequests
                                ? 'yellow'
                                : null
                        }
                    >
                        {this.renderTabsBody()}
                    </Body>

                    {this.renderOutsideSlideIns()}
                </Container>
            );
        } else {
            return null;
        }
    }
}
