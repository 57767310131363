import { Translations } from '../../common/i18n/Text.ts';
import * as ClearancePermitList from '../components/ClearancePermitList.tsx';
import { ResultsListTexts } from '../components/results/ResultsList.tsx';
import * as AddMenu from '../components/shared/AddMenu.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { css } from '@emotion/css';

export const resultsListTexts: Translations<ResultsListTexts> = {
    de: {
        noResultsTitle: () => 'Keine Resultate gefunden.',
        noResultsDescription: () => 'Bitte überprüfen Sie ihre Sucheingabe',
        searchGuide: (
            whitelistCreate: boolean,
            permitsWrite: boolean,
        ): JSX.Element => {
            return (
                <div>
                    <h1>
                        Suchen
                        <Conditional c={permitsWrite}>
                            <span>{' und Bearbeiten'}</span>
                        </Conditional>
                    </h1>
                    <h3 className={css(Typo.body)}>
                        <span>Mittels Suchfeld können Sie bestehende</span>
                        <span>{' Bewilligungen '}</span>
                        <Conditional c={whitelistCreate}>
                            <span>{' oder Freigaben '}</span>
                        </Conditional>
                        <span>{' suchen'}</span>
                        <Conditional c={permitsWrite}>
                            <span>{' und bearbeiten'}</span>
                        </Conditional>
                        .
                    </h3>
                </div>
            );
        },
        Identification: () => 'Identifikation',
        From: () => 'Von',
        To: () => 'Bis',
        PermitType: () => 'Bewilligungsart',
        Info: () => 'Information',
    },
    fr: {
        noResultsTitle: () => 'Aucun résultat relevé.',
        noResultsDescription: () =>
            'Veuillez vérifier le critère de recherche saise',
        searchGuide: (
            whitelistCreate: boolean,
            permitsWrite: boolean,
        ): JSX.Element => {
            return (
                <div>
                    <h1>
                        Rechercher
                        <Conditional c={permitsWrite}>
                            <span>{' et modifier'}</span>
                        </Conditional>
                    </h1>
                    <h3>
                        <span>
                            Avec le champ de recherche vous pouvez rechercher
                        </span>
                        <Conditional c={permitsWrite}>
                            <span>{' et modifier'}</span>
                        </Conditional>
                        <span>{' les autorisations ou'}</span>
                        <Conditional c={whitelistCreate}>
                            <span>{' les approbations'}</span>
                        </Conditional>
                        .
                    </h3>
                </div>
            );
        },
        Identification: () => 'Identification',
        From: () => 'Du',
        To: () => 'Au',
        PermitType: () => "Type d'autorisation",
        Info: () => 'Information',
    },
    it: {
        noResultsTitle: () => 'Nessun risultato trovato.',
        noResultsDescription: () =>
            'Vogliate verificare il criterio di ricerca inserito',
        searchGuide: (
            whitelistCreate: boolean,
            permitsWrite: boolean,
        ): JSX.Element => {
            return (
                <div>
                    <h1>
                        Cercare
                        <Conditional c={permitsWrite}>
                            <span>{' e modificare'}</span>
                        </Conditional>
                    </h1>
                    <h3>
                        <span>Con il campo di ricerca potete cercare</span>
                        <Conditional c={permitsWrite}>
                            <span>{' e modificare'}</span>
                        </Conditional>
                        <span>{' le autorizzazioni'}</span>
                        <Conditional c={whitelistCreate}>
                            <span>{' e le approvazioni'}</span>
                        </Conditional>
                        .
                    </h3>
                </div>
            );
        },
        Identification: () => 'Identificazione',
        From: () => 'Dal',
        To: () => 'Al',
        PermitType: () => "Tipo d'autorizzazione",
        Info: () => 'Informazione',
    },
};

export const permitListTexts: Translations<ClearancePermitList.Texts> = {
    de: {
        CSVDownloadTooltip: () => 'CSV Export',
        FilterTooltip: () => 'Filter definieren',
        HideWhitelists: () => 'Freigaben ausgeblendet',
        listTabHeader: () => 'Liste',
        permitListTabHeader: () => 'Bewilligungsliste',
        clearancesListTabHeader: () => 'Freigabeliste',
        clearancesRequestHeader: () => 'Pendente Anträge',
        pending: () => 'Pendent',
    },
    fr: {
        CSVDownloadTooltip: () => 'Télécharger CSV',
        FilterTooltip: () => 'Définir le filtre',
        HideWhitelists: () => 'Masquer les approbations',
        listTabHeader: () => 'Liste',
        permitListTabHeader: () => 'Liste autorisations',
        clearancesListTabHeader: () => 'Liste approbations',
        clearancesRequestHeader: () => 'Demandes en suspens',
        pending: () => 'En suspens',
    },
    it: {
        CSVDownloadTooltip: () => 'Scarica CSV',
        FilterTooltip: () => 'Definire filtro',
        HideWhitelists: () => 'Nascondi le approvazioni',
        listTabHeader: () => 'Lista',
        permitListTabHeader: () => 'Lista autorizzazioni',
        clearancesListTabHeader: () => 'Lista approvazioni',
        clearancesRequestHeader: () => 'Richieste in sospeso',
        pending: () => 'In sospeso',
    },
};

export const addButtonTexts: Translations<AddMenu.Texts> = {
    de: {
        NewPermitTooltip: () => 'Bewilligung erfassen',
        AddMenuTitle: () => 'Erfassen',
        AddClearance: () => 'Freigabe erfassen...',
        AddPermit: () => 'Bewilligung erfassen...',
    },
    fr: {
        NewPermitTooltip: () => 'Saisir autorisation',
        AddMenuTitle: () => 'Saisir',
        AddClearance: () => 'Saisir approbation...',
        AddPermit: () => 'Saisir autorisation...',
    },
    it: {
        NewPermitTooltip: () => 'Registra autorizzazione',
        AddMenuTitle: () => 'Registra',
        AddClearance: () => 'Registra approvazione...',
        AddPermit: () => 'Registra autorizzazione...',
    },
};
