import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined, isUndefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    Body,
    EditHeader,
} from '../../common/components/LicensePlateDetailSlideIn.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import {
    LicensePlate,
    ServerDataState,
} from '../../common/state/LicensePlateState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { ConfirmationHeader, HalfSlideIn } from '../../ui/slidein/Slidein.tsx';
import * as PermitLicensePlateSlideinTexts from '../i18n/PermitLicensePlateSlideinTexts.ts';
import * as AddressState from '../state/AddressState.ts';
import * as LicensePlateState from '../state/LicensePlateState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';

interface State {
    settings: SettingsState.State;
    entity: LicensePlateState.Entity.State;
    relevantPermitType: Maybe<PermitTypeState.PermitType>;
    server: ServerDataState.State;
    operatorData: OperatorDataState.State;
    currentLogin: CurrentOperatorLoginState.State;
}

const abortCreateProcess = (store: Flux.Store): string => {
    LicensePlateState.resetAllStates(store);
    return 'PermitLicensePlateSlideIn-abortCreateProcess';
};

export const addLicensePlateToNewPermit = (
    store: Flux.Store,
    lp: LicensePlate,
): string => {
    PermitCreateState.Config.set(store, (s: PermitCreateState.Config.State) => {
        const copy = s.licensePlates.slice();
        copy.push(lp);
        s.licensePlates = copy;
        return s;
    });
    LicensePlateState.resetAllStates(store);
    PermitCreateState.Calc.reset(store);
    AddressState.ServerList.reset(store);
    return 'PermitLicensePlateSlidein-addLicensePlateToNewPermit';
};

export const addLicensePlateToExistingPermit = (
    store: Flux.Store,
    lp: LicensePlate,
): string => {
    PermitEditState.Edit.set(store, (s: PermitEditState.Edit.State) => {
        // if the edit state is undefined, we copy over the plates from the server state
        const server = PermitEditState.Server.get(store).data;
        if (isUndefined(s.licensePlates) && isDefined(server)) {
            s.licensePlates = server.licensePlates.slice();
        }

        const editedLps = s.licensePlates;
        if (isDefined(editedLps)) {
            const copy = editedLps.slice();
            copy.push({
                id: lp.id,
                licensePlateNr: lp.licensePlateNr,
                type: lp.type,
                country: lp.country,
            });
            s.licensePlates = copy;
        }
        return s;
    });
    LicensePlateState.resetAllStates(store);
    PermitEditState.Validation.reset(store);
    AddressState.ServerList.reset(store);
    return 'PermitLicensePlateSlidein-addLicensePlateToNewPermit';
};

export class PermitLicensePlateSlidein extends Flux.Container<State> {
    static displayName = 'PermitSlidein';

    getPermitType(): Maybe<PermitTypeState.PermitType> {
        const entity = LicensePlateState.Entity.get(this.props.allState);
        if (
            entity.context === LicensePlateState.Entity.Context.NEW_PERMIT_ADD
        ) {
            const permitTypeId = PermitCreateState.Config.get(
                this.props.allState,
            ).permitTypeId;
            if (isDefined(permitTypeId)) {
                return PermitTypeState.getById(
                    this.props.allState,
                    permitTypeId,
                );
            }
        } else {
            const permit = PermitEditState.Server.get(this.props.allState).data;
            if (isDefined(permit)) {
                return PermitTypeState.getById(
                    this.props.allState,
                    permit.permitTypeId,
                );
            }
        }
    }

    stateSelector(): State {
        const entity = LicensePlateState.Entity.get(this.props.allState);
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            entity,
            relevantPermitType: this.getPermitType(),
            server: ServerDataState.get(
                this.props.allState,
                entity.id ? { lpId: entity.id } : null,
            ),
            operatorData: OperatorDataState.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    missingVignette(): boolean {
        const permitType = this.state.relevantPermitType;
        const serverData = this.state.server.data;
        if (permitType && serverData) {
            for (const allowedType of permitType.allowedLicensePlateTypes) {
                if (
                    allowedType.type === serverData.type &&
                    allowedType.needsVignette &&
                    serverData.vignettes.length === 0
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    confirmDisabled(): boolean {
        return (
            isUndefined(this.state.relevantPermitType) ||
            isUndefined(this.state.server.data) ||
            this.missingVignette()
        );
    }

    confirmButtonSpecialState(): Maybe<ButtonSpecialState> {
        if (
            this.state.entity.confirmPressedWhileError &&
            this.confirmDisabled()
        ) {
            return ButtonSpecialState.ERROR;
        }
        if (this.confirmDisabled()) {
            return ButtonSpecialState.DISABLED;
        }
        return null;
    }

    renderHeader() {
        if (
            this.state.entity.context !== LicensePlateState.Entity.Context.EDIT
        ) {
            return (
                <ConfirmationHeader
                    language={this.state.settings.language}
                    title={PermitLicensePlateSlideinTexts.permitLicensePlateSlideInTexts[
                        this.state.settings.language
                    ].AddLicensePlateToPermit()}
                    onCancel={() => this.update(abortCreateProcess)}
                    confirmButtonSpecialState={this.confirmButtonSpecialState()}
                    onConfirm={() => {
                        const licensePlate = this.state.server.data;
                        if (this.confirmDisabled()) {
                            this.update(
                                LicensePlateState.Entity
                                    .setConfirmPressedWhilteError,
                            );
                        } else if (
                            this.state.entity.context ===
                            LicensePlateState.Entity.Context.NEW_PERMIT_ADD
                        ) {
                            this.props.allState.update(store => {
                                if (licensePlate) {
                                    return addLicensePlateToNewPermit(
                                        store,
                                        licensePlate,
                                    );
                                }
                                return 'LicensePlate not valid';
                            });
                        } else if (
                            this.state.entity.context ===
                            LicensePlateState.Entity.Context.EXISTING_PERMIT_ADD
                        ) {
                            this.props.allState.update(store => {
                                if (licensePlate) {
                                    return addLicensePlateToExistingPermit(
                                        store,
                                        licensePlate,
                                    );
                                }
                                return 'LicensePlate not valid';
                            });
                        } else {
                            return null;
                        }
                    }}
                />
            );
        } else {
            const lp = this.state.server.data;
            if (lp) {
                return (
                    <EditHeader
                        language={this.state.settings.language}
                        lp={lp}
                        update={this.props.allState.update}
                        onClose={() =>
                            this.update(LicensePlateState.resetAllStates)
                        }
                        canEdit={
                            this.state.currentLogin.data
                                ? this.state.currentLogin.data.permissions
                                      .permitsWrite
                                : false
                        }
                    />
                );
            } else {
                return null;
            }
        }
    }

    render() {
        return (
            <HalfSlideIn open={isDefined(this.state.entity.id)} outsideBody>
                {this.state.server.data && (
                    <Body
                        lp={this.state.server.data}
                        language={this.state.settings.language}
                        showTypeDescription={
                            (this.state.operatorData.data &&
                                this.state.operatorData.data.settings
                                    .carTypeDescription) ||
                            false
                        }
                        showMissingVignetteError={
                            this.missingVignette() &&
                            this.state.entity.confirmPressedWhileError
                        }
                        update={this.props.allState.update}
                        canEdit={
                            this.state.currentLogin.data
                                ? this.state.currentLogin.data.permissions
                                      .permitsWrite
                                : false
                        }
                    />
                )}
                {this.renderHeader()}
            </HalfSlideIn>
        );
    }
}
