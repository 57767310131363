import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ActivityStatementState from '../state/ActivityStatementState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import {
    HalfSlideIn,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { MonthPicker } from '../../ui/date-picker/MonthPicker.tsx';
import moment from 'moment';
import { SlideInDatePickerContainer } from '../../ui/slidein/DatePickerSlideIn.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as ActivityStatementTexts from '../i18n/ActivityStatementTexts.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

interface State {
    settings: SettingsState.State;
    layout: ActivityStatementState.Layout.State;
    filter: ActivityStatementState.Filter.State;
    data: ActivityStatementState.Data.State;
}

export interface Texts {
    From: Translation;
    To: Translation;
}

export class FilterSlideIn extends Flux.Container<State> {
    txt(): Texts {
        return ActivityStatementTexts.slideIn[this.state.settings.language];
    }

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ActivityStatementState.Layout.get(this.props.allState),
            filter: ActivityStatementState.Filter.get(this.props.allState),
            data: ActivityStatementState.Data.get(this.props.allState),
        };
    }

    render() {
        const globalMin: Maybe<moment.Moment> =
            this.state.data.data.length > 0
                ? this.state.data.data[this.state.data.data.length - 1].period
                : null;
        const globalMax: Maybe<moment.Moment> =
            this.state.data.data.length > 0
                ? this.state.data.data[0].period.clone().add(1, 'month')
                : null;
        const locale = Formatter.getLocaleFromString(
            this.state.settings.language,
        );

        const monthPickerCaptionStyle = css({
            ...Typo.captionC1,
            color: ColorHex.darkblue,
            marginTop: '24px',
            marginBottom: '8px',
        });

        return (
            <HalfSlideIn open={this.state.layout.filterOpen || false}>
                <StandardFirstLevelHeader
                    onClose={() =>
                        this.update(store =>
                            ActivityStatementState.Layout.stateWrite(store, {
                                filterOpen: false,
                            }),
                        )
                    }
                >
                    <SlideInHeaderTexts
                        title={
                            Formatter.monthAbrevYear(
                                this.state.filter.fromFilter,
                                locale,
                            ) +
                            ' - ' +
                            Formatter.monthAbrevYear(
                                this.state.filter.toFilter,
                                locale,
                            )
                        }
                        hasLeftIcon={false}
                    />
                </StandardFirstLevelHeader>
                <SlideInBody>
                    <SlideInDatePickerContainer>
                        <div className={monthPickerCaptionStyle}>
                            {this.txt().From()}
                        </div>
                        <MonthPicker
                            selectedDate={this.state.filter.fromFilter}
                            currentDate={moment()}
                            onSelect={(d: moment.Moment) => {
                                this.update(store =>
                                    ActivityStatementState.Filter.stateWrite(
                                        store,
                                        { fromFilter: d },
                                    ),
                                );
                            }}
                            minDate={globalMin}
                            maxDate={this.state.filter.toFilter}
                            language={this.state.settings.language}
                        />
                        <div className={monthPickerCaptionStyle}>
                            {this.txt().To()}
                        </div>
                        <MonthPicker
                            selectedDate={this.state.filter.toFilter}
                            currentDate={moment()}
                            onSelect={(d: moment.Moment) => {
                                this.update(store =>
                                    ActivityStatementState.Filter.stateWrite(
                                        store,
                                        { toFilter: d },
                                    ),
                                );
                            }}
                            minDate={this.state.filter.fromFilter}
                            maxDate={globalMax}
                            language={this.state.settings.language}
                        />
                    </SlideInDatePickerContainer>
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}
