import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { NumberMultiSelectionSlideIn } from '../../ui/slidein/MultiSelectionSlideIn.tsx';
import { operatorLoginsListTexts } from '../i18n/OperatorLoginsTexts.ts';
import { OperatorLoginsListTexts } from './OperatorLoginsList.tsx';
import { isUndefined, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { Localized } from '../../common/components/Localized.tsx';

interface OperatorLoginPermitTypeSlideInState {
    layout: OperatorLoginsState.Layout.State;
    operatorLoginsList: CommonOperatorLoginsState.List.State;
    edit: OperatorLoginsState.Edit.State;
    settings: SettingsState.State;
    permitTypes: PermitTypeState.State;
}

export class OperatorLoginPermitTypeSlideIn extends Flux.Container<OperatorLoginPermitTypeSlideInState> {
    static displayName = 'OperatorLoginPermitTypeSlideIn';

    stateSelector(): OperatorLoginPermitTypeSlideInState {
        return {
            layout: OperatorLoginsState.Layout.get(this.props.allState),
            operatorLoginsList: CommonOperatorLoginsState.List.get(
                this.props.allState,
            ),
            edit: OperatorLoginsState.Edit.get(this.props.allState),
            settings: new SettingsState.StateSlice(this.props.allState).state,
            permitTypes: PermitTypeState.get(this.props.allState),
        };
    }

    txt(): OperatorLoginsListTexts {
        return operatorLoginsListTexts[this.state.settings.language];
    }

    render() {
        const selectedId = this.state.layout.selectedOperatorLogin;
        const login = CommonOperatorLoginsState.getLoginById(
            this.state.operatorLoginsList.data,
            selectedId ? selectedId : -1,
        );

        function filterInactiveUnusedPermitTypes(
            permitTypeData: PermitTypeState.PermitType[],
        ) {
            return permitTypeData.filter(permitType => {
                return (
                    permitType.operatorState === 'ACTIVE' ||
                    (permitType.operatorState === 'INACTIVE' &&
                        permitType.hasNonArchivedContracts)
                );
            });
        }

        function flagInactivePermits(permit: PermitTypeState.PermitType) {
            return permit.operatorState === 'INACTIVE' ? (
                <>
                    {`${permit.description} — `}
                    <Localized
                        de="INAKTIV"
                        fr="INACTIVE"
                        it="INATTIVO"
                        en="INACTIVE"
                    />
                </>
            ) : (
                permit.description
            );
        }

        const options = filterInactiveUnusedPermitTypes(
            this.state.permitTypes.data,
        ).map(pt => {
            return {
                id: pt.id,
                displayText: flagInactivePermits(pt),
            };
        });

        if (isUndefined(login) && !this.state.layout.createEnabled) {
            return null;
        } else {
            const allowedPermitTypes = this.state.edit.allowedPermitTypes
                ? this.state.edit.allowedPermitTypes
                : thenElse(login, l => l.allowedPermitTypes, []);

            return (
                <NumberMultiSelectionSlideIn
                    open={this.state.layout.permitTypeSelectionOpen}
                    heading={this.txt().permitTypes()}
                    selection={allowedPermitTypes}
                    options={options}
                    onLeftActionClick={() =>
                        this.update(store =>
                            OperatorLoginsState.Layout.stateWrite(store, {
                                permitTypeSelectionOpen: false,
                            }),
                        )
                    }
                    setNewSelection={(v: number[]) =>
                        this.update(store =>
                            OperatorLoginsState.Edit.stateWrite(store, {
                                allowedPermitTypes: v,
                            }),
                        )
                    }
                    infoText={
                        allowedPermitTypes.length != options.length ? (
                            <Localized
                                de="Neue Bewilligungsarten werden nicht automatisch zugelassen."
                                fr="Les nouveaux types d'autorisation ne sont pas automatiquement autorisés."
                                it="I nuovi tipi di autorizzazione non vengono autorizzati automaticamente."
                                en="New permit types are not automatically allowed."
                            />
                        ) : null
                    }
                />
            );
        }
    }
}
