import moment from 'moment';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import { LicensePlate } from '../components/AddLicensePlateSlideIn.tsx';
import { Badge } from '../components/AddBadgeSlideIn.tsx';

export interface Overlap {
    permitId: number;
    identifier: string;
    type: string;
    validFrom: moment.Moment;
    validTo: moment.Moment;
}

export const parseOverlaps = (overlaps: any[]): Overlap[] => {
    return overlaps.map((o: any): Overlap => {
        return {
            permitId: o.permitId,
            identifier: o.identifier,
            type: o.type,
            validFrom: Parser.isoToMoment(o.validFrom),
            validTo: Parser.isoToMoment(o.validTo),
        };
    });
};

export const parseLpsThatNeedWhitelist = (
    lpsThatNeedWhitelist: any[],
): LicensePlate[] => {
    return lpsThatNeedWhitelist.map((o: any): LicensePlate => {
        return {
            id: o.id,
            licensePlateNr: o.licensePlateNr,
            type: o.type,
            country: o.country,
        };
    });
};

export const parseBadgesThatNeedWhitelist = (
    badgesThatNeedWhitelist: any[],
): Badge[] => {
    return badgesThatNeedWhitelist.map((o: any): Badge => {
        return {
            id: o.id,
            badgeLabelNr: o.badgeLabelNr,
        };
    });
};
