import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';

export namespace Layout {
    export interface State {
        selectedPermitType?: Maybe<number>;
        wthinValidityDatePickerOpen?: boolean;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'permit-types-Layout',
        {
            wthinValidityDatePickerOpen: false,
        },
    );
}

export namespace WithinValidityDatesEdit {
    export interface State {
        dates: Maybe<string[]>;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'permit-types-WithinValidityDatesEdit',
        { dates: null },
    );
}

export namespace ValidityDatesResponse {
    export type State = WriteStateSlice.State<void>;

    export const { get, setResponse } = WriteStateSlice.generate<void>(
        'permit-types-WithinValidityDatesResponse',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (body: any) => body,
    );
}
