import * as superagent from 'superagent';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import {
    generateWriteStateSlice,
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices.ts';
import { Language, languageFromString } from 'dg-web-shared/lib/Text.ts';
import { request } from '../AsyncRequest.ts';
import { Spinner } from '../ui/loaders/Spinner.tsx';
import { ConnectedChangePasswordFormController } from './ChangePasswordFormController.tsx';
import { passwordChangeTokenTexts } from './i18n.ts';
import { ServerCommunicationError } from './ServerCommunicationError.tsx';
import { useEffect } from 'react';
import { redirect, useSearchParams } from 'react-router-dom';

export function ConnectedChangePasswordController() {
    const { update } = useStore(s => ({
        getUserIdentityRemoteRequest: GetUserIdentityRemoteRequestState.get(s),
    }));

    const [searchParams] = useSearchParams();
    const language = languageFromString(searchParams.get('language') ?? 'de');
    const source =
        searchParams.get('source') ===
        PasswordChangeTokenSource[PasswordChangeTokenSource.WEB]
            ? PasswordChangeTokenSource.WEB
            : PasswordChangeTokenSource.APP;

    const token = searchParams.get('token') ?? '';

    useEffect(() => {
        if (token.length === 0) {
            redirect('/');
        }

        update(
            request(
                (data: { token: string }) =>
                    superagent.get(
                        '/ui-api/operator-account/password-change-token/' +
                            encodeURIComponent(data.token),
                    ),

                (store, res): string => {
                    GetUserIdentityRemoteRequestState.setResponse(store, res);
                    return 'change-password';
                },
            ),
            { token },
        );
    }, [token]);

    return (
        <ChangePasswordController
            language={language}
            source={source}
            token={token}
        />
    );
}

export enum PasswordChangeTokenSource {
    APP,
    WEB,
}

function ChangePasswordController({
    language,
    token,
    source,
}: {
    language: Language;
    token: string;
    source: PasswordChangeTokenSource;
}) {
    const t = passwordChangeTokenTexts[language];

    const { storeState } = useStore(s => ({
        getUserIdentityRemoteRequest: GetUserIdentityRemoteRequestState.get(s),
    }));

    switch (storeState.getUserIdentityRemoteRequest.status) {
        case RequestStatus.NEVER_EXECUTED:
            return <Spinner loading={true} />;

        case RequestStatus.PENDING:
            return <Spinner loading={true} />;

        case RequestStatus.ERROR:
            return (
                <ServerCommunicationError
                    language={language}
                    message={t.tokenLookupFailed(
                        storeState.getUserIdentityRemoteRequest.httpStatus,
                    )}
                />
            );

        case RequestStatus.SUCCESS:
            return (
                <ConnectedChangePasswordFormController
                    language={language}
                    token={token}
                    operatorLogin={
                        storeState.getUserIdentityRemoteRequest.data
                            .operatorLogin
                    }
                    source={source}
                />
            );
    }
}

namespace GetUserIdentityRemoteRequestState {
    export type State = ServerRequestState<GetUserIdentityResponse>;

    export const { get, setResponse, reset } =
        generateWriteStateSlice<GetUserIdentityResponse>({
            key: 'change-password-controller-get-user-identity-remote-request',
        });
}

interface GetUserIdentityResponse {
    operatorLogin: { firstName: string; lastName: string };
}
