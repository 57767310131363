import { TableColumn, ColumnWidth, ColVerticalAlign } from './Table.tsx';
import { Icon16 } from '../icons/Icon.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { css } from '@emotion/css';

export const ChevronRightColumn = (): JSX.Element => {
    return (
        <TableColumn
            width={ColumnWidth._24px}
            captionFontSize={true}
            verticalAlign={ColVerticalAlign.middle}
        >
            <div className={css({ paddingTop: 7 })}>
                <Icon16 icon={Icons16.chevronRight} />
            </div>
        </TableColumn>
    );
};
