import { Translations } from '../../common/i18n/Text.ts';
import { Texts } from '../components/Shared.tsx';

export const texts: Translations<Texts> = {
    de: {
        validFrom: () => 'Minimales Von-Datum der Bewilligung ',
        validTo: () => 'Maximales Bis- oder Kaufdatum der Bewilligung',
        personalNumber: () => 'Personalnummer',
        contractNumber: () => 'Vertragsnummer',
        infos: () => 'Zusatzinformationen',
    },
    fr: {
        contractNumber: () => 'Numéro de contrat',
        infos: () => 'Informations complémentaires',
        personalNumber: () => "Numéro d'employé",
        validFrom: () => "Date initiale minimale de l'autorisation",
        validTo: () => "Date finale ou d'achat maximale de l'autorisation",
    },
    it: {
        contractNumber: () => 'Numero contratto',
        infos: () => 'Informazioni aggiuntive',
        personalNumber: () => 'Numero collaboratore',
        validFrom: () => "Data iniziale minima dell'autorizzazione",
        validTo: () => "Data finale o d'acquisto massima dell'autorizzazione",
    },
};
