import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import {
    NumberSingleSelectionSlideIn,
    Option,
} from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as PermitCreateTexts from '../i18n/PermitCreateTexts.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { range } from 'dg-web-shared/lib/ArrayUtil.ts';

export interface Texts {
    Heading: Translation;
    Caption: Translation;
    Vignette: (i: number) => string;
}

interface State {
    settings: SettingsState.State;
    config: PermitCreateState.Config.State;
    layout: PermitCreateState.Layout.State;
    permitType: Maybe<PermitTypeState.PermitType>;
}

export class VignetteAmountSlideIn extends Flux.Container<State> {
    static displayName = 'VignetteAmountSlideIn';

    stateSelector(): State {
        const config = PermitCreateState.Config.get(this.props.allState);
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            config: config,
            layout: PermitCreateState.Layout.get(this.props.allState),
            permitType: PermitTypeState.getById(
                this.props.allState,
                config.permitTypeId,
            ),
        };
    }

    txt(): Texts {
        return PermitCreateTexts.vignetteAmount[this.state.settings.language];
    }

    renderOptions(): Option<number>[] {
        const permitType = this.state.permitType;
        if (isDefined(permitType)) {
            return range(permitType.maxLicenses * 2 + 1).map((i: number) => {
                return {
                    id: i,
                    displayText: this.txt().Vignette(i),
                };
            });
        } else {
            return [];
        }
    }

    render() {
        return (
            <NumberSingleSelectionSlideIn
                open={this.state.layout.vignetteCountSelectionOpen}
                heading={this.txt().Heading()}
                selection={this.state.config.vigetteAmount}
                options={this.renderOptions()}
                onSelect={(v: number) =>
                    this.props.allState.update((store: Flux.Store): string => {
                        PermitCreateState.Config.stateWrite(store, {
                            vigetteAmount: v,
                        });
                        PermitCreateState.Layout.stateWrite(store, {
                            vignetteCountSelectionOpen: false,
                        });
                        return 'VignetteAmountSlideIn-SelectVignetteAmount';
                    })
                }
                onLeftActionClick={() =>
                    this.update(store =>
                        PermitCreateState.Layout.stateWrite(store, {
                            vignetteCountSelectionOpen: false,
                        }),
                    )
                }
                outsideBody
            />
        );
    }
}
