import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import { isDefined, Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { Localized } from '../../common/components/Localized.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import {
    getIdentificationTypesGivenSelection,
    hasBadgeIdentification,
    hasLicensePlateIdentification,
} from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { IconButton24pxType } from '../../ui/buttons/IconButton.tsx';
import {
    MiniTable,
    MiniTableActionOnlyElement,
    MiniTableElement,
} from '../../ui/labeled-elements/MiniTable.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { texts } from '../i18n/SharedTexts.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import { Badge } from './AddBadgeSlideIn.tsx';
import { LicensePlate } from './AddLicensePlateSlideIn.tsx';
import { LicensePlateItem } from './LicensePlateItem.tsx';
import { validityIntervalTexts } from 'dg-web-shared/common/components/translations/PermitValidityIntervalTexts.ts';

export interface Texts {
    RemoveLpTooltip: Translation;
    AddLpTooltip: Translation;
    LicensePlates: Translation;
    RemoveBadgeTooltip: Translation;
    AddBadgeTooltip: Translation;
    Badges: Translation;
    AdditionalInfos: Translation;
    Remark: Translation;
    LicensePlateMissing: Translation;
    BadgeMissing: Translation;
}

interface IdentificationProps {
    canBeChanged: boolean;
    maxIdentificationCount: number;
    onIdentificationClick: (id: number) => void;
    onIdentificationAction: (id: number) => void;
    onAdd: () => void;
    showError: boolean;
    allState: Flux.Store;
}

interface LicensePlatesProps extends IdentificationProps {
    lps: LicensePlate[];
}

interface State {
    settings: SettingsState.State;
    operator: OperatorDataState.State;
    permitTypes: PermitTypeState.State;
    config: PermitCreateState.Config.State;
    permitEditServer: PermitEditState.Server.State;
    layout: PermitCreateState.Layout.State;
}

const getActivePermitTypeId = (s: State): Maybe<number> =>
    isDefined(s.permitEditServer.data) && !s.layout.createEnabled
        ? thenElse(s.permitEditServer.data, d => d.permitTypeId, null)
        : thenElse(s.config.permitTypeId, ptid => ptid, null);

export const renderPrice = (
    p: {
        price: number;
        priceUnit: DurationType | null;
    },
    lang: string,
): string => {
    const priceStr = numberToPrice(p.price);
    if (p.priceUnit && p.price > 0) {
        return `${priceStr} / ${getUnitStr(lang, p.priceUnit)}`;
    } else {
        return priceStr;
    }
};

function getUnitStr(lang: string, unit: string): string {
    switch (unit) {
        case 'day':
            return validityIntervalTexts[lang].Days(1);
        case 'month':
            return validityIntervalTexts[lang].Months(1);
        case 'year':
            return validityIntervalTexts[lang].Years(1);
        default:
            throw Error('Invalid price unit');
    }
}

export class BaseContainer<
    P extends Flux.ContainerProps,
> extends Flux.ContainerWithProps<P, State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            operator: OperatorDataState.get(this.props.allState),
            permitTypes: PermitTypeState.get(this.props.allState),
            config: PermitCreateState.Config.get(this.props.allState),
            permitEditServer: PermitEditState.Server.get(this.props.allState),
            layout: PermitCreateState.Layout.get(this.props.allState),
        };
    }
}

export class LicensePlates extends BaseContainer<LicensePlatesProps> {
    render() {
        if (
            !hasLicensePlateIdentification(
                getIdentificationTypesGivenSelection(
                    this.state.permitTypes.data,
                    thenElse(getActivePermitTypeId(this.state), id => [id], []),
                ),
            )
        ) {
            return null;
        }
        const lps = this.props.lps.map(lp => {
            return (
                <MiniTableElement
                    key={lp.id}
                    onLineItemClick={
                        this.props.canBeChanged
                            ? () => {
                                  this.props.onIdentificationClick(lp.id);
                              }
                            : undefined
                    }
                    actionButton={
                        this.props.canBeChanged
                            ? IconButton24pxType.clear
                            : undefined
                    }
                    onAction={
                        this.props.canBeChanged
                            ? () => this.props.onIdentificationAction(lp.id)
                            : undefined
                    }
                    actionTooltip={texts[
                        this.state.settings.language
                    ].RemoveLpTooltip()}
                >
                    <LicensePlateItem lp={lp} />
                </MiniTableElement>
            );
        });
        if (
            lps.length < this.props.maxIdentificationCount &&
            this.props.canBeChanged
        ) {
            lps.push(
                <MiniTableActionOnlyElement
                    key="add"
                    actionButton={IconButton24pxType.add}
                    actionTooltip={texts[
                        this.state.settings.language
                    ].AddLpTooltip()}
                    showUnderline={lps.length === 0}
                    onAction={
                        this.props.canBeChanged ? this.props.onAdd : undefined
                    }
                    errorText={
                        this.props.showError && lps.length === 0
                            ? texts[
                                  this.state.settings.language
                              ].LicensePlateMissing()
                            : undefined
                    }
                />,
            );
        }
        return (
            <MiniTable
                label={texts[this.state.settings.language].LicensePlates()}
                labelRightText={`${this.props.lps.length}/${this.props.maxIdentificationCount}`}
                actionButtons={this.props.canBeChanged}
            >
                {lps}
            </MiniTable>
        );
    }
}

interface BadgesProps extends IdentificationProps {
    badges: Badge[];
}

export class Badges extends BaseContainer<BadgesProps> {
    render() {
        if (
            !hasBadgeIdentification(
                getIdentificationTypesGivenSelection(
                    this.state.permitTypes.data,
                    thenElse(getActivePermitTypeId(this.state), id => [id], []),
                ),
            )
        ) {
            return null;
        }
        const badges = this.props.badges.map(badge => {
            return (
                <MiniTableElement
                    key={badge.id}
                    actionButton={
                        this.props.canBeChanged
                            ? IconButton24pxType.clear
                            : undefined
                    }
                    onAction={
                        this.props.canBeChanged
                            ? () => this.props.onIdentificationAction(badge.id)
                            : undefined
                    }
                    actionTooltip={texts[
                        this.state.settings.language
                    ].RemoveBadgeTooltip()}
                >
                    <div className="PermitDetailBadgeItem">
                        {badge.badgeLabelNr}
                    </div>
                </MiniTableElement>
            );
        });

        if (
            badges.length < this.props.maxIdentificationCount &&
            this.props.canBeChanged
        ) {
            badges.push(
                <MiniTableActionOnlyElement
                    key="add"
                    actionButton={IconButton24pxType.add}
                    actionTooltip={texts[
                        this.state.settings.language
                    ].AddBadgeTooltip()}
                    showUnderline={badges.length === 0}
                    onAction={
                        this.props.canBeChanged ? this.props.onAdd : undefined
                    }
                    errorText={
                        this.props.showError && badges.length === 0
                            ? texts[this.state.settings.language].BadgeMissing()
                            : undefined
                    }
                />,
            );
        }

        return (
            <MiniTable
                label={texts[this.state.settings.language].Badges()}
                labelRightText={`${this.props.badges.length}/${this.props.maxIdentificationCount}`}
                actionButtons={this.props.canBeChanged}
            >
                {badges}
            </MiniTable>
        );
    }
}

interface InfoProps {
    value: string;
    onChange: (v: string) => void;
    disabled: boolean;
    allState: Flux.Store;
}

export class AdditionalInfos extends BaseContainer<InfoProps> {
    render() {
        return (
            <TextField
                value={this.props.value}
                label={texts[this.state.settings.language].AdditionalInfos()}
                onChange={
                    !this.props.disabled ? this.props.onChange : () => null
                }
                multiline={true}
                inputType="text"
                disabled={this.props.disabled}
            />
        );
    }
}

export class Remark extends BaseContainer<InfoProps> {
    render() {
        return (
            <TextField
                value={this.props.value}
                multiline={true}
                label={texts[this.state.settings.language].Remark()}
                onChange={
                    !this.props.disabled
                        ? this.props.onChange
                        : () => {
                              /*noop*/
                          }
                }
                inputType="text"
                disabled={this.props.disabled}
                toolTip={remarkTooltip}
            />
        );
    }
}

interface EmailProps {
    value: string | null;
    onChange: (v: string) => void;
    error: Message | null;
}

export const Email = (p: EmailProps) => {
    return (
        <TextField
            value={p.value || ''}
            multiline={false}
            label={'E-Mail'}
            onChange={p.onChange}
            inputType="text"
            disabled={false}
            errorText={p.error ? <Localized {...p.error} /> : null}
        />
    );
};

const remarkTooltip = (
    <Localized
        de="Die interne Bemerkungen sind nur hier im Parkingportal sichtbar."
        fr="Les remarques internes sont uniquement visibles ici dans Parkingportal."
        it={
            'Le osservazioni interne sono visibili unicamente qui in Parkingportal.'
        }
        en="The internal comments are only visible here in Parkingportal."
    />
);
