import moment from 'moment';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { MiniTable } from './MiniTable.tsx';
import { getOrElse, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';

export interface UpcomingChange {
    date: moment.Moment;
    description: string;
    comment?: Maybe<string>;
}

interface UpcomingChangesProps {
    changes: UpcomingChange[];
    label: string;
    noChangesText: string;
    language: string;
    maxChanges?: Maybe<number>;
}

export function UpcomingChanges(props: UpcomingChangesProps): JSX.Element {
    const maxChangesToShow = getOrElse(props.maxChanges, 4);
    const changesToDisplay = props.changes.slice(0, maxChangesToShow);

    const hasChanges = props.changes.length > 0;
    const rightLabel = hasChanges
        ? `${changesToDisplay.length}/${props.changes.length}`
        : '';
    return (
        <div>
            <MiniTable
                label={props.label}
                labelRightText={rightLabel}
                actionButtons={false}
            >
                {!hasChanges && (
                    <div
                        className={css({
                            color: ColorHex.darkblue,
                            paddingTop: '2px',
                            ...OperatorTypo.headingOne,
                        })}
                    >
                        {props.noChangesText}
                    </div>
                )}
                {changesToDisplay.map((c, i) => {
                    return (
                        <div
                            key={i}
                            className={css({
                                color: ColorHex.darkblue,
                                paddingTop: '2px',
                                marginBottom: '10px',
                                display: 'flex',
                                flexWrap: 'nowrap',
                            })}
                        >
                            <div
                                className={css({
                                    width: '270px',
                                    flexShrink: 0,
                                    ...OperatorTypo.headingOne,
                                    fontWeight: 500,
                                })}
                            >
                                {c.description}
                            </div>{' '}
                            <div
                                className={css({
                                    marginTop: '5px',
                                    ...OperatorTypo.caption,
                                })}
                            >
                                {Date.Formatter.dateWithDurationFromNow(
                                    c.date,
                                    props.language,
                                )}
                                {c.comment && (
                                    <div
                                        className={css({
                                            ...OperatorTypo.headingOneItalic,
                                        })}
                                    >
                                        {c.comment}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </MiniTable>
        </div>
    );
}
