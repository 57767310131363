import * as Http from '../../api/Http.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as AuthenticationState from '../state/AuthenticationState.ts';
import { AuthStatusType } from '../state/AuthenticationState.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as LandingPageState from '../../landing-page/LandingPageState.ts';
import { removeQueryParam } from '../../utils/Utils.ts';
import { setAppToken } from '../components/Native.tsx';

export const authTokenValidity = AsyncRequest.request(
    Http.OperatorAccount.Authentication.authTokenValidity,
    (store: Flux.Store, res: Response): string => {
        const authenticationState = new AuthenticationState.StateSlice(store);
        if (res.pending) {
            authenticationState.writeStatusPending(true);
        }
        if (res.statusCode.cls.success) {
            authenticationState.writeStatusPending(false);
            authenticationState.writeStatus(AuthStatusType.Authorized);
        }
        if (res.statusCode.cls.error) {
            authenticationState.writeStatusPending(false);
            authenticationState.writeStatus(AuthStatusType.Unauthorized);
        }
        return 'AuthenticationActions-authTokenValidity--log-ignore';
    },
);

export const login = AsyncRequest.request(
    Http.OperatorAccount.Authentication.getAuthToken,
    (store: Flux.Store, res: Response): string => {
        const authenticationState = new AuthenticationState.StateSlice(store);
        if (res.statusCode.cls.success) {
            setAppToken(res.body.appToken);
            authenticationState.writeStatus(AuthStatusType.Authorized);
            LandingPageState.LoginState.reset(store);

            // trigger reload of initially needed data
            CurrentOperatorLoginState.reset(store);
            OperatorDataState.reset(store);
        }
        if (res.statusCode.cls.error) {
            LandingPageState.LoginState.stateWrite(store, {
                showCredentialsError: true,
            });
        }
        return 'AuthenticationActions-login';
    },
);

export const logout = AsyncRequest.request(
    Http.OperatorAccount.Authentication.deleteAuthToken,
    (_store: Flux.Store, res: Response): string => {
        if (res.statusCode.cls.success) {
            removeQueryParam('token');
            setAppToken(null);
            // on logout, reload url to reset all stores
            window.location.reload();
        }
        return 'AuthenticationActions-logout';
    },
);
