export enum OperatorLoginType {
    Admin,
    User,
}

export const fromString = (t: string): OperatorLoginType => {
    switch (t) {
        case 'ADMIN':
        case 'PCS_ADMIN':
            return OperatorLoginType.Admin;
        case 'USER':
            return OperatorLoginType.User;
        default:
            throw new Error(`Unhandled OperatorLoginType: ${t}`);
    }
};

export const isAdmin = (t: OperatorLoginType): boolean =>
    t === OperatorLoginType.Admin;

export const isUser = (t: OperatorLoginType): boolean =>
    t === OperatorLoginType.User;
