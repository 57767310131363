import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { useState } from 'react';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { Icon16, Icon24 } from '../icons/Icon.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

enum ButtonCategory {
    _40px = '_40px',
    _24px = '_24px',
    _16px = '_16px',
}

export enum IconButton40pxType {
    filter_active,
    filter_inactive,
    download,
    delete_,
    print,
    clear,
    add,
    apply,
    renew,
    terminate,
    storno,
    accredit,
    datePicker,
    refresh,
}

export enum IconButton24pxType {
    replace,
    clear,
    add,
    apply,
}

export enum IconButton16pxType {
    INFO = 'INFO',
}

type IconButtonType =
    | IconButton40pxType
    | IconButton24pxType
    | IconButton16pxType;

export enum ButtonSpecialState {
    DISABLED = 'DISABLED',
    ERROR = 'ERROR',
    FLAT = 'FLAT',
}

enum Color {
    white,
    blue,
    grey,
    red,
}

interface Props<T> {
    type: T;
    tooltip?: React.ReactNode;
    tooltipRight?: boolean;
    specialState?: ButtonSpecialState | null;
    link?: string;
    onClick: ClickHandler;
    key?: React.Key;
    withoutCircle?: boolean;
}

interface GenericProps extends Props<IconButtonType> {
    category: ButtonCategory;
}

export function IconButton40px(p: Props<IconButton40pxType>) {
    return <GenericIconButton category={ButtonCategory._40px} {...p} />;
}

export function IconButton24px(p: Props<IconButton24pxType>) {
    return <GenericIconButton category={ButtonCategory._24px} {...p} />;
}

export function IconButton16px(p: Props<IconButton16pxType>) {
    return <GenericIconButton category={ButtonCategory._16px} {...p} />;
}

function GenericIconButton(props: GenericProps) {
    const [hover, setHover] = useState(false);

    const onMouseOver = () => {
        setHover(true);
    };

    const onMouseOut = () => {
        setHover(false);
    };

    return (
        <GenericIconButtonContainer category={props.category} link={props.link}>
            <Clickable
                element="div"
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onClick={props.onClick}
            >
                {props.withoutCircle ? (
                    <GetIcon category={props.category} type={props.type} />
                ) : (
                    <AddCircleToIcon
                        category={props.category}
                        hover={hover}
                        type={props.type}
                        specialState={props.specialState}
                    />
                )}
            </Clickable>
            <Tooltip
                category={props.category}
                showToolTip={hover}
                tooltipRight={!!props.tooltipRight}
                tooltip={props.tooltip}
                specialState={props.specialState}
            />
        </GenericIconButtonContainer>
    );
}

interface GenericIconButtonContainerProps {
    link?: string;
    category: ButtonCategory;
    children: React.ReactNode;
}

function GenericIconButtonContainer(props: GenericIconButtonContainerProps) {
    return props.link ? (
        <a
            className={css({
                position: 'relative',
                ...iconButtonSizeByCategory(props.category),
                textDecoration: 'none',
                display: 'inline-block',
            })}
            target={props.link ? '_blank' : undefined}
            href={props.link}
            rel="noreferrer"
        >
            {props.children}
        </a>
    ) : (
        <div
            className={css({
                position: 'relative',
                ...iconButtonSizeByCategory(props.category),
            })}
        >
            {props.children}
        </div>
    );
}

function iconButtonSizeByCategory(category: ButtonCategory) {
    switch (category) {
        case ButtonCategory._40px:
            return {
                width: '40px',
                height: '40px',
            };
        case ButtonCategory._24px:
            return {
                width: '24px',
                height: '24px',
            };
        case ButtonCategory._16px:
            return {
                width: '16px',
                height: '16px',
            };
    }
}

interface AddCircleToIconProps {
    category: ButtonCategory;
    type: IconButtonType;
    hover: boolean;
    specialState?: ButtonSpecialState | null;
}

function AddCircleToIcon(props: AddCircleToIconProps) {
    const boxShadowStyle = '0 1px 4px rgba(0,0,0,0.3)';

    return (
        <div
            className={css({
                position: 'absolute',
                borderRadius: '100%',
                ...circlePositionByCategory(props.category),
                cursor: 'pointer',
                boxShadow: boxShadowStyle,
                ...circleColor(
                    getColor(props.category, props.type),
                    !props.specialState && props.hover,
                ),
                ...circleSpecialState(
                    props.specialState,
                    getColor(props.category, props.type),
                    boxShadowStyle,
                ),
            })}
        >
            <GetIcon category={props.category} type={props.type} />
        </div>
    );
}

interface GetIconProps {
    category: ButtonCategory;
    type: IconButtonType;
}

function GetIcon(props: GetIconProps) {
    if (props.category === ButtonCategory._40px) {
        switch (props.type) {
            case IconButton40pxType.filter_active:
            case IconButton40pxType.filter_inactive:
                return <Icon24 icon={Icons24.filter} />;
            case IconButton40pxType.download:
                return <Icon24 icon={Icons24.download} />;
            case IconButton40pxType.delete_:
                return <Icon24 icon={Icons24.delete_} />;
            case IconButton40pxType.print:
                return <Icon24 icon={Icons24.print} />;
            case IconButton40pxType.clear:
                return <Icon24 icon={Icons24.clear_b} />;
            case IconButton40pxType.add:
                return <Icon24 icon={Icons24.add_b} />;
            case IconButton40pxType.apply:
                return <Icon24 icon={Icons24.apply_b} />;
            case IconButton40pxType.renew:
                return <Icon24 icon={Icons24.a_erneuern} />;
            case IconButton40pxType.terminate:
                return <Icon24 icon={Icons24.a_beenden} />;
            case IconButton40pxType.storno:
                return <Icon24 icon={Icons24.a_stornieren} />;
            case IconButton40pxType.accredit:
                return <Icon24 icon={Icons24.a_gutschreiben} />;
            case IconButton40pxType.datePicker:
                return <Icon24 icon={Icons24.datePicker} />;
            case IconButton40pxType.refresh:
                return <Icon24 icon={Icons24.refresh} />;
        }
    }
    if (props.category === ButtonCategory._24px) {
        switch (props.type) {
            case IconButton24pxType.replace:
                return <Icon24 icon={Icons24.replace_m} />;
            case IconButton24pxType.clear:
                return <Icon24 icon={Icons24.clear_m} />;
            case IconButton24pxType.add:
                return <Icon24 icon={Icons24.add_m} />;
            case IconButton24pxType.apply:
                return <Icon24 icon={Icons24.apply_m} />;
        }
    } else {
        switch (props.type) {
            case IconButton16pxType.INFO:
                return <Icon16 icon={Icons16.identifier.infoField} />;
        }
    }
    return <div />;
}

function getColor(category: ButtonCategory, type: IconButtonType): Color {
    switch (category) {
        case ButtonCategory._40px:
            switch (type) {
                case IconButton40pxType.filter_active:
                case IconButton40pxType.add:
                case IconButton40pxType.apply:
                case IconButton40pxType.renew:
                    return Color.blue;
                case IconButton40pxType.filter_inactive:
                case IconButton40pxType.download:
                case IconButton40pxType.delete_:
                case IconButton40pxType.print:
                case IconButton40pxType.clear:
                case IconButton40pxType.refresh:
                    return Color.white;
                case IconButton40pxType.terminate:
                    return Color.grey;
                case IconButton40pxType.storno:
                case IconButton40pxType.accredit:
                    return Color.red;
                default:
                    return Color.white;
            }

        case ButtonCategory._24px:
            switch (type) {
                case IconButton24pxType.add:
                case IconButton24pxType.apply:
                    return Color.blue;
                case IconButton24pxType.replace:
                case IconButton24pxType.clear:
                    return Color.white;
                default:
                    return Color.white;
            }

        case ButtonCategory._16px:
            switch (type) {
                case IconButton16pxType.INFO:
                    return Color.white;
                default:
                    return Color.white;
            }
    }
}

function circlePositionByCategory(category: ButtonCategory) {
    switch (category) {
        case ButtonCategory._40px:
            return {
                top: '4px',
                left: '4px',
                width: '32px',
                height: '32px',
                padding: '4px',
            };
        case ButtonCategory._24px:
            return {
                top: '0px',
                left: '0px',
                width: '24px',
                height: '24px',
            };
        case ButtonCategory._16px:
            return {
                top: '0px',
                left: '0px',
                width: '16px',
                height: '16px',
            };
    }
}

function circleSpecialState(
    specialState: string | undefined | null,
    color: Color,
    boxShadowValue: string,
) {
    switch (specialState) {
        case 'disabled':
            return {
                opacity: color === Color.white ? 0.5 : 0.3,
                boxShadow: color === Color.white ? boxShadowValue : 'none',
                cursor: 'default',
            };
        case 'error':
            return {
                background: ColorHex.bCanceled,
                opacity: 1,
            };
        case 'flat':
            return {
                cursor: 'default',
                boxShadow: 'none',
            };
    }
}

function circleColor(color: Color, hover: boolean) {
    switch (color) {
        case Color.white:
            return {
                background: hover ? ColorHex.lightblue : ColorHex.white,
                color: hover ? ColorHex.white : ColorHex.darkblue,
            };
        case Color.blue:
            return {
                background: hover ? ColorHex.lightblue : ColorHex.darkblue,
                color: hover ? ColorHex.white : ColorHex.white,
            };
        case Color.grey:
            return {
                background: hover ? ColorHex.white : ColorHex.typo,
                color: hover ? ColorHex.typo : ColorHex.white,
            };
        case Color.red:
            return {
                background: hover ? ColorHex.white : ColorHex.bCanceled,
                color: hover ? ColorHex.bCanceled : ColorHex.white,
            };
    }
}

function TooltipArrow(p: { category: ButtonCategory; tooltipRight: boolean }) {
    return (
        <div
            className={css({
                position: 'absolute',
                top: '-12px',
                width: '0',
                height: '0',
                borderStyle: 'solid',
                ...arrowDirection(p.tooltipRight),
            })}
        />
    );
}

function arrowDirection(toolTipRight: boolean) {
    return toolTipRight
        ? {
              left: '0',
              borderWidth: '12px 0 0 12px',
              borderColor: `transparent transparent transparent ${ColorHex.rgba(
                  ColorHex.darkblue,
                  0.8,
              )}`,
          }
        : {
              right: '0',
              borderWidth: '0 0 12px 12px',
              borderColor: `transparent transparent  ${ColorHex.rgba(
                  ColorHex.darkblue,
                  0.8,
              )} transparent`,
          };
}

interface RenderTooltipProps {
    category: ButtonCategory;
    tooltip: React.ReactNode;
    showToolTip: boolean;
    tooltipRight: boolean;
    specialState?: ButtonSpecialState | null;
}

function Tooltip(props: RenderTooltipProps) {
    if (!props.tooltip || !props.showToolTip || props.specialState) {
        return null;
    }
    return (
        <div
            className={css({
                ...Typo.robotoBold,
                fontSize: '10.5px',
                lineHeight: '16px',
                letterSpacing: '0.04em',
                textTransform: 'uppercase',
                zIndex: 1000,
                background: `${ColorHex.rgba(ColorHex.darkblue, 0.8)}`,
                color: ColorHex.white,
                padding: '16px',
                position: 'absolute',
                whiteSpace: 'nowrap',
                ...toolTipPosition(props.category, props.tooltipRight),
                boxShadow: '0 2px 6px rgba(0,0,0,0.45)',
            })}
        >
            {props.tooltip}
            <TooltipArrow
                category={props.category}
                tooltipRight={props.tooltipRight}
            />
        </div>
    );
}

function toolTipPosition(category: ButtonCategory, toolTipRight: boolean) {
    const toolTipOffsetByCategory = declareToolTipOffsetByCategory(category);
    return toolTipRight
        ? {
              top: `${toolTipOffsetByCategory.verticalOffset}px`,
              left: `${toolTipOffsetByCategory.horizontalOffset}px`,
              borderRadius: '0px 4px 4px 4px',
          }
        : {
              top: `${toolTipOffsetByCategory.verticalOffset}px`,
              right: `${toolTipOffsetByCategory.horizontalOffset}px`,
              borderRadius: '4px 0px 4px 4px',
          };
}

interface ToolTipOffset {
    verticalOffset: number;
    horizontalOffset: number;
}

function declareToolTipOffsetByCategory(
    category: ButtonCategory,
): ToolTipOffset {
    switch (category) {
        case ButtonCategory._40px:
            return {
                verticalOffset: 50,
                horizontalOffset: 20,
            };
        case ButtonCategory._24px:
            return {
                verticalOffset: 38,
                horizontalOffset: 12,
            };
        case ButtonCategory._16px:
            return {
                verticalOffset: 30,
                horizontalOffset: 7,
            };
    }
}
