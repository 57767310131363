import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import {
    ContentBody,
    ContentBodyProps,
    TabsBody,
    TabsContainer,
} from './Shared.tsx';
import { ContainerLoaderBar } from '../loaders/ContainerLoaderBar.tsx';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';
import { IconButton40px, IconButton40pxType } from '../buttons/IconButton.tsx';
import * as ReactDOM from 'react-dom';
import { Localized } from '../../common/components/Localized.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export { ColumnContent, LeftColumn, RightColumn } from './Shared.tsx';
export { Container } from './Container.tsx';

export const Tabs = TabsContainer;

export const Body = (p: ContentBodyProps) => {
    return (
        <TabsBody>
            <ContentBody {...p}>
                <ContainerLoaderBar
                    loading={p.loading}
                    stateSlices={p.dependingStateSlices}
                />
                {p.children}
            </ContentBody>
        </TabsBody>
    );
};

export interface TabItemProps {
    description: React.ReactNode;
    rightText?: Maybe<string>;
    active: boolean;
    onClick: (() => void) | null;
    specialColor?: Maybe<'yellow'>;
}

export const TabItem = (p: TabItemProps): JSX.Element => {
    return (
        <Clickable
            element="div"
            className={css({
                position: 'relative',
                float: 'left',
                width: 230,
                background: ColorHex.darkblue,
                height: 52,
                color: ColorHex.white,
                ...OperatorTypo.body,
                borderWidth: 0,
                borderStyle: 'solid',
                borderColor: ColorHex.white,
                borderRightWidth: 2,
                ...(!p.active && {
                    background: ColorHex.rgba(ColorHex.darkblue, 0.5),
                    borderBottomWidth: 2,

                    '&:hover': {
                        background: ColorHex.lightblue,
                    },
                }),
                ...(p.specialColor && {
                    background: ColorHex.fPendent,
                    color: ColorHex.fPendentC,
                }),
            })}
            onClick={p.onClick}
        >
            <div
                className={css({
                    position: 'absolute',
                    top: 24,
                    left: 24,
                })}
            >
                {p.description}
            </div>
            <Conditional c={isDefined(p.rightText)}>
                <div
                    className={css({
                        position: 'absolute',
                        textAlign: 'right',
                        top: 24,
                        right: 24,
                    })}
                >
                    {p.rightText}
                </div>
            </Conditional>
        </Clickable>
    );
};

export function getTimestamp() {
    return DateTime.local().toFormat('dd.MM.yyyy – HH:mm:ss');
}

export interface SpecialModalTabResultProps {
    onRefreshClick: () => void;
    successRequest: boolean;
    pendingRequest: boolean;
}

export const SpecialTabResultRefresher = (p: SpecialModalTabResultProps) => {
    const [timeStamp, setTimeStamp] = useState<string | null>(null);
    const [requestWasPending, setRequestWasPending] = useState(false);
    useEffect(() => {
        if (!requestWasPending && p.pendingRequest) {
            setRequestWasPending(true);
        }

        if (requestWasPending && p.successRequest) {
            setTimeStamp(getTimestamp());
        }
    }, [p.successRequest, p.pendingRequest]);

    return (
        <div
            className={css({
                width: '300px',
                float: 'right',
                padding: '10px 40px 10px 0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            })}
        >
            <div
                className={css({
                    marginRight: '4px',
                    color: ColorHex.darkblue,
                    ...Typo.robotoRegular,
                    fontSize: '12px',
                    letterSpacing: '0.04em',
                })}
            >
                {p.pendingRequest ? (
                    <Localized
                        de="Suche läuft..."
                        fr="Recherche..."
                        it="Ricerca in corso..."
                        en="Search in progress..."
                    />
                ) : (
                    timeStamp && timeStamp
                )}
            </div>
            <IconButton40px
                onClick={p.onRefreshClick}
                type={IconButton40pxType.refresh}
            />
        </div>
    );
};
export const refreshButtonModalId = 'refreshButtonModal';
export const ModalSpecialTabResultRefresher = (
    p: SpecialModalTabResultProps,
) => {
    if (document.getElementById(refreshButtonModalId)) {
        /*need this state to ensure that the new element
         has already arrived in the DOM when it is first rendered
         */
        const [show, setShow] = useState(false);
        useEffect(() => {
            setShow(true);
        }, []);
        return (
            show &&
            (ReactDOM.createPortal(
                <SpecialTabResultRefresher
                    pendingRequest={p.pendingRequest}
                    onRefreshClick={p.onRefreshClick}
                    successRequest={p.successRequest}
                />,
                document.getElementById(refreshButtonModalId) as HTMLElement,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any)
        );
    } else {
        return null;
    }
};
