import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import {
    ColumnWidth,
    EmptyTableHeaderColumn,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
    TableRow,
} from '../ui/table/Table.tsx';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { resultsTableTexts } from './Texts.tsx';
import { Detail } from './ClearanceRequestsState.ts';
import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';
import { ChevronRightColumn } from '../ui/table/ColumImplementations.tsx';
import { ClearanceRequestAddressBlock } from './Address.tsx';
import { ClearanceRequest } from '../common/models/ClearanceRequest.ts';
import { css } from '@emotion/css';
import { IconTopOffsetContainer } from '../zones/components/ZoneListBody.tsx';
import { ColorHex } from '../ui/Colors.ts';
import { Icon24 } from '../ui/icons/Icon.tsx';
import {
    clearanceRequest,
    clearanceState,
} from 'dg-web-shared/ui/icons/Icons24.tsx';

export interface ResultsTableTexts {
    headerPermiTypeDescription: Translation;
    headerDate: Translation;
    headerCustomer: Translation;
}

const texts = (p: { settings: SettingsState.State }) =>
    resultsTableTexts[p.settings.language];

const ResultsTableHeader = (p: { settings: SettingsState.State }) => (
    <TableHeader>
        <EmptyTableHeaderColumn
            width={ColumnWidth._24px}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={texts(p).headerPermiTypeDescription()}
            width={ColumnWidth.variable}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={texts(p).headerDate()}
            width={ColumnWidth._136px}
            delimiterBottom={false}
        />
        <TableHeaderColumn
            name={texts(p).headerCustomer()}
            width={ColumnWidth._312px}
            delimiterBottom={false}
        />
        <EmptyTableHeaderColumn
            width={ColumnWidth._24px}
            delimiterBottom={false}
        />
    </TableHeader>
);

const ClearanceRequestRow = (p: {
    clearanceRequest: ClearanceRequest;
    update: Flux.Updater;
}) => (
    <TableRow
        className={css({
            color: ColorHex.darkblue,
            ...getRowStyle(p.clearanceRequest),
        })}
        onClick={() =>
            p.update(store =>
                Detail.stateWrite(store, {
                    selectedClearanceRequest: p.clearanceRequest,
                    remark: null,
                    lpIds: [],
                    priceModifierId: null,
                }),
            )
        }
        data-hoverable={true}
        data-status={p.clearanceRequest.status}
    >
        <TableColumn width={ColumnWidth._24px} />
        <TableColumn width={ColumnWidth.variable}>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                <div
                    className={css({
                        marginRight: '32px',
                        color: getIconColor(p.clearanceRequest),
                    })}
                    data-status={p.clearanceRequest.status}
                >
                    <IconTopOffsetContainer>
                        <Icon24 icon={getIcon(p.clearanceRequest)} />
                    </IconTopOffsetContainer>
                </div>
                {p.clearanceRequest.permitTypeDescription}
            </div>
        </TableColumn>
        <TableColumn width={ColumnWidth._136px}>
            {!!p.clearanceRequest.createTime &&
                Formatter.dayMonthYear(
                    Parser.isoToMoment(p.clearanceRequest.createTime),
                )}
        </TableColumn>
        <TableColumn width={ColumnWidth._312px}>
            <ClearanceRequestAddressBlock
                address={p.clearanceRequest.address}
            />
        </TableColumn>
        <ChevronRightColumn />
    </TableRow>
);

function getRowStyle(clearance: ClearanceRequest) {
    switch (clearance.status) {
        case 'pending':
            return {
                backgroundColor: ColorHex.rgba(ColorHex.fPendent, 0.1),
                '&:hover': {
                    backgroundColor: ColorHex.rgba(ColorHex.fPendent, 0.2),
                },
            };
        case 'accepted':
            return {
                borderTop: `1px solid ${ColorHex.rgba(ColorHex.darkblue, 0.2)}`,
                '&:hover': {
                    backgroundColor: ColorHex.rgba(ColorHex.lightblue, 0.2),
                },
            };
        case 'declined':
            return {
                backgroundColor: ColorHex.rgba(ColorHex.fAbgelehnt, 0.1),
                '&:hover': {
                    backgroundColor: ColorHex.rgba(ColorHex.fAbgelehnt, 0.2),
                },
            };
        default:
            return {};
    }
}

const getIcon = (clearance: ClearanceRequest): JSX.Element => {
    switch (clearance.status) {
        case 'pending':
            return clearanceRequest.pending;
        case 'declined':
            return clearanceRequest.denied;
        default:
            return clearanceState.active;
    }
};

const getIconColor = (clearance: ClearanceRequest): string => {
    switch (clearance.status) {
        case 'pending':
            return ColorHex.fPendent;
        case 'declined':
            return ColorHex.fAbgelehnt;
        default:
            return 'initial';
    }
};

const ResultsTableBody = (p: {
    settings: SettingsState.State;
    clearanceRequests: ClearanceRequest[];
    update: Flux.Updater;
}) => (
    <TableBody>
        {p.clearanceRequests.map(cr => (
            <ClearanceRequestRow {...p} clearanceRequest={cr} key={cr.id} />
        ))}
    </TableBody>
);

export const ResultsTable = (p: {
    settings: SettingsState.State;
    clearanceRequests: ClearanceRequest[];
    update: Flux.Updater;
}) => (
    <Table>
        <ResultsTableHeader {...p} />
        <ResultsTableBody {...p} />
    </Table>
);
