import { Message } from 'dg-web-shared/lib/Localized.ts';

interface BackendFilterConfigBase {
    key: string;
    displayName: Message;
}

export const enum BackendFilterType {
    SELECTION = 'SELECTION',
    DATE = 'DATE',
    DATE_RANGE = 'DATE_RANGE',
    ZONE = 'ZONE',
}

interface BackendKeyValueFilterConfig extends BackendFilterConfigBase {
    type: BackendFilterType.SELECTION;
    filters: BackendFilterValueConfig[];
}

export interface BackendFilterValueConfig {
    displayName: Message;
    value: number | string;
}

interface BackendDateFilterConfig extends BackendFilterConfigBase {
    type: BackendFilterType.DATE;
    disableSelectionInFuture: boolean;
}

interface BackendDateRangeFilterConfig extends BackendFilterConfigBase {
    type: BackendFilterType.DATE_RANGE;
    disableSelectionInFuture: boolean;
}

interface BackendZoneFilterConfig extends BackendFilterConfigBase {
    type: BackendFilterType.ZONE;
    filters: BackendZonePlzFilter[];
}

interface BackendZonePlzFilter {
    displayName: Message;
    zones: BackendFilterValueConfig[];
}

export type BackendFilterConfig =
    | BackendKeyValueFilterConfig
    | BackendDateFilterConfig
    | BackendDateRangeFilterConfig
    | BackendZoneFilterConfig;
