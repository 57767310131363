import { Message } from 'dg-web-shared/lib/Localized';

export type ProductOption = PermittypeOption | ParkingaboProductOption;

export interface ProductOptionBase {
    name: Message;
    contractTemplateId: number;
    type: ProductOptionType;
    paymentChannel: PossibleProductOptionsPaymentChannel;
    operatorState: PermittypeOperatorState;
    defaultDtaAccountId: number;
}

export enum ProductOptionType {
    PERMIT = 'PERMIT',
    PRODUCT = 'PRODUCT',
}

export interface PermittypeOption extends ProductOptionBase {
    type: ProductOptionType.PERMIT;
    permittypeId: number;
    autoActive: AutoActive;
}

export interface ParkingaboProductOption extends ProductOptionBase {
    productTemplateVersionId: string;
    type: ProductOptionType.PRODUCT;
}

export const enum PossibleProductOptionsPaymentChannel {
    PARKINGPAY = 'PARKINGPAY',
    PARKINGABO = 'PARKINGABO',
}

export enum PermittypeOperatorState {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum PermittypeChannels {
    PARKINGPAY = 'PARKINGPAY',
    PARKINGPAY_TWINT = 'PARKINGPAY_TWINT',
    PARKINGPAY_TWINT_QUICKCHECKOUT = 'PARKINGPAY_TWINT_QUICKCHECKOUT',
}

export enum AutoActive {
    STARTS_TRANSACTION = 'STARTS_TRANSACTION',
    ALL_CHANNELS_BUT_WHITELIST = 'ALL_CHANNELS_BUT_WHITELIST',
    ALL_CHANNELS = 'ALL_CHANNELS',
    OPERATOR_ONLY = 'OPERATOR_ONLY',
    OPERATOR_ONLY_AND_WHITELIST = 'OPERATOR_ONLY_AND_WHITELIST',
}
