import { Typo } from 'dg-web-shared/ui/typo.ts';

export namespace OperatorTypo {
    export const headingThreeTopCorrection = '4px';
    export const headingThree = {
        ...Typo.robotoThin,
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '0.01em',
    };

    export const headingTwo = {
        ...Typo.robotoLight,
        fontSize: '20px',
        lineHeight: '28px',
    };

    export const headingOne = {
        ...Typo.robotoLight,
        fontSize: '16px',
        lineHeight: '22px',
    };

    export const headingOneItalic = {
        ...headingOne,
        fontStyle: 'italic',
    };

    export const body = {
        ...Typo.robotoRegular,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.01em',
    };

    export const bodyHl = {
        ...body,
    };

    export const bodyNavbar = {
        ...Typo.robotoMedium,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.02em',
    };

    export const bodyCTopCorrection = '3px';
    export const bodyC = {
        ...Typo.robotoMedium,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.02em',
        'text-transform': 'uppercase',
    };

    export const labelTopCorrection = '4px';
    export const label = {
        ...Typo.robotoRegular,
        fontSize: '11.5px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
    };

    export const captionTopCorrection = '4px';
    export const caption = {
        ...Typo.robotoMedium,
        fontSize: '10.5px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
    };

    export const captionHl = {
        ...caption,
    };

    export const subcaption = {
        ...Typo.robotoUltraBold,
        fontSize: '9px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
        'text-transform': 'uppercase',
    };

    export const landingPageTitle = {
        ...Typo.robotoThin,
        fontSize: '28px',
        lineHeight: '36px',
    };

    export const styledHtmlTags = {
        ...body,
        '& h1': {
            ...headingThree,
            marginTop: '56px',
        },
        '& h2': {
            ...headingTwo,
        },
        '& h3': {
            ...headingOne,
        },
    };
}
