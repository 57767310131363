import { Translations } from '../../common/i18n/Text.ts';
import { Texts } from '../components/shared/Guides.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';

export const guide: Translations<Texts> = {
    de: {
        create: (hasWhitelist: boolean): JSX.Element => {
            return (
                <div>
                    <h1>Erfassen</h1>
                    <h3>
                        Sie können eine neue Bewilligung
                        <Conditional c={hasWhitelist}>
                            <span>{' oder Freigabe'}</span>
                        </Conditional>{' '}
                        erfassen.
                    </h3>
                </div>
            );
        },
    },
    fr: {
        create: (hasWhitelist: boolean): JSX.Element => {
            return (
                <div>
                    <h1>Saisir</h1>
                    <h3>
                        Vous pouvez saisir une nouvelle autorisation
                        <Conditional c={hasWhitelist}>
                            <span>{' ou approbation.'}</span>
                        </Conditional>
                    </h3>
                </div>
            );
        },
    },
    it: {
        create: (hasWhitelist: boolean): JSX.Element => {
            return (
                <div>
                    <h1>Registrare</h1>
                    <h3>
                        Potete registrare una nuova autorizzazione
                        <Conditional c={hasWhitelist}>
                            <span>{' o approvazione.'}</span>
                        </Conditional>
                    </h3>
                </div>
            );
        },
    },
};
