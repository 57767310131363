import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';

const sn = ElementNamer('permit-detail-BadgeState');
const an = (n: string) => sn(`Action-${n}`);

export interface Badge {
    id: number;
    badgeLabelNr: string;
}

export const parseBody = (body: { id: number; labelNr: string }): Badge => {
    return {
        id: body.id,
        badgeLabelNr: body.labelNr,
    };
};

export namespace Entity {
    export interface State {
        id: Maybe<number>;
        confirmPressedWhileError: boolean;
    }
    export const { get, set, stateWrite, reset } = Flux.generateState<State>(
        sn('Entity'),
        { id: null, confirmPressedWhileError: false },
    );
}

export namespace Add {
    export interface State {
        createBadgeOpen: boolean;
    }
    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('Add'),
        {
            createBadgeOpen: false,
        },
    );
}

export const resetAllStates = (store: Flux.Store): string => {
    Entity.reset(store);
    Add.reset(store);
    return an('resetAllStates');
};
