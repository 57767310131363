export namespace Typo {
    export const robotoThin = {
        fontFamily: 'Roboto',
        fontWeight: 100,
    };
    export const robotoLight = {
        fontFamily: 'Roboto',
        fontWeight: 300,
    };

    export const robotoLightItalic = {
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontStyle: 'italic',
    };

    export const robotoRegular = {
        fontFamily: 'Roboto',
        fontWeight: 400,
    };

    export const robotoMedium = {
        fontFamily: 'Roboto',
        fontWeight: 500,
    };

    export const robotoBold = {
        fontFamily: 'Roboto',
        fontWeight: 700,
    };
    export const robotoUltraBold = {
        fontFamily: 'Roboto',
        fontWeight: 900,
    };

    export const heading4TopCorrection = 5; // px
    export const heading4 = {
        ...robotoBold,
        fontSize: '20px',
        lineHeight: '24px',
    };

    export const heading3TopCorrection = 4; // px
    export const heading3 = {
        ...robotoLight,
        fontSize: '32px',
        lineHeight: '40px',
    };

    export const heading2TopCorrection = 4; // px
    export const heading2 = {
        ...robotoMedium,
        fontSize: '28px',
        lineHeight: '36px',
    };

    export const heading1TopCorrection = 5; // px
    export const heading1 = {
        ...robotoMedium,
        fontSize: '24px',
        lineHeight: '32px',
    };
    export const heading1I = {
        ...heading1,
        fontStyle: 'italic',
    };

    export const bodyTopCorrection = 3; // px
    export const body = {
        ...robotoMedium,
        fontSize: '16px',
        lineHeight: '22px',
    };

    export const bodyItalicTopCorrection = 3; // px
    export const bodyItalic = {
        ...robotoLightItalic,
        fontSize: '16px',
        lineHeight: '22px',
    };

    export const bodyBoldTopCorrection = 3; // px
    export const bodyBold = {
        ...robotoMedium,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.3px',
    };

    export const captionTopCorrection = 2; // px
    export const caption = {
        ...robotoRegular,
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const subcaptionTopCorrection = 2; // px

    export const subcaption = {
        ...robotoLight,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const captionC1TopCorrection = 4; // px
    export const captionC1 = {
        ...robotoRegular,
        fontSize: '10.5px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
        'text-transform': 'uppercase',
    };

    export const captionC2TopCorrection = 4; // px
    export const captionC2 = {
        ...robotoBold,
        fontSize: '10.5px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
        'text-transform': 'uppercase',
    };

    export const captionC3TopCorrection = '4px';
    export const captionC3 = {
        ...Typo.robotoUltraBold,
        fontSize: '10.5px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
        'text-transform': 'uppercase',
    };

    export const captionC4 = {
        ...Typo.robotoRegular,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const button = {
        ...robotoMedium,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.04em',
    };

    export const navbutton = {
        ...robotoMedium,
        fontSize: '13px',
        lineHeight: '13px',
        letterSpacing: '0.015em',
        'text-transform': 'uppercase',
    };

    export const listheaderTopCorrection = 4; // px
    export const listheader = {
        ...robotoLight,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const listheader2TopCorrection = 4; // px
    export const listheader2 = {
        ...robotoRegular,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const smallTopCorrection = 2; // px
    export const small = {
        ...robotoRegular,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: '0.08em',
    };

    export const action = {
        ...robotoMedium,
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const styledHtmlTags = {
        ...robotoRegular,
        fontSize: 13,
        lineHeight: '16px',
        letterSpacing: '0.01em',
        '& h1': {
            ...heading3,
            ...robotoThin,
            marginTop: 56,
        },
        '& h2': heading2,
        '& h3': robotoLight,
    };
}
