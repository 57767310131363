import moment from 'moment';
import * as superagent from 'superagent';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Http from 'dg-web-shared/lib/Http.ts';
import {
    getOrElse,
    getOrNull,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { ParamsObject } from 'dg-web-shared/lib/QueryParamsUtil.ts';
import * as ClearanceRequestsState from '../clearance-requests/ClearanceRequestsState.ts';
import * as AddressState from '../permit-detail/state/AddressState.ts';
import { removeQueryParam } from '../utils/Utils.ts';
import { AddressCountry } from 'dg-web-shared/dto/AddressCountry.ts';
import { Language } from 'dg-web-shared/dto/Language.ts';
import { LicensePlateCountry } from 'dg-web-shared/dto/LicensePlateCountry.ts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Request = superagent.Request<any, any>;

export function getAddressCountries(): superagent.Request<
    unknown,
    AddressCountry[]
> {
    return superagent.get('/ui-api/meta/address-countries');
}

export function getLanguages(): superagent.Request<unknown, Language[]> {
    return superagent.get('/ui-api/meta/languages');
}

export function getLicensePlateCountries(): superagent.Request<
    unknown,
    LicensePlateCountry[]
> {
    return superagent.get('/ui-api/meta/license-plate-countries');
}

export const operatorAppApiUrl = (path: string): string =>
    '/ui-api/operator-account' + path;

function getAmountAsRappen(amount: number): number {
    return Math.round(amount * 100);
}

function getAndProcessAppToken() {
    if (window.location.pathname.startsWith('/password-change-token')) {
        return;
    }
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
        removeQueryParam('token');
    }
    return token;
}

export namespace OperatorAccount {
    export namespace Authentication {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        export const authTokenValidity = (): superagent.Request<any, any> => {
            const appToken = getAndProcessAppToken();
            const request = superagent.get(
                operatorAppApiUrl('/auth-token-validity'),
            );
            return appToken
                ? request.set('Authorization', `Bearer ${appToken}`)
                : request;
        };

        export const getAuthToken = (args: {
            username: string;
            password: string;
            longSession: boolean;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }): superagent.Request<any, void> =>
            superagent
                .get(operatorAppApiUrl('/auth-token'))
                .set(
                    'Authorization',
                    Http.basicAuthHeader(args.username, args.password),
                )
                .query({ longSession: args.longSession });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        export const deleteAuthToken = (): superagent.Request<any, void> =>
            superagent.del(operatorAppApiUrl('/auth-token'));
    }

    export namespace Permits {
        export const types = (): Request =>
            superagent.get(operatorAppApiUrl('/permit-types'));

        export const editWithinValidityDates = (p: {
            id: number;
            dates: string[];
        }): Request =>
            superagent
                .put(operatorAppApiUrl(`/permit-types/${p.id}`))
                .send({ withinValidityConstraintDates: p.dates });

        export type SearchEntity =
            | 'LicensePlate'
            | 'Badge'
            | 'ContractNr'
            | 'PersonalNr'
            | 'ClearanceInfo'
            | 'PermitId'
            | 'CustomerName';

        export const search = (params: {
            search: string;
            entity: SearchEntity;
        }): Request => {
            return superagent
                .get(operatorAppApiUrl('/permits/search'))
                .query(params);
        };

        interface FilteredListParams {
            validDateFrom?: Maybe<moment.Moment>;
            validDateTo?: Maybe<moment.Moment>;
            issueDateFrom?: Maybe<moment.Moment>;
            issueDateTo?: Maybe<moment.Moment>;
            issuedBy?: Maybe<(number | null)[]>;
            permitZone?: Maybe<number[]>;
            permitType?: Maybe<number[]>;
            licensePlate?: Maybe<string>;
            timeLimit: TimeLimit | null;
            channel: Channel | null;
            cost: Cost | null;
            status: Status[] | null;
            sortField?: Maybe<string>;
            sortDir?: Maybe<string>;
            lang: string | null | undefined;
        }

        export enum TimeLimit {
            LIMITED_WITH_PAYMENT = 'LIMITED_WITH_PAYMENT',
            LIMITED_WITHOUT_PAYMENT = 'LIMITED_WITHOUT_PAYMENT',
            UNLIMITED = 'UNLIMITED',
        }

        export enum Channel {
            PUBLIC = 'PUBLIC',
            CLEARANCE = 'CLEARANCE',
        }

        export enum Cost {
            FREE = 'FREE',
            CHARGEABLE = 'CHARGEABLE',
        }

        export enum Status {
            VALID = 'VALID',
            EXPIRED = 'EXPIRED',
            CANCELLED = 'CANCELLED',
            PLANNED = 'PLANNED',
        }

        export const filteredListParamsToQueryParams = (
            params: FilteredListParams,
        ): { [idx: string]: string | null } => {
            return {
                validDateFrom: thenElse(
                    params.validDateFrom,
                    d => Formatter.isoYearMonthDay(d),
                    null,
                ),
                validDateTo: thenElse(
                    params.validDateTo,
                    d => Formatter.isoYearMonthDay(d),
                    null,
                ),
                issueDateFrom: thenElse(
                    params.issueDateFrom,
                    d => Formatter.isoYearMonthDay(d),
                    null,
                ),
                issueDateTo: thenElse(
                    params.issueDateTo,
                    d => Formatter.isoYearMonthDay(d),
                    null,
                ),
                issuedBy: thenElse(
                    params.issuedBy,
                    ib => JSON.stringify(ib),
                    null,
                ),
                permitZone: thenElse(
                    params.permitZone,
                    pz => JSON.stringify(pz),
                    null,
                ),
                permitType: thenElse(
                    params.permitType,
                    pt => JSON.stringify(pt),
                    null,
                ),
                licensePlate: getOrNull(params.licensePlate),
                timeLimit: params.timeLimit,
                channel: params.channel,
                cost: params.cost,
                status: params.status ? JSON.stringify(params.status) : null,
                sortField: getOrNull(params.sortField),
                lang: params.lang || null,
            };
        };

        export const filteredList = (args: FilteredListParams) =>
            superagent
                .get(operatorAppApiUrl('/permits/filter'))
                .query(filteredListParamsToQueryParams(args));

        export function detail(id: number): Request {
            return superagent.get(`/ui-api/operator-account/permits/${id}`);
        }

        export type CalcPayload = {
            permitTypeId: number;
            from: string;
            to: string | null;
            time: number | null;
            durationType: DurationType | null;
            additionalInfo: string | null;
            remark: string | null;
            vignetteAmount: number;
            licensePlateIds: number[];
            badgeIds: number[];
            onstreetZoneIds: number[];
            offstreetZoneIds: number[];
            address: AddressState.Address | null;
            email: string | null;
            counterPaymentChannel: CounterPaymentChannel | null;
        };

        export enum CounterPaymentChannel {
            POSTCARD = 'POSTCARD',
            SIX = 'SIX',
            CASH = 'CASH',
            NO_CASHFLOW = 'NO_CASHFLOW',
        }

        export const calc = (payload: CalcPayload): Request => {
            return superagent
                .post(operatorAppApiUrl('/permits'))
                .query({ calculateOnly: true })
                .send(payload);
        };

        export const create = (payload: CalcPayload): Request => {
            return superagent
                .post(operatorAppApiUrl('/permits'))
                .query({ calculateOnly: false })
                .send(payload);
        };

        export interface Changes {
            to?: Maybe<string>;
            additionalInfo?: Maybe<string>;
            remark?: Maybe<string>;
            licensePlateIds?: Maybe<number[]>;
            badgeIds?: Maybe<number[]>;
            zoneIds?: Maybe<number[]>;
            address?: Maybe<Partial<AddressState.Address>>;
            addressId?: Maybe<number>;
        }

        export const edit = (args: {
            id: number;
            changes: Changes;
        }): Request => {
            return superagent
                .put(operatorAppApiUrl(`/permits/${args.id}`))
                .query({ calculateOnly: false })
                .send(args.changes);
        };

        export const editValidation = (args: {
            id: number;
            changes: Changes;
        }): Request => {
            return superagent
                .put(operatorAppApiUrl(`/permits/${args.id}`))
                .query({ calculateOnly: true })
                .send(args.changes);
        };

        export interface CancelPermitPayload {
            doRefund: boolean;
            remark: string | null;
        }

        export const storno = (args: {
            id: number;
            refund: CancelPermitPayload;
        }): Request => {
            return superagent
                .put(operatorAppApiUrl(`/permits/${args.id}/storno`))
                .send({
                    doRefund: args.refund.doRefund,
                    remark: args.refund.remark,
                });
        };

        export const refund = (args: { id: number; amount: number }): Request =>
            superagent
                .put(operatorAppApiUrl(`/permits/${args.id}/refund`))
                .send({ amountRappen: getAmountAsRappen(args.amount) });

        export interface TerminateParameters extends CancelPermitPayload {
            amount: string | null;
            terminateDate: string | null;
        }

        function parseTerminateAmountStringToRappen(
            amount: string | null | undefined,
        ): number | null {
            if (!amount) {
                return null;
            }
            const parsedAmount = parseFloat(amount);
            if (isNaN(parsedAmount)) {
                return null;
            }
            return getAmountAsRappen(parsedAmount);
        }

        export const terminate = (args: {
            id: number;
            terminate: TerminateParameters;
        }): Request =>
            superagent
                .put(operatorAppApiUrl(`/permits/${args.id}/terminate`))
                .send({
                    terminateDate: args.terminate.terminateDate,
                    amountRappen: parseTerminateAmountStringToRappen(
                        args.terminate.amount,
                    ),
                    doRefund: args.terminate.doRefund,
                    remark: args.terminate.remark,
                });

        export const pdfPrint = (id: number) =>
            operatorAppApiUrl(`/permits/${id}/print-pdf2`);
    }

    export namespace Clearances {
        export namespace FilteredList {
            interface Params {
                issueDateFrom: Maybe<moment.Moment>;
                issueDateTo: Maybe<moment.Moment>;
                validDateFrom: Maybe<moment.Moment>;
                validDateTo: Maybe<moment.Moment>;
                issuedBy: Maybe<(number | null)[]>;
                permitType: Maybe<number[]>;
                sortField: Maybe<string>;
                sortDir?: Maybe<string>;
                withOrWithoutPermits: Maybe<string>;
            }

            export const getQueryParams = (
                params: Params,
            ): { [idx: string]: string | null } => {
                return {
                    issueDateFrom: thenElse(
                        params.issueDateFrom,
                        d => Formatter.isoYearMonthDay(d),
                        null,
                    ),
                    issueDateTo: thenElse(
                        params.issueDateTo,
                        d => Formatter.isoYearMonthDay(d),
                        null,
                    ),
                    validDateFrom: thenElse(
                        params.validDateFrom,
                        d => Formatter.isoYearMonthDay(d),
                        null,
                    ),
                    validDateTo: thenElse(
                        params.validDateTo,
                        d => Formatter.isoYearMonthDay(d),
                        null,
                    ),
                    issuedBy: thenElse(
                        params.issuedBy,
                        ib => JSON.stringify(ib),
                        null,
                    ),
                    permitType: thenElse(
                        params.permitType,
                        pt => JSON.stringify(pt),
                        null,
                    ),
                    sortField: getOrNull(params.sortField),
                    withOrWithoutPermits: getOrNull(
                        params.withOrWithoutPermits,
                    ),
                };
            };

            export const get = (args: Params) =>
                superagent
                    .get(
                        operatorAppApiUrl(
                            '/clearances/licenseplate-badge/filter',
                        ),
                    )
                    .query(getQueryParams(args));
        }

        export const detail = (id: number) =>
            superagent.get(
                operatorAppApiUrl(`/clearances/licenseplate-badge/${id}`),
            );

        export const del = (id: number) =>
            superagent.del(
                operatorAppApiUrl(`/clearances/licenseplate-badge/${id}`),
            );

        export interface Changes {
            validFrom?: Maybe<string>;
            validTo?: Maybe<string>;
            infos?: Maybe<string>;
            contractNumber?: Maybe<string>;
            personalNumber?: Maybe<string>;
        }

        export const edit = (args: {
            id: number;
            changes: Changes;
        }): Request => {
            return superagent
                .put(
                    operatorAppApiUrl(
                        `/clearances/licenseplate-badge/${args.id}`,
                    ),
                )
                .send(args.changes);
        };

        export const getCarType = (args: {
            lpNr: string;
            type: LicensePlateType;
            country: string;
        }) => {
            return superagent.get(
                operatorAppApiUrl(
                    `/license-pate/car-type?licensePlateNr=${args.lpNr}&` +
                        `type=${args.type}` +
                        `&country=${args.country}`,
                ),
            );
        };

        export interface ClearanceCreateDTO {
            badgeLabelNr: Maybe<string>;
            licensePlateNumber: Maybe<string>;
            type: Maybe<LicensePlateType>;
            country: Maybe<string>;
            permitTypes: number[];
            validFrom: moment.Moment;
            validTo: Maybe<moment.Moment>;
            personalNumber: Maybe<string>;
            contractNumber: Maybe<string>;
            infos: { [idx: number]: string };
            priceModifierIds: { [idx: number]: number };
            carTypeDescriptions: string[];
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const getPayloadFromDTO = (dto: ClearanceCreateDTO): any => {
            return {
                badgeLabelNr: dto.badgeLabelNr,
                licensePlateNumber: dto.licensePlateNumber,
                type: dto.type,
                country: dto.country,
                permitTypes: dto.permitTypes,
                validFrom: Formatter.isoYearMonthDay(dto.validFrom),
                validTo: Formatter.isoYearMonthDay(
                    getOrElse(dto.validTo, moment('2037-12-31')),
                ),
                personalNumber: dto.personalNumber,
                contractNumber: dto.contractNumber,
                priceModifierIds: dto.priceModifierIds,
                infos: dto.infos,
                carTypeDescriptions: dto.carTypeDescriptions,
            };
        };

        export const create = (dto: ClearanceCreateDTO): Request => {
            return superagent
                .post(operatorAppApiUrl(`/clearances/licenseplate-badge`))
                .send(getPayloadFromDTO(dto));
        };

        export const validate = (dto: ClearanceCreateDTO): Request => {
            return superagent
                .post(
                    operatorAppApiUrl(
                        `/clearances/licenseplate-badge/validation`,
                    ),
                )
                .send(getPayloadFromDTO(dto));
        };
    }

    export namespace ClearanceRequests {
        export interface ListParams {
            search: string | null;
            permitTypeIds: number[] | null;
            status: ClearanceRequestsState.Filter.ClearanceRequestStatus | null;
            dateFrom: moment.Moment | null;
            dateTo: moment.Moment | null;
        }

        export const list = (args: ListParams) =>
            superagent.get(operatorAppApiUrl('/clearance-requests')).query({
                search: args.search,
                permitTypeIds:
                    !!args.permitTypeIds && args.permitTypeIds.length > 0
                        ? JSON.stringify(args.permitTypeIds)
                        : null,
                status: args.status,
                dateFrom: args.dateFrom
                    ? Formatter.isoYearMonthDay(args.dateFrom)
                    : null,
                dateTo: args.dateTo
                    ? Formatter.isoYearMonthDay(args.dateTo)
                    : null,
            });

        export const process = (args: {
            id: number;
            acceptedLps: number[];
            remark: string | null;
            approve: boolean;
            priceModifierId: number | null;
        }) =>
            superagent
                .post(
                    operatorAppApiUrl(
                        `/clearances/requests/${args.id}/process`,
                    ),
                )
                .send({
                    acceptedLps: args.acceptedLps,
                    remark: args.remark,
                    approve: args.approve,
                    priceModifierId: args.priceModifierId,
                });
    }

    export namespace Address {
        export const getListByLpIds = (ids: number[]) =>
            superagent
                .get(operatorAppApiUrl('/address/by-licenseplate'))
                .query({ ids: JSON.stringify(ids) });
    }

    export namespace LicensePlates {
        export const get = (args: { lpId: number }): Request => {
            return superagent.get(
                `/ui-api/operator-account/license-plate/${args.lpId}`,
            );
        };

        export const postTripple = (a: {
            licensePlateNr: string;
            country: string;
            type: LicensePlateType;
        }): Request => {
            return superagent
                .post(`/ui-api/operator-account/license-plate/tripple`)
                .send({
                    licensePlateNr: a.licensePlateNr,
                    country: a.country,
                    type: a.type,
                });
        };

        export interface Address {
            firstName: Maybe<string>;
            lastName: Maybe<string>;
            company: Maybe<string>;
            line1: Maybe<string>;
            city: Maybe<string>;
            zipCode: Maybe<string>;
            countryId: Maybe<string>;
            email: Maybe<string>;
            phoneNumber: Maybe<string>;
            gender: Maybe<string>;
            language: Maybe<string>;
        }

        export const editTypeDescription = (args: {
            id: number;
            types: string[];
        }): Request => {
            return superagent
                .put(
                    `/ui-api/operator-account/license-plate/${args.id}/car-type`,
                )
                .send({ carTypes: args.types });
        };
        export const vignettePdf2 = (id: number) =>
            `/ui-api/operator-account/license-plate/${id}/vignette-pdf2`;
    }

    export namespace Vignette {
        export const add = (args: {
            vignetteLabel: string;
            licensePlateId: number;
        }) => {
            return superagent
                .post(
                    `/ui-api/operator-account/license-plate/${args.licensePlateId}/vignette`,
                )
                .send({ vignetteLabel: args.vignetteLabel });
        };
        export const update = (args: { id: number; vignetteLabel: string }) => {
            return superagent
                .put(`/ui-api/operator-account/vignette/${args.id}`)
                .send({ vignetteLabel: args.vignetteLabel });
        };
    }

    export namespace OperatorLogins {
        export interface EditCreatePayload {
            allowedPermitTypes: number[];
            allowedProductTypes: number[];
            allowedZones: number[];
            allowedRecodeConditions: string[];
            permOperatorAppActive: boolean;
            permEnforcementAppActive: boolean;
            permPermissionsEdit: boolean;
            permTransactions: boolean;
            permRefundParkTransactions: boolean;
            permParkingpay: boolean;
            permPermitsRead: boolean;
            permPermitsWrite: boolean;
            permRefundPermits: boolean;
            permActivityStatement: boolean;
            permWhitelistCreate: boolean;
            permWhitelistDelete: boolean;
            permEnforcementLog: boolean;
            permTaxomex: boolean;
            permTwintQrCodePairing: boolean;
            permSyncApp: boolean;
            permUsbStickEdit: boolean;
            permParkingMeterEdit: boolean;
            permParkingMeterAlerts: boolean;
            permQuotasWrite: boolean;
            permParkingaboInitialBalanceCreate: boolean;
            permCloudConnectorOffstreetDevicesView: boolean;
            permCloudConnectorRecodeTicket: boolean;
            permCloudConnectorAlerts: boolean;
            permCloudConnectorReportingAccess: boolean;
            firstName: string;
            lastName: string;
            username: string;
            zipCode: Maybe<string>;
            description: Maybe<string>;
            softAnonymizeCheckinPublicPermitAfterDays: number;
            city: Maybe<string>;
            telnr: Maybe<string>;
            email: Maybe<string>;
            password?: Maybe<string>;
        }

        export const logins = () =>
            superagent.get(operatorAppApiUrl('/operator-logins'));

        export const edit = (args: {
            loginId: number;
            payload: EditCreatePayload;
        }) =>
            superagent
                .put(operatorAppApiUrl(`/operator-logins/${args.loginId}`))
                .send(args.payload);

        export const postNewLogin = (payload: EditCreatePayload) =>
            superagent
                .post(operatorAppApiUrl(`/operator-logins`))
                .send(payload);

        export const editPassword = (args: {
            loginId: number;
            password: string;
        }) =>
            superagent
                .put(
                    operatorAppApiUrl(
                        `/operator-logins/${args.loginId}/password`,
                    ),
                )
                .send({ password: args.password });
    }

    export namespace EnforcementLog {
        export interface Params {
            validFrom: Maybe<moment.Moment>;
            validTo: Maybe<moment.Moment>;
            zones: Maybe<number[]>;
            search: Maybe<string>;
        }

        export const listQueryParams = (args: Params & { lang: string }) => ({
            validFrom: args.validFrom
                ? Formatter.isoYearMonthDay(args.validFrom)
                : null,
            validTo: args.validTo
                ? Formatter.isoYearMonthDay(args.validTo)
                : null,
            zones: args.zones ? JSON.stringify(args.zones) : null,
            search: args.search,
            lang: args.lang,
        });

        export const list = (args: Params & { lang: string }) =>
            superagent
                .get(operatorAppApiUrl('/control-history'))
                .query(listQueryParams(args));

        export const detail = (args: { id: number }) =>
            superagent.get(operatorAppApiUrl('/control-history/' + args.id));
    }

    export namespace ReferenceData {
        export const getReferenceData = () => {
            return superagent.get('/ui-api/operator-account/reference-data');
        };
    }

    export namespace MasterDataZones {
        export const get = () => {
            return superagent.get('/ui-api/operator-account/master-data-zones');
        };
    }

    export namespace PermitZones {
        export const getFull = (context: { zoneId: number }) => {
            return superagent.get(
                `/ui-api/operator-account/permit-zones/${context.zoneId}`,
            );
        };
    }

    export namespace ActivityStatement {
        export const get = () =>
            superagent.get('/ui-api/operator-account/activity-statement');
        export const pdfUrl = (monthYear: string) =>
            `/ui-api/operator-account/activity-statement/${monthYear}/pdf`;
        export const newPdfUrl = (year: number, month: number) => {
            return `/ui-api/operator-account/monthly-report/${year}/${month}`;
        };
        export const auditReport = (
            auditReportType: 'NONE' | 'PDF' | 'CSV',
            year: number,
            month: number,
        ) => {
            if (auditReportType === 'PDF') {
                return `/ui-api/operator-account/monthly-audit-report-pdf/${year}/${month}`;
            } else {
                return `/ui-api/operator-account/monthly-audit-report/${year}/${month}`;
            }
        };
    }

    export namespace CustomerAccounts {
        export const credit = (p: { customerId: number; amount: string }) =>
            superagent
                .post(
                    `/ui-api/operator-account/customer-accounts/${p.customerId}/credit`,
                )
                .send({ amount: p.amount });

        export const list = (search: string) =>
            superagent
                .get(operatorAppApiUrl('/customer-accounts/search'))
                .query({ search: search });
    }

    export namespace CustomerClearance {
        export const list = () =>
            superagent.get(operatorAppApiUrl('/clearances/customer'));
        export const delByContractId = (id: number) =>
            superagent.del(
                operatorAppApiUrl(`/clearances/customer-by-contract-id/${id}`),
            );

        export interface CustomerClearanceCreateDTO {
            customerNr: number;
            remark: Maybe<string>;
            permitTypeIds: number[];
        }

        export const create = (dto: CustomerClearanceCreateDTO): Request =>
            superagent
                .post(operatorAppApiUrl(`/clearances/customer`))
                .send(dto);
    }

    export namespace Firmwares {
        export const get = () =>
            superagent.get('/ui-api/operator-account/taxomex/firmware');
    }

    export namespace Toms {
        export const get = () =>
            superagent.get('/ui-api/operator-account/taxomex/tom');

        export const getFull = (args: { tomId: number }) =>
            superagent.get(
                `/ui-api/operator-account/taxomex/tom/${args.tomId}`,
            );

        export interface SetOutOfServicePayload {
            tomId: number;
            startDate: string;
            endDate: string;
            title?: Maybe<string>;
            body?: Maybe<string>;
        }

        export const setOutOfService = (payload: SetOutOfServicePayload) => {
            return superagent
                .post(
                    operatorAppApiUrl(
                        `/taxomex/tom/${payload.tomId}/set-out-of-service`,
                    ),
                )
                .send(payload);
        };

        export interface EditBaseDataPayload {
            name?: string;
            operatorExternalId?: Maybe<string>;
            street?: Maybe<string>;
            zipCode?: Maybe<string>;
            place?: Maybe<string>;
        }

        export const editBaseData = (args: {
            tomId: number;
            payload: EditBaseDataPayload;
        }) => {
            return superagent
                .put(operatorAppApiUrl(`/taxomex/tom/${args.tomId}`))
                .send(args.payload);
        };
    }

    export namespace PermissionTags {
        export const get = () =>
            superagent.get('/ui-api/operator-account/taxomex/permission-tag');
    }

    export namespace UsbSticks {
        export const get = () =>
            superagent.get('/ui-api/operator-account/taxomex/usb-stick');

        export const getDetail = (args: { usbStickId: number }) =>
            superagent.get(
                `/ui-api/operator-account/taxomex/usb-stick/${args.usbStickId}`,
            );

        export interface EditBaseDataPayload {
            name: string;
        }

        export const editBaseData = (args: {
            stickId: number;
            payload: EditBaseDataPayload;
        }) => {
            return superagent
                .put(operatorAppApiUrl(`/taxomex/usb-stick/${args.stickId}`))
                .send(args.payload);
        };
    }

    export namespace Tariffs {
        export const get = () =>
            superagent.get('/ui-api/operator-account/taxomex/tariff');
    }

    export namespace TomParkTransactions {
        interface ListParams {
            validFrom: Maybe<moment.Moment>;
            validTo: Maybe<moment.Moment>;
            toms: number[];
            lang?: Maybe<string>;
        }

        export function buildParams(args: ListParams): {
            [k: string]: Maybe<string | number>;
        } {
            return {
                validFrom: thenElse(
                    args.validFrom,
                    from => Formatter.isoYearMonthDay(from),
                    undefined,
                ),
                validTo: thenElse(
                    args.validTo,
                    from => Formatter.isoYearMonthDay(from),
                    undefined,
                ),
                toms: JSON.stringify(args.toms),
                lang: args.lang,
            };
        }

        export const get = (args: ListParams) =>
            superagent
                .get(operatorAppApiUrl('/taxomex/park-transactions'))
                .query(buildParams(args));
    }

    export namespace TomCollections {
        export interface ListParams {
            validFrom: moment.Moment | null | undefined;
            validTo: moment.Moment | null | undefined;
            toms: number[];
            sticks: number[];
            hideEmptyCollections: boolean;
        }

        export const paramsToQueryParams = (args: ListParams): ParamsObject => {
            return {
                validFrom: thenElse(
                    args.validFrom,
                    from => Formatter.isoYearMonthDay(from),
                    null,
                ),
                validTo: thenElse(
                    args.validTo,
                    from => Formatter.isoYearMonthDay(from),
                    null,
                ),
                toms: JSON.stringify(args.toms),
                sticks: JSON.stringify(args.sticks),
                hideEmptyCollections: args.hideEmptyCollections,
            };
        };

        export const get = (args: ListParams) =>
            superagent
                .get(operatorAppApiUrl('/taxomex/tom-collections'))
                .query(paramsToQueryParams(args));
    }

    export namespace LicensePlateSearch {
        export const reassignVignettesRequest = (args: {
            oldId: number;
            licensePlateNr: string;
            type: LicensePlateType;
            country: string;
        }) => {
            return superagent
                .post(
                    operatorAppApiUrl(
                        '/license-plate-search/list/' +
                            args.oldId +
                            '/reassign-vignettes',
                    ),
                )
                .send({
                    licensePlateNr: args.licensePlateNr,
                    type: args.type,
                    country: args.country,
                });
        };
    }
}
