import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Search } from '../../../ui/search/Search.tsx';
import * as SearchState from '../../state/SearchState.ts';
import * as ClearancePermitListState from '../../state/ClearancePermitListState.ts';
import * as PermitCreateState from '../../../permit-detail/state/PermitCreateState.ts';

import * as SettingsState from '../../../common/state/SettingsState.ts';

interface State {
    search: SearchState.State;
    permitCreateLayout: PermitCreateState.Layout.State;
    layout: ClearancePermitListState.Layout.State;
    settings: SettingsState.State;
}

const cn = (e?: string) => Flux.ElementNamer(`Search`)(e);

export class SearchContainer extends Flux.Container<State> {
    static displayName: string = cn();

    stateSelector(): State {
        return {
            search: SearchState.get(this.props.allState),
            permitCreateLayout: PermitCreateState.Layout.get(
                this.props.allState,
            ),
            layout: ClearancePermitListState.Layout.get(this.props.allState),
            settings: new SettingsState.StateSlice(this.props.allState).state,
        };
    }

    render() {
        return (
            <Search
                searchValue={this.state.search.searchValue}
                selected={
                    this.state.layout.selectedTab ===
                    ClearancePermitListState.SelectedTab.Search
                }
                language={this.state.settings.language}
                onSearchFieldChange={(value: string) => {
                    this.props.allState.update(store =>
                        SearchState.stateWrite(store, {
                            searchValue: value,
                        }),
                    );
                }}
                onSelect={() =>
                    this.props.allState.update(store =>
                        ClearancePermitListState.Layout.stateWrite(store, {
                            selectedTab:
                                ClearancePermitListState.SelectedTab.Search,
                        }),
                    )
                }
                mousetrap={this.state.permitCreateLayout.createEnabled || false}
            />
        );
    }
}
