import * as Tom from 'dg-web-shared/model/Tom.ts';
import { tomFirmwareStatusTexts } from '../i18n/TomTexts.ts';
import { Translation } from '../../common/i18n/Text.ts';
import { css } from '@emotion/css';
import { OperatorBadge } from './OperatorBadge.tsx';

export interface TomFirmwareStatusTexts {
    InSync: Translation;
    UpdateRequired: Translation;
}

export function TomFirmwareStatus(props: {
    tom: Tom.ParsedTom;
    language: string;
}): JSX.Element {
    const txt = tomFirmwareStatusTexts[props.language];
    const notInSync =
        props.tom.firmwareId &&
        props.tom.targetFirmwareId &&
        props.tom.firmwareId < props.tom.targetFirmwareId;
    const statusText = notInSync ? txt.UpdateRequired : txt.InSync;
    return (
        <TomStatusIconPositioning
            label={
                <OperatorBadge
                    circular
                    empty
                    color={notInSync ? 'red' : 'green'}
                />
            }
            text={statusText()}
        />
    );
}

export function TomStatusIconPositioning(p: {
    label: React.ReactNode;
    text: string;
}) {
    return (
        <div
            className={css({
                display: 'flex',
                alignItems: 'center',
            })}
        >
            {p.label}
            <div
                className={css({
                    marginLeft: '2px',
                })}
            >
                {p.text}
            </div>
        </div>
    );
}
