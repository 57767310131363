import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import {
    parseSelectOptionType,
    PermitSelectZone,
} from '../../app/state/PermitSelectZone.ts';
import {
    Duration,
    TimeSelectionType,
} from 'dg-web-shared/dto/PermitTimeUnit.ts';
import { Parser, Range } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { isDefined, isUndefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { IntradayValidityWeekProfile } from 'dg-web-shared/model/IntradayValidityWeekProfile.ts';
import { QuotaConfig } from 'dg-web-shared/model/QuotaConfig.ts';
import {
    AutoActive,
    PermittypeChannels,
    ProductOption,
} from 'product-shared/product-template/ProductOptions.tsx';

export const autoActiveFromString = (str: string): AutoActive => {
    switch (str) {
        case 'STARTS_TRANSACTION':
            return AutoActive.STARTS_TRANSACTION;
        case 'ALL_CHANNELS_BUT_WHITELIST':
            return AutoActive.ALL_CHANNELS_BUT_WHITELIST;
        case 'ALL_CHANNELS':
            return AutoActive.ALL_CHANNELS;
        case 'OPERATOR_ONLY':
            return AutoActive.OPERATOR_ONLY;
        case 'OPERATOR_ONLY_AND_WHITELIST':
            return AutoActive.OPERATOR_ONLY_AND_WHITELIST;
        default:
            return AutoActive.STARTS_TRANSACTION;
    }
};

export const isAppChannel = (autoActive: AutoActive): boolean => {
    return (
        [
            AutoActive.ALL_CHANNELS,
            AutoActive.ALL_CHANNELS_BUT_WHITELIST,
        ].indexOf(autoActive) > -1
    );
};

export const isCounterChannel = (autoActive: AutoActive): boolean => {
    return (
        [
            AutoActive.ALL_CHANNELS,
            AutoActive.ALL_CHANNELS_BUT_WHITELIST,
            AutoActive.OPERATOR_ONLY,
            AutoActive.OPERATOR_ONLY_AND_WHITELIST,
        ].indexOf(autoActive) > -1
    );
};

export interface Zone {
    zipCode: string;
    extZoneCode?: number;
    city: string;
    offstreet: boolean;
    name: string;
    id: number;
    restrainedZone: boolean;
}

export interface AllowedLicensePlateType {
    needsVignette: boolean;
    type: LicensePlateType;
}

export interface PriceModifier {
    priceModifierId: number;
    label: string;
}

export interface QuotaPartitionInfo {
    contractQuotaPartitionId: number;
    quotaConfigs: QuotaConfig[];
    otherProducts: ProductOption[];
}

export type AdditionalInfoMode = 'edit' | 'show';

export enum PermitTypeScope {
    ONSTREET = 'ONSTREET',
    OFFSTREET = 'OFFSTREET',
    MIXED = 'MIXED',
}

export interface PermitType {
    id: number;
    permitTypeId: number;
    operatorId: number;
    operatorName: string;
    maxLicenses: number;
    selectZones: PermitSelectZone;
    description: string;
    addInfo: Maybe<string>;
    additionalInfoMode: AdditionalInfoMode;
    showAddInfo: boolean;
    isOffstreet: boolean;
    fromRange: Range;
    toRange: Maybe<Range>;
    validDurationsV2: Maybe<Duration[]>;
    timeSelectionType: TimeSelectionType;
    zones: Zone[];
    permitTypeScope: PermitTypeScope;
    allowedLicensePlateTypes: AllowedLicensePlateType[];
    whitelistRequestPossible: boolean;
    unitPrice: Maybe<number>;
    vatNumber: Maybe<string>;
    autoActive: AutoActive;
    whitelistGroup: Maybe<number>;
    needsWhitelist: boolean;
    profileNr: Maybe<number>;
    withinValidityConstraintType: Maybe<string>;
    withinValidityConstraintDates: Maybe<string[]>;
    operatorState: 'ACTIVE' | 'INACTIVE' | 'DRAFT';
    allowedPriceModifiers: PriceModifier[];
    intradayValidityWeekProfile: IntradayValidityWeekProfile;
    isWhitelistCustomer: boolean;
    dtaAccountId: number;
    mubstBeLinkedToCustomerAccount: boolean;
    hasNonArchivedContracts: boolean;
    permittypeUrlIdentification: string | null;
    channels: PermittypeChannels;
    quotaPartitionInfo: QuotaPartitionInfo;
}

const fetchPermitTypes = AsyncRequest.request(
    Http.OperatorAccount.Permits.types,
    (store: Flux.Store, res: Response): string => {
        setResponse(store, res);
        return 'PermitTypeState-fetch';
    },
);

export type State = ServerStateSlice.ServerState<PermitType[]>;

export const { get, reset, setResponse } = ServerStateSlice.generateServerState<
    PermitType[]
>(
    'common-PermitTypeState',
    () => [],
    (store: Flux.Store, state: State) => {
        if (state.shouldFetch) {
            store.update(fetchPermitTypes);
        }
    },
    (body: PermitTypeRaw[] | undefined | null): PermitType[] => {
        if (!body) {
            return [];
        } else {
            return body.map(t => {
                const fromRange = Parser.rangeFromIsoString(t.fromRange);
                if (!fromRange) {
                    throw new Error('fromRage of permittype is null');
                }

                return {
                    ...t,
                    fromRange: fromRange,
                    toRange: isDefined(t.toRange)
                        ? Parser.rangeFromIsoString(t.toRange)
                        : null,
                    autoActive: autoActiveFromString(t.autoActive),
                    selectZones: parseSelectOptionType(t.selectZones),
                };
            });
        }
    },
);

interface PermitTypeRaw
    extends Omit<
        PermitType,
        'fromRange' | 'toRange' | 'autoActive' | 'selectZones'
    > {
    fromRange: string;
    toRange: string;
    autoActive: string;
    selectZones: number;
}

export const getVisible = (data: PermitType[]): PermitType[] => {
    return data.filter(t => t.operatorState === 'ACTIVE');
};

export const getById = (
    store: Flux.Store,
    permitTypeId: number | null,
): PermitType | null => {
    if (permitTypeId) {
        for (const t of get(store).data) {
            if (t.id === permitTypeId) {
                return t;
            }
        }
    }

    return null;
};

export type IdentificationType = 'LicensePlate' | 'Badge';
export const getIdentificationTypes = (
    permitTypes: Maybe<PermitType[]>,
): IdentificationType[] => {
    if (isUndefined(permitTypes)) {
        return ['LicensePlate', 'Badge'];
    }
    const res: IdentificationType[] = [];
    if (permitTypes.filter(t => t.isOffstreet).length > 0) {
        res.push('Badge');
    }
    if (permitTypes.filter(t => !t.isOffstreet).length > 0) {
        res.push('LicensePlate');
    }
    return res;
};

export const getIdentificationTypesGivenSelection = (
    permitTypes: Maybe<PermitType[]>,
    selectedPermitTypeIds: number[],
): IdentificationType[] => {
    if (isUndefined(permitTypes)) {
        return [];
    }
    const selectedPermitTypes = permitTypes.filter(
        t => selectedPermitTypeIds.indexOf(t.id) > -1,
    );
    return getIdentificationTypes(selectedPermitTypes);
};

export const hasLicensePlateIdentification = (idTypes: IdentificationType[]) =>
    idTypes.indexOf('LicensePlate') > -1;

export const hasBadgeIdentification = (idTypes: IdentificationType[]) =>
    idTypes.indexOf('Badge') > -1;
