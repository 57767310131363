import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { localeCompare } from 'dg-web-shared/lib/String.ts';
import * as Text from '../../../common/i18n/Text.ts';
import * as OperatorLoginsState from '../../../common/state/OperatorLoginsState.ts';
import { getLoginTitle } from '../../../common/state/OperatorLoginsState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import { SingleSelection } from '../../../ui/labeled-elements/SingleSelection.tsx';
import {
    NumberMultiSelectionSlideIn,
    Option,
} from '../../../ui/slidein/MultiSelectionSlideIn.tsx';
import {
    clearOperatorLogins,
    setOperatorLogins,
} from '../../actions/FilteredPermitsActions.ts';
import { operatorLoginFilterTexts } from '../../i18n/FilteredPermitsTexts.ts';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';

export interface OperatorLoginFilterTexts {
    filterTitle: Text.Translation;
    onlineUser: Text.Translation;
}

export const operatorLoginSelection = (
    logins: OperatorLoginsState.OperatorLogin[],
    selection: Maybe<number[]>,
) =>
    isDefined(selection) && selection.length > 0
        ? `${selection.length}/${logins.length + 1}`
        : '';

interface OperatorLoginFilterHalfSlideInState {
    settings: SettingsState.State;
    filter: FilteredPermitsState.Filter.State;
    operatorLogins: OperatorLoginsState.List.State;
}

const cn = ElementNamer('OperatorLoginFilterHalfSlideIn');

export class OperatorLoginFilterHalfSlideIn extends Flux.Container<OperatorLoginFilterHalfSlideInState> {
    static displayName: string = cn('');

    stateSelector(): OperatorLoginFilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
        };
    }

    renderOptions(): Option<number>[] {
        const texts = operatorLoginFilterTexts[this.state.settings.language];
        return [
            {
                id: FilteredPermitsState.Filter.OperatorLoginFilterOnlineOption,
                displayText: texts.onlineUser(),
                hasSpacingBottom: true,
            } as Option<number>,
        ].concat(
            this.state.operatorLogins.data
                .map(l => {
                    return {
                        id: l.operatorLoginId,
                        displayText: getLoginTitle(l),
                    };
                })
                .sort((a, b) => localeCompare(a.displayText, b.displayText)),
        );
    }

    render() {
        return (
            <NumberMultiSelectionSlideIn
                open={this.state.filter.operatorLoginSelectionVisible}
                heading={operatorLoginFilterTexts[
                    this.state.settings.language
                ].filterTitle()}
                selection={this.state.filter.operatorLogins || []}
                options={this.renderOptions()}
                onLeftActionClick={() =>
                    this.update(store =>
                        FilteredPermitsState.Filter.stateWrite(store, {
                            operatorLoginSelectionVisible: false,
                        }),
                    )
                }
                setNewSelection={v =>
                    this.update(store => setOperatorLogins(store, v))
                }
            />
        );
    }
}

interface OperatorLoginFilterSelectionState {
    settings: SettingsState.State;
    filter: FilteredPermitsState.Filter.State;
    operatorLogins: OperatorLoginsState.List.State;
}

export class OperatorLoginFilterSelection extends Flux.Container<OperatorLoginFilterSelectionState> {
    static displayName = 'OperatorLoginFilterSelection';

    stateSelector(): OperatorLoginFilterSelectionState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
        };
    }

    render() {
        const texts = operatorLoginFilterTexts[this.state.settings.language];
        const clear = isDefined(this.state.filter.operatorLogins)
            ? () => this.update(clearOperatorLogins)
            : null;

        return (
            <SingleSelection
                label={texts.filterTitle()}
                focused={false}
                selection={operatorLoginSelection(
                    this.state.operatorLogins.data,
                    this.state.filter.operatorLogins,
                )}
                onClick={() =>
                    this.update(store =>
                        FilteredPermitsState.Filter.stateWrite(store, {
                            operatorLoginSelectionVisible: true,
                        }),
                    )
                }
                onClear={clear}
            />
        );
    }
}
