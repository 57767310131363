import { isDefined, Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Updater } from 'dg-web-shared/lib/Flux.tsx';
import { PrimaryActionButton } from '../../ui/buttons/TextButton.tsx';
import * as Http from '../../api/Http.ts';
import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';

import { RefundContent } from './PermitCancellationSlideIn.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { SlideInBody } from '../../ui/slidein/Slidein.tsx';
import { css } from '@emotion/css';
import { DatePicker } from '../../ui/date-picker/DatePicker.tsx';
import moment from 'moment';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as HttpResponse from 'dg-web-shared/lib/HttpResponse.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';
import * as FilteredPermitsState from '../../clearance-permit-list/state/FilteredPermitsState.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { Localized } from '../../common/components/Localized.tsx';

const terminatePermit = AsyncRequest.request(
    Http.OperatorAccount.Permits.terminate,
    (store: Flux.Store, res: HttpResponse.Response): string => {
        PermitEditState.PermitCancellationResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            PermitEditState.Terminate.reset(store);
            PermitEditState.Refund.reset(store);
            PermitEditState.Edit.reset(store);
            PermitEditState.Server.setResponse(store, res);
            ResultState.refetchSameContext(store, true);
            FilteredPermitsState.List.reset(store);
        }
        return 'PermitEdit-editPermit';
    },
);

export function TerminateHeaderButton({
    permit,
    terminate,
    refund,
    refData,
    isFormValid,
    update,
}: {
    permit: Maybe<PermitEditState.Permit>;
    terminate: PermitEditState.Terminate.State;
    refund: PermitEditState.Refund.State;
    refData: OperatorDataState.OperatorData | null;
    isFormValid: boolean;
    update: Updater;
}) {
    if (
        activeColumn(terminate) === ActiveColumn.AmountEntry &&
        isDefined(permit) &&
        isDefined(refData)
    ) {
        return (
            <PrimaryActionButton
                label={
                    <Localized
                        de="Beenden"
                        fr="Terminer"
                        it="Termina"
                        en="Terminate"
                    />
                }
                onClick={() =>
                    !isFormValid
                        ? update(store =>
                              PermitEditState.Refund.stateWrite(store, {
                                  confirmPressed: true,
                              }),
                          )
                        : update(terminatePermit, {
                              id: permit.id,
                              terminate: {
                                  terminateDate: thenElse(
                                      terminate.terminateDate,
                                      to => Formatter.isoYearMonthDay(to),
                                      null,
                                  ),
                                  amount: refund.amount ? refund.amount : null,
                                  doRefund: refund.doRefund,
                                  remark:
                                      refund.remark ?? (permit.remark || ''),
                              },
                          })
                }
                error={refund.confirmPressed && !isFormValid}
            />
        );
    } else {
        return null;
    }
}

enum ActiveColumn {
    DatePicker = 0,
    AmountEntry,
}

const activeColumn = (s: PermitEditState.Terminate.State) =>
    isDefined(s.terminateDate)
        ? ActiveColumn.AmountEntry
        : ActiveColumn.DatePicker;

export function TerminateBody({
    permit,
    confirmRefundAllowed,
    terminate,
    refund,
    settings,
    isFormValid,
    update,
}: {
    permit: Maybe<PermitEditState.Permit>;
    confirmRefundAllowed: boolean;
    terminate: PermitEditState.Terminate.State;
    refund: PermitEditState.Refund.State;
    settings: SettingsState.State;
    isFormValid: boolean;
    update: Updater;
}) {
    return (
        <SlideInBody>
            {activeColumn(terminate) === ActiveColumn.DatePicker ? (
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'center',
                    })}
                >
                    <DatePicker
                        selectedDate={terminate.terminateDate}
                        currentDate={moment()}
                        onSelect={d =>
                            update(store =>
                                PermitEditState.Terminate.stateWrite(store, {
                                    terminateDate: d,
                                }),
                            )
                        }
                        minDate={
                            permit &&
                            Parser.isoToMoment(permit.validFrom).isAfter(
                                moment(),
                            )
                                ? Parser.isoToMoment(permit.validFrom).add(
                                      1,
                                      'day',
                                  )
                                : moment().startOf('day')
                        }
                        maxDate={thenElse(
                            permit,
                            d =>
                                Parser.isoToMoment(d.validTo).subtract(
                                    1,
                                    'day',
                                ),
                            moment(),
                        )}
                        language={settings.language}
                    />
                </div>
            ) : (
                <AmountEntry
                    permit={permit}
                    confirmRefundAllowed={confirmRefundAllowed}
                    terminate={terminate}
                    refund={refund}
                    settings={settings}
                    isFormValid={isFormValid}
                    update={update}
                />
            )}
        </SlideInBody>
    );
}

function AmountEntry({
    permit,
    confirmRefundAllowed,
    terminate,
    refund,
    settings,
    isFormValid,
    update,
}: {
    permit: Maybe<PermitEditState.Permit>;
    confirmRefundAllowed: boolean;
    terminate: PermitEditState.Terminate.State;
    refund: PermitEditState.Refund.State;
    settings: SettingsState.State;
    isFormValid: boolean;
    update: Updater;
}) {
    if (isDefined(permit)) {
        return (
            <div>
                <LabeledText
                    label={
                        <Localized
                            de="Bewilligung beenden per"
                            fr="Terminer l'autorisation le"
                            it="Terminare l'autorizzazione al"
                            en="Terminate permit by"
                        />
                    }
                >
                    {thenElse(
                        terminate.terminateDate,
                        d => Formatter.dayMonthYear(d),
                        '',
                    )}
                </LabeledText>
                <RefundContent
                    permit={permit}
                    confirmRefundAllowed={confirmRefundAllowed}
                    onlyFullRefundAllowed={false}
                    refund={refund}
                    settings={settings}
                    isFormValid={isFormValid}
                    update={update}
                />
            </div>
        );
    } else {
        return null;
    }
}
