import { css } from '@emotion/css';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';

export function CurrentOperatorLoginText() {
    const { currentLogin } = useOperatorContext();
    return (
        <div
            className={css({
                textAlign: 'right',
            })}
        >
            <div
                className={css({
                    fontSize: 13,
                    lineHeight: '16px',
                    letterSpacing: '0.01em',
                })}
            >
                {`${currentLogin.surname} ${currentLogin.lastname}`}
            </div>
            <div
                className={css({
                    fontSize: 10.5,
                    lineHeight: '16px',
                    letterSpacing: '0.04em',
                    fontWeight: 500,
                })}
            >
                {currentLogin.mandantName}
            </div>
        </div>
    );
}

export function CurrentOperatorLoginId() {
    const { currentLogin } = useOperatorContext();

    return (
        <div
            className={css({
                padding: '0 15px',
            })}
        >
            {currentLogin.mandantId}
        </div>
    );
}
