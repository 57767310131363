import { TomConfiguration } from 'dg-web-shared/model/TomConfiguration.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { Zone } from 'dg-web-shared/model/Zone.ts';
import { tomTexts } from '../i18n/TomTexts.ts';
import { ParkingSpaces } from './ParkingSpaces.tsx';
import { OutOfServiceMessage } from './out-of-service/OutOfServiceMessage.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import {
    coinCurrency,
    coinName,
    coinValue,
    isChf,
    isEuro,
    isToken,
} from 'dg-web-shared/model/Coins.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';

export interface ConfigurationSummaryProps {
    configuration: TomConfiguration;
    zone: Zone;
    language: string;
}

export function ConfigurationSummary(
    props: ConfigurationSummaryProps,
): JSX.Element {
    const txt = tomTexts[props.language];
    const configurationData = props.configuration.configuration;
    const configurationSummaryStyle = css({ margin: '0 0 30px' });

    if (configurationData.isOutOfService) {
        return (
            <div className={configurationSummaryStyle}>
                <BaseConfiguration {...props} />
                <OutOfServiceMessage
                    title={configurationData.outOfServiceTitle}
                    body={configurationData.outOfServiceBody}
                    language={props.language}
                />
            </div>
        );
    }

    const durationTexts = Date.Formatter.durationTexts[props.language];
    const hasTokens =
        !!props.configuration.configuration.acceptedCoinCodes.find(isToken);
    const isByPlate = configurationData.paymentType === 'pay_by_plate';
    return (
        <div className={configurationSummaryStyle}>
            <BaseConfiguration {...props} />
            <LabeledText label={txt.PaymentType()}>
                {txt.PaymentTypeOptions(configurationData.paymentType)}
            </LabeledText>
            <div
                className={css({
                    marginTop: '10px',
                    display: 'flex',
                    alignItems: 'stretch',
                })}
            >
                <Conditional c={!isByPlate}>
                    <div
                        className={css({
                            left: '-3px',
                            position: 'relative',
                            minWidth: '115px',
                            width: '25%',
                            ...Typo.robotoThin,
                        })}
                    >
                        <LabeledText label={txt.Spaces()} />
                        <ParkingSpaces
                            parkingSpaces={configurationData.spaces}
                        />
                    </div>
                </Conditional>
                <div
                    className={
                        !isByPlate
                            ? css({
                                  borderLeft: `1px solid ${ColorHex.rgba(
                                      ColorHex.darkblue,
                                      0.25,
                                  )}`,
                                  paddingLeft: '20px',
                                  flexGrow: 1,
                              })
                            : ''
                    }
                >
                    <LabeledText label={txt.AcceptedCoins()}>
                        <AcceptedCoins
                            configuration={props.configuration}
                            language={props.language}
                        />
                    </LabeledText>
                    <Conditional c={hasTokens}>
                        <LabeledText label={txt.AcceptedTokens()}>
                            <AcceptedTokens
                                configuration={props.configuration}
                                language={props.language}
                            />
                        </LabeledText>
                    </Conditional>
                    <LabeledText label={txt.PaymentTransactionPeriod()}>
                        {isByPlate ? (
                            <>
                                {Date.Formatter.getDurationFromMinutes(
                                    1,
                                    durationTexts,
                                )}
                                {' ' + txt.PayByPlateTimelimitNote()}
                            </>
                        ) : (
                            <>
                                {Date.Formatter.getDurationFromMinutes(
                                    configurationData.paymentTransactionPeriodInMinutes,
                                    durationTexts,
                                )}
                                {' ' + txt.PayBySpaceTimelimitNote()}
                            </>
                        )}
                    </LabeledText>
                </div>
            </div>
        </div>
    );
}

function BaseConfiguration(props: ConfigurationSummaryProps): JSX.Element {
    const txt = tomTexts[props.language];
    return (
        <div>
            <LabeledText label={txt.Zone()}>
                {props.zone.externalZoneId} | {props.zone.name} (
                {props.zone.location})
            </LabeledText>
            <LabeledText label={txt.Permissions()}>
                {props.configuration.tags.map(t => t.name).join(', ')}
            </LabeledText>
        </div>
    );
}

interface AcceptedCoinsProps {
    configuration: TomConfiguration;
    language: string;
}

function getCurrencyStyle(hideData: boolean) {
    return css([{ width: '45%' }, hideData && { display: 'none' }]);
}

function AcceptedCoins(props: AcceptedCoinsProps): JSX.Element {
    const acceptedCoinCodes =
        props.configuration.configuration.acceptedCoinCodes;
    const chf = acceptedCoinCodes.filter(isChf);
    const euro = acceptedCoinCodes.filter(isEuro);
    const currencyCaptionStyle = css({ height: '100%', float: 'left' });
    const coinEntryStyle = css({
        marginLeft: '50px',
        '&:nth-child(2)': { marginTop: '0px' },
    });

    return (
        <div className={css({ display: 'flex', width: '100%' })}>
            <div className={getCurrencyStyle(chf.length === 0)}>
                <div className={currencyCaptionStyle}>
                    {coinCurrency('CH', props.language)}
                </div>
                {chf.map(c => (
                    <div className={coinEntryStyle} key={c}>
                        {coinName(c, props.language)}
                    </div>
                ))}
            </div>
            <div className={getCurrencyStyle(euro.length === 0)}>
                <div className={currencyCaptionStyle}>
                    {coinCurrency('EU', props.language)}
                </div>
                {euro.map(c => (
                    <div className={coinEntryStyle} key={c}>
                        {coinName(c, props.language)}
                    </div>
                ))}
            </div>
        </div>
    );
}

function AcceptedTokens({ language, configuration }: AcceptedCoinsProps) {
    const { operatorData } = useOperatorContext();

    const acceptedCoinCodes = configuration.configuration.acceptedCoinCodes;
    const tokens = acceptedCoinCodes.filter(isToken);
    const currency = coinCurrency('TK', language);

    return (
        <div>
            {tokens.map(c => (
                <div key={c}>
                    {coinName(c, language)}
                    <span className={css({ paddingLeft: '5px' })}>
                        ({currency}{' '}
                        {coinValue(c, language, {
                            tokenValues: operatorData,
                        })}
                        )
                    </span>
                </div>
            ))}
        </div>
    );
}
