import { css } from '@emotion/css';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import * as Text from '../common/i18n/Text.ts';
import { Translations } from '../common/i18n/Text.ts';
import * as CurrentOperatorLoginState from '../common/state/CurrentOperatorLoginState.ts';
import { ColorHex } from '../ui/Colors.ts';
import {
    ColAlign,
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../ui/table/Table.tsx';
import { ResultsError } from '../ui/table/ResultsError.tsx';
import { IconTopOffsetContainer } from '../zones/components/ZoneListBody.tsx';
import { ModalSpecialTabResultRefresher } from '../ui/layout/TabContent.tsx';
import { Icon16, Icon24 } from '../ui/icons/Icon.tsx';
import { transaction } from 'dg-web-shared/ui/icons/Icons24.tsx';
import { chevronRight } from 'dg-web-shared/ui/icons/Icons16.tsx';
import { OnstreetTransactionFilter } from './OnstreetTransactionFilter.tsx';
import { ReactNode } from 'react';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    OnstreetTransaction,
    OnstreetTransactionResponse,
} from './OnstreetTransactionModel.ts';
import {
    TextColumnContent,
    TextLeftColumn,
    TextRightColumn,
} from '../ui/layout/Text.tsx';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import {
    getChannelTitle,
    getDuration,
    getFromDate,
    getPrice,
    getRefundAmount,
    getToDate,
    getZoneName,
} from './OnstreetTransactionUtils.ts';
import { IdentificationLicensePlate } from '../common/components/IdentificationItems.tsx';

export function OnstreetTransactionList({
    transactionListState,
    refetchTransactionList,
    setOpenTransactionDetail,
    language,
    filter,
}: {
    transactionListState: ServerRequestState<
        OnstreetTransactionResponse,
        OnstreetTransactionResponse
    >;
    refetchTransactionList: () => void;
    setOpenTransactionDetail: (transaction: OnstreetTransaction) => void;
    language: string;
    filter: OnstreetTransactionFilter;
}) {
    const { storeState } = useStore(store => ({
        currentOperatorLogin: CurrentOperatorLoginState.get(store),
    }));

    // I object to this, but its copy paste task with no time to fix
    const transactionsListRefresher = (
        <ModalSpecialTabResultRefresher
            onRefreshClick={refetchTransactionList}
            successRequest={
                transactionListState.status == RequestStatus.SUCCESS
            }
            pendingRequest={
                transactionListState.status == RequestStatus.PENDING
            }
        />
    );

    if (!filter.state.filterActive && !filter.state.searchActive) {
        return (
            <Guide
                language={language}
                login={storeState.currentOperatorLogin}
            />
        );
    }

    if (
        transactionListState.status === RequestStatus.PENDING ||
        !isDefined(transactionListState.data)
    ) {
        return transactionsListRefresher;
    }

    if (
        transactionListState.status === RequestStatus.SUCCESS &&
        transactionListState.data.tooManyRows
    ) {
        return (
            <>
                {transactionsListRefresher}
                <TooManyRows lang={language} />
            </>
        );
    }

    if (
        transactionListState.status === RequestStatus.SUCCESS &&
        transactionListState.data.data.length === 0
    ) {
        return (
            <>
                {transactionsListRefresher}
                <NoRows lang={language} />
            </>
        );
    }

    return (
        <>
            {transactionsListRefresher}
            <ResultsTable
                parkTransactions={transactionListState.data.data}
                setOpenTransactionDetail={setOpenTransactionDetail}
                language={language}
            />
        </>
    );
}

interface HasLanguage {
    lang: string;
}

const TooManyRows = (p: HasLanguage) => (
    <ResultsError
        title={transactionsListTexts[p.lang].tooManyRowsTitle()}
        description={transactionsListTexts[p.lang].tooManyRowsDescription()}
    />
);

const NoRows = (p: HasLanguage) => (
    <ResultsError
        title={transactionsListTexts[p.lang].noRowsTitle()}
        description={transactionsListTexts[p.lang].noRowsDescription()}
    />
);

function ResultsTableHeader({ language }: { language: string }) {
    return (
        <TableHeader>
            <EmptyTableHeaderColumn
                width={ColumnWidth._24px}
                delimiterBottom={false}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerIdentifier()}
                width={ColumnWidth._192px}
                delimiterBottom={false}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerDateFrom()}
                width={ColumnWidth._136px}
                delimiterBottom={false}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerDateTo()}
                width={ColumnWidth._136px}
                delimiterBottom={false}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerDuration()}
                width={ColumnWidth._108px}
                delimiterBottom={false}
                align={ColAlign.right}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerZoneName()}
                width={ColumnWidth._208px}
                delimiterBottom={false}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerChannel()}
                width={ColumnWidth._108px}
                delimiterBottom={false}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerPrice()}
                width={ColumnWidth._108px}
                delimiterBottom={false}
                align={ColAlign.right}
            />
            <TableHeaderColumn
                name={transactionsListTexts[language].headerRefundAmount()}
                width={ColumnWidth._108px}
                delimiterBottom={false}
                align={ColAlign.right}
            />
            <EmptyTableHeaderColumn
                width={ColumnWidth._24px}
                delimiterBottom={false}
            />
        </TableHeader>
    );
}

function ResultsTable({
    parkTransactions,
    language,
    setOpenTransactionDetail,
}: {
    parkTransactions: OnstreetTransaction[];
    language: string;
    setOpenTransactionDetail: (transaction: OnstreetTransaction) => void;
}) {
    return (
        <Table>
            <ResultsTableHeader language={language} />
            <TableBody>
                {parkTransactions.map(t => (
                    <TransactionRow
                        onClick={() => setOpenTransactionDetail(t)}
                        backgroundColor={transactionColor(t)}
                        key={t.id}
                    >
                        <TableColumn width={ColumnWidth._24px} />
                        <TableColumn width={ColumnWidth._192px}>
                            <div
                                className={css({
                                    display: 'flex',
                                    alignItems: 'center',
                                })}
                            >
                                <div
                                    className={css({
                                        marginRight: 6,
                                        color: transactionColor(t),
                                    })}
                                >
                                    <IconTopOffsetContainer>
                                        <Icon24 icon={transaction} />
                                    </IconTopOffsetContainer>
                                </div>
                                <IdentificationLicensePlate
                                    licensePlateNr={t.licensePlateNr}
                                    type={t.licensePlateType}
                                    country={t.licensePlateCountry}
                                    inlineMode={true}
                                />
                            </div>
                        </TableColumn>
                        <TableColumn width={ColumnWidth._136px}>
                            {getFromDate(t)}
                        </TableColumn>
                        <TableColumn width={ColumnWidth._136px}>
                            {getToDate(t)}
                        </TableColumn>
                        <TableColumn
                            width={ColumnWidth._108px}
                            align={ColAlign.right}
                        >
                            {getDuration(t, language)}
                        </TableColumn>
                        <TableColumn width={ColumnWidth._208px}>
                            <div
                                className={css({
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                })}
                            >
                                {getZoneName(t)}
                            </div>
                        </TableColumn>
                        <TableColumn width={ColumnWidth._108px}>
                            {getChannelTitle(t)}
                        </TableColumn>
                        <TableColumn
                            width={ColumnWidth._108px}
                            align={ColAlign.right}
                        >
                            {getPrice(t, false)}
                        </TableColumn>
                        <TableColumn
                            width={ColumnWidth._108px}
                            align={ColAlign.right}
                        >
                            {getRefundAmount(t, false)
                                ? `- ${getRefundAmount(t, false)}`
                                : null}
                        </TableColumn>
                    </TransactionRow>
                ))}
            </TableBody>
        </Table>
    );
}

function TransactionRow({
    onClick,
    children,
    backgroundColor,
}: {
    onClick: ClickHandler | undefined;
    children?: ReactNode;
    backgroundColor: string;
}) {
    return (
        <DefaultTableRow
            onClick={onClick}
            hoverable={!!onClick}
            backgroundColor={backgroundColor}
        >
            {children}
            <TableColumn width={ColumnWidth._24px}>
                <Conditional c={!!onClick}>
                    <IconTopOffsetContainer>
                        <Icon16 icon={chevronRight} />
                    </IconTopOffsetContainer>
                </Conditional>
            </TableColumn>
        </DefaultTableRow>
    );
}

const transactionColor = (transaction: OnstreetTransaction) =>
    transaction.isRunning ? ColorHex.bActive : ColorHex.bExpired;

const Guide = ({
    login,
    language,
}: {
    login: CurrentOperatorLoginState.State;
    language: string;
}) => {
    const texts = guideTexts[language];
    if (isDefined(login.data)) {
        return (
            <TextColumnContent>
                <TextLeftColumn>
                    <h1>{texts.Header()}</h1>
                    <h3>{texts.Intro()}</h3>
                    <h3>{texts.ListDescription()}</h3>
                    <ul>
                        <li>{texts.Licenseplate()}</li>
                        <li>{texts.Id()}</li>
                    </ul>
                </TextLeftColumn>
                <TextRightColumn>
                    <h1>{texts.HeaderExport()}</h1>
                    <h3>{texts.IntroExport()}</h3>
                </TextRightColumn>
            </TextColumnContent>
        );
    } else {
        return null;
    }
};

interface GuideTexts {
    Header: Translation;
    Intro: Translation;
    ListDescription: Translation;
    Licenseplate: Translation;
    Id: Translation;
    Customername: Translation;
    Customernumber: Translation;
    HeaderExport: Translation;
    IntroExport: Translation;
}

const guideTexts: Translations<GuideTexts> = {
    de: {
        Header: () => 'Suchen und Bearbeiten',
        Intro: () =>
            'Mittels Suchfeld und/oder Filter können Sie bestehende Parkvorgänge suchen und bearbeiten.',
        ListDescription: () =>
            'Folgende Suchkriterien sind im Suchfeld verfügbar:',
        Licenseplate: () => 'Kennzeichen',
        Id: () => 'Transaktions-ID',
        Customername: () => 'Kontoinhaber',
        Customernumber: () => 'Kontonummer',
        HeaderExport: () => 'Exportieren',
        IntroExport: () =>
            'Die Suchresultate können als CSV herunterladen werden.',
    },
    fr: {
        Header: () => 'Rechercher et modifier',
        Intro: () =>
            'Avec le champ de recherche et/ou le filtre vous pouvez rechercher et evt. modifier les procédures existantes.',
        ListDescription: () =>
            'Les critères suivants sont disponibles dans le champ de recherche:',
        Licenseplate: () => 'Immatriculation',
        Id: () => 'ID transaction',
        Customername: () => 'Titulaire du compte',
        Customernumber: () => 'N° du compte',
        HeaderExport: () => 'Exporter',
        IntroExport: () =>
            'Les résultats de la recherche peuvent être téléchargés en format CSV.',
    },
    it: {
        Header: () => ' Cercare e modificare',
        Intro: () =>
            'Con il campo di ricerca e/o il filtro è potete cercare ed ev. modificare le procedure esistenti.',
        ListDescription: () => 'Sono disponibili i seguenti criteri:',
        Licenseplate: () => 'Numero di targa',
        Id: () => 'ID transazione',
        Customername: () => 'Titolare del conto',
        Customernumber: () => 'Nr. di conto',
        HeaderExport: () => 'Esportare',
        IntroExport: () =>
            'I risultati della ricerca possono essere scaricati in formato CSV.',
    },
};

interface TransactionsListTexts {
    headerIdentifier: Text.Translation;
    headerPresentCars: Text.Translation;
    headerDateFrom: Text.Translation;
    headerDateTo: Text.Translation;
    headerDuration: Text.Translation;
    headerZoneName: Text.Translation;
    headerChannel: Text.Translation;
    headerLocation: Text.Translation;
    headerCustomerAccount: Text.Translation;
    headerPrice: Text.Translation;
    headerRefundAmount: Text.Translation;
    tooManyRowsTitle: Text.Translation;
    tooManyRowsDescription: Text.Translation;
    noRowsTitle: Text.Translation;
    noRowsDescription: Text.Translation;
}

export const transactionsListTexts: Translations<TransactionsListTexts> = {
    de: {
        noRowsDescription: () =>
            'Bitte überprüfen Sie die ausgewählten Filterkriterien.',
        noRowsTitle: () => 'Keine Resultate gefunden.',
        tooManyRowsDescription: () =>
            'Bitte schränken Sie die Suche mit den Filterkriterien weiter ein oder exportieren Sie die Suchergebnisse zur Verarbeitung.',
        tooManyRowsTitle: () => 'Zu viele Resultate.',

        headerIdentifier: () => 'Identifikation',
        headerPresentCars: () => 'Anwesende Fahrzeuge',
        headerDateFrom: () => 'Beginn',
        headerDateTo: () => 'Ende',
        headerDuration: () => 'Dauer',
        headerZoneName: () => 'Zone',
        headerChannel: () => 'Kanal',
        headerLocation: () => 'Ort',
        headerCustomerAccount: () => 'Benutzerkonto',
        headerPrice: () => 'Preis',
        headerRefundAmount: () => 'Rückzahlung',
    },
    fr: {
        noRowsDescription: () =>
            'Vérifier dans le filtre les critères selectionnés.',
        noRowsTitle: () => 'Aucun résultat relevé.',
        tooManyRowsDescription: () =>
            'Restreignez la recherche avec les critères du filtre ou exportez les résultats pour traitement.',
        tooManyRowsTitle: () => 'Trop de résultats.',

        headerIdentifier: () => 'Identification',
        headerPresentCars: () => 'Véhicules présents',
        headerDateFrom: () => 'Début',
        headerDateTo: () => 'Fin',
        headerDuration: () => 'Durée',
        headerZoneName: () => 'Zone',
        headerChannel: () => 'Canal',
        headerLocation: () => 'Lieu',
        headerCustomerAccount: () => 'Compte utilisateur',
        headerPrice: () => 'Prix',
        headerRefundAmount: () => 'Remboursement',
    },
    it: {
        noRowsDescription: () => 'Verificare nel filtro i criteri selezionati.',
        noRowsTitle: () => 'Nessun risultato trovato.',
        tooManyRowsDescription: () =>
            "Restringere il campo di ricerca utilizzando i criteri del filtro o esportare i risultati per l'elaborazione.",
        tooManyRowsTitle: () => 'Troppi risultati.',

        headerIdentifier: () => 'Identificazione',
        headerPresentCars: () => 'Veicoli presenti',
        headerDateFrom: () => 'Inizio',
        headerDateTo: () => 'Fine',
        headerDuration: () => 'Durata',
        headerZoneName: () => 'Zona',
        headerChannel: () => 'Canale',
        headerLocation: () => 'Luogo',
        headerCustomerAccount: () => 'Conto utente',
        headerPrice: () => 'Prezzo',
        headerRefundAmount: () => 'Rimborso',
    },
    en: {
        noRowsDescription: () => 'Please check the selected filter criteria.',
        noRowsTitle: () => 'No results found.',
        tooManyRowsDescription: () =>
            'Please narrow down the search using the filter criteria or export the search results for processing.',
        tooManyRowsTitle: () => 'Too many results.',

        headerIdentifier: () => 'Identifier',
        headerPresentCars: () => 'Present Cars',
        headerDateFrom: () => 'Start',
        headerDateTo: () => 'End',
        headerDuration: () => 'Duration',
        headerZoneName: () => 'Zone',
        headerChannel: () => 'Channel',
        headerLocation: () => 'Location',
        headerCustomerAccount: () => 'User Account',
        headerPrice: () => 'Price',
        headerRefundAmount: () => 'Refund',
    },
};
