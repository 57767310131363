import { FieldValues } from 'react-hook-form';
import {
    ReactHookFormDropdownSelect,
    ReactHookFormSelectProps,
} from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormSelect.tsx';

export function OperatorFormSelect<
    FormFields extends FieldValues,
    FieldType extends number | string | string[],
>(props: ReactHookFormSelectProps<FormFields, FieldType>) {
    return (
        <ReactHookFormDropdownSelect
            {...props}
            itemStyle={{
                color: theme => theme.palette.blue.main,
                fontWeight: '500',
                fontSize: '16px',
            }}
        />
    );
}
