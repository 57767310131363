import {
    isDefined,
    isUndefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as PermitCreateTexts from '../i18n/PermitCreateTexts.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import moment from 'moment';
import { DatePickerSlideIn } from '../../ui/slidein/DatePickerSlideIn.tsx';

export interface Texts {
    ToCaption: Translation;
}

interface State {
    settings: SettingsState.State;
    layout: PermitCreateState.Layout.State;
    config: PermitCreateState.Config.State;
    permitType: Maybe<PermitTypeState.PermitType>;
}

export class PermitCreateValidityToDateSlideIn extends Flux.Container<State> {
    static displayName = 'PermitCreateValidityToDateSlideIn';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: PermitCreateState.Layout.get(this.props.allState),
            config: PermitCreateState.Config.get(this.props.allState),
            permitType: PermitTypeState.getById(
                this.props.allState,
                PermitCreateState.Config.get(this.props.allState).permitTypeId,
            ),
        };
    }

    txt(): Texts {
        return PermitCreateTexts.validityToDate[this.state.settings.language];
    }

    onDateSelected(dateField: string, selectedDate: moment.Moment): void {
        this.update((store: Flux.Store): string => {
            PermitCreateState.Config.stateWrite(store, {
                [dateField]: selectedDate,
            });
            PermitCreateState.Layout.stateWrite(store, {
                validityToDatePickerOpen: false,
            });
            PermitCreateState.Calc.reset(store);
            return 'PermitCreateValidityToDateSlideIn-selectDate';
        });
    }

    render() {
        const permitType = this.state.permitType;

        if (isUndefined(permitType)) {
            return null;
        } else {
            if (isUndefined(permitType.toRange)) {
                return null;
            } else {
                const configMinDate = thenElse(
                    permitType.toRange,
                    range => range.start,
                    null,
                );

                // don't allow selection of date before selected start date
                const minDate = thenElse(
                    this.state.config.startDate,
                    startDate =>
                        configMinDate
                            ? moment.max(configMinDate, startDate)
                            : startDate,
                    configMinDate,
                );
                const configMaxDate = thenElse(
                    permitType.toRange,
                    range =>
                        minDate == null
                            ? range.end
                            : moment.min(
                                  range.end,
                                  minDate
                                      .clone()
                                      .add(2, 'years')
                                      .add(-1, 'day'),
                              ),
                    null,
                );

                return (
                    <DatePickerSlideIn
                        open={this.state.layout.validityToDatePickerOpen}
                        canSelectMaxDate={isDefined(configMaxDate)}
                        caption={this.txt().ToCaption()}
                        onClose={() =>
                            this.update(store =>
                                PermitCreateState.Layout.stateWrite(store, {
                                    validityToDatePickerOpen: false,
                                }),
                            )
                        }
                        selectedDate={this.state.config.endDate}
                        onSelect={(selectedDate: moment.Moment) => {
                            return this.onDateSelected('endDate', selectedDate);
                        }}
                        minDate={minDate}
                        maxDate={configMaxDate}
                        language={this.state.settings.language}
                        outsideBody
                    />
                );
            }
        }
    }
}
