import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';

export interface AlertDefinition {
    key: string;
    id: number;
    description: {
        de: string;
        en: string;
        fr: string;
        it: string;
    };
    troubleshooting: {
        de: string;
        en: string;
        fr: string;
        it: string;
    };
}

interface TomAlertData {
    [errorId: string]: AlertDefinition;
}

export interface TomAlarmNameProps {
    code: number;
    language: string;
    type: TomAlertType;
    backendType: BackendType;
    render: (alertDef: AlertDefinition | null) => JSX.Element;
}

export enum BackendType {
    OPERATOR = 'OPERATOR',
    ADMIN = 'ADMIN',
}

export enum TomAlertType {
    ERROR = 'ERROR',
    ALARM = 'ALARM',
}

export function TomAlertMap({
    code,
    backendType,
    type,
    render,
}: TomAlarmNameProps) {
    const alertDefinitionPath = getAlertDefinitionPath(backendType, type);
    const [backendAlertDefinitionState] = useServerFetch<TomAlertData, object>(
        () => ({ url: alertDefinitionPath }),
        {},
    );
    if (backendAlertDefinitionState.status !== RequestStatus.SUCCESS) {
        return render(null);
    }
    const alertDef = backendAlertDefinitionState.data[code.toString()];
    return render(alertDef);
}

function getAlertDefinitionPath(backendType: BackendType, type: TomAlertType) {
    return backendPath(backendType) + tomAlertPath(type);
}

function backendPath(backendType: BackendType): string {
    switch (backendType) {
        case BackendType.ADMIN:
            return '/ui-api/admin';
        case BackendType.OPERATOR:
            return '/ui-api/operator-account';
    }
}

function tomAlertPath(type: TomAlertType) {
    switch (type) {
        case TomAlertType.ERROR:
            return '/taxomex/tom/errorEntries';
        case TomAlertType.ALARM:
            return '/taxomex/tom/errorEntries';
    }
}
