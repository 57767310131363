import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import {
    Conditional,
    ElementNamerGenerator,
} from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Text from '../../common/i18n/Text.ts';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../../ui/filter/FilterSummary.tsx';
import * as ParkTransactionsState from '../state/ParkTransactionsState.ts';
import { parkTransactionsFilterSummaryTexts } from '../i18n/ParkTransactionsTexts.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ParkTransactionsActions from '../actions/ParkTransactionsActions.ts';
import * as TomState from '../../tom/state/TomState.ts';
import { ParsedTom } from 'dg-web-shared/model/Tom.ts';
import { tomSelectionText } from '../../tom/components/TomFilterHalfSlideIn.tsx';
import { FilterButtonWithSummary } from '../../ui/filter/FilterButtonWithSummary.tsx';

const eng = ElementNamerGenerator('TomParkTransactionsFilterSummary');

export interface FilterSummaryTexts {
    dateFrom: Text.Translation;
    dateTo: Text.Translation;
    parkingMeters: Text.Translation;
    unlimited: Text.Translation;
}

interface SummaryElementProps {
    filter: ParkTransactionsState.Filter.State;
    texts: FilterSummaryTexts;
}

const DateFilterSummary = (p: SummaryElementProps): JSX.Element => (
    <Conditional c={ParkTransactionsState.Filter.dateFilterActive(p.filter)}>
        <FilterSummaryItem hasSeparator={false}>
            <FilterSummaryItemTitle title={p.texts.dateFrom()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    Formatter.openRange(
                        p.filter.validFrom,
                        p.filter.validTo,
                        Formatter.dayMonthYear,
                        p.texts.unlimited(),
                    )
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

interface TomsFilterSummaryProps extends SummaryElementProps {
    toms: ParsedTom[];
}

const TomsFilterSummary = (p: TomsFilterSummaryProps): JSX.Element => (
    <Conditional c={ParkTransactionsState.Filter.tomsFilterActive(p.filter)}>
        <FilterSummaryItem
            hasSeparator={ParkTransactionsState.Filter.dateFilterActive(
                p.filter,
            )}
        >
            <FilterSummaryItemTitle title={p.texts.parkingMeters()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' + tomSelectionText(p.filter.toms, p.toms.length)
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

const fscn = eng();

interface ParkTransactionsFilterSummaryState {
    settings: SettingsState.State;
    filter: ParkTransactionsState.Filter.State;
    toms: TomState.List.State;
}

export class ParkTransactionsFilterSummary extends Flux.Container<ParkTransactionsFilterSummaryState> {
    static displayName: string = fscn('');

    stateSelector(): ParkTransactionsFilterSummaryState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: ParkTransactionsState.Filter.get(this.props.allState),
            toms: TomState.List.get(this.props.allState),
        };
    }

    render() {
        const texts =
            parkTransactionsFilterSummaryTexts[this.state.settings.language];
        return (
            <FilterButtonWithSummary
                onFilterClick={() =>
                    this.update(store =>
                        ParkTransactionsState.Filter.stateWrite(store, {
                            filterSlideInVisible: true,
                        }),
                    )
                }
                clearFilter={() =>
                    this.update(ParkTransactionsActions.clearFilter)
                }
            >
                {ParkTransactionsState.Filter.filterActive(
                    this.state.filter,
                ) && [
                    <DateFilterSummary
                        filter={this.state.filter}
                        texts={texts}
                        key="date-filter"
                    />,
                    <TomsFilterSummary
                        filter={this.state.filter}
                        texts={texts}
                        toms={this.state.toms.data}
                        key="toms-filter"
                    />,
                ]}
            </FilterButtonWithSummary>
        );
    }
}
