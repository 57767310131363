import { Clearance } from '../../state/ResultState.ts';
import { ClearancesListItem } from './ClearancesListItem.tsx';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';

interface ClearancesListProps {
    clearances: Clearance[];
    update: Flux.Updater;
}

export const ClearancesList = (p: ClearancesListProps): JSX.Element | null => {
    const clearances = p.clearances;

    if (!clearances) {
        return null;
    } else {
        return (
            <div>
                {p.clearances.map((clearance: Clearance) => {
                    return (
                        <ClearancesListItem
                            key={clearance.id}
                            clearance={clearance}
                            forceExpanded={clearances.length === 1}
                            update={p.update}
                        />
                    );
                })}
            </div>
        );
    }
};
