import * as LicensePlateTexts from 'dg-web-shared/common/components/translations/LicensePlateTexts.ts';

import { Updater } from 'dg-web-shared/lib/Flux.tsx';
import {
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderButtonsContainer,
    SlideInHeaderTexts,
} from '../../ui/slidein/Slidein.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    ButtonDropdown,
    ButtonDropdownItem,
    ButtonDropdownLinkItem,
} from '../../ui/slidein/ButtonDropdown.tsx';
import {
    CarTypeState,
    LicensePlate,
    NewLicensePlateTargetState,
} from '../state/LicensePlateState.ts';
import * as Http from '../../api/Http.ts';
import { CarTypeEditSlideIn } from './CarTypeEditSlideIn.tsx';
import {
    LicensePlateEditCountrySlideIn,
    LicensePlateEditSlideIn,
} from './LicensePlateEditSlideIn.tsx';
import { getTypeString } from 'dg-web-shared/dto/LicensePlateType.ts';

interface Props {
    lp: LicensePlate;
    language: string;
    showTypeDescription: boolean;
    showMissingVignetteError: boolean;
    update: Updater;
    canEdit: boolean;
}

interface HeaderProps {
    lp: LicensePlate;
    language: string;
    update: Updater;
    onClose: () => void;
    canEdit: boolean;
}

export const EditHeader = (p: HeaderProps) => {
    const texts = LicensePlateTexts.detailTexts[p.language];
    return (
        <SecondLevelHeader onClose={p.onClose}>
            <SlideInHeaderTexts
                title={texts.SlideInTitle()}
                hasLeftIcon={false}
            />
            {p.canEdit && (
                <SlideInHeaderButtonsContainer>
                    <ButtonDropdown label={texts.DropdownLabel()}>
                        <ButtonDropdownItem
                            label={texts.ChangeLicensePlate()}
                            onClick={() =>
                                p.update(store =>
                                    NewLicensePlateTargetState.stateWrite(
                                        store,
                                        {
                                            open: true,
                                            licensePlateNr: '',
                                        },
                                    ),
                                )
                            }
                        />
                        <Conditional c={p.lp.vignettePrintPossible}>
                            <ButtonDropdownLinkItem
                                label={texts.PrintTooltip()}
                                href={Http.OperatorAccount.LicensePlates.vignettePdf2(
                                    p.lp.id,
                                )}
                            />
                        </Conditional>
                    </ButtonDropdown>
                </SlideInHeaderButtonsContainer>
            )}
        </SecondLevelHeader>
    );
};

export const Body = (p: Props) => {
    const texts = LicensePlateTexts.texts[p.language];
    return (
        <SlideInBody>
            <LabeledText label={texts.LicensePlate()}>
                {p.lp.licensePlateNr}
            </LabeledText>
            <LabeledText label={texts.Type()}>
                {getTypeString(p.language, p.lp.type)}
            </LabeledText>
            <LabeledText label={texts.Country()}>{p.lp.country}</LabeledText>
            {p.showTypeDescription && (
                <SingleSelection
                    focused={false}
                    label={texts.TypeDescription()}
                    onClick={() =>
                        p.update(store =>
                            CarTypeState.stateWrite(store, { open: true }),
                        )
                    }
                    selection={p.lp.typeDescriptions.join(', ')}
                />
            )}
        </SlideInBody>
    );
};

export const LicensePlateDetailLevel2SlideIns = (p: {
    outsideBody: boolean;
}) => {
    return (
        <div>
            <CarTypeEditSlideIn {...p} />
            <LicensePlateEditSlideIn {...p} />
            <LicensePlateEditCountrySlideIn {...p} />
        </div>
    );
};
