import moment from 'moment';
import { Formatter } from 'dg-web-shared/lib/Date.ts';

export const ISO_MONDAY = 1;
export const ISO_TUESDAY = 2;
export const ISO_WEDNESDAY = 3;
export const ISO_THURSDAY = 4;
export const ISO_FRIDAY = 5;
export const ISO_SATURDAY = 6;
export const ISO_SUNDAY = 7;

export function addDays(date: moment.Moment, addDays: number): string {
    const newDate = date.clone().add(addDays, 'days');
    return Formatter.isoYearMonthDay(newDate);
}

export function nextWeekdayAfter(
    refDay: moment.Moment,
    targetIsoWeekday: number,
): moment.Moment {
    const currentWeekday = refDay.isoWeekday();
    const daysToAdd = (targetIsoWeekday - currentWeekday + 7) % 7; // ensure positive result
    return refDay.clone().add(daysToAdd, 'days');
}

export function getEasterSunday(year: number): moment.Moment {
    if (year <= 0 || year >= 10000) {
        throw new Error('Invalid year ' + year);
    }

    // using the "Anonymous Gregorian Algorithm", see http://en.wikipedia.org/wiki/Computus#Algorithms
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const L = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * L) / 451);
    const n = h + L - 7 * m + 114;
    const month = Math.floor(n / 31);
    const day = (n % 31) + 1;

    return moment(`${year}-${month}-${day}`, 'YYYY-M-D');
}

export function getHilariFriday(year: number): moment.Moment {
    // 13.01 if friday
    // 12.01 if 13.01 is saturday
    // otherwise subsequent weekend

    const refDay = moment(`${year}-01-13`);
    if (refDay.isoWeekday() === ISO_FRIDAY) {
        return refDay;
    } else if (refDay.isoWeekday() === ISO_SATURDAY) {
        return refDay.subtract(1, 'day');
    } else {
        const nextFriday = nextWeekdayAfter(refDay, ISO_FRIDAY);
        return nextFriday;
    }
}

export function getMaienzug(year: number): moment.Moment {
    // first Friday in July
    const refDay = moment(`${year}-07-01`);
    const firstFriday = nextWeekdayAfter(refDay, ISO_FRIDAY);
    return firstFriday;
}

export function getKnabenschiessenMonday(year: number): moment.Moment {
    // Monday after second weekend in September or one week before Eidg. Bettag

    const refDay = moment(`${year}-09-01`);
    const firstSaturday = nextWeekdayAfter(refDay, ISO_SATURDAY);
    const knabenschiessenSunday = firstSaturday.add(1, 'week').add(1, 'day');
    if (knabenschiessenSunday.isSame(getEidgBettag(year))) {
        knabenschiessenSunday.subtract(1, 'week');
    }
    return knabenschiessenSunday.add(1, 'day');
}

export function getEidgBettag(year: number): moment.Moment {
    // third Sunday in September
    const refDay = moment(`${year}-09-01`);
    const firstSunday = nextWeekdayAfter(refDay, ISO_SUNDAY);
    return firstSunday.add(2, 'weeks');
}

export function getGenevaBettag(year: number): moment.Moment {
    // Thursday following the first Sunday of September
    const refDay = moment(`${year}-09-01`);
    const firstSunday = nextWeekdayAfter(refDay, ISO_SUNDAY);
    return firstSunday.add(4, 'day');
}
