import { MainContent } from '../../shared-mui-components/MainContent.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { OperatorAsyncLoadedSection } from '../../app/components/OperatorAsyncLoadedSection.tsx';
import { LoadingSpinnerPresets } from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { EmptyResults } from '../../shared-mui-components/ListMessages.tsx';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Article, PictureAsPdf } from '@mui/icons-material';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { MaterialHeader } from '../../layout/components/MaterialHeader.tsx';
import { MaterialTitle } from '../../layout/components/MaterialTitle.tsx';
import { SeparationBar } from '../../layout/components/SeparationBar.tsx';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { useOperatorLanguage } from '../../common/state/SettingsState.ts';

interface PayStationStatementResponse {
    monthlyStatements: PayStationStatementItem[];
    summary: PayStationStatementsSummary;
}
interface PayStationStatementsSummary {
    cashlessPaymentsRappen: number;
    cashPaymentsRappen: number;
    totalRappen: number;
}
interface PayStationStatementItem extends PayStationStatementsSummary {
    isCurrentMonth: boolean;
    period: string;
}

export function PayStationStatements() {
    const language = useOperatorLanguage();
    const [payStationStatementResultState] = useServerFetch<
        PayStationStatementResponse,
        object
    >(
        () => ({
            url: `/ui-api/operator-account/pay-station/monthly-statement`,
        }),
        {},
    );

    return (
        <MainContent>
            <MaterialHeader editMode={false}>
                <MaterialTitle
                    Icon={Article}
                    title={
                        <Localized
                            de="Kassen-Abrechnung"
                            fr="Comptabilité de trésorerie"
                            it="Contabilità di cassa"
                            en="Paystation statement"
                        />
                    }
                />
            </MaterialHeader>
            <SeparationBar />
            <OperatorAsyncLoadedSection
                requestState={payStationStatementResultState}
                pendingLoaderPreset={
                    LoadingSpinnerPresets.FillAllSpaceAndCenter
                }
                render={data => {
                    if (data.monthlyStatements.length === 0) {
                        return <EmptyResults hasFilter={false} />;
                    }
                    return (
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Localized
                                                de="Monat"
                                                fr="Mois"
                                                it="Mese"
                                                en="Month"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Bargeldlose Zahlungen"
                                                fr="Paiements sans numéraire"
                                                it="Pagamenti senza contanti"
                                                en="Cashless payments"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Barzahlungen"
                                                fr="Paiements en espèces"
                                                it="Pagamenti in contanti"
                                                en="Cash payments"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Gesamt"
                                                fr="Totale"
                                                it="Totale"
                                                en="Total"
                                            />
                                        </TableCell>
                                        <TableCell>PDF</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <PayStationSummaryRow
                                        language={language}
                                        summary={data.summary}
                                    />
                                    {data.monthlyStatements.map(
                                        monthlyStatement => (
                                            <PayStationStatementRow
                                                key={monthlyStatement.period}
                                                language={language}
                                                monthlyStatement={
                                                    monthlyStatement
                                                }
                                            />
                                        ),
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            />
        </MainContent>
    );
}

function PayStationSummaryRow({
    language,
    summary,
}: {
    language: Language;
    summary: PayStationStatementsSummary;
}) {
    return (
        <TableRow
            sx={{
                color: theme => theme.palette.primary.contrastText,
                backgroundColor: theme => theme.palette.primary.main,
            }}
        >
            <TableCell sx={{ color: 'inherit' }}>
                <Localized de="Gesamt" fr="Totale" it="Totale" en="Total" />
            </TableCell>
            <AmountChfField
                language={language}
                amountRappen={summary.cashlessPaymentsRappen}
            />
            <AmountChfField
                language={language}
                amountRappen={summary.cashPaymentsRappen}
            />
            <AmountChfField
                language={language}
                amountRappen={summary.totalRappen}
            />
            <TableCell></TableCell>
        </TableRow>
    );
}

function PayStationStatementRow({
    language,
    monthlyStatement,
}: {
    language: Language;
    monthlyStatement: PayStationStatementItem;
}) {
    const [year, month] = monthlyStatement.period.split('-');
    return (
        <TableRow>
            <TableCell>{monthlyStatement.period}</TableCell>
            <AmountChfField
                language={language}
                amountRappen={monthlyStatement.cashlessPaymentsRappen}
            />
            <AmountChfField
                language={language}
                amountRappen={monthlyStatement.cashPaymentsRappen}
            />
            <AmountChfField
                language={language}
                amountRappen={monthlyStatement.totalRappen}
            />
            <TableCell>
                {!monthlyStatement.isCurrentMonth && (
                    <a
                        target="_blank"
                        href={`/ui-api/operator-account/pay-station/monthly-statement/pdf/${year}/${month}`}
                        rel="noreferrer"
                    >
                        <PictureAsPdf
                            sx={{
                                color: theme => theme.palette.primary.main,
                                '&:hover': {
                                    color: theme => theme.palette.blue.electric,
                                },
                            }}
                        />
                    </a>
                )}
            </TableCell>
        </TableRow>
    );
}

function AmountChfField({
    language,
    amountRappen,
}: {
    language: Language;
    amountRappen: number;
}) {
    return (
        <TableCell sx={{ color: 'inherit' }}>
            {currencyCentsToLocalPrice(language, amountRappen)}
        </TableCell>
    );
}
