import { getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as TariffDefinition from 'dg-web-shared/common/tariff-logic/TariffDefinition.ts';
import { TariffSummary } from 'taxomex-shared/tariff/componants/TariffSummary.tsx';
import { tariffTexts } from '../i18n/TariffTexts.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { css } from '@emotion/css';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';

export interface TariffPreviewProps {
    tariff?: {
        name: string;
        tariffDefinition: TariffDefinition.FrontendTariffDefinition;
    } | null;
    showName?: boolean;
    language: string;
}

export function TariffPreview(props: TariffPreviewProps): JSX.Element | null {
    const txt = tariffTexts[props.language];
    const disabled = true;

    if (props.tariff) {
        return (
            <div>
                {getOrElse(props.showName, true) && (
                    <LabeledText label={txt.Tariff()}>
                        {props.tariff.name}
                    </LabeledText>
                )}
                {!disabled && (
                    <>
                        <LabeledText label={txt.TariffCard()} />
                        <div
                            className={css({
                                width: '100%',
                                backgroundColor: Colors.rgba(
                                    Colors.darkblue,
                                    0.1,
                                ),
                                textAlign: 'center',
                                margin: 'auto auto 20px',
                                padding: '20px',
                                ...Typo.robotoLight,
                                color: Colors.darkblue,
                            })}
                        >
                            <TariffSummary
                                tariff={props.tariff.tariffDefinition}
                                language={props.language}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    } else {
        return null;
    }
}
