import { css } from '@emotion/css';

export function OperatorBadge({
    children,
    color,
    circular,
    empty,
}: {
    children?: React.ReactNode;
    color: 'red' | 'yellow' | 'green';
    circular?: boolean;
    empty?: boolean;
}) {
    return (
        <div
            className={css({
                display: 'inline-block',
                lineHeight: 1,
                verticalAlign: 'baseline',
                marginRight: '0.14285714em',
                backgroundColor: '#e8e8e8',
                backgroundImage: 'none',
                padding: '0.5833em 0.833em',
                color: 'rgba(0,0,0,.6)',
                textTransform: 'none',
                fontWeight: 700,
                border: '0 solid transparent',
                borderRadius: '0.28571429rem',
                transition: 'background .1s ease',
                fontSize: '0.85714286rem',
                ...(color === 'red'
                    ? {
                          backgroundColor: '#db2828',
                          borderColor: '#db2828',
                          color: '#fff',
                      }
                    : {}),
                ...(color === 'green'
                    ? {
                          backgroundColor: '#21BA45',
                          borderColor: '#21BA45',
                          color: '#fff',
                      }
                    : {}),
                ...(color === 'yellow'
                    ? {
                          backgroundColor: '#FBBD08',
                          borderColor: '#FBBD08',
                          color: '#fff',
                      }
                    : {}),
                ...(circular
                    ? {
                          minWidth: '2em',
                          minHeight: '2em',
                          padding: '0.5em',
                          lineHeight: '1em',
                          textAlign: 'center',
                          borderRadius: '500rem',
                          ...(empty
                              ? {
                                    minWidth: 0,
                                    minHeight: 0,
                                    overflow: 'hidden',
                                    width: '0.5em',
                                    height: '0.5em',
                                    verticalAlign: 'baseline',
                                }
                              : {}),
                      }
                    : {}),
            })}
        >
            {children}
        </div>
    );
}
