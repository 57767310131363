import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { OperatorLogin } from '../../common/state/OperatorLoginsState.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';

export namespace Layout {
    export interface State {
        selectedOperatorLogin: number | null;
        permitTypeSelectionOpen: boolean;
        productTypeSelectionOpen: boolean;
        zoneSelectionOpen: boolean;
        recodeConditionSelectionOpen: boolean;
        editPasswordOpen: boolean;
        createEnabled: boolean;
        showLoginErrors: boolean;
        showPasswordErrors: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'operator-logins/OperatorLoginsState.Layout',
        {
            selectedOperatorLogin: null,
            permitTypeSelectionOpen: false,
            productTypeSelectionOpen: false,
            zoneSelectionOpen: false,
            recodeConditionSelectionOpen: false,
            editPasswordOpen: false,
            createEnabled: false,
            showLoginErrors: false,
            showPasswordErrors: false,
        },
    );
}

export namespace Edit {
    export interface State {
        allowedPermitTypes?: number[] | null;
        allowedProductTypes?: number[] | null;
        allowedZones?: number[] | null;
        allowedRecodeConditions?: string[] | null;
        permOperatorAppActive?: boolean | null;
        permEnforcementAppActive?: boolean | null;
        permTransactions?: boolean | null;
        permRefundParkTransactions?: boolean | null;
        permPermissionsEdit?: boolean | null;

        permParkingpay?: boolean | null;
        permPermitsRead?: boolean | null;
        permPermitsWrite?: boolean | null;
        permRefundPermits?: boolean | null;
        permActivityStatement?: boolean | null;
        permWhitelistCreate?: boolean | null;
        permWhitelistDelete?: boolean | null;
        permEnforcementLog?: boolean | null;
        permTwintQrCodePairing?: boolean | null;
        permQuotasWrite?: boolean | null;
        permParkingaboInitialBalanceCreate?: boolean | null;
        permCloudConnectorOffstreetDevicesView?: boolean | null;
        permCloudConnectorRecodeTicket?: boolean | null;
        permCloudConnectorAlerts?: boolean | null;
        permCloudConnectorReportingAccess?: boolean | null;

        permTaxomex?: boolean | null;
        permSyncApp?: boolean | null;
        permUsbStickEdit?: boolean | null;
        permParkingMeterEdit?: boolean | null;
        permParkingMeterAlerts?: boolean | null;

        firstName?: string | null;
        lastName?: string | null;
        username?: string | null;
        zipCode?: string | null;
        description?: string | null;
        softAnonymizeCheckinPublicPermitAfterDays: string | null;
        city?: string | null;
        telnr?: string | null;
        email?: string | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'operator-logins/OperatorLoginsState.Edit',
        {
            softAnonymizeCheckinPublicPermitAfterDays: null,
        },
    );

    function mapOperatorLoginToState(l: OperatorLogin): State {
        return {
            ...l,
            softAnonymizeCheckinPublicPermitAfterDays:
                l.softAnonymizeCheckinPublicPermitAfterDays.toString(),
            permOperatorAppActive: l.permissions.operatorAppActive,
            permEnforcementAppActive: l.permissions.enforcementAppActive,
            permTransactions: l.permissions.transactions,
            permRefundParkTransactions: l.permissions.refundParkTransactions,
            permPermissionsEdit: l.permissions.userPermissionEdit,
            permParkingpay: l.permissions.parkingpayModule,
            permPermitsRead: l.permissions.permitsRead,
            permPermitsWrite: l.permissions.permitsWrite,
            permRefundPermits: l.permissions.refundPermits,
            permActivityStatement: l.permissions.activityStatement,
            permWhitelistCreate: l.permissions.whitelistCreate,
            permWhitelistDelete: l.permissions.whitelistDelete,
            permEnforcementLog: l.permissions.enforcementLog,
            permTaxomex: l.permissions.taxomexModule,
            permSyncApp: l.permissions.syncApp,
            permUsbStickEdit: l.permissions.usbStickEdit,
            permParkingMeterEdit: l.permissions.parkingMeterEdit,
            permParkingMeterAlerts: l.permissions.parkingMeterAlerts,
            permTwintQrCodePairing: l.permissions.twintQrCodePairing,
            permQuotasWrite: l.permissions.quotasWrite,
            permParkingaboInitialBalanceCreate:
                l.permissions.parkingaboInitialBalanceCreate,
            permCloudConnectorOffstreetDevicesView:
                l.permissions.cloudConnectorOffstreetDevicesView,
            permCloudConnectorRecodeTicket:
                l.permissions.cloudConnectorRecodeTicket,
            permCloudConnectorAlerts: l.permissions.cloudConnectorAlerts,
            permCloudConnectorReportingAccess:
                l.permissions.cloudConnectorReportingAccess,
        };
    }

    export function hasChanges(s: State, l: OperatorLogin): boolean {
        const loginMappedToState = mapOperatorLoginToState(l);

        for (const [key, sValue] of Object.entries(s)) {
            if (sValue !== loginMappedToState[key as keyof State]) {
                return true;
            }
        }
        return false;
    }
}

export namespace PasswordEdit {
    export interface State {
        password1?: string | null;
        password2?: string | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'operator-logins/OperatorLoginsState.PasswordEdit',
        {},
    );
}

export namespace EditCreateResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'operator-logins/OperatorLoginsState.EditCreateResponse',
        () => null,
    );
}
