import { Translations } from '../../common/i18n/Text.ts';
import { FilterSummaryTexts } from '../components/collection/CollectionsFilterSummary.tsx';
import { DateFilterTexts } from '../components/collection/DateFilter.tsx';
import { FilterHalfSlideInTexts } from '../components/collection/FilterHalfSlideIn.tsx';
import { CollectionDetailTexts } from '../components/collection/CollectionDetailSlideIn.tsx';
import { CollectionListTexts } from '../components/collection/CollectionsList.tsx';
import { CollectionGuideTexts } from '../components/collection/Guide.tsx';
import { EmptyCollectionsFilterTexts } from '../components/collection/EmptyCollectionsFilterSelection.tsx';

/* tslint:disable */

export const collectionsFilterTexts: Translations<FilterSummaryTexts> = {
    de: {
        dateFrom: () => 'Von',
        dateTo: () => 'Bis',
        parkingMeters: () => 'Parkuhren',
        Amount: () => 'Betrag',
        Sticks: () => 'USB-Sticks',
        WithoutEmptyCollections: () => 'Ohne 0.00 Leerungen',
    },
    fr: {
        dateFrom: () => 'Du',
        dateTo: () => 'Au',
        parkingMeters: () => 'Horodateurs',
        Amount: () => 'Montant',
        Sticks: () => 'Clés USB',
        WithoutEmptyCollections: () => 'Sans collectes à 0.00',
    },
    it: {
        dateFrom: () => 'Dal',
        dateTo: () => 'Al',
        parkingMeters: () => 'Parchimetri',
        Amount: () => 'Importo',
        Sticks: () => 'Chiavette USB',
        WithoutEmptyCollections: () => 'Senza vuotature a 0.00',
    },
};

export const dateFilterTexts: Translations<DateFilterTexts> = {
    de: {
        filterTitle: () => 'Leerungsdatum',
        from: () => 'Von',
        to: () => 'Bis',
        unlimited: () => 'unbegrenzt',
    },
    fr: {
        filterTitle: () => 'Date de collecte',
        from: () => 'Du',
        to: () => 'Au',
        unlimited: () => 'illimitée',
    },
    it: {
        filterTitle: () => 'Data vuotatura',
        from: () => 'Dal',
        to: () => 'Al',
        unlimited: () => 'illimitata',
    },
};

export const emptyCollectionsFilterTexts: Translations<EmptyCollectionsFilterTexts> =
    {
        de: {
            hideEmptyCollections: () => '0.00 Leerungen ausblenden',
        },
        fr: {
            hideEmptyCollections: () => 'Cacher les collectes à 0.00',
        },
        it: {
            hideEmptyCollections: () => 'Nascondere vuotature a 0.00',
        },
    };

export const filterHalfSlideInTexts: Translations<FilterHalfSlideInTexts> = {
    de: {
        title: () => 'Filter',
    },
    fr: {
        title: () => 'Filtre',
    },
    it: {
        title: () => 'Filtro',
    },
};

export const collectionDetailTexts: Translations<CollectionDetailTexts> = {
    de: {
        BatteryVoltage: () => 'Batteriespannung',
        CollectedAmount: () => 'Betrag',
        CollectedAmountChf: () => 'Betrag CHF',
        CollectedAmountEuro: () => 'Betrag €',
        CollectedAmountToken: () => 'Betrag Jeton',
        CollectionNumber: () => 'Leerungsnummer',
        CollectionStickId: () => 'Verwendeter USB Stick',
        CollectionTime: () => 'Leerungsdatum',
        ParkingMeter: () => 'Parkuhr',
        PaidWith: () => 'Bezahlt mit',
        Total: () => 'Total',
        Token: () => 'Jeton',
        RejectedCoinsCount: () => 'Abgelehnte Münzen',
        SlideInHeaderCaption: () => 'Leerung',
        TransactionCount: () => 'Anzahl Transaktionen',
    },
    fr: {
        BatteryVoltage: () => 'Tension de la batterie',
        CollectedAmount: () => 'Montant',
        CollectedAmountChf: () => 'Montant CHF',
        CollectedAmountEuro: () => 'Montant €',
        CollectedAmountToken: () => 'Montant du jeton',
        CollectionNumber: () => 'Numéro de collecte',
        CollectionStickId: () => 'Clé USB utilisée',
        CollectionTime: () => 'Date de collecte',
        ParkingMeter: () => 'Horodateur',
        PaidWith: () => 'Payé par',
        Total: () => 'Somme',
        Token: () => 'Jetons',
        RejectedCoinsCount: () => 'Pièces refusées',
        SlideInHeaderCaption: () => 'Collecte',
        TransactionCount: () => 'Nombre de transactions',
    },
    it: {
        BatteryVoltage: () => 'Voltaggio batteria',
        CollectedAmount: () => 'Importo',
        CollectedAmountChf: () => 'Importo in CHF',
        CollectedAmountEuro: () => 'Importo in €',
        CollectedAmountToken: () => 'Importo in gettoni',
        CollectionNumber: () => 'Numero di vuotatura',
        CollectionStickId: () => 'Chiavetta USB usata',
        CollectionTime: () => 'Data di vuotatura',
        ParkingMeter: () => 'Parchimetro',
        PaidWith: () => 'Pagato con',
        Total: () => 'Totale',
        Token: () => 'Gettoni',
        RejectedCoinsCount: () => 'Monete rifiutate',
        SlideInHeaderCaption: () => 'Vuotatura',
        TransactionCount: () => 'Numero di transazioni',
    },
};

export const collectionListTexts: Translations<CollectionListTexts> = {
    de: {
        chfAmount: () => 'CHF',
        eurAmount: () => 'EUR',
        tokenAmount: () => 'Token',
        headerCollectionNumber: () => 'Leerungs-nummer',
        headerStick: () => 'USB-Stick',
        headerDate: () => 'Datum',
        headerParkingMeterName: () => 'Parkuhr',
        headerTransactionCount: () => 'Anzahl Transaktionen',
        noRowsDescription: () =>
            'Bitte überprüfen Sie die ausgewählten Filterkriterien.',
        noRowsTitle: () => 'Keine Resultate gefunden.',
        tooManyRowsDescription: () =>
            'Bitte schränken Sie die Suche mit den Filterkriterien weiter ein oder exportieren Sie die Suchergebnisse zur Verarbeitung.',
        tooManyRowsTitle: () => 'Zu viele Resultate.',
    },
    fr: {
        chfAmount: () => 'CHF',
        eurAmount: () => 'EUR',
        tokenAmount: () => 'Jetons',
        headerCollectionNumber: () => 'No. de collecte',
        headerStick: () => 'Clé USB',
        headerDate: () => 'Date',
        headerParkingMeterName: () => 'Horodateur',
        headerTransactionCount: () => 'Quantité transactions',
        noRowsDescription: () =>
            'Vérifier dans le filtre les critères selectionnés.',
        noRowsTitle: () => 'Aucun résultat relevé.',
        tooManyRowsDescription: () =>
            'Restreignez la recherche avec les critères du filtre ou exportez les résultats pour traitement.',
        tooManyRowsTitle: () => 'Trop de résultats.',
    },
    it: {
        chfAmount: () => 'CHF',
        eurAmount: () => 'EUR',
        tokenAmount: () => 'Gettoni',
        headerCollectionNumber: () => 'Nr. vuotatura',
        headerStick: () => 'Chiavetta USB',
        headerDate: () => 'Data',
        headerParkingMeterName: () => 'Parchimetro',
        headerTransactionCount: () => 'Quantità transazioni',
        noRowsDescription: () => 'Verificare nel filtro i criteri selezionati.',
        noRowsTitle: () => 'Nessun risultato trovato.',
        tooManyRowsDescription: () =>
            "Restringere il campo di ricerca utilizzando i criteri del filtro o esportare i risultati per l'elaborazione.",
        tooManyRowsTitle: () => 'Troppi risultati.',
    },
};

export const collectionsGuideTexts: Translations<CollectionGuideTexts> = {
    de: {
        Header: () => 'Anzeigen',
        Intro: () =>
            'Mittels Filter können Sie Liste von den Leerungen erzeugen.',
        ListDescription: () => 'Folgende Filterkriterien sind verfügbar:',
        ParkingMeters: () => 'Parkuhren',
        ValidDate: () => 'Leerungsdatum',
        UsbSticks: () => 'USB Sticks',
    },
    fr: {
        Header: () => 'Afficher',
        Intro: () =>
            "Vous pouvez générer la liste des collectes à l'aide de filtres.",
        ListDescription: () => 'Les critères suivants sont disponibles:',
        ParkingMeters: () => 'Horodateurs',
        ValidDate: () => 'Date de collecte',
        UsbSticks: () => 'Clés USB',
    },
    it: {
        Header: () => 'Visualizzare',
        Intro: () => 'Si può creare una lista delle vuotature usando i filtri.',
        ListDescription: () => 'Sono disponibili i seguenti criteri:',
        ParkingMeters: () => 'Parchimetri',
        ValidDate: () => 'Data vuotatura',
        UsbSticks: () => 'Chiavette USB',
    },
};

/* tslint:enable */
