import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    LicensePlate,
    parseBody,
} from '../../common/state/LicensePlateState.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';

export namespace Entity {
    export enum Context {
        EDIT,
        NEW_PERMIT_ADD,
        EXISTING_PERMIT_ADD,
    }

    export interface State {
        id: Maybe<number>;
        context: Context;
        confirmPressedWhileError: boolean;
    }

    export const { get, set, stateWrite, reset } = Flux.generateState<State>(
        'permit-detail-LicensePlateState.Entity',
        {
            id: null,
            context: Context.EDIT,
            confirmPressedWhileError: false,
        },
    );

    export const edit = (store: Flux.Store, licensePlateId: number): string =>
        stateWrite(store, {
            id: licensePlateId,
            context: Context.EDIT,
            confirmPressedWhileError: false,
        });

    export const new_permit_add = (
        store: Flux.Store,
        licensePlateId: number,
    ): string =>
        stateWrite(store, {
            id: licensePlateId,
            context: Context.NEW_PERMIT_ADD,
            confirmPressedWhileError: false,
        });

    export const existing_permit_add = (
        store: Flux.Store,
        licensePlateId: number,
    ): string =>
        stateWrite(store, {
            id: licensePlateId,
            context: Context.EXISTING_PERMIT_ADD,
            confirmPressedWhileError: false,
        });

    export const setConfirmPressedWhilteError = (store: Flux.Store): string => {
        set(store, (s: State): State => {
            s.confirmPressedWhileError = true;
            return s;
        });
        return 'permit-detail-LicensePlateState.AdditionalAddressEdit-confirmPressedWhileError';
    };
}

export namespace AddTripple {
    export interface State {
        createLpOpen: boolean;
        lpCountrySelectionOpen: boolean;
        type: LicensePlateType | null;
        licensePlateNr: Maybe<string>;
        country: string | null;
        confirmPressedWhileError: boolean;
        formValid: boolean;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'permit-detail-LicensePlateState.AddTripple',
        {
            licensePlateNr: null,
            type: LicensePlateType.CAR,
            country: 'CH',
            confirmPressedWhileError: false,
            createLpOpen: false,
            lpCountrySelectionOpen: false,
            formValid: false,
        },
    );
}

export namespace AddServerData {
    export type State = WriteStateSlice.State<LicensePlate | null>;
    export const { get, reset, setResponse } =
        WriteStateSlice.generate<LicensePlate>(
            'clearance-permit-list-LicensePlateState.AddServer',
            parseBody,
        );
}

export const resetAllStates = (store: Flux.Store): string => {
    Entity.reset(store);
    AddTripple.reset(store);
    AddServerData.reset(store);
    return 'clearance-permit-list-LicensePlateState-clear-all';
};
