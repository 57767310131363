import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    FilterSummaryItem,
    FilterSummaryItemSubTitle,
    FilterSummaryItemTitle,
} from '../ui/filter/FilterSummary.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { FilterButtonWithSummary } from '../ui/filter/FilterButtonWithSummary.tsx';
import { Filter, UI } from './ClearanceRequestsState.ts';
import { dateFilterTexts, permitTypeFilterTexts } from './Texts.tsx';
import { permitTypeSelection } from './PermitTypeFilter.tsx';
import * as PermitTypeState from '../common/state/PermitTypeState.ts';
import { statusSelection } from './StatusFilter.tsx';

const dfTexts = (p: { settings: SettingsState.State }) =>
    dateFilterTexts[p.settings.language];

const ptfTexts = (p: { settings: SettingsState.State }) =>
    permitTypeFilterTexts[p.settings.language];

interface SummaryElementProps {
    filter: Filter.State;
    settings: SettingsState.State;
}

const DateFilterSummary = (p: SummaryElementProps) => (
    <Conditional c={Filter.dateFilterActive(p.filter)}>
        <FilterSummaryItem hasSeparator={false}>
            <FilterSummaryItemTitle title={dfTexts(p).from()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    Formatter.openRange(
                        p.filter.dateFrom,
                        p.filter.dateTo,
                        Formatter.dayMonthYear,
                    )
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

interface PermitTypeFilterSummaryProps extends SummaryElementProps {
    permitTypes: PermitTypeState.State;
}

const PermitTypeFilterSummary = (p: PermitTypeFilterSummaryProps) => (
    <Conditional c={Filter.permitTypeFilterActive(p.filter)}>
        <FilterSummaryItem hasSeparator={Filter.dateFilterActive(p.filter)}>
            <FilterSummaryItemTitle title={ptfTexts(p).filterTitle()} />
            <FilterSummaryItemSubTitle
                title={
                    '\u00A0' +
                    permitTypeSelection(
                        p.permitTypes.data,
                        p.filter.permitTypeIds,
                    )
                }
            />
        </FilterSummaryItem>
    </Conditional>
);

const StatusFilterSummary = (p: SummaryElementProps) => (
    <Conditional c={Filter.statusFilterActive(p.filter)}>
        <FilterSummaryItem
            hasSeparator={
                Filter.permitTypeFilterActive(p.filter) ||
                Filter.dateFilterActive(p.filter)
            }
        >
            <FilterSummaryItemTitle
                title={'\u00A0' + statusSelection(p.filter.status, p)}
            />
        </FilterSummaryItem>
    </Conditional>
);

export const FilterSummary = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        ui: UI.get(store),
        filter: Filter.get(store),
        permitTypes: PermitTypeState.get(store),
    }),
    p => (
        <FilterButtonWithSummary
            onFilterClick={() =>
                p.update(store =>
                    UI.stateWrite(store, {
                        filterSlideInVisible: true,
                    }),
                )
            }
            clearFilter={() => p.update(Filter.reset)}
        >
            {Filter.filterActive(p.filter) && [
                <DateFilterSummary key="date" {...p} />,
                <PermitTypeFilterSummary key="permitType" {...p} />,
                <StatusFilterSummary key="status" {...p} />,
            ]}
        </FilterButtonWithSummary>
    ),
);
