import moment from 'moment';

import {
    isUnlimitedFromDate,
    Parser,
    unlimitedFromDate,
    unlimitedToDate,
} from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { getOrElse, isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ClearanceEdit from './ClearanceEdit.tsx';
import * as ClearanceEditTexts from '../i18n/ClearanceEditTexts.ts';
import * as ClearanceEditState from '../state/ClearanceEditState.ts';
import { FromDatePickerSlideIn } from './Shared.tsx';

interface State {
    settings: SettingsState.State;
    edit: ClearanceEditState.Edit.State;
    server: ClearanceEditState.Server.State;
    layout: ClearanceEditState.Layout.State;
}

export class ClearanceEditValidityFromDateSlideIn extends Flux.Container<State> {
    static displayName = 'ClearanceEditValidityFromDateSlideIn';

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            edit: ClearanceEditState.Edit.get(this.props.allState),
            server: ClearanceEditState.Server.get(this.props.allState),
            layout: ClearanceEditState.Layout.get(this.props.allState),
        };
    }

    getTexts(): ClearanceEdit.Texts {
        return ClearanceEditTexts.texts[this.state.settings.language];
    }

    render() {
        const server = this.state.server.data;
        const validFrom = getOrElse(
            this.state.edit.validFrom,
            isDefined(server)
                ? Parser.isoToMoment(server.validFrom)
                : unlimitedFromDate,
        );
        const validTo = getOrElse(
            this.state.edit.validTo,
            isDefined(server)
                ? Parser.isoToMoment(server.validTo)
                : unlimitedToDate,
        );
        return (
            <FromDatePickerSlideIn
                open={!!this.state.layout.fromDatePickerOpen}
                onClose={() =>
                    this.update(store =>
                        ClearanceEditState.Layout.stateWrite(store, {
                            fromDatePickerOpen: false,
                        }),
                    )
                }
                validFrom={isUnlimitedFromDate(validFrom) ? null : validFrom}
                validTo={validTo}
                onSelect={(selectedDate: moment.Moment) =>
                    this.update((s: Flux.Store): string => {
                        ClearanceEditState.Edit.stateWrite(s, {
                            validFrom: selectedDate,
                        });
                        ClearanceEditState.Layout.stateWrite(s, {
                            fromDatePickerOpen: false,
                        });
                        return 'ClearanceEditValidityFromDateSlideIn-setDate';
                    })
                }
                language={this.state.settings.language}
            />
        );
    }
}
