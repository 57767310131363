import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import { Body } from '../../ui/layout/TabContent.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { ZoneMapView } from './ZoneMapView.tsx';
import { MasterDataZoneRelevanceTag } from '../../common/state/MasterDataZonesState.ts';
import { ResultsError } from '../../ui/table/ResultsError.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { MountSlideIn, ZoneDetailSlideIn } from './ZoneDetailSlideIn.tsx';
import { useState } from 'react';

export function ZoneMapBody() {
    const { storeState } = useStore(store => ({
        zones: MasterDataZonesState.get(store),
    }));

    const [selectedZoneId, setSelectedZoneId] = useState<number | null>(null);

    const filteredZones = storeState.zones.pending
        ? null
        : storeState.zones.data
              .filter(
                  zone =>
                      zone.geodataText &&
                      !zone.isFromForeignMandant &&
                      zone.relevance.tag === MasterDataZoneRelevanceTag.ACTIVE,
              )
              .map(zone => {
                  return {
                      id: zone.id,
                      externalName: zone.zoneName,
                      externalId: zone.zoneCode,
                      geodataText: zone.geodataText,
                  };
              });

    return (
        <Body disabled={false} loading={!filteredZones}>
            <MountSlideIn>
                <ZoneDetailSlideIn
                    zoneId={selectedZoneId}
                    setSelectedZoneId={setSelectedZoneId}
                />
            </MountSlideIn>
            {filteredZones &&
                (filteredZones?.length > 0 ? (
                    <ZoneMapView
                        zonesData={filteredZones}
                        onZoneClick={(id: number) => {
                            setSelectedZoneId(id);
                        }}
                    />
                ) : (
                    <ResultsError
                        title={
                            <Localized
                                de="Keine Geodaten vorhanden."
                                fr="Aucune donnée de géolocalisation disponible."
                                it="Nessun dato di geolocalizzazione disponibile."
                                en="No geodata available."
                            />
                        }
                        description={
                            <Localized
                                de="Bitte nehmen Sie Kontakt mit unserem Helpdesk auf, um die entsprechenden Daten konfigurieren zu lassen."
                                fr="Veuillez contacter notre helpdesk pour faire configurer les données pertinentes."
                                it="Per far configurare i relativi dati voglia contattare il nostro helpdesk."
                                en="Please contact our helpdesk to have the respective data configured."
                            />
                        }
                    />
                ))}
        </Body>
    );
}
