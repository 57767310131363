import { useEffect, useState } from 'react';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { Parser } from 'dg-web-shared/lib/Date.ts';
import { isDefined, isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { FullZone } from 'dg-web-shared/model/Zone.ts';
import {
    ZoneConfigSpecificationState,
    ZoneEnforcedConfig,
} from 'dg-web-shared/model/ZoneConfiguration.ts';
import { Translation, TranslationWithArgs1 } from '../../common/i18n/Text.ts';
import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import { MasterDataZoneRelevanceTag } from '../../common/state/MasterDataZonesState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { TariffPreview } from '../../tariffs/components/TariffPreview.tsx';
import * as TariffState from '../../tariffs/state/TariffState.ts';
import {
    LabeledText,
    LabeledTextStatus,
} from '../../ui/labeled-elements/LabeledText.tsx';
import {
    UpcomingChange,
    UpcomingChanges,
} from '../../ui/labeled-elements/UpcomingChanges.tsx';
import {
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { zoneDetailTexts, zoneTexts } from '../i18n/ZoneTexts.ts';
import * as ZoneDetailState from '../state/ZoneDetailState.ts';
import { SingleZoneView } from './ZoneMapView.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { css } from '@emotion/css';
import { contentBodySlideInPortal } from '../../ui/layout/Shared.tsx';
import {
    LabeledSignalisationDownload,
    SignalisationType,
} from '../../common/components/LabeledSignalisationDownload.tsx';

export interface ZoneDetailTexts {
    ValidSince: Translation;
    EuroExchangeRate: Translation;
    Tariff: Translation;
    ParkingpayTariff: Translation;
    TomTariff: Translation;
    UpcomingChanges: Translation;
    NoUpcomingChanges: Translation;
    ConfigurationChangeType: TranslationWithArgs1<ZoneConfigurationChangeType>;
    TariffChange: Translation;
    ExchangeRateChange: Translation;
    OtherChange: Translation;
}

export const ZoneDetailSlideIn = contentBodySlideInPortal(
    ({
        zoneId,
        setSelectedZoneId,
    }: {
        zoneId: number | null;
        setSelectedZoneId: (value: number | null) => void;
    }) => {
        const { storeState } = useStore(store => ({
            settings: new SettingsState.StateSlice(store).state,
            permitTypes: PermitTypeState.get(store),
            zones: MasterDataZonesState.get(store),
            zoneDetail: ZoneDetailState.Detail.get(
                store,
                zoneId ? { zoneId } : null,
            ),
            tariffs: TariffState.List.get(store),
            referenceData: OperatorDataState.get(store),
        }));

        /*
         * Do not use <Localized> in this component as for long zone lists render time gets very slow.
         */
        const txt = zoneTexts[storeState.settings.language];

        const referenceData = storeState.referenceData.data;
        const hasParkingpayModule = isDefined(referenceData)
            ? referenceData.hasParkingpayModule
            : false;

        const zoneFinder = storeState.zones.data.filter(z => z.id === zoneId);
        const account =
            isDefined(referenceData) &&
            zoneFinder.length > 0 &&
            referenceData.billingProperties.accounts.length > 1
                ? referenceData.billingProperties.accounts.find(
                      account =>
                          account.dtaAccountId === zoneFinder[0].dtaAccountId,
                  )
                : null;
        if (zoneFinder.length > 0) {
            const zone = zoneFinder[0];
            const vatNumber = zone.vatNumber;
            return (
                <FullSlideIn open={true}>
                    <SlideInBody>
                        <FullSlideInLeftColumn>
                            <LabeledText label={txt.Name()}>
                                {zone.zoneName}
                            </LabeledText>
                            <LabeledText label={txt.NrLong()}>
                                {zone.zoneCode}
                            </LabeledText>
                            <Conditional
                                c={
                                    storeState.zoneDetail.data
                                        ?.enforcementHub === 'SBB'
                                }
                            >
                                <LabeledText label={txt.EnforcementHubZoneId()}>
                                    {storeState.zoneDetail.data
                                        ?.enforcementHubZoneId ??
                                        `NICHT GESETZT!`}
                                </LabeledText>
                            </Conditional>
                            <LabeledText label={txt.City()}>
                                {`${zone.zipCode} ${zone.city}`}
                            </LabeledText>
                            {zone.signalisationPdfOnstreet && (
                                <LabeledSignalisationDownload
                                    signalisationPdfId={
                                        zone.signalisationPdfOnstreet
                                    }
                                    type={SignalisationType.ZONE}
                                />
                            )}
                            <Conditional c={hasParkingpayModule}>
                                <div>
                                    <LabeledText label={txt.AllowedTypes()}>
                                        {' '}
                                        {txt.CarType()}{' '}
                                    </LabeledText>
                                    <LabeledText label={txt.PermitTypes()}>
                                        {renderPermitTypes(
                                            zone,
                                            storeState.permitTypes.data,
                                        )}
                                    </LabeledText>
                                </div>
                            </Conditional>
                        </FullSlideInLeftColumn>
                        <FullSlideInRightColumn>
                            <Conditional
                                c={
                                    !zone.isFromForeignMandant &&
                                    zone.relevance.tag ===
                                        MasterDataZoneRelevanceTag.ACTIVE &&
                                    !!zone.geodataText
                                }
                            >
                                <div
                                    className={css({
                                        width: '100%',
                                        height: '400px',
                                    })}
                                >
                                    <SingleZoneView
                                        zone={{
                                            id: zone.id,
                                            externalName: zone.zoneName,
                                            geodataText: zone.geodataText,
                                        }}
                                    />
                                </div>
                            </Conditional>
                            <Conditional c={hasParkingpayModule}>
                                <div>
                                    <LabeledText
                                        label={txt.NeedsPermit()}
                                        status={
                                            zone.needsPermit
                                                ? LabeledTextStatus.enabled
                                                : LabeledTextStatus.disabled
                                        }
                                    />
                                    <LabeledText label={txt.Vat()}>
                                        {isDefined(vatNumber) ? vatNumber : '-'}
                                    </LabeledText>
                                    {isDefined(account) ? (
                                        <LabeledText label={txt.Account()}>
                                            {account.iban} | {account.dtaText}
                                        </LabeledText>
                                    ) : null}
                                    <Conditional
                                        c={isDefined(zone.transactionType)}
                                    >
                                        <LabeledText label={txt.ParkType()}>
                                            {zone.transactionType ===
                                            MasterDataZonesState.TransactionType
                                                .duration
                                                ? txt.Duration()
                                                : txt.StartStop()}
                                        </LabeledText>
                                    </Conditional>
                                </div>
                            </Conditional>
                            <CurrentConfigurationDetail
                                zoneDetail={storeState.zoneDetail}
                                tariffs={storeState.tariffs}
                                language={storeState.settings.language}
                            />
                        </FullSlideInRightColumn>
                    </SlideInBody>
                    <StandardFirstLevelHeader
                        onClose={() => setSelectedZoneId(null)}
                    >
                        <SlideInHeaderTexts
                            title={txt.HeaderCaption()}
                            hasLeftIcon={false}
                        />
                    </StandardFirstLevelHeader>
                </FullSlideIn>
            );
        } else {
            return <FullSlideIn open={false} />;
        }
    },
);

function renderPermitTypes(
    zone: MasterDataZonesState.MasterDataZone,
    permitTypes: PermitTypeState.PermitType[],
): (JSX.Element | undefined)[] {
    return permitTypes
        .filter(pt => pt.operatorState === 'ACTIVE')
        .map((t, i) => {
            for (const z of t.zones) {
                if (z.id === zone.id) {
                    return <div key={i}>{t.description}</div>;
                }
            }
        })
        .filter(e => !!e);
}

const MAX_CHANGES_TO_SHOW = 3;

interface CurrentConfigurationDetailProps {
    zoneDetail: ZoneDetailState.Detail.State;
    tariffs: TariffState.List.State;
    language: string;
}

function CurrentConfigurationDetail(
    props: CurrentConfigurationDetailProps,
): JSX.Element | null {
    if (
        !props.zoneDetail ||
        props.zoneDetail.pending ||
        !props.zoneDetail.data ||
        !props.tariffs ||
        props.tariffs.pending
    ) {
        return null;
    }
    const txt = zoneDetailTexts[props.language];

    const currentConfiguration = props.zoneDetail.data.activeConfiguration;

    if (isUndefined(currentConfiguration)) {
        return null;
    }

    if (
        !currentConfiguration.tomEcoTariffId &&
        !currentConfiguration.parkingpayTariffId
    ) {
        // no information is displayed if there is no tom configuration
        return null;
    }

    const chfString = NumberFormatter.format(
        props.language,
        currentConfiguration.euroExchangeRate,
        '0,0.00',
    );
    const parkingpayTariff = TariffState.List.getTariffById(
        props.tariffs.data,
        currentConfiguration.parkingpayTariffId,
    );
    const tomTariff = TariffState.List.getTariffById(
        props.tariffs.data,
        currentConfiguration.tomEcoTariffId,
    );

    return (
        <div>
            {isDefined(parkingpayTariff) && (
                <div>
                    <LabeledText label={txt.ParkingpayTariff()} />
                    <TariffPreview
                        showName={true}
                        tariff={parkingpayTariff}
                        language={props.language}
                    />
                </div>
            )}
            {isDefined(tomTariff) && (
                <div>
                    <LabeledText label={txt.TomTariff()} />
                    <TariffPreview
                        showName={true}
                        tariff={tomTariff}
                        language={props.language}
                    />
                </div>
            )}
            <LabeledText label={txt.ValidSince()}>
                {Date.Formatter.dateWithDurationFromNow(
                    Parser.isoToMoment(currentConfiguration.validFrom),
                    props.language,
                )}
            </LabeledText>
            <LabeledText label={txt.EuroExchangeRate()}>
                {`1 € = ${chfString} CHF`}
            </LabeledText>
            <UpcomingZoneChanges
                zone={props.zoneDetail.data}
                currentConfiguration={currentConfiguration}
                language={props.language}
            />
        </div>
    );
}

interface UpcomingZoneChangesProps {
    zone: FullZone;
    currentConfiguration: ZoneEnforcedConfig;
    language: string;
}

function UpcomingZoneChanges(props: UpcomingZoneChangesProps): JSX.Element {
    const txt = zoneDetailTexts[props.language];
    const zoneConfigurations = props.zone.configurations;
    const upcomingConfigurations = zoneConfigurations.filter(
        c =>
            Parser.isoToMoment(c.validFrom).isAfter(
                props.currentConfiguration.validFrom,
            ) &&
            c.specificationStatus === ZoneConfigSpecificationState.released,
    );

    let previousConfiguration = props.currentConfiguration;
    const upcomingChanges: UpcomingChange[] = upcomingConfigurations.map(c => {
        const changeTypes = getConfigurationChangeTypes(
            previousConfiguration,
            c,
        );
        previousConfiguration = c;
        return {
            date: Parser.isoToMoment(c.validFrom),
            description: changeTypes
                .map(txt.ConfigurationChangeType)
                .join(', '),
        };
    });

    return (
        <UpcomingChanges
            changes={upcomingChanges}
            label={txt.UpcomingChanges()}
            noChangesText={txt.NoUpcomingChanges()}
            language={props.language}
            maxChanges={MAX_CHANGES_TO_SHOW}
        />
    );
}

export type ZoneConfigurationChangeType = 'tariff' | 'exchangeRate' | 'other';

function getConfigurationChangeTypes(
    configA: ZoneEnforcedConfig,
    configB: ZoneEnforcedConfig,
): ZoneConfigurationChangeType[] {
    const changeTypes: ZoneConfigurationChangeType[] = [];
    if (configA.euroExchangeRate !== configB.euroExchangeRate) {
        changeTypes.push('exchangeRate');
    }
    if (
        (configA.tomEcoTariffId &&
            configB.tomEcoTariffId &&
            configA.tomEcoTariffId !== configB.tomEcoTariffId) ||
        (configA.parkingpayTariffId &&
            configB.parkingpayTariffId &&
            configA.parkingpayTariffId !== configB.parkingpayTariffId)
    ) {
        changeTypes.push('tariff');
    }
    return changeTypes;
}

export function MountSlideIn({ children }: { children?: React.ReactNode }) {
    /*
     * By inserting this component between the creation of the body component and the creation of the slide in portal we
     * ensure that the slide in portal is created after the mounting div is rendered. If we don't use useEffect the
     * portal and the mounting div are created in the same render, which results in the slide in portal not to be mounted
     * in the DOM, as its mounting node does not yet exist in the DOM.
     */
    const [renderChildren, setRenderChildren] = useState(false);
    useEffect(() => setRenderChildren(true));

    return children && renderChildren ? <>{children}</> : null;
}
