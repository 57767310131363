import { ShouldConfirmBeforeExitFormProps } from 'dg-web-shared/common/utils/FormDefinitions.tsx';
import { ParkingaboUser } from './OperatorParkingaboUsersForm.tsx';
import {
    RequestStatus,
    ServerWriteState,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { makeLocalizedText, Message } from 'dg-web-shared/lib/Localized.ts';
import { Box, InputLabel, Switch, TextField, Tooltip } from '@mui/material';
import { AssignmentInd, Person } from '@mui/icons-material';
import { Localized, useLocalized } from '../common/components/Localized.tsx';
import { getCustomerDisplayName } from 'dg-web-shared/common/models/Users.tsx';
import { DateTime, Duration } from 'luxon';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils.tsx';
import { useParkingaboUserFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { generatePath, useOutletContext } from 'react-router-dom';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { intervalDurationToStartSelectOptions } from 'product-shared/product-template/SubscriptionIntervalDuration.ts';
import { ParkingaboUsersProductsAddContextType } from './OperatorParkingaboUsersProducts.tsx';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
    Control,
    Controller,
    FieldValues,
    Path,
    useForm,
} from 'react-hook-form';
import {
    FormContentLayout,
    FormLeftColumn,
    FormRightColumn,
} from '../layout/components/form/FormLayout.tsx';
import InfoIcon from '@mui/icons-material/Info';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { MaterialBar } from '../layout/components/MaterialBar.tsx';
import { OperatorFormSelect } from '../ui/form/OperatorFormSelect.tsx';
import {
    AllAccessBarrierGateConfig,
    PermitFreeFixedDurationBarrierGateConfig,
    PermitUntilRevocationBarrierGateConfig,
    ProductTemplateConfig,
    ProductTemplateConfigType,
    SubscriptionConfig,
} from 'product-shared/product-template/ProductTemplate.ts';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { HeaderCancelSaveButtons } from '../layout/components/HeaderCancelSaveButtons.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { GenericFormSubmitError } from './ParkingaboUsersUtils.tsx';

export function OperatorParkingaboUsersProductsAdd() {
    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    const {
        parkingaboUser,
        purchasableProductTemplates,
        refetchProducts,
        refetchParkingaboUser,
    } = useOutletContext<ParkingaboUsersProductsAddContextType>();
    const selfUrl = `${generatePath('/parkingabo/users/:customerNr/products', {
        customerNr: parkingaboUser.customerNr,
    })}${makeSearchQueryWithFilters(searchText, activeFilters)}`;

    return (
        <OperatorRoutedModal
            variant={ModalVariant.STANDARD}
            backUrl={selfUrl}
            render={controller => (
                <OperatorParkingaboUsersProductsAddContent
                    onCreated={() => {
                        controller.setShouldConfirmBeforeLeave(false);
                        refetchProducts();
                        controller.safeNavigateTo(selfUrl);
                        refetchParkingaboUser();
                    }}
                    parkingaboUser={parkingaboUser}
                    productTemplates={purchasableProductTemplates}
                    onClose={controller.close}
                    onDirtyStateChange={controller.setShouldConfirmBeforeLeave}
                />
            )}
        />
    );
}

function useProductSubmitReuest(
    onCreated: () => void,
): ServerWriteState<null, ValidationData, CreateProductPayload> {
    const [productAddRequestState, submitProductAddRequest, setState] =
        useServerWrite<CreateProductPayload, null, ValidationData>(payload => ({
            url: `/ui-api/operator-account/parkingabo/customer/${payload.customerNr}/product`,
        }));

    useServerSuccessEffect(productAddRequestState, onCreated);

    return [productAddRequestState, submitProductAddRequest, setState];
}

interface OperatorParkingaboUsersProductsAddProps
    extends ShouldConfirmBeforeExitFormProps {
    parkingaboUser: ParkingaboUser;
    productTemplates: ProductsAddTemplateItem[];
    onCreated: () => void;
}

function OperatorParkingaboUsersProductTemplateSwitch({
    productTemplates,
    render,
}: {
    productTemplates: ProductsAddTemplateItem[];
    render: (
        productTemplateDropdown: JSX.Element,
        selectedProductTemplate: ProductsAddTemplateItem,
    ) => JSX.Element;
}) {
    const form = useForm<ContractTemplateIdValue>({
        defaultValues: {
            [ContractTemplateIdField.contractTemplateId]:
                productTemplates[0].contractTemplateId,
        },
    });
    const contractTemplateId = form.watch(
        ContractTemplateIdField.contractTemplateId,
    );
    const selectedProductTemplate = productTemplates.find(
        productTemplate =>
            productTemplate.contractTemplateId == contractTemplateId,
    );
    if (selectedProductTemplate == undefined) {
        throw new Error('Product not inside selectable Dropdown');
    }
    return render(
        <OperatorFormSelect
            readonly={productTemplates.length < 2}
            label={
                <Localized
                    de="Produkt-Typ"
                    fr="Type de produit"
                    it="Tipo prodotto"
                    en="Product type"
                />
            }
            name={ContractTemplateIdField.contractTemplateId}
            control={form.control}
            options={productTemplates.map(productTemplate => ({
                display: useLocalized(
                    productTemplate.productConfig.productName,
                ),
                value: productTemplate.contractTemplateId,
            }))}
        />,
        selectedProductTemplate,
    );
}

export function OperatorParkingaboUsersProductsAddContent({
    parkingaboUser,
    productTemplates,
    onClose,
    onDirtyStateChange,
    onCreated,
}: OperatorParkingaboUsersProductsAddProps) {
    return (
        <OperatorParkingaboUsersProductTemplateSwitch
            productTemplates={productTemplates}
            render={(productTemplateDropdown, selectedProductTemplate) => {
                switch (selectedProductTemplate.productConfig.configType) {
                    case ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE:
                    case ProductTemplateConfigType.SUBSCRIPTION_ENFORCED:
                    case ProductTemplateConfigType.SUBSCRIPTION_ENFORCED_APP:
                    case ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE_APP:
                        return (
                            <OperatorParkingaboUsersProductsAddSubscription
                                parkingaboUser={parkingaboUser}
                                onDirtyStateChange={onDirtyStateChange}
                                productTemplateDropdown={
                                    productTemplateDropdown
                                }
                                onClose={onClose}
                                onCreated={onCreated}
                                subscriptionConfig={
                                    selectedProductTemplate.productConfig
                                }
                                zonesDescriptions={
                                    selectedProductTemplate.zonesDescriptions
                                }
                                contractTemplateId={
                                    selectedProductTemplate.contractTemplateId
                                }
                            />
                        );
                    case ProductTemplateConfigType.ALL_ACCESS_BARRIER_GATE:
                    case ProductTemplateConfigType.PERMIT_UNTIL_REVOCATION_BARRIER_GATE:
                    case ProductTemplateConfigType.PERMIT_FREE_FIXED_DURATION_BARRIER_GATE:
                        return (
                            <OperatorParkingaboUsersProductsAddPermitUntilRevocation
                                parkingaboUser={parkingaboUser}
                                onDirtyStateChange={onDirtyStateChange}
                                productTemplateDropdown={
                                    productTemplateDropdown
                                }
                                permitUntilRevocationConfig={
                                    selectedProductTemplate.productConfig
                                }
                                zonesDescriptions={
                                    selectedProductTemplate.zonesDescriptions
                                }
                                contractTemplateId={
                                    selectedProductTemplate.contractTemplateId
                                }
                                onClose={onClose}
                                onCreated={onCreated}
                            />
                        );
                    case ProductTemplateConfigType.GUEST_RESERVATION_ENFORCED:
                    case ProductTemplateConfigType.GUEST_RESERVATION_BARRIER_GATE:
                    case ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_BARRIER_GATE:
                    case ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_ENFORCED:
                        throw new Error('Product type should not be possible');
                }
            }}
        />
    );
}

type ProductAddProps = {
    parkingaboUser: ParkingaboUser;
    onDirtyStateChange?: (shouldConfirm: boolean) => void;
    productTemplateDropdown: JSX.Element;
    onClose: () => void;
    onCreated: () => void;
    contractTemplateId: number;
    zonesDescriptions: string[];
};

function OperatorParkingaboUsersProductsAddSubscription({
    parkingaboUser,
    onDirtyStateChange,
    subscriptionConfig,
    onClose,
    productTemplateDropdown,
    onCreated,
    contractTemplateId,
    zonesDescriptions,
}: {
    subscriptionConfig: SubscriptionConfig;
} & ProductAddProps) {
    const language = useOperatorLanguage();
    const [productAddRequestState, submitProductAddRequest] =
        useProductSubmitReuest(onCreated);
    const {
        formInfo: { control, handleSubmit, watch },
        genericSubmitError,
    } = useEasyForm(onDirtyStateChange, productAddRequestState, language, {
        defaultValues: {
            [Fields.startDate]: '',
            [Fields.tenantRemark]: '',
        },
    });
    const startDate = watch(Fields.startDate);

    function onSubmit(formData: FormFields) {
        submitProductAddRequest({
            ...formData,
            tenantId: parkingaboUser.tenant.tenantId,
            customerNr: parkingaboUser.customerNr,
            contractTemplateId: contractTemplateId,
        });
    }

    return (
        <OperatorRoutedModalContent
            header={
                <OperatorParkingaboUsersProductsAddHeader
                    loading={
                        productAddRequestState.status === RequestStatus.PENDING
                    }
                    onClose={onClose}
                    saveDisabled={!startDate}
                    onSubmit={handleSubmit(onSubmit)}
                />
            }
            bar={
                <MaterialBar
                    title={getCustomerDisplayName(parkingaboUser)}
                    Icon={Person}
                />
            }
            body={
                <>
                    <GenericFormSubmitError
                        submitState={productAddRequestState}
                        error={genericSubmitError}
                    />
                    <OperatorParkingaboUsersProductsAddSubscriptionBody
                        productTemplateDropdown={productTemplateDropdown}
                        subscriptionConfig={subscriptionConfig}
                        zonesDescriptions={zonesDescriptions}
                        language={language}
                        control={control}
                        startDateField={Fields['startDate']}
                        tenantRemarkField={Fields['tenantRemark']}
                    />
                </>
            }
        />
    );
}

function OperatorParkingaboUsersProductsAddPermitUntilRevocation({
    parkingaboUser,
    onDirtyStateChange,
    productTemplateDropdown,
    permitUntilRevocationConfig,
    onClose,
    onCreated,
    contractTemplateId,
    zonesDescriptions,
}: {
    permitUntilRevocationConfig:
        | PermitUntilRevocationBarrierGateConfig
        | PermitFreeFixedDurationBarrierGateConfig
        | AllAccessBarrierGateConfig;
} & ProductAddProps) {
    const language = useOperatorLanguage();
    const [productAddRequestState, submitProductAddRequest] =
        useProductSubmitReuest(onCreated);
    const {
        formInfo: { control, handleSubmit, watch },
        genericSubmitError,
    } = useEasyForm(onDirtyStateChange, productAddRequestState, language, {
        defaultValues: {
            [Fields.startDate]: DateTime.now().toISODate(),
            [Fields.tenantRemark]: '',
        },
    });
    const startDate = watch(Fields.startDate);

    function onSubmit(formData: FormFields) {
        submitProductAddRequest({
            ...formData,
            tenantId: parkingaboUser.tenant.tenantId,
            customerNr: parkingaboUser.customerNr,
            contractTemplateId: contractTemplateId,
        });
    }

    return (
        <OperatorRoutedModalContent
            header={
                <OperatorParkingaboUsersProductsAddHeader
                    loading={
                        productAddRequestState.status === RequestStatus.PENDING
                    }
                    onClose={onClose}
                    saveDisabled={!startDate}
                    onSubmit={handleSubmit(onSubmit)}
                />
            }
            bar={
                <MaterialBar
                    title={getCustomerDisplayName(parkingaboUser)}
                    Icon={Person}
                />
            }
            body={
                <>
                    <GenericFormSubmitError
                        submitState={productAddRequestState}
                        error={genericSubmitError}
                    />
                    <OperatorParkingaboUsersProductsAddPermitUntilRevocationBody
                        productTemplateDropdown={productTemplateDropdown}
                        permitUntilRevocationConfig={
                            permitUntilRevocationConfig
                        }
                        zonesDescriptions={zonesDescriptions}
                        control={control}
                        startDateField={Fields['startDate']}
                        currentStartDate={startDate}
                        tenantRemarkField={Fields['tenantRemark']}
                    />
                </>
            }
        />
    );
}

function OperatorParkingaboUsersProductsAddHeader({
    loading,
    onClose,
    saveDisabled,
    onSubmit,
}: {
    loading: boolean;
    onClose: () => void;
    saveDisabled: boolean;
    onSubmit: () => void;
}) {
    return (
        <HeaderComponent
            Icon={AssignmentInd}
            title={
                <Localized
                    de="Neues Produkt"
                    fr="Nouveau produit"
                    it="Nuovo prodotto"
                    en="New product"
                />
            }
            editMode={true}
            buttons={
                <HeaderCancelSaveButtons
                    onCancel={onClose}
                    onSave={onSubmit}
                    loading={loading}
                    saveDisabled={saveDisabled}
                    noDiscardDialog={true}
                />
            }
        />
    );
}

function OperatorParkingaboUsersProductsAddSubscriptionBody<
    TFieldValues extends FieldValues,
>({
    subscriptionConfig,
    zonesDescriptions,
    language,
    control,
    startDateField,
    tenantRemarkField,
    productTemplateDropdown,
}: {
    subscriptionConfig: SubscriptionConfig;
    zonesDescriptions: string[];
    language: Language;
    control: Control<TFieldValues>;
    startDateField: Path<TFieldValues>;
    tenantRemarkField: Path<TFieldValues>;
    productTemplateDropdown: JSX.Element;
}) {
    const now = DateTime.now();
    const startOptions = Array.from(
        {
            length: subscriptionConfig.intervalConfig
                .maxIntervalsPurchaseAheadInFuture,
        },
        (_v, k) => k + 1,
    ).map(amount => {
        const option = intervalDurationToStartSelectOptions(
            now,
            amount,
            subscriptionConfig.intervalConfig.intervalDuration,
            language,
        );
        return {
            display: option.dateText,
            value: option.startDate.toISODate(),
        };
    });
    return (
        <FormContentLayout>
            <FormLeftColumn>
                {productTemplateDropdown}
                <OperatorFormSelect
                    readonly={startOptions.length == 0}
                    label={
                        <Localized
                            de="Beginn"
                            fr="Début"
                            it="Inizio"
                            en="Start"
                        />
                    }
                    name={startDateField}
                    control={control}
                    options={startOptions}
                />
                <OperatorParkingaboUsersProductsAdditionalInfo
                    additionalInfo={subscriptionConfig.additionalInfo}
                />
                <OperatorParkingaboUsersProductsAddZoneDescription
                    zonesDescriptions={zonesDescriptions}
                />
            </FormLeftColumn>
            <OperatorParkingaboUsersProductsAddRightColumn
                control={control}
                tenantRemarkField={tenantRemarkField}
            />
        </FormContentLayout>
    );
}

function OperatorParkingaboUsersProductsAddPermitUntilRevocationBody<
    TFieldValues extends FieldValues,
>({
    productTemplateDropdown,
    permitUntilRevocationConfig,
    zonesDescriptions,
    control,
    startDateField,
    currentStartDate,
    tenantRemarkField,
}: {
    productTemplateDropdown: JSX.Element;
    permitUntilRevocationConfig:
        | PermitUntilRevocationBarrierGateConfig
        | PermitFreeFixedDurationBarrierGateConfig
        | AllAccessBarrierGateConfig;
    zonesDescriptions: string[];
    control: Control<TFieldValues>;
    startDateField: Path<TFieldValues>;
    currentStartDate: string;
    tenantRemarkField: Path<TFieldValues>;
}) {
    const now = DateTime.now();
    const localize = makeLocalizedText(useOperatorLanguage());
    return (
        <FormContentLayout>
            <FormLeftColumn>
                {productTemplateDropdown}
                <Controller
                    name={startDateField}
                    control={control}
                    render={({ field }) => (
                        <>
                            <InputLabel variant="standard">
                                {
                                    <Localized
                                        de="Beginn"
                                        fr="Début"
                                        it="Inizio"
                                        en="Start"
                                    />
                                }
                            </InputLabel>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '-8px !important',
                                    marginLeft: '-12px !important',
                                }}
                            >
                                <Switch
                                    checked={
                                        currentStartDate == now.toISODate()
                                    }
                                    onChange={(_e, checked) => {
                                        if (!checked) {
                                            field.onChange(
                                                now
                                                    .plus(
                                                        Duration.fromISO('P1D'),
                                                    )
                                                    .toISODate(),
                                            );
                                        } else {
                                            field.onChange(now.toISODate());
                                        }
                                    }}
                                />

                                {currentStartDate == now.toISODate() ? (
                                    <Box
                                        sx={{
                                            paddingLeft: '5px',
                                            paddingRight: '40px',
                                            color: theme =>
                                                theme.palette.blue.main,
                                        }}
                                    >
                                        <Localized
                                            de="Sofort"
                                            fr="Immédiatement"
                                            it="Subito"
                                            en="Immediately"
                                        />
                                    </Box>
                                ) : (
                                    <DesktopDatePicker
                                        format="dd.MM.yyyy"
                                        value={DateTime.fromISO(
                                            currentStartDate,
                                        )}
                                        disablePast={true}
                                        onChange={d =>
                                            field.onChange(d!.toISODate())
                                        }
                                        slotProps={{
                                            textField: {
                                                size: 'medium',
                                            },
                                        }}
                                    />
                                )}
                            </Box>
                        </>
                    )}
                />
                {permitUntilRevocationConfig.configType ===
                    ProductTemplateConfigType.PERMIT_FREE_FIXED_DURATION_BARRIER_GATE && (
                    <TextField
                        disabled
                        label={
                            <Localized
                                de="Dauer"
                                fr="Durée"
                                it="Durata"
                                en="Duration"
                            />
                        }
                        value={localize({
                            de: '1 Jahr',
                            fr: '1 an',
                            it: '1 anno',
                            en: '1 year',
                        })}
                    />
                )}
                <OperatorParkingaboUsersProductsAdditionalInfo
                    additionalInfo={permitUntilRevocationConfig.additionalInfo}
                />
                <OperatorParkingaboUsersProductsAddZoneDescription
                    zonesDescriptions={zonesDescriptions}
                />
            </FormLeftColumn>
            <OperatorParkingaboUsersProductsAddRightColumn
                control={control}
                tenantRemarkField={tenantRemarkField}
            />
        </FormContentLayout>
    );
}

function OperatorParkingaboUsersProductsAdditionalInfo({
    additionalInfo,
}: {
    additionalInfo: Message | null;
}) {
    const localize = makeLocalizedText(useOperatorLanguage());
    return (
        <TextField
            multiline
            disabled
            label={
                <Localized
                    de="Zusatzinformationen"
                    fr="Informations complémentaires"
                    it="Informazioni supplementari"
                    en="Additional information"
                />
            }
            value={additionalInfo ? localize(additionalInfo) : EmDash}
        />
    );
}

function OperatorParkingaboUsersProductsAddZoneDescription({
    zonesDescriptions,
}: {
    zonesDescriptions: string[];
}) {
    return (
        <TextField
            multiline
            disabled
            label={
                <Localized
                    de="Parkings"
                    fr="Parkings"
                    it="Parcheggi"
                    en="Parkings"
                />
            }
            value={
                zonesDescriptions.length > 0
                    ? zonesDescriptions.join('\n')
                    : EmDash
            }
        />
    );
}

function OperatorParkingaboUsersProductsAddRightColumn<
    TFieldValues extends FieldValues,
>({
    control,
    tenantRemarkField,
}: {
    control: Control<TFieldValues>;
    tenantRemarkField: Path<TFieldValues>;
}) {
    return (
        <FormRightColumn>
            <Controller
                name={tenantRemarkField}
                control={control}
                render={({ field }) => (
                    <TextField
                        multiline
                        minRows={3}
                        maxRows={3}
                        label={
                            <OperatorParkingaboUsersProductsTenantRemarkLabel />
                        }
                        value={field.value}
                        onChange={e => field.onChange(e)}
                    />
                )}
            />
        </FormRightColumn>
    );
}

export function OperatorParkingaboUsersProductsTenantRemarkLabel() {
    return (
        <>
            <Localized
                de="Interne Bemerkungen"
                fr="Remarques internes"
                it="Commenti interni"
                en="Internal comments"
            />
            <Tooltip
                title={
                    <Localized
                        de="Die interne Bemerkungen sind nur hier im Parkingportal sichtbar."
                        fr="Les remarques internes sont uniquement visibles ici dans Parkingportal."
                        it="Le osservazioni interne sono visibili unicamente qui in Parkingportal."
                        en="The internal remarks are only visible here in Parkingportal."
                    />
                }
                placement="right"
            >
                <InfoIcon
                    sx={{
                        color: theme => theme.palette.blue.main,
                        marginLeft: 1,
                    }}
                />
            </Tooltip>
        </>
    );
}

export interface ProductsAddTemplateItem {
    contractTemplateId: number;
    productConfig: ProductTemplateConfig;
    zonesDescriptions: string[];
}

enum Fields {
    startDate = 'startDate',
    tenantRemark = 'tenantRemark',
}

interface FormFields {
    [Fields.startDate]: string;
    [Fields.tenantRemark]: string;
}

enum ContractTemplateIdField {
    contractTemplateId = 'contractTemplateId',
}

interface ContractTemplateIdValue {
    [ContractTemplateIdField.contractTemplateId]: number;
}

interface CreateProductPayload extends FormFields {
    tenantId: string;
    customerNr: string;
    contractTemplateId: number;
}
