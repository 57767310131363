import moment from 'moment';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import * as ClearanceDetailActions from '../actions/ClearanceDetailActions.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { AdditionalInfoMode } from '../../common/state/PermitTypeState.ts';

export interface Clearance {
    id: number;

    validFrom: string;
    validTo: string;

    identificationType: 'BADGE' | 'LICENSE_PLATE';
    badgeNr: string;
    licensePlateNr: string;
    licensePlateId: number;
    licensePlateType: Maybe<LicensePlateType>;
    licensePlateCountry: Maybe<string>;
    permitTypeId: number;
    permitTypeName: string;

    contractNumber: string;
    personalNumber: string;
    infos: string;
    editDate: string;

    adminId: number;
    priceMultiplier: Maybe<number>;
    isActive: boolean;
    hasPermits: boolean;
    additionalInfoMode: AdditionalInfoMode;
}

export namespace Server {
    const sideEffects = (store: Flux.Store, state: State) => {
        const clearanceId = new ClearancePermitListState.StateSlice(store).state
            .selectedClearanceId;
        if (state.shouldFetch && isDefined(clearanceId)) {
            store.update(
                ClearanceDetailActions.receiveClearanceDetail,
                clearanceId,
            );
        }
    };

    export type State = ServerStateSlice.ServerState<Maybe<Clearance>>;

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<Maybe<Clearance>>(
            'clearance-detail-ClearanceEditState.Server',
            () => null,
            sideEffects,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (body: any): Clearance => body,
        );
}

export namespace Edit {
    export interface State {
        validFrom?: Maybe<moment.Moment>;
        validTo?: Maybe<moment.Moment>;
        contractNumber?: Maybe<string>;
        personalNumber?: Maybe<string>;
        infos?: Maybe<string>;
    }

    export const { get, set, reset, stateWrite } = Flux.generateState<State>(
        'clearance-edit-ClearanceEditState.Edit',
        {},
    );
}

export namespace EditResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'clearance-edit-ClearanceEditState.EditResponse',
        () => null,
    );
}

export namespace Delete {
    export interface State {
        deletePressed?: Maybe<boolean>;
    }

    export const { get, set, reset, stateWrite } = Flux.generateState<State>(
        'clearance-edit-ClearanceEditState.Delete',
        {
            deletePressed: false,
        },
    );
}

export namespace DeleteResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'clearance-edit-ClearanceEditState.DeleteResponse',
        () => null,
    );
}

export namespace Layout {
    export interface State {
        fromDatePickerOpen?: boolean;
        toDatePickerOpen?: boolean;
    }

    export const { get, reset, set, stateWrite } = Flux.generateState<State>(
        'clearance-edit-ClearanceEditState.Layout',
        {
            fromDatePickerOpen: false,
            toDatePickerOpen: false,
        },
    );
}

export const resetAllStates = (store: Flux.Store) => {
    Server.reset(store);
    Edit.reset(store);
    Layout.reset(store);
    EditResponse.reset(store);
};
