import * as Text from '../../../common/i18n/Text.ts';
import { emptyCollectionsFilterTexts } from '../../i18n/CollectionsTexts.ts';
import { Switch, SwitchType } from '../../../ui/switches/Switches.tsx';

export interface EmptyCollectionsFilterTexts {
    hideEmptyCollections: Text.Translation;
}

export interface EmptyCollectionsFilterSelectionProps {
    hideEmptyCollections: boolean;
    onChange: (newValue: boolean) => void;
    language: string;
}

export function EmptyCollectionsFilterSelection(
    props: EmptyCollectionsFilterSelectionProps,
): JSX.Element {
    const texts = emptyCollectionsFilterTexts[props.language];

    return (
        <div>
            <Switch
                label={texts.hideEmptyCollections()}
                selected={props.hideEmptyCollections}
                onClick={() => props.onChange(!props.hideEmptyCollections)}
                type={SwitchType.Checkbox}
            />
        </div>
    );
}
