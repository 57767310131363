import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Icon16 } from '../icons/Icon.tsx';
import {
    getOrElse,
    isDefined,
    isUndefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { range } from 'dg-web-shared/lib/ArrayUtil.ts';
import { iconButtonStyleParkingPortal, MonthPicker } from './MonthPicker.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import moment from 'moment';
import { css } from '@emotion/css';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { ColorHex } from '../Colors.ts';
import { OperatorTypo } from '../OperatorTypo.ts';
import React from 'react';

const Weekday = (p: { day: Message }): JSX.Element => (
    <div
        className={css({
            color: ColorHex.rgba(ColorHex.darkblue, 0.4),
            float: 'left',
            width: '40px',
            height: '40px',
            textAlign: 'center',
            paddingTop: '11px',
        })}
    >
        <Localized {...p.day} />
    </div>
);

const Weekdays = (): JSX.Element => (
    <div
        className={css({
            height: '40px',
            width: '100%',
            background: ColorHex.rgba(ColorHex.lightblue, 0.1),
            position: 'absolute',
            top: '40px',
            ...OperatorTypo.bodyC,
        })}
    >
        <Weekday day={{ de: 'M', fr: 'L', it: 'L', en: 'M' }} />
        <Weekday day={{ de: 'D', fr: 'M', it: 'M', en: 'T' }} />
        <Weekday day={{ de: 'M', fr: 'M', it: 'M', en: 'W' }} />
        <Weekday day={{ de: 'D', fr: 'J', it: 'G', en: 'T' }} />
        <Weekday day={{ de: 'F', fr: 'V', it: 'V', en: 'F' }} />
        <Weekday day={{ de: 'S', fr: 'S', it: 'S', en: 'S' }} />
        <Weekday day={{ de: 'S', fr: 'D', it: 'D', en: 'S' }} />
    </div>
);

interface MonthYearNavigationProps {
    visibleDate: moment.Moment;
    onPrev: Maybe<ClickHandler>;
    onNext: Maybe<ClickHandler>;
    onCurrent: ClickHandler;
    language: string;
}

const MonthYearNavigation = (p: MonthYearNavigationProps): JSX.Element => {
    return (
        <div
            className={css({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '40px',
                background: ColorHex.rgba(ColorHex.lightblue, 0.2),
                color: ColorHex.darkblue,
            })}
        >
            <Clickable
                element="div"
                className={iconButtonStyleParkingPortal(
                    isUndefined(p.onPrev),
                    'prev',
                )}
                onClick={p.onPrev}
                disabled={isUndefined(p.onPrev)}
            >
                <Icon16 icon={Icons16.chevronLeft} />
            </Clickable>
            <Clickable
                element="div"
                className={iconButtonStyleParkingPortal(
                    isUndefined(p.onNext),
                    'next',
                )}
                onClick={p.onNext}
                disabled={isUndefined(p.onNext)}
            >
                <Icon16 icon={Icons16.chevronRight} />
            </Clickable>
            <Clickable
                element="div"
                className={css([
                    {
                        margin: '0 40px',
                        textAlign: 'center',
                        height: '100%',
                        paddingTop: '11px',
                        ...OperatorTypo.bodyC,
                    },
                    !isUndefined(p.onCurrent) && {
                        '&:hover': {
                            background: ColorHex.lightblue,
                            color: ColorHex.white,
                        },
                    },
                ])}
                disabled={isUndefined(p.onCurrent)}
                onClick={p.onCurrent}
            >
                {Formatter.monthNameYear(
                    p.visibleDate,
                    Formatter.getLocaleFromString(p.language),
                )}
            </Clickable>
        </div>
    );
};

export type DateSelectHandler = (d: moment.Moment) => void;

interface CalendarElementProps {
    day: moment.Moment;
    onSelect: DateSelectHandler;
    currentDate: moment.Moment;
    selectedDate: Maybe<moment.Moment>;
    minDate: Maybe<moment.Moment>;
    maxDate: Maybe<moment.Moment>;
}

interface DayProps extends CalendarElementProps {
    invalid: boolean;
    inactive: boolean;
}

interface DayState {
    hover: boolean;
}

class Day extends React.Component<DayProps, DayState> {
    constructor(props: DayProps) {
        super(props);
        this.state = { hover: false };
    }

    getState(): string {
        if (this.props.invalid) {
            return 'invalid';
        } else if (this.props.inactive) {
            return 'inactive';
        } else {
            return 'active';
        }
    }

    render() {
        const current = this.props.day.isSame(this.props.currentDate, 'day');
        const selected = thenElse(
            this.props.selectedDate,
            d => this.props.day.isSame(d, 'day'),
            false,
        );
        return (
            <Clickable
                element="div"
                className={css([
                    {
                        ...OperatorTypo.body,
                        color: ColorHex.darkblue,
                        width: '32px',
                        height: '32px',
                        margin: '4px',
                        float: 'left',
                        textAlign: 'center',
                        borderRadius: '50%',
                        position: 'relative',
                        border: `2px solid ${ColorHex.rgba('#000', 0)}`,
                    },
                    this.getState() === 'active' && [
                        this.state.hover && {
                            color: ColorHex.white,
                            background: ColorHex.lightblue,
                        },
                        !selected && [
                            current && [
                                {
                                    borderColor: ColorHex.darkblue,
                                },
                                this.state.hover && {
                                    borderColor: ColorHex.lightblue,
                                },
                            ],
                        ],
                        selected && {
                            background: ColorHex.darkblue,
                            color: ColorHex.white,
                        },
                    ],
                ])}
                disabled={this.props.invalid || this.props.inactive}
                onClick={() => this.props.onSelect(this.props.day)}
                onMouseOver={() => this.setState({ hover: true })}
                onMouseOut={() => this.setState({ hover: false })}
            >
                <div
                    className={css([
                        {
                            paddingTop: '4px',
                            height: '100%',
                            width: '100%',
                            border: `2px solid ${ColorHex.rgba('#000', 0)}`,
                            borderRadius: '50%',
                        },
                        this.getState() === 'inactive' && {
                            color: ColorHex.rgba(ColorHex.darkblue, 0.4),
                        },
                        !this.props.invalid && [
                            current && [
                                this.state.hover && {
                                    borderColor: ColorHex.white,
                                },
                            ],
                            selected && [
                                { borderColor: ColorHex.darkblue },
                                current && { borderColor: ColorHex.white },
                            ],
                        ],
                    ])}
                >
                    {this.props.invalid ? '' : this.props.day.format('D')}
                </div>
            </Clickable>
        );
    }
}

interface WeekProps extends CalendarElementProps {
    month: moment.Moment;
}

const Week = (p: WeekProps): JSX.Element => {
    const { minDate, maxDate } = p;
    return (
        <div
            className={css({
                height: '40px',
                width: '100%',
                background: ColorHex.rgba(ColorHex.lightblue, 0.1),
            })}
        >
            {range(7).map((d, i) => {
                const day = p.day.clone().add(d, 'day');

                const outsideRange =
                    (isDefined(minDate) && day.isBefore(minDate)) ||
                    (isDefined(maxDate) &&
                        day.isAfter(maxDate.clone().endOf('day')));

                return (
                    <Day
                        {...p}
                        key={i}
                        day={day}
                        invalid={!day.isSame(p.month, 'month')}
                        inactive={outsideRange}
                    />
                );
            })}
        </div>
    );
};

type MonthProps = CalendarElementProps;

const Month = (p: MonthProps): JSX.Element => (
    <div
        className={css({
            position: 'absolute',
            top: '80px',
            bottom: 0,
            width: '100%',
        })}
    >
        {range(6)
            .map(w => p.day.clone().startOf('isoWeek').add(w, 'week'))
            .map((d, i) => (
                <Week {...p} key={i} day={d} month={p.day} />
            ))}
    </div>
);

export interface DatePickerProps {
    currentDate: moment.Moment;
    selectedDate: Maybe<moment.Moment>;
    onSelect: DateSelectHandler;
    minDate?: Maybe<moment.Moment>;
    maxDate?: Maybe<moment.Moment>;
    language: string;
}

interface DatePickerState {
    visibleDate?: moment.Moment;
    monthPickerVisible?: boolean;
}

export class DatePicker extends React.Component<
    DatePickerProps,
    DatePickerState
> {
    constructor(props: DatePickerProps) {
        super(props);
        this.state = {
            visibleDate: getOrElse(props.selectedDate, props.currentDate),
            monthPickerVisible: false,
        };
    }

    renderMonthPicker(): JSX.Element | null {
        if (!this.state.monthPickerVisible) {
            return null;
        }
        return (
            <div
                className={css({
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                })}
            >
                <MonthPicker
                    currentDate={moment()}
                    selectedDate={this.state.visibleDate}
                    onSelect={m =>
                        this.setState({
                            visibleDate: m,
                            monthPickerVisible: false,
                        })
                    }
                    onClose={() => this.setState({ monthPickerVisible: false })}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    language={this.props.language}
                />
            </div>
        );
    }

    getPrevMonthHandler(): Maybe<ClickHandler> {
        const { minDate } = this.props;
        if (
            isDefined(minDate) &&
            this.state.visibleDate &&
            this.state.visibleDate.clone().startOf('month').isBefore(minDate)
        ) {
            return null;
        } else {
            return () => {
                if (!this.state.visibleDate) {
                    return;
                }

                this.setState({
                    visibleDate: this.state.visibleDate
                        .clone()
                        .subtract(1, 'month'),
                });
            };
        }
    }

    getNextMonthHandler(): Maybe<ClickHandler> {
        const { maxDate } = this.props;
        if (
            isDefined(maxDate) &&
            this.state.visibleDate &&
            this.state.visibleDate.clone().endOf('month').isAfter(maxDate)
        ) {
            return null;
        } else {
            return () => {
                if (!this.state.visibleDate) {
                    return;
                }

                this.setState({
                    visibleDate: this.state.visibleDate.clone().add(1, 'month'),
                });
            };
        }
    }

    render() {
        return (
            <div
                className={css({
                    height: '320px',
                    width: '280px',
                    position: 'relative',
                    ...paper(3),
                })}
            >
                <MonthYearNavigation
                    visibleDate={this.state.visibleDate || moment()}
                    onPrev={this.getPrevMonthHandler()}
                    onNext={this.getNextMonthHandler()}
                    onCurrent={() =>
                        this.setState({ monthPickerVisible: true })
                    }
                    language={this.props.language}
                />
                <Weekdays />
                <Month
                    currentDate={this.props.currentDate}
                    selectedDate={this.props.selectedDate}
                    day={
                        this.state.visibleDate
                            ? this.state.visibleDate.clone().startOf('month')
                            : moment()
                    }
                    onSelect={this.props.onSelect}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                />
                {this.renderMonthPicker()}
            </div>
        );
    }
}
