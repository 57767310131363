import { Box, useTheme } from '@mui/material';

export function MaterialHeader({
    editMode,
    children,
}: {
    editMode: boolean;
    children: React.ReactNode | React.ReactNodeArray;
}) {
    const theme = useTheme();

    return (
        <Box
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: theme.spacing(1.875, 3),
                color: editMode ? theme.palette.common.white : undefined,
                backgroundColor: editMode
                    ? theme.palette.blue.electric
                    : undefined,
            }}
        >
            {children}
        </Box>
    );
}
