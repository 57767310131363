export const enum AppPurchaseVisibility {
    HIDDEN = 'HIDDEN',
    VISIBLE = 'VISIBLE',
}

export enum ProductAllowedPaymentMode {
    DIGITAL_PURCHASE_AND_COUNTER = 'DIGITAL_PURCHASE_AND_COUNTER',
    DIGITAL_PURCHASE = 'DIGITAL_PURCHASE',
    COUNTER = 'COUNTER',
}

export const enum AppPurchaseMode {
    ALL = 'ALL',
    NONE = 'NONE',
}
