import { getOrElse, isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import { ButtonSpecialState } from '../buttons/IconButton.tsx';
import {
    PrimaryActionButton,
    SecondaryActionButton,
} from '../buttons/TextButton.tsx';
import { Color, ColorHex } from '../Colors.ts';
import { Mousetrap } from '../layout/Mousetrap.tsx';
import { LoaderBar } from '../loaders/LoaderBar.tsx';
import {
    BackgroundOpacity,
    SlideInBodyContainer,
    SlideInHeaderButtonsContainer,
    SlideInHeaderClose,
    SlideInHeaderContainer,
    SlideInHeaderLoaderContainer,
    SlideInHeaderSubtitle,
    SlideInHeaderTitle,
    SlideInHeaderTitleContainer,
    SlideInHeaderTitleIcon,
} from './SlideInHeader2.tsx';
import * as SlideinTexts from './SlideinTexts.ts';
import { defaultTransition } from 'dg-web-shared/tb-ui/transitions.ts';
import { css } from '@emotion/css';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import styled from '@emotion/styled';

export * from './SlideInHeader2.tsx';

export enum LeftActionIcon {
    chevronRight,
    info,
    add,
    edit,
    error,
    replace,
}

interface SlideInProps {
    open: boolean;
    disabled?: boolean;
    outsideBody?: boolean;
    children?: React.ReactNode;
}

export const FullSlideIn = (p: SlideInProps): JSX.Element => (
    <div
        className={css([
            {
                ...defaultTransition('transform opacity'),
                position: 'absolute',
                bottom: 0,
                top: p.outsideBody ? '2px' : '-4px',
                background: ColorHex.white,
                transform: 'translateX(110%)',
                opacity: 0,
                ...paper(5),
                left: 0,
                width: '100%',
            },
            p.open && {
                transform: 'translateX(0)',
                opacity: 1,
            },
            p.outsideBody && {
                left: '20px',
                width: 'calc(100% - 40px)',
                bottom: '22px',
            },
        ])}
    >
        {p.open ? p.children : null}
        <Conditional c={p.disabled || false}>
            <div
                className={css({
                    position: 'absolute',
                    left: 0,
                    top: p.outsideBody ? '-4px' : 0,
                    bottom: 0,
                    width: '100%',
                    background: ColorHex.rgba(ColorHex.white, 0.7),
                })}
            />
        </Conditional>
    </div>
);

export const HalfSlideIn = (p: SlideInProps): JSX.Element => (
    <div
        className={css([
            {
                ...defaultTransition('transform opacity'),
                position: 'absolute',
                bottom: 0,
                top: p.outsideBody ? '2px' : '-4px',
                background: ColorHex.white,
                transform: 'translateX(110%)',
                opacity: 0,
                ...paper(5),
                width: '50%',
                right: 0,
            },
            p.open && {
                transform: 'translateX(0)',
                opacity: 1,
            },
            p.outsideBody && {
                right: '20px',
                width: 'calc(50% - %s) % 40px',
                bottom: '22px',
            },
        ])}
        data-open={p.open}
        data-outside-body={p.outsideBody || false}
    >
        {p.open ? p.children : null}
    </div>
);

interface SlideInHeaderProps {
    backgroundColor: Color;
    backgroundOpacity?: Maybe<BackgroundOpacity>;
    children?: React.ReactNode;
    onClose?: Maybe<ClickHandler>;
}

export const SlideInHeader = (p: SlideInHeaderProps): JSX.Element => (
    <SlideInHeaderContainer
        borderBottomSize="small"
        borderBottomColor={Color.darkblue}
    >
        <SlideInBodyContainer
            backgroundColor={p.backgroundColor}
            backgroundOpacity={getOrElse(p.backgroundOpacity, 0.1)}
        >
            {p.children}
        </SlideInBodyContainer>
        <SlideInHeaderClose onClose={p.onClose} />
    </SlideInHeaderContainer>
);

export interface SlideInHeaderTextsProps {
    subtitle?: React.ReactNode;
    title: React.ReactNode;
    hasLeftIcon: boolean;
    color?: Color;
}

export const SlideInHeaderTexts = (p: SlideInHeaderTextsProps): JSX.Element => (
    <SlideInHeaderTitleContainer
        color={getOrElse(p.color, Color.darkblue)}
        hasLeftIcon={p.hasLeftIcon}
    >
        <SlideInHeaderTitle title={p.title} />
        <Conditional c={!!p.subtitle && p.subtitle !== p.title}>
            <SlideInHeaderSubtitle subtitle={p.subtitle || ''} />
        </Conditional>
    </SlideInHeaderTitleContainer>
);

export const SlideInHeaderIcon = SlideInHeaderTitleIcon;

export const FullSlideInLeftColumn = (p: {
    children?: React.ReactNode;
}): JSX.Element => {
    return (
        <div
            className={css({
                position: 'relative',
                height: '100%',
                width: '50%',
                float: 'left',
                paddingTop: '16px',
                paddingRight: '48px',
                '&::after': {
                    position: 'absolute',
                    content: '" "',
                    right: '0px',
                    top: '32px',
                    bottom: '32px',
                    width: '1px',
                    background: ColorHex.rgba(ColorHex.darkblue, 0.25),
                },
            })}
        >
            {p.children}{' '}
        </div>
    );
};

export const FullSlideInRightColumn = (p: {
    children?: React.ReactNode;
}): JSX.Element => {
    return (
        <div
            className={css({
                position: 'relative',
                height: '100%',
                width: '50%',
                float: 'left',
                paddingTop: '16px',
                paddingLeft: '47px',
            })}
        >
            {p.children}{' '}
        </div>
    );
};

interface SlideInBodyProps {
    disabled?: boolean;
    children?: React.ReactNode;
    hasSubheader?: boolean;
}

export const SlideInBody = (p: SlideInBodyProps): JSX.Element => (
    <div
        className={css({
            position: 'absolute',
            top: p.hasSubheader ? '104px' : '63px',
            bottom: 0,
            width: '100%',
            padding: '16px 48px 0px',
            overflowY: 'auto',
        })}
        data-has-subheader={p.hasSubheader || false}
    >
        {p.children}
        <Mousetrap enabled={p.disabled || false} />
    </div>
);

export const SlideInInfoBox = styled.div({
    fontSize: '15px',
    fontWeight: '500',
    backgroundColor: 'orange',
    color: 'white',
    padding: '8px 12px',
    marginBottom: '8px',
});

export interface ConfirmationHeaderTexts {
    cancel: Translation;
    confirm: Translation;
    save: Translation;
}

interface ConfirmationHeaderProps {
    language: string;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    onCancel: () => void;
    onConfirm: () => void;
    confirmButtonSpecialState?: Maybe<ButtonSpecialState>;
    confirmText?: Maybe<string>;
}

export const ConfirmationHeader = (p: ConfirmationHeaderProps) => (
    <SlideInHeaderContainer
        borderBottomSize="large"
        borderBottomColor={Color.darkblue}
    >
        <SlideInBodyContainer backgroundColor={Color.lightblue}>
            <SlideInHeaderTexts
                subtitle={p.subtitle}
                title={p.title}
                hasLeftIcon={false}
                color={Color.white}
            />
            <SlideInHeaderButtonsContainer>
                <SecondaryActionButton
                    label={SlideinTexts.confirmationHeaderTexts[
                        p.language
                    ].cancel()}
                    onClick={p.onCancel}
                />
                <PrimaryActionButton
                    label={getOrElse(
                        p.confirmText,
                        SlideinTexts.confirmationHeaderTexts[
                            p.language
                        ].confirm(),
                    )}
                    onClick={p.onConfirm}
                    error={
                        p.confirmButtonSpecialState === ButtonSpecialState.ERROR
                    }
                    faded={
                        p.confirmButtonSpecialState ===
                        ButtonSpecialState.DISABLED
                    }
                />
            </SlideInHeaderButtonsContainer>
        </SlideInBodyContainer>
    </SlideInHeaderContainer>
);

interface ConfirmSaveHeaderProps {
    language: string;
    title: string | JSX.Element;
    subtitle?: string;
    onCancel: () => void;
    onSave: () => void;
    confirmButtonSpecialState?: Maybe<ButtonSpecialState>;
}

export const ConfirmSaveHeader = (p: ConfirmSaveHeaderProps) => (
    <ConfirmationHeader
        confirmText={SlideinTexts.confirmationHeaderTexts[p.language].save()}
        onConfirm={p.onSave}
        {...p}
    />
);

export interface ErrorHeaderTexts {
    back: Translation;
}

interface ErrorHeaderProps {
    title: string | JSX.Element;
    subtitle?: string;
    onCancel: () => void;
    language: string;
}

export const ErrorHeader = (p: ErrorHeaderProps) => {
    return (
        <SlideInHeaderContainer
            borderBottomSize="large"
            borderBottomColor={Color.error}
        >
            <SlideInBodyContainer
                backgroundColor={Color.error}
                backgroundOpacity={0.1}
            >
                <SlideInHeaderTexts
                    subtitle={p.subtitle}
                    title={p.title}
                    hasLeftIcon={false}
                />
                <SlideInHeaderButtonsContainer>
                    <PrimaryActionButton
                        label={SlideinTexts.errorHeaderTexts[p.language].back()}
                        onClick={p.onCancel}
                    />
                </SlideInHeaderButtonsContainer>
            </SlideInBodyContainer>
        </SlideInHeaderContainer>
    );
};

interface LoaderHeaderProps {
    title?: Maybe<string | JSX.Element>;
}

export const LoaderHeader = (p: LoaderHeaderProps) => {
    return (
        <SlideInHeaderContainer>
            <SlideInBodyContainer
                backgroundColor={Color.darkblue}
                backgroundOpacity={0.1}
            >
                <SlideInHeaderTexts
                    title={getOrElse(p.title, '')}
                    hasLeftIcon={false}
                />
                <SlideInHeaderLoaderContainer>
                    <LoaderBar />
                </SlideInHeaderLoaderContainer>
            </SlideInBodyContainer>
        </SlideInHeaderContainer>
    );
};

interface LevelHeaderProps {
    children?: React.ReactNode;
    onClose: () => void;
    title?: React.ReactNode;
}

const LevelHeaderTitle = (p: LevelHeaderProps) => (
    <Conditional c={isDefined(p.title)}>
        <SlideInHeaderTexts title={p.title} hasLeftIcon={false} />
    </Conditional>
);

export const SecondLevelHeader = (p: LevelHeaderProps) => (
    <SlideInHeaderContainer
        borderBottomSize="small"
        borderBottomColor={Color.darkblue}
    >
        <SlideInBodyContainer
            backgroundColor={Color.white}
            backgroundOpacity={0.1}
        >
            <LevelHeaderTitle {...p} />
            {p.children}
        </SlideInBodyContainer>
        <SlideInHeaderClose onClose={p.onClose} />
    </SlideInHeaderContainer>
);
export const StandardFirstLevelHeader = (p: LevelHeaderProps) => (
    <SlideInHeaderContainer
        borderBottomSize="small"
        borderBottomColor={Color.darkblue}
    >
        <SlideInBodyContainer
            backgroundColor={Color.darkblue}
            backgroundOpacity={0.1}
        >
            <LevelHeaderTitle {...p} />
            {p.children}
        </SlideInBodyContainer>
        <SlideInHeaderClose onClose={p.onClose} />
    </SlideInHeaderContainer>
);
