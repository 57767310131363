import { BaseAbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import { Maybe, thenElse, getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { css, keyframes } from '@emotion/css';
import { ColorHex } from '../Colors.ts';

const containerLoaderBar = keyframes`
0% {
    left: -38.2%
}
100% {
    left: 100%
}
`;

interface ContainerLoaderBarProps {
    loading?: Maybe<boolean>;
    stateSlices?: Maybe<BaseAbstractLegacyServerState[]>;
}
export function ContainerLoaderBar(
    props: ContainerLoaderBarProps,
): JSX.Element {
    const loadsPending: boolean = thenElse<
        BaseAbstractLegacyServerState[],
        boolean
    >(
        props.stateSlices,
        (slices: BaseAbstractLegacyServerState[]) =>
            slices.some(
                (slice: BaseAbstractLegacyServerState) => slice.pending,
            ),
        false,
    );
    const loading = getOrElse(props.loading, false) || loadsPending;

    return (
        <div
            className={css({
                width: '100%',
                height: 4,
                background: ColorHex.darkblue,
                position: 'absolute',
                overflow: 'hidden',
                top: -4,
                display: loading ? undefined : 'none',
            })}
        >
            <div
                className={css({
                    position: 'absolute',
                    height: '100%',
                    width: '38.2%',
                    background: ColorHex.lightblue,
                    animation: `${containerLoaderBar} 1.7s infinite linear`,
                })}
            />
        </div>
    );
}
