import moment from 'moment';

import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import {
    getOrElse,
    isDefined,
    isUndefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { rfidCardErrorText } from 'dg-web-shared/lib/RfidCardValidation.ts';
import * as StringConversions from 'dg-web-shared/lib/StringConversions.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';
import { LicensePlateTrippleEntry } from '../../common/components/LicensePlateTrippleEntry.tsx';
import * as GeneralTexts from '../../common/i18n/GeneralTexts.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitCreateState from '../../permit-detail/state/PermitCreateState.ts';
import * as PermitEditState from '../../permit-detail/state/PermitEditState.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import {
    Notification,
    NotificationBodyList,
    NotificationColor,
    NotificationIcon,
} from '../../ui/notifications/Notification.tsx';
import {
    ConfirmSaveHeader,
    ErrorHeader,
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    LoaderHeader,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import * as ClearanceCreateTexts from '../i18n/ClearanceCreateTexts.ts';
import * as ClearanceCreateState from '../state/ClearanceCreateState.ts';
import {
    AdditionalInfos,
    ContractNumber,
    FromDateSelection,
    PersonalNumber,
    ToDateSelection,
} from './Shared.tsx';
import { languageFromString } from 'dg-web-shared/lib/Text.ts';

type ClearanceCreateDTO = Http.OperatorAccount.Clearances.ClearanceCreateDTO;

interface State {
    settings: SettingsState.State;
    layout: ClearanceCreateState.Layout.State;
    create: ClearanceCreateState.Create.State;
    createResponse: ClearanceCreateState.CreateResponse.State;
    validation: ClearanceCreateState.Validation.State;
    permitTypes: PermitTypeState.State;
    operator: OperatorDataState.State;
    carType: ClearanceCreateState.CarType.State;
}

export interface Texts {
    HeaderCaption: Translation;
    LicensePlateType: Translation;
    Country: Translation;
    LicensePlate: Translation;
    Badge: Translation;
    CarType: Translation;
    MotorcycleType: Translation;
    LicensePlateAlreadyHasClearance: Translation;
    PermitType: Translation;
    CreateError: Translation;
    PriceMultiplierError: Translation;
    PriceMultiplier: Translation;
    CarTypeDescription: Translation;
}

namespace Derived {
    type MaybeDTO = Maybe<ClearanceCreateDTO>;

    export interface CreateRequest {
        bodyDisabled: boolean;
        formErrorsToShow: ClearanceCreateState.FormErrors;
        missingPriceMultipliersToShow: number[];
        overlaps: ClearanceCreateState.Overlaps;
        payload: MaybeDTO;
        confirmButtonSpecialState: Maybe<ButtonSpecialState>;
        requestReturnedError: boolean;
        requestIsPending: boolean;
        showLpEntry: boolean;
        showBadgeEntry: boolean;
    }

    const getButtonSpecialState = (
        state: State,
        payload: MaybeDTO,
    ): ButtonSpecialState | null => {
        if (isDefined(payload)) {
            return null;
        } else {
            return state.layout.confirmPressedWhileError
                ? ButtonSpecialState.ERROR
                : ButtonSpecialState.DISABLED;
        }
    };

    export const get = (state: State): CreateRequest => {
        const operatorData = state.operator.data;
        const validation = state.validation;
        const unvalidated = ClearanceCreateState.makeUnvalidatedPayload(
            state.create,
            operatorData ? operatorData.licensePlatePermitSettings : null,
            state.permitTypes.data,
            state.createResponse,
            validation,
        );
        const identMandant = PermitTypeState.getIdentificationTypes(
            state.permitTypes.data,
        );
        const identSelected =
            PermitTypeState.getIdentificationTypesGivenSelection(
                state.permitTypes.data,
                state.create.permitTypes,
            );
        const showLpEntry =
            (identMandant.length === 1 &&
                identMandant.indexOf('LicensePlate') > -1) ||
            identSelected.indexOf('LicensePlate') > -1;

        const data = validation.data;
        if (isDefined(data)) {
            const hasValidationErrors =
                data.overlappingClearances.lp.length > 0 ||
                data.overlappingClearances.badge.length > 0 ||
                data.permitTypesWithMissingPriceMultipliers.length > 0 ||
                (showLpEntry && !state.create.licensePlateValid);
            if (hasValidationErrors) {
                unvalidated.payload = null;
            }
        } else {
            unvalidated.payload = null;
        }

        return {
            bodyDisabled:
                state.layout.lpCountrySelectionOpen ||
                state.layout.permitTypeSelectionOpen ||
                state.layout.validFromDatePickerOpen ||
                state.layout.validToDatePickerOpen ||
                isDefined(state.layout.priceMultiplierOfPermitTypeOpen),
            formErrorsToShow: state.layout.confirmPressedWhileError
                ? unvalidated.formErrors
                : { lp: [], badge: [], other: [] },
            overlaps: thenElse(validation.data, d => d.overlappingClearances, {
                lp: [],
                badge: [],
            }),
            missingPriceMultipliersToShow:
                state.layout.confirmPressedWhileError && validation.data
                    ? validation.data.permitTypesWithMissingPriceMultipliers
                    : [],
            payload: unvalidated.payload,
            confirmButtonSpecialState: getButtonSpecialState(
                state,
                unvalidated.payload,
            ),
            requestIsPending: state.createResponse.pending,
            requestReturnedError: state.createResponse.statusCode.cls.error,
            showLpEntry,
            showBadgeEntry:
                (identMandant.length === 1 &&
                    identMandant.indexOf('Badge') > -1) ||
                identSelected.indexOf('Badge') > -1,
        };
    };
}

export const createClearance = AsyncRequest.request(
    Http.OperatorAccount.Clearances.create,
    (store: Flux.Store, res: Response): string => {
        ClearanceCreateState.CreateResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            ClearanceCreateState.resetAllStates(store);
            PermitEditState.Validation.reset(store);
            PermitCreateState.Calc.reset(store);
            ResultState.refetchSameContext(store, true);
        }
        return 'ClearanceDetailActions-createClearance';
    },
);

// hack to avoid conflicts with Badge Feature
const getDefaultDate = (
    permitTypes: Maybe<PermitTypeState.PermitType[]>,
    startDate: moment.Moment,
): moment.Moment | null => {
    if (
        isDefined(permitTypes) &&
        permitTypes.length > 0 &&
        permitTypes[0].operatorId === 113
    ) {
        return startDate.clone().add(1, 'year').subtract(1, 'day');
    }
    return null;
};

export const Header = (p: {
    state: State;
    update: Flux.Updater;
    texts: Texts;
}) => {
    const createRequest = Derived.get(p.state);
    const cancel = () =>
        p.update((store: Flux.Store): string => {
            ClearanceCreateState.resetAllStates(store);
            return 'ClearanceCreate-cancel';
        });

    if (createRequest.requestIsPending) {
        return <LoaderHeader />;
    } else if (createRequest.requestReturnedError) {
        return (
            <ErrorHeader
                language={p.state.settings.language}
                title={p.texts.CreateError()}
                onCancel={cancel}
            />
        );
    } else {
        return (
            <ConfirmSaveHeader
                language={p.state.settings.language}
                subtitle={p.texts.HeaderCaption()}
                title={p.texts.HeaderCaption()}
                onCancel={cancel}
                confirmButtonSpecialState={
                    createRequest.confirmButtonSpecialState
                }
                onSave={() => {
                    if (isDefined(createRequest.payload)) {
                        if (isUndefined(createRequest.payload.validTo)) {
                            createRequest.payload.validTo = getDefaultDate(
                                p.state.permitTypes.data,
                                createRequest.payload.validFrom,
                            );
                        }
                        p.update(createClearance, createRequest.payload);
                    } else if (
                        createRequest.confirmButtonSpecialState ===
                        ButtonSpecialState.DISABLED
                    ) {
                        p.update(store =>
                            ClearanceCreateState.Layout.stateWrite(store, {
                                confirmPressedWhileError: true,
                            }),
                        );
                    }
                }}
            />
        );
    }
};

interface OverlapNotificationProps {
    createRequest: Derived.CreateRequest;
    language: string;
    overlaps: ClearanceCreateState.Overlap[];
}

export const OverlapNotification = (p: OverlapNotificationProps) => {
    const texts = ClearanceCreateTexts.texts[p.language];

    if (p.overlaps.length > 0) {
        return (
            <Notification
                icon={NotificationIcon.error}
                color={NotificationColor.error}
                title={texts.LicensePlateAlreadyHasClearance()}
            >
                <NotificationBodyList>
                    {p.overlaps.map(v => (
                        <OverlapEntity
                            entity={v}
                            lang={p.language}
                            key={v.id}
                        />
                    ))}
                </NotificationBodyList>
            </Notification>
        );
    } else {
        return null;
    }
};

export const OverlapEntity = (p: {
    entity: ClearanceCreateState.Overlap;
    lang: string;
}): JSX.Element => {
    return (
        <li>
            <span>{p.entity.description + ' '}</span>
            <span>
                {`(${Formatter.dayMonthYear(p.entity.validFrom)}` +
                    ` - ${Formatter.formatPermitToDateWithIndefinite(
                        p.entity.validTo,
                    )})`}
            </span>
        </li>
    );
};

const badgeError = (state: State): Maybe<string> => {
    const createRequest = Derived.get(state);
    if (createRequest.formErrorsToShow.other.indexOf('badgeEmpty') > -1) {
        return GeneralTexts.general[state.settings.language].MandatoryField();
    } else if (createRequest.formErrorsToShow.badge.length > 0) {
        return rfidCardErrorText(
            state.create.badgeLabelNr,
            languageFromString(state.settings.language),
        );
    }
};

class ClearanceCreateContainer extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ClearanceCreateState.Layout.get(this.props.allState),
            create: ClearanceCreateState.Create.get(this.props.allState),
            permitTypes: PermitTypeState.get(this.props.allState),
            createResponse: ClearanceCreateState.CreateResponse.get(
                this.props.allState,
            ),
            validation: ClearanceCreateState.Validation.get(
                this.props.allState,
            ),
            operator: OperatorDataState.get(this.props.allState),
            carType: ClearanceCreateState.CarType.get(this.props.allState),
        };
    }

    getCreateRequest(): Derived.CreateRequest {
        return Derived.get(this.state);
    }
}

class LicensePlateEntry extends ClearanceCreateContainer {
    render() {
        if (this.getCreateRequest().showLpEntry) {
            const txts =
                ClearanceCreateTexts.texts[this.state.settings.language];
            return (
                <div>
                    <LicensePlateTrippleEntry
                        language={this.state.settings.language}
                        onLicensePlateNumberChange={(
                            v: string,
                            formValid: boolean,
                        ) => {
                            this.props.allState.update(store =>
                                ClearanceCreateState.Create.stateWrite(store, {
                                    licensePlateNumber: v,
                                    licensePlateValid: formValid,
                                }),
                            );
                        }}
                        onCountrySlideInToggle={() =>
                            this.props.allState.update(store =>
                                ClearanceCreateState.Layout.stateWrite(store, {
                                    lpCountrySelectionOpen: true,
                                }),
                            )
                        }
                        onTypeSelect={(type: LicensePlateType) =>
                            this.props.allState.update(store =>
                                ClearanceCreateState.Create.stateWrite(store, {
                                    type,
                                }),
                            )
                        }
                        licensePlateType={this.state.create.type}
                        licensePlateNr={this.state.create.licensePlateNumber}
                        country={this.state.create.country}
                        showErrors={this.state.layout.confirmPressedWhileError}
                        outsideLicensePlateNrErrorGen={null}
                    />
                    <OverlapNotification
                        overlaps={Derived.get(this.state).overlaps.lp}
                        language={this.state.settings.language}
                        createRequest={Derived.get(this.state)}
                    />
                    <Conditional
                        c={
                            (this.state.operator.data &&
                                this.state.operator.data.settings
                                    .carTypeDescription) ||
                            false
                        }
                    >
                        <div>
                            <TextField
                                inputType="text"
                                label={txts.CarTypeDescription()}
                                value={getOrElse<string>(
                                    this.state.create.carTypeDescriptions[0],
                                    this.state.carType.data &&
                                        this.state.carType.data.carTypes
                                            .length > 0
                                        ? this.state.carType.data.carTypes[0]
                                        : '',
                                )}
                                saveable={false}
                                maxLength={50}
                                onChange={(v: string) =>
                                    this.props.allState.update(store =>
                                        ClearanceCreateState.Create.setCarTypeDescription(
                                            store,
                                            { value: v, second: false },
                                        ),
                                    )
                                }
                            />
                            <Conditional
                                c={
                                    this.state.create.carTypeDescriptions
                                        .length > 1
                                }
                            >
                                <TextField
                                    inputType="text"
                                    label={txts.CarTypeDescription() + ' 2'}
                                    value={getOrElse<string>(
                                        this.state.create
                                            .carTypeDescriptions[1],
                                        this.state.carType.data &&
                                            this.state.carType.data.carTypes
                                                .length > 0
                                            ? this.state.carType.data
                                                  .carTypes[1]
                                            : '',
                                    )}
                                    saveable={false}
                                    onChange={(v: string) =>
                                        this.props.allState.update(store =>
                                            ClearanceCreateState.Create.setCarTypeDescription(
                                                store,
                                                { value: v, second: true },
                                            ),
                                        )
                                    }
                                />
                            </Conditional>
                        </div>
                    </Conditional>
                </div>
            );
        } else {
            return null;
        }
    }
}

class BadgeEntry extends ClearanceCreateContainer {
    render() {
        if (this.getCreateRequest().showBadgeEntry) {
            const txts =
                ClearanceCreateTexts.texts[this.state.settings.language];
            return (
                <div>
                    <TextField
                        inputType="text"
                        label={txts.Badge()}
                        value={getOrElse<string>(
                            this.state.create.badgeLabelNr,
                            '',
                        )}
                        saveable={false}
                        onChange={(v: string) =>
                            this.props.allState.update(store =>
                                ClearanceCreateState.Create.stateWrite(store, {
                                    badgeLabelNr:
                                        StringConversions.stripNonNumericCharacters(
                                            v,
                                        ),
                                }),
                            )
                        }
                        errorText={badgeError(this.state)}
                    />
                    <OverlapNotification
                        overlaps={Derived.get(this.state).overlaps.badge}
                        language={this.state.settings.language}
                        createRequest={Derived.get(this.state)}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
}

class TypeSpecificEntries extends ClearanceCreateContainer {
    render() {
        const permitWhitelistInfoPerPermittype =
            this.state.operator.data &&
            this.state.operator.data.licensePlatePermitSettings
                .permitWhitelistInfoPerPermittype;
        const permitTypes = this.state.permitTypes.data;
        const missingMultipliers = Derived.get(
            this.state,
        ).missingPriceMultipliersToShow;
        const txt = ClearanceCreateTexts.texts[this.state.settings.language];
        if (!permitTypes) {
            return null;
        } else {
            const typesWithFactor = permitTypes.filter(
                pt =>
                    isDefined(
                        pt.allowedPriceModifiers &&
                            pt.allowedPriceModifiers.length > 0,
                    ) && this.state.create.permitTypes.indexOf(pt.id) > -1,
            );
            if (
                typesWithFactor.length === 0 &&
                !permitWhitelistInfoPerPermittype
            ) {
                return null;
            } else {
                return (
                    <div>
                        {permitTypes
                            .filter(
                                pt =>
                                    this.state.create.permitTypes.indexOf(
                                        pt.id,
                                    ) > -1,
                            )
                            .map(pt => {
                                const multiplier: Maybe<number> =
                                    this.state.create.priceModifierIds[pt.id];
                                return (
                                    <div key={pt.id}>
                                        <Conditional
                                            c={
                                                pt.allowedPriceModifiers &&
                                                pt.allowedPriceModifiers
                                                    .length > 0
                                            }
                                        >
                                            <SingleSelection
                                                key={pt.id + 'pm'}
                                                focused={false}
                                                label={`${
                                                    pt.description
                                                } ${txt.PriceMultiplier()}`}
                                                onClick={() =>
                                                    this.update(store =>
                                                        ClearanceCreateState.Layout.stateWrite(
                                                            store,
                                                            {
                                                                priceMultiplierOfPermitTypeOpen:
                                                                    pt.id,
                                                            },
                                                        ),
                                                    )
                                                }
                                                selection={
                                                    isDefined(multiplier)
                                                        ? pt.allowedPriceModifiers.filter(
                                                              m =>
                                                                  m.priceModifierId ===
                                                                  multiplier,
                                                          )[0].label
                                                        : null
                                                }
                                                errorText={
                                                    missingMultipliers.indexOf(
                                                        pt.id,
                                                    ) > -1
                                                        ? txt.PriceMultiplierError()
                                                        : null
                                                }
                                            />
                                        </Conditional>
                                        <Conditional
                                            c={
                                                permitWhitelistInfoPerPermittype ||
                                                false
                                            }
                                        >
                                            <AdditionalInfos
                                                allState={this.props.allState}
                                                mode={pt.additionalInfoMode}
                                                onChange={(v: string) =>
                                                    this.update(store =>
                                                        ClearanceCreateState.Create.setAdditionalInfo(
                                                            store,
                                                            {
                                                                info: v,
                                                                permitTypeId:
                                                                    pt.id,
                                                            },
                                                        ),
                                                    )
                                                }
                                                value={
                                                    pt.additionalInfoMode ===
                                                    'show'
                                                        ? pt.addInfo || ''
                                                        : this.state.create
                                                              .infos[pt.id] ||
                                                          ''
                                                }
                                                headerPrefix={pt.description}
                                                disabled={false}
                                            />
                                        </Conditional>
                                    </div>
                                );
                            })}
                    </div>
                );
            }
        }
    }
}

export class ClearanceCreateSlideIn extends ClearanceCreateContainer {
    static displayName = 'ClearanceCreateSlideIn';

    txt(): Texts {
        return ClearanceCreateTexts.texts[this.state.settings.language];
    }

    generalTxt(): GeneralTexts.Texts {
        return GeneralTexts.general[this.state.settings.language];
    }

    permitTypesError(): Maybe<string> {
        if (
            this.getCreateRequest().formErrorsToShow.other.indexOf(
                'noPermitTypes',
            ) > -1
        ) {
            return this.generalTxt().MandatoryField();
        } else {
            return null;
        }
    }

    renderSelectedPermitTypes() {
        if (this.state.create.permitTypes.length === 0) {
            return null;
        } else {
            return (
                <div>
                    {this.state.create.permitTypes.map((id: number) => {
                        const type = PermitTypeState.getById(
                            this.props.allState,
                            id,
                        );
                        if (isDefined(type)) {
                            return <div key={type.id}>{type.description}</div>;
                        } else {
                            return null;
                        }
                    })}
                </div>
            );
        }
    }

    render() {
        const permitWhitelistInfoPerPermittype =
            this.state.operator.data &&
            this.state.operator.data.licensePlatePermitSettings
                .permitWhitelistInfoPerPermittype;
        const additionalInfoMode: PermitTypeState.AdditionalInfoMode =
            this.state.permitTypes.data.filter(
                pt =>
                    this.state.create.permitTypes.indexOf(pt.id) > -1 &&
                    pt.additionalInfoMode === 'edit',
            ).length > 0
                ? 'edit'
                : 'show';
        return (
            <FullSlideIn open={this.state.layout.createEnabled} outsideBody>
                <SlideInBody disabled={this.getCreateRequest().bodyDisabled}>
                    <FullSlideInLeftColumn>
                        <FromDateSelection
                            focused={false}
                            language={this.state.settings.language}
                            onClick={() =>
                                this.update(store =>
                                    ClearanceCreateState.Layout.stateWrite(
                                        store,
                                        { validFromDatePickerOpen: true },
                                    ),
                                )
                            }
                            validFrom={this.state.create.validFrom}
                        />
                        <ToDateSelection
                            focused={false}
                            language={this.state.settings.language}
                            onClick={() =>
                                this.update(store =>
                                    ClearanceCreateState.Layout.stateWrite(
                                        store,
                                        { validToDatePickerOpen: true },
                                    ),
                                )
                            }
                            onClear={
                                isDefined(this.state.create.validTo)
                                    ? () =>
                                          this.update(store =>
                                              ClearanceCreateState.Create.stateWrite(
                                                  store,
                                                  { validTo: null },
                                              ),
                                          )
                                    : null
                            }
                            validTo={thenElse(
                                this.state.create.validTo,
                                v => v,
                                getDefaultDate(
                                    this.state.permitTypes.data,
                                    this.state.create.validFrom,
                                ),
                            )}
                        />
                        <SingleSelection
                            focused={false}
                            label={this.txt().PermitType()}
                            selection={this.renderSelectedPermitTypes()}
                            onClick={() =>
                                this.update(store =>
                                    ClearanceCreateState.Layout.stateWrite(
                                        store,
                                        { permitTypeSelectionOpen: true },
                                    ),
                                )
                            }
                            errorText={this.permitTypesError()}
                        />
                        <PersonalNumber
                            allState={this.props.allState}
                            onChange={(v: string) =>
                                this.update(store =>
                                    ClearanceCreateState.Create.stateWrite(
                                        store,
                                        { personalNumber: v },
                                    ),
                                )
                            }
                            value={this.state.create.personalNumber || ''}
                        />
                        <ContractNumber
                            allState={this.props.allState}
                            onChange={(v: string) =>
                                this.update(store =>
                                    ClearanceCreateState.Create.stateWrite(
                                        store,
                                        { contractNumber: v },
                                    ),
                                )
                            }
                            value={this.state.create.contractNumber || ''}
                        />
                        <Conditional
                            c={
                                !permitWhitelistInfoPerPermittype &&
                                additionalInfoMode === 'edit'
                            }
                        >
                            <AdditionalInfos
                                allState={this.props.allState}
                                onChange={(v: string) =>
                                    this.update(store =>
                                        ClearanceCreateState.Create.setAdditionalInfo(
                                            store,
                                            { info: v },
                                        ),
                                    )
                                }
                                mode="edit"
                                value={this.state.create.infos[0] || ''}
                                disabled={false}
                            />
                        </Conditional>
                        <TypeSpecificEntries allState={this.props.allState} />
                    </FullSlideInLeftColumn>
                    <FullSlideInRightColumn>
                        <LicensePlateEntry allState={this.props.allState} />
                        <BadgeEntry allState={this.props.allState} />
                    </FullSlideInRightColumn>
                </SlideInBody>
                <Header
                    state={this.state}
                    update={this.props.allState.update}
                    texts={this.txt()}
                />
            </FullSlideIn>
        );
    }
}
