import { Translations } from '../../common/i18n/Text.ts';
import { IdentifierTexts } from '../components/shared/Identifier.ts';

export const identifierTexts: Translations<IdentifierTexts> = {
    de: {
        badge: () => 'Badge',
        badges: () => 'Badges',
        licenseplate: () => 'Kennzeichen',
        licenseplates: () => 'Kennzeichen',
    },
    fr: {
        badge: () => 'Badge',
        badges: () => 'Badges',
        licenseplate: () => 'Immatriculation',
        licenseplates: () => 'Immatriculations',
    },
    it: {
        badge: () => 'Badge',
        badges: () => 'Badges',
        licenseplate: () => 'Targa',
        licenseplates: () => 'Targhe',
    },
};
