import { Box, Container } from '@mui/material';

export function MainContent({ children }: { children: React.ReactNode }) {
    return (
        <Box
            sx={{
                height: '100vh',
                backgroundColor: 'whitesmoke.main',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <Container
                disableGutters={true}
                sx={{
                    flexGrow: 1,
                    height: '100%',
                    paddingTop: '72px',
                    paddingBottom: '24px',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'common.white',
                        borderRadius: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    {children}
                </Box>
            </Container>
        </Box>
    );
}
