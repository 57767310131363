import { Localized } from '../common/components/Localized.tsx';
import { Box, FormControl, InputLabel, Stack, TextField } from '@mui/material';
import { PanTool, PlayCircle, StopCircle } from '@mui/icons-material';
import * as Date from 'dg-web-shared/lib/Date.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import {
    ApprovingContractInfo,
    ApprovingContractType,
    BarrierGateIdentificationEntity,
    OffstreetTransactionActions,
    OffstreetTransactionRefundBlockedExplanation,
    OffstreetTransactionRefundInputValues,
} from './OperatorOffstreetTransactionTypes.ts';
import Alert from '@mui/material/Alert';
import { TerminalProvider } from '../common/state/ParkingsState.ts';
import { BarrierGateIdentification } from '../common/components/IdentificationItems.tsx';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import { RoutedModalController } from 'dg-web-shared/common/components/material-ui/routed-modal/RoutedModal.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { PoolFullInfo } from './OperatorOffstreetTransactionEntryWithoutExit.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { useOffstreetTransactionsFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import {
    addSpacesEveryFourthFromEnd,
    BarrierGateIdentificationIcon,
} from './OperatorOffstreetTransactionsRoute.tsx';
import getDuration = Formatter.getDuration;

export function OperatorOffstreetTransactionDetailRoutedModal({
    render,
}: {
    render: (controller: RoutedModalController) => React.ReactNode;
}) {
    const { activeFilters, searchText } =
        useOffstreetTransactionsFilterConfiguration();

    return (
        <OperatorRoutedModal
            variant={ModalVariant.STANDARD}
            backUrl={`/offstreet-transactions${makeSearchQueryWithFilters(
                searchText,
                activeFilters,
            )}`}
            render={render}
        />
    );
}

export function OffstreetTransactionOpen({
    isPresent,
    hasAntipassback,
}: {
    isPresent: boolean;
    hasAntipassback: boolean;
}) {
    return (
        <ReadOnlyTextField
            label={
                <Localized
                    de="Anwesenheit"
                    fr="Présence"
                    it="Presenza"
                    en="Presence"
                />
            }
            value={
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                    {isPresent ? (
                        hasAntipassback ? (
                            <>
                                <PlayCircle color={'success'} />
                                <Localized
                                    de="Im Parking ("
                                    fr="Dans le parking ("
                                    it="Nel parcheggio ("
                                    en="In the parking ("
                                />
                                <PanTool
                                    sx={{
                                        color: Colors.yellow,
                                        marginLeft: '-6px',
                                    }}
                                />
                                <Localized
                                    de="Antipassback aktiv)"
                                    fr="anti-passback actif)"
                                    it="anti-passback attivo)"
                                    en="anti-passback active)"
                                />
                            </>
                        ) : (
                            <>
                                <PlayCircle color={'success'} />
                                <Localized
                                    de="Im Parking"
                                    fr="Dans le parking"
                                    it="Nel parcheggio"
                                    en="In the parking"
                                />
                            </>
                        )
                    ) : (
                        <>
                            <StopCircle color={'secondary'} />
                            <Localized
                                de="Ausgefahren"
                                fr="Sorti"
                                it="Uscito"
                                en="Exited"
                            />
                        </>
                    )}
                </Box>
            }
        />
    );
}

export function BarrierGateIdentificationEnityValue({
    barrierGateIdentificationEntity,
    barrierGateProvider,
}: {
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
    barrierGateProvider?: TerminalProvider | null;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <BarrierGateIdentificationEnityIcon
                barrierGateIdentificationEntity={
                    barrierGateIdentificationEntity
                }
            />
            <BarrierGateIdentificationEnityText
                barrierGateIdentificationEntity={
                    barrierGateIdentificationEntity
                }
                barrierGateProvider={barrierGateProvider}
            />
        </Box>
    );
}

export function BarrierGateIdentificationEnityIcon({
    barrierGateIdentificationEntity,
}: {
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
}): JSX.Element {
    switch (barrierGateIdentificationEntity.identificationMedia) {
        case BarrierGateIdentification.BADGE:
            return (
                <BarrierGateIdentificationIcon
                    identificationType={BarrierGateIdentification.BADGE}
                    licensePlateType={null}
                />
            );
        case BarrierGateIdentification.LICENSE_PLATE:
            return (
                <BarrierGateIdentificationIcon
                    identificationType={BarrierGateIdentification.LICENSE_PLATE}
                    licensePlateType={
                        barrierGateIdentificationEntity.licenseGroup
                    }
                />
            );
        case BarrierGateIdentification.QR_CODE:
            return (
                <BarrierGateIdentificationIcon
                    identificationType={BarrierGateIdentification.QR_CODE}
                    licensePlateType={null}
                />
            );
        case BarrierGateIdentification.TICKET:
            return (
                <BarrierGateIdentificationIcon
                    identificationType={BarrierGateIdentification.TICKET}
                    licensePlateType={null}
                />
            );
    }
}

function BarrierGateIdentificationEnityText({
    barrierGateIdentificationEntity,
    barrierGateProvider,
}: {
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
    barrierGateProvider?: TerminalProvider | null;
}) {
    switch (barrierGateIdentificationEntity.identificationMedia) {
        case BarrierGateIdentification.BADGE:
            return (
                <Box sx={{ paddingLeft: '5px' }}>
                    {addSpacesEveryFourthFromEnd(
                        barrierGateIdentificationEntity.labelNr,
                    )}
                    {barrierGateIdentificationEntity.rfidDecimal && (
                        <DecimalProviderLabel
                            barrierGateProvider={barrierGateProvider}
                        />
                    )}
                </Box>
            );
        case BarrierGateIdentification.LICENSE_PLATE:
            return (
                <Box sx={{ paddingLeft: '5px' }}>
                    {barrierGateIdentificationEntity.countryCode
                        ? `${barrierGateIdentificationEntity.countryCode} - `
                        : ''}
                    {barrierGateIdentificationEntity.licenseNumber}
                </Box>
            );
        case BarrierGateIdentification.QR_CODE:
            return (
                <Box sx={{ paddingLeft: '5px' }}>
                    {barrierGateIdentificationEntity.qrCodeId}
                </Box>
            );
        case BarrierGateIdentification.TICKET:
            return (
                <Box sx={{ paddingLeft: '5px' }}>
                    {barrierGateIdentificationEntity.ticketApiString}
                </Box>
            );
    }
}

function DecimalProviderLabel({
    barrierGateProvider,
}: {
    barrierGateProvider?: TerminalProvider | null;
}): JSX.Element | null {
    if (!barrierGateProvider) {
        return null;
    }
    switch (barrierGateProvider) {
        case TerminalProvider.SKIDATA_CLOUD:
        case TerminalProvider.SKIDATA_CLOUD_V7:
            return (
                <Localized
                    de="Skidata-Nr. "
                    fr="N° Skidata "
                    it="Nr. Skidata "
                    en="Skidata Nr "
                />
            );
        case TerminalProvider.SB:
            return (
                <Localized
                    de="Scheidt & Bachmann-Nr. "
                    fr="N° Scheidt & Bachmann "
                    it="Nr. Scheidt & Bachmann"
                    en="Scheidt & Bachmann Nr "
                />
            );
        case TerminalProvider.DESIGNA:
        case TerminalProvider.HUB_PARKING:
        case TerminalProvider.PM:
        case TerminalProvider.Sitax:
        case TerminalProvider.VB:
        case TerminalProvider.CLOUD_CONNECTOR:
            return null;
    }
}

export function OffstreetTransactionRefundInputFields({
    price,
    refundInputValues,
    setRefundInputValues,
}: {
    price: number;
    refundInputValues: OffstreetTransactionRefundInputValues;
    setRefundInputValues: (
        values: OffstreetTransactionRefundInputValues,
    ) => void;
}) {
    const formattedPrice = price.toFixed(2);

    return (
        <FormControl fullWidth>
            <Stack spacing={3}>
                <TextField
                    label={
                        <Localized
                            de={`Rückerstattungsbetrag (max. CHF ${formattedPrice})`}
                            fr={`Montant remboursement (max. CHF ${formattedPrice})`}
                            it={`Importo rimborso (max. CHF ${formattedPrice})`}
                            en={`Refund amount (max. CHF ${formattedPrice})`}
                        />
                    }
                    value={refundInputValues.amount}
                    onChange={e => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                            setRefundInputValues({
                                ...refundInputValues,
                                amount: value,
                            });
                        }
                    }}
                    inputMode="text"
                    autoFocus={true}
                    error={isRefundAmountTooHigh(
                        price,
                        parseChf(refundInputValues.amount),
                    )}
                    helperText={
                        isRefundAmountTooHigh(
                            price,
                            parseChf(refundInputValues.amount),
                        )
                            ? `Max. CHF ${formattedPrice}`
                            : null
                    }
                />
                <TextField
                    value={refundInputValues.remark}
                    label={
                        <Localized
                            de="Interne Bemerkungen"
                            fr="Remarques internes"
                            it="Osservazioni interne"
                            en="Internal remarks"
                        />
                    }
                    onChange={e =>
                        setRefundInputValues({
                            ...refundInputValues,
                            remark: e.target.value,
                        })
                    }
                    inputMode="text"
                />
            </Stack>
        </FormControl>
    );
}

export function parseChf(price: string) {
    return parseFloat(parseFloat(price).toFixed(2));
}

export function isInputAmountRefundable(price: number, inputAmount: number) {
    return (
        !isNaN(inputAmount) &&
        inputAmount !== 0 &&
        !isRefundAmountTooHigh(price, inputAmount)
    );
}

export function isRefundAmountTooHigh(amount: number, refundAmount: number) {
    return refundAmount ? refundAmount > amount : false;
}

export function getDurationFormatted(start: string, end: string) {
    const language = useOperatorLanguage();
    const durationTexts = Date.Formatter.durationTexts[language];
    return getDuration(start, end, durationTexts);
}

export function getNetPayedPriceString(
    price: number,
    refundAmount: number | null,
) {
    const amount = refundAmount ? price - refundAmount : price;
    return refundAmount != null
        ? `CHF ${amount.toFixed(2)} (R)`
        : `CHF ${amount.toFixed(2)}`;
}

export function ApprovingContractInfoField({
    approvingContractInfo,
    poolFullInfo,
    bProfile,
}: {
    approvingContractInfo: ApprovingContractInfo | null;
    poolFullInfo?: PoolFullInfo | null;
    bProfile?: number | null;
}) {
    const permitLabel = (
        <Localized
            de="Bewilligung"
            fr="Autorisation"
            it="Autorizzazione"
            en="Permit"
        />
    );
    if (poolFullInfo) {
        return (
            <Box>
                <InputLabel sx={{ lineHeight: '1em' }}>
                    {permitLabel}
                </InputLabel>
                <PoolFullInfoBox
                    poolFullContractId={poolFullInfo.poolFullContractId}
                    poolFullPermitTypeName={poolFullInfo.poolFullPermitTypeName}
                />
            </Box>
        );
    }
    if (approvingContractInfo) {
        switch (approvingContractInfo.approvingContractType) {
            case ApprovingContractType.PRODUCT:
                return (
                    <ReadOnlyTextField
                        label={
                            <Localized
                                de="Produkt"
                                fr="Produit"
                                it="Prodotto"
                                en="Product"
                            />
                        }
                        value={
                            <Localized {...approvingContractInfo.infoText} />
                        }
                    />
                );
            case ApprovingContractType.PERMIT:
                return (
                    <ReadOnlyTextField
                        label={permitLabel}
                        value={
                            <Localized {...approvingContractInfo.infoText} />
                        }
                    />
                );
        }
    }
    return (
        <ReadOnlyTextField
            label={permitLabel}
            value={bProfile ? `n/d (profile ${bProfile})` : EmDash}
        />
    );
}

function PoolFullInfoBox({
    poolFullContractId,
    poolFullPermitTypeName,
}: {
    poolFullContractId: number;
    poolFullPermitTypeName: string;
}) {
    return (
        <>
            <Box sx={{ color: Colors.red, fontWeight: '500' }}>
                <Localized
                    de="Nicht gültig bei der Einfahrt"
                    fr="Non valable à l'entrée"
                    it="Non valido all'ingresso"
                    en="Not valid at the entrance"
                />
                <br />
                <Box sx={{ fontSize: '14px' }}>
                    <Localized
                        de="(max. Anzahl anwesende Fahrzeuge erreicht)"
                        fr="(nombre max de véhicules présents atteint)"
                        it="(raggiunto il numero massimo di veicoli presenti)"
                        en="(max. number of vehicles present reached)"
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    color: theme => theme.palette.blue.main,
                    fontWeight: '500',
                    fontSize: '16px',
                    paddingBottom: '5px',
                }}
            >
                {`${poolFullPermitTypeName} (#${poolFullContractId})`}
            </Box>
        </>
    );
}

export function OffstreetTransactionActionText({
    action,
}: {
    action: OffstreetTransactionActions;
}) {
    switch (action) {
        case OffstreetTransactionActions.REIMBURSE_CUSTOMER:
            return (
                <Localized
                    de="Zurückzahlen"
                    fr="Rembourser"
                    it="Rimborsa"
                    en="Refund"
                />
            );
        case OffstreetTransactionActions.DOWNLOAD_RECEIPT:
            return (
                <Localized
                    de="Quittung herunterladen"
                    fr="Télécharger le reçu"
                    it="Scarica ricevuta"
                    en="Download receipt"
                />
            );
        case OffstreetTransactionActions.CANCEL_ENTRY:
            return (
                <Localized
                    de="Einfahrt annullieren"
                    fr="Supprimer l'entrée"
                    it="Cancella entrata"
                    en="Delete entry"
                />
            );
    }
}

export function OffstreetTransactionRefundResponseBanner({
    open,
    onClose,
    success,
}: {
    open: boolean;
    onClose: () => void;
    success: boolean;
}) {
    if (!open) {
        return null;
    }
    return (
        <Alert onClose={onClose} severity={success ? 'success' : 'error'}>
            {success ? (
                <Localized
                    de="Der angegebene Betrag wurde erstattet."
                    fr="Le montant spécifié a été remboursé."
                    it="L'importo specificato è stato rimborsato."
                    en="The specified amount was refunded."
                />
            ) : (
                <Localized
                    de="Das für die Transaktion verwendete Zahlungsmittel ist nicht mehr verfügbar, weshalb eine Rückerstattung nicht vorgenommen werden kann."
                    fr="Le moyen de paiement utilisé pour la transaction n'est plus disponible, c'est pourquoi un remboursement ne peut être effectué."
                    it="Il mezzo di pagamento utilizzato per la transazione non è più disponibile, per questo non è possibile effettuare il rimborso."
                    en="The payment method used for the transaction is no longer available and a refund cannot be issued."
                />
            )}
        </Alert>
    );
}

export function OffstreetTransactionRefundBlocked({
    refundBlockedExplanation,
}: {
    refundBlockedExplanation: OffstreetTransactionRefundBlockedExplanation;
}): JSX.Element {
    switch (refundBlockedExplanation) {
        case OffstreetTransactionRefundBlockedExplanation.ALREADY_REFUNDED:
            return (
                <Localized
                    de="Diese Transaktion wurde bereits zurückerstattet"
                    fr="Cette transaction a déjà été remboursée"
                    it="Questa transazione è già stata rimborsata"
                    en="This transaction has already been refunded"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.PAST_REFUND_TIME:
            return (
                <Localized
                    de="Rückerstattung nicht mehr möglich: Transaktion älter als 3 Monate"
                    fr="Remboursement n'est plus possible: transaction de plus de 3 mois"
                    it="Rimborso non più possibile: transazione più vecchia di 3 mesi"
                    en="Refund no longer possible: transaction older than 3 months"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.MISSING_PERMISSIONS:
            return (
                <Localized
                    de="Funktion nicht verfügbar: Ihnen fehlen die erforderlichen Berechtigungen"
                    fr="Fonction non disponible: vous ne disposez pas des droits nécessaires"
                    it="Funzione non disponibile: non dispone dei diritt necessari"
                    en="Function not available: you don't have the necessary permissions"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.NO_DIGITAL_PURCHASE:
            return (
                <Localized
                    de="Rückerstattung nicht möglich: es gibt keine Online-Zahlung"
                    fr="Remboursement impossible: il n'y a aucun paiement en ligne"
                    it="Rimborso non possibile: non esiste alcun pagamento online"
                    en="Refund not possible: there is no online payment"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.NON_POSITIVE_PRICE:
            return (
                <Localized
                    de="Rückerstattung nicht möglich: Gratis Transaktion"
                    fr="Remboursement impossible: transaction gratuite"
                    it="Rimborso non possibile: transazione gratuita"
                    en="Refund not possible: free transaction"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.PARKING_NOT_COMPLETE:
            return (
                <Localized
                    de="Rückerstattung nicht möglich: die Transaktion ist noch nicht beendet"
                    fr="Remboursement impossible: la transaction n'est pas encore terminé"
                    it="Rimborso non possibile: la transazione non è ancora terminata"
                    en="Refund not possible: the transaction is not yet complete"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.NO_ACTIVE_ACCOUNT_TO_REFUND:
            return (
                <Localized
                    de="Rückerstattung nicht möglich: Benutzerkonto gesperrt"
                    fr="Remboursement impossible: compte utilisateur bloqué"
                    it="Rimborso non possibile: conto utente bloccato"
                    en="Refund not possible: user account blocked"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.CONTRACT_TYPE_NOT_SUPPORTED:
            return (
                <Localized
                    de="Funktion nicht verfügbar für diese Transaktionstyp"
                    fr="Fonction non disponible pour ce type de transaction"
                    it="Funzione non disponibile per questo tipo di transazione"
                    en="Function not available for this transaction type"
                />
            );
        case OffstreetTransactionRefundBlockedExplanation.NO_SUBSCRIPTION_CHARGE:
            return (
                <Localized
                    de="Es gibt noch keine Belastungen. Daher ist keine Rückerstattung möglich."
                    fr="Aucun débit n'a encore été effectué. Aucun remboursement n'est donc possible."
                    it="Non sono stati ancora effettuati addebiti. Pertanto nessun rimborso è possibile."
                    en="There have not been any charges yet. Therefore no refund is possible."
                />
            );
    }
}

export function NoReceiptForRunningContract() {
    return (
        <Localized
            de="Quittung nicht verfügbar: die Transaktion ist noch nicht beendet"
            fr="Reçu pas disponible: la transaction n'est pas encore terminé"
            it="Ricevuta non disponibile: la transazione non è ancora terminata"
            en="Receipt not available: the transaction is not yet complete"
        />
    );
}
