import { NativeContextProdivder } from './Native.tsx';
import { Box } from '@mui/material';

export function MobileLayout() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <NativeContextProdivder />
        </Box>
    );
}
