import { ClearanceRequestAddress } from '../common/models/ClearanceRequest.ts';

export const ClearanceRequestAddressBlock = (p: {
    address: ClearanceRequestAddress;
}): JSX.Element => {
    return (
        <>
            <div>
                <div>{`${p.address.firstname || ''} ${
                    p.address.lastname || ''
                }`}</div>
                <div>
                    {`${p.address.company1 || ''} ${p.address.company2 || ''}`}{' '}
                </div>
                <div>{`${p.address.address || ''}`} </div>
                <div>{`${p.address.country + ' - ' || ''} ${
                    p.address.zipCode || ''
                } ${p.address.city || ''}`}</div>
            </div>
        </>
    );
};
