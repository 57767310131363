import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { languageFromString } from 'dg-web-shared/lib/Localized.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { getParameterByName } from 'dg-web-shared/lib/Url.ts';
import { ALLOWED_LANGUAGE_CODES } from '../i18n/Text.ts';

export interface State {
    language: string;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'common-SettingsState';
    }

    sideEffects(_store: Flux.Store): void {
        // nop
    }

    getInitialState(): State {
        // it is possible to switch default language by using a url query parameter ?lang=[language]
        const langFromUrl = getLanguageParameter();
        return {
            language: langFromUrl !== null ? langFromUrl : 'de',
        };
    }

    writeLanguage(language: string): void {
        this.set((s: State) => {
            s.language = language;
            return s;
        });
    }

    writeLanguageFromServer(language: string): void {
        const langFromUrl = getLanguageParameter();
        this.set((s: State) => {
            s.language = langFromUrl !== null ? langFromUrl : language;
            return s;
        });
    }
}

function getLanguageParameter() {
    const lngQueryParameter = getParameterByName(window.location.href, 'lang');

    return isDefined(lngQueryParameter) &&
        isValidLanguageCode(lngQueryParameter)
        ? lngQueryParameter
        : null;
}

function isValidLanguageCode(language: string | null): boolean {
    return (
        isDefined(language) && ALLOWED_LANGUAGE_CODES.indexOf(language) !== -1
    );
}

export function useOperatorLanguage() {
    const { storeState: language } = Flux.useStore(
        store => new StateSlice(store).state.language,
    );

    return languageFromString(language);
}
