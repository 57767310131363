import {
    Form,
    FormValidation,
    FormValidators,
} from 'dg-web-shared/lib/FormValidation.ts';
import { ParsedTom } from 'dg-web-shared/model/Tom.ts';
import { ApiError } from 'dg-web-shared/dto/ApiError.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as TomActions from '../actions/TomActions.ts';
import * as TomState from '../state/TomState.ts';

export namespace Validation {
    export namespace TomBaseDataEditForm {
        const validators: Form<TomState.Edit.State> = {
            name: [FormValidators.NotEmpty],
            operatorExternalId: [],
            street: [],
        };

        export interface Props {
            tom: ParsedTom | null;
            edit: TomState.Edit.State;
            editResponse: TomState.EditBaseDataResponse.State;
            settings: SettingsState.State;
        }

        export const validateForm = (p: Props) => {
            const formValues = TomActions.makeEditPayload(p.edit, p.tom);
            return FormValidation.validate(
                formValues,
                validators,
                p.settings.language,
            );
        };

        export const usernameIsDuplicate = (p: Props) => {
            return (
                p.editResponse.statusCode.badRequest &&
                p.editResponse.errorData &&
                (p.editResponse.errorData.message as ApiError) ===
                    'TOM_OPERATOR_EXTERNAL_ID_ALREADY_IN_USE'
            );
        };
    }
}
