import { ButtonWithDropdown, DropdownItem } from './ButtonWithDropdown.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function HeaderActionCloseButtons({
    isLoading,
    dropdownItems,
    onClose,
}: {
    isLoading?: boolean;
    dropdownItems?: DropdownItem[] | null;
    onClose?: () => void;
}) {
    const navigate = useNavigate();
    return (
        <>
            {dropdownItems && (
                <ButtonWithDropdown
                    isLoading={isLoading}
                    label={
                        <Localized
                            de="Aktionen"
                            fr="Actions"
                            it="Azioni"
                            en="Actions"
                        />
                    }
                    itemVariant={'action'}
                    dropdownItems={dropdownItems}
                />
            )}
            <Button
                variant="outlined"
                onClick={() => (onClose ? onClose() : navigate('..'))}
                disabled={isLoading}
            >
                <Localized de="Schliessen" fr="Fermer" it="Chiudi" en="Close" />
            </Button>
        </>
    );
}
