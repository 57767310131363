import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { stringifyParams } from 'dg-web-shared/lib/QueryParamsUtil.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Http from '../../../api/Http.ts';
import * as OperatorDataState from '../../../common/state/OperatorDataState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import {
    FilterLayout,
    FilterLayoutBody,
    FilterLayoutHeader,
} from '../../../ui/filter/FilterLayout.tsx';
import {
    ButtonDropdown,
    ButtonDropdownLinkItem,
} from '../../../ui/slidein/ButtonDropdown.tsx';
import * as FilteredPermitsState from '../../state/FilteredPermitsState.ts';
import { AddMenu } from '../shared/AddMenu.tsx';
import { CostFilterHalfSlideIn } from './CostFilter.tsx';
import { FilteredPermitsFilterHalfSlideIn } from './FilteredPermitsFilterHalfSlideIn.tsx';
import { FilteredPermitsFilterSummary } from './FilteredPermitsFilterSummary.tsx';
import { FilteredPermitsList } from './FilteredPermitsList.tsx';
import { IssueDateFilterHalfSlideIn } from './IssueDateFilter.tsx';
import { OperatorLoginFilterHalfSlideIn } from './OperatorLoginFilter.tsx';
import { PermitTypeFilterHalfSlideIn } from './PermitTypeFilter.tsx';
import { StatusFilterHalfSlideIn } from './StatusFilter.tsx';
import { TimeLimitFilterHalfSlideIn } from './TimeLimitFilter.tsx';
import { ValidDateFilterHalfSlideIn } from './ValidDateFilter.tsx';
import { ChannelFilterHalfSlideIn } from './ChannelFilter.tsx';
import { Localized } from '../../../common/components/Localized.tsx';

export const renderPermitFilterSlideIns = (allState: Flux.Store) => (
    <>
        <FilteredPermitsFilterHalfSlideIn allState={allState} />
        <ValidDateFilterHalfSlideIn allState={allState} />
        <PermitTypeFilterHalfSlideIn />
        <OperatorLoginFilterHalfSlideIn allState={allState} />
        <IssueDateFilterHalfSlideIn allState={allState} />
        <TimeLimitFilterHalfSlideIn />
        <ChannelFilterHalfSlideIn />
        <CostFilterHalfSlideIn />
        <StatusFilterHalfSlideIn />
    </>
);

const filterToParams = (state: FilteredPermitsState): string => {
    return stringifyParams(
        Http.OperatorAccount.Permits.filteredListParamsToQueryParams({
            validDateFrom: state.filter.validDateFrom,
            validDateTo: state.filter.validDateTo,
            permitType: state.filter.permitTypes,
            issuedBy: thenElse(
                state.filter.operatorLogins,
                l =>
                    l.map(id =>
                        id ===
                        FilteredPermitsState.Filter
                            .OperatorLoginFilterOnlineOption
                            ? null
                            : id,
                    ),
                null,
            ),
            issueDateFrom: state.filter.issueDateFrom,
            issueDateTo: state.filter.issueDateTo,
            timeLimit: state.filter.timeLimit,
            channel: state.filter.channel,
            cost: state.filter.cost,
            status: state.filter.status,
            lang: state.settings.language,
            sortField: 'validTo',
        }),
    );
};

interface FilteredPermitsState {
    settings: SettingsState.State;
    filter: FilteredPermitsState.Filter.State;
    operator: OperatorDataState.State;
}

const cn = ElementNamer('FilteredPermits');

export class FilteredPermits extends Flux.Container<FilteredPermitsState> {
    static displayName: string = cn('');

    stateSelector(): FilteredPermitsState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredPermitsState.Filter.get(this.props.allState),
            operator: OperatorDataState.get(this.props.allState),
        };
    }

    render() {
        const filterParams = filterToParams(this.state);
        const filterActive = FilteredPermitsState.Filter.filterActive(
            this.state.filter,
        );

        return (
            <FilterLayout>
                <FilterLayoutHeader
                    filterSummary={
                        <FilteredPermitsFilterSummary
                            allState={this.props.allState}
                        />
                    }
                    rightDropdown={<AddMenu label={captureText} />}
                    secondRightDropdown={
                        <ButtonDropdown label={exportText}>
                            <ButtonDropdownLinkItem
                                href={`/ui-api/operator-account/permits/csv-export?${filterParams}`}
                                label={
                                    <Localized
                                        de="Bewilligungen"
                                        fr="Autorisations"
                                        it="Autorizzazioni"
                                        en="Permissions"
                                    />
                                }
                                disabled={!filterActive}
                            />
                            <ButtonDropdownLinkItem
                                href={`/ui-api/operator-account/permits/by-entity-csv-export?${filterParams}`}
                                label={
                                    <Localized
                                        de="Bewilligungen pro Kennzeichen / Badge"
                                        fr="Autorisations par immatriculation / badge"
                                        it="Autorizzazioni per targa / badge"
                                        en="License plate / badge authorizations"
                                    />
                                }
                                disabled={!filterActive}
                            />
                            <ButtonDropdownLinkItem
                                href={`/ui-api/operator-account/permits/by-zone-csv-export?${filterParams}`}
                                label={
                                    <Localized
                                        de="Bewilligungen pro Zone"
                                        fr="Autorisations par zone"
                                        it="Autorizzazioni per zona"
                                        en="Permissions per zone"
                                    />
                                }
                                disabled={!filterActive}
                            />
                            <ButtonDropdownLinkItem
                                href={`/ui-api/operator-account/permits/per-zone-and-day-export?${filterParams}`}
                                label={
                                    <Localized
                                        de="Anzahl Bewilligungen pro Tag, Zone und Art"
                                        fr="Quantité d'autorisations par jour, zone et type"
                                        it="Quantità di autorizzazioni per giorno, zona e tipo"
                                        en="Quantity of permits per day, zone and type"
                                    />
                                }
                                disabled={!filterActive}
                            />

                            {this.state.operator.data &&
                                this.state.operator.data
                                    .licensePlatePermitSettings
                                    .hasSalaryDeductionExport && (
                                    <ButtonDropdownLinkItem
                                        href={`/ui-api/operator-account/permits/salary-deduction-export?${filterParams}`}
                                        label={
                                            <Localized
                                                de="Lohnabzug"
                                                fr="Retenue sur le salaire"
                                                it="Deduzione dal salario"
                                                en="Deduction from salary"
                                            />
                                        }
                                        disabled={!filterActive}
                                    />
                                )}
                        </ButtonDropdown>
                    }
                />
                <FilterLayoutBody>
                    <FilteredPermitsList allState={this.props.allState} />
                </FilterLayoutBody>
            </FilterLayout>
        );
    }
}

export const captureText = (
    <Localized de="Erfassen" fr="Saisir" it="Registra" en="Capture" />
);

export const exportText = (
    <Localized de="Exportieren" fr="Exporter" it="Esporta" en="Export" />
);
