import moment from 'moment';
import * as Text from '../../common/i18n/Text.ts';
import {
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import { usbStickTexts } from '../i18n/UsbStickTexts.ts';
import * as StickState from '../state/UsbStickState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Body } from '../../ui/layout/TabContent.tsx';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import * as UsbStick from 'dg-web-shared/model/UsbStick.ts';
import { UsbStickState, UsbStickType } from 'dg-web-shared/model/UsbStick.ts';
import { UsbStickDetailSlideIn } from './UsbStickDetailSlideIn.tsx';
import { UsbStickSyncStatus } from './UsbStickSyncStatus.tsx';
import { SyncAppSlideIn } from '../../sync-app/components/SyncAppSlideIn.tsx';
import * as SyncAppHelpSlideInState from '../../sync-app/state/SyncAppHelpSlideInState.ts';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { TomStatusIconPositioning } from '../../tom/components/TomFirmwareStatus.tsx';
import { Icon16 } from '../../ui/icons/Icon.tsx';

export interface UsbStickTexts {
    Id: Text.Translation;
    Title: Text.Translation;
    StickTypeLabel: Text.Translation;
    StickTypeCollection: Text.Translation;
    StickTypeMaintenance: Text.Translation;
    StickTypeUnknown: Text.Translation;
    StickType: Text.TranslationWithArgs1<UsbStickType>;

    StateLabel: Text.Translation;
    StickState: Text.TranslationWithArgs1<UsbStickState>;
    StateDraft: Text.Translation;
    StateCommissioned: Text.Translation;
    StateInvalidated: Text.Translation;
    StateDeleted: Text.Translation;
    StateUnknown: Text.Translation;
    LastWriteLabel: Text.Translation;
    LastWriteNever: Text.Translation;
    LastWriteValue: Text.TranslationWithArgs1<moment.Moment>;
    VersionLabel: Text.Translation;
    Permissions: Text.Translation;
    TagsHint: Text.Translation;
    PinCodeLabel: Text.Translation;
    PinCodeCheckbox: Text.Translation;
    PinCodeNotSet: Text.Translation;
    LanguagePlaceholder: Text.Translation;
    ExpirationLabel: Text.Translation;
    ExpirationCheckbox: Text.Translation;
    ExpirationNotSet: Text.Translation;
    ExpirationValue: Text.TranslationWithArgs1<moment.Moment>;
    CommentLabel: Text.Translation;

    SlideInHeaderCaption: Text.Translation;
    SyncStatus: Text.Translation;
    NeedsSynchronization: Text.Translation;
    InSync: Text.Translation;

    WriteToStick: Text.Translation;
    SaveEdit: Text.Translation;
}

interface State {
    settings: SettingsState.State;
    layout: StickState.Layout.State;
    usbSticks: StickState.List.State;
    syncSlideIn: SyncAppHelpSlideInState.Layout.State;
}

interface ItemProps {
    allState: Flux.Store;
    usbStick: UsbStick.UsbStick;
    language: string;
}

const UsbStickItem = (p: ItemProps): JSX.Element => {
    const txt = usbStickTexts[p.language];
    return (
        <DefaultTableRow
            onClick={() =>
                p.allState.update(store =>
                    StickState.Layout.stateWrite(store, {
                        selectedUsbStickId: p.usbStick.stickId,
                    }),
                )
            }
        >
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth._72px}>
                {p.usbStick.stickId}
            </TableColumn>
            <TableColumn width={ColumnWidth.variable}>
                {p.usbStick.name}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px}>
                {txt.StickType(p.usbStick.type)}
            </TableColumn>
            <TableColumn width={ColumnWidth._192px}>
                {txt.StickState(p.usbStick.state)}
            </TableColumn>
            <TableColumn width={ColumnWidth._208px}>
                <TomStatusIconPositioning
                    label={
                        <UsbStickSyncStatus
                            synced={!p.usbStick.needsSynchronization}
                        />
                    }
                    text={
                        p.usbStick.needsSynchronization
                            ? txt.NeedsSynchronization()
                            : txt.InSync()
                    }
                />
            </TableColumn>
            <TableColumn width={ColumnWidth._24px} captionFontSize>
                <IconTopOffsetContainer>
                    <Icon16 icon={Icons16.chevronRight} />
                </IconTopOffsetContainer>
            </TableColumn>
        </DefaultTableRow>
    );
};

export class UsbSticksBody extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: StickState.Layout.get(this.props.allState),
            usbSticks: StickState.List.get(this.props.allState),
            syncSlideIn: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
        };
    }

    getDependingStateSlices(): AbstractLegacyServerState<any>[] {
        return [this.state.usbSticks];
    }

    renderSlideIns(): JSX.Element[] {
        return [
            <UsbStickDetailSlideIn
                allState={this.props.allState}
                key="usb-stick-detail-slideIn"
            />,
            <SyncAppSlideIn allState={this.props.allState} key="sync-app" />,
        ];
    }

    render() {
        const txt = usbStickTexts[this.state.settings.language];
        // do not display deleted and invalidated sticks in list for operator
        const sticksToDisplay = this.state.usbSticks.data.filter(
            s =>
                s.state !== UsbStickState.deleted &&
                s.state !== UsbStickState.invalidated,
        );
        return (
            <Body
                disabled={false}
                dependingStateSlices={this.getDependingStateSlices()}
                slideIns={this.renderSlideIns()}
                loading={this.state.syncSlideIn.loading}
            >
                <Table>
                    <TableHeader>
                        <EmptyTableHeaderColumn
                            width={ColumnWidth._24px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.Id()}
                            onClick={null}
                            width={ColumnWidth._72px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.Title()}
                            onClick={null}
                            width={ColumnWidth.variable}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.StickTypeLabel()}
                            direction={SortDirection.Ascending}
                            selected={true}
                            onClick={null}
                            width={ColumnWidth._192px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.StateLabel()}
                            onClick={null}
                            width={ColumnWidth._192px}
                            delimiterBottom={true}
                        />
                        <TableHeaderColumn
                            name={txt.SyncStatus()}
                            onClick={null}
                            width={ColumnWidth._208px}
                            delimiterBottom={true}
                        />
                        <EmptyTableHeaderColumn
                            width={ColumnWidth._24px}
                            delimiterBottom={true}
                        />
                    </TableHeader>
                    <TableBody>
                        {sticksToDisplay.map(
                            (stick: UsbStick.UsbStick, index: number) => {
                                return (
                                    <UsbStickItem
                                        usbStick={stick}
                                        language={this.state.settings.language}
                                        allState={this.props.allState}
                                        key={stick.stickId || index}
                                    />
                                );
                            },
                        )}
                    </TableBody>
                </Table>
            </Body>
        );
    }
}
