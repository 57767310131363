import debounce from 'debounce';
import moment from 'moment';

import { DurationType } from 'dg-web-shared/dto/PermitTimeUnit.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as ClearancePermitListState from '../../clearance-permit-list/state/ClearancePermitListState.ts';
import * as ResultState from '../../clearance-permit-list/state/ResultState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import {
    getIdentificationTypesGivenSelection,
    hasBadgeIdentification,
    hasLicensePlateIdentification,
} from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import {
    ConfirmSaveHeader,
    ErrorHeader,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    LoaderHeader,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import * as PermitCreateTexts from '../i18n/PermitCreateTexts.ts';
import * as AddressState from '../state/AddressState.ts';
import * as BadgeState from '../state/BadgeState.ts';
import * as LicensePlateState from '../state/LicensePlateState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import { AddressSelection } from './Address.tsx';
import { CounterPaymentChannelEdit } from './CounterPaymentChannel.tsx';
import { DurationField, EndDateField } from './PermitCreateSubComponents.tsx';
import {
    getDurationFromId,
    getDurationString,
} from './PermitTimeUnitHelpers.ts';
import {
    AdditionalInfos,
    Badges,
    Email,
    LicensePlates,
    Remark,
    renderPrice,
} from './Shared.tsx';
import {
    BadgesThatNeedAccountNotification,
    BadgesThatNeedWhitelistNotification,
    LpsThatNeedWhitelistNotification,
    OverlappingEntitiesNotification,
} from './SharedNotifications.tsx';
import {
    ContractQuotaInfos,
    ContractQuotaInfosPerEntity,
    ContractQuotaInfoType,
    keyForQuotaInfo,
} from 'dg-web-shared/common/components/quota-info/ContractQuotaInfos.ts';
import {
    Notification,
    NotificationColor,
    NotificationIcon,
} from '../../ui/notifications/Notification.tsx';
import { DateTime, Duration } from 'luxon';
import { Localized } from '../../common/components/Localized.tsx';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';

const PERMITTYPES_WITH_REQUIRED_ADDRESS = [
    4604, 4605, 4606, 4607, 4608, 4609, 4610, 4611, 4612, 4613, 4614, 4615,
    4616, 4617, 4705, 4618, 4619, 4620, 4621, 4622, 4623, 4624, 4625, 4626,
    4627, 4628, 4629, 4630, 4631, 4706, 4149, 4632, 4633, 4729, 4725,
];

export const closePermitCreateSlidin = (store: Flux.Store) => {
    PermitCreateState.resetAllState(store);
    AddressState.resetAll(store);
};

const abortPermitCreateProcess = (store: Flux.Store): string => {
    closePermitCreateSlidin(store);
    return 'PermitCreateSlideIn-abortPermitCreateProcess';
};

const removeLicensePlate = (store: Flux.Store, id: number): string => {
    PermitCreateState.Config.set(
        store,
        (s: PermitCreateState.Config.State): PermitCreateState.Config.State => {
            s.licensePlates = s.licensePlates.filter(lp => lp.id !== id);
            return s;
        },
    );
    PermitCreateState.Calc.reset(store);
    AddressState.ServerList.reset(store);
    return 'PermitCreateSlideIn-removeLicensePlate';
};

const removeBadge = (store: Flux.Store, id: number): string => {
    PermitCreateState.Config.set(
        store,
        (s: PermitCreateState.Config.State): PermitCreateState.Config.State => {
            s.badges = s.badges.filter(badge => badge.id !== id);
            return s;
        },
    );
    PermitCreateState.Calc.reset(store);
    return 'PermitCreateSlideIn-removeLicensePlate';
};

const clearAddress = (store: Flux.Store): string => {
    AddressState.Edit.reset(store);
    AddressState.Select.reset(store);
    AddressState.ServerList.reset(store);
    PermitCreateState.Config.stateWrite(store, { address: null });
    return 'PermitCreateSlideIn-clearAddress';
};

const createPermit = AsyncRequest.request(
    Http.OperatorAccount.Permits.create,
    (store: Flux.Store, res: Response): string => {
        PermitCreateState.CreateResponse.setResponse(store, res);

        if (res.statusCode.cls.success) {
            PermitEditState.Server.setResponse(store, res);
            const serverData = PermitEditState.Server.get(store).data;

            if (serverData) {
                new ClearancePermitListState.StateSlice(store).selectPermit(
                    serverData.id,
                );
            }

            PermitCreateState.resetAllState(store);
            AddressState.resetAll(store);
            ResultState.refetchSameContext(store, true);
        }
        return 'PermitCreateSlideIn-createPermit';
    },
);

const editAddress = (store: Flux.Store) => {
    AddressState.Select.stateWrite(store, { selectAddressOpen: true });
    if (AddressState.getLicensePlates(store).length === 0) {
        AddressState.Select.stateWrite(store, { addressSelected: true });
    }
    return 'PermitCreateSlideIn-editAddress';
};

interface State {
    settings: SettingsState.State;
    config: PermitCreateState.Config.State;
    calc: PermitCreateState.Calc.State;
    layout: PermitCreateState.Layout.State;
    addLp: LicensePlateState.AddTripple.State;
    permitType: PermitTypeState.PermitType | null;
    permitTypes: PermitTypeState.State;
    createResponse: PermitCreateState.CreateResponse.State;
    selectedLicensePlate: LicensePlateState.AddServerData.State;
    licensePlate: LicensePlateState.Entity.State;
    operatorData: OperatorDataState.State;
    currentLogin: CurrentOperatorLoginState.State;
}

const getIdentificationTypes = (s: State) =>
    getIdentificationTypesGivenSelection(
        s.permitTypes.data,
        s.config.permitTypeId ? [s.config.permitTypeId] : [],
    );

export const zoneToString = (z: PermitTypeState.Zone): string =>
    `${z.extZoneCode ? z.extZoneCode + ' — ' : ''}${z.name} — ${z.city}`;

function zoneSelectionString(
    zones: PermitTypeState.Zone[],
): JSX.Element | null {
    if (zones.length === 0) {
        return null;
    } else {
        return (
            <div>
                {zones.map((z: PermitTypeState.Zone) => (
                    <div key={z.id}>{zoneToString(z)}</div>
                ))}
            </div>
        );
    }
}

export class PermitCreate extends Flux.Container<State> {
    static displayName = 'PermitCreate';

    resetCalc: () => void;

    constructor(props: Flux.ContainerProps) {
        super(props);
        this.resetCalc = debounce(() => {
            this.update(PermitCreateState.Calc.reset);
        }, 500);
    }

    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            config: PermitCreateState.Config.get(this.props.allState),
            calc: PermitCreateState.Calc.get(this.props.allState),
            layout: PermitCreateState.Layout.get(this.props.allState),
            addLp: LicensePlateState.AddTripple.get(this.props.allState),
            permitType: PermitTypeState.getById(
                this.props.allState,
                PermitCreateState.Config.get(this.props.allState).permitTypeId,
            ),
            permitTypes: PermitTypeState.get(this.props.allState),
            createResponse: PermitCreateState.CreateResponse.get(
                this.props.allState,
            ),
            selectedLicensePlate: LicensePlateState.AddServerData.get(
                this.props.allState,
            ),
            licensePlate: LicensePlateState.Entity.get(this.props.allState),
            operatorData: OperatorDataState.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    txt() {
        return PermitCreateTexts.texts[this.state.settings.language];
    }

    renderZones(permitType: PermitTypeState.PermitType): JSX.Element {
        if (permitType.selectZones === 'NO_CHOICE') {
            return (
                <LabeledText label={this.txt().ValidZones()}>
                    {permitType.zones.length > 10 ? (
                        this.txt().FixedZones(permitType.zones.length)
                    ) : (
                        <div>
                            {permitType.zones.map(z => (
                                <div key={z.id}>{zoneToString(z)}</div>
                            ))}
                        </div>
                    )}
                </LabeledText>
            );
        } else {
            const selectedZones = permitType.zones.filter(z =>
                this.state.config.onstreetZones
                    ? this.state.config.onstreetZones.indexOf(z.id) > -1
                    : false,
            );

            return (
                <SingleSelection
                    label={this.txt().ValidZones()}
                    onClick={() =>
                        this.props.allState.update(store =>
                            PermitCreateState.Layout.stateWrite(store, {
                                zonePickerOpen: true,
                            }),
                        )
                    }
                    selection={zoneSelectionString(selectedZones)}
                    errorText={
                        this.state.layout.confirmPressedOnError &&
                        selectedZones.length === 0
                            ? this.txt().MissingZone()
                            : null
                    }
                    focused={this.state.layout.zonePickerOpen}
                />
            );
        }
    }

    getDurationString(): string {
        const permitType = this.state.permitType;
        if (permitType && permitType.validDurationsV2) {
            const duration = getDurationFromId(
                this.state.config.durationId,
                permitType.validDurationsV2,
            );
            if (duration) {
                return getDurationString(
                    duration,
                    this.state.settings.language,
                );
            }
        }
        return '';
    }

    validityIntervalErrorText() {
        if (
            this.state.layout.confirmPressedOnError &&
            this.noValidityInterval()
        ) {
            return this.txt().ValidityIntervalMissing();
        }

        return null;
    }

    permitTypeErrorText() {
        if (this.state.layout.confirmPressedOnError && !this.state.permitType) {
            return this.txt().PermitTypeMissing();
        }

        return null;
    }

    selectableDurationsCount(): number {
        return this.state.permitType?.validDurationsV2?.length ?? 0;
    }

    isDurationSelectable(): boolean {
        return this.selectableDurationsCount() > 0;
    }

    noValidityInterval(): boolean {
        const { durationId, startDate, endDate } = this.state.config;
        const canSelectDuration = this.isDurationSelectable();
        return (
            !startDate ||
            (canSelectDuration && !durationId) ||
            (!canSelectDuration && !endDate)
        );
    }

    zonesSelected(): boolean {
        const permitType = this.state.permitType;
        const selectedZones = this.state.config.onstreetZones;
        if (permitType) {
            switch (permitType.selectZones) {
                case 'NO_CHOICE':
                    return true;
                case 'SELECT_ONE':
                    return !!selectedZones && selectedZones.length === 1;
                case 'SELECT_MANY':
                    return !!selectedZones && selectedZones.length >= 1;
                default:
                    return false;
            }
        } else {
            return false;
        }
    }

    incompleteData(): boolean {
        if (
            PERMITTYPES_WITH_REQUIRED_ADDRESS.indexOf(
                this.state.permitType?.id ?? -1,
            ) > -1 &&
            !this.state.config.address
        ) {
            return true;
        }

        if (this.noValidityInterval()) {
            return true;
        }

        if (
            hasLicensePlateIdentification(getIdentificationTypes(this.state)) &&
            this.state.config.licensePlates.length === 0
        ) {
            return true;
        }

        if (
            hasBadgeIdentification(getIdentificationTypes(this.state)) &&
            this.state.config.badges.length === 0
        ) {
            return true;
        }

        if (!this.zonesSelected()) {
            return true;
        }

        if (
            this.state.permitType &&
            this.state.permitType.mubstBeLinkedToCustomerAccount &&
            !this.state.config.email
        ) {
            return true;
        }

        const calc = this.state.calc.data;
        if (calc) {
            if (calc.emailIsNotALogin) {
                return true;
            }

            if (
                (calc.overlappingLpPermits &&
                    calc.overlappingLpPermits.length > 0) ||
                (calc.overlappingBadgePermits &&
                    calc.overlappingBadgePermits.length > 0)
            ) {
                return true;
            }

            if (calc.lpsThatNeedWhitelist.length > 0) {
                return true;
            }
        }

        if (
            this.state.operatorData.data?.requireCounterPaymentChannel &&
            !this.state.config.counterPaymentChannel
        ) {
            return true;
        }

        return false;
    }

    confirmButtonSpecialState(): ButtonSpecialState | null {
        if (
            this.state.calc.pending ||
            this.state.createResponse.pending ||
            !this.checkQuotaApprovalRights()
        ) {
            return ButtonSpecialState.DISABLED;
        }
        if (this.incompleteData() && this.state.layout.confirmPressedOnError) {
            return ButtonSpecialState.ERROR;
        }
        if (this.incompleteData()) {
            return ButtonSpecialState.DISABLED;
        } else {
            return null;
        }
    }

    isEndDateSelectable(): boolean {
        const notSelectable =
            this.state.permitType &&
            this.state.config.endDate &&
            this.state.permitType.toRange &&
            this.state.permitType.toRange.start.isSame(
                this.state.permitType.toRange.end,
            );
        return !notSelectable;
    }

    getPriceString(): string {
        const calc = this.state.calc.data;
        if (this.state.calc.pending) {
            return '...';
        }
        if (!calc) {
            return this.txt().PriceNotAvailable();
        } else {
            return renderPrice(calc, this.state.settings.language);
        }
    }

    getValidToCalcString(): string | null {
        const calc = this.state.calc.data;
        const startDate = this.state.config.startDate;
        const permitType = this.state.permitType;
        if (permitType && permitType.timeSelectionType === 'from_to') {
            return null;
        }
        if (this.state.calc.pending) {
            return '...';
        }
        if (!calc) {
            const durationId = this.state.config.durationId;

            if (
                !!startDate &&
                !!permitType &&
                !!durationId &&
                !this.state.config.endDate
            ) {
                const duration = getDurationFromId(
                    durationId,
                    permitType.validDurationsV2 || [],
                );

                if (duration) {
                    /*
                       this is super hacky but date calculation is done in fronted and desperately
                       needs refactor. No time to do that.

                       Therefore simple split to retain old logic.
                     */

                    const startDateLuxon = DateTime.fromISO(
                        startDate.toISOString(),
                    );

                    const now = DateTime.now();

                    if (duration.type === 'hour') {
                        const exactStart =
                            startDateLuxon < now && duration.type === 'hour'
                                ? now
                                : startDateLuxon;
                        return Formatter.dayMonthYearHourMinute(
                            exactStart
                                .plus(
                                    toLuxonDuration(
                                        duration.type,
                                        duration.quantity,
                                    ),
                                )
                                .minus({ seconds: 1 }),
                        );
                    } else {
                        return Formatter.dayMonthYear(
                            startDateLuxon
                                .plus(
                                    toLuxonDuration(
                                        duration.type,
                                        duration.quantity,
                                    ),
                                )
                                .minus({ days: 1 }),
                        );
                    }
                }
            }
            return this.txt().DateNotAvailable();
        } else {
            return Formatter.dayMonthYearHourMinute(calc.validTo);
        }
    }

    renderNotification(): JSX.Element | null {
        const calc = this.state.calc.data;
        const quotasWrite =
            this.state.currentLogin.data?.permissions.quotasWrite || false;
        if (calc) {
            if (calc.lpsThatNeedWhitelist.length > 0) {
                return (
                    <LpsThatNeedWhitelistNotification
                        lpsThatNeedWhitelist={calc.lpsThatNeedWhitelist}
                        language={this.state.settings.language}
                        permitTypeId={this.state.config.permitTypeId}
                        update={this.props.allState.update}
                    />
                );
            } else if (calc.badgesThatNeedWhitelist.length > 0) {
                return (
                    <BadgesThatNeedWhitelistNotification
                        badgesThatNeedWhitelist={calc.badgesThatNeedWhitelist}
                        language={this.state.settings.language}
                        permitTypeId={this.state.config.permitTypeId}
                        update={this.props.allState.update}
                    />
                );
            } else if (calc.overlappingLpPermits.length > 0) {
                return (
                    <OverlappingEntitiesNotification
                        overlaps={calc.overlappingLpPermits}
                        language={this.state.settings.language}
                        identificationType="LicensePlate"
                    />
                );
            } else if (calc.overlappingBadgePermits.length > 0) {
                return (
                    <OverlappingEntitiesNotification
                        overlaps={calc.overlappingBadgePermits}
                        language={this.state.settings.language}
                        identificationType="Badge"
                    />
                );
            } else if (calc.badgesThatNeedAccount.length > 0) {
                return (
                    <BadgesThatNeedAccountNotification
                        badgesThatNeedAccount={calc.badgesThatNeedAccount}
                        language={this.state.settings.language}
                    />
                );
            } else if (calc.quotaInfo) {
                return (
                    <>
                        {calc.quotaInfo.map(infoItem => (
                            <QuotaReachedInfo
                                key={keyForQuotaInfo(infoItem)}
                                quotaInfo={infoItem}
                                quotasWrite={quotasWrite}
                            />
                        ))}
                    </>
                );
            }
        }
        return null;
    }

    checkQuotaApprovalRights(): boolean {
        const qutoaInfo = this.state.calc.data?.quotaInfo;
        const quotaOverlapping =
            qutoaInfo?.some(
                quotaInfo =>
                    quotaInfo.type ==
                    ContractQuotaInfoType.CONTRACT_PURCHASE_OVERLAPPING,
            ) || false;
        const quotaWrites =
            this.state.currentLogin.data?.permissions.quotasWrite;
        return !quotaOverlapping || Boolean(quotaWrites);
    }

    renderHeader(): JSX.Element {
        if (this.state.createResponse.pending || this.state.calc.pending) {
            return <LoaderHeader title={this.txt().HeaderCaption()} />;
        }

        if (
            this.state.createResponse.errorData ||
            (this.incompleteData() && this.state.layout.confirmPressedOnError)
        ) {
            return (
                <ErrorHeader
                    language={this.state.settings.language}
                    title={
                        <Localized
                            de="Fehler beim Erstellen der Bewilligung"
                            fr="Erreur lors de la création du permis"
                            it="Errore durante la creazione del permesso"
                            en="Error on creating the permit"
                        />
                    }
                    onCancel={() => this.update(abortPermitCreateProcess)}
                />
            );
        }

        return (
            <ConfirmSaveHeader
                language={this.state.settings.language}
                title={this.txt().HeaderCaption()}
                onCancel={() => this.update(abortPermitCreateProcess)}
                onSave={() => {
                    if (!this.checkQuotaApprovalRights()) {
                        // Do nothing
                    } else if (this.incompleteData()) {
                        this.update(store =>
                            PermitCreateState.Layout.stateWrite(store, {
                                confirmPressedOnError: true,
                            }),
                        );
                    } else {
                        this.update(store =>
                            createPermit(
                                store,
                                PermitCreateState.makePermitPayload(
                                    this.props.allState,
                                ) ?? undefined,
                            ),
                        );
                    }
                }}
                confirmButtonSpecialState={this.confirmButtonSpecialState()}
            />
        );
    }

    render() {
        const create = this.state.config;
        const updater = this.props.allState.update;
        const startDate =
            this.state.calc.data?.validFrom ?? this.state.config.startDate;
        const endDate =
            this.state.calc.data?.validTo ?? this.state.config.endDate;
        const type = this.state.permitType;
        const createTexts =
            PermitCreateTexts.permitTypeSelection[this.state.settings.language];

        return (
            <div>
                <Dialog
                    open={this.state.createResponse.errorData?.code === -710}
                >
                    <DialogTitle
                        sx={theme => ({
                            background: theme.palette['error'].main,
                            color: theme.palette['error'].contrastText,
                            paddingY: 1,
                        })}
                    >
                        <Localized
                            de={'Badge-Fehler'}
                            fr={'Erreur badge'}
                            it={'Errore badge'}
                            en={'Badge error'}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <p>
                            <Localized
                                de={
                                    'Es besteht ein Problem mit einem oder meheren badges.'
                                }
                                fr={
                                    'Il y a un problème avec un ou plusieurs badges.'
                                }
                                it={
                                    'Si è verificato un problema con uno o più badge.'
                                }
                                en={
                                    'There is a problem with one or more badges.'
                                }
                            />
                            <br />
                            {this.state.createResponse.errorData?.message}
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                updater(PermitCreateState.CreateResponse.reset)
                            }
                        >
                            <Localized de="OK" fr="OK" it="OK" en="OK" />
                        </Button>
                    </DialogActions>
                </Dialog>

                <SlideInBody disabled={false}>
                    <FullSlideInLeftColumn>
                        <SingleSelection
                            selection={this.state.permitType?.description ?? ''}
                            focused={this.state.layout.permitTypeSelectionOpen}
                            label={createTexts.HeaderHeading()}
                            onClick={() => {
                                this.update(store =>
                                    PermitCreateState.Layout.stateWrite(store, {
                                        permitTypeSelectionOpen: true,
                                    }),
                                );
                            }}
                            errorText={this.permitTypeErrorText()}
                        />
                        {type ? (
                            <div>
                                <SingleSelection
                                    focused={
                                        this.state.layout
                                            .validityFromDatePickerOpen
                                    }
                                    label={this.txt().StartDate()}
                                    onClick={() =>
                                        this.update(store =>
                                            PermitCreateState.Layout.stateWrite(
                                                store,
                                                {
                                                    validityFromDatePickerOpen:
                                                        true,
                                                },
                                            ),
                                        )
                                    }
                                    errorText={this.validityIntervalErrorText()}
                                    selection={
                                        startDate
                                            ? Formatter.dayMonthYear(startDate)
                                            : ' — '
                                    }
                                />
                                {this.state.config.startDate &&
                                    startDate &&
                                    Formatter.isoYearMonthDay(
                                        this.state.config.startDate,
                                    ) !==
                                        Formatter.isoYearMonthDay(
                                            startDate,
                                        ) && <ChangedValidFromDateWarning />}
                                <DurationField
                                    label={this.txt().Duration()}
                                    onClick={() =>
                                        this.update(store =>
                                            PermitCreateState.Layout.stateWrite(
                                                store,
                                                {
                                                    validityDurationPickerOpen:
                                                        true,
                                                },
                                            ),
                                        )
                                    }
                                    errorText={this.validityIntervalErrorText()}
                                    value={this.getDurationString()}
                                    durationsAvailable={this.selectableDurationsCount()}
                                />
                                <EndDateField
                                    label={this.txt().EndDate()}
                                    selectedValue={
                                        endDate
                                            ? endDate.isAfter(
                                                  moment('2030-01-01'),
                                              )
                                                ? this.txt().UntilRecall()
                                                : Formatter.dayMonthYear(
                                                      endDate,
                                                  )
                                            : null
                                    }
                                    calculatedValue={this.getValidToCalcString()}
                                    onClick={() =>
                                        this.update(store =>
                                            PermitCreateState.Layout.stateWrite(
                                                store,
                                                {
                                                    validityToDatePickerOpen:
                                                        true,
                                                },
                                            ),
                                        )
                                    }
                                    errorText={this.validityIntervalErrorText()}
                                    endDateSelectable={this.isEndDateSelectable()}
                                />
                                <LicensePlates
                                    allState={this.props.allState}
                                    canBeChanged={true}
                                    lps={this.state.config.licensePlates}
                                    maxIdentificationCount={type.maxLicenses}
                                    onIdentificationClick={(id: number) =>
                                        this.update(store =>
                                            LicensePlateState.Entity.edit(
                                                store,
                                                id,
                                            ),
                                        )
                                    }
                                    onIdentificationAction={(id: number) =>
                                        this.update(store =>
                                            removeLicensePlate(store, id),
                                        )
                                    }
                                    onAdd={() =>
                                        this.update(store =>
                                            LicensePlateState.AddTripple.stateWrite(
                                                store,
                                                { createLpOpen: true },
                                            ),
                                        )
                                    }
                                    showError={
                                        this.state.layout.confirmPressedOnError
                                    }
                                />
                                <Badges
                                    allState={this.props.allState}
                                    canBeChanged={true}
                                    badges={this.state.config.badges}
                                    maxIdentificationCount={type.maxLicenses}
                                    onIdentificationClick={() => null}
                                    onIdentificationAction={(id: number) =>
                                        this.update(store =>
                                            removeBadge(store, id),
                                        )
                                    }
                                    onAdd={() =>
                                        this.update(store =>
                                            BadgeState.Add.stateWrite(store, {
                                                createBadgeOpen: true,
                                            }),
                                        )
                                    }
                                    showError={
                                        this.state.layout.confirmPressedOnError
                                    }
                                />
                            </div>
                        ) : null}
                        {this.renderNotification()}
                        {type ? this.renderZones(type) : null}
                    </FullSlideInLeftColumn>

                    <FullSlideInRightColumn>
                        <LabeledText label={this.txt().Price()}>
                            {this.getPriceString()}
                        </LabeledText>

                        {this.state.operatorData.data
                            ?.requireCounterPaymentChannel && (
                            <CounterPaymentChannelEdit />
                        )}

                        <AddressSelection
                            address={this.state.config.address}
                            onEdit={() => this.update(editAddress)}
                            onClear={
                                this.state.config.address
                                    ? () => this.update(clearAddress)
                                    : null
                            }
                            disabled={false}
                            showRequiredError={
                                this.state.layout.confirmPressedOnError &&
                                PERMITTYPES_WITH_REQUIRED_ADDRESS.indexOf(
                                    this.state.permitType?.id ?? -1,
                                ) > -1
                            }
                        />

                        {(create.additionalInfo &&
                            create.additionalInfo !== '') ||
                        (type && type.additionalInfoMode === 'edit') ? (
                            <AdditionalInfos
                                value={create.additionalInfo || ''}
                                allState={this.props.allState}
                                onChange={(additionalInfo: string) =>
                                    updater(store =>
                                        PermitCreateState.Config.stateWrite(
                                            store,
                                            {
                                                additionalInfo,
                                            },
                                        ),
                                    )
                                }
                                disabled={
                                    !!type && type.additionalInfoMode === 'show'
                                }
                            />
                        ) : null}
                        <Remark
                            value={create.remark || ''}
                            allState={this.props.allState}
                            disabled={false}
                            onChange={(v: string) =>
                                updater(store =>
                                    PermitCreateState.Config.stateWrite(store, {
                                        remark: v,
                                    }),
                                )
                            }
                        />
                        {type && type.mubstBeLinkedToCustomerAccount && (
                            <Email
                                value={this.state.config.email}
                                onChange={(v: string) => {
                                    this.update(s => {
                                        const name =
                                            PermitCreateState.Config.stateWrite(
                                                s,
                                                { email: v },
                                            );
                                        PermitCreateState.Layout.stateWrite(s, {
                                            confirmPressedOnError: false,
                                        });
                                        return name;
                                    });
                                    this.resetCalc();
                                }}
                                error={lprEmailError(
                                    this.state.layout.confirmPressedOnError,
                                    this.state.calc.data || null,
                                    this.state.config.email || '',
                                )}
                            />
                        )}

                        {hasLicensePlateIdentification(
                            getIdentificationTypes(this.state),
                        ) &&
                            type &&
                            type.allowedLicensePlateTypes.filter(
                                t => t.needsVignette,
                            ).length > 0 && (
                                <SingleSelection
                                    focused={false}
                                    label={this.txt().VignetteToInvoice()}
                                    onClick={() =>
                                        this.update(store =>
                                            PermitCreateState.Layout.stateWrite(
                                                store,
                                                {
                                                    vignetteCountSelectionOpen:
                                                        true,
                                                },
                                            ),
                                        )
                                    }
                                    selection={
                                        this.state.config.vigetteAmount?.toString() ??
                                        null
                                    }
                                />
                            )}
                    </FullSlideInRightColumn>
                </SlideInBody>
                {this.renderHeader()}
            </div>
        );
    }
}

function lprEmailError(
    confirmPressedOnError: boolean,
    calcData: PermitCreateState.Calc.CalcData | null,
    inputValue: string,
): Message | null {
    if (!confirmPressedOnError) {
        return null;
    }

    if (!inputValue) {
        return {
            de: 'Pflichtfeld',
            fr: 'Champ obligatoire',
            it: 'Campo obbligatorio',
            en: 'Required field',
        };
    }

    if (calcData && calcData.emailIsNotALogin) {
        return {
            de: 'Diese E-Mail ist kein Parkingpay-Login oder hat die Registrierung nicht abgeschlossen',
            fr: "Cet e-mail n'est pas un login Parkingpay ou n'a pas terminé l'enregistrement",
            it: 'Questa e-mail non è un login Parkingpay o non ha completato la registrazione',
            en: 'This e-mail is not a Parkingpay login or has not completed the registration',
        };
    }

    return null;
}

function toLuxonDuration(durationType: DurationType, value: number): Duration {
    switch (durationType) {
        case 'hour':
            return Duration.fromObject({ hours: value });
        case 'day':
            return Duration.fromObject({ days: value });
        case 'week':
            return Duration.fromObject({ weeks: value });
        case 'month':
            return Duration.fromObject({ months: value });
        case 'year':
            return Duration.fromObject({ years: value });
        case 'date':
            console.error(
                `'date' is not a valid moment time unit. 'days' are used instead.`,
            );
            return Duration.fromObject({ days: value });
    }
}

function QuotaReachedInfo({
    quotaInfo,
    quotasWrite,
}: {
    quotaInfo: ContractQuotaInfos;
    quotasWrite: boolean;
}) {
    switch (quotaInfo.type) {
        case ContractQuotaInfoType.CONTRACT_PURCHASE_OVERLAPPING:
            return (
                <Notification
                    color={
                        quotasWrite
                            ? NotificationColor.blue
                            : NotificationColor.error
                    }
                    icon={NotificationIcon.info}
                    title={<ContractQuotaInfoPerPeriodTitle />}
                >
                    <ContractQuotaInfoPerPeriodBody />
                </Notification>
            );
        case ContractQuotaInfoType.CONTRACT_PURCHASE_PER_ENTITY: {
            const fullyReached = quotaInfo.quotaReachingEntities.every(
                e => e.daysLeft === 0,
            );
            return (
                <Notification
                    color={NotificationColor.blue}
                    icon={NotificationIcon.info}
                    title={
                        <ContractQuotaInfoPerEntityTitle
                            fullyReached={fullyReached}
                        />
                    }
                >
                    <ContractQuotaInfoPerEntityBody
                        quotaInfo={quotaInfo}
                        fullyReached={fullyReached}
                    />
                </Notification>
            );
        }
        default:
            console.error(
                `Unknown ContractQuotaInfo '${quotaInfo}'. Contract purchase is blocked without a user message.`,
            );
            return null;
    }
}

function ChangedValidFromDateWarning() {
    return (
        <Notification
            title={
                <Localized
                    de="Datum angepasst"
                    fr="Date adaptée"
                    it="Data modificata"
                    en="Date adjusted"
                />
            }
            color={NotificationColor.blue}
            icon={NotificationIcon.info}
        >
            <Localized
                de="Das ausgewählte Datum ist nicht verfügbar, daher wurde auf das nächste verfügbare Datum umgestellt."
                fr="La date sélectionnée n'est pas disponible, elle a donc été modifiée à la prochaine date disponible."
                it="La data selezionata non è disponibile, quindi è stata cambiata sulla prossima data disponibile."
                en="The selected date is not available, therefore it has been changed to the next available date."
            />
        </Notification>
    );
}

function ContractQuotaInfoPerEntityTitle({
    fullyReached,
}: {
    fullyReached: boolean;
}) {
    return fullyReached ? (
        <Localized
            de="Kontingent erschöpft"
            fr="Contingent épuisé"
            it="Contingente esaurito"
            en="Quota exhausted"
        />
    ) : (
        <Localized
            de="Ungenügendes Kontingent"
            fr="Contingent insuffisant"
            it="Contingente insufficiente"
            en="Insufficient quota"
        />
    );
}

function ContractQuotaInfoPerEntityBody({
    quotaInfo,
    fullyReached,
}: {
    quotaInfo: ContractQuotaInfosPerEntity;
    fullyReached: boolean;
}) {
    return (
        <>
            <p>
                <Localized
                    de={`Diese Bewilligungsart unterliegt einer Kontingentierung und kann daher vom Benutzer nur für ${
                        quotaInfo.days
                    } Tage ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'pro Monat'
                            : 'pro Jahr'
                    } erworben werden.`}
                    fr={`Ce type d'autorisation est soumis à un contingent et ne peut donc être acheté par l'utilisateur que pour ${
                        quotaInfo.days
                    } jours ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'par mois'
                            : 'par année'
                    }.`}
                    it={`Questo tipo di autorizzazione è soggetta a un contingente e quindi può essere acquistata dall'utente solo per ${
                        quotaInfo.days
                    } giorni ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'al mese'
                            : "all'anno"
                    }.`}
                    en={`This type of permit is subject to a quota and can therefore only be purchased by the user for ${
                        quotaInfo.days
                    } days ${
                        quotaInfo.period === 'CALENDAR_MONTH'
                            ? 'per month'
                            : 'per year'
                    }.`}
                />
            </p>
            {!fullyReached && (
                <>
                    <p>
                        <Localized
                            de={`Das Kontingent für die folgenden ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'Kennzeichen'
                                    : 'Badge'
                            } ist unzureichend:`}
                            fr={`Le contingent pour ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'les plaques suivantes'
                                    : 'les badges suivants'
                            } est insuffisant.`}
                            it={`Il contingente per ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'le seguenti targhe'
                                    : 'i seguenti badges'
                            } è insufficiente:`}
                            en={`The quota for the following ${
                                quotaInfo.entityType === 'LICENSE_PLATE'
                                    ? 'license plates'
                                    : 'badges'
                            } is insufficient:`}
                        />
                    </p>
                    <p>
                        {quotaInfo.quotaReachingEntities
                            .filter(e => e.notEnoughQuota)
                            .map(e => (
                                <div key={e.description}>
                                    <strong>{e.description}</strong>
                                    {' – '}
                                    {e.daysLeft > 0 ? (
                                        <Localized
                                            de={`${e.daysLeft} ${
                                                e.daysLeft > 1 ? 'Tage' : 'Tag'
                                            } verfügbar`}
                                            fr={`${e.daysLeft} ${
                                                e.daysLeft > 1
                                                    ? 'jours disponibles'
                                                    : 'jour disponible'
                                            }`}
                                            it={`${e.daysLeft} ${
                                                e.daysLeft > 1
                                                    ? 'giorni disponibili'
                                                    : 'giorno disponibile'
                                            }`}
                                            en={`${e.daysLeft} ${
                                                e.daysLeft > 1 ? 'days' : 'day'
                                            } available`}
                                        />
                                    ) : (
                                        <Localized
                                            de="verfügbare Tage verbraucht"
                                            fr="jours disponibles épuisés"
                                            it="giorni a disposizione esauriti"
                                            en="days available sold out"
                                        />
                                    )}
                                </div>
                            ))}
                    </p>
                </>
            )}
        </>
    );
}

function ContractQuotaInfoPerPeriodTitle() {
    return (
        <Localized
            de="Kontingent erschöpft"
            fr="Contingent épuisé"
            it="Contingente esaurito"
            en="Quota exhausted"
        />
    );
}

function ContractQuotaInfoPerPeriodBody() {
    return (
        <p>
            <Localized
                de="Das Kontingent für diese Bewilligung ist für mindestends einen Tag im ausgewählten Zeitraum erschöpft."
                fr="Le contingent pour cette autorisation est épuisé pour au moins un jour dans la période sélectionnée."
                it="Il contingente per questa autorizzazione è esaurito per almeno un giorno nel periodo selezionato."
                en="The quota for this permit is exhausted for at least one day in the selected period."
            />
        </p>
    );
}
