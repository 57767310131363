import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

export function init(dsn: string): void {
    Sentry.init({
        dsn: dsn,
        tunnel: '/ui-api/sentry-tunnel',
        integrations: [
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
    });
}
