import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { OperatorTypo } from '../OperatorTypo.ts';
import { ColorHex } from '../Colors.ts';
import { css } from '@emotion/css';
import { Localized } from '../../common/components/Localized.tsx';

export function Container(p: { children: React.ReactNode; menu: JSX.Element }) {
    return (
        <div
            className={css({
                ...OperatorTypo.body,
                fontSize: '12px',
                minHeight: '60px',
                display: 'flex',
                background: ColorHex.rgba(ColorHex.darkblue, 0.1),
                color: ColorHex.darkblue,
                alignItems: 'stretch',
            })}
        >
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                })}
            >
                <div
                    className={css({
                        padding: '0 24px',
                    })}
                >
                    <Localized
                        de="Suchkriterien"
                        fr="Critère de recherche"
                        it="Criterio di ricerca"
                        en="Search criteria"
                    />
                </div>
                <div
                    className={css({
                        display: 'flex',
                        flex: 1,
                        flexWrap: 'wrap',
                    })}
                >
                    {p.children}
                </div>
            </div>
            {p.menu && (
                <div
                    className={css({
                        display: 'flex',
                        paddingLeft: '22px',
                        marginRight: '22px',
                        borderLeft: `2px solid ${ColorHex.white}`,
                        alignItems: 'center',
                    })}
                >
                    <div
                        className={css({
                            position: 'relative',
                        })}
                    >
                        {p.menu}
                    </div>
                </div>
            )}
        </div>
    );
}

interface ButtonProps {
    label: React.ReactNode;
    onClick: () => void;
    selected: boolean;
}

export const Button = (p: ButtonProps): JSX.Element => {
    return (
        <Clickable
            element="div"
            className={css({
                background: p.selected ? ColorHex.darkblue : ColorHex.white,
                color: p.selected ? ColorHex.white : ColorHex.darkblue,
                height: '20px',
                padding: '2px 20px 0',
                marginRight: '3px',
                marginBottom: '3px',
                '&:hover': {
                    background: ColorHex.lightblue,
                    color: ColorHex.white,
                },
            })}
            onClick={p.onClick}
            disabled={p.selected}
        >
            {p.label}
        </Clickable>
    );
};
