import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as FilteredClearancesState from '../state/FilteredClearancesState.ts';
import * as ClearancePermitListState from '../state/ClearancePermitListState.ts';
import moment from 'moment';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { fromDateConstraint, toDateConstraint } from './shared.ts';

const an = ElementNamer('clearance-permit-list-FilteredClearancesActions');

export const setPermitTypes = (s: Flux.Store, pt: number[]): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        permitTypes: pt.length === 0 ? null : pt,
    });
    FilteredClearancesState.List.reset(s);
    return an('setPermitTypes');
};
export const clearPermitTypes = (s: Flux.Store): string => {
    FilteredClearancesState.Filter.stateWrite(s, { permitTypes: null });
    FilteredClearancesState.List.reset(s);
    return an('clearPermitTypes');
};

export const setPermits = (s: Flux.Store, v: string): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        permits: v,
        permitsSelectionVisible: false,
    });
    FilteredClearancesState.List.reset(s);
    return an('setPermits');
};
export const clearPermits = (s: Flux.Store): string => {
    FilteredClearancesState.Filter.stateWrite(s, { permits: null });
    FilteredClearancesState.List.reset(s);
    return an('clearPermits');
};

export const setOperatorLogins = (s: Flux.Store, ol: number[]): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        operatorLogins: ol.length === 0 ? null : ol,
    });
    FilteredClearancesState.List.reset(s);
    return an('setOperatorLogins');
};
export const clearOperatorLogins = (s: Flux.Store): string => {
    FilteredClearancesState.Filter.stateWrite(s, { operatorLogins: null });
    FilteredClearancesState.List.reset(s);
    return an('clearOperatorLogins');
};

export const setIssueDateFrom = (s: Flux.Store, d: moment.Moment): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        issueDateFrom: d,
        issueDateTo: toDateConstraint(
            d,
            FilteredClearancesState.Filter.get(s).issueDateTo,
        ),
    });
    FilteredClearancesState.List.reset(s);
    return an('setIssueDateFrom');
};
export const setIssueDateTo = (s: Flux.Store, d: moment.Moment): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        issueDateTo: d,
        issueDateFrom: fromDateConstraint(
            d,
            FilteredClearancesState.Filter.get(s).issueDateFrom,
        ),
    });
    FilteredClearancesState.List.reset(s);
    return an('setIssueDateTo');
};

export const setValidDateFrom = (s: Flux.Store, d: moment.Moment): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        validDateFrom: d,
        validDateTo: toDateConstraint(
            d,
            FilteredClearancesState.Filter.get(s).validDateTo,
        ),
    });
    FilteredClearancesState.List.reset(s);
    return an('setValidDateFrom');
};
export const setValidDateTo = (s: Flux.Store, d: moment.Moment): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        validDateTo: d,
        validDateFrom: fromDateConstraint(
            d,
            FilteredClearancesState.Filter.get(s).validDateFrom,
        ),
    });
    FilteredClearancesState.List.reset(s);
    return an('setValidDateTo');
};

export const clearIssueDate = (s: Flux.Store): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        issueDateFrom: null,
        issueDateTo: null,
    });
    FilteredClearancesState.List.reset(s);
    return an('clearIssueDate');
};

export const clearValidDate = (s: Flux.Store): string => {
    FilteredClearancesState.Filter.stateWrite(s, {
        validDateFrom: null,
        validDateTo: null,
    });
    FilteredClearancesState.List.reset(s);
    return an('clearIssueDate');
};

export const clearFilter = (s: Flux.Store) => {
    FilteredClearancesState.Filter.stateWrite(s, {
        permitTypes: null,
        operatorLogins: null,
        issueDateFrom: null,
        issueDateTo: null,
        permits: null,
        validDateFrom: null,
        validDateTo: null,
    });
    FilteredClearancesState.FilterSummary.stateWrite(s, { hover: false });
    FilteredClearancesState.List.reset(s);
    return an('clearFilter');
};

export const selectFilteredClearancesTab = (s: Flux.Store) => {
    ClearancePermitListState.Layout.stateWrite(s, {
        selectedTab: ClearancePermitListState.SelectedTab.FilteredClearances,
    });
    return an('selectFilteredClearancesTab');
};
