import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    IconButton40px,
    IconButton40pxType,
} from '../../ui/buttons/IconButton.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { download } from 'dg-web-shared/ui/icons/Icons16.tsx';
import * as ActivityStatementState from '../state/ActivityStatementState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { FilterSlideIn } from './FilterSlideIn.tsx';
import * as ActivityStatementTexts from '../i18n/ActivityStatementTexts.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import moment from 'moment';
import { Body, Container } from '../../ui/layout/SingleContent.tsx';
import {
    ColAlign,
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
    TableRow,
} from '../../ui/table/Table.tsx';
import { NoPermission } from '../../common/components/NoPermission.tsx';
import * as Http from '../../api/Http.ts';
import { BaseAbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { Icon16 } from '../../ui/icons/Icon.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

type ActivityMonth = ActivityStatementState.Data.ActivityMonth;

interface State {
    settings: SettingsState.State;
    layout: ActivityStatementState.Layout.State;
    filter: ActivityStatementState.Filter.State;
    data: ActivityStatementState.Data.State;
    currentLogin: CurrentOperatorLoginState.State;
}

interface SubProps extends State {
    update: Flux.Updater;
    auditReportColumn: ActivityStatementState.Data.AuditReportType;
    includesParkingAboProducts: boolean;
}

export interface Texts {
    Total: Translation;
    MonthCol: Translation;
    TransactionsCol: Translation;
    PermitsCol: Translation;
    ProductsCol: Translation;
    RefundsCol: Translation;
    InkassoCol: Translation;
    VersionCol: Translation;
    OthersCol: Translation;
    TotalCol: Translation;
    PdfCol: Translation;
    ExportCol: Translation;
    CurrentMonth: Translation;
}

const widths = {
    monthCol: ColumnWidth.variable,
    transactionCol: ColumnWidth._108px,
    permitsCol: ColumnWidth._108px,
    parkingAboProductCol: ColumnWidth._108px,
    refundsCol: ColumnWidth._108px,
    inkassoCol: ColumnWidth._108px,
    versionCol: ColumnWidth._108px,
    othersCol: ColumnWidth._108px,
    totalCol: ColumnWidth._108px,
    pdfCol: ColumnWidth._72px,
};

export const ActivityTableHeader = (p: SubProps) => {
    const txt = ActivityStatementTexts.texts[p.settings.language];
    return (
        <TableHeader>
            <EmptyTableHeaderColumn width={ColumnWidth._24px} />
            <TableHeaderColumn
                selected={true}
                direction={SortDirection.Descending}
                width={widths.monthCol}
                name={txt.MonthCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.transactionCol}
                name={txt.TransactionsCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.permitsCol}
                name={txt.PermitsCol()}
            />
            <Conditional c={p.includesParkingAboProducts}>
                <TableHeaderColumn
                    align={ColAlign.right}
                    width={widths.parkingAboProductCol}
                    name={txt.ProductsCol()}
                />
            </Conditional>
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.refundsCol}
                name={txt.RefundsCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.inkassoCol}
                name={txt.InkassoCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.versionCol}
                name={txt.VersionCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.othersCol}
                name={txt.OthersCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.totalCol}
                name={txt.Total()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={widths.pdfCol}
                name={txt.PdfCol()}
            />
            <Conditional c={p.auditReportColumn !== 'NONE'}>
                <TableHeaderColumn
                    align={ColAlign.right}
                    width={widths.pdfCol}
                    name={txt.ExportCol()}
                />
            </Conditional>
        </TableHeader>
    );
};

export interface MonthSum {
    transactions: number;
    permits: number;
    parkingAboProducts: number;
    refundCount: number;
    comission: number;
    other: number;
    calculatedBalance: number;
}

const monthSum = (months: ActivityMonth[]): MonthSum => {
    const acc: MonthSum = {
        transactions: 0,
        permits: 0,
        parkingAboProducts: 0,
        refundCount: 0,
        comission: 0,
        other: 0,
        calculatedBalance: 0,
    };
    const FLOAT_DIV = 1000;
    for (const m of months) {
        acc.transactions += m.transactions * FLOAT_DIV;
        acc.permits += m.permits * FLOAT_DIV;
        acc.parkingAboProducts += m.parkingAboProducts * FLOAT_DIV;
        acc.comission += m.comission * FLOAT_DIV;
        acc.other += m.other * FLOAT_DIV;
        acc.calculatedBalance += m.calculatedBalance * FLOAT_DIV;
        acc.refundCount += m.refundCount;
    }
    acc.transactions /= FLOAT_DIV;
    acc.permits /= FLOAT_DIV;
    acc.parkingAboProducts /= FLOAT_DIV;
    acc.comission /= FLOAT_DIV;
    acc.other /= FLOAT_DIV;
    acc.calculatedBalance /= FLOAT_DIV;
    return acc;
};

const currentMonth = (month: moment.Moment) =>
    month.month() === moment().month() && month.year() === moment().year();

const getPeriodString = (month: moment.Moment, lang: string) => {
    if (currentMonth(month)) {
        return ActivityStatementTexts.texts[lang].CurrentMonth();
    }
    return Formatter.monthAbrevYear(month, Formatter.getLocaleFromString(lang));
};

export const Row = (p: {
    month: ActivityMonth;
    language: string;
    includesParkingAboProducts: boolean;
}): JSX.Element => {
    const m = p.month;
    return (
        <DefaultTableRow key={m.period.toISOString()} hoverable={false}>
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={widths.monthCol} captionFontSize={true}>
                {getPeriodString(m.period, p.language)}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={widths.transactionCol}>
                {NumberFormatter.numberToPrice(m.transactions, false)}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={widths.permitsCol}>
                {NumberFormatter.numberToPrice(m.permits, false)}
            </TableColumn>
            <Conditional c={p.includesParkingAboProducts}>
                <TableColumn align={ColAlign.right} width={widths.permitsCol}>
                    {NumberFormatter.numberToPrice(m.parkingAboProducts, false)}
                </TableColumn>
            </Conditional>
            <TableColumn align={ColAlign.right} width={widths.refundsCol}>
                {!currentMonth(m.period) && !!m.refundCount ? (
                    <>
                        {m.refundCount}
                        <DownloadLink
                            href={`/ui-api/operator-account/refund-export/${m.period.format(
                                'YYYY-MM',
                            )}?language=${p.language}`}
                        />
                    </>
                ) : (
                    EmDash
                )}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={widths.inkassoCol}>
                {currentMonth(m.period)
                    ? EmDash
                    : NumberFormatter.numberToPrice(m.comission, false)}
                {!currentMonth(m.period) && (
                    <DownloadLink
                        href={`/ui-api/operator-account/commission-export/${m.period.format(
                            'YYYY-MM',
                        )}?language=${p.language}`}
                    />
                )}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={widths.versionCol}>
                {m.contractVersion == null
                    ? EmDash
                    : m.contractVersion.toUpperCase()}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={widths.othersCol}>
                {NumberFormatter.numberToPrice(m.other, false)}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={widths.totalCol}>
                <b>
                    {NumberFormatter.numberToPrice(m.calculatedBalance, false)}
                </b>
            </TableColumn>
            <TableColumn align={ColAlign.right} width={widths.pdfCol}>
                <Conditional c={m.hasEndOfMonthReport}>
                    <a
                        target="_blank"
                        className={css({
                            textDecoration: 'none',
                            color: ColorHex.darkblue,
                        })}
                        href={
                            m.period.isBefore(moment('2017-12-01'))
                                ? Http.OperatorAccount.ActivityStatement.pdfUrl(
                                      Formatter.isoYearMonth(m.period),
                                  )
                                : Http.OperatorAccount.ActivityStatement.newPdfUrl(
                                      m.period.year(),
                                      m.period.month() + 1,
                                  )
                        }
                        rel="noreferrer"
                    >
                        <IconTopOffsetContainer>
                            <Icon16 icon={Icons16.pdf} />
                        </IconTopOffsetContainer>
                    </a>
                </Conditional>
            </TableColumn>
            <Conditional c={m.specialReport !== 'NONE'}>
                <TableColumn align={ColAlign.right} width={widths.pdfCol}>
                    <a
                        target="_blank"
                        className="AcitvityStatementPdfLink"
                        href={Http.OperatorAccount.ActivityStatement.auditReport(
                            m.specialReport,
                            m.period.year(),
                            m.period.month() + 1,
                        )}
                        rel="noreferrer"
                    >
                        <Icon16 icon={Icons16.download} />
                    </a>
                </TableColumn>
            </Conditional>
        </DefaultTableRow>
    );
};

export const ActivityTableBody = (p: SubProps) => {
    const data = p.data.data.filter((m): boolean => {
        const { fromFilter, toFilter } = p.filter;
        if (isDefined(fromFilter) && m.period.isBefore(fromFilter)) {
            return false;
        }
        if (isDefined(toFilter) && m.period.isAfter(toFilter)) {
            return false;
        }
        return true;
    });
    const sum = monthSum(data);
    return (
        <TableBody>
            <TableRow
                className={css({
                    borderTop: `1px solid ${ColorHex.rgba(
                        ColorHex.lightblue,
                        0.1,
                    )}`,
                })}
                key={'sum'}
            >
                <TableColumn width={ColumnWidth._24px} />
                <TableColumn width={widths.monthCol} captionFontSize={true}>
                    <b>
                        {ActivityStatementTexts.texts[
                            p.settings.language
                        ].Total()}
                    </b>
                </TableColumn>
                <TableColumn
                    align={ColAlign.right}
                    width={widths.transactionCol}
                >
                    <b>
                        {NumberFormatter.numberToPrice(sum.transactions, false)}
                    </b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={widths.permitsCol}>
                    <b>{NumberFormatter.numberToPrice(sum.permits, false)}</b>
                </TableColumn>
                <Conditional c={p.includesParkingAboProducts}>
                    <TableColumn
                        align={ColAlign.right}
                        width={widths.parkingAboProductCol}
                    >
                        <b>
                            {NumberFormatter.numberToPrice(
                                sum.parkingAboProducts,
                                false,
                            )}
                        </b>
                    </TableColumn>
                </Conditional>
                <TableColumn align={ColAlign.right} width={widths.permitsCol}>
                    <b>{sum.refundCount || EmDash}</b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={widths.inkassoCol}>
                    <b>{NumberFormatter.numberToPrice(sum.comission, false)}</b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={widths.versionCol} />
                <TableColumn align={ColAlign.right} width={widths.othersCol}>
                    <b>{NumberFormatter.numberToPrice(sum.other, false)}</b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={widths.totalCol}>
                    <b>
                        {NumberFormatter.numberToPrice(
                            sum.calculatedBalance,
                            false,
                        )}
                    </b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={widths.pdfCol} />
                <Conditional c={p.auditReportColumn !== 'NONE'}>
                    <TableColumn align={ColAlign.right} width={widths.pdfCol} />
                </Conditional>
            </TableRow>
            {data.map((m, i) => (
                <Row
                    month={m}
                    language={p.settings.language}
                    key={`r${i}${m}`}
                    includesParkingAboProducts={p.includesParkingAboProducts}
                />
            ))}
        </TableBody>
    );
};

function DownloadLink(props: { href: string }) {
    return (
        <a
            target="_blank"
            className={css({
                display: 'inline-block',
                verticalAlign: 'bottom',
                textDecoration: 'none',
                marginLeft: '4px',
                marginBottom: '-3px',
                color: ColorHex.darkblue,
            })}
            href={props.href}
            rel="noreferrer"
        >
            <Icon16 icon={download} />
        </a>
    );
}

export class ActivityStatement extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ActivityStatementState.Layout.get(this.props.allState),
            filter: ActivityStatementState.Filter.get(this.props.allState),
            data: ActivityStatementState.Data.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    renderHeader(): JSX.Element {
        const locale = Formatter.getLocaleFromString(
            this.state.settings.language,
        );
        return (
            <div
                className={css({
                    position: 'absolute',
                    top: '0px',
                    width: '100%',
                    height: '60px',
                    paddingLeft: '24px',
                })}
            >
                <div
                    className={css({
                        float: 'left',
                        marginTop: '8px',
                        marginRight: '16px',
                    })}
                >
                    <IconButton40px
                        onClick={() =>
                            this.update(store =>
                                ActivityStatementState.Layout.stateWrite(
                                    store,
                                    { filterOpen: true },
                                ),
                            )
                        }
                        type={IconButton40pxType.datePicker}
                    />
                </div>
                <div
                    className={css({
                        marginTop: '20px',
                        ...Typo.captionC3,
                        color: ColorHex.darkblue,
                        float: 'left',
                    })}
                >
                    {Formatter.monthAbrevYear(
                        this.state.filter.fromFilter,
                        locale,
                    ) +
                        ' - ' +
                        Formatter.monthAbrevYear(
                            this.state.filter.toFilter,
                            locale,
                        )}
                </div>
            </div>
        );
    }

    getDependingStateSlices(): BaseAbstractLegacyServerState[] {
        return [this.state.data];
    }

    render() {
        if (
            isDefined(this.state.currentLogin.data) &&
            !this.state.currentLogin.data.permissions.activityStatement
        ) {
            return <NoPermission />;
        }
        const specialReportColumn =
            this.state.data.data.length > 0
                ? this.state.data.data[0].specialReport
                : 'NONE';
        const includesParkingAboProducts =
            this.state.data.data.reduce(
                (sum, activityMonth) => sum + activityMonth.parkingAboProducts,
                0,
            ) > 0.0;
        return (
            <Container>
                <Body
                    disabled={this.state.layout.filterOpen || false}
                    slideIns={<FilterSlideIn allState={this.props.allState} />}
                    dependingStateSlices={this.getDependingStateSlices()}
                >
                    {this.renderHeader()}
                    <div
                        className={css({
                            position: 'absolute',
                            top: '60px',
                            bottom: 0,
                            width: '100%',
                        })}
                    >
                        <Table>
                            <ActivityTableHeader
                                update={this.update}
                                auditReportColumn={specialReportColumn}
                                includesParkingAboProducts={
                                    includesParkingAboProducts
                                }
                                {...this.state}
                            />
                            <ActivityTableBody
                                auditReportColumn={specialReportColumn}
                                update={this.update}
                                includesParkingAboProducts={
                                    includesParkingAboProducts
                                }
                                {...this.state}
                            />
                        </Table>
                    </div>
                </Body>
            </Container>
        );
    }
}
