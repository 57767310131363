import {
    Box,
    Stack,
    SvgIconTypeMap,
    Typography,
    useTheme,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export function HeaderComponent({
    title,
    Icon,
    editMode,
    buttons,
}: {
    title: React.ReactNode;
    Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
    };
    buttons: JSX.Element;
    editMode: boolean;
}) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: theme.spacing(1.875, 3),
                color: editMode ? theme.palette.common.white : undefined,
                backgroundColor: editMode
                    ? theme.palette.blue.electric
                    : undefined,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {Icon && (
                    <Icon
                        sx={{
                            fontSize: 42,
                            color: theme.palette.blue.main,
                            marginRight: theme.spacing(2),
                        }}
                    />
                )}
                <Typography variant="h3" component="h1">
                    {title}
                </Typography>
            </Box>
            <Stack direction="row" alignItems="center" spacing={2}>
                {buttons}
            </Stack>
        </Box>
    );
}
