import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import superagent from 'superagent';

export enum TerminalProvider {
    VB = 'VB',
    PM = 'PM',
    SKIDATA_CLOUD = 'SKIDATA_CLOUD',
    SKIDATA_CLOUD_V7 = 'SKIDATA_CLOUD_V7',
    Sitax = 'Sitax',
    SB = 'SB',
    DESIGNA = 'DESIGNA',
    HUB_PARKING = 'HUB_PARKING',
    CLOUD_CONNECTOR = 'CLOUD_CONNECTOR',
}

export interface ParkingDTO {
    id: number;
    zipCode: string;
    offlineLimit: number | null;
    terminalId: string;
    entryLimit: number | null;
    permitOnly: boolean;
    city: string;
    name: string;
    pingWatchdog: boolean;
    vatNumber: string | null;
    terminalProvider: TerminalProvider | null;
    dtaAccountId: number | null;
    isOperatorStateActive: boolean;
    geodataText: string | null;
    hasLprReport: boolean;
    mcInstallationId: string | null;
    mcInstallationNr: string | null;
    mcInstallationName: string | null;
}

export type State = ServerStateSlice.ServerState<ParkingDTO[]>;

export const { get, reset, setResponse } = ServerStateSlice.generateServerState<
    ParkingDTO[]
>(
    'common-Parkings',
    () => [],
    (store: Flux.Store, state: State) => {
        if (state.shouldFetch) {
            store.update(fetchZones);
        }
    },
    (body): ParkingDTO[] => {
        return body?.map((z: ParkingDTO): ParkingDTO => z) ?? [];
    },
);

const fetchZones = AsyncRequest.request(
    () => {
        return superagent.get('/ui-api/operator-account/parkings');
    },
    (store: Flux.Store, res: Response): string => {
        setResponse(store, res);
        return 'ReferenceDataState-fetchParkings';
    },
);
