import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';

export interface State {
    selectedPermitId: Maybe<number>;
    selectedClearanceId: Maybe<number>;
}

export enum PermitSlideInConfirmation {
    terminate,
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'clearance-permit-list-ClearancePermitListState';
    }
    sideEffects(): void {
        return;
    }

    getInitialState(): State {
        return {
            selectedPermitId: null,
            selectedClearanceId: null,
        };
    }

    selectPermit(id: number | null): void {
        this.set((s: State): State => {
            s.selectedPermitId = id;
            return s;
        });
    }

    selectClearance(id: number | null): void {
        this.set((s: State): State => {
            s.selectedClearanceId = id;
            return s;
        });
    }
}

export enum SelectedTab {
    Search,
    FilteredPermits,
    FilteredClearances,
    ClearanceRequests,
}
export namespace Layout {
    export interface State {
        selectedTab?: SelectedTab;
    }
    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'clearance-permit-list-ClearancePermitListState.Layout',
        {
            selectedTab: SelectedTab.Search,
        },
    );
}
