import { Translation, Translations } from '../../../lib/Text.ts';

export interface ValidityIntervalTexts {
    FromDate: Translation;
    ToDate: Translation;
    ExpirationDate: Translation;
    Duration: Translation;
    Hours: (i: number) => string;
    Days: (i: number) => string;
    Weeks: (i: number) => string;
    Months: (i: number) => string;
    Years: (i: number) => string;
}

export const validityIntervalTexts: Translations<ValidityIntervalTexts> = {
    de: {
        FromDate: () => 'Gültig ab',
        ToDate: () => 'Gültig bis',
        ExpirationDate: () => 'Gültig bis',
        Duration: () => 'Dauer',
        Hours: (i: number) => (i === 1 ? `Stunde` : `Stunden`),
        Days: (i: number) => (i === 1 ? 'Tag' : 'Tage'),
        Weeks: (i: number) => (i === 1 ? 'Woche' : 'Wochen'),
        Months: (i: number) => (i === 1 ? 'Monat' : 'Monate'),
        Years: (i: number) => (i === 1 ? 'Jahr' : 'Jahre'),
    },

    fr: {
        Hours: (i: number) => (i === 1 ? `heure` : `heures`),
        FromDate: () => 'Début',
        ToDate: () => 'Fin',
        ExpirationDate: () => 'Fin',
        Duration: () => 'Durée',
        Days: (i: number) => (i === 1 ? 'jour' : 'jours'),
        Weeks: (i: number) => (i === 1 ? 'semaine' : 'semaines'),
        Months: (i: number) => (i === 1 ? 'mois' : 'mois'),
        Years: (i: number) => (i === 1 ? 'année' : 'ans'),
    },

    it: {
        Hours: (i: number) => (i === 1 ? `ora` : `ore`),
        FromDate: () => 'Inizio',
        ToDate: () => 'Fine',
        ExpirationDate: () => 'Fine',
        Duration: () => 'Durata',
        Days: (i: number) => (i === 1 ? 'giorno' : 'giorni'),
        Weeks: (i: number) => (i === 1 ? 'settimana' : 'settimane'),
        Months: (i: number) => (i === 1 ? 'mese' : 'mesi'),
        Years: (i: number) => (i === 1 ? 'anno' : 'anni'),
    },

    en: {
        Hours: (i: number) => (i === 1 ? `hour` : `hours`),
        FromDate: () => 'Start',
        ToDate: () => 'End',
        ExpirationDate: () => 'End',
        Duration: () => 'Duration',
        Days: (i: number) => (i === 1 ? 'day' : 'day'),
        Weeks: (i: number) => (i === 1 ? 'week' : 'weeks'),
        Months: (i: number) => (i === 1 ? 'month' : 'months'),
        Years: (i: number) => (i === 1 ? 'year' : 'years'),
    },
};
