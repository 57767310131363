import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { css } from '@emotion/css';
import { Icon16 } from '../../ui/icons/Icon.tsx';
import { licensePlateType } from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

interface LicensePlate {
    licensePlateNr: string;
    type: LicensePlateType;
    country: string;
}

export const LicensePlateItem = (p: { lp: LicensePlate }) => (
    <div className={css({ position: 'relative' })}>
        {p.lp.licensePlateNr}
        <div
            className={css({
                ...Typo.captionC2,
                position: 'absolute',
                right: '36px',
                top: 0,
            })}
        >
            <span>{p.lp.country} </span>
            <div
                className={css({
                    marginLeft: '4px',
                    display: 'inline-block',
                    position: 'relative',
                    top: '2px',
                })}
            >
                <Icon16
                    icon={
                        p.lp.type === LicensePlateType.MOTORCYCLE
                            ? licensePlateType.motorcycle
                            : licensePlateType.car
                    }
                />
            </div>
        </div>
    </div>
);
