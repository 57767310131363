import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import {
    StringSingleSelectionSlideIn,
    Option,
} from '../../ui/slidein/SingleSelectionSlideIn.tsx';
import * as MetaState from '../../common/state/MetaState.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as AdditionalAddressEditSlideInText from '../i18n/AdditionalAddressEditSlideInText.ts';
import * as AddressState from '../state/AddressState.ts';
import * as PermitCreateState from '../state/PermitCreateState.ts';
import { thenElse, getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';

export interface Texts {
    Heading: Translation;
    Caption: Translation;
}

interface State {
    settings: SettingsState.State;
    countries: MetaState.AddressCountries.State;
    edit: AddressState.Edit.State;
    config: PermitCreateState.Config.State;
}

interface Props extends Flux.ContainerProps {
    onSelect: (v: string) => void;
    onLeftActionClick: () => void;
    open: boolean;
}

export const setAddressCountry = (store: Flux.Store, country: string) => {
    AddressState.Edit.writeAddress(store, { country: country });
    AddressState.Edit.stateWrite(store, { selectAddressCountryOpen: false });
    return 'AddressCountrySelectionSlideIn-setCountry';
};

export class AddressCountrySelectionSlideIn extends Flux.ContainerWithProps<
    Props,
    State
> {
    static displayName = 'AddressCountrySelectionSlideIn';
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            countries: MetaState.AddressCountries.get(this.props.allState),
            edit: AddressState.Edit.get(this.props.allState),
            config: PermitCreateState.Config.get(this.props.allState),
        };
    }
    txt(): Texts {
        return AdditionalAddressEditSlideInText.countrySelection[
            this.state.settings.language
        ];
    }

    renderOptions(): Option<string>[] {
        return this.state.countries.data.map((t: MetaState.AddressCountry) => {
            return {
                id: t.id,
                displayText: `${t.id} — ${
                    t.name[this.state.settings.language]
                }`,
            };
        });
    }

    render() {
        const defaultCountry = 'CH';
        const selection = getOrElse(
            this.state.edit.address.country,
            thenElse(
                this.state.config.address,
                c => getOrElse(c.country, defaultCountry),
                defaultCountry,
            ),
        );
        return (
            <StringSingleSelectionSlideIn
                open={this.props.open}
                heading={this.txt().Heading()}
                selection={selection}
                options={this.renderOptions()}
                onSelect={this.props.onSelect}
                onLeftActionClick={this.props.onLeftActionClick}
                outsideBody
            />
        );
    }
}
