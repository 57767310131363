import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import {
    HalfSlideIn,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ParkTransactionsState from '../state/ParkTransactionsState.ts';
import { DateFilterSelection } from './DateFilter.tsx';
import { TomsFilterSelection } from '../../tom/components/TomFilter.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import { filterHalfSlideInTexts } from '../i18n/ParkTransactionsTexts.ts';
import {
    clearToms,
    openTomsFilter,
} from '../actions/ParkTransactionsActions.ts';

export interface FilterHalfSlideInTexts {
    title: Translation;
}

interface FilterHalfSlideInState {
    settings: SettingsState.State;
    filter: ParkTransactionsState.Filter.State;
}

export class FilterHalfSlideIn extends Flux.Container<FilterHalfSlideInState> {
    stateSelector(): FilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: ParkTransactionsState.Filter.get(this.props.allState),
        };
    }

    render() {
        return (
            <HalfSlideIn open={this.state.filter.filterSlideInVisible}>
                <StandardFirstLevelHeader
                    onClose={() =>
                        this.props.allState.update(store =>
                            ParkTransactionsState.Filter.stateWrite(store, {
                                filterSlideInVisible: false,
                            }),
                        )
                    }
                    title={filterHalfSlideInTexts[
                        this.state.settings.language
                    ].title()}
                />
                <SlideInBody>
                    <DateFilterSelection allState={this.props.allState} />
                    <TomsFilterSelection
                        selectedToms={this.state.filter.toms}
                        onOpenSlideIn={() => this.update(openTomsFilter)}
                        onClear={() => this.update(clearToms)}
                        allState={this.props.allState}
                    />
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}
