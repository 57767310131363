import moment from 'moment';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { css } from '@emotion/css';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import { ColorHex } from '../../../ui/Colors.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';

interface DateHeaderProps {
    label: string;
    date: moment.Moment | undefined | null;
}

export const DateHeader = (p: DateHeaderProps) => (
    <div className={css({ marginBottom: '8px' })}>
        <span
            className={css({
                ...OperatorTypo.headingOne,
                color: ColorHex.rgba(ColorHex.darkblue, p.date ? 1 : 0.6),
            })}
        >
            {p.label}
        </span>{' '}
        <span
            className={css({
                ...OperatorTypo.headingOne,
                ...Typo.robotoMedium,
                color: ColorHex.darkblue,
            })}
        >
            {thenElse(p.date, d => Formatter.dayMonthYear(d), '')}
        </span>
    </div>
);
