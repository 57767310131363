import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import {
    BadgesThatNeedAccountNotification,
    BadgesThatNeedWhitelistNotification,
    LpsThatNeedWhitelistNotification,
    OverlappingEntitiesNotification,
} from './SharedNotifications.tsx';

export function LicensePlateNotifications(): JSX.Element | null {
    const { storeState, update } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        server: PermitEditState.Server.get(s),
        validation: PermitEditState.Validation.get(s),
    }));

    const data = storeState.validation.data;
    const server = storeState.server.data;

    if (!!data && !!server) {
        if (data) {
            if (data.lpsThatNeedWhitelist.length > 0) {
                return (
                    <LpsThatNeedWhitelistNotification
                        lpsThatNeedWhitelist={data.lpsThatNeedWhitelist}
                        language={storeState.settings.language}
                        permitTypeId={server.permitTypeId}
                        update={update}
                    />
                );
            } else if (data.badgesThatNeedWhitelist.length > 0) {
                return (
                    <BadgesThatNeedWhitelistNotification
                        badgesThatNeedWhitelist={data.badgesThatNeedWhitelist}
                        language={storeState.settings.language}
                        permitTypeId={server.permitTypeId}
                        update={update}
                    />
                );
            } else if (data.overlappingLpPermits.length > 0) {
                return (
                    <OverlappingEntitiesNotification
                        overlaps={data.overlappingLpPermits}
                        language={storeState.settings.language}
                        identificationType="LicensePlate"
                    />
                );
            } else if (data.overlappingBadgePermits.length > 0) {
                return (
                    <OverlappingEntitiesNotification
                        overlaps={data.overlappingBadgePermits}
                        language={storeState.settings.language}
                        identificationType="Badge"
                    />
                );
            } else if (data.badgesThatNeedAccount.length > 0) {
                return (
                    <BadgesThatNeedAccountNotification
                        badgesThatNeedAccount={data.badgesThatNeedAccount}
                        language={storeState.settings.language}
                    />
                );
            }
        }
    }

    return null;
}
