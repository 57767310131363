import { TextColumnContent, TextLeftColumn } from '../../ui/layout/Text.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { guideTexts } from '../i18n/ParkTransactionsTexts.ts';

interface GuideProps {
    login: CurrentOperatorLoginState.State;
    language: string;
}

export interface Texts {
    Header: Translation;
    Intro: Translation;
    ListDescription: Translation;
    ValidDate: Translation;
    ParkingMeter: Translation;
}

export const Guide = (p: GuideProps) => {
    const login = p.login.data;
    const texts = guideTexts[p.language];
    if (isDefined(login)) {
        return (
            <TextColumnContent>
                <TextLeftColumn>
                    <h1>{texts.Header()}</h1>
                    <h3>{texts.Intro()}</h3>
                    <h3>{texts.ListDescription()}</h3>
                    <ul>
                        <li>{texts.ValidDate()}</li>
                        <li>{texts.ParkingMeter()}</li>
                    </ul>
                </TextLeftColumn>
            </TextColumnContent>
        );
    } else {
        return <noscript />;
    }
};
