import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Icon16 } from '../icons/Icon.tsx';
import { Option as OptionBase } from './Option.ts';
import {
    HalfSlideIn,
    SecondLevelHeader,
    SlideInBody,
    SlideInHeaderTexts,
    SlideInInfoBox,
} from './Slidein.tsx';
import { SubheaderContainer } from './SlideInSubheader.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { css } from '@emotion/css';
import { OperatorTypo } from '../OperatorTypo.ts';
import { ColorHex } from '../Colors.ts';
import React from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export interface Option<I> extends OptionBase<I> {
    hasSpacingBottom?: boolean;
}

interface Props<I> {
    open: boolean;
    caption?: string;
    heading: React.ReactNode;
    selection: I[];
    options: Option<I>[];
    onLeftActionClick: () => void;
    setNewSelection: (selection: I[]) => void;
    outsideBody?: boolean;
    infoText?: JSX.Element | null;
}

class GenericMultiSelectionSlideIn<I> extends React.Component<Props<I>> {
    static displayName = 'MultiSelectionSlideIn';

    toggleItem(id: I): void {
        if (this.props.selection.indexOf(id) > -1) {
            this.props.setNewSelection(
                this.props.selection.filter(i => i !== id),
            );
        } else {
            const copy = this.props.selection.slice();
            copy.push(id);
            this.props.setNewSelection(copy);
        }
    }

    showAll(): boolean {
        return this.props.selection.length !== this.props.options.length;
    }

    toggleAllNone(): void {
        if (this.showAll()) {
            this.props.setNewSelection(
                this.props.options.filter(o => !o.disabled).map(o => o.id),
            );
        } else {
            this.props.setNewSelection(
                this.props.options
                    .filter(
                        o => o.disabled && this.props.selection.includes(o.id),
                    )
                    .map(o => o.id),
            );
        }
    }

    renderElements(): JSX.Element[] {
        return this.props.options.map((o: Option<I>, key: number) => {
            const selected = this.props.selection.indexOf(o.id) > -1;
            return (
                <Clickable
                    key={`clickable-${key}`}
                    element="div"
                    disabled={o.disabled}
                    className={css([
                        {
                            ...OperatorTypo.headingOne,
                            fontWeight: 500,
                            lineHeight: '1.5em',
                            color: ColorHex.rgba(
                                ColorHex.darkblue,
                                o.disabled ? 0.4 : 0.8,
                            ),
                            background: ColorHex.rgba(ColorHex.darkblue, 0.2),
                            padding: '4px 48px',
                            margin: '0 -48px',
                            '&:hover': o.disabled
                                ? undefined
                                : {
                                      background: ColorHex.darkblue,
                                      color: ColorHex.white,
                                  },
                        },
                        !selected && {
                            background: 'none',
                            '&:hover': o.disabled
                                ? undefined
                                : {
                                      background: ColorHex.darkblue,
                                      color: ColorHex.white,
                                  },
                        },
                        o.hasSpacingBottom && {
                            marginBottom: '25px',
                        },
                    ])}
                    onClick={() => !o.disabled && this.toggleItem(o.id)}
                >
                    <div
                        className={css({
                            position: 'relative',
                            top: '3px',
                            marginRight: '16px',
                            display: 'inline-block',
                            verticalAlign: 'baseline',
                        })}
                    >
                        <Icon16
                            icon={
                                selected
                                    ? Icons16.checkbox.on
                                    : Icons16.checkbox.off
                            }
                        />
                    </div>
                    <span>{o.displayText}</span>
                </Clickable>
            );
        });
    }

    render() {
        return (
            <HalfSlideIn
                open={this.props.open}
                outsideBody={this.props.outsideBody}
            >
                <SecondLevelHeader onClose={this.props.onLeftActionClick}>
                    <SlideInHeaderTexts
                        subtitle={this.props.caption}
                        title={this.props.heading}
                        hasLeftIcon={false}
                    />
                </SecondLevelHeader>
                <SubheaderContainer>
                    <Clickable
                        element="div"
                        className={css({
                            ...Typo.captionC1,
                            color: ColorHex.rgba(ColorHex.darkblue, 0.7),
                            paddingTop: '2px',
                        })}
                        onClick={() => this.toggleAllNone()}
                    >
                        <div
                            className={css({
                                position: 'relative',
                                top: '4px',
                                marginRight: '16px',
                                display: 'inline-block',
                                verticalAlign: 'baseline',
                            })}
                        >
                            <Icon16
                                icon={
                                    this.showAll()
                                        ? Icons16.checkbox.all
                                        : Icons16.checkbox.none
                                }
                            />
                        </div>
                        <span>
                            {this.showAll() ? (
                                <Localized
                                    de="alle"
                                    fr="tous"
                                    it="tutti"
                                    en="all"
                                />
                            ) : (
                                <Localized
                                    de="keine"
                                    fr="aucunes"
                                    it="nessuno"
                                    en="none"
                                />
                            )}
                        </span>
                        <Conditional c={this.showAll()}>
                            <span className={css({ ...Typo.captionC2 })}>
                                {' ' + this.props.options.length}
                            </span>
                        </Conditional>
                    </Clickable>
                </SubheaderContainer>
                <SlideInBody hasSubheader>
                    {this.props.infoText && (
                        <SlideInInfoBox>{this.props.infoText}</SlideInInfoBox>
                    )}
                    {this.renderElements()}
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}

// generic components crap, see https://github.com/Microsoft/TypeScript/issues/3960

export class NumberMultiSelectionSlideIn extends GenericMultiSelectionSlideIn<number> {}

export class StringMultiSelectionSlideIn extends GenericMultiSelectionSlideIn<string> {}
