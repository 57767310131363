import { css } from '@emotion/css';

import { isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Icon16 } from '../icons/Icon.tsx';
import { BelowContent, Content, Label, LabeledElement } from './Internal.tsx';

export enum LabeledTextStatus {
    enabled,
    disabled,
}

interface LabeledTextProps {
    label: React.ReactNode;
    toolTip?: React.ReactNode;
    toolTipRight?: boolean;
    children?: React.ReactNode;
    status?: Maybe<LabeledTextStatus>;
}

export const LabeledTextStatusIcon = (p: {
    status: Maybe<LabeledTextStatus>;
}) => {
    return (
        <Conditional c={isDefined(p.status)}>
            <div
                className={css({
                    color: 'rgba($c-darkblue, 0.8)',
                    '&[data-disabled="true"]': {
                        color: 'rgba($c-darkblue, 0.3)',
                    },
                    position: 'absolute',
                    right: 0,
                    top: 16,
                })}
                data-disabled={p.status === LabeledTextStatus.disabled}
            >
                <Icon16
                    icon={
                        p.status === LabeledTextStatus.enabled
                            ? Icons16.node.on
                            : Icons16.node.off
                    }
                />
            </div>
        </Conditional>
    );
};

export const LabeledText = (p: LabeledTextProps): JSX.Element => {
    return (
        <LabeledElement>
            <Label
                label={p.label}
                focused={false}
                hovered={false}
                disabled={p.status === LabeledTextStatus.disabled}
                toolTip={p.toolTip}
                toolTipRight={p.toolTipRight}
            />
            <LabeledTextStatusIcon status={p.status} />
            <Content
                focused={false}
                hovered={false}
                empty={false}
                userSelectable={true}
            >
                {p.children}
            </Content>
            <BelowContent
                underline={false}
                focused={false}
                hovered={false}
                errorText={null}
            />
        </LabeledElement>
    );
};
