import * as OperatorLoginType from '../../app/state/OperatorLoginType.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { LoginsGroup } from '../../operator-logins/components/OperatorLoginsList.tsx';

export interface Permissions {
    operatorAppActive: boolean;
    enforcementAppActive: boolean;
    parkingpayModule: boolean;
    taxomexModule: boolean;
    transactions: boolean;
    permitsRead: boolean;
    permitsWrite: boolean;
    activityStatement: boolean;
    whitelistCreate: boolean;
    whitelistDelete: boolean;
    userPermissionEdit: boolean;
    enforcementLog: boolean;
    syncApp: boolean;
    usbStickEdit: boolean;
    parkingMeterEdit: boolean;
    parkingMeterAlerts: boolean;
    twintQrCodePairing: boolean;
    refundParkTransactions: boolean;
    refundPermits: boolean;
    quotasWrite: boolean;
    parkingaboInitialBalanceCreate: boolean;
    cloudConnectorOffstreetDevicesView: boolean;
    cloudConnectorRecodeTicket: boolean;
    cloudConnectorAlerts: boolean;
    cloudConnectorReportingAccess: boolean;
}

export interface OperatorLogin {
    operatorLoginId: number;
    operatorId: number;
    operatorLoginType: OperatorLoginType.OperatorLoginType;
    allowedPermitTypes: number[];
    allowedProductTypes: number[];
    allowedZones: number[];
    allowedRecodeConditions: string[];
    permissions: Permissions;
    firstName: string;
    lastName: string;
    username: string;
    zipCode: Maybe<string>;
    description: Maybe<string>;
    softAnonymizeCheckinPublicPermitAfterDays: number;
    city: Maybe<string>;
    telnr: Maybe<string>;
    email: Maybe<string>;
    editableMetaInfo: boolean;
    editablePassword: boolean;
}

export namespace List {
    export type State = ServerStateSlice.ServerState<OperatorLogin[]>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parseOperatorLogin = (l: any): OperatorLogin => {
        return {
            ...l,
            operatorLoginType: OperatorLoginType.fromString(
                l.operatorLoginType,
            ),
        };
    };

    export const { get, reset, setResponse } =
        ServerStateSlice.generateServerState<OperatorLogin[]>(
            'operator-logins-OperatorLogins',
            () => [],
            (store: Flux.Store, state: State) => {
                if (state.shouldFetch) {
                    store.update(fetchOperatorLogins);
                }
            },
            body => body.map(parseOperatorLogin),
        );

    const fetchOperatorLogins = AsyncRequest.request(
        Http.OperatorAccount.OperatorLogins.logins,
        setResponse,
    );
}

const filterLoginsByType = (
    logins: OperatorLogin[],
    type: OperatorLoginType.OperatorLoginType,
): OperatorLogin[] => logins.filter(l => l.operatorLoginType === type);

export const administatorLogin = (logins: OperatorLogin[]): OperatorLogin =>
    filterLoginsByType(logins, OperatorLoginType.OperatorLoginType.Admin)[0];

export const userLogins = (
    logins: OperatorLogin[],
    group: LoginsGroup,
): OperatorLogin[] =>
    filterLoginsByType(logins, OperatorLoginType.OperatorLoginType.User).filter(
        login => {
            const isActive =
                login.permissions.enforcementAppActive ||
                login.permissions.operatorAppActive;
            switch (group) {
                case LoginsGroup.SUPER_USER: {
                    return isActive && login.permissions.userPermissionEdit;
                }
                case LoginsGroup.ACTIVE:
                    return isActive && !login.permissions.userPermissionEdit;
                case LoginsGroup.INACTIVE:
                    return !isActive;
            }
        },
    );

export const getLoginById = (
    logins: OperatorLogin[],
    id: number,
): Maybe<OperatorLogin> => logins.find(l => l.operatorLoginId === id);

export const getLoginTitle = (l: OperatorLogin): string =>
    `${l.lastName} ${l.firstName}`;
