import moment from 'moment';

import { Formatter, Parser } from 'dg-web-shared/lib/Date.ts';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import * as OperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import {
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import * as PermitSlideInTexts from '../i18n/PermitSlideInTexts.ts';
import * as AddressState from '../state/AddressState.ts';
import * as PermitEditState from '../state/PermitEditState.ts';
import {
    Badge,
    LicensePlate,
    OnstreetZoneType,
    Permit,
    Zone,
    zoneIds,
} from '../state/PermitEditState.ts';
import { AddressSelection } from './Address.tsx';
import { CounterPaymentChannel } from './CounterPaymentChannel.tsx';
import { LicensePlateNotifications } from './LicensePlateNotifications.tsx';
import { AdditionalInfos, Badges, LicensePlates, Remark } from './Shared.tsx';
import {
    MiniTable,
    MiniTableElement,
} from '../../ui/labeled-elements/MiniTable.tsx';
import { ZonesSelection } from './ZoneSelectionSlideIn.tsx';
import { CLEARANCE_PERMIT_SLIDEIN_ID } from '../../clearance-permit-list/components/ClearancePermitList.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { useState } from 'react';
import { NicerPaymentChannel } from '../../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import ReactDOM from 'react-dom';

export function PermitVersion(props: {
    disabled: boolean;
    canBeChanged: boolean;
    remarkCanBeChanged: boolean;
    permit: Permit;
    changes: {
        licensePlates?: LicensePlate[];
        badges?: Badge[];
        onstreetZones?: PermitEditState.OnstreetZone[];
        offstreetZones?: PermitEditState.OffstreetZone[];
        additionalInfo?: string;
        remark?: string;
        address?: Partial<AddressState.PermitAddress>;
        addressId?: number;
    };
    onEditLicensePlate: (id: number) => void;
    onEditZones: (zones: Zone[]) => void;
    onRemoveLicensePlate: (id: number) => void;
    onAddLicensePlate: () => void;
    onRemoveBadge: (id: number) => void;
    onAddBadge: () => void;
    onEditAddress: () => void;
    onClearAddress: () => void;
    onChangeAdditionalInfo: (v: string) => void;
    onChangeRemark: (v: string) => void;
}) {
    const { storeState, store } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    }));

    const txt = PermitSlideInTexts.body[storeState.settings.language];

    const versionBaseStyle = css({
        color: ColorHex.rgba(ColorHex.darkblue, 0.6),
        ...Typo.captionC1,
    });

    const versionCaptionStyle = css({ ...Typo.captionC2 });

    return (
        <SlideInBody disabled={props.disabled}>
            <FullSlideInLeftColumn>
                <LabeledText label={txt.PermitType()}>
                    {props.permit.permitTypeDescription}
                </LabeledText>

                <LabeledText label={txt.StartDate()}>
                    {Formatter.dayMonthYearHourMinute(
                        Parser.isoToMoment(props.permit.validFrom),
                    )}
                </LabeledText>

                <LabeledText label={txt.EndDate()}>
                    {Parser.isoToMoment(props.permit.validTo).isAfter(
                        moment('2030-01-01'),
                    )
                        ? txt.UntilRecall()
                        : Formatter.dayMonthYearHourMinute(
                              Parser.isoToMoment(props.permit.validTo),
                          )}
                </LabeledText>

                <LicensePlates
                    lps={
                        props.changes.licensePlates ??
                        props.permit.licensePlates
                    }
                    canBeChanged={props.canBeChanged}
                    maxIdentificationCount={props.permit.maxLicensePlates}
                    onIdentificationClick={props.onEditLicensePlate}
                    onIdentificationAction={props.onRemoveLicensePlate}
                    onAdd={props.onAddLicensePlate}
                    allState={store}
                    showError={false}
                />
                <Badges
                    badges={props.changes.badges ?? props.permit.badges}
                    canBeChanged={props.canBeChanged}
                    maxIdentificationCount={props.permit.maxLicensePlates}
                    onIdentificationClick={() => null}
                    onIdentificationAction={props.onRemoveBadge}
                    onAdd={props.onAddBadge}
                    allState={store}
                    showError={false}
                />

                <LicensePlateNotifications />

                <PermitZones
                    canBeChanged={props.canBeChanged}
                    permitTypeId={props.permit.permitTypeId}
                    onEditZones={props.onEditZones}
                    onstreetZoneType={props.permit.onstreetZoneType}
                    onstreetZones={
                        props.changes.onstreetZones ??
                        props.permit.onstreetZones
                    }
                    offstreetZones={
                        props.changes.offstreetZones ??
                        props.permit.offstreetZones
                    }
                />
            </FullSlideInLeftColumn>
            <FullSlideInRightColumn>
                <LabeledText label={txt.Price()}>
                    {PermitEditState.getPermitPrice(
                        props.permit,
                        txt.RefundedNote,
                        storeState.settings.language,
                    )}
                </LabeledText>

                {!!props.permit.counterPaymentChannel && (
                    <CounterPaymentChannel
                        counterPaymentChannel={
                            props.permit.counterPaymentChannel
                        }
                    />
                )}

                {props.permit.priceModifier && (
                    <LabeledText label={txt.PriceMultiplier()}>
                        {props.permit.priceModifier}
                    </LabeledText>
                )}

                <AddressSelection
                    address={
                        props.changes.addressId !== 0
                            ? ((props.changes.address ??
                                  props.permit.address) as AddressState.Address)
                            : undefined
                    }
                    onEdit={props.onEditAddress}
                    disabled={!props.canBeChanged}
                    showRequiredError={false}
                    onClear={
                        !!props.changes.address || !!props.permit.address
                            ? props.onClearAddress
                            : null
                    }
                />

                {(!!props.permit.additionalInfo &&
                    props.permit.additionalInfo !== '') ||
                props.permit.permittypeAdditionalInfoMode === 'edit' ? (
                    <AdditionalInfos
                        value={
                            props.changes.additionalInfo !== undefined
                                ? props.changes.additionalInfo
                                : props.permit.additionalInfo || ''
                        }
                        allState={store}
                        onChange={props.onChangeAdditionalInfo}
                        disabled={
                            !props.canBeChanged ||
                            props.permit.permittypeAdditionalInfoMode === 'show'
                        }
                    />
                ) : null}

                <Remark
                    value={
                        props.changes.remark !== undefined
                            ? props.changes.remark
                            : props.permit.remark || ''
                    }
                    allState={store}
                    onChange={props.onChangeRemark}
                    disabled={!props.remarkCanBeChanged}
                />

                <VignetteInvoiced permit={props.permit} />

                <div
                    key="createdBy"
                    className={`${versionBaseStyle} ${css({
                        marginTop: '48px',
                    })}`}
                >
                    <span className={versionCaptionStyle}>
                        {`${txt.Created()}: `}
                    </span>

                    <CreatedBy permit={props.permit} />
                </div>

                {!!props.permit.refund && (
                    <div key="refundedBy" className={versionBaseStyle}>
                        <span className={versionCaptionStyle}>
                            {`${txt.RefundedBy()}: `}
                        </span>
                        {props.permit.refund.refundedBy},{' '}
                        {Formatter.dayMonthYearHourMinute(
                            Parser.isoToMoment(props.permit.refund.refundedAt),
                        )}
                    </div>
                )}

                <br />

                <div key="channel" className={versionBaseStyle}>
                    <span className={versionCaptionStyle}>
                        {`${txt.Channel()}: `}
                    </span>
                    <span>
                        <NicerPaymentChannel
                            paymentChannel={props.permit.paymentChannel}
                        />
                        {props.permit.customer &&
                            ` (${props.permit.customer.customerNr})`}
                    </span>
                </div>

                <br />

                <div key="permitId" className={versionBaseStyle}>
                    <span className={versionCaptionStyle}>
                        {`${txt.PermitId()}: `}
                    </span>
                    <span>{props.permit.id}</span>
                </div>

                <br />
            </FullSlideInRightColumn>
        </SlideInBody>
    );
}

function PermitZones({
    canBeChanged,
    permitTypeId,
    onEditZones,
    onstreetZoneType,
    onstreetZones,
    offstreetZones,
}: {
    canBeChanged: boolean;
    permitTypeId: number;
    onEditZones: (zones: Zone[]) => void;
    onstreetZoneType: PermitEditState.OnstreetZoneType;
    onstreetZones: PermitEditState.OnstreetZone[];
    offstreetZones: PermitEditState.OffstreetZone[];
}): JSX.Element {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        permitType: PermitTypeState.getById(s, permitTypeId),
    }));
    const [zoneSelectionOpen, setZoneSelectionOpen] = useState(false);
    const txt = PermitSlideInTexts.body[storeState.settings.language];

    const zones = [...onstreetZones, ...offstreetZones];
    const zonesLength = zones.length;

    const templateZones = storeState.permitType?.zones || [];

    const readOnly = !canBeChanged;
    const selectedZoneIds = zoneIds(onstreetZones, offstreetZones);
    const zoneText: string | JSX.Element =
        onstreetZoneType === 'FIXED_ZONES' && zonesLength > 10 ? (
            txt.FixedZones(zonesLength)
        ) : zonesLength === 0 ? (
            EmDash
        ) : (
            <div>
                {zones.map(z => (
                    <div key={z.id}>
                        {`${
                            !z.offstreet && z.extZoneCode
                                ? z.extZoneCode + ' — '
                                : ''
                        }${z.name} —
                        ${z.city}`}
                    </div>
                ))}
            </div>
        );

    switch (onstreetZoneType) {
        case OnstreetZoneType.SINGLE_CHOICE:
        case OnstreetZoneType.MULTIPLE_CHOICE:
            // eslint-disable-next-line no-case-declarations
            const htmlElement = document.getElementById(
                CLEARANCE_PERMIT_SLIDEIN_ID,
            ) as HTMLElement;

            return (
                <>
                    {htmlElement &&
                        ReactDOM.createPortal(
                            <>
                                <div className={css({ opacity: '0.8' })}>
                                    <FullSlideIn
                                        open={zoneSelectionOpen}
                                        outsideBody={true}
                                        disabled={true}
                                    />
                                </div>
                                <ZonesSelection
                                    open={zoneSelectionOpen}
                                    onClose={() => setZoneSelectionOpen(false)}
                                    selectZoneType={
                                        onstreetZoneType ===
                                        OnstreetZoneType.SINGLE_CHOICE
                                            ? 'SELECT_ONE'
                                            : 'SELECT_MANY'
                                    }
                                    zones={templateZones}
                                    selection={selectedZoneIds}
                                    onSingleSelect={id => {
                                        onEditZones([
                                            templateZones.find(
                                                z => id === z.id,
                                            )!,
                                        ]);
                                        setZoneSelectionOpen(false);
                                    }}
                                    onMultiSelect={ids => {
                                        onEditZones(
                                            templateZones.filter(zone =>
                                                ids.includes(zone.id),
                                            ),
                                        );
                                    }}
                                />
                            </>,
                            htmlElement,
                        )}
                    <MiniTable label={txt.ValidZones()} actionButtons={false}>
                        <MiniTableElement
                            onLineItemClick={
                                (!readOnly &&
                                    (() => setZoneSelectionOpen(true))) ||
                                undefined
                            }
                        >
                            {zoneText}
                        </MiniTableElement>
                    </MiniTable>
                </>
            );

        case OnstreetZoneType.FIXED_ZONES:
            return (
                <LabeledText label={txt.ValidZones()}>{zoneText}</LabeledText>
            );
    }
}

function VignetteInvoiced(props: { permit: Permit }): JSX.Element | null {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        permitTypes: PermitTypeState.get(s),
    }));

    const txt = PermitSlideInTexts.body[storeState.settings.language];
    const vignetteInvoiced = props.permit.vignetteInvoiced;

    const type =
        storeState.permitTypes.data &&
        storeState.permitTypes.data.find(
            t => t.permitTypeId === props.permit.permitTypeId,
        );

    const allowVignette = type
        ? type.allowedLicensePlateTypes.filter(t => t.needsVignette).length > 0
        : false;

    if ((!!vignetteInvoiced && vignetteInvoiced !== 0) || allowVignette) {
        return (
            <LabeledText label={txt.VignetteInvoiced()}>
                {vignetteInvoiced}
            </LabeledText>
        );
    } else {
        return null;
    }
}

function CreatedBy(props: { permit: Permit }): JSX.Element {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        operatorLogins: OperatorLoginsState.List.get(s),
    }));

    const txt = PermitSlideInTexts.body[storeState.settings.language];

    const operatorUser: OperatorLoginsState.OperatorLogin | undefined | null =
        thenElse(
            props.permit.adminId,
            a =>
                OperatorLoginsState.getLoginById(
                    storeState.operatorLogins.data,
                    a,
                ),
            null,
        );

    const userString = thenElse(
        operatorUser,
        u => `${u.firstName.toUpperCase()} ${u.lastName.toUpperCase()}`,
        txt.OnlineUser(),
    );

    const content = `${userString}, ${Formatter.dayMonthYearHourMinute(
        Parser.isoToMoment(props.permit.creationDate),
    )}`;

    return <span>{content}</span>;
}
