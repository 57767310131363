import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import * as ParkTransactionsState from '../state/ParkTransactionsState.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import {
    ColAlign,
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../ui/table/Table.tsx';
import {
    finishedTransactionsListTexts,
    parkTransactionsListTexts,
} from '../i18n/ParkTransactionsTexts.ts';
import { ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import * as ParkTransactionsActions from '../actions/ParkTransactionsActions.ts';
import * as Text from '../../common/i18n/Text.ts';
import { Guide } from './Guide.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as TomState from '../../tom/state/TomState.ts';
import { ParsedTom } from 'dg-web-shared/model/Tom.ts';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { OperatorTypo } from '../../ui/OperatorTypo.ts';
import { TomTransaction } from 'dg-web-shared/model/TomTransaction.ts';
import { Icon16, Icon24 } from '../../ui/icons/Icon.tsx';
import { error } from 'dg-web-shared/ui/icons/Icons24.tsx';
import { chevronRight } from 'dg-web-shared/ui/icons/Icons16.tsx';

export interface FinishedTransactionsListTexts {
    tooManyRowsTitle: Text.Translation;
    tooManyRowsDescription: Text.Translation;

    noRowsTitle: Text.Translation;
    noRowsDescription: Text.Translation;
}

export interface ParkTransactionsListTexts {
    headerDateFrom: Text.Translation;
    headerDateTo: Text.Translation;
    headerParkingMeterName: Text.Translation;
    headerTransactionNumber: Text.Translation;
    headerDuration: Text.Translation;
    headerPrice: Text.Translation;
    headerParkingSpace: Text.Translation;
}

interface FinishedTransactionsListState {
    settings: SettingsState.State;
    parkTransactionsList: ParkTransactionsState.List.State;
    toms: TomState.List.State;
    filter: ParkTransactionsState.Filter.State;
    login: CurrentOperatorLoginState.State;
}

export class FinishedTransactionsList extends Flux.Container<FinishedTransactionsListState> {
    stateSelector(): FinishedTransactionsListState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            parkTransactionsList: ParkTransactionsState.List.Completed.get(
                this.props.allState,
            ),
            toms: TomState.List.get(this.props.allState),
            filter: ParkTransactionsState.Filter.get(this.props.allState),
            login: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    renderIntroduction(): JSX.Element {
        return (
            <Guide
                login={this.state.login}
                language={this.state.settings.language}
            />
        );
    }

    renderTooManyRows(): JSX.Element {
        const texts =
            finishedTransactionsListTexts[this.state.settings.language];
        return (
            <ResultsError
                title={texts.tooManyRowsTitle()}
                description={texts.tooManyRowsDescription()}
            />
        );
    }

    renderNoRows(): JSX.Element {
        const texts =
            finishedTransactionsListTexts[this.state.settings.language];
        return (
            <ResultsError
                title={texts.noRowsTitle()}
                description={texts.noRowsDescription()}
            />
        );
    }

    render() {
        const texts = parkTransactionsListTexts[this.state.settings.language];

        if (this.state.parkTransactionsList.pending) {
            return null;
        }

        if (!ParkTransactionsState.Filter.filterActive(this.state.filter)) {
            return this.renderIntroduction();
        }

        if (
            this.state.parkTransactionsList.data.size > 0 &&
            this.state.parkTransactionsList.data.data.length < 1
        ) {
            return this.renderTooManyRows();
        }

        if (this.state.parkTransactionsList.data.size < 1) {
            return this.renderNoRows();
        }

        return (
            <Table>
                <TableHeader>
                    <EmptyTableHeaderColumn
                        width={ColumnWidth._24px}
                        delimiterBottom={false}
                    />
                    <TableHeaderColumn
                        name={texts.headerDateFrom()}
                        width={ColumnWidth._136px}
                        delimiterBottom={false}
                    />
                    <TableHeaderColumn
                        name={texts.headerDateTo()}
                        direction={SortDirection.Descending}
                        selected={true}
                        width={ColumnWidth._136px}
                        delimiterBottom={false}
                    />
                    <TableHeaderColumn
                        name={texts.headerDuration()}
                        width={ColumnWidth._108px}
                        delimiterBottom={false}
                    />
                    <TableHeaderColumn
                        name={texts.headerParkingMeterName()}
                        width={ColumnWidth.variable}
                        delimiterBottom={false}
                    />
                    <TableHeaderColumn
                        name={texts.headerParkingSpace()}
                        width={ColumnWidth._108px}
                        delimiterBottom={false}
                    />
                    <TableHeaderColumn
                        name={texts.headerPrice()}
                        width={ColumnWidth._108px}
                        delimiterBottom={false}
                        align={ColAlign.right}
                    />
                    <EmptyTableHeaderColumn
                        width={ColumnWidth._24px}
                        delimiterBottom={false}
                    />
                </TableHeader>
                <TableBody>
                    {this.state.parkTransactionsList.data.data.map(
                        (t: TomTransaction) => {
                            return (
                                <TomTransactionRow
                                    transaction={t}
                                    allState={this.props.allState}
                                    toms={this.state.toms.data}
                                    key={t.transactionId}
                                    language={this.state.settings.language}
                                    onClick={() =>
                                        this.update(store =>
                                            ParkTransactionsActions.openTransactionDetail(
                                                store,
                                                t,
                                            ),
                                        )
                                    }
                                />
                            );
                        },
                    )}
                </TableBody>
            </Table>
        );
    }
}

interface ResultsErrorProps {
    title: string;
    description: string;
}

const ResultsError = (p: ResultsErrorProps) => (
    <div
        className={css({
            textAlign: 'center',
            height: '80px',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            marginTop: '-40px',
        })}
    >
        <div
            className={css({
                color: ColorHex.error,
            })}
        >
            <Icon24 icon={error} />
        </div>
        <div
            className={css({
                ...OperatorTypo.headingTwo,
                color: ColorHex.error,
            })}
        >
            {p.title}
        </div>
        <div
            className={css({
                ...OperatorTypo.headingOne,
                color: ColorHex.typo,
            })}
        >
            {p.description}
        </div>
    </div>
);

interface TomTransactionRowProps {
    allState: Flux.Store;
    transaction: TomTransaction;
    toms: ParsedTom[];
    language: string;
    onClick: ClickHandler;
}

const TomTransactionRow = (p: TomTransactionRowProps): JSX.Element => (
    <DefaultTableRow onClick={p.onClick}>
        <TableColumn width={ColumnWidth._24px} />
        <TableColumn width={ColumnWidth._136px}>
            {Date.Formatter.dayMonthYearHourMinute(p.transaction.startTime)}
        </TableColumn>
        <TableColumn width={ColumnWidth._136px}>
            {Date.Formatter.dayMonthYearHourMinute(
                p.transaction.expirationTime,
            )}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px} align={ColAlign.right}>
            {Date.Formatter.getDuration(
                p.transaction.startTime,
                p.transaction.expirationTime,
                Date.Formatter.durationTexts[p.language],
            )}
        </TableColumn>
        <TableColumn width={ColumnWidth.variable}>
            {TomState.getTomName(p.toms, p.transaction.tomId)}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px}>
            {p.transaction.label}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px} align={ColAlign.right}>
            {NumberFormatter.numberToPrice(
                p.transaction.paidAmount / 100,
                false,
            )}
        </TableColumn>
        <TableColumn width={ColumnWidth._24px}>
            <IconTopOffsetContainer>
                <Icon16 icon={chevronRight} />
            </IconTopOffsetContainer>
        </TableColumn>
    </DefaultTableRow>
);
