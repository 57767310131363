import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';
import { Translation, Translations } from '../../../common/i18n/Text.ts';
import { Permit } from '../../state/ResultState.ts';
import { ClearancePermitsList } from '../clearances-list/ClearancePermitsList.tsx';
import {
    ClearanceListItem,
    ClearanceListItemRow,
    Toggle,
} from '../clearances-list/ClearancesListItem.tsx';
import { PermitItem } from '../permit/PermitItem.tsx';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../../ui/Colors.ts';
import React from 'react';

interface PermitsListProps {
    language: string;
    permits: Permit[];
    update: Flux.Updater;
    hasClearances: boolean;
}

interface Texts {
    ClearancesWithoutPermits: Translation;
}

const texts: Translations<Texts> = {
    de: {
        ClearancesWithoutPermits: () => 'Bewilligungen ohne Freigabe',
    },
    fr: {
        ClearancesWithoutPermits: () => 'Autorisations sans approbation',
    },
    it: {
        ClearancesWithoutPermits: () => 'Autorizzazioni senza approvazione',
    },
};

export class PermitsList extends React.Component<
    PermitsListProps,
    { expanded: boolean }
> {
    constructor(p: PermitsListProps) {
        super(p);
        this.state = { expanded: true };
    }

    renderListItems(permits: Permit[]): JSX.Element[] {
        return permits.map((permit: Permit) => {
            return (
                <PermitItem
                    key={permit.id}
                    permit={permit}
                    update={this.props.update}
                />
            );
        });
    }

    renderPermitsWithoutWhitelist(permits: Permit[]): JSX.Element {
        return (
            <ClearanceListItem>
                <ClearanceListItemRow onClick={() => {}}>
                    <Toggle
                        expandable={true}
                        expanded={this.state.expanded}
                        onToggle={() => {
                            this.setState({ expanded: !this.state.expanded });
                        }}
                    />
                    <div
                        className={css({
                            ...OperatorTypo.bodyC,
                            display: 'table-cell',
                            verticalAlign: 'middle',
                            paddingLeft: '8px',
                            color: ColorHex.darkblue,
                            position: 'relative',
                        })}
                    >
                        {texts[this.props.language].ClearancesWithoutPermits()}
                    </div>
                </ClearanceListItemRow>
                <ClearancePermitsList
                    permits={permits}
                    expanded={this.state.expanded}
                    update={this.props.update}
                />
            </ClearanceListItem>
        );
    }

    render() {
        const permits = this.props.permits;
        if (isUndefined(permits) || permits.length === 0) {
            return null;
        } else if (this.props.hasClearances) {
            return <div>{this.renderPermitsWithoutWhitelist(permits)}</div>;
        } else {
            return <div>{this.renderListItems(permits)}</div>;
        }
    }
}
