import { Translations } from '../../common/i18n/Text.ts';
import { FilteredClearancesFilterHalfSlideInTexts } from '../components/filtered-clearances/FilteredClearancesFilterHalfSlideIn.tsx';
import { FilterSummaryTexts } from '../components/filtered-clearances/FilteredClearancesFilterSummary.tsx';
import { FilteredClearancesListTexts } from '../components/filtered-clearances/FilteredClearancesList.tsx';
import { IssueDateFilterTexts } from '../components/filtered-clearances/IssueDateFilter.tsx';
import { OperatorLoginFilterTexts } from '../components/filtered-clearances/OperatorLoginFilter.tsx';
import { PermitFilterTexts } from '../components/filtered-clearances/PermitFilter.tsx';
import { PermitTypeFilterTexts } from '../components/filtered-clearances/PermitTypeFilter.tsx';

export const filteredClearancesFilterHalfSlideInTexts: Translations<FilteredClearancesFilterHalfSlideInTexts> =
    {
        de: {
            title: () => 'Filter',
        },
        fr: {
            title: () => 'Filtre',
        },
        it: {
            title: () => 'Filtro',
        },
    };

export const filteredClearancesListTexts: Translations<FilteredClearancesListTexts> =
    {
        de: {
            filterGuideCreateDate: () => 'Freigabedatum',
            filterGuideExportDescription: () =>
                'Mit einem aktiven Filter können Sie die angezeigte Freigabenliste als CSV herunterladen.',
            filterGuideExportTitle: () => 'Liste exportieren',
            filterGuideIntro: () =>
                'Mittels Filter können Sie Listen von den gewünschten Freigaben erzeugen.',
            filterGuideListDescription: () =>
                'Folgende Filterkriterien sind verfügbar:',
            filterGuideTitle: () => 'Liste anzeigen',
            filterGuideType: () => 'Typ',
            filterGuideUser: () => 'Erfasst von',
            filterGuideValidity: () => 'Gültigkeit',
            filterWithOrWithoutDate: () => 'Mit / Ohne Bewilligungen',
            hasMany: () => 'mehrere',
            headerStatus: () => 'Status',
            headerIdentifier: () => 'Kennzeichen',
            headerCustomerNr: () => 'Kontonummer',
            headerIssuedDate: () => 'Freigabedatum',
            headerOperatorLoginName: () => 'Erfasst von',
            headerPermitId: () => 'Nr.',
            headerPermitTypeName: () => 'Bewilligungsart',
            headerValidFrom: () => 'Gültig von',
            headerValidTo: () => 'Gültig bis',
            headerZoneName: () => 'Zone',
            noRowsDescription: () =>
                'Bitte überprüfen Sie das aktuelle Filterset.',
            noRowsTitle: () => 'Keine Resultate gefunden.',
            onlineUser: () => 'Online-Benutzer',
            tooManyRowsDescription: () =>
                'Sie können die Liste als CSV herunterladen oder die Filterkriterien weiter einschränken.',
            tooManyRowsTitle: () => 'Zu viele Resultate.',
        },
        fr: {
            filterGuideCreateDate: () => 'Date approvation',
            filterGuideExportDescription: () =>
                'Avec un filtre actif vous pouvez télécharger la liste des approbations en format CSV.',
            filterGuideExportTitle: () => 'Exporter la liste',
            filterGuideIntro: () =>
                'Au moyen du filtre vous pouvez générer des listes avec les approbations désirées.',
            filterGuideListDescription: () =>
                'Les critères suivants sont disponibles:',
            filterGuideTitle: () => 'Afficher la liste',
            filterGuideType: () => 'Type',
            filterGuideUser: () => 'Saisie de',
            filterGuideValidity: () => 'Validité',
            filterWithOrWithoutDate: () => 'Avec / Sans autorisations',
            hasMany: () => 'plusieurs',
            headerStatus: () => 'Statut',
            headerIdentifier: () => 'Immatriculation',
            headerCustomerNr: () => 'No de compte',
            headerIssuedDate: () => 'Date approbation',
            headerOperatorLoginName: () => 'Saisie de',
            headerPermitId: () => 'No',
            headerPermitTypeName: () => "Type d'autorisation",
            headerValidFrom: () => 'Valable du',
            headerValidTo: () => "Valable jusqu'au",
            headerZoneName: () => 'Zone',
            noRowsDescription: () => 'Veuillez vérifier le filtre actuel.',
            noRowsTitle: () => 'Aucun résultat relevé.',
            onlineUser: () => 'Utilisateur en ligne',
            tooManyRowsDescription: () =>
                'Vous avez la possibilitè de télécharger la liste en format CSV ou de resteindre la recherche avec les critères.',
            tooManyRowsTitle: () => 'Trop de résultats.',
        },
        it: {
            filterGuideCreateDate: () => 'Data approvazione',
            filterGuideExportDescription: () =>
                'Con un filtro attivo potete scaricare la lista delle approvazioni in formato CSV. ',
            filterGuideExportTitle: () => 'Esportare la lista',
            filterGuideIntro: () =>
                'Tramite il filtro potete generare delle liste con le approvazioni desiderate.',
            filterGuideListDescription: () =>
                'Sono disponibili i seguenti criteri:',
            filterGuideTitle: () => 'Visualizza la lista',
            filterGuideType: () => 'Tipo',
            filterGuideUser: () => 'Registrata da',
            filterGuideValidity: () => 'Validità',
            filterWithOrWithoutDate: () => 'Con / Senza autorizzazioni',
            hasMany: () => 'diversi',
            headerStatus: () => 'Stato',
            headerIdentifier: () => 'Targa',
            headerCustomerNr: () => 'Nr. conto',
            headerIssuedDate: () => 'Data approvazione',
            headerOperatorLoginName: () => 'Registrata da',
            headerPermitId: () => 'Nr.',
            headerPermitTypeName: () => "Tipo d'autorizzazione",
            headerValidFrom: () => 'Valida dal',
            headerValidTo: () => 'Valida fino al',
            headerZoneName: () => 'Zona',
            noRowsDescription: () => "Vogliate controllare l'attuale filtro.",
            noRowsTitle: () => 'Nessun risultato trovato.',
            onlineUser: () => 'Utente online',
            tooManyRowsDescription: () =>
                'Avete la possibilità di scaricare la lista in formato CSV oppure restringere il campo di ricerca tramite i criteri.',
            tooManyRowsTitle: () => 'Troppi risultati.',
        },
    };

export const filterSummaryTexts: Translations<FilterSummaryTexts> = {
    de: {
        zone: () => 'Zone',
    },
    fr: {
        zone: () => 'Zone',
    },
    it: {
        zone: () => 'Zona',
    },
};

export const permitTypeFilterTexts: Translations<PermitTypeFilterTexts> = {
    de: {
        filterTitle: () => 'Typ',
        inactive: () => 'INAKTIV',
    },
    fr: {
        filterTitle: () => 'Type',
        inactive: () => 'INACTIVE',
    },
    it: {
        filterTitle: () => 'Tipo',
        inactive: () => 'INATTIVO',
    },
};

export const operatorLoginFilterTexts: Translations<OperatorLoginFilterTexts> =
    {
        de: {
            filterTitle: () => 'Erfasst von',
            onlineUser: () => 'Online-Benutzer',
        },
        fr: {
            filterTitle: () => 'Saisie de',
            onlineUser: () => 'Utilisateur en ligne',
        },
        it: {
            filterTitle: () => 'Registrata da',
            onlineUser: () => 'Utente online',
        },
    };

export const issueDateFilterTexts: Translations<IssueDateFilterTexts> = {
    de: {
        filterTitle: () => 'Freigabedatum',
        from: () => 'Von',
        to: () => 'Bis',
    },
    fr: {
        filterTitle: () => 'Date approbation',
        from: () => 'Du',
        to: () => 'Au',
    },
    it: {
        filterTitle: () => 'Data approvazione',
        from: () => 'Dal',
        to: () => 'Al',
    },
};

export const permitFilterTexts: Translations<PermitFilterTexts> = {
    de: {
        filterTitle: () => 'Mit / Ohne Bewilligungen',
        withoutPermits: () => 'Freigaben ohne Bewilligungen',
        withPermits: () => 'Freigaben mit Bewilligungen',
    },
    fr: {
        filterTitle: () => 'Avec / Sans autorisations',
        withoutPermits: () => 'Approbations sans autorisations',
        withPermits: () => 'Approbations avec autorisations',
    },
    it: {
        filterTitle: () => 'Con / Senza autorizzazioni',
        withoutPermits: () => 'Approvazioni senza autorizzazioni',
        withPermits: () => 'Approvazioni con autorizzazioni',
    },
};
