import {
    RequestMethod,
    RequestStatus,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    QuotaConfig,
    QuotaInfoType,
    QuotaPerEntityPeriod,
} from 'dg-web-shared/model/QuotaConfig.ts';
import {
    Outlet,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { Box, InputLabel, TextField } from '@mui/material';
import { PieChart } from '@mui/icons-material';
import { Localized } from '../common/components/Localized.tsx';
import { useEffect, useState } from 'react';
import { makeLocalizedText, Message } from 'dg-web-shared/lib/Localized.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { HeaderCancelSaveButtons } from '../layout/components/HeaderCancelSaveButtons.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';

export function OperatorQuotasDetail() {
    const { contractQuotaPartitionId } = useParams();
    if (!contractQuotaPartitionId) {
        throw new Error(
            'Contract Qutoa Partition Id must be defined in the Url',
        );
    }

    const [quotaResultState] = useServerFetch<
        QuotaDetailResult,
        { contractQuotaPartitionId: string },
        null
    >(
        () => ({
            url: `/ui-api/operator-account/parkingabo/quotas/${contractQuotaPartitionId}`,
        }),
        { contractQuotaPartitionId: contractQuotaPartitionId },
    );

    return (
        <OperatorRoutedModal
            backUrl={'..'}
            variant={ModalVariant.SMALL}
            render={controller => (
                <OperatorAsyncLoadedSection
                    requestState={quotaResultState}
                    render={result => (
                        <OperatorQuotasDetailModal
                            contractQuotaPartitionId={contractQuotaPartitionId}
                            quotaDetailResult={result}
                            onSuccess={() =>
                                controller.setShouldConfirmBeforeLeave(false)
                            }
                            onDirtyStateChange={
                                controller.setShouldConfirmBeforeLeave
                            }
                        />
                    )}
                />
            )}
        />
    );
}

function OperatorQuotasDetailModal({
    contractQuotaPartitionId,
    quotaDetailResult,
    onSuccess,
    onDirtyStateChange,
}: {
    contractQuotaPartitionId: string;
    quotaDetailResult: QuotaDetailResult;
    onSuccess: () => void;
    onDirtyStateChange: (shouldConfirm: boolean) => void;
}) {
    const { refetch } = useOperatorQuotaOutlet();
    const [updateQuotaLimitState, updateQuotaLimit] = useServerWrite<
        UpdateQuotaLimitsPayload,
        null
    >(({ contractQuotaPartitionId }) => ({
        url: `/ui-api/operator-account/parkingabo/quotas/${contractQuotaPartitionId}`,
        method: RequestMethod.PUT,
    }));
    const [newQuotaConfigs, setNewQuotaConfigs] = useState(
        quotaDetailResult.quotaConfigs,
    );
    const [remark, setRemark] = useState('');
    const isDirty = quotaDetailResult.quotaConfigs
        .map((config, index) => config.limit != newQuotaConfigs[index].limit)
        .some(isChanged => isChanged);
    const navigate = useNavigate();
    const localize = makeLocalizedText(useOperatorLanguage());
    useEffect(() => {
        onDirtyStateChange(isDirty);
    }, [isDirty]);
    useServerSuccessEffect(updateQuotaLimitState, () => {
        onSuccess();
        refetch();
        navigate('..');
    });

    return (
        <OperatorRoutedModalContent
            header={
                <HeaderComponent
                    Icon={PieChart}
                    title={
                        <Localized
                            de="Kontingent"
                            fr="Contingent"
                            it="Contingente"
                            en="Quota"
                        />
                    }
                    editMode={isDirty}
                    buttons={
                        isDirty ? (
                            <HeaderCancelSaveButtons
                                onCancel={() => {
                                    setNewQuotaConfigs(
                                        quotaDetailResult.quotaConfigs,
                                    );
                                    setRemark('');
                                }}
                                onSave={() => {
                                    updateQuotaLimit({
                                        contractQuotaPartitionId:
                                            contractQuotaPartitionId,
                                        quotaConfigs: newQuotaConfigs,
                                        remark: remark,
                                    });
                                }}
                                saveDisabled={remark == ''}
                                loading={
                                    updateQuotaLimitState.status ===
                                    RequestStatus.PENDING
                                }
                            />
                        ) : (
                            <HeaderActionCloseButtons />
                        )
                    }
                />
            }
            body={
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexGrow: 1,
                                flexDirection: 'column',
                            }}
                        >
                            <InputLabel>
                                <Localized
                                    de="Typ"
                                    fr="Type"
                                    it="Tipo"
                                    en="Type"
                                />
                            </InputLabel>
                            {newQuotaConfigs.map((quotaConfig, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        color: theme => theme.palette.blue.main,
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        paddingTop: '4px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    <QuotaPartitionText
                                        quotaConfig={quotaConfig}
                                    />
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '150px',
                                flexGrow: 0,
                                flexDirection: 'column',
                            }}
                        >
                            <InputLabel>
                                <Localized
                                    de="Limite"
                                    fr="Limite"
                                    it="Limite"
                                    en="Limit"
                                />
                            </InputLabel>
                            {newQuotaConfigs.map((quotaConfig, index) => (
                                <TextField
                                    key={index}
                                    value={quotaConfig.limit}
                                    onChange={e => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            setNewQuotaConfigs(
                                                newQuotaConfigs.map(
                                                    (config, changeIndex) =>
                                                        index == changeIndex
                                                            ? {
                                                                  ...config,
                                                                  limit: Number(
                                                                      value,
                                                                  ),
                                                              }
                                                            : config,
                                                ),
                                            );
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                    {isDirty && (
                        <TextField
                            label={
                                <Localized
                                    de="Bemerkung"
                                    fr="Remarque"
                                    it="Osservazione"
                                    en="Remark"
                                />
                            }
                            sx={{ marginTop: '24px' }}
                            value={remark}
                            onChange={e => setRemark(e.target.value)}
                        />
                    )}
                    <TextField
                        label={
                            <Localized
                                de="Produkttypen"
                                fr="Types de produits"
                                it="Tipi di prodotto"
                                en="Product types"
                            />
                        }
                        disabled
                        multiline
                        value={quotaDetailResult.productOptionNames
                            .map(name => localize(name))
                            .join('\n')}
                        sx={{ marginTop: '24px' }}
                    />
                </>
            }
        />
    );
}

function QuotaPartitionText({ quotaConfig }: { quotaConfig: QuotaConfig }) {
    switch (quotaConfig.type) {
        case QuotaInfoType.CONTRACT_PURCHASE_OVERLAPPING:
            return (
                <Localized
                    de="Gleichzeitig gültig"
                    fr="Valide simultanément"
                    it="Valido contemporaneamente"
                    en="Valid simultaneously"
                />
            );
        case QuotaInfoType.CONTRACT_PURCHASE_PER_ENTITY:
            return <QuotaPartitionPerEntityText period={quotaConfig.period} />;
    }
}

function QuotaPartitionPerEntityText({
    period,
}: {
    period: QuotaPerEntityPeriod;
}) {
    switch (period) {
        case QuotaPerEntityPeriod.CALENDAR_MONTH:
            return (
                <Localized
                    de="Pro Monat / Kennzeichen"
                    fr="Par mois / immatriculation"
                    it="Per mese / targa"
                    en="Per month / license plate"
                />
            );
        case QuotaPerEntityPeriod.CALENDAR_YEAR:
            return (
                <Localized
                    de="Pro Jahr / Kennzeichen"
                    fr="Par année / immatriculation"
                    it="Per anno / targa"
                    en="Per year / license plate"
                />
            );
    }
}

interface QuotaDetailResult {
    productOptionNames: Message[];
    quotaConfigs: QuotaConfig[];
}

interface UpdateQuotaLimitsPayload {
    contractQuotaPartitionId: string;
    quotaConfigs: QuotaConfig[];
    remark: string;
}

function useOperatorQuotaOutlet() {
    return useOutletContext<OperatorQuotaOutletContext>();
}

export function OperatorQuotaOutlet({
    context,
}: {
    context: OperatorQuotaOutletContext;
}) {
    return <Outlet context={context} />;
}

interface OperatorQuotaOutletContext {
    refetch: () => void;
}
