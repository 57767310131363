import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { permitTypeFilterTexts } from './Texts.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import { SingleSelection } from '../ui/labeled-elements/SingleSelection.tsx';
import { Filter, UI } from './ClearanceRequestsState.ts';
import * as PermitTypeState from '../common/state/PermitTypeState.ts';
import { NumberMultiSelectionSlideIn } from '../ui/slidein/MultiSelectionSlideIn.tsx';

export interface PermitTypeFilterTexts {
    filterTitle: Translation;
}

const selectablePermitTypes = (permitTypes: PermitTypeState.PermitType[]) =>
    permitTypes.filter(
        pt => pt.operatorState === 'ACTIVE' && pt.needsWhitelist,
    );

export const permitTypeSelection = (
    permitTypes: PermitTypeState.PermitType[],
    selectedPt: number[],
) =>
    selectedPt.length > 0
        ? `${selectedPt.length}/${selectablePermitTypes(permitTypes).length}`
        : '';

const texts = (p: { settings: SettingsState.State }) =>
    permitTypeFilterTexts[p.settings.language];

export const PermitTypeFilterSlideIn = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
        permitTypes: PermitTypeState.get(store),
        ui: UI.get(store),
    }),
    p => (
        <NumberMultiSelectionSlideIn
            open={p.ui.permitTypesSlideInVisible}
            heading={permitTypeFilterTexts[p.settings.language].filterTitle()}
            selection={p.filter.permitTypeIds}
            options={selectablePermitTypes(p.permitTypes.data).map(pt => {
                return { id: pt.id, displayText: pt.description };
            })}
            onLeftActionClick={() =>
                p.update(store =>
                    UI.stateWrite(store, {
                        permitTypesSlideInVisible: false,
                    }),
                )
            }
            setNewSelection={v =>
                p.update(store =>
                    Filter.stateWrite(store, { permitTypeIds: v }),
                )
            }
        />
    ),
);

export const PermitTypeFilterSelection = Flux.selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
        permitTypes: PermitTypeState.get(store),
    }),
    p => (
        <SingleSelection
            label={texts(p).filterTitle()}
            focused={false}
            selection={permitTypeSelection(
                p.permitTypes.data,
                p.filter.permitTypeIds,
            )}
            onClick={() =>
                p.update(store =>
                    UI.stateWrite(store, {
                        permitTypesSlideInVisible: true,
                    }),
                )
            }
            onClear={
                p.filter.permitTypeIds.length > 0
                    ? () =>
                          p.update(store =>
                              Filter.stateWrite(store, { permitTypeIds: [] }),
                          )
                    : null
            }
        />
    ),
);
