import { Translations } from '../../common/i18n/Text.ts';
import * as ActivityStatement from '../components/ActivityStatement.tsx';
import * as FilterSlideIn from '../components/FilterSlideIn.tsx';
// import * as FilterSlideIn from './../components/FilterSlideIn';
/* tslint:disable */
export const texts: Translations<ActivityStatement.Texts> = {
    de: {
        CurrentMonth: () => 'laufender monat',
        InkassoCol: () => 'Kommissionen',
        VersionCol: () => 'Version',
        MonthCol: () => 'Monat',
        OthersCol: () => 'Sonstige',
        PdfCol: () => 'PDF',
        PermitsCol: () => 'Bewilligungen',
        ProductsCol: () => 'Produkte',
        RefundsCol: () => 'Rückerstat-tungen',
        Total: () => 'Gesamt',
        TotalCol: () => 'Total',
        TransactionsCol: () => 'Parkvorgänge',
        ExportCol: () => 'AUDIT',
    },
    fr: {
        CurrentMonth: () => 'mois courant',
        InkassoCol: () => 'Commissions',
        VersionCol: () => 'Version',
        MonthCol: () => 'Mois',
        OthersCol: () => 'Autre',
        PdfCol: () => 'PDF',
        PermitsCol: () => 'Autorisations',
        ProductsCol: () => 'Produits',
        RefundsCol: () => 'Rembourse-ments',
        Total: () => 'Global',
        TotalCol: () => 'Total',
        TransactionsCol: () => 'Procédures',
        ExportCol: () => 'AUDIT',
    },
    it: {
        CurrentMonth: () => 'mese corrente',
        InkassoCol: () => 'Commissioni',
        VersionCol: () => 'Versione',
        MonthCol: () => 'Mese',
        OthersCol: () => 'Altro',
        PdfCol: () => 'PDF',
        PermitsCol: () => 'Autorizzazioni',
        ProductsCol: () => 'Prodotti',
        RefundsCol: () => 'Rimborsi',
        Total: () => 'Globale',
        TotalCol: () => 'Totale',
        TransactionsCol: () => 'Procedure',
        ExportCol: () => 'AUDIT',
    },
};

export const slideIn: Translations<FilterSlideIn.Texts> = {
    de: {
        From: () => 'Von',
        To: () => 'Bis',
    },
    fr: {
        From: () => 'Du',
        To: () => "Jusqu'au",
    },
    it: {
        From: () => 'Dal',
        To: () => 'Fino al',
    },
};

/* tslint:enable */
