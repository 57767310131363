import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    Stack,
    SvgIconTypeMap,
    TextField,
    Tooltip,
} from '@mui/material';

import { Language, Message } from 'dg-web-shared/lib/Localized.ts';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField.tsx';
import {
    EMPTY_TRANSLATION,
    Localized,
    useLocalized,
} from '../common/components/Localized.tsx';
import {
    RequestMethod,
    RequestStatus,
    ServerRequestState,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils.tsx';
import { GenericFormSubmitError } from './ParkingaboUsersUtils.tsx';
import { AutoAwesome, Circle, Person } from '@mui/icons-material';
import {
    FormContentLayout,
    FormLeftColumn,
    FormRightColumn,
} from '../layout/components/form/FormLayout.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { IconForCustomerStatus } from './OperatorParkingaboUsers.tsx';
import { OperatorTypo } from '../ui/OperatorTypo.ts';
import { ColorHex } from '../ui/Colors.ts';
import { css } from '@emotion/css';
import { Control, Controller } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import * as EmailValidator from 'email-validator';
import {
    CustomerTenantLegalForm,
    getCustomerDisplayName,
} from 'dg-web-shared/common/models/Users.tsx';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useState } from 'react';
import { mandatoryFieldErrorMessage } from './OperatorParkingaboUsersVehicleForm.tsx';
import {
    AppMode,
    TenantAllowedBarrierGateVehicleIdentification,
    TenantAllowedEnforcedVehicleIdentification,
    TenantPaymentMode,
} from 'product-shared/tenant/TenantEnums.ts';
import { OperatorFormSelect } from '../ui/form/OperatorFormSelect.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { HeaderCancelSaveButtons } from '../layout/components/HeaderCancelSaveButtons.tsx';
import {
    DetailHeader,
    DetailTabs,
    ParkingaboUserInfoTab,
    useParkingaboUserDetailOutletContext,
} from './OperatorParkingaboUsersDetail.tsx';
import { useParkingaboUserFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { ShouldConfirmBeforeExitFormProps } from 'dg-web-shared/common/utils/FormDefinitions.tsx';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Alert from '@mui/material/Alert';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

interface ParkingaboUserFormData {
    language: Language;
    firstName: string | null;
    lastName: string | null;
    company1: string | null;
    company2: string | null;
    email: string | null;
    phone: string | null;
    reference: string | null;
    remarks: string | null;
    legalForm: CustomerTenantLegalForm;
    initialBalance: InitialBalanceFormData | null;
}

interface InitialBalanceFormData {
    amount: number;
    remark: string;
}

export interface PostParkignaboUserPayload extends ParkingaboUserFormData {
    tenantId: string;
}

interface CreateParkingaboUserInitialValues extends ParkingaboUserFormData {
    customerNr: string | null;
    state: ParkingaboUserStatus | null;
    paymentMeansState: PaymentMeansState | null;
    logins: ParkingaboLogin[] | null;
}

const enum PaymentMeansState {
    VALID = 'VALID',
    INVALID = 'INVALID',
    MISSING = 'MISSING',
}

export interface Tenant {
    tenantId: string;
    name: Message;
    appMode: AppMode;
    paymentMode: TenantPaymentMode;
    allowedCustomerLegalForm: TenantAllowedCustomerLegalForm;
    allowedEnforcedVehicleIdentification: TenantAllowedEnforcedVehicleIdentification | null;
    allowedBarrierGateVehicleIdentification: TenantAllowedBarrierGateVehicleIdentification | null;
}

export enum TenantAllowedCustomerLegalForm {
    PRIVATE_ONLY = 'PRIVATE_ONLY',
    COMPANY_ONLY = 'COMPANY_ONLY',
    PRIVATE_AND_COMPANY = 'PRIVATE_AND_COMPANY',
}

export enum ParkingaboUserStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    LOCKED = 'LOCKED',
}

export enum ParkingaboLoginState {
    CREATED = 'CREATED',
    CONFIRMED = 'CONFIRMED',
    DELETED = 'DELETED',
}

export interface ParkingaboLogin {
    loginId: string;
    email: string;
    requestedEmail: string | null;
    state: ParkingaboLoginState;
}

export interface ParkingaboUser extends CreateParkingaboUserInitialValues {
    customerNr: string;
    state: ParkingaboUserStatus;
    logins: ParkingaboLogin[];
    onboardingCompleteTime: string | null;
    hasActiveOrFutureProducts: boolean;
    hasNegativeSaldo: boolean;
    hasAppLogin: boolean;
    hasActiveAlias: boolean;
    tenant: Tenant;
    needsProduct: boolean;
    needsVehicleWithBadge: boolean;
    isAllowedInitialBalanceCreation: boolean;
}

enum Fields {
    ID = 'customerNr',
    State = 'state',
    PaymentMeansState = 'paymentMeansState',
    FirstName = 'firstName',
    LastName = 'lastName',
    Company1 = 'company1',
    Company2 = 'company2',
    Reference = 'reference',
    Remarks = 'remarks',
    Language = 'language',
    Email = 'email',
    Phone = 'phone',
    LegalForm = 'legalForm',
    InitialBalance = 'initialBalance',
}

enum InitialBalanceFields {
    Amount = 'amount',
    Remark = 'remark',
}

export type FieldValues = {
    [Fields.Language]: Language;
    [Fields.FirstName]: string | null;
    [Fields.LastName]: string | null;
    [Fields.Company1]: string | null;
    [Fields.Company2]: string | null;
    [Fields.Email]: string | null;
    [Fields.Phone]: string | null;
    [Fields.Reference]: string | null;
    [Fields.Remarks]: string | null;
    [Fields.ID]: string | null;
    [Fields.State]: ParkingaboUserStatus | null;
    [Fields.PaymentMeansState]: PaymentMeansState | null;
    [Fields.LegalForm]: CustomerTenantLegalForm;
    [Fields.InitialBalance]: InitialBalanceFormData;
};

export function OperatorParkingaboUserEditForm() {
    const {
        parkingaboUser,
        refetchParkingaboUser,
        tenants,
        onDirtyStateChange,
        onClose,
    } = useParkingaboUserDetailOutletContext();
    const [updateParkingaboUserState, updateParkingaboUser] = useServerWrite<
        {
            customerNr: string;
        } & PostParkignaboUserPayload,
        ParkingaboUser,
        ValidationData
    >(payload => ({
        url: `/ui-api/operator-account/parkingabo/customer/${payload.customerNr}`,
        method: RequestMethod.PUT,
    }));

    useServerSuccessEffect(updateParkingaboUserState, () => {
        refetchParkingaboUser();
        refetchList();
    });
    const { refetchList } = useParkingaboUserFilterConfiguration();

    return (
        <OperatorParkingaboUserForm
            title={getCustomerDisplayName(parkingaboUser)}
            Icon={Person}
            parkingaboUser={parkingaboUser}
            onSubmit={payload =>
                updateParkingaboUser({
                    tenantId: parkingaboUser.tenant.tenantId,
                    customerNr: parkingaboUser.customerNr,
                    ...payload,
                    initialBalance:
                        payload.initialBalance?.amount &&
                        !parkingaboUser.initialBalance
                            ? payload.initialBalance
                            : null,
                })
            }
            submitState={updateParkingaboUserState}
            readonly={parkingaboUser.state === ParkingaboUserStatus.ARCHIVED}
            tenantOfUser={parkingaboUser.tenant}
            onDirtyStateChange={onDirtyStateChange}
            onClose={onClose}
            tenants={tenants}
            detailHeader={<DetailHeader />}
            detailBar={
                <DetailTabs
                    customerNr={parkingaboUser.customerNr}
                    parkingaboUserInfoTab={ParkingaboUserInfoTab.DETAILS}
                    tenantHasDigitalPurchase={
                        parkingaboUser.tenant.paymentMode ===
                        TenantPaymentMode.DIGITAL_PURCHASE
                    }
                />
            }
        />
    );
}

interface OperatorParkingaboUserCreateFormProps
    extends ShouldConfirmBeforeExitFormProps {
    onCreated: (newCustomerNr: string) => void;
    tenantOfUser: Tenant;
    tenants: Tenant[];
}

export function OperatorParkingaboUserCreateForm({
    tenantOfUser,
    tenants,
    onCreated,
    onClose,
    onDirtyStateChange,
}: OperatorParkingaboUserCreateFormProps) {
    const [createState, createRequest] = useServerWrite<
        PostParkignaboUserPayload,
        ParkingaboUser,
        ValidationData
    >(() => ({
        url: '/ui-api/operator-account/parkingabo/customer',
    }));

    useServerSuccessEffect(createState, data => onCreated(data.customerNr));

    return (
        <OperatorParkingaboUserForm
            title={
                <Localized
                    de="Neuer Benutzer"
                    fr="Nouveau utilisateur"
                    it="Nuovo utente"
                    en="New user"
                />
            }
            Icon={Person}
            onSubmit={payload =>
                createRequest({
                    tenantId: tenantOfUser.tenantId,
                    ...payload,
                    initialBalance: !payload.initialBalance?.amount
                        ? null
                        : payload.initialBalance,
                })
            }
            submitState={createState}
            tenantOfUser={tenantOfUser}
            tenants={tenants}
            readonly={false}
            onClose={onClose}
            onDirtyStateChange={onDirtyStateChange}
        />
    );
}

export function OperatorParkingaboUserInviteForm({
    tenantOfUser,
    tenants,
    onCreated,
    onClose,
    onDirtyStateChange,
}: OperatorParkingaboUserCreateFormProps) {
    const [createState, createRequest] = useServerWrite<
        PostParkignaboUserPayload,
        ParkingaboUser,
        ValidationData
    >(() => ({
        url: '/ui-api/operator-account/parkingabo/customer',
    }));

    useServerSuccessEffect(createState, data => onCreated(data.customerNr));

    return (
        <OperatorParkingaboUserForm
            title={
                <Localized
                    de="Benutzer einladen"
                    fr="Inviter l'utilisateur"
                    it="Invita utente"
                    en="Invite user"
                />
            }
            Icon={AutoAwesome}
            onSubmit={payload =>
                createRequest({
                    tenantId: tenantOfUser.tenantId,
                    ...payload,
                    initialBalance: !payload.initialBalance?.amount
                        ? null
                        : payload.initialBalance,
                })
            }
            submitState={createState}
            tenantOfUser={tenantOfUser}
            tenants={tenants}
            readonly={false}
            onClose={onClose}
            onDirtyStateChange={onDirtyStateChange}
        />
    );
}

interface OperatorParkingaboUserFormProps {
    title: React.ReactNode;
    Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
    };
    parkingaboUser?: ParkingaboUser;
    onSubmit?: (formData: ParkingaboUserFormData) => void;
    submitState: ServerRequestState<ParkingaboUser, ValidationData>;
    readonly: boolean;
    tenantOfUser: Tenant;
    tenants: Tenant[];
    onClose: () => void;
    onDirtyStateChange?: (dirty: boolean) => void;
    detailHeader?: JSX.Element;
    detailBar?: JSX.Element;
}

function getTranslationForPaymeantState(
    state: PaymentMeansState | null | undefined,
): Message {
    if (!state) return EMPTY_TRANSLATION;
    switch (state) {
        case PaymentMeansState.VALID:
            return {
                de: 'GÜLTIG',
                fr: 'VALABLE',
                it: 'VALIDO',
                en: 'VALID',
            };
        case PaymentMeansState.INVALID:
            return {
                de: 'NICHT GÜLTIG',
                fr: 'PAS VALIDE',
                it: 'NON VALIDO',
                en: 'INVALID',
            };

        case PaymentMeansState.MISSING:
            return {
                de: 'NICHT VORHANDEN',
                fr: 'PAS EXISTANT',
                it: 'NON PRESENTE',
                en: 'NOT AVAILABLE',
            };
    }
}

function getTranslationForParkingaboUserState(
    state: ParkingaboUserStatus | null | undefined,
    onboardingCompleteTime: string | null | undefined,
    appMode: AppMode,
): Message {
    if (!state) return EMPTY_TRANSLATION;

    switch (state) {
        case ParkingaboUserStatus.ACTIVE:
            if (onboardingCompleteTime || appMode === AppMode.NONE) {
                return {
                    de: 'AKTIV',
                    fr: 'ACTIF',
                    it: 'ATTIVO',
                    en: 'ACTIVE',
                };
            }
            return {
                de: 'PENDENT',
                fr: 'EN ATTENTE',
                it: 'IN ATTESA',
                en: 'PENDING',
            };
        case ParkingaboUserStatus.ARCHIVED:
            return {
                de: 'ARCHIVIERT',
                fr: 'ARCHIVÉ',
                it: 'ARCHIVIATO',
                en: 'ARCHIVED',
            };
        case ParkingaboUserStatus.LOCKED:
            return {
                de: 'GESPERRT',
                fr: 'VERROUILLÉ',
                it: 'BLOCCATO',
                en: 'LOCKED',
            };
    }
}

function FirstNameLastNameFields({
    readonly,
    control,
    longTooManyCharsMessage,
    mandatoryFieldMessage,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    longTooManyCharsMessage: string;
    mandatoryFieldMessage: string;
}) {
    return (
        <Stack spacing={2} direction="row">
            <ReactHookFormTextField
                readonly={readonly}
                label={
                    <Localized de="Name" fr="Nom" it="Cognome" en="Lastname" />
                }
                name={Fields.LastName}
                autoFocus={true}
                style={{
                    flexGrow: 1,
                }}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: mandatoryFieldMessage,
                    },
                    maxLength: {
                        value: MAX_LENGTH_LONG,
                        message: longTooManyCharsMessage,
                    },
                }}
                trimOnBlur={true}
            />
            <ReactHookFormTextField
                readonly={readonly}
                label={
                    <Localized
                        de="Vorname"
                        fr="Prénom"
                        it="Nome"
                        en="Firstname"
                    />
                }
                name={Fields.FirstName}
                style={{
                    flexGrow: 1,
                }}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: mandatoryFieldMessage,
                    },
                    maxLength: {
                        value: MAX_LENGTH_LONG,
                        message: longTooManyCharsMessage,
                    },
                }}
                trimOnBlur={true}
            />
        </Stack>
    );
}

function StateAndIdFields({
    parkingaboUser,
    control,
    statusTranslation,
    tenantOfUser,
}: {
    parkingaboUser?: ParkingaboUser;
    control: Control<FieldValues, object>;
    statusTranslation: string;
    tenantOfUser: Tenant;
}) {
    return (
        <Stack spacing={2} direction="row">
            {parkingaboUser?.state && (
                <TextField
                    disabled={true}
                    label={
                        <Localized
                            de="Zustand"
                            fr="État"
                            it="Stato"
                            en="State"
                        />
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconForCustomerStatus
                                    status={parkingaboUser.state}
                                    onboardingCompleteTime={
                                        parkingaboUser.onboardingCompleteTime
                                    }
                                    appMode={tenantOfUser.appMode}
                                />
                            </InputAdornment>
                        ),
                    }}
                    defaultValue={statusTranslation}
                />
            )}
            {parkingaboUser?.customerNr && (
                <ReactHookFormTextField
                    readonly={true}
                    label={<Localized de="ID" fr="ID" it="ID" en="ID" />}
                    name={Fields.ID}
                    control={control}
                />
            )}
        </Stack>
    );
}

function EmailField({
    readonly,
    control,
    emailInvalidMessage,
    longTooManyCharsMessage,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    emailInvalidMessage: string;
    longTooManyCharsMessage: string;
}) {
    return (
        <ReactHookFormTextField
            readonly={readonly}
            label={<Localized de="E-Mail" fr="E-mail" it="E-mail" en="Email" />}
            name={Fields.Email}
            control={control}
            rules={{
                validate: {
                    isEmailValid: (value: string | null) =>
                        value !== '' && value !== null
                            ? EmailValidator.validate(value) ||
                              emailInvalidMessage
                            : true,
                },
                maxLength: {
                    value: MAX_LENGTH_LONG,
                    message: longTooManyCharsMessage,
                },
            }}
            trimOnBlur={true}
        />
    );
}

function ReferenceField({
    readonly,
    control,
    shortTooManyCharsMessage,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    shortTooManyCharsMessage: string;
}) {
    return (
        <ReactHookFormTextField
            sx={{
                marginTop: '48px !important',
            }}
            readonly={readonly}
            label={
                <Localized
                    de="Referenz"
                    fr="Référence"
                    it="Referenza"
                    en="Reference"
                />
            }
            name={Fields.Reference}
            control={control}
            trimOnBlur={true}
            rules={{
                maxLength: {
                    value: MAX_LENGTH_SHORT,
                    message: shortTooManyCharsMessage,
                },
            }}
        />
    );
}

function PhoneField({
    readonly,
    control,
    shortTooManyCharsMessage,
    phoneInvalidCharsMessage,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    shortTooManyCharsMessage: string;
    phoneInvalidCharsMessage: string;
}) {
    return (
        <ReactHookFormTextField
            readonly={readonly}
            label={
                <Localized
                    de="Telefon"
                    fr="Téléphone"
                    it="Telefono"
                    en="Phone"
                />
            }
            name={Fields.Phone}
            control={control}
            rules={{
                maxLength: {
                    value: MAX_LENGTH_SHORT,
                    message: shortTooManyCharsMessage,
                },
                validate: {
                    isPhoneValid: (value: string) => {
                        if (value === '') {
                            return true;
                        }

                        if (!/^[+0-9][0-9 ]*$/.test(value)) {
                            return phoneInvalidCharsMessage;
                        }

                        return true;
                    },
                },
            }}
            trimOnBlur={true}
        />
    );
}

function CompanyDataContent({
    readonly,
    control,
    longTooManyCharsMessage,
    shortTooManyCharsMessage,
    statusTranslation,
    parkingaboUser,
    tenantOfUser,
    mandatoryFieldMessage,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    longTooManyCharsMessage: string;
    shortTooManyCharsMessage: string;
    statusTranslation: string;
    parkingaboUser?: ParkingaboUser;
    tenantOfUser: Tenant;
    mandatoryFieldMessage: string;
}) {
    return (
        <>
            <DivideBar
                largeMarginTop={false}
                title={
                    <Localized
                        de="Firmendaten"
                        fr="Détails de l'entreprise"
                        it="Dettagli della Compagnia"
                        en="Company details"
                    />
                }
            />
            <StateAndIdFields
                parkingaboUser={parkingaboUser}
                control={control}
                statusTranslation={statusTranslation}
                tenantOfUser={tenantOfUser}
            />
            <ReactHookFormTextField
                readonly={readonly}
                label={
                    <Localized
                        de="Firmenname 1"
                        fr="Nom de l'entreprise 1"
                        it="Nome dell'azienda 1"
                        en="Company name 1"
                    />
                }
                name={Fields.Company1}
                autoFocus={true}
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: mandatoryFieldMessage,
                    },
                    maxLength: {
                        value: MAX_LENGTH_LONG,
                        message: longTooManyCharsMessage,
                    },
                }}
                trimOnBlur={true}
            />
            <ReactHookFormTextField
                readonly={readonly}
                label={
                    <Localized
                        de="Firmenname 2"
                        fr="Nom de l'entreprise 2"
                        it="Nome dell'azienda 2"
                        en="Company name 2"
                    />
                }
                name={Fields.Company2}
                control={control}
                rules={{
                    maxLength: {
                        value: MAX_LENGTH_LONG,
                        message: longTooManyCharsMessage,
                    },
                }}
                trimOnBlur={true}
            />
            <ReferenceField
                readonly={readonly}
                control={control}
                shortTooManyCharsMessage={shortTooManyCharsMessage}
            />
        </>
    );
}

function ContactPersonContent({
    readonly,
    control,
    longTooManyCharsMessage,
    shortTooManyCharsMessage,
    emailInvalidMessage,
    phoneInvalidCharsMessage,
    mandatoryFieldMessage,
    tenantOfUser,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    longTooManyCharsMessage: string;
    shortTooManyCharsMessage: string;
    emailInvalidMessage: string;
    phoneInvalidCharsMessage: string;
    mandatoryFieldMessage: string;
    tenantOfUser: Tenant;
}) {
    return (
        <>
            <DivideBar
                largeMarginTop={true}
                title={
                    <Localized
                        de="Kontaktperson"
                        fr="Personne de contact"
                        it="Persona di contatto"
                        en="Contact person"
                    />
                }
            />
            <FirstNameLastNameFields
                readonly={readonly}
                control={control}
                longTooManyCharsMessage={longTooManyCharsMessage}
                mandatoryFieldMessage={mandatoryFieldMessage}
            />
            {tenantOfUser.appMode == AppMode.NONE && (
                <EmailField
                    control={control}
                    readonly={readonly}
                    emailInvalidMessage={emailInvalidMessage}
                    longTooManyCharsMessage={longTooManyCharsMessage}
                />
            )}
            <PhoneField
                readonly={readonly}
                control={control}
                shortTooManyCharsMessage={shortTooManyCharsMessage}
                phoneInvalidCharsMessage={phoneInvalidCharsMessage}
            />
        </>
    );
}

function UserDataContent({
    readonly,
    control,
    longTooManyCharsMessage,
    shortTooManyCharsMessage,
    emailInvalidMessage,
    phoneInvalidCharsMessage,
    statusTranslation,
    parkingaboUser,
    tenantOfUser,
    mandatoryFieldMessage,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    longTooManyCharsMessage: string;
    shortTooManyCharsMessage: string;
    emailInvalidMessage: string;
    phoneInvalidCharsMessage: string;
    statusTranslation: string;
    parkingaboUser?: ParkingaboUser;
    tenantOfUser: Tenant;
    mandatoryFieldMessage: string;
}) {
    return (
        <>
            <DivideBar
                largeMarginTop={false}
                title={
                    <Localized
                        de="Personendaten"
                        fr="Données personnelles"
                        it="Dati personali"
                        en="Personal data"
                    />
                }
            />
            <StateAndIdFields
                parkingaboUser={parkingaboUser}
                control={control}
                statusTranslation={statusTranslation}
                tenantOfUser={tenantOfUser}
            />
            <FirstNameLastNameFields
                readonly={readonly}
                control={control}
                longTooManyCharsMessage={longTooManyCharsMessage}
                mandatoryFieldMessage={mandatoryFieldMessage}
            />
            <ReferenceField
                readonly={readonly}
                control={control}
                shortTooManyCharsMessage={shortTooManyCharsMessage}
            />
            {tenantOfUser.appMode == AppMode.NONE && (
                <EmailField
                    control={control}
                    readonly={readonly}
                    emailInvalidMessage={emailInvalidMessage}
                    longTooManyCharsMessage={longTooManyCharsMessage}
                />
            )}
            <PhoneField
                readonly={readonly}
                control={control}
                shortTooManyCharsMessage={shortTooManyCharsMessage}
                phoneInvalidCharsMessage={phoneInvalidCharsMessage}
            />
        </>
    );
}

function SettingsContent({
    readonly,
    control,
    hasAppLogin,
    tenants,
    tenantNameTranslation,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    hasAppLogin: boolean;
    tenants: Tenant[];
    tenantNameTranslation: string;
}) {
    return (
        <>
            <DivideBar
                largeMarginTop={hasAppLogin}
                title={
                    <Localized
                        de="Einstellungen"
                        fr="Paramètres"
                        it="Impostazioni"
                        en="Settings"
                    />
                }
            />

            {tenants.length > 1 && (
                <TextField
                    disabled={true}
                    label={
                        <Localized
                            de="Kategorie"
                            fr="Catégorie"
                            it="Categoria"
                            en="Category"
                        />
                    }
                    defaultValue={tenantNameTranslation}
                />
            )}
            <OperatorFormSelect
                readonly={readonly}
                label={
                    <Localized
                        de="Sprache"
                        fr="Langue"
                        it="Lingua"
                        en="Language"
                    />
                }
                name={Fields.Language}
                control={control}
                options={[
                    {
                        display: (
                            <Localized
                                de="Deutsch"
                                fr="Allemande"
                                it="Tedesco"
                                en="German"
                            />
                        ),
                        value: Language.DE,
                    },
                    {
                        display: (
                            <Localized
                                de="Französisch"
                                fr="Français"
                                it="Francese"
                                en="French"
                            />
                        ),
                        value: Language.FR,
                    },
                    {
                        display: (
                            <Localized
                                de="Italienisch"
                                fr="Italienne"
                                it="Italiano"
                                en="Italian"
                            />
                        ),
                        value: Language.IT,
                    },
                    {
                        display: (
                            <Localized
                                de="Englisch"
                                fr="Anglaise"
                                it="Inglese"
                                en="English"
                            />
                        ),
                        value: Language.EN,
                    },
                ]}
            />
        </>
    );
}

function MiscellaneousContent({
    readonly,
    control,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
}) {
    return (
        <>
            <DivideBar
                largeMarginTop={true}
                title={
                    <Localized
                        de="Diverses"
                        fr="Divers"
                        it="Varie"
                        en="Miscellaneous"
                    />
                }
            />
            <ReactHookFormTextField
                readonly={readonly}
                label={
                    <>
                        <Localized
                            de="Bemerkungen"
                            fr="Remarques"
                            it="Osservazioni"
                            en="Remarks"
                        />
                        <Tooltip
                            title={
                                <Localized
                                    de="Die interne Bemerkungen sind nur hier im Parkingportal sichtbar."
                                    fr="Les remarques internes sont uniquement visibles ici dans Parkingportal."
                                    it="Le osservazioni interne sono visibili unicamente qui in Parkingportal."
                                    en="The internal remarks are only visible here in Parkingportal."
                                />
                            }
                            placement="right"
                        >
                            <InfoIcon
                                sx={{
                                    color: theme => theme.palette.blue.main,
                                    marginLeft: 1,
                                }}
                            />
                        </Tooltip>
                    </>
                }
                name={Fields.Remarks}
                control={control}
                multiline={true}
                rows={3}
                trimOnBlur={true}
            />
        </>
    );
}

function AppContent({
    parkingaboUser,
    paymentTranslation,
}: {
    parkingaboUser?: ParkingaboUser;
    paymentTranslation: string;
}) {
    const hasLogin =
        parkingaboUser?.logins && parkingaboUser?.logins.length > 0;
    return (
        <>
            <DivideBar
                largeMarginTop={false}
                title={<Localized de="App" fr="App" it="App" en="App" />}
            />

            {parkingaboUser?.tenant.paymentMode ===
                TenantPaymentMode.DIGITAL_PURCHASE && (
                <TextField
                    disabled={true}
                    label={
                        <Localized
                            de="Zahlungsmittel"
                            fr="Moyen de paiement"
                            it="Mezzo di pagamento"
                            en="Payment mean"
                        />
                    }
                    InputProps={
                        hasLogin
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <PaymentMeansStateIcon
                                              state={
                                                  parkingaboUser?.paymentMeansState
                                              }
                                          />
                                      </InputAdornment>
                                  ),
                              }
                            : undefined
                    }
                    defaultValue={hasLogin ? paymentTranslation : EmDash}
                />
            )}
            {
                <ReadOnlyTextField
                    label={'APP-LOGIN'}
                    value={
                        hasLogin
                            ? parkingaboUser.logins.map(login => (
                                  <div
                                      key={login.loginId}
                                      className={css({
                                          marginTop: '0px !important',
                                          marginBottom: '8px',
                                          ...Typo.robotoMedium,
                                          color: ColorHex.darkblue,
                                      })}
                                  >
                                      <p
                                          className={css({
                                              marginTop: '0px',
                                              marginBottom: '8px',
                                          })}
                                      >
                                          {getEmailLabelFromLogin(login)}
                                      </p>
                                      {login.requestedEmail && (
                                          <p
                                              className={css({
                                                  fontStyle: 'italic',
                                                  marginTop: '0px',
                                                  marginBottom: '8px',
                                              })}
                                          >
                                              {useLocalized({
                                                  de: `${login.requestedEmail} (noch nicht bestätigt)`,
                                                  fr: `${login.requestedEmail} (non encore confirmé)`,
                                                  it: `${login.requestedEmail} (non ancora confermato)`,
                                                  en: `${login.requestedEmail} (not yet confirmed)`,
                                              })}
                                          </p>
                                      )}
                                  </div>
                              ))
                            : EmDash
                    }
                />
            }
        </>
    );
}

function isCurrentLegalFormToggleDisabled(
    toggleButtonLegalForm: CustomerTenantLegalForm,
    legalForm: CustomerTenantLegalForm | null,
    allowedLegalForm: TenantAllowedCustomerLegalForm,
) {
    if (
        allowedLegalForm === TenantAllowedCustomerLegalForm.PRIVATE_AND_COMPANY
    ) {
        return false;
    }

    return (
        legalForm === null ||
        (legalForm === CustomerTenantLegalForm.COMPANY &&
            allowedLegalForm === TenantAllowedCustomerLegalForm.COMPANY_ONLY &&
            toggleButtonLegalForm === CustomerTenantLegalForm.PRIVATE) ||
        (legalForm === CustomerTenantLegalForm.PRIVATE &&
            allowedLegalForm === TenantAllowedCustomerLegalForm.PRIVATE_ONLY &&
            toggleButtonLegalForm === CustomerTenantLegalForm.COMPANY)
    );
}

const MAX_LENGTH_SHORT = 25;
const MAX_LENGTH_LONG = 50;

export function OperatorParkingaboUserForm({
    title,
    Icon,
    parkingaboUser,
    onSubmit,
    submitState,
    readonly,
    tenantOfUser,
    tenants,
    onDirtyStateChange,
    onClose,
    detailHeader,
    detailBar,
}: OperatorParkingaboUserFormProps) {
    const language = useOperatorLanguage();
    const isCreate = !parkingaboUser?.customerNr;

    const {
        formInfo: {
            control,
            handleSubmit,
            watch,
            formState: { isDirty, isValid },
            reset,
            setValue,
        },
        genericSubmitError,
    } = useEasyForm(onDirtyStateChange, submitState, language, {
        defaultValues: deriveFormValuesOrUseDefault(
            parkingaboUser,
            tenantOfUser.allowedCustomerLegalForm,
            language,
        ),
    });
    const legalFormValue = watch(Fields.LegalForm);
    const initialBalance = watch(Fields.InitialBalance);

    useServerSuccessEffect(submitState, newValues => {
        reset(
            deriveFormValuesOrUseDefault(
                newValues,
                tenantOfUser.allowedCustomerLegalForm,
                language,
            ),
        );
    });

    useEffect(() => {
        if (parkingaboUser) {
            reset(
                deriveFormValuesOrUseDefault(
                    parkingaboUser,
                    tenantOfUser.allowedCustomerLegalForm,
                    language,
                ),
            );
        }
    }, [parkingaboUser]);

    const paymentTranslation = useLocalized(
        getTranslationForPaymeantState(parkingaboUser?.paymentMeansState),
    );
    const statusTranslation = useLocalized(
        getTranslationForParkingaboUserState(
            parkingaboUser?.state,
            parkingaboUser?.onboardingCompleteTime,
            tenantOfUser.appMode,
        ),
    );
    const tenantNameTranslation = useLocalized(tenantOfUser.name);
    const emailInvalidMessage = useLocalized({
        de: 'E-Mail nicht gültig.',
        fr: 'E-mail non valide.',
        it: 'E-mail non valida.',
        en: 'Email not valid.',
    });
    const phoneInvalidCharsMessage = useLocalized({
        de: 'Es sind nur Zahlen, + und Leerzeichen zulässig.',
        fr: 'Seuls les chiffres, + et les espaces sont autorisés.',
        it: 'Sono consentiti solo numeri, + e spazi.',
        en: 'Only numbers, +, and spaces are allowed.',
    });
    const shortTooManyCharsMessage = useLocalized({
        de: `Es sind nicht mehr als ${MAX_LENGTH_SHORT} Zeichen erlaubt für dieses Feld.`,
        fr: `Pas plus de ${MAX_LENGTH_SHORT} caractères sont autorisés pour ce champ.`,
        it: `Non sono consentiti più di ${MAX_LENGTH_SHORT} caratteri per questo campo.`,
        en: `No more than ${MAX_LENGTH_SHORT} characters are allowed for this field.`,
    });
    const longTooManyCharsMessage = useLocalized({
        de: `Es sind nicht mehr als ${MAX_LENGTH_LONG} Zeichen erlaubt für dieses Feld.`,
        fr: `Pas plus de ${MAX_LENGTH_LONG} caractères sont autorisés pour ce champ.`,
        it: `Non sono consentiti più di ${MAX_LENGTH_LONG} caratteri per questo campo.`,
        en: `No more than ${MAX_LENGTH_LONG} characters are allowed for this field.`,
    });
    const mandatoryFieldMessage = useLocalized(mandatoryFieldErrorMessage);

    const { operatorData, currentLogin } = useOperatorContext();

    const canCreateInitialBalance = parkingaboUser
        ? parkingaboUser.isAllowedInitialBalanceCreation
        : tenantOfUser.paymentMode === TenantPaymentMode.DIGITAL_PURCHASE &&
          currentLogin.permissions.parkingaboInitialBalanceCreate &&
          operatorData.hasParkingaboUserInitialBalanceActive;

    const [openInternalBalanceWarning, setOpenInternalBalanceWarning] =
        useState(false);

    useServerErrorEffect(submitState, () => {
        setOpenInternalBalanceWarning(false);
    });

    return (
        <OperatorRoutedModalContent
            header={
                detailHeader && !isDirty ? (
                    detailHeader
                ) : (
                    <HeaderComponent
                        title={title}
                        Icon={Icon}
                        editMode={true}
                        buttons={
                            <HeaderCancelSaveButtons
                                onCancel={isCreate ? onClose : reset}
                                onSave={
                                    initialBalance?.amount &&
                                    !parkingaboUser?.initialBalance &&
                                    isValid
                                        ? () =>
                                              setOpenInternalBalanceWarning(
                                                  true,
                                              )
                                        : onSubmit
                                          ? () => handleSubmit(onSubmit)()
                                          : undefined
                                }
                                loading={
                                    submitState.status === RequestStatus.PENDING
                                }
                                noDiscardDialog={isCreate}
                            />
                        }
                    />
                )
            }
            bar={detailBar}
            body={
                <>
                    <InternalBalanceWarningDialog
                        open={openInternalBalanceWarning}
                        onCancel={() => {
                            setValue(Fields.InitialBalance, {
                                [InitialBalanceFields.Amount]: 0,
                                [InitialBalanceFields.Remark]: '',
                            });
                            setOpenInternalBalanceWarning(false);
                        }}
                        onSubmit={
                            onSubmit
                                ? () => handleSubmit(onSubmit)()
                                : undefined
                        }
                        initialBalanceAmount={initialBalance.amount}
                    />
                    {genericSubmitError != null && (
                        <GenericFormSubmitError
                            error={genericSubmitError}
                            submitState={submitState}
                        />
                    )}
                    {parkingaboUser?.needsProduct ? (
                        <Alert severity="warning" sx={{ margin: '0 0 20px 0' }}>
                            <Localized
                                de="Nächster Schritt: mindestens ein PRODUKT hinzufügen"
                                fr="Prochaine étape: ajouter au moins un PRODUIT"
                                it="Prossimo passo: aggiungere almeno un PRODOTTO"
                                en="Next step: add at least a PRODUCT"
                            />
                        </Alert>
                    ) : (
                        parkingaboUser?.needsVehicleWithBadge && (
                            <Alert
                                severity="warning"
                                sx={{ margin: '0 0 20px 0' }}
                            >
                                <Localized
                                    de="Letzter Schritt: mindestens ein FAHRZEUG MIT BADGE hinzufügen"
                                    fr="Dernière étape: ajoute au moins un VÉHICULE AVEC BADGE"
                                    it="Ultimo passo: aggiungere almeno un VEICOLO CON BADGE"
                                    en="Next step: add at least a VEHICLE WITH BADGE"
                                />
                            </Alert>
                        )
                    )}
                    <FormContentLayout>
                        <FormLeftColumn>
                            <Controller
                                control={control}
                                name="legalForm"
                                render={({ field }) => (
                                    <ToggleButtonGroup
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                        exclusive={true}
                                        value={field.value}
                                        onChange={field.onChange}
                                    >
                                        <ToggleButton
                                            value="PRIVATE"
                                            sx={{
                                                flex: 1,
                                                color: Colors.darkblue,
                                                backgroundColor: Colors.white,
                                                '&.Mui-selected': {
                                                    color: Colors.white,
                                                    backgroundColor: theme =>
                                                        theme.palette.blue.main,
                                                    pointerEvents: 'none',
                                                },
                                            }}
                                            disabled={isCurrentLegalFormToggleDisabled(
                                                CustomerTenantLegalForm.PRIVATE,
                                                parkingaboUser?.legalForm ??
                                                    null,
                                                tenantOfUser.allowedCustomerLegalForm,
                                            )}
                                        >
                                            <Localized
                                                de="Person"
                                                fr="Personne"
                                                it="Persona"
                                                en="Person"
                                            />
                                        </ToggleButton>
                                        <ToggleButton
                                            value="COMPANY"
                                            sx={{
                                                flex: 1,
                                                color: Colors.darkblue,
                                                backgroundColor: Colors.white,
                                                '&.Mui-selected': {
                                                    color: Colors.white,
                                                    backgroundColor: theme =>
                                                        theme.palette.blue.main,
                                                    pointerEvents: 'none',
                                                },
                                            }}
                                            disabled={isCurrentLegalFormToggleDisabled(
                                                CustomerTenantLegalForm.COMPANY,
                                                parkingaboUser?.legalForm ??
                                                    null,
                                                tenantOfUser.allowedCustomerLegalForm,
                                            )}
                                        >
                                            <Localized
                                                de="Firma"
                                                fr="Entreprise"
                                                it="Azienda"
                                                en="Company"
                                            />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            />
                            {legalFormValue ===
                            CustomerTenantLegalForm.COMPANY ? (
                                <>
                                    <CompanyDataContent
                                        parkingaboUser={parkingaboUser}
                                        tenantOfUser={tenantOfUser}
                                        control={control}
                                        readonly={readonly}
                                        statusTranslation={statusTranslation}
                                        longTooManyCharsMessage={
                                            longTooManyCharsMessage
                                        }
                                        shortTooManyCharsMessage={
                                            shortTooManyCharsMessage
                                        }
                                        mandatoryFieldMessage={
                                            mandatoryFieldMessage
                                        }
                                    />
                                    <ContactPersonContent
                                        tenantOfUser={tenantOfUser}
                                        control={control}
                                        readonly={readonly}
                                        longTooManyCharsMessage={
                                            longTooManyCharsMessage
                                        }
                                        shortTooManyCharsMessage={
                                            shortTooManyCharsMessage
                                        }
                                        emailInvalidMessage={
                                            emailInvalidMessage
                                        }
                                        phoneInvalidCharsMessage={
                                            phoneInvalidCharsMessage
                                        }
                                        mandatoryFieldMessage={
                                            mandatoryFieldMessage
                                        }
                                    />
                                </>
                            ) : (
                                <UserDataContent
                                    parkingaboUser={parkingaboUser}
                                    control={control}
                                    readonly={readonly}
                                    tenantOfUser={tenantOfUser}
                                    statusTranslation={statusTranslation}
                                    emailInvalidMessage={emailInvalidMessage}
                                    phoneInvalidCharsMessage={
                                        phoneInvalidCharsMessage
                                    }
                                    longTooManyCharsMessage={
                                        longTooManyCharsMessage
                                    }
                                    shortTooManyCharsMessage={
                                        shortTooManyCharsMessage
                                    }
                                    mandatoryFieldMessage={
                                        mandatoryFieldMessage
                                    }
                                />
                            )}
                        </FormLeftColumn>
                        <FormRightColumn>
                            {(canCreateInitialBalance ||
                                parkingaboUser?.initialBalance) && (
                                <InitialBalanceContent
                                    readonly={readonly}
                                    control={control}
                                    canCreateInitialBalance={Boolean(
                                        canCreateInitialBalance,
                                    )}
                                    hasAmountDefined={Boolean(
                                        initialBalance.amount,
                                    )}
                                />
                            )}
                            {parkingaboUser?.hasAppLogin && (
                                <AppContent
                                    parkingaboUser={parkingaboUser}
                                    paymentTranslation={paymentTranslation}
                                />
                            )}
                            <SettingsContent
                                readonly={readonly}
                                control={control}
                                hasAppLogin={Boolean(
                                    parkingaboUser?.hasAppLogin,
                                )}
                                tenants={tenants}
                                tenantNameTranslation={tenantNameTranslation}
                            />
                            <MiscellaneousContent
                                readonly={readonly}
                                control={control}
                            />
                        </FormRightColumn>
                    </FormContentLayout>
                </>
            }
        />
    );
}

function PaymentMeansStateIcon({
    state,
}: {
    state?: PaymentMeansState | null;
}) {
    if (!state) return <>{EmDash}</>;
    switch (state) {
        case PaymentMeansState.VALID:
            return <Circle color="success" />;
        case PaymentMeansState.INVALID:
            return <Circle color="warning" />;
        case PaymentMeansState.MISSING:
            return <Circle color="error" />;
    }
}

function deriveFormValuesOrUseDefault(
    inputValues: ParkingaboUser | CreateParkingaboUserInitialValues | undefined,
    allowedCustomerLegalForm: TenantAllowedCustomerLegalForm,
    language: Language,
): FieldValues {
    return {
        [Fields.Language]: inputValues?.language ?? language,
        [Fields.FirstName]: inputValues?.firstName ?? '',
        [Fields.LastName]: inputValues?.lastName ?? '',
        [Fields.Company1]: inputValues?.company1 ?? '',
        [Fields.Company2]: inputValues?.company2 ?? '',
        [Fields.Email]: inputValues?.email ?? '',
        [Fields.Phone]: inputValues?.phone ?? '',
        [Fields.Reference]: inputValues?.reference ?? '',
        [Fields.Remarks]: inputValues?.remarks ?? '',
        [Fields.ID]: inputValues?.customerNr ?? null,
        [Fields.State]: inputValues?.state ?? null,
        [Fields.PaymentMeansState]: inputValues?.paymentMeansState ?? null,
        [Fields.LegalForm]:
            inputValues?.legalForm ??
            getDefaultLegalFormValueByTenantAllowedLegalForm(
                allowedCustomerLegalForm,
            ),
        [Fields.InitialBalance]: {
            [InitialBalanceFields.Amount]:
                inputValues?.initialBalance?.amount || 0,
            [InitialBalanceFields.Remark]:
                inputValues?.initialBalance?.remark || '',
        },
    };
}

function DivideBar({
    title,
    largeMarginTop,
}: {
    title: JSX.Element;
    largeMarginTop: boolean;
}) {
    return (
        <Box
            sx={{
                marginTop: largeMarginTop ? '48px' : '24px',
                marginBottom: '48px',
                ...OperatorTypo.headingOne,
                textTransform: 'uppercase',
                fontWeight: '600',
                color: theme => theme.palette.blue.main,
                backgroundColor: theme => theme.palette.grey.A200,
            }}
        >
            <Box sx={{ textAlign: 'center', margin: '2px 0' }}>{title}</Box>
        </Box>
    );
}

function getDefaultLegalFormValueByTenantAllowedLegalForm(
    allowedCustomerLegalForm: TenantAllowedCustomerLegalForm,
): CustomerTenantLegalForm {
    switch (allowedCustomerLegalForm) {
        case TenantAllowedCustomerLegalForm.COMPANY_ONLY:
            return CustomerTenantLegalForm.COMPANY;
        case TenantAllowedCustomerLegalForm.PRIVATE_ONLY:
        case TenantAllowedCustomerLegalForm.PRIVATE_AND_COMPANY:
            return CustomerTenantLegalForm.PRIVATE;
    }
}

function getEmailLabelFromLogin(login: ParkingaboLogin): string {
    switch (login.state) {
        case ParkingaboLoginState.CONFIRMED:
            return login.email;
        case ParkingaboLoginState.CREATED:
            return useLocalized({
                de: `${login.email} (nicht bestätigt)`,
                fr: `${login.email} (non confirmé)`,
                it: `${login.email} (non confermato)`,
                en: `${login.email} (not confirmed)`,
            });
        case ParkingaboLoginState.DELETED:
            return '–';
    }
}

function InitialBalanceContent({
    readonly,
    control,
    canCreateInitialBalance,
    hasAmountDefined,
}: {
    readonly: boolean;
    control: Control<FieldValues, object>;
    canCreateInitialBalance: boolean;
    hasAmountDefined: boolean;
}) {
    return (
        <>
            <DivideBar
                largeMarginTop
                title={
                    <Localized
                        de="RESTWERT DER SALDOKARTE"
                        fr="VALEUR RÉSIDUELLE CARTE DE SOLDE"
                        it="VALORE RESIDUO CARTA A SALDO"
                        en="RESIDUAL VALUE BALANCE CARD"
                    />
                }
            />
            <ReactHookFormTextField
                type="number"
                readonly={readonly}
                label={
                    <Localized
                        de="Betrag (CHF)"
                        fr="Montant (CHF)"
                        it="Importo (CHF)"
                        en="Amount (CHF)"
                    />
                }
                inputProps={{ step: 0.01 }}
                name={`${Fields.InitialBalance}.${InitialBalanceFields.Amount}`}
                control={control}
                disabled={!canCreateInitialBalance}
            />
            <ReactHookFormTextField
                readonly={readonly}
                label={
                    <>
                        <Localized
                            de="Interne Bemerkungen"
                            fr="Remarques internes"
                            it="Commenti interni"
                            en="Internal comments"
                        />
                        <Tooltip
                            title={
                                <Localized
                                    de="Die interne Bemerkungen sind nur hier im Parkingportal sichtbar."
                                    fr="Les remarques internes sont uniquement visibles ici dans Parkingportal."
                                    it="Le osservazioni interne sono visibili unicamente qui in Parkingportal."
                                    en="The internal remarks are only visible here in Parkingportal."
                                />
                            }
                            placement="right"
                        >
                            <InfoIcon
                                sx={{
                                    color: theme => theme.palette.blue.main,
                                    marginLeft: 1,
                                }}
                            />
                        </Tooltip>
                    </>
                }
                name={`${Fields.InitialBalance}.${InitialBalanceFields.Remark}`}
                control={control}
                trimOnBlur={true}
                disabled={!canCreateInitialBalance || !hasAmountDefined}
            />
        </>
    );
}

function InternalBalanceWarningDialog({
    open,
    onCancel,
    onSubmit,
    initialBalanceAmount,
}: {
    open: boolean;
    onCancel: () => void;
    onSubmit?: () => void;
    initialBalanceAmount: number;
}) {
    return (
        <Dialog open={open} maxWidth="xs" onClose={onCancel}>
            <DialogTitle>
                <Localized
                    de="RESTWERT DER SALDOKARTE"
                    fr="VALEUR RÉSIDUELLE CARTE DE SOLDE"
                    it="VALORE RESIDUO CARTA A SALDO"
                    en="RESIDUAL VALUE BALANCE CARD"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>
                        <Localized
                            de={`Beim Speichern dieses Benutzers wird ihm CHF ${initialBalanceAmount} als Startguthaben aus dem Restwert der vorherigen Saldo-Karte gutgeschrieben.`}
                            fr={`En sauvegardant cet utilisateur, il sera crédité de ${initialBalanceAmount} CHF comme crédit initial correspondant à la valeur résiduelle de l'ancienne carte de solde.`}
                            it={`Salvando questo utente, gli verranno accreditati CHF ${initialBalanceAmount} come credito iniziale corrispondente al valore residuo della vecchia carta a saldo.`}
                            en={`By saving this user, he will be credited CHF ${initialBalanceAmount} as initial credit from the residual value of the old balance card.`}
                        />
                    </p>
                    <p>
                        <b>
                            <Localized
                                de="Wichtig: diese Aktion kann nicht rückgängig gemacht oder geändert werden."
                                fr="Important: cette action ne peut être ni annulée ni modifiée."
                                it="Importante: questa azione non può essere annullata o modificata."
                                en="Important: this action cannot be undone or modified."
                            />
                        </b>
                    </p>
                    <p>
                        <Localized
                            de="Möchten Sie fortfahren?"
                            fr="Voulez-vous continuer?"
                            it="Vuole continuare?"
                            en="Would you like to continue?"
                        />
                    </p>
                </DialogContentText>
                <DialogActions>
                    <Button onClick={onCancel}>
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    </Button>
                    <Button onClick={onSubmit}>
                        <Localized
                            de="Bestätigen"
                            fr="Confirmer"
                            it="Conferma"
                            en="Confirm"
                        />
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
