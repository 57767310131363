import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import {
    getOrElse,
    isDefined,
    isUndefined,
    Maybe,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { Color, ColorHex, colorString } from '../Colors.ts';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars.ts';

export interface ButtonProps {
    label: React.ReactNode;
    onClick?: Maybe<ClickHandler>;
    disabled?: boolean;
}

function textButtonStyle({
    color,
    backgroundColor,
    colorFadeWhenDisabled,
    backgroundColorFadeWhenDisabled,
    variant,
    disabled,
}: {
    color?: Maybe<Color>;
    backgroundColor?: Maybe<Color>;
    colorFadeWhenDisabled?: Maybe<boolean>;
    backgroundColorFadeWhenDisabled?: Maybe<boolean>;
    variant?: Maybe<'open' | 'error' | 'faded'>;
    onClick?: Maybe<ClickHandler>;
    disabled?: boolean;
}) {
    const fadeAmount = 0.6;
    const defaultColor = ColorHex[colorString(getOrElse(color, Color.white))];
    const defaultBackgroundColor =
        ColorHex[colorString(getOrElse(backgroundColor, Color.darkblue))];

    return css({
        fontSize: 11,
        textTransform: 'uppercase',
        letterSpacing: '0.04em',
        height: 32,
        lineHeight: '32px',
        padding: '0 22px',
        margin: '0 4px',
        boxShadow: `0 1px 4px rgba(${Color.black} 0.30)`,
        borderRadius: 2,
        color: defaultColor,
        backgroundColor: defaultBackgroundColor,
        border: '0',
        outline: 'none',
        textDecoration: 'none',
        ...(disabled
            ? {
                  ...(colorFadeWhenDisabled
                      ? {
                            color: Colors.rgba(defaultColor, fadeAmount),
                            '&:hover': {
                                color: Colors.rgba(defaultColor, fadeAmount),
                            },
                        }
                      : {
                            '&:hover': {
                                color: defaultColor,
                            },
                        }),
                  ...(backgroundColorFadeWhenDisabled && {
                      backgroundColor: Colors.rgba(
                          defaultBackgroundColor,
                          fadeAmount,
                      ),
                  }),
              }
            : {
                  '&:hover': {
                      backgroundColor: Colors.middleblue,
                      color: Colors.white,
                  },
              }),
        ...(variant === 'open' && {
            backgroundColor: Colors.middleblue,
            color: Colors.white,
        }),
        ...(variant === 'error' && {
            backgroundColor: Colors.error,
            color: Colors.white,
        }),
        ...(variant === 'faded' && {
            ...(colorFadeWhenDisabled && {
                color: Colors.rgba(defaultColor, fadeAmount),
            }),
            ...(backgroundColorFadeWhenDisabled && {
                backgroundColor: Colors.rgba(
                    defaultBackgroundColor,
                    fadeAmount,
                ),
            }),
        }),
    });
}

export function TextButton({
    label,
    onClick,
    disabled,
    color,
    backgroundColor,
    colorFadeWhenDisabled,
    backgroundColorFadeWhenDisabled,
    variant,
}: {
    label: React.ReactNode;
    onClick?: Maybe<ClickHandler>;
    disabled?: boolean;
    color?: Maybe<Color>;
    backgroundColor?: Maybe<Color>;
    colorFadeWhenDisabled?: Maybe<boolean>;
    backgroundColorFadeWhenDisabled?: Maybe<boolean>;
    variant?: Maybe<'open' | 'error' | 'faded'>;
}) {
    const isDisabled = isUndefined(onClick) || disabled || false;
    return (
        <Clickable
            element="button"
            className={textButtonStyle({
                color: color,
                backgroundColor: backgroundColor,
                colorFadeWhenDisabled: colorFadeWhenDisabled,
                backgroundColorFadeWhenDisabled:
                    backgroundColorFadeWhenDisabled,
                variant: variant,
                onClick: onClick,
                disabled: isDisabled,
            })}
            onClick={onClick}
            disabled={isDisabled}
        >
            {label}
        </Clickable>
    );
}

interface ActionButtonProps extends ButtonProps {
    error?: Maybe<boolean>;
    faded?: Maybe<boolean>;
}
export const PrimaryActionButton = (p: ActionButtonProps) => (
    <TextButton
        backgroundColor={Color.darkblue}
        color={Color.white}
        backgroundColorFadeWhenDisabled={true}
        variant={
            isDefined(p.error) && p.error
                ? 'error'
                : isDefined(p.faded) && p.faded
                  ? 'faded'
                  : null
        }
        {...p}
    />
);

export const SecondaryActionButton = (p: ActionButtonProps) => (
    <TextButton
        backgroundColor={Color.white}
        color={Color.darkblue}
        colorFadeWhenDisabled={true}
        {...p}
    />
);

interface DropdownButtonProps extends ButtonProps {
    open: boolean;
}
export const DropdownButton = (p: DropdownButtonProps) => (
    <TextButton
        backgroundColor={Color.darkblue}
        color={Color.white}
        colorFadeWhenDisabled={true}
        variant={p.open ? 'open' : null}
        {...p}
    />
);

export function PrimaryLinkButton({
    label,
    href,
    disabled,
    target,
}: {
    label: JSX.Element | string;
    href?: string | undefined;
    disabled?: boolean | undefined;
    target?: string | undefined;
}) {
    return (
        <a
            className={textButtonStyle({
                color: Color.white,
                backgroundColor: Color.darkblue,
                colorFadeWhenDisabled: true,
                backgroundColorFadeWhenDisabled: true,
                disabled: isUndefined(href) || disabled || false,
            })}
            href={!disabled ? href : undefined}
            target={target}
        >
            {label}
        </a>
    );
}
