import {
    ConfirmSaveHeader,
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { usbStickTexts } from '../i18n/UsbStickTexts.ts';
import * as UsbStickState from '../state/UsbStickState.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { getOrElse, isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { UsbStickDetail } from 'dg-web-shared/model/UsbStick.ts';
import { languageTexts } from '../../common/i18n/GeneralTexts.ts';
import { UsbStickSyncStatus } from './UsbStickSyncStatus.tsx';
import { TextButton } from '../../ui/buttons/TextButton.tsx';
import { SlideInHeaderButtonsContainer } from '../../ui/slidein/SlideInHeader2.tsx';
import { openUsbAppOrInfo } from '../../sync-app/actions/SyncAppActions.ts';
import * as SyncAppHelpSlideInState from '../../sync-app/state/SyncAppHelpSlideInState.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { CustomPermissionCheckers } from '../../app/state/Permission.ts';
import { RestrictedComponent } from '../../app/components/RestrictedComponent.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { editBaseData, makeEditPayload } from '../actions/UsbStickActions.ts';
import { TomStatusIconPositioning } from '../../tom/components/TomFirmwareStatus.tsx';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { UsbAppAction } from 'taxomex-shared/usbApp/UsbAppAction.ts';

interface State {
    settings: SettingsState.State;
    layout: UsbStickState.Layout.State;
    usbSticks: UsbStickState.List.State;
    syncSlideIn: SyncAppHelpSlideInState.Layout.State;
    usbStickDetail?: UsbStickState.Detail.State | null;
    edit: UsbStickState.Edit.State;
    login: CurrentOperatorLoginState.State;
}

export class UsbStickDetailSlideIn extends Flux.Container<State> {
    stateSelector(): State {
        const layoutState = UsbStickState.Layout.get(this.props.allState);
        const usbStickId = layoutState.selectedUsbStickId;
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: layoutState,
            usbSticks: UsbStickState.List.get(this.props.allState),
            syncSlideIn: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
            usbStickDetail: usbStickId
                ? UsbStickState.Detail.get(this.props.allState, { usbStickId })
                : null,
            edit: UsbStickState.Edit.get(this.props.allState),
            login: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    updateStick(usbStick: UsbStickDetail): void {
        this.update(store =>
            openUsbAppOrInfo(store, {
                usbAppAction: UsbAppAction.UPDATE_STICK,
                usbAppArgs: {
                    operatorId: usbStick.operatorId as number,
                    stickId: usbStick.stickId as number,
                },
                stateResetActionId: 'refetch-after-stick-configuration-write',
                stateResetAction: (store: Flux.Store) => {
                    UsbStickState.List.reset(store);
                    UsbStickState.Detail.refetchSameContext(store, false);
                },
            }),
        );
    }

    closeSlideIn(): void {
        this.update(UsbStickState.Layout.reset);
        this.update(UsbStickState.Edit.reset);
    }

    saveBaseData(usbStick: UsbStickDetail): void {
        this.update(editBaseData, {
            stickId: usbStick.stickId || 0,
            payload: makeEditPayload(this.state.edit, usbStick),
        });
    }

    render() {
        const lng = this.state.settings.language;
        const txt = usbStickTexts[lng];
        const openSlideIn = isDefined(this.state.layout.selectedUsbStickId);
        if (openSlideIn) {
            const usbStick: Maybe<UsbStickDetail> =
                this.state.usbStickDetail && !this.state.usbStickDetail.pending
                    ? this.state.usbStickDetail.data
                    : null;
            const login = this.state.login.data;
            if (usbStick && login) {
                const currentConfiguration =
                    usbStick.configurations[usbStick.configurations.length - 1];
                const allowEdit = login.permissions.usbStickEdit;
                return (
                    <HalfSlideIn
                        open={true}
                        disabled={this.state.syncSlideIn.open}
                    >
                        <SlideInBody>
                            {allowEdit && (
                                <TextField
                                    inputType="text"
                                    value={getOrElse(
                                        this.state.edit.name,
                                        usbStick.name,
                                    )}
                                    label={txt.Title()}
                                    onChange={(v: string) =>
                                        this.update(store =>
                                            UsbStickState.Edit.stateWrite(
                                                store,
                                                { name: v },
                                            ),
                                        )
                                    }
                                />
                            )}
                            {!allowEdit && (
                                <LabeledText label={txt.Title()}>
                                    {usbStick.name}
                                </LabeledText>
                            )}
                            <LabeledText label={txt.Id()}>
                                {usbStick.stickId}
                            </LabeledText>
                            <LabeledText label={txt.StickTypeLabel()}>
                                {txt.StickType(usbStick.type)}
                            </LabeledText>
                            <LabeledText label={txt.PinCodeLabel()}>
                                {currentConfiguration.pinCode
                                    ? currentConfiguration.pinCode
                                    : txt.PinCodeNotSet()}
                            </LabeledText>
                            <LabeledText label={txt.LanguagePlaceholder()}>
                                {languageTexts[currentConfiguration.language]}
                            </LabeledText>
                            <LabeledText label={txt.ExpirationLabel()}>
                                {currentConfiguration.expiryDate
                                    ? txt.ExpirationValue(
                                          currentConfiguration.expiryDate,
                                      )
                                    : txt.ExpirationNotSet()}
                            </LabeledText>
                            <LabeledText label={txt.Permissions()}>
                                {currentConfiguration.tags
                                    .map(t => t.name)
                                    .join(', ')}
                            </LabeledText>
                            <LabeledText label={txt.LastWriteLabel()}>
                                {currentConfiguration.lastWrite
                                    ? txt.LastWriteValue(
                                          currentConfiguration.lastWrite,
                                      )
                                    : txt.LastWriteNever()}
                            </LabeledText>
                            <LabeledText label={txt.SyncStatus()}>
                                <TomStatusIconPositioning
                                    label={
                                        <UsbStickSyncStatus
                                            synced={isDefined(
                                                currentConfiguration.lastWrite,
                                            )}
                                        />
                                    }
                                    text={
                                        currentConfiguration.lastWrite
                                            ? txt.InSync()
                                            : txt.NeedsSynchronization()
                                    }
                                />
                            </LabeledText>
                        </SlideInBody>
                        <SlideInHeader
                            language={lng}
                            hasChanges={UsbStickState.Edit.hasChanges(
                                this.state.edit,
                                usbStick,
                            )}
                            stick={usbStick}
                            onUpdateStick={() => this.updateStick(usbStick)}
                            onClose={() => this.closeSlideIn()}
                            onSave={() => this.saveBaseData(usbStick)}
                        />
                    </HalfSlideIn>
                );
            } else {
                return (
                    <HalfSlideIn open={true}>
                        <LoaderHeader title={txt.SlideInHeaderCaption()} />
                    </HalfSlideIn>
                );
            }
        } else {
            return <HalfSlideIn open={false} />;
        }
    }
}

interface UsbSlideInHeaderProps {
    language: string;
    hasChanges: boolean;
    stick: UsbStickDetail;
    onUpdateStick: () => void;
    onClose: () => void;
    onSave: () => void;
}

function SlideInHeader(props: UsbSlideInHeaderProps): JSX.Element {
    const txt = usbStickTexts[props.language];

    if (props.hasChanges) {
        return (
            <ConfirmSaveHeader
                language={props.language}
                title={txt.SaveEdit()}
                onCancel={props.onClose}
                onSave={props.onSave}
            />
        );
    } else {
        return (
            <StandardFirstLevelHeader onClose={props.onClose}>
                <SlideInHeaderTexts
                    title={txt.SlideInHeaderCaption()}
                    hasLeftIcon={false}
                />
                <SlideInHeaderButtonsContainer>
                    <RestrictedComponent
                        route={OperatorAppRoutes.UsbSticks}
                        permissionChecker={
                            CustomPermissionCheckers.syncAppInteractionAllowed
                        }
                    >
                        <TextButton
                            label={txt.WriteToStick()}
                            onClick={props.onUpdateStick}
                        />
                    </RestrictedComponent>
                </SlideInHeaderButtonsContainer>
            </StandardFirstLevelHeader>
        );
    }
}
