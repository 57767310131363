import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import * as Text from '../common/i18n/Text.ts';
import { Translations } from '../common/i18n/Text.ts';
import { LabeledText } from '../ui/labeled-elements/LabeledText.tsx';
import { TextField } from '../ui/labeled-elements/TextField.tsx';
import { css } from '@emotion/css';
import { OnstreetTransaction } from './OnstreetTransactionModel.ts';
import { transactionsListTexts } from './OnstreetTransactionList.tsx';
import { IdentificationLicensePlate } from '../common/components/IdentificationItems.tsx';
import {
    getChannelTitle,
    getDuration,
    getFromDate,
    getPrice,
    getRefundAmount,
    getToDate,
    getTransactionDateTime,
    getZoneName,
} from './OnstreetTransactionUtils.ts';
import { Dispatch, SetStateAction } from 'react';
import { RefundInputValues } from './OnstreetTransactionDetailSlideIn.tsx';

export function OnstreetTransactionDetailSlideInBody({
    transaction,
    language,
    refundInputState,
    setRefundInputState,
}: {
    transaction: OnstreetTransaction;
    language: string;
    refundInputState: RefundInputValues | null;
    setRefundInputState: Dispatch<SetStateAction<RefundInputValues | null>>;
}) {
    const listTexts = transactionsListTexts[language];
    const detailTexts = transactionDetailTexts[language];

    return (
        <div>
            <LabeledText label={detailTexts.headerId()}>
                {transaction.id}
            </LabeledText>

            <LabeledText label={listTexts.headerIdentifier()}>
                <IdentificationLicensePlate
                    licensePlateNr={transaction.licensePlateNr}
                    type={transaction.licensePlateType}
                    country={transaction.licensePlateCountry}
                    inlineMode={false}
                />
            </LabeledText>

            <LabeledText label={listTexts.headerDateFrom()}>
                {getFromDate(transaction)}
            </LabeledText>

            <LabeledText label={listTexts.headerDateTo()}>
                {getToDate(transaction) || EmDash}
            </LabeledText>

            <LabeledText label={listTexts.headerDuration()}>
                {getDuration(transaction, language) || EmDash}
            </LabeledText>
            <LabeledText label={listTexts.headerZoneName()}>
                {getZoneName(transaction)}
            </LabeledText>
            <LabeledText label={listTexts.headerChannel()}>
                {getChannelTitle(transaction)}
            </LabeledText>
            <LabeledText label={listTexts.headerPrice()}>
                {getPrice(transaction, true) || EmDash}
            </LabeledText>
            {transaction.refund && (
                <LabeledText label={listTexts.headerRefundAmount()}>
                    {`- ${getRefundAmount(transaction, true)}`}
                    <br />
                    <span className={css({ fontSize: '14px' })}>
                        {`${transaction.refund.user}, ${getTransactionDateTime(
                            transaction.refund.refundedAt,
                        )}`}
                        {transaction.refund.remark && (
                            <>
                                <br />
                                {transaction.refund.remark}
                            </>
                        )}
                    </span>
                </LabeledText>
            )}
            {refundInputState != null && (
                <RefundInputFields
                    language={language}
                    amount={refundInputState.amount}
                    maxAmount={
                        transaction.maxRefundableAmount ?? transaction.price
                    }
                    onChangeAmount={(amount: string) =>
                        setRefundInputState(state =>
                            state != null
                                ? {
                                      ...state,
                                      amount,
                                  }
                                : null,
                        )
                    }
                    remark={refundInputState.remark}
                    onChangeRemark={(remark: string) =>
                        setRefundInputState(state =>
                            state != null
                                ? {
                                      ...state,
                                      remark,
                                  }
                                : null,
                        )
                    }
                />
            )}
        </div>
    );
}

function RefundInputFields(props: {
    language: string;
    amount: string;
    maxAmount: number;
    onChangeAmount: (v: string) => void;
    onChangeRemark: (v: string) => void;
    remark: string;
}) {
    const detailTexts = transactionDetailTexts[props.language];
    const amountAsNumber = parseFloat(props.amount);
    const amountTooHigh = amountAsNumber
        ? amountAsNumber > props.maxAmount
        : false;
    const maxAmountCHF = 'CHF ' + props.maxAmount.toFixed(2);

    return (
        <>
            <TextField
                value={props.amount}
                label={detailTexts.refundFieldLabel(maxAmountCHF)}
                onChange={props.onChangeAmount}
                inputType="number"
                autofocus={true}
                errorText={amountTooHigh ? `Max. ${maxAmountCHF}` : null}
            />
            <TextField
                value={props.remark}
                label={detailTexts.refundRemarkLabel()}
                onChange={props.onChangeRemark}
                inputType="text"
            />
        </>
    );
}

interface TransactionDetailTexts {
    refundFieldLabel: Text.TranslationWithArgs1<string>;
    refundRemarkLabel: Text.Translation;
    headerId: Text.Translation;
}

const transactionDetailTexts: Translations<TransactionDetailTexts> = {
    de: {
        refundFieldLabel: maxAmount => `Rückzahlungsbetrag (max. ${maxAmount})`,
        refundRemarkLabel: () => 'Interne Bemerkungen',
        headerId: () => 'Transaktions-ID',
    },
    fr: {
        refundFieldLabel: maxAmount =>
            `Montant remboursement (max. ${maxAmount})`,
        refundRemarkLabel: () => 'Remarques internes',
        headerId: () => 'ID transaction',
    },
    it: {
        refundFieldLabel: maxAmount => `Importo rimborso (max. ${maxAmount})`,
        refundRemarkLabel: () => 'Osservazioni interne',
        headerId: () => 'ID transazione',
    },
};
