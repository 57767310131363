import { Translations } from 'dg-web-shared/lib/Text.ts';
import { DateFilterTexts } from './DateFilter.tsx';
import { FilterSlideInTexts } from './FilterSlideIn.tsx';
import { ResultsTableTexts } from './ResultsTable.tsx';
import { GuideTexts } from './Guide.tsx';
import { PermitTypeFilterTexts } from './PermitTypeFilter.tsx';
import { StatusFilterTexts } from './StatusFilter.tsx';
import { DetailTexts } from './Detail.tsx';

/* tslint:disable */
export const dateFilterTexts: Translations<DateFilterTexts> = {
    de: {
        filterTitle: () => 'Datum',
        from: () => 'Von',
        to: () => 'Bis',
    },
    fr: {
        filterTitle: () => 'Date',
        from: () => 'Du',
        to: () => 'Au',
    },
    it: {
        filterTitle: () => 'Data',
        from: () => 'Dal',
        to: () => 'Al',
    },
};

export const permitTypeFilterTexts: Translations<PermitTypeFilterTexts> = {
    de: {
        filterTitle: () => 'Bewilligungsart',
    },
    fr: {
        filterTitle: () => "Type d'autorisation",
    },
    it: {
        filterTitle: () => "Tipo d'autorizzazione",
    },
};

export const statusFilterTexts: Translations<StatusFilterTexts> = {
    de: {
        filterTitle: () => 'Status',
        valuePending: () => 'Nur Pendente',
        valueDeclined: () => 'Nur Abgelehnte',
        valueAccepted: () => 'Nur Angenommene',
    },
    fr: {
        filterTitle: () => 'État',
        valuePending: () => 'Seulement en suspens',
        valueDeclined: () => 'Seulement rejetés',
        valueAccepted: () => 'Seulement acceptés',
    },
    it: {
        filterTitle: () => 'Stato',
        valuePending: () => 'Solo in sospeso',
        valueDeclined: () => 'Solo rifiutate',
        valueAccepted: () => 'Solo accettate',
    },
};

export const filterSlideInTexts: Translations<FilterSlideInTexts> = {
    de: {
        title: () => 'Filter',
    },
    fr: {
        title: () => 'Filtre',
    },
    it: {
        title: () => 'Filtro',
    },
};

export const resultsTableTexts: Translations<ResultsTableTexts> = {
    de: {
        headerPermiTypeDescription: () => 'Bewilligungsart',
        headerDate: () => 'Datum',
        headerCustomer: () => 'Antragsteller',
    },
    fr: {
        headerPermiTypeDescription: () => "Type d'autorisation",
        headerDate: () => 'Date',
        headerCustomer: () => 'Demandeur',
    },
    it: {
        headerPermiTypeDescription: () => "Tipo d'autorizzazione",
        headerDate: () => 'Data',
        headerCustomer: () => 'Richiedente',
    },
};

export const guideTexts: Translations<GuideTexts> = {
    de: {
        header: () => 'Suchen und Bearbeiten',
        intro: () =>
            'Mittels Suchfeld und/oder Filter können Sie die Anträge suchen und ggf. bearbeiten.',
        listDescription: () =>
            'Folgende Suchkriterien sind im Suchfeld verfügbar:',
        licenseplate: () => 'Kennzeichen',
        customerName: () => 'Kontoinhaber',
        customerNumber: () => 'Kontonummer',
    },
    fr: {
        header: () => 'Rechercher et modifier',
        intro: () =>
            'Avec le champ de recherche et/ou le filtre vous pouvez rechercher et evt. modifier les demandes.',
        listDescription: () =>
            'Les critères suivants sont disponibles dans le champ de recherche:',
        licenseplate: () => 'Immatriculation',
        customerName: () => 'Titulaire du compte',
        customerNumber: () => 'N° du compte',
    },
    it: {
        header: () => 'Cercare e modificare',
        intro: () =>
            'Con il campo di ricerca e/o il filtro potete cercare ed ev. modificare le richieste.',
        listDescription: () =>
            'I seguenti criteri sono disponibili nel campo di ricerca:',
        licenseplate: () => 'Numero di targa',
        customerName: () => 'Titolare del conto',
        customerNumber: () => 'Nr. di conto',
    },
};

export const detailTexts: Translations<DetailTexts> = {
    de: {
        DenyRequest: () => 'Antrag ablehnen',
        AcceptRequest: () => 'Antrag annehmen',
        RequestCaption: () => 'Antrag',
        Requester: () => 'Antragsteller',
        LicensePlates: () => 'Kennzeichen',
        Remark: () => 'Bemerkung',
        DropdownLabel: () => 'Bearbeiten',
        PermitType: () => 'Bewilligungsart',
        Modifiers: () => 'Preiskategorie',
    },
    fr: {
        DenyRequest: () => 'Rejeter la demande',
        AcceptRequest: () => 'Accepter la demande',
        RequestCaption: () => 'Demande',
        Requester: () => 'Demandeur',
        LicensePlates: () => 'Immatriculations',
        Remark: () => 'Remarques',
        DropdownLabel: () => 'Modifier',
        PermitType: () => "Type d'autorisation",
        Modifiers: () => 'Catégorie de prix',
    },
    it: {
        DenyRequest: () => 'Rifiuta richiesta',
        AcceptRequest: () => 'Accetta richiesta',
        RequestCaption: () => 'Richiesta',
        Requester: () => 'Richiedente',
        LicensePlates: () => 'Targhe',
        Remark: () => 'Osservazioni',
        DropdownLabel: () => 'Modifica',
        PermitType: () => "Tipo d'autorizzazione",
        Modifiers: () => 'Categoria di prezzo',
    },
};

/* tslint:enable */
