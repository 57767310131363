import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Http from '../api/Http.ts';
import * as AsyncRequest from '../AsyncRequest.ts';
import moment from 'moment';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import * as WriteStateSlice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import { ClearanceRequest } from '../common/models/ClearanceRequest.ts';

const sn = (s: string) => `clearance-requests/ClearanceRequestsState.${s}`;

export namespace UI {
    export interface State {
        filterSlideInVisible: boolean;
        validDateSlideInVisible: boolean;
        statusSlideInVisible: boolean;
        permitTypesSlideInVisible: boolean;

        filterSummaryHover: boolean;
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        sn('UI'),
        {
            filterSlideInVisible: false,
            validDateSlideInVisible: false,
            statusSlideInVisible: false,
            permitTypesSlideInVisible: false,
            filterSummaryHover: false,
        },
    );
}

export namespace Filter {
    export type ClearanceRequestStatus = 'pending' | 'declined' | 'accepted';

    export interface State {
        search: string | null;
        permitTypeIds: number[];
        status: ClearanceRequestStatus | null;
        dateFrom: moment.Moment | null;
        dateTo: moment.Moment | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('Filter'),
        {
            search: null,
            dateFrom: null,
            dateTo: null,
            status: null,
            permitTypeIds: [],
        },
    );

    export const searchActive = (s: State) =>
        !!s.search && s.search.length >= 3;

    export const dateFilterActive = (s: State) => !!s.dateFrom || !!s.dateTo;

    export const statusFilterActive = (s: State) => !!s.status;

    export const permitTypeFilterActive = (s: State) =>
        s.permitTypeIds.length > 0;

    export const filterActive = (s: State) =>
        dateFilterActive(s) ||
        statusFilterActive(s) ||
        permitTypeFilterActive(s);

    export const pendingOnly: State = {
        search: null,
        permitTypeIds: [],
        status: 'pending',
        dateFrom: null,
        dateTo: null,
    };
}

export namespace List {
    interface Context {
        search: string | null;
        permitTypeIds: number[] | null;
        status: Filter.ClearanceRequestStatus | null;
        dateFrom: moment.Moment | null;
        dateTo: moment.Moment | null;
    }

    export const { get, getState, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<
            Context,
            ClearanceRequest[]
        >({
            key: sn('List'),
            requestGenerator: (context: Context) =>
                Http.OperatorAccount.ClearanceRequests.list(context),
            request: AsyncRequest.request,
            parseBody: b => b,
            debounceTime: 300,
        });
}

export namespace Detail {
    export interface State {
        selectedClearanceRequest: ClearanceRequest | null;
        remark: string | null;
        lpIds: number[];
        priceModifierId: number | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        sn('Detail'),
        {
            selectedClearanceRequest: null,
            remark: null,
            lpIds: [],
            priceModifierId: null,
        },
    );
    export const toggleLpId = (store: Flux.Store, lp: number) => {
        const lps = get(store).lpIds.slice();
        const i = lps.indexOf(lp);
        if (i === -1) {
            lps.push(lp);
        } else {
            lps.splice(i, 1);
        }
        stateWrite(store, { lpIds: lps });
        return sn('Detail.toggleLpId');
    };
}

export namespace ProcessResponse {
    export type State = WriteStateSlice.State<void>;
    export const { get, setResponse } = WriteStateSlice.generate<void>(
        sn('ProcessResponse'),
        () => null,
    );
}
