import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { selectState, Store, UpdateProp } from 'dg-web-shared/lib/Flux.tsx';
import { licensePlateErrorText } from 'dg-web-shared/lib/LicensePlateValidation.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { stripNonNumericAndNoLetterCharactersAllowUmlauts } from 'dg-web-shared/lib/StringConversions.ts';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import { Spinner } from '../../ui/loaders/Spinner.tsx';
import * as GeneralTexts from '../i18n/GeneralTexts.ts';
import { texts } from 'dg-web-shared/common/components/translations/LicensePlateTexts.ts';
import { LicensePlateCountries } from '../state/MetaState.ts';
import { LicensePlateTypeSelection } from './LicensePlateTypeSelection.tsx';
import React from 'react';
import { errors } from 'dg-web-shared/common/components/translations/LicensePlateErrorTexts.ts';

type OusideErrorGen = ((args: Tripple) => string | null) | null;

interface Props {
    language: string;
    allowedLicensePlateTypes?: LicensePlateType[];
    onLicensePlateNumberChange: (v: string, formValid: boolean) => void;
    onCountrySlideInToggle: () => void;
    onTypeSelect: (type: LicensePlateType) => void;
    licensePlateNr: Maybe<string>;
    country: Maybe<string>;
    licensePlateType: LicensePlateType | null;
    showErrors: boolean;
    outsideLicensePlateNrErrorGen: OusideErrorGen;
    hideTypeSelectionIfPossible?: boolean;
}

export interface Tripple {
    licensePlateType: Maybe<LicensePlateType>;
    licensePlateNr: Maybe<string>;
    country: Maybe<string>;
}

interface ValidatorInput extends Tripple {
    language: string;
    outsideLicensePlateNrErrorGen: OusideErrorGen;
}

interface ValidatorOutput {
    licensePlateNrError: string | null;
    countryError: string | null;
}

const validateLp = (p: ValidatorInput): string | null => {
    const outsideLicensePlateNrError = p.outsideLicensePlateNrErrorGen
        ? p.outsideLicensePlateNrErrorGen(p)
        : null;
    if (!p.licensePlateNr || p.licensePlateNr.length === 0) {
        return GeneralTexts.general[p.language].MandatoryField();
    } else if (!p.country) {
        return null;
    } else if (outsideLicensePlateNrError) {
        return outsideLicensePlateNrError;
    } else {
        return (
            licensePlateErrorText(
                {
                    licensePlateNr: p.licensePlateNr,
                    licensePlateCountryId: p.country,
                },
                errors[p.language],
            ) || null
        );
    }
};

const validateCountry = (p: ValidatorInput): string | null => {
    if (!p.country) {
        return GeneralTexts.general[p.language].MandatoryField();
    } else {
        return null;
    }
};

const validate = (p: ValidatorInput): ValidatorOutput => {
    return {
        licensePlateNrError: validateLp(p),
        countryError: validateCountry(p),
    };
};

export const valuesValid = (p: ValidatorInput): boolean => {
    const res = validate(p);
    return !res.licensePlateNrError && !res.countryError;
};

interface State {
    countries: LicensePlateCountries.State;
}

class TrippleEntry extends React.Component<State & Props & UpdateProp> {
    componentDidMount() {
        if (this.props.licensePlateNr && valuesValid(this.props)) {
            this.props.onLicensePlateNumberChange(
                this.props.licensePlateNr,
                true,
            );
        }
        if (
            this.props.allowedLicensePlateTypes &&
            this.props.licensePlateType &&
            this.props.allowedLicensePlateTypes.indexOf(
                this.props.licensePlateType,
            ) === -1
        ) {
            this.props.onTypeSelect(this.props.allowedLicensePlateTypes[0]);
        }
    }

    render() {
        const p = this.props;
        const t = texts[p.language];
        const errors = validate(p);
        if (p.countries.pending) {
            return <Spinner />;
        } else if (!p.countries.data) {
            return <div>Error</div>;
        }
        return (
            <div>
                {p.hideTypeSelectionIfPossible &&
                p.allowedLicensePlateTypes &&
                p.allowedLicensePlateTypes.length === 1 ? null : (
                    <LicensePlateTypeSelection
                        allowedTypes={p.allowedLicensePlateTypes}
                        selectedType={p.licensePlateType}
                        language={p.language}
                        onSelect={p.onTypeSelect}
                    />
                )}
                <SingleSelection
                    focused={false}
                    label={t.Country()}
                    selection={
                        p.countries.data.filter(c => c.id === p.country)[0]
                            .name[p.language]
                    }
                    onClick={p.onCountrySlideInToggle}
                    errorText={p.showErrors ? errors.countryError : null}
                />
                <TextField
                    inputType="text"
                    label={t.LicensePlate()}
                    value={p.licensePlateNr || ''}
                    saveable={false}
                    onChange={(v: string) => {
                        const normalizedLicensePlate =
                            stripNonNumericAndNoLetterCharactersAllowUmlauts(
                                v.toUpperCase(),
                            );
                        p.onLicensePlateNumberChange(
                            normalizedLicensePlate,
                            valuesValid({
                                licensePlateNr: normalizedLicensePlate,
                                language: p.language,
                                country: p.country,
                                licensePlateType: p.licensePlateType,
                                outsideLicensePlateNrErrorGen:
                                    p.outsideLicensePlateNrErrorGen,
                            }),
                        );
                    }}
                    errorText={p.showErrors ? errors.licensePlateNrError : null}
                />
            </div>
        );
    }
}

export const LicensePlateTrippleEntry = selectState<Props, State>(
    (store: Store): State => {
        return {
            countries: LicensePlateCountries.get(store),
        };
    },
    TrippleEntry,
);
