import { css } from '@emotion/css';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { selectState } from 'dg-web-shared/lib/Flux.tsx';
import { Language, languageFromString } from 'dg-web-shared/lib/Text.ts';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import * as AuthenticationActions from '../app/actions/AuthenticationActions.ts';
import * as AuthenticationState from '../app/state/AuthenticationState.ts';
import * as SettingsState from '../common/state/SettingsState.ts';
import { BasicButton } from '../ui/buttons/BasicButton.tsx';
import { ColorHex } from '../ui/Colors.ts';
import { TextField } from '../ui/labeled-elements/TextField.tsx';
import { ContainerLoaderBar } from '../ui/loaders/ContainerLoaderBar.tsx';
import { LoginState } from './LandingPageState.ts';
import { loginBlockTexts } from './LandingPageTexts.tsx';
import { Icon24 } from '../ui/icons/Icon.tsx';
import { apply_m } from 'dg-web-shared/ui/icons/Icons24.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

const texts = (p: { settings: SettingsState.State }) =>
    loginBlockTexts[p.settings.language];

const loginEnabled = (p: { login: LoginState.State }) =>
    p.login.username && p.login.password;

const passwordErrorText = (p: {
    authentication: AuthenticationState.State;
    settings: SettingsState.State;
    login: LoginState.State;
}) =>
    p.authentication.status.unauthorized &&
    !p.authentication.statusPending &&
    p.login.showCredentialsError
        ? texts(p).invalidCredentials()
        : null;

const setUsername = (store: Flux.Store, username: string) => {
    LoginState.stateWrite(store, {
        username: username,
        showCredentialsError: false,
    });
    return 'setUsername';
};
const setPassword = (store: Flux.Store, password: string) => {
    LoginState.stateWrite(store, {
        password: password,
        showCredentialsError: false,
    });
    return 'setPassword';
};

const submit = (p: { login: LoginState.State; update: Flux.Updater }) =>
    p.update(AuthenticationActions.login, {
        username: p.login.username || '',
        password: p.login.password || '',
        longSession: p.login.longSession,
    });

export const LoginBlock = selectState(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        authentication: new AuthenticationState.StateSlice(store).state,
        login: LoginState.get(store),
    }),
    p => (
        <div className={loginBlockStyle}>
            <div className={headerStyle}>{texts(p).header()}</div>
            <ContainerLoaderBar loading={p.authentication.statusPending} />
            <form
                action="javascript:void(0)"
                noValidate
                onKeyDown={e => {
                    if (e && e.keyCode === 13) {
                        submit(p);
                    }
                }}
            >
                <TextField
                    inputType="text"
                    label={texts(p).username()}
                    value={p.login.username || ''}
                    onChange={(v: string) =>
                        p.update(store => setUsername(store, v))
                    }
                    tabIndex={0}
                    context="darkblue"
                />
                <TextField
                    label={texts(p).password()}
                    inputType="password"
                    value={p.login.password || ''}
                    onChange={(v: string) =>
                        p.update(store => setPassword(store, v))
                    }
                    errorText={passwordErrorText(p)}
                    tabIndex={0}
                    context="darkblue"
                />
                <div className={loginRowStyle}>
                    <Clickable
                        element="div"
                        className={rememberPwStyle}
                        onClick={() =>
                            p.update(store =>
                                LoginState.stateWrite(store, {
                                    longSession: !p.login.longSession,
                                }),
                            )
                        }
                        data-not-selected={!p.login.longSession}
                    >
                        {texts(p).saveLogin()}
                        <div
                            className={rememberPwIconStyle}
                            data-hidden={!p.login.longSession}
                        >
                            <Icon24 icon={apply_m} />
                        </div>
                    </Clickable>
                    <BasicButton
                        label={texts(p).submitLabel()}
                        onClick={() => submit(p)}
                        disabled={!loginEnabled(p)}
                        context="darkblue"
                    />
                </div>
            </form>

            <div className={css({ marginTop: '85px' })}>
                <a
                    className={forgotPasswordLinkStyle}
                    href={resetPasswordLink(
                        languageFromString(p.settings.language),
                        p.login.username || '',
                    )}
                >
                    {texts(p).forgotPassword()}
                </a>
            </div>
        </div>
    ),
);

function resetPasswordLink(language: Language, username: string): string {
    return (
        '/reset-password?username=' +
        encodeURIComponent(username) +
        '&language=' +
        encodeURIComponent(language)
    );
}

const loginBlockStyle = css`
    max-width: 224px;
    margin: 0 auto;
    transform: translateX(0);
`;

const headerStyle = css`
    ${Typo.robotoThin};
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: ${ColorHex.white};
    margin-top: 25px;
`;

const loginRowStyle = css`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`;

const rememberPwStyle = css`
    ${Typo.robotoRegular};
    color: ${ColorHex.white};
    font-size: 10.5px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 40px;

    &[data-not-selected='true'] {
        color: ${ColorHex.rgba(ColorHex.white, 0.6)};
    }
`;

const rememberPwIconStyle = css`
    &[data-hidden='true'] {
        visibility: hidden;
    }
`;

const forgotPasswordLinkStyle = css`
    ${Typo.robotoThin};
    color: ${ColorHex.white};
    font-size: 16px;
    text-decoration: none;
`;
