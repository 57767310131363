import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as ContextualServerStateSlice from 'dg-web-shared/lib/ContextualServerStateSlice.ts';
import { Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as WriteStateSLice from 'dg-web-shared/common/state/WriteStateSlice.ts';
import * as Shared from './CompanyLike.ts';

export interface LicensePlate {
    licenseNr: string;
}

export interface Badge {
    label: string;
}

export interface CustomerAccount extends Shared.CompanyLike {
    country: string;
    customerNr: number;
    firstName: string;
    lastName: string;
    licensePlates: LicensePlate[];
    language: string;
    type: string;
    paymentType: string;
    badges: Badge[];
    balance?: Maybe<number>;
    customerId: number;
    registrationDate: string;
    activationDate: Maybe<string>;
    locked: boolean;
}

export enum SelectedTab {
    CUSTOMER_CLEARANCE,
}

export namespace Layout {
    export interface State {
        selectedAccount?: CustomerAccount | null;
        creditExpanded?: boolean;
        creditAmountExpanded?: boolean;
    }

    export const { get, stateWrite } = Flux.generateState<State>(
        'customer-account-CustomerAccountState-Layout',
        {
            selectedAccount: null,
            creditExpanded: false,
        },
    );
}

export namespace List {
    interface Context {
        search: string;
    }

    export type State = ContextualServerStateSlice.ContextualState<
        CustomerAccount[]
    >;
    export const { get, refetchSameContext } =
        ContextualServerStateSlice.generateContextualState<
            Context,
            CustomerAccount[]
        >({
            key: 'CustomerAccountState-List',
            requestGenerator: (context: Context) =>
                Http.OperatorAccount.CustomerAccounts.list(context.search),
            request: AsyncRequest.request,
            parseBody: b => b,
            debounceTime: 300,
        });
}

export namespace Filter {
    export interface State {
        search: string | null;
    }

    export const { get, set, stateWrite } = Flux.generateState<State>(
        'customer-account-CustomerAccountState-Filter',
        {
            search: null,
        },
    );

    export const searchActive = (s: State) => s.search && s.search.length >= 3;
}

export namespace Credit {
    export interface State {
        selectedAmount: number | null;
        customAmount?: Maybe<string>;
        confirmPressedWhileError?: boolean;
    }

    export const { get, stateWrite, reset } = Flux.generateState<State>(
        'customer-account-CustomerAccountState-Credit',
        {
            selectedAmount: null,
            customAmount: null,
            confirmPressedWhileError: false,
        },
    );
}

export namespace CreditResponse {
    export type State = WriteStateSLice.State<CustomerAccount>;
    export const { get, setResponse, reset } = WriteStateSLice.generate(
        'customer-account-CustomerAccountState-CreditResponse',
        b => b,
    );
}
