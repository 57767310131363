import { Texts } from '../components/SharedNotifications.tsx';
import { Translations } from '../../common/i18n/Text.ts';
/* tslint:disable:typedef max-line-length */

export const texts: Translations<Texts> = {
    de: {
        OverlapNotificationHeading: () => 'Bereits vorhandene Bewilligungen',
        LpOverlapNotificationPrefix: () =>
            'Folgende Kennzeichen sind in diesem Gültigkeitszeitraum bereits erfasst:',
        BadgeOverlapNotificationPrefix: () =>
            'Folgende Badges sind in diesem Gültigkeitszeitraum bereits erfasst:',
        OverlapNotificationPostfix: () =>
            'Bitte passen Sie den Gültigkeitszeitraum an oder editieren sie die bereits erfassten Bewilligungen.',
        OverlapEntityValidTo: () => 'gültig bis',

        MissingWhitelistCreateWhitelist: () => 'Freigabe erfassen...',

        MissingLpWhitelistHeading: () => 'Kennzeichen ohne Freigabe',
        MissingLpWhitelistPrefix: () =>
            'Folgende Kennzeichen sind für diesen Gültigkeitszeitraum nicht freigegeben:',
        MissingLpWhitelistPostfix: () =>
            'Bitte erfassen Sie Freigaben für diese Kennzeichen',

        MissingBadgeWhitelistHeading: () => 'Badge ohne Freigabe',
        MissingBadgeWhitelistPrefix: () =>
            'Folgende Badges sind für diesen Gültigkeitszeitraum nicht freigegeben:',
        MissingBadgeWhitelistPostfix: () =>
            'Bitte erfassen Sie Freigaben für diese Badges',
        BadgesThatNeedAccountHeading: () => 'Badges ohne Konto',
        BadgesThatNeedAccountPrefix: () =>
            'Folgende Badges müssen einem Benutzer-Konto zugeordnet sein:',
    },
    fr: {
        OverlapEntityValidTo: () => "valable jusqu'au",
        OverlapNotificationHeading: () => 'Autorisations existantes',
        OverlapNotificationPostfix: () =>
            'Adapter le période de validité saisie ou modifier les autorisations existantes.',
        LpOverlapNotificationPrefix: () =>
            'Dans cette période de validité les immatriculasions suivantes ont déjà une autorisation:',
        BadgeOverlapNotificationPrefix: () =>
            'Dans cette période de validité les badges suivantes ont déjà une autorisation:',

        MissingWhitelistCreateWhitelist: () => 'Saisir approbation',

        MissingLpWhitelistHeading: () => 'Immatriculation sans approbation',
        MissingLpWhitelistPostfix: () =>
            "Veuillez saisir l'approbation pour cette immatriculation",
        MissingLpWhitelistPrefix: () =>
            "Les immatriculations suivantes n'ont aucune approbation pour cette periode de validité:",

        MissingBadgeWhitelistHeading: () => 'Badge sans approbation',
        MissingBadgeWhitelistPrefix: () =>
            "Les badges suivantes n'ont aucune approbation pour cette periode de validité:",
        MissingBadgeWhitelistPostfix: () =>
            "Veuillez saisir un'approbation pour ces badges",
        BadgesThatNeedAccountHeading: () => 'Badges sans compte',
        BadgesThatNeedAccountPrefix: () =>
            'Les badges suivantes doivent êtres saisies dans un compte:',
    },
    it: {
        OverlapEntityValidTo: () => 'valida fino al',
        OverlapNotificationHeading: () => 'Autorizzazioni esistenti',
        OverlapNotificationPostfix: () =>
            'Adattare il periodo di validità inserito oppure modificare le autorizzazioni esistenti.',
        LpOverlapNotificationPrefix: () =>
            "In questo periodo di validità le seguenti targhe hanno già un'autorizzazione:",
        BadgeOverlapNotificationPrefix: () =>
            "In questo periodo di validità i seguenti bagdes hanno già un'autorizzazione:",

        MissingWhitelistCreateWhitelist: () => 'Registra approvazione',

        MissingLpWhitelistHeading: () => 'Targa senza approvazione',
        MissingLpWhitelistPostfix: () =>
            "Vogliate registrare l'approvazione per questo numero di targa",
        MissingLpWhitelistPrefix: () =>
            'I seguenti numeri di targa non hanno alcuna approvazione per questo periodo di validità:',

        MissingBadgeWhitelistHeading: () => 'Badge senza approvazione',
        MissingBadgeWhitelistPrefix: () =>
            'I seguenti badges non hanno alcuna approvazione per questo periodo di validità:',
        MissingBadgeWhitelistPostfix: () =>
            "Vogliate registrare un'approvazione per questi badges",
        BadgesThatNeedAccountHeading: () => 'Badges senza conto',
        BadgesThatNeedAccountPrefix: () =>
            'I seguenti badges devono essere registrati in un conto:',
    },
};

/* tslint:enable:typedef max-line-length */
