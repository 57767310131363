import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Body } from '../../../ui/layout/TabContent.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import { AbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import * as TomState from '../../state/TomState.ts';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import { collectionTexts } from '../../i18n/TomTexts.ts';
import { CollectionsFilterSummary } from './CollectionsFilterSummary.tsx';
import { CollectionsList } from './CollectionsList.tsx';
import { DateFilterHalfSlideIn } from './DateFilter.tsx';
import { FilterHalfSlideIn } from './FilterHalfSlideIn.tsx';
import { TomFilterCollectionsListSlideIn } from './TomFilterCollectionsListSlideIn.tsx';
import { CollectionDetailSlideIn } from './CollectionDetailSlideIn.tsx';
import { isDefined, isUndefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as ParkTransactionsState from '../../../park-transactions-tom/state/ParkTransactionsState.ts';
import { SyncAppSlideIn } from '../../../sync-app/components/SyncAppSlideIn.tsx';
import * as SyncAppHelpSlideInState from '../../../sync-app/state/SyncAppHelpSlideInState.ts';
import { openUsbAppOrInfo } from '../../../sync-app/actions/SyncAppActions.ts';
import {
    FilterLayout,
    FilterLayoutBody,
    FilterLayoutHeader,
} from '../../../ui/filter/FilterLayout.tsx';
import { RestrictedComponent } from '../../../app/components/RestrictedComponent.tsx';
import { CustomPermissionCheckers } from '../../../app/state/Permission.ts';
import {
    ButtonDropdown,
    ButtonDropdownItem,
    ButtonDropdownLinkItem,
    ButtonDropdownSeparator,
} from '../../../ui/slidein/ButtonDropdown.tsx';
import { OperatorAccount } from '../../../api/Http.ts';
import { stringifyParams } from 'dg-web-shared/lib/QueryParamsUtil.ts';
import { OperatorAppRoutes } from '../../../app/config/OperatorRoutingConfig.tsx';
import { UsbAppAction } from 'taxomex-shared/usbApp/UsbAppAction.ts';

export interface CollectionTexts {
    TabTitle: Text.Translation;
    StartCollection: Text.Translation;
    MenuTitle: Text.Translation;
    Export: Text.Translation;
    Pdf: Text.Translation;
}

interface CollectionsBodyState {
    settings: SettingsState.State;
    collections: TomCollectionsState.List.State;
    filter: TomCollectionsState.Filter.State;
    toms: TomState.List.State;
    detail: TomCollectionsState.Detail.State;
    currentLogin: CurrentOperatorLoginState.State;
    syncSlideIn: SyncAppHelpSlideInState.Layout.State;
}

export class CollectionsBody extends Flux.Container<CollectionsBodyState> {
    stateSelector(): CollectionsBodyState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            collections: TomCollectionsState.List.get(this.props.allState),
            filter: TomCollectionsState.Filter.get(this.props.allState),
            toms: TomState.List.get(this.props.allState),
            detail: TomCollectionsState.Detail.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            syncSlideIn: SyncAppHelpSlideInState.Layout.get(
                this.props.allState,
            ),
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDependingStateSlices(): AbstractLegacyServerState<any>[] {
        return [this.state.toms, this.state.collections];
    }

    renderSlideIns(): JSX.Element[] {
        return [
            <FilterHalfSlideIn allState={this.props.allState} key="filter" />,
            <DateFilterHalfSlideIn
                allState={this.props.allState}
                key="date-filter"
            />,
            <TomFilterCollectionsListSlideIn
                allState={this.props.allState}
                key="tom-filter"
            />,
            <CollectionDetailSlideIn
                allState={this.props.allState}
                key="collection-detail"
            />,
            <SyncAppSlideIn allState={this.props.allState} key="sync-app" />,
        ];
    }

    isBodyDisabled(): boolean {
        const filterState = this.state.filter;
        const filterOpen =
            filterState.filterSlideInVisible ||
            filterState.tomSelectionVisible ||
            filterState.validDateSelectionVisible;
        return (
            isDefined(this.state.detail.selectedCollection) ||
            filterOpen ||
            this.state.syncSlideIn.open
        );
    }

    readCollectionData(): void {
        const operatorId =
            this.state.currentLogin.data &&
            this.state.currentLogin.data.mandantId;
        this.update(store =>
            openUsbAppOrInfo(store, {
                usbAppAction: UsbAppAction.COLLECTION,
                usbAppArgs: { operatorId: operatorId as number },
                stateResetActionId: 'refetch-after-usb-collection',
                stateResetAction: (store: Flux.Store) => {
                    TomCollectionsState.List.reset(store);
                    ParkTransactionsState.List.Completed.reset(store);
                    TomState.List.reset(store);
                },
            }),
        );
    }

    isLoading(): boolean {
        return this.state.syncSlideIn.loading;
    }

    render() {
        if (isUndefined(this.state.currentLogin.data)) {
            return null;
        }

        const txt = collectionTexts[this.state.settings.language];
        const queryParams = stringifyParams(
            OperatorAccount.TomCollections.paramsToQueryParams({
                validFrom: this.state.filter.validFrom,
                validTo: this.state.filter.validTo,
                toms: this.state.filter.toms,
                sticks: this.state.filter.sticks,
                hideEmptyCollections: this.state.filter.hideEmptyCollections,
            }),
        );
        return (
            <Body
                disabled={this.isBodyDisabled()}
                dependingStateSlices={this.getDependingStateSlices()}
                slideIns={this.renderSlideIns()}
                loading={this.isLoading()}
            >
                <FilterLayout>
                    <FilterLayoutHeader
                        filterSummary={
                            <CollectionsFilterSummary
                                allState={this.props.allState}
                            />
                        }
                        rightDropdown={
                            <ButtonDropdown label={txt.MenuTitle()}>
                                <RestrictedComponent
                                    route={OperatorAppRoutes.ParkingMeters}
                                    permissionChecker={
                                        CustomPermissionCheckers.syncAppInteractionAllowed
                                    }
                                >
                                    <div>
                                        <ButtonDropdownItem
                                            onClick={() =>
                                                this.readCollectionData()
                                            }
                                            label={txt.StartCollection()}
                                        />
                                        <ButtonDropdownSeparator />
                                    </div>
                                </RestrictedComponent>

                                <ButtonDropdownLinkItem
                                    href={`/ui-api/operator-account/taxomex/tom-collections/pdf?${queryParams}`}
                                    label={txt.Pdf()}
                                    disabled={
                                        this.state.collections.data.size === 0
                                    }
                                />
                                <ButtonDropdownLinkItem
                                    href={`/ui-api/operator-account/taxomex/tom-collections/csv?${queryParams}`}
                                    label={txt.Export()}
                                    disabled={
                                        this.state.collections.data.size === 0
                                    }
                                />
                            </ButtonDropdown>
                        }
                    />

                    <FilterLayoutBody>
                        <CollectionsList allState={this.props.allState} />
                    </FilterLayoutBody>
                </FilterLayout>
            </Body>
        );
    }
}
