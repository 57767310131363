import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';

import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { guide } from '../../i18n/SharedTexts.tsx';
export interface Texts {
    create: (hasWhitelist: boolean) => JSX.Element;
}

export const CreateGuide = (p: {
    login: CurrentOperatorLoginState.CurrentOperatorLogin;
    lang: string;
}) => {
    return (
        <Conditional c={p.login.permissions.permitsWrite}>
            {guide[p.lang].create(p.login.permissions.whitelistCreate)}
        </Conditional>
    );
};
