import { Translations } from '../../common/i18n/Text.ts';
import * as AddressCountrySelectionSlideIn from '../components/AddressCountrySelectionSlideIn.tsx';
/* tslint:disable:typedef max-line-length */

export const countrySelection: Translations<AddressCountrySelectionSlideIn.Texts> =
    {
        de: {
            Caption: () => 'Versandadresse Editieren',
            Heading: () => 'Versandland erfassen',
        },
        fr: {
            Caption: () => "Modifier l'adresse d'envoi",
            Heading: () => "Saisir le pays dans l'adresse d'envoi",
        },
        it: {
            Caption: () => "Modificare indirizzo d'invio",
            Heading: () => "Registrare la nazione nell'indirizzo d'invio.",
        },
    };
