import { texts } from '../common/components/translations/LicensePlateTexts.ts';

export enum LicensePlateType {
    CAR = 'CAR',
    MOTORCYCLE = 'MOTORCYCLE',
}

export const getTypeString = (
    language: string,
    type: LicensePlateType,
): string => {
    switch (type) {
        case LicensePlateType.CAR:
            return texts[language].CarType();
        case LicensePlateType.MOTORCYCLE:
            return texts[language].MotorcycleType();
        default:
            console.error(
                `No translation found for lp type '${type}'. Using type 'CAR' instead.`,
            );
            return texts[language].CarType();
    }
};
