import QRCodeReact from 'qrcode.react';
import { Box } from '@mui/material';
import { Localized } from '../common/components/Localized.tsx';
import { useParams } from 'react-router-dom';
import { Download } from '@mui/icons-material';

export function LostTicketRoute() {
    const urlParams = useParams<{ code: string | undefined }>();
    const code = urlParams.code ? urlParams.code : null;
    const qrCode = window.location.href;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                overflowY: 'auto',
                padding: 5,
            }}
        >
            {code === null ? (
                <Box sx={{ paddingTop: 5, textAlign: 'center' }}>
                    <h2>
                        <Localized
                            de="Nicht gefunden"
                            fr="Pas trouvé"
                            it="Non trovato"
                            en="Not Found"
                        />
                    </h2>
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '400px',
                            height: 'auto',
                        }}
                    >
                        <QRCodeReact
                            value={qrCode}
                            renderAs="svg"
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                    <Box sx={{ paddingTop: 5, textAlign: 'center' }}>
                        <h2>
                            <Localized
                                de="Verwenden Sie diesen QR-Code beim Verlassen vom Parkhaus."
                                fr="Utilisez ce code QR en sortant du parking."
                                it="Utilizza questo codice QR quando esci dal parcheggio."
                                en="Use this QR code when exiting the Parking."
                            />
                        </h2>
                    </Box>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`/ui-api/operator-account/lost-ticket/${code}`}
                    >
                        <Download />
                        <Localized
                            de="Herunterladen"
                            fr="Télécharger"
                            it="Scaricare"
                            en="Download"
                        />
                    </a>
                </>
            )}
        </Box>
    );
}
