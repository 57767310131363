import { Formatter } from 'dg-web-shared/lib/Date.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { isDefined, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { Translation } from '../../common/i18n/Text.ts';
import * as OperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import {
    getLoginById,
    getLoginTitle,
} from '../../common/state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import {
    ButtonDropdown,
    ButtonDropdownItem,
} from '../../ui/slidein/ButtonDropdown.tsx';
import {
    ConfirmationHeader,
    ErrorHeader,
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderButtonsContainer,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import * as CustomerClearanceTexts from '../i18n/CustomerClearanceTexts.ts';
import * as CustomerClearanceState from '../state/CustomerClearanceState.ts';
import { CustomerClearanceTableTexts } from './CustomerClearanceTable.tsx';

const cn = ElementNamer('CustomerClearanceDetailSlideIn');

const deleteClearanceByContractId = AsyncRequest.request(
    Http.OperatorAccount.CustomerClearance.delByContractId,
    (store: Flux.Store, res: Response): string => {
        CustomerClearanceState.DeleteResponse.setResponse(store, res);
        if (
            CustomerClearanceState.DeleteResponse.get(store).statusCode.cls
                .success
        ) {
            CustomerClearanceState.List.reset(store);
            CustomerClearanceState.Detail.reset(store);
        }
        return cn('deleteClearanceByContractId');
    },
);

const closeDetail = (store: Flux.Store): string => {
    CustomerClearanceState.DeleteResponse.reset(store);
    CustomerClearanceState.Detail.reset(store);
    return cn('closeDetail');
};

export interface CustomerClearanceDetailSlideInTexts {
    slideInHeader: Translation;
    confirmDelete: Translation;
    deletingClearance: Translation;
    deleteError: Translation;
    editLabel: Translation;
    delete: Translation;
}

interface CustomerClearanceDetailSlideInState {
    settings: SettingsState.State;
    detail: CustomerClearanceState.Detail.State;
    operatorLogins: OperatorLoginsState.List.State;
    deleteResponse: CustomerClearanceState.DeleteResponse.State;
}

export class CustomerClearanceDetailSlideIn extends Flux.Container<CustomerClearanceDetailSlideInState> {
    static displayName: string = cn('');

    stateSelector(): CustomerClearanceDetailSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            detail: CustomerClearanceState.Detail.get(this.props.allState),
            operatorLogins: OperatorLoginsState.List.get(this.props.allState),
            deleteResponse: CustomerClearanceState.DeleteResponse.get(
                this.props.allState,
            ),
        };
    }

    texts(): CustomerClearanceDetailSlideInTexts {
        return CustomerClearanceTexts.customerClearanceDetailSlideInTexts[
            this.state.settings.language
        ];
    }

    tableTexts(): CustomerClearanceTableTexts {
        return CustomerClearanceTexts.customerClearanceTableTexts[
            this.state.settings.language
        ];
    }

    renderBody(): JSX.Element | null {
        const clearance = this.state.detail.selectedClearance;

        if (!clearance) {
            return null;
        }

        return (
            <SlideInBody>
                <LabeledText label={this.tableTexts().createdDate()}>
                    {Formatter.dayMonthYear(clearance.created)}
                </LabeledText>
                <LabeledText label={this.tableTexts().createdBy()}>
                    {thenElse(
                        getLoginById(
                            this.state.operatorLogins.data,
                            clearance.adminId,
                        ),
                        l => getLoginTitle(l),
                        '',
                    )}
                </LabeledText>
                <LabeledText label={this.tableTexts().customerNr()}>
                    {clearance.customerNr}
                </LabeledText>
                <LabeledText label={this.tableTexts().remark()}>
                    {CustomerClearanceState.remark(clearance)}
                </LabeledText>
                <LabeledText label={this.tableTexts().permitTypeName()}>
                    {clearance.permitTypeName}
                </LabeledText>
            </SlideInBody>
        );
    }

    renderHeader(): JSX.Element {
        if (this.state.deleteResponse.pending) {
            return <LoaderHeader title={this.texts().deletingClearance()} />;
        } else if (this.state.deleteResponse.statusCode.cls.error) {
            return (
                <ErrorHeader
                    language={this.state.settings.language}
                    title={this.texts().deleteError()}
                    onCancel={() => this.update(closeDetail)}
                />
            );
        } else if (this.state.detail.confirmDeleteVisible) {
            return (
                <ConfirmationHeader
                    language={this.state.settings.language}
                    title={this.texts().confirmDelete()}
                    onCancel={() =>
                        this.update(store =>
                            CustomerClearanceState.Detail.stateWrite(store, {
                                confirmDeleteVisible: false,
                            }),
                        )
                    }
                    onConfirm={() => {
                        if (this.state.detail.selectedClearance) {
                            this.update(store =>
                                deleteClearanceByContractId(
                                    store,
                                    this.state.detail.selectedClearance
                                        ?.contractId ?? undefined,
                                ),
                            );
                        }
                    }}
                />
            );
        } else {
            return (
                <StandardFirstLevelHeader
                    onClose={() =>
                        this.update(CustomerClearanceState.Detail.reset)
                    }
                >
                    <SlideInHeaderTexts
                        hasLeftIcon={false}
                        title={this.texts().slideInHeader()}
                    />
                    <SlideInHeaderButtonsContainer>
                        <ButtonDropdown label={this.texts().editLabel()}>
                            <ButtonDropdownItem
                                label={this.texts().delete()}
                                onClick={() =>
                                    this.update(store =>
                                        CustomerClearanceState.Detail.stateWrite(
                                            store,
                                            {
                                                confirmDeleteVisible: true,
                                            },
                                        ),
                                    )
                                }
                            />
                        </ButtonDropdown>
                    </SlideInHeaderButtonsContainer>
                </StandardFirstLevelHeader>
            );
        }
    }

    render() {
        return (
            <HalfSlideIn open={isDefined(this.state.detail.selectedClearance)}>
                {this.renderBody()}
                {this.renderHeader()}
            </HalfSlideIn>
        );
    }
}
