import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { selectState, Store, UpdateProp } from 'dg-web-shared/lib/Flux.tsx';
import {
    ConfirmationHeader,
    HalfSlideIn,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import { Translation } from '../i18n/Text.ts';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';

import * as SettingsState from '../state/SettingsState.ts';
import { getOrElse, isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as Http from '../../api/Http.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import {
    CarTypeResponse,
    CarTypeState,
    ServerDataState,
} from '../state/LicensePlateState.ts';
import { carTypeTexts } from 'dg-web-shared/common/components/translations/LicensePlateTexts.ts';

export interface Texts {
    Type: Translation;
}

interface CarTypeEditState {
    settings: SettingsState.State;
    edit: CarTypeState.State;
    server: ServerDataState.State;
}

export const save = AsyncRequest.request(
    Http.OperatorAccount.LicensePlates.editTypeDescription,
    (store: Flux.Store, res: Response): string => {
        CarTypeResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            CarTypeState.reset(store);
            ServerDataState.refetchSameContext(store, true);
        }
        return 'CarTypeEditSlidein-save';
    },
);

const txt = (p: CarTypeEditState): Texts => {
    return carTypeTexts[p.settings.language];
};

const assemblePayload = (p: CarTypeEditState): string[] => {
    if (p.server.data) {
        const clone = p.server.data.typeDescriptions.slice();
        if (isDefined(p.edit.type1)) {
            if (clone.length > 0) {
                clone[0] = p.edit.type1;
            } else {
                clone.push(p.edit.type1);
            }
        }
        if (isDefined(p.edit.type2)) {
            if (clone.length > 1) {
                clone[1] = p.edit.type2;
            } else {
                clone.push(p.edit.type2);
            }
        }
        // remove empty strings (= deletions)
        return clone.slice(0, 2).filter(s => !!s);
    } else {
        return [];
    }
};

const Content = (p: CarTypeEditState & UpdateProp) => {
    const edit = p.edit;
    const server = p.server.data;
    if (server) {
        return (
            <SlideInBody>
                <TextField
                    inputType="text"
                    label={txt(p).Type()}
                    value={getOrElse(
                        edit.type1,
                        getOrElse<string>(server.typeDescriptions[0], ''),
                    )}
                    saveable={false}
                    onChange={(v: string) =>
                        p.update(store =>
                            CarTypeState.stateWrite(store, {
                                type1: v,
                            }),
                        )
                    }
                />
                <Conditional c={server.vignettes.length > 1}>
                    <TextField
                        inputType="text"
                        label={txt(p).Type() + ' 2'}
                        value={getOrElse(
                            edit.type2,
                            server.typeDescriptions.length > 1
                                ? server.typeDescriptions[1]
                                : '',
                        )}
                        saveable={false}
                        onChange={(v: string) =>
                            p.update(store =>
                                CarTypeState.stateWrite(store, {
                                    type2: v,
                                }),
                            )
                        }
                    />
                </Conditional>
            </SlideInBody>
        );
    } else {
        return null;
    }
};

export const CarTypeEditSlideIn = selectState<
    { outsideBody: boolean },
    CarTypeEditState
>(
    (store: Store): CarTypeEditState => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            edit: CarTypeState.get(store),
            server: ServerDataState.getState(store),
        };
    },
    p => {
        return (
            <HalfSlideIn open={p.edit.open} outsideBody={p.outsideBody}>
                <ConfirmationHeader
                    language={p.settings.language}
                    title={txt(p).Type()}
                    onCancel={() =>
                        p.update(store =>
                            CarTypeState.stateWrite(store, {
                                open: false,
                            }),
                        )
                    }
                    onConfirm={() =>
                        p.update(save, {
                            id: p.server.data ? p.server.data.id : 0,
                            types: assemblePayload(p),
                        })
                    }
                />
                <Content {...p} />
            </HalfSlideIn>
        );
    },
);
