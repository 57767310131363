import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as Date from 'dg-web-shared/lib/Date.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import {
    ColAlign,
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
} from '../../../ui/table/Table.tsx';
import { collectionListTexts } from '../../i18n/CollectionsTexts.ts';
import { ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import * as Text from '../../../common/i18n/Text.ts';
import { Guide } from './Guide.tsx';
import * as CurrentOperatorLoginState from '../../../common/state/CurrentOperatorLoginState.ts';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24.tsx';
import { TomCollection } from 'dg-web-shared/model/TomCollection.ts';
import * as TomCollectionsState from '../../state/TomCollectionsState.ts';
import { IconTopOffsetContainer } from '../../../zones/components/ZoneListBody.tsx';
import { ModalSpecialTabResultRefresher } from '../../../ui/layout/TabContent.tsx';
import { css } from '@emotion/css';
import { ColorHex } from '../../../ui/Colors.ts';
import { OperatorTypo } from '../../../ui/OperatorTypo.ts';
import { Icon16, Icon24 } from '../../../ui/icons/Icon.tsx';

export interface CollectionListTexts {
    headerDate: Text.Translation;
    headerParkingMeterName: Text.Translation;
    headerStick: Text.Translation;
    headerCollectionNumber: Text.Translation;
    chfAmount: Text.Translation;
    eurAmount: Text.Translation;
    tokenAmount: Text.Translation;
    headerTransactionCount: Text.Translation;

    tooManyRowsTitle: Text.Translation;
    tooManyRowsDescription: Text.Translation;

    noRowsTitle: Text.Translation;
    noRowsDescription: Text.Translation;
}

interface CollectionsListState {
    settings: SettingsState.State;
    login: CurrentOperatorLoginState.State;
    collections: TomCollectionsState.List.State;
    filter: TomCollectionsState.Filter.State;
}

export class CollectionsList extends Flux.Container<CollectionsListState> {
    stateSelector(): CollectionsListState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            login: CurrentOperatorLoginState.get(this.props.allState),
            collections: TomCollectionsState.List.get(this.props.allState),
            filter: TomCollectionsState.Filter.get(this.props.allState),
        };
    }

    openCollectionDetail(collection: TomCollection): void {
        this.update(store =>
            TomCollectionsState.Detail.stateWrite(store, {
                selectedCollection: collection,
            }),
        );
    }

    renderIntroduction(): JSX.Element {
        return (
            <Guide
                login={this.state.login}
                language={this.state.settings.language}
            />
        );
    }

    renderTooManyRows(): JSX.Element {
        const texts = collectionListTexts[this.state.settings.language];
        return (
            <ResultsError
                title={texts.tooManyRowsTitle()}
                description={texts.tooManyRowsDescription()}
            />
        );
    }

    renderNoRows(): JSX.Element {
        const texts = collectionListTexts[this.state.settings.language];
        return (
            <ResultsError
                title={texts.noRowsTitle()}
                description={texts.noRowsDescription()}
            />
        );
    }

    render() {
        const texts = collectionListTexts[this.state.settings.language];

        const collectionsListRefresher = (
            <ModalSpecialTabResultRefresher
                onRefreshClick={() =>
                    this.update(TomCollectionsState.List.forceRefetch, true)
                }
                successRequest={
                    this.state.collections.statusCode.statusCode === 200
                }
                pendingRequest={this.state.collections.pending}
            />
        );

        if (this.state.collections.pending) {
            return collectionsListRefresher;
        }

        if (!TomCollectionsState.Filter.filterActive(this.state.filter)) {
            return this.renderIntroduction();
        }

        if (
            this.state.collections.data.size > 0 &&
            this.state.collections.data.data.length < 1
        ) {
            return (
                <>
                    {collectionsListRefresher} {this.renderTooManyRows()}
                </>
            );
        }

        if (this.state.collections.data.size < 1) {
            return (
                <>
                    {collectionsListRefresher} {this.renderNoRows()}
                </>
            );
        }

        return (
            <>
                {collectionsListRefresher}
                <div>
                    <Table>
                        <TableHeader>
                            <EmptyTableHeaderColumn
                                width={ColumnWidth._24px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerCollectionNumber()}
                                width={ColumnWidth._72px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerDate()}
                                selected={true}
                                direction={SortDirection.Descending}
                                width={ColumnWidth._136px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerStick()}
                                width={ColumnWidth._136px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerParkingMeterName()}
                                width={ColumnWidth.variable}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.headerTransactionCount()}
                                width={ColumnWidth._136px}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.chfAmount()}
                                width={ColumnWidth._108px}
                                align={ColAlign.right}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.eurAmount()}
                                width={ColumnWidth._108px}
                                align={ColAlign.right}
                                delimiterBottom={false}
                            />
                            <TableHeaderColumn
                                name={texts.tokenAmount()}
                                width={ColumnWidth._108px}
                                align={ColAlign.right}
                                delimiterBottom={false}
                            />
                            <EmptyTableHeaderColumn
                                width={ColumnWidth._24px}
                                delimiterBottom={false}
                            />
                        </TableHeader>
                        <TableBody>
                            {this.state.collections.data.data.map(
                                (c: TomCollection) => {
                                    return (
                                        <CollectionRow
                                            collection={c}
                                            key={c.collectionId}
                                            language={
                                                this.state.settings.language
                                            }
                                            onClick={() =>
                                                this.openCollectionDetail(c)
                                            }
                                            allState={this.props.allState}
                                        />
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </div>
            </>
        );
    }
}

interface ResultsErrorProps {
    title: string;
    description: string;
}

const ResultsError = (p: ResultsErrorProps) => (
    <div
        className={css({
            textAlign: 'center',
            height: '80px',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            marginTop: '-40px',
        })}
    >
        <div className={css({ color: ColorHex.error })}>
            <Icon24 icon={Icons24.error} />
        </div>
        <div
            className={css({
                color: ColorHex.error,
                ...OperatorTypo.headingTwo,
            })}
        >
            {p.title}
        </div>
        <div
            className={css({
                color: ColorHex.typo,
                ...OperatorTypo.headingOne,
            })}
        >
            {p.description}
        </div>
    </div>
);

interface CollectionRowProps {
    allState: Flux.Store;
    collection: TomCollection;
    language: string;
    onClick: ClickHandler;
}

const CollectionRow = (p: CollectionRowProps): JSX.Element => (
    <DefaultTableRow onClick={p.onClick}>
        <TableColumn width={ColumnWidth._24px} />
        <TableColumn width={ColumnWidth._72px}>
            {p.collection.collectionNumber}
        </TableColumn>
        <TableColumn width={ColumnWidth._136px}>
            {Date.Formatter.dayMonthYearHourMinute(p.collection.collectionTime)}
        </TableColumn>
        <TableColumn width={ColumnWidth._136px}>
            {p.collection.stickDescription}
        </TableColumn>
        <TableColumn width={ColumnWidth.variable}>
            {p.collection.tomExternalOperatorId
                ? p.collection.tomExternalOperatorId
                : p.collection.tomId}{' '}
            - {p.collection.tomName}
        </TableColumn>

        <TableColumn width={ColumnWidth._136px}>
            {p.collection.transactionCount}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px} align={ColAlign.right}>
            {NumberFormatter.numberToPrice(
                p.collection.collectedAmountChf,
                false,
            )}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px} align={ColAlign.right}>
            {NumberFormatter.numberToPrice(
                p.collection.collectedAmountEuro,
                false,
            )}
        </TableColumn>
        <TableColumn width={ColumnWidth._108px} align={ColAlign.right}>
            {NumberFormatter.numberToPrice(
                p.collection.collectedAmountToken,
                false,
            )}
        </TableColumn>
        <TableColumn width={ColumnWidth._24px}>
            <IconTopOffsetContainer>
                <Icon16 icon={Icons16.chevronRight} />
            </IconTopOffsetContainer>
        </TableColumn>
    </DefaultTableRow>
);
