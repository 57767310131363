import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { ElementNamer } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import {
    ConfirmSaveHeader,
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
} from '../../ui/slidein/Slidein.tsx';
import * as CustomerClearanceState from '../state/CustomerClearanceState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import { isDefined, thenElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { Translation } from '../../common/i18n/Text.ts';
import * as CustomerClearanceTexts from '../i18n/CustomerClearanceTexts.ts';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as Http from '../../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';

const cn = ElementNamer('CustomerClearanceCreateSlideIn');

export interface CustomerClearanceCreateSlideInTexts {
    slideInHeader: Translation;
    permitTypes: Translation;
    customerNr: Translation;
    remark: Translation;

    createInProgress: Translation;

    invalidCustomerNr: Translation;
    noPermitTypesSelected: Translation;
    hasOverlaps: Translation;
}

interface CustomerClearanceCreateSlideInState {
    settings: SettingsState.State;
    add: CustomerClearanceState.Add.State;
    addResponse: CustomerClearanceState.AddResponse.State;
    addErrors: CustomerClearanceState.AddErrors.State;
}

export const closeCreate = (s: Flux.Store): string => {
    CustomerClearanceState.Add.reset(s);
    CustomerClearanceState.AddResponse.reset(s);
    CustomerClearanceState.AddErrors.reset(s);
    return cn('closeCreate');
};

const createClearance = AsyncRequest.request(
    Http.OperatorAccount.CustomerClearance.create,
    (store: Flux.Store, res: Response): string => {
        CustomerClearanceState.AddResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            closeCreate(store);
            CustomerClearanceState.List.reset(store);
        } else if (res.statusCode.cls.error) {
            CustomerClearanceState.AddErrors.reset(store);
            CustomerClearanceState.AddErrors.stateWrite(store, {
                errors: res.body.errors,
            });
        }
        return cn('createClearance');
    },
);

export class CustomerClearanceCreateSlideIn extends Flux.Container<CustomerClearanceCreateSlideInState> {
    static displayName: string = cn('');

    stateSelector(): CustomerClearanceCreateSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            add: CustomerClearanceState.Add.get(this.props.allState),
            addResponse: CustomerClearanceState.AddResponse.get(
                this.props.allState,
            ),
            addErrors: CustomerClearanceState.AddErrors.get(
                this.props.allState,
            ),
        };
    }

    texts(): CustomerClearanceCreateSlideInTexts {
        return CustomerClearanceTexts.customerClearanceCreateSlideInTexts[
            this.state.settings.language
        ];
    }

    isFormValid(): boolean {
        return CustomerClearanceState.Add.isValid(this.state.add);
    }

    renderHeader(): JSX.Element {
        if (this.state.addResponse.pending) {
            return <LoaderHeader title={this.texts().createInProgress()} />;
        } else {
            return (
                <ConfirmSaveHeader
                    language={this.state.settings.language}
                    title={this.texts().slideInHeader()}
                    onCancel={() => this.update(closeCreate)}
                    onSave={() => {
                        if (this.isFormValid()) {
                            this.update(createClearance, {
                                customerNr: parseInt(
                                    this.state.add.customerNr!,
                                    10,
                                ),
                                remark: this.state.add.remark,
                                permitTypeIds:
                                    this.state.add.permitTypeIds || [],
                            });
                        } else {
                            this.update(store =>
                                CustomerClearanceState.Add.stateWrite(store, {
                                    confirmPressedWhileError: true,
                                }),
                            );
                        }
                    }}
                />
            );
        }
    }

    renderSelectedPermitTypes(): JSX.Element | null {
        if (
            !this.state.add.permitTypeIds ||
            this.state.add.permitTypeIds.length === 0
        ) {
            return null;
        } else {
            return (
                <div>
                    {this.state.add.permitTypeIds.map((id: number) => {
                        const type = PermitTypeState.getById(
                            this.props.allState,
                            id,
                        );
                        if (isDefined(type)) {
                            return <div key={type.id}>{type.description}</div>;
                        } else {
                            return null;
                        }
                    })}
                </div>
            );
        }
    }

    customerNrErrorText(): string {
        return (this.state.add.confirmPressedWhileError &&
            !CustomerClearanceState.Add.isCustomerNrValid(this.state.add)) ||
            CustomerClearanceState.AddErrors.customerNrInvalid(
                this.state.addErrors,
            )
            ? this.texts().invalidCustomerNr()
            : '';
    }

    permitTypesErrorText(): string {
        return this.state.add.confirmPressedWhileError &&
            !CustomerClearanceState.Add.isPermitTypeIdsValid(this.state.add)
            ? this.texts().noPermitTypesSelected()
            : CustomerClearanceState.AddErrors.hasOverlaps(this.state.addErrors)
              ? this.texts().hasOverlaps()
              : '';
    }

    renderBody(): JSX.Element {
        return (
            <SlideInBody>
                <TextField
                    inputType="text"
                    label={this.texts().customerNr()}
                    onChange={(v: string) =>
                        this.update(store =>
                            CustomerClearanceState.Add.stateWrite(store, {
                                customerNr: v,
                            }),
                        )
                    }
                    value={thenElse(
                        this.state.add.customerNr,
                        cnr => cnr.toString(),
                        '',
                    )}
                    errorText={this.customerNrErrorText()}
                />
                <TextField
                    inputType="text"
                    label={this.texts().remark()}
                    onChange={(v: string) =>
                        this.update(store =>
                            CustomerClearanceState.Add.stateWrite(store, {
                                remark: v,
                            }),
                        )
                    }
                    value={thenElse(this.state.add.remark, r => r, '')}
                    maxLength={20}
                />
                <SingleSelection
                    label={this.texts().permitTypes()}
                    focused={false}
                    selection={this.renderSelectedPermitTypes()}
                    onClick={() =>
                        this.update(store =>
                            CustomerClearanceState.Add.stateWrite(store, {
                                permitTypeSelectionOpen: true,
                            }),
                        )
                    }
                    onClear={null}
                    errorText={this.permitTypesErrorText()}
                />
            </SlideInBody>
        );
    }

    render() {
        return (
            <HalfSlideIn open={!!this.state.add.createCustomerClearanceOpen}>
                {this.renderBody()}
                {this.renderHeader()}
            </HalfSlideIn>
        );
    }
}
