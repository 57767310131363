import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { LabeledText } from '../../ui/labeled-elements/LabeledText.tsx';
import { RadioButton } from '../../ui/switches/Switches.tsx';
import { texts } from 'dg-web-shared/common/components/translations/LicensePlateTexts.ts';

export function LicensePlateTypeSelection(props: {
    allowedTypes?: LicensePlateType[];
    selectedType: LicensePlateType | null;
    onSelect: (type: LicensePlateType) => void;
    language: string;
}) {
    function disabled(type: LicensePlateType) {
        if (!props.allowedTypes) {
            return false;
        }

        return !props.allowedTypes.includes(type);
    }

    const selectedType =
        props.allowedTypes && props.selectedType && disabled(props.selectedType)
            ? props.allowedTypes[0]
            : props.selectedType;

    const t = texts[props.language];

    return (
        <LabeledText label={t.Type()}>
            <div>
                <RadioButton
                    label={t.CarType()}
                    selected={selectedType === LicensePlateType.CAR}
                    disabled={disabled(LicensePlateType.CAR)}
                    onClick={() => props.onSelect(LicensePlateType.CAR)}
                />
            </div>
            <div>
                <RadioButton
                    label={t.MotorcycleType()}
                    selected={selectedType === LicensePlateType.MOTORCYCLE}
                    disabled={disabled(LicensePlateType.MOTORCYCLE)}
                    onClick={() => props.onSelect(LicensePlateType.MOTORCYCLE)}
                />
            </div>
        </LabeledText>
    );
}
