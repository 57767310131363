import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { detailTexts } from './Texts.tsx';
import * as SettingsState from '../common/state/SettingsState.ts';
import {
    Detail,
    Filter,
    List,
    ProcessResponse,
    UI,
} from './ClearanceRequestsState.ts';
import * as PermitTypeState from '../common/state/PermitTypeState.ts';
import {
    HalfSlideIn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../ui/slidein/Slidein.tsx';
import * as AsyncRequest from '../AsyncRequest.ts';
import * as Http from '../api/Http.ts';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import { SlideInHeaderButtonsContainer } from '../ui/slidein/SlideInHeader2.tsx';
import {
    ButtonDropdown,
    ButtonDropdownItem,
} from '../ui/slidein/ButtonDropdown.tsx';
import { LabeledText } from '../ui/labeled-elements/LabeledText.tsx';
import { ClearanceRequestAddressBlock } from './Address.tsx';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import { TextField } from '../ui/labeled-elements/TextField.tsx';
import {
    ClearanceRequest,
    ClearanceRequestLicensePlate,
} from '../common/models/ClearanceRequest.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../ui/Colors.ts';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { Icon16 } from '../ui/icons/Icon.tsx';
import { checkbox } from 'dg-web-shared/ui/icons/Icons16.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export interface DetailTexts {
    DenyRequest: Translation;
    AcceptRequest: Translation;
    Requester: Translation;
    RequestCaption: Translation;
    LicensePlates: Translation;
    Remark: Translation;
    DropdownLabel: Translation;
    PermitType: Translation;
    Modifiers: Translation;
}

const texts = (p: { settings: SettingsState.State }) =>
    detailTexts[p.settings.language];

const processRequest = AsyncRequest.request(
    Http.OperatorAccount.ClearanceRequests.process,
    (store: Flux.Store, res: Response): string => {
        ProcessResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            Detail.reset(store);
            List.refetchSameContext(store, true);
        }
        return 'clearance-requests/Detail.processRequest';
    },
);

interface HeaderProps {
    settings: SettingsState.State;
    processResponse: ProcessResponse.State;
    update: Flux.Updater;
    clearanceRequest: ClearanceRequest;
    detail: Detail.State;
    hasApprovableLpIds: boolean;
}

const HeaderActionMenu = (p: HeaderProps) => {
    const processHandler = (approve: boolean) =>
        p.update(processRequest, {
            id: p.clearanceRequest.id,
            acceptedLps: p.detail.lpIds,
            remark: p.detail.remark || '',
            approve: approve,
            priceModifierId:
                p.detail.priceModifierId ??
                p.clearanceRequest.priceModifiers[0]?.id,
        });
    if (p.clearanceRequest.status === 'pending') {
        const notAcceptable =
            p.hasApprovableLpIds && p.detail.lpIds.length === 0;
        const mayDeny = !p.hasApprovableLpIds || p.detail.lpIds.length === 0;
        return (
            <SlideInHeaderButtonsContainer>
                <ButtonDropdown label={texts(p).DropdownLabel()}>
                    <ButtonDropdownItem
                        label={texts(p).AcceptRequest()}
                        onClick={() => processHandler(true)}
                        disabled={notAcceptable}
                    />
                    <ButtonDropdownItem
                        label={texts(p).DenyRequest()}
                        onClick={() => processHandler(false)}
                        disabled={!mayDeny}
                    />
                </ButtonDropdown>
            </SlideInHeaderButtonsContainer>
        );
    } else {
        return null;
    }
};
const Header = (p: HeaderProps) => {
    if (p.processResponse.pending) {
        return <LoaderHeader />;
    }
    return (
        <StandardFirstLevelHeader onClose={() => p.update(Detail.reset)}>
            <SlideInHeaderTexts
                title={texts(p).RequestCaption()}
                hasLeftIcon={false}
            />
            <HeaderActionMenu {...p} />
        </StandardFirstLevelHeader>
    );
};

interface LicensePlateProps {
    lp: ClearanceRequestLicensePlate;
    onClick: ClickHandler | null;
    selectedLps: number[];
}

const LicensePlate = (p: LicensePlateProps) => {
    const editMode = !!p.onClick;
    const isSelected = editMode
        ? p.selectedLps.indexOf(p.lp.clearanceRequestCarId) > -1
        : p.lp.requestGranted;
    return (
        <Clickable
            element="div"
            className={css({
                color: isSelected
                    ? ColorHex.darkblue
                    : ColorHex.rgba(ColorHex.darkblue, 0.6),
            })}
            onClick={p.onClick}
            disabled={!editMode}
        >
            <div
                className={css({
                    position: 'relative',
                    top: '3px',
                    marginRight: '16px',
                    display: 'inline-block',
                    verticalAlign: 'baseline',
                })}
            >
                <Icon16 icon={isSelected ? checkbox.on : checkbox.off} />
            </div>
            <span>{p.lp.licensePlateNr}</span>
            <span
                className={css({
                    ...Typo.captionC2,
                    marginLeft: '6px',
                })}
            >
                {p.lp.country}
            </span>
        </Clickable>
    );
};

const Remark = (p: {
    detail: ClearanceRequest;
    settings: SettingsState.State;
    update: Flux.Updater;
    remark: string | null;
}) =>
    p.detail.status !== 'pending' ? (
        <LabeledText label={texts(p).Remark()}>
            {p.detail.remark || '-'}
        </LabeledText>
    ) : (
        <TextField
            label={texts(p).Remark()}
            inputType="text"
            multiline={true}
            value={p.remark || ''}
            maxLength={500}
            onChange={(v: string) => {
                return p.update(store =>
                    Detail.stateWrite(store, { remark: v }),
                );
            }}
        />
    );

export const DetailSlideIn = Flux.selectState<
    { hideMultipliers?: boolean },
    {
        settings: SettingsState.State;
        filter: Filter.State;
        permitTypes: PermitTypeState.State;
        ui: UI.State;
        detail: Detail.State;
        processResponse: ProcessResponse.State;
    }
>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        filter: Filter.get(store),
        permitTypes: PermitTypeState.get(store),
        ui: UI.get(store),
        detail: Detail.get(store),
        processResponse: ProcessResponse.get(store),
    }),
    p =>
        p.detail.selectedClearanceRequest ? (
            <HalfSlideIn open={true} outsideBody={false}>
                <Header
                    {...p}
                    clearanceRequest={p.detail.selectedClearanceRequest}
                    hasApprovableLpIds={
                        p.detail.selectedClearanceRequest.licensePlates.length >
                        0
                    }
                />
                <SlideInBody>
                    <LabeledText label={texts(p).PermitType()}>
                        {
                            p.detail.selectedClearanceRequest
                                .permitTypeDescription
                        }
                    </LabeledText>
                    <LabeledText label={texts(p).Requester()}>
                        <ClearanceRequestAddressBlock
                            address={p.detail.selectedClearanceRequest.address}
                        />
                    </LabeledText>
                    {p.detail.selectedClearanceRequest.licensePlates.length >
                    0 ? (
                        <LabeledText label={texts(p).LicensePlates()}>
                            {p.detail.selectedClearanceRequest.licensePlates.map(
                                lp => (
                                    <LicensePlate
                                        key={lp.clearanceRequestCarId}
                                        lp={lp}
                                        onClick={
                                            p.detail.selectedClearanceRequest &&
                                            p.detail.selectedClearanceRequest
                                                .status === 'pending'
                                                ? () =>
                                                      p.update(store =>
                                                          Detail.toggleLpId(
                                                              store,
                                                              lp.clearanceRequestCarId,
                                                          ),
                                                      )
                                                : null
                                        }
                                        selectedLps={p.detail.lpIds}
                                    />
                                ),
                            )}
                        </LabeledText>
                    ) : null}
                    {!p.hideMultipliers &&
                        p.detail.selectedClearanceRequest.priceModifiers
                            .length > 0 && (
                            <LabeledText label={texts(p).Modifiers()}>
                                {p.detail.selectedClearanceRequest.priceModifiers.map(
                                    (modifier, index) => (
                                        <LabeledToggle
                                            key={modifier.id}
                                            type={ToggleType.radio}
                                            label={modifier.label}
                                            selected={
                                                p.detail.priceModifierId !==
                                                null
                                                    ? p.detail
                                                          .priceModifierId ===
                                                      modifier.id
                                                    : index === 0
                                            }
                                            onClick={
                                                p.detail
                                                    .selectedClearanceRequest
                                                    ?.status === 'pending'
                                                    ? () =>
                                                          p.update(store =>
                                                              Detail.stateWrite(
                                                                  store,
                                                                  {
                                                                      priceModifierId:
                                                                          modifier.id,
                                                                  },
                                                              ),
                                                          )
                                                    : () => null
                                            }
                                        />
                                    ),
                                )}
                            </LabeledText>
                        )}
                    {p.detail.selectedClearanceRequest.customFields.map(f => {
                        const label = (() => {
                            switch (p.settings.language) {
                                case 'de':
                                    return f.nameDe;
                                case 'fr':
                                    return f.nameFr;
                                case 'it':
                                    return f.nameIt;
                                case 'en':
                                    return f.nameEn;
                                default:
                                    return f.nameDe;
                            }
                        })();
                        return (
                            <LabeledText key={label} label={label}>
                                {f.value}
                            </LabeledText>
                        );
                    })}
                    <Remark
                        {...p}
                        detail={p.detail.selectedClearanceRequest}
                        remark={p.detail.remark}
                    />
                    {p.detail.selectedClearanceRequest.operatorLoginName && (
                        <LabeledText
                            label={
                                <Localized
                                    de="Bearbeitet durch"
                                    fr="Traitée par"
                                    it="Elaborata da"
                                    en="Processed by"
                                />
                            }
                        >
                            {
                                p.detail.selectedClearanceRequest
                                    .operatorLoginName
                            }
                        </LabeledText>
                    )}
                </SlideInBody>
            </HalfSlideIn>
        ) : (
            <HalfSlideIn open={false} />
        ),
);
