import {
    compareLicensePlateCountries,
    LicensePlateCountry,
} from 'dg-web-shared/dto/LicensePlateCountry.ts';
import { MultiLangString } from 'dg-web-shared/dto/MultiLangString.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';

export type { LicensePlateCountry } from 'dg-web-shared/dto/LicensePlateCountry.ts';

export interface AddressCountry {
    id: string;
    name: MultiLangString;
}

export namespace LicensePlateCountries {
    export type State = ServerStateSlice.ServerState<LicensePlateCountry[]>;

    export const { get, setResponse } = ServerStateSlice.generateServerState<
        LicensePlateCountry[]
    >(
        'common-Meta-icensePlateCountryState',
        () => [],
        (store: Flux.Store, state: State) => {
            if (state.shouldFetch) {
                store.update(fetchLicensePlateCountries);
            }
        },
        (body: LicensePlateCountry[]): LicensePlateCountry[] => {
            body.sort(compareLicensePlateCountries);
            return body;
        },
    );

    const fetchLicensePlateCountries = AsyncRequest.request(
        Http.getLicensePlateCountries,
        setResponse,
    );
    export const getName = (
        store: Flux.Store,
        id: string,
        lang: string,
    ): string => {
        const find = get(store).data.filter(lpc => lpc.id === id);
        return find.length > 0 ? find[0].name[lang] || '' : '...';
    };
}

export namespace Languages {
    export interface Language {
        shortcut: string;
        name: MultiLangString;
    }

    export type State = ServerStateSlice.ServerState<Language[]>;

    export const { get, setResponse } = ServerStateSlice.generateServerState<
        Language[]
    >(
        'common-MetaState-Languages.Language',
        () => [],
        (store: Flux.Store, state: State) => {
            if (state.shouldFetch) {
                store.update(fetchLanguages);
            }
        },
        body => body,
    );

    const fetchLanguages = AsyncRequest.request(Http.getLanguages, setResponse);
}

export namespace AddressCountries {
    export type State = ServerStateSlice.ServerState<AddressCountry[]>;

    export const { get, setResponse } = ServerStateSlice.generateServerState<
        AddressCountry[]
    >(
        'common-Meta-AddressCountries',
        () => [],
        (store: Flux.Store, state: State) => {
            if (state.shouldFetch) {
                store.update(fetchAddressCountries);
            }
        },
        (body: any): AddressCountry[] => body,
    );

    const fetchAddressCountries = AsyncRequest.request(
        Http.getAddressCountries,
        setResponse,
    );

    export const getName = (
        store: Flux.Store,
        id: string,
        lang: string,
    ): string => {
        const find = get(store).data.filter(ac => ac.id === id);
        return find.length > 0
            ? // eslint-disable-next-line no-constant-binary-expression
              `${find[0].id} — ${find[0].name[lang]}` || ''
            : '...';
    };
}
