import { Translations } from '../../common/i18n/Text.ts';
import * as ClearanceViewHeader from '../components/ClearanceViewHeader.tsx';

export const texts: Translations<ClearanceViewHeader.Texts> = {
    de: {
        active: () => 'Aktive Freigabe',
        expired: () => 'Inaktive Freigabe',
        clearance: () => 'Freigabe',
        terminateTooltip: () => 'Freigabe löschen',
        editLabel: () => 'Bearbeiten',
    },
    fr: {
        active: () => 'Approbation active',
        expired: () => 'Approbation inactive',
        clearance: () => 'approbation',
        terminateTooltip: () => 'Effacer approbation',
        editLabel: () => 'Modifier',
    },
    it: {
        active: () => 'Approvazione attiva',
        expired: () => 'Approvazione inattiva',
        clearance: () => 'approvazione',
        terminateTooltip: () => 'Elimina approvazione',
        editLabel: () => 'Modificare',
    },
};
