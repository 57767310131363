import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import {
    HalfSlideIn,
    SlideInBody,
    StandardFirstLevelHeader,
} from '../../../ui/slidein/Slidein.tsx';
import * as SettingsState from '../../../common/state/SettingsState.ts';
import * as FilteredClearancesState from '../../state/FilteredClearancesState.ts';
import { PermitTypeFilterSelection } from './PermitTypeFilter.tsx';
import { OperatorLoginFilterSelection } from './OperatorLoginFilter.tsx';
import { IssueDateFilterSelection } from './IssueDateFilter.tsx';
import { PermitFilterSelection } from './PermitFilter.tsx';
import { Translation } from '../../../common/i18n/Text.ts';
import { filteredClearancesFilterHalfSlideInTexts } from '../../i18n/FilteredClearancesTexts.ts';
import { ValidDateFilterSelection } from './ValidDateFilter.tsx';

export interface FilteredClearancesFilterHalfSlideInTexts {
    title: Translation;
}

interface FilteredClearancesFilterHalfSlideInState {
    settings: SettingsState.State;
    filter: FilteredClearancesState.Filter.State;
}

export class FilteredClearancesFilterHalfSlideIn extends Flux.Container<FilteredClearancesFilterHalfSlideInState> {
    stateSelector(): FilteredClearancesFilterHalfSlideInState {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            filter: FilteredClearancesState.Filter.get(this.props.allState),
        };
    }

    render() {
        return (
            <HalfSlideIn open={this.state.filter.filterSlideInVisible}>
                <StandardFirstLevelHeader
                    onClose={() =>
                        this.props.allState.update(store =>
                            FilteredClearancesState.Filter.stateWrite(store, {
                                filterSlideInVisible: false,
                            }),
                        )
                    }
                    title={filteredClearancesFilterHalfSlideInTexts[
                        this.state.settings.language
                    ].title()}
                />
                <SlideInBody>
                    <PermitTypeFilterSelection />
                    <OperatorLoginFilterSelection
                        allState={this.props.allState}
                    />
                    <IssueDateFilterSelection allState={this.props.allState} />
                    <PermitFilterSelection allState={this.props.allState} />
                    <ValidDateFilterSelection allState={this.props.allState} />
                </SlideInBody>
            </HalfSlideIn>
        );
    }
}
