import superagent from 'superagent';
import { LATEST_GITHUB_RELEASE_API_URL } from 'dg-web-shared/model/GithubRelease.ts';

export namespace SyncAppGithubRelease {
    export namespace Latest {
        export const get = () => {
            return superagent.get(LATEST_GITHUB_RELEASE_API_URL);
        };
    }
}
