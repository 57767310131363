import { css } from '@emotion/css';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { BasicButton } from '../ui/buttons/BasicButton.tsx';
import { TextField } from '../ui/labeled-elements/TextField.tsx';
import { passwordChangeTokenTexts, PasswordChangeTokenTexts } from './i18n.ts';
import { localState } from 'dg-web-shared/lib/Flux.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const ChangePasswordForm = localState<Props, State>(
    {
        password: '',
        passwordEdited: false,
        confirmation: '',
    },
    props => {
        const t = passwordChangeTokenTexts[props.language];

        return (
            <div
                className={style}
                onKeyDown={e => {
                    if (e && e.keyCode === 13) {
                        handleEnter(props, props.state);
                    }
                }}
            >
                <p className={titleStyle}>
                    {t.resetPasswordForUserX(
                        props.operatorLogin.firstName,
                        props.operatorLogin.lastName,
                    )}
                </p>

                <div className={fieldStyle}>
                    <TextField
                        label={t.newPassword()}
                        inputType="password"
                        value={props.state.password}
                        onChange={password => {
                            props.setState({ password });
                        }}
                        onBlur={() => {
                            props.setState({ passwordEdited: true });
                        }}
                        errorText={passwordErrorText(t, props.state)}
                        context="darkblue"
                    />
                </div>

                <div className={fieldStyle}>
                    <TextField
                        label={t.confirmPassword()}
                        inputType="password"
                        value={props.state.confirmation}
                        onChange={confirmation => {
                            props.setState({ confirmation });
                        }}
                        errorText={confirmationErrorText(t, props.state)}
                        context="darkblue"
                    />
                </div>

                <div className={buttonStyle}>
                    <BasicButton
                        label={t.save()}
                        onClick={() => props.onSave(props.state.password)}
                        disabled={
                            validate(
                                props.state.password,
                                props.state.confirmation,
                            ) !== ValidationResult.OK
                        }
                        context="darkblue"
                    />
                </div>
            </div>
        );
    },
);

interface Props {
    language: Language;
    operatorLogin: { firstName: string; lastName: string };
    onSave: (password: string) => void;
}

export interface State {
    password: string;
    passwordEdited: boolean;
    confirmation: string;
}

function handleEnter(props: Props, state: State): void {
    if (validate(state.password, state.confirmation) === ValidationResult.OK) {
        props.onSave(state.password);
    }
}

function passwordErrorText(t: PasswordChangeTokenTexts, state: State): string {
    if (!state.passwordEdited) {
        return '';
    }

    switch (validate(state.password, state.confirmation)) {
        case ValidationResult.ERROR_EMPTY:
            return t.requiredField();

        case ValidationResult.ERROR_TOO_SHORT:
            return t.passwordIsTooShort(MIN_PASSWORD_LENGTH);

        default:
            return '';
    }
}

function confirmationErrorText(
    t: PasswordChangeTokenTexts,
    state: State,
): string {
    if (
        !state.passwordEdited ||
        state.confirmation.length < state.password.length
    ) {
        return '';
    }

    if (
        validate(state.password, state.confirmation) ===
        ValidationResult.ERROR_CONFIRMATION_MISMATCH
    ) {
        return t.passwordFieldsDoNotMatch();
    }

    return '';
}

function validate(password: string, confirmation: string): ValidationResult {
    if (password === '') {
        return ValidationResult.ERROR_EMPTY;
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
        return ValidationResult.ERROR_TOO_SHORT;
    }

    if (password !== confirmation) {
        return ValidationResult.ERROR_CONFIRMATION_MISMATCH;
    }

    return ValidationResult.OK;
}

enum ValidationResult {
    OK,
    ERROR_EMPTY,
    ERROR_TOO_SHORT,
    ERROR_CONFIRMATION_MISMATCH,
}

export const MIN_PASSWORD_LENGTH = 5;

const style = css`
    display: flex;
    flex-direction: column;
    transform: translateX(0);
`;

const titleStyle = css`
    font-size: 28px;
    line-height: 36px;
    ${Typo.robotoThin};
    margin: 25px 25px 0px 25px;
`;

const fieldStyle = css`
    margin-left: 25px;
    margin-right: 25px;
`;

const buttonStyle = css`
    align-self: center;
    margin-top: 25px;
`;
