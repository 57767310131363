import { ContentBody, SingleContentBody, ContentBodyProps } from './Shared.tsx';

export { ColumnContent, LeftColumn, RightColumn } from './Shared.tsx';
export { Container } from './Container.tsx';

export const Body = (p: ContentBodyProps) => {
    return (
        <SingleContentBody>
            <ContentBody {...p}>{p.children}</ContentBody>
        </SingleContentBody>
    );
};
